import axios from "axios";
import { change, formValueSelector, initialize } from "redux-form";

import history from "../../components/common/routers/history";
import {
  deserializeFieldsCarteira,
  deserializeFieldsCarteiraPrecificacaoFormFilter,
  serializeFieldsCarteiraFluxo,
} from "../../components/business/carteira/mappings/CarteiraMap";

import { showAllMessages } from "../message/ErrorMessagesAPIAction";

import {
  PRECIFICACAO_ROUTE_PATH,
  CARTEIRA_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";
import { downloadExcelFile } from "../../utils/helpers/FileHelper";
import { getCurrentDateTimeString } from "../../utils/helpers/DateTimeHelper";

export const CARTEIRAS_FETCHED = "CARTEIRAS_FETCHED";
export const INDICADORES_CARTEIRA_FETCHED = "INDICADORES_CARTEIRA_FETCHED";
export const CARTEIRAS_CONSULT_CURRENT = "CARTEIRAS_CONSULT_CURRENT";
export const LOAD_FILTER_FORM_CARTEIRA = "LOAD_FILTER_FORM_CARTEIRA";
export const LOAD_TELA_FLUXOS_CARTEIRA = "LOAD_TELA_FLUXOS_CARTEIRA";
export const RESET = "RESET";
export const FUNDOS_CARTEIRA_OPTIONS_FETCHED =
  "FUNDOS_CARTEIRA_OPTIONS_FETCHED";

export const EMAIL_NAO_ENVIADO = "Não foi enviado e-mail nesta etapa.";

export const CARTEIRA_SHOW_DELETE_DIALOG = "CARTEIRA_SHOW_DELETE_DIALOG";

const CARTEIRA_API =
  process.env.REACT_APP_URL_API +
  process.env.REACT_APP_PATH_FUNDO_CARTEIRA

const FUNDO_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_FUNDO;

export function loadFilterFormCarteira(consult) {
  return async (dispatch) => {
    try {
      const result = await axios.get(CARTEIRA_API + "tela-validacoes-pendentes");
      const { dataFiltroInicial, dataFiltroFinal } = result.content;

      const dataInicio = dataFiltroInicial.slice(0, 10);
      const dataFinal = dataFiltroFinal.slice(0, 10);

      dispatch([
        {
          type: LOAD_FILTER_FORM_CARTEIRA,
          payload: result.content,
        },
        !consult && change("CarteiraForm", "dataInicio", dataInicio),
        !consult && change("CarteiraForm", "dataFinal", dataFinal),
        !consult && getCarteiras({dataInicio, dataFinal}),
        consult && getCarteiras(consult, consult?.pagingConfig?.pageIndex),
      ]);
    } catch (error) {
      // Handle errors appropriately
    }
  };
}

export function getCarteiras(filters, page = 1) {
  const params = deserializeFieldsCarteiraPrecificacaoFormFilter(filters, page);

  return (dispatch) => {
    return axios.post(`${CARTEIRA_API}validacoes-pendentes`, params).then((resultValidacao) => {
      return axios.post(`${CARTEIRA_API}indicadores-validacoes-pendentes`, params).then((result) => {
        const { dias, status } = result.content;

        dispatch([
          {
            type: CARTEIRAS_FETCHED,
            payload: resultValidacao,
          },
          {
            type: INDICADORES_CARTEIRA_FETCHED,
            payload: { dias, status },
          },
          {
            type: CARTEIRAS_CONSULT_CURRENT,
            payload: params
          },
        ])
      })
    })
    // .catch((error) => {
    //   const messages = error.response.data.messages

    //   dispatch([
    //     {
    //       type: CARTEIRAS_FETCHED,
    //       payload: null,
    //     },
    //     {
    //       type: INDICADORES_CARTEIRA_FETCHED,
    //       payload: null,
    //     },
    //   ])
    // });
  };
}

export function getSugestaoFundoCarteira(term) {
  return (dispatch) => {
    return axios.get(`${FUNDO_API}sugestoes?termo=${term}`).then((result) =>
      dispatch({
        type: FUNDOS_CARTEIRA_OPTIONS_FETCHED,
        payload: result.content,
      })
    );
  };
}

export function resetPagina() {
  return (dispatch) => {
    return dispatch([
      { type: RESET },
    ]);
  };
}

export function sairDaPagina() {
  return (dispatch) => {
    return dispatch([
      { type: RESET },
      history.push(
        `/${PRECIFICACAO_ROUTE_PATH}/${CARTEIRA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`
      ),
    ]);
  };
}

export function loadCarteiraForm(idCarteira, pagina = null) {
  return (dispatch) => {
    return axios
      .get(CARTEIRA_API + `tela/${idCarteira || ""}`)
      .then((result) => {
        const carteiraPrecificacao = result.content;
        const values = serializeFieldsCarteiraFluxo(carteiraPrecificacao);
        if (result.messages.infos.length > 0) {
          dispatch([showAllMessages(result.messages), sairDaPagina()]);
        } else {
          dispatch([
            idCarteira ? history.push(`/${CARTEIRA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/${idCarteira}`) : null,
            showAllMessages(result.messages),
            initialize("CarteiraDetail", values),
            {
              type: LOAD_TELA_FLUXOS_CARTEIRA,
              payload: result.content,
            },
          ]);
        }
      });
  };
}

export function revisarDados(formValues) {
  const params = deserializeFieldsCarteira(formValues);

  return (dispatch) => {
    return axios
      .post(CARTEIRA_API + `revisar`, params).then((result) => {
        dispatch([
          showAllMessages(result.messages),
          history.push(`/${CARTEIRA_ROUTE_PATH}`)
        ])
      })
  }
}

export function validarDados(id) {
  return (dispatch) => {
    return axios
      .post(CARTEIRA_API + `validar/${id}`).then((result) => {
        dispatch([
          showAllMessages(result.messages),
          history.push(`/${CARTEIRA_ROUTE_PATH}`)
        ])
      })
  }
}

export function exportarRelatorioAdministrador(id, nomeFundo) {
  return (dispatch) => {
    return axios
      .post(`${CARTEIRA_API}exportar-planilha-base/${id}`)
      .then((result) => {
        dispatch([
          downloadExcelFile(result.content, `Relatório Administrador - ${nomeFundo} - ${getCurrentDateTimeString()}.xlsx`),
          showAllMessages(result.messages),
        ]);
      });
  };
}

export function retornaValor(field) {
  return (dispatch, getState) => {
    const selector = formValueSelector('CarteiraDetail')
    let fieldBlocked = selector(getState(), `${field}Blocked`);

    return dispatch([
        change("CarteiraDetail", field, fieldBlocked),
    ]);
  }
}