import axios from "axios";
import { change } from "redux-form";
import moment from "moment";

import history from './../../components/common/routers/history';
import {
    PRECIFICACAO_ROUTE_PATH,
    INDICE_ROUTE_PATH,
    LISTA_ROUTE_PATH,
    NOVO_ROUTE_PATH,
    DETALHAR_ROUTE_PATH,
    EDITAR_ROUTE_PATH
} from "./../../components/common/routers/constantesRoutes";

import { showAllMessages } from "./../../actions/message/ErrorMessagesAPIAction";
import { showConfirmDialog } from './../common/modal/ConfirmDialogAction';
import { downloadExcelFile } from "./../../utils/helpers/FileHelper";

import {
    serializeFieldsIndiceForm,
    deserializeFieldsIndiceForm,
    deserializeFieldsIndiceFormFilterExportExcel,
    deserializeFieldsCalculoVariacaoPercentualIndice,
    deserializeFieldsIndiceFormFilter,
} from "./../../components/business/precificacao/mappings/IndiceMap";


export const INDICES_FETCHED = "INDICES_FETCHED";
export const INDICE_SHOW_DELETE_DIALOG = 'INDICE_SHOW_DELETE_DIALOG';
export const LOAD_FILTER_FORM_INDICE = "LOAD_FILTER_FORM_INDICE";
export const LOAD_FORM_INDICE = "LOAD_FORM_INDICE";
export const CLEAN_LIST_CONTENT = "CLEAN_LIST_CONTENT";
export const SET_TAXA_MENSAL_VALUE = "SET_TAXA_MENSAL_VALUE";
export const CHANGE_HEADER = "CHANGE_HEADER";
export const INDICES_DI_FUTURO_FETCHED = "INDICES_DI_FUTURO_FETCHED";

export const ID_TAXA_DI_FUTURO = 10;

const INDICE_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PRECIFICACAO_API + process.env.REACT_APP_PATH_INDICE_TAXA_API;

export function getIndices(filters, page = 1) {
    if (isTaxaDiFuturo(filters.tipoTaxa)) {
        return consultarTaxaDiFuturo(filters, page);
    } else {
        return consultarTaxas(filters, page);
    }
}


function isTaxaDiFuturo(taxa) {
    return taxa && ID_TAXA_DI_FUTURO === taxa.id;
}

export function consultarTaxaDiFuturo(filters, page) {
    const taxaMensal = isTaxaMensal(filters.tipoTaxa);
    const params = deserializeFieldsIndiceFormFilter(filters, page, taxaMensal);

    return (dispatch) => {
        return axios
            .post(`${INDICE_API}indicesDiFuturo`, { ...params })
            .then(result =>
                dispatch([
                    {
                        type: INDICES_DI_FUTURO_FETCHED,
                        payload: { result },
                    }
                ])
            );
    }

}

export function consultarTaxas(filters, page) {
    const tipoTaxa = filters.tipoTaxa;
    const taxaMensal = isTaxaMensal(tipoTaxa);
    const params = deserializeFieldsIndiceFormFilter(filters, page, taxaMensal);

    const changeHeader = true && taxaMensal;

    return (dispatch) => {
        return axios
            .post(`${INDICE_API}indices`, { ...params })
            .then(result =>
                dispatch([
                    {
                        type: INDICES_FETCHED,
                        payload: { result, tipoTaxa },
                    },
                    changeTaxaFilter(tipoTaxa, filters.dataInicio, changeHeader),
                ])
            );
    }
}

export function changeTaxaFilter(taxa, dataInicio, changeHeader = false) {
    const taxaMensal = isTaxaMensal(taxa);
    let novaDataInicio = '';

    if (dataInicio) {
        novaDataInicio = transformDataInicio(dataInicio, taxaMensal);
    }

    return (dispatch) => {
        return dispatch([
            novaDataInicio.length > 0 ? change('indiceForm', 'dataInicio', novaDataInicio) : undefined,
            {
                type: CHANGE_HEADER,
                payload: changeHeader
            },
            {
                type: SET_TAXA_MENSAL_VALUE,
                payload: taxaMensal
            },
        ]);
    }
}


function transformDataInicio(dataInicio, taxaMensal) {
    let data = dataInicio;

    const isDataMesAno = dataInicio !== null && (dataInicio.match(/-/g) || []).length === 1;

    if (taxaMensal && dataInicio && !isDataMesAno) {
        data = dataInicio.slice(0, -3);
    } else if (!taxaMensal && isDataMesAno) {
        data = dataInicio + '-01';
    }

    return data;
}

export function isTaxaMensal(taxa) {
    return taxa && taxa.tipoPagamento && taxa.tipoPagamento.key === 3
        && ((taxa.tipoTaxa >= 1 && taxa.tipoTaxa <= 5) || taxa.tipoTaxa === 7 || taxa.tipoTaxa === 8);
}

export function getIndice(idIndice) {
    return dispatch => {
        return axios
            .get(INDICE_API + idIndice)
            .then(result => dispatch([serializeFieldsIndiceForm(result.content)]));
    };
}

export function calculoVariacaoPercentualDoIndice(tipoTaxa, dataInicio, numeroIndice) {
    dataInicio = dataInicio ? dataInicio : null; 
    numeroIndice = numeroIndice ? numeroIndice : null;

    return (dispatch) => {
        const taxaMensal = isTaxaMensal(tipoTaxa);
        let dataReferencia = transformDataInicio(dataInicio, taxaMensal);

        if (taxaMensal) {
            let valorDataFinal = null;

            if (dataReferencia && tipoTaxa && tipoTaxa.id) {
                valorDataFinal = getDataFinalPorTipoTaxa(dataReferencia, tipoTaxa);
            }

            if (tipoTaxa && dataReferencia && numeroIndice) {
                const params = deserializeFieldsCalculoVariacaoPercentualIndice(tipoTaxa, `${dataReferencia}-01`, numeroIndice);

                return axios
                    .post(`${INDICE_API}variacaopercentual`, { ...params })
                    .then(result => dispatch(
                        [
                            change('indiceForm', 'valorTaxa', result.content),
                            change('indiceForm', 'dataFim', valorDataFinal),
                            change('indiceForm', 'dataInicio', dataReferencia),
                        ]
                    ));
            } else if (valorDataFinal !== null) {
                return dispatch([
                    change('indiceForm', 'dataFim', valorDataFinal),
                    change('indiceForm', 'dataInicio', dataReferencia),
                ]);
            }
        }

        if (tipoTaxa && tipoTaxa.tipoTaxa === 9) {
            if (tipoTaxa && dataReferencia && numeroIndice) {
                const params = deserializeFieldsCalculoVariacaoPercentualIndice(tipoTaxa, dataReferencia, numeroIndice);

                return axios
                    .post(`${INDICE_API}variacaopercentual`, { ...params })
                    .then(result => dispatch(
                        [
                            change('indiceForm', 'valorTaxa', result.content),
                            change('indiceForm', 'dataInicio', dataReferencia),
                        ]
                    ));
            } else {
                return dispatch([
                    change('indiceForm', 'dataInicio', dataReferencia),
                ]);
            }
        }

        return dispatch([
            change('indiceForm', 'numeroIndice', numeroIndice),
            change('indiceForm', 'dataInicio', dataReferencia),
        ]);
    };
}

export function getDataFinalPorTipoTaxa(dataInicio, taxa) {
    let dataFinal = dataInicio;
    if (taxa.tipoPagamento.key === 3 && taxa.tipoTaxa >= 1 && taxa.tipoTaxa <= 3) {
        let dataFimMaisUmMes = moment(dataInicio, "YYYY-MM-DD").toDate()
        dataFimMaisUmMes.setMonth(dataFimMaisUmMes.getMonth() + 1);

        dataFinal = moment(dataFimMaisUmMes).format('YYYY-MM-DD');
    }

    return dataFinal;
}

export function saveIndice(indice) {
    const taxaMensal = isTaxaMensal(indice.taxa) || false;
    const params = deserializeFieldsIndiceForm(indice, taxaMensal);

    return (dispatch) => {
        return axios
            .post(INDICE_API, { ...params })
            .then(result =>
                dispatch([
                    showIndiceList(),
                    showAllMessages(result.messages),
                ])
            );
    }
}

export function editIndice(indice) {
    const taxaMensal = isTaxaMensal(indice.taxa) || false;
    const params = deserializeFieldsIndiceForm(indice, taxaMensal);

    return (dispatch) => {
        return axios
            .put(INDICE_API, { ...params })
            .then(result =>
                dispatch([
                    showIndiceList(),
                    showAllMessages(result.messages),
                ])
            );
    }
}

export function removeIndice(idIndice) {
    return (dispatch) => {
        return axios
            .delete(INDICE_API + idIndice)
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    showIndiceDeleteDialog(false, 0),
                    showIndiceList(),
                ])
            );
    }
}

export function loadFilterFormIndice() {
    return dispatch => {
        return axios.get(INDICE_API + 'tela')
            .then(result =>
                dispatch({
                    type: LOAD_FILTER_FORM_INDICE,
                    payload: result.content
                })
            );
    };
}

export function loadFormIndice(idIndice, editAction) {
    return dispatch => {
        return axios.get(INDICE_API + `tela/${idIndice || ''}`)
            .then(result => {
                let routeAction = editAction ?  EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH ;

                dispatch([
                    idIndice ? history.push(`/${PRECIFICACAO_ROUTE_PATH}/${INDICE_ROUTE_PATH}/${routeAction}/${idIndice}`) : undefined,
                    {
                        type: LOAD_FORM_INDICE,
                        payload: result.content
                    },
                    serializeFieldsIndiceForm(result.content.taxaPeriodo)
                ])
             });
    };
}

export function exportExcel(filters, taxaMensal) {
    let params = deserializeFieldsIndiceFormFilterExportExcel(filters, taxaMensal);
    const fileName = filters.tipoTaxa && filters.tipoTaxa.descricao ?
        'Indices_' + filters.tipoTaxa.descricao + ".xlsx" : null;

    if (isTaxaDiFuturo(filters.tipoTaxa)) {
        return exportDiFuturoToExcel(params, fileName);
    } else {
        return exportIndicesToExcel(params, fileName);
    }

}

export function exportIndicesToExcel(params, fileName) {
    return (dispatch) => {
        return axios
            .post(`${INDICE_API}exportar/excel`, { ...params })
            .then(result => {
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function exportDiFuturoToExcel(params, fileName) {
    return (dispatch) => {
        return axios
            .post(`${INDICE_API}exportarDiFuturo/excel`, { ...params })
            .then(result => {
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function showIndiceList(filters = {}) {
    return dispatch => {
        return dispatch([
            cleanResult(),
            showConfirmDialog(false),
            history.push(`/${PRECIFICACAO_ROUTE_PATH}/${INDICE_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)
        ]);
    };
}

export function cleanResult() {
    return { type: CLEAN_LIST_CONTENT };
}

export function showIndiceForm() {
    return dispatch => {
        return dispatch([
            loadFormIndice(),
            history.push(`/${PRECIFICACAO_ROUTE_PATH}/${INDICE_ROUTE_PATH}/${NOVO_ROUTE_PATH}`)
        ]);
    };
}

export function showIndiceDeleteDialog(showDialog, idIndice) {
    return (dispatch) => {
        return dispatch([
            {
                type: INDICE_SHOW_DELETE_DIALOG,
                payload: { showDialog, idIndice }
            },
            history.push(`/${PRECIFICACAO_ROUTE_PATH}/${INDICE_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)
        ])
    }
}