import React from 'react';
import Card from '@material-ui/core/Card';
import ButtonBase from '@material-ui/core/ButtonBase';
import './assets/CardAttachment.css';
import If from "../../common/If/If"

export default class CardAttachment extends React.Component {
    get iconClassExtension() {
        let extension = this.props.label ? this.props.label.split('.')[1] : '';
        extension = extension || '';
        
        let iconClass = "file-o";

        if (['jpeg', 'jpg', 'png'].some(e => e === extension.toLowerCase()))
            iconClass = "file-image-o";
        else if ("pdf" === extension.toLowerCase())
            iconClass = "file-pdf-o";
        else if (['xls', 'xlsx'].some(e => e === extension.toLowerCase()))
            iconClass = "file-excel-o";


        return `fa fa-${iconClass}`;
    }

    render() {
        return (
            <Card className={`card-attachment ${this.props.optionalClass || ''}`} >
                <div className="row mx-0 d-flex align-items-center card-attachment-container flex-nowrap">
                    <div className="card-attachment-ic">
                        <i className={this.iconClassExtension} aria-hidden="true" />
                    </div>
                    <div className="col text-truncate">
                        {this.props.label}
                    </div>
                    <If test={this.props.isDownloaded}>
                        <div className="col-md-3">
                            <ButtonBase onClick={() => this.props.onDownload()} >
                                <div className="download-button">
                                    <i className="fa fa-cloud-download p-color-imp f-22" aria-hidden="true" />
                                </div>
                            </ButtonBase>
                        </div>
                    </If>
                    <If test={this.props.isRemoveable}>
                        <div className="mt-0">
                            <ButtonBase onClick={() => this.props.onClick()}>
                                <div className="delete-button">
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </div>
                            </ButtonBase>
                        </div>
                    </If>
                </div>
            </Card>
        );
    }
}
