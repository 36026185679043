import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector, Field } from "redux-form";

import { showConfirmDialog } from "../../../../../actions/common/modal/ConfirmDialogAction";
import {
  showUsuarioList,
  onChangeConta,
  changeConta,
  onChangeUsuario,
} from "../../../../../actions/usuario/UsuarioAction";

import If from "../../../../common/If/If";
import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import FloatButtonGroup from "../../../../common/button/FloatButtonGroup";
import FloatButton from "../../../../common/button/FloatButton";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";
import DropdownListInput from "../../../../common/input/DropdownListInput";
import CheckboxInput from "../../../../common/input/CheckboxInput";

let UsuarioPerfilForm = (props) => {
  return (
    <Form>
      <FormHeader title={props.title} col={"col-lg"} offSet={20}></FormHeader>
      <FormContent handleSubmit={props.handleSubmit}>
        <div className="shadow-custom rounded-12 bg-white px-3 py-2 column">
          <p className="page-head__title f-22 mb-2 mt-3 px-2">Controle</p>
          <div className="row px-2">
            <div className="col-lg-6">
              <Field
                component={DropdownListInput}
                inputId="usuario"
                name="usuario"
                label="Usuário"
                options={props.usuarioList}
                textField="nome"
                valueField="id"
                filter="contains"
                readOnly={props.userReadOnly}
                onChange={(value) => (
                  props.onChangeUsuario(value, props.perfilList),
                  props.changeConta(value, props.userLogged)
                )}
              />
            </div>
          </div>
        </div>

        <If test={props.userLogged.length > 0}>
          <div className="shadow-custom rounded-12 bg-white px-3 py-2 column mt-4">
            <p className="page-head__title f-22 mb-2 mt-3 px-2">Ambientes</p>
            <div className="row px-2">
              {props.userLogged.map((conta, index) => {
                return (
                  <div className="col-lg-4" key={index}>
                    <Field
                      index={index}
                      component={CheckboxInput}
                      inputId={`userLogged[${index}].checked`}
                      name={`userLogged[${index}].checked`}
                      disabled={props.readOnly}
                      label={conta.descricao}
                      onChange={(e) =>
                        props.onChangeConta(
                          e.target.checked,
                          conta.id
                        )
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </If>

        <If test={props.areaAtuacaoList.length > 0}>
          <div className="shadow-custom rounded-12 bg-white px-3 py-2 column mt-4">
            <p className="page-head__title f-22 mb-2 mt-3 px-2">Área de Negócio</p>
            <div className="row px-2">
              {props.areaAtuacaoList.map((area, index) => {
                return (
                  <div className="col-lg-4" key={index}>
                    <Field
                      index={index}
                      component={CheckboxInput}
                      inputId={`areaAtuacaoList[${index}].checked`}
                      name={`areaAtuacaoList[${index}].checked`}
                      disabled={props.readOnly}
                      label={area.descricao}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </If>

        <If test={props.perfilList.length > 0}>
          <div className="shadow-custom rounded-12 bg-white px-3 py-2 column my-4">
            <p className="page-head__title f-22 mb-2 mt-3 px-2">Permissões</p>
            <div className="row px-2">
              {props.perfilList.map((perfil, index) => {
                return (
                  <div className="col-lg-4" key={index}>
                    <Field
                      index={index}
                      component={CheckboxInput}
                      inputId={`perfilList[${index}].checked`}
                      name={`perfilList[${index}].checked`}
                      disabled={props.readOnly}
                      label={perfil.descricao}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </If>
        <FloatButtonGroup>
          <FloatButton
            activeClassName={"nd1 nds"}
            title={"Cancelar"}
            icon={"fa fa-ban edit edit-sub-menu"}
            action={() =>
              props.floatButtonVisible
                ? props.showConfirmDialog(true)
                : props.showUsuarioList()
            }
          />
          <If test={props.floatButtonVisible}>
            <FloatButton
              activeClassName={"nd3 nds"}
              title={"Salvar"}
              icon={"material-icons edit edit-sub-menu"}
              cloudDone={true}
              action={props.handleSubmit}
            />
          </If>
        </FloatButtonGroup>
      </FormContent>
      <ConfirmDialog
        title="Aviso"
        contentText="Os dados do formulário serão perdidos, deseja realmente sair ?"
        textButtonCancel="CANCELAR"
        textButtonConfirm="CONFIRMAR"
        showDialog={props.showCancelConfirmDialog}
        onClose={() => props.showConfirmDialog(false)}
        onConfirm={() => props.showUsuarioList()}
        type="warning"
        maxWidth="md"
      />
    </Form>
  );
};

UsuarioPerfilForm = reduxForm({ form: "usuarioPerfilForm" })(UsuarioPerfilForm);
const selector = formValueSelector("usuarioPerfilForm");

const mapStateToProps = (state) => ({
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
  usuarioList: state.usuarioReducer.usuarioFormTypes.usuarioList || [],
  perfilList: state.usuarioReducer.usuarioFormTypes.perfilList || [],
  areaAtuacaoList: state.usuarioReducer.usuarioFormTypes.areaAtuacaoList || [],
  userLogged: state.usuarioReducer.userLogged.contas || [],
  usuario: selector(state, "usuario"),
  perfil: selector(state, "perfil"),
  idUsuarioPerfil: selector(state, "id"),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showUsuarioList,
      showConfirmDialog,
      onChangeConta,
      changeConta,
      onChangeUsuario,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioPerfilForm);
