import { initialize } from "redux-form";
import {
  getBooleanValueFromString,
  getStringBooleanValue,
} from "./../../../../utils/helpers/BooleanHelper";

import { convertCurrencyToNumber } from "./../../../../utils/helpers/NumberHelper";
import { getCurrentDateTimeStringNormal } from "../../../../utils/helpers/DateTimeHelper";
import { extractFromObj } from "../../../../utils/helpers/ObjectHelper";
import { customFormatPhone, customNumber } from "../../../../utils/helpers/StringHelper";
import { addZerosAEsquerda } from "../../../../utils/helpers/OthersHelpers";

export function serializeFieldsDespesaForm(despesa, idOperacao = 0, content) {

  const { operacoes } = content;

  let despesaOperacao = {};

  if (despesa) {
    if (despesa?.contatos?.length > 0) {
      despesa.contatos = despesa?.contatos.map((ct) => (
        {...ct, telefone: customFormatPhone(customNumber(ct?.telefone))}
      ))
    }

    const idDespesaTipo = despesa?.idDespesaTipo ? extractFromObj(despesa?.idDespesaTipo) : null;
    const idEmpresaPrestadora = despesa?.idEmpresaPrestadora ? extractFromObj(despesa?.idEmpresaPrestadora, "idEmpresaPrestadora") : null;

    despesaOperacao = {
      ...despesa,
      idDespesaTipo,
      idEmpresaPrestadora,
      possuiPagamentoFlat: getStringBooleanValue(despesa.possuiPagamentoFlat),
      possuiPagamentoRecorrente: getStringBooleanValue(despesa.possuiPagamentoRecorrente),
      despesaPagamentoFlat: {
        ...despesa?.despesaPagamentoFlat,
        despesaReembolsavel: getStringBooleanValue(despesa?.despesaPagamentoFlat?.despesaReembolsavel),
        dataVencimento: getCurrentDateTimeStringNormal(despesa?.despesaPagamentoFlat?.dataVencimento),
      },
      despesaPagamentoRecorrente: {
        ...despesa?.despesaPagamentoRecorrente,
        possuiCorrecao: getStringBooleanValue(despesa?.despesaPagamentoRecorrente?.possuiCorrecao),
        despesaReembolsavel: getStringBooleanValue(despesa?.despesaPagamentoRecorrente?.despesaReembolsavel),
        dataPrimeiraParcela: getCurrentDateTimeStringNormal(despesa?.despesaPagamentoRecorrente?.dataPrimeiraParcela),
        dataVencimento: getCurrentDateTimeStringNormal(despesa?.despesaPagamentoRecorrente?.dataVencimento),
      },
    };

    despesaOperacao = {
      ...despesaOperacao,
      serie: operacoes?.find((op) => op.id == despesa?.idOperacao)
    }

    if (despesa?.aprovacao) {
      despesaOperacao = {
        ...despesaOperacao,
        aprovacao: {
          ...despesaOperacao?.aprovacao,
          dataSolicitacao: getCurrentDateTimeStringNormal(despesa?.aprovacao?.dataSolicitacao),
        }
      }
    }
  }

  return initialize("DespesaForm", despesaOperacao);
}

export function deserializeFieldsDespesaForm(despesa) {
  
  const faturamentos = despesa?.faturamentos?.map(item => ({ id: extractFromObj(item?.id, "idEmpresaPrestadora") }));

  return {
    ...despesa,
    possuiPagamentoFlat: getBooleanValueFromString(despesa?.possuiPagamentoFlat),
    possuiPagamentoRecorrente: getBooleanValueFromString(despesa?.possuiPagamentoRecorrente),
    serie: extractFromObj(despesa?.serie) || null,
    idOperacao: despesa?.idOperacao || (extractFromObj(despesa?.serie) || null),
    idPatrimonioSeparado: extractFromObj(despesa?.idPatrimonioSeparado, "key"),
    idDespesaTipo: extractFromObj(despesa?.idDespesaTipo),
    idEmpresaPrestadora: extractFromObj(despesa?.idEmpresaPrestadora, "idEmpresaPrestadora"),
    idDocumentoSuporte: extractFromObj(despesa?.idDocumentoSuporte),
    despesaPagamentoFlat: {
      ...despesa.despesaPagamentoFlat,
      despesaReembolsavel: getBooleanValueFromString(despesa?.despesaPagamentoFlat?.despesaReembolsavel),
      idReembolsante: extractFromObj(despesa?.despesaPagamentoFlat?.idReembolsante, "idEmpresaPrestadora"),
      idOrigemRecurso: extractFromObj(despesa?.despesaPagamentoFlat?.idOrigemRecurso),
      idContaOrigem: extractFromObj(despesa?.despesaPagamentoFlat?.idContaOrigem),
      idContaDestino: extractFromObj(despesa?.despesaPagamentoFlat?.idContaDestino),
      valorBruto: convertCurrencyToNumber(despesa?.despesaPagamentoFlat?.valorBruto),
    },
    despesaPagamentoRecorrente: {
      ...despesa.despesaPagamentoRecorrente,
      possuiCorrecao: getBooleanValueFromString(despesa?.despesaPagamentoRecorrente?.possuiCorrecao),
      despesaReembolsavel: getBooleanValueFromString(despesa?.despesaPagamentoRecorrente?.despesaReembolsavel),
      diaPagamento: extractFromObj(despesa?.despesaPagamentoRecorrente?.diaPagamento, "textField"),
      mesesDefasagem: extractFromObj(despesa?.despesaPagamentoRecorrente?.mesesDefasagem, "textField"),
      mesCorrecao: extractFromObj(despesa?.despesaPagamentoRecorrente?.mesCorrecao, "key"),
      idReembolsante: extractFromObj(despesa?.despesaPagamentoRecorrente?.idReembolsante, "idEmpresaPrestadora"),
      idOrigemRecurso: extractFromObj(despesa?.despesaPagamentoRecorrente?.idOrigemRecurso),
      idContaOrigem: extractFromObj(despesa?.despesaPagamentoRecorrente?.idContaOrigem),
      idContaDestino: extractFromObj(despesa?.despesaPagamentoRecorrente?.idContaDestino),
      valorBrutoInicial: convertCurrencyToNumber(despesa?.despesaPagamentoRecorrente?.valorBrutoInicial),
    },
    // 
    faturamentos,
  };
}

// recebimento
export function normalizeContaNome(conta) {
  if (conta) {
    return {
      ...conta,
      nomeExibicao: `${conta?.banco?.codigo || ''}_${addZerosAEsquerda(conta?.agencia || '', 5)}${conta?.agenciaDigito ? `-${conta?.agenciaDigito}` : ''}/${addZerosAEsquerda(conta?.contaCorrente || '', 10)}${conta?.digito ? `-${conta.digito}` : ''}`
    };
  }

  return null; 
}

export function deserializeLancamentos({ estatisticas, lancamentos }) {
  let lanc = lancamentos;

  if (lancamentos?.hasItems) {
    const items = lancamentos?.items.map((item) => {
      return {
        ...item,
        contaOrigem: normalizeContaNome(item?.contaOrigem),
        contaDestino: normalizeContaNome(item?.contaDestino),
      }
    })

    lanc = {
      ...lancamentos,
      items,
    }
  }

  return {
    estatisticas,
    lancamentos: lanc,
  };
}
