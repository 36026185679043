import { initialize } from "redux-form";
import {
  getBooleanValueFromString,
  getStringBooleanValue,
} from "../../../../utils/helpers/BooleanHelper";

import { convertCurrencyToNumber } from "../../../../utils/helpers/NumberHelper";
import { getCurrentDateTimeStringNormal } from "../../../../utils/helpers/DateTimeHelper";
import { extractFromObj } from "../../../../utils/helpers/ObjectHelper";
import { customFormatPhone, customNumber } from "../../../../utils/helpers/StringHelper";
import { addZerosAEsquerda } from "../../../../utils/helpers/OthersHelpers";

export function serializeFieldsReceitaForm(receita, idOperacao = 0) {
  let receitaOperacao = {};

  if (receita) {
    if (receita?.contatos?.length > 0) {
      receita.contatos = receita?.contatos.map((ct) => (
        {...ct, telefone: customFormatPhone(customNumber(ct?.telefone))}
      ))
    }

    const idTipoReceita = receita?.idTipoReceita ? extractFromObj(receita?.idTipoReceita) : null;
    receitaOperacao = {
      ...receita,
      idTipoReceita,
      dataUltimaParcela: getCurrentDateTimeStringNormal(receita?.dataUltimaParcela),
      possuiPagamentoFlat: getStringBooleanValue(receita.possuiPagamentoFlat),
      possuiPagamentoRecorrente: getStringBooleanValue(receita.possuiPagamentoRecorrente),
      receitaContratoFlat: {
        ...receita?.receitaContratoFlat,
        dataPagamento: getCurrentDateTimeStringNormal(receita?.receitaContratoFlat?.dataPagamento),
        pagamentoProporcional: getStringBooleanValue(receita?.receitaContratoFlat?.pagamentoProporcional),
      },
      receitaContratoRecorrente: {
        ...receita?.receitaContratoRecorrente,
        mesesCorrecao: receita?.receitaContratoRecorrente?.mesesCorrecao.map(({mes}) => (mes)),
        mesesPagamento: receita?.receitaContratoRecorrente?.mesesPagamento.map(({mes}) => (mes)),
        possuiCorrecao: getStringBooleanValue(receita?.receitaContratoRecorrente?.possuiCorrecao),
        pagamentoProporcional: getStringBooleanValue(receita?.receitaContratoRecorrente?.pagamentoProporcional),
        somenteVariacaoPositiva: getStringBooleanValue(receita?.receitaContratoRecorrente?.somenteVariacaoPositiva),
        dataInicioCorrecao: getCurrentDateTimeStringNormal(receita?.receitaContratoRecorrente?.dataInicioCorrecao),
        dataVencimento: getCurrentDateTimeStringNormal(receita?.receitaContratoRecorrente?.dataVencimento),
        dataInicio: getCurrentDateTimeStringNormal(receita?.receitaContratoRecorrente?.dataInicio),
      },
    };

    if (receita?.aprovacao) {
      receitaOperacao = {
        ...receitaOperacao,
        aprovacao: {
          ...receitaOperacao?.aprovacao,
          dataSolicitacao: getCurrentDateTimeStringNormal(receita?.aprovacao?.dataSolicitacao),
        }
      }
    }
  }

  return initialize("ReceitaForm", receitaOperacao);
}

export function deserializeFieldsReceitaForm(receita) {
  let receitaData = receita;

  const { possuiPagamentoFlat: possuiFlat, possuiPagamentoRecorrente: possuiRec } = receitaData

  const possuiPagamentoFlat = possuiFlat ? getBooleanValueFromString(possuiFlat) : null;
  const possuiPagamentoRecorrente = possuiRec ? getBooleanValueFromString(possuiRec) : null;

  receitaData = {
    ...receitaData,
    idEmpresaFaturamento: extractFromObj(receita?.idEmpresaFaturamento),
    idProdutoReceita: extractFromObj(receita?.idProdutoReceita),
    possuiPagamentoFlat,
    possuiPagamentoRecorrente,
  };

  console.log(receitaData?.receitaContratoFlat?.divisoes);

  if (possuiPagamentoFlat) {
    receitaData = {
      ...receitaData,
      receitaContratoFlat: {
        ...receitaData?.receitaContratoFlat,
        idOrigemRecurso: extractFromObj(receitaData?.receitaContratoFlat?.idOrigemRecurso),
        idContaOrigem: extractFromObj(receitaData?.receitaContratoFlat?.idContaOrigem),
        pagamentoProporcional: getBooleanValueFromString(receitaData?.receitaContratoFlat?.pagamentoProporcional),
        valorLiquido: convertCurrencyToNumber(receitaData?.receitaContratoFlat?.valorLiquido),
        valorBruto: convertCurrencyToNumber(receitaData?.receitaContratoFlat?.valorBruto),
        divisoes: receitaData?.receitaContratoFlat?.divisoes?.map(({ proporcao, idPessoa, ...rest }) => ({ ...rest, proporcao, idPessoa: idPessoa?.id ?? idPessoa })),
      },
    }
  }

  if (possuiPagamentoRecorrente) {
    const possuiCorrecao = getBooleanValueFromString(receitaData?.receitaContratoRecorrente?.possuiCorrecao)

    receitaData = {
      ...receitaData,
      receitaContratoRecorrente: {
        ...receitaData?.receitaContratoRecorrente,
        mesesPagamento: receitaData?.receitaContratoRecorrente?.mesesPagamento.map((mes) => ({ mes: { ...mes } })),
        idContaOrigem: extractFromObj(receitaData?.receitaContratoRecorrente?.idContaOrigem),
        idPeriodicidadePagamento: extractFromObj(receitaData?.receitaContratoRecorrente?.idPeriodicidadePagamento),
        pagamentoProporcional: getBooleanValueFromString(receitaData?.receitaContratoRecorrente?.pagamentoProporcional),
        possuiCorrecao,
        diaFaturamento: extractFromObj(receitaData?.receitaContratoRecorrente?.diaFaturamento, "textField"),
        idOrigemRecurso: extractFromObj(receitaData?.receitaContratoRecorrente?.idOrigemRecurso),
        valorLiquido: convertCurrencyToNumber(receitaData?.receitaContratoRecorrente?.valorLiquido),
        valorBruto: convertCurrencyToNumber(receitaData?.receitaContratoRecorrente?.valorBruto),
        prazoPagamento: extractFromObj(receitaData?.receitaContratoRecorrente?.prazoPagamento, "textField"),
        divisoes: receitaData?.receitaContratoRecorrente?.divisoes?.map(({ proporcao, idPessoa , ...rest }) => ({ ...rest, proporcao, idPessoa: idPessoa?.id ?? idPessoa })),
      },
    }
    
    if (possuiCorrecao) {
      receitaData = {
        ...receitaData,
        receitaContratoRecorrente: {
          ...receitaData?.receitaContratoRecorrente,
          idTaxa: extractFromObj(receitaData?.receitaContratoRecorrente?.idTaxa),
          mesesDefasagem: extractFromObj(receitaData?.receitaContratoRecorrente?.mesesDefasagem, "textField"),
          idPeriodicidadeCorrecao: extractFromObj(receitaData?.receitaContratoRecorrente?.idPeriodicidadeCorrecao),
          mesesCorrecao: receitaData?.receitaContratoRecorrente?.mesesCorrecao.map((mes) => ({ mes: { ...mes } })),
          somenteVariacaoPositiva: getBooleanValueFromString(receitaData?.receitaContratoRecorrente?.somenteVariacaoPositiva),
        }
      }
    }
  }

  return receitaData;
}