/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";

import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";
import {
  formatStatusPDDInverted,
  formatStatusPDD,
} from "../../../../../utils/helpers/OthersHelpers";

import {
  getContasReceber,
  exportContasReceberToExcel,
} from "../../../../../actions/contasReceber/ContasReceberAction";
import ReactTooltip from "react-tooltip";
import {
  convertCurrencyNumber,
  formatDecimal,
} from "../../../../../utils/helpers/NumberHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  FLUXO_ROUTE_PATH,
  CONTAS_RECEBER_ROUTE_PATH,
} from "../../../../common/routers/constantesRoutes";

const ContasReceberList = (props) => {
  const {
    getContasReceber,
    contasReceberData,
    contasReceberConsult,
    indicadores,
    messages,
    exportContasReceberToExcel,
  } = props;

  const user = JSON.parse(localStorage.getItem("userLogged"));
  const areas = user.areasAtuacoes;
  const isTeasury = areas.some(({ id }) => id === 9);

  const tableHeader = (
    <tr>
      <th className="text-center"></th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          ID
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          DATA CRIAÇÃO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          CRIADO POR
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          PATRIMÔNIO SEPARADO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          FATURADO CONTRA
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          TIPO DE RECEITA
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          MODALIDADE
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          TIPO DE FATURAMENTO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          DATA PAGAMENTO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          VALOR BRUTO
        </div>
      </th>
      <th className="text-center"></th>
    </tr>
  );

  const emptyData = messages?.infos[0];

  function indicadorBase(ind, mb = true) {
    return (
      <div
        key={`indicador-${ind?.status?.value}`}
        className={`w-100 shadow-custom rounded-8 bg-white mx-lg-1 mb-lg-0 p-2 overflow-hidden border-bottom-card-cr-base ${
          ind?.status?.raw
        } mb-${mb ? "3" : "0"}`}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="fw-500 mt-4">{formatDecimal(ind.quantidade, 0)}</p>
          <div
            data-type="opea"
            data-for={`ttp-${ind?.status?.value}`}
            className="text-nowrap overflow-hidden text-overflow-ellipsis"
            data-tip={`R$ ${convertCurrencyNumber(ind.valor, false, 2)}`}
          >
            <p className="fw-400 text-nowrap overflow-hidden text-overflow-ellipsis text-center">
              R$ {convertCurrencyNumber(ind.valor, false, 2)}
            </p>
            <ReactTooltip
              effect="solid"
              className="px-2 py-1"
              globalEventOff="click"
              id={`ttp-${ind?.status?.value}`}
              style={{ width: "100px" }}
            />
          </div>
          <span className="fw-500 text-nowrap">{ind?.status?.value}</span>
        </div>
      </div>
    );
  }

  function indicadorOuter(ind, mb = true) {
    return (
      <div
        key={`indicador-${ind?.status?.value}`}
        className={`d-flex flex-column justify-content-between w-100 h-100 shadow-custom rounded-8 bg-white border-left-card-cr-base ${
          ind?.status?.raw
        } px-3 py-2 mb-${mb ? "3" : "0"}`}
      >
        <div className="d-flex flex-row align-items-lg-center justify-content-between">
          <span className="fw-500 mr-2 text-capitalize">
            {(ind?.status?.value) || "N/A"}
          </span>
        </div>
        <div className="d-flex flex-xl-row flex-column align-items-xl-end justify-content-xl-between justify-content-start mb-1 mt-2">
          <span className="f-22 lh-22 fw-400 text-nowrap mb-xl-0 mb-2 mr-2">
            {formatDecimal(ind.quantidade, 0)}
          </span>
          <div
            data-type="opea"
            data-for={`ttp-${ind?.status?.value}`}
            className="text-nowrap overflow-hidden text-overflow-ellipsis text-xl-right text-left"
            data-tip={`R$ ${convertCurrencyNumber(ind.valor, false, 2)}`}
          >
            <span className="f-12 lh-12 fw-400 text-nowrap overflow-hidden w-auto text-overflow-ellipsis text-xl-right text-left">
              R$ {convertCurrencyNumber(ind.valor, false, 2)}
            </span>
            <ReactTooltip
              effect="solid"
              className="px-2 py-1"
              globalEventOff="click"
              id={`ttp-${ind?.status?.value}`}
              style={{ width: "100px" }}
            />
          </div>
        </div>
      </div>
    );
  }

  const tableContent =
    contasReceberData &&
    contasReceberData.hasItems &&
    contasReceberData.items.map((item, index) => (
      <tr key={index} className={`tr-status`}>
        <td className="text-center td-status">
          <div className="d-flex justify-content-start align-items-center pl-2">
            <div
              data-type="light"
              data-for={`ttp-list-${index}`}
              data-tip={item.status.value}
              className={`circle-status sts-cr-${item.status.key} rounded-circle mr-2`}
            />
            <ReactTooltip
              noArrow
              effect="solid"
              id={`ttp-list-${index}`}
              className="px-2 py-1"
              globalEventOff="click"
              style={{ width: "100px" }}
            />
          </div>
        </td>
        <td className="text-center fw-400">{item?.identificador || "--"}</td>
        <td className="text-center fw-400">
          {item?.dataCriacao
            ? getDateFormatedFromString(item.dataCriacao)
            : "--"}
        </td>
        <td className="text-center fw-400">{item?.criadoPor || "--"}</td>
        <td className="text-center fw-400">
          {item?.patrimonioSeparado || "--"}
        </td>
        <td className="text-center fw-400">{item?.faturadoContra || "--"}</td>
        <td className="text-center fw-400">{item?.tipoDeReceita || "--"}</td>
        <td className="text-center fw-400">
          {item?.modalidade?.value || "--"}
        </td>
        <td className="text-center fw-400">
          {item?.tipoPagamento?.value || "--"}
        </td>
        <td className="text-center fw-400">
          {item?.dataPagamento
            ? getDateFormatedFromString(item.dataPagamento)
            : "--"}
        </td>
        <td className="text-center fw-400">
          {item?.valorBruto
            ? convertCurrencyNumber(item?.valorBruto, "R$ ", 2)
            : "--"}
        </td>
        <td className="text-center">
          <Link
            to={`/${CONTAS_RECEBER_ROUTE_PATH}/${FLUXO_ROUTE_PATH}/${item.id}`}
            className="p-color-imp f-20 mr-3 ml-2 px-1"
          >
            <FontAwesomeIcon color="#630a37" icon={faChevronRight} />
          </Link>
        </td>
      </tr>
    ));

  const statusBase = [
    "Cadastro Pendente",
    "Cadastrado",
    "Aprovado",
    "Exportado",
  ];
  const statusOuter = ["A revisar", "Cancelado"];

  return (
    <div className="container-fluid">
      <div className="p-3">
        {indicadores && (
          <div className="row cards-pgmt mt-3">
            <div className="col-12">
              <div className="w-100 position-relative border-content-pgmt rounded-8 p-3 d-flex flex-column flex-lg-row">
                <span className="position-absolute fw-400 title-pgmt px-2">
                  Status dos Faturamentos
                </span>
                {statusBase.map((status) =>
                  indicadores
                    .filter((ind) => ind?.status?.value === status)
                    .map((ind) => indicadorBase(ind))
                )}
                <div className="d-flex flex-column ml-0 ml-lg-2 px-0 col-lg-2 col-12">
                  {statusOuter.map((status) =>
                    indicadores
                      .filter((ind) => ind?.status?.value === status)
                      .map((ind) => indicadorOuter(ind, status === "A revisar"))
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {contasReceberData?.totalCount > 0 && (
          <Table
            withoutHeader
            files={[
              {
                label: "Exportar para Excel",
                function: () =>
                  exportContasReceberToExcel(contasReceberConsult),
              },
            ]}
            classes="shadow-custom rounded-12 col-12 mt-4 mb-3 pd-10 bg-white"
            totalItems={contasReceberData?.totalCount}
            pagination={
              <PaginationRB
                pageNumber={contasReceberData.pageIndex - 1}
                itemsPerPage={contasReceberData.pageSize}
                totalItems={contasReceberData?.totalCount}
                filter={contasReceberConsult}
                fetchFunction={getContasReceber}
                pageRange={5}
              />
            }
          >
            <TableHeader>{tableHeader}</TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        )}

        {contasReceberData.totalCount <= 0 && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

formValueSelector("ContasReceberForm");

const mapStateToProps = (state) => ({
  contasReceberData: state.contasReceberReducer.contasReceber,
  contasReceberConsult: state.contasReceberReducer.contasReceberConsultCurrent,
  indicadores: state.contasReceberReducer.indicadores || null,
  messages: state.contasReceberReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getContasReceber,
      exportContasReceberToExcel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(ContasReceberList);
