import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { }
    from '../../../../../../actions/gestao/DiarioBordoAction';
import ItemsAnualDiarioBordoList from './ItemsAnualDiarioBordoList';

class EventoDiarioBordoListView extends Component {
    render() {
        return (
            <div className="w-100">

                <div className='w-100'>

                    {this.props.diariosEncontrados.map((item, index) =>
                        (
                            <div className="col-lg-12" key={index}>{
                                <ItemsAnualDiarioBordoList
                                    diario={item}
                                />
                            }</div>
                        )
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({})
const mapDispacthToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(EventoDiarioBordoListView));
