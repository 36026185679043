import axios from "axios";

import { showAllMessages } from "../message/ErrorMessagesAPIAction";

import { downloadExcelFile } from "../../utils/helpers/FileHelper";
import { deserializeFieldsHistoricoPrecificacaoFormFilter } from "../../components/business/historicocarteira/mappings/HistoricoMap";
import { getCurrentDateTimeString } from "../../utils/helpers/DateTimeHelper";

export const HISTORICOS_FETCHED = "HISTORICOS_FETCHED";
export const HISTORICOS_CONSULT_CURRENT = "HISTORICOS_CONSULT_CURRENT";
export const RESET = "RESET";
export const FUNDOS_HISTORICO_OPTIONS_FETCHED =
  "FUNDOS_HISTORICO_OPTIONS_FETCHED";

const HISTORICO_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_FUNDO_CARTEIRA;

const FUNDO_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_FUNDO;

export function exportarHistoricoCarteira(filters, page = 1) {
  const params = deserializeFieldsHistoricoPrecificacaoFormFilter(filters, page)

  return (dispatch) => {
    return axios
      .post(`${HISTORICO_API}exportar-dados-carteira`, params)
      .then((result) => {
        const { nomeFundo } = params;
        
        dispatch([
          downloadExcelFile(result.content, `Histórico Carteira - ${nomeFundo} - ${getCurrentDateTimeString()}.xlsx`),
          showAllMessages(result.messages),
        ]);
      });
  };
}

export function getHistoricos(filters, page = 1) {
  const params = deserializeFieldsHistoricoPrecificacaoFormFilter(filters, page)

  return (dispatch) => {
    return axios
      .post(`${HISTORICO_API}historico-carteira`, params)
      .then((result) => {
        const { content, messages } = result
        dispatch([
          {
            type: HISTORICOS_FETCHED,
            payload: { content, messages },
          },
          {
            type: HISTORICOS_CONSULT_CURRENT,
            payload: params,
          },
        ])
      });
  };
}

export function getSugestaoFundoHistorico(term) {
  return (dispatch) => {
    return axios.get(`${FUNDO_API}sugestoes?termo=${term}`).then((result) =>
      dispatch({
        type: FUNDOS_HISTORICO_OPTIONS_FETCHED,
        payload: result.content,
      })
    );
  };
}

export function importaDadosHistorico(file, filters, provedor = 1) {
  let formData = new FormData();
  formData.append("file", file);

  return (dispatch) => {
    return axios
      .post(`${HISTORICO_API}importar-planilha?provedor=${provedor}`, formData)
      .then((result) =>
        dispatch([
          filters ? getHistoricos(filters) : null,
          showAllMessages(result.messages),
        ])
      );
  };
}

export function resetPagina() {
  return (dispatch) => {
    return dispatch([
      { type: RESET },
    ]);
  };
}