import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import PaginationRB from "../../../../common/pagination/PaginationRB";

import { formatCPF } from "../../../../../utils/helpers/StringHelper";
import {
  getUsuariosPortal,
  acoes,
} from "../../../../../actions/acessoPortal/AcessoPortalAction";
import PopoverButonGroup from "../../../../common/button/PopoverButonGroup";
import {
  ACESSO_PORTAL,
  READ,
  UPDATE,
} from "../../../../../utils/actionsAndFeaturesAuth";
import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import If from "../../../../common/If/If";

const AcessoPortalList = (props) => {
  const { email, cpf, portalUsuarioStatusValidacao, nome } = props;

  const options = [
    {
      title: "Detalhar",
      hasAuthentication: true,
      action: READ,
      feature: ACESSO_PORTAL,
    },
    {
      title: "Gerenciar",
      hasAuthentication: true,
      action: UPDATE,
      feature: ACESSO_PORTAL,
    },
  ];

  const tableContent =
    props.usuarios && props.usuarios.hasItems ? (
      props.usuarios.items.map((item, index) => {
        let checkedIconColorDocumentoValido = "#e1dfdc";

        if (item.possuiDocumentoValido) {
          checkedIconColorDocumentoValido = "#17D498";
        }

        return (
          <tr key={index}>
            <td className="text-center fw-400 text-nowrap">
              {`${item.nome} ${item.sobreNome || ""}`}
            </td>
            <td className="text-center fw-400 text-nowrap">
              {item.cpf ? formatCPF(item.cpf) : ""}
            </td>
            <td className="text-center fw-400 text-nowrap">{item.email}</td>
            <td className="text-center fw-400 text-nowrap">
              <CheckCircleIcon
                style={{ color: checkedIconColorDocumentoValido }}
              />
            </td>
            <td className="text-center fw-400">
              <PopoverButonGroup
                classNameDiv="col pt-1"
                idItem={item.id}
                acoes={props.acoes}
                options={options}
              />
            </td>
          </tr>
        );
      })
    ) : (
      <div className="w-100 my-2 d-flex justify-content-center flex-row">
        Nada encontrado
      </div>
    );

  return (
    <div className="w-100 px-4 mt-0">
      <If test={props.usuarios.totalCount > 0}>
        <MuiThemeProvider>
          <Table
            withoutHeader
            classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
            totalItems={props.usuarios.totalCount}
            pagination={
              <PaginationRB
                pageNumber={props.usuarios.pageIndex - 1}
                itemsPerPage={props.usuarios.pageSize}
                totalItems={props.usuarios.totalCount}
                filter={{ email, cpf, portalUsuarioStatusValidacao, nome }}
                fetchFunction={props.getUsuariosPortal}
                pageRange={5}
              />
            }
          >
            <TableHeader>
              <tr>
                <th className="text-center fw-700 text-nowrap">Nome</th>
                <th className="text-center fw-700 text-nowrap">Cpf</th>
                <th className="text-center fw-700 text-nowrap">E-mail</th>
                <th className="text-center fw-700 text-nowrap">
                  Possui documento válido
                </th>
                <th></th>
              </tr>
            </TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        </MuiThemeProvider>
      </If>
    </div>
  );
};

const selector = formValueSelector("acessoPortalFilterForm");
const mapStateToProps = (state) => ({
  usuarios: state.acessoPortalReducer.usuarios || [],
  messages: state.acessoPortalReducer.messages,
  email: selector(state, "email"),
  cpf: selector(state, "cpf"),
  portalUsuarioStatusValidacao: selector(state, "portalUsuarioStatusValidacao"),
  nome: selector(state, "nome"),
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsuariosPortal,
      acoes,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(AcessoPortalList);
