import {
    LOAD_FILTER_FORM_USUARIO_PERFIL,
    USUARIOS_FETCHED,
    CLEAN_LIST_CONTENT,
    USUARIO_SHOW_DELETE_DIALOG,
    LOAD_FORM_USUARIO,
    LOGGED_ACCOUNTS,
} from "../../actions/usuario/UsuarioAction";

const INITIAL_STATE = {
    usuarios: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    usuarioFormTypes: {},
    formUsuario: {},
    userLogged: {},
    messages: null,
    showUsuarioDeleteDialog :{
        showDialog: false,
        idUsuario: 0
    }
}

const CLEAN_CONTENT = {
    usuarios: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    }
}

export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case LOAD_FILTER_FORM_USUARIO_PERFIL:
            return {
                ...state,
                usuarioFormTypes: action.payload
            }
        case LOAD_FORM_USUARIO:
            return {
                ...state,
                formUsuario: action.payload
            }
        case LOGGED_ACCOUNTS:
            return {
                ...state,
                userLogged: action.payload
            }
        case USUARIOS_FETCHED:
            return {
                ...state,
                usuarios: action.payload.result.content,
                messages: action.payload.result.messages,
            }
        case CLEAN_LIST_CONTENT:
            return {
                ...state,
                ...CLEAN_CONTENT,
            }
        case USUARIO_SHOW_DELETE_DIALOG:
            return {
                ...state,
                showUsuarioDeleteDialog: action.payload
            }
        default:
            return state;
    }
}