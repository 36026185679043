/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import {
  saveDespesa,
  loadFormDespesa,
  showDespesaList,
} from "../../../../../actions/operacao/DespesaAction";

import { OPERACAO_TABS } from "./../../../operacao/pages/OperacaoTabs";
import DespesaForm from "./../../../operacao/pages/despesa/form/DespesaForm";
import ContainerPage from "../../../../common/container/ContainerPage";

const DespesaIncludePage = (props) => {
  const { saveDespesa, operacoes, loadFormDespesa, showDespesaList } = props;
  const idsOperacoes = operacoes.map((o) => o.key);

  React.useEffect(() => {
    const query = new URLSearchParams(props.location.search).get("q");
    query
      ? loadFormDespesa(null, operacoes[0]?.key || null, undefined, query)
      : showDespesaList(idsOperacoes);
  }, []);

  return (
    <ContainerPage tabs={OPERACAO_TABS} paramsRoute={idsOperacoes}>
      <DespesaForm
        title={"Nova - Despesa"}
        onSubmit={saveDespesa}
        readOnly={false}
        floatButtonVisible={true}
      />
    </ContainerPage>
  );
};

const mapStateToProps = (state) => ({
  tab: state.tab,
  operacoes: state.operacaoReducer.selectedOperacoes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDespesa,
      loadFormDespesa,
      showDespesaList,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(DespesaIncludePage)
);
