import {
    LOAD_FILTER_FORM_REMESSA,
    SALVA_FILTROS_REMESSA,
    REMESSA_FETCHED,
    REMESSA_CONSULT_CURRENT,
    REMESSA_RESET,
    LOAD_FORM_REMESSA,
} from "../../actions/remessa/RemessaAction";

const INITIAL_STATE = {
    remessa: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    remessaConsultCurrent: null,
    messages: null, 
    isLoading: false,
    remessaFormTypesFilter: {},
    remessaForm: null,
}

export default function (state = INITIAL_STATE, action = {}) {    
    switch(action.type) {
        case REMESSA_FETCHED:
            return {
                ...state,
                remessa: action.payload.content,
                messages: action.payload.messages,
                isLoading: true,                
            }
        case REMESSA_CONSULT_CURRENT:
            return {
                ...state,
                remessaConsultCurrent: action.payload,      
                isLoading: true,     
            }
        case LOAD_FILTER_FORM_REMESSA:
            return {
                ...state,
                remessaFormTypesFilter: action.payload,
            }
        case LOAD_FORM_REMESSA:
            return {
                ...state,
                remessaForm: action.payload,
            }
        case REMESSA_RESET:
            return {
                ...INITIAL_STATE
            }
        case SALVA_FILTROS_REMESSA:
            return {
                ...state,
                filtrosAprovacao: action.payload
            }
        default:
            return state;
    }
}
