import {
    DIARIOS_BORDO_FETCHED,
    DIARIO_BORDO_LOAD_FILTER_FORM,
    DIARIO_BORDO_CHANGED_TIPO_EVENTO,
    SHOW_MODAL_GENERICO,
    DIARIO_BORDO_DELETE_DIALOG
} from '../../actions/gestao/DiarioBordoAction';
import { mappingDiarioBordoByYear, mappingTipoEspecieAssembleiaComunicadoList } from '../../components/business/gestao/mappings/DiarioBordoMap';
import { DIARIO_BORDO_OPERACAO_OPTIONS_FETCHED, RESET_DIARIO_BORDO_FILTERS } from './../../actions/gestao/DiarioBordoAction';

const INITIAL_STATE = {
    diariosBordo: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    operacaoFormDiarioBordo:[],
    diariosBordoPorAno: [],
    diarioBoardFormTypes: {},
    diarioBoardContratosTypes: [],
    diarioBoardEspecieAssembleiaTypes: [],
    diarioBoardEspecieComunicadoTypes: {},
    showModalDialogGenerico: {
        showDialogCadastro: false,
        showDialogUploadImg: false,
        showDialogId: 0,
        justView: false
    },
    showEventoDiarioBordoDeleteDialog: {
        showDialog: false,
        idEventoDiarioBordo: 0
    },
    messages: null,
};

export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case DIARIOS_BORDO_FETCHED:
            return {
                ...state,
                diariosBordo: action.payload.result.content,
                diariosBordoPorAno: mappingDiarioBordoByYear(action.payload.result.content.items),
                messages: action.payload.result.messages,
            };
        case DIARIO_BORDO_LOAD_FILTER_FORM:
            const TIPO_EVENTO_ESPECIE_ASSEMBLEIA = 1;
            const TIPO_EVENTO_ESPECIE_COMUNICADO = 3;

            const tipoEventoDiarioBordoList = action.payload.tipoEventoDiarioBordoList.map(tipoEvento =>({ ...tipoEvento, checked: false }));
            const tipoEspecie               = action.payload.tipoEspecieAssembleiaComunicadoList.map(tipoEvento =>({ ...tipoEvento, checked: false }));

            const tipoEspecieAssembleiaList = mappingTipoEspecieAssembleiaComunicadoList(tipoEspecie, TIPO_EVENTO_ESPECIE_ASSEMBLEIA);
            const tipoEspecieComunicadoList = mappingTipoEspecieAssembleiaComunicadoList(tipoEspecie, TIPO_EVENTO_ESPECIE_COMUNICADO);
            const tipoContratoAditamentoList = action.payload.tipoContratoAditamentoList.map(tipoEvento =>({ ...tipoEvento, checked: false }));

            return {
                ...state,
                diarioBoardFormTypes: { tipoEventoDiarioBordoList },
                diarioBoardEspecieAssembleiaTypes: { tipoEspecieAssembleiaList },
                diarioBoardEspecieComunicadoTypes: { tipoEspecieComunicadoList },
                diarioBoardContratosTypes: { tipoContratoAditamentoList }
            }
        case DIARIO_BORDO_CHANGED_TIPO_EVENTO:
            const tipoEventoList = state.diarioBoardFormTypes.tipoEventoDiarioBordoList.map(tipoEvento => {
                if (tipoEvento.id === action.payload) {
                    tipoEvento.checked = !tipoEvento.checked;
                }
                return tipoEvento;
            });

            return {
                ...state,
                diarioBoardFormTypes: { tipoEventoDiarioBordoList: tipoEventoList }
            }
        case SHOW_MODAL_GENERICO:
            return {
                ...state,
                showModalDialogGenerico: action.payload
            };
        case DIARIO_BORDO_DELETE_DIALOG:
            return {
                ...state,
                showEventoDiarioBordoDeleteDialog: action.payload
            }
        case DIARIO_BORDO_OPERACAO_OPTIONS_FETCHED:
            return{
                ...state,
                operacaoFormDiarioBordo: action.payload
            }
        case RESET_DIARIO_BORDO_FILTERS:
                if(state.diarioBoardFormTypes){
                    state.diarioBoardFormTypes.tipoEventoDiarioBordoList.map((check) => 
                    (check.checked = false));
                }
                return {
                    ...state,
                        diariosBordoPorAno: []

                };        
        default:
            return state;
    }
}
