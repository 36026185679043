import React, {Component} from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiButton from "@material-ui/core/Button";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import WarningIcon from '@material-ui/icons/Warning';

import MuiTypography from '@material-ui/core/Typography';
import If from "../If/If";


const Dialog = withStyles(theme => ({
  paper: {
    backgroundColor: "transparent",
    borderRadius: "0"
  },
}))(MuiDialog);

const Typography = withStyles(theme => ({
  root: {
    fontWeight: "400",
    textTransform: "none",
    marginRight: "3rem"
  },
}))(MuiTypography);

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey[50],
    borderTopLeftRadius: "0",
    borderTopRightRadius: "0"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[50],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography color="inherit" variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
    minHeight: "150px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    flexDirection: "column",
    justifyContent: "center"
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
    backgroundColor: "white"
  },
}))(MuiDialogActions);

const DialogContentText = withStyles(theme => ({
  root: {
    fontSize: "14px"
  },
}))(MuiDialogContentText);

const Button = withStyles(theme => ({
  root: {
    borderRadius: "0",
    color: theme.palette.primary.main,
    fontWeight: "400"
  },
}))(MuiButton);

class ConfirmDialog extends Component {  

  renderSwitch(param, style) {
    switch(param) {
      case 'warning':
        return <WarningIcon className={`${style} alert-icon`} />;
      case 'success':
        return <CheckCircleIcon className={`${style} alert-icon`} />;
      case 'danger':
        return <BlockIcon className={`${style} alert-icon`} />;
      case 'info':
        return <AnnouncementIcon className={`${style} alert-icon`} />;
      default:
        return null;
    }
  }

  render() { 
    const {
      showDialog,
      scroll,
      title,
      contentText,
      textButtonCancel,
      textButtonConfirm,
      textButtonTesteCustom,
      onClose,
      onConfirm,
      onCustomAction,
      type,
      maxWidth
    } = this.props;

    return(
      <div>
        <Dialog
          maxWidth={maxWidth}
          onClose={onClose}
          open={showDialog}
          scroll={scroll ? scroll : "paper"}
          aria-labelledby="scroll-dialog-title"          
        >
          <DialogTitle id="scroll-dialog-title" onClose={onClose}>
              {title}
          </DialogTitle>

          <DialogContent>           
            <div>{this.renderSwitch(type, `text-${type}`)}</div>
            <DialogContentText>
                {contentText}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose} id={"btnCancel"}>
              {textButtonCancel}
            </Button>
            <Button onClick={onConfirm} id={"btnConfirm"}>
              {textButtonConfirm}
            </Button>

            <If test={onCustomAction && textButtonTesteCustom}>
              <Button onClick={onCustomAction} id={"btnCustom"}>
                {textButtonTesteCustom}
              </Button>
            </If>

          </DialogActions>
        </Dialog>
      </div>
    );
  }
}    

ConfirmDialog.propTypes = {
  title:PropTypes.string.isRequired,
  contentText:PropTypes.string.isRequired,
  textButtonCancel:PropTypes.string.isRequired,
  textButtonConfirm:PropTypes.string.isRequired,
  showDialog:PropTypes.bool.isRequired,
  onClose:PropTypes.func.isRequired,
  onConfirm:PropTypes.func.isRequired,
};

export default ConfirmDialog;
