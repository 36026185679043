/* eslint eqeqeq: 0 */

import { initialize } from "redux-form";
import { extractFromObj } from "../../../../utils/helpers/ObjectHelper";

export function deserializeFieldsExportacaoFormFilter(filters, page) {
    return {
        ...filters,
        tipoItem: filters?.tipoItem?.key ? filters?.tipoItem : null,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        }
    };
}

export function serializeFieldsExportacaoForm(exportacao) {
    let exportacaoData = {};
    
    if (exportacao) {
        exportacaoData = {
            ...exportacao,
            idExportacao: exportacao?.identificador,
            idTipoItem: exportacao?.tipoItem?.key,
            status: exportacao?.status?.value,
        }
    }

    return initialize("ExportacaoForm", exportacaoData);
}

export function deserializeFieldsExportacaoForm(exportacao) {
    let exportacaoData = {};

    if (exportacao) {
        exportacaoData = {
            ...exportacao,
            ativo: exportacao?.ativo || false,
            idTipoAprovacao: extractFromObj(exportacao?.idTipoAprovacao),
            idUsuario: extractFromObj(exportacao?.idUsuario),
        }
    }

    return exportacaoData;
}