import {
    CARTEIRAS_FETCHED,
    CARTEIRAS_CONSULT_CURRENT,
    LOAD_FILTER_FORM_CARTEIRA,
    LOAD_TELA_FLUXOS_CARTEIRA,
    RESET,
    CARTEIRA_SHOW_DELETE_DIALOG,
    FUNDOS_CARTEIRA_OPTIONS_FETCHED,
    INDICADORES_CARTEIRA_FETCHED
} from "../../actions/carteira/CarteiraAction";

const INITIAL_STATE = {
    carteiras: null,
    carteirasConsultCurrent: null,
    indicadores: null,
    messages: null, 
    showCarteiraDeleteDialog: {
        showDialog: false,
        paramsReiniciarCarteira: {},
    },
    isLoading: false,
    carteiraFormTypes: {},
    carteiraFormTypesFilter: null,
    options: null,
}

export default function (state = INITIAL_STATE, action = {}) {    
    switch(action.type) {
        case CARTEIRAS_FETCHED:
            return {
                ...state,
                carteiras: action.payload?.content,
                messages: action.payload?.messages,
                isLoading: true,                
            }
        case INDICADORES_CARTEIRA_FETCHED:
            return {
                ...state,
                indicadores: action.payload,           
            }
        case FUNDOS_CARTEIRA_OPTIONS_FETCHED:
            return {
              ...state,
              options: action.payload,
            };
        case CARTEIRAS_CONSULT_CURRENT:
            return {
                ...state,
                carteirasConsultCurrent: action.payload,      
                isLoading: true,     
            }
        case LOAD_FILTER_FORM_CARTEIRA:
            return {
                ...state,
                carteiraFormTypesFilter: action.payload,
            }
        case CARTEIRA_SHOW_DELETE_DIALOG:
            return {
                ...state,
                showCarteiraDeleteDialog: action.payload
            }
        case LOAD_TELA_FLUXOS_CARTEIRA:
            return {
                ...state,
                carteiraFormTypes: action.payload,
            }
        case RESET:
            return {
                ...INITIAL_STATE
            }
        default:
            return state;
    }
}
