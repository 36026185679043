import {
  LOAD_FILTER_FORM_ERRO,
  LOAD_FORM_ERRO,
  CLEAN_LIST_CONTENT,
  SEARCH_ERRO,
  LOAD_FORM_FILTER_LOGS,
} from "../../actions/precificacao/ErroAction";

const INITIAL_STATE = {
  erroFormTypes: null,
  opcaoFilterLogs: null,
  search: {
    codigo: "",
    dataInicio: "",
    dataFinal: "",
  },
  erroForm: {
    newValues: "",
    oldValues: "",
    dateTime: "",
    action: "",
    tableName: "",
    id: "",
  },
};

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOAD_FORM_ERRO:
      return {
        ...state,
        erroForm: action.payload,
      };
    case LOAD_FILTER_FORM_ERRO:
      return {
        ...state,
        erroFormTypes: action.payload,
      };
    case LOAD_FORM_FILTER_LOGS:
      return {
        ...state,
        opcaoFilterLogs: action.payload,
      };
    case SEARCH_ERRO:
      return {
        ...state,
        search: action.payload,
      };
    case CLEAN_LIST_CONTENT:
      return {
        ...state,
        erroForm: {
          newValues: "",
          oldValues: "",
          dateTime: "",
          action: "",
          tableName: "",
          id: "",
        },
        search: {
          codigo: "",
          dataInicio: "",
          dataFinal: "",
        },
        erroFormTypes: [],
      };
    default:
      return state;
  }
}
