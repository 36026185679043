/* eslint eqeqeq: 0 */

import { formatCNPJToString, validarCpfCnpj } from "../../../../../utils/helpers/StringHelper";

export const validate = (values) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  let errors = {
    documento: !values.documento ? 'Preenchimento obrigatório' : !validarCpfCnpj(formatCNPJToString(values.documento)) ? 'Documento inválido' : null,
    nome: !values.nome ? "Preenchimento obrigatório" : null,
    tipos: !values.tipos?.length ? 'Preenchimento obrigatório' : null,
    tiposActive: values.tiposActive?.length
      ? values.tiposActive.map((tipo) => ({
          emailsList: !tipo || !tipo.emailsList?.length
            ? "Preenchimento obrigatório"
            : tipo.emailsList.map((email, idx, arr) => ({
                email: !email.email
                  ? "Email obrigatório"
                  : !emailPattern.test(email.email)
                  ? "Email inválido"
                  : arr.filter(e => e.email === email.email).length > 1
                  ? "Email repetido na mesma lista"
                  : null,
              })).filter(Boolean),
        })).filter(Boolean)
      : null,
  };

  return errors;
};
