import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import { formValueSelector } from "redux-form";
import Input from "../../../../common/input/Input";
import { editSuspenderAssembleia } from "../../../../../actions/assembleia/AssembleiaAction";

let ModalSuspenderAssembleia = (props) => {
  const {
    close,
    open,
    idAssembleia,
    patrimonioSeparado,
    editSuspenderAssembleia,
  } = props;

  const [dataAssembleia, setDataAssembleia] = React.useState("");
  const [horarioAssembleia, setHorarioAssembleia] = React.useState("");

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Suspender Assembleia
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-4">
            <p className="text-dark-hard fw-400">
              Você está prestes a{" "}
              <strong className="text-danger fw-500">
                suspender a assembleia
              </strong>{" "}
              da operação {patrimonioSeparado?.value}.
            </p>
            <p className="my-2 text-dark-hard fw-400">
              Com isso, a assembleia voltará o status de Assembleia Convocada e
              a data e horário da assembleia serão alterados conforme abaixo:
            </p>

            <div className="row">
              <div className="col-lg col-12">
                <Input
                  label="Data da Assembleia"
                  type="date"
                  input={{
                    value: dataAssembleia,
                    onChange: (e) => setDataAssembleia(e.target.value),
                  }}
                />
              </div>
              <div className="col-lg col-12">
                <Input
                  label="Horário da Assembleia"
                  type="time"
                  input={{
                    value: horarioAssembleia,
                    onChange: (e) => setHorarioAssembleia(e),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Não, Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Sim, Confirmar"}
              disabled={!dataAssembleia || !horarioAssembleia}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={async () => {
                const dataHoraISO = new Date(`${dataAssembleia}T${horarioAssembleia}:00.000Z`).toISOString();
                const payload = { id: idAssembleia, dataAssembleia: dataHoraISO };
                await editSuspenderAssembleia(payload);
                close();
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const selector = formValueSelector("AssembleiaForm");

const mapStateToProps = (state) => ({
  patrimonioSeparado: selector(state, "patrimonioSeparado") || false,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editSuspenderAssembleia,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSuspenderAssembleia);
