import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";

import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";

import {
  getHistoricos,
  importaDadosHistorico,
} from "../../../../../actions/historicocarteira/HistoricoAction";

import ReactTooltip from "react-tooltip";
import { convertCurrencyNumber } from "../../../../../utils/helpers/NumberHelper";
import { formatCNPJ } from "../../../../../utils/helpers/StringHelper";
import PaginationRB from "../../../../common/pagination/PaginationRB";

const HistoricoList = (props) => {
  const fileInputRef = React.useRef(null);

  const { historicoData, messages, importaDadosHistorico, historicoConsult, getHistoricos } = props;

  const tableHeader = (
    <tr>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          +
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Data
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Qtde Cotas
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Disponibilidades
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Renda Fixa
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Direitos Creditórios Estoque
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Hedge
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          PDD
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Contas
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Patrimônio Líquido
        </div>
      </th>
    </tr>
  );

  const emptyData = messages?.infos[0];

  function mudaVisibilidade(index) {
    document.getElementById(`rowTable-${index}`).classList.toggle("hide");
  }

  const acionaImport = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const enviaImport = (event) => {
    const file = event.target.files[0];
    importaDadosHistorico(file, historicoConsult);
  };

  const tableContent =
    historicoData?.carteiras &&
    historicoData?.carteiras?.hasItems &&
    historicoData?.carteiras?.items.map((item, index) => (
      <React.Fragment key={index}>
        <tr className="tr-status">
          <td
            role="button"
            className="text-center fw-400"
            onClick={() => mudaVisibilidade(index)}
          >
            ...
          </td>
          <td className="text-center td-status">
            <div className="d-flex flex-row justify-content-center align-items-center pr-2">
              <div
                data-type="light"
                data-for={`ttp-list-${index}`}
                data-tip={item.status.value}
                className={`circle-status sts-hist-${item.status.key} rounded-circle mr-2`}
              />
              <ReactTooltip
                noArrow
                effect="solid"
                id={`ttp-list-${index}`}
                className="px-2 py-1"
                globalEventOff="click"
                style={{ width: "100px" }}
              />
              <span className="fw-400 mt-1 mx-0 mb-0">
                {getDateFormatedFromString(item.dataBase) || "--"}
              </span>
            </div>
          </td>
          <td className="text-center fw-400">
            {convertCurrencyNumber(item.quantidadeCotas, "", 2) || "--"}
          </td>
          <td className="text-center fw-400">
            {convertCurrencyNumber(item.disponibilidadeTotal, "R$", 2) || "--"}
          </td>
          <td className="text-center fw-400">
            {convertCurrencyNumber(item.rendaFixaTotal, "R$", 2) || "--"}
          </td>
          <td className="text-center fw-400">
            {convertCurrencyNumber(item.direitosCreditoriosTotal, "R$", 2) ||
              "--"}
          </td>
          <td className="text-center fw-400">
            {convertCurrencyNumber(item.hedgeTotal, "R$", 2) || "--"}
          </td>
          <td className="text-center fw-400">
            {convertCurrencyNumber(item.pddTotal, "R$", 2) || "--"}
          </td>
          <td className="text-center fw-400">
            {convertCurrencyNumber(item.contasTotal, "R$", 2) || "--"}
          </td>
          <td className="text-center fw-400">
            {convertCurrencyNumber(item.patrimonioLiquido, "R$", 2) || "--"}
          </td>
        </tr>
        <tr id={`rowTable-${index}`} className="hide">
          <td></td>
          <td></td>
          <td></td>
          <td className="text-center">
            <div className="text-center d-flex flex-column justify-content-center w-100 mb-2">
              <p className="mb-0 fw-700">Caixa</p>
              <p className="mb-0">
                {convertCurrencyNumber(item.disponibilidadeCaixa, "R$", 2)}
              </p>
            </div>
            <div className="text-center d-flex flex-column justify-content-center w-100 mb-2">
              <p className="mb-0 fw-700">Compromissadas</p>
              <p className="mb-0">
                {convertCurrencyNumber(
                  item.disponibilidadeCompromissadas,
                  "R$",
                  2
                )}
              </p>
            </div>
          </td>
          <td></td>
          <td></td>
          <td className="text-center">
            <div className="text-center d-flex flex-column justify-content-center w-100 mb-2">
              <p className="mb-0 fw-700">Opções</p>
              <p className="mb-0">
                {convertCurrencyNumber(item.hedgeOpcoes, "R$", 2)}
              </p>
            </div>
            <div className="text-center d-flex flex-column justify-content-center w-100 mb-2">
              <p className="mb-0 fw-700">Swap</p>
              <p className="mb-0">
                {convertCurrencyNumber(item.hedgeSwap, "R$", 2)}
              </p>
            </div>
          </td>
          <td></td>
          <td className="text-center">
            <div className="text-center d-flex flex-column justify-content-center w-100 mb-2">
              <p className="mb-0 fw-700">À Pagar</p>
              <p className="mb-0">
                {convertCurrencyNumber(item.contasPagar, "R$", 2)}
              </p>
            </div>
            <div className="text-center d-flex flex-column justify-content-center w-100 mb-2">
              <p className="mb-0 fw-700">À Receber</p>
              <p className="mb-0">
                {convertCurrencyNumber(item.contasReceber, "R$", 2)}
              </p>
            </div>
            <div className="text-center d-flex flex-column justify-content-center w-100 mb-2">
              <p className="mb-0 fw-700">Valid</p>
              <p className="mb-0">
                {convertCurrencyNumber(item.contasValid, "R$", 2)}
              </p>
            </div>
          </td>
          <td></td>
        </tr>
      </React.Fragment>
    ));

  return (
    <div className="container-fluid">
      <div className="p-3">
        {historicoData && historicoData?.carteiras?.hasItems && (
          <>
            <div className="shadow-custom rounded-12 col-12 my-2 pt-4 pb-3 bg-white">
              <p className="p-color fw-700 f-24">Dados do Fundo</p>
              <div className="d-flex flex-xl-row flex-column col-12 px-0 fw-400">
                <span className="col-12 col-xl-3 pl-0">
                  <span className="fw-700 text-dark">CNPJ do Fundo</span>:{" "}
                  {formatCNPJ(historicoData.cnpj)}
                </span>
                <span className="col-12 col-xl-9 pl-0">
                  <span className="fw-700 text-dark">Administrador</span>:{" "}
                  {historicoData.administrador}
                </span>
              </div>
            </div>

            <Table
              withoutHeader
              classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
              totalItems={historicoData.carteiras.totalCount}
              pagination={
                <PaginationRB
                  pageNumber={historicoData.carteiras.pageIndex - 1}
                  itemsPerPage={historicoData.carteiras.pageSize}
                  totalItems={historicoData.carteiras.totalCount}
                  filter={historicoConsult}
                  fetchFunction={getHistoricos}
                  pageRange={5}
                />
              }
            >
              <TableHeader>{tableHeader}</TableHeader>
              <TableContent>{tableContent}</TableContent>
            </Table>
          </>
        )}
        <div className="col-12 my-3 d-flex flex-row px-0 h-38 justify-content-end align-items-end">
          <button
            onClick={acionaImport}
            className="btn-add scs-bg-imp rounded btn-search h-38 d-flex justify-content-center px-3"
          >
            Importar Dados
            <input
              type="file"
              className="d-none"
              ref={fileInputRef}
              accept=".xls, .xlsx"
              onChange={enviaImport}
            ></input>
          </button>
        </div>

        {!historicoData && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

formValueSelector("HistoricoForm");

const mapStateToProps = (state) => ({
  historicoData: state.historicoCarteiraReducer.historicos,
  historicoConsult: state.historicoCarteiraReducer.historicoConsult,
  messages: state.historicoCarteiraReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getHistoricos,
      importaDadosHistorico,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(HistoricoList);
