import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";
import Button from "../../../../../common/button/Button";
import { getDateInvertedFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import {
  LOG,
  READ,
  GENERATE,
} from "../../../../../../utils/actionsAndFeaturesAuth";
import { authorizationWithoutDispatch } from "../../../../../../actions/authorization/AuthorizationAction";

import {
  getLogs,
  exportLog,
} from "../../../../../../actions/precificacao/LogAction";
import If from "../../../../../common/If/If";

let LogListFilter = (props) => {
  const { getLogs, exportLog, inicio, final } = props;

  const [dataInicio, setDataInicio] = useState(inicio || "");
  const [dataFinal, setDataFinal] = useState(final || "");

  return (
    <div className="w-100">
      <Form>
        <FormHeader title={"Logs do Sistema"} />
        <FormContent classes="shadow-custom rounded-12 bg-white px-3 py-2">
          <div className="row px-0">
            <div className="col-lg-3 ">
              <div className="form-group field-floating">
                <input
                  type="date"
                  name="dataInicial"
                  id="txtfield_dataInicial"
                  defaultValue={dataInicio}
                  className="form-control bg-transparent"
                  onChange={(v) => setDataInicio(v.target.value)}
                />
                <label
                  className="control-label"
                  htmlFor="dataInicial"
                  id="lbl_dataInicial"
                >
                  Data Início
                </label>
                <span className="material-input" />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group field-floating">
                <input
                  type="date"
                  name="dataFinal"
                  id="txtfield_dataFinal"
                  defaultValue={dataFinal}
                  className="form-control bg-transparent"
                  onChange={(v) => setDataFinal(v.target.value)}
                />
                <label
                  className="control-label"
                  htmlFor="dataFinal"
                  id="lbl_dataFinal"
                >
                  Data Final
                </label>
                <span className="material-input" />
              </div>
            </div>
          </div>
          <div className="row px-0">
            <div className="col-lg-12 d-flex flex-row align-items-center">
              <Button
                icon={"search"}
                name={"Buscar"}
                classes="p-bg-imp rounded"
                action={() =>
                  authorizationWithoutDispatch(
                    { idAcao: READ, idFuncionalidade: LOG },
                    () => getLogs(dataInicio, dataFinal)
                  )
                }
              />
              <If test={dataInicio && dataFinal}>
                <label
                  role="button"
                  className="mb-0 p-color-lighten ml-3"
                  onClick={() =>
                    authorizationWithoutDispatch(
                      { idAcao: GENERATE, idFuncionalidade: LOG },
                      () =>
                        exportLog(
                          getDateInvertedFormatedFromString(dataInicio),
                          getDateInvertedFormatedFromString(dataFinal)
                        )
                    )
                  }
                >
                  Exportar para Excel
                </label>
              </If>
            </div>
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  inicio: state.logReducer.dataInicioLog,
  final: state.logReducer.dataFinalLog,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLogs,
      exportLog,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LogListFilter);
