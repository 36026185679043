import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field, formValueSelector } from "redux-form";

import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import Button from "../../../../../common/button/Button";

import {
  createAnaliticReport,
  expenseContractReport,
  revenueContractReport,
  paymentAnalitcRepot,
  selectOperacaoNoForm,
} from "../../../../../../actions/relatorios/analiticReportAction";
import Input from "../../../../../common/input/Input";
import If from "../../../../../common/If/If";
import { getOperacoes } from "../../../../../../actions/operacao/OperacaoAction";

const TIPO_EXPORTACAO_BASE = 1;
const TIPO_PASSIVO = 2;
const TIPO_CONTRATO_DESPESA = 3;
const TIPO_CONTRATO_RECEITA = 4;

let AnaliticReportListFilter = (props) => {
  const {
    tiposRelatoriosAnaliticos,
    typeReport,
    createAnaliticReport,
    paymentAnalitcRepot,
    expenseContractReport,
    revenueContractReport,
    dataInicioPagamento,
    dataFimPagamento,
  } = props;

  const tipoRelatorioPassivo = typeReport && typeReport.key === TIPO_PASSIVO;

  let callAnaliticReport = (params) => {
    if (typeReport && typeReport.key) {
      if (typeReport.key === TIPO_PASSIVO) {
        paymentAnalitcRepot({
          dataInicio: dataInicioPagamento,
          dataFim: dataFimPagamento,
        });
      }

      if (typeReport.key === TIPO_EXPORTACAO_BASE) createAnaliticReport(params);
      if (typeReport.key === TIPO_CONTRATO_DESPESA) expenseContractReport();
      if (typeReport.key === TIPO_CONTRATO_RECEITA) revenueContractReport();
    }
  };

  return (
    <div className="w-100 px-4">
      <Form>
        <FormHeader title="Relatório Analítico" col="col=md" />
        <FormContent classes="shadow-custom rounded-12 bg-white px-3 py-2">
          <div className="row">
            <div className="col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="typeReport"
                name="typeReport"
                label="Relatório"
                options={tiposRelatoriosAnaliticos}
                textField="value"
                valueField="key"
                filter="contains"
              />
            </div>
            <If test={tipoRelatorioPassivo}>
              <div className="col-lg-2">
                <Field
                  component={Input}
                  className={"filter-input"}
                  inputId="dataInicioPagamento"
                  name={`dataInicioPagamento`}
                  type={"date"}
                  label="Data Início"
                />
              </div>
              <div className="col-lg-2">
                <Field
                  component={Input}
                  className={"filter-input"}
                  inputId="dataFimPagamento"
                  name={`dataFimPagamento`}
                  type={"date"}
                  label="Data Fim"
                />
              </div>
            </If>
          </div>
          <div className="row px-0">
            <div className="col-lg-12">
              <Button
                action={() => callAnaliticReport({ typeReport })}
                classes="p-bg-imp rounded"
                icon={"file-text"}
                name={"Gerar"}
              />
            </div>
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

AnaliticReportListFilter = reduxForm({ form: "RelatorioAnaliticoForm" })(
  AnaliticReportListFilter
);
const selector = formValueSelector("RelatorioAnaliticoForm");

const mapStateToProps = (state) => ({
  tiposRelatoriosAnaliticos:
    state.analiticReportReducer.tiposFormTypes.relatorioAnaliticoList || [],
  typeReport: selector(state, "typeReport"),
  dataInicioPagamento: selector(state, "dataInicioPagamento"),
  dataFimPagamento: selector(state, "dataFimPagamento"),
  dataBase: selector(state, "dataBase"),
  operacao: selector(state, "operacao"),
  options: state.operacaoReducer.operacoes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAnaliticReport,
      paymentAnalitcRepot,
      expenseContractReport,
      revenueContractReport,
      getOperacoes,
      selectOperacaoNoForm,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnaliticReportListFilter);
