import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import Button from "../../../../common/button/Button";
import DropdownListInput from "../../../../common/input/DropdownListInput";

import {
  changeTipoFilter,
  changeValueForm,
  cleanResult,
  showOpcaoForm,
} from "../../../../../actions/precificacao/OpcaoAction";
import { CREATE, OPCAO } from "../../../../../utils/actionsAndFeaturesAuth";
import { authorizationWithoutDispatch } from "../../../../../actions/authorization/AuthorizationAction";
import { withRouter } from "react-router-dom";

let OpcaoListFilter = (props) => {
  const { changeTipoFilter, tipo, showOpcaoForm, changeValueForm, cleanResult, handleSubmit } = props;

  let tiposList = [
    { id: "moeda", descricao: "Moedas" },
    { id: "selo", descricao: "Selos" },
    { id: "tipo-aprovacao", descricao: "Tipo Aprovação" },
    { id: "produto", descricao: "Produto (Pedido de Compra)" },
    { id: "movimentacao-financeira", descricao: "Movimentação Financeira (Pedido de Compra)" },
    { id: "natureza", descricao: "Natureza" },
    { id: "banco-externo", descricao: "Bancos" },
    { id: "assembleia/assunto", descricao: "Assuntos (Assembleia)" },
    { id: "receita/produto", descricao: "Produto (Receita)" },
  ];

  tiposList.sort((a, b) => a.descricao.localeCompare(b.descricao));

  React.useEffect(() => {
    changeValueForm("tipo", tiposList.find((item) => item.id === tipo))
  }, [tipo])

  return (
    <div className="w-100 px-3">
      <Form>
        <FormHeader title={"Opções do Sistema"} />
        <FormContent
          handleSubmit={handleSubmit}
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
        >
          <div className="container-fluid">
            <div className="row">
              <Field
                component={DropdownListInput}
                inputId="tipo"
                outerClassName="col-lg-6 px-0"
                name="tipo"
                label="Tipo Campo"
                options={tiposList}
                textField="descricao"
                valueField="id"
                filter="contains"
                onChange={(v) => {
                  changeTipoFilter(v ? v.id : null)
                  cleanResult()
                }}
              />
            </div>

            <div className="row w-100">
              <div className="col-xl-2 col-12 px-0 pr-xl-3">
                <Button
                  type="submit"
                  name={"Buscar"}
                  classes="p-bg-imp rounded btn-search h-38 mt-1 w-100 d-flex justify-content-center align-items-center"
                  nameClass="text-capitalize f-14 lh-1 mt-1px"
                  activeClassName="w-100"
                />
              </div>

              {tipo && (
                <div className="col-xl-2 col-12 px-0">
                  <Button
                    name={"Criar Registro"}
                    icon="plus-circle"
                    classes="darkblue-bg-imp rounded btn-search h-38 mt-1 w-100 d-flex justify-content-center align-items-center"
                    nameClass="mt-2px text-capitalize f-14"
                    activeClassName="w-100"
                    action={() =>
                      authorizationWithoutDispatch(
                        { idAcao: CREATE, idFuncionalidade: OPCAO },
                        () => showOpcaoForm(tipo)
                      )
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

// VALIDAÇÃO
const validate = (values) => {
  const errors = {
    tipo: !values.tipo ? 'Preenchimento obrigatório' : null,
  };

  return errors;
};

const mapStateToProps = (state) => ({
  tipo: state.opcaoReducer.tipo,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeTipoFilter,
      showOpcaoForm,
      changeValueForm,
      cleanResult,
    },
    dispatch
  );

const ConnectedOpcaoListFilter = withRouter(connect(mapStateToProps, mapDispatchToProps)(OpcaoListFilter));

export default reduxForm({
  form: "OpcaoForm",
  validate,
})(ConnectedOpcaoListFilter);
