/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import {
  loadCarteiraForm,
  retornaValor,
  revisarDados,
  validarDados,
  exportarRelatorioAdministrador,
} from "../../../../../actions/carteira/CarteiraAction";
import Input from "../../../../common/input/Input";
import MaskedInput from "../../../../common/input/MaskedInput";
import Button from "../../../../common/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";

import { CheckCircle, Close } from "@material-ui/icons";
import { Dialog, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { convertCurrencyNumberNew } from "../../../../../utils/helpers/NumberHelper";
import Textarea from "../../../../common/input/Textarea";
import ReactTooltip from "react-tooltip";

let CarteiraDetail = (props) => {
  const {
    fundo,
    carteira,
    loadCarteiraForm,
    patrimonioLiquidoDiferenca,
    revisarDados,
    validarDados,
    retornaValor,
    exportarRelatorioAdministrador,

    formValues,
  } = props;

  const id = props.match.params.idCarteira;

  // prettier-ignore
  const [alt, setAlt] = React.useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
  const [checked, setChecked] = React.useState(false);
  const [indexSelecionado, setIndexSelecionado] = React.useState(null);

  let temDiferenca =
    typeof patrimonioLiquidoDiferenca == "number" &&
    patrimonioLiquidoDiferenca != 0;
  
  let patrLiqVariacao = carteira && carteira["patrimonioLiquidoVariacao"];
  
  const iconeWarn = (
    <>
      <div 
        data-for="msg-warn"
        className="col-xl-1 d-flex align-items-center mt-1"
        data-tip="Não é possível calcular a variação em casos que o D-1 é 0 e o valor atual é diferente de 0"
      >
        <FontAwesomeIcon color="#FFC107" icon={faExclamationTriangle} />
      </div>
      <ReactTooltip
        effect="solid"
        globalEventOff="click"
        className="tooltip-custom px-2 py-1"
        id="msg-warn"
      />
    </>
  )

  const emRevisao = alt.some((el) => el === true);
  const moedaSimbolo = "R$";
  const nomesCampo = [
    { label: "Caixa", field: "disponibilidadeCaixa" },
    { label: "Compromissadas", field: "disponibilidadeCompromissadas" },
    { label: "Total Disponibilidades", field: "disponibilidadeTotal" },
    { label: "Total Renda Fixa", field: "rendaFixaTotal" },
    { label: "Á Vencer", field: "direitosCreditoriosAVencer" }, // DESABILITADO
    { label: "Vencidos (+30 dias)", field: "direitosCreditoriosVencidos" }, // DESABILITADO
    { label: "Total Direitos Creditórios", field: "direitosCreditoriosTotal" },
    { label: "Opções", field: "hedgeOpcoes" },
    { label: "Swap", field: "hedgeSwap" },
    { label: "Total Hedge", field: "hedgeTotal" },
    { label: "Total PDD", field: "pddTotal" },
    { label: "Pagar", field: "contasPagar" },
    { label: "Receber", field: "contasReceber" },
    { label: "Total Contas", field: "contasTotal" },
  ];

  React.useEffect(() => {
    if (id) loadCarteiraForm(id);
  }, []);

  React.useEffect(() => {
    if (!indexSelecionado) setChecked(null);
  }, [indexSelecionado]);

  const mudaEstado = (i) => {
    const newAlt = [...alt];
    newAlt[i] = !newAlt[i];

    if (!newAlt[i]) retornaValor(nomesCampo[i].field);

    setAlt(newAlt);
  };

  function acaoBotao(index) {
    alt[index] ? mudaEstado(index) : setIndexSelecionado(index);
  }

  function classArrow(field) {
    return field === 0 ? "animhide" : field > 0 ? "animtop" : "animdown";
  }

  // prettier-ignore
  const variacaoElement = (index) => {
    let field = carteira[`${nomesCampo[index].field}Variacao`];

    let warn = field == null;

    let variacao = (
      <div className="d-flex flex-row align-items-center dt-cota ml-2 txt-grey">
        <FontAwesomeIcon
          icon={faArrowDown}
          className={`mr-1 mb-1px icon-arrow ${classArrow(field)}`}
        />
        <span className="ml-1">{convertCurrencyNumberNew(field, "", 2)}%</span>
      </div>
    )

    return warn ? iconeWarn : variacao; 
  }

  return (
    <div className="card bg-transparent border-0 px-4 shadow-none">
      {fundo && carteira && (
        <div className="card-body shadow-custom bg-white rounded-12 col-12 mb-3">
          <div className="d-flex flex-row justify-content-between align-items-start">
            <div className="d-flex flex-row align-items-center">
              <h4 className="page-head__title m-0 f-36 font-weight-bold mr-3">
                {fundo.nomeDoFundo}
              </h4>
              <span
                className={`rounded-pill text-uppercase border border-darken-1 px-2 py-0 workflow-tag sts-hist-${carteira.status.key}`}
              >
                {carteira.status.value}
              </span>
            </div>
          </div>

          <div className="row px-0 mt-2">
            <div className="col-xl-2">
              <Field
                component={Input}
                inputId="cnpjDoFundo"
                name={"cnpjDoFundo"}
                label="CNPJ do Fundo"
                readOnly={true}
              />
            </div>
            <div className="col-xl-3">
              <Field
                component={Input}
                inputId="administrador"
                name={"administrador"}
                label="Administrador"
                readOnly={true}
              />
            </div>
            <div className="col-xl-2">
              <Field
                component={Input}
                inputId="dataDeReferencia"
                name="dataDeReferencia"
                label="Data de Referência"
                readOnly={true}
              />
            </div>
            <div className="col-xl-2">
              <Field
                component={Input}
                inputId="dataDeConstituicao"
                name="dataDeConstituicao"
                label="Data de Constituição"
                readOnly={true}
              />
            </div>
            <div className="col-xl-2">
              <Field
                component={Input}
                inputId="dataDeVencimento"
                name="dataDeVencimento"
                label="Data de Vencimento"
                readOnly={true}
              />
            </div>
          </div>
        </div>
      )}
      {/*  */}

      <div className="card-body shadow-custom bg-white rounded-12 col-12">
        <p className="p-color f-22 font-weight-bold py-2">
          Validação das Informações da Carteira
        </p>

        {/* Patrimônio Líquido */}
        <div className="d-flex flex-column">
          <p className="p-color f-16 font-weight-bold mb-0">
            Patrimônio Líquido
          </p>
          <div className="row pl-xl-5 my-2">
            <div className="col-xl-4 d-flex align-items-center justify-content-between">
              <Field
                component={MaskedInput}
                inputId="patrimonioLiquido"
                name="patrimonioLiquido"
                label="Patrimônio Líquido Informado"
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked"
                outerClass="w-100"
              />

              {carteira && patrLiqVariacao == null && (iconeWarn)}
              {carteira && patrLiqVariacao != null && (
                <div>
                  <div className="d-flex flex-row align-items-center dt-cota ml-2 txt-grey mr-xl-0 mr-4 mt-2">
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      className={`mr-1 mb-1px icon-arrow ${classArrow(patrLiqVariacao)}`}
                    />
                    <span className="ml-1">
                      {convertCurrencyNumberNew(patrLiqVariacao, "", 2)}%
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                inputId="patrimonioLiquidoCalculado"
                name="patrimonioLiquidoCalculado"
                label="Patrimônio Líquido Calculado"
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked"
              />
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                inputId="patrimonioLiquidoDiferenca"
                name="patrimonioLiquidoDiferenca"
                label="Diferença Patrimônio Líquido"
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked"
              />
            </div>

            {temDiferenca && (
              <div className="col-xl-1 d-flex align-items-center mt-1">
                <FontAwesomeIcon color="#FFC107" icon={faExclamationTriangle} />
              </div>
            )}
          </div>
        </div>

        {/* Disponibilidades */}
        <div className="d-flex flex-column mb-3">
          <p className="p-color f-16 font-weight-bold mb-2">Disponibilidades</p>
          <div className="row pl-xl-5 d-flex align-items-center my-2">
            <div className="col-xl-4 d-flex flex-row align-items-center justify-content-xl-between">
              <span>{nomesCampo[0].label}</span>
              {carteira && variacaoElement(0)}
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                outerClass="my-0"
                inputId="disponibilidadeCaixaBlocked"
                name="disponibilidadeCaixaBlocked"
                label=""
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked mb-xl-0 mb-2"
              />
            </div>

            {alt[0] && (
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  outerClass="my-0"
                  inputId="disponibilidadeCaixa"
                  name="disponibilidadeCaixa"
                  label=""
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </div>
            )}

            <div className="col-auto">
              <span
                role="button"
                onClick={() => acaoBotao(0)}
                className="btn-link text-underline"
              >
                {alt[0] ? "Voltar" : "Alterar"}
              </span>
            </div>
          </div>
          <div className="row pl-xl-5 d-flex align-items-center my-2">
            <div className="col-xl-4 d-flex flex-row align-items-center justify-content-xl-between">
              <span>{nomesCampo[1].label}</span>
              {carteira && variacaoElement(1)}
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                outerClass="my-0"
                inputId="disponibilidadeCompromissadasBlocked"
                name="disponibilidadeCompromissadasBlocked"
                label=""
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked mb-xl-0 mb-2"
              />
            </div>

            {alt[1] && (
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  outerClass="my-0"
                  inputId="disponibilidadeCompromissadas"
                  name="disponibilidadeCompromissadas"
                  label=""
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </div>
            )}

            <div className="col-auto">
              <span
                role="button"
                onClick={() => acaoBotao(1)}
                className="btn-link text-underline"
              >
                {alt[1] ? "Voltar" : "Alterar"}
              </span>
            </div>
          </div>
          <div className="row pl-xl-5 d-flex align-items-center my-2">
            <div className="col-xl-4 d-flex flex-row align-items-center justify-content-xl-between">
              <span className="fw-500 txt-grey">{nomesCampo[2].label}</span>
              {carteira && variacaoElement(2)}
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                outerClass="my-0"
                inputId="disponibilidadeTotalBlocked"
                name="disponibilidadeTotalBlocked"
                label=""
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked mb-xl-0 mb-2"
              />
            </div>
          </div>
        </div>

        {/* Renda Fixa */}
        <div className="d-flex flex-column mb-3">
          <p className="p-color f-16 font-weight-bold mb-2">Renda Fixa</p>
          <div className="row pl-xl-5 d-flex align-items-center my-2">
            <div className="col-xl-4 d-flex flex-row align-items-center justify-content-xl-between">
              <span className="fw-500 txt-grey">{nomesCampo[3].label}</span>
              {carteira && variacaoElement(3)}
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                outerClass="my-0"
                inputId="rendaFixaTotalBlocked"
                name="rendaFixaTotalBlocked"
                label=""
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked mb-xl-0 mb-2"
              />
            </div>

            {alt[3] && (
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  outerClass="my-0"
                  inputId="rendaFixaTotal"
                  name="rendaFixaTotal"
                  label=""
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </div>
            )}

            <div className="col-auto">
              <span
                role="button"
                onClick={() => acaoBotao(3)}
                className="btn-link text-underline"
              >
                {alt[3] ? "Voltar" : "Alterar"}
              </span>
            </div>
          </div>
        </div>

        {/* Direitos Creditórios Estoque */}
        <div className="d-flex flex-column mb-3">
          <p className="p-color f-16 font-weight-bold mb-2">
            Direitos Creditórios Estoque
          </p>
          <div className="row pl-xl-5 d-flex align-items-center my-2">
            <div className="col-xl-4 d-flex flex-row align-items-center justify-content-xl-between">
              <span className="fw-500 txt-grey">{nomesCampo[6].label}</span>
              {carteira && variacaoElement(6)}
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                outerClass="my-0"
                inputId="direitosCreditoriosTotalBlocked"
                name="direitosCreditoriosTotalBlocked"
                label=""
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked mb-xl-0 mb-2"
              />
            </div>

            {alt[6] && (
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  outerClass="my-0"
                  inputId="direitosCreditoriosTotal"
                  name="direitosCreditoriosTotal"
                  label=""
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </div>
            )}

            <div className="col-auto">
              <span
                role="button"
                onClick={() => acaoBotao(6)}
                className="btn-link text-underline"
              >
                {alt[6] ? "Voltar" : "Alterar"}
              </span>
            </div>
          </div>
        </div>

        {/* Hedge */}
        <div className="d-flex flex-column mb-3">
          <p className="p-color f-16 font-weight-bold mb-2">Hedge</p>
          <div className="row pl-xl-5 d-flex align-items-center my-2">
            <div className="col-xl-4 d-flex flex-row align-items-center justify-content-xl-between">
              <span>{nomesCampo[7].label}</span>
              {carteira && variacaoElement(7)}
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                outerClass="my-0"
                inputId="hedgeOpcoesBlocked"
                name="hedgeOpcoesBlocked"
                label=""
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked mb-xl-0 mb-2"
              />
            </div>

            {alt[7] && (
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  outerClass="my-0"
                  inputId="hedgeOpcoes"
                  name="hedgeOpcoes"
                  label=""
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </div>
            )}

            <div className="col-auto">
              <span
                role="button"
                onClick={() => acaoBotao(7)}
                className="btn-link text-underline"
              >
                {alt[7] ? "Voltar" : "Alterar"}
              </span>
            </div>
          </div>
          <div className="row pl-xl-5 d-flex align-items-center my-2">
            <div className="col-xl-4 d-flex flex-row align-items-center justify-content-xl-between">
              <span>{nomesCampo[8].label}</span>
              {carteira && variacaoElement(8)}
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                outerClass="my-0"
                inputId="hedgeSwapBlocked"
                name="hedgeSwapBlocked"
                label=""
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked mb-xl-0 mb-2"
              />
            </div>

            {alt[8] && (
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  outerClass="my-0"
                  inputId="hedgeSwap"
                  name="hedgeSwap"
                  label=""
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </div>
            )}

            <div className="col-auto">
              <span
                role="button"
                onClick={() => acaoBotao(8)}
                className="btn-link text-underline"
              >
                {alt[8] ? "Voltar" : "Alterar"}
              </span>
            </div>
          </div>
          <div className="row pl-xl-5 d-flex align-items-center my-2">
            <div className="col-xl-4 d-flex flex-row align-items-center justify-content-xl-between">
              <span className="fw-500 txt-grey">{nomesCampo[9].label}</span>
              {carteira && variacaoElement(9)}
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                outerClass="my-0"
                inputId="hedgeTotalBlocked"
                name="hedgeTotalBlocked"
                label=""
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked mb-xl-0 mb-2"
              />
            </div>
          </div>
        </div>

        {/* Provisão para Devedores Duvidosos */}
        <div className="d-flex flex-column mb-3">
          <p className="p-color f-16 font-weight-bold mb-2">
            Provisão para Devedores Duvidosos
          </p>
          <div className="row pl-xl-5 d-flex align-items-center my-2">
            <div className="col-xl-4 d-flex flex-row align-items-center justify-content-xl-between">
              <span className="fw-500 txt-grey">{nomesCampo[10].label}</span>
              {carteira && variacaoElement(10)}
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                outerClass="my-0"
                inputId="pddTotalBlocked"
                name="pddTotalBlocked"
                label=""
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked mb-xl-0 mb-2"
              />
            </div>

            {alt[10] && (
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  outerClass="my-0"
                  inputId="pddTotal"
                  name="pddTotal"
                  label=""
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </div>
            )}

            <div className="col-auto">
              <span
                role="button"
                onClick={() => acaoBotao(10)}
                className="btn-link text-underline"
              >
                {alt[10] ? "Voltar" : "Alterar"}
              </span>
            </div>
          </div>
        </div>

        {/* Contas */}
        <div className="d-flex flex-column mb-3">
          <p className="p-color f-16 font-weight-bold mb-2">Contas</p>
          <div className="row pl-xl-5 d-flex align-items-center my-2">
            <div className="col-xl-4 d-flex flex-row align-items-center justify-content-xl-between">
              <span>{nomesCampo[11].label}</span>
              {carteira && variacaoElement(11)}
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                outerClass="my-0"
                inputId="contasPagarBlocked"
                name="contasPagarBlocked"
                label=""
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked mb-xl-0 mb-2"
              />
            </div>

            {alt[11] && (
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  outerClass="my-0"
                  inputId="contasPagar"
                  name="contasPagar"
                  label=""
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </div>
            )}

            <div className="col-auto">
              <span
                role="button"
                onClick={() => acaoBotao(11)}
                className="btn-link text-underline"
              >
                {alt[11] ? "Voltar" : "Alterar"}
              </span>
            </div>
          </div>
          <div className="row pl-xl-5 d-flex align-items-center my-2">
            <div className="col-xl-4 d-flex flex-row align-items-center justify-content-xl-between">
              <span>{nomesCampo[12].label}</span>
              {carteira && variacaoElement(12)}
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                outerClass="my-0"
                inputId="contasReceberBlocked"
                name="contasReceberBlocked"
                label=""
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked mb-xl-0 mb-2"
              />
            </div>

            {alt[12] && (
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  outerClass="my-0"
                  inputId="contasReceber"
                  name="contasReceber"
                  label=""
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </div>
            )}

            <div className="col-auto">
              <span
                role="button"
                onClick={() => acaoBotao(12)}
                className="btn-link text-underline"
              >
                {alt[12] ? "Voltar" : "Alterar"}
              </span>
            </div>
          </div>
          <div className="row pl-xl-5 d-flex align-items-center my-2">
            <div className="col-xl-4 d-flex flex-row align-items-center justify-content-xl-between">
              <span className="fw-500 txt-grey">{nomesCampo[13].label}</span>
              {carteira && variacaoElement(13)}
            </div>
            <div className="col-xl-3">
              <Field
                component={MaskedInput}
                outerClass="my-0"
                inputId="contasTotalBlocked"
                name="contasTotalBlocked"
                label=""
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                className="blocked mb-xl-0 mb-2"
              />
            </div>
          </div>

          <div className="d-flex flex-column my-2">
            <p className="p-color f-16 font-weight-bold mb-2">Observações</p>
            <div className="row my-0">
              <div className="col-xl-12">
                <Field
                  outerClass="my-0"
                  component={Textarea}
                  inputId="observacao"
                  name="observacao"
                  minHeight={7}
                  maxLength={500}
                  readOnly={carteira?.status?.key == 3}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-end align-items-center mt-3">
        <p className="h-38 my-2 d-flex align-items-center pl-xl-2 pr-4">
          <span
            role="button"
            className="p-color-lighten fw-400"
            onClick={() =>
              exportarRelatorioAdministrador(id, fundo.nomeDoFundo)
            }
          >
            Exportar Relatório Administrador
          </span>
        </p>

        {!emRevisao && !temDiferenca ? (
          <Button
            name={"Validar Dados"}
            classes="scs-bg-imp bg-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            action={() => validarDados(id)}
          />
        ) : (
          <Button
            name={"Revisar Dados"}
            classes="err-bg-imp bg-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            action={() =>
              revisarDados({ ...formValues, id, idFundo: fundo.id })
            }
          />
        )}
      </div>

      {/* MODAL */}
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={indexSelecionado != null}
        onClose={() => setIndexSelecionado(null)}
      >
        <div className="p-4">
          <div className="d-flex flex-row justify-content-between align-items-start">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Alteração nos dados do campo '
              {nomesCampo[indexSelecionado]?.label}'
            </p>
            <Close
              role="button"
              onClick={() => setIndexSelecionado(null)}
              className="text-danger mt-2"
            />
          </div>

          <p className="mt-3 mb-0 f-16 fw-400">
            Deseja realizar a alteração na informação do campo?
          </p>

          <div className="py-2 text-dark f-16">
            <div>
              <RadioGroup
                row
                value={checked}
                name="saldoDisponivel"
                onChange={(e) => setChecked(e.target.value)}
              >
                <FormControlLabel
                  value="Sim"
                  label="Sim"
                  control={
                    <Radio
                      checkedIcon={<CheckCircle style={{ color: "#83BF74" }} />}
                    />
                  }
                />
                <FormControlLabel
                  value="Nao"
                  label="Não"
                  control={
                    <Radio
                      checkedIcon={<CheckCircle style={{ color: "#83BF74" }} />}
                    />
                  }
                />
              </RadioGroup>
            </div>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Voltar"}
              action={() => setIndexSelecionado(null)}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Confirmar"}
              action={() => {
                if (checked == "Sim") {
                  mudaEstado(indexSelecionado);
                }

                setIndexSelecionado(null);
                setChecked(false);
              }}
              disabled={checked == "Nao" || !checked}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

CarteiraDetail = reduxForm({ form: "CarteiraDetail", destroyOnUnmount: true })(
  CarteiraDetail
);

const selector = formValueSelector("CarteiraDetail");

const mapStateToProps = (state) => ({
  fundo: state.carteiraReducer.carteiraFormTypes.fundo || null,
  carteira: state.carteiraReducer.carteiraFormTypes.fundoCarteira || null,

  patrimonioLiquidoDiferenca: selector(state, "patrimonioLiquidoDiferenca"),

  formValues: selector(
    state,
    "disponibilidadeCaixa",
    "disponibilidadeCompromissadas",
    "disponibilidadeTotal",
    "rendaFixaTotal",
    "direitosCreditoriosTotal",
    "hedgeOpcoes",
    "hedgeSwap",
    "hedgeTotal",
    "pddTotal",
    "contasPagar",
    "contasReceber",
    "contasTotal",
    "observacao"
  ),
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadCarteiraForm,
      revisarDados,
      validarDados,
      retornaValor,
      exportarRelatorioAdministrador,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(CarteiraDetail);
