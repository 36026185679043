/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import {
  getAprovadores,
  loadFormAprovadores,
} from "../../../../../actions/aprovadores/AprovadoresAction";
import TableActionWithRouteAuthorization from "../../../../common/table/TableActionWithRouteAuthorization";
import { APROVADORES } from "../../../../../utils/actionsAndFeaturesAuth";
import { msgError } from "../../../../../utils/helpers/OthersHelpers";

const AprovadoresList = (props) => {
  const {
    getAprovadores,
    aprovadoresData,
    aprovadoresConsult,
    messages,
    loadFormAprovadores,
  } = props;

  let user = JSON.parse(localStorage.getItem("userLogged"));
  let acessosUser = user.acessos;
  let permitido = acessosUser.some((acesso) => acesso === 1);

  const classTable = "text-center fw-700 d-flex justify-content-center w-100";
  const emptyData = messages?.infos[0];

  const tableHeader = (
    <tr>
      <th>
        <div className={`${classTable}`}>APROVADOR</div>
      </th>
      <th>
        <div className={`${classTable}`}>ÁREAS DE NEGÓCIO</div>
      </th>
      <th>
        <div className={`${classTable}`}>TIPO</div>
      </th>
      <th>
        <div className={`${classTable}`}>STATUS</div>
      </th>
      <th className="text-center"></th>
    </tr>
  );

  const tableContent =
    aprovadoresData &&
    aprovadoresData?.hasItems &&
    aprovadoresData?.items.map((item, index) => (
      <tr key={index}>
        <td className="text-center fw-400">
          {item?.usuario?.nome || "--"}
        </td>
        <td className="text-center fw-400 w-30">
          {item?.areasNegocio?.join(", ") || "--"}
        </td>
        <td className="text-center fw-400">
          {item?.tipoAprovacao?.tipo || "--"}
        </td>
        <td className="text-center fw-400">
          {item?.ativo ? 'Ativo' : 'Inativo' || "--"}
        </td>

        <TableActionWithRouteAuthorization
          arrow={true}
          feature={APROVADORES}
          viewAction={() => {
            permitido ? loadFormAprovadores(item.id) : msgError("Usuário não possui acesso");
          }}
        />
      </tr>
    ));

  return (
    <div className="container-fluid">
      <div className="p-3">
        {aprovadoresData.totalCount > 0 && (
          <>
            <Table
              withoutHeader
              classes="shadow-custom rounded-12 col-12 mt-2 mb-3 pd-10 bg-white"
              totalItems={aprovadoresData.totalCount}
              pagination={
                <PaginationRB
                  pageNumber={aprovadoresData.pageIndex - 1}
                  itemsPerPage={aprovadoresData.pageSize}
                  totalItems={aprovadoresData.totalCount}
                  filter={aprovadoresConsult}
                  fetchFunction={getAprovadores}
                  pageRange={5}
                />
              }
            >
              <TableHeader>{tableHeader}</TableHeader>
              <TableContent>{tableContent}</TableContent>
            </Table>
          </>
        )}

        {aprovadoresData.totalCount <= 0 && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

formValueSelector("AprovacaoForm");

const mapStateToProps = (state) => ({
  aprovadoresData: state.aprovadoresReducer.aprovadores,
  aprovadoresConsult: state.aprovadoresReducer.aprovadoresConsultCurrent,
  messages: state.aprovadoresReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getAprovadores,
      loadFormAprovadores,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(AprovadoresList);
