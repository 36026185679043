import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { buscarAnexoDiariodeBordoPorId } from "../../../../../../actions/gestao/DiarioBordoAction";
import { useDropzone } from "react-dropzone";

let UploadArquivosDiarioBordoForm = (props) => {
  const { diarioBordoAnexoList, buscarAnexoDiariodeBordoPorId } = props;

  const { getRootProps } = useDropzone({
    disabled: true,
  });

  return (
    <div>
      <section>
        <div className="row">
          <div className="col-12">
            <div
              {...getRootProps()}
              className="w-100 d-flex flex-column justify-content-center align-items-center text-center position-relative p-2 rounded mt-3 mb-4"
              style={{
                height: "200px",
                transition: "border 0.3s ease-out",
                border: "2px dashed #cccccc",
              }}
            >
              <div className="d-flex flex-column h-100 overflow-auto w-100 p-2">
                {diarioBordoAnexoList?.length > 0 &&
                  diarioBordoAnexoList?.map((file, i) => {
                    return (
                      <div
                        key={`file-${i}`}
                        onClick={(e) => e.stopPropagation()}
                        style={{
                          backgroundColor: "#E7E7E7",
                          border: "1px solid #B8B8B8",
                        }}
                        className="p-2 d-flex flex-row align-items-center justify-content-between rounded fw-400 my-1"
                      >
                        <span>{file.nomeArquivo || file.name}</span>
                        <div className="d-flex flex-row">
                          {file?.id && (
                            <i
                              role="button"
                              className="fa fa-cloud-download f-20"
                              onClick={async () =>
                                await buscarAnexoDiariodeBordoPorId({
                                  idAnexo: file?.id,
                                })
                              }
                            ></i>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buscarAnexoDiariodeBordoPorId,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadArquivosDiarioBordoForm);
