import axios from "axios";
import { toastr } from "react-redux-toastr";

import history from "../../components/common/routers/history";
import { OPCAO_SISTEMA_ROUTE_PATH, LOG_ROUTE_PATH, LISTA_ROUTE_PATH, DETALHAR_ROUTE_PATH, AUDIT_ROUTE_PATH } from "../../components/common/routers/constantesRoutes";

import { showAllMessages } from "../message/ErrorMessagesAPIAction";

import { downloadExcelFile } from "../../utils/helpers/FileHelper";

export const LOAD_FILTER_FORM_LOG = "LOAD_FILTER_FORM_LOG";
export const LOAD_FORM_LOG = "LOAD_FORM_LOG";
export const CLEAN_LIST_CONTENT = "CLEAN_LIST_CONTENT";
export const DATA_INICIO_LOG = "DATA_INICIO_LOG";
export const DATA_FINAL_LOG = "DATA_FINAL_LOG";

const LOG_API = process.env.REACT_APP_URL_API;

export function showLogList(from = "") {
  return (dispatch) => {
    return dispatch([history.push(`/${OPCAO_SISTEMA_ROUTE_PATH}/${LOG_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)]);
  };
}

export function getLogs(inicio, fim, page = 1, tamanhoPagina = 20) {
  return (dispatch) => {
    if (inicio && fim) {
      return axios.get(LOG_API + `${AUDIT_ROUTE_PATH}?dataInicial=${inicio}&dataFinal=${fim}&pagina=${page}&tamanhoPagina=${tamanhoPagina}`).then((result) =>
        dispatch([
          {
            type: LOAD_FILTER_FORM_LOG,
            payload: result.content,
          },
          {
            type: DATA_INICIO_LOG,
            payload: inicio,
          },
          {
            type: DATA_FINAL_LOG,
            payload: fim,
          },
          showAllMessages(result.messages),
        ])
      );
    } else {
      return toastr.error("Erro", "Por favor, preencha os campos de data");
    }
  };
}

export function exportLog(inicio, fim) {
  return (dispatch) => {
    return axios.post(LOG_API + `${AUDIT_ROUTE_PATH}/exportar?dataInicial=${inicio}&dataFinal=${fim}`).then((result) => {
      dispatch([downloadExcelFile(result.content, `Logs_${inicio}-a-${fim}.xlsx`), showAllMessages(result.messages)]);
    });
  };
}

export function loadFormLog(id) {
  return (dispatch) => {
    return axios.get(LOG_API + `${AUDIT_ROUTE_PATH}/detalhe/${id || ""}`).then((result) => {
      dispatch([
        id ? history.push(`/${OPCAO_SISTEMA_ROUTE_PATH}/${LOG_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/${id}`) : undefined,
        {
          type: LOAD_FORM_LOG,
          payload: result.content,
        },
      ]);
    });
  };
}

export function cleanResult() {
  return { type: CLEAN_LIST_CONTENT };
}
