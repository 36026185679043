/* eslint eqeqeq: 0 */

import { checkDocument } from "../../../../../utils/helpers/OthersHelpers";

export const validate = (values) => {
  const EPC = values.empresaPrestadora?.contas;
  const pers = values.personalidade?.key;
  const stsComp = values.complemento?.statusCompliance?.key;

  const registrarCadastro = values.typeSubmit == 'Registrar';

  let errors = {
    // Dados do Fornecedor
    personalidade: !values.personalidade ? "Preenchimento obrigatório" : null,
    empresaPrestadora: {
      cnpj: !pers
        ? "Defina a Personalidade"
        : !values.empresaPrestadora?.cnpj
        ? "Preenchimento obrigatório"
        : pers === 1 && values.empresaPrestadora?.cnpj.length !== 18
        ? "O campo deve ter 18 caracteres"
        : pers === 2 && values.empresaPrestadora?.cnpj.length !== 14
        ? "O campo deve ter 14 caracteres"
        : values.empresaPrestadora?.cnpj && !checkDocument(values.empresaPrestadora?.cnpj)
        ? "Documento inválido"
        : null,

      nomeSimplificado: !values.empresaPrestadora?.nomeSimplificado ? "Preenchimento obrigatório" : null,
      sigla: !values.empresaPrestadora?.sigla ? "Preenchimento obrigatório" : null,
      codigo: !values.empresaPrestadora?.codigo ? "Preenchimento obrigatório" : null,
      perfis: (!values.empresaPrestadora?.perfis || values.empresaPrestadora?.perfis?.length <= 0) ? "Preenchimento obrigatório" : null,
      emailPessoa: !values.empresaPrestadora?.emailPessoa ? "Preenchimento obrigatório" : null,
      telefone: !values.empresaPrestadora?.telefone ? "Preenchimento obrigatório" : null,
      estadoCivil: !values.empresaPrestadora?.estadoCivil ? "Preenchimento obrigatório" : null,

      idCnae: !values.empresaPrestadora?.idCnae && pers == 1 ? "Preenchimento obrigatório" : null,
      nomeFantasia: !values.empresaPrestadora?.nomeFantasia && pers == 1 ? "Preenchimento obrigatório" : null,
      razaoSocial: !values.empresaPrestadora?.razaoSocial && pers == 1 ? "Preenchimento obrigatório" : null,

      rg: !values.empresaPrestadora?.rg && pers == 2 ? "Preenchimento obrigatório" : null,

      contas: EPC && EPC.length 
        ? EPC.map((conta) => ({
          idBanco: !conta || !conta.idBanco ? 'Preenchimento obrigatório' : null,
          ...(conta && conta.idBanco
            ? {
                agencia: !conta.agencia ? 'Preenchimento obrigatório' : null,
                conta: !conta.conta ? 'Preenchimento obrigatório' : null,
                contaDigito: !conta.contaDigito ? 'Preenchimento obrigatório' : null,
            }
            : {}),
          })).filter(Boolean)
        : null,
    },

    // Endereço
    endereco: {
      cep: !values.endereco?.cep
        ? "Preenchimento obrigatório"
        : values.endereco?.cep?.replace(/-/g, '')?.length < 8
        ? "Deve conter no mínimo 9 caracteres"
        : null,
      pais: !values.endereco?.pais ? "Preenchimento obrigatório" : null,
      endereco: !values.endereco?.endereco ? "Preenchimento obrigatório" : null,
      numero: !values.endereco?.numero ? "Preenchimento obrigatório" : null,
      bairro: !values.endereco?.bairro ? "Preenchimento obrigatório" : null,
      cidade: !values.endereco?.cidade ? "Preenchimento obrigatório" : null,
      estado: !values.endereco?.estado ? "Preenchimento obrigatório" : null,
    },
  };

  if (registrarCadastro) {
    errors = {
      ...errors,
      // Compliance
      complemento: {
        statusCompliance: !values.complemento?.statusCompliance
          ? "Preenchimento obrigatório"
          : null,
        comentarioCompliance:
          !values.complemento?.comentarioCompliance && stsComp !== 2
            ? "Preenchimento obrigatório"
            : null,
      },
    }
  }

  if (values?.empresaPrestadora?.emitirNotaFiscal) {
    errors = {
      ...errors,
      complemento: {
        ...errors.complemento,
        codigoClienteProtheus: (!values.complemento?.codigoClienteProtheus || values.complemento?.codigoClienteProtheus?.length < 6) ? "Inferior a 6 caracteres" : null,
      },
    };
  }

  if (values?.empresaPrestadora?.realizarPagamento) {
    errors = {
      ...errors,
      complemento: {
        ...errors.complemento,
        codigoFornecedorProtheus: (!values.complemento?.codigoFornecedorProtheus || values.complemento?.codigoFornecedorProtheus?.length < 6) ? "Inferior a 6 caracteres" : null,
      },
    };
  }

  return errors;
};
