import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';

import { selectTab } from '../../../../../actions/operacao/tabs/TabsAction';

import AtivoList from './list/AtivoList';
import OperacaoHeader from './../OperacaoHeader';
import { 
    OPERACAO_TABS, 
    ATIVO_TAB
} from './../OperacaoTabs';
import ContainerPage from '../../../../common/container/ContainerPage';

const AtivoPage = ({ selectTab }) => {
    useEffect(() => {
        selectTab(ATIVO_TAB);
    }, [selectTab]);

    return (
        <ContainerPage
            tabs={OPERACAO_TABS}
            paramsRoute={''}
            header={<OperacaoHeader />}
        >
            <AtivoList />
        </ContainerPage>
    );
};

const mapStateToProps = state => ({ 
    tab: state.tab,
    operacoes: state.operacaoReducer.selectedOperacoes, 
});

const mapDispatchToProps = dispatch => bindActionCreators({     
    selectTab,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AtivoPage));
