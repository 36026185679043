import React from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector, Field } from "redux-form";

import Form from "./../../../../common/form/Form";
import FormHeader from "./../../../../common/form/FormHeader";
import Button from "./../../../../common/button/Button";
import FormContent from "./../../../../common/form/FormContent";
import { bindActionCreators } from "redux";
import DropdownListInput from "./../../../../common/input/DropdownListInput";
import Input from "./../../../../common/input/Input";

import {
  getJobs,
  processarJob,
  showProcessarJobDialog,
} from "../../../../../actions/job/GerenciamentoJobAction";
import If from "../../../../common/If/If";

import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import { GENERATE, JOB } from "../../../../../utils/actionsAndFeaturesAuth";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";

const TIPO_JOB_PRECIFICACAO_AGING_KEY = 3;
const TIPO_JOB_AGENDA_COMPLEMENTO_MONITORAMENTO_KEY = 6;
const TIPO_JOB_RELATORIO_GESTAO_FIDUCIARIA_KEY = 8;

const exibirBotaoProcessarJob = (tipoJob) => {
  return (
    tipoJob &&
    tipoJob.key !== TIPO_JOB_PRECIFICACAO_AGING_KEY &&
    tipoJob.key !== TIPO_JOB_AGENDA_COMPLEMENTO_MONITORAMENTO_KEY &&
    tipoJob.key !== TIPO_JOB_RELATORIO_GESTAO_FIDUCIARIA_KEY
  );
};

let GerenciamentoJobListFilter = (props) => {
  const {
    getJobs,
    tipoJob,
    statusJob,
    tipoList,
    statusList,
    dataRegistro,
    processarJob,
    authorizationFunction,
    showProcessarJobDialog,
    showJobDialog,
    permitirProcessarJob,
  } = props;

  return (
    <div className="w-100 px-4">
      <Form>
        <FormHeader title={"Gerenciamento de Jobs"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getJobs}
        >
          <div className="col-lg-12 px-0">
            <div className="row px-0">
              <div className="col-lg-4">
                <Field
                  component={DropdownListInput}
                  inputId="tipoJob"
                  name="tipo"
                  label="Tipo"
                  options={tipoList}
                  textField="value"
                  valueField="key"
                  filter="contains"
                />
              </div>
              <div className="col-lg-4">
                <Field
                  component={DropdownListInput}
                  inputId="statusJob"
                  name="status"
                  label="Status"
                  options={statusList}
                  textField="value"
                  valueField="key"
                  filter="contains"
                />
              </div>
              <div className="col-lg-4">
                <Field
                  component={Input}
                  className={"filter-input"}
                  inputId="dataRegistro"
                  type={"date"}
                  name="dataRegistro"
                  label="Data"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <Button
                  action={() => getJobs({ tipoJob, statusJob, dataRegistro })}
                  classes="p-bg-imp rounded"
                  icon={"search"}
                  name={"Buscar"}
                />
                <If test={exibirBotaoProcessarJob(tipoJob)}>
                  <Button
                    action={() =>
                      permitirProcessarJob
                        ? showProcessarJobDialog(true, { tipoJob })
                        : authorizationFunction(
                            { idAcao: GENERATE, idFuncionalidade: JOB },
                            () => processarJob({ tipoJob })
                          )
                    }
                    classes="p-bg-imp rounded ml-3"
                    icon={"history"}
                    name={"Processar Job"}
                  />
                </If>
              </div>
            </div>
          </div>
        </FormContent>
      </Form>
      <ConfirmDialog
        title="Aviso"
        contentText="Tem certeza que deseja executar esse JOB?"
        textButtonCancel="CANCELAR"
        textButtonConfirm="CONFIRMAR"
        showDialog={showJobDialog.showDialog}
        onClose={() => showProcessarJobDialog(false)}
        onConfirm={() =>
          authorizationFunction(
            { idAcao: GENERATE, idFuncionalidade: JOB },
            () => processarJob(showJobDialog.tipoJob)
          )
        }
        type="warning"
        maxWidth="md"
      />
    </div>
  );
};
GerenciamentoJobListFilter = reduxForm({ form: "gerenciamentojobForm" })(
  GerenciamentoJobListFilter
);
const selector = formValueSelector("gerenciamentojobForm");

const mapStateToProps = (state) => ({
  tipoJob: selector(state, "tipo"),
  statusJob: selector(state, "status"),
  dataRegistro: selector(state, "dataRegistro"),
  tipoList:
    state.gerenciamentoJobReducer.gerenciamentoJobFormTypes.tiposJob || [],
  statusList:
    state.gerenciamentoJobReducer.gerenciamentoJobFormTypes.jobsStatus || [],
  showJobDialog: state.gerenciamentoJobReducer.showGerenciarJobDialog,
  permitirProcessarJob:
    state.gerenciamentoJobReducer.gerenciamentoJobFormTypes
      .permissaoProcessarJob,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getJobs,
      processarJob,
      authorizationFunction,
      showProcessarJobDialog,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GerenciamentoJobListFilter);
