import axios from "axios";
import qs from 'qs';

import { showAllMessages } from "../message/ErrorMessagesAPIAction";
import { downloadExcelFile } from "../../utils/helpers/FileHelper";

import {
    deserializeFieldsFilterForm,
    deserializeFieldsRegulatorio,
    serializeFieldsRegulatorio,
} from "../../components/business/relatorios/mappings/RegulatorioMap";
import { change } from "redux-form";
import { changeMessage } from "../infra/LoadingScreenAction";
import { LISTA_ROUTE_PATH, REGULATORIO_ROUTE_PATH, RELATORIOS_ROUTE_PATH } from "../../components/common/routers/constantesRoutes";
import { msgError } from "../../utils/helpers/OthersHelpers";
import history from "../../components/common/routers/history";


export const FILTER_FETCHED = "FILTER_FETCHED";
export const REGULATORIOS_FETCHED = "REGULATORIOS_FETCHED";
export const REGULATORIOS_CONSULT = "REGULATORIOS_CONSULT";
export const LOAD_FORM_REGULATORIO = "LOAD_FORM_REGULATORIO";
export const SAVE_FORM_REGULATORIO_ERRORS = "SAVE_FORM_REGULATORIO_ERRORS";

export const CLEAR_SELECTEDS = "CLEAR_SELECTEDS";
export const XML_IDS = "XML_IDS";

const REGULATORIO_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_RELATORIO_API + process.env.REACT_APP_PATH_REGULATORIO_API;

export function loadFilter() {
    return dispatch => {
        dispatch(changeMessage("Carregando Filtros dos Relatórios Regulatórios"))
        return axios.get(`${REGULATORIO_API}filtro`)
            .then(result =>
                dispatch({
                    type: FILTER_FETCHED,
                    payload: result.content
                })
            );
    };
}

export function loadRegulatorios(filters, page = 1) {
    const params = deserializeFieldsFilterForm(filters, page);

    return (dispatch) => {
        dispatch(changeMessage("Carregando Relatórios Regulatórios"))
        return axios
            .post(`${REGULATORIO_API}busca`, { ...params })
            .then(result =>
                dispatch([
                    {
                        type: REGULATORIOS_FETCHED,
                        payload: result,
                    },
                    {
                        type: REGULATORIOS_CONSULT,
                        payload: {
                            ...filters,
                            pagingConfig: { pageIndex: page, pageSize: 10 },
                        },
                    },
                ])
            );
    }
}

export function saveSelectedValues(ids) {
    return dispatch => {
        dispatch({
            type: XML_IDS,
            payload: ids
        })
    };
}

export function clearSelecteds() {
    return (dispatch) => {
        dispatch([
            {
                type: CLEAR_SELECTEDS
            },
        ])
    }
}

// EDIT/SAVE/LOAD

export function loadFormRegulatorio(idRegulatorio = '') {
  let url = REGULATORIO_API + `tela/${idRegulatorio}`;

  return (dispatch) => {
    if (idRegulatorio) dispatch(changeMessage("Carregando Relatório Regulatório"))
    return axios.get(url).then((result) => {
      if (idRegulatorio && !result?.content?.relatorioRegulatorio) {
        dispatch([
          msgError("Relatório não encontrado!", false),
          backToList(),
        ])
        return false
      }

      dispatch([
        {
          type: LOAD_FORM_REGULATORIO,
          payload: result.content,
        },
        serializeFieldsRegulatorio(result.content),
      ])
      
      return result.content;
    });
  };
}

export function editRegulatorio(regulatorio) {
  const deserializeRegulatorio = deserializeFieldsRegulatorio(regulatorio);

  return (dispatch) => {
    dispatch(changeMessage("Salvando Informe"))
    dispatch([
      {
        type: SAVE_FORM_REGULATORIO_ERRORS,
        payload: null,
      },
    ]);
    
    return axios
      .put(REGULATORIO_API, deserializeRegulatorio)
      .then((result) => {
        return dispatch([
          backToList(),
          showAllMessages(result.messages),
        ])
      })
      .catch((err) => {
        if (err && err.data?.messages && err.data?.messages?.hasError) {
          document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" });
          dispatch([
            {
              type: SAVE_FORM_REGULATORIO_ERRORS,
              payload: err.data?.messages,
            },
          ]);
        }
      });
  };
}

// IMPORT/EXPORT

// Exportar Controle em Excel
export function exportControleToExcel(filters) {
    const params = deserializeFieldsFilterForm(filters);

    const data = {
        ...params,
        pagingConfig: {
            pageSize: 50,
            pageIndex: filters?.pagingConfig.page || params?.pagingConfig.page,
        },
    }

    return (dispatch) => {
      dispatch(changeMessage("Exportando Controle"))
      return axios
        .post(`${REGULATORIO_API}exportar/busca`, data)
        .then((result) => {
          if (result.content) {
            const { nomeArquivo, extensaoArquivo, arquivo } = result.content;
            dispatch([
              downloadExcelFile(arquivo, `${nomeArquivo}.${extensaoArquivo}`),
              showAllMessages(result.messages),
            ]);
          } else {
            dispatch([showAllMessages(result.messages)]);
          }
        });
    };
  }

// Baixar Modelo Service/Contábil/Outros Dados
export function exportRegulatoriosModelo(tipo) {
    return (dispatch) => {
      dispatch(changeMessage("Baixando Modelo"))
      return axios
        .post(`${REGULATORIO_API}exportar/modelo/${tipo}`)
        .then((result) => {
          if (result.content) {
            const { nomeArquivo, extensaoArquivo, arquivo } = result.content;
            dispatch([
              downloadExcelFile(arquivo, `${nomeArquivo}.${extensaoArquivo}`),
              showAllMessages(result.messages),
            ]);
          } else {
            dispatch([showAllMessages(result.messages)]);
          }
        });
    };
  }

// Baixar Arquivo da Lista
export function exportArquivo(tipo = '', id) {
    return (dispatch) => {
      dispatch(changeMessage(`Baixando ${tipo?.toUpperCase()}`))
      return axios
        .post(`${REGULATORIO_API}exportar/informe-cvm/${tipo}/relatorio/${id}`, { id })
        .then((result) => {
          if (result.content) {
            const { nomeArquivo, extensaoArquivo, arquivo } = result.content;
            dispatch([
              downloadExcelFile(arquivo, `${nomeArquivo}.${extensaoArquivo}`),
              showAllMessages(result.messages),
            ]);
          } else {
            dispatch([showAllMessages(result.messages)]);
          }
        });
    };
  }

  export function importRegulatorios(tipo, file, individual, id) {
    let formData = new FormData();
    formData.append("file", file);

    const query = qs.stringify({ individual, id }, { addQueryPrefix: true });
    const url = `${REGULATORIO_API}importar/${tipo}${query}`;
  
    return async dispatch => {
        dispatch(changeMessage("Enviando Importação"));

        try {
            const result = await axios.post(url, formData);
            dispatch([
                showAllMessages(result.messages),
            ]);
            return result;
        } catch (error) {
            console.error("Erro na importação:", error);
            return { messages: { hasError: true }}
        }
    };
}

  // HELPERS

  export function backToList() {
    return (dispatch) => {
      return dispatch([
        history.push(`/${RELATORIOS_ROUTE_PATH}/${REGULATORIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
      ]);
    };
  }

  export function zeraDependentesOnChange(dependentes) {
    return (dispatch) => {
      const actions = dependentes.map(dependente => change("RegulatorioForm", dependente, 0));
      dispatch(actions);
    };
  }