import React, { Component } from 'react';
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';

import Table from '../../../../../common/table/Table';
import TableHeader from '../../../../../common/table/TableHeader';
import TableContent from '../../../../../common/table/TableContent';
import PaginationRB from '../../../../../common/pagination/PaginationRB';

const styles = theme => ({});

class PatrimonioContasVinculadas extends Component {
    render() {
        const { 
            classes,
            dataList,
            getContas
        } = this.props;

        return(
            <div className={`table-responsive ${classes.root}`}>
                <Table
                    withoutHeader
                    classes="border-0 shadow-none col-12 my-0 pd-10 bg-white"
                    totalItems={dataList.totalCount || 0}
                    pagination={    
                        <PaginationRB
                            pageNumber={dataList.pageIndex - 1}
                            itemsPerPage={dataList.pageSize}
                            totalItems={dataList.totalCount || 0}
                            fetchFunction={getContas}
                            pageRange={5}
                        />
                    }
                >
                    <TableHeader>
                        <tr>
                            <th className={`text-center fw-700`}>Código Opea</th>
                            <th className={`text-center fw-700`}>Código B3</th>
                            <th className={`text-center fw-700`}>Tipo Conta</th>
                            <th className={`text-center fw-700`}>Banco</th>
                            <th className={`text-center fw-700`}>Agência / Conta</th>
                            <th className={`text-center fw-700`}>Origem dos Recursos</th>
                        </tr>
                    </TableHeader>
                    <TableContent>
                        {
                            dataList?.items?.length > 0 
                            ? (
                                dataList?.items.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className={`text-center fw-400`}>{item.codigoOpea || "--"}</td>
                                            <td className={`text-center fw-400`}>{item.codigoB3 || "--"}</td>
                                            <td className={`text-center fw-400`}>{item.tipoConta || "--"}</td>
                                            <td className={`text-center fw-400`}>{item.banco}</td>
                                            <td className={`text-center fw-400`}>{`${item.agecia} / ${item.contaCorrente}-${item.contaCorrenteDigito}` || "--"}</td>
                                            <td className={`text-center fw-400`}>{item.origemRecursos}</td>
                                        </tr>
                                    );
                                })
                            )
                            : (
                                <tr>
                                    <td 
                                        colSpan={12}
                                        align="center" 
                                        className={`text-center fw-400`} 
                                    >
                                        Não há vínculos
                                    </td>
                                </tr>
                            )
                        }
                    </TableContent>
                </Table>
            </div>
        );
    }
}


PatrimonioContasVinculadas = connect()(PatrimonioContasVinculadas);

export default withStyles(styles)(PatrimonioContasVinculadas);
