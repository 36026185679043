/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";

import {
  exportParticipantesModel,
  exportParticipantesToExcel,
  getParticipantes,
  deleteParticipante,
} from "../../../../../actions/participante/ParticipanteAction";
import {
  PARTICIPANTE_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
} from "../../../../common/routers/constantesRoutes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { formatStrToCnpjOrCpf } from "../../../../../utils/helpers/StringHelper";
import ModalAction from "../../../../common/modal/ModalAction";

const ParticipanteList = (props) => {
  const {
    getParticipantes,
    participantesData,
    participanteConsult,
    messages,
    exportParticipantesToExcel,
    exportParticipantesModel,
    deleteParticipante,
  } = props;

  const [modalDelete, setModalDelete] = React.useState(false);

  const arrHeader = [
    "CPF/CNPJ",
    "NOME PARTICIPANTE",
    "E-MAIL",
    "TIPO",
    "AÇÕES",
  ];

  const tableHeader = (
    <tr>
      {arrHeader.map((item, i) => (
        <th key={i}>
          <div className="text-center fw-700 d-flex justify-content-center w-100">
            {item}
          </div>
        </th>
      ))}
    </tr>
  );

  const emptyData = messages?.infos[0];

  const tableContent =
    participantesData &&
    participantesData.hasItems &&
    participantesData.items.map((item, index) => (
      <tr key={index}>
        <td className="text-center fw-400">
          {item?.documento ? formatStrToCnpjOrCpf(item?.documento) : "--"}
        </td>
        <td className="text-center fw-400">{item?.nome || "--"}</td>
        <td className="text-center fw-400">{item?.emails || "--"}</td>
        <td className="text-center fw-400">{item?.tipos || "--"}</td>
        <td className="text-center">
          <div className="d-flex flex-row align-items-center justify-content-center">
            <Link
              className="p-color-imp f-20 mr-3 ml-2 px-1 mx-1"
              to={`/${PARTICIPANTE_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/${item.id}`}
            >
              <FontAwesomeIcon color="#630a37" icon={faPencilAlt} />
            </Link>

            <FontAwesomeIcon
              size="lg"
              role="button"
              color="#630a37"
              icon={faTrashAlt}
              onClick={() => setModalDelete(item)}
            />
          </div>
        </td>
      </tr>
    ));

  return (
    <div className="container-fluid">
      <div className="p-3">
        {participantesData?.totalCount > 0 && (
          <Table
            withoutHeader
            files={[
              {
                label: "Baixar Modelo de Importação",
                function: () => exportParticipantesModel(participanteConsult),
              },
              {
                label: "Exportar para Excel",
                function: () => exportParticipantesToExcel(participanteConsult),
              },
            ]}
            classes="shadow-custom rounded-12 col-12 mt-xl-2 mt-1 mb-3 pd-10 bg-white"
            totalItems={participantesData.totalCount}
            pagination={
              <PaginationRB
                pageNumber={participantesData.pageIndex - 1}
                itemsPerPage={participantesData.pageSize}
                totalItems={participantesData?.totalCount}
                filter={participanteConsult}
                fetchFunction={getParticipantes}
                pageRange={5}
              />
            }
          >
            <TableHeader>{tableHeader}</TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        )}

        {participantesData?.totalCount <= 0 && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}

        <ModalAction
          item={modalDelete}
          title="Excluir participante"
          open={Boolean(modalDelete)}
          close={() => setModalDelete(false)}
          message={`Você está prestes a excluir o participante: ${modalDelete?.nome}`}
          action={async () => {
            await deleteParticipante(modalDelete?.id)
            getParticipantes()
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  participantesData: state.participanteReducer.participantes,
  participanteConsult: state.participanteReducer?.participanteConsultCurrent,
  messages: state.participanteReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getParticipantes,
      exportParticipantesToExcel,
      exportParticipantesModel,
      deleteParticipante,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(ParticipanteList);
