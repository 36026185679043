import {
    GET_PREFIXADO_COMPOSTO,
    GET_PREFIXADO_SIMPLES,
    GET_FLUTUANTE,
    GET_CORRECAO_MONETARIA,
} from '../../actions/operacao/PrecificacaoPrecisaoAction';

const INITIAL_STATE = {
    precificacaoPrecisaoList: [],
    labelTipoPagamento: "",
};

const prefixado_composto = [
    {descricao: 'Valor Nominal Antes do Pagamento (VNB)', id: 1, formula: 'VNB'}, 

    {descricao: 'Fator de Juros/Spread Diário (FS Diário)', id: 2, formula: '(1 + i)^(30/360)^(dcp/dct)', rowSpan: 5}, 
    {id: 21, formula: '(1 + i)', isRowSpan: true}, 
    {id: 22, formula: '(30/360)', isRowSpan: true}, 
    {id: 23, formula: '(dcp/dct)', isRowSpan: true}, 
    {id: 24, formula: '(1 + i)^(30/360)', isRowSpan: true}, 


    {descricao: 'Fator de Juros/Spread Acumulado (FSA)  ', formula: 'FSA – 1', id: 3}, 
    {descricao: 'Juros ( J )', formula: 'VNB * (FSA-1)', id: 4}, 
    
    {descricao: 'Amortização (AM)', id: 5, formula: 'Ta * VNB', rowSpan: 2}, 
    {id: 5, formula: 'Ta', isRowSpan: true}, 

    {descricao: 'Prestação paga Pagamento (PMT)', formula: 'J + AM', id: 6}, 
    {descricao: 'Valor Nominal Corrigido ou Pós Evento de Pagamento (VNC)', formula: 'VNB + J – PMT', id: 7}, 
    {descricao: 'Taxa de Amortização Extraordinária (Taex)', formula: 'Taex', id: 8}, 
    {descricao: 'Amortização Extraordinária (AMEX)', formula: 'VNB * Taex', id: 9}, 
    {descricao: 'Valor Nominal Corrigido ou Pós Evento de Pagamento Extraordinário (VNC 2)', formula: 'VNC – AMEX', id: 10}, 
];


const flutuante = [
    {descricao: 'Valor Nominal Antes do Pagamento (VNB)', formula: 'VNB', id: 1}, 

    {descricao: 'Fator de Spread Diário (FS Diário)', formula: '(1 + i)^(1/252)', id: 2, rowSpan: 3}, 
    {descricao: 'Fator de Spread Diário (FS Diário)', formula: '(1 + i)', id: 21, isRowSpan: true},
    {descricao: 'Fator de Spread Diário (FS Diário)', formula: '(1/252)', id: 22, isRowSpan: true},

    {descricao: 'Fator de Spread Acumulado (FSA)', formula: 'FSA', id: 3}, 

    {descricao: 'Fator DI Diário (TDIk)', id: 11, formula: '(1 + p * TDIk)^(1/252)', rowSpan: 3}, 
    {descricao: 'Fator DI Diário (TDIk)', id: 111, formula: '(% Taxa DI)', isRowSpan: true},
    {descricao: 'Fator DI Diário (TDIk)', id: 112, formula: '(1 + p * TDIk)', isRowSpan: true},

    {descricao: 'Fator DI Acumulado (FDI)', formula: 'FDI', id: 12}, 
    {descricao: 'Fator de Juros (FJ)', formula: 'FSA * FDI', id: 13}, 
    {descricao: 'Juros ( J )', formula: 'VNB * (FSA-1)', id: 4}, 

    {descricao: 'Amortização (AM)', id: 5, formula: 'Ta * VNB ou Ta * Fj * VNB', rowSpan: 2},
    {descricao: 'Amortização (AM)', id: 51, formula: 'Ta', isRowSpan: true},

    {descricao: 'Prestação paga Pagamento (PMT)', id: 6, formula: 'J + AM'}, 
    {descricao: 'Valor Nominal Corrigido ou Pós Evento de Pagamento (VNC)', formula: 'VNB + J – PMT', id: 7}, 
    {descricao: 'Taxa de Amortização Extraordinária (Taex)', id: 8, formula: 'Taex'}, 
    {descricao: 'Amortização Extraordinária (AMEX)', id: 9, formula: 'VNB * Taex'}, 
    {descricao: 'Valor Nominal Corrigido ou Pós Evento de Pagamento Extraordinário (VNC 2)', id: 10, formula: 'VNC – AMEX'}, 
];

const correcaoMonetaria = [
    {descricao: 'Valor Nominal Antes do Pagamento (VN 1, VN 2 e VN 3)', id: 1, formula: 'VNB'}, 
        
    {descricao: 'Fator de Spread Diário (FS Diário)', id: 2, formula: '(1 + i)^(1/252)', rowSpan: 3}, 
    {id: 21, formula: '(1 + i)', isRowSpan: true}, 
    {id: 22, formula: '1/252', isRowSpan: true}, 
    
    {descricao: 'Fator de Spread Acumulado (FSA) ', id: 3, formula: 'FSA'}, 
    
    {descricao: 'Fato Correção Diária (C Diário)', id: 14, formula: '(1 + C)^(1/dcp)', rowSpan: 4}, 
    {id: 141, formula: 'C( Nik/Nik-1 )', isRowSpan: true}, 
    {id: 142, formula: '1/dct', isRowSpan: true}, 
    {id: 143, formula: '(1 + C)', isRowSpan: true}, 
    
    {descricao: 'Fator Correção ( C )', id: 15, formula: 'C'}, 
    
    {descricao: 'Juros ( J )', id: 4, formula: 'VNB * (FSA-1)'}, 
    {descricao: 'Amortização (AM)', id: 5, formula: 'Ta * VNB', rowSpan: 2}, 
    {id: 51, formula: 'Ta', isRowSpan: true}, 

    {descricao: 'Prestação paga Pagamento (PMT)', id: 6, formula: 'J + AM'}, 
    {descricao: 'Valor Nominal Corrigido ou Pós Evento de Pagamento (VNC)', id: 7, formula: 'VNB + J – PMT'}, 
    {descricao: 'Taxa de Amortização Extraordinária (Tamex)', id: 8, formula: 'Tamex'}, 
    {descricao: 'Amortização Extraordinária (AMEX)', id: 9, formula: 'VNB * Tamex'}, 
    {descricao: 'Valor Nominal Corrigido ou Pós Evento de Pagamento Extraordinário (VNC 3)', id: 10, formula: 'VN 2 + J - PMT – AMEX'}, 
];

export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case GET_PREFIXADO_COMPOSTO:
            return {
                ...state,
                labelTipoPagamento: "Prefixado Composto",
                precificacaoPrecisaoList: prefixado_composto,
            };
        case GET_PREFIXADO_SIMPLES:
            return {
                ...state,
                labelTipoPagamento: "Prefixado Simples",
                precificacaoPrecisaoList: prefixado_composto,
            };
        case GET_FLUTUANTE:
            return {
                ...state,
                labelTipoPagamento: "Flutuante",
                precificacaoPrecisaoList: flutuante,
            };
        case GET_CORRECAO_MONETARIA:
            return {
                ...state,
                labelTipoPagamento: "Correção Monetária",
                precificacaoPrecisaoList: correcaoMonetaria,
            };
        default:
            return state;
    }
}