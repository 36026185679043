/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";

import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";

import {
  cleanMonitoramento,
  exportMonitoramentosToExcel,
  getMonitoramento,
  getMonitoramentos,
} from "../../../../../actions/monitoramento/MonitoramentoAction";
import TableActionWithRouteAuthorization from "../../../../common/table/TableActionWithRouteAuthorization";
import { MONITORAMENTO } from "../../../../../utils/actionsAndFeaturesAuth";
import ReactTooltip from "react-tooltip";
import ModalMonitoramentos from "../modals/ModalMonitoramentos";

const MonitoramentoList = (props) => {
  const {
    getMonitoramentos,
    getMonitoramento,
    cleanMonitoramento,
    monitoramentosData,
    monitoramentosConsult,
    messages,
    exportMonitoramentosToExcel,
    filtrosMonitoramento,
    monitoramentoDto,
  } = props;

  const tableHeader = (
    <tr>
      <th className="text-center"></th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          PATRIMÔNIO SEPARADO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          CÓDIGO OPEA
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          APELIDO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          DATA APURAÇÃO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          DATA RENOVAÇÃO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          TIPO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          TÍTULO COMPLEMENTO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          ETAPA
        </div>
      </th>
      <th className="text-center"></th>
    </tr>
  );

  const emptyData = messages?.infos[0];

  const tableContent =
    monitoramentosData &&
    monitoramentosData.hasItems &&
    monitoramentosData.items.map((item, index) => (
      <tr key={index} className={`tr-status sts-${item.status}`}>
        <td className="text-center td-status">
          <div className="ml-3">
            <div
              data-type="light"
              data-for={`ttp-list-${index}`}
              data-tip={item.status}
              className={`circle-status sts-${item.status} rounded-circle mx-0`}
            />
            <ReactTooltip
              noArrow
              effect="solid"
              id={`ttp-list-${index}`}
              className="px-2 py-1"
              globalEventOff="click"
              style={{ width: "100px" }}
            />
          </div>
        </td>

        <td className="text-center fw-400">
          {item.descricaoPatrimonioSeparado || "--"}
        </td>

        <td className="text-center fw-400">
          {item?.codigosOpea?.length > 0 ? item.codigosOpea.join(", ") : "--"}
        </td>

        <td className="text-center fw-400">
          {item?.apelidosOperacao?.length > 0 ? item.apelidosOperacao.join(", ") : "--"}
        </td>

        <td className="text-center fw-400">
          {getDateFormatedFromString(item.dataInicio) || "--"}
        </td>

        <td className="text-center fw-400">
          {getDateFormatedFromString(item.dataRenovacao) || "--"}
        </td>

        <td className="text-center fw-400">{item.tipo || "--"}</td>

        <td className="text-center fw-400">{item.tituloComplemento || "--"}</td>

        <td className="text-center fw-400">{item.statusAgenda || "--"}</td>

        <TableActionWithRouteAuthorization
          arrow={true}
          feature={MONITORAMENTO}
          viewAction={async () => {
            await getMonitoramento(item.idAgenda);
          }}
        />
      </tr>
    ));

  return (
    <div className="container-fluid">
      <div className="p-3">
        {monitoramentosData?.totalCount > 0 && (
          <Table
            withoutHeader
            files={[
              { label: 'Exportar para Excel', function: () => exportMonitoramentosToExcel(filtrosMonitoramento) }
            ]}        
            classes="shadow-custom rounded-12 col-12 mt-4 mb-3 pd-10 bg-white"
            totalItems={monitoramentosData.totalCount}
            pagination={
              <PaginationRB
                pageNumber={monitoramentosData.pageIndex - 1}
                itemsPerPage={monitoramentosData.pageSize}
                totalItems={monitoramentosData?.totalCount}
                filter={monitoramentosConsult}
                fetchFunction={getMonitoramentos}
                pageRange={5}
              />
            }
          >
            <TableHeader>{tableHeader}</TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        )}

        {monitoramentoDto && (
          <ModalMonitoramentos
            open={Boolean(monitoramentoDto)}
            monitoramento={monitoramentoDto}
            close={(refresh) => {
              cleanMonitoramento();
              if (refresh) getMonitoramentos(monitoramentosConsult, monitoramentosData?.pageIndex)
            }}
          />
        )}

        {monitoramentosData?.totalCount <= 0 && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

formValueSelector("MonitoramentoForm");

const mapStateToProps = (state) => ({
  monitoramentosData: state.monitoramentoReducer.monitoramentos,
  monitoramentoDto:
    state.monitoramentoReducer.monitoramento?.gestaoMonitoramentoDto,
  monitoramentosConsult:
    state.monitoramentoReducer.monitoramentosConsultCurrent,
  messages: state.monitoramentoReducer.messages,
  filtrosMonitoramento: state.monitoramentoReducer.filtrosMonitoramento,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getMonitoramentos,
      getMonitoramento,
      cleanMonitoramento,
      exportMonitoramentosToExcel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(MonitoramentoList);
