/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import {
  loadCotaForm,
  revisarDados,
  validarDados,
  calcularVariacaoBenchmark,
  calcularVariacaoCota,
} from "../../../../../actions/cota/CotaAction";
import Input from "../../../../common/input/Input";
import MaskedInput from "../../../../common/input/MaskedInput";
import Button from "../../../../common/button/Button";
import Textarea from "../../../../common/input/Textarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

let CotaDetail = (props) => {
  const {
    fundo,
    cotas,
    loadCotaForm,
    revisarDados,
    validarDados,
    cotasField,
    calcularVariacaoBenchmark,
    calcularVariacaoCota,
    observacao,
    status
  } = props;

  const id = props.match.params.idCota;
  const moedaSimbolo = "R$";
  const porcentagemSimbolo = "%";

  React.useEffect(() => {
    if (id) loadCotaForm(id);
  }, []);

  function classArrow(index, type) {
    const field = cotasField[index][type];
    return field === 0 ? "animhide" : field > 0 ? "animtop" : "animdown";
  }

  const iconeWarn = (
    <>
      <div 
        data-for="msg-warn"
        className="col-xl-1 d-flex align-items-center mt-1 px-0"
        data-tip="Não é possível calcular a variação em casos que o D-1 é 0 e o valor atual é diferente de 0"
      >
        <FontAwesomeIcon color="#FFC107" icon={faExclamationTriangle} />
      </div>
      <ReactTooltip
        effect="solid"
        globalEventOff="click"
        className="tooltip-custom px-2 py-1"
        id="msg-warn"
      />
    </>
  )

  return (
    <div className="card bg-transparent border-0 px-4 shadow-none">
      {fundo && cotas && status && (
        <div className="card-body shadow-custom bg-white rounded-12 col-12 mb-3 pb-0">
          <div className="d-flex flex-row justify-content-between align-items-start">
            <div className="d-flex flex-row align-items-center">
              <h4 className="page-head__title m-0 f-36 font-weight-bold mr-3">
                {fundo.nomeDoFundo}
              </h4>
              <span
                className={`rounded-pill text-uppercase border border-darken-1 px-2 py-0 workflow-tag sts-hist-${status.key}`}
              >
                {status.value}
              </span>
            </div>
          </div>

          <div className="row px-0 mt-2">
            <div className="col-xl-2">
              <Field
                component={Input}
                inputId="cnpjDoFundo"
                name={"cnpjDoFundo"}
                label="CNPJ do Fundo"
                readOnly={true}
              />
            </div>
            <div className="col-xl-4">
              <Field
                component={Input}
                inputId="administrador"
                name={"administrador"}
                label="Administrador"
                readOnly={true}
              />
            </div>
            <div className="col-xl-2">
              <Field
                component={Input}
                inputId="dataDeReferencia"
                name="dataDeReferencia"
                label="Data de Referência"
                readOnly={true}
              />
            </div>
            <div className="col-xl-2">
              <Field
                component={Input}
                inputId="dataDeConstituicao"
                name="dataDeConstituicao"
                label="Data de Constituição"
                readOnly={true}
              />
            </div>
            <div className="col-xl-2">
              <Field
                component={Input}
                inputId="dataDeVencimento"
                name="dataDeVencimento"
                label="Data de Vencimento"
                readOnly={true}
              />
            </div>
          </div>
          <div className="row px-0">
            <div className="col-xl-2">
              <Field
                component={MaskedInput}
                name="patrimonioLiquido.valor"
                inputId="patrimonioLiquido.valor"
                label="Patrimônio Líquido"
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                readOnly={true}
                disabled={true}
              />
            </div>
            <div className="col-xl-2">
              <Field
                component={MaskedInput}
                name="patrimonioLiquido.valorAnterior"
                inputId="patrimonioLiquido.valorAnterior"
                label="Patrimônio Líquido D-1"
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                readOnly={true}
                disabled={true}
              />
            </div>
            <div className="col-xl-2">
            <Field
                component={MaskedInput}
                name="patrimonioLiquido.variacao"
                inputId="patrimonioLiquido.variacao"
                label="Variação PL"
                suffix={`${porcentagemSimbolo}`}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={4}
                fixedDecimalScale
                readOnly={true}
                disabled={true}
              />
            </div>
            <div className="col-xl-2">
              <Field
                component={Input}
                inputId="patrimonioLiquido.status.value"
                name="patrimonioLiquido.status.value"
                label="Status PL"
                readOnly={true}
                disabled={true}
              />
            </div>
          </div>
        </div>
      )}
      {/*  */}

      {fundo && cotas && (
        <>
          <div className="card-body shadow-custom bg-white rounded-12 col-12">
            <p className="p-color f-22 font-weight-bold py-2">
              Validação das Informações da Cota
            </p>

            {/* Loop */}
            {cotas.map((el, i) => {
              return (
                <div key={i} className="d-flex flex-column">
                  <p className="p-color f-16 font-weight-bold mb-0">
                    Classe {el.nomeClasse} - Cota {el.nomeCota}
                  </p>
                  <div className="row pl-xl-5 my-2">
                    <div className="col-xl-3">
                      <Field
                        component={MaskedInput}
                        name={`cotas[${i}].valorBenchmarkAnterior`}
                        inputId={`cotas[${i}].valorBenchmarkAnterior`}
                        label="Valor Benchmark D-1"
                        prefix={`${moedaSimbolo} `}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={8}
                        fixedDecimalScale
                        blur={true}
                        readOnly={true}
                        onValueChange={() => calcularVariacaoBenchmark(i)}
                      />
                    </div>

                    <div className="col-xl-3">
                      <Field
                        component={MaskedInput}
                        name={`cotas[${i}].valorBenchmark`}
                        inputId={`cotas[${i}].valorBenchmark`}
                        label="Valor Benchmark"
                        prefix={`${moedaSimbolo} `}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={8}
                        fixedDecimalScale
                        blur={true}
                        readOnly={true}
                        onValueChange={() => calcularVariacaoBenchmark(i)}
                      />
                    </div>

                    
                    <div className="col-xl-3">
                      <div className="w-100 d-flex flex-row align-items-center dt-cota">

                        {cotas[i].valorBenchmarkVariacao !== null && cotas[i].valorBenchmarkVariacao != undefined && !isNaN(cotas[i].valorBenchmarkVariacao) ? (
                          <FontAwesomeIcon
                            icon={faArrowDown}
                            className={`mt-2 mr-1 icon-arrow ${classArrow(
                              i,
                              "valorBenchmarkVariacao"
                            )}`}
                          />
                        ) : (
                          iconeWarn
                        )}

                        <Field
                          component={MaskedInput}
                          name={`cotas[${i}].valorBenchmarkVariacao`}
                          inputId={`cotas[${i}].valorBenchmarkVariacao`}
                          label="Variação Benchmark"
                          suffix={`${porcentagemSimbolo}`}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale
                          disabled={true}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row pl-xl-5 my-2">
                    <div className="col-xl-3">
                      <Field
                        component={MaskedInput}
                        name={`cotas[${i}].valorCotaAnterior`}
                        inputId={`cotas[${i}].valorCotaAnterior`}
                        label="Valor Cota D-1"
                        prefix={`${moedaSimbolo} `}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={8}
                        fixedDecimalScale
                        blur={true}
                        readOnly={true}
                        onValueChange={() => calcularVariacaoCota(i)}
                      />
                    </div>

                    <div className="col-xl-3">
                      <Field
                        component={MaskedInput}
                        name={`cotas[${i}].valorCota`}
                        inputId={`cotas[${i}].valorCota`}
                        label="Valor Cota"
                        prefix={`${moedaSimbolo} `}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={8}
                        fixedDecimalScale
                        blur={true}
                        onValueChange={() => calcularVariacaoCota(i)}
                      />
                    </div>

                    <div className="col-xl-3">
                      <div className="w-100 d-flex flex-row align-items-center dt-cota">
                        
                        {cotas[i].valorCotaVariacao !== null && cotas[i].valorCotaVariacao != undefined && !isNaN(cotas[i].valorCotaVariacao) ? (
                          <FontAwesomeIcon
                            icon={faArrowDown}
                            className={`mt-2 mr-1 icon-arrow ${classArrow(
                              i,
                              "valorCotaVariacao"
                            )}`}
                          />
                        ) : (
                          iconeWarn
                        )}

                        <div className="w-100">
                          <Field
                            component={MaskedInput}
                            name={`cotas[${i}].valorCotaVariacao`}
                            inputId={`cotas[${i}].valorCotaVariacao`}
                            label="Variação Cota"
                            suffix={`${porcentagemSimbolo}`}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale
                            disabled={true}
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="d-flex flex-column my-2">
              <p className="p-color f-16 font-weight-bold mb-2">Observações</p>
              <div className="row my-0">
                <div className="col-xl-12">
                  <Field
                    outerClass="my-0"
                    component={Textarea}
                    inputId="observacao"
                    name="observacao"
                    minHeight={7}
                    maxLength={500}
                    readOnly={status?.key == 3}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-end align-items-center mt-3">
            <Button
              name={"Validar Dados"}
              classes="scs-bg-imp bg-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mr-3"
              action={() => validarDados({ id, cotas: cotasField, observacao })}
            />
            <Button
              name={"Revisar Dados"}
              classes="err-bg-imp bg-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={() => revisarDados({ id, cotas: cotasField, observacao })}
            />
          </div>
        </>
      )}
    </div>
  );
};

CotaDetail = reduxForm({ form: "CotaDetail", destroyOnUnmount: true })(
  CotaDetail
);

const selector = formValueSelector("CotaDetail");

const mapStateToProps = (state) => ({
  fundo: state.cotaReducer.cotaFormTypes.fundo || null,
  cotas: state.cotaReducer.cotaFormTypes.cotas || [],
  status: state.cotaReducer.cotaFormTypes.status || [],
  cotasField: selector(state, "cotas"),
  observacao: selector(state, "observacao"),
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadCotaForm,
      revisarDados,
      validarDados,
      calcularVariacaoBenchmark,
      calcularVariacaoCota,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(CotaDetail);
