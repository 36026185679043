/* eslint eqeqeq: 0 */

import { initialize } from "redux-form";
import { extractFromObj } from "../../../../utils/helpers/ObjectHelper";

export function deserializeFieldsAprovadoresFormFilter(filters, page) {
    return {
        ...filters,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        }
    };
}

export function serializeFieldsAprovadoresForm(aprovador) {
    let aprovadorData = {};

    if (aprovador) {
        aprovadorData = {
            ...aprovador
        }
    }

    return initialize("aprovadoresForm", aprovadorData);
}

export function deserializeFieldsAprovadoresForm(aprovador) {
    let aprovadorData = {};

    if (aprovador) {
        aprovadorData = {
            ...aprovador,
            ativo: aprovador?.ativo || false,
            idTipoAprovacao: extractFromObj(aprovador?.idTipoAprovacao),
            idUsuario: extractFromObj(aprovador?.idUsuario),
        }
    }

    return aprovadorData;
}