import axios from "axios";

const URL_API_BLOB_FILE = process.env.REACT_APP_URL_API_BLOB_FILE;

export function uploadBlobFile(folderName, file) {
    let formData = new FormData();
    formData.append("file", file);

    return axios
        .post(`${URL_API_BLOB_FILE}saveFile?path=${folderName}`, formData);
};

export function uploadBlobMultipleFiles(folderName, files) {
    let formData = new FormData();
    
    for (let x in files) {
        formData.append("files", files[x]);
    }

    return axios
        .post(`${URL_API_BLOB_FILE}saveFiles?path=${folderName}`, formData);
};

export function downloadBlobFile(folderName, fileHash) {
    return axios
        .get(`${URL_API_BLOB_FILE}fetchFile/${fileHash}?path=${folderName}`, { responseType: 'blob' });
};

export function removeBlobFile(folderName, fileHash) {
    return axios
        .delete(`${URL_API_BLOB_FILE}removeFile/${fileHash}?path=${folderName}`);
};
