import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import { convertCurrencyNumber } from '../../../../../../utils/helpers/NumberHelper';
// import { NumberUtils } from 'rb-core/lib/Utils';

class OperacaoDistribuicao extends Component {
    render() {
        const { operacoesEmDistribuicao } = this.props;

        const tableHeaders = (
            <>
            <div className="col center-head">CÓDIGO OPEA</div>
            <div className="col center-head">APELIDO</div>
            <div className="col center-head">% INTEGRALIZADO</div>
            <div className="col center-head">DATA FINAL DE DISTRIBUIÇÃO</div>
            </>
        );

        const tableContent = (operacoesEmDistribuicao.map((item, index) =>
            (<div className="row table pt-1" key={index} style={{ borderBottom: "1px solid #ebe8e5" }}>
                <div className="col pt-3">{item.codigoOpea}</div>
                <div className="col pt-3">{item.apelidoOperacao}</div>
                <div className="col pt-3">{convertCurrencyNumber(item.percentualDistribuicao, ``, 2)}</div>
                <div className="col pt-3">{getDateFormatedFromString(item.dataFinalDistribuicao) || "-"}</div>
            </div>)
        ));

        return (
            <div className='monitor-workflow'> 
                <div className="shadow-custom rounded-12 p-3 bg-white">
                    <div className='header'>
                        <h1 className='chart-title fw-400'>Operações em distribuição</h1>
                    </div>
                    <div className="internal-aspect-ratio">
                        <div className="monitor-workflow monitor-workflow-table-field pt-1" style={{ backgroundColor: "#fff" }}>
                            <section className="monitor-workflow overflow-auto pb-1 table-data">
                                <div className="monitor-workflow row table head">
                                   {tableHeaders}
                                </div>
                                <div className="monitor-workflow table-body">
                                    {tableContent}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    operacoesEmDistribuicao: state.dashboardReducer.operacoesEmDistribuicao
});

export default withRouter(connect(mapStateToProps)(OperacaoDistribuicao));
