/* eslint eqeqeq: 0 */

import React, { Component } from 'react';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import '../assets/EventLogbookModal.css';
export default class EventLogbookModal extends Component {
    render() {
        return (
            <Dialog
                className={`logbook-modal ${this.props.className || ''}`}
                open={this.props.open}
                onClose={this.props.onClose}
                fullWidth={true}
                maxWidth={this.props.dialogSize}
                disableBackdropClick={this.props.disableBackdropClick}
                classes={{ paper: `${this.props.dialogSize ? '' : 'logbook-modal__paper'}`}}>
                <MuiDialogTitle className={this.props.titleClass ? this.props.titleClass : 'pt-3 pb-1'}>
                    <div className="row mx-0">
                        <div className="col px-0 d-flex align-items-center">
                            <Typography variant='h6'>
                                <span className='logbook-modal__title'>{this.props.title}</span>
                                <div className="logbook-modal__title logbook__card-body-header-Tertiary-info">
                                        { (this.props.subTitle && this.props.subTitle != "") ? `Autor: ${this.props.subTitle}` : ""}
                                </div>
                            </Typography>
                        </div>
                        <div>
                            <IconButton onClick={() => this.props.onClose()}>
                                <CloseIcon nativeColor='#DC3545'></CloseIcon>
                            </IconButton>
                        </div>
                    </div>
                </MuiDialogTitle>
                <MuiDialogContent className='logbook-modal__container py-0'>
                    {this.props.children}
                </MuiDialogContent>
                {this.props.footer ?
                    <MuiDialogActions>{this.props.footer}</MuiDialogActions>
                    : <></>}
            </Dialog>
        );
    }
}
