import React from "react";
import { connect } from "react-redux";
import If from "../If/If";
import "./../../../assets/css/loadingScreen.css";

const LoadingScreen = (props) => {
  return (
    <If test={props.isLoading}>
      <div
        className="d-flex flex-column h-100 w-100 justify-content-center align-items-center"
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "9999",
          backgroundColor: "#00000050",
          backdropFilter: 'blur(3px)'
        }}
      >
          <div className="loading-screen">
            <i className="fa fa-circle-o-notch" aria-hidden="true"></i>
          </div>

          {props.messageLoading && (
            <span className="rounded-pill px-3 f-20 mt-2 badge p-color-imp fw-700">{props.messageLoading}</span>
          )}
      </div>
    </If>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.loadingScreen.isLoading,
  messageLoading: state.loadingScreen.messageLoading,
});

export default connect(mapStateToProps)(LoadingScreen);
