import { 
    PRECIFICACAO_CALCULO_FETCHED,
    PRECIFICACAO_PRECO_FETCHED,
} from "../../actions/precificacao/PrecificacaoAction";

const INITIAL_STATE = {
    precificacaoCalculo: {
        remuneracao: null,
        ultimoPagamento: null,
        vencimento: null,
        proximoPagamento: null,
        pageResult: {
            lastPage: 0,
            totalCount: 0,
            hasItems: false,
            pageSize: 0,
            pageIndex: 0,
            items: [],
        }
    },
    precificacaoPreco: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    messages: null, 
    isLoading: false,
}

export default function (state = INITIAL_STATE, action = {}) {
    switch(action.type) {
        case PRECIFICACAO_CALCULO_FETCHED:
            return {
                ...state,
                precificacaoCalculo: action.payload.content,
                messages: action.payload.messages,
                isLoading: true,
            }
        case PRECIFICACAO_PRECO_FETCHED:
            return {
                ...state,
                precificacaoPreco: action.payload.content,
                messages: action.payload.messages,
                isLoading: true,
            }
        default:
            return state;
    }
}