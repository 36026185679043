import { 
    OPERACAO_OPTIONS_FETCHED,
    SELECTED_OPERACAO_OPTIONS,
    DESELECTED_OPERACAO_OPTIONS,
    MOEDA_SELECTED
} from '../../actions/operacao/OperacaoAction';


const INITIAL_STATE = { 
    selectedOperacoes: [],
    operacoes: [],
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case OPERACAO_OPTIONS_FETCHED:
            return {
                ...state,
                operacoes: action.payload
            }
        case SELECTED_OPERACAO_OPTIONS:
            return {
                ...state,
                selectedOperacoes: action.payload
            }
        case DESELECTED_OPERACAO_OPTIONS:
            return {
                ...state,
                selectedOperacoes: action.payload
            }
        case MOEDA_SELECTED:
            return {
                ...state,
                selectedMoeda: action.payload
            }
        default:
            return state
    }
}

