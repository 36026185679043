import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from "redux-form";

import Table from '../../../../../common/table/Table';
import TableHeader from '../../../../../common/table/TableHeader';
import TableContent from '../../../../../common/table/TableContent';
import TableEmptyData from '../../../../../common/table/TableEmptyData';
import PaginationRB from '../../../../../common/pagination/PaginationRB';

import { getDateFormatedFromString } from '../../../../../../utils/helpers/DateTimeHelper';
import { formatPercent, convertCurrencyNumber } from '../../../../../../utils/helpers/NumberHelper';

import {
    getAging,
} from '../../../../../../actions/precificacao/AgingAction';

const AgingList = (props) => {
    const {
        getAging,
        agingData,
        messages,
        dataBase,
        operacao,
    } = props;

    const tableHeader = (
        <tr>
            <th>Código RB</th>
            <th>Data Base</th>
            <th>Taxa de Juros</th>
            <th>Data Aniversário</th>
            <th>PMT</th>
            <th>PMT-VP</th>
            <th>Duration</th>            
        </tr>
    );

    const emptyData = messages && messages.infos && messages.infos[0];

    const tableContent = (
        agingData && agingData.hasItems
        ? agingData.items.map((item, index) =>  
                (<tr key={index}>
                    <td>{item.codigoRb}</td>
                    <td>{getDateFormatedFromString(item.dataBase)}</td>
                    <td>{formatPercent(item.taxaJuros)}</td>
                    <td>{getDateFormatedFromString(item.dataAniversario)}</td>
                    <td>{convertCurrencyNumber(item.pmt, true)}</td>
                    <td>{convertCurrencyNumber(item.pmtFinanceiro, true)}</td>
                    <td>{convertCurrencyNumber(item.duration, false)}</td>
                </tr>)
            ) 
        : <TableEmptyData message={emptyData} />
    );

    return (
        <div className='container-fluid'>
            <Table
                withoutHeader={true}
                pagination={
                    <PaginationRB
                        pageNumber={agingData.pageIndex-1}
                        itemsPerPage={agingData.pageSize}
                        totalItems={agingData.totalCount}
                        filter={{ operacao, dataBase }}
                        fetchFunction={getAging} 
                        pageRange={5}
                    />
                }
            >
                <TableHeader >
                    {tableHeader}
                </TableHeader>
                <TableContent>
                    {tableContent}
                </TableContent>
            </Table>
        </div>
    );
}

const selector = formValueSelector('agingForm');

const mapStateToProps = state => ({
    agingData: state.agingReducer.relatorioAging, 
    messages: state.agingReducer.messages, 
    dataBase: selector(state, 'dataBase'),
    operacao: selector(state, 'operacao'),
});

const mapDispacthToProps = dispatch => bindActionCreators({ 
    getAging,
}, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(AgingList);