/* eslint eqeqeq: 0 */

import React from "react";
import { useDropzone } from "react-dropzone";
import { convertFileToBase64 } from "../../../../../utils/helpers/FileHelper";
import { downloadArquivoRemessa, loadFormRemessa, removeArquivoRemessa, uploadArquivoRemessa } from "../../../../../actions/remessa/RemessaAction";
import { msgError } from "../../../../../utils/helpers/OthersHelpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const DropzoneAnexos = (props) => {
  const {
    idRemessa,
    uploadArquivoRemessa,
    removeArquivoRemessa,
    downloadArquivoRemessa,
    arquivosAnexos,
    loadFormRemessa,
    status,
  } = props;

  const INICIADO = status?.key == 1;

  const [isDraggingAnexos, setIsDraggingAnexos] = React.useState(false);

  const onDrop = async (acceptedFiles) => {
    const files = acceptedFiles;

    async function uploadFiles(files) {
      for (const file of files) {
        const data = {
          idRemessa,
          nome: file?.name?.split('.').slice(0, -1).join('.') || '',
          arquivo: await convertFileToBase64(file),
          extensao: file?.name?.split('.').pop().toLowerCase() || '',
          tipo: {
            key: 2,
            value: "Anexo",
            raw: "Anexo"
          },
        };
    
        await uploadArquivoRemessa(data, false);
      }
    }
    
    await uploadFiles(files);

    loadFormRemessa(idRemessa);

    setIsDraggingAnexos(false);
  };

  const onDelete = async (nome) => {
    await removeArquivoRemessa({
      idRemessa,
      nome
    });
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "text/xml": [".xml"],
    },
    onDragEnter: () => setIsDraggingAnexos(true),
    onDragLeave: () => setIsDraggingAnexos(false),
    onDropRejected: (rejected) => {
      const names = rejected.map((item) => item.file.name);
      names.forEach((name) => msgError(`Arquivo (${name}) inválido`));
    },
  });

  return (
    <div
      {...getRootProps()}
      className="w-100 d-flex flex-column justify-content-start align-items-center text-center position-relative p-2 rounded my-2"
      style={{
        minHeight: "100px",
        maxHeight: "200px",
        transition: "border 0.3s ease-out",
        border: isDraggingAnexos
          ? "2px dashed #630A37"
          : "2px dashed #cccccc",
      }}
    >
      <input {...getInputProps()} />
      <p className="py-2 mb-0">
          Arraste os arquivos ou{" "}
          <u className="btn-link-opea" role="button">
          clique aqui
          </u>{" "}
          para upload
      </p>
      <div className="d-flex flex-column h-100 overflow-auto w-100 p-2">
        {arquivosAnexos?.length > 0 &&
          arquivosAnexos?.map((file, i) => {
            return (
              <div
                key={`file-${i}`}
                onClick={(e) => e.stopPropagation()}
                style={{
                  backgroundColor: "#E7E7E7",
                  border: "1px solid #B8B8B8",
                }}
                className="p-2 d-flex flex-row align-items-center justify-content-between rounded fw-400 my-1"
              >
                <span>{file}</span>
                <div className="d-flex flex-row">
                  <i
                    role="button"
                    className="fa fa-cloud-download f-20 mr-3"
                    onClick={async () =>
                      await downloadArquivoRemessa({
                        idRemessa,
                        nome: file,
                      })
                    }
                  ></i>
                  
                  {INICIADO && (
                    <i
                      role="button"
                      onClick={() => onDelete(file)}
                      className="fa fa-minus-circle f-20 d-flex align-items-center mr-1"
                    ></i>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  idRemessa: state.remessaReducer.remessaForm?.id || null,
  status: state.remessaReducer.remessaForm?.status || null,
  arquivosAnexos: state.remessaReducer.remessaForm?.arquivosAnexos || [],
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadArquivoRemessa,
      removeArquivoRemessa,
      downloadArquivoRemessa,
      loadFormRemessa,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(DropzoneAnexos);
