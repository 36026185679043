/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../common/button/Button";
import { Close } from "@material-ui/icons";

import { actionRemessa } from "../../../../../actions/remessa/RemessaAction";

let ModalAction = (props) => {
  const { close, open, item, actionRemessa } = props;

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              {item?.action == "cancelar" && (
                <span>Cancelamento de Remessa</span>
              )}
              {item?.action == "importar" && (
                <span>Processamento de Pedidos de Compra</span>
              )}
              {item?.action == "reprocessar" && (
                <span>Reprocessamento de Pedidos de Compra</span>
              )}
              {item?.action == "criar" && (
                <span>Criação de Pedidos de Compra</span>
              )}
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-4">
            {item?.action == "cancelar" && (
              <>
                <p>
                  Você está prestes a prosseguir com o{" "}
                  <span className="fw-700">cancelamento</span> do{" "}
                  <span className="fw-700">arquivo de remessa</span> de despesas
                  recorrentes.
                </p>
                <p>
                  Com isso, os dados importados não gerarão novas ordens de
                  compra no módulo de{" "}
                  <span className="fw-700">Pedido de Compra. </span>{" "}
                  <span className="text-danger fw-700">
                    Esta ação é irreversível.
                  </span>
                </p>
              </>
            )}

            {item?.action == "importar" && (
              <>
                <p>
                  Você está prestes a prosseguir com o{" "}
                  <span className="fw-700">processamento</span> do{" "}
                  <span className="fw-700">Pedido de Compra</span> de despesas
                  recorrentes.
                </p>
                <p>
                  Com isso, os dados importados serão processados no módulo de{" "}
                  <span className="fw-700">Pedido de Compra. </span>{" "}
                  <span className="text-danger fw-700">
                    Esta ação é irreversível.
                  </span>
                </p>
              </>
            )}

            {item?.action == "reprocessar" && (
              <>
                <p>
                  Você está prestes a prosseguir com o{" "}
                  <span className="fw-700">reprocessamento</span> do{" "}
                  <span className="fw-700">Pedido de Compra</span> de despesas
                  recorrentes.
                </p>
                <p>
                  Com isso, os dados importados serão reprocessados no módulo de{" "}
                  <span className="fw-700">Pedido de Compra. </span>{" "}
                  <span className="text-danger fw-700">
                    Esta ação é irreversível.
                  </span>
                </p>
              </>
            )}

            {item?.action == "criar" && (
              <>
                <p>
                  Você está prestes a prosseguir com a{" "}
                  <span className="fw-700">criação</span> de{" "}
                  <span className="fw-700">Pedidos de Compra</span> de despesas
                  recorrentes.
                </p>
                <p>
                  Com isso, os dados importados gerarão novas ordens de compra
                  no módulo de{" "}
                  <span className="fw-700">Pedido de compra. </span>{" "}
                  <span className="text-danger fw-700">
                    Esta ação é irreversível.
                  </span>
                </p>
              </>
            )}

            <p className="mb-0">Tem certeza que deseja prosseguir?</p>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Não, Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Sim, Confirmar"}
              action={async () => {
                await actionRemessa(item);
                close();
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      actionRemessa,
    },
    dispatch
  );

export default connect(null, mapDispacthToProps)(ModalAction);
