import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, formValueSelector } from "redux-form";

import Button from "../../../../common/button/Button";

import Input from "../../../../common/input/Input";
import MaskedFormatInput from "../../../../common/input/MaskedFormatInput";

import {
  CPF,
  customFormatCnpjCpf,
} from "../../../../../utils/helpers/StringHelper";
import {
  getUsuariosPortal,
  cleanResult,
} from "../../../../../actions/acessoPortal/AcessoPortalAction";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import DropdownListInput from "../../../../common/input/DropdownListInput";
import {
  ACESSO_PORTAL,
  READ,
} from "../../../../../utils/actionsAndFeaturesAuth";

let AcessoPortalFilter = (props) => {
  const { email, cpf, portalUsuarioStatusValidacao, nome } = props;
  return (
    <div className="w-100 p-4 m-2">
      <div className="mt-0 page-head__title f-36 mb-4">
        Acesso Portal
      </div>
      <div className="shadow-custom rounded-12 bg-white px-3 py-2">
        <div className="row">
          <div className="col-lg-4">
            <Field
              component={Input}
              className="filter-input"
              inputId="nome"
              name="nome"
              label="Usuário"
            />
          </div>
          <div className="col-lg-4">
            <Field
              component={Input}
              className="filter-input"
              inputId="email"
              name="email"
              label="E-mail"
            />
          </div>
          <div className="col-lg-2">
            <Field
              name="cpf"
              label="CPF"
              inputId="cpf"
              maxLength="14"
              className="filter-input"
              component={MaskedFormatInput}
              placeholder={CPF.placeholder}
              formatMask={(value) => customFormatCnpjCpf(value)}
            />
          </div>
          <div className="col-lg-2">
            <Field
              component={DropdownListInput}
              inputId="portalUsuarioStatusValidacao"
              name="portalUsuarioStatusValidacao"
              label="Status Validação"
              options={props.portalUsuarioStatusValidacaoList}
              textField="value"
              valueField="key"
              filter="contains"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 ml-3">
            <div className="row">
              <div className="d-flex flex-row align-items-center">
                <Button
                  action={() =>
                    props.authorizationFunction(
                      { idAcao: READ, idFuncionalidade: ACESSO_PORTAL },
                      () =>
                        props.getUsuariosPortal({
                          email,
                          cpf,
                          portalUsuarioStatusValidacao,
                          nome,
                        })
                    )
                  }
                  classes="p-bg-imp rounded"
                  icon={"search"}
                  name={"Buscar"}
                />
                <Button
                  name={"Limpar"}
                  activeClassName={"col-lg-auto"}
                  action={() => props.cleanResult()}
                  classes="bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AcessoPortalFilter = reduxForm({
  form: "acessoPortalFilterForm",
  destroyOnUnmount: true,
})(AcessoPortalFilter);
const selector = formValueSelector("acessoPortalFilterForm");

const mapStateToProps = (state) => ({
  email: selector(state, "email") || "",
  cpf: selector(state, "cpf") || "",
  portalUsuarioStatusValidacaoList:
    state.acessoPortalReducer.filter.portalUsuarioStatusValidacaoList || [],
  portalUsuarioStatusValidacao: selector(state, "portalUsuarioStatusValidacao"),
  nome: selector(state, "nome") || "",
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsuariosPortal,
      cleanResult,
      authorizationFunction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AcessoPortalFilter);
