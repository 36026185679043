import React from "react";

import ContainerPage from "../../../../common/container/ContainerPage";
import AgingListFilter from "../../pages/aging/form/AgingListFilter";
import AgingList from "../../pages/aging/list/AgingList";

const AgingPage = () => {

    return (
        <ContainerPage>
            <AgingListFilter />
            <AgingList />
        </ContainerPage>
    );
}

export default (AgingPage);