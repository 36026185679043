import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { install } from "@material-ui/styles";

import history from './components/common/routers/history';
import App from "./App";
import configureStore from "./reducers/configureStore";
import registerServiceWorker from "./registerServiceWorker";

install();
const theme = createMuiTheme({ typography: { useNextVariants: true } });

const initialState = window.initialReduxState;
export const store = configureStore(history, initialState);

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  rootElement
);


registerServiceWorker();