import {
    LOAD_FILTER_FORM_EXPORTACAO,
    SALVA_FILTROS_EXPORTACAO,
    EXPORTACAO_FETCHED,
    EXPORTACAO_CONSULT_CURRENT,
    EXPORTACAO_RESET,
    LOAD_FORM_EXPORTACAO,
} from "../../actions/exportacao/ExportacaoAction";

const INITIAL_STATE = {
    exportacao: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    exportacaoConsultCurrent: null,
    messages: null, 
    isLoading: false,
    exportacaoFormTypesFilter: {},
    exportacaoForm: {},
}

export default function (state = INITIAL_STATE, action = {}) {    
    switch(action.type) {
        case EXPORTACAO_FETCHED:
            return {
                ...state,
                exportacao: action.payload.content,
                messages: action.payload.messages,
                isLoading: true,                
            }
        case EXPORTACAO_CONSULT_CURRENT:
            return {
                ...state,
                exportacaoConsultCurrent: action.payload,      
                isLoading: true,     
            }
        case LOAD_FILTER_FORM_EXPORTACAO:
            return {
                ...state,
                exportacaoFormTypesFilter: action.payload,
            }
        case LOAD_FORM_EXPORTACAO:
            return {
                ...state,
                exportacaoForm: action.payload,
            }
        case EXPORTACAO_RESET:
            return {
                ...INITIAL_STATE
            }
        case SALVA_FILTROS_EXPORTACAO:
            return {
                ...state,
                filtrosAprovacao: action.payload
            }
        default:
            return state;
    }
}
