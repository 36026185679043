import React, { Component } from 'react';
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';

import { getDateFormatedFromString } from '../../../../../../utils/helpers/DateTimeHelper';
import Table from '../../../../../common/table/Table';
import TableHeader from '../../../../../common/table/TableHeader';
import TableContent from '../../../../../common/table/TableContent';

const styles = theme => ({});

class PatrimonioSeriesVinculadas extends Component {
    render() {
        const { 
            classes,
            dataList,
        } = this.props;

        return(
            <div className={`table-responsive ${classes.root}`}>
                <Table
                    withoutHeader
                    classes="border-0 shadow-none col-12 my-0 pd-10 bg-white">
                    <TableHeader>
                        <tr>
                            <th className={`text-center fw-700`}>Código Opea</th>
                            <th className={`text-center fw-700`}>Código B3</th>
                            <th className={`text-center fw-700`}>Data de Emissão</th>
                            <th className={`text-center fw-700`}>Data de Vencimento</th>
                            <th className={`text-center fw-700`}>Quantidade Emitida</th>
                            <th className={`text-center fw-700`}>Data 1ª Integralização</th>
                            <th className={`text-center fw-700`}>Quantidade Integralizada</th>
                            <th className={`text-center fw-700`}>Status Série</th>
                        </tr>
                    </TableHeader>
                    <TableContent>
                        {
                            dataList.length > 0 
                            ? (
                                dataList.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className={`text-center fw-400`}>{item.codigoOpea || "--"}</td>
                                            <td className={`text-center fw-400`}>{item.codigoB3Cetip || "--"}</td>
                                            <td className={`text-center fw-400`}>{getDateFormatedFromString(item.dataEmissao) || "--"}</td>
                                            <td className={`text-center fw-400`}>{getDateFormatedFromString(item.dataVencimento) || "--"}</td>
                                            <td className={`text-center fw-400`}>{item.quantidadeEmitida || "--"}</td>
                                            <td className={`text-center fw-400`}>{getDateFormatedFromString(item.dataPrimeiraIntegralizacao) || "--"}</td>
                                            <td className={`text-center fw-400`}>{item.quantidadeIntegralizada || "--"}</td>
                                            <td className={`text-center fw-400`}>{item.descricaoStatus || "--"}</td>
                                        </tr>
                                    );
                                })
                            )
                            : (
                                <tr>
                                    <td 
                                        colSpan={12}
                                        align="center" 
                                        className={`text-center fw-400`} 
                                    >
                                        Não há vínculos
                                    </td>
                                </tr>
                            )
                        }
                    </TableContent>
                </Table>
            </div>
        );
    }
}

PatrimonioSeriesVinculadas = connect()(PatrimonioSeriesVinculadas);

export default withStyles(styles)(PatrimonioSeriesVinculadas);
