import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, formValueSelector, change, arrayRemove } from "redux-form";

import MaskedInput from "../../../../../../common/input/MaskedInput";
import CheckboxInput from "../../../../../../common/input/CheckboxInput";

import {
  atualizarListagemForm,
  onChangeAplicarPrecisao,
} from "../../../../../../../actions/operacao/PassivoAction";
import If from "../../../../../../common/If/If";

class TablePrecificacaoPrecisao extends Component {
  componentDidMount() {
    let listaUnica = new Set([]);
    const precificacaoPrecisaoList =
      this.props.precificacaoPrecisaoListForm.length > 0
        ? this.props.precificacaoPrecisaoListForm
        : this.props.precificacaoPrecisaoList;

    let ultimoIndex = precificacaoPrecisaoList.length;

    this.props.listagem.forEach((d) => {
      let indexNoForm = -1;
      precificacaoPrecisaoList.forEach((precificacaoPrecisao, index) => {
        let equal = false;

        if (precificacaoPrecisao.codigoCampo) {
          equal = precificacaoPrecisao.codigoCampo.key === d.id;
          if (
            precificacaoPrecisao.codigoCampoFormula &&
            precificacaoPrecisao.codigoCampoFormula.key
          ) {
            equal = precificacaoPrecisao.codigoCampoFormula.key === d.id;
          }

          if (equal) {
            indexNoForm = index;
            if (d.checkAplicar) precificacaoPrecisao.checkAplicar = true;
            if (d.isRowSpan) precificacaoPrecisao.isRowSpan = true;
            if (d.rowSpan) precificacaoPrecisao.rowSpan = d.rowSpan;

            precificacaoPrecisao.formula = d.formula;
            precificacaoPrecisao.desc = d.descricaoCampo;

            precificacaoPrecisao.disableFields =
              precificacaoPrecisao.checkAplicar === true &&
              precificacaoPrecisao.aplicarPrecisao === false;
            listaUnica.add(precificacaoPrecisao);
            return;
          }
        }
      });

      if (indexNoForm < 0) {
        indexNoForm = ultimoIndex++;
      }
    });

    this.props.atualizarListagemForm(listaUnica);
  }

  render() {
    return (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">{`Precificação Precisão - ${this.props.labelTable}`}</span>
          <div className="flex-column flex-fill">
            <div className="card-content table-responsive">
              <table className="table table-hover table-bordered mt-3">
                <tbody>
                  <tr>
                    <td align="center" className="font-weight-bold">
                      Aplicar
                    </td>
                    <td align="center" className="w-28 font-weight-bold">
                      Variável
                    </td>
                    <td align="center" className="w-25 font-weight-bold">
                      Elemento
                    </td>
                    <td align="center" className="font-weight-bold">
                      Casas Decimais
                    </td>
                    <td align="center" className="font-weight-bold">
                      Arrendondar
                    </td>
                  </tr>
                  {this.props.precificacaoPrecisaoListForm.map(
                    (d, indexNoForm) => {
                      return (
                        <tr key={indexNoForm}>
                          {d.checkAplicar ? (
                            <td className="table-label-checkbox text-center">
                              <Field
                                component={CheckboxInput}
                                inputId={`precificacaoPrecisaoList[${indexNoForm}].aplicarPrecisao`}
                                name={`precificacaoPrecisaoList[${indexNoForm}].aplicarPrecisao`}
                                disabled={this.props.readOnly}
                                onChange={() =>
                                  this.props.onChangeAplicarPrecisao(
                                    !`precificacaoPrecisaoList[${indexNoForm}].aplicarPrecisao`,
                                    indexNoForm
                                  )
                                }
                              />
                            </td>
                          ) : (
                            <td></td>
                          )}
                          {!d.isRowSpan ? (
                            <td rowSpan={d.rowSpan ? d.rowSpan : 1}>
                              {d.desc}
                            </td>
                          ) : null}
                          <td>{d.formula}</td>
                          <td>
                            <Field
                              component={MaskedInput}
                              name={`precificacaoPrecisaoList[${indexNoForm}].valorPrecisao`}
                              inputId={`precificacaoPrecisaoList[${indexNoForm}].valorPrecisao`}
                              maxLength={2}
                              readOnly={
                                this.props.readOnly ||
                                (d.disableFields &&
                                  !this.props.precificacaoPrecisaoListForm[
                                    indexNoForm
                                  ].aplicarPrecisao) ||
                                this.props.precificacaoPrecisaoListForm[
                                  indexNoForm
                                ].codigoCampo.key === 6
                              }
                            />
                          </td>

                          <td className="table-label-checkbox text-center">
                            <If
                              test={
                                this.props.precificacaoPrecisaoListForm[
                                  indexNoForm
                                ].codigoCampo.key !== 6
                              }
                            >
                              <Field
                                component={CheckboxInput}
                                inputId={`precificacaoPrecisaoList[${indexNoForm}].arredondamento`}
                                name={`precificacaoPrecisaoList[${indexNoForm}].arredondamento`}
                                disabled={
                                  this.props.readOnly ||
                                  (d.disableFields &&
                                    !this.props.precificacaoPrecisaoListForm[
                                      indexNoForm
                                    ].aplicarPrecisao)
                                }
                              />
                            </If>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector("passivoForm");
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { change, arrayRemove, atualizarListagemForm, onChangeAplicarPrecisao },
    dispatch
  );

const mapStateToProps = (state) => ({
  precificacaoPrecisaoList:
    state.passivoReducer.passivoFormTypes.precificacaoPrecisaoList,
  precificacaoPrecisaoListForm:
    selector(state, "precificacaoPrecisaoList") || [],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TablePrecificacaoPrecisao);
