import { toastr } from "react-redux-toastr";

/**
 * @description Retorna o formato correto do log do sistema
 * @param {String} string action.
 */
export function formatActionLog(action) {
  let ac =
    {
      Added: "Adicionado",
      Deleted: "Deletado",
      Modified: "Modificado",
    }[action] || "N/A";

  return ac;
}

export function hasNull(obj) {
  if (obj === null) return true;
  
  if (typeof obj !== 'object') return false;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === null) {
        return true;
      } else if (typeof obj[key] === 'object' && hasNull(obj[key])) {
        return true;
      }
    }
  }

  return false;
};

export function formatTypeError(type) {
  let ac =
    {
      ERROR: "Erro",
    }[type] || "N/A";

  return ac;
}

export function formatStatusPGMT(key) {
  let result =
    {
      1: "Futuro",
      2: "Disponível",
      3: "Fechado",
      4: "Validado",
      5: "Lançado",
      6: "Agendado",
      7: "Pago",
      8: "A Revisar",
      9: "Não Pago",
    }[key] || "N/A";

  return result;
}

export function formatStatusPGMTInverted(key) {
  let result =
    {
      "Futuro": 1,
      "Disponivel": 2,
      "Fechado": 3,
      "Validado": 4,
      "Lancado": 5,
      "Agendado": 6,
      "Pago": 7,
      "Revisado": 8,
    }[key] || "N/A";

  return result;
}

export function formatStatusPDD(key) {
  let result =
    {
      2: "Cadastrado",
      3: "Aprovado",
      4: "Escriturado",
      5: "Exportado",
      6: "Pago",
      7: "A Revisar",
      8: "Cancelado",
    }[key] || "N/A";

  return result;
}

export function formatStatusPDDInverted(key) {
  let result =
    {
      "Cadastrado": 2,
      "Aprovado": 3,
      "Escriturado": 4,
      "Exportado": 5,
      "Pago": 6,
      "A Revisar": 7,
      "Cancelado": 8,
    }[key] || "N/A";

  return result;
}

export function getLabelDisplayedRows ({ from, to, count }) {
  return `${from}-${to} de ${count} linhas`;
};

export function checkDocument(document) {
  function validateCPF(cpf) {
    const cleanCPF = cpf.replace(/\D/g, ''); // Remove caracteres não numéricos
    if (cleanCPF.length !== 11) return false;

    // Calcula o primeiro dígito verificador do CPF
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
    }
    let remainder = 11 - (sum % 11);
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cleanCPF.charAt(9))) return false;

    // Calcula o segundo dígito verificador do CPF
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
    }
    remainder = 11 - (sum % 11);
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cleanCPF.charAt(10))) return false;

    return true;
  }

  function validateCNPJ(cnpj) {
    const cleanCNPJ = cnpj.replace(/\D/g, ''); // Remove caracteres não numéricos
    if (cleanCNPJ.length !== 14) return false;

    const weights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let sum = 0;

    for (let i = 0; i < 12; i++) {
      sum += parseInt(cleanCNPJ.charAt(i)) * weights[i];
    }

    const remainder = sum % 11;
    const firstDigit = remainder < 2 ? 0 : 11 - remainder;

    if (firstDigit !== parseInt(cleanCNPJ.charAt(12))) return false;

    weights.unshift(6);
    sum = 0;

    for (let i = 0; i < 13; i++) {
      sum += parseInt(cleanCNPJ.charAt(i)) * weights[i];
    }

    const secondRemainder = sum % 11;
    const secondDigit = secondRemainder < 2 ? 0 : 11 - secondRemainder;

    if (secondDigit !== parseInt(cleanCNPJ.charAt(13))) return false;

    return true;
  }

  const cleanDocument = document.replace(/\D/g, ''); // Remove caracteres não numéricos

  if (cleanDocument.length === 11) {
    return validateCPF(cleanDocument);
  } else if (cleanDocument.length === 14) {
    return validateCNPJ(cleanDocument);
  } else {
    return false;
  }
}

export function msgError(msg, scrollToTop = true, trackedId = "topContent") {
  toastr.error("Erro", msg);
  if (scrollToTop) document.getElementById(trackedId).scrollTo({ top: 0, behavior: 'smooth' });
}


export function addZerosAEsquerda(value, quantidadeZeros = 10) {
  if (value.length <= 10) {
    return value.padStart(quantidadeZeros, '0');
  } else {
      return value;
  }
}

export function copiarParaArea(conteudo, mensagem = "") {
  navigator.clipboard.writeText(conteudo)
    .then(() => {
      if (mensagem) toastr.success("Sucesso", mensagem);
    })
    .catch((err) => {
      toastr.error("Erro", `Erro ao copiar para área de transferência: ${err}`);
    });
}

export function extrairCodigoSuporte(mensagem) {
  let match = mensagem.match(/informando o código\s+(.*)$/);
  return match ? match[1].replace('.', '') : null;
}