import React from 'react';
import { Route, Switch } from "react-router-dom";


import { JOBS_ROUTE_PATH } from "../../../common/routers/constantesRoutes";


import GerenciamentoJobPage from '../pages/GerenciamentoJobPage';


export default () => (
    <Switch>
        <Route exact={true} path={`/${JOBS_ROUTE_PATH}`} component={GerenciamentoJobPage} />

    </Switch>
);