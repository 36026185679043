import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import GenericPickerInput from "../../common/input/GenericPickerInput";
import { 
    getAtivosNaoVinculados,
    selectedAtivoNaoVinculadoOption, 
    addAtivoNaoVinculado 
} from "../../../actions/ativo/AtivoAction";

const AtivoNaoVinculadoPicker = (props) => (
    <div className={`col-md-${props.col}`}>
        <div className="input-group-search">
            <GenericPickerInput
                loadData={props.getAtivosNaoVinculados}
                options={props.options}
                selectedOptionForm={props.selectedAtivoNaoVinculadoOption}
                isMulti={true}
                multiControl={true}
                placeholder={"Pesquisar Ativo"}
                loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                noOptionsMessage="Ativo informado não localizado."
            />
            <span className="input-group-btn">
            </span>
        </div>
    </div>
);

const mapDispatchToProps = dispatch => bindActionCreators({
    getAtivosNaoVinculados,
    selectedAtivoNaoVinculadoOption,
    addAtivoNaoVinculado,
}, dispatch);

const mapStateToProps = state => ({
    options: state.ativoReducer.ativoNaoVinculadoOptions,
    ativosNaoVinculadosOptions: state.ativoReducer.selectedAtivoNaoVinculadoOptions
});

export default connect(mapStateToProps, mapDispatchToProps)(AtivoNaoVinculadoPicker);

