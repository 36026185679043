import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { ControlClearCache } from "./utils/controlClearCache";

import { makeStyles } from '@material-ui/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import './assets/css/demo.css';
import './assets/css/pure-css-checkbox-master.css';
import './assets/css/material-dashboard.css';

import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/roboto-fontface/css/roboto/roboto-fontface.css';
import './assets/fonts/material-icon.css';

import './assets/css/placeholder.css';
import './assets/css/custom.css';
import './assets/css/form.css';


// import Routes from './routes/Routes';
import Routes from './components/common/routers/Routes';
import Message from './components/common/messages/Message';
import LoadingScreen from './components/common/loading/LoadingScreen';
import Header from './components/common/header/Header';
const useStyles = makeStyles(theme => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    /*flexGrow: 1,
    paddingTop: '20px',
    overflowX: 'hidden'*/
    marginTop: '6em'
  },
  multi: {
    marginTop: '8em'
  }
}));

const theme = createMuiTheme({
  palette: {
    common: {
      black: "#000",
      white: "#fff"
    },
    background: {
      paper: "#fff",
      default: "#fafafa"
    },
    primary: {
      light: "rgba(110, 98, 89, 1)",
      main: "#630A37",
      dark: "#303f9f",
      contrastText: "#fff"
    },
    secondary: {
      main: "#630A37",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    }
  },
  typography: { useNextVariants: true },
  overrides: {
    MuiInput: {
      underline: {
        '&:after': { //underline color when textfield is inactive
          borderBottom: '2px solid black',
        },
      }
    }
  }
});

function extractTokenFromRoute() {
  const route = window.location.pathname;
  const regex = /precificacao\/pagamento\/af\/([^/]+)$/;
  const match = route.match(regex);

  return match ? match[1] : null;
}

const Main = (props) => {
  let multiplasContas = props.credentials.contas.length > 1;
  
  const classes = useStyles();
  ControlClearCache()

  // AF EXTERNO
  const queryToken = new URLSearchParams(window.location.search).get("token");
  const tokenRoute = extractTokenFromRoute();

  if (queryToken || tokenRoute) localStorage.clear();
  
  return (
    <Router>
      <div className="wrapper d-flex">
          <MuiThemeProvider theme={theme}>
            <Header credentials={props.credentials} />
            <main className={`w-100 bg-df-opea ${classes.content} ${multiplasContas ? classes.multi : ''}`}>
              <Routes />
            </main>
            <Message />
            <LoadingScreen />
            <ReactTooltip effect="solid" className="tooltip-custom" globalEventOff="click" />
          </MuiThemeProvider>
      </div>
    </Router>
  )
}

export default Main;