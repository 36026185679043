/* eslint eqeqeq: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */
/* react/jsx-no-target-blank: 0 */

import React, { Component } from "react";
import { change } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Collapse, Fade, ButtonBase } from '@material-ui/core';
import moment from "moment";
import 'moment/locale/pt-br';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import {
    showModalGenerico,
    editEventoDiarioDeBordo,
    showEventoDiarioBordoDeleteDialog,
    removerEventoDiarioDeBordo

} from '../../../../../../actions/gestao/DiarioBordoAction';

import FooterCardDiarioBordo from "./FooterCardDiarioBordo";
import CadastroEventosDiarioBordoForm from "../form/CadastroEventosDiarioBordoForm";
import UploadArquivosDiarioBordoForm from "../form/UploadArquivosDiarioBordoForm";
import EventLogbookModal from '../modal/EventLogbookModal';
import "../assets/EventoDiarioBordoCard.css";
import If from "../../../../../common/If/If";
import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import { authorizationFunction } from '../../../../../../actions/authorization/AuthorizationAction';
import { DIARIO_DE_BORDO, UPDATE, DELETE, READ } from '../../../../../../utils/actionsAndFeaturesAuth';
import ModalConfirm from "../modal/ModalConfirm";

class EventoDiarioBordoCard extends Component {
    constructor(props) {
        super(props);
        this.state = { isVisible: true };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState(state => ({
            isVisible: !state.isVisible
        }));
    }

    getEventTitle(justView, titulo) {
        let title = `Editar evento - ${titulo}`;

        if (justView)
            title = titulo;

        return title;
    }

    tratarLimiteDescricao(texto) {
        const limiteTotal = 160
        return (texto && texto.length >= limiteTotal) ? `${texto.substr(0, limiteTotal)}...` : texto
    }

    render() {
        const { isVisible } = this.state;
        const {
            modalGeral,
            showModalGenerico,
            editEventoDiarioDeBordo,
            showEventoDialog,
            showEventoDiarioBordoDeleteDialog,
            authorizationFunction,
        } = this.props;

        const usuarioAlteracao = (this.props.diarioBordoAtual.usuarioAlteracao && this.props.diarioBordoAtual.usuarioAlteracao != "") ?
            this.props.diarioBordoAtual.usuarioAlteracao :
            "GERADO AUTOMATICAMENTE PELO SISTEMA";

        let renderViewCard = (
            <div className="d-flex w-100 h-100">
                <ButtonBase className="p-0 text-white d-flex w-100 justify-content-center align-items-center"
                    onClick={
                        () => authorizationFunction(
                            { idAcao: READ, idFuncionalidade: DIARIO_DE_BORDO },
                            () => showModalGenerico(true, false, this.props.diarioBordoAtual.id, true)
                        )}>
                    <i className="fa fa-eye" style={{ fontSize: '1.5em' }}></i>
                </ButtonBase>
            </div>
        );

        let renderEditCard = (
            <div className="d-flex w-100 h-100">
                <ButtonBase className="p-0 text-white d-flex w-100 justify-content-center align-items-center"
                    onClick={
                        () => authorizationFunction(
                            { idAcao: UPDATE, idFuncionalidade: DIARIO_DE_BORDO },
                            () => showModalGenerico(true, false, this.props.diarioBordoAtual.id)
                        )
                    }>
                    <i className="fa fa-pencil-square-o" style={{ fontSize: '1.5em' }}></i>
                </ButtonBase>
                <EventLogbookModal
                    title={this.getEventTitle(modalGeral.justView, this.props.diarioBordoAtual.titulo)}
                    subTitle={`${usuarioAlteracao}, ${getDateFormatedFromString(this.props.diarioBordoAtual.dataInclusao)}`}
                    open={modalGeral.showDialogCadastro ? modalGeral.showDialogId === this.props.diarioBordoAtual.id : false}
                    onClose={() => showModalGenerico(false, false, this.props.diarioBordoAtual.id, modalGeral.justView)}>
                    <CadastroEventosDiarioBordoForm
                        idOperacao={this.props.diarioBordoAtual.idOperacao}
                        diarioBordoAtual={this.props.diarioBordoAtual}
                        onSubmit={editEventoDiarioDeBordo}
                        isEdit={true}
                        justView={modalGeral.justView}
                        onCancel={() => showModalGenerico(false, false, this.props.diarioBordoAtual.id, modalGeral.justView)}
                    />
                </EventLogbookModal>
            </div>
        );

        let renderLink = (
            <a href={this.props.diarioBordoAtual.link}
                className="view pointer text-white d-flex w-100"
                data-toggle="tooltip"
                data-placement="top"
                data-original-title="Visualizar"
                container="body"
                target="_blank"
                rel="noopener noreferrer">
                <ButtonBase className="d-flex w-100 align-items-center justify-content-start">
                    <i className="fa fa-link px-2" aria-hidden="true">&nbsp;<span style={{ fontFamily: "sans-serif", fontSize: "0.9em" }}>Link do evento</span></i>
                </ButtonBase>
            </a>
        );

        let renderDelete = (
            <div className="d-flex w-100 h-100">
                <ButtonBase className="p-0 text-white d-flex w-100 justify-content-center align-items-center" onClick={() => showEventoDiarioBordoDeleteDialog(true, this.props.diarioBordoAtual.id)}>
                    <i className="fa fa-trash" style={{ fontSize: '1.5em' }}></i>
                </ButtonBase>
                <ModalConfirm
                    open={showEventoDialog.showDialog ? showEventoDialog.idEventoDiarioBordo === this.props.diarioBordoAtual.id : false}
                    close={() => showEventoDiarioBordoDeleteDialog(false, 0)}
                    confirm={() => {
                        authorizationFunction(
                            { idAcao: DELETE, idFuncionalidade: DIARIO_DE_BORDO },
                            () => this.props.removerEventoDiarioDeBordo(this.props.showEventoDialog.idEventoDiarioBordo)
                        )
                    }}
                />
            </div>
        );

        let renderFilesCard = (
            <div className="d-flex w-100 h-100">
                <ButtonBase className="p-0 text-white d-flex w-100 justify-content-center align-items-center" onClick={() => showModalGenerico(false, true, this.props.diarioBordoAtual.id)}>
                    <i className="fa fa-files-o" style={{ fontSize: '1.5em' }}></i>
                </ButtonBase>
                <EventLogbookModal
                    title={'Arquivo - ' + this.props.diarioBordoAtual.titulo}
                    subTitle={`${usuarioAlteracao}, ${getDateFormatedFromString(this.props.diarioBordoAtual.dataInclusao)}`}
                    open={modalGeral.showDialogUploadImg ? modalGeral.showDialogId === this.props.diarioBordoAtual.id : false}
                    onClose={() => showModalGenerico(false, false, this.props.diarioBordoAtual.id)}>
                    <If test={this.props.diarioBordoAtual.diarioBordoAnexoList.length == 0}>
                        <p className="my-4">Nenhum arquivo anexado.</p>
                    </If>

                    <If test={this.props.diarioBordoAtual.diarioBordoAnexoList.length > 0}>
                        <UploadArquivosDiarioBordoForm
                            diarioBordoAnexoList={this.props.diarioBordoAtual.diarioBordoAnexoList} />
                    </If>
                </EventLogbookModal>
            </div>
        );

        let EventoDiarioBordo = (
            <div className="logbook__card-field">
                <div className="logbook__card-body" key={this.props.diarioBordoAtual.id}>
                    <ButtonBase onClick={this.handleClick} className="w-100">
                        <div className="logbook__card-body-header p-2 w-100">
                            <div className="d-flex">
                                <div className="col text-left pr-0 pl-1 logbook__card-header-contents">
                                    <div className="row mx-0">
                                        <div className="col px-0 logbook__card-body-header-secondary-info">
                                            {moment(this.props.diarioBordoAtual.dataOcorrencia).format("DD/MM/YYYY")}
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <Tooltip TransitionComponent={Zoom} title={`${this.props.diarioBordoAtual.codigoOpea} - ${this.props.diarioBordoAtual.tipoEventoDiarioBordo}`} placement="bottom-start" className="mr-4">
                                            <div className="col px-0 logbook__card-body-header-secondary-info logbook__card-body-header-title">
                                                {this.props.diarioBordoAtual.codigoOpea} - {this.props.diarioBordoAtual.tipoEventoDiarioBordo}
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="row mx-0">
                                        <Tooltip TransitionComponent={Zoom} title={this.props.diarioBordoAtual.titulo} placement="bottom-start" className="mr-4">
                                            <div className="col px-0 logbook__card-body-header-title">
                                                {this.props.diarioBordoAtual.titulo}
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center px-1">
                                    {isVisible ? <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i cclassNamelass="fa fa-chevron-down" aria-hidden="true"></i>}
                                </div>
                            </div>
                        </div>
                    </ButtonBase>
                    <Collapse in={isVisible} timeout={500}>
                        <Fade in={isVisible} timeout={500}>
                            <section>
                                <div className={isVisible ? "logbook__card-body-content p-2" : "logbook__card-body-content p-2 hide"}>
                                    <small style={{ wordBreak: "break-all" }}>{this.tratarLimiteDescricao(this.props.diarioBordoAtual.descricao)}</small>
                                </div>


                                <FooterCardDiarioBordo
                                    color={this.props.diarioBordoAtual.tipoEventoDiarioBordoCorHex}
                                    tipo={this.props.diarioBordoAtual.tipoEventoDiarioBordo}
                                    link={renderLink}
                                    view={renderViewCard}
                                    edit={renderEditCard}
                                    filesList={renderFilesCard}
                                    delete={renderDelete}
                                    status={isVisible}
                                    idDiarioBordo={this.props.diarioBordoAtual.id}

                                ></FooterCardDiarioBordo>
                            </section>
                        </Fade>
                    </Collapse>
                    <Collapse in={!isVisible} timeout={500}>
                        <div style={{ borderBottom: `8px solid ${this.props.diarioBordoAtual.tipoEventoDiarioBordoCorHex}` }}></div>
                    </Collapse>
                </div>

            </div>
        );
        return (
            <div>
                {EventoDiarioBordo}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        showModalGenerico,
        editEventoDiarioDeBordo,
        change,
        showEventoDiarioBordoDeleteDialog,
        removerEventoDiarioDeBordo,
        authorizationFunction
    }, dispatch);

const mapStateToProps = state => ({
    modalGeral: state.diarioBordoReducer.showModalDialogGenerico,
    showEventoDialog: state.diarioBordoReducer.showEventoDiarioBordoDeleteDialog
});


export default connect(mapStateToProps, mapDispatchToProps)(EventoDiarioBordoCard);
