/* eslint eqeqeq: 0 */

import { initialize } from 'redux-form';

import { multiply } from "mathjs";
import { getArrayDeserialized } from './../../../../utils/helpers/ArrayHelper';
import {
    getStringBooleanValue,
    getBooleanValueFromString,
} from "./../../../../utils/helpers/BooleanHelper";

import {
    convertCurrencyToNumber,
    formatPercentToNumber,
} from "./../../../../utils/helpers/NumberHelper";
import { calcularTotalDistribuido } from './../../../../actions/operacao/PassivoAction';
import { listItems } from '../../../../actions/common/Pagination';
import { extractFromObj } from '../../../../utils/helpers/ObjectHelper';
import { getDateFormatedFromString } from '../../../../utils/helpers/DateTimeHelper';

const PAGAMENTO_JUROS_PARCIAL = 6;
const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");
const gestora = currEnv == process.env.REACT_APP_ENV_GEST_ID;

export function serializeFieldsPassivoForm(content, patrimonio) {
    const { passivoOperacao: passivo, selos } = content
    let passivoOperacao = {};

    const seloPassivoOperacoes = passivo?.seloPassivoOperacoes.map(item => {
      return selos.find(selo => selo.id === item.idSelo);
    });

    if (passivo) {
        let calcTotalEmitido = 0;
        let calcTotalDistribuido = 0;

        if (passivo.quantidadeEmitida && passivo.precoUnitario) calcTotalEmitido = multiply(passivo.quantidadeEmitida, passivo.precoUnitario);

        if (passivo.pagamentoPassivo.distribuicaoList)
            calcTotalDistribuido = calcularTotalDistribuido(passivo.pagamentoPassivo.distribuicaoList);
        
        const contasArrecadadora = passivo?.contaArrecadadoraList.map((item) => ({
          ...item,
          contaArrecadadoraOrigemRecurso: item?.contaArrecadadoraOrigemRecurso.map((subItem) => ({ id: subItem.idOrigemRecurso }))
        }));

        const aprovadores = passivo?.aprovacao?.aprovadores;

        function filtraAreaAtuacao(areaAtuacao) {
            const res = aprovadores?.find(item => item.idAreaAtuacao === areaAtuacao);
        
            return res
                ? {
                    nome: res.aprovador.nome,
                    status: res.statusAprovacao,
                    dataResposta: res.dataAprovacao ? getDateFormatedFromString(res.dataAprovacao) : null,
                    observacao: res.observacao,
                }
                : null;
        }

        const aprovacoes = passivo?.aprovacoes;

        function mapearAprovacoes(descricao) {
            return ({
              data: aprovacoes?.filter((apr) => apr?.descricao === descricao).map((apr) => getDateFormatedFromString(apr?.dataSolicitacao)),
              responsavel: aprovacoes?.filter((apr) => apr?.descricao === descricao).map((apr) => apr?.solicitante?.nome),
            })
        };

        const ativacaoInfo = mapearAprovacoes("Ativação");
        const aprovacaoInfo = mapearAprovacoes("Aprovação");

        passivoOperacao = {
            ...passivo,
            ...patrimonio,
            id: passivo.id,
            estruturadorAprovacao: filtraAreaAtuacao(3),
            gestorAprovacao: filtraAreaAtuacao(4),
            contaArrecadadoraList: contasArrecadadora || [{}],
            seloPassivoOperacoes: seloPassivoOperacoes,
            // CONTROLE
            produtoPassivo: { key: patrimonio?.idProduto, id: patrimonio?.idProduto, value: patrimonio?.idProduto, raw: patrimonio?.idProduto },
            usuario: { id: patrimonio?.idUsuarioGestao, value: patrimonio?.idUsuarioGestao, raw: patrimonio?.idUsuarioGestao },
            usuarioServicoCredito: { id: patrimonio?.idUsuarioServicoCredito, value: patrimonio?.idUsuarioServicoCredito, raw: patrimonio?.idUsuarioServicoCredito },
            // CARACTERISTICAS
            emissor: { id: patrimonio?.idEmissor, value: patrimonio?.idEmissor, raw: patrimonio?.idEmissor },
            naturezaOperacao: { id: patrimonio?.idNaturezaOperacao, value: patrimonio?.idNaturezaOperacao, raw: patrimonio?.idNaturezaOperacao },
            segmento: patrimonio?.segmento,
            regimeFiduciario: getStringBooleanValue(passivo.regimeFiduciario) || null,
            // ----------------
            patrimonioSeparado: { id: patrimonio?.id },
            // 
            revolvencia: getStringBooleanValue(passivo.revolvencia) || null,
            existenciaPrePagamento: getStringBooleanValue(passivo.existenciaPrePagamento) || null,
            existenciaPremioPrePagamento: getStringBooleanValue(passivo.existenciaPremioPrePagamento) || null,
            opcaoResgateSecuritizadora: getStringBooleanValue(passivo.opcaoResgateSecuritizadora) || null,
            pagamentoPassivo: serializeFieldsPagamentoPassivo(passivo.pagamentoPassivo),
            operacao: {
                ...passivo.operacao,
                operacaoRegistro: {
                    ...passivo.operacao.operacaoRegistro,
                    enviarRegistroCvm: getStringBooleanValue(passivo?.operacao?.operacaoRegistro?.enviarRegistroCvm),
                    temAnuncioDistribuicao: getStringBooleanValue(passivo?.operacao?.operacaoRegistro?.temAnuncioDistribuicao),
                    temCoobrigacaoEmissora: getStringBooleanValue(passivo?.operacao?.operacaoRegistro?.temCoobrigacaoEmissora),
                    temFaculdade3Art7: getStringBooleanValue(passivo?.operacao?.operacaoRegistro?.temFaculdade3Art7),
                    temGarantiaFlutuante: getStringBooleanValue(passivo?.operacao?.operacaoRegistro?.temGarantiaFlutuante),
                    temPrevisaoIncorporarJuros: getStringBooleanValue(passivo?.operacao?.operacaoRegistro?.temPrevisaoIncorporarJuros),
                    temRating: getStringBooleanValue(passivo?.operacao?.operacaoRegistro?.temRating),
                    temRegistroCvm: getStringBooleanValue(passivo?.operacao?.operacaoRegistro?.temRegistroCvm),
                }
            },
            precificacaoPrecisaoList: passivo.operacao.precificacaoPrecisaoList,
            tipoPagamentoOriginal: passivo.pagamentoPassivo ? passivo.pagamentoPassivo.tipoPagamentoPassivo : null,
            precificacaoPrecisaoOriginal: passivo.operacao.precificacaoPrecisaoList,
            totalEmitido: calcTotalEmitido,
            totalDistribuido: calcTotalDistribuido,
            curvaPagamentoToEdit: {
                perda: 0.0,
                amex: 0.0,
                premio: 0.0
            },
            lastNewId: 0,
            curvaPaggingInfo: {
                pageIndex: 0,
                pageSize: 10,
                pageRange: 5,
                totalCount: passivo.pagamentoPassivo.curvaPagamentoPassivoList.length,
                items: listItems(passivo.pagamentoPassivo.curvaPagamentoPassivoList, 1, 10)
            },
            agendaRatingsList: passivo.agendaRatings,
            cedoc: passivo.cedoc,
            // GESTORA -----------------------------
            possuiMultiGestao: typeof passivo.possuiMultiGestao == "boolean" ? (passivo.possuiMultiGestao ? "Sim" : "Não") : null,
            // 
            dataAtivacao: ativacaoInfo.data,
            responsavelAtivacao: ativacaoInfo.responsavel,
            dataAprovacaoCadastro: aprovacaoInfo.data,
            responsavelCadastro: aprovacaoInfo.responsavel,
        }
    } else {
        const seloPassivoOperacoes = selos.filter((selo) => selo?.sigla?.toLowerCase() === "atena");

        let contaArrecadadoraTipo = gestora ? [] : [{ contaArrecadadoraOrigemRecurso: []}];

        passivoOperacao = {
            contaArrecadadoraList: contaArrecadadoraTipo,
            premioPrePagamentoList: [],
            pagamentoPassivo: {
                taxaJurosPagamentoList: gestora ? [] : [{}],
                curvaPagamentoPassivoList: [],
                distribuicaoList: [{}],
            },
            seloPassivoOperacoes: seloPassivoOperacoes || [],
            statusOperacaoPassivo: {
                key: 5,
                value: "Estruturação",
                raw: "Estruturacao"
            },
            precificacaoPrecisaoList: [],
            curvaPagamentoToEdit: {
                perda: 0.0,
                amex: 0.0,
                premio: 0.0
            },
            lastNewId: 0,
            curvaPaggingInfo: {
                pageIndex: 0,
                pageSize: 10,
                pageRange: 5,
                totalCount: 0,
                items: []
            },
            ratingAgenciaList: [],
            agenciaList: []
        };
    }

    return initialize("passivoForm", passivoOperacao);
}

export function serializeBoolsOperacaoRegistro(operacaoRegistro) {
    let operacaoRegistroSerialized = {};

    if (operacaoRegistro) {
        operacaoRegistroSerialized = {
            enviarRegistroCvm: getBooleanValueFromString(operacaoRegistro?.enviarRegistroCvm),
            temAnuncioDistribuicao: getBooleanValueFromString(operacaoRegistro?.temAnuncioDistribuicao),
            temCoobrigacaoEmissora: getBooleanValueFromString(operacaoRegistro?.temCoobrigacaoEmissora),
            temFaculdade3Art7: getBooleanValueFromString(operacaoRegistro?.temFaculdade3Art7),
            temGarantiaFlutuante: getBooleanValueFromString(operacaoRegistro?.temGarantiaFlutuante),
            temPrevisaoIncorporarJuros: getBooleanValueFromString(operacaoRegistro?.temPrevisaoIncorporarJuros),
            temRating: getBooleanValueFromString(operacaoRegistro?.temRating),
            temRegistroCvm: getBooleanValueFromString(operacaoRegistro?.temRegistroCvm),
        }
    }

    return operacaoRegistroSerialized;
}

export function serializeFieldsPagamentoPassivo(pagamentoPassivo) {
    let pagamentoPassivoSerialized = {};

    if (!pagamentoPassivo.pagamentoPassivoSla) {
        pagamentoPassivo.pagamentoPassivoSla = {
            prazoDisponibilizarEvento: 5,
            prazoStatusDisponivel: 3,
            formatoStatusDisponivel: { key: 1, value: "Dias Úteis", raw: "Dias_Uteis" },
            prazoStatusFechado: 2,
            formatoStatusFechado: { key: 1, value: "Dias Úteis", raw: "Dias_Uteis" },
            prazoStatusRevisado: 2,
            formatoStatusRevisado: { key: 1, value: "Dias Úteis", raw: "Dias_Uteis" },
            prazoStatusValidado: 1,
            formatoStatusValidado: { key: 1, value: "Dias Úteis", raw: "Dias_Uteis" },
            prazoStatusLancado: 1,
            formatoStatusLancado: { key: 1, value: "Dias Úteis", raw: "Dias_Uteis" },
            prazoStatusAgendado: 1,
            formatoStatusAgendado: { key: 1, value: "Dias Úteis", raw: "Dias_Uteis" },
        };
      }

    if (pagamentoPassivo) {
        pagamentoPassivoSerialized = {
            ...pagamentoPassivo,
            diaReferenciaAtualizacao: pagamentoPassivo.diaReferenciaAtualizacao,
            incrementoDias: pagamentoPassivo.incrementoDias,
            dataPrimeiraAtualizacao: pagamentoPassivo.dataPrimeiraAtualizacao,
            possuiResiduoInflacionario: getStringBooleanValue(pagamentoPassivo.possuiResiduoInflacionario) || null,
            carenciaJuros: getStringBooleanValue(pagamentoPassivo.carenciaJuros) || null,
            taxaJurosPagamentoList: pagamentoPassivo.taxaJurosPagamentoList.map(taxa => ({
                ...taxa,
                defasagemTaxaIndexador: { textField: taxa?.defasagemTaxaIndexador?.toString() || "", valueField: taxa?.defasagemTaxaIndexador?.toString() || "" },
                diaReferenciaTR: { textField: taxa.diaReferenciaTR, valueField: taxa.diaReferenciaTR },
                somenteVariacaoPositiva: getStringBooleanValue(taxa.somenteVariacaoPositiva) || null,
                mesAtualizacaoMonentariaNovoList: taxa.mesAtualizacaoMonentariaList ? taxa.mesAtualizacaoMonentariaList.map(mesAtualizacao => ({
                    ...mesAtualizacao
                })) : [],
                mesAtualizacaoMonentariaList: taxa.mesAtualizacaoMonentariaList ? serializeMesAtualizacaoMonentariaList(taxa.mesAtualizacaoMonentariaList) : null,
            })),
            curvaPagamentoPassivoList: pagamentoPassivo.curvaPagamentoPassivoList ? pagamentoPassivo.curvaPagamentoPassivoList.map(m => ({
                ...m, enableField: (m.pagamentoJuros && m.pagamentoJuros.key === PAGAMENTO_JUROS_PARCIAL) ? true : false
            })) : [],
            curvaPagamentoPassivoRemodivaList: [],
        }
    } else {
        pagamentoPassivoSerialized = {
            taxaJurosPagamentoList: [],
            curvaPagamentoPassivoList: [],
            distribuicaoList: [],
        }
    }

    return pagamentoPassivoSerialized;
}

function verificaObjetoParticipante(obj) {
    return obj?.idTipoServicoPrestado ? obj : null; 
}

function verificaObj(obj) {
    if (Object.keys(obj).length === 0) {
      return null;
    }
  
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && (obj[key] === null || obj[key] === undefined)) {
        return null;
      }
    }

    return obj;
}

export function deserializeFieldsPassivoForm(passivo) {
    let listaPrecificacaoPrecisao = [];
    getArrayDeserialized(passivo.precificacaoPrecisaoList).forEach(pp => listaPrecificacaoPrecisao.push(pp));

    const curvas = getArrayDeserialized(passivo.pagamentoPassivo.curvaPagamentoPassivoList);
    const curvasRemovidas = getArrayDeserialized(passivo.pagamentoPassivo.curvaPagamentoPassivoRemodivaList);

    let curvaPagamentoPassivoRemodivaList = curvasRemovidas.filter(function (cr) {
        return !curvas.some(function (c) {
            return cr.dataEvento === c.dataEvento;
        });
    });

    let diaReferenciaAtualizacao = null;
    let incrementoDias = null;
    let dataPrimeiraAtualizacao = null;
    if (passivo.pagamentoPassivo &&
        passivo.pagamentoPassivo.periodicidadeFrequenciaJuros &&
        passivo.pagamentoPassivo.periodicidadeFrequenciaJuros.id !== 1 &&
        passivo.pagamentoPassivo.tipoPagamentoPassivo &&
        passivo.pagamentoPassivo.tipoPagamentoPassivo.key === 3) {
        diaReferenciaAtualizacao = passivo.pagamentoPassivo.diaReferenciaAtualizacao === '0' ? null : passivo.pagamentoPassivo.diaReferenciaAtualizacao;
        incrementoDias = passivo.pagamentoPassivo.incrementoDias ? passivo.pagamentoPassivo.incrementoDias : 0;
        dataPrimeiraAtualizacao = passivo.pagamentoPassivo.dataPrimeiraAtualizacao;
    }

    let passivoProdutoPassivo = { id: passivo.produtoPassivo?.key, value: passivo.produtoPassivo?.key, raw: passivo.produtoPassivo?.key };
    let passivoConcentracao = { id: passivo.concentracao?.key, value: passivo.concentracao?.key, raw: passivo.concentracao?.key };

    const contasArrecadadora = passivo?.contaArrecadadoraList.map((item) => ({
      ...item,
      contaArrecadadoraOrigemRecurso: item?.contaArrecadadoraOrigemRecurso.map((subItem) => ({ idOrigemRecurso: subItem.id }))
    }));

    const sec =  {
        ...passivo,
        moeda: { id: passivo?.moeda?.id || null },
        contaArrecadadoraList: contasArrecadadora || [],
        precificacaoPrecisaoList: listaPrecificacaoPrecisao,
        premioPrePagamentoList: getArrayDeserialized(passivo.premioPrePagamentoList).map(premio => ({
            ...premio,
            percentual: formatPercentToNumber(premio.percentual)
        })),
        precoUnitario: convertCurrencyToNumber(passivo.precoUnitario),
        valorGlobalSerie: convertCurrencyToNumber(passivo.valorGlobalSerie),
        regimeFiduciario: getBooleanValueFromString(passivo.regimeFiduciario),
        existenciaPrePagamento: getBooleanValueFromString(passivo.existenciaPrePagamento),
        existenciaPremioPrePagamento: getBooleanValueFromString(passivo.existenciaPremioPrePagamento),
        opcaoResgateSecuritizadora: getBooleanValueFromString(passivo.opcaoResgateSecuritizadora),
        revolvencia: getBooleanValueFromString(passivo.revolvencia),
        pagamentoPassivo: passivo.pagamentoPassivo && {
            ...passivo.pagamentoPassivo,
            diaReferenciaAtualizacao: diaReferenciaAtualizacao?.valueField || diaReferenciaAtualizacao || null,
            incrementoDias: incrementoDias?.valueField !== undefined ? incrementoDias.valueField : incrementoDias === 0 ? 0 : (incrementoDias ? incrementoDias : null),
            dataPrimeiraAtualizacao: dataPrimeiraAtualizacao,
            possuiResiduoInflacionario: getBooleanValueFromString(passivo.pagamentoPassivo.possuiResiduoInflacionario),
            carenciaJuros: getBooleanValueFromString(passivo.pagamentoPassivo.carenciaJuros),
            taxaJurosPagamentoList: getArrayDeserialized(passivo.pagamentoPassivo.taxaJurosPagamentoList).map(taxa => ({
                ...taxa,
                taxaJuros: formatPercentToNumber(taxa.taxaJuros),
                percentualFlutuante: formatPercentToNumber(taxa.percentualFlutuante),
                defasagemTaxaIndexador: taxa.defasagemTaxaIndexador ? taxa.defasagemTaxaIndexador.valueField : "",
                diaReferenciaTR: taxa.diaReferenciaTR ? taxa.diaReferenciaTR.valueField : null,
                somenteVariacaoPositiva: getBooleanValueFromString(taxa.somenteVariacaoPositiva),
                idPeriodicidadeCorrecaoMonetaria: taxa.periodicidadeCorrecaoMonetaria ? taxa.periodicidadeCorrecaoMonetaria.id : null,
                mesAtualizacaoMonentariaList: taxa.mesAtualizacaoMonentariaNovoList ? taxa.mesAtualizacaoMonentariaNovoList : null
            })),
            curvaPagamentoPassivoList: curvas.map(curva => ({
                ...curva,
                percentualPagamento: formatPercentToNumber(curva.percentualPagamento),
                percentualPagamentoAmortizacao: formatPercentToNumber(curva.percentualPagamentoAmortizacao),
                amex: convertCurrencyToNumber(curva.amex),
                perda: convertCurrencyToNumber(curva.perda),
                premio: convertCurrencyToNumber(curva.premio),
                id: curva.id < 0 ? 0 : curva.id
            })),
            curvaPagamentoPassivoRemodivaList: curvaPagamentoPassivoRemodivaList.map(curvaExcluida => ({
                ...curvaExcluida
            })),
            integralizacaoList: [],
            distribuicaoList: getArrayDeserialized(passivo.pagamentoPassivo.distribuicaoList).map(item => ({
                ...item,
                idCoordenador: extractFromObj(item?.idCoordenador, "idEmpresaPrestadora"),
                subscricaoList: getArrayDeserialized(item?.subscricaoList).map((itemSub) => ({
                    ...itemSub,
                    integralizacaoList: getArrayDeserialized(itemSub?.integralizacaoList).map((itemInteg) => ({
                        ...itemInteg,
                        percentualFlutuante: formatPercentToNumber(itemInteg.percentualFlutuante),
                        precoUnitario: convertCurrencyToNumber(itemInteg.precoUnitario),
                        taxaVenda: formatPercentToNumber(itemInteg.taxaVenda),
                    }))
                }))
            }))
        },
        possibilidadePrePagamento: passivo.possibilidadePrePagamento || null,
        seloPassivoOperacoes: passivo.seloPassivoOperacoes.map((el) => ({ idSelo: el?.id })),
        produtoPassivo: verificaObj(passivoProdutoPassivo),
        concentracao: verificaObj(passivoConcentracao),
        possuiMultiGestao: !passivo.possuiMultiGestao ? null : passivo.possuiMultiGestao == "Não" ? false : true,
        operacao: {
          ...passivo?.operacao,
          operacaoRegistro: {
              ...passivo?.operacao.operacaoRegistro,
              ...serializeBoolsOperacaoRegistro(passivo?.operacao?.operacaoRegistro),

              idAgenciaRating: passivo?.operacao?.operacaoRegistro?.idAgenciaRating?.id || passivo?.operacao?.operacaoRegistro?.idAgenciaRating || null,
              idRatingAgencia: passivo?.operacao?.operacaoRegistro?.idRatingAgencia?.id || passivo?.operacao?.operacaoRegistro?.idRatingAgencia || null,
          },
          idSubClasse: extractFromObj(passivo?.operacao?.idSubClasse),
          subClasse: {
            ...passivo?.operacao?.subClasse,
            idClasse: extractFromObj(passivo?.operacao?.subClasse?.idClasse),
            idFundo: extractFromObj(passivo?.operacao?.subClasse?.idFundo),
          },
        },
        // PARTICIPANTES
        administradorFiduciario: verificaObjetoParticipante(passivo.administradorFiduciario),
        gestora: verificaObjetoParticipante(passivo.gestora),
        gestoraII: verificaObjetoParticipante(passivo.gestoraII),
        consultorCredito: verificaObjetoParticipante(passivo.consultorCredito),
        controladoria: verificaObjetoParticipante(passivo.controladoria),
        distribuidor: verificaObjetoParticipante(passivo.distribuidor),
        escriturador: verificaObjetoParticipante(passivo.escriturador),
        custodiante: verificaObjetoParticipante(passivo.custodiante),
        agenteMonitoramento: verificaObjetoParticipante(passivo.agenteMonitoramento),
        agenteFormalizacao: verificaObjetoParticipante(passivo.agenteFormalizacao),
        assessorLegal: verificaObjetoParticipante(passivo.assessorLegal),
        agenteCobrancaJudicial: verificaObjetoParticipante(passivo.agenteCobrancaJudicial),
        agenteCobrancaExtrajudicial: verificaObjetoParticipante(passivo.agenteCobrancaExtrajudicial),
        auditoria: verificaObjetoParticipante(passivo.auditoria),
    };

    const gest = {
        ...sec,
        // 
        produtoPassivo: null,
        concentracao: null,
        quantidadeEmitida: convertCurrencyToNumber(passivo.quantidadeEmitida),
        pagamentoPassivo: passivo.pagamentoPassivo && {
            ...passivo.pagamentoPassivo,
            distribuicaoList: [],
            carenciaJuros: getBooleanValueFromString(passivo.pagamentoPassivo.carenciaJuros),
            taxaJurosPagamentoList: getArrayDeserialized(passivo.pagamentoPassivo.taxaJurosPagamentoList).map(taxa => ({
                ...taxa,
                taxaJuros: formatPercentToNumber(taxa.taxaJuros),
                percentualFlutuante: formatPercentToNumber(taxa.percentualFlutuante),
                defasagemTaxaIndexador: taxa.defasagemTaxaIndexador ? taxa.defasagemTaxaIndexador.valueField : "",
                diaReferenciaTR: taxa.diaReferenciaTR ? taxa.diaReferenciaTR.valueField : null,
                somenteVariacaoPositiva: getBooleanValueFromString(taxa.somenteVariacaoPositiva),
                idPeriodicidadeCorrecaoMonetaria: taxa.periodicidadeCorrecaoMonetaria ? taxa.periodicidadeCorrecaoMonetaria.id : null,
                mesAtualizacaoMonentariaList: taxa.mesAtualizacaoMonentariaNovoList ? taxa.mesAtualizacaoMonentariaNovoList : null
            })),
            curvaPagamentoPassivoList: curvas.map(curva => ({
                ...curva,
                percentualPagamento: formatPercentToNumber(curva.percentualPagamento),
                percentualPagamentoAmortizacao: formatPercentToNumber(curva.percentualPagamentoAmortizacao),
                amex: convertCurrencyToNumber(curva.amex),
                perda: convertCurrencyToNumber(curva.perda),
                premio: convertCurrencyToNumber(curva.premio),
                id: curva.id < 0 ? 0 : curva.id
            })),
            integralizacaoList: getArrayDeserialized(passivo.pagamentoPassivo.integralizacaoList).map(integra => ({
                ...integra,
                taxaVenda: formatPercentToNumber(integra.taxaVenda),
                precoUnitario: convertCurrencyToNumber(integra.precoUnitario),
                percentualFlutuante: formatPercentToNumber(integra.percentualFlutuante),
                quantidadeIntegralizada: convertCurrencyToNumber(integra.quantidadeIntegralizada),
            })),
        }
    }

    return gestora ? gest : sec;
}

function serializeMesAtualizacaoMonentariaList(mesAtualizacaoMonentariaList) {
    let result = {};

    mesAtualizacaoMonentariaList.forEach((item, index) => {
        result = {
            ...result,
            [item.mes.key - 1]: true
        };
    });

    return result;
}
