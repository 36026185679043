import { initialize } from "redux-form";

export function deserializeFieldsUsuarioFormFilter(filters, page) {
    return {
        idUsuario: filters.usuario ? filters.usuario.id : 0,
        idPerfil: filters.perfil ? filters.perfil.id : 0,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        },
    }
}

export function serializeFieldsUsuarioForm(usuarioPerfil) {
       const usuarioSerialized = {
            ...usuarioPerfil,
            usuario: usuarioPerfil.idUsuario ? { id: usuarioPerfil.idUsuario } :  { id: null, nome: '' }
        };

    return initialize("usuarioPerfilForm", usuarioSerialized);
}

export function deserializeFieldsUsuarioPerfilForm(usuarioPerfil) {
    const areaAtuacaoList = usuarioPerfil?.areaAtuacaoList?.map((item, index) => (item && item.checked === true ? { checked: item.checked, idAreaAtuacao: item.idAreaAtuacao || index } : item)) || [];
    const perfilList = usuarioPerfil?.perfilList?.filter(item => item.checked).map(item => ({ idPerfil: item.idPerfil, checked: item.checked })) || [];
    const contaList = usuarioPerfil?.userLogged?.filter(item => item?.id).map(item => ({ idConta: item.id, checked: item.checked })) || [];

    return {
        id: usuarioPerfil.id,
        idUsuario: usuarioPerfil.usuario != null && usuarioPerfil.usuario.id != null ? usuarioPerfil.usuario.id : 0,
        areaAtuacaoList: areaAtuacaoList?.filter(item => item.checked) || [],
        perfilList,
        contaList,
    }
}