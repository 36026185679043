import React from "react";
import { Link } from 'react-router-dom'; 

import ReactTooltip from "react-tooltip";

export default (props) => {

    let button = (
        <>
            <button
                type={props.type || "button"}
                className={`btn-add btn-${props.buttonStyle} ${props.classes}`}
                onClick={props.action}
                disabled={props.disabled}
                style={{background: props.backgroundButtonColor ? "#630A37" : "",...props.style}}
            >
                {props.icon && (
                    <i className={`fa fa-${props.icon} mr-2`} aria-hidden="true"></i>
                )}
                <span className={`text-nowrap ${props.nameClass}`}> 
                    {props.startStrongName && (<strong>{props.startStrongName}</strong>)}
                    {props.name}
                    {props.endStrongName && (<strong>{props.endStrongName}</strong>)}
                </span>
            </button>

            {Boolean(props.tooltip) && (
                <ReactTooltip
                effect="solid"
                className="px-2 py-1"
                globalEventOff="click"
                id={props.tooltipId || 'buttonAction'}
                style={{ width: "100px" }}
                />
            )}
        </>
    );

    return (
        <div
            {...props.containerProps}
            data-tip={props.tooltipTip || ''}
            data-type={props.tooltipType}
            data-class={props.tooltipClass}
            className={props.activeClassName || ''}
            data-for={props.tooltipId || 'buttonAction'}
        >
            {props.toRoute ? (
                <Link to={props.toRoute || ''}> 
                    {button}
                </Link>
            ) : (button)}
        </div>
    );
}