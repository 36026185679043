
import { initialize } from 'redux-form';
import { formatStrToCnpjOrCpf, removeFormat } from './../../../../utils/helpers/StringHelper';
import { extractFromObj } from '../../../../utils/helpers/ObjectHelper';

export function serializeFieldsPrestadoraForm(prestadora, filter, novo = false) {
    let prestadoraSerialized;

    if (prestadora.empresaPrestadora) { 
        const tipoServicoPrestadoEmpresaList = prestadora.empresaPrestadora.tipoServicoPrestadoEmpresaList.map((tipo) => {
            return {...tipo, tipoServicoPrestadoEmpresaEmails: tipo.tipoServicoPrestadoEmpresaEmails.map((i) => i.email)}
        })

        prestadoraSerialized = {
            servicosPrestados: prestadora.servicosPrestados,
            empresaPrestadora: {
                ...prestadora.empresaPrestadora,
                perfis: Array.from(prestadora.empresaPrestadora?.perfis)?.map((perfil) => (perfil?.perfil)),
                perfisOriginal: Array.from(prestadora.empresaPrestadora?.perfis)?.map((perfil) => (perfil?.perfil)),
                cnpj: prestadora.empresaPrestadora.cnpj ? formatStrToCnpjOrCpf(prestadora.empresaPrestadora.cnpj) : "",
                tipoServicoPrestadoList: serializeServicosPrestadosSelecionados(tipoServicoPrestadoEmpresaList || [], prestadora.servicosPrestados || []),
                tiposServicosPrestadosSelecionados: tipoServicoPrestadoEmpresaList.sort((a, b) => sortServicosPrestadosList(a, b)) || [],
            },

            personalidade: prestadora.empresaPrestadora?.personalidade,
            endereco: {
                ...prestadora.empresaPrestadora?.enderecos[0]
            },
            complemento: {
                ...prestadora.empresaPrestadora?.complemento,
            }
        };

        if (!prestadora.empresaPrestadora.dadosInterno && novo) {
            prestadoraSerialized = {
                ...prestadoraSerialized,
                complemento: {
                    ...prestadoraSerialized.empresaPrestadora?.complemento,
                    lojaProtheus: "01",
                    relacionamentoProtheus: "Fornecedor",
                },
                empresaPrestadora: {
                    ...prestadoraSerialized.empresaPrestadora,
                    realizarPagamento: true,
                }
            }
        }
    } else {
        const isCPF = prestadora?.documento?.length == 14;
        
        prestadoraSerialized = {
            complemento: {
                lojaProtheus: "01",
                relacionamentoProtheus: "Fornecedor",
            },
            endereco: {
                pais: {
                    key: 55,
                    value: "Brasil",
                    raw: "Brasil"
                },
            },
            personalidade: {
                key: isCPF ? 2 : 1,
                value: isCPF ? "Pessoa Física" : "Pessoa Jurídica",
                raw: isCPF ? "PF" : "PJ"
            },
            empresaPrestadora: {
                dadosInterno: false,
                cnpj: prestadora.documento,
                realizarPagamento: true,
                tipoServicoPrestadoList: [],
                tiposServicosPrestadosSelecionados: [],
                statusAprovacao: {
                    key: 1,
                    value: "Verificado",
                    raw: "Verificado"
                },
            },
            servicosPrestados: prestadora.servicosPrestados
        };
    }

    prestadoraSerialized.filter = { ...filter };

    return initialize("prestadoraForm", prestadoraSerialized);
}

export function deserializeFieldsPrestadoraForm(prestadora) {
    function ajustaObjetos(obj) {
        return obj.map(tipo => {
            const emailsObj = tipo.tipoServicoPrestadoEmpresaEmails.map(email => ({ email }));
            return {...tipo, tipoServicoPrestadoEmpresaEmails: emailsObj};
        })
    }

    const contas = prestadora?.empresaPrestadora?.contas?.map((ct) => ({...ct, idBanco: null, idBancoExterno: extractFromObj(ct?.idBancoExterno)})) || [];

    var result = {
        id: prestadora.empresaPrestadora.id || 0,
        idEmissor: prestadora.empresaPrestadora.idEmissor || 0,
        cnpj: prestadora.empresaPrestadora.cnpj ? removeFormat(prestadora.empresaPrestadora.cnpj) : null,
        razaoSocial: prestadora.empresaPrestadora.razaoSocial || null,
        nomeSimplificado: prestadora.empresaPrestadora.nomeSimplificado || null,
        sigla: prestadora.empresaPrestadora.sigla || null,
        codigo: prestadora.empresaPrestadora.codigo || null,
        tipoServicoPrestadoEmpresaList: ajustaObjetos(prestadora.empresaPrestadora.tiposServicosPrestadosSelecionados),
        personalidade: prestadora.personalidade,
        complemento: prestadora.complemento,
        enderecos: [{...prestadora.endereco}],
        idCnae: extractFromObj(prestadora?.empresaPrestadora?.idCnae),
        contas,
        statusAprovacao: prestadora?.empresaPrestadora?.statusAprovacao,
        enviarEmail: prestadora?.enviarEmail,
        confirmarRegistro: prestadora?.confirmarRegistro,
        //
        emailPessoa: prestadora.empresaPrestadora?.emailPessoa,
        telefone: prestadora.empresaPrestadora?.telefone,
        nomeFantasia: prestadora.empresaPrestadora?.nomeFantasia,
        perfis: Array.from(prestadora.empresaPrestadora?.perfis).map((perfil) => ({ perfil })),
        rg: prestadora.empresaPrestadora?.rg,
        emitirNotaFiscal: prestadora.empresaPrestadora?.emitirNotaFiscal,
        realizarPagamento: prestadora.empresaPrestadora?.realizarPagamento,
        estadoCivil: prestadora.empresaPrestadora?.estadoCivil,
    }
    return result;
}



export function sereializeFieldsPrestadoraFilter(filters, page = 1) {
    return {
        cnpj: filters.cnpj ? removeFormat(filters.cnpj) : "",
        nomeSimplificado: filters.nomeSimplificado,
        idStatus: filters.idStatus,
        pagingConfig: { pageIndex: page, pageSize: 10 }
    }
}

export function sereializeFieldsCnpjValidation(idPrestadora, cnpj) {

    return {
        Id: idPrestadora,
        cnpj: cnpj ? removeFormat(cnpj) : "",
    }
}

function serializeServicosPrestadosSelecionados(selecionados, servicos) {
  const res = Array(servicos.length).fill(null);

  selecionados.forEach(item => {
    const posicao = servicos.findIndex(servico => servico.descricao === item.descricao);
    if (posicao !== -1) res[posicao] = true;
  });

  return res;
}

function sortServicosPrestadosList(servicoA, servicoB) {
    var result = 0;
    if (servicoA.idTipoServicoPrestado < servicoB.idTipoServicoPrestado) {
        result = -1
    }
    else if (servicoA.idTipoServicoPrestado > servicoB.idTipoServicoPrestado) {
        result = 1
    }
    return result

}
