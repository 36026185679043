import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';

import { 
    editIndice,
    loadFormIndice,
}  from '../../../../../actions/precificacao/IndiceAction';

import ContainerPage from '../../../../common/container/ContainerPage';
import IndiceForm from './form/IndiceForm';

class IndiceUpdatePage extends Component {

    componentDidMount() {
        let idIndice = this.props.match.params.idIndice;
        this.props.loadFormIndice(idIndice, true);
    }

    render() {
        const {
            editIndice,
        } = this.props;

        return (
            <ContainerPage >
                <IndiceForm 
                    title={"Editar - Índice"} 
                    onSubmit={editIndice} 
                    readOnly={false}
                    floatButtonVisible={true}
                />
            </ContainerPage>
        );
    }
}

const mapDispacthToProps = dispatch => bindActionCreators({ 
    editIndice,
    loadFormIndice,
}, dispatch);

export default withRouter(connect(null, mapDispacthToProps)(IndiceUpdatePage));