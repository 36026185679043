import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../common/button/Button";
import { Close } from "@material-ui/icons";

import { actionContasReceber } from "../../../../../actions/contasReceber/ContasReceberAction";

let ModalCancelamentoContasReceber = (props) => {
  const { close, open, item, actionContasReceber } = props;

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Cancelamento de Faturamento
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-4">
            <p>
              Você está prestes a prosseguir com o{" "}
              <span className="fw-700">cancelamento</span> da solicitação de
              aprovação e do cadastro do faturamento{" "}
              <span className="fw-700">{item.identificador}.</span>
            </p>
            <p>
              Com isso, os dados inseridos no sistema serão desconsiderados para
              fins de faturamento e o cadastro seguirá para a etapa de
              “Cancelado”.{" "}
              <span className="text-danger fw-700">
                Esta ação é irreversível.
              </span>
            </p>
            <p className="mb-0">Tem certeza que deseja prosseguir?</p>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Não, Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Sim, Confirmar"}
              action={async () => {
                await actionContasReceber(item?.id, "cancelar");
                close();
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      actionContasReceber,
    },
    dispatch
  );

export default connect(null, mapDispacthToProps)(ModalCancelamentoContasReceber);
