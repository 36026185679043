export function deserializeFieldsAgingFormFilter(filters, page) {
    return {
        idOperacao: filters && filters.operacao ? filters.operacao.map(o => o.key) : [],
        dataBase: filters.dataBase ? `${filters.dataBase}-01` : null,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        },
    };
}

export function deserializeFieldsAgingFormFilterExportExcel(filters) {
    return {
        idOperacao: filters && filters.operacao ? filters.operacao.map(o => o.key) : [],
        dataBase: filters.dataBase ? `${filters.dataBase}-01` : null,
    };
}

export function deseralizeAgingFormFilter(filters){
    return {
        idOperacao: filters && filters.operacao ? filters.operacao.map(o => o.key) : [],
        dataBase: filters.dataBase ? `${filters.dataBase}-01` : null,
    }
}