/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "../../../../../common/button/Button";
import ModalConfirmarPagamento from "../modals/ModalConfirmarPagamento";
import { dataMenorOuIgualHoje, getDateTimeString } from "../../../../../../utils/helpers/DateTimeHelper";
import { downloadExcelFile } from "../../../../../../utils/helpers/FileHelper";
import { msgError } from "../../../../../../utils/helpers/OthersHelpers";

let SectionEtapaPagamento = (props) => {
  const { pagamento } = props;

  const [openConfirmaPagamento, setOpenConfirmarPagamento] = React.useState(false);
  const [acaoPagamento, setAcaoPagamento] = React.useState(false);

  const statusId = pagamento?.ultimaEtapa?.id;
  
  function checaDataPagamento(acao) {
    const dataPagamento = pagamento?.dataPagamento;

    if (dataMenorOuIgualHoje(dataPagamento)) {
      setAcaoPagamento(acao);
      setOpenConfirmarPagamento(true);
    } else {
      msgError("Não é possível confirmar a liquidação do pagamento de evento futuro")
    }
  }

  return (
    <>
      <div className="card-body shadow-custom bg-white rounded-12 col-12 mt-4">
        <h4 className="page-head__title h3 mx-0 mt-0 mb-3 f-22 font-weight-bold">
          Etapa - Pagamento
        </h4>

        {statusId == 6 && (
          <div className="row px-0 d-flex">
            <div className="col-xl-7 d-flex flex-wrap mt-2">
              <span className="fw-400">
                Estamos aguardando a confirmação de pagamento no banco
                liquidante.
              </span>
            </div>
            <div className="col-xl-5 d-flex align-items-center justify-content-xl-end mt-xl-0 mt-3">
              {/* <Button
                name={"Confirmar Inadimplência"}
                action={() => checaDataPagamento(false)}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mr-4"
              /> */}
              <Button
                name={"Confirmar Pagamento"}
                action={() => checaDataPagamento(true)}
                classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              />
              <ModalConfirmarPagamento
                action={acaoPagamento}
                open={openConfirmaPagamento}
                close={() => setOpenConfirmarPagamento(false)}
              />
            </div>
          </div>
        )}

        {statusId == 7 && (
          <div className="row px-3">
            <div className="col-lg-6 pl-0 pr-lg-3 pr-0 mb-lg-0 mb-3">
              <p className="mb-0 text-gray fw-400 f-10-5">Data</p>
              <p className="mb-0 fw-400">
                {getDateTimeString(pagamento.dataEnvioComprovantePagamento) || "--"}
              </p>
            </div>
              <div className="col-lg-6 px-0">
                <p className="mb-0 text-gray fw-400 f-10-5">Comprovante</p>
                  {pagamento?.base64ArquivoComprovantePagamento ? (
                    <span
                      role="button"
                      className="txt-blue-link text-underline"
                      onClick={() =>
                        downloadExcelFile(
                          pagamento?.base64ArquivoComprovantePagamento,
                          pagamento?.nomeComprovantePagamento
                        )
                      }
                    >
                      <span className="mr-2">
                        {pagamento?.nomeComprovantePagamento}
                      </span>
                      <i className="fa fa-download f-14"></i>
                    </span>
                  ): <p className="mb-0 fw-400">--</p>}
              </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      //
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(SectionEtapaPagamento);
