import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../common/button/Button";
import { Close } from "@material-ui/icons";

import { criarArquivo } from "../../../../../actions/exportacao/ExportacaoAction";

let ModalCriarArquivo = (props) => {
  const { close, open, item, criarArquivo } = props;

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Geração de Arquivo Exportação
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-4">
            {item?.key == 1 && (
              <>
                <p>
                  Você está prestes a prosseguir com a{" "}
                  <span className="fw-700">exportação</span> dos{" "}
                  <span className="fw-700">pedidos de compra</span> cadastrados,
                  aprovados e escriturados no sistema.
                </p>
                <p>
                  Com isso, as <span className="fw-700">ordens de compra</span>{" "}
                  exportadas serão atualizadas no módulo de{" "}
                  <span className="fw-700">Pedido de Compra</span>.{" "}
                  <span className="text-danger fw-700">
                    Esta ação é irreversível.
                  </span>
                </p>
              </>
            )}

            {item?.key == 2 && (
              <>
                <p>
                  Você está prestes a prosseguir com a{" "}
                  <span className="fw-700">exportação</span> das{" "}
                  <span className="fw-700">movimentações financeiras</span>{" "}
                  cadastradas e aprovadas no sistema.
                </p>
                <p>
                  Com isso, as{" "}
                  <span className="fw-700">movimentações financeiras</span>{" "}
                  exportadas serão atualizadas no módulo de{" "}
                  <span className="fw-700">Pedido de Compra</span>.{" "}
                  <span className="text-danger fw-700">
                    Esta ação é irreversível.
                  </span>
                </p>
              </>
            )}

            <p className="mb-0">Tem certeza que deseja prosseguir?</p>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Não, Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Sim, Confirmar"}
              action={async () => {
                await criarArquivo(item);
                close();
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      criarArquivo,
    },
    dispatch
  );

export default connect(null, mapDispacthToProps)(ModalCriarArquivo);
