import axios from "axios";

import history from "../../components/common/routers/history";
import { deserializeFieldsPagamentoPrecificacaoFormFilter } from "../../components/business/monitoramentos/mappings/MonitoramentoMap";

import { showAllMessages } from "../message/ErrorMessagesAPIAction";

import {
  PRECIFICACAO_ROUTE_PATH,
  PAGAMENTO_ROUTE_PATH,
  LISTA_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";

import { downloadExcelFile, downloadFile } from "../../utils/helpers/FileHelper";
import { getCurrentDateTimeString } from "../../utils/helpers/DateTimeHelper";

export const SALVA_FILTROS_MONITORAMENTOS = "SALVA_FILTROS_MONITORAMENTOS";
export const MONITORAMENTOS_FETCHED = "MONITORAMENTOS_FETCHED";
export const MONITORAMENTO_FETCHED = "MONITORAMENTO_FETCHED";
export const MONITORAMENTOS_CONSULT_CURRENT = "MONITORAMENTOS_CONSULT_CURRENT";
export const LOAD_FILTER_FORM_MONITORAMENTO = "LOAD_FILTER_FORM_MONITORAMENTO";

export const PAGAMENTO_RESET = "PAGAMENTO_RESET";

const MONITORAMENTO_API =
  process.env.REACT_APP_URL_API +
  process.env.REACT_APP_PATH_OPERACAO_API +
  process.env.REACT_APP_PATH_COMPLEMENTO_API +
  process.env.REACT_APP_PATH_GESTAO_MONITORAMENTO_API;

export function loadFilterMonitoramento() {
  return (dispatch) => {
    return axios.get(MONITORAMENTO_API + "tela/filtro").then((result) =>
      dispatch([
        {
          type: LOAD_FILTER_FORM_MONITORAMENTO,
          payload: result.content,
        },
      ])
    );
  };
}

export function getMonitoramento(id) {
  return (dispatch) => {
    return axios.get(MONITORAMENTO_API + `tela/${id}`).then((result) =>
      dispatch([
        {
          type: MONITORAMENTO_FETCHED,
          payload: result.content,
        },
      ])
    );
  };
}

export function cleanMonitoramento() {
  return (dispatch) => {
    dispatch([
      {
        type: MONITORAMENTO_FETCHED,
        payload: null,
      },
    ]);
  };
}

export function getMonitoramentos(filters, page = 1) {
  const params = deserializeFieldsPagamentoPrecificacaoFormFilter(
    filters,
    page
  );

  return (dispatch) => {
    return axios
      .post(MONITORAMENTO_API + "consulta/agendas", { ...params })
      .then((result) =>
        dispatch([
          {
            type: MONITORAMENTOS_FETCHED,
            payload: result,
          },
          {
            type: MONITORAMENTOS_CONSULT_CURRENT,
            payload: {
              ...filters,
              pagingConfig: { pageIndex: page, pageSize: 10 },
            },
          },
        ])
      );
  };
}

export function salvaFiltrosMonitoramento(filters) {
  const params = deserializeFieldsPagamentoPrecificacaoFormFilter(filters, 1);

  return (dispatch, getState) => {
    return dispatch([
      {
        type: SALVA_FILTROS_MONITORAMENTOS,
        payload: params,
      },
    ]);
  };
}

export function exportMonitoramentosToExcel(filters) {
  return (dispatch) => {
    return axios
      .post(`${MONITORAMENTO_API}exportar/excel`, filters)
      .then((result) => {
        const fileName = `Monitoramentos_Planilha_${getCurrentDateTimeString()}.xlsx`;
        dispatch([
          downloadExcelFile(result.content, fileName),
          showAllMessages(result.messages),
        ]);
      });
  };
}

export function getAnexoMonitoramento(id) {
  return (dispatch) => {
    return axios.get(MONITORAMENTO_API + `anexo/${id}`).then((result) => {
      const { arquivo, nomeArquivo } = result.content;
      const fileName = `Anexo_${nomeArquivo}`;

      dispatch([
        downloadFile(arquivo, fileName),
        showAllMessages(result.messages),
      ]);
    });
  };
}

export function saveMonitoramento(payload) {
  return (dispatch) => {
    return axios.put(MONITORAMENTO_API, payload).then((result) => {
      dispatch([showAllMessages(result.messages)]);
    });
  };
}

export function voltarMonitoramento(id) {
  return (dispatch) => {
    return axios
      .put(MONITORAMENTO_API + `voltarstatus/${id}?idMonitoramentoAgenda=${id}`)
      .then((result) => {
        dispatch([showAllMessages(result.messages)]);
      });
  };
}

// -----------------

export function sairDaPagina() {
  return (dispatch) => {
    return dispatch([
      { type: PAGAMENTO_RESET },
      history.push(
        `/${PRECIFICACAO_ROUTE_PATH}/${PAGAMENTO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`
      ),
    ]);
  };
}
