import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../common/container/ContainerPage";

import { resetPagina } from "../../../../actions/carteira/CarteiraAction";
import CarteiraListFilter from "./form/CarteiraListFilter";
import CarteiraList from "./list/CarteiraList";

class CarteiraPage extends Component {
  componentDidMount() {
    this.props.resetPagina();
  }

  render() {
    return (
      <ContainerPage>
        <CarteiraListFilter />
        <CarteiraList />
      </ContainerPage>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPagina,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CarteiraPage);
