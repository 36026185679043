import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog, FormControlLabel, Checkbox } from "@material-ui/core";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import { getCurrentDateString } from "../../../../../../utils/helpers/DateTimeHelper";
import { acoesPagamentoAF } from "../../../../../../actions/precificacao/PagamentoAction";

let ModalSemResposta = (props) => {
  const { close, open, id, acoesPagamentoAF, pagamento } = props;

  const [radioChecked, setRadioChecked] = React.useState(false);

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row justify-content-between align-items-start">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Confirmação de não resposta do Agente Fiduciário
            </p>
            <Close
              role="button"
              onClick={() => close()}
              className="text-danger mt-2"
            />
          </div>

          <div className="py-4 text-dark f-16">
            <div>
              <FormControlLabel
                className="d-flex flex-row align-items-start"
                control={
                  <Checkbox
                    color="default"
                    className="pt-0"
                    checked={radioChecked}
                    onChange={(e) => setRadioChecked(e.target.checked)}
                  />
                }
                label={`Certifico que até a data de hoje, ${getCurrentDateString()}, não recebemos resposta por nenhum canal oficial do agente fiduciário. Sendo assim confirmo que o pagamento está aprovado.`}
              />
            </div>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Confirmar"}
              disabled={!radioChecked}
              action={() =>
                acoesPagamentoAF(
                  {
                    id,
                    idPagamentoPrecificacao: pagamento?.idPagamentoPrecificacao,
                  },
                  "semresposta"
                )
              }
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
  id: state.pagamentoReducer.pagamentoFormTypes.id,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      acoesPagamentoAF,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(ModalSemResposta);
