import { getDateTimeString } from "../../../../../../utils/helpers/DateTimeHelper";

const ESTR = "Estruturação";
const QUIT = "Quitada";
const RESG = "Resgatada";
const EXEC = "Execução";
const ATIV = "Ativa";

const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");
const gestora = currEnv == process.env.REACT_APP_ENV_GEST_ID;

export const validate = (values) => {
  const CAL = values.contaArrecadadoraList;
  const PPIL = values.pagamentoPassivo?.integralizacaoList;
  const PPTJPL = values.pagamentoPassivo?.taxaJurosPagamentoList;
  const dataEmissao = values.dataEmissaoSerie;
  const dtInicioDist = values.dataInicioDistribuicao;

  const isRCVM160 = (values?.tipoOferta?.value && values?.tipoOferta?.value.includes("RCVM 160"));

  let errors = {};

  const EXE_ATI = [EXEC, ATIV].includes(values?.statusOperacaoPassivo?.value);
  const QUI_RES = [QUIT, RESG].includes(values?.statusOperacaoPassivo?.value);

  const validacao1 = {
    // Controle
    patrimonioSeparado: !values.patrimonioSeparado ? 'Preenchimento obrigatório' : null,
    produtoPassivo: !values.produtoPassivo ? 'Preenchimento obrigatório' : null,
    divulgacao: !values.divulgacao ? 'Preenchimento obrigatório' : null,
    usuarioEstruturacao: !values.usuarioEstruturacao ? 'Preenchimento obrigatório' : null,
    statusOperacaoPassivo: !values.statusOperacaoPassivo ? 'Preenchimento obrigatório' : null,
    // Datas e Preços
    dataResgate: !values.dataResgate ? 'Preenchimento obrigatório' : null,
    dataExecucao: !values.dataExecucao ? 'Preenchimento obrigatório' : null,
    // Características da Oferta
    emissor: !values.emissor ? 'Preenchimento obrigatório' : null,
    titulo: !values.titulo ? 'Preenchimento obrigatório' : null,
    emissao: !values.emissao ? 'Preenchimento obrigatório' : null,
    serie: !values.serie ? 'Preenchimento obrigatório' : null,
    camaraLiquidacao: !values.camaraLiquidacao ? 'Preenchimento obrigatório' : null,
    revolvencia: !values.revolvencia ? 'Preenchimento obrigatório' : null,
    tipoInvestidor: !values.tipoInvestidor ? 'Preenchimento obrigatório' : null,
    // Códigos
    apelidoOperacao: !values.apelidoOperacao ? 'Preenchimento obrigatório' : null,
    // Participantes
    agentePagamento: !values.agentePagamento ? 'Preenchimento obrigatório' : null,
    agenteFiduciario: !values.agenteFiduciario ? 'Preenchimento obrigatório' : null,
    gestorFiduciario: !values.gestorFiduciario ? 'Preenchimento obrigatório' : null,
    coordenadorLider: !values.coordenadorLider ? 'Preenchimento obrigatório' : null,
    assessorLegal: !values.assessorLegal ? 'Preenchimento obrigatório' : null,
    escriturador: !values.escriturador ? 'Preenchimento obrigatório' : null,
    bancoLiquidante: !values.bancoLiquidante ? 'Preenchimento obrigatório' : null,
  }

  if (!gestora) {
    errors = validacao1;

    if (QUI_RES) {
      errors = {
          ...validacao1,

          // ABA: GERAL //
          // Datas e Preços
          dataVencimentoSerie: dataEmissao && (values.dataVencimentoSerie < dataEmissao) ? 'Data de Vencimento é menor do que a Data de Emissão' : null,
      }

      // if (enviandoParaAprovacao || REPASSADO) {
      //   errors = {
      //     ...errors,

      //     // ABA: GERAL //
          
      //     // Controle
      //     usuario: !values.usuario ? 'Preenchimento obrigatório' : null,
      //     usuarioServicoCredito: !values.usuarioServicoCredito ? 'Preenchimento obrigatório' : null,
      //     idCedoc: !values.idCedoc ? 'Preenchimento obrigatório' : values.idCedoc?.length !== 36 ? 'O ID Cedoc deve conter 36 caracteres' : null,

      //     // Características da Oferta
      //     tipoOferta: !values.tipoOferta ? 'Preenchimento obrigatório' : null,
      //     regimeFiduciario: !values.regimeFiduciario ? 'Preenchimento obrigatório' : null,
      //     classeOperacao: !values.classeOperacao ? 'Preenchimento obrigatório' : null,
      //     concentracao: !values.concentracao ? 'Preenchimento obrigatório' : null,
      //     moeda: !values.moeda ? 'Preenchimento obrigatório' : null,

      //     // Datas e Preços
      //     dataEmissaoSerie: !dataEmissao ? 'Preenchimento obrigatório' : null,
      //     dataVencimentoSerie: !values.dataVencimentoSerie ? 'Preenchimento obrigatório' : dataEmissao && (values.dataVencimentoSerie < dataEmissao) ? 'Data de Vencimento é menor do que a Data de Emissão' : null,
      //     quantidadeEmitida: !values.quantidadeEmitida ? 'Preenchimento obrigatório' : null,
      //     precoUnitario: !values.precoUnitario ? 'Preenchimento obrigatório' : null,

      //     // Códigos
      //     codigoCetipBbb: !values.codigoCetipBbb ? 'Preenchimento obrigatório' : null,
      //     codigoIsin: !values.codigoIsin ? 'Preenchimento obrigatório' : null,
        
      //     // Contas Fiduciárias
      //     contaArrecadadoraList: CAL && CAL.length 
      //     ? CAL.map((conta) => ({
      //       banco: !conta || !conta.banco ? 'Preenchimento obrigatório' : null,
      //       ...(conta && conta.banco
      //         ? {
      //             agencia: !conta.agencia ? 'Preenchimento obrigatório' : null,
      //             contaCorrente: !conta.contaCorrente ? 'Preenchimento obrigatório' : null,
      //             digito: !conta.digito ? 'Preenchimento obrigatório' : null,
      //             tipoContaArrecadadora: !conta.tipoContaArrecadadora ? 'Preenchimento obrigatório' : null,
      //             cnpj: !conta.cnpj ? 'Preenchimento obrigatório' : null,
      //             razaoSocial: !conta.razaoSocial ? 'Preenchimento obrigatório' : null,
      //             tipoRendimento: !conta.tipoRendimento ? 'Preenchimento obrigatório' : null,
      //             contaArrecadadoraOrigemRecurso: !conta.contaArrecadadoraOrigemRecurso ? 'Preenchimento obrigatório' : null,
      //         }
      //         : {}),
      //       })).filter(Boolean)
      //     : null,
        
      //     // Pré-Pagamento
      //     existenciaPrePagamento: !values.existenciaPrePagamento ? 'Preenchimento obrigatório' : null,
      //     formaPrePagamento: !values.formaPrePagamento ? 'Preenchimento obrigatório' : null,
      //     possibilidadePrePagamento: !values.possibilidadePrePagamento ? 'Preenchimento obrigatório' : null,
      //     dataLockup: !values.dataLockup ? 'Preenchimento obrigatório' : null,
      //     opcaoResgateSecuritizadora: !values.opcaoResgateSecuritizadora ? 'Preenchimento obrigatório' : null,
      //     clausulaContrato: !values.clausulaContrato ? 'Preenchimento obrigatório' : null,

      //     // ABAS: DISTRIBUIÇÃO-CURVA-PAGAMENTOS //
        
      //     // Controle de Pagamentos
      //     formaCalculo: !values.formaCalculo ? 'Preenchimento obrigatório' : null,

      //     // Dist/Curva/Pmt
      //     pagamentoPassivo: {
      //       tipoPagamentoPassivo: !values.pagamentoPassivo.tipoPagamentoPassivo ? 'Preenchimento obrigatório' : null,
      //       dataBasePrimeiroAniversario: !values.pagamentoPassivo.dataBasePrimeiroAniversario ? 'Preenchimento obrigatório' : null,
      //       inicioJuros: !values.pagamentoPassivo.inicioJuros ? 'Preenchimento obrigatório' : null,
      //       inicioJurosRemuneracao: !values.pagamentoPassivo.inicioJurosRemuneracao ? 'Preenchimento obrigatório' : null,
      //       possuiResiduoInflacionario: !values.pagamentoPassivo.possuiResiduoInflacionario ? 'Preenchimento obrigatório' : null,
      //       periodicidadeFrequenciaJuros: !values.pagamentoPassivo.periodicidadeFrequenciaJuros ? 'Preenchimento obrigatório' : null,
      //       carenciaJuros: !values.pagamentoPassivo.carenciaJuros ? 'Preenchimento obrigatório' : null,
      //       dataCarencia: !values.pagamentoPassivo.dataCarencia ? 'Preenchimento obrigatório' : null,
      //       periodicidadeFrequenciaAmortizacao: !values.pagamentoPassivo.periodicidadeFrequenciaAmortizacao ? 'Preenchimento obrigatório' : null,
      //       dataPrimeiraAtualizacao: !values.pagamentoPassivo.dataPrimeiraAtualizacao ? 'Preenchimento obrigatório' : null,
      //       tipoIncrementoDias: !values.pagamentoPassivo.tipoIncrementoDias ? 'Preenchimento obrigatório' : null,
      //       diaReferenciaAtualizacao: !values.pagamentoPassivo.diaReferenciaAtualizacao ? 'Preenchimento obrigatório' : null,
      //       incrementoDias: values.pagamentoPassivo.incrementoDias === null || values.pagamentoPassivo.incrementoDias === undefined ? 'Preenchimento obrigatório' : null,

      //       // Taxas de Pagamento
      //       taxaJurosPagamentoList: PPTJPL && PPTJPL.length
      //       ? PPTJPL.map((taxa, index) => ({
      //           taxaJuros: !taxa || (typeof taxa?.taxaJuros === 'undefined' || taxa?.taxaJuros === null || taxa?.taxaJuros === '') ? 'Preenchimento obrigatório' : null,
      //           baseCalculoTaxaJuros: !taxa || !taxa.baseCalculoTaxaJuros ? 'Preenchimento obrigatório' : null,
      //           tipoTaxaProjetada: !taxa || !taxa.tipoTaxaProjetada ? 'Preenchimento obrigatório' : null,
      //           baseCalculoTaxaPosFixada: !taxa || !taxa.baseCalculoTaxaPosFixada ? 'Preenchimento obrigatório' : null,
      //           baseCalculoIndexador: !taxa || !taxa.baseCalculoIndexador ? 'Preenchimento obrigatório' : null,
      //           indexadorCorrecaoMonetaria: !taxa || !taxa.indexadorCorrecaoMonetaria ? 'Preenchimento obrigatório' : null,
      //           diaReferenciaTR: !taxa || !taxa.diaReferenciaTR ? 'Preenchimento obrigatório' : null,
      //           taxaPosFixada: !taxa || !taxa.taxaPosFixada ? 'Preenchimento obrigatório' : null,
      //           percentualFlutuante: !taxa || !taxa.percentualFlutuante ? 'Preenchimento obrigatório' : null,
      //           defasagemTaxaIndexador: !taxa || !taxa.defasagemTaxaIndexador ? 'Preenchimento obrigatório' : null,
      //           somenteVariacaoPositiva: !taxa || !taxa.somenteVariacaoPositiva ? 'Preenchimento obrigatório' : null,
      //           periodicidadeCorrecaoMonetaria: !taxa || !taxa.periodicidadeCorrecaoMonetaria ? 'Preenchimento obrigatório' : null,
      //           // Data Vigência
      //           dataInicioTaxaJuros: index === 0
      //             ? !taxa.dataInicioTaxaJuros
      //               ? 'Preenchimento obrigatório'
      //               : taxa.dataInicioTaxaJuros > dataEmissao
      //                 ? `Data Vigência da 1ª taxa de juros/spread deve ser igual a data de emissão do passivo ${dataEmissao && `(${getDateTimeString(dataEmissao)})`}`
      //                 : null
      //             : !taxa.dataInicioTaxaJuros
      //               ? 'Preenchimento obrigatório'
      //               : null
      //         }))
      //       : null,
      //     }
      //   }
      // }
    }

    if (EXE_ATI) {
      errors = {
        ...validacao1,

        // ABA: GERAL //
          
        // Controle
        usuario: !values.usuario ? 'Preenchimento obrigatório' : null,
        usuarioServicoCredito: !values.usuarioServicoCredito ? 'Preenchimento obrigatório' : null,
        idCedoc: !values.idCedoc ? 'Preenchimento obrigatório' : values.idCedoc?.length !== 36 ? 'O ID Cedoc deve conter 36 caracteres' : null,

        // Características da Oferta
        tipoOferta: !values.tipoOferta ? 'Preenchimento obrigatório' : null,
        regimeFiduciario: !values.regimeFiduciario ? 'Preenchimento obrigatório' : null,
        classeOperacao: !values.classeOperacao ? 'Preenchimento obrigatório' : null,
        concentracao: !values.concentracao ? 'Preenchimento obrigatório' : null,
        moeda: !values.moeda ? 'Preenchimento obrigatório' : null,

        // Datas e Preços
        dataEmissaoSerie: !dataEmissao ? 'Preenchimento obrigatório' : null,
        dataVencimentoSerie: !values.dataVencimentoSerie ? 'Preenchimento obrigatório' : null,
        quantidadeEmitida: !values.quantidadeEmitida ? 'Preenchimento obrigatório' : null,
        precoUnitario: !values.precoUnitario ? 'Preenchimento obrigatório' : null,

        // Códigos
        codigoCVM: isRCVM160 && !values.codigoCVM ? 'Preenchimento obrigatório' : null,
        processoCVM: isRCVM160 && !values.processoCVM ? 'Preenchimento obrigatório' : null,
        // codigoBolsa: !values.codigoBolsa ? 'Preenchimento obrigatório' : null,
        codigoCetipBbb: !values.codigoCetipBbb ? 'Preenchimento obrigatório' : null,
        codigoIsin: !values.codigoIsin ? 'Preenchimento obrigatório' : null,
      
        // Contas Fiduciárias
        contaArrecadadoraList: CAL && CAL.length 
        ? CAL.map((conta) => ({
          banco: (!conta || !conta.banco) ? 'Preenchimento obrigatório' : 
          (conta?.tipoContaArrecadadora?.descricao?.includes("Liquidação") && conta?.banco?.nome !== values?.bancoLiquidante?.nomeSimplificado) ? 
          'Deve ser igual ao Banco Liquidante' : null,
          ...(conta && conta.banco
            ? {
                agencia: !conta.agencia ? 'Preenchimento obrigatório' : null,
                contaCorrente: !conta.contaCorrente ? 'Preenchimento obrigatório' : null,
                digito: !conta.digito ? 'Preenchimento obrigatório' : null,
                tipoContaArrecadadora: !conta.tipoContaArrecadadora ? 'Preenchimento obrigatório' : null,
                cnpj: !conta.cnpj ? 'Preenchimento obrigatório' : null,
                razaoSocial: !conta.razaoSocial ? 'Preenchimento obrigatório' : null,
                tipoRendimento: !conta.tipoRendimento ? 'Preenchimento obrigatório' : null,
                contaArrecadadoraOrigemRecurso: !conta.contaArrecadadoraOrigemRecurso ? 'Preenchimento obrigatório' : null,
              }
            : {}),
        })).filter(Boolean)
        : null,
      
        // Pré-Pagamento
        existenciaPrePagamento: !values.existenciaPrePagamento ? 'Preenchimento obrigatório' : null,
        formaPrePagamento: !values.formaPrePagamento ? 'Preenchimento obrigatório' : null,
        possibilidadePrePagamento: !values.possibilidadePrePagamento ? 'Preenchimento obrigatório' : null,
        dataLockup: !values.dataLockup ? 'Preenchimento obrigatório' : null,
        opcaoResgateSecuritizadora: !values.opcaoResgateSecuritizadora ? 'Preenchimento obrigatório' : null,
        clausulaContrato: !values.clausulaContrato ? 'Preenchimento obrigatório' : null,

        // ABAS: DISTRIBUIÇÃO-CURVA-PAGAMENTOS //
      
        // Controle
        dataPrimeiraIntegralizacao: !values.dataPrimeiraIntegralizacao ? 'Preenchimento obrigatório' : null,
        // dataInicioDistribuicao: !values.dataInicioDistribuicao ? 'Preenchimento obrigatório' : null,
        // dataFinalDistribuicao: !values.dataFinalDistribuicao ? 'Preenchimento obrigatório' : null,
      
        // Controle de Pagamentos
        formaCalculo: !values.formaCalculo ? 'Preenchimento obrigatório' : null,

        // Dist/Curva/Pmt
        pagamentoPassivo: {
          tipoPagamentoPassivo: !values.pagamentoPassivo.tipoPagamentoPassivo ? 'Preenchimento obrigatório' : null,
          dataBasePrimeiroAniversario: !values.pagamentoPassivo.dataBasePrimeiroAniversario ? 'Preenchimento obrigatório' : null,
          inicioJuros: !values.pagamentoPassivo.inicioJuros ? 'Preenchimento obrigatório' : null,
          inicioJurosRemuneracao: !values.pagamentoPassivo.inicioJurosRemuneracao ? 'Preenchimento obrigatório' : null,
          possuiResiduoInflacionario: !values.pagamentoPassivo.possuiResiduoInflacionario ? 'Preenchimento obrigatório' : null,
          periodicidadeFrequenciaJuros: !values.pagamentoPassivo.periodicidadeFrequenciaJuros ? 'Preenchimento obrigatório' : null,
          carenciaJuros: !values.pagamentoPassivo.carenciaJuros ? 'Preenchimento obrigatório' : null,
          dataCarencia: !values.pagamentoPassivo.dataCarencia ? 'Preenchimento obrigatório' : null,
          periodicidadeFrequenciaAmortizacao: !values.pagamentoPassivo.periodicidadeFrequenciaAmortizacao ? 'Preenchimento obrigatório' : null,
          dataPrimeiraAtualizacao: !values.pagamentoPassivo.dataPrimeiraAtualizacao ? 'Preenchimento obrigatório' : null,
          tipoIncrementoDias: !values.pagamentoPassivo.tipoIncrementoDias ? 'Preenchimento obrigatório' : null,
          diaReferenciaAtualizacao: !values.pagamentoPassivo.diaReferenciaAtualizacao ? 'Preenchimento obrigatório' : null,
          incrementoDias: values.pagamentoPassivo.incrementoDias === null || values.pagamentoPassivo.incrementoDias === undefined ? 'Preenchimento obrigatório' : null,

          // Integralização
          integralizacaoList: PPIL && PPIL.length
            ? PPIL.map((integ) => ({
              dataIntegralizacao: !integ || !integ.dataIntegralizacao
                ? 'Preenchimento obrigatório'
                : (dtInicioDist && integ.dataIntegralizacao < dtInicioDist)
                ? 'Data de Integralização é menor que a Data Início Distribuição'
              : null,
              quantidadeIntegralizada: !integ || !integ.quantidadeIntegralizada ? 'Preenchimento obrigatório' : null,
              taxaVenda: !integ || (typeof integ?.taxaVenda === 'undefined' || integ?.taxaVenda === null || integ?.taxaVenda === '') ? 'Preenchimento obrigatório' : null,
              percentualFlutuante: !integ || (typeof integ?.percentualFlutuante === 'undefined' || integ?.percentualFlutuante === null || integ?.percentualFlutuante === '') ? 'Preenchimento obrigatório' : null,
              precoUnitario: !integ || (typeof integ?.precoUnitario === 'undefined' || integ?.precoUnitario === null || integ?.precoUnitario === '') ? 'Preenchimento obrigatório' : null,
            })).filter(Boolean)
          : null,

          // Taxas de Pagamento
          taxaJurosPagamentoList: PPTJPL && PPTJPL.length
          ? PPTJPL.map((taxa, index) => ({
              taxaJuros: !taxa || (typeof taxa?.taxaJuros === 'undefined' || taxa?.taxaJuros === null || taxa?.taxaJuros === '') ? 'Preenchimento obrigatório' : null,
              baseCalculoTaxaJuros: !taxa || !taxa.baseCalculoTaxaJuros ? 'Preenchimento obrigatório' : null,
              tipoTaxaProjetada: !taxa || !taxa.tipoTaxaProjetada ? 'Preenchimento obrigatório' : null,
              baseCalculoTaxaPosFixada: !taxa || !taxa.baseCalculoTaxaPosFixada ? 'Preenchimento obrigatório' : null,
              baseCalculoIndexador: !taxa || !taxa.baseCalculoIndexador ? 'Preenchimento obrigatório' : null,
              indexadorCorrecaoMonetaria: !taxa || !taxa.indexadorCorrecaoMonetaria ? 'Preenchimento obrigatório' : null,
              diaReferenciaTR: !taxa || !taxa.diaReferenciaTR ? 'Preenchimento obrigatório' : null,
              taxaPosFixada: !taxa || !taxa.taxaPosFixada ? 'Preenchimento obrigatório' : null,
              percentualFlutuante: !taxa || (typeof taxa?.percentualFlutuante === 'undefined' || taxa?.percentualFlutuante === null || taxa?.percentualFlutuante === '') ? 'Preenchimento obrigatório' : null,
              defasagemTaxaIndexador: !taxa || !taxa.defasagemTaxaIndexador ? 'Preenchimento obrigatório' : null,
              somenteVariacaoPositiva: !taxa || !taxa.somenteVariacaoPositiva ? 'Preenchimento obrigatório' : null,
              periodicidadeCorrecaoMonetaria: !taxa || !taxa.periodicidadeCorrecaoMonetaria ? 'Preenchimento obrigatório' : null,
              // Data Vigência
              dataInicioTaxaJuros: index === 0
                ? !taxa.dataInicioTaxaJuros
                  ? 'Preenchimento obrigatório'
                  : taxa.dataInicioTaxaJuros > dataEmissao
                    ? `Data Vigência da 1ª taxa de juros/spread deve ser igual a data de emissão do passivo ${dataEmissao && `(${getDateTimeString(dataEmissao)})`}`
                    : null
                : !taxa.dataInicioTaxaJuros
                  ? 'Preenchimento obrigatório'
                  : null
            }))
          : null,
        }
      }
    }
  } 

  return errors;
};