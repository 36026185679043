/* eslint no-useless-escape: 0 */

import {
  getDateInvertedFormatedFromString,
  getHourFormatedFromString,
} from "../../../../utils/helpers/DateTimeHelper";
import { initialize } from "redux-form";
import { convertCurrencyToNumber } from "../../../../utils/helpers/NumberHelper";

export function deserializeFieldsAssembleiaFormFilter(filters, page) {
  return {
    ...filters,
    operacoes: filters.operacoes ? filters.operacoes.map((item) => item.key) : null,
    pagingConfig: {
      pageIndex: page || filters.pagingConfig.pageIndex,
      pageSize: 10,
    },
  };
}

export function serializeFieldsAssembleia(dadosAssembleia) {
  let { assembleia } = dadosAssembleia;
  let serializeAssembleia = {}
  
  if (assembleia) {
      serializeAssembleia = {
        ...assembleia,
        dataAssembleia: getDateInvertedFormatedFromString(assembleia?.dataAssembleia),
        horarioAssembleia: getHourFormatedFromString(assembleia?.dataAssembleia),
        dataConvocacao: getDateInvertedFormatedFromString(assembleia?.dataConvocacao),
      }
        
    if (assembleia?.documentos?.length) {
      const { documentos } = assembleia

      serializeAssembleia = {
        ...serializeAssembleia,
        documentosEdital: documentos.filter((item) => item?.tipo.key == 1),
        documentosProposta: documentos.filter((item) => item?.tipo.key == 2),
        documentosAta: documentos.filter((item) => item?.tipo.key == 3),
        documentosOutros: documentos.filter((item) => item?.tipo.key == 4),
      }
    }
    
    if (assembleia?.operacoes?.length) {
      serializeAssembleia = {
        ...serializeAssembleia,
        operacoes: assembleia?.operacoes.map(({key, value}) => ({ id: key, descricao: value }))
      }
    }
  }

  return initialize("AssembleiaForm", serializeAssembleia);
}

export function deserializeFieldsAssembleia(assembleia) {
  const {
    documentosEdital = [],
    documentosProposta = [],
    documentosAta = [],
    documentosOutros = [],
    ordemConvocacao,
    operacoes,
    dataAssembleia,
    horarioAssembleia,
    valorFaturamento,
    ...restAssembleia
  } = assembleia;

  const dataHoraISO = new Date(`${dataAssembleia}T${horarioAssembleia}:00.000Z`).toISOString();

  return {
    ...restAssembleia,
    valorFaturamento: convertCurrencyToNumber(valorFaturamento || 0),
    ordemConvocacao: ordemConvocacao?.valueField || ordemConvocacao,
    documentos: [...documentosEdital, ...documentosProposta, ...documentosAta, ...documentosOutros],
    dataAssembleia: dataHoraISO,
    operacoes: operacoes.map(op => ({ idOperacao: op.id }))
  };
}
