/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */


import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconMenu from "@material-ui/icons/Menu";
import { FormControl, Select, AppBar, CssBaseline, Drawer, IconButton, Toolbar } from "@material-ui/core";
import { MenuItem } from "material-ui";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

import logo from "./../../../assets/img/LOGO_OPEA_POS_RGB.svg";
import SideBar from "./../../template/SideBar";
import ExitToAppIcon from "./../../template/ExitToAppIcon";

import { SIDEBAR_ITEMS } from "./../../common/sidebar/SideBarItems";
import { logout, alterarAmbienteUsuario } from "../../../actions/login/LoginAction";
import HubWeb from "./notification/HubWeb";
import VersionComponent from "../../../utils/gitVersion/VersionComponent";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import useIsMobile from "../../../utils/isMobile";
import history from "../routers/history";
import { decodeBase64 } from "../../../utils/helpers/StringHelper";

const Header = (props) => {
  const isMobile = useIsMobile();

  const [open, setOpen] = React.useState(isMobile);
  const [env, setEnv] = React.useState("");

  let multiplasContas = props.credentials.contas.length > 1;
  
  let contas = props.credentials.contas;
  let currEnv = localStorage.getItem('currEnv');
  let contasPossiveis =
  contas.find((conta) => conta.id === process.env.REACT_APP_ENV_SEC_ID || conta.descricao == "Securitizadora")?.id ||
  contas.find((conta) => conta.id)?.id || null;

  let contaDefault = currEnv || contasPossiveis;

  const redirectTo = new URLSearchParams(history.location.search).get('redirectTo');

  useEffect(() => {
    if (redirectTo) localStorage.setItem('redirectTo', decodeBase64(redirectTo))
  }, [redirectTo])

  useEffect(() => {
    if (contaDefault && contaDefault !== currEnv) {
      setEnv(contaDefault);
      localStorage.setItem('currEnv', contaDefault);
      
      const email = props.credentials.email;
      props.alterarAmbienteUsuario({ email, idContaMestre: contaDefault });
    } else if (!env && contaDefault) {
      const redirectTo = localStorage.getItem('redirectTo');
      if (redirectTo) window.location.href = redirectTo
      
      localStorage.removeItem('redirectTo');

      setEnv(contaDefault);
    }
  }, [contaDefault, currEnv, env, props.credentials.email, props.alterarAmbienteUsuario]);

  useEffect(() => {
    isMobile ? handleDrawerClose() : handleDrawerOpen();
  }, [isMobile])

  function handleDrawerOpen() {
    setOpen(false);
  }

  function handleDrawerClose() {
    setOpen(true);
  }

  function changeEnvironment(id) {
    localStorage.setItem('currEnv', id);
    setEnv(id || contasPossiveis);
  }

  async function changeAmbiente(el) {
    let email = props.credentials.email;
    let idContaMestre = el.target.value;

    await props.alterarAmbienteUsuario({email, idContaMestre});
    changeEnvironment(idContaMestre);
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    ambientes: {
      backgroundColor: "#630A37",
      boxShadow: "none !important",
      border: "none !important",
      height: 28,
      zIndex: theme.zIndex.appBar + 1 + " !important",
      marginLeft: 72,
      width: `calc(100% - ${72}px) !important`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      paddingLeft: 16,
      display: "flex",
      alignItems: "center",
      flexDirection: "row"
    },
    ambientesAnim: {
      marginLeft: 260,
      width: `calc(100% - ${260}px) !important`,
    },
    dropAmbiente: {
      top: 0,
      display: "flex",
      flexDirection: "row",
      height: 20,
    },
    nativeDrop: {
      height: 20,
      color: "#333",
      backgroundColor: "#fafafa",
      borderRadius: 2,
      fontSize: "12px !important",
      padding: '4px 3px 3px 6px',
    },
    nativeDropOption: {
      color: "#333 !important",
      minHeight: "38px !important",
      height: "40px",
      display: "flex !important",
      alignItems: "center",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1 + " !important",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginTop: multiplasContas ? 28 : 0,
      backgroundColor: "#fafafa",
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundSize: "320px, 100%",
      backgroundPosition: "top left, top left",
      boxShadow: "none !important",
      borderBottom: "1px solid #6e6259",
      marginLeft: 72,
      width: `calc(100% - ${72}px) !important`,
    },
    appBarShift: {
      marginLeft: 260,
      width: `calc(100% - ${260}px) !important`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundSize: "260px, 100%",
      boxShadow: "none !important",
    },
    myToolbar: {
      padding: "0 25px 0 0!important",
      minHeight: 84 + "px !important",
      backgroundColor: "#FFFFFF !important",
    },
    actionNavBar: {
      marginLeft: "auto",
      color: "#630A37",
    },
    navbarHeaderLogo: {
      padding: "15px 45px",
      justifyContent: "center",
      display: "flex",
    },
    drawer: {
      width: 260,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: 260,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: "#630A37 !important",
      borderColor: "#72224A !important",
    },
    drawerClose: {
      backgroundColor: "#630A37 !important",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      borderColor: "#72224A !important",
      overflowX: "hidden",
      width: theme.spacing.unit * 7 + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing.unit * 9 + 1,
      },
    },
    toolbarCustom: {
      minHeight: "84px !important",
      backgroundColor: "#630A37",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: multiplasContas ? 28 : 0,
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    boxSideBar: {
      height: "100%",
      backgroundColor: "#630A37",
      borderTop: "1px solid #72224A",
      overflowY: "auto",
    },
    cursorPointer: {
      cursor: "pointer",
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      {multiplasContas &&
        <MuiThemeProvider>
          <AppBar
            position="fixed"
            className={`${classNames(classes.ambientes, { [classes.ambientesAnim]: !open })} d-flex flex-row`}
            >
            <label className="text-white mb-0 mr-2">Ambientes: </label>
            <FormControl className={`${classNames(classes.dropAmbiente)}`} size="small">
              <Select 
                value={env}
                label="Ambientes"
                id="select-ambientes"
                disableUnderline
                size='small'
                className={`${classNames(classes.nativeDrop)}`}
                
                MenuProps={{ getContentAnchorEl: null, anchorOrigin: { vertical: "bottom", horizontal: "left" }}}
                onChange={changeAmbiente}
              >
                { props.credentials && props.credentials.contas && props.credentials.contas.length > 0 &&
                  props.credentials.contas.map((conta, index) => {
                    return(
                      <MenuItem classes={{ selected: classes.selected }} className={`${classNames(classes.nativeDropOption)}`} key={index} value={conta.id}>
                        {conta.descricao}
                      </MenuItem>
                  )})
                }
              </Select>
            </FormControl>
          </AppBar>
        </MuiThemeProvider>
      }
      <AppBar
        position="fixed"
        className={`${classNames(classes.appBar, {
          [classes.appBarShift]: !open,
        })}`}
      >
        <Toolbar className={classNames(classes.myToolbar)} disableGutters={!open}>
          <div className={`${classes.navbarHeaderLogo}`}>
            <img onClick={() => history.push('/')} role="button" src={logo} title="Logo Opea" alt="Logo Opea" height="50px" />
          </div>
          <div className={`${classes.actionNavBar}`}>
            <div className="d-flex flex-row align-items-center py-1">
              {(props?.credentials?.nome) ? (
                <p className="fw-400 m-0">
                  Bem vindo(a),{" "}
                  <span className="font-italic">{props.credentials.nome}</span>
                </p>
              ) : (
                <p className="fw-400 m-0">Usuário desconhecido</p>
              )}
              <HubWeb />
              <IconButton style={{ color: "#79C1DB" }} onClick={() => props.logout()}>
                <ExitToAppIcon />
              </IconButton>
            </div>
            <VersionComponent className="f-12 opacity-50 text-dark" />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: !open,
          [classes.drawerClose]: open,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: !open,
            [classes.drawerClose]: open,
          }),
        }}
        open={open}
      >
 
        <div className={`${classes.toolbarCustom} ${classes.toolbar}`}>
          <IconButton className="btn-sidebar" onClick={!open ? handleDrawerClose : handleDrawerOpen}>
            {!open ? <ChevronLeftIcon /> : <IconMenu />} 
          </IconButton>
        </div>
        
        <div className={`${classes.boxSideBar}`}>
          <SideBar sideBarItems={SIDEBAR_ITEMS} sbOpen={open} />
        </div>
      </Drawer>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      alterarAmbienteUsuario
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Header);
