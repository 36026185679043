/* eslint eqeqeq: 0 */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { HubConnectionBuilder, HttpTransportType } from '@aspnet/signalr';
import { showAllMessages, storageAllMessagesNotification } from "../../../../actions/message/ErrorMessagesAPIAction";
import CenterNotification from './CenterNotification';
import history from "../../../../components/common/routers/history";
import { recebeNotificacaoPagamentoJob, notificaErroPagamento } from '../../../../actions/precificacao/PagamentoAction';
import { logoutMessage } from "../../../../actions/login/LoginAction";

const OPERACAO_API = process.env.REACT_APP_URL_API;

// SignalR - Responsável pelo recebimento de notificação da comunicação entre o back-front (websocket)
class HubWeb extends Component {

  constructor(props) {
    super(props);
    this.conexao = {};
    this.state = {control:false};
  }
  
  initHub(){
    // Crio a instância do Hub de conexão do SignalR
    this.conexao = new HubConnectionBuilder().withUrl(
      OPERACAO_API + '/notification', 
      {
        skipNegotiation: true, 
        transport: HttpTransportType.WebSockets
      }
    ).build();
  };

  inicialize() {
    try {
      this.conexao.start();
      console.log("Connected");
    } catch (err) {
      console.log("DISCONNECTED" + err.toString())
      setTimeout(() => this.inicialize(), 10000);
    }
  };

  receivedMessage(){
    function validaEmail(email) {
      let userLogged = JSON.parse(localStorage.getItem('userLogged'));
      let emailLogged = userLogged?.email;
      return emailLogged === email;
    }

    function checaRotaFluxo(id) {
      const path = window.location.pathname;
      return path.includes('/precificacao/pagamento/fluxo') && path.substring(path.lastIndexOf('/') + 1) === id;
    }

    // Trato o recebimento da mensagem.
    this.conexao.on("ReceiveMessage", (message, domain, email, obj) => {
      if (message.errors[0] == "notification-payment") {
        if (validaEmail(email) && checaRotaFluxo(obj?.idPagamentoPrecificacao)) {
          this.props.notificaErroPagamento();
        }
      } else {
        switch (message.successes[0]) {
          case "update-domain": {
            if (validaEmail(email)) {
              const redirectTo = localStorage.getItem('redirectTo');
              localStorage.setItem('currEnv', domain);

              if (redirectTo) {
                window.location.href = redirectTo
              } else {
                history.replace("/");
                window.location.reload(false);
              }
              
              localStorage.removeItem('redirectTo');
            }
            break;
          }
        
          case "notification-payment": {
            if (validaEmail(email) && checaRotaFluxo(obj?.idPagamentoPrecificacao)) {
              this.props.recebeNotificacaoPagamentoJob(obj?.idPagamentoPrecificacao);
            }
            break;
          }
        
          case "notification-update-profile": {
            if (validaEmail(email)) {
              this.props.logoutMessage("Perfil alterado, favor realizar o acesso ao sistema novamente");
            };
            break;
          }

          default: {
            let currEnv = localStorage.getItem('currEnv');
            if (domain === currEnv) {
              showAllMessages(message);
              storageAllMessagesNotification(message, domain);
              this.setState({control: true});
            }
            break;
          }
        } 
      }
    }); 
  };

  detectClosing(){
    this.conexao.onclose(async () => {
      //start();
      setTimeout(() => this.inicialize(), 10000);
    });
  };


  componentDidMount() {

    // Constroi a conexão
    this.initHub();

    // Recebe a mensagem
    this.receivedMessage();

    // Detecta o fechamento e prepara a reconexão
    this.detectClosing();

    // Inicia a conexão
    this.inicialize();
  
  }

  /*
  Caso queira que o front envie dados para o Back e replica para todos os clientes conectados
  enviarMensagem(e) {
    e.preventDefault();
    // Invoco o método EnviarMensagem no lado do servidor.
    this.conexao.invoke('ReceiveMessage', {nome: this.state.nome, msg: this.state.mensagem});
    this.setState({mensagem:''})
  }
  */
 
  render() {
    let loadImagem = ( <div> <CenterNotification /> </div> );
    
    return ( 
      <div className='mx-3'> { loadImagem } </div>
    );
  }
}

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      recebeNotificacaoPagamentoJob,
      notificaErroPagamento,
      logoutMessage
    },
    dispatch
  );

export default connect(null, mapDispacthToProps)(HubWeb);