/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../../common/container/ContainerPage";
import OpcaoListFilter from "./OpcaoListFilter";
import MoedaList from "./moeda/list/MoedaList";
import SeloList from "./selo/list/SeloList";
import TipoAprovacaoList from "./tipoAprovacao/list/TipoAprovacaoList";
import ProdutoList from "./produto/list/ProdutoList";
import MovimentacaoFinanceiraList from "./movimentacaoFinanceira/list/MovimentacaoFinanceiraList";
import NaturezaList from "./natureza/list/NaturezaList";
import BancosList from "./bancos/list/BancosList";
import AssuntosAssembleiaList from "./assuntosAssembleia/list/AssuntosAssembleiaList";
import ReceitaProduto from "./receitaProduto/list/ReceitaProdutoList";

import { getTipos, removeOpcao, showOpcaoDeleteDialog } from "../../../../../actions/precificacao/OpcaoAction";
import ModalAction from "../../../../common/modal/ModalAction";
import { authorizationWithoutDispatch } from "../../../../../actions/authorization/AuthorizationAction";
import { DELETE, OPCAO } from "../../../../../utils/actionsAndFeaturesAuth";

let OpcaoPage = (props) => {
  const { tipo, showDeleteDialog, showOpcaoDeleteDialog, removeOpcao, getTipos } = props

  return (
    <ContainerPage>
      <OpcaoListFilter onSubmit={({ tipo }) => getTipos(tipo.id)} />

      {tipo == "moeda" && <MoedaList />}
      {tipo == "selo" && <SeloList />}
      {tipo == "tipo-aprovacao" && <TipoAprovacaoList />}
      {tipo == "produto" && <ProdutoList />}
      {tipo == "movimentacao-financeira" && <MovimentacaoFinanceiraList /> }
      {tipo == "natureza" && <NaturezaList />}
      {tipo == "banco-externo" && <BancosList />}
      {tipo == "assembleia/assunto" && <AssuntosAssembleiaList />}
      {tipo == "receita/produto" && <ReceitaProduto />}

      <ModalAction
        title="Excluir item"
        open={showDeleteDialog.showDialog}
        close={() => showOpcaoDeleteDialog(false)}
        message="Você está prestes a excluir o item selecionado"
        action={() => {
          authorizationWithoutDispatch(
            { idAcao: DELETE, idFuncionalidade: OPCAO },
            () => removeOpcao(showDeleteDialog.idOpcao, tipo)
          );
        }}
      />
    </ContainerPage>
  );
}

const mapStateToProps = (state) => ({
  tipo: state.opcaoReducer.tipo,
  showDeleteDialog: state.opcaoReducer.showOpcaoDeleteDialog,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showOpcaoDeleteDialog,
      removeOpcao,
      getTipos,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OpcaoPage);
