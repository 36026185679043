import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import { acoesPagamentoAF } from "../../../../../../actions/precificacao/PagamentoAction";

let ModalContestacaoRevisao = (props) => {
  const { pagamento, close, open, acoesPagamentoAF } = props;

  const [justificativaContestacao, setJustificativaContestacao] =
    React.useState("");

  return (
    <>
      <Dialog open={open} onClose={() => close()}>
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Contestação de Revisão
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-3 text-dark f-16 fw-400">
            <p className="mb-0">
              Você está prestes a confirmar um pagamento com valores revisados
              pelo agente fiduciário. Justifique abaixo o motivo.
            </p>
          </div>

          <div className="row px-3 mb-3">
            <span className="f-10-5 mb-2p lh-normal fw-400 txt-grey">
              Justificativa
              <span className="text-warning font-weight-bold ml-1">*</span>
            </span>
            <textarea
              rows="6"
              style={{ resize: "none" }}
              value={justificativaContestacao}
              className="form-control brd-1 p-2"
              onChange={(e) => setJustificativaContestacao(e.target.value)}
            ></textarea>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Contestar Revisão"}
              action={() => {
                acoesPagamentoAF(
                  {
                    emRevisao: false,
                    id: pagamento?.id,
                    justificativaContestacao,
                    idPagamentoPrecificacao: pagamento?.idPagamentoPrecificacao,
                  },
                  "prosseguir"
                );

                close();
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      acoesPagamentoAF,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ModalContestacaoRevisao);
