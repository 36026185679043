import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Table from "../../../../../../common/table/Table";
import TableHeader from "../../../../../../common/table/TableHeader";
import TableContent from "../../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../../common/table/TableEmptyData";

import {
  showOpcaoDeleteDialog,
  loadFormOpcao,
  getTipos,
} from "../../../../../../../actions/precificacao/OpcaoAction";

import TableActionWithRouteAuthorization from "../../../../../../common/table/TableActionWithRouteAuthorization";
import { OPCAO } from "../../../../../../../utils/actionsAndFeaturesAuth";
import PaginationRB from "../../../../../../common/pagination/PaginationRB";

const AssuntosAssembleiaList = (props) => {
  const { showOpcaoDeleteDialog, opcoes, loadFormOpcao, getTipos, tipo } = props;
  const { items, hasItems, pageIndex, pageSize, totalCount } = opcoes;

  const tableHeader = (
    <tr>
      <th className="text-center fw-700 text-nowrap">Assunto</th>
      <th className="text-center fw-700 text-nowrap">Descrição</th>
      <th className="text-center"></th>
    </tr>
  );

  const tableContent =
    opcoes && hasItems ? (
      items.map((item, index) => (
        <tr key={index}>
          <td className="text-center fw-400 text-nowrap">
            {item?.assunto || "--"}
          </td>
          <td className="text-center fw-400" style={{ maxWidth: 400 }}>
            {item?.descricao || "--"}
          </td>
          <TableActionWithRouteAuthorization
            space="2"
            feature={OPCAO}
            viewAction={() => loadFormOpcao(item.id, false, tipo)}
            updateAction={() => loadFormOpcao(item.id, true, tipo)}
            remove={() => showOpcaoDeleteDialog(true, item.id)}
          />
        </tr>
      ))
    ) : (
      <TableEmptyData message={"Sem itens no momento"} />
    );

  return (
    <div className="w-100 px-4 m-2">
      {totalCount > 0 && (
        <Table
          withoutHeader
          classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
          totalItems={totalCount}
          pagination={
            <PaginationRB
              pageRange={5}
              itemsPerPage={pageSize || 0}
              totalItems={totalCount || 0}
              pageNumber={pageIndex - 1 || 0}
              fetchFunction={(event) => getTipos(tipo, event)}
            />
          }
        >
          <TableHeader>{tableHeader}</TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  opcoes: state.opcaoReducer.opcaoFormTypes || [],
  tipo: state.opcaoReducer.tipo,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      showOpcaoDeleteDialog,
      loadFormOpcao,
      getTipos,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(AssuntosAssembleiaList);
