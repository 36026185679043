import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector } from "redux-form";

import Form from '../../../../../common/form/Form';
import FormHeader from '../../../../../common/form/FormHeader';
import FormContent from '../../../../../common/form/FormContent';
import Input from '../../../../../common/input/Input';
import Button from '../../../../../common/button/Button';
import CheckboxInput from '../../../../../common/input/CheckboxInput';
import OperacaoPrecificacaoCalculoPicker from '../../../../pickers/OperacaoPrecificacaoCalculoPicker';

import { 
    getCalculos,
    exportPrecificacaoCalculoToExcel,
    exportPrecificacaoCalculoToExcelSimplificada
} from '../../../../../../actions/precificacao/PrecificacaoAction';

let CalculoListFilter = (props) => {

    const {
        getCalculos,
        exportPrecificacaoCalculoToExcel,
        exportPrecificacaoCalculoToExcelSimplificada,
        operacao,
        dataEventoInicial,
        dataEventoFinal,
        diario,
    } = props;

    return (
        <div className='container-fluid'>
            <Form>
                <FormHeader 
                    title={'Cálculo'}
                />
                <FormContent classes="shadow-custom rounded-12 bg-white px-3 py-2" handleSubmit={getCalculos}>
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <label className="f-12 text-dark mb-0">Busca</label>
                            <Field 
                                component={OperacaoPrecificacaoCalculoPicker}
                                name="operacao"
                            />
                        </div>
                        <div className="col-lg-3">
                            <Field 
                                component={Input}
                                inputId="dataEventoInicial"
                                type="date"
                                name="dataEventoInicial"
                                label="Data Início"
                                readOnly={false}
                            />
                        </div>
                        <div className="col-lg-3">
                            <Field 
                                component={Input}
                                inputId="dataEventoFinal"
                                type="date"
                                name="dataEventoFinal"
                                label="Data Fim"
                                readOnly={false}
                            />
                        </div>
                        <div className="col-lg-6 mt-3">
                            <Field 
                                component={CheckboxInput}
                                inputId="diario"
                                inputName="diario"
                                name="diario"
                                label="Diário"
                                disabled={false}
                            />
                        </div>

                        <div className="col-12">
                            <div className='d-flex flex-row align-items-center'>
                                <Button
                                    action={() => getCalculos({ operacao, dataEventoInicial, dataEventoFinal, diario })}
                                    classes="p-bg-imp rounded"
                                    icon={"search"}
                                    name={"Buscar"}
                                />
                                
                                <label
                                    role='button'
                                    className='mb-0 p-color-lighten ml-3'
                                    onClick={() => exportPrecificacaoCalculoToExcel({ operacao, dataEventoInicial, dataEventoFinal, diario })}>
                                        Memória Completa
                                </label>

                                <label
                                    role='button'
                                    className='mb-0 p-color-lighten ml-3'
                                    onClick={() => exportPrecificacaoCalculoToExcelSimplificada({ operacao, dataEventoInicial, dataEventoFinal, diario })}>
                                        Memória Simplificada
                                </label>
                            </div>
                        </div>
                    </div>
                </FormContent>
            </Form>
        </div>
    );
}

CalculoListFilter = reduxForm({ form: 'calculoForm' })(CalculoListFilter);
const selector = formValueSelector('calculoForm');

const mapStateToProps = state => ({ 
    operacao: selector(state, 'operacao'),
    dataEventoInicial: selector(state, 'dataEventoInicial'),
    dataEventoFinal: selector(state, 'dataEventoFinal'),
    diario: selector(state, 'diario'),
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
    getCalculos,
    exportPrecificacaoCalculoToExcel,
    exportPrecificacaoCalculoToExcelSimplificada,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CalculoListFilter);