/* eslint eqeqeq: 0 */

import React, { Component } from 'react';
import { faBoxOpen, faMoneyCheckAlt, faToolbox, faCreditCard, faChartBar } from '@fortawesome/free-solid-svg-icons';
import CardValueDescription from '../../../../../common/card/CardValueDescription';
import { connect } from 'react-redux';
// import { NumberUtils } from 'rb-core/lib/Utils';
import If from '../../../../../common/If/If';
import Grid from '@material-ui/core/Grid';
import { convertCurrencyNumber } from '../../../../../../utils/helpers/NumberHelper';

class AgrupamentoCard extends Component {
    render(){
        const {
            operacoes,
            saldoValue,
            seriesValue,
            novasSeriesValue,
            proximosPagamentosValue,
            proximosMonitoramentosValue,
        } = this.props;

        const idsOperacoes = operacoes.map(o => o.key);

        const gridProps = {item:true, xs:12, md:6, lg:4, xl:true, className:"pb-3", style:{padding:"16px"}}

        return(
            <Grid container className="customGrid">
                { saldoValue && saldoValue.length > 0 &&
                    saldoValue.map((item, index) => {
                            return <Grid {...gridProps} key={index}>
                                        <CardValueDescription
                                            title={convertCurrencyNumber(item.resultado, `${item.moeda} `, 2)}
                                            description="Saldo"
                                            awesomeIcon={faMoneyCheckAlt}
                                            textTooltip="Saldo em aberto"
                                        />
                                    </Grid>
                    })
                }
                <Grid {...gridProps}>
                    <CardValueDescription
                        title={seriesValue}
                        description="Séries"
                        awesomeIcon={faBoxOpen}
                        textTooltip="Operações ativas ou em execução"
                    />  
                </Grid>
                <If test={idsOperacoes.length == 0}>
                    <Grid {...gridProps}>
                        <CardValueDescription
                            title={novasSeriesValue}
                            description="Novas Séries"
                            awesomeIcon={faToolbox}
                            textTooltip="Operações ativas ou em execução emitidas nos últimos 60 dias"
                        />  
                    </Grid>
                </If>
                <Grid {...gridProps}>
                    <CardValueDescription
                        title={proximosPagamentosValue}
                        description="Próximos Pagamentos"
                        awesomeIcon={faCreditCard}
                        textTooltip="Quantidade de pagamentos previstos para os próximos 60 dias"
                    />  
                </Grid>
                <Grid {...gridProps}>
                    <CardValueDescription
                        title={proximosMonitoramentosValue}
                        description="Próximos Monitoramentos"
                        awesomeIcon={faChartBar}
                        textTooltip="Monitoramentos previstos nos próximos 60 dias"
                    />  
                </Grid>
            </Grid>
        );
    }}

const mapStateToProps = state => ({
    saldoValue: state.dashboardReducer.saldoValue || [],
    seriesValue: state.dashboardReducer.seriesValue,
    novasSeriesValue: state.dashboardReducer.novasSeriesValue,
    proximosPagamentosValue: state.dashboardReducer.proximosPagamentosValue,
    proximosMonitoramentosValue: state.dashboardReducer.proximosMonitoramentosValue,
    operacoes: state.operacaoReducer.selectedOperacoes,
});

export default connect(mapStateToProps)(AgrupamentoCard);

