import {
    COMPLEMENTO_FETCHED,
    COMPLEMENTO_SHOW_DELETE_DIALOG,
    LOAD_FORM_COMPLEMENTO,
    SHOW_MODAL_COMPLEMENTO_REGRA,
    SHOW_MODAL_COMPLEMENTO_CONTATO,
    COMPLEMENTO_SHOW_DELETE_DIALOG_CONTATO,
    CHANGE_COMPLEMENTO_ID_OPERACAO,
    SAVE_COMPLEMENTO_ID_OPERACAO,
} from '../../actions/operacao/ComplementoAction';

const INITIAL_STATE = {
    complementos: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    isLoading: false,
    messages: null,
    showComplementoDeleteDialog: {
        showDialog: false,
        idComplemento: 0
    },
    currentIdOperation: null,
    complementoFormTypes: {},
    showModalComplementoRegra: {
        showDialog: false,
        indexRegra:false,
    },
    showModalComplementoContato: {
        showDialog: false,
        indexContato:false,
    },
    showComplementoDeleteDialogContato: {
        showDialog: false,
        idComplemento: 0
    },
}

export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case COMPLEMENTO_FETCHED:
            return {
                ...state,
                complementos: action.payload.content,
                messages: action.payload.messages,
                isLoading: true
            };        
        case COMPLEMENTO_SHOW_DELETE_DIALOG:
            return {
                ...state,
                showComplementoDeleteDialog: action.payload
            };
        case LOAD_FORM_COMPLEMENTO:
            return {
                ...state,
                complementoFormTypes: action.payload
            };
        case CHANGE_COMPLEMENTO_ID_OPERACAO:
            return {
                ...state,
                complementoFormTypes: {
                    ...state?.complementoFormTypes,
                    complementoOperacao: {
                        ...state?.complementoFormTypes?.complementoOperacao,
                        idOperacao: action.payload,
                    }
                }
            };
        case SAVE_COMPLEMENTO_ID_OPERACAO:
            return {
                ...state,
                currentIdOperation: action.payload,
            };
        case SHOW_MODAL_COMPLEMENTO_REGRA:
            return {
                ...state,
                showModalComplementoRegra: action.payload
            };
        case SHOW_MODAL_COMPLEMENTO_CONTATO:
            return {
                ...state,
                showModalComplementoContato: action.payload
            };
            case COMPLEMENTO_SHOW_DELETE_DIALOG_CONTATO:
                return {
                    ...state,
                    showComplementoDeleteDialogContato: action.payload
                };            
        default:
            return state;
    }
}