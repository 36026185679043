/* eslint eqeqeq: 0 */

import { initialize } from "redux-form";
import { extractFromObj } from "../../../../utils/helpers/ObjectHelper";
import { getCurrentDateTimeStringNormal } from "../../../../utils/helpers/DateTimeHelper";

export function deserializeFieldsExportacaoFormFilter(filters, page) {
    return {
        ...filters,
        tipoItem: filters?.tipoItem?.key ? filters?.tipoItem : null,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        }
    };
}

export function serializeFieldsExportacaoForm(exportacao) {
    let exportacaoData = exportacao;
    
    if (exportacaoData) {
        exportacaoData = {
            ...exportacaoData,
            identificador: exportacao?.identificador,
            tipoPagamento: exportacao?.tipopagamento,
            dataPagamento: exportacao?.dataPagamento ? getCurrentDateTimeStringNormal(exportacao.dataPagamento) : null,
            status: exportacao?.status?.value,
        }
    }

    return initialize("ExportacaoCARForm", exportacaoData);
}

export function deserializeFieldsExportacaoForm(exportacao) {
    let exportacaoData = {};

    if (exportacao) {
        exportacaoData = {
            ...exportacao,
            ativo: exportacao?.ativo || false,
            idTipoAprovacao: extractFromObj(exportacao?.idTipoAprovacao),
            idUsuario: extractFromObj(exportacao?.idUsuario),
        }
    }

    return exportacaoData;
}