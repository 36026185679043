/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Poppers from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import NotificationIcoPlus from "../../../template/NotificationIcoPlus";
import If from "../../If/If";
import DeleteIcon from "@material-ui/icons/Delete";
import { copiarParaArea } from "../../../../utils/helpers/OthersHelpers";

class CenterNotification extends Component {
  getLocalStorage = () => {
    let storage =
      localStorage.getItem("notifications") &&
      localStorage.getItem("notifications").length > 0
        ? JSON.parse(localStorage.getItem("notifications"))
        : [];

    let currEnv = localStorage.getItem("currEnv");

    if (storage.length > 0) {
      return Array.from(storage).filter((item) => item.key == currEnv);
    } else {
      return [];
    }
  };

  state = {
    open: false,
    listNotification: this.getLocalStorage(),
  };

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  // Usado para atualiza a inclusão de novas notificações
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateStatusList();
  }

  removeAllNotifications() {
    let storage =
      localStorage.getItem("notifications") &&
      localStorage.getItem("notifications").length > 0
        ? JSON.parse(localStorage.getItem("notifications"))
        : [];

    let currEnv = localStorage.getItem("currEnv");
    let restNotifications = [];

    if (storage.length > 0)
      restNotifications = Array.from(storage).filter(
        (item) => item.key !== currEnv
      );

    // Remove os itens
    localStorage.setItem("notifications", JSON.stringify(restNotifications));
    // Atualiza o estado da lista
    this.updateStatusList();
    // Fecha modal notificações
    this.setState({ open: false });
  }

  // Remove os itens da lista
  removeNotification(index) {
    // Identificando o objeto a ser removido
    let storage =
      localStorage.getItem("notifications") != ""
        ? JSON.parse(localStorage.getItem("notifications"))
        : "";

    let currEnv = localStorage.getItem("currEnv");
    let items = [];
    let otherItems = [];

    if (storage.length > 0) {
      items = Array.from(storage).filter((item) => item.key === currEnv);
      otherItems = Array.from(storage).filter((item) => item.key !== currEnv);
    }

    let filteredItems = items
      .slice(0, index)
      .concat(items.slice(index + 1, items.length));

    let newNotifications = [...otherItems, ...filteredItems];

    // Remove os itens
    localStorage.removeItem("notifications");
    localStorage.setItem(
      "notifications",
      newNotifications.length > 0 ? JSON.stringify(newNotifications) : []
    );

    // Atualiza o estado da lista
    this.updateStatusList();
  }

  // Atualiza os estados da lista de notificações
  updateStatusList = () => {
    // Atualiza o estado da lista
    this.setState((state) => ({ listNotification: this.getLocalStorage() }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    const { open, listNotification } = this.state;

    return (
      <div>
        <div className="dropdown show-dropdown d-flex flex-row">
          <Button
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
            onClick={this.handleToggle}
          >
            <NotificationIcoPlus style={{ color: "#79C1DB" }} />
            <span className="badge badge-danger ml-1 pt-1">
              {listNotification && listNotification.length > 0
                ? listNotification.length
                : "0"}
            </span>
          </Button>
          <Poppers
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            style={{ zIndex: 9999 }}
            className="MuiPaper-elevation2-14 rounded shadow-lg"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <Paper
                      style={{ maxWidth: "500px" }}
                      className="shadow-none"
                    >
                      <MenuList
                        role="menu"
                        className="position-relative"
                        style={{
                          maxHeight: "50vh",
                          overflowY: "auto",
                          marginTop: 35,
                          marginBottom: listNotification.length > 0 ? 40 : 0,
                        }}
                      >
                        <div
                          className="w-100 bg-white position-fixed text-dark fw-400 border-bottom d-flex align-items-center px-3"
                          style={{ top: 0, height: 35, zIndex: 9999 }}
                        >
                          Notificações
                        </div>

                        <If
                          test={listNotification && listNotification.length > 0}
                        >
                          <div>
                            {listNotification && listNotification.length > 0 ? (
                              listNotification &&
                              listNotification.map((element, index) => {
                                return (
                                  <MenuItem
                                    className="align-items-center d-flex flex-row"
                                    style={{ height: "auto" }}
                                    key={index}
                                  >
                                    <span
                                      className={
                                        "mr-1 badge badge-pill badge-" +
                                        element.nome
                                      }
                                    >
                                      {" "}
                                      Mensagem{" "}
                                    </span>
                                    <p
                                      onClick={() =>
                                        copiarParaArea(
                                          element.valor,
                                          "Mensagem copiada para área de transferência",
                                        )
                                      }
                                      className="text-wrap m-0 px-3"
                                      style={{ minWidth: "350px" }}
                                    >
                                      {element.valor}
                                    </p>
                                    <DeleteIcon
                                      onClick={() =>
                                        this.removeNotification(index)
                                      }
                                      style={{ color: "#79C1DB" }}
                                    />
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem>
                                <span> Não há notificação. </span>
                              </MenuItem>
                            )}
                          </div>
                        </If>
                        <If
                          test={
                            listNotification && listNotification.length == 0
                          }
                        >
                          <MenuItem>
                            <span> Não há notificação. </span>
                          </MenuItem>
                        </If>

                        <If
                          test={listNotification && listNotification.length > 0}
                        >
                          <div
                            className="w-100 bg-white position-fixed border-top d-flex align-items-center justify-content-end px-3"
                            style={{ bottom: 0, zIndex: 9999, height: 40 }}
                          >
                            <div
                              role="button"
                              onClick={() => this.removeAllNotifications()}
                              style={{
                                height: "max-content",
                                padding: "4px 7px",
                              }}
                              className="p-color text-capitalize text-danger rounded fw-400 d-flex align-items-center justify-content-center mr-3"
                            >
                              <span>Limpar tudo</span>
                              <i className="fa fa-trash ml-2 f-16" />
                            </div>
                          </div>
                        </If>
                      </MenuList>
                    </Paper>
                  </ClickAwayListener>
                </>
              </Grow>
            )}
          </Poppers>
        </div>
      </div>
    );
  }
}

export default CenterNotification;
