/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
/* no-mixed-operators: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";

import Button from "../../../../common/button/Button";

import {
  salvaFiltrosExportacao,
  getExportacao,
  loadFilterFormExportacao,
  loadFormExportacao,
} from "../../../../../actions/exportacao/ExportacaoAction";

import {
  getOperacoes,
  selectOperacaoNoForm,
} from "../../../../../actions/operacao/OperacaoAction";
import { DropdownList } from "react-widgets";
import { CREATE, EXPORTACAO } from "../../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import Input from "../../../../common/input/Input";

let ExportacaoListFilter = (props) => {
  const {
    getExportacao,
    loadFilterFormExportacao,
    salvaFiltrosExportacao,
    exportacaoConsult,
    tipoItemsList,
    loadFormExportacao,
    authorizationFunction,
  } = props;

  const [arquivoExportacao, setArquivoExportacao] = React.useState("");
  const [dataInicio, setDataInicio] = React.useState("");
  const [dataFinal, setDataFinal] = React.useState("");
  const [tipoItem, setTipoItem] = React.useState(null);

  function searchFilters() {
    const data = {
      tipoItem,
      arquivoExportacao,
      dataInicio,
      dataFinal,
    };

    getExportacao(data);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      await loadFilterFormExportacao();
      if (!exportacaoConsult) searchFilters();
    };
  
    fetchData();
  }, []);

  // prettier-ignore
  React.useEffect(() => {
    salvaFiltrosExportacao({
      tipoItem,
      arquivoExportacao,
      dataInicio,
      dataFinal,
    })

  }, [tipoItem, arquivoExportacao, dataInicio, dataFinal]);

  const messages = {
    emptyList: "Nenhum registro encontrado",
    emptyFilter: "Nenhum registro encontrado com esse filtro",
  };

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Exportacão Protheus"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getExportacao}
        >
          <div className="row w-100 align-items-start mx-0 mt-2">
            <div className="col-xl align-self-start px-0 pr-xl-3">
              <Field
                component={Input}
                inputId="arquivoExportacao"
                name="arquivoExportacao"
                label="Arquivo de Exportação"
                value={arquivoExportacao}
                onChange={(e_, v) =>  setArquivoExportacao(v)}
              />
            </div>
            
            <div className="col-xl align-self-start px-0 pr-xl-3 mt-1">
              <div className="d-flex flex-column mt-2px">
                <label className="f-10-5 mb-1 lh-normal opacity-25 text-dark">
                  Tipo
                </label>
                <DropdownList
                  value={tipoItem?.key ? tipoItem : ""}
                  valueField="key"
                  textField="value"
                  data={[
                    { key: null, value: "Selecione..." },
                    ...tipoItemsList
                  ]}
                  containerClassName="h-38-imp"
                  messages={messages}
                  placeholder="Todos"
                  onChange={(e) => setTipoItem(e)}
                />
              </div>
            </div>

            <div className="col-xl align-self-start px-0 pr-xl-3 mt-3 mt-xl-0">
              <Field
                component={Input}
                inputId="dataInicio"
                type={"date"}
                name="dataInicio"
                label="Data Início"
                value={dataInicio}
                onChange={(e_, v) => setDataInicio(v)}
              />
            </div>

            <div className="col-xl align-self-start px-0">
              <Field
                component={Input}
                inputId="dataFinal"
                type={"date"}
                name="dataFinal"
                label="Data Final"
                value={dataFinal}
                onChange={(e_, v) => setDataFinal(v)}
              />
            </div>
          </div>

          <div className="row w-100 mx-0">
            <div className="col-xl-2 col-12 px-0 pr-xl-3">
              <Button
                activeClassName="w-100"
                action={() => searchFilters()}
                classes="p-bg-imp rounded btn-search h-38 mt-3 w-100 d-flex justify-content-center"
                nameClass="text-capitalize f-14 lh-1 mt-1px"
                name={"Buscar"}
              />
            </div>
            <div className="col-xl-2 col-12 px-0">
              <Button
                activeClassName="w-100"
                action={() =>
                  authorizationFunction({
                      idAcao: CREATE,
                      idFuncionalidade: EXPORTACAO,
                      validarAreaAtuacao: true,
                  }, () => loadFormExportacao())
                }
                classes="darkblue-bg-imp rounded btn-search h-38 mt-3 w-100 d-flex justify-content-center align-items-center"
                icon="plus-circle mr-2"
                nameClass="mt-2px text-capitalize f-14"
                name={"Criar Arquivo"}
              />
            </div>
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

ExportacaoListFilter = reduxForm({ form: "ExportacaoForm" })(ExportacaoListFilter);

const mapStateToProps = (state) => ({
  tipoItemsList: state.exportacaoReducer.exportacaoFormTypesFilter.tipoItemsList || [],
  exportacaoConsult: state.exportacaoReducer.exportacaoConsultCurrent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getExportacao,
      loadFilterFormExportacao,
      salvaFiltrosExportacao,
      getOperacoes,
      selectOperacaoNoForm,
      loadFormExportacao,
      authorizationFunction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportacaoListFilter);
