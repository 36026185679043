import {
    LOAD_FILTER_FORM_PARTICIPANTE,
    PARTICIPANTES_FETCHED,
    LOAD_FORM_PARTICIPANTE,
    PARTICIPANTES_CONSULT_CURRENT,
    SAVE_FORM_PARTICIPANTE_ERRORS,
} from "../../actions/participante/ParticipanteAction";

const INITIAL_STATE = {
    participantes: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    participanteFilter: {},
    participanteForm: {},
    messages: null,
}

export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case PARTICIPANTES_FETCHED:
            return {
                ...state,
                participantes: action.payload.result.content,
            }
        case SAVE_FORM_PARTICIPANTE_ERRORS:
            return {
              ...state,
              messages: action.payload
            }
        case LOAD_FORM_PARTICIPANTE:
            return {
                ...state,
                participanteForm: action.payload,
            }
        case LOAD_FILTER_FORM_PARTICIPANTE:
            return {
                ...state,
                participanteFilter: action.payload
            }
        case PARTICIPANTES_CONSULT_CURRENT:
            return {
                ...state,
                participanteConsultCurrent: action.payload,
                isLoading: true,
            };
        default:
            return state;
    }
}
