/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
/* no-mixed-operators: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";

import GenericPickerInput from "../../../../common/input/GenericPickerInput";
import Input from "../../../../common/input/Input";
import Button from "../../../../common/button/Button";

import {
  getAssembleias,
  loadFilterFormAssembleia,
  novaAssembleia,
} from "../../../../../actions/assembleia/AssembleiaAction";

import { getOperacoes } from "../../../../../actions/operacao/OperacaoAction";
import { getSugestaoPatrimonio } from "../../../../../actions/patrimonio/PatrimonioAction";
import { DropdownList } from "react-widgets";

let AssembleiaListFilter = (props) => {
  const {
    getAssembleias,
    loadFilterFormAssembleia,
    assembleiaStatusList,
    novaAssembleia,
    origemDemandaList,
    tipoAssembleiaList,
    assuntoList,
    assembleiaConsult,
  } = props;

  const [operacoes, setOperacoes] = React.useState([]);
  const [idPatrimonioSeparado, setIdPatrimonioSeparado] = React.useState(null);
  const [dataInicio, setDataInicio] = React.useState("");
  const [dataFim, setDataFim] = React.useState("");
  const [origemDemanda, setOrigemDemanda] = React.useState(null);
  const [tipoAssembleia, setTipoAssembleia] = React.useState(null);
  const [idAssembleiaAssunto, setIdAssembleiaAssunto] = React.useState(null);
  const [statusAssembleia, setStatusAssembleia] = React.useState([]);

  const [counterStatus, setCounterStatus] = React.useState(0);

  const chooseStatus = (item) => {
    const { key } = item;
    const updatedStatus = statusAssembleia.some(existingItem => existingItem.key === key)
      ? statusAssembleia.filter(existingItem => existingItem.key !== key)
      : [...statusAssembleia, item];
  
    setStatusAssembleia(updatedStatus);
  };

  const checkStatus = (key) => statusAssembleia?.some(o => o.key === key) ? key : "";

  async function searchFilters() {
    const data = {
      operacoes,
      idPatrimonioSeparado: idPatrimonioSeparado?.key || null,
      dataInicio,
      dataFim,
      origemDemanda,
      tipoAssembleia,
      idAssembleiaAssunto: idAssembleiaAssunto?.id || null,
      statusAssembleia,
    };

    await getAssembleias(data);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      await loadFilterFormAssembleia();
      if (!assembleiaConsult) searchFilters();
    }
    
    fetchData();
  }, []);

  React.useEffect(() => {
    setCounterStatus(assembleiaStatusList?.length);
  }, [assembleiaStatusList]);

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Assembleias"} />
        <FormContent
          handleSubmit={getAssembleias}
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
        >
          <div className="row w-100 align-items-start mx-0 mt-2">
            <div className="col-12 col-xl-6 align-self-start mt-1p mb-3 pl-0 pr-0 pr-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Patrimônio Separado
                </label>
                <GenericPickerInput
                  isClearable
                  loadData={props.getSugestaoPatrimonio}
                  options={props.patrimoniosOptions}
                  activeClassName="inputPgmt"
                  formName={"MonitoramentoForm"}
                  fieldName={"operacao"}
                  placeholder={"Pesquisar Patrimônio"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={idPatrimonioSeparado}
                  selectedOptionForm={(e) => setIdPatrimonioSeparado(e)}
                />
              </div>
            </div>

            <div className="col-12 col-xl-6 align-self-start mt-1p mb-xl-3 mb-2 pl-0 pr-0">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Operações
                </label>
                <GenericPickerInput
                  isClearable
                  loadData={(term) => props.getOperacoes(term, idPatrimonioSeparado?.key)}
                  options={props.options}
                  activeClassName="inputPgmt"
                  formName={"MonitoramentoForm"}
                  fieldName={"operacao"}
                  isMulti={true}
                  multiControl={true}
                  placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={operacoes}
                  selectedOptionForm={(e) => setOperacoes(e)}
                />
              </div>
            </div>
          </div>

          <div className="row w-100 align-items-start mx-0">
            <div className="col-12 col-xl-2 align-self-start pl-0 pr-0 pr-xl-2">
              <Field
                component={Input}
                outerClassName={"mt-4"}
                className={"filter-input"}
                inputId="dataInicio"
                type={"date"}
                name="dataInicio"
                label="Data Início"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </div>

            <div className="col-12 col-xl-2 align-self-start pl-0 pr-0 pr-xl-2">
              <Field
                component={Input}
                outerClassName={"mt-4"}
                className={"filter-input"}
                inputId="dataFim"
                type={"date"}
                name="dataFim"
                label="Data Final"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </div>

            <div className="col-12 col-xl align-self-start mt-3px mb-xl-3 mb-3 pl-0 pr-0 pr-xl-2">
              <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                Origem da Demanda
              </label>
              <DropdownList
                value={origemDemanda || ""}
                onChange={(e) => setOrigemDemanda(e?.key ? e : null)}
                valueField="key"
                textField={"value"}
                messages={{
                  emptyList: "Lista vazia",
                  emptyFilter: "Nenhum registro encontrado",
                }}
                placeholder={"Todas"}
                containerClassName="h-38-imp"
                filter={"contains"}
                data={[
                  { key: null, value: "Selecione..." },
                  ...origemDemandaList
                ]}
              />
            </div>

            <div className="col-12 col-xl align-self-start mt-3px mb-xl-3 mb-3 pl-0 pr-0 pr-xl-2">
              <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                Tipo de Assembleia
              </label>
              <DropdownList
                value={tipoAssembleia || ""}
                onChange={(e) => setTipoAssembleia(e?.key ? e : null)}
                valueField="key"
                textField={"value"}
                messages={{
                  emptyList: "Lista vazia",
                  emptyFilter: "Nenhum registro encontrado",
                }}
                placeholder={"Todos"}
                containerClassName="h-38-imp"
                filter={"contains"}
                data={[
                  { key: null, value: "Selecione..." },
                  ...tipoAssembleiaList
                ]}
              />
            </div>

            <div className="col-12 col-xl align-self-start mt-3px mb-xl-3 mb-2 pl-0 pr-0">
              <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                Assunto
              </label>
              <DropdownList
                value={idAssembleiaAssunto || ""}
                onChange={(e) => setIdAssembleiaAssunto(e?.id ? e : null)}
                valueField="id"
                textField={"assunto"}
                messages={{
                  emptyList: "Lista vazia",
                  emptyFilter: "Nenhum registro encontrado",
                }}
                placeholder={"Todos"}
                containerClassName="h-38-imp"
                filter={"contains"}
                data={[
                  { id: null, assunto: "Selecione..." },
                  ...assuntoList
                ]}
              />
            </div>
          </div>

          {assembleiaStatusList.length > 0 && (
            <div className="row w-100 align-items-center mx-0 mb-2 mt-2 pl-0 pr-3">
              <span className="d-lg-block d-none col-lg-auto fw-500 f-12 pl-0 mb-1">
                Status
              </span>
              <div className="px-0 col-12 col-lg-11 w-100 d-flex flex-row flex-wrap align-items-center">
                {assembleiaStatusList.map((sts, index) => {
                  return (
                    <div
                      key={`sts-${index}`}
                      className="col-auto mb-1 px-2 tr-status"
                    >
                      <div
                        role="button"
                        onClick={() => chooseStatus(sts)}
                        className="d-flex flex-row align-items-center td-status prevent-select"
                      >
                        <div
                          data-status={sts.key}
                          className={`ass-sts circle-status sts-ass-${checkStatus(
                            sts.key
                          )} rounded-circle mr-1`}
                        ></div>
                        <p className="mb-0 mt-1px">{sts.value}</p>
                      </div>
                    </div>
                  );
                })}

                {statusAssembleia.length < counterStatus && (
                  <span
                    role="button"
                    className="text-underline p-color-lighten mb-xl-1 ml-2"
                    onClick={() => setStatusAssembleia(assembleiaStatusList)}
                  >
                    Selecionar todos
                  </span>
                )}
                {statusAssembleia.length == counterStatus && (
                  <span
                    role="button"
                    onClick={() => setStatusAssembleia([])}
                    className="text-underline p-color-lighten mb-xl-1 ml-2"
                  >
                    Desmarcar todos
                  </span>
                )}
              </div>
            </div>
          )}

          <div className="row w-100 align-items-start mx-0 mt-2">
            <Button
              name={"Buscar"}
              action={searchFilters}
              nameClass="text-capitalize f-14 lh-1 mt-1px"
              activeClassName="col-12 col-xl-2   pl-0 pr-0 pr-xl-2"
              classes="p-bg-imp rounded btn-search h-38 mt-2 mt-xl-3 w-100 d-flex justify-content-center px-5"
            />

            <Button
              icon="plus-circle"
              name={"Criar Registro"}
              action={novaAssembleia}
              activeClassName="col-12 col-xl-2   pl-0 pr-0"
              nameClass="mt-2px text-capitalize f-14"
              classes="darkblue-bg-imp rounded btn-search h-38 mt-0 mt-xl-3 mr-1 w-100 d-flex justify-content-center align-items-center"
            />
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  options: state.operacaoReducer.operacoes,
  patrimoniosOptions: state.patrimonioReducer.patrimoniosOptions,

  assembleiaStatusList: state.assembleiaReducer.assembleiaFilter.assembleiaStatusList || [],
  origemDemandaList: state.assembleiaReducer.assembleiaFilter.origemDemandaList || [],
  tipoAssembleiaList: state.assembleiaReducer.assembleiaFilter.tipoAssembleiaList || [],
  assuntoList: state.assembleiaReducer.assembleiaFilter.assuntoList || [],
  assembleiaConsult: state.monitoramentoReducer.assembleiaConsultCurrent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAssembleias,
      loadFilterFormAssembleia,
      getOperacoes,
      getSugestaoPatrimonio,
      novaAssembleia,
    },
    dispatch
  );


const ConnectedAssembleiaFormFilter = connect(mapStateToProps, mapDispatchToProps)(AssembleiaListFilter);

export default reduxForm({ form: "AssembleiaFormFilter"})(ConnectedAssembleiaFormFilter);
