/* eslint eqeqeq: 0 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";

import {
    initializeForm,
} from '../../../../../actions/operacao/PassivoAction';

import {
    OPERACAO_TABS,
} from './../OperacaoTabs';
import PassivoForm from './form/PassivoForm';
import ContainerPage from '../../../../common/container/ContainerPage';

const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");

class PassivoVisualizePage extends Component {
    componentDidMount() {
        let idPassivo = this.props.match.params.idPassivo;
        this.props.initializeForm(idPassivo);
    }

    render() {
        const {
            operacoes,
            handleSubmit,
            gestora
        } = this.props;

        const idsOperacoes = operacoes.map(o => o.key);

        return (
            <ContainerPage
                tabs={OPERACAO_TABS}
                paramsRoute={idsOperacoes}
            >
                <PassivoForm
                    title={`Detalhar - ${gestora ? "Cota" : 'Passivo'}`}
                    handleSubmit={handleSubmit}
                    readOnly={true}
                    floatButtonVisible={false}
                />
            </ContainerPage>
        );
    }
}

const mapStateToProps = state => ({
    tab: state.tab,
    operacoes: state.operacaoReducer.selectedOperacoes,
    gestora: currEnv == process.env.REACT_APP_ENV_GEST_ID,
});

const mapDispacthToProps = dispatch => bindActionCreators({
    initializeForm,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(PassivoVisualizePage));
