import axios from 'axios';
import { change, formValueSelector, arrayInsert, arrayRemove } from "redux-form";
import { showAllMessages, showErrorMessages } from "./../../actions/message/ErrorMessagesAPIAction";
import { showConfirmDialog } from './../common/modal/ConfirmDialogAction';
import { getArrayDeserialized } from './../../utils/helpers/ArrayHelper';

import history from './../../components/common/routers/history';
import {
    OPERACAO_ROUTE_PATH,
    COMPLEMENTO_ROUTE_PATH,
    LISTA_ROUTE_PATH,
    NOVO_ROUTE_PATH,
    EDITAR_ROUTE_PATH,
    DETALHAR_ROUTE_PATH
} from "./../../components/common/routers/constantesRoutes";

import {
    serializeFieldsComplementoForm,
    deserializeFieldsComplementoForm,
    deserializeFieldsComplementoFilterExportExcel,
} from "./../../components/business/operacao/mappings/ComplementoMap";
import { downloadExcelFile } from '../../utils/helpers/FileHelper';
import { changeMessage } from '../infra/LoadingScreenAction';

export const COMPLEMENTO_FETCHED = "COMPLEMENTO_FETCHED";
export const COMPLEMENTO_SHOW_DELETE_DIALOG = 'COMPLEMENTO_SHOW_DELETE_DIALOG';
export const LOAD_FORM_COMPLEMENTO = "LOAD_FORM_COMPLEMENTO";
export const SHOW_MODAL_COMPLEMENTO_REGRA = "SHOW_MODAL_COMPLEMENTO_REGRA";
export const SHOW_MODAL_DELETE_COMPLEMENTO_REGRA = "SHOW_MODAL_DELETE_COMPLEMENTO_REGRA";
export const SHOW_MODAL_COMPLEMENTO_CONTATO = "SHOW_MODAL_COMPLEMENTO_CONTATO";
export const COMPLEMENTO_SHOW_DELETE_DIALOG_CONTATO = "COMPLEMENTO_SHOW_DELETE_DIALOG_CONTATO";
export const CHANGE_COMPLEMENTO_ID_OPERACAO = "CHANGE_COMPLEMENTO_ID_OPERACAO";
export const SAVE_COMPLEMENTO_ID_OPERACAO = "SAVE_COMPLEMENTO_ID_OPERACAO";

const OPERACAO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API;

const COMPLEMENTO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API + process.env.REACT_APP_PATH_COMPLEMENTO_API;

export function limpaDependenteOnChange(dependente, value = "") {
    return (dispatch) => {
      dispatch([change("complementoForm", dependente, value)]);
    };
}

export function changeIdOperacao(idOperacao) {
    return (dispatch) => {
      dispatch([
        {
            type: CHANGE_COMPLEMENTO_ID_OPERACAO,
            payload: idOperacao
        }
      ]);
    };
}

export function getComplementos(idsOperacoes, page = 1) {
    let params = idsOperacoes.length > 0 ? idsOperacoes : 0;

    return (dispatch) => {
        dispatch(changeMessage("Carregando Complementos"))
        return axios
            .get(OPERACAO_API + `${params}/complementos?pagina=${page}&tamanhoPagina=9`)
            .then(result =>
                dispatch({
                    type: COMPLEMENTO_FETCHED,
                    payload: result
                })
            )
    }
}

export function getComplemento(idComplementoOperacao) {
    return dispatch => {
        dispatch(changeMessage("Carregando Complemento"))
        return axios
            .get(COMPLEMENTO_API + idComplementoOperacao)
            .then(result => dispatch([serializeFieldsComplementoForm(result.content)]));
    };
}

export function saveComplemento(complemento) {
    const complementoDeserialized = deserializeFieldsComplementoForm(complemento);

    return (dispatch) => {
        dispatch(changeMessage("Salvando Complemento"))
        return axios
            .post(COMPLEMENTO_API, { ...complementoDeserialized })
            .then(result =>
                dispatch([
                    showComplementoList(),
                    showAllMessages(result.messages),
                ])
            );
    }
}

export function editComplemento(complemento) {
    const complementoDeserialized = deserializeFieldsComplementoForm(complemento);

    return (dispatch) => {
        dispatch(changeMessage("Salvando Complemento"))
        return axios
            .put(COMPLEMENTO_API, { ...complementoDeserialized })
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    showComplementoList(),
                ])
            );
    }
}

export function removeComplemento(idComplementoOperacao, idsOperacoes) {
    return (dispatch) => {
        dispatch(changeMessage("Removendo Complemento"))
        return axios
            .delete(COMPLEMENTO_API + idComplementoOperacao)
            .then(result =>
                dispatch([
                    showComplementoDeleteDialog(false, 0),
                    showComplementoList(idsOperacoes),
                    showAllMessages(result.messages),
                ])
            );
    }
}

export function loadFormComplemento(idComplementoOperacao, idOperacao, editAction, query) {
    return dispatch => {
        return axios.get(COMPLEMENTO_API + `tela/${idComplementoOperacao || ''}${query ? `?idOperacao=${query}` : ''}`)
            .then(result => {
                dispatch(changeMessage(`Carregando ${idComplementoOperacao ? 'Complemento' : 'Tela'}`))
                let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;
                dispatch([
                    idComplementoOperacao ? history.push(`/${OPERACAO_ROUTE_PATH}/${COMPLEMENTO_ROUTE_PATH}/${routeAction}/${idComplementoOperacao}`) : undefined,
                    {
                        type: LOAD_FORM_COMPLEMENTO,
                        payload: result.content
                    },
                    {
                        type: SAVE_COMPLEMENTO_ID_OPERACAO,
                        payload: result.content?.complementoOperacao?.idOperacao || null
                    },
                    serializeFieldsComplementoForm(result.content.complementoOperacao, idOperacao)
                ])
            });
    };
}

export function showComplementoList(idsOperacoes = '') {
    return dispatch => {
        return dispatch([
            showConfirmDialog(false),
            history.push(`/${OPERACAO_ROUTE_PATH}/${COMPLEMENTO_ROUTE_PATH}/${LISTA_ROUTE_PATH}/${idsOperacoes}`)
        ]);
    };
}

export function showComplementoForm(idOperacao) {
    return dispatch => {
        return dispatch([
            history.push(`/${OPERACAO_ROUTE_PATH}/${COMPLEMENTO_ROUTE_PATH}/${NOVO_ROUTE_PATH}?q=${idOperacao}`)
        ]);
    };
}

export function showComplementoDeleteDialog(showDialog, idComplemento) {
    return (dispatch) => {
        return dispatch([
            showDeleteDialog(showDialog, idComplemento),
            history.push(`/${OPERACAO_ROUTE_PATH}/${COMPLEMENTO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)
        ])
    }
}

export function showDeleteDialog(showDialog, idComplemento) {
    return {
        type: COMPLEMENTO_SHOW_DELETE_DIALOG,
        payload: { showDialog, idComplemento }
    }
}

export function filtrarTitulosComplementoDeAcordoComOTipo(itemEscolhido) {

    const tituloList = getArrayDeserialized(itemEscolhido.tituloList)

    return (dispatch) => {

        return dispatch([
            arrayRemove("complementoForm", "tituloComplementosLista", 0),
            arrayInsert("complementoForm", "tituloComplementosLista", 0, tituloList),
            change('complementoForm', 'tituloComplementosLista', tituloList)
        ]);
    }
}
function validarRegraMonitoramento(regra) {
    let errors = [];

    if (!regra || !regra.regra) {
        errors.push("O campo Regra é obrigatório")
    }

    if (!(regra) || !regra.regraAtividade) {
        errors.push("O campo Atividade é obrigatório")
    }

    if (!(regra) || !regra.regraCondicao) {
        errors.push("O campo Condição é obrigatório")
    }

    if (!(regra) || !regra.descricao) {
        errors.push("O campo Descrição é obrigatório")
    }

    if (regra && regra.regraCondicao && regra.regraCondicao.id !== 3 && !regra.valorReferencia) {
        errors.push("O campo Valor de Referência é obrigatório");
    }

    return errors;
}
export function addRegraDeMonitoramento(umaRegra) {
    let error = validarRegraMonitoramento(umaRegra)
    return (dispatch, getState) => {
        if (error.length === 0) {
            umaRegra.id = getNewId();
            const selector = formValueSelector('complementoForm');
            let listaMonitoramentoRegra = selector(getState(), 'complementoMonitoramento.monitoramentoRegrasList') || null;
            let indexAdd = listaMonitoramentoRegra != null ? listaMonitoramentoRegra.length : 0;

            return dispatch([
                arrayInsert("complementoForm", "complementoMonitoramento.monitoramentoRegrasList", indexAdd, umaRegra),
                change("complementoForm", "umaRegra", {}),
                showModalComplementoRegraDialog(false, -1)
            ]);
        } else {
            return dispatch([
                showErrorMessages(error)
            ]);
        }
    }


}
export function atualizarRegraDeMonitoramento(umaRegra, index) {
    let error = validarRegraMonitoramento(umaRegra)
    return (dispatch) => {
        if (error.length === 0) {
            return dispatch([
                change(`complementoForm`, `complementoMonitoramento.monitoramentoRegrasList[${index}]`, umaRegra),
                change("complementoForm", "umaRegra", {}),
                showModalComplementoRegraDialog(false, -1)

            ]);
        }
        else {
            return dispatch([
                showErrorMessages(error)
            ]);
        }
    }
}
export function removeRegraDeMonitoramento(index) {
    return (dispatch) => {
        return dispatch([
            arrayRemove("complementoForm", "complementoMonitoramento.monitoramentoRegrasList", index),
            showDeleteDialog(false, 0)
        ]);
    }
}

export function editarRegraDeMonitoramento(indexRegra) {
    return (dispatch, getState) => {

        const selector = formValueSelector('complementoForm')
        let listaDeRegra = selector(getState(), 'complementoMonitoramento.monitoramentoRegrasList') || null;

        return dispatch([
            change("complementoForm", "umaRegra", listaDeRegra[indexRegra]),
            showModalComplementoRegraDialog(true, indexRegra)
        ]);
    }
}
export function exportComplementoOperacaoToExcel(filters) {
    let params = deserializeFieldsComplementoFilterExportExcel(filters);

    return (dispatch) => {
        return axios
            .post(`${OPERACAO_API}complemento/exportar/excel`, { ...params })
            .then(result => {
                const fileName = 'ComplementoOperação.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function exportRegrasComplemento(idComplemento) {

    return (dispatch) => {
        return axios
            .get(`${OPERACAO_API}complemento/exportar/excel/historicomonitoramento/${idComplemento}`)
            .then(result => {
                const fileName = 'Regras de Monitoramento.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function exportModeloComplemento() {
    return (dispatch) => {
        return axios
            .get(`${OPERACAO_API}complemento/exportar/excel/modelo`)
            .then(result => {
                const fileName = 'ModeloComplemento.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function cadastroComplementoImportacaoPorExcel(file, idOperacao) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("idOperacao", idOperacao);

    return dispatch => {
        return axios
            .post(`${OPERACAO_API}complemento/importar/excel`, formData)
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                    showComplementoList()
                ])
            });
    };
}

export function showModalComplementoRegraDialog(showDialog, indexRegra) {
    return (dispatch) => {
        return dispatch([
            indexRegra < 0 && change("complementoForm", "umaRegra", {}),
            {
                type: SHOW_MODAL_COMPLEMENTO_REGRA,
                payload: { showDialog, indexRegra }
            },
        ])
    }
}

export function showModalComplementoContatoDialog(showDialog, indexContato) {
    return (dispatch) => {
        return dispatch([
            indexContato < 0 && change("complementoForm", "umContato", {}),
            {
                type: SHOW_MODAL_COMPLEMENTO_CONTATO,
                payload: { showDialog, indexContato }
            }
        ])
    }
}

export function addContatoDeMonitoramento(umContato) {
    let error = validarContatoMonitoramento(umContato)

    return (dispatch) => {
        if (error.length === 0) {
            umContato.id = getNewId();

            return dispatch([
                arrayInsert("complementoForm", "complementoMonitoramento.monitoramentoContatoList", 0, umContato),
                change("complementoForm", "umContato", {}),
                showModalComplementoContatoDialog(false, -1)
            ]);
        } else {
            return dispatch([
                showErrorMessages(error)
            ]);
        }
    }
}

export function atualizarContatoDeMonitoramento(umContato, index) {
    let error = validarContatoMonitoramento(umContato)

    return (dispatch) => {
        if (error.length === 0) {
            return dispatch([
                change(`complementoForm`, `complementoMonitoramento.monitoramentoContatoList[${index}]`, umContato),
                change("complementoForm", "umContato", {}),
                showModalComplementoContatoDialog(false, -1)
            ]);
        } else {
            return dispatch([
                showErrorMessages(error)
            ]);
        }
    }
}

export function editarContatoDeMonitoramento(indexContato) {
    return (dispatch, getState) => {
        const selector = formValueSelector('complementoForm')
        let listaDeContato = selector(getState(), 'complementoMonitoramento.monitoramentoContatoList') || null;

        return dispatch([
            change("complementoForm", "umContato", listaDeContato[indexContato]),
            showModalComplementoContatoDialog(true, indexContato)
        ]);
    }
}

export function removeContatoDeMonitoramento(index) {
    return (dispatch) => {
        return dispatch([
            arrayRemove("complementoForm", "complementoMonitoramento.monitoramentoContatoList", index),
            showDeleteDialogContato(false, 0)
        ]);
    }
}

export function showDeleteDialogContato(showDialog, idComplemento) {
    return {
        type: COMPLEMENTO_SHOW_DELETE_DIALOG_CONTATO,
        payload: { showDialog, idComplemento }
    }
}

function validarContatoMonitoramento(contato) {
    let errors = [];

    if (!contato || !contato.nome)
        errors.push("O campo Nome é obrigatório");

    if (!(contato) || !contato.email)
        errors.push("O campo Email é obrigatório");

    return errors;
}

function getNewId() {
    let date = new Date();
    let newId = `-${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`;

    return newId;
}

export function obterDataVencimentoOperacao(idOperacao) {
    return dispatch => {
        return axios.get(COMPLEMENTO_API + `datavencimentooperacao/${idOperacao}`)
            .then(result => {
                const dataVencimentoOperacao = result.content != null ? result.content.replace('T00:00:00', '') : null;

                dispatch([
                    change('complementoForm', 'complementoMonitoramento.dataValidade', dataVencimentoOperacao),
                    showAllMessages(result.messages),
                ])
            });
    };
}
