import React from "react";
import infoWarn from "./../../../../src/assets/img/info-warn.svg";
import arrowFloat from "./../../../../src/assets/img/arrow-float.svg";
import { Close } from "@material-ui/icons";

const FixedFloaterErrors = ({ outerClass, messages, title }) => {
  const [show, setShow] = React.useState(false);

  return (
    <>
      <img
        role="button"
        src={arrowFloat}
        alt="arrowFloat"
        onClick={() => setShow(!show)}
        className={`fixed-floater-arrow ${show && "hide"}`}
      />
      <div
        className={`col-12 fixed-floater-content ${!show && "hide"} ${outerClass}`}
      >
        <div className="column shadow-custom rounded-12 col-12 p-4 bg-white">
          <p className="d-flex flex-row align-items-center mb-0 justify-content-between">
            <span className="h3 page-head__title m-0">{title}</span>
            <Close role="button" onClick={() => setShow(!show)} />
          </p>
          <div className="d-flex flex-column dv-messages">
            {messages?.errors.map((msg, i) => {
              return (
                <p
                  key={`erro-${i}`}
                  className="pt-2 m-0 d-flex flex-row align-items-center fw-400"
                >
                  <img src={infoWarn} className="mr-2" alt={`img-err-${i}`} />
                  <span className="mt-2p">{msg}</span>
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default FixedFloaterErrors;
