import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import {
    Checkbox,
    Input,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select
} from '@material-ui/core';

import { backgroundColors, borderColors } from './chartColors';

class MonitoramentosChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedFilters: [],
        };
    }

    render() {
        const {
            statusMonitoramentoChartList,
            monitoramentosChartData,
        } = this.props;

        const groupedDataSorted = {};
        let tipoMonitoramentoList = [];

        if (statusMonitoramentoChartList && statusMonitoramentoChartList.length !== 0 && monitoramentosChartData && monitoramentosChartData.length !== 0) {
            // Criação de um objeto inicial agrupando por categorias
            let minDate = moment().subtract(6, 'months').format('YYYYMM');
            let maxDate = moment().add(6, 'months').format('YYYYMM');
            
            const initialGroupedData = statusMonitoramentoChartList.reduce((acc, item) => {
                return {
                    ...acc,
                    [item]: {},
                }
            }, {});

            const tipoMonitoramentoSet = {};
            monitoramentosChartData.map((item) => {
                tipoMonitoramentoSet[item.tipoMonitoramento] = null;
                if (minDate > item.mesAnoMonitoramento) minDate = item.mesAnoMonitoramento;
                if (maxDate < item.mesAnoMonitoramento) maxDate = item.mesAnoMonitoramento;
            });

            tipoMonitoramentoList = Object.keys(tipoMonitoramentoSet).sort();

            // Injeta dados nulos quando algum dado está faltando em algum período
            while (minDate <= maxDate) {
                Object.keys(initialGroupedData).forEach(key => {
                    initialGroupedData[key][minDate] = [];
                });

                minDate = moment(minDate, 'YYYYMM').add(1, 'months').format('YYYYMM');
            }

            // Filtro dos dados
            const monitoramentosFiltered = this.state.selectedFilters.length === 0
                ? monitoramentosChartData
                : monitoramentosChartData.filter((item) => this.state.selectedFilters.includes(item.tipoMonitoramento));

            // Agrupamento dos dados de acordo com as categorias
            const groupedData = monitoramentosFiltered.reduce((accumulator, item) => {
                accumulator[item.status][item.mesAnoMonitoramento].push({
                    quantidade: item.quantidade,
                    tipoMonitoramento: item.tipoMonitoramento,
                });
                return accumulator;
            }, initialGroupedData);

            // Ordenação dos dados por data
            Object.keys(groupedData).forEach((key) => {
                const orderedObj = Object.keys(groupedData[key]).sort().reduce(
                    (acc, date) => { 
                        acc[date] = groupedData[key][date]; 
                        return acc;
                    }, 
                    {}
                );

                groupedDataSorted[key] = orderedObj;
            });
        }

        const unformattedLabels = Object.keys(groupedDataSorted).length > 0
            ? Object.keys(groupedDataSorted[Object.keys(groupedDataSorted)[0]])
            : [];

        const labels = unformattedLabels.map(dateStr => moment(dateStr, 'YYYYMM').format('MMM/YY'));

        let datasetCount = 0;
        const datasets = Object.keys(groupedDataSorted).map(key => {
            datasetCount += 1;
            return {
                label: key,
                data: Object.values(groupedDataSorted[key]).map(item => {
                    return item.reduce((acc, subitem) => (acc + subitem.quantidade), 0);
                }),
                fill: false,
                backgroundColor: backgroundColors[datasetCount-1],
                borderColor: borderColors[datasetCount-1],
            };
        });

        const data = {
            labels: labels,
            datasets: datasets,
        };
        
        const options = {
            aspectRatio: 1.8,
            devicePixelRatio: 4,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                    beginAtZero: true,
                    grace: '1%',
                    ticks: {
                        precision: 0,
                    },
                },
            },
            interaction: {
                mode: 'nearest',
                // intersect: false,
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        afterLabel: (context) => {
                            return (groupedDataSorted[context.dataset.label][unformattedLabels[context.parsed.x]]).reduce((acc, item) => {
                                return acc + '\n' + item.tipoMonitoramento + ': ' + item.quantidade;
                            }, '')
                        },
                    },
                },
                legend: {
                    labels: {
                        boxWidth: 12,
                    },
                },
            },
        };
          
        const ITEM_HEIGHT = 64;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 260,
                },
            },
            MenuListProps: {
                subheader: <ListSubheader component="div">Tipos de Monitoramentos</ListSubheader>,
            }
        };

        return (
            <div className="shadow-custom rounded-12 p-3 bg-white">
                <div className='header d-flex justify-content-between'>
                    <h1 className='chart-title fw-400'>Monitoramentos</h1>
                    <Select
                        // labelId="filter-mutiple-checkbox-label"
                        id="filter-mutiple-checkbox"
                        multiple
                        displayEmpty
                        value={this.state.selectedFilters}
                        onChange={event => { this.setState({ selectedFilters: event.target.value }) }}
                        input={<Input />}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em>Filtrar...</em>;
                            }
                            return selected.join(', ');
                        }}
                        MenuProps={MenuProps}
                        disabled={tipoMonitoramentoList.length === 0}
                        style={{ minWidth: 120, maxWidth: 300 }}
                    >
                        {tipoMonitoramentoList.map((tipoMonitoramento) => (
                            <MenuItem key={tipoMonitoramento} value={tipoMonitoramento}>
                                <Checkbox checked={this.state.selectedFilters.indexOf(tipoMonitoramento) > -1} />
                                <ListItemText primary={tipoMonitoramento} />
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div className="internal-aspect-ratio">
                    <Bar type="bar" data={data} options={options} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    statusMonitoramentoChartList: state.dashboardReducer.statusMonitoramentoChartList,
    monitoramentosChartData: state.dashboardReducer.monitoramentosChartData,
});

export default withRouter(connect(mapStateToProps)(MonitoramentosChart));
