import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "../../../../../common/button/Button";
import ModalAprovarPagamento from "../modals/ModalAprovarPagamento";
import ModalRevisarPagamento from "../modals/ModalRevisarPagamento";
import ModalSemResposta from "../modals/ModalSemResposta";

let SectionEmails = ({ emails }) => {
  const [openAprovacao, setOpenAprovacao] = React.useState(false);
  const [openRevisao, setOpenRevisao] = React.useState(false);
  const [openSemResposta, setOpenSemResposta] = React.useState(false);

  return (
    <>
      <div className="card-body shadow-custom bg-white rounded-12 col-12 mt-4">
        <h4 className="page-head__title h3 mx-0 mt-0 mb-3 f-22 font-weight-bold">
          Enviado para os e-mails:
        </h4>
        <div className="row px-0 d-flex">
          <div className="col-xl-7 d-flex flex-wrap mt-2">
            {emails.map((email, index) => {
              return (
                <div
                  key={index}
                  className="mr-2 rounded-pill d-flex flex-row text-nowrap px-2 py-1 bg-df-gray align-items-center mb-2"
                >
                  <label className="p-color-imp m-0 f-12">{email}</label>
                </div>
              );
            })}
          </div>
          <div className="col-xl-5 d-flex align-items-center justify-content-xl-end mt-xl-0 mt-3">
            <Button
              name={"Aprovar Pagamento"}
              action={() => setOpenAprovacao(true)}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
            <ModalAprovarPagamento
              open={openAprovacao}
              close={() => setOpenAprovacao(false)}
            />

            <Button
              name={"Revisar Pagamento"}
              action={() => setOpenRevisao(true)}
              classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mx-2"
            />
            <ModalRevisarPagamento
              open={openRevisao}
              close={() => setOpenRevisao(false)}
            />

            <Button
              name={"Sem Resposta"}
              action={() => setOpenSemResposta(true)}
              classes="default-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
            <ModalSemResposta
              open={openSemResposta}
              close={() => setOpenSemResposta(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  //
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      //
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(SectionEmails);
