import {
    FILTER_FETCHED,
    REGULATORIOS_FETCHED,
    REGULATORIOS_CONSULT,
    LOAD_FORM_REGULATORIO,
    SAVE_FORM_REGULATORIO_ERRORS,

    CLEAR_SELECTEDS,
    XML_IDS,
} from "../../actions/relatorios/regulatorioAction";

const INITIAL_STATE = {
    regulatorios: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    relatorios: [],
    filters: {},
    messages: null,
    isLoading: false,
    selectedsCount: 0,
    idsXml: []
}

export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case FILTER_FETCHED:
            return {
                ...state,
                filters: action.payload
            }
        case REGULATORIOS_FETCHED:
            return {
                ...state,
                regulatorios: action.payload.content,
                messages: action.payload.messages,
                isLoading: true,                
            }
        case REGULATORIOS_CONSULT:
            return {
                ...state,
                regulatoriosConsult: action.payload,
                isLoading: true,
            };
        case LOAD_FORM_REGULATORIO:
            return {
                ...state,
                regulatorioForm: action.payload,
            }
        case SAVE_FORM_REGULATORIO_ERRORS:
            return {
              ...state,
              messages: action.payload
            }
        case CLEAR_SELECTEDS: {
            return {
                ...state,
                selectedsCount: state.selectedsCount += 1,
            }
        }
        case XML_IDS: {
            return {
                ...state,
                idsXml: action.payload,
            }
        }
        default:
            return state;
    }
}