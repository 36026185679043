/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  saveSubclasse,
  loadFormSubclasse,
} from "../../../../../actions/subclasse/SubclasseAction";
import SubclasseForm from "./SubclasseForm";

const SubclasseFormInclude = (props) => {
  const { readOnly, saveSubclasse, subclasseFormTypes, loadFormSubclasse } =
    props;

  React.useEffect(() => {
    if (Object.keys(subclasseFormTypes).length <= 0) loadFormSubclasse();
  }, []);

  return (
    <SubclasseForm
      title={"Nova - Subclasse"}
      onSubmit={saveSubclasse}
      readOnly={readOnly}
      floatButtonVisible={true}
    />
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveSubclasse,
      loadFormSubclasse,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  subclasseFormTypes: state.subclasseReducer.subclasseFormTypes,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubclasseFormInclude);
