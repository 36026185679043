/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dialog, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import { alteraPossuiRecurso } from "../../../../../../actions/precificacao/PagamentoAction";

let ModalSaldoDisponivel = (props) => {
  const { close, open, alteraPossuiRecurso, idPagamentoPrecificacao } = props;

  const [checked, setChecked] = React.useState("");

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        keepMounted={false}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row justify-content-between align-items-start">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Confirmação de transferência
            </p>
            <Close
              role="button"
              onClick={() => close()}
              className="text-danger mt-2"
            />
          </div>

          <p className="mt-3 mb-0 f-16 fw-400">
            O saldo para pagamento deste evento foi transferido para a conta que será utilizada para realizar a liquidação (centralizadora ou liquidação)?
          </p>

          <div className="py-2 text-dark f-16">
            <div>
              <RadioGroup
                row
                value={checked}
                name="saldoDisponivel"
                onChange={(e) =>
                  setChecked(e.target.value)
                }
              >
                <FormControlLabel
                  value="Sim"
                  label="Sim"
                  control={
                    <Radio
                      checkedIcon={<CheckCircle style={{ color: "#83BF74" }} />}
                    />
                  }
                />
                <FormControlLabel
                  value="Nao"
                  label="Não"
                  control={
                    <Radio
                      checkedIcon={<CheckCircle style={{ color: "#83BF74" }} />}
                    />
                  }
                />
              </RadioGroup>
            </div>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Confirmar"}
              action={() => {
                if (checked == "Sim") {
                  alteraPossuiRecurso({
                    idPagamentoPrecificacao,
                    possuiRecurso: true,
                  });
                }

                close();
              }}
              disabled={checked === ""}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  idPagamentoPrecificacao:
    state.pagamentoReducer.pagamentoFormTypes.idPagamentoPrecificacao,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      alteraPossuiRecurso,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ModalSaldoDisponivel);
