/* eslint no-useless-escape: 0 */

import {
  getDateInvertedFormatedFromString,
  getHourFormatedFromString,
} from "../../../../utils/helpers/DateTimeHelper";
import { initialize } from "redux-form";
import { formatCNPJToString } from "../../../../utils/helpers/StringHelper";

export function deserializeFieldsParticipanteFormFilter(filters, page) {
  return {
    ...filters,
    pagingConfig: {
      pageIndex: page || filters?.pagingConfig?.pageIndex,
      pageSize: 10,
    },
  };
}

export function serializeFieldsParticipante(dadosParticipante) {
  let { assembleiaParticipante, tipoList } = dadosParticipante;
  let serializeParticipante = {}
  
  if (assembleiaParticipante) {
    let { emailList } = assembleiaParticipante
    
    const tipos = tipoList.filter(tipo => emailList.some(({ tipo: { key } }) => key === tipo.key));
    const emailMap = emailList.reduce((acc, { tipo: { key }, id, email, idAssembleiaParticipante }) => {
      if (!acc[key]) acc[key] = [];
      acc[key].push({ id, email, idAssembleiaParticipante });
      return acc;
    }, {});

    const tiposActive = tipos.map(tipo => ({
      ...tipo,
      emailsList: emailMap[tipo.key] || [{ id: null, email: "", idAssembleiaParticipante: assembleiaParticipante.id || null }]
    }));
  
    serializeParticipante = {
      ...assembleiaParticipante,
      tipos,
      tiposActive
    };
  }

  return initialize("ParticipanteForm", serializeParticipante);
}

export function deserializeFieldsParticipante(participante) {
  const { tiposActive = [], tipos = [], id = undefined, nome, documento, tipoList = [] } = participante || {};

  const tipoMap = tipoList.reduce((acc, { tipo, id, idAssembleiaParticipante }) => {
    if (tipo && tipo.key != null) acc[tipo.key] = { id, idAssembleiaParticipante, tipo };
    return acc;
  }, {});

  const tipoListDeserialized = tipos.map(tipo => ({
    id: tipoMap[tipo.key]?.id || undefined,
    idAssembleiaParticipante: tipoMap[tipo.key]?.idAssembleiaParticipante || id || undefined,
    tipo
  }));

  const emailList = tiposActive.flatMap(tipo =>
    tipo.emailsList?.map(email => ({
      ...(email.id != null && { id: email.id }),
      ...(email.idAssembleiaParticipante != null && { idAssembleiaParticipante: email.idAssembleiaParticipante }),
      tipo: { key: tipo.key, value: tipo.value, raw: tipo.raw },
      email: email.email || ""
    })) || []
  );

  const deserialize = { id, nome, documento: formatCNPJToString(documento), tipoList: tipoListDeserialized, emailList };

  return deserialize;
}
