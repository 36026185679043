import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AtivoForm from "./AtivoForm";
import { loadFormAtivo } from "../../../../../actions/ativo/AtivoAction";

let AtivoFormVisualize = (props) => {
  const { loadFormAtivo, match } = props;

  React.useEffect(() => {
    let idAtivo = match.params.idAtivo;
    loadFormAtivo(idAtivo);
  }, []);

  return (
    <AtivoForm
      title={"Editar - Ativo"}
      readOnly={true}
      floatButtonVisible={false}
    />
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormAtivo,
    },
    dispatch
  );

export default withRouter(
  connect(null, mapDispatchToProps)(AtivoFormVisualize)
);
