import React from 'react';
import { Route, Switch } from "react-router-dom";

import {
    ASSEMBLEIA_ROUTE_PATH, LISTA_ROUTE_PATH, NOVO_ROUTE_PATH, EDITAR_ROUTE_PATH, DETALHAR_ROUTE_PATH,
} from '../../../common/routers/constantesRoutes';

import AssembleiaPage from '../pages/AssembleiaPage';
import AssembleiaIncludePage from '../pages/AssembleiaIncludePage';
import AssembleiaUpdatePage from '../pages/AssembleiaUpdatePage';

export default () => (
    <Switch>
        <Route exact={true} path={`/${ASSEMBLEIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={AssembleiaPage} />
        <Route exact={true} path={`/${ASSEMBLEIA_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={AssembleiaIncludePage} />
        <Route exact={true} path={`/${ASSEMBLEIA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idAssembleia`} component={AssembleiaUpdatePage} />
    </Switch>
);
