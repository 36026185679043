/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import Input from "../../../../../common/input/Input";
import MaskedInput from "../../../../../common/input/MaskedInput";
import { CURRENCY } from "../../../../../../utils/helpers/StringHelper";
import PercentageInput from "../../../../../common/input/PercentageInput";

const Series = (props) => {
  const {
    readOnly,
    manual,
    seriesList,
  } = props;
    console.log("🚀 → Series → seriesList: ", seriesList)

  const arrSeries = [
    { num: '2.1.1', label: 'Classe', id: 'series.classe', readOnly: true },
    { num: '2.1.2', label: 'Número da Série', id: 'series.numeroSerie', readOnly: true },
    { num: '2.2', label: 'Quantidade de Certificados', id: 'series.quantidadeCertificados', readOnly: true },

    (!readOnly && manual)
      ? { num: '2.3', label: 'Valor dos Certificados', id: 'valorCertificados', list: true }
      : { num: '2.3', label: 'Valor dos Certificados', id: 'series.valorCertificadosRead', readOnly: true },
    (!readOnly && manual)
      ? { num: '2.4', label: 'Rendimento Distribuído', id: 'rendimentoDistribuido', list: true }
      : { num: '2.4', label: 'Rendimento Distribuído', id: 'series.rendimentoDistribuidoRead', readOnly: true },
    (!readOnly && manual)
      ? { num: '2.5', label: 'Amortização Realizada', id: 'amortizacaoRealizada', list: true }
      : { num: '2.5', label: 'Amortização Realizada', id: 'series.amortizacaoRealizadaRead', readOnly: true },

    { num: '2.6', label: 'Rentabilidade no Período', id: 'series.rentabilidadeNoPeriodoRead', readOnly: true },
    { num: '2.7', label: 'Classificação de Risco', id: 'series.classificacaoRiscoRead', readOnly: true },

    !readOnly
      ? { num: '2.8.1', label: 'Índice de Subordinação Mínimo', id: 'indiceSubordinacaoMinimo', list: true, percent: true }
      : { num: '2.8.1', label: 'Índice de Subordinação Mínimo', id: 'series.indiceSubordinacaoMinimoRead', readOnly: true },
    !readOnly
      ? { num: '2.8.2', label: 'Índice de Subordinação na Data-base', id: 'indiceSubordinacaoDataBase', list: true, percent: true }
      : { num: '2.8.2', label: 'Índice de Subordinação na Data-base', id: 'series.indiceSubordinacaoDataBaseRead', readOnly: true },
  ];
  
  const fields = (arr) =>
    arr.map(({ num, label, id, readOnly, list, percent }) => {
      return (
        <div className="row d-flex align-items-center my-lg-2 mb-4 mt-2 flex-wrap" key={id}>
          <div className="col-lg-4 col-12 mb-2">
            <div className="row">
              <div className="col-lg-2 col-3 mt-lg-2 mt-0 fw-400">{num}</div>
              <div className="col-lg col-9 mt-lg-2 mt-0 fw-400 px-2">{label}</div>
            </div>
          </div>

            {list && seriesList ? (
              seriesList.map((item, i) => (
                <div className="col-lg-4 col-12 d-flex flex-row align-items-center mb-2" key={i}>
                  <span className="mr-2">{seriesList[i].numeroSerie}ª</span>
                  {percent ? (
                    <Field
                      component={PercentageInput}
                      outerClass="m-0 w-100"
                      inputId={`seriesList[${i}].[${id}]`}
                      name={`seriesList[${i}].[${id}]`}
                      readOnly={readOnly}
                      decimalScale={2}
                      decimalSeparator={","}
                      limitToHundred={true}
                    />
                  ) : (
                    <Field
                      component={MaskedInput}
                      outerClass="m-0 w-100"
                      inputId={`seriesList[${i}].[${id}]`}
                      name={`seriesList[${i}].[${id}]`}
                      readOnly={readOnly}
                      prefix="R$ "
                      placeholder={CURRENCY.placeholder}
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  )}
                </div>
              ))
            ) : (
              <div className="col-lg-8 col-12">
                <Field
                  component={Input}
                  outerClassName="m-0"
                  inputId={id}
                  name={id}
                  readOnly={readOnly}
                />
              </div>
            )
          }
        </div>
      )
    });

  return (
    <div className="container-fluid px-0">
      <div className="column shadow-custom bg-white rounded-12 col-12 px-4 pt-1 pb-3">
        <p className="h3 page-head__title my-3 mb-0">2. Séries</p>

        {fields(arrSeries)}
      </div>
    </div>
  );
};

const selector = formValueSelector("RegulatorioForm");

const mapStateToProps = (state) => ({
  seriesList: selector(state, "seriesList") || [],
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Series);
