import React, { Component } from "react";
import { connect } from 'react-redux';
import ContainerPage from "../../../../common/container/ContainerPage";
import PagamentoListFilter from "./form/PagamentoListFilter";
import PagamentoList from "./list/PagamentoList";

class PagamentoPage extends Component {
    render() {
        return (
            <ContainerPage>
                <PagamentoListFilter />
                <PagamentoList />
            </ContainerPage>
        );
    }
}

export default connect(null, null)(PagamentoPage);