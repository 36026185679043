import React from "react";
import Dialog from "@material-ui/core/Dialog";
import RegrasMonitoramentosForm from "./RegrasMonitoramentosForm";
import { connect } from "react-redux";
import {
  addRegraDeMonitoramento,
  atualizarRegraDeMonitoramento,
  showModalComplementoRegraDialog,
} from "../../../../../../../actions/operacao/ComplementoAction";
import { bindActionCreators } from "redux";
import Button from "../../../../../../common/button/Button";

function DraggableDialog(props) {
  const [umaRegra, setUmaRegra] = React.useState(null);

  const {
    showDialog,
    onClose,
    indexRegra,
    addRegraDeMonitoramento,
    atualizarRegraDeMonitoramento,
    showModalComplementoRegraDialog,
  } = props;

  return (
    <div>
      <Dialog
        open={showDialog}
        onClose={onClose}
        className="p-0 m-0"
        aria-labelledby="draggable-dialog-title"
      >
        <div className="modal-dialog modal-dialog-scrollable m-0">
          <div className="modal-content">
            <div
              className="modal-header border-bottom px-4 py-3 d-flex flex-row align-items-center"
              id="draggable-dialog-title"
            >
              <p className="mb-0 f-20 fw-500 text-dark">
                Regras de Monitoramento
              </p>
              <span
                role="button"
                className="close f-24 text-dark"
                aria-hidden="true"
                onClick={onClose}
              >
                &times;
              </span>
            </div>

            <div className="modal-body px-4 py-3">
              <RegrasMonitoramentosForm
                umaRegraChange={(regra) => setUmaRegra(regra)}
              />
            </div>

            <div className="modal-footer d-flex flex-row align-items-center justify-content-between border-top px-4 py-2">
              <Button
                name={"Cancelar"}
                activeClassName="m-0"
                action={() => showModalComplementoRegraDialog(false, -1)}
                classes="text-dark bg-white border shadow-none rounded btn-search py-2 px-3 m-0 text-capitalize f-14"
              />

              <Button
                activeClassName="m-0"
                name={indexRegra >= 0 ? "Atualizar" : "Adicionar"}
                classes="default-bg-imp rounded btn-search py-2 px-3 m-0 text-capitalize f-14"
                action={() => {
                  indexRegra >= 0
                    ? atualizarRegraDeMonitoramento(umaRegra, indexRegra)
                    : addRegraDeMonitoramento(umaRegra);
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  indexRegra: state.complementoReducer.showModalComplementoRegra.indexRegra,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addRegraDeMonitoramento,
      atualizarRegraDeMonitoramento,
      showModalComplementoRegraDialog,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DraggableDialog);
