/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
/* no-mixed-operators: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";

import GenericPickerInput from "../../../../../common/input/GenericPickerInput";
import Input from "../../../../../common/input/Input";
import Button from "../../../../../common/button/Button";
import CheckBox from "@material-ui/icons/CheckBox";

import Fade from "@material-ui/core/Fade";

import ExpandMore from "@material-ui/icons/ExpandMore";

import {
  getIndicadores,
  loadFilterFormPagamento,
  salvaFiltrosPagamento,
  getPagamentos,
} from "../../../../../../actions/precificacao/PagamentoAction";

import {
  getOperacoes,
  selectOperacaoNoForm,
} from "../../../../../../actions/operacao/OperacaoAction";
import If from "../../../../../common/If/If";
import CheckboxInput from "../../../../../common/input/CheckboxInput";
import { Chip } from "@material-ui/core";
import { Multiselect } from "react-widgets";
import { primeiroDiaMesPassado, ultimoDiaProximoMes } from "../../../../../../utils/helpers/DateTimeHelper";

let PagamentoListFilter = (props) => {
  const {
    getPagamentos,
    getIndicadores,
    loadFilterFormPagamento,
    salvaFiltrosPagamento,
    pagamentoConsult,
    ultimasEtapasList,
  } = props;

  const [checked, setChecked] = React.useState(false);
  const [idsUltimasEtapas, setIdsUltimasEtapas] = React.useState(pagamentoConsult ? [] : [2]);
  const [idsUsuarioGestor, setIdsUsuarioGestor] = React.useState([]);
  const [idsBancoLiquidante, setIdsBancoLiquidante] = React.useState([]);
  const [idsCamaraLiquidacao, setIdsCamaraLiquidacao] = React.useState([]);
  const [idsOperacao, setIdsOperacao] = React.useState([]);
  const [dataInicio, setDataInicio] = React.useState(pagamentoConsult ? "" : primeiroDiaMesPassado());
  const [dataFim, setDataFim] = React.useState(pagamentoConsult ? "" : ultimoDiaProximoMes());
  const [saldoDisponivelSim, setSaldoDisponivelSim] = React.useState(false);
  const [saldoDisponivelNao, setSaldoDisponivelNao] = React.useState(false);
  const [vencidosSim, setVencidosSim] = React.useState(false);
  const [vencidosNao, setVencidosNao] = React.useState(false);
  const [alertaSlaSim, setAlertaSlaSim] = React.useState(false);
  const [alertaSlaNao, setAlertaSlaNao] = React.useState(false);
  const [possuiRecursoNao, setPossuiRecursoNao] = React.useState(false);
  const [possuiRecursoSim, setPossuiRecursoSim] = React.useState(false);
  const [counterStatus, setCounterStatus] = React.useState(0);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const chooseStatus = (key) => {
    if (idsUltimasEtapas.includes(key)) {
      let newArrIds = idsUltimasEtapas.filter((elemento) => elemento !== key);
      setIdsUltimasEtapas(newArrIds);
    } else {
      setIdsUltimasEtapas([...idsUltimasEtapas, key]);
    }
  };

  const checkStatus = (key) => {
    return idsUltimasEtapas?.some((o) => o == key) ? key : "";
  };

  const checaBool = (sim = false, nao = false) => {
    return sim && !nao ? true : !sim && nao ? false : null;
  };

  const selecionarStatus = () => {
    const chips = Array.from(document.querySelectorAll(".pgmt-sts"));
    let arrStatus = [];

    chips.forEach((el) => {
      if (!el.classList.contains("pe-none")) arrStatus.push(Number(el.dataset.status));
    })

    setIdsUltimasEtapas(arrStatus);
  }

  const cleanStatus = () => {
    let arrStatus = [];
    setIdsUltimasEtapas(arrStatus);
  }

  async function searchFilters() {
    const data = {
      dataInicio,
      dataFim,
      idsUsuarioGestor,
      idsOperacao,
      idsBancoLiquidante,
      possuiRecurso: checaBool(possuiRecursoSim, possuiRecursoNao),
      saldoDisponivel: checaBool(saldoDisponivelSim, saldoDisponivelNao),
      vencidos: checaBool(vencidosSim, vencidosNao),
      alertaSla: checaBool(alertaSlaSim, alertaSlaNao),
      idsCamaraLiquidacao,
      ultimasEtapas: idsUltimasEtapas,
    }

    await getIndicadores(data);
    getPagamentos(data);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      await loadFilterFormPagamento();
      if (!pagamentoConsult) searchFilters();
    };
  
    fetchData();
  }, []);

  React.useEffect(() => {
    if (vencidosSim || vencidosSim && vencidosNao) {
      const arrEtapas = [];
      for (let i = 0; i < idsUltimasEtapas.length; i++) {
        if (idsUltimasEtapas[i] !== 7) arrEtapas.push(idsUltimasEtapas[i]);
      }
      setIdsUltimasEtapas(arrEtapas);
    };
  }, [vencidosSim, vencidosNao]);

  React.useEffect(() => {
    const arrEtapas = [];
    if (possuiRecursoSim && !possuiRecursoNao) {
      for (let i = 0; i < idsUltimasEtapas.length; i++) {
        if (idsUltimasEtapas[i] == 6 || idsUltimasEtapas[i] == 7) arrEtapas.push(idsUltimasEtapas[i]);
      }
      setIdsUltimasEtapas(arrEtapas);
    } else if (possuiRecursoNao && !possuiRecursoSim) {
      for (let i = 0; i < idsUltimasEtapas.length; i++) {
        if (idsUltimasEtapas[i] == 6) arrEtapas.push(idsUltimasEtapas[i]);
      }
      setIdsUltimasEtapas(arrEtapas);
    }
  }, [possuiRecursoSim, possuiRecursoNao]);

  // prettier-ignore
  React.useEffect(() => {
    salvaFiltrosPagamento({
      idsOperacao,
      dataInicio,
      dataFim,
      saldoDisponivel: checaBool(saldoDisponivelSim, saldoDisponivelNao),
      vencidos: checaBool(vencidosSim, vencidosNao),
      alertaSla: checaBool(alertaSlaSim, alertaSlaNao),
      possuiRecurso: checaBool(possuiRecursoSim, possuiRecursoNao),
      ultimasEtapas: idsUltimasEtapas,
      idsUsuarioGestor,
      idsBancoLiquidante,
      idsCamaraLiquidacao,
    })

    if (idsUltimasEtapas) {
      const chips = Array.from(document.querySelectorAll(".pgmt-sts"));
      let countBlocked = 0;

      chips.forEach((el) => {
        if (el.classList.contains("pe-none")) countBlocked++;
      })

      const resCount = (ultimasEtapasList?.length || 0) - countBlocked;
      setCounterStatus(resCount);
    }

  }, [idsOperacao, dataInicio, dataFim, saldoDisponivelSim, saldoDisponivelNao, vencidosSim, 
      vencidosNao, alertaSlaSim, alertaSlaNao, possuiRecursoSim, possuiRecursoNao, idsUltimasEtapas, 
      idsUsuarioGestor, idsBancoLiquidante, idsCamaraLiquidacao, ultimasEtapasList]);

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Etapas de Pagamento"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getPagamentos}
        >
          <div className="row w-100 align-items-start mx-0 mt-2">
            <div className="col-12 col-xl-4 align-self-start mt-1p mb-xl-3 pl-0 pr-0 pr-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Busca
                </label>
                <GenericPickerInput
                  loadData={props.getOperacoes}
                  options={props.options}
                  activeClassName="inputPgmt"
                  formName={"PagamentoForm"}
                  fieldName={"operacao"}
                  isMulti={true}
                  placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={idsOperacao}
                  selectedOptionForm={(e) => setIdsOperacao(e)}
                />
              </div>
            </div>

            <div className="col-12 col-xl-2 align-self-start mt-3 mt-xl-0 px-0 px-xl-2">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataInicio"
                type={"date"}
                name="dataInicio"
                label="Data Início"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </div>

            <div className="col-12 col-xl-2 align-self-start px-0 px-xl-2">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataFim"
                type={"date"}
                name="dataFim"
                label="Data Final"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </div>

            <div className="col-12 col-xl-4 align-self-start mt-1p px-0 pl-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Responsável pela Gestão
                </label>
                <Multiselect
                  value={idsUsuarioGestor}
                  onChange={(e) => setIdsUsuarioGestor(e)}
                  textField={"descricao"}
                  messages={{
                    emptyList: "Lista vazia",
                    emptyFilter: "Nenhum registro encontrado",
                  }}
                  placeholder={"Todos"}
                  filter={"contains"}
                  data={props.idsUsuarioGestorList}
                />
              </div>
            </div>
          </div>

          <div
            className={`w-100 mx-0 py-2 line-collapse position-relative d-flex align-items-center ${
              checked ? "mb-1" : "mb-2"
            }`}
            onClick={handleChange}
          >
            <div className="line-inter position-absolute"></div>
            <div
              role="button"
              onClick={handleChange}
              className={`position-absolute bg-white d-flex justify-content-center align-items-center rounded-circle arrow-anim-pgmt ${
                checked && "checked"
              }`}
            >
              <ExpandMore color="primary" style={{ transform: "scale(0.6)" }} />
            </div>
          </div>

          <If test={checked}>
            <Fade in={checked}>
              <>
                <div className="row w-100 align-items-start mx-0 mb-2">
                  <div className="break-xxl-expand px-0 mt-1px">
                    <div className="d-flex flex-row">
                      <div className="col pl-0 pr-2">
                        <label className="f-10-5 mb-1 opacity-25 text-dark mb-2">
                          Saldo Disponível
                        </label>
                        <div className="d-flex flex-row align-items-center cst-checkbox">
                          <div className="mr-2">
                            <Field
                              component={CheckboxInput}
                              checkedIcon={
                                <CheckBox style={{ color: "#83BF74" }} />
                              }
                              inputId="saldoDisponivelSim"
                              inputName="saldoDisponivelSim"
                              name="saldoDisponivelSim"
                              label="Sim"
                              value={saldoDisponivelSim}
                              onChange={(e) =>
                                setSaldoDisponivelSim(e.target.checked)
                              }
                            />
                          </div>
                          <div>
                            <Field
                              component={CheckboxInput}
                              checkedIcon={
                                <CheckBox style={{ color: "#83BF74" }} />
                              }
                              inputId="saldoDisponivelNao"
                              inputName="saldoDisponivelNao"
                              name="saldoDisponivelNao"
                              label="Não"
                              value={saldoDisponivelNao}
                              onChange={(e) =>
                                setSaldoDisponivelNao(e.target.checked)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col px-2">
                        <label className="f-10-5 mb-1 opacity-25 text-dark mb-2">
                          Vencidos
                        </label>
                        <div className="d-flex flex-row align-items-center cst-checkbox">
                          <div className="mr-2">
                            <Field
                              component={CheckboxInput}
                              checkedIcon={
                                <CheckBox style={{ color: "#83BF74" }} />
                              }
                              inputId="vencidosSim"
                              inputName="vencidosSim"
                              name="vencidosSim"
                              label="Sim"
                              value={vencidosSim}
                              onChange={(e) => setVencidosSim(e.target.checked)}
                            />
                          </div>
                          <div>
                            <Field
                              component={CheckboxInput}
                              checkedIcon={
                                <CheckBox style={{ color: "#83BF74" }} />
                              }
                              inputId="vencidosNao"
                              inputName="vencidosNao"
                              name="vencidosNao"
                              label="Não"
                              value={vencidosNao}
                              onChange={(e) => setVencidosNao(e.target.checked)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col px-2">
                        <label className="f-10-5 mb-1 opacity-25 text-dark mb-2">
                          Alerta SLA
                        </label>
                        <div className="d-flex flex-row align-items-center cst-checkbox">
                          <div className="mr-2">
                            <Field
                              component={CheckboxInput}
                              checkedIcon={
                                <CheckBox style={{ color: "#83BF74" }} />
                              }
                              inputId="alertaSlaSim"
                              inputName="alertaSlaSim"
                              name="alertaSlaSim"
                              label="Sim"
                              value={alertaSlaSim}
                              onChange={(e) =>
                                setAlertaSlaSim(e.target.checked)
                              }
                            />
                          </div>
                          <div>
                            <Field
                              component={CheckboxInput}
                              checkedIcon={
                                <CheckBox style={{ color: "#83BF74" }} />
                              }
                              inputId="alertaSlaNao"
                              inputName="alertaSlaNao"
                              name="alertaSlaNao"
                              label="Não"
                              value={alertaSlaNao}
                              onChange={(e) =>
                                setAlertaSlaNao(e.target.checked)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col px-2">
                        <label className="f-10-5 mb-1 opacity-25 text-dark mb-2">
                          Recurso Transferido
                        </label>
                        <div className="d-flex flex-row align-items-center cst-checkbox">
                          <div className="mr-2">
                            <Field
                              component={CheckboxInput}
                              checkedIcon={
                                <CheckBox style={{ color: "#83BF74" }} />
                              }
                              inputId="possuiRecursoSim"
                              inputName="possuiRecursoSim"
                              name="possuiRecursoSim"
                              label="Sim"
                              value={possuiRecursoSim}
                              onChange={(e) =>
                                setPossuiRecursoSim(e.target.checked)
                              }
                            />
                          </div>
                          <div>
                            <Field
                              component={CheckboxInput}
                              checkedIcon={
                                <CheckBox style={{ color: "#83BF74" }} />
                              }
                              inputId="possuiRecursoNao"
                              inputName="possuiRecursoNao"
                              name="possuiRecursoNao"
                              label="Não"
                              value={possuiRecursoNao}
                              onChange={(e) =>
                                setPossuiRecursoNao(e.target.checked)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="break-xxl-expand row align-items-start mx-0 mt-2-xxl-expand">
                    <div className="col-12 col-xl-6 align-self-start mt-1p mt-3 mt-xl-1 pl-0 pr-0 pr-xl-2 bg-white">
                      <div className="d-flex flex-column">
                        <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                          Banco Liquidante
                        </label>
                        <Multiselect
                          value={idsBancoLiquidante}
                          onChange={(e) => setIdsBancoLiquidante(e)}
                          textField={"descricao"}
                          messages={{
                            emptyList: "Lista vazia",
                            emptyFilter: "Nenhum registro encontrado",
                          }}
                          placeholder={"Todos"}
                          filter={"contains"}
                          data={props.idsBancoLiquidanteList}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-xl-6 align-self-start mt-1p mt-xl-1 pl-xl-2 pl-0 pr-0">
                      <div className="d-flex flex-column">
                        <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                          Câmara de Liquidação
                        </label>
                        <Multiselect
                          value={idsCamaraLiquidacao}
                          onChange={(e) => setIdsCamaraLiquidacao(e)}
                          textField={"descricao"}
                          messages={{
                            emptyList: "Lista vazia",
                            emptyFilter: "Nenhum registro encontrado",
                          }}
                          placeholder={"Todas"}
                          filter={"contains"}
                          data={props.idsCamaraLiquidacaoList}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </Fade>
          </If>

          {props.ultimasEtapasList.length > 0 && (
            <div className="row w-100 align-items-center mx-0 mb-2 mt-2 pl-0 pr-3">
              <span className="d-lg-block d-none col-lg-auto fw-500 f-12 pl-0 mb-1">
                Status
              </span>
              <div className="px-0 col-12 col-lg-11 w-100 d-flex flex-row flex-wrap align-items-center">
                {props.ultimasEtapasList.map((sts, index) => {
                  return (
                    <div key={`sts-${index}`} className="col-auto mb-1 px-2 tr-status">
                      <div
                        role="button"
                        onClick={() => {
                            if (
                              ((sts.key === 7) && (vencidosSim || (vencidosSim && vencidosNao))) ||
                              ((sts.key !== 6 && sts.key !== 7) && possuiRecursoSim && !possuiRecursoNao) ||
                              ((sts.key !== 6) && possuiRecursoNao && !possuiRecursoSim)
                            ) {
                              return null;
                            } else {
                              return chooseStatus(sts.key);
                            }
                          }
                        }
                        className="d-flex flex-row align-items-center td-status prevent-select"
                      >
                        <div
                          data-status={sts.key}
                          className={`
                            pgmt-sts circle-status sts-${checkStatus(sts.key)} rounded-circle mr-1
                            ${(sts.key == 7) && (vencidosSim || vencidosSim && vencidosNao) && 'pe-none opacity-50'}
                            ${(sts.key !== 6 && sts.key !== 7) && possuiRecursoSim && !possuiRecursoNao && 'pe-none opacity-50'}
                            ${(sts.key !== 6) && possuiRecursoNao && !possuiRecursoSim && 'pe-none opacity-50'}
                          `}
                        ></div>
                        <p className="mb-0 mt-1px">{sts.value}</p>
                      </div>
                    </div>
                  );
                })}

                {idsUltimasEtapas.length < counterStatus && (
                  <span onClick={selecionarStatus} role="button" className="text-underline p-color-lighten mb-xl-1 ml-2">Selecionar todos</span>
                )}
                {idsUltimasEtapas.length == counterStatus && (
                  <span onClick={cleanStatus} role="button" className="text-underline p-color-lighten mb-xl-1 ml-2">Desmarcar todos</span>
                )}
              </div>
            </div>
          )}

          <div className="col-xl-2 col-12 px-0 mt-0">
            <Button
              activeClassName="w-100"
              action={() => searchFilters()}
              classes="p-bg-imp rounded btn-search h-38 mt-3 w-100 d-flex justify-content-center"
              name={"Buscar"}
            />
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

PagamentoListFilter = reduxForm({ form: "PagamentoForm" })(PagamentoListFilter);

const mapStateToProps = (state) => ({
  options: state.operacaoReducer.operacoes,
  ultimasEtapasList:
    state.pagamentoReducer.pagamentoFormTypesFilter.ultimasEtapas || [],
  idsUsuarioGestorList:
    state.pagamentoReducer.pagamentoFormTypesFilter.idsUsuarioGestor || [],
  idsCamaraLiquidacaoList:
    state.pagamentoReducer.pagamentoFormTypesFilter.idsCamaraLiquidacao || [],
  idsBancoLiquidanteList:
    state.pagamentoReducer.pagamentoFormTypesFilter.idsBancoLiquidante || [],
  pagamentoConsult: state.pagamentoReducer.pagamentosConsultCurrent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPagamentos,
      getIndicadores,
      loadFilterFormPagamento,
      salvaFiltrosPagamento,
      getOperacoes,
      selectOperacaoNoForm,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PagamentoListFilter);
