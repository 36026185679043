import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  loadFormExportacao,
} from "../../../../actions/exportacaoContas/ExportacaoAction";

import ExportacaoForm from "./form/ExportacaoForm";

class ExportacaoIncludePage extends Component {
  componentDidMount() {
    this.props.loadFormExportacao();
  }

  render() {
    return (
      <ExportacaoForm
        title={"Nova - Exportacão"}
        readOnly={false}
        edit={false}
      />
    );
  }
}

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormExportacao,
    },
    dispatch
  );

export default withRouter(
  connect(null, mapDispacthToProps)(ExportacaoIncludePage)
);
