import React, { Component } from "react";
import { Field, formValueSelector, arrayInsert, arrayRemove } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import If from "../../../../../../common/If/If";
import Input from "../../../../../../common/input/Input";
import MaskedInput from "../../../../../../common/input/MaskedInput";

import {
  getDataPrimeiraIntegralizacao,
  calcularDistribuicaoRelacao,
  calcularDistribuicaoRelacaoGeral,
} from "../../../../../../../actions/operacao/PassivoAction";
import Button from "../../../../../../common/button/Button";
import DropdownListInput from "../../../../../../common/input/DropdownListInput";
import { createNumberMask } from "redux-form-input-masks";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const TIPO_PAGAMENTO_FLUTUANTE = 2;

class PassivoFormIntegralizacao extends Component {
  componentWillMount() {
    if (this.props.distribuicaoList.length === 0) {
      this.addCoordenador(0);
    }
  }

  addCoordenador(index) {
    if (!this.props.readOnly) {
      this.props.arrayInsert("passivoForm", "pagamentoPassivo.distribuicaoList", index, {
        idPagamentoPassivo: this.props.passivoOperacao?.pagamentoPassivo?.id
      });
    }
  }

  removeCoordenador(index) {
    if (!this.props.readOnly) {
      this.props.arrayRemove("passivoForm", "pagamentoPassivo.distribuicaoList", index);

      this.props.getDataPrimeiraIntegralizacao();
      
      if (index > 1) {
        this.props.calcularDistribuicaoRelacao(index);
      } else {
        this.props.calcularDistribuicaoRelacaoGeral();
      }
    }
  }

  addSubscricao(index, indexSubscricao) {
    if (!this.props.readOnly) {
      this.props.arrayInsert("passivoForm", `pagamentoPassivo.distribuicaoList[${index}].subscricaoList`, indexSubscricao, {});
    }
  }

  removeSubscricao(index, indexSubscricao) {
    if (!this.props.readOnly) {
      this.props.arrayRemove("passivoForm", `pagamentoPassivo.distribuicaoList[${index}].subscricaoList`, indexSubscricao);
      this.props.getDataPrimeiraIntegralizacao();
      this.props.calcularDistribuicaoRelacao(index);
    }
  }

  addIntegralizacao(index, indexSubscricao, indexIntegralizacao) {
    if (!this.props.readOnly) {
      this.props.arrayInsert("passivoForm", `pagamentoPassivo.distribuicaoList[${index}].subscricaoList[${indexSubscricao}].integralizacaoList`, indexIntegralizacao, {});
    }
  }

  removeIntegralizacao(index, indexSubscricao, indexIntegralizacao) {
    if (!this.props.readOnly) {
      this.props.arrayRemove("passivoForm", `pagamentoPassivo.distribuicaoList[${index}].subscricaoList[${indexSubscricao}].integralizacaoList`, indexIntegralizacao);
      this.props.getDataPrimeiraIntegralizacao();
      this.props.calcularDistribuicaoRelacao(index);
    }
  }

  render() {
    const {
      readOnly,
      distribuicaoList,
      getDataPrimeiraIntegralizacao,
      tipoPagamentoPassivo,
      passivoOperacao,
      moedaDinamica,
      coordenadorLiderList,
      //
      calcularDistribuicaoRelacao,
    } = this.props;

    const currencyFormat = {
      prefix: '',
      suffix: '',
      decimalPlaces: 0,
      locale: 'pt-BR',
      allowEmpty: true,
      allowNegative: true,
    }

    let moedaSimbolo = moedaDinamica || passivoOperacao?.moeda?.simbolo || "R$";
    const currencyMask = createNumberMask(currencyFormat)

    return (
      <div className="mt-4">
        <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
          <div className="column">
            <span className="page-head__title h3 mb-3">Controle</span>
            <div className="row">
              <div className="col-12 col-lg">
                <Field
                  component={Input}
                  inputId="dataInicioDistribuicao"
                  name="dataInicioDistribuicao"
                  type="date"
                  classLabel="text-nowrap"
                  label="Data Início Distribuição"
                  readOnly={readOnly}
                />
              </div>

              <div className="col-12 col-lg">
                <Field
                  component={Input}
                  inputId="dataFinalDistribuicao"
                  name="dataFinalDistribuicao"
                  type="date"
                  label="Data Final Distribuição"
                  readOnly={readOnly}
                />
              </div>
            </div>
            
            <div className="row">
              <div className="col-12 col-lg">
                <Field
                  component={MaskedInput}
                  inputId="quantidadeTotalSubscrita"
                  name="quantidadeTotalSubscrita"
                  label="Quantidade Total Subscrita"
                  readOnly={true}
                  allowNegative={false}
                  icon="info-circle"
                  iconColor="#630a3679"
                  tooltipMessage="Soma das quantidades no agrupamento integralização."
                  tooltipId="sqai"
                  required
                />
              </div>

              <div className="col-12 col-lg">
                <Field
                  component={MaskedInput}
                  inputId="quantidadeTotalIntegralizada"
                  name="quantidadeTotalIntegralizada"
                  label="Quantidade Total Integralizada"
                  readOnly={true}
                  allowNegative={false}
                  icon="info-circle"
                  iconColor="#630a3679"
                  tooltipMessage="Soma das quantidades no agrupamento integralização."
                  tooltipId="sqai"
                  required
                />
              </div>

              <div className="col-12 col-lg">
                <Field
                  component={MaskedInput}
                  inputId="volumeTotalIntegralizado"
                  name="volumeTotalIntegralizado"
                  label="Volume Total Integralizado"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={8}
                  fixedDecimalScale
                  icon="info-circle"
                  iconColor="#630a3679"
                  tooltipMessage="Quantidade Distribuída X Preço Unitário."
                  tooltipId="qd-pu"
                  required
                />
              </div>

              <div className="col-12 col-lg">
                <Field
                  component={Input}
                  inputId="dataPrimeiraIntegralizacao"
                  name="dataPrimeiraIntegralizacao"
                  type="date"
                  classLabel="text-nowrap"
                  label="Data Primeira Integralização"
                  readOnly={true}
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
          <div className="column">
            <span className="page-head__title h3 mb-3">Distribuição</span>
            <div className="d-flex flex-column w-100">
              <div className="col more-fields p-0">
                <TransitionGroup>
                  {distribuicaoList.map((item, index) => {
                    const subscricaoList = item?.subscricaoList;
                    
                    return (
                      <CSSTransition key={`dist-${index}`} timeout={300} classNames="item">
                        <div key={`dist-${index}`} className="more-fields-item border border-1 px-3 rounded">
                          <div className="row">
                            <div className="col-12 col-lg-3">
                              <Field
                                component={DropdownListInput}
                                inputId="coordenador"
                                name={`pagamentoPassivo.distribuicaoList[${index}].idCoordenador`}
                                label="Coordenador"
                                options={coordenadorLiderList}
                                textField="razaoSocialComCnpj"
                                valueField="idEmpresaPrestadora"
                                filter="contains"
                                readOnly={readOnly}
                              />
                            </div>

                            <div className="col-lg-3">
                              <Field
                                component={Input}
                                inputId="quantidadeTotalSubscrita"
                                name={`pagamentoPassivo.distribuicaoList[${index}].quantidadeTotalSubscrita`}
                                label="Quantidade Total Subscrita"
                                readOnly={true}
                                allowNegative={false}
                                type="tel"
                                {...currencyMask}
                                required
                              />
                            </div>

                            <div className="col-lg-3">
                              <Field
                                component={Input}
                                inputId="quantidadeTotalIntegralizada"
                                name={`pagamentoPassivo.distribuicaoList[${index}].quantidadeTotalIntegralizada`}
                                label="Quantidade Total Integralizada"
                                readOnly={true}
                                allowNegative={false}
                                type="tel"
                                {...currencyMask}
                                required
                              />
                            </div>

                            <If test={!readOnly}>
                              <>
                                <div className="col-12 col-lg-1 d-flex align-items-center">
                                  <Button
                                    classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize"
                                    activeClassName={"col-lg-auto p-0"}
                                    buttonStyle={"success"}
                                    action={() => this.removeCoordenador(index)}
                                    icon={"trash"}
                                    name={"Remover"}
                                  />
                                </div>
                                <div className="col-12 col-lg-2 d-flex align-items-center">
                                  <Button
                                    classes="btn btn-outline-opea bg-white shadow-none brd-p-color-imp rounded p-2 m-0 text-center text-capitalize w-100"
                                    activeClassName={"col-lg-auto p-0 w-100"}
                                    buttonStyle={"success"}
                                    action={() => this.addSubscricao(index, subscricaoList?.length || 0)}
                                    icon={"plus"}
                                    name={"Subscrição"}
                                  />
                                </div>
                              </>
                            </If>
                          </div>

                          <TransitionGroup>
                            {subscricaoList?.length > 0 && 
                              subscricaoList.map((item, iSubscricao) => {
                                const integralizacaoList = item?.integralizacaoList;

                                return (
                                  <CSSTransition key={`subs-${iSubscricao}`} timeout={300} classNames="item">
                                    <div key={`subs-${iSubscricao}`}>
                                      <div className="row">
                                        <div className="col-12 col-lg-2">
                                          <Field
                                            component={Input}
                                            inputId="dataSubscricao"
                                            inputName="dataSubscricao"
                                            name={`pagamentoPassivo.distribuicaoList[${index}].subscricaoList[${iSubscricao}].dataSubscricao`}
                                            type="date"
                                            required={true}
                                            label="Data da Subscrição"
                                            readOnly={readOnly}
                                          />
                                        </div>

                                        <div className="col-lg-2">
                                          <Field
                                            component={Input}
                                            inputId="quantidadeSubscrita"
                                            name={`pagamentoPassivo.distribuicaoList[${index}].subscricaoList[${iSubscricao}].quantidadeSubscrita`}
                                            label="Quantidade Subscrita"
                                            readOnly={readOnly}
                                            type="tel"
                                            {...currencyMask}
                                            required
                                            onBlur={() => calcularDistribuicaoRelacao(index)}
                                          />
                                        </div>

                                        <If test={!readOnly}>
                                          <>
                                            <div className="col-12 col-lg-1 d-flex align-items-center">
                                              <Button
                                                classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize"
                                                activeClassName={"col-lg-auto p-0"}
                                                buttonStyle={"success"}
                                                action={() => this.removeSubscricao(index, iSubscricao)}
                                                icon={"trash"}
                                                name={"Remover"}
                                              />
                                            </div>
                                            <div className="col-12 col-lg-2 d-flex align-items-center">
                                              <Button
                                                classes="btn btn-outline-opea bg-white shadow-none brd-p-color-imp rounded p-2 m-0 text-center text-capitalize w-100"
                                                activeClassName={"col-lg-auto p-0 w-100"}
                                                buttonStyle={"success"}
                                                action={() => this.addIntegralizacao(index, iSubscricao, integralizacaoList?.length || 0)}
                                                icon={"plus"}
                                                name={"Integralização"}
                                              />
                                            </div>
                                          </>
                                        </If>
                                      </div>

                                      <TransitionGroup>
                                        {integralizacaoList?.length > 0 && 
                                          integralizacaoList.map((item, iIntegralizacao) => {
                                            const tipoPagamentoFlutuante = tipoPagamentoPassivo && tipoPagamentoPassivo.key === TIPO_PAGAMENTO_FLUTUANTE;

                                            return (
                                              <CSSTransition key={`integ-${iIntegralizacao}`} timeout={300} classNames="item">
                                                <div className="row" key={`integ-${iIntegralizacao}`}>
                                                  <div className="col-12 col-lg">
                                                    <Field
                                                      component={Input}
                                                      inputId="dataIntegralizacao"
                                                      inputName="dataIntegralizacao"
                                                      name={`pagamentoPassivo.distribuicaoList[${index}].subscricaoList[${iSubscricao}].integralizacaoList[${iIntegralizacao}].dataIntegralizacao`}
                                                      type="date"
                                                      required={true}
                                                      label="Data Integralização"
                                                      readOnly={readOnly}
                                                      onBlur={() => getDataPrimeiraIntegralizacao()}
                                                    />
                                                  </div>

                                                  <div className="col-12 col-lg">
                                                    <Field
                                                      component={Input}
                                                      inputId="quantidadeIntegralizada"
                                                      name={`pagamentoPassivo.distribuicaoList[${index}].subscricaoList[${iSubscricao}].integralizacaoList[${iIntegralizacao}].quantidadeIntegralizada`}
                                                      label="Quantidade Integralizada"
                                                      readOnly={readOnly}
                                                      allowNegative={false}
                                                      type="tel"
                                                      {...currencyMask}
                                                      required
                                                      onBlur={() => calcularDistribuicaoRelacao(index)}
                                                    />
                                                  </div>

                                                  <div className={`col-12 col-lg`}>
                                                    <Field
                                                      component={MaskedInput}
                                                      inputId="taxaVenda"
                                                      inputName="taxaVenda"
                                                      name={`pagamentoPassivo.distribuicaoList[${index}].subscricaoList[${iSubscricao}].integralizacaoList[${iIntegralizacao}].taxaVenda`}
                                                      label="Taxa de Venda"
                                                      readOnly={readOnly}
                                                      suffix={"%"}
                                                      thousandSeparator={"."}
                                                      decimalSeparator={","}
                                                      decimalScale={4}
                                                      fixedDecimalScale
                                                    />
                                                  </div>

                                                  {tipoPagamentoFlutuante && (
                                                    <div className="col-12 col-lg">
                                                      <Field
                                                        component={MaskedInput}
                                                        inputId="percentualFlutuante"
                                                        inputName="taxaVenda"
                                                        name={`pagamentoPassivo.distribuicaoList[${index}].subscricaoList[${iSubscricao}].integralizacaoList[${iIntegralizacao}].percentualFlutuante`}
                                                        label="% Flutuante"
                                                        readOnly={readOnly}
                                                        suffix={"%"}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        decimalScale={4}
                                                        fixedDecimalScale
                                                      />
                                                    </div>
                                                  )}


                                                  <div className={`col-12 col-lg`}>
                                                    <Field
                                                      component={MaskedInput}
                                                      inputId="precoUnitario"
                                                      inputName="precoUnitario"
                                                      name={`pagamentoPassivo.distribuicaoList[${index}].subscricaoList[${iSubscricao}].integralizacaoList[${iIntegralizacao}].precoUnitario`}
                                                      required={true}
                                                      label="Preço Unitário"
                                                      readOnly={readOnly}
                                                      prefix={`${moedaSimbolo} `}
                                                      thousandSeparator={"."}
                                                      decimalSeparator={","}
                                                      decimalScale={11}
                                                      fixedDecimalScale
                                                      placeholder={"R$ 0,00000000000"}
                                                      onValueChange={() =>
                                                        calcularDistribuicaoRelacao(index)
                                                      }
                                                      blur={true}
                                                    />
                                                  </div>

                                                  <If test={!readOnly}>
                                                    <div className="col-12 col-lg-auto d-flex align-items-center mr-3 mt-2">
                                                      <Button
                                                        classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize"
                                                        activeClassName={"col-lg-auto p-0"}
                                                        buttonStyle={"success"}
                                                        action={() => this.removeIntegralizacao(index, iSubscricao, iIntegralizacao)}
                                                        icon={"trash"}
                                                        name={"Remover"}
                                                      />
                                                    </div>
                                                  </If>
                                                </div>
                                              </CSSTransition>
                                            )
                                        })}
                                      </TransitionGroup>
                                    </div>
                                  </CSSTransition>
                                )
                            })}
                          </TransitionGroup>
                        </div>
                      </CSSTransition>
                    );
                  })}
                </TransitionGroup>
              </div>
              <If test={!readOnly}>
                <div className="mx-auto mt-3">
                  <Button
                    classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                    activeClassName={"col-lg-auto p-0"}
                    buttonStyle={"success"}
                    action={() => this.addCoordenador(distribuicaoList.length)}
                    icon={"plus"}
                    name={"Adicionar Coordenador"}
                  />
                </div>
              </If>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector("passivoForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      arrayInsert,
      arrayRemove,
      getDataPrimeiraIntegralizacao,
      calcularDistribuicaoRelacao,
      calcularDistribuicaoRelacaoGeral,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  distribuicaoList: selector(state, "pagamentoPassivo.distribuicaoList") || [],
  tipoPagamentoPassivo: selector(state, "pagamentoPassivo.tipoPagamentoPassivo"),
  passivoOperacao: state.passivoReducer.passivoFormTypes.passivoOperacao || [],
  coordenadorLiderList: state.passivoReducer.passivoFormTypes.coordenadorLiderList || [],
  moedaDinamica: state.passivoReducer.moedaDinamica || null,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PassivoFormIntegralizacao);
