import axios from "axios";
import history from "../../components/common/routers/history";
import {
  deserializeFieldsExportacaoFormFilter,
  serializeFieldsExportacaoForm,
  deserializeFieldsExportacaoForm,
} from "../../components/business/exportacaoContas/mappings/ExportacaoMap";
import { showAllMessages } from "../message/ErrorMessagesAPIAction";

import {
  EXPORTACAO_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  EXPORTACAO_CONTAS_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";

import { downloadFile } from "../../utils/helpers/FileHelper";
import { showConfirmDialog } from "../common/modal/ConfirmDialogAction";

export const EXPORTACAO_RESET = "EXPORTACAO_RESET";
export const EXPORTACAO_CONSULT_CURRENT = "EXPORTACAO_CONSULT_CURRENT";
export const EXPORTACAO_FETCHED = "EXPORTACAO_FETCHED";
export const SALVA_FILTROS_EXPORTACAO = "SALVA_FILTROS_EXPORTACAO";
export const LOAD_FILTER_FORM_EXPORTACAO = "LOAD_FILTER_FORM_EXPORTACAO";
export const LOAD_FORM_EXPORTACAO = "LOAD_FORM_EXPORTACAO";

const RECEBER_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_CONTAS_RECEBER_API;

export function loadFilterFormExportacao() {
  return (dispatch, getState) => {
    return axios
      .get(`${RECEBER_API}${EXPORTACAO_ROUTE_PATH}/tela`)
      .then((result) =>
        dispatch([
          {
            type: LOAD_FILTER_FORM_EXPORTACAO,
            payload: result.content,
          },
        ])
      );
  };
}

export function salvaFiltrosExportacao(filters) {
  const params = deserializeFieldsExportacaoFormFilter(filters, 1);

  return (dispatch) => {
    return dispatch([
      {
        type: SALVA_FILTROS_EXPORTACAO,
        payload: params,
      },
    ]);
  };
}

export function getExportacao(filters, page = 1) {
  const params = deserializeFieldsExportacaoFormFilter(filters, page);

  return (dispatch) => {
    return axios
      .post(`${RECEBER_API}${EXPORTACAO_ROUTE_PATH}/busca`, params)
      .then((result) =>
        dispatch([
          {
            type: EXPORTACAO_FETCHED,
            payload: result,
          },
          {
            type: EXPORTACAO_CONSULT_CURRENT,
            payload: {
              ...filters,
              pagingConfig: { pageIndex: page, pageSize: 10 },
            },
          },
        ])
      );
  };
}

export function sairDaPagina() {
  return (dispatch) => {
    return dispatch([
      { type: EXPORTACAO_RESET },
      showConfirmDialog(false),
      history.push(
        `/${EXPORTACAO_ROUTE_PATH}/${EXPORTACAO_CONTAS_ROUTE_PATH}/${LISTA_ROUTE_PATH}`
      ),
    ]);
  };
}

export function criarArquivo(data) {
  return (dispatch) => {
    return axios
      .post(`${RECEBER_API}${EXPORTACAO_ROUTE_PATH}/iniciar`, data)
      .then((result) => {
        const id = result?.content?.id;

        dispatch([
          showAllMessages(result.messages),
          id
            ? history.push(
                `/${EXPORTACAO_ROUTE_PATH}/${EXPORTACAO_CONTAS_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/${id}`
              )
            : null,
        ]);
      });
  };
}

export function actionExportacao(data) {
  return (dispatch) => {
    return axios
      .post(`${RECEBER_API}${EXPORTACAO_ROUTE_PATH}/${data?.action}`, data)
      .then((result) => {
        dispatch([showAllMessages(result.messages)]);

        if (data?.somenteExportarArquivoCsv) {
          const { content } = result;

          dispatch([
            downloadFile(content.arquivo, `${content.nomeArquivo}.zip`),
          ]);
        } else {
          dispatch([sairDaPagina(), getExportacao()]);
        }
      });
  };
}

export function loadFormExportacao(id) {
  let appendUrl = "tela";

  if (id) appendUrl = appendUrl + `/${id}`;

  return (dispatch) => {
    return axios
      .get(`${RECEBER_API}${EXPORTACAO_ROUTE_PATH}/` + appendUrl)
      .then((result) => {
        let routeAction = id ? `${EDITAR_ROUTE_PATH}/${id}` : NOVO_ROUTE_PATH;

        dispatch([
          history.push(
            `/${EXPORTACAO_ROUTE_PATH}/${EXPORTACAO_CONTAS_ROUTE_PATH}/${routeAction}`
          ),
          {
            type: LOAD_FORM_EXPORTACAO,
            payload: result.content,
          },
          serializeFieldsExportacaoForm(result.content?.exportacao),
        ]);
      });
  };
}
