/* eslint eqeqeq: 0 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  LISTA_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  EXPORTACAO_ROUTE_PATH,
  EXPORTACAO_CONTAS_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

import ExportacaoPage from "../pages/ExportacaoPage";
import ExportacaoIncludePage from "../pages/ExportacaoIncludePage";
import ExportacaoUpdatePage from "../pages/ExportacaoUpdatePage";

export default () => (
  <Switch>
    <Route exact={true} path={`/${EXPORTACAO_ROUTE_PATH}/${EXPORTACAO_CONTAS_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={ExportacaoPage}/>
    <Route exact={true} path={`/${EXPORTACAO_ROUTE_PATH}/${EXPORTACAO_CONTAS_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={ExportacaoIncludePage} />
    <Route exact={true} path={`/${EXPORTACAO_ROUTE_PATH}/${EXPORTACAO_CONTAS_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idExportacao`} component={ExportacaoUpdatePage} />
  </Switch>
);
