/* eslint react-hooks/exhaustive-deps: 0 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SubclasseCotasEmitidas from "./SubclasseCotasEmitidas";

import { loadFormSubclasseCotasEmitidas } from "../../../../../../actions/subclasse/SubclasseAction";

const SubclasseFormCotasEmitidas = (props) => {
  const {
    readOnly,
    subclasseCotasEmitidas,
    loadFormSubclasseCotasEmitidas,
    subclasseId,
  } = props;

  useEffect(() => {
    if (subclasseId) loadFormSubclasseCotasEmitidas(subclasseId);
  }, []);

  let subclasseCodigoB3Cetip = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4 mb-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">
          Cotas Vinculadas com a Subclasse
        </span>
        <div>
          <SubclasseCotasEmitidas
            readOnly={readOnly}
            dataList={subclasseId ? subclasseCotasEmitidas : []}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="d-flex flex-column mt-4">{subclasseCodigoB3Cetip}</div>
  );
};

const mapStateToProps = (state) => ({
  subclasseId: state.subclasseReducer.subclasseFormTypes?.subClasse?.id || null,
  subclasseCotasEmitidas: state.subclasseReducer?.subclasseCotasEmitidas || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormSubclasseCotasEmitidas,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubclasseFormCotasEmitidas);
