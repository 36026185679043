import {
    USUARIOS_PORTAL_LOAD_FILTER,
    USUARIOS_PORTAL_FETCHED,
    CLEAN_LIST_CONTENT,
    ACESSO_PORTAL_LOAD_FORM,
    ACESSO_PORTAL_SHOW_ANEXO_DELETE_DIALOG
} from "../../actions/acessoPortal/AcessoPortalAction"

const INITIAL_STATE = {
    usuarios: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    usuario: {
        anexoList: []
    },
    filter: {
        usuarioPortalList: []
    },
    messages: {},
    showAnexoDeleteDialog: {
        showDialog: false,
        idUsuario: 0,
        idAnexo: 0,
        fileHash: "",
    },
}

const CLEAN_CONTENT = {
    usuarios: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    usuariosFilterList: [],
}

export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case CLEAN_LIST_CONTENT:
            return {
                ...state,
                ...CLEAN_CONTENT
            }
        case USUARIOS_PORTAL_LOAD_FILTER:
            return {
                ...state,
                filter: { ...action.payload },
            }
        case USUARIOS_PORTAL_FETCHED:
            return {
                ...state,
                usuarios: action.payload.result.content,
                messages: action.payload.result.messages,
            }
        case ACESSO_PORTAL_LOAD_FORM:
            return {
                ...state,
                usuario: action.payload
            }
        case ACESSO_PORTAL_SHOW_ANEXO_DELETE_DIALOG: 
            return {
                ...state,
                showAnexoDeleteDialog: action.payload
            }
        default:
            return state;
    }
}
