import React from "react";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Table from "./../../../../common/table/Table";
import PaginationRB from "./../../../../common/pagination/PaginationRB";
import TableHeader from "./../../../../common/table/TableHeader";
import TableContent from "./../../../../common/table/TableContent";
import TableEmptyData from "./../../../../common/table/TableEmptyData";

import { getJobs } from "../../../../../actions/job/GerenciamentoJobAction";

import { getDateFormatedFromStringWithTime } from "../../../../../utils/helpers/DateTimeHelper";
import If from "../../../../common/If/If";

const GerenciamentoJobList = (props) => {
  const { getJobs, jobData, messages, tipoJob, statusJob, dataRegistro } =
    props;

  const tableHeader = (
    <tr>
      <th className="text-center fw-700 text-nowrap">Identificador</th>
      <th className="text-center fw-700 text-nowrap">Tipo</th>
      <th className="text-center fw-700 text-nowrap">Status</th>
      <th className="text-center fw-700 text-nowrap">Data</th>
      <th className="text-center fw-700 text-nowrap">Observacao</th>
    </tr>
  );

  const emptyData = messages && messages.infos && messages.infos[0];

  const tableContent =
    jobData && jobData.hasItems ? (
      jobData.items.map((item, index) => (
        <tr key={index}>
          <td className="text-center fw-400 text-nowrap">
            {item.codigoOpea ? item.codigoOpea : item.identificador}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {item.tipoJob.value}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {item.jobStatus.value}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {getDateFormatedFromStringWithTime(item.dataInclusao)}
          </td>
          <td className="text-center fw-400 text-nowrap">{item.observacao}</td>
        </tr>
      ))
    ) : (
      <TableEmptyData message={emptyData} />
    );

  return (
    <div className="w-100 px-4 mx-3">
      <If test={jobData.totalCount > 0}>
        <Table
          withoutHeader
          classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
          totalItems={jobData.totalCount}
          pagination={
            <PaginationRB
              pageNumber={jobData.pageIndex - 1}
              itemsPerPage={jobData.pageSize}
              totalItems={jobData.totalCount}
              fetchFunction={getJobs}
              filter={{ tipoJob, statusJob, dataRegistro }}
              pageRange={5}
            />
          }
        >
          <TableHeader>{tableHeader}</TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      </If>
    </div>
  );
};

const selector = formValueSelector("gerenciamentojobForm");

const mapStateToProps = (state) => ({
  tipoJob: selector(state, "tipo"),
  statusJob: selector(state, "status"),
  dataRegistro: selector(state, "dataRegistro"),
  jobData: state.gerenciamentoJobReducer.jobs,
  messages: state.gerenciamentoJobReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getJobs,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(GerenciamentoJobList);
