import { 
  GERENCIAMENTO_JOB_FETCHED, 
  LOAD_FORM_GERENCIAMENTO_JOB,
  GERENCIAMENTO_JOB_SHOW_DIALOG,
 } from "../../actions/job/GerenciamentoJobAction";

const INITIAL_STATE = {
    jobs: {
      lastPage: 0,
      totalCount: 0,
      hasItems: false,
      pageSize: 0,
      pageIndex: 0,
      items: [],
    },
    gerenciamentoJobFormTypes:{},
    messages: null,
    showGerenciarJobDialog: {
      showDialog: false,
      tipoJob: null,
    },
}

export default function(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
      case GERENCIAMENTO_JOB_FETCHED:
        return {
            ...state,
            jobs: action.payload.content,
            messages: action.payload.messages
        }
        case LOAD_FORM_GERENCIAMENTO_JOB:
            return {
                ...state,
                gerenciamentoJobFormTypes: action.payload
        }
        case GERENCIAMENTO_JOB_SHOW_DIALOG:
          return {
            ...state,
            showGerenciarJobDialog: action.payload
          }
        
        default: 
          return state; 
    }
}