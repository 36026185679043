import {
    CALCULADORA_FETCH,
    CALCULADORA_PAGINATION,
    LIMIT_ITEMS_PER_PAGE,
    CALCULADORA_SPINNER,
    CALCULADORA_CLEAN_FORM
} from "../../actions/precificacao/CalculadoraAction";

import { deepClone } from "./../../utils/helpers/ObjectHelper";

const INITIAL_STATE = {
    data: {
        duration: null,
        codigoRb: null,
        idOperacao: null,
        taxa: null,
        percentualFlutuante: null,
        preco: null,
        eventoAgingList: [],
        excelFile: []
    },
    paggingInfo: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: []
    },
    labelTaxaPreco: 'Taxa',
    messages: null, 
    loading: false,
}

export default function (state = INITIAL_STATE, action = {}) {    
    switch(action.type) {
        case CALCULADORA_FETCH:
            const content = action.payload.aging;
            const totalCount = content && content.eventoAgingList ? 
                content.eventoAgingList.length : 0;

            let hasItems = false;
            let pageSize = 0;
            let pageIndex = 0;
            let lastPage = 0;
            let items = [];

            if (totalCount > 0) {
                hasItems = true;
                lastPage = totalCount / LIMIT_ITEMS_PER_PAGE;
                pageSize = LIMIT_ITEMS_PER_PAGE;
                pageIndex = 1;
                items = content.eventoAgingList;
            }
            return {
                ...state,
                data: {...action.payload.aging}, 
                messages: action.payload.messages,
                labelTaxaPreco: action.payload.labelTaxaPreco,
                paggingInfo: {
                    hasItems, lastPage, pageIndex, pageSize, items, totalCount
                },
            };
        case CALCULADORA_PAGINATION: 
            let newPaggingInfo = deepClone(state.paggingInfo);
            newPaggingInfo['items'] = action.payload.items;
            newPaggingInfo['pageIndex'] = action.payload.pageIndex;

            return {
                ...state,
                paggingInfo : {...newPaggingInfo}
            }
        case CALCULADORA_SPINNER:
            return {
                ...state,
                loading: action.payload
            }
        case CALCULADORA_CLEAN_FORM:
            return { ...INITIAL_STATE};
        default:
            return state;
    }

}