import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { editPatrimonio, loadFormPatrimonio } from '../../../../../actions/patrimonio/PatrimonioAction';
import PatrimonioForm from './PatrimonioForm';

class PatrimonioFormUpdate extends Component {
    componentDidMount() {
        let idPatrimonio = this.props.match.params.idPatrimonio;
        this.props.loadFormPatrimonio(idPatrimonio, true);
    }

    render() {
        const { editPatrimonio, patrimonioSeparado } = this.props;

        const statusValue = patrimonioSeparado?.statusAprovacao?.key;
        
        return (
            <PatrimonioForm 
                title={"Editar - Patrimônio"} 
                onSubmit={editPatrimonio} 
                readOnly={statusValue >= 1}
                floatButtonVisible={true} />
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    editPatrimonio,
    loadFormPatrimonio
}, dispatch);

const mapStateToProps = (state) => ({
    patrimonioSeparado: state.patrimonioReducer.patrimonioFormTypes?.patrimonioSeparado,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatrimonioFormUpdate));