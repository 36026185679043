import React from 'react';
import { Route, Switch } from "react-router-dom";
 
import { 
    RELATORIOS_ROUTE_PATH, 
    GERENCIA_ROUTE_PATH,
    ANALITICO_ROUTE_PATH,
    LISTA_ROUTE_PATH,
    REGULATORIO_ROUTE_PATH,
    EDITAR_ROUTE_PATH,
    DETALHAR_ROUTE_PATH
} from './../../../common/routers/constantesRoutes';

import ViewMainRelatorios from '../pages/gerenciais/viewMainRelatorios';
import RelatorioAnaliticoPage from '../pages/analiticos/relatorioAnaliticoPage';
import RegulatorioPage from '../pages/regulatorios/RegulatorioPage';
import RegulatorioUpdatePage from '../pages/regulatorios/RegulatorioUpdatePage';
import RegulatorioVisualizePage from '../pages/regulatorios/RegulatorioVisualizePage';

export default () => (
    <Switch>
        <Route exact={true} path={`/${RELATORIOS_ROUTE_PATH}/${GERENCIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={ViewMainRelatorios} />
        <Route exact={true} path={`/${RELATORIOS_ROUTE_PATH}/${ANALITICO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={RelatorioAnaliticoPage} />
        {/* REGULATÓRIO */}
        <Route exact={true} path={`/${RELATORIOS_ROUTE_PATH}/${REGULATORIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={RegulatorioPage} />
        <Route exact={true} path={`/${RELATORIOS_ROUTE_PATH}/${REGULATORIO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idRegulatorio`} component={RegulatorioUpdatePage} />
        <Route exact={true} path={`/${RELATORIOS_ROUTE_PATH}/${REGULATORIO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idRegulatorio`} component={RegulatorioVisualizePage} />
    </Switch>
);