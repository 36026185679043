import React from "react";
import ReactTooltip from 'react-tooltip';

import If from "../If/If";

const FormHeader = props => {
  return (
    <div className={`pt-3`}>

      <If test={props.codigoOpea}>
        <p className="mb-2 col text-right">
          <span className="badge badge-secondary p-bg-imp text-white px-2 py-1" data-for="id-op" data-tip={"Identificador da Operação"}>{props.codigoOpea}</span>
        </p>
        <ReactTooltip effect="solid" className="tooltip-custom px-2 py-1" globalEventOff="click" id="id-op" />
      </If>

      <div className={`${props.col}`}>
        {!props.noTitle && (
          <h3 className="mt-0 page-head__title f-36">
            {props.title}
          </h3>
        )}
        {props.children}
      </div>

    </div>
  );
};

export default FormHeader;
