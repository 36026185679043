/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import InfoWithLeftBar from "../../../../common/text/InfoWithLeftBar";
import CardAttachment from "../../../../common/File/CardAttachment";
import { formatCPF } from "../../../../../utils/helpers/StringHelper";
import If from "../../../../common/If/If";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";
import {
  convertFileToBase64,
  ACCEPT_FILE_OPTIONS,
} from "../../../../../utils/helpers/FileHelper";
import { showErrorMessages } from "../../../../../actions/message/ErrorMessagesAPIAction";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import {
  ACESSO_PORTAL,
  DELETE,
  UPDATE,
} from "../../../../../utils/actionsAndFeaturesAuth";

import {
  downloadDocumento,
  validarUsuario,
  cancelarValidarUsuario,
  removerDocumento,
  showAnexoDeleteDialog,
  updloadDocumentosDiversos,
  downloadDocumentoBlobFile,
  removerDocumentoBlobFile,
} from "../../../../../actions/acessoPortal/AcessoPortalAction";
import Button from "../../../../common/button/Button";

class AcessoPortalForm extends Component {
  handleDrop(evt) {
    evt.preventDefault();

    if (evt.dataTransfer) {
      let files = Array.from(evt.dataTransfer.files);
      if (files == null) return;
      else if (files.length == 0) return;

      const arrAcceptFiles = ACCEPT_FILE_OPTIONS.PDF_EXCEL_IMAGE;
      const acceptable = files.every((file) => {
        const fileExtension = `.${file.name.split(".")[1]}`;
        return arrAcceptFiles.includes(fileExtension);
      });

      if (acceptable) {
        this.uploadMultipleFiles(files);
      }
    }
  }

  handleUploadDiversos() {
    let element = document.createElement("input");
    element.type = "file";
    element.multiple = true;
    element.accept = ACCEPT_FILE_OPTIONS.PDF_EXCEL_IMAGE;
    element.onchange = (e) => {
      let files = element.files;
      if (files == null) return;
      else if (files.length == 0) return;

      this.uploadMultipleFiles(files);
    };
    element.click();
  }

  async uploadMultipleFiles(files) {
    try {
      const filesToUpload = [];
      for (let i = 0; i < files.length; i++) {
        filesToUpload.push({
          name: files[i].name,
          arquivo: await convertFileToBase64(files[i]),
          all: files[i],
        });
      }

      this.props.updloadDocumentosDiversos(
        this.props.usuario.id,
        filesToUpload,
        this.props.usuario.pastaArquivoBlob
      );
    } catch (e) {
      showErrorMessages("Erro ao importar o arquivo");
    }
  }

  render() {
    const { usuario } = this.props;
    return (
      <div className="pr-1">
        <div className="monitor-workflow px-4">
          <div className="monitor-workflow-head w-100 logbook-head px-3">
            <div className="row align-items-center pt-4">
              <div className="mt-0 page-head__title f-36 mb-2 mt-1">
                Gerenciar Acesso Portal
              </div>
            </div>
          </div>
        </div>

        <div className="monitor-workflow px-4">
          <div className="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white">
            <div className="column px-3 py-2">
              <p className="page-head__title f-22 mb-2">
                Informações do Usuário
              </p>
              <div className="row pb-1">
                <div className="col-4">
                  <InfoWithLeftBar
                    title="Nome"
                    value={usuario.nome}
                    titleClass="pl-2"
                    valueClass="pl-2 pt-1"
                    colorBar="lightgrey"
                  />
                </div>
                <div className="col-4">
                  <InfoWithLeftBar
                    title="Sobrenome"
                    value={usuario.sobreNome}
                    titleClass="pl-2"
                    valueClass="pl-2 pt-1"
                    colorBar="lightgrey"
                  />
                </div>
                <div className="col-4">
                  <InfoWithLeftBar
                    title="Sexo"
                    value={usuario.genero ? usuario.genero.value : ""}
                    titleClass="pl-2"
                    valueClass="pl-2 pt-1"
                    colorBar="lightgrey"
                  />
                </div>
              </div>
              <div className="row pb-1">
                <div className="col-4">
                  <InfoWithLeftBar
                    title="CPF"
                    value={usuario.cpf ? formatCPF(usuario.cpf) : ""}
                    titleClass="pl-2"
                    valueClass="pl-2 pt-1"
                    colorBar="lightgrey"
                  />
                </div>
                <div className="col-4">
                  <InfoWithLeftBar
                    title="E-mail"
                    value={usuario.email}
                    titleClass="pl-2"
                    valueClass="pl-2 pt-1"
                    colorBar="lightgrey"
                  />
                </div>
                <div className="col-4">
                  <InfoWithLeftBar
                    title="Participa de Assembleia"
                    value={`${usuario.participaAssembleia ? "Sim" : "Não"}`}
                    titleClass="pl-2"
                    valueClass="pl-2 pt-1"
                    colorBar="lightgrey"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4">
          <div className="column p-3 shadow-custom rounded-12 col-12 my-3 bg-white">
            <p className="page-head__title f-22 mb-2 px-2">
              Documentos do Usuário
            </p>
            <div className="row pt-2 px-2">
              <If test={!this.props.readOnly}>
                <div className="col-4 align-items-center drop-area-documentos">
                  <div
                    className="d-flex align-items-center justify-content-center attachment-upload-field white-background h-100"
                    onDrop={(e) => this.handleDrop(e)}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <div className="d-flex flex-column justify-content-center">
                      <div className="mb-2">
                        Solte arquivos aqui para enviar ou
                      </div>
                      <div className="text-center">
                        <Button
                          name={"Selecione o arquivo"}
                          action={() =>
                            this.props.authorizationFunction(
                              {
                                idAcao: UPDATE,
                                idFuncionalidade: ACESSO_PORTAL,
                              },
                              () => this.handleUploadDiversos()
                            )
                          }
                          classes="px-3 py-2 rounded p-bg-imp text-white m-0 mxh-40 my-2 text-center"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </If>

              <div
                className={`files-area ${
                  this.props.readOnly
                    ? "file-area-detalhar-acesso-portal ml-3"
                    : "col mr-2 files-area"
                }`}
              >
                <div className="d-flex flex-wrap justify-content-documents pt-1">
                  {usuario.anexoList &&
                    usuario.anexoList.map((documento, index) => {
                      return (
                        <CardAttachment
                          key={index}
                          optionalClass="m-3"
                          isDownloaded
                          label={documento.nomeArquivo}
                          onDownload={() =>
                            this.props.authorizationFunction(
                              {
                                idAcao: UPDATE,
                                idFuncionalidade: ACESSO_PORTAL,
                              },
                              () =>
                                documento.hashArquivo &&
                                documento.hashArquivo != null
                                  ? this.props.downloadDocumentoBlobFile({
                                      folderName: usuario.pastaArquivoBlob,
                                      fileHash: documento.hashArquivo,
                                      fileName: documento.nomeArquivo,
                                      userId: usuario.id,
                                    })
                                  : this.props.downloadDocumento(
                                      documento.id,
                                      documento.nomeArquivo
                                    )
                            )
                          }
                          isRemoveable={this.props.readOnly ? false : true}
                          onClick={() =>
                            this.props.showAnexoDeleteDialog(
                              true,
                              usuario.id,
                              documento.id,
                              documento.hashArquivo
                            )
                          }
                        />
                      );
                    })}
                  <ConfirmDialog
                    title="Aviso"
                    contentText={`Você realmente deseja excluir documento?`}
                    textButtonCancel="CANCELAR"
                    textButtonConfirm="CONFIRMAR"
                    showDialog={this.props.anexoDeleteDialog.showDialog}
                    onClose={() =>
                      this.props.showAnexoDeleteDialog(
                        false,
                        this.props.anexoDeleteDialog.idUsuario,
                        this.props.anexoDeleteDialog.idAnexo,
                        this.props.anexoDeleteDialog.fileHash
                      )
                    }
                    onConfirm={() =>
                      this.props.authorizationFunction(
                        { idAcao: DELETE, idFuncionalidade: ACESSO_PORTAL },
                        () =>
                          this.props.anexoDeleteDialog.fileHash &&
                          this.props.anexoDeleteDialog.fileHash != "" &&
                          this.props.anexoDeleteDialog.fileHash != null
                            ? this.props.removerDocumentoBlobFile(
                                this.props.anexoDeleteDialog.idAnexo,
                                this.props.anexoDeleteDialog.idUsuario,
                                this.props.usuario.pastaArquivoBlob,
                                this.props.anexoDeleteDialog.fileHash
                              )
                            : this.props.removerDocumento(
                                this.props.anexoDeleteDialog.idAnexo,
                                this.props.anexoDeleteDialog.idUsuario,
                                this.props.usuario.pastaArquivoBlob,
                                this.props.anexoDeleteDialog.fileHash
                              )
                      )
                    }
                    type="warning"
                    maxWidth="md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 pr-4 d-flex justify-content-end mt-3">
          <If test={!this.props.readOnly}>
            <div className="mr-3">
              <Button
                name={"Validar usuário"}
                disabled={usuario.possuiDocumentoValido}
                action={() =>
                  this.props.authorizationFunction(
                    { idAcao: UPDATE, idFuncionalidade: ACESSO_PORTAL },
                    () => this.props.validarUsuario(usuario.id)
                  )
                }
                classes="px-3 py-2 scs-bg-imp text-white rounded m-0 mxh-40 my-2 text-center text-capitalize"
              />
            </div>
          </If>
          <div>
            <Button
              name={"Cancelar"}
              action={() => this.props.cancelarValidarUsuario()}
              classes="bg-white px-3 py-2 border border-danger rounded text-danger m-0 mxh-40 h-33 my-2 text-center text-capitalize"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.acessoPortalReducer.usuario,
  anexoDeleteDialog: state.acessoPortalReducer.showAnexoDeleteDialog,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      downloadDocumento,
      validarUsuario,
      cancelarValidarUsuario,
      removerDocumento,
      showAnexoDeleteDialog,
      updloadDocumentosDiversos,
      authorizationFunction,
      downloadDocumentoBlobFile,
      removerDocumentoBlobFile,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AcessoPortalForm)
);
