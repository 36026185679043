import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import PrestadoraForm from "./form/PrestadoraForm";
import { loadFormPrestadora } from "./../../../../actions/prestadora/PrestadoraAction";
import { formValueSelector } from "redux-form";

class PrestadoraVisualizePage extends Component {
  componentDidMount() {
    let idPrestadora = this.props.match.params.idPrestadora;
    const filter = {
      cnpj: this.props.cnpj || "",
      nomeSimplificado: this.props.nomeSimplificado || "",
      pesquisou: this.props.pesquisou || false,
      page: this.props.page || 1,
    };
    this.props.loadFormPrestadora(filter, idPrestadora);
  }

  render() {
    return (
      <PrestadoraForm
        title={"Detalhar - Pessoa"}
        onSubmit={this.props.handleSubmit}
        readOnly={true}
        view={true}
        floatButtonVisible={false}
      />
    );
  }
}

const selector = formValueSelector("prestadorFilterForm");
const mapStateToProps = (state) => ({
  cnpj: selector(state, "cnpj"),
  nomeSimplificado: selector(state, "nomeSimplificado"),
  pesquisou: selector(state, "pesquisou"),
  page: selector(state, "page"),
});
const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormPrestadora,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(PrestadoraVisualizePage)
);
