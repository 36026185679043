import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { selectTab } from "../../../../../actions/operacao/tabs/TabsAction";

import OperacaoHeader from "../OperacaoHeader";
import FundoList from "./list/FundoList";
import { OPERACAO_TABS } from "../OperacaoTabs";
import { FUNDO_TAB } from "../../../../../utils/tabs";
import ContainerPage from "../../../../common/container/ContainerPage";

class ComplementoPage extends Component {
  componentDidMount() {
    this.props.selectTab(FUNDO_TAB);
  }

  render() {
    return (
      <ContainerPage
        tabs={OPERACAO_TABS}
        paramsRoute={""}
        header={<OperacaoHeader />}
      >
        <FundoList />
      </ContainerPage>
    );
  }
}

const mapStateToProps = (state) => ({
  tab: state.tab,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      selectTab,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ComplementoPage)
);
