/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "../../../../../common/button/Button";

import ModalRevisarFechamento from "../modals/ModalRevisarFechamento";
import ModalContestacaoRevisao from "../modals/ModalContestacaoRevisao";
import { downloadExcelFile } from "../../../../../../utils/helpers/FileHelper";

let SectionRevisao = (props) => {
  const { pagamento } = props;

  const [openRevisarFechamento, setOpenRevisarFechamento] =
    React.useState(false);
  const [openContestacao, setOpenContestacao] = React.useState(false);

  const statusId = pagamento?.ultimaEtapa?.id;

  return (
    <>
      <div className="card-body shadow-custom bg-white rounded-12 col-12 mt-4">
        <h4 className="page-head__title h3 mx-0 mt-0 mb-3 f-22 font-weight-bold">
          Revisão
        </h4>

        <div className="row fw-400">
          <div className="col-lg-4 col-12">
            <p className="text-gray f-10-5 mb-0">Motivo</p>
            <p>{pagamento?.tipoMotivo || "--"}</p>
          </div>
          {pagamento?.motivo && (
            <div className="col-lg-5 col-12">
              <p className="text-gray f-10-5 mb-0">Qual o motivo?</p>
              <p>{pagamento?.motivo || "--"}</p>
            </div>
          )}
          <div className="col-lg-3 col-12">
            <p className="text-gray f-10-5 mb-0">Arquivo Anexado</p>
            {pagamento?.base64ArquivoAnexoRevisao ? (
              <span
                role="button"
                className="txt-blue-link text-underline"
                onClick={() =>
                  downloadExcelFile(
                    pagamento?.base64ArquivoAnexoRevisao,
                    pagamento?.nomeArquivoAnexoRevisao
                  )
                }
              >
                <span className="mr-2">
                  {pagamento?.nomeArquivoAnexoRevisao}
                </span>
                <i className="fa fa-download f-14"></i>
              </span>
            ) : (
              <p>--</p>
            )}
          </div>
        </div>
        {pagamento?.descricaoMotivo && (
          <div className="row fw-400">
            <div className="col-12">
              <p className="text-gray f-10-5 mb-0">Descrição</p>
              <p>{pagamento?.descricaoMotivo || "--"}</p>
            </div>
          </div>
        )}

        {pagamento?.justificativaContestacao && (
          <>
            <p className="page-head__title mt-3 mb-1">Contestado pela Gestão</p>
            <div className="row fw-400">
              <div className="col-12">
                <p className="text-gray f-10-5 mb-0">Justificativa</p>
                <p>{pagamento?.justificativaContestacao || "--"}</p>
              </div>
            </div>
          </>
        )}

        {!pagamento?.emRevisao && statusId == 8 && (
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div>
              <Button
                name={"Revisar Fechamento"}
                action={() => setOpenRevisarFechamento(true)}
                classes="default-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              />
              <ModalRevisarFechamento
                open={openRevisarFechamento}
                close={() => setOpenRevisarFechamento(false)}
              />
            </div>
            <div>
              <Button
                name={"Prosseguir com Pagamento"}
                action={() => setOpenContestacao(true)}
                classes="warn-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              />
              <ModalContestacaoRevisao
                open={openContestacao}
                close={() => setOpenContestacao(false)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      //
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(SectionRevisao);
