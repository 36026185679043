/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field } from "redux-form";
import Input from "../../../../../common/input/Input";
import TextArea from "../../../../../common/input/Textarea";

const OutrosDados = (props) => {
  const { readOnly } = props;

  const arrOutros1 = [
    { num: "7.1", label: "Agência classificadora", id: "outrosDados.agenciaClassificadora", readOnly: true },
    { num: "7.2", label: "Data da última classificação", id: "outrosDados.dataDaUltimaClassificacao", readOnly: true },
  ]

  const arrOutros2 = [
    { num: "8.3", label: "Subordinação - Recomposição de índice", id: "outrosDados.subordinacaoRecomposicaoDeIndice", readOnly: readOnly },
  ];

  const arrOutros3 = [
    { num: "16.1", label: "Outras informações relevantes", id: "outrosDados.outrasInformacoesRelevantes", readOnly: readOnly },
  ]

  const arrOutros4 = [
    { num: "17.1.1", label: "Processos judiciais - Principais fatos", id: "outrosDados.processosJudiciaisPrincipaisFatos", readOnly: readOnly },
    { num: "17.1.2", label: "Processos judiciais - Valores, bens", id: "outrosDados.processosJudiciaisValoresBens", readOnly: readOnly },
    { num: "17.2", label: "Outras Contingências", id: "outrosDados.outrasContingencias", readOnly: readOnly }
  ]

  const fieldsText = (arr) => {
    return arr.map(({ num, label, id, readOnly }) =>
      <div className="row d-flex align-items-center my-lg-2 mb-4 mt-2" key={id}>
        <div className="col-1 mt-lg-2 mt-0 fw-400">{num}</div>
        <div className="col-lg-3 col-11 text-lg-left text-center mt-lg-2 mt-0 fw-400 px-2 pr-lg-2 pr-5">{label}</div>
        <div className="col-lg col-12">
          <Field
            component={Input}
            outerClassName="m-0"
            inputId={id}
            name={id}
            readOnly={readOnly}
          />
        </div>
      </div>
    );
  }

  const fieldsTextArea = (arr) => {
    return arr.map(({ num, label, id, readOnly }) =>
      <div className="row d-flex align-items-center my-lg-2 mb-4 mt-2" key={id}>
        <div className="col-1 mt-lg-2 mt-0 fw-400">{num}</div>
        <div className="col-lg-3 col-11 text-lg-left text-center mt-lg-2 mt-0 fw-400 px-2 pr-lg-2 pr-5">{label}</div>
        <div className="col-lg col-12">
          <Field
            required
            minHeight={7}
            maxLength={5000}
            readOnly={readOnly}
            outerClass="m-0"
            component={TextArea}
            name={id}
            inputId={id}
          />
        </div>
      </div>
    );
  }
  

  return (
    <div className="container-fluid px-0">
      <div>
        <div className="column shadow-custom bg-white rounded-12 col-12 px-4 pt-1 pb-3 mb-3">
          <p className="h3 page-head__title my-3 mb-0">7. Classificação de Risco</p>
          {fieldsText(arrOutros1)}
        </div>

        <div className="column shadow-custom bg-white rounded-12 col-12 px-4 pt-1 pb-3 mb-3">
          <p className="h3 page-head__title my-3 mb-0">8. Subordinação</p>
          {fieldsText(arrOutros2)}
        </div>
        
        <div className="column shadow-custom bg-white rounded-12 col-12 px-4 pt-1 pb-3 mb-3">
          <p className="h3 page-head__title my-3 mb-0">16. Informações Relevantes</p>
          {fieldsTextArea(arrOutros3)}
        </div>

        <div className="column shadow-custom bg-white rounded-12 col-12 px-4 pt-1 pb-3">
          <p className="h3 page-head__title my-3 mb-0">17. Contingências do Patrimônio Separado</p>
          {fieldsTextArea(arrOutros4)}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispacthToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(OutrosDados);
