/* eslint eqeqeq: 0 */

import { initialize } from "redux-form";

import { getDateInvertedFormatedFromString } from "../../../../utils/helpers/DateTimeHelper";
import { formatCNPJToString } from "../../../../utils/helpers/StringHelper";
import { extractFromObj } from "../../../../utils/helpers/ObjectHelper";

// prettier-ignore
export function serializeFieldsFundoForm(fundo) {
    let fundoSerialized = {};

    if(fundo) {
        fundoSerialized = {
            ...fundo,
            dataDeConstituicao: getDateInvertedFormatedFromString(fundo.dataDeConstituicao),
            dataDeVencimento: getDateInvertedFormatedFromString(fundo.dataDeVencimento),
            dataRegistro: getDateInvertedFormatedFromString(fundo.dataRegistro),
            dataDaPrimeiraIntegralizacao: getDateInvertedFormatedFromString(fundo.dataDaPrimeiraIntegralizacao),
            possuiVencimento: typeof fundo.possuiVencimento == "boolean" ? (fundo.possuiVencimento ? "Sim" : "Não") : null,
            possuiMultiGestao: typeof fundo.possuiMultiGestao == "boolean" ? (fundo.possuiMultiGestao ? "Sim" : "Não") : null,
            arquivoImagemEstrutura: fundo?.fundoEstrutura?.arquivoImagemEstrutura || "",
            nomeImagemEstrutura: fundo?.fundoEstrutura?.nomeImagemEstrutura || "",
            descricaoEstrutura: fundo?.fundoEstrutura?.descricaoEstrutura || "",
        }
    } else {
        fundoSerialized = {};
    }

    return initialize("fundoForm", fundoSerialized);
}

function verificaObjetoParticipante(obj) {
    return obj?.idTipoServicoPrestado ? obj : null; 
}

// prettier-ignore
export function deserializeFieldsFundoForm(fundo) {
    let { arquivoImagemEstrutura, nomeImagemEstrutura, descricaoEstrutura, fundoContaArrecadadoras, classes, ...newFundo } = fundo;

    if (fundoContaArrecadadoras && fundoContaArrecadadoras.length > 0) {
        fundoContaArrecadadoras = Array.from(fundoContaArrecadadoras).filter((conta) => Object.keys(conta).length > 0).map((conta) => {
            return {...conta, idBanco: extractFromObj(conta?.idBanco), idFundo: fundo.id}
        })
    }
    
    if (classes && classes.length > 0) {
        classes = Array.from(classes).filter((conta) => Object.keys(conta).length > 0).map((conta) => {
            return {...conta, idFundo: fundo.id}
        })
    }

    return {
        ...newFundo,
        idUsuarioCompliance: extractFromObj(fundo.idUsuarioCompliance),
        idUsuarioGestao: extractFromObj(fundo.idUsuarioGestao),
        idUsuarioServicoCredito: extractFromObj(fundo.idUsuarioServicoCredito),
        dataDeVencimento: fundo.dataDeVencimento ? fundo.dataDeVencimento : null,
        dataDeConstituicao: fundo.dataDeConstituicao ? fundo.dataDeConstituicao : null,
        cnpjDoFundo: formatCNPJToString(fundo.cnpjDoFundo),
        classes: classes || [],
        fundoContaArrecadadoras: fundoContaArrecadadoras || [],
        possuiVencimento: fundo.possuiVencimento ? (fundo.possuiVencimento == "Sim" ? true : false) : null,
        possuiMultiGestao: !fundo.possuiMultiGestao ? null : fundo.possuiMultiGestao == "Não" ? false : true,
        fundoEstrutura: {
            arquivoImagemEstrutura: arquivoImagemEstrutura || null,
            nomeImagemEstrutura: nomeImagemEstrutura || null,
            descricaoEstrutura: descricaoEstrutura || null,
        },
        // PARTICIPANTES
        administradorFiduciario: verificaObjetoParticipante(fundo.administradorFiduciario),
        gestora: verificaObjetoParticipante(fundo.gestora),
        gestoraII: verificaObjetoParticipante(fundo.gestoraII),
        consultorCredito: verificaObjetoParticipante(fundo.consultorCredito),
        controladoria: verificaObjetoParticipante(fundo.controladoria),
        distribuidor: verificaObjetoParticipante(fundo.distribuidor),
        escriturador: verificaObjetoParticipante(fundo.escriturador),
        custodiante: verificaObjetoParticipante(fundo.custodiante),
        agenteMonitoramento: verificaObjetoParticipante(fundo.agenteMonitoramento),
        agenteFormalizacao: verificaObjetoParticipante(fundo.agenteFormalizacao),
        assessorLegal: verificaObjetoParticipante(fundo.assessorLegal),
        agenteCobrancaJudicial: verificaObjetoParticipante(fundo.agenteCobrancaJudicial),
        agenteCobrancaExtrajudicial: verificaObjetoParticipante(fundo.agenteCobrancaExtrajudicial),
        auditoria: verificaObjetoParticipante(fundo.auditoria),
    };
}
