/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field } from "redux-form";
import MaskedInput from "../../../../../common/input/MaskedInput";
import { CURRENCY } from "../../../../../../utils/helpers/StringHelper";

const Desembolsos = (props) => {
  const { readOnly, manual } = props;

  const arrDesembolsos = [
    { num: '14.1', label: 'Cronograma de Despesas', id: 'desembolso.cronogramaDeDespesas', readOnly: true },
    { num: '14.1.1', label: 'Até 30 dias', id: 'desembolso.despesasAte30Dias', readOnly: readOnly },
    { num: '14.1.2', label: 'De 31 a 60 dias', id: 'desembolso.despesasDe31a60Dias', readOnly: readOnly },
    { num: '14.1.3', label: 'De 61 a 90 dias', id: 'desembolso.despesasDe61a90Dias', readOnly: readOnly },
    { num: '14.1.4', label: 'De 91 a 120 dias', id: 'desembolso.despesasDe91a120Dias', readOnly: readOnly },
    { num: '14.1.5', label: 'De 121 a 150 dias', id: 'desembolso.despesasDe121a150Dias', readOnly: readOnly },
    { num: '14.1.6', label: 'De 151 a 180 dias', id: 'desembolso.despesasDe151a180Dias', readOnly: readOnly },
    { num: '14.1.7', label: 'De 181 a 360 dias', id: 'desembolso.despesasDe181a360Dias', readOnly: readOnly },
    { num: '14.1.8', label: 'Acima de 361 dias', id: 'desembolso.despesasAcimaDe361Dias', readOnly: readOnly },
    { num: '14.2', label: 'Cronograma Pagamento Investidores', id: 'desembolso.cronogramaPagamentoInvestidores', readOnly: true },
    { num: '14.2.1', label: 'Até 30 dias', id: 'desembolso.investidoresAte30Dias', readOnly: ((!readOnly && !manual) || readOnly) },
    { num: '14.2.2', label: 'De 31 a 60 dias', id: 'desembolso.investidoresDe31a60Dias', readOnly: ((!readOnly && !manual) || readOnly) },
    { num: '14.2.3', label: 'De 61 a 90 dias', id: 'desembolso.investidoresDe61a90Dias', readOnly: ((!readOnly && !manual) || readOnly) },
    { num: '14.2.4', label: 'De 91 a 120 dias', id: 'desembolso.investidoresDe91a120Dias', readOnly: ((!readOnly && !manual) || readOnly) },
    { num: '14.2.5', label: 'De 121 a 150 dias', id: 'desembolso.investidoresDe121a150Dias', readOnly: ((!readOnly && !manual) || readOnly) },
    { num: '14.2.6', label: 'De 151 a 180 dias', id: 'desembolso.investidoresDe151a180Dias', readOnly: ((!readOnly && !manual) || readOnly) },
    { num: '14.2.7', label: 'De 181 a 360 dias', id: 'desembolso.investidoresDe181a360Dias', readOnly: ((!readOnly && !manual) || readOnly) },
    { num: '14.2.8', label: 'Acima de 361 dias', id: 'desembolso.investidoresAcimaDe361Dias', readOnly: ((!readOnly && !manual) || readOnly) }
  ]

  const fields = (arr) => {
    return arr.map(({ num, label, id, readOnly }) =>
      id ? (
        <div className="row d-flex align-items-center my-lg-2 mb-4 mt-2" key={id}>
          <div className="col-1 mt-lg-2 mt-0 fw-400">{num}</div>
          <div className="col-lg-3 col-11 text-lg-left text-center mt-lg-2 mt-0 fw-400 px-2 pr-lg-2 pr-5">{label}</div>
          <div className="col-lg col-12">
            <Field
              component={MaskedInput}
              outerClass="m-0"
              inputId={id}
              name={id}
              readOnly={readOnly}
              prefix="R$ "
              placeholder={CURRENCY.placeholder}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
            />
          </div>
        </div>
      ) : (
        <div className="row d-flex align-items-center my-2 py-1" key={num}>
          <div className="col-1 mt-lg-2 mt-0 fw-400">{num}</div>
          <div className="col-lg-3 col-11 text-lg-left text-center mt-lg-2 mt-0 fw-400 px-2 pr-lg-2 pr-5">{label}</div>
        </div>
      )
    );
  }

  return (
    <div className="container-fluid px-0">
      <div className="column shadow-custom bg-white rounded-12 col-12 px-4 pt-1 pb-3">
      <p className="h3 page-head__title my-3 mb-0">14. Desembolsos</p>

        {fields(arrDesembolsos)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispacthToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(Desembolsos);
