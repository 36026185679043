import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector, Field } from 'redux-form';

import logo from './../../../../assets/img/LOGO_OPEA_NEG_RGB.svg';
import Button from './../../../common/button/Button';

import { savePrimeiroAcesso } from './../../../../actions/login/LoginAction';
import Message from './../../../common/messages/Message';

let LoginPrimeiroAcessoPage = (props) => {

    const {
        savePrimeiroAcesso,
        primeiroAcesso,
        email,
        cpf,
        senha,
    } = props;
    
    return primeiroAcesso ? (
        <div class="login-page">
            <div class="page-header header-filter">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 ml-auto mr-auto">
                            <div class="card card-login">
                                <form class="form">
                                    <div class="card-header card-header-warning text-center">
                                        <img src={logo} title="Logo Opea" alt="Logo Opea" style={{width: "70%", objectFit: "cover"}} />    
                                    </div>
                                    <div className="card-body off-set-top-50">
                                        <div className="input-group"  style={{ border: "1px solid #9F7E7D", borderRadius: 6 }}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ backgroundColor: "#A57C7C", color: "#fff", border: "none" }}>
                                                    <i className="material-icons">email</i>
                                                </span>
                                            </div>
                                            <Field
                                                className="form-control"
                                                name="email"
                                                component="input"
                                                type="email"
                                                readOnly={true}
                                                disabled={true}
                                                style={{ color: '#A57C7C', paddingLeft: 4, paddingRight: 4 }}
                                            />
                                        </div>
                                        <div className="input-group" style={{ marginTop: 8, border: "1px solid #9F7E7D", borderRadius: 6 }}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ backgroundColor: "#A57C7C", color: "#fff", border: "none" }}>
                                                    <i className="material-icons">account_circle</i>
                                                </span>
                                            </div>
                                            <Field
                                                className="form-control"
                                                name="cpf"
                                                component="input"
                                                type="text"
                                                readOnly={true}
                                                disabled={true}
                                                style={{ color: '#A57C7C', paddingLeft: 4, paddingRight: 4 }}
                                            />
                                        </div>
                                        <div className="input-group" style={{ marginTop: 8, border: "1px solid #9F7E7D", borderRadius: 6 }}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ backgroundColor: "#A57C7C", color: "#fff", border: "none" }}>
                                                    <i className="material-icons">lock_outline</i>
                                                </span>
                                            </div>
                                            <Field
                                                className="form-control"
                                                name="senha"
                                                component="input"
                                                type="password"
                                                placeholder="Senha"
                                                style={{ color: '#A57C7C', paddingLeft: 4, paddingRight: 4 }}
                                            />
                                        </div>
                                        <div className="input-group">
                                            {/* <a href="#" title="Esqueci minha senha" className="esqueci-minha-senha">Esqueci minha senha.</a> */}
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div style={{ width: "100%" }}>
                                            <Button 
                                                action={() => savePrimeiroAcesso({email, cpf, senha})}
                                                buttonStyle={"success"}
                                                backgroundButtonColor={true}
                                                name={"Salvar"}
                                                style={{ color: "#630A37", backgroundColor: "#79C1DB", width: "100%", marginBottom: 0 }}
                                                containerProps={{ style: { width: "100%" } }}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Message />
        </div>
    ) : null;
}

LoginPrimeiroAcessoPage = reduxForm({ form: 'loginPrimeiroAcessoForm' })(LoginPrimeiroAcessoPage);
const selector = formValueSelector('loginPrimeiroAcessoForm');

LoginPrimeiroAcessoPage = connect(
    state => ({
      initialValues: state.loginReducer.login
    }),
)(LoginPrimeiroAcessoPage)

const mapStateToProps = state => ({ 
    email: selector(state, 'email'),
    cpf: selector(state, 'cpf'),
    senha: selector(state, 'senha'),
    primeiroAcesso: selector(state, 'primeiroAcesso'),
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
    savePrimeiroAcesso,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginPrimeiroAcessoPage);