/* eslint eqeqeq: 0 */

import axios from "axios";
import { change, formValueSelector, reset } from "redux-form";

import { selectTab } from "../operacao/tabs/TabsAction";
import { showAllMessages } from "../message/ErrorMessagesAPIAction";
import { FUNDO_TAB } from "../../utils/tabs";
import { showConfirmDialog } from "../common/modal/ConfirmDialogAction";
import { downloadFile } from "../../utils/helpers/FileHelper";

import history from "../../components/common/routers/history";
import {
  DETALHAR_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  FUNDO_ROUTE_PATH,
  LISTA_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";

import {
  serializeFieldsFundoForm,
  deserializeFieldsFundoForm,
} from "../../components/business/fundo/mappings/FundoMap";

export const SERVICER = 7;

export const FUNDO_FORM_TAB = "FUNDO_FORM_TAB";
export const SELECTED_FUNDO_OPTION = "SELECTED_FUNDO_OPTION";
export const FUNDO_OPTIONS_FETCHED = "FUNDO_OPTIONS_FETCHED";
export const FUNDO_FETCHED = "FUNDO_FETCHED";
export const FUNDO_SHOW_LIST = "FUNDO_SHOW_LIST";
export const FUNDO_SHOW_DELETE_DIALOG = "FUNDO_SHOW_DELETE_DIALOG";
export const LOAD_FORM_FUNDO = "LOAD_FORM_FUNDO";
export const LOAD_FORM_FUNDO_SUBCLASSES = "LOAD_FORM_FUNDO_SUBCLASSES";
export const LOAD_FORM_FUNDO_COTAS = "LOAD_FORM_FUNDO_COTAS";
export const FUNDO_CLEAN_LIST = "FUNDO_CLEAN_LIST";
export const FUNDO_INSERT_NOME_PASTA_CEDOC = "FUNDO_INSERT_NOME_PASTA_CEDOC";
export const CEDOC_ID = "CEDOC_ID";
export const LOAD_TAB_FUNDOS_COTAS = "LOAD_TAB_FUNDOS_COTAS";
export const FUNDO_PAGINATION = "FUNDO_PAGINATION";
export const RESET = "RESET";
export const ULTIMO_VINCULO_CEDOC = "ULTIMO_VINCULO_CEDOC";

const FUNDO_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_FUNDO;

const OPERACAO_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API;

const PASSIVO_API = OPERACAO_API + process.env.REACT_APP_PATH_PASSIVO_API;

export function inserirNomePastaCedoc() {
  return (dispatch) => {
    return dispatch([
      { type: FUNDO_INSERT_NOME_PASTA_CEDOC, payload: { showDialog: true } },
    ]);
  };
}

export function closeModalInserirNomePastaCedoc(ultimoVinculoSalvo, original) {
  return (dispatch) => {
    return dispatch([
      !ultimoVinculoSalvo ? change("fundoForm", "idCedoc", ultimoVinculoSalvo == "" ? ultimoVinculoSalvo : original || "") : change("fundoForm", "idCedoc", ultimoVinculoSalvo || ""),
      {
        type: FUNDO_INSERT_NOME_PASTA_CEDOC,
        payload: { showDialog: false },
      },
    ]);
  };
}

export function changeCedocId(cedocId) {
  return (dispatch, getState) => {
    const selector = formValueSelector('fundoForm')
    let original = selector(getState(), 'idCedoc') || "";

    return dispatch([
      { type: CEDOC_ID, payload: cedocId },
      { type: ULTIMO_VINCULO_CEDOC, payload: original },
      { type: FUNDO_INSERT_NOME_PASTA_CEDOC, payload: { showDialog: false }},
    ]);
  };
}

export function downloadImagemEstrutura(idFundo, nomeArquivo, arquivo) {
  return (dispatch) => {
    if (arquivo && arquivo.length > 0) {
      return dispatch([{ type: "" }, downloadFile(arquivo, nomeArquivo)]);
    }

    return axios
      .get(`${FUNDO_API}downloadestruturaoperacao/${idFundo}`)
      .then((result) => {
        dispatch([
          showAllMessages(result.messages),
          downloadFile(result.content, nomeArquivo),
        ]);
      });
  };
}

export function goToFormPage(editAction, idFundo) {
  let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;
  return (dispatch) => {
    return dispatch([
      history.push(`/${FUNDO_ROUTE_PATH}/${routeAction}/${idFundo}`),
    ]);
  };
}

export function getFundo(idFundo) {
  return (dispatch) => {
    return axios.get(FUNDO_API + `tela/${idFundo}`).then((result) => {
      dispatch([
        {
          type: LOAD_FORM_FUNDO,
          payload: result.content,
        },
        serializeFieldsFundoForm(result.content.fundo),
      ]);
    });
  };
}

export function loadFormFundoSubclasse(idFundo) {
  return (dispatch) => {
    return axios
      .get(FUNDO_API + `${idFundo}/classes/sub-classes`)
      .then((result) => {
        dispatch([
          {
            type: LOAD_FORM_FUNDO_SUBCLASSES,
            payload: result,
          },
        ]);
      });
  };
}

export function loadFormFundoCotas(idFundo) {
  return (dispatch) => {
    return axios
      .get(PASSIVO_API + `cotas-emitidas/${FUNDO_ROUTE_PATH}/${idFundo}`)
      .then((result) => {
        dispatch([
          {
            type: LOAD_FORM_FUNDO_COTAS,
            payload: result.content,
          },
        ]);
      });
  };
}

export function saveFundo(fundo) {
  const fundoDeserialized = deserializeFieldsFundoForm(fundo);

  return (dispatch) => {
    return axios
      .post(FUNDO_API, { ...fundoDeserialized })
      .then((result) =>
        dispatch([showAllMessages(result.messages), goBackToList()])
      );
  };
}

export function editFundo(fundo) {
  const fundoDeserialized = deserializeFieldsFundoForm(fundo);

  return (dispatch) => {
    return axios
      .put(FUNDO_API + fundo.id, { ...fundoDeserialized })
      .then((result) =>
        dispatch([showAllMessages(result.messages), goBackToList()])
      );
  };
}

export function removeFundo(idFundo, idsFundos) {
  return (dispatch) => {
    return axios
      .delete(FUNDO_API + idFundo)
      .then((result) =>
        dispatch([
          showFundoDeleteDialog(false, 0),
          showFundoList(true, idsFundos),
          showAllMessages(result.messages),
        ])
      );
  };
}

export function goBackToList() {
  return (dispatch) => {
    return dispatch([
      showConfirmDialog(false),
      { type: FUNDO_CLEAN_LIST },
      history.push(`/${FUNDO_ROUTE_PATH}`),
    ]);
  };
}

export function showFundoOperacaoList(showList) {
  return (dispatch) => {
    return dispatch([
      showConfirmDialog(false),
      history.push(`/${FUNDO_ROUTE_PATH}`),
    ]);
  };
}

export function changeFundoFormTab(formTab) {
  return (dispatch) => {
    return dispatch([
      {
        type: FUNDO_FORM_TAB,
        payload: formTab,
      },
    ]);
  };
}

export function showFundoTabActive() {
  return (dispatch) => {
    return dispatch([selectTab(FUNDO_TAB), showFundoOperacaoList(true)]);
  };
}

export function showFundoDeleteDialog(showDialog, idFundo) {
  return (dispatch) => {
    return dispatch([
      {
        type: FUNDO_SHOW_DELETE_DIALOG,
        payload: { showDialog, idFundo },
      },
    ]);
  };
}

export function selectedFundoOption(option) {
  let fundosSelected = option ? option.map((o) => o.key) : [];

  return (dispatch) => {
    return dispatch([
      {
        type: SELECTED_FUNDO_OPTION,
        payload: option,
      },
      showFundoList(true, fundosSelected),
    ]);
  };
}

export function getSugestaoFundo(term) {
  return (dispatch) => {
    return axios.get(`${FUNDO_API}sugestoes?termo=${term}`).then((result) =>
      dispatch({
        type: FUNDO_OPTIONS_FETCHED,
        payload: result.content,
      })
    );
  };
}

export function getFundos(idsFundo, page = 1) {
  let params = idsFundo.length > 0 ? idsFundo : null;

  let route = `${FUNDO_API}${LISTA_ROUTE_PATH}?pagina=${page}&tamanhoPagina=10`;
  let url = params ? `${route}&ids=${params}` : route;

  return (dispatch) => {
    return axios.get(url).then((result) =>
      dispatch([
        {
          type: FUNDO_FETCHED,
          payload: result,
        },
        {
          type: FUNDO_PAGINATION,
          payload: page,
        }
      ])
    );
  };
}

export function getFundosCota(idsOperacoes, page = 1) {
  let params = idsOperacoes.length > 0 ? idsOperacoes : 0;

  return (dispatch) => {
      return axios
          .get(FUNDO_API + `cotas/${params}?pagina=${page}&tamanhoPagina=10`)
          .then(result =>
              dispatch({
                  type: LOAD_TAB_FUNDOS_COTAS,
                  payload: result.content
              })
          )
  }
}

export function showFundoList(showList, idsFundo) {
  return (dispatch) => {
    return dispatch([
      {
        type: FUNDO_SHOW_LIST,
        payload: showList,
      },
      getFundos(idsFundo),
    ]);
  };
}

export function limpaDependenteOnChange(dependente, value = "") {
  return (dispatch) => {
    dispatch([change("fundoForm", dependente, value)]);
  };
}

export function loadFormFundo(idFundo, editAction) {
  return (dispatch) => {
    return axios.get(FUNDO_API + `tela/${idFundo || ""}`).then((result) => {
      let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;

      dispatch([
        idFundo
          ? history.push(`/${FUNDO_ROUTE_PATH}/${routeAction}/${idFundo}`)
          : undefined,
        {
          type: LOAD_FORM_FUNDO,
          payload: result.content,
        },
        {
          type: LOAD_FORM_FUNDO_SUBCLASSES,
          payload: [],
        },
        {
          type: LOAD_FORM_FUNDO_COTAS,
          payload: [],
        },
        serializeFieldsFundoForm(result.content.fundo),
      ]);
    });
  };
}

export function newFundoForm() {
  return (dispatch) => {
    return dispatch([
      loadFormFundo(),
      history.push(`/${FUNDO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`),
    ]);
  };
}

export function resetPagina() {
  return (dispatch) => {
    return dispatch([
      reset("fundoForm"),
      { type: RESET },
    ]);
  };
}