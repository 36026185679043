import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importa estilos

const Editor = ({ input, meta }) => {  // Desestruture input do props
  const modules = {
    toolbar: [
      [{ 'font': [] }, { 'size': [] }],            // Font family and size
      ['bold', 'italic', 'underline', 'strike'],   // Bold, italic, underline, strike
      [{ 'color': [] }, { 'background': [] }],     // Text color and background color
      [{ 'script': 'sub' }, { 'script': 'super' }],// Subscript / superscript
      [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'], // Headers, blockquote, code block
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], // Lists and indents
      [{ 'align': [] }],                           // Text alignment
      ['link', 'image', 'video'],                  // Media: link, image, video
      ['clean'],                                   // Remove formatting
    ]
  };

  return (
    <>
      <ReactQuill
        value={input.value} // Use o valor do input
        onChange={(content, delta, source, editor) => input.onChange(content)} // Atualize o valor do input
        modules={modules} 
      />
      {meta && meta.error && meta.touched && (
        <p className="error-msg sp-error text-danger f-12 mb-0">{meta.error}</p>
      )}
    </>
  );
};

export default Editor;