import React from "react";

export const HiddenField = ({ meta, className }) => {
  return (
    <>
      {meta && meta?.touched && meta.error && (
        <p className={`error-msg sp-error text-danger f-12 mb-0 ${className}`}></p>
      )}
    </>
  );
};
