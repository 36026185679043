import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector } from "redux-form";

import Form from '../../../../../common/form/Form';
import FormHeader from '../../../../../common/form/FormHeader';
import FormContent from '../../../../../common/form/FormContent';
import Input from '../../../../../common/input/Input';
import Button from '../../../../../common/button/Button';
import GenericPickerInput from '../../../../../common/input/GenericPickerInput';
import ConfirmDialog from '../../../../../common/modal/ConfirmDialog';


import {
    showGerarAgingDialog,
    getAging,
    exportRelatorioAgingToExcel,
    gerarAging
} from '../../../../../../actions/precificacao/AgingAction';

import { getOperacoes, selectOperacaoNoForm } from '../../../../../../actions/operacao/OperacaoAction';

let AgingListFilter = (props) => {
    const {
        showGerarAging,
        showGerarAgingDialog,
        gerarAging,
        getAging,
        exportRelatorioAgingToExcel,
        dataBase,
        operacao
    } = props;

    return (
        <div className='container-fluid'>
            <Form>
                <FormHeader
                    title={'Relatório Aging'}
                    col={"col-md"}
                />
                <FormContent handleSubmit={getAging}>
                    <div className="row">
                        <div className="col-lg-12 mb-2">
                            <div className="input-group-search">
                                <GenericPickerInput
                                    id={"operacaoPicker"}
                                    loadData={props.getOperacoes}
                                    options={props.options}
                                    formName={"agingForm"}
                                    fieldName={"operacao"}
                                    isMulti={true}
                                    placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                                    loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                                    noOptionsMessage="Nenhum registro encontrado"
                                    value={props.operacoes}
                                    selectedOptionForm={props.selectOperacaoNoForm}
                                />
                            </div>
                        </div>

                        <div className="col-lg-3">
                            <Field
                                component={Input}
                                className={"filter-input"}
                                inputId="dataBase"
                                name="dataBase"
                                type={"month"}
                                label="Data Base"
                            />
                        </div>
                        
                        <div className="col-lg-9">
                            <div className="row">
                                <Button
                                    action={() => getAging({operacao, dataBase })}
                                    buttonStyle={"default"}
                                    icon={"search"}
                                    name={"Buscar"}
                                />
                                <Button
                                    action={() => exportRelatorioAgingToExcel({ operacao, dataBase })}
                                    buttonStyle={"default ml-2"}
                                    name={"Exportar para Excel"}
                                    icon={"file-excel-o"}
                                />
                                <Button
                                    action={() => showGerarAgingDialog(true)}
                                    buttonStyle={"default ml-2"}
                                    icon={"file-text"}
                                    name={"Gerar aging"}
                                />
                                <ConfirmDialog
                                    title="Aviso"
                                    contentText="Todos os dados para esta database serão sobrescritos e poderá demorar alguns minutos. Deseja continuar?"
                                    textButtonCancel="CANCELAR"
                                    textButtonConfirm="CONFIRMAR"
                                    showDialog={showGerarAging}
                                    onClose={() => showGerarAgingDialog(false)}
                                    onConfirm={() => gerarAging({operacao, dataBase })}
                                    type="warning"
                                    maxWidth="md"
                                />
                            </div>
                        </div>
                    </div>

                </FormContent>
            </Form>
        </div>
    );

}

AgingListFilter = reduxForm({ form: 'agingForm', })(AgingListFilter);
const selector = formValueSelector('agingForm');

const mapStateToProps = state => ({
    operacao: selector(state, 'operacao'),
    dataBase: selector(state, 'dataBase'),
    options: state.operacaoReducer.operacoes,
    showGerarAging: state.agingReducer.showGerarAgingDialog,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    showGerarAgingDialog,
    getOperacoes,
    selectOperacaoNoForm,
    getAging,
    exportRelatorioAgingToExcel,
    gerarAging
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgingListFilter);