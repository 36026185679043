/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import {
  convertCurrencyNumber,
  formatPercent,
} from "../../../../../utils/helpers/NumberHelper";
import { withRouter } from "react-router-dom";
import Button from "../../../../common/button/Button";
import {
  getDateFormatedFromString,
  getDateFormatedFromStringWithTime,
} from "../../../../../utils/helpers/DateTimeHelper";
import {
  actionModal,
  loadContratoReceita,
} from "../../../../../actions/aprovacao/AprovacaoAction";
import history from "../../../../common/routers/history";
import {
  APROVACAO_ROUTE_PATH,
  LISTA_ROUTE_PATH,
} from "../../../../common/routers/constantesRoutes";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import { APROVACAO, READ } from "../../../../../utils/actionsAndFeaturesAuth";
import { getStringBooleanValue } from "../../../../../utils/helpers/BooleanHelper";

let ModalContratoReceita = (props) => {
  const {
    actionModal,
    open,
    contrato,
    permiteAprovar,
    idAprovacao,
    loadContratoReceita,
    authorizationFunction,
  } = props;

  const [observacao, setObservacao] = React.useState(
    contrato?.observacao || ""
  );

  React.useEffect(() => {
    async function fetchLoad() {
      await authorizationFunction(
        { idAcao: READ, idFuncionalidade: APROVACAO },
        () => loadContratoReceita(idAprovacao)
      );
    }

    if (open) fetchLoad();
  }, [open]);

  if (!open) return null;

  const close = () => {
    history.push(`/${APROVACAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`);
  };

  const moedaSimbolo = "R$";
  const idFormatado = contrato?.identificador || null;
  const identificador = contrato?.aprovacao?.identificador || null;

  return Object.keys(contrato)?.length > 0 ? (
    <Dialog maxWidth="xl" open={open} className="modalDialog" onClose={close}>
      <div className="modal-dialog modal-xl modal-fullscreen-2 modal-dialog-scrollable m-0 overflow-hidden">
        <div className="modal-content">
          <div className="modal-header px-4 pt-4 pb-2 d-flex flex-row align-items-start justify-content-between">
            <div className="d-flex flex-column">
              <span className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
                {contrato?.patrimonioSeparado?.descricao}
              </span>
              <span className="f-22 m-0 mt-2 txt-blue-link-dark">
                Contrato de Receita
                {`${idFormatado ? ` — ${idFormatado}` : null}`}
              </span>
            </div>
            <Close role="button" className="text-danger" onClick={close} />
          </div>

          <div className="modal-body px-4 py-0">
            <div className="text-dark f-16">
              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-3">Contrato</div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Empresa Faturamento</p>
                  <p className="fw-400 mb-0">
                    {contrato?.empresaFaturamento?.razaoSocialComCnpj || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Tipo de Receita</p>
                  <p className="fw-400 mb-0">
                    {contrato?.produtoReceita?.nome || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Observações</p>
                  <p className="fw-400 mb-0">{contrato?.observacao || "--"}</p>
                </div>
              </div>

              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-4">Receita Flat</div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Conta Origem</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoFlat?.contaOrigem?.conta || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Origem dos Recursos</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoFlat?.origemRecurso?.descricao ||
                      "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data de Pagamento</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoFlat?.dataPagamento
                      ? getDateFormatedFromString(
                          contrato?.receitaContratoFlat?.dataPagamento
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg"></div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Valor Líquido</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoFlat?.valorLiquido
                      ? convertCurrencyNumber(
                          contrato?.receitaContratoFlat?.valorLiquido,
                          `${moedaSimbolo} `,
                          2
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Gross-up</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoFlat?.grossUp
                      ? formatPercent(contrato?.receitaContratoFlat?.grossUp, 2)
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Valor Bruto</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoFlat?.valorBruto
                      ? convertCurrencyNumber(
                          contrato?.receitaContratoFlat?.valorBruto,
                          `${moedaSimbolo} `,
                          2
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Pagamento Proporcional?</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoFlat
                      ? contrato?.receitaContratoFlat?.pagamentoProporcional !== null
                        ? getStringBooleanValue(contrato?.receitaContratoFlat?.pagamentoProporcional)
                        : "--"
                      : "--"}
                  </p>
                </div>
              </div>

              {contrato?.receitaContratoFlat?.divisoes &&
                contrato?.receitaContratoFlat?.divisoes?.length && (
                  <>
                    {contrato?.receitaContratoFlat?.divisoes.map((item) => {
                      return (
                        <div className="row w-100 mb-3" key={item?.id}>
                          <div className="col-lg">
                            <p className="mb-1">Faturar Contra</p>
                            <p className="fw-400 mb-0">
                              {item?.pessoa?.razaoSocialComCnpj || "--"}
                            </p>
                          </div>
                          <div className="col-lg">
                            <p className="mb-1">Proporção</p>
                            <p className="fw-400 mb-0">
                              {item?.proporcao
                                ? formatPercent(item.proporcao, 2)
                                : "--"}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}

              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-4">
                Pagamento Recorrente
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Conta Origem</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoRecorrente?.contaOrigem?.conta ||
                      "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Origem dos Recursos</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoRecorrente?.origemRecurso
                      ?.descricao || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data de Início (1ª Parcela)</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoRecorrente?.dataInicio
                      ? getDateFormatedFromString(
                          contrato?.receitaContratoRecorrente?.dataInicio
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg"></div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Valor Líquido</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoRecorrente?.valorLiquido
                      ? convertCurrencyNumber(
                          contrato?.receitaContratoRecorrente?.valorLiquido,
                          `${moedaSimbolo} `,
                          2
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Gross-up</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoRecorrente?.grossUp
                      ? formatPercent(
                          contrato?.receitaContratoRecorrente?.grossUp,
                          2
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Valor Bruto</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoRecorrente?.valorBruto
                      ? convertCurrencyNumber(
                          contrato?.receitaContratoRecorrente?.valorBruto,
                          `${moedaSimbolo} `,
                          2
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data de Vencimento (Contrato)</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoRecorrente?.dataVencimento
                      ? getDateFormatedFromString(
                          contrato?.receitaContratoRecorrente?.dataVencimento
                        )
                      : "--"}
                  </p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Periodicidade Pagamento</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoRecorrente
                      ?.periodicidadePagamento || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Meses de Pagamento</p>
                  <p className="fw-400 mb-0">
                    {(
                      contrato?.receitaContratoRecorrente?.mesesPagamento?.map(
                        ({ mes }) => mes.value
                      ) || []
                    ).join(", ") || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Valor do Contrato</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoRecorrente?.valorContrato
                      ? convertCurrencyNumber(
                          contrato?.receitaContratoRecorrente?.valorContrato,
                          `${moedaSimbolo} `,
                          2
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg"></div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Indexador de Correção</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoRecorrente?.taxa?.descricao ||
                      "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Periodicidade de Correção</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoRecorrente
                      ?.periodicidadeCorrecao || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Meses de Correção</p>
                  <p className="fw-400 mb-0">
                    {(
                      contrato?.receitaContratoRecorrente?.mesesCorrecao?.map(
                        ({ mes }) => mes.value
                      ) || []
                    ).join(", ") || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Pagamento Proporcional?</p>
                  <p className="fw-400 mb-0">
                    {contrato?.receitaContratoRecorrente
                      ? contrato?.receitaContratoRecorrente?.pagamentoProporcional !== null
                        ? getStringBooleanValue(contrato?.receitaContratoRecorrente?.pagamentoProporcional)
                        : "--"
                      : "--"}
                  </p>
                </div>
              </div>
              {contrato?.receitaContratoRecorrente?.divisoes &&
                contrato?.receitaContratoRecorrente?.divisoes?.length && (
                  <>
                    {contrato?.receitaContratoRecorrente?.divisoes.map(
                      (item) => {
                        return (
                          <div className="row w-100 mb-3" key={item?.id}>
                            <div className="col-lg">
                              <p className="mb-1">Faturar Contra</p>
                              <p className="fw-400 mb-0">
                                {item?.pessoa?.razaoSocialComCnpj || "--"}
                              </p>
                            </div>
                            <div className="col-lg">
                              <p className="mb-1">Proporção</p>
                              <p className="fw-400 mb-0">
                                {item?.proporcao
                                  ? formatPercent(item.proporcao, 2)
                                  : "--"}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </>
                )}

              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-4">Aprovação</div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Enviado por</p>
                  <p className="fw-400 mb-0">
                    {contrato?.aprovacao?.solicitante?.nome || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data e Hora de Envio</p>
                  <p className="fw-400 mb-0">
                    {contrato?.aprovacao?.dataSolicitacao
                      ? getDateFormatedFromStringWithTime(
                          contrato?.aprovacao?.dataSolicitacao
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg"></div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Aprovador 1</p>
                  <p className="fw-400 mb-0">
                    {contrato?.aprovacao?.aprovadores[0]?.aprovador?.nome || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data e Hora Aprovação</p>
                  <p className="fw-400 mb-0">
                    {contrato?.aprovacao?.aprovadores[0]?.dataAprovacao
                      ? getDateFormatedFromStringWithTime(
                          contrato?.aprovacao?.aprovadores[0]?.dataAprovacao
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Observação</p>
                  <p className="fw-400 mb-0">
                    {contrato?.aprovacao?.aprovadores[0]?.observacao || "--"}
                  </p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Aprovador 2</p>
                  <p className="fw-400 mb-0">
                    {contrato?.aprovacao?.aprovadores[1]?.aprovador?.nome || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data e Hora Aprovação</p>
                  <p className="fw-400 mb-0">
                    {contrato?.aprovacao?.aprovadores[1]?.dataAprovacao
                      ? getDateFormatedFromStringWithTime(
                          contrato?.aprovacao?.aprovadores[1]?.dataAprovacao
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Observação</p>
                  <p className="fw-400 mb-0">
                    {contrato?.aprovacao?.aprovadores[1]?.observacao || "--"}
                  </p>
                </div>
              </div>

              {permiteAprovar && (
                <>
                  <div className="p-color f-20 fw-500 mb-2 mt-4">
                    Observação
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12 pr-0">
                      <textarea
                        className="w-100 rounded brd-1 p-2"
                        rows={7}
                        value={observacao}
                        onChange={(e) => setObservacao(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {permiteAprovar && (
            <div className="modal-footer px-3 py-2 d-flex flex-row align-content-center justify-content-between m-0">
              <Button
                name={"Rejeitar Contrato"}
                action={async () => {
                  await actionModal({
                    action: "reprovar",
                    identificador,
                    observacao,
                  });
                  close();
                }}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              />
              <Button
                name={"Aprovar Contrato"}
                action={async () => {
                  await actionModal({
                    action: "aprovar",
                    identificador,
                    observacao,
                  });
                  close();
                }}
                classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  contrato: state.aprovacaoReducer.receitaContratoAprovacao || {},
  permiteAprovar:
    state.aprovacaoReducer.receitaContratoAprovacao?.permiteAprovar,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      actionModal,
      loadContratoReceita,
      authorizationFunction,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ModalContratoReceita)
);
