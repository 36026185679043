import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Button from "../../../../../common/button/Button";

let ModalConfirm = (props) => {
  const { close, open, confirm } = props;

  return (
    <>
      <Dialog
        open={open}
        maxWidth={false}
        PaperProps={{ style: { maxWidth: "max-content" } }}
        onClose={() => close()}
      >
        <div className="modal modal-dialog modal-dialog-scrollable m-0">
          <div className="modal-content">
            <div className="modal-header d-flex flex-row align-items-center justify-content-between pb-3">
              <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">Aviso</p>
              <Close
                role="button"
                className="text-danger"
                onClick={() => close()}
              />
            </div>

            <div className="modal-body text-dark f-16 fw-400 py-2">
              <p className="my-2">
                Confirmar exclusão do evento selecionado?
              </p>
            </div>

            <div className="modal-footer d-flex flex-row align-content-center justify-content-between p-3">
              <Button
                name={"Cancelar"}
                action={() => close()}
                classes="bg-danger px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0 mw-100px"
              />

              <Button
                name={"Confirmar"}
                action={() => confirm()}
                classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mw-100px"
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispacthToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(ModalConfirm);
