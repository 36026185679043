import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../../../common/container/ContainerPage";
import OpcaoForm from "./form/MoedaForm";
import { saveOpcao } from "../../../../../../actions/precificacao/OpcaoAction";

const MoedaIncludePage = (props) => {
  const { 
    saveOpcao,
  } = props;

  return (
    <ContainerPage>
      <OpcaoForm
        title={"Nova - Moeda"}
        readOnly={false}
        floatButtonVisible={true}
        onSubmit={(e) => saveOpcao({...e, codigoIso: ""}, "moeda")}
      />
    </ContainerPage>
  );
};

const mapDispacthToProps = (dispatch) => bindActionCreators(
    {
      saveOpcao,
    },
    dispatch
  );

export default withRouter(
  connect(null, mapDispacthToProps)(MoedaIncludePage)
);
