import React, { Component } from "react";
import { connect } from 'react-redux';
import ContainerPage from "../../../common/container/ContainerPage";
import PedidosListFilter from "./form/PedidosListFilter";
import PedidosList from "./list/PedidosList";

class PedidosPage extends Component {
    render() {
        return (
            <ContainerPage>
                <PedidosListFilter />
                <PedidosList />
            </ContainerPage>
        );
    }
}

export default connect(null, null)(PedidosPage);