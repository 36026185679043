import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { convertFileToBase64, downloadFile } from "../../../../../utils/helpers/FileHelper";
import { msgError } from "../../../../../utils/helpers/OthersHelpers";
import {
  AddToArrayOnChange,
  downloadAnexoAssembleia,
  removeToArrayOnChange,
} from "../../../../../actions/assembleia/AssembleiaAction";
import { formValueSelector } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt, faTrashAlt, faFileExcel, faFilePdf, faFileWord, faFile } from "@fortawesome/free-solid-svg-icons";

const DropzoneAssembleia = (props) => {
  const {
    input = {},
    meta = {},
    readOnly,
    multiple,
    AddToArrayOnChange,
    removeToArrayOnChange,
    typeKey,
    tiposArquivoList,
    accept,
    maxFiles,
    required,
    submitted,
    downloadAnexoAssembleia
  } = props;

  const [isDragging, setIsDragging] = useState(false);

  let arquivos = input.value
  let name = input.name

  const formats = accept ? Object.values(accept).flat().join(', ') : null;

  const onDrop = async (acceptedFiles) => {
    const newFiles = [];

    for (const file of acceptedFiles) {
      const data = {
        arquivo: await convertFileToBase64(file),
        nomeDocumento: file?.name?.split(".").slice(0, -1).join(".") || "",
        formato: file?.name?.split(".").pop().toLowerCase() || "",
        tipo: tiposArquivoList.find((item) => item.key === typeKey),
      };

      newFiles.push(data);
      AddToArrayOnChange(name, data);
    }

    input.onChange([...arquivos, ...newFiles]);
    setIsDragging(false);
  };

  const onDownload = async (file) => {
    if (file?.idDocumento) {
      await downloadAnexoAssembleia(file);
    } else {
      downloadFile(file.arquivo, `${file.nomeDocumento}.${file.formato}`);
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    multiple,
    noClick: readOnly || (maxFiles && arquivos?.length >= maxFiles),
    disabled: readOnly,
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false),
    onDropRejected: (rejected) => {
      rejected.forEach(({ file }) => msgError(`Arquivo (${file.name}) inválido`));
    },
  });

  const renderFileIcon = (ext) => {
    const icon = {
      xls: faFileExcel,
      xlsx: faFileExcel,
      pdf: faFilePdf,
      doc: faFileWord,
      docx: faFileWord,
      default: faFile,
    }[ext] || faFile;
  
    return <FontAwesomeIcon color="#5d5d5d" size="lg" style={{ padding: 1 }} className="mr-1" icon={icon} />;
  };

  return (
    <>
      <div
        {...getRootProps()}
        className="w-100 d-flex flex-column justify-content-start align-items-center text-center position-relative p-2 rounded my-2"
        style={{
          minHeight: "100px",
          maxHeight: "200px",
          transition: "border 0.3s ease-out",
          border: isDragging ? "2px dashed #630A37" : "1px dashed #B8B8B8",
          background: readOnly ? '#e9e9e9' : '#ffffff'
        }}
      >
        <input {...getInputProps()} />
        {(!readOnly && (!maxFiles || arquivos?.length < maxFiles)) && (
          <p className="py-2 mb-0">
            Arraste os arquivos ({formats || 'qualquer formato'}) ou{" "}
            <u className="btn-link-opea" role="button">
              clique aqui
            </u>{" "}
            para upload
          </p>
        )}
        <div className="d-flex flex-column h-100 overflow-auto w-100 p-2">
          {arquivos?.length > 0 &&
            arquivos.map((file, i) => (
              <div
                key={`file-${i}`}
                onClick={(e) => e.stopPropagation()}
                style={{
                  backgroundColor: readOnly ? "#ffffff" : "#e9e9e9",
                  border: "1px solid #B8B8B8",
                }}
                className="p-2 d-flex flex-row align-items-center justify-content-between rounded fw-400 my-1"
              >
                <span>
                  {renderFileIcon(file?.formato)} {file?.nomeDocumento}.{file?.formato}
                </span>
                <div className="d-flex flex-row">
                  {(file?.idDocumento || file?.arquivo) && (
                    <FontAwesomeIcon
                      color="#630a37"
                      size="lg"
                      role="button"
                      className="mx-1"
                      style={{ padding: 1 }}
                      icon={faCloudDownloadAlt}
                      onClick={async () => onDownload(file)}
                    />
                  )}

                  {!readOnly && (
                    <FontAwesomeIcon
                      size="lg"
                      role="button"
                      color="#630a37"
                      className="mx-1"
                      icon={faTrashAlt}
                      style={{ padding: 1 }}
                      onClick={() => {
                        removeToArrayOnChange(name, i);
                        const newValue = arquivos.filter((_, index) => index !== i);
                        input.onChange(newValue);
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
        {required ? <span className="text-warning font-weight-bold position-absolute" style={{ right: 6, top: 2 }}>*</span> : ""}
      </div>
      {meta && meta.error && submitted && (
        <p className="error-msg sp-error text-danger f-12 mb-0">{meta.error}</p>
      )}
    </>
  );
};

const selector = formValueSelector("AssembleiaForm");

const mapStateToProps = (state) => ({
  submitted: selector(state, "submitted") || false,
  tiposArquivoList: [
    { key: 1, value: "Edital de Convocação", raw: "EditalConvocacao" },
    { key: 2, value: "Proposta para Assembleia", raw: "PropostaParaAssembleia" },
    { key: 3, value: "Ata da Assembleia", raw: "AtaAssembleia" },
    { key: 4, value: "Outros Documento", raw: "OutrosDocumentos" },
  ],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      AddToArrayOnChange,
      removeToArrayOnChange,
      downloadAnexoAssembleia,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DropzoneAssembleia);
