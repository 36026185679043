import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { getErros, loadFormErro, showErroList } from "../../../../../actions/precificacao/ErroAction";

import ContainerPage from "../../../../common/container/ContainerPage";
import ErroForm from "./form/ErroForm";


let ErroVisualizePage = (props) => {
  const { erro, loadFormErro, getErros, showErroList } = props

  React.useEffect(() => {
    const fecthData = async () => {
      if (!erro.id) {
        const checkId = id => id.includes('-') ? 'GUID' : 'Integer';
        const paramId = props.match.params.idErro
        let type = checkId(paramId);
        
        if (type == 'Integer') {
          loadFormErro(paramId);
        } else {
          const erros = await getErros({ codigo: paramId })
          
          if (erros.content.hasItems) {
            loadFormErro(erros.content.items[0].id)
          } else {
            showErroList()
          }
        }
      }
    }

    fecthData()
  }, [])

  return (
    <ContainerPage>
      <ErroForm title={"DETALHE - Erros do Sistema"} readOnly={true} floatButtonVisible={false} />
    </ContainerPage>
  );
}

const mapStateToProps = (state) => ({
  erro: state.erroReducer.erroForm,
});
const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormErro,
      getErros,
      showErroList,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(ErroVisualizePage));
