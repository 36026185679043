import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector, Field } from "redux-form";

import {
  showIndiceList,
  calculoVariacaoPercentualDoIndice,
} from "../../../../../../actions/precificacao/IndiceAction";
import { showConfirmDialog } from "../../../../../../actions/common/modal/ConfirmDialogAction";

import If from "./../../../../../common/If/If";
import Form from "./../../../../../common/form/Form";
import FormHeader from "./../../../../../common/form/FormHeader";
import FormContent from "./../../../../../common/form/FormContent";
import FloatButtonGroup from "./../../../../../common/button/FloatButtonGroup";
import FloatButton from "./../../../../../common/button/FloatButton";
import ConfirmDialog from "./../../../../../common/modal/ConfirmDialog";
import Input from "./../../../../../common/input/Input";
import DropdownListInput from "./../../../../../common/input/DropdownListInput";
import MaskedInput from "./../../../../../common/input/MaskedInput";

const TIPO_TAXA_IPCA = "IPCA";
const TIPO_TAXA_IGPM = "IGPM";
const TIPO_TAXA_IGPDI = "IGPDI";
const TIPO_TAXA_INCCM = "INCCM";
const TIPO_TAXA_INCCDI = "INCCDI";
const TIPO_TAXA_CDI = "CDI";
const TIPO_TAXA_TR = "TR";
const TIPO_TAXA_FIXO = "FIXO";
const TIPO_TAXA_DIFUTURO = "DIFUTURO";
const TIPO_TAXA_INPC = "INPC";
const TIPO_TAXA_PTAX = "PTAX";

let IndiceForm = (props) => {
  const {
    title,
    handleSubmit,
    readOnly,
    showIndiceList,
    floatButtonVisible,
    calculoVariacaoPercentualDoIndice,
    showConfirmDialog,
    showCancelConfirmDialog,
    taxaList,
    tipoTaxa,
    dataInicio,
    numeroIndice,
  } = props;

  const taxaMensal =
    tipoTaxa &&
    (tipoTaxa.descricao === TIPO_TAXA_IPCA ||
      tipoTaxa.descricao === TIPO_TAXA_IGPM ||
      tipoTaxa.descricao === TIPO_TAXA_IGPDI ||
      tipoTaxa.descricao === TIPO_TAXA_INCCM ||
      tipoTaxa.descricao === TIPO_TAXA_INCCDI ||
      tipoTaxa.descricao === TIPO_TAXA_FIXO ||
      tipoTaxa.descricao === TIPO_TAXA_INPC ||
      tipoTaxa.descricao === TIPO_TAXA_PTAX);

  const isPTAX = tipoTaxa && tipoTaxa.descricao === TIPO_TAXA_PTAX;

  let labelCampoVaricao = "Taxa";
  let labelNumeroIndice = "Número Índice";

  if (isPTAX) labelNumeroIndice = "Cotação";

  if (
    tipoTaxa &&
    (tipoTaxa.descricao === TIPO_TAXA_CDI ||
      tipoTaxa.descricao === TIPO_TAXA_TR)
  )
    labelCampoVaricao = "Taxa Efetiva";
  if (tipoTaxa && taxaMensal) labelCampoVaricao = "Variação";

  const classNameForTaxaMensal = taxaMensal ? "col-lg-4 col-xl-3" : "col-lg-4";
  const calssNameForTipoTaxaMensal = taxaMensal ? "col-lg-3" : "col-lg-4";

  let taxaListSemDiFuturo = taxaList.filter((obj, i, arr) => {
    return obj.descricao !== TIPO_TAXA_DIFUTURO;
  });

  return (
    <div className="w-100">
      <Form>
        <FormHeader title={title} col={"col-md"} offSet={20}></FormHeader>
        <FormContent handleSubmit={handleSubmit}>
          <div className="shadow-custom rounded-12 bg-white px-3 py-2 column">
            <p className="page-head__title f-22 my-2">Controle</p>
            <div className="row">
              <div className={calssNameForTipoTaxaMensal}>
                <Field
                  component={DropdownListInput}
                  inputId="taxa"
                  name="taxa"
                  label="Tipo Taxa"
                  options={taxaListSemDiFuturo}
                  textField="descricao"
                  valueField="id"
                  filter="contains"
                  readOnly={readOnly}
                  onChange={(value) =>
                    calculoVariacaoPercentualDoIndice(
                      value,
                      dataInicio,
                      numeroIndice
                    )
                  }
                />
              </div>
              <If test={taxaMensal}>
                <div className={classNameForTaxaMensal}>
                  <Field
                    component={MaskedInput}
                    inputId="numeroIndice"
                    name={"numeroIndice"}
                    label={labelNumeroIndice}
                    readOnly={readOnly}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={4}
                    maxLength={21}
                    placeholder={"000,0000"}
                    onValueChange={(value) =>
                      calculoVariacaoPercentualDoIndice(
                        tipoTaxa,
                        dataInicio,
                        value
                      )
                    }
                    blur={true}
                  />
                </div>
              </If>
              <div className={classNameForTaxaMensal}>
                <Field
                  component={Input}
                  inputId="dataInicio"
                  name="dataInicio"
                  type={taxaMensal && !isPTAX ? "month" : "date"}
                  label="Data Referência"
                  onBlur={(e) =>
                    calculoVariacaoPercentualDoIndice(
                      tipoTaxa,
                      e.target.value,
                      numeroIndice
                    )
                  }
                  readOnly={readOnly}
                />
              </div>
              <div className={classNameForTaxaMensal}>
                <Field
                  component={MaskedInput}
                  inputId="valorTaxa"
                  name={"valorTaxa"}
                  label={labelCampoVaricao}
                  readOnly={readOnly}
                  suffix={"%"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={16}
                  maxLength={32}
                  placeholder={"000,0000"}
                />
              </div>
              <div className="col-lg-4">
                <Field
                  component={MaskedInput}
                  inputId="valorTaxaProjecao"
                  name={"valorTaxaProjecao"}
                  label="Valor Taxa Projeção"
                  readOnly={readOnly}
                  suffix={"%"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={16}
                  maxLength={12}
                  placeholder={"000,0000"}
                />
              </div>
              <div className="col-lg-4">
                <Field
                  component={Input}
                  inputId="dataInicioProjecao"
                  name="dataInicioProjecao"
                  type="date"
                  label="Data Início Projeção"
                  readOnly={readOnly}
                />
              </div>
              <div className="col-lg-4">
                <Field
                  component={Input}
                  inputId="dataFimProjecao"
                  name="dataFimProjecao"
                  type="date"
                  label="Data Final Projeção"
                  readOnly={readOnly}
                />
              </div>
            </div>
          </div>
          <FloatButtonGroup>
            <FloatButton
              activeClassName={"nd1 nds"}
              title={"Cancelar"}
              icon={"fa fa-ban edit edit-sub-menu"}
              action={() =>
                floatButtonVisible ? showConfirmDialog(true) : showIndiceList()
              }
            />
            <If test={floatButtonVisible}>
              <FloatButton
                activeClassName={"nd3 nds"}
                title={"Salvar"}
                icon={"material-icons edit edit-sub-menu"}
                cloudDone={true}
                action={handleSubmit}
              />
            </If>
          </FloatButtonGroup>
        </FormContent>
        <ConfirmDialog
          title="Aviso"
          contentText="Os dados do formulário serão perdidos, deseja realmente sair ?"
          textButtonCancel="CANCELAR"
          textButtonConfirm="CONFIRMAR"
          showDialog={showCancelConfirmDialog}
          onClose={() => showConfirmDialog(false)}
          onConfirm={() => showIndiceList()}
          type="warning"
          maxWidth="md"
        />
      </Form>
    </div>
  );
};

IndiceForm = reduxForm({ form: "indiceForm" })(IndiceForm);
const selector = formValueSelector("indiceForm");

const mapStateToProps = (state) => ({
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
  taxaList: state.indiceReducer.indiceFormTypes.taxaList || [],
  labelCampoVariacao: state.indiceReducer.labelCampoVariacao,
  tipoTaxa: selector(state, "taxa"),
  dataInicio: selector(state, "dataInicio"),
  numeroIndice: selector(state, "numeroIndice"),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showIndiceList,
      showConfirmDialog,
      calculoVariacaoPercentualDoIndice,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(IndiceForm);
