import axios from "axios";
import { initialize, change, formValueSelector } from "redux-form";
import { multiply, add, divide } from "mathjs";

import history from './../../components/common/routers/history';
import {
    deserializeFieldsPagamentoPrecificacaoFormFilter,
    serializeFieldsPagamentoFluxo,
    deserializeFieldsEtapaFechamento,
} from "./../../components/business/precificacao/mappings/PagamentoMap";

import { showAllMessages } from './../../actions/message/ErrorMessagesAPIAction';

import {
    PRECIFICACAO_ROUTE_PATH,
    PAGAMENTO_ROUTE_PATH,
    LISTA_ROUTE_PATH,
    FLUXO_ROUTE_PATH,
} from "./../../components/common/routers/constantesRoutes";

import {
    downloadExcelFile,
    downloadTxtFile
} from "../../utils/helpers/FileHelper";
import normalizeDecimals, { convertCurrencyToNumber, convertCurrency, formatPercentToNumber, convertCurrencyNumber } from "./../../utils/helpers/NumberHelper";
import { primeiroDiaMesPassado, ultimoDiaProximoMes } from "./../../utils/helpers/DateTimeHelper";
import { changeLoading, changeMessage } from "../infra/LoadingScreenAction";
import { toastr } from "react-redux-toastr";

export const TELA_FECHAMENTO = 'FECHAMENTO';
export const TELA_VALIDACAO = 'VALIDACAO';
export const TELA_VALIDACAO_CONFIRMACAO = 'VALIDACAO_CONFIRMACAO';
export const TELA_AGENDAMENTO = 'AGENDAMENTO';
export const TELA_LANCAMENTO = 'LANCAMENTO';
export const TELA_PAGAMENTO = 'PAGAMENTO';

export const IS_LOADING = 'IS_LOADING';
export const SALVA_FILTROS_PAGAMENTO = 'SALVA_FILTROS_PAGAMENTO';
export const EXTERNO_PAGAMENTO_AF = 'EXTERNO_PAGAMENTO_AF';
export const CHECK_LINK_EXTERNO_PAGAMENTO_AF = 'CHECK_LINK_EXTERNO_PAGAMENTO_AF';
export const PAGAMENTO_MODAL_PRECIFICACAO = 'PAGAMENTO_MODAL_PRECIFICACAO';
export const PAGAMENTOS_FETCHED = 'PAGAMENTOS_FETCHED';
export const PAGAMENTOS_CONSULT_CURRENT = 'PAGAMENTOS_CONSULT_CURRENT';
export const INDICADORES_FETCHED = 'INDICADORES_FETCHED';
export const LOAD_FILTER_FORM_PAGAMENTO = 'LOAD_FILTER_FORM_PAGAMENTO';
export const LOAD_INFO_PAGAMENTO = 'LOAD_INFO_PAGAMENTO';
export const PAGAMENTO_IR_PARA_PAGINA = 'PAGAMENTO_IR_PARA_PAGINA';
export const CHANGE_STATUS_PAGAMENTO_DIALOGS = 'CHANGE_STATUS_PAGAMENTO_DIALOGS';
export const LOAD_TELA_FLUXOS_PAGAMENTO = 'LOAD_TELA_FLUXOS_PAGAMENTO';
export const PAGAMENTO_RESET = 'PAGAMENTO_RESET';
// export const PAGAMENTO_ATUALIZAR_STEPPERS_STATUS = 'PAGAMENTO_ATUALIZAR_STEPPERS_STATUS';

export const EMAIL_NAO_ENVIADO = 'Não foi enviado e-mail nesta etapa.';

export const PAGAMENTO_SHOW_DELETE_DIALOG = 'PAGAMENTO_SHOW_DELETE_DIALOG';

export const OPEN_MODAL_EXECUCAO_PRECIFICACAO = 'OPEN_MODAL_EXECUCAO_PRECIFICACAO';

const PAGAMENTO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PRECIFICACAO_API + process.env.REACT_APP_PATH_PAGAMENTO_TAXA_API;

export function loadFilterFormPagamento() {
    return (dispatch, getState) => {
        dispatch(changeMessage("Carregando Filtros das Etapas de Pagamento"))
        const hasConsult = getState().pagamentoReducer.pagamentosConsultCurrent;
        
        return axios.get(PAGAMENTO_API + 'tela/filtro')
            .then(result =>
                dispatch([
                    {
                        type: LOAD_FILTER_FORM_PAGAMENTO,
                        payload: result.content
                    },
                    !hasConsult ? change("PagamentoForm", "dataInicio", primeiroDiaMesPassado()) : null,
                    !hasConsult ? change("PagamentoForm", "dataFim", ultimoDiaProximoMes()) : null,
                ])
            );
    };
}

export function getIndicadores(filters) {
    let idsUsuarioGestor = filters && filters.idsUsuarioGestor ? filters.idsUsuarioGestor.map(o => o.id) : [];
    let idsOperacao = filters && filters.idsOperacao ? filters.idsOperacao.map(o => o.key) : [];
    let idsBancoLiquidante = filters && filters.idsBancoLiquidante ? filters.idsBancoLiquidante.map(o => o.id) : [];
    let idsCamaraLiquidacao = filters && filters.idsCamaraLiquidacao ? filters.idsCamaraLiquidacao.map(o => o.id) : [];

    return (dispatch) => {
        dispatch(changeMessage("Carregando Indicadores das Etapas de Pagamento"))
        return axios
            .post(`${PAGAMENTO_API}indicadores/status`, {...filters, idsUsuarioGestor, idsOperacao, idsBancoLiquidante, idsCamaraLiquidacao})
            .then(result =>
                dispatch([
                    {
                        type: INDICADORES_FETCHED,
                        payload: result,
                    },
                ])
            );
    }
}

export function salvaFiltrosPagamento(filters) {
    const params = deserializeFieldsPagamentoPrecificacaoFormFilter(filters, 1);
    
    return (dispatch, getState) => {
        return dispatch([
            {
                type: SALVA_FILTROS_PAGAMENTO,
                payload: params
            },
        ]);
    }
}

export function getPagamentos(filters, page = 1) {
    const params = deserializeFieldsPagamentoPrecificacaoFormFilter(filters, page);
    
    return (dispatch) => {
        dispatch(changeMessage("Carregando Etapas de Pagamento"))
        return axios
            .post(`${PAGAMENTO_API}buscar`, { ...params })
            .then(result =>
                dispatch([
                    {
                        type: PAGAMENTOS_FETCHED,
                        payload: result,
                    },
                    {
                        type: PAGAMENTOS_CONSULT_CURRENT,
                        payload: {...filters, pagingConfig: { pageIndex: page, pageSize: 10 }},
                    },
                ])
            );
    }
}

export function exportPagamentosToExcel(filters) {
    return (dispatch) => {
        return axios
            .post(`${PAGAMENTO_API}exportar/excel`, filters)
            .then(result => {
                const fileName = 'PagamentosPrecificacao.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function sairDaPagina() {
    return dispatch => {
        return dispatch([
            { type: PAGAMENTO_RESET },
            history.push(`/${PRECIFICACAO_ROUTE_PATH}/${PAGAMENTO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)
        ]);
    };
}

// export function calcularSaldo() {
//     return (dispatch, getState) => {
//         const selector = formValueSelector('FasePagamentoForm');

//         let totalIntegralizacao = selector(getState(), 'quantidadeIntegralizacoes') ? selector(getState(), 'quantidadeIntegralizacoes') : 0;
//         let valorRecebido = selector(getState(), 'valorRecebido') ? convertCurrencyToNumber(selector(getState(), 'valorRecebido')) : 0;
//         let jurosPago = selector(getState(), 'jurosPago') ? convertCurrencyToNumber(selector(getState(), 'jurosPago')) : 0;
//         let amortizacao = selector(getState(), 'amortizacao') ? convertCurrencyToNumber(selector(getState(), 'amortizacao')) : 0;
//         let amex = selector(getState(), 'curvaPagamentoPassivo.amex') ? convertCurrencyToNumber(selector(getState(), 'curvaPagamentoPassivo.amex')) : 0;
//         let premio = selector(getState(), 'curvaPagamentoPassivo.premio') ? convertCurrencyToNumber(selector(getState(), 'curvaPagamentoPassivo.premio')) : 0;
//         let percentualJuros = selector(getState(), 'curvaPagamentoPassivo.percentualPagamento') ? convertCurrencyToNumber(selector(getState(), 'curvaPagamentoPassivo.percentualPagamento')) : 0;
//         let jurosRemuneracao = selector(getState(), 'jurosRemuneracao') ? convertCurrencyToNumber(selector(getState(), 'jurosRemuneracao')) : 0;

//         const pagamentoTotalPmtCri = calcularPagamentoTotal({ jurosRemuneracao, amortizacao, amex, premio, totalIntegralizacao, percentualJuros });
//         const resultValorRecebidoUnitario = calcularValorRecebidoUnitario(valorRecebido, totalIntegralizacao);
//         const pagamentoTotalPmtCriUnitario = calcularPagamentoTotalUnitario({ jurosRemuneracao, amortizacao, amex, premio, totalIntegralizacao, percentualJuros });
//         const saldoFinal = calcularSaldoPagamento({ valorRecebido: valorRecebido, pagamento: pagamentoTotalPmtCri });
//         const resultadoSaldoUnitario = calcularSaldoUnitario(resultValorRecebidoUnitario, pagamentoTotalPmtCriUnitario);

//         jurosPago = multiply(jurosRemuneracao, divide(formatPercentToNumber(percentualJuros), 100));

//         return dispatch([
//             change("FasePagamentoForm", "pagamentoUnitario", convertCurrency(pagamentoTotalPmtCriUnitario, 8)),
//             change("FasePagamentoForm", "saldo", saldoFinal),
//             change("FasePagamentoForm", "pagamento", pagamentoTotalPmtCri),
//             change("FasePagamentoForm", "valorRecebidoUnitario", convertCurrency(resultValorRecebidoUnitario, 8)),
//             change("FasePagamentoForm", "saldoUnitario", convertCurrency(resultadoSaldoUnitario, 8)),
//             change("FasePagamentoForm", "jurosPago", convertCurrency(jurosPago, 8)),
//         ]);
//     }
// }

export function resetAmortizacao() {
    return (dispatch) => {
        return dispatch([
            change("FasePagamentoForm", "amortizacaoPercentual", 0),
            change("FasePagamentoForm", "amortizacaoValorAPagar", 0.00),
        ]);
    }
}

export function calcularSaldoPagamento(params) {
    const valorRecebido = params.valorRecebido.toFixed(2);
    const pagamento = params.pagamento.toFixed(2);
    return valorRecebido - pagamento;
}

export function calcularPagamentoTotal(params) {
    const amex = convertCurrencyToNumber(params.amex);
    const percentualJuros = divide(formatPercentToNumber(params.percentualJuros), 100);
    const jurosCalculado = multiply(params.jurosRemuneracao, percentualJuros);

    let sum1 = add(jurosCalculado, params.amortizacao);
    let sum2 = add(amex, params.premio);
    let result = add(sum1, sum2);

    if (params.totalIntegralizacao !== 0)
        result = multiply(result, params.totalIntegralizacao);

    return result;
}

export function calcularValorRecebidoUnitario(valorRecebido, totalIntegralizacao) {
    let result = valorRecebido;

    if (totalIntegralizacao !== 0)
        result = divide(valorRecebido, totalIntegralizacao);

    return result;
}

export function calcularSaldoUnitario(resultValorRecebidoUnitario, pagamentoTotalPmtCriUnitario) {
    return (resultValorRecebidoUnitario - pagamentoTotalPmtCriUnitario).toFixed(8).replace('.', ',');
}

export function calcularPagamentoTotalUnitario(params) {
    const amex = convertCurrencyToNumber(params.amex);
    let jurosCalculado = params.jurosRemuneracao;

    if (params.percentualJuros) {
        const percentualJuros = divide(formatPercentToNumber(params.percentualJuros), 100);
        jurosCalculado = multiply(params.jurosRemuneracao, percentualJuros);
    }

    let sum1 = add(jurosCalculado, params.amortizacao);
    let sum2 = add(amex, params.premio);
    let result = add(sum1, sum2);

    return result;
}

export function showPagamentoDeleteDialog(showDialog, paramsReiniciarPagamento) {
    return (dispatch) => {
        return dispatch([
            {
                type: PAGAMENTO_SHOW_DELETE_DIALOG,
                payload: {
                    showDialog, 
                    paramsReiniciarPagamento
                }
            }
        ])
    }
}


// ENDPOINTS NORMALIZADOS PARA A NOVA VERSÃO DO WORKFLOW ---------------------------------------------------------------------------------------


// export function loadPagamentoForm(idPagamento) {
//     return dispatch => {
//         return axios.get(PAGAMENTO_API + `tela/${idPagamento || ''}`)
//             .then(result => {
//                 const pagamentoPrecificacao = result.content;
//                 let values = serializeFieldsPagamentoFluxo(pagamentoPrecificacao);

//                 dispatch([
//                     {
//                         type: LOAD_TELA_FLUXOS_PAGAMENTO,
//                         payload: {}
//                     },
//                 ]);

//                 dispatch([
//                     idPagamento ? history.push(`/${PRECIFICACAO_ROUTE_PATH}/${PAGAMENTO_ROUTE_PATH}/${FLUXO_ROUTE_PATH}/${idPagamento}`) : undefined,
//                     showAllMessages(result.messages),
//                     initialize("FasePagamentoForm", values),
//                     {
//                         type: LOAD_TELA_FLUXOS_PAGAMENTO,
//                         payload: values
//                     },
//                 ]);
//             });
//     };
// }

export function loadPagamentoForm(idPagamento, pagina = null) {
    return dispatch => {
        dispatch(changeMessage("Carregando Etapa de Pagamento"))
        return axios.get(PAGAMENTO_API + `tela/${idPagamento || ''}`)
            .then(result => {
                const pagamentoPrecificacao = result.content;
                const values = serializeFieldsPagamentoFluxo(pagamentoPrecificacao);

                dispatch([
                    {
                        type: LOAD_TELA_FLUXOS_PAGAMENTO,
                        payload: {}
                    },
                ]);

                if (result.messages.infos.length > 0) {
                    dispatch([
                        showAllMessages(result.messages),
                        sairDaPagina(),
                    ]);
                } else {
                    dispatch([
                        idPagamento ? history.push(`/${PRECIFICACAO_ROUTE_PATH}/${PAGAMENTO_ROUTE_PATH}/${FLUXO_ROUTE_PATH}/${idPagamento}`) : undefined,
                        showAllMessages(result.messages),
                        initialize("FasePagamentoForm", values),
                        {
                            type: LOAD_TELA_FLUXOS_PAGAMENTO,
                            payload: values
                        },
                    ]);
                }

            });
    };
}

// INICIA FLUXO DE PAGAMENTO
export function iniciarPagamento(formValues) {
    return (dispatch) => {
        return axios
            .post(`${PAGAMENTO_API}acao/iniciar`, formValues)
            .then(result => {
                dispatch([
                    loadPagamentoForm(result.content),
                    showAllMessages(result.messages),
                ])
            })
    }
}

// REINICIA FLUXO DE PAGAMENTO
export function reiniciarPagamento(values) {
    return (dispatch) => {
        return axios
            .post(`${PAGAMENTO_API}reiniciar`, values)
            .then(result => {
                dispatch([                    
                    loadPagamentoForm(result.content.idPrecificacao),
                    showAllMessages(result.messages),
                ])
            }); 
    }
}

// EXECUTA PRECIFICAÇÃO
export function executarPrecificacao(pagamento, formValues) {
    return (dispatch) => {
        const values = deserializeFieldsEtapaFechamento(pagamento, formValues);

        return axios
            .put(`${PAGAMENTO_API}acao/fechar`, values)
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                    changeLoading(true),
                ])
            })
    }
}

// BUSCA DADOS MODAL APÓS EXECUÇÃO DA PRECIFICAÇÃO
export function buscaPagamentoModalPrecificacao(id) {
    return (dispatch) => {
        return axios
            .get(`${PAGAMENTO_API}${id}/modal`)
            .then(result => {
                dispatch([
                    loadPagamentoForm(result.content.idPagamentoPrecificacao),
                    showAllMessages(result.messages),
                    {
                        type: PAGAMENTO_MODAL_PRECIFICACAO,
                        payload: result
                    },
                ])
            })
    }
}

// VALIDAR MODAL PAGAMENTO PRECIFICAÇÃO
export function validaPagamentoModalPrecificacao(values) {
    return (dispatch) => {
        return axios
            .put(`${PAGAMENTO_API}acao/validar`, values)
            .then(result => {
                dispatch([
                    loadPagamentoForm(values.idPagamentoPrecificacao),
                    showAllMessages(result.messages),
                ])
            })
    }
}

// BUSCA EXTERNA PAGAMENTO AF
export function buscaExternoPagamentoAF(id) {
    return (dispatch) => {
        return axios
            .get(`${PAGAMENTO_API}af/${id}`)
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                    {
                        type: EXTERNO_PAGAMENTO_AF,
                        payload: {...result, content: {...result.content, idExternoAF: id}}
                    },
                ])
            })
    }
}

// VERIFICA LINK EXTERNO AF
export function verificaLinkExternoPagamentoAF(id) {
    return (dispatch) => {
        return axios
            .get(`${PAGAMENTO_API}af/${id}/link`)
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                    {
                        type: CHECK_LINK_EXTERNO_PAGAMENTO_AF,
                        payload: result
                    },
                ])
            })
    }
}

// APROVA/REVISAO/SEM RESPOSTA - PAGAMENTO AF
export function acoesPagamentoAF(values, tipo = null) {
    return (dispatch) => {
        return axios
            .put(`${PAGAMENTO_API}af/${values.id}/${tipo}`, values)
            .then(result => {
                let userLogged = JSON.parse(localStorage.getItem("userLogged"));
                dispatch([
                    showAllMessages(result.messages),
                    !userLogged ? buscaExternoPagamentoAF(values.id) : loadPagamentoForm(values.idPagamentoPrecificacao),
                ])
            })
    }
}

// CANCELA REVISÃO
export function cancelarRevisao(values) {
    return (dispatch) => {
        return axios
            .post(`${PAGAMENTO_API}cancelarcorrecao`, values)
            .then(result => {
                dispatch([                    
                    loadPagamentoForm(values.idPagamentoPrecificacao),
                    showAllMessages(result.messages),
                ])
            }); 
    }
}

// CONFIRMA ENVIO CAMARA
export function confirmaCamaraPagamento(formValues) {
    return (dispatch) => {
        return axios
            .put(`${PAGAMENTO_API}acao/lancar`, formValues)
            .then(result => {
                dispatch([
                    loadPagamentoForm(formValues.idPagamentoPrecificacao),
                    showAllMessages(result.messages),
                ])
            })
    }
}

// AGENDAMENTO BANCO LIQUIDANTE
export function solicitaAgendamentoPagamento(formValues) {
    return (dispatch) => {
        return axios
            .put(`${PAGAMENTO_API}acao/agendar`, formValues)
            .then(result => {
                dispatch([
                    loadPagamentoForm(formValues.idPagamentoPrecificacao),
                    showAllMessages(result.messages),
                ])
            })
    }
}

// CONFIRMA PAGAMENTO
export function lancarConfirmacaoPagamento(formValues) {
    return (dispatch) => {
        return axios
        .put(`${PAGAMENTO_API}acao/pagar`, formValues)
        .then(result => {
                const id = formValues?.idPagamentoPrecificacao;
                dispatch([
                    alteraSaldoDisponivel({ idPagamentoPrecificacao: id, saldoDisponivel: true }, false),
                    alteraPossuiRecurso({ idPagamentoPrecificacao: id, possuiRecurso: true }),
                    showAllMessages(result.messages),
                ])
            })
    }
}

// MODIFICA O SALDO DISPONÍVEL
export function alteraSaldoDisponivel(values, load = true) {
    return (dispatch) => {
        return axios
            .put(`${PAGAMENTO_API}/saldodisponivel`, values)
            .then(result => {
                dispatch([
                    load ? loadPagamentoForm(values.idPagamentoPrecificacao) : null,
                    showAllMessages(result.messages),
                ])
            })
    }
}

// MODIFICA O POSSUI RECURSO
export function alteraPossuiRecurso(values, load = true) {
    return (dispatch) => {
        return axios
            .put(`${PAGAMENTO_API}/possuirecurso`, values)
            .then(result => {
                dispatch([
                    load ? loadPagamentoForm(values.idPagamentoPrecificacao) : null,
                    showAllMessages(result.messages),
                ])
            })
    }
}

// EXPORTA ARQUIVO CAMARA LIQUIDAÇÃO - TABELA
export function exportaArquivoCamaraPagamento(formValues) {
    return (dispatch) => {
        return axios
            .post(`${PAGAMENTO_API}exportar/camaraliquidacao`, formValues)
            .then(result => {
                var nomeArquivo = `Camara_Liquidacao_${formValues.codigoOpea}_${formValues.dataPagamento}.txt`;
                dispatch([
                    downloadTxtFile(result.content, nomeArquivo),
                    showAllMessages(result.messages),
                ])
            });
    }
}

// EXPORTA ARQUIVO BANCO LIQUIDANTE - TABELA
export function exportaArquivoBancoLiquidante(formValues) {
    return (dispatch) => {
        return axios
            .post(`${PAGAMENTO_API}exportar/bancoliquidante`, formValues)
            .then(result => {
                var nomeArquivo = `Banco_Liquidante_${formValues.codigoOpea}_${formValues.dataPagamento}.xlsx`;
                dispatch([
                    downloadExcelFile(result.content, nomeArquivo),
                    showAllMessages(result.messages),
                ])
            });
    }
}

// EXPORTA ARQUIVO PROTHEUS
export function exportaArquivoProtheus(formValues) {
    return (dispatch) => {
        return axios
            .post(`${PAGAMENTO_API}exportar/protheus`, formValues)
            .then(result => {
                dispatch([
                    downloadExcelFile(result.content?.arquivo, result.content?.nomeArquivoProtheus),
                    showAllMessages(result.content?.message),
                ])
            });
    }
}

// RECEBE NOTIFICAÇÃO EM TEMPO REAL
export function recebeNotificacaoPagamentoJob(id) {
    return (dispatch) => {
        dispatch([
            {
                type: OPEN_MODAL_EXECUCAO_PRECIFICACAO,
                payload: { open: true, idPagamentoPrecificacao: id }
            },
        ])
    }
}

// NOTIFICAÇÃO ERRO PRECIFICAÇÃO
export function notificaErroPagamento() {
    return (dispatch) => {
      toastr.error("Erro", "Ocorreu um erro no Job da Execução da Precificação");
      dispatch([
        changeLoading(false),
      ]);
    };
}

export function limpaModalPrecificacao() {
    return (dispatch, getState) => {
        return dispatch([
            {
                type: OPEN_MODAL_EXECUCAO_PRECIFICACAO,
                payload: { open: false, idPagamentoPrecificacao: null }
            },
        ]);
    }
}
  

// CÁCULOS FLUXO PAGAMENTO -------------------

export function resetaAmex() {
    return (dispatch, getState) => {
        return dispatch([
            change("FasePagamentoForm", "amex", convertCurrency(0, 8)),
            calcularPagamentoTotalPMT(),
        ]);
    }
}

export function maxJurosValorTotal() {
    return (dispatch, getState) => {
        const selector = formValueSelector('FasePagamentoForm');
        
        let jurosRemuneracao = selector(getState(), 'jurosRemuneracao') ? convertCurrencyToNumber(selector(getState(), 'jurosRemuneracao')) : 0;

        return dispatch([
            change("FasePagamentoForm", "jurosPercentual", convertCurrency(100, 4)),
            change("FasePagamentoForm", "jurosValorAPagar", convertCurrency(jurosRemuneracao, 8)),
            calcularPagamentoTotalPMT(),
        ]);
    }
}

export function minJurosValorTotal() {
    return (dispatch) => {
        return dispatch([
            change("FasePagamentoForm", "jurosPercentual", convertCurrency(0, 4)),
            change("FasePagamentoForm", "jurosValorAPagar", convertCurrency(0, 8)),
            calcularPagamentoTotalPMT(),
        ]);
    }
}

// CALCULOS JUROS

export function calcularJurosValorAPagar(jurosValorAPagarPrecisao) {
    return (dispatch, getState) => {
        const selector = formValueSelector('FasePagamentoForm');
        
        let jurosPercentual = selector(getState(), 'jurosPercentual') ? convertCurrencyToNumber(selector(getState(), 'jurosPercentual')) : 0;
        let jurosRemuneracao = selector(getState(), 'jurosRemuneracao') ? convertCurrencyToNumber(selector(getState(), 'jurosRemuneracao')) : 0;
        
        jurosPercentual = (jurosPercentual <= 0) ? 0 : (jurosPercentual >= 100) ? 100 : jurosPercentual;
        jurosPercentual = normalizeDecimals(jurosPercentual, 16, true); // jurosPercentual não tem precisão, 16 é default, e true pois o result causa dízima, necessitando de arredondamento

        let result = (jurosPercentual / 100) * jurosRemuneracao
        result = normalizeDecimals(result, jurosValorAPagarPrecisao);

        return dispatch([
            change("FasePagamentoForm", "jurosPercentual", jurosPercentual),
            change("FasePagamentoForm", "jurosValorAPagar", result),
        ]);
    }
}

export function calcularJurosPercentual(jurosValorAPagarPrecisao) {
    return (dispatch, getState) => {
        const selector = formValueSelector('FasePagamentoForm');
     
        let jurosValorAPagar = selector(getState(), 'jurosValorAPagar') ? convertCurrencyToNumber(selector(getState(), 'jurosValorAPagar')) : 0;
        let jurosRemuneracao = selector(getState(), 'jurosRemuneracao') ? convertCurrencyToNumber(selector(getState(), 'jurosRemuneracao')) : 0;

        jurosValorAPagar = (jurosValorAPagar <= 0) ? 0 : (jurosValorAPagar >= jurosRemuneracao) ? jurosRemuneracao : jurosValorAPagar;
        jurosValorAPagar = normalizeDecimals(jurosValorAPagar, jurosValorAPagarPrecisao);

        let result = (jurosValorAPagar / jurosRemuneracao) * 100;
        result = normalizeDecimals(result, 16, true); // jurosPercentual não tem precisão, 16 é default, e true pois o result causa dízima, necessitando de arredondamento

        return dispatch([
            change("FasePagamentoForm", "jurosValorAPagar", jurosValorAPagar),
            change("FasePagamentoForm", "jurosPercentual", result),
        ]);
    }
}

// CALCULOS AMORTIZAÇÃO

export function calcularAmortizacaoValorAPagar(amortizacaoValorAPagarPrecisao, amortizacaoPercentualPrecisao) {
    return (dispatch, getState) => {
        const selector = formValueSelector('FasePagamentoForm');
        
        let amortizacaoPercentual = selector(getState(), 'amortizacaoPercentual') ? convertCurrencyToNumber(selector(getState(), 'amortizacaoPercentual')) : 0;
        let valorNominalAtualizado = selector(getState(), 'valorNominalAtualizado') ? convertCurrencyToNumber(selector(getState(), 'valorNominalAtualizado')) : 0;
        
        amortizacaoPercentual = (amortizacaoPercentual <= 0) ? 0 : (amortizacaoPercentual >= 100) ? 100 : amortizacaoPercentual;
        amortizacaoPercentual = normalizeDecimals(amortizacaoPercentual, amortizacaoPercentualPrecisao, true); // true pois o result causa dízima, necessitando de arredondamento

        let result = (amortizacaoPercentual / 100) * valorNominalAtualizado;
        result = normalizeDecimals(result, amortizacaoValorAPagarPrecisao);

        return dispatch([
            change("FasePagamentoForm", "amortizacaoPercentual", amortizacaoPercentual),
            change("FasePagamentoForm", "amortizacaoValorAPagar", result),
        ]);
    }
}

export function calcularAmortizacaoPercentual(amortizacaoValorAPagarPrecisao, amortizacaoPercentualPrecisao) {
    return (dispatch, getState) => {
        const selector = formValueSelector('FasePagamentoForm');
     
        let amortizacaoValorAPagar = selector(getState(), 'amortizacaoValorAPagar') ? convertCurrencyToNumber(selector(getState(), 'amortizacaoValorAPagar')) : 0;
        let valorNominalAtualizado = selector(getState(), 'valorNominalAtualizado') ? convertCurrencyToNumber(selector(getState(), 'valorNominalAtualizado')) : 0;

        amortizacaoValorAPagar = (amortizacaoValorAPagar <= 0) ? 0 : (amortizacaoValorAPagar >= valorNominalAtualizado) ? valorNominalAtualizado : amortizacaoValorAPagar;
        amortizacaoValorAPagar = normalizeDecimals(amortizacaoValorAPagar, amortizacaoValorAPagarPrecisao);

        let result = (amortizacaoValorAPagar / valorNominalAtualizado) * 100;
        result = normalizeDecimals(result, amortizacaoPercentualPrecisao, true); // true pois o result causa dízima, necessitando de arredondamento

        return dispatch([
            change("FasePagamentoForm", "amortizacaoValorAPagar", amortizacaoValorAPagar),
            change("FasePagamentoForm", "amortizacaoPercentual", result),
        ]);
    }
}

// CÁLCULO 'Valor Fechamento (Unitário)'

export function calcularValorFechamentoUnitario() {
    return (dispatch, getState) => {
        const selector = formValueSelector('FasePagamentoForm');
     
        let valorFechamento = selector(getState(), 'valorFechamento') ? convertCurrencyToNumber(selector(getState(), 'valorFechamento')) : 0;
        let quantidadeIntegralizada = selector(getState(), 'quantidadeIntegralizada') ? convertCurrencyToNumber(selector(getState(), 'quantidadeIntegralizada')) : 0;

        valorFechamento = normalizeDecimals(valorFechamento, 2); // 2 é precisão default de valores comuns, sem arredondamento

        let result = valorFechamento / quantidadeIntegralizada;
        result = normalizeDecimals(result, 8); // 8 é precisão default de valores unitários, sem arredondamento

        return dispatch([
            change("FasePagamentoForm", "valorFechamentoUnitario", result),
            calcularSaldoFechamentoUnitario(),
        ]);
    }
}

// CÁLCULO 'Pagamento Total PMT & Pagamento Total PMT (Unitário)'

export function calcularPagamentoTotalPMT() {
    return (dispatch, getState) => {
        const selector = formValueSelector('FasePagamentoForm');
     
        let jurosValorAPagar = selector(getState(), 'jurosValorAPagar') ? convertCurrencyToNumber(selector(getState(), 'jurosValorAPagar')) : 0;
        let amortizacaoValorAPagar = selector(getState(), 'amortizacaoValorAPagar') ? convertCurrencyToNumber(selector(getState(), 'amortizacaoValorAPagar')) : 0;
        let amex = selector(getState(), 'amex') ? convertCurrencyToNumber(selector(getState(), 'amex')) : 0;
        let eventoGenerico = selector(getState(), 'eventoGenerico') ? convertCurrencyToNumber(selector(getState(), 'eventoGenerico')) : 0;
        let quantidadeIntegralizada = selector(getState(), 'quantidadeIntegralizada') ? convertCurrencyToNumber(selector(getState(), 'quantidadeIntegralizada')) : 0;

        amex = normalizeDecimals(amex, 8); // 8 é precisão default de valores unitários, sem arredondamento
        eventoGenerico = normalizeDecimals(eventoGenerico, 8); // 8 é precisão default de valores unitários, sem arredondamento

        let soma = jurosValorAPagar + amortizacaoValorAPagar + amex + eventoGenerico;
        soma = normalizeDecimals(soma, 8); // 8 é precisão default de valores unitários, sem arredondamento
        let total = soma * quantidadeIntegralizada;
        total = normalizeDecimals(total, 2); // 2 é precisão default de valores comuns, sem arredondamento

        return dispatch([
            change("FasePagamentoForm", "pagamentoTotalPmt", total),
            change("FasePagamentoForm", "pagamentoTotalPmtUnitario", soma),
            change("FasePagamentoForm", "amex", amex),
            change("FasePagamentoForm", "eventoGenerico", eventoGenerico),
            calcularSaldoFechamento(),
            calcularSaldoFechamentoUnitario(),
        ]);
    }
}

// CÁLCULO 'Saldo Fechamento'

export function calcularSaldoFechamento() {
    return (dispatch, getState) => {
        const selector = formValueSelector('FasePagamentoForm');

        let valorFechamento = selector(getState(), 'valorFechamento') ? convertCurrencyToNumber(selector(getState(), 'valorFechamento')) : 0;
        let pagamentoTotalPmt = selector(getState(), 'pagamentoTotalPmt') ? convertCurrencyToNumber(selector(getState(), 'pagamentoTotalPmt')) : 0;

        valorFechamento = normalizeDecimals(valorFechamento, 2); // 2 é precisão default de valores comuns, sem arredondamento
        pagamentoTotalPmt = normalizeDecimals(pagamentoTotalPmt, 2); // 2 é precisão default de valores comuns, sem arredondamento

        let result = normalizeDecimals((valorFechamento - pagamentoTotalPmt), 2); // 2 é precisão default de valores comuns, sem arredondamento
        // result = result === 0 ? "0,00" : result;

        return dispatch([
            change("FasePagamentoForm", "saldoFechamento", convertCurrencyNumber(result, false, 2)),
        ]);
    }
}

// CÁLCULO 'Saldo Fechamento (Unitário)'

export function calcularSaldoFechamentoUnitario() {
    return (dispatch, getState) => {
        const selector = formValueSelector('FasePagamentoForm');

        let valorFechamentoUnitario = selector(getState(), 'valorFechamentoUnitario') ? convertCurrencyToNumber(selector(getState(), 'valorFechamentoUnitario')) : 0;
        let pagamentoTotalPmtUnitario = selector(getState(), 'pagamentoTotalPmtUnitario') ? convertCurrencyToNumber(selector(getState(), 'pagamentoTotalPmtUnitario')) : 0;

        valorFechamentoUnitario = normalizeDecimals(valorFechamentoUnitario, 8); // 8 é precisão default de valores unitários, sem arredondamento
        pagamentoTotalPmtUnitario = normalizeDecimals(pagamentoTotalPmtUnitario, 8); // 8 é precisão default de valores unitários, sem arredondamento

        let result = normalizeDecimals((valorFechamentoUnitario - pagamentoTotalPmtUnitario), 8) // 8 é precisão default de valores unitários, sem arredondamento

        return dispatch([
            change("FasePagamentoForm", "saldoFechamentoUnitario", result),
        ]);
    }
}
