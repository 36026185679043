import { LOAD_FILTER_FORM_LOG, LOAD_FORM_LOG, CLEAN_LIST_CONTENT, DATA_INICIO_LOG, DATA_FINAL_LOG } from "../../actions/precificacao/LogAction";

const INITIAL_STATE = {
  dataInicioLog: "",
  dataFinalLog: "",
  logFormTypes: null,
  logForm: {
    newValues: "",
    oldValues: "",
    dateTime: "",
    action: "",
    tableName: "",
    id: "",
  },
};

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOAD_FORM_LOG:
      return {
        ...state,
        logForm: action.payload,
      };
    case LOAD_FILTER_FORM_LOG:
      return {
        ...state,
        logFormTypes: action.payload,
      };
    case DATA_INICIO_LOG:
      return {
        ...state,
        dataInicioLog: action.payload,
      };
    case DATA_FINAL_LOG:
      return {
        ...state,
        dataFinalLog: action.payload,
      };
    case CLEAN_LIST_CONTENT:
      return {
        ...state,
        logForm: {
          newValues: "",
          oldValues: "",
          dateTime: "",
          action: "",
          tableName: "",
          id: "",
        },
        logFormTypes: [],
        dataInicioLog: "",
        dataFinalLog: "",
      };
    default:
      return state;
  }
}
