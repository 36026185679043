import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  loadFormParticipante,
  saveParticipante,
} from "../../../../actions/participante/ParticipanteAction";
import ContainerPage from "../../../common/container/ContainerPage";
import ParticipanteForm from "./form/ParticipanteForm";
import { authorizationFunction } from "../../../../actions/authorization/AuthorizationAction";
import { PARTICIPANTE, READ } from "../../../../utils/actionsAndFeaturesAuth";

let ParticipanteIncludePage = (props) => {
  const { saveParticipante, loadFormParticipante, authorizationFunction, tipoList } = props;

  React.useEffect(() => {
    let idParticipante = props.match.params.idParticipante;
    async function fetchLoad() {
      await authorizationFunction(
        { idAcao: READ, idFuncionalidade: PARTICIPANTE },
        () => loadFormParticipante(idParticipante)
      );
    }

    fetchLoad();
  }, []);

  return (
    <ContainerPage>
      <ParticipanteForm
        onSubmit={(v) => saveParticipante({tipoList, ...v})}
        readOnly={false}
        floatButtonVisible={true}
        title={"Criar Participante"}
        justView={false}
      />
    </ContainerPage>
  );
};

const mapStateToProps = (state) => ({
  tipoList: state.participanteReducer.participanteForm?.tipoList || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authorizationFunction,
      loadFormParticipante,
      saveParticipante,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParticipanteIncludePage);
