import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  getFundos,
  removeFundo,
  showFundoDeleteDialog,
  loadFormFundo,
} from "../../../../../actions/fundo/FundoAction";

import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import TableEmptyData from "../../../../common/table/TableEmptyData";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";
import TableActionWithRouteAuthorization from "../../../../common/table/TableActionWithRouteAuthorization";
import {
  FUNDO,
  DELETE,
} from "../../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";
import { formatCNPJ } from "../../../../../utils/helpers/StringHelper";
import { convertCurrencyNumber } from "../../../../../utils/helpers/NumberHelper";
import ReactTooltip from "react-tooltip";

class FundoList extends Component {
  render() {
    const {
      selectedFundos,
      fundoData,
      messages,
      getFundos,
      removeFundo,
      showFundoDeleteDialog,
      showDeleteDialog,
      loadFormFundo,
      authorizationFunction,
    } = this.props;

    const idsFundos = selectedFundos.map((o) => o.key);
    const emptyData = messages && messages.infos && messages.infos[0];

    const tableHeader = (
      <tr>
        <th className="text-center fw-700">Fundo</th>
        <th className="text-center fw-700">CNPJ Fundo</th>
        <th className="text-center fw-700">Patrimônio Líquido</th>
        <th className="text-center fw-700">Administrador</th>
        <th className="text-center fw-700">Data Constituição</th>
        <th className="text-center fw-700">Data de Vencimento</th>
        <th className="text-center"></th>
      </tr>
    );

    const tableContent =
      fundoData && fundoData.hasItems ? (
        fundoData.items.map((fundo, index) => (
          <tr key={fundo.id}>
            <td className="text-center fw-400">{fundo.nome || "--"}</td>
            <td className="text-center fw-400">{formatCNPJ(fundo.cnpj) || "--"}</td>
            <td className="text-center fw-400">
              {fundo.ultimoPLValidado ? (
                <div
                  data-type="opea"
                  data-for={`fundoPL-${index}`}
                  data-tip={getDateFormatedFromString(fundo.dataUltimoPLValidado)}
                >
                  <span>{convertCurrencyNumber(fundo.ultimoPLValidado, "R$", 2)}</span>
                  <ReactTooltip
                    noArrow
                    effect="solid"
                    id={`fundoPL-${index}`}
                    className="px-2 py-1"
                    globalEventOff="click"
                    style={{ width: "100px" }}
                  />
                </div>
              ) : "--"}
            </td>
            <td className="text-center fw-400">{fundo.administrador || "--"}</td>
            <td className="text-center fw-400">
              {getDateFormatedFromString(fundo.dataConstituicao) || "--"}
            </td>
            <td className="text-center fw-400">
              {getDateFormatedFromString(fundo.dataVencimento) || "--"}
            </td>
            <TableActionWithRouteAuthorization
              space="2"
              feature={FUNDO}
              viewAction={() => loadFormFundo(fundo.id)}
              updateAction={() => loadFormFundo(fundo.id, true)}
            />
          </tr>
        ))
      ) : (
        <TableEmptyData message={emptyData} />
      );

    return (
      <div>
        {fundoData.totalCount > 0 && (
          <Table
            withoutHeader
            classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
            totalItems={fundoData.totalCount}
            pagination={
              <PaginationRB
                pageNumber={fundoData.pageIndex - 1}
                itemsPerPage={fundoData.pageSize}
                totalItems={fundoData.totalCount}
                filter={idsFundos}
                fetchFunction={getFundos}
                pageRange={5}
              />
            }
          >
            <TableHeader>{tableHeader}</TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        )}

        <ConfirmDialog
          title="Aviso"
          contentText="Confirmar exclusão do fundo selecionado?"
          textButtonCancel="CANCELAR"
          textButtonConfirm="CONFIRMAR"
          showDialog={showDeleteDialog.showDialog}
          onClose={() => showFundoDeleteDialog(false)}
          onConfirm={() =>
            authorizationFunction(
              { idAcao: DELETE, idFuncionalidade: FUNDO },
              () => removeFundo(showDeleteDialog.idFundo, idsFundos)
            )
          }
          type="warning"
          maxWidth="md"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showDeleteDialog: state.fundoReducer.showFundoDeleteDialog,
  fundoData: state.fundoReducer.fundos,
  messages: state.fundoReducer.messages,
  selectedFundos: state.fundoReducer.selectedFundoOptions,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getFundos,
      removeFundo,
      showFundoDeleteDialog,
      loadFormFundo,
      authorizationFunction,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(FundoList)
);
