import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  saveAprovador,
  loadFormAprovadores,
} from "../../../../actions/aprovadores/AprovadoresAction";

import AprovadoresForm from "./form/AprovadoresForm";

class AprovadoresIncludePage extends Component {
  componentDidMount() {
    this.props.loadFormAprovadores();
  }

  render() {
    const { saveAprovador } = this.props;

    return (
      <AprovadoresForm
        title={"Novo - Aprovador"}
        onSubmit={saveAprovador}
        readOnly={false}
        floatButtonVisible={true}
      />
    );
  }
}

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormAprovadores,
      saveAprovador,
    },
    dispatch
  );

export default withRouter(
  connect(null, mapDispacthToProps)(AprovadoresIncludePage)
);
