import React from 'react';
import Pagination from "react-js-pagination";
import PropTypes from 'prop-types';
import { getNumberValue } from '../../../utils/helpers/NumberHelper';

const PaginationRB = ({
    pageNumber, 
    itemsPerPage, 
    totalItems, 
    fetchFunction,
    filter, 
    pageRange,
}) => {

    const refreshPageHandleChange = (pageNumberAux) => {
       filter ? fetchFunction(filter, pageNumberAux) : fetchFunction(pageNumberAux)
    }

    return (
        <nav 
            aria-label="Page navigation" 
            style={getNumberValue(totalItems) === 0 ? {'display': 'none'} : {} }
        >
            <Pagination             
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination mb-0 mt-3 mb-2"
                firstPageText={<i id="firstPage" className='fa fa-angle-double-left' />}
                lastPageText={<i id="lastPage" className='fa fa-angle-double-right' />}
                prevPageText={<i id="prevPage"className='fa fa-angle-left' />}
                nextPageText={<i id="nextPage" className='fa fa-angle-right' />}
                activePage={pageNumber+1}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={totalItems}
                pageRangeDisplayed={pageRange}
                onChange={refreshPageHandleChange}
            />
        </nav>
    );
}

PaginationRB.propTypes = {
    pageNumber: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number,
    totalItems: PropTypes.number.isRequired,
    fetchFunction: PropTypes.func.isRequired,
}

export default PaginationRB;
