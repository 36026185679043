import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { newPatrimonioForm } from "../../../../actions/patrimonio/PatrimonioAction";
import Button from "../../../common/button/Button";
import Container from "../../../common/container/Container";
import ContainerRow from "../../../common/container/ContainerRow";
import PatrimonioPicker from "../../pickers/PatrimonioPicker";
import PatrimonioList from "./list/PatrimonioList";
import { authorizationFunction } from "../../../../actions/authorization/AuthorizationAction";
import { CREATE, PATRIMONIO } from "../../../../utils/actionsAndFeaturesAuth";

const Patrimonio = props => (
  <Container>
    <p className="page-head__title f-36 mb-0 mt-2 px-3 py-4">Patrimônios</p>
    <div className="col-md my-2">
      <ContainerRow>
        <div className="shadow-custom rounded-12 w-100 pd-12 mx-3 mb-3 mt-0 d-flex flex-row justify-content-between align-items-end bg-white">
          <div className="d-flex flex-column w-100">
            <label className="f-12 text-dark mb-1">Busca</label>
            <PatrimonioPicker />
          </div>
          <Button
            classes="rounded text-white p-bg-imp mb-0 h-38 px-4 py-1"
            activeClassName={"col-lg-auto pr-0"}
            buttonStyle={"success"}
            action={() => props.authorizationFunction(
              { idAcao: CREATE, idFuncionalidade: PATRIMONIO },
              () => props.newPatrimonioForm()
            )}
            icon={"plus-circle"}
            name={"Cadastrar Patrimônio"}
          />
        </div>
      </ContainerRow>
      <ContainerRow>
        <div className="col-md">
          <PatrimonioList />
        </div>
      </ContainerRow>
    </div>
  </Container>
);

const mapDispatchToProps = dispatch => bindActionCreators({
  newPatrimonioForm, authorizationFunction,
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Patrimonio));
