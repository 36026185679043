/* eslint eqeqeq: 0 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";

import {
    initializeForm,
    editPassivo,
} from '../../../../../actions/operacao/PassivoAction';

import {
    OPERACAO_TABS,
} from './../OperacaoTabs';
import PassivoForm from './form/PassivoForm';
import ContainerPage from '../../../../common/container/ContainerPage';
import { formValueSelector } from 'redux-form';

const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");

class PassivoUpdatePage extends Component {
    componentDidMount() {
        let idPassivo = this.props.match.params.idPassivo;
        this.props.initializeForm(idPassivo);
    }

    render() {
        const {
            editPassivo,
            operacoes,
            gestora,
            typeSubmit,
        } = this.props;
        
        const idsOperacoes = operacoes.map(o => o.key);
        
        return (
            <ContainerPage
            tabs={OPERACAO_TABS}
            paramsRoute={idsOperacoes}
            >
                <PassivoForm
                    title={`Editar - ${gestora ? "Cota" : 'Passivo'}`}
                    onSubmit={(e) => editPassivo(e, typeSubmit)}
                    readOnly={false}
                    floatButtonVisible={true}
                    editing={true}
                />
            </ContainerPage>
        );
    }
}

const selector = formValueSelector("passivoForm");

const mapStateToProps = state => ({
    tab: state.tab,
    operacoes: state.operacaoReducer.selectedOperacoes,
    gestora: currEnv == process.env.REACT_APP_ENV_GEST_ID,
    typeSubmit: selector(state, "typeSubmit"),
});

const mapDispacthToProps = dispatch => bindActionCreators({
    initializeForm,
    editPassivo,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(PassivoUpdatePage));
