import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { loadFormDominio } from "../../../../../actions/precificacao/DominioAction";

import ContainerPage from "../../../../common/container/ContainerPage";
import DominioForm from "./form/DominioForm";

class DominioUpdatePage extends Component {
  componentDidMount() {
    const idDominio = this.props.match.params.idDominio;
    this.props.loadFormDominio(idDominio, true);
  }

  render() {
    return (
      <ContainerPage>
        <DominioForm title={"Editar - Domínio"} readOnly={false} floatButtonVisible={true} />
      </ContainerPage>
    );
  }
}

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormDominio,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispacthToProps)(DominioUpdatePage));
