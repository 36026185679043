/* eslint eqeqeq: 0 */

import { initialize } from "redux-form";
import { extractFromObj } from "../../../../utils/helpers/ObjectHelper";

export function deserializeFieldsRemessaFormFilter(filters, page) {
    return {
        ...filters,
        status: filters?.status?.key ? filters?.status : null,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        }
    };
}

export function serializeFieldsRemessaForm(remessa) {
    let remessaData = {};
    
    if (remessa) {
        remessaData = {
            ...remessa,
            idRemessa: remessa?.identificador,
        }
    }

    return initialize("RemessaForm", remessaData);
}

export function deserializeFieldsRemessaForm(remessa) {
    let remessaData = {};

    if (remessa) {
        remessaData = {
            ...remessa,
            ativo: remessa?.ativo || false,
            idTipoAprovacao: extractFromObj(remessa?.idTipoAprovacao),
            idUsuario: extractFromObj(remessa?.idUsuario),
        }
    }

    return remessaData;
}