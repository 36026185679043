import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../common/container/ContainerPage";

import AssembleiaListFilter from "./form/AssembleiaListFilter";
import AssembleiaList from "./list/AssembleiaList";

let AssembleiaPage = (props) => {
  return (
    <ContainerPage>
      <AssembleiaListFilter />
      <AssembleiaList />
    </ContainerPage>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(null, mapDispatchToProps)(AssembleiaPage);
