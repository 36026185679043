import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { loadFormLog } from "../../../../../actions/precificacao/LogAction";

import ContainerPage from "../../../../common/container/ContainerPage";
import LogForm from "./form/LogForm";

class LogVisualizePage extends Component {
  componentDidMount() {
    if (!this.props.log.id) {
      let idLog = this.props.match.params.idLog;
      this.props.loadFormLog(idLog);
    }
  }

  render() {
    return (
      <ContainerPage>
        <LogForm title={"DETALHE - Logs do Sistema"} readOnly={true} floatButtonVisible={false} />
      </ContainerPage>
    );
  }
}

const mapStateToProps = (state) => ({
  log: state.logReducer.logForm,
});
const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormLog,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(LogVisualizePage));
