/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  reduxForm,
  formValueSelector,
  Field,
  getFormValues,
  change,
} from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import Input from "../../../../common/input/Input";
import MultSelectListInput from "../../../../common/input/MultSelectListInput";
import { validate } from "../validation/ParticipanteValidation";
import { msgError } from "../../../../../utils/helpers/OthersHelpers";
import Button from "../../../../common/button/Button";
import FixedFloaterErrors from "../../../../common/floats/FixedFloaterErrors";
// MODALS
import ModalAction from "../../../../common/modal/ModalAction";
import MaskedFormatInput from "../../../../common/input/MaskedFormatInput";
import {
  customFormatCnpjCpf,
} from "../../../../../utils/helpers/StringHelper";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { backToList } from "../../../../../actions/participante/ParticipanteAction";
import { CSSTransition, TransitionGroup } from "react-transition-group";

let ParticipanteForm = (props) => {
  const {
    invalid,
    messages,
    tiposActive,
    participante,
    formValues,
    tipoList,
    tipos,
    // ACTIONS
    backToList,
    handleSubmit,
  } = props;

  const [confirmReturn, setConfirmReturn] = React.useState(false);

  const handleAddEmail = iTipo => {
    const newEmail = { id: null, email: '', idAssembleiaParticipante: participante?.id || null };
    props.change('ParticipanteForm', `tiposActive[${iTipo}].emailsList`, [
      ...(formValues?.tiposActive[iTipo]?.emailsList || []), newEmail
    ]);
  };
  
  const handleRemoveEmail = (iTipo, iEmail) => {
    props.change("ParticipanteForm", `tiposActive[${iTipo}].emailsList`, 
      (formValues?.tiposActive[iTipo]?.emailsList || []).filter((_, index) => index !== iEmail)
    );
  };
  
  const handleAddTipo = newTipo => {
    props.change("ParticipanteForm", "tiposActive", [
      ...(formValues?.tiposActive || []), 
      { key: newTipo.key, value: newTipo.value, raw: newTipo.raw, emailsList: [{ id: null, email: "", idAssembleiaParticipante: participante?.id || null }] }
    ]);
  };
  
  const handleRemoveTipo = tipoKey => {
    props.change("ParticipanteForm", "tiposActive", 
      (formValues?.tiposActive || []).filter(tipo => tipo.key !== tipoKey)
    );
  };  

  React.useEffect(() => {
    if (tipos && tiposActive) {
      const tiposToAdd = tipos.filter(t => !tiposActive.some(a => a.key === t.key));
      const tiposToRemove = tiposActive.filter(a => !tipos.some(t => t.key === a.key));
  
      tiposToAdd.forEach(handleAddTipo);
      tiposToRemove.forEach(t => handleRemoveTipo(t.key));
    }
  }, [tipos, tiposActive]);
  

  return (
    <Form className="w-100">
      <FormHeader title={"Participante"} offSet={20}></FormHeader>

      <FormContent handleSubmit={handleSubmit} classes="px-0">
        {/* ERRORS */}
        {messages?.hasError && (
          <FixedFloaterErrors messages={messages} title="Status do Cadastro" />
        )}

        <div className="col-12 px-0">
          <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
            <h3 className="my-0 page-head__title f-28">Geral</h3>
            <div className="row">
              <div className="col-lg">
                <Field
                  name="documento"
                  label="CPF/CNPJ"
                  maxLength="18"
                  inputId="documento"
                  className={"filter-input"}
                  component={MaskedFormatInput}
                  formatMask={customFormatCnpjCpf}
                />
              </div>
              <div className="col-lg">
                <Field
                  component={Input}
                  className={"filter-input"}
                  inputId="nome"
                  name="nome"
                  type="text"
                  label="Nome"
                />
              </div>
              <div className="col-lg">
                <Field
                  component={MultSelectListInput}
                  className="mb-0"
                  inputId="tipos"
                  name="tipos"
                  label="Tipos"
                  options={tipoList}
                  dataKey="key"
                  textField="value"
                  valueField="key"
                  filter="contains"
                />
              </div>
            </div>

            {tiposActive?.length > 0 && (<h3 className="my-0 page-head__title f-28">E-mails</h3>)}

            <TransitionGroup>
              {tiposActive?.length > 0 &&
                  tiposActive.map((tipo, iTipo) => (
                    <CSSTransition key={`tipos-${iTipo}`} timeout={300} classNames="item">
                      <div className="row flex-column mt-2" key={`tipos-${iTipo}`}>
                          <p className="px-3 mb-0 mt-2 fw-700 f-18">Emails <u>{tipo?.value}</u></p>
                          
                          <TransitionGroup>
                            {tipo.emailsList.map((email_, iEmail) => (
                              <CSSTransition key={`emails-${iEmail}`} timeout={300} classNames="item">
                                <div className="col-12 d-flex align-items-start justify-content-center" key={`emails-${iEmail}`}>
                                  <Field
                                    component={Input}
                                    outerClassName="my-2 flex-grow-1"
                                    className="filter-input"
                                    inputId={`tiposActive[${iTipo}].emailsList[${iEmail}].email`}
                                    name={`tiposActive[${iTipo}].emailsList[${iEmail}].email`}
                                    type="email"
                                    placeholder="Insira um email válido e pressione 'Tab' para criar um novo"
                                    onKeyDown={(e) => {
                                      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                                      
                                      if (e.key === 'Tab') {
                                        const valueCurrent = tiposActive[iTipo].emailsList[iEmail].email
                                        const valueNext = tiposActive[iTipo].emailsList[iEmail + 1]
                                        if (!valueNext && emailPattern.test(valueCurrent)) {
                                          handleAddEmail(iTipo)
                                        }
                                      }
                                    }}
                                  />
                                  {iEmail > 0 && (
                                    <FontAwesomeIcon
                                      size="lg"
                                      className="mx-3 mb-1 mt-3"
                                      role="button"
                                      color="#630a37"
                                      icon={faTrashAlt}
                                      onClick={() => handleRemoveEmail(iTipo, iEmail)}
                                    />
                                  )}
                                </div>
                              </CSSTransition>
                            ))}
                          </TransitionGroup>

                          <Button
                            type="button"
                            name={"Adicionar"}
                            activeClassName="px-3 py-2"
                            action={() => handleAddEmail(iTipo)}
                            classes="default-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                          />
                      </div>
                    </CSSTransition>
                  ))}
            </TransitionGroup>
          </div>
        </div>

        {/* --- ACTIONS --- */}
        <div className="col-12 px-0">
          <div className="d-flex flex-row justify-content-between align-items-center col-12 mb-4 mt-2 p-0">
            <Button
              type="button"
              name={"Cancelar Registro"}
              classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={() => setConfirmReturn(true)}
            />

            <Button
              type="submit"
              name={"Salvar Registro"}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={() => {
                if (invalid) msgError("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.");
              }}
            />
          </div>
        </div>
      </FormContent>

      {/* MODAL RETURN */}
      <ModalAction
        action={backToList}
        open={confirmReturn}
        title="Cancelar Alterações"
        close={() => setConfirmReturn(false)}
        message="Todo o progresso será perdido"
      />
    </Form>
  );
};

const selector = formValueSelector("ParticipanteForm");

const mapStateToProps = (state) => ({
  formValues: getFormValues("ParticipanteForm")(state),
  tipos: selector(state, "tipos") || [],
  tiposActive: selector(state, "tiposActive") || [],
  participante: state.participanteReducer.participanteForm?.assembleiaParticipante,
  participantes: state.participanteReducer.participantes,
  tipoList: state.participanteReducer.participanteForm?.tipoList || [],
  messages: state.participanteReducer.messages || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      change,
      backToList,
    },
    dispatch
  );

const ConnectedParticipanteForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ParticipanteForm)
);

export default reduxForm({ form: "ParticipanteForm", validate })(
  ConnectedParticipanteForm
);
