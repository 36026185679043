import {
    ERROR_MESSAGES_ADD,
    ERROR_MESSAGE_REMOVE,
    ERROR_MESSAGES_UNATHORIZED_ADD,
    ERROR_MESSAGE_UNATHORIZED_REMOVE
} from './../../actions/message/ErrorMessagesAPIAction';

const INITIAL_STATE = {
    messages: [],
    messagesUnathorized: []
}

export default function errorMessagesAPI(state = INITIAL_STATE, action = {}) {
    switch(action.type) {
        case ERROR_MESSAGES_ADD:
            return {
                ...state,
                messages: action.payload
            }
        case ERROR_MESSAGE_REMOVE:
            return {
                ...state,
                messages: removeErrorMessage(state.messages, action.payload)
            }
        case ERROR_MESSAGES_UNATHORIZED_ADD:
            return {
                ...state,
                messagesUnathorized: action.payload
            }
        case ERROR_MESSAGE_UNATHORIZED_REMOVE:
            return {
                ...state,
                messages: removeErrorMessage(state.messagesUnathorized, action.payload)
            }
        default:
            return state;
    }
}

function removeErrorMessage(errorMessages, index) {
    let newErrorMessages = [];
    
    if(errorMessages) {
        newErrorMessages = errorMessages.slice(0);
        newErrorMessages.splice(index, 1);
    }
    
    return newErrorMessages;
}