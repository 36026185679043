/* eslint eqeqeq: 0 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  LISTA_ROUTE_PATH,
  APROVACAO_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

import AprovacaoPage from "../pages/AprovacaoPage";
import ModalContratoDespesa from "../pages/modals/ModalContratoDespesa";
import ModalPedidoCompra from "../pages/modals/ModalPedidoCompra";
import ModalContratoReceita from "../pages/modals/ModalContratoReceita";
import ModalContasReceber from "../pages/modals/ModalContasReceber";

export default () => (
  <Switch>
    <Route
      exact={true}
      path={`/${APROVACAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`}
      component={AprovacaoPage}
      
    />
    <Route
      exact={true}
      path={`/${APROVACAO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/contrato/:idAprovacao`}
      render={({ match }) => (
        <div>
          <AprovacaoPage showModal={true} />
          <ModalContratoDespesa
            open={!!match.params.idAprovacao}
            idAprovacao={match.params.idAprovacao}
          />
        </div>
      )}
    />
    <Route
      exact={true}
      path={`/${APROVACAO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/contrato/receita/:idAprovacao`}
      render={({ match }) => (
        <div>
          <AprovacaoPage showModal={true} />
          <ModalContratoReceita
            open={!!match.params.idAprovacao}
            idAprovacao={match.params.idAprovacao}
          />
        </div>
      )}
    />
    <Route
      exact={true}
      path={`/${APROVACAO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/contas/:idAprovacao`}
      render={({ match }) => (
        <div>
          <AprovacaoPage showModal={true} />
          <ModalContasReceber
            open={!!match.params.idAprovacao}
            idAprovacao={match.params.idAprovacao}
          />
        </div>
      )}
    />
    <Route
      exact={true}
      path={`/${APROVACAO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/pedido/:idAprovacao`}
      render={({ match }) => (
        <div>
          <AprovacaoPage showModal={true} />
          <ModalPedidoCompra
            open={!!match.params.idAprovacao}
            idAprovacao={match.params.idAprovacao}
          />
        </div>
      )}
    />
  </Switch>
);
