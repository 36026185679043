import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PatrimonioForm from './PatrimonioForm';
import { getPatrimonio } from '../../../../../actions/patrimonio/PatrimonioAction';

class PatrimonioFormVisualize extends Component {
    componentDidMount() {
        let idPatrimonio = this.props.match.params.idPatrimonio;
        this.props.getPatrimonio(idPatrimonio);
    }

    render() {
        return (
            <PatrimonioForm 
                title={"Detalhar - Patrimônio"} 
                readOnly={true}
                view={true}
                floatButtonVisible={false} />
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getPatrimonio
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(PatrimonioFormVisualize));