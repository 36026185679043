/* eslint eqeqeq: 0 */
/* no-mixed-operators: 0 */

import axios from "axios";
import { multiply } from "mathjs";
import { change, formValueSelector, arrayRemoveAll, arrayPush, arrayRemove, getFormValues } from "redux-form";

import { selectTab } from "./tabs/TabsAction";
import { showAllMessages } from './../../actions/message/ErrorMessagesAPIAction';
import { showConfirmDialog } from './../common/modal/ConfirmDialogAction';

import {
    OPERACAO_ROUTE_PATH,
    PASSIVO_ROUTE_PATH,
    PATRIMONIO_ROUTE_PATH,
    LISTA_ROUTE_PATH,
    EDITAR_ROUTE_PATH,
    DETALHAR_ROUTE_PATH
} from "./../../components/common/routers/constantesRoutes";
import history from './../../components/common/routers/history';

import {
    serializeFieldsPassivoForm,
    deserializeFieldsPassivoForm,
    serializeBoolsOperacaoRegistro,
} from "./../../components/business/operacao/mappings/PassivoMap";

import {
    removeDuplicates,
    calculateSumNorm,
} from "./../../utils/helpers/ArrayHelper";
import { convertCurrencyToNumber } from "./../../utils/helpers/NumberHelper";
import { listItems } from "../common/Pagination";
import { deepClone } from "../../utils/helpers/ObjectHelper";
import { toastr } from "react-redux-toastr";
import { msgError } from "../../utils/helpers/OthersHelpers";
import { downloadExcelFile } from "../../utils/helpers/FileHelper";
import { changeMessage } from "../infra/LoadingScreenAction";

export const PASSIVO_FORM_TAB = "PASSIVO_FORM_TAB";
export const PASSIVO_FETCHED = "PASSIVO_FETCHED";
export const PATRIMONIO_FETCHED = "PATRIMONIO_FETCHED";
export const LOAD_FORM_PASSIVO = "LOAD_FORM_PASSIVO";
export const LOAD_FORM_PASSIVO_MOEDA = "LOAD_FORM_PASSIVO_MOEDA";
export const PASSIVO_SHOW_DELETE_DIALOG = "PASSIVO_SHOW_DELETE_DIALOG";
export const PASSIVO_EDIT_CURVA_FROM_TABLE = "PASSIVO_EDIT_CURVA_FROM_TABLE";
export const PASSIVO_CHANGE_BASE_CALCULO_DESCRIPTION = "PASSIVO_CHANGE_BASE_CALCULO_DESCRIPTION";
export const PASSIVO_INSERT_NOME_PASTA_CEDOC = "PASSIVO_INSERT_NOME_PASTA_CEDOC";
export const PASSIVO_CHANGE_DATA_EMISSAO = "PASSIVO_CHANGE_DATA_EMISSAO";
export const LOAD_CLASSES_FUNDO = "LOAD_CLASSES_FUNDO";
export const LOAD_SUBCLASSES_FUNDO = "LOAD_SUBCLASSES_FUNDO";
export const SAVE_FORM_PASSIVO_ERRORS = "SAVE_FORM_PASSIVO_ERRORS";
export const SHOW_MODAL_APPROVING_PASSIVO_WORKFLOW = "SHOW_MODAL_APPROVING_PASSIVO_WORKFLOW";
export const SHOW_MODAL_ONBOARDING_PASSIVO_WORKFLOW = "SHOW_MODAL_ONBOARDING_PASSIVO_WORKFLOW";
export const SHOW_MODAL_FINISH_PASSIVO_WORKFLOW = "SHOW_MODAL_FINISH_PASSIVO_WORKFLOW";
export const LOAD_FORM_PASSIVO_APROVACAO = "LOAD_FORM_PASSIVO_APROVACAO";
export const UPDATE_RATING_LIST_REGISTRO = "UPDATE_RATING_LIST_REGISTRO";

const OPERACAO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API;

const PASSIVO_API =
    OPERACAO_API + process.env.REACT_APP_PATH_PASSIVO_API;

const PATRIMONIO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PATRIMONIO_API;

const FUNDO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_FUNDO;

const SUBCLASSE_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_SUBCLASSE;
  

const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");
const gestora = currEnv == process.env.REACT_APP_ENV_GEST_ID;

export const KEY_TIPO_EVENTO_ATUALIZADO = 3;
export const PAGAMENTO_AMORTIZACAO_NAO_HA = 1;

export const PRIMEIRA_INTEGRALIZACAO = 1;
export const DATA_EMISSAO = 2;

export function showMessageAlert(msg) {
    return () => {
        msgError(msg);
        return false;
    }
}

function verificaMeses(periodo){
    switch (periodo?.id || periodo?.key) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      case 4:
        return 2;
      case 5:
        return 1;
    }
}

function validaTaxaCorrecaoMonetaria(passivo) {
    const { pagamentoPassivo } = passivo
    const { taxaJurosPagamentoList, tipoPagamentoPassivo } = pagamentoPassivo

    // Correção Monetária
    if (tipoPagamentoPassivo?.key == 3) {
        if (taxaJurosPagamentoList.length > 0) {
            let checkTaxa = Array.from(taxaJurosPagamentoList).map((el) => {
                const { mesAtualizacaoMonentariaNovoList, periodicidadeCorrecaoMonetaria } = el;
                return mesAtualizacaoMonentariaNovoList.length < verificaMeses(periodicidadeCorrecaoMonetaria) ? false : true;
            })

            let result = checkTaxa.every(item => item === true);

            if (!result) toastr.error("Erro", "Defina a quantidade mínima de meses da Atualização Monetária");

            return result;
        }
    } else {
        return true
    }
}

export function exportModeloCurvaPagamento() {
    return (dispatch) => {
        return axios
            .get(`${PASSIVO_API}exportar/excel/modelo`)
            .then(result => {
                const fileName = 'ModeloCurvaPagamento.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            }); 

    }
}

export function exportArquivoB3() {
    return (dispatch, getState) => {
        dispatch(changeMessage("Baixando Registro do Passivo"))

        const formValues = getFormValues("passivoForm")(getState());
        
        const { id: idPassivoOperacao, operacao } = formValues
        const { id: idOperacao, operacaoRegistro } = operacao
        
        const registroBools = serializeBoolsOperacaoRegistro(operacaoRegistro)
        const data = { ...operacaoRegistro , ...registroBools }

        const payload = {
            ...data,
            idAgenciaRating: operacaoRegistro?.idAgenciaRating?.id || operacaoRegistro?.idAgenciaRating || null,
            idRatingAgencia: operacaoRegistro?.idRatingAgencia?.id || operacaoRegistro?.idRatingAgencia || null,

            idPassivoOperacao,
            idOperacao
        }

        return axios
            .post(`${PASSIVO_API}gerar-arquivo-b3`, payload)
            .then(result => {
                const { content } = result
                dispatch([
                    (!content || !content?.arquivo) ? msgError("Não foi possível baixar o arquivo", false) : downloadExcelFile(content?.arquivo, content?.nomeArquivo),
                    showAllMessages(result.messages),
                ])
            }); 

    }
}

export function getPassivos(idsOperacoes, page = 1) {
    return dispatch => {
        dispatch(changeMessage("Carregando Passivos"))
        return axios
            .get(OPERACAO_API + `${idsOperacoes}/passivos?pagina=${page}&tamanhoPagina=10`)
            .then(result =>
                dispatch({
                    type: PASSIVO_FETCHED,
                    payload: result
                })
            );
    };
}

export function getPatrimonios(idsOperacoes) {
    return dispatch => {
        dispatch(changeMessage("Carregando Patrimônios"))
        return axios
            .get(OPERACAO_API + `${idsOperacoes}/patrimonios-separados`)
            .then(result =>
                dispatch({
                type: PATRIMONIO_FETCHED,
                    payload: result
                })
            );
    };
}

export function updateRatingList(list) {
    return (dispatch) => {
        return dispatch([
            {
                type: UPDATE_RATING_LIST_REGISTRO,
                payload: list
            },
        ]);
    }
}

export function savePassivo(passivo) {
    return dispatch => {
        dispatch(changeMessage("Salvando Passivo"))
        dispatch([
            {
                type: SAVE_FORM_PASSIVO_ERRORS,
                payload: null
            },
        ])
        if (gestora || !gestora && validaTaxaCorrecaoMonetaria(passivo)) {
            let paramsDeserialized = deserializeFieldsPassivoForm(passivo);
            return axios.post(PASSIVO_API, { ...paramsDeserialized }).then(result =>
                    dispatch([
                        showAllMessages(result.messages),
                        showPassivoList(),
                    ])
                ).catch((err) => {
                    if (err && err.data?.messages && err.data?.messages?.hasError) {
                        document.getElementById('topContent').scrollTo({ top: 0, behavior: 'smooth' });
                        dispatch([
                            {
                                type: SAVE_FORM_PASSIVO_ERRORS,
                                payload: err.data?.messages
                            },
                        ])
                    }
                })
        }
    };
}

export function gerarPrecificacao(idOperacao) {
    return (dispatch) => {
        return axios
            .get(OPERACAO_API + `precificacao/CalcularPrecificacao?idOperacao=${idOperacao}`)
            .then(result =>
                dispatch([
                    showPassivoDeleteDialog(false, ""),
                    showAllMessages(result.messages)
                ])
            );
    }
}

export function showModalAprovacao(show = false) {
    return dispatch => {
        dispatch([
            {
                type: SHOW_MODAL_APPROVING_PASSIVO_WORKFLOW,
                payload: show
            },
        ])
    }
}

export function showModalOnboarding(show = false) {
    return dispatch => {
        dispatch([
            {
                type: SHOW_MODAL_ONBOARDING_PASSIVO_WORKFLOW,
                payload: show
            },
        ])
    }
}

export function showModalFinalizacao(show = false) {
    return dispatch => {
        dispatch([
            {
                type: SHOW_MODAL_FINISH_PASSIVO_WORKFLOW,
                payload: show
            },
        ])
    }
}

export function confirmarOnboarding(payload) {
  return (dispatch) => {
    return axios.post(PASSIVO_API + `confirmar-onboarding`, payload).then((result) => {
      dispatch([
          showAllMessages(result.messages),
      ]);
    });
  };
} 

export function finalizaDistribuicao() {
    return (dispatch, getState) => {
        const formValues = getFormValues("passivoForm")(getState());

        const payload = {
            ...formValues,
            finalizarDistribuicao: true,
        }

        dispatch([
            editPassivo(payload),
        ]);
    }
}

export function aprovarCadastro(modal) {
    return (dispatch, getState) => {
        const formValues = getFormValues("passivoForm")(getState());

        const payload = {
            ...formValues,
            enviarParaGestao: true,
            pagamentoPassivo: {
                ...formValues?.pagamentoPassivo,
                ...modal,
            }
        }

        dispatch([
            editPassivo(payload),
        ]);
    }
}

export function editPassivo(passivo, typeSubmit = "") {
    return dispatch => {
        dispatch(changeMessage("Salvando Passivo"))
        if (gestora || !gestora && validaTaxaCorrecaoMonetaria(passivo)) {
            let paramsDeserialized = deserializeFieldsPassivoForm(passivo);
            dispatch([
                {
                    type: SAVE_FORM_PASSIVO_ERRORS,
                    payload: null
                },
            ])

            return axios.put(PASSIVO_API, { ...paramsDeserialized }).then(result => {
                dispatch([
                    showAllMessages(result.messages),
                    typeSubmit !== 'Enviar' && typeSubmit !== "Onboarding" ? showPassivoList() : null,
                    typeSubmit == 'Enviar' ? loadPassivoAprovacao(passivo?.id) : null,
                ])

                return true;
            }).catch((err) => {
                    if (err && err.data?.messages && err.data?.messages?.hasError) {
                        document.getElementById('topContent').scrollTo({ top: 0, behavior: 'smooth' });
                        dispatch([
                            {
                                type: SAVE_FORM_PASSIVO_ERRORS,
                                payload: err.data?.messages
                            },
                        ])

                        return false;
                    }
                })
        }
    };
}

export function loadPassivoAprovacao(idPassivo) {
  return (dispatch) => {
    return axios.get(PASSIVO_API + `aprovacao/${idPassivo}`).then((result) => {
      dispatch([
        {
          type: LOAD_FORM_PASSIVO_APROVACAO,
          payload: result.content,
        },
        result?.content && showModalAprovacao(true)
      ]);
    });
  };
} 

export function changeMoeda(simbolo) {
    return dispatch => {
        return dispatch([
            {
                type: LOAD_FORM_PASSIVO_MOEDA,
                payload: simbolo || null
            },
        ])
    };  
}

export function loadFormPassivo(idPassivo, editAction) {
    return dispatch => {
        dispatch(changeMessage(idPassivo ? "Carregando Passivo" : "Carregando Tela"))
        return axios.get(PASSIVO_API + `tela/${idPassivo || ''}`)
            .then(result => {
                let idFundo = result.content?.passivoOperacao?.operacao?.subClasse?.idFundo;
                let idClasse = result.content?.passivoOperacao?.operacao?.subClasse?.idClasse;
                let distribuicaoList = result.content?.passivoOperacao?.pagamentoPassivo?.distribuicaoList;

                dispatch([
                    {
                        type: LOAD_FORM_PASSIVO,
                        payload: result.content
                    },
                    serializeFieldsPassivoForm(result.content, result.content.patrimonioSeparado),
                    idFundo ? loadDataFundo(idFundo, idClasse, false) : null,
                ])

                if (distribuicaoList && distribuicaoList.length > 0) {
                  for (let i = 0; i < distribuicaoList.length; i++) dispatch(calcularDistribuicaoRelacao(i));
                }
            })
    };
}

export function resetLoadFormPassivo() {
    return dispatch => {
        return dispatch([
            {
                type: LOAD_FORM_PASSIVO,
                payload: {}
            },
        ])
    }
}

export function loadFormPassivoPatrimonio(idPatrimonio) {
    if (idPatrimonio) {
        return dispatch => {
            return axios.get(PATRIMONIO_API + `${idPatrimonio}?retornarEstrutura=false`)
            .then(result => {
                let patrimonio = result.content;

                dispatch([
                        change('passivoForm', 'operacao.idPatrimonioSeparado', idPatrimonio),
                    
                        change('passivoForm', 'produtoPassivo.key', patrimonio.idProduto),
                        change('passivoForm', 'usuario.id', patrimonio.idUsuarioGestao),
                        change('passivoForm', 'usuarioServicoCredito.id', patrimonio.idUsuarioServicoCredito),
    
                        change('passivoForm', 'emissor.id', patrimonio.idEmissor),
                        change('passivoForm', 'naturezaOperacao.id', patrimonio.idNaturezaOperacao),
                        change('passivoForm', 'segmento', patrimonio.segmento),
                    ])
                });
        };
    } else {
        return dispatch => {
            dispatch([
                change('passivoForm', 'operacao.idPatrimonioSeparado', null),
            
                change('passivoForm', 'produtoPassivo', null),
                change('passivoForm', 'usuario', null),
                change('passivoForm', 'usuarioServicoCredito', null),

                change('passivoForm', 'emissor', null),
                change('passivoForm', 'naturezaOperacao', null),
                change('passivoForm', 'segmento', null),
            ])
        }
    }
}

export function uploadCurvaEventoFile(curvaEventosFile, curvasPagamento = []) {
    let formData = new FormData();
    formData.append("file", curvaEventosFile);

    return dispatch => {
        dispatch(changeMessage("Enviando Curvas de Pagamento"))
        return axios
            .post(PASSIVO_API + "curvas", formData)
            .then(result => {
                let i = 0;
                let curvasPagamentoNovaList = [];
                let date = new Date();
                let newId = `-${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`;

                result.content.forEach(curvaPagamentoNova => {
                    let resultFind = curvasPagamento.find(obj => obj.dataEvento === curvaPagamentoNova.dataEvento);

                    if ((!resultFind || resultFind === undefined) || (resultFind && resultFind.dataEvento === curvaPagamentoNova.dataEvento)) {
                        curvaPagamentoNova.id = `${newId}${i++}`;
                        curvasPagamentoNovaList.push(curvaPagamentoNova);
                    }
                });

                let curvasPagamentoIncrementado = changeCurvaPagamentoList(curvasPagamentoNovaList, curvasPagamento);

                dispatch([
                    change('passivoForm', 'pagamentoPassivo.curvaPagamentoPassivoList', curvasPagamentoIncrementado),
                    change("passivoForm", "curvaPaggingInfo.totalCount", curvasPagamentoIncrementado.length),
                    change("passivoForm", "curvaPaggingInfo.items", listItems(curvasPagamentoIncrementado, 1, 10)),
                    showAllMessages(result.messages),
                ])
            });
    };
}

export function changeCurvaPagamentoList(resultUploadFile, curvasPagamento) {
    Array.prototype.push.apply(resultUploadFile, curvasPagamento);
    let result = removeDuplicates(resultUploadFile, "dataEvento");

    return result;
}

export function showPassivoList() {
    return dispatch => {
        return dispatch([
            showConfirmDialog(false),
            history.push(`/${OPERACAO_ROUTE_PATH}/${PASSIVO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
        ]);
    };
}

export function changePassivoFormTab(formTab) {
    return dispatch => {
        return dispatch([
            {
                type: PASSIVO_FORM_TAB,
                payload: formTab
            }
        ]);
    };
}

export function initializeForm(params = 0) {
    return dispatch => {
        return dispatch([
            selectTab(1),
            loadFormPassivo(params),
        ]);
    };
}

export function goToFormPage(editAction, idPassivo) {
    let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;
    return dispatch => {
        return dispatch([
            history.push(`/${OPERACAO_ROUTE_PATH}/${PASSIVO_ROUTE_PATH}/${routeAction}/${idPassivo}`)
        ]);
    };
}

export function goToFormPagePatrimonio(editAction, idPassivo) {
    let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;
    return dispatch => {
        return dispatch([
            history.push(`/${PATRIMONIO_ROUTE_PATH}/${routeAction}/${idPassivo}`)
        ]);
    };
}

export function cleanPrecificacaoPrecisaoList(tipoPagamentoAtual) {
    return (dispatch, getState) => {
        const selector = formValueSelector('passivoForm')
        let tipoPagamentoOriginal = selector(getState(), 'tipoPagamentoOriginal') || null;
        let precificacaoPrecisaoList = [];

        const tipoPagamentoOriginalSelecionado = tipoPagamentoOriginal && tipoPagamentoAtual.key === tipoPagamentoOriginal.key;

        if (tipoPagamentoOriginalSelecionado) {
            precificacaoPrecisaoList = JSON.parse(JSON.stringify(selector(getState(), 'precificacaoPrecisaoOriginal'))) || []
        }

        return dispatch([
            change('passivoForm', 'precificacaoPrecisaoList', precificacaoPrecisaoList),
        ]);
    }
}

export function atualizarListagemForm(listaUnica) {
    const lista = [...listaUnica];
    return (dispatch) => {
        return dispatch([
            arrayRemoveAll('passivoForm', 'precificacaoPrecisaoList'),
            change('passivoForm', 'precificacaoPrecisaoList', lista)
        ]);
    }
}

export function onChangeAplicarPrecisao(valor, indexNoForm) {
    return (dispatch, getState) => {
        const selector = formValueSelector('passivoForm')
        let precificacaoList = selector(getState(), 'precificacaoPrecisaoList') || [];

        precificacaoList[indexNoForm].aplicarPrecisao = valor;
        precificacaoList[indexNoForm].disableFields = !valor;

        return dispatch([
            change('passivoForm', 'precificacaoPrecisaoList', precificacaoList),
        ]);
    }
}


export function showPassivoDeleteDialog(showDialog, codigoRB, idOperacao) {
    return (dispatch) => {
        return dispatch([
            {
                type: PASSIVO_SHOW_DELETE_DIALOG,
                payload: { showDialog, codigoRB, idOperacao }
            }
        ])
    }
}

export function getDataPrimeiraIntegralizacaoGest() {
    return (dispatch, getState) => {
        const selector = formValueSelector('passivoForm');
        const integralizacaoList = selector(getState(), 'pagamentoPassivo.integralizacaoList') || [];

        const findMinDate = (dates) => dates.reduce((minDate, currentDate) => (currentDate < minDate ? currentDate : minDate));

        const allDates = integralizacaoList
            .map(integ => integ?.dataIntegralizacao)
            .filter(date => date);

        const menorDataIntegralizacao = allDates.length > 0 ? findMinDate(allDates) : null;

        return dispatch([
            change('passivoForm', 'dataPrimeiraIntegralizacao', menorDataIntegralizacao),
        ]);
    }
}

export function getDataPrimeiraIntegralizacao() {
    return (dispatch, getState) => {
        const selector = formValueSelector('passivoForm');
        const distribuicaoList = selector(getState(), 'pagamentoPassivo.distribuicaoList') || [];

        const findMinDate = (dates) => dates.reduce((minDate, currentDate) => (currentDate < minDate ? currentDate : minDate));

        const allDates = distribuicaoList
            .flatMap(dist => dist?.subscricaoList ?? [])
            .flatMap(sub => sub?.integralizacaoList ?? [])
            .map(integ => integ?.dataIntegralizacao)
            .filter(date => date);

        const menorDataIntegralizacao = allDates.length > 0 ? findMinDate(allDates) : null;

        return dispatch([
            change('passivoForm', 'dataPrimeiraIntegralizacao', menorDataIntegralizacao),
        ]);
    }
}

export function calcularQuantidadeDistribuidaIntegralizacaoGest() {
    return (dispatch, getState) => {
        const selector = formValueSelector('passivoForm');
        let integralizacaoList = selector(getState(), 'pagamentoPassivo.integralizacaoList') || [];
        let quantidadeDistribuida = null;
        let totalDistribuido = 0;

        if (integralizacaoList.length > 0) {
            integralizacaoList = integralizacaoList.map((item) => ({...item, quantidadeIntegralizada: convertCurrencyToNumber(item.quantidadeIntegralizada)}))
            quantidadeDistribuida = calculateSumNorm(integralizacaoList, 'quantidadeIntegralizada', true);
            totalDistribuido = calcularTotalDistribuido(integralizacaoList);
        }

        return dispatch([
            change('passivoForm', 'quantidadeDistribuida', quantidadeDistribuida),
            change("passivoForm", "totalDistribuido", totalDistribuido),
        ]);
    }
}

export function calcularQuantidadeDistribuidaIntegralizacao() {
    return (dispatch, getState) => {
        const selector = formValueSelector('passivoForm');
        const distribuicaoList = selector(getState(), 'pagamentoPassivo.distribuicaoList') || [];
        
        let quantidadeDistribuida = distribuicaoList
        .flatMap(dist => dist?.subscricaoList ?? [])
        .flatMap(sub => sub?.integralizacaoList ?? [])
        .reduce((total, integ) => total + (integ?.quantidadeIntegralizada ?? 0), 0);

        let totalDistribuido = distribuicaoList
        .flatMap(dist => dist?.subscricaoList ?? [])
        .flatMap(sub => sub?.integralizacaoList ?? [])
        .reduce((total, integ) => {
            const quantidadeIntegralizada = integ?.quantidadeIntegralizada ?? 0;
            const precoUnitario = integ?.precoUnitario ? convertCurrencyToNumber(integ.precoUnitario) : 0;
            return total + multiply(quantidadeIntegralizada, precoUnitario);
        }, 0);

        if (quantidadeDistribuida === 0) quantidadeDistribuida = null;

        return dispatch([
            change('passivoForm', 'quantidadeDistribuida', quantidadeDistribuida),
            change("passivoForm", "totalDistribuido", totalDistribuido),
        ]);
    }
}

export function calcularDistribuicaoRelacao(index) {
    return (dispatch, getState) => {
            const selector = formValueSelector('passivoForm');
            const subscricaoList = selector(getState(), `pagamentoPassivo.distribuicaoList[${index}].subscricaoList`) || [];
            
            let quantidadeTotalIntegralizada = null;
            let quantidadeTotalSubscrita = null;
    
            // Quantidade Total Integralizada
            subscricaoList.forEach((sub) => {
                if (sub?.integralizacaoList?.length > 0) {
                    sub.integralizacaoList.forEach((integ) => {
                        quantidadeTotalIntegralizada += integ.quantidadeIntegralizada ?? 0;
                    });
                }
            });
    
            // Quantidade Total Subscrita
            if (subscricaoList.length > 0) quantidadeTotalSubscrita = calculateSumNorm(subscricaoList, 'quantidadeSubscrita');
    
    
            if (quantidadeTotalIntegralizada === 0) quantidadeTotalIntegralizada = null;
            if (quantidadeTotalSubscrita === 0) quantidadeTotalSubscrita = null;
    
            return dispatch([
                change('passivoForm', `pagamentoPassivo.distribuicaoList[${index}].quantidadeTotalIntegralizada`, quantidadeTotalIntegralizada),
                change('passivoForm', `pagamentoPassivo.distribuicaoList[${index}].quantidadeTotalSubscrita`, quantidadeTotalSubscrita),
                calcularDistribuicaoRelacaoGeral(),
            ]);
    }
}

export function calcularDistribuicaoRelacaoGeral() {
    return (dispatch, getState) => {
        const selector = formValueSelector('passivoForm');
        const distribuicaoList = selector(getState(), 'pagamentoPassivo.distribuicaoList') || [];
        
        let quantidadeTotalIntegralizadaGeral = null;
        let quantidadeTotalSubscritaGeral = null;
        let volumeTotalIntegralizadoGeral = null;

        // Quantidade Total Integralizada - Geral
        quantidadeTotalIntegralizadaGeral = distribuicaoList
        .flatMap(dist => dist?.subscricaoList ?? [])
        .flatMap(sub => sub?.integralizacaoList ?? [])
        .reduce((total, integ) => total + (integ?.quantidadeIntegralizada ?? 0), 0);

        // Quantidade Total Subscrita - Geral
        quantidadeTotalSubscritaGeral = distribuicaoList
        .flatMap(dist => dist?.subscricaoList ?? [])
        .reduce((total, sub) => total + (sub?.quantidadeSubscrita ?? 0), 0);

        // Volume Total Integralizado - Geral
        volumeTotalIntegralizadoGeral = distribuicaoList
        .flatMap(dist => dist?.subscricaoList ?? [])
        .flatMap(sub => sub?.integralizacaoList ?? [])
        .reduce((total, integ) => {
            const quantidadeIntegralizada = integ?.quantidadeIntegralizada ?? 0;
            const precoUnitario = integ?.precoUnitario ? convertCurrencyToNumber(integ.precoUnitario) : 0;
            return total + multiply(quantidadeIntegralizada, precoUnitario);
        }, 0);
        
        if (quantidadeTotalIntegralizadaGeral === 0) quantidadeTotalIntegralizadaGeral = null;
        if (quantidadeTotalSubscritaGeral === 0) quantidadeTotalSubscritaGeral = null;
        if (volumeTotalIntegralizadoGeral === 0) volumeTotalIntegralizadoGeral = null;

        return dispatch([
            change('passivoForm', `quantidadeTotalIntegralizada`, quantidadeTotalIntegralizadaGeral),
            change('passivoForm', `quantidadeTotalSubscrita`, quantidadeTotalSubscritaGeral),
            change('passivoForm', `volumeTotalIntegralizado`, volumeTotalIntegralizadoGeral),
        ]);
    }
}

export function calcularTotalDistribuido(integralizacoes) {
    let totalDistribuido = 0;

    integralizacoes.forEach(elem => {
        let quantidadeIntegralizada = elem.quantidadeIntegralizada || 0;
        let precoUnitario = elem.precoUnitario ? convertCurrencyToNumber(elem.precoUnitario) : 0;

        totalDistribuido = totalDistribuido + multiply(quantidadeIntegralizada, precoUnitario);
    });

    return totalDistribuido;
}

export function handleChangeDataEventoCurvaPagamento(evento, tipoEvento) {
    if (tipoEvento.key === KEY_TIPO_EVENTO_ATUALIZADO) {
        return (dispatch) => {
            return dispatch(
                change('passivoForm', `curvaPagamentoToEdit.dataPagamento`, evento.target.value)
            );
        }
    }

    return { type: '' };
}

export function handleChangePercentualPagamentoAmortizacao(key) {
    if (key === PAGAMENTO_AMORTIZACAO_NAO_HA) {
        return (dispatch) => {
            return dispatch(
                change('passivoForm', `curvaPagamentoToEdit.percentualPagamentoAmortizacao`, null)
            );
        }
    }

    return { type: '' };
}

export function removeCurvaFromTable(idCurva) {
    return (dispatch, getState) => {
        const selector = formValueSelector('passivoForm');
        const curvaPagamentoPassivoList = selector(getState(), 'pagamentoPassivo.curvaPagamentoPassivoList') || [];
        const indexCurva = curvaPagamentoPassivoList.findIndex(curva => curva.id === idCurva);
        let pageIndex = selector(getState(), 'curvaPaggingInfo.pageIndex') || 1;
        let goToPreviousData = false;

        if (pageIndex < 1) {
            pageIndex = 1;
        }

        let curvaPagamentoPassivoUpdated = deepClone(curvaPagamentoPassivoList);
        curvaPagamentoPassivoUpdated.splice(indexCurva, 1);

        let curvasUpdatedPagged = listItems(curvaPagamentoPassivoUpdated, pageIndex, 10);

        if (curvasUpdatedPagged.length === 0 && pageIndex > 1) {
            curvasUpdatedPagged = listItems(curvaPagamentoPassivoUpdated, pageIndex - 1, 10);
            goToPreviousData = true;
        }

        return dispatch([
            arrayPush("passivoForm", "pagamentoPassivo.curvaPagamentoPassivoRemodivaList", curvaPagamentoPassivoList[indexCurva]),
            change("passivoForm", "pagamentoPassivo.curvaPagamentoPassivoList", curvaPagamentoPassivoUpdated),
            change("passivoForm", "curvaPaggingInfo.items", curvasUpdatedPagged),
            change("passivoForm", "curvaPaggingInfo.totalCount", curvaPagamentoPassivoUpdated.length),
            goToPreviousData ? change("passivoForm", "curvaPaggingInfo.pageIndex", pageIndex - 1) : undefined,
        ]);
    }
}

export function editCurvaFromTable(curvaPagamento) {
    return (dispatch) => {
        return dispatch(
            change("passivoForm", "curvaPagamentoToEdit", curvaPagamento)
        );
    }
}

export function cancelEditCurvaPagamento() {
    return (dispatch) => {
        return dispatch(
            change("passivoForm", "curvaPagamentoToEdit", {})
        );
    }
}

export function saveData() {
    return (dispatch, getState) => {
        const selector = formValueSelector('passivoForm');
        const curvaPagamentoPassivoList = selector(getState(), 'pagamentoPassivo.curvaPagamentoPassivoList') || [];
        const curvaPagamentoToEdit = selector(getState(), 'curvaPagamentoToEdit') || {};
        let pageIndex = selector(getState(), 'curvaPaggingInfo.pageIndex') || 1;
        let lastNewId = null;

        if (pageIndex < 1) {
            pageIndex = 1;
        }

        if (curvaPagamentoToEdit.id) {
            const indexCurva = curvaPagamentoPassivoList.findIndex(curva => curva.id === curvaPagamentoToEdit.id);

            indexCurva < 0 ?
                curvaPagamentoPassivoList.unshift(curvaPagamentoToEdit) :
                curvaPagamentoPassivoList[indexCurva] = curvaPagamentoToEdit;
        } else {
            lastNewId = selector(getState(), 'lastNewId') - 1;
            curvaPagamentoToEdit.id = lastNewId;
            curvaPagamentoPassivoList.unshift(curvaPagamentoToEdit);
            pageIndex = 1;
        }

        return dispatch([
            change("passivoForm", "pagamentoPassivo.curvaPagamentoPassivoList", curvaPagamentoPassivoList),
            change("passivoForm", "curvaPagamentoToEdit", {}),
            lastNewId !== null ? change("passivoForm", "lastNewId", lastNewId) : undefined,
            lastNewId !== null ? change("passivoForm", "curvaPaggingInfo.pageIndex", pageIndex) : undefined,
            change("passivoForm", "curvaPaggingInfo.items", listItems(curvaPagamentoPassivoList, pageIndex, 10))
        ]);
    }
}

export function updateTablePagination(pageIndex) {
    return (dispatch, getState) => {
        const selector = formValueSelector('passivoForm');
        const curvaPagamentoPassivoList = selector(getState(), 'pagamentoPassivo.curvaPagamentoPassivoList') || [];

        return dispatch([
            change("passivoForm", "curvaPaggingInfo.items", listItems(curvaPagamentoPassivoList, pageIndex, 10)),
            change("passivoForm", "curvaPaggingInfo.pageIndex", pageIndex)
        ]);
    }
}

export function changeDescriptionBaseCalculoMesesCorridos(frequenciaJuros) {
    return (dispatch) => {
        return dispatch(
            {
                type: PASSIVO_CHANGE_BASE_CALCULO_DESCRIPTION,
                payload: frequenciaJuros
            }
        )
    }
}

export function onChangeMesAtualizacaoMonentaria(e, indexTaxa, indexMes, itemMes, periodo) {
    return (dispatch, getState) => {
        const selector = formValueSelector('passivoForm');
        let listNova = selector(getState(), `pagamentoPassivo.taxaJurosPagamentoList[${indexTaxa}].mesAtualizacaoMonentariaNovoList`) || [];
        
        if (!periodo) {
            e.preventDefault();
            toastr.error("Erro", "Defina a Periodicidade de Correção");
        } else {
            let pai = e.target.closest('.group-check');
            
            if (e.target.checked) {
                let max = verificaMeses(periodo)
                let filhos = pai.querySelectorAll("input[type=checkbox]:checked")
                
                if (filhos.length > max) {
                    e.preventDefault();
                    toastr.error("Erro", "Máximo atingido");
                } else {
                    return dispatch([
                        arrayPush('passivoForm', `pagamentoPassivo.taxaJurosPagamentoList[${indexTaxa}].mesAtualizacaoMonentariaNovoList`, { mes: itemMes })
                    ]);
                }
            } else {
                return dispatch([
                    arrayRemove("passivoForm", `pagamentoPassivo.taxaJurosPagamentoList[${indexTaxa}].mesAtualizacaoMonentariaNovoList`, findIndexMesAtualizacaoMonentaria(listNova, { mes: itemMes }))
                ]);
            }
        }
    }
}

export function mudaPeriodicidadeCorrecao(indexTaxa, value, mesList) {
    return (dispatch) => {
        if (value?.id && value?.id == 1) {
            let novoList = mesList.filter((el) => el.key >= 1 && el.key <= 12).map((el) => ({mes: el}))
            let list = mesList.filter((el) => el.key >= 1 && el.key <= 12);

            return dispatch([
                change('passivoForm', `pagamentoPassivo.taxaJurosPagamentoList[${indexTaxa}].mesAtualizacaoMonentariaNovoList`, novoList),
                change('passivoForm', `pagamentoPassivo.taxaJurosPagamentoList[${indexTaxa}].mesAtualizacaoMonentariaList`, list),
            ]);
        } else {
            return dispatch([
                change('passivoForm', `pagamentoPassivo.taxaJurosPagamentoList[${indexTaxa}].mesAtualizacaoMonentariaNovoList`, []),
                change('passivoForm', `pagamentoPassivo.taxaJurosPagamentoList[${indexTaxa}].mesAtualizacaoMonentariaList`, []),
            ]);
        }
    }
}

function findIndexMesAtualizacaoMonentaria(list, itemMes) {
    let indexresult = -1;

    list.forEach((item, index) => {
        if (item.mes.key === itemMes.mes.key) {
            indexresult = index;
        }
    });

    return indexresult;
}

export function changeDataInicioJuros(valorInicioJuros){
    return (dispatch,getState) => {
        const selector = formValueSelector('passivoForm');
        let newData = "";
        if(valorInicioJuros == PRIMEIRA_INTEGRALIZACAO){
          newData = selector(getState(), 'dataPrimeiraIntegralizacao') || "";
        }
        else if(valorInicioJuros == DATA_EMISSAO){
            newData = selector(getState(), 'dataEmissaoSerie') || "";
        }
        return dispatch([
            change("passivoForm","pagamentoPassivo.inicioJurosRemuneracao", newData)
        ]);
    }
    
}

export function inserirNomePastaCedoc() {
    return dispatch => {
        return dispatch([
            { type: PASSIVO_INSERT_NOME_PASTA_CEDOC, payload: { showDialog: true } },
        ]);
    }
}

export function changeDataEmissao(value) {
    return dispatch => {
        return dispatch([
            { type: PASSIVO_CHANGE_DATA_EMISSAO, payload: value },
        ]);
    }
}

//

export function limpaDependenteOnChange(dependente, value = "") {
    return (dispatch) => {
      dispatch([change("passivoForm", dependente, value)]);
    };
  }

export function loadDataFundo(idFundo, idClasse, clean = true) {
  return (dispatch) => {
    dispatch([
        clean ? change("passivoForm", "operacao.subClasse.idClasse", null) : null,
        clean ? change("passivoForm", "operacao.idSubClasse", null) : null,
        {
          type: LOAD_CLASSES_FUNDO,
          payload: {classes: [], classesLoaded: false},
        },
        {
          type: LOAD_SUBCLASSES_FUNDO,
          payload: {subclasses: [], subclassesLoaded: false},
        },
        idFundo ? loadClassesFundo(idFundo, idClasse, clean): null,
      ])
  };
}

export function loadClassesFundo(idFundo, idClasse, clean = true) {
  return (dispatch) => {
    if (idFundo) {
        return axios
          .get(FUNDO_API + `${idFundo}/classes`)
          .then((result) => {
            const { classes, fundo } = result.content;
            
            let cls = classes.length > 0;
            if (!cls) toastr.error("Erro", "Não há classes vinculadas a esse fundo");

            if (clean) {
                dispatch([
                  change('passivoForm', 'administradorFiduciario', fundo.administradorFiduciario),
                  change('passivoForm', 'gestora', fundo.gestora),
                  change('passivoForm', 'possuiMultiGestao', typeof fundo.possuiMultiGestao == "boolean" ? (fundo.possuiMultiGestao ? "Sim" : "Não") : null),
                  change('passivoForm', 'gestoraII', fundo.gestoraII),
                  change('passivoForm', 'consultorCredito', fundo.consultorCredito),
                  change('passivoForm', 'controladoria', fundo.controladoria),
                  change('passivoForm', 'distribuidor', fundo.distribuidor),
                  change('passivoForm', 'escriturador', fundo.escriturador),
                  change('passivoForm', 'custodiante', fundo.custodiante),
                  change('passivoForm', 'agenteMonitoramento', fundo.agenteMonitoramento),
                  change('passivoForm', 'agenteFormalizacao', fundo.agenteFormalizacao),
                  change('passivoForm', 'assessorLegal', fundo.assessorLegal),
                  change('passivoForm', 'agenteCobrancaJudicial', fundo.agenteCobrancaJudicial),
                  change('passivoForm', 'agenteCobrancaExtrajudicial', fundo.agenteCobrancaExtrajudicial),
                  change('passivoForm', 'auditoria', fundo.auditoria),
                ])
            }
    
            dispatch([
              {
                type: LOAD_CLASSES_FUNDO,
                payload: {classes, classesLoaded: true},
              },
              idClasse ? loadSubclassesFundo(idClasse): null,
            ]);
          });
    } else {
        return false;
    }
  };
}

export function loadSubclassesFundo(idClasse) {
    return (dispatch) => {
      return axios
        .get(SUBCLASSE_API + `classe/${idClasse}`)
        .then((result) => {
          let subs = result.content?.length > 0;
          if (!subs) toastr.error("Erro", "Não há subclasses vinculadas a essa classe");

          let subclasses = Array.from(result.content).map((el) => ({...el, nomeSubClasse: `${el?.idSubClasse.value} - ${el.codigoSubClasse}`}))

          dispatch([
            {
              type: LOAD_SUBCLASSES_FUNDO,
              payload: {subclasses, subclassesLoaded: true},
            },
          ]);
        });
    };
  }

  export async function loadResumoFaq(idOperacao) {
    return (dispatch) => {
      return axios.get(OPERACAO_API + `resumo?idOperacao=${idOperacao}`).then((result) => {
        dispatch([
            showAllMessages(result.messages),
        ]);

        return result;
      });
    };
  } 