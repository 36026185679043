/* eslint eqeqeq: 0 */

import React from 'react';
import { Route, Switch } from "react-router-dom";
import { 
    MONITORAMENTO_ROUTE_PATH,
    LISTA_ROUTE_PATH,
} from '../../../common/routers/constantesRoutes';

import MonitoramentoPage from './../pages/MonitoramentoPage';

export default () => (
    <Switch>
        <Route exact={true} path={`/${MONITORAMENTO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={MonitoramentoPage} />
    </Switch>
);