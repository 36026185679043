import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";

import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";

import {
  getHistoricos,
  importaDadosHistorico,
} from "../../../../../actions/historicocotas/HistoricoAction";

import ReactTooltip from "react-tooltip";
import {
  convertCurrencyNumber,
  formatPercent,
  intToRoman,
  reorganizeSubclassArr
} from "../../../../../utils/helpers/NumberHelper";
import { formatCNPJ } from "../../../../../utils/helpers/StringHelper";
import PaginationRB from "../../../../common/pagination/PaginationRB";

const HistoricoList = (props) => {
  const fileInputRef = React.useRef(null);

  const {
    historicoData,
    messages,
    importaDadosHistorico,
    historicoConsult,
    getHistoricos,
  } = props;

  // prettier-ignore
  function normalizaColunas() {
    const colunas =  historicoData?.cotas?.items
      .map((el) => el.fundoCotaItens.map((el) => `${el.subClasse.value} ${intToRoman(el.codigoSubClasse)}`))
      .flat();

    return reorganizeSubclassArr([...new Set(colunas)]);
  }

  const tableHeader = historicoData?.cotas &&
    historicoData?.cotas?.hasItems && (
      <tr>
        <th>
          <div className="text-center fw-700 d-flex justify-content-center w-100">
            +
          </div>
        </th>
        <th>
          <div className="text-center fw-700 d-flex justify-content-center w-100">
            Data
          </div>
        </th>
        {normalizaColunas().map((el, i) => (
          <th key={i}>
            <div className="text-center fw-700 d-flex justify-content-center w-100">
              {el}
            </div>
          </th>
        ))}
      </tr>
    );

  const emptyData = messages?.infos[0];

  function mudaVisibilidade(index) {
    document.getElementById(`rowTable-${index}`).classList.toggle("hide");
  }

  const acionaImport = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const enviaImport = (event) => {
    const file = event.target.files[0];
    importaDadosHistorico(file, historicoConsult);
  };

  const tableContent =
    historicoData?.cotas &&
    historicoData?.cotas?.hasItems &&
    historicoData?.cotas?.items.map((item, index) => (
      <React.Fragment key={index}>
        <tr className="tr-status">
          <td
            role="button"
            className="text-center fw-400"
            onClick={() => mudaVisibilidade(index)}
          >
            ...
          </td>
          <td className="text-center td-status">
            <div className="d-flex flex-row justify-content-center align-items-center pr-2">
              <div
                data-type="light"
                data-for={`ttp-list-${index}`}
                data-tip={item.status.value}
                className={`circle-status sts-hist-${item.status.key} rounded-circle mr-2`}
              />
              <ReactTooltip
                noArrow
                effect="solid"
                id={`ttp-list-${index}`}
                className="px-2 py-1"
                globalEventOff="click"
                style={{ width: "100px" }}
              />
              <span className="fw-400 mt-1 mx-0 mb-0">
                {getDateFormatedFromString(item.dataBase) || "--"}
              </span>
            </div>
          </td>

          {normalizaColunas().map((el, index) => {
            for (let i = 0; i < item.fundoCotaItens.length; i++) {
              const value = item.fundoCotaItens[i].subClasse.value;
              const codigoSubClasse = item.fundoCotaItens[i].codigoSubClasse;

              if (el === `${value} ${intToRoman(codigoSubClasse)}`) {
                return (
                  <td key={index} className="text-center fw-400">
                    {convertCurrencyNumber(item.fundoCotaItens[i].valorTotal, "R$", 2)}
                  </td>
                );
              }
            }
            
            return <td key={index} className="text-center">--</td>;
          })}
        </tr>
        {/*  */}
        <tr id={`rowTable-${index}`} className="hide">
          <td></td>
          <td>
            <div className="text-center d-flex flex-column justify-content-center w-100 mb-2">
              <p className="mb-0 fw-700">Quantidade</p>
              <p className="mb-0 fw-700">Valor Da Cota</p>
              <p className="mb-0 fw-700">% PL</p>
            </div>
          </td>

          {normalizaColunas().map((el, index) => {
            for (let i = 0; i < item.fundoCotaItens.length; i++) {
              const value = item.fundoCotaItens[i].subClasse.value;
              const codigoSubClasse = item.fundoCotaItens[i].codigoSubClasse;

              if (el === `${value} ${intToRoman(codigoSubClasse)}`) {
                return (
                  <td key={index}>
                    <div className="text-center d-flex flex-column justify-content-center w-100 mb-2">
                      <p className="mb-0">{convertCurrencyNumber(item.fundoCotaItens[i].quantidade, "", 0)}</p>
                      <p className="mb-0">{convertCurrencyNumber(item.fundoCotaItens[i].valorUnitario, "R$", 8)}</p>
                      <p className="mb-0">{formatPercent(item.fundoCotaItens[i].porcentagemPatrimonioLiquido, 2)}</p>
                    </div>
                  </td>
                );
              }
            }
            
            return <td key={index} className="text-center">--</td>;
          })}
        </tr>
      </React.Fragment>
    ));

  return (
    <div className="container-fluid">
      <div className="p-3">
        {historicoData && historicoData?.cotas?.hasItems && (
          <>
            <div className="shadow-custom rounded-12 col-12 my-2 pt-4 pb-3 bg-white">
              <p className="p-color fw-700 f-24">Dados do Fundo</p>
              <div className="d-flex flex-xl-row flex-column col-12 px-0 fw-400">
                <span className="col-12 col-xl-3 pl-0">
                  <span className="fw-700 text-dark">CNPJ do Fundo</span>:{" "}
                  {formatCNPJ(historicoData.cnpj)}
                </span>
                <span className="col-12 col-xl-9 pl-0">
                  <span className="fw-700 text-dark">Administrador</span>:{" "}
                  {historicoData.administrador}
                </span>
              </div>
            </div>

            <Table
              withoutHeader
              classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
              totalItems={historicoData.cotas.totalCount}
              pagination={
                <PaginationRB
                  pageNumber={historicoData.cotas.pageIndex - 1}
                  itemsPerPage={historicoData.cotas.pageSize}
                  totalItems={historicoData.cotas.totalCount}
                  filter={historicoConsult}
                  fetchFunction={getHistoricos}
                  pageRange={5}
                />
              }
            >
              <TableHeader>{tableHeader}</TableHeader>
              <TableContent>{tableContent}</TableContent>
            </Table>
          </>
        )}
        <div className="col-12 my-3 d-flex flex-row px-0 h-38 justify-content-end align-items-end">
          <button
            onClick={acionaImport}
            className="btn-add scs-bg-imp rounded btn-search h-38 d-flex justify-content-center px-3"
          >
            Importar Dados
            <input
              type="file"
              className="d-none"
              ref={fileInputRef}
              accept=".xls, .xlsx"
              onChange={enviaImport}
            ></input>
          </button>
        </div>

        {!historicoData && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

formValueSelector("HistoricoForm");

const mapStateToProps = (state) => ({
  historicoData: state.historicoCotasReducer.historicos,
  historicoConsult: state.historicoCotasReducer.historicoConsult,
  messages: state.historicoCotasReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getHistoricos,
      importaDadosHistorico,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(HistoricoList);
