import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ContainerPage from "../../../common/container/ContainerPage";
import AcessoPortalFilter from "./form/AcessoPortalFilter";
import AcessoPortalList from "./list/AcessoPortalList";
import { loadFilter } from "../../../../actions/acessoPortal/AcessoPortalAction";


class AcessoPortalPage extends Component {

    componentDidMount() {
        this.props.loadFilter();
    }

    render() {
        return (
            <ContainerPage>
                <AcessoPortalFilter />
                <AcessoPortalList />
            </ContainerPage>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ loadFilter }, dispatch);

export default connect(null, mapDispatchToProps)(AcessoPortalPage);
