/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, change } from "redux-form";
import { bindActionCreators } from "redux";
import FileUploadCard from "../../../../../common/File/FileUploadCard";
import Editor from "../../../../../common/editor/Editor";
import {
  convertFileToBase64,
  ACCEPT_FILE_OPTIONS,
} from "../../../../../../utils/helpers/FileHelper";
import { showErrorMessages } from "../../../../../../actions/message/ErrorMessagesAPIAction";
import { downloadImagemEstrutura } from "../../../../../../actions/fundo/FundoAction";

class FundoFormEstrutura extends Component {
  handleUploadFile() {
    let element = document.createElement("input");
    element.type = "file";
    element.multiple = false;
    element.accept = ACCEPT_FILE_OPTIONS.IMAGE;
    element.onchange = (e) => {
      let files = element.files;
      if (files == null) {
        return;
      } else if (files.length == 0) {
        return;
      } else {
        this.upload(files[0]);
      }
    };

    element.click();
  }

  removeFile() {
    this.props.change("fundoForm", "arquivoImagemEstrutura", "");
    this.props.change("fundoForm", "nomeImagemEstrutura", "");
  }

  async upload(file) {
    try {
      this.props.change(
        "fundoForm",
        "arquivoImagemEstrutura",
        await convertFileToBase64(file)
      );
      this.props.change("fundoForm", "nomeImagemEstrutura", file.name);
    } catch (e) {
      showErrorMessages("Erro ao importar o arquivo");
    }
  }

  render() {
    const { readOnly, arquivoImagemEstrutura, nomeImagemEstrutura, idFundo } =
      this.props;

    return (
      <div className="column">
        <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4 mt-4">
          <div className="row">
            <div className="pl-3 pr-4">
              <span className="label-estrutura-operacao">
                Imagem da Estrutura
              </span>
            </div>
            <div className="pl-1">
              <FileUploadCard
                showCard={
                  arquivoImagemEstrutura && arquivoImagemEstrutura.length > 0
                }
                outterClass="upload-button-position-estrutura-operacao"
                fileName={nomeImagemEstrutura}
                isRemoveable={!readOnly}
                disabled={readOnly}
                isDownloaded={idFundo > 0}
                handleUploadFile={() => this.handleUploadFile()}
                handleRemoveFile={() => this.removeFile()}
                handleDownloadFile={() =>
                  this.props.downloadImagemEstrutura(
                    idFundo,
                    nomeImagemEstrutura,
                    arquivoImagemEstrutura
                  )
                }
              />
            </div>
          </div>
          <div className="pb-2">
            <span className="label-estrutura-operacao">Detalhe Operação</span>
          </div>
          <Field
            component={Editor}
            readOnly={readOnly}
            inputId="descricaoEstrutura"
            name="descricaoEstrutura"
            label="Detalhe Operação"
          />
        </div>
      </div>
    );
  }
}

const selector = formValueSelector("fundoForm");
const mapStateToProps = (state) => ({
  arquivoImagemEstrutura:
    selector(state, "arquivoImagemEstrutura") || undefined,
  nomeImagemEstrutura: selector(state, "nomeImagemEstrutura") || "",
  descricaoEstrutura: selector(state, "descricaoEstrutura") || "",
  idFundo: selector(state, "id") || 0,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ change, downloadImagemEstrutura }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FundoFormEstrutura);
