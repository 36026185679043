import React from "react";
import { connect } from "react-redux";
import { Checkbox, Dialog, FormControlLabel } from "@material-ui/core";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";

import SingleFileInput from "../../../../../common/input/SingleFileInput";
import { turnFileToBase64 } from "../../../../../../utils/helpers/FileHelper";

let ModalGestaoComprovante = (props) => {
  const { close, open, confirm } = props;

  const [dataOnboarding, setDataOnboarding] = React.useState("");
  const [comprovante, setComprovante] = React.useState("");
  const [radioChecked, setRadioChecked] = React.useState(false);

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Confirmação de Onboarding
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-3">
            <div className="row px-3">
              <div
                className={`form-group field-floating has-float-label mb-0 pl-0 pr-lg-3 pr-0 col-lg-4`}
              >
                <input
                  type="date"
                  name="dataOnboarding"
                  value={dataOnboarding}
                  className="form-control w-100"
                  onChange={(e) => setDataOnboarding(e.target.value)}
                />
                <label htmlFor="dataOnboarding" className="control-label">
                  Data do Onboarding
                  <span className="text-warning font-weight-bold ml-1">*</span>
                </label>
              </div>

              <SingleFileInput
                label="Comprovante"
                value={comprovante}
                outerClassName="mb-0 px-0 col-lg-8"
                removeFile={() => setComprovante("")}
                onChange={(e) => setComprovante(e.target.files[0])}
              />
            </div>

            <div className="mt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    checked={radioChecked}
                    onChange={(e) => setRadioChecked(e.target.checked)}
                  />
                }
                label="Certifico que o onboarding da operação foi realizado junto a área de Gestão de Portfólio."
              />
            </div>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Confirmar"}
              disabled={!radioChecked || !dataOnboarding}
              action={async () => {
                const fileName = comprovante?.name;
                const tipoArquivoComprovante = fileName?.split(".")?.pop();

                confirm({
                  dataOnboarding,
                  tipoArquivoComprovante,
                  comprovante: comprovante
                    ? await turnFileToBase64(comprovante)
                    : null,
                });
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default connect(null, null)(ModalGestaoComprovante);
