import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';

import { 
    loadFormIndice,
}  from '../../../../../actions/precificacao/IndiceAction';

import ContainerPage from '../../../../common/container/ContainerPage';
import IndiceForm from './form/IndiceForm';

class IndiceVisualizePage extends Component {

    componentDidMount() {
        let idIndice = this.props.match.params.idIndice;
        this.props.loadFormIndice(idIndice);
    }

    render() {
        return (
            <ContainerPage >
                <IndiceForm 
                    title={"Detalhar - Índice"} 
                    onSubmit={this.props.handleSubmit} 
                    readOnly={true}
                    floatButtonVisible={false}
                />
            </ContainerPage>
        );
    }
}

const mapDispacthToProps = dispatch => bindActionCreators({ 
    loadFormIndice,
}, dispatch);

export default withRouter(connect(null, mapDispacthToProps)(IndiceVisualizePage));