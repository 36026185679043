import {
    INDICES_FETCHED,
    LOAD_FORM_INDICE,
    LOAD_FILTER_FORM_INDICE,
    INDICE_SHOW_DELETE_DIALOG,
    CLEAN_LIST_CONTENT,
    SET_TAXA_MENSAL_VALUE,
    CHANGE_HEADER,
    INDICES_DI_FUTURO_FETCHED,
} from "../../actions/precificacao/IndiceAction";

const INITIAL_STATE = {
    indices: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    indicesDiFuturo: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    indiceFormTypes: {},
    showIndiceDeleteDialog: {
        showDialog: false,
        idIndice: 0
    },
    messages: null,
    isLoading: false,
    taxaMensal: false,
    alterarCabecalhoParaTaxaMensal: false,
    labelCampoVariacao: 'Taxa',
    taxaFilterDiFuturo: false,
}

const TIPO_TAXA_CDI = 'CDI';
const TIPO_TAXA_TR = 'TR';
const TIPO_TAXA_SELIC = 'SELIC';


export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case INDICES_FETCHED:
            let label = 'Variação';
            if (action.payload.tipoTaxa.descricao === TIPO_TAXA_CDI || action.payload.tipoTaxa.descricao === TIPO_TAXA_TR) label = 'Taxa Efetiva';
            if (action.payload.tipoTaxa.descricao === TIPO_TAXA_SELIC) label = 'Taxa'

            return {
                ...state,
                indices: action.payload.result.content,
                messages: action.payload.result.messages,
                labelCampoVariacao: label,
                isLoading: true,
                taxaFilterDiFuturo: false
            }
        case INDICES_DI_FUTURO_FETCHED:
            return {
                ...state,
                indicesDiFuturo: action.payload.result.content,
                messages: action.payload.result.messages,
                taxaFilterDiFuturo: true
            }
        case INDICE_SHOW_DELETE_DIALOG:
            return {
                ...state,
                showIndiceDeleteDialog: action.payload
            }
        case LOAD_FORM_INDICE:
            return {
                ...state,
                indiceFormTypes: action.payload
            }
        case LOAD_FILTER_FORM_INDICE:
            return {
                ...state,
                indiceFormTypes: action.payload
            }
        case CLEAN_LIST_CONTENT:
            return {
                ...state,
                indices: {
                    lastPage: 0,
                    totalCount: 0,
                    hasItems: false,
                    pageSize: 0,
                    pageIndex: 0,
                    items: [],
                }
            }
        case SET_TAXA_MENSAL_VALUE:
            return {
                ...state,
                taxaMensal: action.payload
            }
        case CHANGE_HEADER:
            return {
                ...state,
                alterarCabecalhoParaTaxaMensal: action.payload
            }
        default:
            return state;
    }
}