/* eslint eqeqeq: 0 */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import {
    showComplementoForm,
    removeComplemento,
    getComplementos,
    showComplementoDeleteDialog,
    exportComplementoOperacaoToExcel,
    cadastroComplementoImportacaoPorExcel,
    exportModeloComplemento,
    loadFormComplemento,
    exportRegrasComplemento
} from '../../../../../../actions/operacao/ComplementoAction';

import PaginationRB from '../../../../../common/pagination/PaginationRB';
import FloatButton from '../../../../../common/button/FloatButton';
import If from '../../../../../common/If/If';
import ConfirmDialog from '../../../../../common/modal/ConfirmDialog';
import { authorizationFunction } from '../../../../../../actions/authorization/AuthorizationAction';
import { OPERACAO_COMPLEMENTO, CREATE, DELETE, READ } from '../../../../../../utils/actionsAndFeaturesAuth';
import FloatButtonGroup from '../../../../../common/button/FloatButtonGroup';
import { ACCEPT_FILE_OPTIONS } from "../../../../../../utils/helpers/FileHelper";
import { showErrorMessages } from '../../../../../../actions/message/ErrorMessagesAPIAction';
import Table from '../../../../../common/table/Table';
import TableHeader from '../../../../../common/table/TableHeader';
import TableContent from '../../../../../common/table/TableContent';
import TableEmptyData from '../../../../../common/table/TableEmptyData';
import TableActionWithRouteAuthorization from '../../../../../common/table/TableActionWithRouteAuthorization';
import { getStringBooleanValue } from "../../../../../../utils/helpers/BooleanHelper";

class ComplementoList extends Component {
    getIdsOperacao = () => {
        let idsOperacao = 0;

        if (Array.isArray(this.props.operacoes) && this.props.operacoes.length) {
            idsOperacao = this.props.operacoes.map(o => o.key);    
        }

        return idsOperacao;
    }

    updateData = () => {
        this.props.getComplementos(this.getIdsOperacao());
    }
    
    // setIdsOperacaoFromRouteParams = () => {
    //     if (this.props.match.params.idsOperacao) {
    //         const idsOperacaoFromRouteParams = this.props.match.params.idsOperacao;

    //         if(this.getIdsOperacao()){
    //             this.updateData();
    //         } else {
    //             this.props.getComplementos(idsOperacaoFromRouteParams);
    //         }
    //     }
    // }

    // setRouteParamsFromIdsOperacao = () => {
    //     console.log("COMPLEMENTO",this.getIdsOperacao());
    //     history.push(this.props.match.path.replace(':idsOperacao?', `${this.getIdsOperacao() || ''}`));
    // }


    componentDidMount() {
        this.updateData();
        // this.setIdsOperacaoFromRouteParams();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.operacoes.length !== this.props.operacoes.length) {
            this.updateData();
            // this.setRouteParamsFromIdsOperacao();
        }
    }

    handleUploadFile() {
        let element = document.createElement('input');
        element.type = 'file';
        element.multiple = false;
        element.accept = ACCEPT_FILE_OPTIONS.EXCEL;
        element.onchange = (e) => {
            let files = element.files;
            if (files == null) {
                return;
            } else if (files.length == 0) {
                return;
            } else {
                this.upload(files[0]);
            }
        };

        element.click();
    }

    async upload(file) {
        try {
            this.props.cadastroComplementoImportacaoPorExcel(file, this.getIdsOperacao());
        } catch (e) {
            showErrorMessages("Erro ao importar o arquivo")
        }
    }

    render() {
        const {
            operacoes,
            complementoData,
            messages,
            showComplementoForm,
            removeComplemento,
            showComplementoDeleteDialog,
            getComplementos,
            showDeleteDialog,
            exportComplementoOperacaoToExcel,
            authorizationFunction,
            exportModeloComplemento,
            exportRegrasComplemento,
            loadFormComplemento
        } = this.props;

        const idsOperacoes = operacoes.map(o => o.key);
        const emptyData = messages && messages.infos && messages.infos[0];
        let classNdBotaoNovo = "nd5";
        let classNdBotaoImportar = "nd4";

        if (complementoData && !complementoData.hasItems) {
            classNdBotaoNovo = "nd4";
            classNdBotaoImportar = "nd3";
        }

        const tableHeader = (
            <tr>
                <th className="text-center fw-700 text-nowrap">PS</th>
                <th className="text-center fw-700 text-nowrap">Cód. Opea</th>
                <th className="text-center fw-700 text-nowrap">Tipo</th>
                <th className="text-center fw-700 text-nowrap">Título</th>
                <th className="text-center fw-700 text-nowrap">Monitoramento</th>
                <th className="text-center fw-700 text-nowrap">Última Renovação</th>
                <th className="text-center fw-700 text-nowrap">Próxima Renovação</th>
                <th className="text-center fw-700 text-nowrap">Status</th>
                <th className="text-center"></th>
            </tr>
        );

        const tableContent = (
            complementoData && complementoData.hasItems
                ? complementoData.items.map(complemento =>
                    (<tr key={complemento.idComplementoOperacao}>
                        <td className="text-center fw-400">{complemento.descricaoPatrimonioSeparado || '-'}</td>
                        <td className="text-center fw-400">{complemento.codigoOpea}</td>
                        <td className="text-center fw-400">{complemento.tipoComplemento}</td>
                        <td className="text-center fw-400">{complemento.descricaoTitulo}</td>
                        <td className="text-center fw-400">{getStringBooleanValue(complemento.monitoramento)}</td>
                        <td className="text-center fw-400">{complemento.dataUltimaRenovacao || '-'}</td>
                        <td className="text-center fw-400">{complemento.dataProximaRenovacao || '-'}</td>
                        <td className="text-center fw-400">{complemento.statusMonitoramento || '-'}</td>
                        <TableActionWithRouteAuthorization
                            space="2"
                            align="start"
                            feature={OPERACAO_COMPLEMENTO}
                            updateAction={() => loadFormComplemento(complemento.idComplementoOperacao, null, true)}
                            viewAction={() => loadFormComplemento(complemento.idComplementoOperacao)}
                            remove={() => showComplementoDeleteDialog(true, complemento.idComplementoOperacao)}
                            excelAction={complemento.possuiHistorico
                                ? {
                                    callback: () => exportRegrasComplemento(complemento.idComplementoOperacao),
                                    tooltipTitle: 'Download de histórico de monitoramento',
                                }
                                : null
                            }
                        />
                    </tr>)
                )
                : <TableEmptyData message={emptyData} />
        );

        return (
            <div>
                <If test={complementoData.totalCount > 0}>
                    <div className="d-flex flex-column">
                        <div className="mx-2 shadow-custom bg-white rounded-12">
                            <p className="page-head__title f-28 mb-0 px-3 mt-4 mb-3">Complementos das Operações</p>
                            <Table
                                withoutHeader
                                classes="card col-12 shadow-none bg-white py-0 m-0 mb-2 rounded-12"
                                totalItems={complementoData.totalCount}
                                pagination={
                                    <PaginationRB
                                        pageNumber={complementoData.pageIndex - 1}
                                        itemsPerPage={complementoData.pageSize}
                                        totalItems={complementoData.totalCount}
                                        filter={idsOperacoes}
                                        fetchFunction={getComplementos}
                                        pageRange={5}
                                    />
                                }
                            >
                                <TableHeader>
                                    {tableHeader}
                                </TableHeader>
                                <TableContent>
                                    {tableContent}
                                </TableContent>
                            </Table>
                        </div>
                    </div>
                </If>

                <FloatButtonGroup>
                    <FloatButton
                        activeClassName={'nd1 nds dark-grey-button'}
                        title={'Download planilha modelo'}
                        icon={'fa fa-cloud-download edit edit-sub-menu'}
                        action={() => exportModeloComplemento()}
                    />
                    <If test={complementoData && complementoData.hasItems}>
                        <FloatButton
                            activeClassName={'nd3 nds dark-grey-button'}
                            title="Exportar para excel"
                            icon="fa fa-file-excel-o edit edit-sub-menu"
                            action={() => authorizationFunction
                                (
                                    { idAcao: READ, idFuncionalidade: OPERACAO_COMPLEMENTO },
                                    () => exportComplementoOperacaoToExcel({ operacoes })
                                )
                            }
                        />
                    </If>

                    <If test={operacoes && operacoes.length === 1}>
                        <FloatButton
                            activeClassName={`${classNdBotaoImportar} nds yellow-button`}
                            title={'Importar Planilha'}
                            icon={'fa fa-upload edit edit-sub-menu'}
                            action={() => authorizationFunction
                                (
                                    { idAcao: CREATE, idFuncionalidade: OPERACAO_COMPLEMENTO },
                                    () => this.handleUploadFile()
                                )
                            }
                        />

                        <FloatButton
                            activeClassName={`${classNdBotaoNovo} nds blue-button`}
                            title="Novo"
                            icon={"fa fa-plus edit edit-sub-menu"}
                            action={() => authorizationFunction
                                (
                                    { idAcao: CREATE, idFuncionalidade: OPERACAO_COMPLEMENTO },
                                    () => showComplementoForm(operacoes[0].key)
                                )
                            }
                        />
                    </If>
                </FloatButtonGroup>
                <ConfirmDialog
                    title="Aviso"
                    contentText="Confirmar exclusão do complemento selecionado?"
                    textButtonCancel="CANCELAR"
                    textButtonConfirm="CONFIRMAR"
                    showDialog={showDeleteDialog.showDialog}
                    onClose={() => showComplementoDeleteDialog(false)}
                    onConfirm={() => authorizationFunction
                        (
                            { idAcao: DELETE, idFuncionalidade: OPERACAO_COMPLEMENTO },
                            () => removeComplemento(showDeleteDialog.idComplemento, idsOperacoes)
                        )
                    }
                    type="warning"
                    maxWidth="md"
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    showDeleteDialog: state.complementoReducer.showComplementoDeleteDialog,
    complementoData: state.complementoReducer.complementos,
    messages: state.complementoReducer.messages,
    operacoes: state.operacaoReducer.selectedOperacoes,
})

const mapDispacthToProps = dispatch => bindActionCreators({
    showComplementoForm,
    removeComplemento,
    getComplementos,
    showComplementoDeleteDialog,
    exportComplementoOperacaoToExcel,
    cadastroComplementoImportacaoPorExcel,
    authorizationFunction,
    exportModeloComplemento,
    loadFormComplemento,
    exportRegrasComplemento
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(ComplementoList));
