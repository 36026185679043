import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, formValueSelector } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import Button from "../../../../common/button/Button";

import { getUsuarios } from "../../../../../actions/usuario/UsuarioAction";
import DropdownListInput from "../../../../common/input/DropdownListInput";

let UsuarioFilter = (props) => {
  return (
    <div className="w-100 px-3">
      <Form>
        <FormHeader title={"Usuários"} />
        <FormContent classes="shadow-custom rounded-12 bg-white px-3 py-2 column">
          <div className="row">
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="usuario"
                name="usuario"
                label="Usuário"
                options={props.usuarioList}
                textField="nome"
                valueField="id"
                filter="contains"
              />
            </div>
            <div className="col-12 col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="perfil"
                name="perfil"
                label="Perfil"
                options={props.perfilList}
                textField="descricao"
                valueField="id"
                filter="contains"
              />
            </div>
          </div>
          <div className="col-lg-12 px-0">
            <Button
              action={() =>
                props.getUsuarios({
                  usuario: props.usuario,
                  perfil: props.perfil,
                })
              }
              classes="p-bg-imp rounded"
              icon={"search"}
              name={"Buscar"}
            />
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

UsuarioFilter = reduxForm({ form: "usuarioFilterForm" })(UsuarioFilter);
const selector = formValueSelector("usuarioFilterForm");

const mapStateToProps = (state) => ({
  usuarioList: state.usuarioReducer.usuarioFormTypes.usuarioList || [],
  perfilList: state.usuarioReducer.usuarioFormTypes.perfilList || [],
  usuario: selector(state, "usuario"),
  perfil: selector(state, "perfil"),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsuarios,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioFilter);
