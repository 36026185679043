/* eslint eqeqeq: 0 */

import {
  ERRO_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  LOG_ROUTE_PATH,
  OPCAO_SISTEMA_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

export const LOG_TAB = "LOG";
export const ERROS_TAB = "ERRO";

export const LogTabs = [
  {
    pathRoute: `/${OPCAO_SISTEMA_ROUTE_PATH}/${LOG_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    target: LOG_TAB,
    label: `${LOG_TAB}S`,
    addPlural: true,
  },
  {
    pathRoute: `/${OPCAO_SISTEMA_ROUTE_PATH}/${ERRO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    target: ERROS_TAB,
    label: `${ERROS_TAB}S`,
    addPlural: true,
  },
];

export const LOG_TABS = LogTabs;
