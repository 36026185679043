import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  editParticipante,
  loadFormParticipante,
} from "../../../../actions/participante/ParticipanteAction";
import ContainerPage from "../../../common/container/ContainerPage";
import ParticipanteForm from "./form/ParticipanteForm";
import { PARTICIPANTE, READ } from "../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../actions/authorization/AuthorizationAction";

let ParticipanteUpdatePage = (props) => {
  const {
    authorizationFunction,
    loadFormParticipante,
    editParticipante,
  } = props;

  React.useEffect(() => {
    let idParticipante = props.match.params.idParticipante;
    async function fetchLoad() {
      await authorizationFunction(
        { idAcao: READ, idFuncionalidade: PARTICIPANTE },
        async () => {
          loadFormParticipante(idParticipante)
        }
      );
    }

    fetchLoad();
  }, []);

  return (
    <ContainerPage>
      <ParticipanteForm
        readOnly={false}
        onSubmit={editParticipante}
        title={"Editar Assembléia"}
      />
    </ContainerPage>
  );
};


const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editParticipante,
      authorizationFunction,
      loadFormParticipante,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParticipanteUpdatePage);
