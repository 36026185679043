import React from 'react';
import { Route, Switch } from "react-router-dom";
 
import { 
    USUARIO_ROUTE_PATH, NOVO_ROUTE_PATH, LISTA_ROUTE_PATH, EDITAR_ROUTE_PATH, DETALHAR_ROUTE_PATH
} from '../../../common/routers/constantesRoutes';

import UsuarioPage from '../../usuario/pages/UsuarioPage';
import UsuarioIncludePage from '../pages/UsuarioIncludePage';
import UsuarioUpdatePage from '../pages/UsuarioUpdatePage';
import UsuarioVisualizePage from '../pages/UsuarioVisualizePage';

export default () => (
    <Switch>
        <Route exact={true} path={`/${USUARIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={UsuarioPage} />
        <Route exact={true} path={`/${USUARIO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={UsuarioIncludePage} />
        <Route exact={true} path={`/${USUARIO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idUsuario`} component={UsuarioUpdatePage} />
        <Route exact={true} path={`/${USUARIO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idUsuario`} component={UsuarioVisualizePage} />
    </Switch>
);