/* eslint react-hooks/exhaustive-deps: 0 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';

import { saveComplemento, loadFormComplemento }  from '../../../../../actions/operacao/ComplementoAction';

import { OPERACAO_TABS } from './../OperacaoTabs';
import ComplementoForm from './form/ComplementoForm';
import ContainerPage from '../../../../common/container/ContainerPage';

const ComplementoIncludePage = props => {
    const {
        saveComplemento,
        operacoes,
    } = props;

    const idsOperacoes = operacoes.map(o => o.key);
    
    React.useEffect(() => {
        const query = new URLSearchParams(props.location.search).get('q');
        props.loadFormComplemento(null, operacoes[0]?.key || null, undefined, query)
    }, [])
    
    return (
        <ContainerPage
            tabs={OPERACAO_TABS}
            paramsRoute={idsOperacoes}
        >
            <ComplementoForm 
                title={"Cadastro de Complemento"} 
                onSubmit={saveComplemento} 
                readOnly={false}
                floatButtonVisible={true}
            />
        </ContainerPage>
    );
}

const mapStateToProps = state => ({ 
    tab: state.tab,
    operacoes: state.operacaoReducer.selectedOperacoes,
});

const mapDispacthToProps = dispatch => bindActionCreators({ 
    saveComplemento,
    loadFormComplemento
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(ComplementoIncludePage));