import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import PrestadoraForm from "./form/PrestadoraForm";
import {
  loadFormPrestadora,
  editPrestadora,
} from "./../../../../actions/prestadora/PrestadoraAction";
import { formValueSelector } from "redux-form";

class PrestadoraUpdatePage extends Component {
  componentDidMount() {
    let idPrestadora = this.props.match.params.idPrestadora;
    const filter = {
      cnpj: this.props.cnpj || "",
      nomeSimplificado: this.props.nomeSimplificado || "",
      pesquisou: this.props.pesquisou || false,
      page: this.props.page || 1,
    };
    this.props.loadFormPrestadora(filter, idPrestadora);
  }

  render() {
    const { editPrestadora, empresaPrestadora } = this.props;
    
    const statusValue = empresaPrestadora?.statusAprovacao?.key;
    const dadosLegado = empresaPrestadora?.dadosLegado;

    return (
      <PrestadoraForm
        title={"Editar - Pessoa"}
        onSubmit={editPrestadora}
        readOnly={dadosLegado ? false : statusValue >= 2}
        floatButtonVisible={true}
      />
    );
  }
}

const selector = formValueSelector("prestadorFilterForm");
const mapStateToProps = (state) => ({
  cnpj: selector(state, "cnpj"),
  nomeSimplificado: selector(state, "nomeSimplificado"),
  pesquisou: selector(state, "pesquisou"),
  page: selector(state, "page"),
  empresaPrestadora: state.prestadoraReducer.prestadoraFormTypes.empresaPrestadora || [],
});
const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      editPrestadora,
      loadFormPrestadora,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(PrestadoraUpdatePage)
);
