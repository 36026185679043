export const SHOW_CONFIRM_DIALOG = "SHOW_CONFIRM_DIALOG";

export function showConfirmDialog(showDialog) {
    return (dispatch) => {
        return dispatch([
            {
                type: SHOW_CONFIRM_DIALOG,
                payload: showDialog
            }
        ])
    }
}