import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  editRegulatorio,
  loadFormRegulatorio,
} from "../../../../../actions/relatorios/regulatorioAction";
import ContainerPage from "../../../../common/container/ContainerPage";
import RegulatorioForm from "./form/RegulatorioForm";
import { RELATORIOS_REGULATORIOS, READ } from "../../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";

let RegulatorioUpdatePage = (props) => {
  const {
    authorizationFunction,
    loadFormRegulatorio,
    editRegulatorio,
  } = props;

  React.useEffect(() => {
    let idRegulatorio = props.match.params.idRegulatorio;
    async function fetchLoad() {
      await authorizationFunction(
        { idAcao: READ, idFuncionalidade: RELATORIOS_REGULATORIOS },
        async () => {
          loadFormRegulatorio(idRegulatorio)
        }
      );
    }

    fetchLoad();
  }, []);

  return (
    <ContainerPage>
      <RegulatorioForm
        readOnly={false}
        onSubmit={editRegulatorio}
        title={"Editar Informe CVM"}
      />
    </ContainerPage>
  );
};


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editRegulatorio,
      authorizationFunction,
      loadFormRegulatorio,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RegulatorioUpdatePage);
