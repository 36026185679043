import axios from "axios";
import { change, formValueSelector } from "redux-form";

import { showAllMessages } from "../message/ErrorMessagesAPIAction";
import { showConfirmDialog } from "../common/modal/ConfirmDialogAction";

import {
  serializeFieldsReceitaForm,
  deserializeFieldsReceitaForm,
} from "../../components/business/operacao/mappings/ReceitaMap";

import history from "../../components/common/routers/history";
import {
  OPERACAO_ROUTE_PATH,
  RECEITA_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  DETALHAR_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";
import { msgError } from "../../utils/helpers/OthersHelpers";
import { changeMessage } from "../infra/LoadingScreenAction";

export const RECEITA_FETCHED = "RECEITA_FETCHED";
export const RECEITA_FETCHED_FILTROS = "RECEITA_FETCHED_FILTROS";
export const RECEITA_FORM_TAB = "RECEITA_FORM_TAB";
export const RECEITA_SHOW_DELETE_DIALOG = "RECEITA_SHOW_DIALOG";
export const LOAD_FORM_RECEITA = "LOAD_FORM_RECEITA";
export const SAVE_FORM_RECEITA_ERRORS = "SAVE_FORM_RECEITA_ERRORS";
export const LOAD_CONTAS_DESTINO = "LOAD_CONTAS_DESTINO";

const OPERACAO_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API;
const RECEITA_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API + process.env.REACT_APP_PATH_RECEITA_API;

export function showMessageAlert(msg) {
  return () => {
      msgError(msg);
      return false;
  }
}

export function limpaDependentesOnChange(dependentes) {
  return (dispatch) => {
    const actions = dependentes.map(dependente => change("ReceitaForm", dependente, null));
    dispatch(actions);
  };
}

export function mudaDependenteOnChange(dependente, value = null) {
  return (dispatch) => {
    dispatch(change("ReceitaForm", dependente, value));
  };
}

export function getReceitas(idsOperacoes, page = 1) {
  let params = idsOperacoes?.length > 0 ? idsOperacoes : 0;

  return (dispatch, getState) => {
    dispatch(changeMessage("Carregando Receitas das Operações"))

    const selector = formValueSelector("receitaListForm");
    const status = selector(getState(), "statusAprovacao.key");
    const idTomador = selector(getState(), "tomador.id");
    const identificador = selector(getState(), "identificador") || '';
    const idTipoReceita = selector(getState(), "tipoReceita.id");

    const url = `${params}/receitas?pagina=${page}&tamanhoPagina=10` +
      (status ? `&statusAprovacao=${status}` : '') +
      (idTomador ? `&idTomador=${idTomador}` : '') +
      (identificador ? `&identificador=${identificador}` : '') +
      (idTipoReceita ? `&idTipoReceita=${idTipoReceita}` : '')

    return axios
      .get(OPERACAO_API + url)
      .then((result) =>
        dispatch({
          type: RECEITA_FETCHED,
          payload: result,
        })
      );
  };
}

export function getFiltrosReceita() {
  return (dispatch) => {
    dispatch(changeMessage("Carregando Filtros das Receitas das Operações"))

    return axios
      .get(RECEITA_API + 'filtros')
      .then((result) =>
        dispatch({
          type: RECEITA_FETCHED_FILTROS,
          payload: result,
        })
      );
  };
}

export function showReceitaForm(idOperacao) {
  return dispatch => {
      return dispatch([
          history.push(`/${OPERACAO_ROUTE_PATH}/${RECEITA_ROUTE_PATH}/${NOVO_ROUTE_PATH}?q=${idOperacao}`)
      ]);
  };
}

export function saveReceita(receita) {
  const receitaDeserialized = deserializeFieldsReceitaForm(receita);

  return (dispatch) => {
    dispatch(changeMessage("Salvando Receita"))

    dispatch([
      {
        type: SAVE_FORM_RECEITA_ERRORS,
        payload: null,
      },
    ]);
    return axios
      .post(RECEITA_API, { ...receitaDeserialized })
      .then((result) =>
        dispatch([showAllMessages(result.messages), showReceitaList()])
      )
      .catch((err) => {
        if (err && err.data?.messages && err.data?.messages?.hasError) {
          document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" });
          dispatch([
            {
              type: SAVE_FORM_RECEITA_ERRORS,
              payload: err.data?.messages,
            },
          ]);
        }
      });
  };
}

export function editReceita(receita) {
  const receitaDeserialized = deserializeFieldsReceitaForm(receita);

  return (dispatch) => {
    dispatch(changeMessage("Salvando Receita"))

    dispatch([
      {
        type: SAVE_FORM_RECEITA_ERRORS,
        payload: null,
      },
    ]);
    return axios
      .put(RECEITA_API, { ...receitaDeserialized })
      .then((result) =>
        dispatch([showAllMessages(result.messages), showReceitaList()])
      )
      .catch((err) => {
        if (err && err.data?.messages && err.data?.messages?.hasError) {
          document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" });
          dispatch([
            {
              type: SAVE_FORM_RECEITA_ERRORS,
              payload: err.data?.messages,
            },
          ]);
        }
      });
  };
}

export function removeReceita(idReceita, idsOperacoes) {
  return (dispatch) => {
    dispatch(changeMessage("Removendo Receita"))
    return axios
      .delete(RECEITA_API + idReceita)
      .then((result) =>
        dispatch([
          showReceitaDeleteDialog(false, 0),
          showAllMessages(result.messages),
          getReceitas(),
        ])
      );
  };
}

export function loadFormReceita(idReceita, idOperacao, editAction, query) {
  return (dispatch) => {
    dispatch(changeMessage("Carregando Receita da Operação"))

    return axios.get(RECEITA_API + `tela/${idReceita || ''}${query ? `?idOperacao=${query}` : ''}`)
    .then((result) => {
      dispatch([
        idReceita && goToFormPage(editAction, idReceita),
        {
          type: LOAD_FORM_RECEITA,
          payload: result.content,
        },
        serializeFieldsReceitaForm(result.content?.receitaContrato, idOperacao),
      ]);
    });
  };
}

export function showReceitaList(idsOperacoes = "") {
  return (dispatch) => {
    return dispatch([
      showConfirmDialog(false),
      history.push(
        `/${OPERACAO_ROUTE_PATH}/${RECEITA_ROUTE_PATH}/${LISTA_ROUTE_PATH}/${idsOperacoes}`
      ),
    ]);
  };
}

export function changeReceitaFormTab(formTab) {
  return (dispatch) => {
    return dispatch([
      {
        type: RECEITA_FORM_TAB,
        payload: formTab,
      },
    ]);
  };
}

export function showReceitaDeleteDialog(showDialog, idReceita) {
  return (dispatch) => {
    return dispatch([
      {
        type: RECEITA_SHOW_DELETE_DIALOG,
        payload: { showDialog, idReceita },
      },
      history.push(
        `/${OPERACAO_ROUTE_PATH}/${RECEITA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`
      ),
    ]);
  };
}

export function goToFormPage(editAction, idReceita) {
  let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;
  return (dispatch) => {
    return dispatch([
      history.push(
        `/${OPERACAO_ROUTE_PATH}/${RECEITA_ROUTE_PATH}/${routeAction}/${idReceita}`
      ),
    ]);
  };
}
