import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// import DeleteRoundedIcon from '@material-ui/';
import { lighten } from 'material-ui/utils/colorManipulator';

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
        fontSize: '1.1em',
        fontWeight: 300,
    },
  });

let TableSelectToolbar = (props) => {
    const {numSelected, classes, title } = props;

    return (
        <Toolbar>
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography className={classes.title} color="default" variant="subtitle1">
                        {numSelected} {`selecionado${numSelected > 1 ? 's' : ''}`}
                    </Typography>
                ) : (
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        {title}
                    </Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                <Tooltip title="Delete">
                    <IconButton aria-label="Delete">
                        {/* <DeleteRoundedIcon /> */}
                    </IconButton>
                </Tooltip>
            </div>
        </Toolbar>
    );
}


export default withStyles(toolbarStyles)(TableSelectToolbar);

TableSelectToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
};