import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../common/container/ContainerPage";

import AprovadoresListFilter from "./form/AprovadoresListFilter";
import AprovadoresList from "./list/AprovadoresList";

class AprovadoresPage extends Component {
  render() {
    return (
      <ContainerPage>
        <AprovadoresListFilter />
        <AprovadoresList />
      </ContainerPage>
    );
  }
}

export default connect(null, null)(AprovadoresPage);
