import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../common/table/TableEmptyData";
import ConfirmDialog from "../../../../../common/modal/ConfirmDialog";
import FloatButton from "../../../../../common/button/FloatButton";

import {
  showDominioForm,
  showDominioDeleteDialog,
  removeDominio,
  loadFormDominio,
  getDominio,
} from "../../../../../../actions/precificacao/DominioAction";

import TableActionWithRouteAuthorization from "../../../../../common/table/TableActionWithRouteAuthorization";
import {
  DOMINIO,
  CREATE,
  DELETE,
} from "../../../../../../utils/actionsAndFeaturesAuth";
import { authorizationWithoutDispatch } from "../../../../../../actions/authorization/AuthorizationAction";
import PaginationRB from "../../../../../common/pagination/PaginationRB";
import If from "../../../../../common/If/If";

const DominioList = (props) => {
  const {
    showDominioForm,
    showDominioDeleteDialog,
    showDeleteDialog,
    removeDominio,
    dominios,
    loadFormDominio,
    getDominio,
  } = props;

  const { items, hasItems, pageIndex, pageSize, totalCount } = dominios;

  const tableHeader = (
    <tr>
      <th className="text-center fw-700 text-nowrap">Nome</th>
      <th className="text-center"></th>
    </tr>
  );

  const tableContent =
    dominios && hasItems ? (
      items.map((item, index) => (
        <tr key={index}>
          <td className="text-center fw-400 text-nowrap">{item.descricao}</td>
          <TableActionWithRouteAuthorization
            feature={DOMINIO}
            updateAction={() => loadFormDominio(item.id, true)}
          />
        </tr>
      ))
    ) : (
      <TableEmptyData message={"Sem itens no momento"} />
    );

  return (
    <div className="w-100 px-4 mx-2">
      <If test={totalCount > 0}>
        <Table
          withoutHeader
          classes="shadow-custom rounded-12 col-12 mb-3 mt-0 pd-10 bg-white"
          totalItems={totalCount}
          pagination={
            <PaginationRB
              pageRange={5}
              itemsPerPage={pageSize || 0}
              totalItems={totalCount || 0}
              pageNumber={pageIndex - 1 || 0}
              fetchFunction={(event) => getDominio(event)}
            />
          }
        >
          <TableHeader>{tableHeader}</TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      </If>
      <FloatButton
        title="Novo"
        activeClassName={"float"}
        icon={"fa fa-plus my-float"}
        action={() =>
          authorizationWithoutDispatch(
            { idAcao: CREATE, idFuncionalidade: DOMINIO },
            () => showDominioForm()
          )
        }
      />
      <ConfirmDialog
        title="Aviso"
        textButtonCancel="CANCELAR"
        textButtonConfirm="CONFIRMAR"
        showDialog={showDeleteDialog.showDialog}
        onClose={() => showDominioDeleteDialog(false)}
        contentText="Você realmente deseja excluir o item selecionado ?"
        onConfirm={() =>
          authorizationWithoutDispatch(
            { idAcao: DELETE, idFuncionalidade: DOMINIO },
            () => removeDominio(showDeleteDialog.idDominio)
          )
        }
        type="warning"
        maxWidth="md"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  messages: state.dominioReducer.messages,
  showDeleteDialog: state.dominioReducer.showDominioDeleteDialog,
  dominios: state.dominioReducer.dominioFormTypes || [],
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      showDominioForm,
      showDominioDeleteDialog,
      removeDominio,
      loadFormDominio,
      getDominio,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(DominioList);
