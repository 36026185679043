import axios from "axios";
import { change, formValueSelector } from "redux-form";

import { showAllMessages } from "./../../actions/message/ErrorMessagesAPIAction";
import { showConfirmDialog } from "./../common/modal/ConfirmDialogAction";

import {
  serializeFieldsDespesaForm,
  deserializeFieldsDespesaForm,
  deserializeLancamentos,
} from "./../../components/business/operacao/mappings/DespesaMap";

import history from "./../../components/common/routers/history";
import {
  OPERACAO_ROUTE_PATH,
  DESPESA_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  DETALHAR_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
} from "./../../components/common/routers/constantesRoutes";
import { msgError } from "../../utils/helpers/OthersHelpers";
import { downloadFile } from "../../utils/helpers/FileHelper";
import { changeMessage } from "../infra/LoadingScreenAction";

export const DESPESA_FETCHED = "DESPESA_FETCHED";
export const DESPESA_SEARCHED = "DESPESA_SEARCHED";
export const DESPESA_FORM_TAB = "DESPESA_FORM_TAB";
export const DESPESA_SHOW_DELETE_DIALOG = "DESPESA_SHOW_DIALOG";
export const LOAD_FORM_DESPESA = "LOAD_FORM_DESPESA";
export const SAVE_FORM_DESPESA_ERRORS = "SAVE_FORM_DESPESA_ERRORS";
export const LOAD_CONTAS_DESTINO = "LOAD_CONTAS_DESTINO";
export const LOAD_LANCAMENTOS = "LOAD_LANCAMENTOS";

const OPERACAO_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API;
const DESPESA_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API + process.env.REACT_APP_PATH_DESPESA_API;
const PRESTADORA_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PRESTADORA_API;

export function showMessageAlert(msg) {
  return () => {
      msgError(msg);
      return false;
  }
}

export function limpaDependentesOnChange(dependentes) {
  return (dispatch) => {
    const actions = dependentes.map(dependente => change("DespesaForm", dependente, null));
    dispatch(actions);
  };
}

export function mudaDependenteOnChange(dependente, value = null) {
  return (dispatch) => {
    dispatch([change("DespesaForm", dependente, value)]);
  };
}

export function getDespesas(idsOperacoes, page = 1) {
  let params = idsOperacoes?.length > 0 ? idsOperacoes : 0;

  return (dispatch, getState) => {
    dispatch(changeMessage("Carregando Despesas da Operação"))
    const selector = formValueSelector("despesaListForm");
    const status = selector(getState(), "status.key");
    const identificador = selector(getState(), "identificador") || '';

    const url = `${params}/despesas?pagina=${page}&tamanhoPagina=10` +
      (status ? `&status=${status}` : '') +
      (identificador ? `&identificador=${identificador}` : '');

    return axios
      .get(OPERACAO_API + url)
      .then((result) =>
        dispatch({
          type: DESPESA_FETCHED,
          payload: result,
        })
      );
  };
}

export function getDespesa(idDespesa) {
  return (dispatch) => {
    dispatch(changeMessage("Carregando Despesa da Operação"))
    return axios
      .get(DESPESA_API + idDespesa)
      .then((result) => dispatch([serializeFieldsDespesaForm(result.content)]));
  };
}

export function showDespesaForm(idOperacao) {
  return dispatch => {
      return dispatch([
          history.push(`/${OPERACAO_ROUTE_PATH}/${DESPESA_ROUTE_PATH}/${NOVO_ROUTE_PATH}?q=${idOperacao}`)
      ]);
  };
}

export function saveDespesa(despesa) {
  const despesaDeserialized = deserializeFieldsDespesaForm(despesa);

  return (dispatch) => {
    dispatch(changeMessage("Salvando Despesa"))
    dispatch([
      {
        type: SAVE_FORM_DESPESA_ERRORS,
        payload: null,
      },
    ]);
    return axios
      .post(DESPESA_API, { ...despesaDeserialized })
      .then((result) =>
        dispatch([showAllMessages(result.messages), showDespesaList()])
      )
      .catch((err) => {
        if (err && err.data?.messages && err.data?.messages?.hasError) {
          document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" });
          dispatch([
            {
              type: SAVE_FORM_DESPESA_ERRORS,
              payload: err.data?.messages,
            },
          ]);
        }
      });
  };
}

export function editDespesa(despesa) {
  const despesaDeserialized = deserializeFieldsDespesaForm(despesa);

  return (dispatch) => {
    dispatch(changeMessage("Salvando Despesa"))
    dispatch([
      {
        type: SAVE_FORM_DESPESA_ERRORS,
        payload: null,
      },
    ]);
    return axios
      .put(DESPESA_API, { ...despesaDeserialized })
      .then((result) =>
        dispatch([showAllMessages(result.messages), showDespesaList()])
      )
      .catch((err) => {
        if (err && err.data?.messages && err.data?.messages?.hasError) {
          document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" });
          dispatch([
            {
              type: SAVE_FORM_DESPESA_ERRORS,
              payload: err.data?.messages,
            },
          ]);
        }
      });
  };
}

export function onDropDocument(arquivos) {
  return (dispatch, getState) => {
      const selector = formValueSelector("DespesaForm");
      const documentos = selector(getState(), "documentos") || [];

      dispatch([
          change("DespesaForm", "documentos", [...documentos, ...arquivos])
      ])
  }
}

export function onDeleteDocument(index) {
  return (dispatch, getState) => {
      const selector = formValueSelector("DespesaForm");
      const documentos = selector(getState(), "documentos") || [];

      const newFiles = [...documentos];
      newFiles.splice(index, 1);

      dispatch([
          change("DespesaForm", "documentos", newFiles)
      ])
  }
}

export function downloadPedidoDocument(params) {
  return (dispatch) => {
      return axios
          .post(`${DESPESA_API}exportar-documento`, params)
          .then(result => {
              if (result?.content){
                const { content } = result;

                dispatch([
                  downloadFile(content?.arquivoAnexado, `${content?.nomeArquivo}.${content?.formatoArquivo}`),
                  showAllMessages(result.messages),
                ])
              } else {
                msgError("Arquivo não encontrado", false);
                return false;
              }
          });
  }
}

export function removeDespesa(idDespesa, idsOperacoes) {
  return (dispatch) => {
    dispatch(changeMessage("Removendo Despesa"))
    return axios
      .delete(DESPESA_API + idDespesa)
      .then((result) =>
        dispatch([
          showDespesaDeleteDialog(false, 0),
          showAllMessages(result.messages),
          getDespesas()
        ])
      );
  };
}

export function loadFormDespesa(idDespesa, idOperacao, editAction, query) {
  return (dispatch) => {
    dispatch(changeMessage(idDespesa ? "Carregando Despesa" : "Carregando Tela"))
    return axios.get(DESPESA_API + `tela/${idDespesa || ''}${query ? `?idOperacao=${query}` : ''}`)
    .then((result) => {
      const idEmpresaPrestadora = result?.content?.despesaOperacao?.idEmpresaPrestadora;

      dispatch([
        idDespesa && goToFormPage(editAction, idDespesa),
        {
          type: LOAD_FORM_DESPESA,
          payload: result.content,
        },
        serializeFieldsDespesaForm(result.content.despesaOperacao, idOperacao, result.content),
        loadContasDestino(idEmpresaPrestadora),
      ]);
    });
  };
}

export function loadContasDestino(idPrestadora) {
  return (dispatch) => {
    dispatch(changeMessage("Carregando Contas"))
    if (idPrestadora) {
      return axios.get(PRESTADORA_API + `contas/${idPrestadora}`)
      .then((result) => {
        dispatch([
          {
            type: LOAD_CONTAS_DESTINO,
            payload: result.content,
          },
        ]);
      });
    }
    return false;
  };
}

export function loadLancamentos(idPrestadora, pageIndex) {
  return (dispatch) => {
    dispatch(changeMessage("Carregando Lançamentos"))
    if (idPrestadora) {
      return axios.get(DESPESA_API + `lancamentos/${idPrestadora}?pageSize=10&pageIndex=${pageIndex}`)
      .then((result) => {
        const lancamentosDeserialized = deserializeLancamentos(result?.content);

        const { estatisticas } = result?.content;

        dispatch([
          change("DespesaForm", "estatisticas.valorMedio", estatisticas?.valorMedio),
          change("DespesaForm", "estatisticas.totalPago", estatisticas?.totalPago),
          change("DespesaForm", "estatisticas.parcelasPagas", estatisticas?.parcelasPagas),
          change("DespesaForm", "estatisticas.variacaoPercentual", estatisticas?.variacaoPercentual),
          {
            type: LOAD_LANCAMENTOS,
            payload: lancamentosDeserialized,
          },
        ]);
      });
    }
    return false;
  };
}

export function uploadCurvaPagamento(curvaPagamentoFile) {
  let formData = new FormData();
  formData.append("file", curvaPagamentoFile);

  return (dispatch) => {
    return axios
      .post(DESPESA_API + "curvas", formData)
      .then((result) =>
        dispatch([
          showAllMessages(result.messages) &&
            change("DespesaForm", "curvaPagamentoList", result.content),
        ])
      );
  };
}

export function calculaParcelas(diferencaEmDias, diaPagamento) {
  return (dispatch) => {
    const resultado = Math.ceil(diferencaEmDias / diaPagamento);

    dispatch([
      change("DespesaForm", "despesaPagamentoRecorrente.quantidadeParcela", resultado),
    ])

    return resultado;
  };
}

export function showDespesaList(idsOperacoes = "") {
  return (dispatch) => {
    return dispatch([
      getDespesas(idsOperacoes),
      showConfirmDialog(false),
      history.push(
        `/${OPERACAO_ROUTE_PATH}/${DESPESA_ROUTE_PATH}/${LISTA_ROUTE_PATH}/${idsOperacoes}`
      ),
    ]);
  };
}

export function changeDespesaFormTab(formTab) {
  return (dispatch) => {
    return dispatch([
      {
        type: DESPESA_FORM_TAB,
        payload: formTab,
      },
    ]);
  };
}

export function showDespesaDeleteDialog(showDialog, idDespesa) {
  return (dispatch) => {
    return dispatch([
      {
        type: DESPESA_SHOW_DELETE_DIALOG,
        payload: { showDialog, idDespesa },
      },
      history.push(
        `/${OPERACAO_ROUTE_PATH}/${DESPESA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`
      ),
    ]);
  };
}

export function goToFormPage(editAction, idDespesa) {
  let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;
  return (dispatch) => {
    return dispatch([
      history.push(
        `/${OPERACAO_ROUTE_PATH}/${DESPESA_ROUTE_PATH}/${routeAction}/${idDespesa}`
      ),
    ]);
  };
}
