import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector, Field } from "redux-form";

import { showConfirmDialog } from "../../../../../actions/common/modal/ConfirmDialogAction";
import {
  showUsuarioList,
  onChangeConta,
  changeConta,
  onChangeUsuario,
} from "../../../../../actions/usuario/UsuarioAction";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import DropdownListInput from "../../../../common/input/DropdownListInput";
import Input from "../../../../common/input/Input";
import { actionExportacao, sairDaPagina } from "../../../../../actions/exportacaoContas/ExportacaoAction";
import Button from "../../../../common/button/Button";
import { withRouter } from "react-router-dom";
import ModalCriarArquivo from "../modals/ModalCriarArquivo";
import { convertCurrencyNumber } from "../../../../../utils/helpers/NumberHelper";
import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";
import TableEmptyData from "../../../../common/table/TableEmptyData";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import Table from "../../../../common/table/Table";
import ModalConfirmacao from "../modals/ModalConfirmacao";

let ExportacaoForm = (props) => {
  const {
    title,
    handleSubmit,
    readOnly,
    sairDaPagina,
    tipoPagamento,
    dataPagamento,
    exportacao,
    tipoPagamentoList,
    edit,
    status,
    actionExportacao,
  } = props;

  const [openCriar, setOpenCriar] = React.useState(false);
  const [action, setAction] = React.useState("");

  const PENDENTE = status?.key == 1;
  const CONCLUIDO = status?.key == 2;

  const data = {
    id: props.match.params?.idExportacao,
    tipoPagamento,
    dataPagamento,
    action
  }

  const tableHeader = (
    <tr>
        <th className='text-center fw-700'>ID</th>
        <th className='text-center fw-700'>DATA CRIAÇÃO</th>
        <th className='text-center fw-700'>DATA COBRANÇA</th>
        <th className='text-center fw-700'>PATRIMÔNIO SEPARADO</th>
        <th className='text-center fw-700'>SÉRIE</th>
        <th className='text-center fw-700'>TIPO DE RECEITA</th>
        <th className='text-center fw-700'>FATURAR CONTRA</th>
        <th className='text-center fw-700'>VALOR BRUTO</th>
    </tr>
  );

  const tableContent = (
    exportacao?.contasReceber?.length > 0 
        ? exportacao?.contasReceber.map((item, index) =>
            (<tr key={index}>
                <td className='text-center'>{item?.identificador || '--'}</td>
                <td className='text-center'>{item.dataCriacao ? getDateFormatedFromString(item?.dataCriacao) : '--'}</td>
                <td className='text-center'>{item.dataPagamento ? getDateFormatedFromString(item?.dataPagamento) : '--'}</td>
                <td className='text-center'>{item?.patrimonioSeparado || '--'}</td>
                <td className='text-center'>{item?.serie || '--'}</td>
                <td className='text-center'>{item?.tipoReceita || '--'}</td>
                <td className='text-center'>{item?.faturarContra || '--'}</td>
                <td className='text-center'>{item?.valorBruto ? convertCurrencyNumber(item?.valorBruto, "R$", 2) : '--'}</td>
            </tr>)
        )
        : <TableEmptyData message={"Sem dados a serem exibidos"} />
  );

  return (
    <Form>
      <FormHeader title={title} col={"col-lg"} offSet={20}></FormHeader>
      <FormContent handleSubmit={handleSubmit}>
        <div className="shadow-custom rounded-12 bg-white px-3 py-2 column">
          <div className="row px-0">
            <div className="col-lg">
              <Field
                component={Input}
                inputId="identificador"
                name="identificador"
                label="ID de Exportação"
                readOnly={true}
              />
            </div>

            <div className="col-lg">
              <Field
                component={DropdownListInput}
                inputId="tipoPagamento"
                name="tipoPagamento"
                label="Tipo de Pagamento"
                options={tipoPagamentoList}
                textField="value"
                valueField="key"
                readOnly={readOnly}
              />
            </div>

            <div className="col-lg">
              <Field
                component={Input}
                type="date"
                inputId="dataPagamento"
                name="dataPagamento"
                label="Data de Pagamento (até)"
                readOnly={readOnly}
              />
            </div>

            <div className="col-lg">
              <Field
                component={Input}
                inputId="status"
                name="status"
                label="Status"
                readOnly={true}
              />
            </div>
          </div>
        </div>

        {exportacao?.contasReceber?.length > 0 && (
          <Table
            withoutHeader
            files={CONCLUIDO ? [
              { 
                label: 'Exportar Arquivo', 
                function: () => actionExportacao({ ...data, action: "gerar", somenteExportarArquivoCsv: true }) 
              }
            ] : undefined}
            classes="col-12 shadow-custom rounded-12 bg-white pt-2 pb-3 mb-2"
          >
            <TableHeader >
                {tableHeader}
            </TableHeader>
            <TableContent>
                {tableContent}
            </TableContent>
          </Table>
        )}

        <div className="col-12 px-0">
          <div className="d-flex flex-row justify-content-between align-items-center col-12 p-0 mb-2 mt-3">
            <Button
              name="Voltar Tela"
              classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={() => sairDaPagina()}
            />

            {(!exportacao && !edit) && (
              <Button
                name={"Iniciar Criação do Arquivo"}
                classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                action={() => setOpenCriar(true)}
                disabled={!tipoPagamento || !dataPagamento}
              />
            )}

            {PENDENTE && (
              <div className="d-flex flex-row justify-content-between align-items-center">
                <Button
                  name={"Cancelar Criação"}
                  classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mr-3"
                  action={() => setAction("cancelar")}
                />

                <Button
                  name={"Gerar Arquivo"}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => setAction("gerar")}
                />
              </div>
            )}
          </div>
        </div>
      </FormContent>

      {!exportacao && (
        <ModalCriarArquivo
          open={openCriar}
          close={() => setOpenCriar(false)}
          item={{ tipoPagamento, dataPagamento }}
        />
      )}

      {action && (
        <ModalConfirmacao
          open={Boolean(action)}
          close={() => setAction("")}
          data={data}
        />
      )}
    </Form>
  );
};

// VALIDAÇÃO
const validate = (values) => {
  const errors = {};
  return errors;
};

const selector = formValueSelector("ExportacaoCARForm");

const mapStateToProps = (state) => ({
  tipoPagamento: selector(state, "tipoPagamento") || null,
  dataPagamento: selector(state, "dataPagamento") || null,
  lista: selector(state, "lista") || null,
  exportacao: state.exportacaoContasReducer.exportacaoForm.exportacao || null,
  status: state.exportacaoContasReducer.exportacaoForm.exportacao?.status || null,
  tipoPagamentoList: state.exportacaoContasReducer.exportacaoForm.tipoPagamentoList || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showUsuarioList,
      showConfirmDialog,
      onChangeConta,
      changeConta,
      onChangeUsuario,
      sairDaPagina,
      actionExportacao,
    },
    dispatch
  );

const ConnectedExportacaoForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(ExportacaoForm));

export default reduxForm({
  form: "ExportacaoCARForm",
  validate,
})(ConnectedExportacaoForm);
