import {
    PASSIVO_FETCHED,
    PATRIMONIO_FETCHED,
    PASSIVO_FORM_TAB,
    LOAD_FORM_PASSIVO,
    PASSIVO_SHOW_DELETE_DIALOG,
    PASSIVO_CHANGE_BASE_CALCULO_DESCRIPTION,
    PASSIVO_INSERT_NOME_PASTA_CEDOC,
    PASSIVO_CHANGE_DATA_EMISSAO,
    LOAD_FORM_PASSIVO_MOEDA,
    LOAD_CLASSES_FUNDO,
    LOAD_SUBCLASSES_FUNDO,
    SAVE_FORM_PASSIVO_ERRORS,
    SHOW_MODAL_APPROVING_PASSIVO_WORKFLOW,
    SHOW_MODAL_ONBOARDING_PASSIVO_WORKFLOW,
    SHOW_MODAL_FINISH_PASSIVO_WORKFLOW,
    LOAD_FORM_PASSIVO_APROVACAO,
    UPDATE_RATING_LIST_REGISTRO,
} from '../../actions/operacao/PassivoAction';

const ID_FREQUENCIA_MENSAL = 1;
const ID_BASE_CALCULO_MESES_CORRIDOS_30_360 = 5;

const INITIAL_STATE = {
    passivos: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    isLoading: false,
    messages: null,
    showPassivoDeleteDialog: {
        showDialog: false,
        codigoRB: "",
        idOperacao: 0,
    },
    showInserirNomePastaCedocDialog: {
        showDialog: false,
        idAssembleia: 0,
    },
    changePassivoFormTab: '',
    passivoFormTypes: {},
    dataEmissaoSerie: "",
    showModalAprovacao: false,
    showModalOnboarding: false,
    showModalFinalizacao: false,
    aprovacaoPassivoForm: {},
};

export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case PASSIVO_FETCHED:
            return {
                ...state,
                passivos: action.payload.content,
                messages: action.payload.messages,
                isLoading: true
            };
        case PATRIMONIO_FETCHED:
            return {
                ...state,
                patrimonios: action.payload.content,
                messages: action.payload.messages,
                isLoading: true
            };
        case PASSIVO_FORM_TAB:
            return {
                ...state,
                changePassivoFormTab: action.payload
            };
        case SHOW_MODAL_APPROVING_PASSIVO_WORKFLOW:
            return {
                ...state,
                showModalAprovacao: action.payload
            };
        case SHOW_MODAL_ONBOARDING_PASSIVO_WORKFLOW:
            return {
                ...state,
                showModalOnboarding: action.payload
            };
        case SHOW_MODAL_FINISH_PASSIVO_WORKFLOW:
            return {
                ...state,
                showModalFinalizacao: action.payload
            };
        case PASSIVO_SHOW_DELETE_DIALOG:
            return {
                ...state,
                showPassivoDeleteDialog: action.payload
            }
        case SAVE_FORM_PASSIVO_ERRORS:
            return {
              ...state,
              messages: action.payload
            }
        case LOAD_FORM_PASSIVO_APROVACAO:
            return {
                ...state,
                aprovacaoPassivoForm: action.payload,
            }
        case LOAD_FORM_PASSIVO:
            let baseCalculoTaxaJurosList  = action.payload.baseCalculoTaxaJurosList;

            if (action.payload.passivoOperacao && 
                action.payload.passivoOperacao.pagamentoPassivo && 
                action.payload.passivoOperacao.pagamentoPassivo.periodicidadeFrequenciaJuros) {
                baseCalculoTaxaJurosList = updateBaseCalculoTaxaJurosDescription(action.payload.passivoOperacao.pagamentoPassivo.periodicidadeFrequenciaJuros.id, action.payload.baseCalculoTaxaJurosList);
            }

            return {
                ...state,
                passivoFormTypes: {
                    ...action.payload,
                    baseCalculoTaxaJurosList
                },
            };
        case PASSIVO_CHANGE_BASE_CALCULO_DESCRIPTION:
            const newBaseCalculoTaxaJurosList = updateBaseCalculoTaxaJurosDescription(action.payload.id, state.passivoFormTypes.baseCalculoTaxaJurosList);

            return {
                ...state,
                passivoFormTypes: {
                    ...state.passivoFormTypes,
                    baseCalculoTaxaJurosList: newBaseCalculoTaxaJurosList
                }
            }            
        case PASSIVO_INSERT_NOME_PASTA_CEDOC:
            return {
                ...state,
                showInserirNomePastaCedocDialog: action.payload
            }
        case PASSIVO_CHANGE_DATA_EMISSAO:
            return {
                ...state,
                dataEmissaoSerie: action.payload
            }
        case LOAD_FORM_PASSIVO_MOEDA:
            return {
                ...state,
                moedaDinamica: action.payload
            }
        case LOAD_CLASSES_FUNDO:
            const { classes, classesLoaded } = action.payload;

            return {
                ...state,
                passivoFormTypes: {
                    ...state.passivoFormTypes,
                    classesFundoList: classes,
                    classesLoaded
                }
            }
        case LOAD_SUBCLASSES_FUNDO:
            const { subclasses, subclassesLoaded } = action.payload;

            return {
                ...state,
                passivoFormTypes: {
                    ...state.passivoFormTypes,
                    subclassesFundoList: subclasses,
                    subclassesLoaded
                }
            }
        case UPDATE_RATING_LIST_REGISTRO:
            return {
                ...state,
                passivoFormTypes: {
                    ...state.passivoFormTypes,
                    ratingList: action.payload
                }
            }
        default:
            return state;
    }
}

function updateBaseCalculoTaxaJurosDescription (frequenciaJuros, baseCalculoList) {
    const newDescription = frequenciaJuros === ID_FREQUENCIA_MENSAL ?
                'Meses Corridos: 30/360' :
                'Meses Corridos: nº meses x 30/360';

    return baseCalculoList.map(element => {
        return element.key === ID_BASE_CALCULO_MESES_CORRIDOS_30_360 ?
            { 
                key: ID_BASE_CALCULO_MESES_CORRIDOS_30_360, 
                value: newDescription,
                raw: 'MesesCorridos_30_360'
            } :
            element;
    });
}