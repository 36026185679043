/* eslint eqeqeq: 0 */

export const validate = (values) => {
  const CRIAR = values.criarAssembleia
  const NOVA = values.novaConvocacao
  const ENCERRAR = values.encerrarAssembleia
  const CONFIRMAR = values.confirmarAssembleia
  const statusValue = values?.status?.key
  const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

  let errors = {
    patrimonioSeparado: !values.patrimonioSeparado ? "Preenchimento obrigatório" : null,
    operacoes: !values.operacoes?.length > 0 ? "Preenchimento obrigatório" : null,
    dataAssembleia: !values.dataAssembleia ? "Preenchimento obrigatório" : null,
    horarioAssembleia: !values.horarioAssembleia ? "Preenchimento obrigatório" : null,
    ordemConvocacao: !values.ordemConvocacao ? "Preenchimento obrigatório" : null,
    tipoAssembleia: !values.tipoAssembleia ? "Preenchimento obrigatório" : null,
    quorumInstalacao: !values.quorumInstalacao ? "Preenchimento obrigatório" : null,
    dataConvocacao: !values.dataConvocacao ? "Preenchimento obrigatório" : null,
    origemDemanda: !values.origemDemanda ? "Preenchimento obrigatório" : null,
    formatoPublicacao: !values.formatoPublicacao ? "Preenchimento obrigatório" : null,
    formatoPublicacao: !values.formatoPublicacao ? "Preenchimento obrigatório" : null,
    assembleiaComplemento: {
      descricao: !values.assembleiaComplemento?.descricao ? "Preenchimento obrigatório" : null,
    },
    // DOCUMENTOS
    documentosEdital: !values.documentosEdital?.length > 0 ? "Preenchimento obrigatório" : null,
    documentosProposta: !values.documentosProposta?.length
      ? "Preenchimento obrigatório"
      : !values.documentosProposta.some(doc => ["doc", "docx"].includes(doc.formato))
      ? "Arquivo DOC ou DOCX obrigatório"
      : !values.documentosProposta.some(doc => doc.formato === "pdf")
      ? "Arquivo PDF obrigatório"
      : null,
  };

  if (CRIAR) {
    errors = {
      ...errors,
      assembleiaComplemento: {
        ...errors?.assembleiaComplemento,
        linkEdital: !values.assembleiaComplemento?.linkEdital 
          ? "Preenchimento obrigatório" 
          : !urlPattern.test(values.assembleiaComplemento.linkEdital) 
          ? "URL inválida" 
          : null,
        linkProposta: !values.assembleiaComplemento?.linkProposta 
            ? "Preenchimento obrigatório" 
            : !urlPattern.test(values.assembleiaComplemento.linkProposta) 
            ? "URL inválida" 
            : null,
      }
    }
  }

  if (statusValue >= 3 && CONFIRMAR) {
    errors = {
      ...errors,
      documentosAta: !values.documentosAta?.length > 0 ? "Preenchimento obrigatório" : null,
      assembleiaComplemento: {
        ...errors?.assembleiaComplemento,
        linkAta: !values.assembleiaComplemento?.linkAta 
          ? "Preenchimento obrigatório" 
          : !urlPattern.test(values.assembleiaComplemento.linkAta) 
          ? "URL inválida" 
          : null,
      }
    }
  }

  if (NOVA || ENCERRAR) {
    errors = {
      ...errors,
      valorFaturamento: !values.valorFaturamento ? "Preenchimento obrigatório" : null,
    }
  }

  return errors;
};
