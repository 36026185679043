import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { editOpcao, loadFormOpcao } from "../../../../../../actions/precificacao/OpcaoAction";

import ContainerPage from "../../../../../common/container/ContainerPage";
import OpcaoForm from "./form/SeloForm";

class SeloUpdatePage extends Component {
  componentDidMount() {
    let idOpcao = this.props.match.params.idOpcao;
    this.props.loadFormOpcao(idOpcao, true, "selo");
  }

  render() {
    const { editOpcao } = this.props;

    return (
      <ContainerPage>
        <OpcaoForm
          title={"Editar - Selo"}
          readOnly={false}
          floatButtonVisible={true}
          onSubmit={(e) => {
            if (e.arquivo) {
              editOpcao({
                ...e,
                extensaoArquivo: e?.nomeArquivo?.split(".").pop(),
              }, "selo")
            }
          }}
        />
      </ContainerPage>
    );
  }
}

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormOpcao,
      editOpcao,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispacthToProps)(SeloUpdatePage));
