import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import { acoesPagamentoAF } from "../../../../../../actions/precificacao/PagamentoAction";

let ModalRevisarFechamento = (props) => {
  const { pagamento, close, open, acoesPagamentoAF } = props;

  return (
    <>
      <Dialog open={open} onClose={() => close()}>
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">Revisar Fechamento</p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-3 text-dark f-16 fw-400">
            <p className="mb-0">
              Você está prestes a revisar este fechamento. Com isso ele será
              aberto para edição{" "}
              <span className="font-weight-bold">
                mas não será enviado novamente para validação do Agente
                Fiduciário.
              </span>
            </p>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Continuar"}
              action={() => {
                let userLogged = JSON.parse(localStorage.getItem("userLogged"));
                let emailLogged = userLogged?.email;

                acoesPagamentoAF(
                  {
                    emRevisao: true,
                    id: pagamento?.id,
                    emailAprovacao: emailLogged,
                    idPagamentoPrecificacao: pagamento?.idPagamentoPrecificacao,
                    isExternal: true // geralmente usado apenas no AF externo (usado internamente apenas nessa exceção), pois ignora Comprovante e Data
                  },
                  "enviar"
                );

                close();
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      acoesPagamentoAF,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ModalRevisarFechamento);
