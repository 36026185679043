/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import CloudDownload from "@material-ui/icons/CloudDownload";

import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../common/table/TableEmptyData";
import PaginationRB from "../../../../../common/pagination/PaginationRB";
import If from "../../../../../common/If/If";

import {
  formatPercent,
  truncate,
  convertCurrencyNumber,
} from "../../../../../../utils/helpers/NumberHelper";
import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";

import {
  TAXA_KEY,
  paginacao,
  download,
} from "../../../../../../actions/precificacao/CalculadoraAction";

const CalculadoraList = (props) => {
  const tableHeader = (
    <tr>
      <th className="text-center fw-700 text-nowrap">Data Evento</th>
      <th className="text-center fw-700 text-nowrap">Juros</th>
      <th className="text-center fw-700 text-nowrap">Juros Pago</th>
      <th className="text-center fw-700 text-nowrap">Amortização</th>
      <th className="text-center fw-700 text-nowrap">Amex</th>
      <th className="text-center fw-700 text-nowrap">Dias Período</th>
      <th className="text-center fw-700 text-nowrap">Fator Desconto</th>
      <th className="text-center fw-700 text-nowrap">PMT-VP</th>
    </tr>
  );

  const emptyData =
    props.messages && props.messages.infos && props.messages.infos[0];

  const moedaSimbolo = props.data?.moeda?.simbolo || "R$";

  const tableContent = props.paggingInfo.hasItems ? (
    props.paggingInfo.items.map((item, index) => (
      <tr key={index}>
        <td className="text-center fw-400 text-nowrap">{getDateFormatedFromString(item.dataEvento)}</td>
        <td className="text-center fw-400 text-nowrap">{item.juros === 0 ? "0,00%" : formatPercent(item.juros)}</td>
        <td className="text-center fw-400 text-nowrap">
          {item.jurosPago === 0
            ? `${moedaSimbolo} 0,00`
            : convertCurrencyNumber(item.jurosPago, moedaSimbolo)}
        </td>
        <td className="text-center fw-400 text-nowrap">
          {item.amortizacao === 0
            ? `${moedaSimbolo} 0,00`
            : convertCurrencyNumber(item.amortizacao, moedaSimbolo)}
        </td>
        <td className="text-center fw-400 text-nowrap">
          {item.amortizacaoExtraordinaria === 0
            ? `${moedaSimbolo} 0,00`
            : convertCurrencyNumber(
                item.amortizacaoExtraordinaria,
                moedaSimbolo
              )}
        </td>
        <td className="text-center fw-400 text-nowrap">{item.diasPeriodo}</td>
        <td className="text-center fw-400 text-nowrap">
          {item.fatorDesconto === 0
            ? "0,00"
            : convertCurrencyNumber(item.fatorDesconto, false)}
        </td>
        <td className="text-center fw-400 text-nowrap">
          {item.pmtVp === 0
            ? `${moedaSimbolo} 0,00`
            : convertCurrencyNumber(item.pmtVp, moedaSimbolo)}
        </td>
      </tr>
    ))
  ) : (
    <TableEmptyData message={emptyData} />
  );

  const groupedData = (
    <div className="card bg-transparent border-0 px-4 shadow-none">
      <div className="card-body shadow-custom bg-white rounded-12 col-12">
        <div className="row px-0">
          <div className="col-lg-4">
            <span className="font-weight-bold">{props.labelTaxaPreco}:</span>
            <If test={props.labelTaxaPreco === "Taxa"}>
              <span className="ml-2">
                {formatPercent(props.data.taxaPreco) || "-"}
              </span>
            </If>
            <If test={props.labelTaxaPreco === "Preço Unitário"}>
              <span className="ml-2">
                {convertCurrencyNumber(props.data.taxaPreco, moedaSimbolo) ||
                  "-"}
              </span>
            </If>
          </div>
          <div className="col-lg-4">
            <span className="font-weight-bold">Duration:</span>
            <span className="ml-2">
              {props.data.duration ? truncate(props.data.duration, 2) : "-"}
            </span>
          </div>
          <div className="col-lg-4">
            <span className="font-weight-bold">
              Exportar para excel:
              <a
                role="button"
                className={`p-2 p-color-imp f-22 ${
                  !props.paggingInfo.hasItems ? " isDisabled" : ""
                }`}
                onClick={() =>
                  props.paggingInfo.hasItems
                    ? props.download(props.data, props.messages)
                    : undefined
                }
              >
                <CloudDownload />
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-100">
      <If test={props.paggingInfo.totalCount > 0}>
        {groupedData}

        <div className="px-4">
          <Table
            withoutHeader
            classes="shadow-custom rounded-12 col-12 mb-4 mt-0 pd-10 bg-white"
            totalItems={props.paggingInfo.totalCount}
            pagination={
              <PaginationRB
                pageNumber={props.paggingInfo.pageIndex - 1}
                itemsPerPage={props.paggingInfo.pageSize}
                totalItems={props.paggingInfo.totalCount}
                filter={props.data.eventoAgingList}
                fetchFunction={props.paginacao}
                pageRange={5}
              />
            }
          >
            <TableHeader>{tableHeader}</TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        </div>
      </If>
    </div>
  );
};

const selector = formValueSelector("calculadoraFilterForm");

const mapStateToProps = (state) => ({
  labelTaxaPreco: state.calculadoraReducer.labelTaxaPreco,
  data: state.calculadoraReducer.data,
  paggingInfo: state.calculadoraReducer.paggingInfo,
  messages: state.calculadoraReducer.messages,
  tipo: selector(state, "tipoRelatorio") || TAXA_KEY,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      download,
      paginacao,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(CalculadoraList);
