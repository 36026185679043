import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Close } from "@material-ui/icons";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";

export default (props) => {
  return (
    <div
      className={`form-group field-floating has-float-label ${
        props.outerClassName
      } ${props.readOnly && "disabled-field"}`}
    >
      <div className="input-group form-control">
        <div className="custom-file position-relative d-flex justify-content-center h-100">
          {!props.value && (
            <>
              <input
                type="file"
                {...props.input}
                value={props.value}
                readOnly={props.readOnly}
                onChange={props.onChange}
              />

              <div className="input-group-append pe-none d-flex align-items-center position-absolute w-100 h-100 d-flex justify-content-end">
                <div className="p-color-imp d-flex flex-row align-items-center brd-left-1 pl-3 pr-2 h-100 f-16">
                  <span className="mr-2">{props.buttonName || "Buscar"}</span>
                  <FontAwesomeIcon icon={faFileUpload} />
                </div>
              </div>
            </>
          )}

          <div className="position-absolute h-100 w-100 d-flex align-items-center justify-content-between row overflow-hidden">
            <span className={`col-10 overflow-hidden text-nowrap px-0 ${!props.value && 'opacity-50 f-14'}`}>
              {props.value?.name || props.placeholder || "Nenhum arquivo selecionado"}
            </span>
            {props.value && (
              <div className="col-2 d-flex justify-content-end pr-0">
                <Close role="button" onClick={props.removeFile} />
              </div>
            )}
          </div>
        </div>
      </div>
      <label className="control-label">
        {props.label}
        {props.required ? <span className="text-warning font-weight-bold ml-1">*</span> : ""}
      </label>
    </div>
  );
};
