import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { editOpcao, loadFormOpcao } from "../../../../../../actions/precificacao/OpcaoAction";

import ContainerPage from "../../../../../common/container/ContainerPage";
import BancosForm from "./form/BancosForm";

class BancosUpdatePage extends Component {
  componentDidMount() {
    let idOpcao = this.props.match.params.idOpcao;
    this.props.loadFormOpcao(idOpcao, true, "banco-externo");
  }

  render() {
    const { editOpcao } = this.props;

    return (
      <ContainerPage>
        <BancosForm
          title={"Editar - Bancos"}
          readOnly={false}
          floatButtonVisible={true}
          onSubmit={(e) => editOpcao(e, "banco-externo")}
        />
      </ContainerPage>
    );
  }
}

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormOpcao,
      editOpcao,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispacthToProps)(BancosUpdatePage));
