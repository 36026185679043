import React from "react";
import ReactTooltip from "react-tooltip";
import If from "../If/If";

export default (props) => {

  // let activateFocus = e => {
  //   e.target.parentNode.classList.add("has-float-label");
  // };

  // let activateBlur = e => {
  //   const element = e.target;
  //   element.parentNode.classList.remove("has-float-label");
  // };

  let blurFunction = (blur, e) => {
    // activateBlur(e);
    blur(e);
  }

  let classNameInput = (className) => {
    let name = 'form-control';

    if (className) {
      name += ' ' + className;
    }

    return name;
  }

  const handleChange = (e) => {
    if (!e || !e.target) return;
    const value = props.filter ? props.filter(e.target.value || e) : (e.target.value || e);
    props.input.onChange(value);
    if (props.onChange) props.onChange(e);
  };

  const changeHandler = props.type === 'date' ? props.input.onChange : handleChange;

  return (
    <div className={`form-group field-floating has-float-label ${props.outerClassName} ${props.readOnly && 'disabled-field'}`}>
      <input
        {...props.input}
        className={classNameInput(props.className)}
        id={"txtfield_" + props.inputId}
        type={props.type}
        readOnly={props.readOnly}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        max={props.max}
        mask={props.mask}
        onKeyUp={props.onKeyUp}
        onKeyDown={props.onKeyDown}
        onChange={changeHandler}
        onBlur={(e) => props.input.onBlur && blurFunction(props.input.onBlur, e)}
        // onFocus={activateFocus}
      />
      <If test={props.label}>
        <label
          className={`control-label ${props.classLabel}`}
          htmlFor={props.name}
          id={"lbl_" + props.inputId}
        >
          {props.label}
          {props.required ? <span className="text-warning font-weight-bold ml-1">*</span> : ""}
          {props.icon && props.tooltipMessage && (
            <>
              <i
                className={`fa fa-${props.icon} icon-margin-l-5`}
                aria-hidden="true"
                style={{color: props.iconColor, cursor: "default"}}
                data-tip={props.tooltipMessage}
                data-for={props.tooltipId || props.label}
              />
              <ReactTooltip
                noArrow
                effect="solid"
                className="px-2 py-1"
                globalEventOff="click"
                style={{ width: "100px" }}
                textColor="#333"
                backgroundColor="#fff"
                id={props.tooltipId || props.label}
              />
            </>
          )}
        </label>
      </If>
      {(props.meta && props.meta.error && props.meta.touched &&
        <p className="error-msg sp-error text-danger f-12 mb-0">{props.meta.error}</p>)
      }
      <span className="material-input" />
    </div>
  );
}
