import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { editOpcao, loadFormOpcao } from "../../../../../../actions/precificacao/OpcaoAction";

import ContainerPage from "../../../../../common/container/ContainerPage";
import NaturezaForm from "./form/NaturezaForm";

class NaturezaUpdatePage extends Component {
  componentDidMount() {
    let idOpcao = this.props.match.params.idOpcao;
    this.props.loadFormOpcao(idOpcao, true, "natureza");
  }

  render() {
    const { editOpcao } = this.props;

    return (
      <ContainerPage>
        <NaturezaForm
          title={"Editar - Natureza"}
          readOnly={false}
          floatButtonVisible={true}
          onSubmit={(e) => editOpcao(e, "natureza")}
        />
      </ContainerPage>
    );
  }
}

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormOpcao,
      editOpcao,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispacthToProps)(NaturezaUpdatePage));
