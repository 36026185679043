/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import {
  loadPagamentoForm,
  sairDaPagina,
} from "../../../../../../actions/precificacao/PagamentoAction";

import PagamentoFasePagamentoPage from "./PagamentoFasePagamentoPage";

function getSteps() {
  return ["Disponível", "Fechado", "Validado", "Lançado", "Agendado", "Pago"];
}

const styles = (theme) => ({
  parent: {
    width: "100%",
    marginTop: "0.5%",
    backgroundColor: "transparent",
  },
  mrButton: {
    marginRight: theme.spacing.unit,
  },
  button: {
    color: "#630A37",
    "&:disabled": {
      color: "#D0D0D0",
    },
  },
  buttons: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "1rem !important",
  },
  stepper: {
    backgroundColor: "transparent",
  },
  active: {},
  completed: {},
  customStepLabel: {
    "& [class*='MuiStepLabel-labelContainer']": {
      display: "flex",
      justifyContent: "center",
    },
    "& [class*='MuiTypography-root']": {
      width: "max-content",
      padding: "0 10px",
      borderRadius: "20px",
      // fontSize: "12px",
      // textTransform: "uppercase",
      "&[class*='MuiStepLabel']": {
        backgroundColor: "#B8B8B8",
        color: "#121212c9",
        fontWeight: 500,
      },
      "&[class*='MuiStepLabel-active']": {
        backgroundColor: "#FFC107",
      },
      "&[class*='MuiStepLabel-completed']": {
        backgroundColor: "#83BF74",
        color: "#ffffff",
        "&.revisao": {
          backgroundColor: "#cc0000",
        },
      },
    },
  },
});

function CustomStepIcon({ active, completed, classes }) {
  const emRevisao = classes?.completed?.includes("revisao");
  const cRevi = "#cc0000";
  const cComp = "#83BF74";
  const cActv = "#FFC107";
  const cDflt = "#888888";

  return emRevisao ? (
    <RadioButtonChecked style={{ color: cRevi }} />
  ) : completed ? (
    <CheckCircleOutline style={{ color: cComp }} />
  ) : active ? (
    <RadioButtonChecked style={{ color: cActv }} />
  ) : (
    <RadioButtonUnchecked style={{ color: cDflt }} />
  );
}

class PagamentoFasesPage extends React.Component {
  componentDidMount() {
    this.props.loadPagamentoForm(this.props.match.params.idPrecificacao);
  }

  render() {
    const { classes, etapasConcluidas, pagamento, ultimaEtapa } = this.props;
    let steps = getSteps();

    let concluidos = etapasConcluidas;

    const naoPago = ultimaEtapa?.id == 9;
    if (naoPago) {
      steps.pop();
      steps.push("Não Pago"); 
    }

    const emRevisao = ultimaEtapa?.id == 8;
    if (emRevisao) concluidos = [0, 1, 2];
    
    function checaTemPagamento() {
      return Object.keys(pagamento).length > 0;
    }

    return (
      <div className="mt-3">
        <div className={classes.parent}>
          {/* <p className="mb-2 mt-0 col text-right">
            <span className="badge badge-secondary px-2 py-1 p-bg-imp">
              {this.props.codigoOpea}
            </span>
          </p> */}
          {checaTemPagamento() && (
             <Stepper
                classes={{ root: classes.stepper }}
                activeStep={emRevisao ? null : (concluidos?.length - 1) || 0}
                alternativeLabel
              >
                {steps.map((step, index) => (
                  <Step
                    key={index}
                    completed={concluidos.some((et) => et === (index + 1))}
                  >
                    <StepLabel
                      completed={concluidos.some((et) => et === (index + 1))}
                      className={classes.customStepLabel}
                      StepIconComponent={CustomStepIcon}
                      classes={{
                        completed: `${classes.completed} ${emRevisao && (index + 1) == 2 && 'revisao'}`,
                      }}
                      StepIconProps={{
                        classes: {
                          completed: `${classes.completed} ${emRevisao && (index + 1) == 2 && 'revisao'}`,
                          active: classes.active,
                          disabled: classes.disabled,
                        },
                      }}
                    >
                      {step}
                    </StepLabel>
                  </Step>
                ))}
             </Stepper>
          )}
        </div>
        <div>
          <div>
            <PagamentoFasePagamentoPage  />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  idPrecificacao: state.pagamentoReducer.pagamentoFormTypes.idPrecificacao,
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
  statusEtapas: state.pagamentoReducer.statusEtapas,
  codigoOpea: state.pagamentoReducer.pagamentoFormTypes.codigoOpea,
  etapasConcluidas: state.pagamentoReducer.pagamentoFormTypes.etapasConcluidas || [],
  ultimaEtapa: state.pagamentoReducer.pagamentoFormTypes.ultimaEtapa || null,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadPagamentoForm,
      sairDaPagina,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispacthToProps
  )(withStyles(styles)(PagamentoFasesPage))
);
