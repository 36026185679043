import React from "react";
import Tooltip from '@material-ui/core/Tooltip';

const CardValueDescription = (props) => {
    const {
        title, 
        description, 
        textTooltip,
    } = props;
    
    return (
        <Tooltip title={textTooltip || ""} placement="top">
            <div className="shadow-custom rounded-12 d-flex flex-column justify-content-between bg-white p-3 overflow-hidden h-100" data-tooltip={textTooltip}>
                <div className='header d-flex justify-content-between'>
                    <h1 className='title-card-value-description'>{title}</h1>
                </div>
                
                <div className='description-card-value'>
                    {description}
                </div>
            </div>
        </Tooltip> 
    )
}

export default CardValueDescription;
