import PropTypes from "prop-types";
import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import { components } from "react-select";
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ReactTooltip from "react-tooltip";

let timoutRequest = null;

const customStyles = {
  control: (base) => ({
    ...base,
    borderColor: "#B8B8B8 !important",
    boxShadow: "none !important",
    minHeight: "27px",
    backgroundColor:"transparent",
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft:"8px",
    paddingRight:"8px"
  })
}

const CustomCheckbox = withStyles({
  root: {
    color: '#bbbbbb',
    '&$checked': {
      color: '#630a37',
    },
    padding: 0,
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class GenericPickerInput extends Component {
  filterOptions(inputValue) {
    return (inputValue && this.props.options.filter(r => r.label));
  }

  promiseOptions(inputValue) {
    if(timoutRequest !== null) {
      clearTimeout(timoutRequest);
    }
    
    return new Promise(resolve => {
      timoutRequest = setTimeout(() => {
        if (inputValue && inputValue.trim().length > 0) {
          let term = inputValue ? inputValue.trim() : "";

          if (term.length < 3) {
            resolve([]);
            return;
          }

          this.props.loadData(term, this.props.reducerName).then(data => {
            if (data.payload) {
              resolve(this.filterOptions(term));
            }
          });
        }
      }, 2000);
    });
  }

  loadingMessage() {
    return this.props.loadingMessage || "Carregando...";
  }

  noOptionsMessage() {
    return this.props.noOptionsMessage || "Nenhum registro encontrado";
  }

  handleSelectedOptionsChange(newValue) {
    if (this.props.input) {
      this.props.input.onChange(newValue);
    } else {
      this.props.selectedOptionForm(newValue);
    }
    if (typeof this.props.nextSelectHandler === 'function') {
      this.props.nextSelectHandler();
    }
  }

  findParent (el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  }

  constructor(props) {
    super(props);
    this.state = {
      closeMenuOnSelect: props.closeMenuOnSelect ?? true
    };
  }

  handleCheckboxClick = () => {
    this.setState(prevState => ({ closeMenuOnSelect: !prevState.closeMenuOnSelect }));
  };

  render() {
    const { 
      label,
      inputId,
      fieldName,
      required,
      activeClassName,
      isMulti,
      placeholder,
      value,
      defaultValue,
      closeMenuOnSelect: propsCloseMenuOnSelect,
      multiControl,
      readOnly,
      isClearable,
      input,
      meta = {}
    } = this.props;

    const closeMenuOnSelect = propsCloseMenuOnSelect ?? this.state.closeMenuOnSelect;

    const Control = ({ children, ...props }) => (
      <components.Control {...props}>
        {multiControl && (
          <>
            <CustomCheckbox 
              className={`ml-2`}
              checked={!closeMenuOnSelect}
              data-for="msg-check"
              onChange={this.handleCheckboxClick}
              data-tip={`${!closeMenuOnSelect ? 'Desabilitar' : 'Habilitar'} seleção múltipla`}
            />
            <ReactTooltip
              type="opea"
              effect="solid"
              id="msg-check"
              globalEventOff="click"
            />
          </>
        )}
        {children}
      </components.Control>
    );
    
    return (
      <div>
        {label && (
          <label id={"lbl_" + inputId} htmlFor={inputId}>
            {fieldName}
            {required && <span>*</span>}:
          </label>
        )}
        <AsyncSelect
          className={`mb-0 ${activeClassName || ""}`}
          isMulti={isMulti}
          defaultOptions={(closeMenuOnSelect == undefined || closeMenuOnSelect) ? null : this.props.options}
          loadOptions={this.promiseOptions.bind(this)}
          onChange={this.handleSelectedOptionsChange.bind(this)}
          loadingMessage={() => this.loadingMessage()}
          noOptionsMessage={() => this.noOptionsMessage()}
          placeholder={placeholder}
          value={input ? input.value : value}
          defaultValue={defaultValue}
          styles={customStyles}
          cacheOptions={false}
          components={{ LoadingIndicator: null, Control }}
          closeMenuOnSelect={closeMenuOnSelect}
          isDisabled={readOnly}
          isClearable={isClearable}
        />
        {meta && meta.error && meta.touched && (
          <p className="error-msg sp-error text-danger f-12 mb-0">{meta.error}</p>
        )}
      </div>
    );
  }
}

GenericPickerInput.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  inputId: PropTypes.string,
  inputName: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  loadData: PropTypes.func.isRequired,
  loadingMessage: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  selectedOptionForm: PropTypes.func,
  reducerName: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object
};

GenericPickerInput.defaultProps = {
  readOnly: false,
  required: false,
  inputId: null
};

export default GenericPickerInput;
