/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field } from "redux-form";
import MaskedInput from "../../../../../common/input/MaskedInput";
import { CURRENCY } from "../../../../../../utils/helpers/StringHelper";
import { zeraDependentesOnChange } from "../../../../../../actions/relatorios/regulatorioAction";

const Derivativos = (props) => {
  const { readOnly, derivativo, zeraDependentesOnChange } = props;

  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    
    if (!derivativo) {
      zeraDependentesOnChange([
        "derivativo.mercadoATermoJuros",
        "derivativo.mercadoATermoCommodities",
        "derivativo.mercadoATermoCambio",
        "derivativo.mercadoATermoOutros",
        "derivativo.futurosJuros",
        "derivativo.futurosCommodities",
        "derivativo.futurosCambio",
        "derivativo.futurosOutros",
        "derivativo.opcoesJuros",
        "derivativo.opcoesCommodities",
        "derivativo.opcoesCambio",
        "derivativo.opcoesOutros",
        "derivativo.swapJuros",
        "derivativo.swapCommodities",
        "derivativo.swapCambio",
        "derivativo.swapOutros",
      ])
    }
  }, [derivativo])

  const arrDerivativos = [
    { num: "13.1", label: "Mercado a Termo" },
    { num: "13.1.1", label: "Juros", id: "derivativo.mercadoATermoJuros", readOnly: readOnly },
    { num: "13.1.2", label: "Commodities", id: "derivativo.mercadoATermoCommodities", readOnly: readOnly },
    { num: "13.1.3", label: "Câmbio", id: "derivativo.mercadoATermoCambio", readOnly: readOnly },
    { num: "13.1.4", label: "Outros", id: "derivativo.mercadoATermoOutros", readOnly: readOnly },
    { num: "13.2", label: "Futuros" },
    { num: "13.2.1", label: "Juros", id: "derivativo.futurosJuros", readOnly: readOnly },
    { num: "13.2.2", label: "Commodities", id: "derivativo.futurosCommodities", readOnly: readOnly },
    { num: "13.2.3", label: "Câmbio", id: "derivativo.futurosCambio", readOnly: readOnly },
    { num: "13.2.4", label: "Outros", id: "derivativo.futurosOutros", readOnly: readOnly },
    { num: "13.3", label: "Opções" },
    { num: "13.3.1", label: "Juros", id: "derivativo.opcoesJuros", readOnly: readOnly },
    { num: "13.3.2", label: "Commodities", id: "derivativo.opcoesCommodities", readOnly: readOnly },
    { num: "13.3.3", label: "Câmbio", id: "derivativo.opcoesCambio", readOnly: readOnly },
    { num: "13.3.4", label: "Outros", id: "derivativo.opcoesOutros", readOnly: readOnly },
    { num: "13.4", label: "Swap" },
    { num: "13.4.1", label: "Juros", id: "derivativo.swapJuros", readOnly: readOnly },
    { num: "13.4.2", label: "Commodities", id: "derivativo.swapCommodities", readOnly: readOnly },
    { num: "13.4.3", label: "Câmbio", id: "derivativo.swapCambio", readOnly: readOnly },
    { num: "13.4.4", label: "Outros", id: "derivativo.swapOutros", readOnly: readOnly }
  ]

  const fields = (arr) => {
    return arr.map(({ num, label, id, readOnly }) =>
      id ? (
        <div className="row d-flex align-items-center my-lg-2 mb-4 mt-2" key={id}>
          <div className="col-1 mt-lg-2 mt-0 fw-400">{num}</div>
          <div className="col-lg-3 col-11 text-lg-left text-center mt-lg-2 mt-0 fw-400 px-2 pr-lg-2 pr-5">{label}</div>
          <div className="col-lg col-12">
            <Field
              component={MaskedInput}
              outerClass="m-0"
              inputId={id}
              name={id}
              readOnly={readOnly}
              prefix="R$ "
              placeholder={CURRENCY.placeholder}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
            />
          </div>
        </div>
      ) : (
        <div className="row d-flex align-items-center my-2 py-1" key={num}>
          <div className="col-1 mt-lg-2 mt-0 fw-400">{num}</div>
          <div className="col-lg-3 col-11 text-lg-left text-center mt-lg-2 mt-0 fw-400 px-2 pr-lg-2 pr-5">{label}</div>
        </div>
      )
    );
  }

  return (
    <div className="container-fluid px-0">
      <div className="column shadow-custom bg-white rounded-12 col-12 px-4 pt-1 pb-3">
        <p className="h3 page-head__title my-3 mb-0">13. Informações sobre Derivativos</p>

        {fields(arrDerivativos)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispacthToProps = (dispatch) => bindActionCreators({
  zeraDependentesOnChange
}, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(Derivativos);
