/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { Field, formValueSelector, arrayInsert, arrayRemove } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getBooleanValueFromString } from "./../../../../../../../utils/helpers/BooleanHelper";
import { getArrayNumberRange } from "./../../../../../../../utils/helpers/ArrayHelper";

import If from "../../../../../../common/If/If";
import Input from "../../../../../../common/input/Input";
import MaskedInput from "../../../../../../common/input/MaskedInput";
import DropdownListInput from "../../../../../../common/input/DropdownListInput";
import SimpleMaskedInput from "../../../../../../common/input/SimpleMaskedInput";
import CheckboxInput from "../../../../../../common/input/CheckboxInput";

import PrecificacaoPrecisaoPrefixado from "./PrecificacaoPrecisaoPrefixado";
import PrecificacaoPrecisaoFlutuante from "./PrecificacaoPrecisaoFlutuante";
import PrecificacaoPrecisaoCorrecaoMonetaria from "./PrecificacaoPrecisaoCorrecaoMonetaria";

import {
  cleanPrecificacaoPrecisaoList,
  changeDescriptionBaseCalculoMesesCorridos,
  onChangeMesAtualizacaoMonentaria,
  changeDataInicioJuros,
  mudaPeriodicidadeCorrecao,
} from "./../../../../../../../actions/operacao/PassivoAction";
import Button from "../../../../../../common/button/Button";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const TIPO_PAGAMENTO_PREFIXADO = 1;
const TIPO_PAGAMENTO_PREFIXADO_SIMPLES = 4;
const TIPO_PAGAMENTO_FLUTUANTE = 2;
const TIPO_PAGAMENTO_CORRECAO_MONETARIA = 3;

const FREQUENCIA_JUROS_MESAL = 1;

const INDEXADOR_CORRECAO_MONETARIA = "TR";

const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");

class PassivoFormPagamentos extends Component {
  addTaxaPagamento(index) {
    if (!this.props.readOnly) {
      if (this.props.taxaJurosPagamentoList.length === 0) {
        this.props.arrayInsert(
          "passivoForm",
          "pagamentoPassivo.taxaJurosPagamentoList",
          0,
          { dataInicioTaxaJuros: this.props.dataEmissaoSerie }
        );
      } else {
        this.props.arrayInsert(
          "passivoForm",
          "pagamentoPassivo.taxaJurosPagamentoList",
          index,
          {}
        );
      }
    }
  }

  removeTaxaPagamento(index) {
    if (!this.props.readOnly && this.props.taxaJurosPagamentoList.length > 1) {
      this.props.arrayRemove(
        "passivoForm",
        "pagamentoPassivo.taxaJurosPagamentoList",
        index
      );
    }
  }

  render() {
    const {
      readOnly,
      tipoPagamentoPassivo,
      taxaJurosPagamentoList,
      pagamentoPassivo,
      tipoPagamentoList,
      periodicidadeFrequenciaJurosList,
      periodicidadeFrequenciaAmortizacaoList,
      baseCalculoTaxaJurosList,
      tipoTaxaProjetadaList,
      tipoIncrementoDiasList,
      indexadorCorrecaoMonetariaList,
      taxaPosFixadaList,
      basePagamentoRemuneracaoList,
      baseCalculoTaxaPosFixadaList,
      baseCalculoIndexadorList,
      periodicidadeCorrecaoMonetariaList,
      mesList,
      onChangeMesAtualizacaoMonentaria,
      formaCalculoList,
      pagamentoFormatoSlaList,
      inicioJurosList,
      mudaPeriodicidadeCorrecao,
      gestora,
    } = this.props;

    const exibirDiaReferenciaAtualizacao =
      tipoPagamentoPassivo &&
      tipoPagamentoPassivo.key === TIPO_PAGAMENTO_CORRECAO_MONETARIA &&
      pagamentoPassivo.periodicidadeFrequenciaJuros &&
      pagamentoPassivo.periodicidadeFrequenciaJuros.id !==
        FREQUENCIA_JUROS_MESAL;

    let acessosUser = JSON.parse(localStorage.getItem("userLogged")).acessos;
    let permiteSLA = acessosUser.some((acesso) => acesso === 3 || acesso === 1);

    let passivoControleSLA = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Controle de SLA</span>
          <div className="row align-items-center px-3 mt-3 fw-400">
            <span>Disponibilizar evento</span>
            <div className="mmw-45px mx-2">
              <Field
                component={SimpleMaskedInput}
                className="brd-1 m-0 h-38 pd-input-df text-center"
                id="pagamentoPassivo.pagamentoPassivoSla.prazoDisponibilizarEvento"
                name="pagamentoPassivo.pagamentoPassivoSla.prazoDisponibilizarEvento"
                readOnly={readOnly || !permiteSLA}
                allowNegative={false}
                decimalScale={0}
                maxLength={2}
              />
            </div>
            <span>Dia(s) úteis antes do vencimento.</span>
          </div>

          <div className="mt-3 fw-400">
            <p>Tempo máximo que um processo pode ficar em uma etapa.</p>
            <p className="mb-0">SLA por Status</p>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="align-items-center d-flex flex-row mt-3">
                <div className="mw-100px">
                  <span className="chip-pgmt pdt-2 sts-2 h-controle-sla-chip w-mx-cnt px-2 py-0 d-flex align-items-center rounded-pill f-12">
                    Disponíveis
                  </span>
                </div>
                <div className="mmw-45px">
                  <Field
                    component={SimpleMaskedInput}
                    className="brd-1 m-0 h-38 pd-input-df text-center"
                    id="pagamentoPassivo.pagamentoPassivoSla.prazoStatusDisponivel"
                    name="pagamentoPassivo.pagamentoPassivoSla.prazoStatusDisponivel"
                    readOnly={readOnly || !permiteSLA}
                    allowNegative={false}
                    decimalScale={0}
                    maxLength={2}
                  />
                </div>
                <div className="col pr-0">
                  <Field
                    component={DropdownListInput}
                    outerClassName={`m-0 ${
                      (readOnly || !permiteSLA) && "mt-2"
                    }`}
                    inputId="pagamentoPassivo.pagamentoPassivoSla.formatoStatusDisponivel"
                    name="pagamentoPassivo.pagamentoPassivoSla.formatoStatusDisponivel"
                    label=""
                    options={pagamentoFormatoSlaList}
                    textField="value"
                    valueField="key"
                    filter="contains"
                    readOnly={readOnly || !permiteSLA}
                  />
                </div>
              </div>
              <div className="align-items-center d-flex flex-row mt-3">
                <div className="mw-100px">
                  <span className="chip-pgmt pdt-2 sts-3 h-controle-sla-chip w-mx-cnt px-2 py-0 d-flex align-items-center rounded-pill f-12">
                    Fechados
                  </span>
                </div>
                <div className="mmw-45px">
                  <Field
                    component={SimpleMaskedInput}
                    className="brd-1 m-0 h-38 pd-input-df text-center"
                    id="pagamentoPassivo.pagamentoPassivoSla.prazoStatusFechado"
                    name="pagamentoPassivo.pagamentoPassivoSla.prazoStatusFechado"
                    readOnly={readOnly || !permiteSLA}
                    allowNegative={false}
                    decimalScale={0}
                    maxLength={2}
                  />
                </div>
                <div className="col pr-0">
                  <Field
                    component={DropdownListInput}
                    outerClassName={`m-0 ${
                      (readOnly || !permiteSLA) && "mt-2"
                    }`}
                    inputId="pagamentoPassivo.pagamentoPassivoSla.formatoStatusFechado"
                    name="pagamentoPassivo.pagamentoPassivoSla.formatoStatusFechado"
                    label=""
                    options={pagamentoFormatoSlaList}
                    textField="value"
                    valueField="key"
                    filter="contains"
                    readOnly={readOnly || !permiteSLA}
                  />
                </div>
              </div>
              <div className="align-items-center d-flex flex-row mt-3">
                <div className="mw-100px">
                  <span className="chip-pgmt pdt-2 sts-8 h-controle-sla-chip w-mx-cnt px-2 py-0 d-flex align-items-center rounded-pill f-12">
                    Revisados
                  </span>
                </div>
                <div className="mmw-45px">
                  <Field
                    component={SimpleMaskedInput}
                    className="brd-1 m-0 h-38 pd-input-df text-center"
                    id="pagamentoPassivo.pagamentoPassivoSla.prazoStatusRevisado"
                    name="pagamentoPassivo.pagamentoPassivoSla.prazoStatusRevisado"
                    readOnly={readOnly || !permiteSLA}
                    allowNegative={false}
                    decimalScale={0}
                    maxLength={2}
                  />
                </div>
                <div className="col pr-0">
                  <Field
                    component={DropdownListInput}
                    outerClassName={`m-0 ${
                      (readOnly || !permiteSLA) && "mt-2"
                    }`}
                    inputId="pagamentoPassivo.pagamentoPassivoSla.formatoStatusRevisado"
                    name="pagamentoPassivo.pagamentoPassivoSla.formatoStatusRevisado"
                    label=""
                    options={pagamentoFormatoSlaList}
                    textField="value"
                    valueField="key"
                    filter="contains"
                    readOnly={readOnly || !permiteSLA}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="align-items-center d-flex flex-row mt-3">
                <div className="mw-100px">
                  <span className="chip-pgmt pdt-2 sts-4 h-controle-sla-chip w-mx-cnt px-2 py-0 d-flex align-items-center rounded-pill f-12">
                    Validados
                  </span>
                </div>
                <div className="mmw-45px">
                  <Field
                    component={SimpleMaskedInput}
                    className="brd-1 m-0 h-38 pd-input-df text-center"
                    id="pagamentoPassivo.pagamentoPassivoSla.prazoStatusValidado"
                    name="pagamentoPassivo.pagamentoPassivoSla.prazoStatusValidado"
                    readOnly={readOnly || !permiteSLA}
                    allowNegative={false}
                    decimalScale={0}
                    maxLength={2}
                  />
                </div>
                <div className="col pr-0">
                  <Field
                    component={DropdownListInput}
                    outerClassName={`m-0 ${
                      (readOnly || !permiteSLA) && "mt-2"
                    }`}
                    inputId="pagamentoPassivo.pagamentoPassivoSla.formatoStatusValidado"
                    name="pagamentoPassivo.pagamentoPassivoSla.formatoStatusValidado"
                    label=""
                    options={pagamentoFormatoSlaList}
                    textField="value"
                    valueField="key"
                    filter="contains"
                    readOnly={readOnly || !permiteSLA}
                  />
                </div>
              </div>
              <div className="align-items-center d-flex flex-row mt-3">
                <div className="mw-100px">
                  <span className="chip-pgmt pdt-2 sts-5 h-controle-sla-chip w-mx-cnt px-2 py-0 d-flex align-items-center rounded-pill f-12">
                    Lançados
                  </span>
                </div>
                <div className="mmw-45px">
                  <Field
                    component={SimpleMaskedInput}
                    className="brd-1 m-0 h-38 pd-input-df text-center"
                    id="pagamentoPassivo.pagamentoPassivoSla.prazoStatusLancado"
                    name="pagamentoPassivo.pagamentoPassivoSla.prazoStatusLancado"
                    readOnly={readOnly || !permiteSLA}
                    allowNegative={false}
                    decimalScale={0}
                    maxLength={2}
                  />
                </div>
                <div className="col pr-0">
                  <Field
                    component={DropdownListInput}
                    outerClassName={`m-0 ${
                      (readOnly || !permiteSLA) && "mt-2"
                    }`}
                    inputId="pagamentoPassivo.pagamentoPassivoSla.formatoStatusLancado"
                    name="pagamentoPassivo.pagamentoPassivoSla.formatoStatusLancado"
                    label=""
                    options={pagamentoFormatoSlaList}
                    textField="value"
                    valueField="key"
                    filter="contains"
                    readOnly={readOnly || !permiteSLA}
                  />
                </div>
              </div>
              <div className="align-items-center d-flex flex-row mt-3">
                <div className="mw-100px">
                  <span className="chip-pgmt pdt-2 sts-6 h-controle-sla-chip w-mx-cnt px-2 py-0 d-flex align-items-center rounded-pill f-12">
                    Agendados
                  </span>
                </div>
                <div className="mmw-45px">
                  <Field
                    component={SimpleMaskedInput}
                    className="brd-1 m-0 h-38 pd-input-df text-center"
                    id="pagamentoPassivo.pagamentoPassivoSla.prazoStatusAgendado"
                    name="pagamentoPassivo.pagamentoPassivoSla.prazoStatusAgendado"
                    readOnly={readOnly || !permiteSLA}
                    allowNegative={false}
                    decimalScale={0}
                    maxLength={2}
                  />
                </div>
                <div className="col pr-0">
                  <Field
                    component={DropdownListInput}
                    outerClassName={`m-0 ${
                      (readOnly || !permiteSLA) && "mt-2"
                    }`}
                    inputId="pagamentoPassivo.pagamentoPassivoSla.formatoStatusAgendado"
                    name="pagamentoPassivo.pagamentoPassivoSla.formatoStatusAgendado"
                    label=""
                    options={pagamentoFormatoSlaList}
                    textField="value"
                    valueField="key"
                    filter="contains"
                    readOnly={readOnly || !permiteSLA}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let passivoPagamentoGeral = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">
            Controle de Pagamentos
          </span>
          <div className="row">
            {!gestora && (
              <div className="col-12 col-lg-3">
                <Field
                  component={DropdownListInput}
                  inputId="formaCalculo"
                  name="formaCalculo"
                  label="Forma de Cálculo"
                  options={formaCalculoList}
                  textField="value"
                  valueField="key"
                  filter="contains"
                  readOnly={readOnly}
                />
              </div>
            )}
            
            <div className="col-12 col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="tipoPagamento"
                name="pagamentoPassivo.tipoPagamentoPassivo"
                label="Tipo Pagamento"
                options={tipoPagamentoList}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                filter="contains"
                onChange={(v) => this.props.cleanPrecificacaoPrecisaoList(v)}
              />
            </div>

            <div className="col-12 col-lg-3">
              <Field
                component={Input}
                inputId="dataBasePrimeiroAniversario"
                name="pagamentoPassivo.dataBasePrimeiroAniversario"
                type="date"
                label="Data Base Primeiro DCT/DUT"
                readOnly={readOnly}
              />
            </div>

            <div className="col-12 col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="inicioJuros"
                name={"pagamentoPassivo.inicioJuros"}
                label="Inicio de Juros"
                options={inicioJurosList}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                filter="contains"
                onChange={(e) => this.props.changeDataInicioJuros(e.key)}
              />
            </div>
            <div className="col-12 col-lg-3">
              <Field
                component={Input}
                inputId="inicioJurosRemuneracao"
                name={"pagamentoPassivo.inicioJurosRemuneracao"}
                type="date"
                classLabel="text-nowrap"
                label="Data Início Juros/Remuneração (Exclusive) "
                readOnly={readOnly}
                required
              />
            </div>

            <If
              test={
                tipoPagamentoPassivo &&
                tipoPagamentoPassivo.key === TIPO_PAGAMENTO_CORRECAO_MONETARIA
              }
            >
              <div className="col-12 col-lg-3">
                <Field
                  component={DropdownListInput}
                  inputId="possuiResiduoInflacionario"
                  name="pagamentoPassivo.possuiResiduoInflacionario"
                  label="Possui Resíduo Inflacionário"
                  readOnly={readOnly}
                />
              </div>
            </If>

            <div className="col-12 col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="periodicidadeFrequenciaJuros"
                name="pagamentoPassivo.periodicidadeFrequenciaJuros"
                label="Frequência Juros"
                options={periodicidadeFrequenciaJurosList}
                textField="descricao"
                valueField="id"
                readOnly={readOnly}
                filter="contains"
                onChange={(element) =>
                  this.props.changeDescriptionBaseCalculoMesesCorridos(element)
                }
              />
            </div>

            <div className="col-12 col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="carenciaJuros"
                name="pagamentoPassivo.carenciaJuros"
                label="Carência de Juros/Remuneração"
                readOnly={readOnly}
              />
            </div>

            <If
              test={
                pagamentoPassivo &&
                pagamentoPassivo.carenciaJuros &&
                getBooleanValueFromString(pagamentoPassivo.carenciaJuros)
              }
            >
              <div className="col-12 col-lg-3">
                <Field
                  component={Input}
                  inputId="dataCarencia"
                  name="pagamentoPassivo.dataCarencia"
                  type="date"
                  label="Data Carência"
                  readOnly={readOnly}
                />
              </div>
            </If>

            <div className="col-12 col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="periodicidadeFrequenciaAmortizacao"
                name="pagamentoPassivo.periodicidadeFrequenciaAmortizacao"
                label="Frequência de Amortizações"
                options={periodicidadeFrequenciaAmortizacaoList}
                textField="descricao"
                valueField="id"
                readOnly={readOnly}
                filter="contains"
              />
            </div>

            <If test={exibirDiaReferenciaAtualizacao}>
              <div className="col-12 col-lg-3">
                <Field
                  component={Input}
                  inputId="dataPrimeiraAtualizacao"
                  name="pagamentoPassivo.dataPrimeiraAtualizacao"
                  type="date"
                  label="Data da Primeira Atualização"
                  readOnly={readOnly}
                />
              </div>

              <div className="col-12 col-lg-3">
                <Field
                  component={DropdownListInput}
                  inputId="tipoIncrementoDias"
                  name="pagamentoPassivo.tipoIncrementoDias"
                  label="Modalidade no Tipo de Incremento"
                  options={tipoIncrementoDiasList}
                  textField="value"
                  valueField="key"
                  readOnly={readOnly}
                  filter="contains"
                />
              </div>

              <div className="col-12 col-lg-3 d-flex flex-column mt-1px">
                <label className="control-label mt-1 pt-1 txt-grey">Dia Referência Atualização / Incremento (Dias)</label>
                <div className="row mt-2p py-0">
                  <div className="col pr-1">
                    <Field
                      component={DropdownListInput}
                      id="diaReferenciaAtualizacao"
                      outerClassName="m-0"
                      name={"pagamentoPassivo.diaReferenciaAtualizacao"}
                      // label="Modalidade no Tipo de Incremento"
                      options={getArrayNumberRange(1, 30)}
                      textField="textField"
                      valueField="valueField"
                      readOnly={readOnly}
                    />
                  </div>
                  <span className="input-group-text bg-transparent border-0 col-auto p-0">
                    <i className="material-icons center">add</i>
                  </span>
                  <div className="col pl-1">
                    <Field
                      component={DropdownListInput}
                      id="incrementoDias"
                      outerClassName="m-0"
                      name={"pagamentoPassivo.incrementoDias"}
                      options={getArrayNumberRange(0, 99)}
                      textField="textField"
                      valueField="valueField"
                      readOnly={readOnly}
                    />
                  </div>
                </div>
              </div>
            </If>

            <If
              test={
                !gestora &&
                tipoPagamentoPassivo &&
                tipoPagamentoPassivo.key === TIPO_PAGAMENTO_FLUTUANTE
              }
            >
              <div className="col-12 col-lg-3">
                <Field
                  component={DropdownListInput}
                  inputId="basePagamentoRemuneracao"
                  name={"pagamentoPassivo.basePagamentoRemuneracao"}
                  label="Base para Pagamento da Remuneração"
                  options={basePagamentoRemuneracaoList}
                  textField="value"
                  valueField="key"
                  readOnly={readOnly}
                  filter="contains"
                />
              </div>
            </If>
          </div>
        </div>
      </div>
    );

    function verificaMeses(periodo){
      switch (periodo.id || periodo.key) {
        case 1:
          return 12;
        case 2:
          return 6;
        case 3:
          return 4;
        case 4:
          return 2;
        case 5:
          return 1;
      }
    }

    let passivoPagamentoTaxaJuros = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Taxas de Pagamento</span>
          <div className="d-flex flex-column w-100">
            <div className="col more-fields p-0">
              <TransitionGroup>
                {taxaJurosPagamentoList &&
                  taxaJurosPagamentoList.map((item, index) => {
                    return (
                      <CSSTransition key={index} timeout={300} classNames="item">
                        <div key={index} className="more-fields-item">
                          <div className="d-flex">
                            <div className="row w-100">
                              <div className="col-12 col-lg-4">
                                <Field
                                  component={MaskedInput}
                                  inputId="taxaJurosPagamentoList"
                                  name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].taxaJuros`}
                                  label="Taxa Juros/Spread"
                                  readOnly={readOnly}
                                  suffix={"%"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  decimalScale={4}
                                  fixedDecimalScale
                                  placeholder={"0,0000%"}
                                />
                              </div>

                              <div className="col-12 col-lg-4">
                                <Field
                                  component={Input}
                                  inputId="dataInicio"
                                  name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].dataInicioTaxaJuros`}
                                  type="date"
                                  label="Data Vigência"
                                  readOnly={readOnly}
                                />
                              </div>

                              <div className="col-12 col-lg-4">
                                <Field
                                  component={DropdownListInput}
                                  inputId="baseCalculoTaxaJuros"
                                  name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].baseCalculoTaxaJuros`}
                                  label="Base de Cálculo do Spread"
                                  options={baseCalculoTaxaJurosList}
                                  textField="value"
                                  valueField="key"
                                  readOnly={readOnly}
                                  filter="contains"
                                />
                              </div>

                              <If
                                test={
                                  tipoPagamentoPassivo &&
                                  tipoPagamentoPassivo.key !==
                                    TIPO_PAGAMENTO_PREFIXADO &&
                                  tipoPagamentoPassivo.key !==
                                    TIPO_PAGAMENTO_PREFIXADO_SIMPLES
                                }
                              >
                                <div className="col-12 col-lg-4">
                                  <Field
                                    component={DropdownListInput}
                                    inputId="tipoTaxaProjetada"
                                    name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].tipoTaxaProjetada`}
                                    label="Tipo Taxa Projetada"
                                    options={tipoTaxaProjetadaList}
                                    textField="value"
                                    valueField="key"
                                    readOnly={readOnly}
                                    filter="contains"
                                  />
                                </div>
                              </If>

                              <If
                                test={
                                  tipoPagamentoPassivo &&
                                  tipoPagamentoPassivo.key ===
                                    TIPO_PAGAMENTO_FLUTUANTE
                                }
                              >
                                <div className="col-12 col-lg-4">
                                  <Field
                                    component={DropdownListInput}
                                    inputId="baseCalculoTaxaPosFixada"
                                    name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].baseCalculoTaxaPosFixada`}
                                    label="Base Cálculo Taxa Pós-Fixada"
                                    options={baseCalculoTaxaPosFixadaList}
                                    textField="value"
                                    valueField="key"
                                    readOnly={readOnly}
                                    filter="contains"
                                  />
                                </div>
                              </If>

                              <If
                                test={
                                  tipoPagamentoPassivo &&
                                  tipoPagamentoPassivo.key ===
                                    TIPO_PAGAMENTO_CORRECAO_MONETARIA
                                }
                              >
                                <div className="col-12 col-lg-4">
                                  <Field
                                    component={DropdownListInput}
                                    inputId="baseCalculoIndexador"
                                    name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].baseCalculoIndexador`}
                                    label="Base de Cálculo do Indexador"
                                    options={baseCalculoIndexadorList}
                                    textField="value"
                                    valueField="key"
                                    readOnly={readOnly}
                                    filter="contains"
                                  />
                                </div>
                              </If>

                              <If
                                test={
                                  tipoPagamentoPassivo &&
                                  tipoPagamentoPassivo.key ===
                                    TIPO_PAGAMENTO_CORRECAO_MONETARIA
                                }
                              >
                                <div className="col-12 col-lg-4">
                                  <Field
                                    component={DropdownListInput}
                                    inputId="indexadorCorrecaoMonetaria"
                                    name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].indexadorCorrecaoMonetaria`}
                                    label="Indexador de Correção Monetária"
                                    options={indexadorCorrecaoMonetariaList}
                                    textField="value"
                                    valueField="key"
                                    readOnly={readOnly}
                                    filter="contains"
                                  />
                                </div>

                                <If
                                  test={
                                    taxaJurosPagamentoList[index]
                                      .indexadorCorrecaoMonetaria &&
                                    taxaJurosPagamentoList[index]
                                      .indexadorCorrecaoMonetaria.value ===
                                      INDEXADOR_CORRECAO_MONETARIA
                                  }
                                >
                                  <div className="col-12 col-lg-4">
                                    <Field
                                      component={DropdownListInput}
                                      inputId="diaReferenciaTR"
                                      name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].diaReferenciaTR`}
                                      label="Dia TR"
                                      options={getArrayNumberRange(1, 31)}
                                      textField="textField"
                                      valueField="valueField"
                                      readOnly={readOnly}
                                    />
                                  </div>
                                </If>
                              </If>

                              <If
                                test={
                                  tipoPagamentoPassivo &&
                                  tipoPagamentoPassivo.key ===
                                    TIPO_PAGAMENTO_FLUTUANTE
                                }
                              >
                                <div className="col-12 col-lg-4">
                                  <Field
                                    component={DropdownListInput}
                                    inputId="taxaPosFixada"
                                    name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].taxaPosFixada`}
                                    label="Taxa Pós-Fixada"
                                    options={taxaPosFixadaList}
                                    textField="value"
                                    valueField="key"
                                    readOnly={readOnly}
                                    filter="contains"
                                  />
                                </div>

                                <div className="col-12 col-lg-4">
                                  <Field
                                    component={MaskedInput}
                                    inputId="percentualFlutuante"
                                    name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].percentualFlutuante`}
                                    label="% Flutuante"
                                    readOnly={readOnly}
                                    suffix={"%"}
                                    //format={() => teste()} TODO: Necessário limitar a quantidade de números de acordo com o banco de dados
                                    decimalSeparator={","}
                                    decimalScale={4}
                                    fixedDecimalScale
                                    placeholder={"0,0000%"}
                                  />
                                </div>
                              </If>

                              <If
                                test={
                                  tipoPagamentoPassivo &&
                                  tipoPagamentoPassivo.key !==
                                    TIPO_PAGAMENTO_PREFIXADO &&
                                  tipoPagamentoPassivo.key !==
                                    TIPO_PAGAMENTO_PREFIXADO_SIMPLES
                                }
                              >
                                <div className="col-12 col-lg-4">
                                  <Field
                                    component={DropdownListInput}
                                    inputId="defasagemTaxaIndexador"
                                    name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].defasagemTaxaIndexador`}
                                    label="Defasagem de Taxa/Indexador"
                                    options={getArrayNumberRange(0, 8)}
                                    textField="textField"
                                    valueField="valueField"
                                    readOnly={readOnly}
                                  />
                                </div>
                              </If>

                              <If
                                test={
                                  tipoPagamentoPassivo &&
                                  tipoPagamentoPassivo.key ===
                                    TIPO_PAGAMENTO_CORRECAO_MONETARIA
                                }
                              >
                                <div className="col-12 col-lg-4">
                                  <Field
                                    component={DropdownListInput}
                                    inputId="somenteVariacaoPositiva"
                                    name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].somenteVariacaoPositiva`}
                                    label="Somente Variação Positiva"
                                    readOnly={readOnly}
                                  />
                                </div>
                              </If>

                              <If
                                test={
                                  tipoPagamentoPassivo &&
                                  tipoPagamentoPassivo.key ===
                                    TIPO_PAGAMENTO_CORRECAO_MONETARIA
                                }
                              >
                                <div className="col-12 col-lg-4">
                                  <Field
                                    component={DropdownListInput}
                                    inputId="periodicidadeCorrecaoMonetaria"
                                    name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].periodicidadeCorrecaoMonetaria`}
                                    label="Periodicidade de Correção"
                                    onChange={(_props, v) => mudaPeriodicidadeCorrecao(index, v, mesList)}
                                    options={periodicidadeCorrecaoMonetariaList}
                                    textField="descricao"
                                    valueField="id"
                                    readOnly={readOnly}
                                    filter="contains"
                                  />
                                </div>
                              </If>

                              <If
                                test={
                                  tipoPagamentoPassivo &&
                                  // pagamentoPassivo.taxaJurosPagamentoList[index].periodicidadeCorrecaoMonetaria &&
                                  tipoPagamentoPassivo.key ===
                                    TIPO_PAGAMENTO_CORRECAO_MONETARIA
                                }
                              >
                                <div className="col-lg-12">
                                  Meses Atualização Monetária
                                  {pagamentoPassivo.taxaJurosPagamentoList[index].periodicidadeCorrecaoMonetaria && 
                                  (<span className="fw-500">{` (${verificaMeses(pagamentoPassivo.taxaJurosPagamentoList[index].periodicidadeCorrecaoMonetaria)} mes(es)) `}</span>)}:
                                </div>

                                <div className="group-check d-flex flex-wrap">
                                  {mesList?.map((mes, indexMes) => {
                                    if (mes.key !== 13) {
                                      let indexMesAtualizacao = `${index}_${mes.key}`;
                                      return (
                                        <div
                                        className="col-lg-3"
                                        key={indexMesAtualizacao}
                                        >
                                          <Field
                                            index={indexMesAtualizacao}
                                            component={CheckboxInput}
                                            inputId={indexMesAtualizacao}
                                            name={`pagamentoPassivo.taxaJurosPagamentoList[${index}].mesAtualizacaoMonentariaList[${indexMes}]`}
                                            disabled={readOnly}
                                            label={mes.value}
                                            onChange={(e) =>
                                              onChangeMesAtualizacaoMonentaria(
                                                e,
                                                index,
                                                indexMes,
                                                mes,
                                                pagamentoPassivo.taxaJurosPagamentoList[index].periodicidadeCorrecaoMonetaria,
                                                )
                                              }
                                              />
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </If>

                              <If test={!readOnly}>
                                <div className="col-12 col-lg-2 d-flex align-items-center minus-box-btn">
                                  <Button
                                    classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize"
                                    activeClassName={"col-lg-auto p-0"}
                                    buttonStyle={"success"}
                                    action={() => this.removeTaxaPagamento(index)}
                                    icon={"trash"}
                                    name={"Remover"}
                                  />
                                </div>
                              </If>
                            </div>
                          </div>
                        </div>
                      </CSSTransition>
                    );
                  })}
              </TransitionGroup>
            </div>
            <If test={!readOnly}>
              <div className="mx-auto mt-3">
                <Button
                  classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                  activeClassName={"col-lg-auto p-0"}
                  buttonStyle={"success"}
                  action={() =>
                    this.addTaxaPagamento(taxaJurosPagamentoList.length)
                  }
                  icon={"plus"}
                  name={"Adicionar"}
                />
              </div>
            </If>
          </div>
          <If test={taxaJurosPagamentoList.length === 0}>
            <div colSpan="999" className="text-center w-100 mt-3">
              <span>Não existe informações cadastradas.</span>
            </div>
          </If>
        </div>
      </div>
    );

    return (
      <div className="d-flex flex-column mt-4">
        {!gestora && passivoControleSLA}

        {passivoPagamentoGeral}

        {passivoPagamentoTaxaJuros}

        <If
          test={
            tipoPagamentoPassivo &&
            tipoPagamentoPassivo.key === TIPO_PAGAMENTO_PREFIXADO
          }
        >
          <PrecificacaoPrecisaoPrefixado
            tipoPagamento="Prefixado Juros Composto"
            readOnly={readOnly}
          />
        </If>

        <If
          test={
            tipoPagamentoPassivo &&
            tipoPagamentoPassivo.key === TIPO_PAGAMENTO_PREFIXADO_SIMPLES
          }
        >
          <PrecificacaoPrecisaoPrefixado
            tipoPagamento="Prefixado Juros Simples"
            readOnly={readOnly}
          />
        </If>

        <If
          test={
            tipoPagamentoPassivo &&
            tipoPagamentoPassivo.key === TIPO_PAGAMENTO_FLUTUANTE
          }
        >
          <PrecificacaoPrecisaoFlutuante
            tipoPagamento="Flutuantes"
            readOnly={readOnly}
          />
        </If>

        <If
          test={
            tipoPagamentoPassivo &&
            tipoPagamentoPassivo.key === TIPO_PAGAMENTO_CORRECAO_MONETARIA
          }
        >
          <PrecificacaoPrecisaoCorrecaoMonetaria
            tipoPagamento="Correção Monetária"
            readOnly={readOnly}
          />
        </If>
      </div>
    );
  }
}

const selector = formValueSelector("passivoForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      arrayInsert,
      arrayRemove,
      cleanPrecificacaoPrecisaoList,
      changeDescriptionBaseCalculoMesesCorridos,
      onChangeMesAtualizacaoMonentaria,
      changeDataInicioJuros,
      mudaPeriodicidadeCorrecao,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  pagamentoPassivo: selector(state, "pagamentoPassivo"),
  taxaJurosPagamentoList:
    selector(state, "pagamentoPassivo.taxaJurosPagamentoList") || [],
  tipoPagamentoPassivo: selector(
    state,
    "pagamentoPassivo.tipoPagamentoPassivo"
  ),
  tipoPagamentoList: state.passivoReducer.passivoFormTypes.tipoPagamentoList,
  periodicidadeFrequenciaJurosList:
    state.passivoReducer.passivoFormTypes.periodicidadeFrequenciaJurosList,
  diasReferenciaAtualizacaoList:
    state.passivoReducer.passivoFormTypes.diasReferenciaAtualizacaoList,
  periodicidadeFrequenciaAmortizacaoList:
    state.passivoReducer.passivoFormTypes
      .periodicidadeFrequenciaAmortizacaoList,
  baseCalculoTaxaJurosList:
    state.passivoReducer.passivoFormTypes.baseCalculoTaxaJurosList,
  tipoTaxaProjetadaList:
    state.passivoReducer.passivoFormTypes.tipoTaxaProjetadaList,
  tipoIncrementoDiasList:
    state.passivoReducer.passivoFormTypes.tipoIncrementoDiasList,
  indexadorCorrecaoMonetariaList:
    state.passivoReducer.passivoFormTypes.indexadorCorrecaoMonetariaList,
  taxaPosFixadaList: state.passivoReducer.passivoFormTypes.taxaPosFixadaList,
  basePagamentoRemuneracaoList:
    state.passivoReducer.passivoFormTypes.basePagamentoRemuneracaoList,
  inicioJurosList: state.passivoReducer.passivoFormTypes.inicioJurosList,
  baseCalculoTaxaPosFixadaList:
    state.passivoReducer.passivoFormTypes.baseCalculoTaxaPosFixadaList,
  baseCalculoIndexadorList:
    state.passivoReducer.passivoFormTypes.baseCalculoIndexadorList,
  periodicidadeCorrecaoMonetariaList:
    state.passivoReducer.passivoFormTypes.periodicidadeCorrecaoMonetariaList,
  mesList: state.passivoReducer.passivoFormTypes.mesList,
  formaCalculoList: state.passivoReducer.passivoFormTypes.formaCalculoList,
  inicioJuros: selector(state, "pagamentoPassivo.inicioJuros"),
  dataEmissaoSerie: state.passivoReducer.dataEmissaoSerie,
  pagamentoFormatoSlaList:
    state.passivoReducer.passivoFormTypes.pagamentoFormatoSlaList,
  gestora: currEnv == process.env.REACT_APP_ENV_GEST_ID,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PassivoFormPagamentos);
