import {
    LOAD_FILTER_TIPO
} from "../../actions/relatorios/analiticReportAction";

const INITIAL_STATE = {
    tiposFormTypes: {},
    messages: null,
}

export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case LOAD_FILTER_TIPO:
            return {
                ...state,
                tiposFormTypes: action.payload
            }
        default:
            return state;
    }
}