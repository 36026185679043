import {
  LOAD_FILTER_FORM_CONTAS_RECEBER,
  CONTAS_RECEBER_CONSULT_CURRENT,
  CONTAS_RECEBER_FETCHED,
  SALVA_FILTROS_CONTAS_RECEBER,
  LOAD_TELA_FLUXOS_CONTAS_RECEBER,
  CONTAS_RECEBER_RESET,
  SAVE_FORM_CONTAS_RECEBER_ERRORS,
  CHANGE_LIST_RECEITAS,
  CHANGE_LIST_FORNECEDORES,
  CHANGE_LIST_CONTA_ORIGEM,
  CHANGE_LIST_CONTA_DESTINO,
  CHANGE_LIST_OPERACOES,
  //
  INDICADORES_CONTAS_RECEBER_FETCHED,
} from "../../actions/contasReceber/ContasReceberAction";

const INITIAL_STATE = {
  messages: null,
  isLoading: false,
  contasReceberFormTypesFilter: {},
  contasReceberConsultCurrent: null,
  contasReceber: {
    lastPage: 0,
    totalCount: 0,
    hasItems: false,
    pageSize: 0,
    pageIndex: 0,
    items: [],
  },
  contasReceberFormTypes: {},
  //
  indicadores: null,
};

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOAD_FILTER_FORM_CONTAS_RECEBER:
      return {
        ...state,
        contasReceberFormTypesFilter: action.payload,
      };
    case CONTAS_RECEBER_CONSULT_CURRENT:
      return {
        ...state,
        contasReceberConsultCurrent: action.payload,
        isLoading: true,
      };
    case CONTAS_RECEBER_FETCHED:
      return {
        ...state,
        contasReceber: action.payload.content,
        messages: action.payload.messages,
        isLoading: true,
      };
    case SALVA_FILTROS_CONTAS_RECEBER:
      return {
        ...state,
        filtrosContasReceber: action.payload,
      };
    case LOAD_TELA_FLUXOS_CONTAS_RECEBER:
      return {
        ...state,
        contasReceberFormTypes: action.payload,
      };
    case SAVE_FORM_CONTAS_RECEBER_ERRORS:
      return {
        ...state,
        messages: action.payload
      }
    case CONTAS_RECEBER_RESET:
      return {
        ...INITIAL_STATE,
      };
    case CHANGE_LIST_RECEITAS:
      return {
        ...state,
        contasReceberFormTypes: {
          ...state.contasReceberFormTypes,
          receitaList: action.payload,
        },
      };
    case CHANGE_LIST_FORNECEDORES:
      return {
        ...state,
        contasReceberFormTypes: {
          ...state.contasReceberFormTypes,
          filteredFornecedorList: action.payload,
        },
      };
    case CHANGE_LIST_CONTA_ORIGEM:
      return {
        ...state,
        contasReceberFormTypes: {
          ...state.contasReceberFormTypes,
          contaOrigemList: action.payload,
        },
      };
    case CHANGE_LIST_CONTA_DESTINO:
      return {
        ...state,
        contasReceberFormTypes: {
          ...state.contasReceberFormTypes,
          contaDestinoList: action.payload,
        },
      };
    case CHANGE_LIST_OPERACOES:
      return {
        ...state,
        contasReceberFormTypes: {
          ...state.contasReceberFormTypes,
          serieList: action.payload,
        },
      };
    //

    case INDICADORES_CONTAS_RECEBER_FETCHED:
      return {
        ...state,
        indicadores: action.payload.content,
        isLoading: true,
      };

    default:
      return state;
  }
}
