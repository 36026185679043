import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import CheckBox from "@material-ui/icons/CheckBox";
import { formValueSelector } from "redux-form";
import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";

let ModalConfirmacaoAssembleia = (props) => {
  const { close, open, confirm, patrimonioSeparado, dataAssembleia } = props;

  const [publicadoManualmente, setPublicadoManualmente] = React.useState(false)
  const [salvoRede, setSalvoRede] = React.useState(false)
  const [publicadoDiarioBordo, setPublicadoDiarioBordo] = React.useState(false)

  const CheckboxInput = ({ checked, onChange, label, checkedIconStyle = { color: "#83BF74" } }) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            checkedIcon={<CheckBox style={checkedIconStyle} />}
          />
        }
        label={label}
      />
    );
  };

  const closeModal = () => {
    setPublicadoManualmente(false)
    setSalvoRede(false)
    setPublicadoDiarioBordo(false)
    close()
  }

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => closeModal()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Confirmar Realização de Assembleia
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => closeModal()}
            />
          </div>

          <div className="py-4">
            <p className="text-dark-hard fw-400">
              Você está prestes a {" "}
              <strong className="text-danger fw-500">confirmar a relização da assembleia</strong> da
              operação {patrimonioSeparado?.value} prevista para ocorrer no dia{" "}
              <strong className="text-danger fw-500">{getDateFormatedFromString(dataAssembleia)}</strong>.
            </p>
            <p className="my-2 text-dark-hard fw-400">
              A prosseguir com o fluxo, você confirma que:
            </p>

            <CheckboxInput
              checked={publicadoManualmente}
              onChange={(v) => setPublicadoManualmente(v)}
              label="A ata da assembleia foi publicada manualmente no Fundos.Net"
            />
            <CheckboxInput
              checked={salvoRede}
              onChange={(v) => setSalvoRede(v)}
              label="A gravação da assembleia foi salva na rede"
            />
            <CheckboxInput
              checked={publicadoDiarioBordo}
              onChange={(v) => setPublicadoDiarioBordo(v)}
              label="Estou ciente que os documentos da assembleia serão publicados automaticamente no diário de bordo e portal"
            />
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Não, Voltar"}
              action={() => closeModal()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Sim, Confirmar"}
              disabled={!publicadoManualmente || !salvoRede || !publicadoDiarioBordo}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={async () => confirm()}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const selector = formValueSelector("AssembleiaForm");

const mapStateToProps = (state) => ({
  patrimonioSeparado: selector(state, "patrimonioSeparado") || false,
  dataAssembleia: selector(state, "dataAssembleia") || false,
});

export default connect(
  mapStateToProps,
  null
)(ModalConfirmacaoAssembleia);
