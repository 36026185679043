import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import Input from "../../../../../../common/input/Input";
import ChipInputCustom from "../../../../../../common/input/ChipInputCustom";

function ContatosMonitoramentosForm(props) {
  const { readOnly, umContato, umContatoChange } = props;

  React.useEffect(() => {
    umContatoChange(umContato);
  }, [umContato, umContatoChange]);

  return (
    <div>
      <div className="d-flex w-100 align-items-center">
        <div className="col more-fields px-0">
          <div className="row">
            <div className="col-lg-12">
              <Field
                component={Input}
                inputId="nome"
                name="umContato.nome"
                type="text"
                label="Nome"
                readOnly={readOnly}
                maxLength={150}
                required
              />
            </div>

            <div className="col-lg-6">
              <Field
                component={Input}
                inputId="telefone"
                name="umContato.telefone"
                type="text"
                label="Telefone"
                readOnly={readOnly}
                maxLength={200}
              />
            </div>

            <div className="col-lg-6">
              <Field
                component={Input}
                inputId="funcao"
                name="umContato.funcao"
                label="Função"
                type="text"
                readOnly={readOnly}
                maxLength={250}
              />
            </div>

            <div className="col-lg-12">
              <Field
                component={ChipInputCustom}
                id="email"
                floatingLabelText={"Email *"}
                name="umContato.email"
                hintText="ex.: email@email.com"
                className="m-0"
                required
                params={{
                  form: "complementoForm",
                  field: "email",
                  currentValue:
                    umContato && umContato.email ? umContato.email : [],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const selector = formValueSelector("complementoForm");

const mapStateToProps = (state) => ({
  umContato: selector(state, "umContato"),
});

export default connect(mapStateToProps, null)(ContatosMonitoramentosForm);
