import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  SUBCLASSE_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  DETALHAR_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

import Subclasse from "../pages/Subclasse";
import SubclasseFormInclude from "../pages/form/SubclasseFormInclude";
import SubclasseFormUpdate from "../pages/form/SubclasseFormUpdate";
import SubclasseFormVisualize from "../pages/form/SubclasseFormVisualize";

export default () => (
  <Switch>
    <Route
      exact={true}
      path={`/${SUBCLASSE_ROUTE_PATH}`}
      component={Subclasse}
    />
    <Route
      exact={true}
      path={`/${SUBCLASSE_ROUTE_PATH}/${NOVO_ROUTE_PATH}`}
      component={SubclasseFormInclude}
    />
    <Route
      exact={true}
      path={`/${SUBCLASSE_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idSubclasse`}
      component={SubclasseFormUpdate}
    />
    <Route
      exact={true}
      path={`/${SUBCLASSE_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idSubclasse`}
      component={SubclasseFormVisualize}
    />
  </Switch>
);
