import {
  HISTORICOS_FETCHED,
  FUNDOS_HISTORICO_OPTIONS_FETCHED,
  HISTORICOS_CONSULT_CURRENT,
  RESET
} from "../../actions/historicocarteira/HistoricoAction";

const INITIAL_STATE = {
  historicos: null,
  messages: null,
  options: null,
  isLoading: false,
  historicoConsult: null
};

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case HISTORICOS_FETCHED:
      return {
        ...state,
        historicos: action.payload.content,
        messages: action.payload.messages,
        isLoading: true,
      };
    case HISTORICOS_CONSULT_CURRENT:
      return {
          ...state,
          historicoConsult: action.payload,      
          isLoading: true, 
      }
    case FUNDOS_HISTORICO_OPTIONS_FETCHED:
      return {
        ...state,
        options: action.payload,
      };
    case RESET:
      return {
          ...INITIAL_STATE
      }
    default:
      return state;
  }
}
