import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const styles = theme => ({
    button: {
        color:"#FFF !important",
    },
    root: {
        flexGrow: 1,
        width: '100%',
    },
    tabRoot: {
        color: "#000000",
        '&:hover': {
            color: 'inherit',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#630A38',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#630A38',
            outline: "none"
        }
    },
    tabError: {
        color: "red",
        borderBottom: "2px solid red",
        '&$tabSelected': {
            color: 'red',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: 'red',
            outline: "none"
        }
    },
    tabSelected: {
        color: '#fff',
        fontWeight: theme.typography.fontWeightMedium,
    },
    indicator: {
        backgroundColor: "#630A38",
    },
    indicatorError: {
        backgroundColor: "red",
    }
  });

class FormTab extends React.Component {
    state = {
        tabs: {},
        scrollButtons: "auto",
        activeTab: 0,
        paramsRoute: []
      };
    
    render(){
        const props = this.props;
        const { classes } = props;
        const { activeTab } = this.state;

        return (
            <div>
                <Tabs 
                    value={activeTab}
                    variant="scrollable"
                    scrollButtons="off"
                    onChange={(ev, idx) => this.setState({ activeTab: idx })}
                    classes={{ indicator: classes.indicator, root: classes.root}}
                >
                    {props.formTabNames.map((tabName, index) => {
                        const isObj = typeof tabName == 'object';

                        return (
                            <Tab
                                classes={{
                                  root: `${
                                    tabName?.invalid
                                      ? classes.tabError
                                      : classes.tabRoot
                                  }`,
                                }}
                                key={index}
                                label={isObj ? tabName.name : tabName}
                                onClick={() => props.changeFormTabActive(isObj ? tabName.name : tabName)}
                            />
                        )
                    })}
                </Tabs>
            </div>
        );
    }
}

export default withStyles(styles)(FormTab);