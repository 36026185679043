import React from 'react';
import If from '../If/If';

const Table = ({
    col,
    withoutHeader,
    color,
    headerTitle,
    classes,
    pagination,
    totalItems,
    classesOuterTable,
    styleOuterTable,
    headerCustom,
    files = [],
    ...props
}) => (
    <div className={`${props.classesOuter}`}>
        <div className={`card ${classes}`}>
            {headerCustom && (headerCustom)}
            <If test={!withoutHeader}>
                <div className="card-header" data-background-color={`${color || 'lightwine'}`}>
                    <h4 className="m-0">{headerTitle}</h4>
                </div>
            </If>
            <div className={`card-content table-responsive p-0 ${classesOuterTable}`} style={{styleOuterTable}}>
                <table className="table table-hover">
                    {props.children}
                </table>
            </div>

            <div className='d-flex flex-row justify-content-between align-items-end'>
                <div className='d-flex flex-xl-row flex-column align-items-xl-center'>
                    {pagination}

                    <div className='d-flex flex-xl-row flex-column align-items-xl-center mt-1'>
                        {files && files.map((file, index) => (
                            <React.Fragment key={index}>
                                <label className='ml-xl-3 lh-1 ml-0 pt-xl-2 pt-1 p-color-lighten mb-0' role='button' onClick={file.function}>
                                    {file.label}
                                </label>
                                {index < files.length - 1 && (
                                    <div className='table-files-divider ml-xl-3 pt-xl-2 mt-1 d-xl-block d-none'></div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <div className='d-flex flex-row align-items-center'>
                    <If test={totalItems}>
                        <label className='mx-2 text-gray mt-xl-3 mb-xl-2 mb-0'>Registros encontrados: {totalItems}</label>
                    </If>
                </div>
            </div>
        </div>
    </div>
        
);

export default Table;