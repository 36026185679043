import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../common/table/TableEmptyData";

import {
  getLogs,
  loadFormLog,
} from "../../../../../../actions/precificacao/LogAction";

import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import PaginationRB from "../../../../../common/pagination/PaginationRB";

import TableActionWithRouteAuthorization from "../../../../../common/table/TableActionWithRouteAuthorization";
import { LOG } from "../../../../../../utils/actionsAndFeaturesAuth";
import If from "../../../../../common/If/If";
import { formatActionLog } from "../../../../../../utils/helpers/OthersHelpers";

const LogList = (props) => {
  const { logs, loadFormLog, inicio, final, getLogs } = props;
  const { items, hasItems, pageIndex, pageSize, totalCount } = logs;

  const tableHeader = (
    <tr>
      <th className="text-center fw-700 text-nowrap">Tabela</th>
      <th className="text-center fw-700 text-nowrap">Usuário</th>
      <th className="text-center fw-700 text-nowrap">Procedimento</th>
      <th className="text-center fw-700 text-nowrap">Data</th>
      <th className="text-center">Ação</th>
    </tr>
  );

  const tableContent =
    logs && hasItems ? (
      items.map((item, index) => (
        <tr key={index}>
          <td className="text-center fw-400 text-nowrap">{item.tableName}</td>
          <td className="text-center fw-400 text-nowrap">
            {item.usuarioAudit}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {formatActionLog(item.action)}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {getDateFormatedFromString(item.dateTime)}
          </td>
          <TableActionWithRouteAuthorization
            feature={LOG}
            viewAction={() => loadFormLog(item.id)}
          />
        </tr>
      ))
    ) : (
      <TableEmptyData message={"Sem itens no momento"} />
    );

  return (
    <div className="w-100 px-3">
      <If test={totalCount > 0}>
        <Table
          withoutHeader
          classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
          totalItems={totalCount}
          pagination={
            <PaginationRB
              pageRange={5}
              itemsPerPage={pageSize || 0}
              totalItems={totalCount || 0}
              pageNumber={pageIndex - 1 || 0}
              fetchFunction={(event) => getLogs(inicio, final, event)}
            />
          }
        >
          <TableHeader>{tableHeader}</TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      </If>
    </div>
  );
};

const mapStateToProps = (state) => ({
  logs: state.logReducer.logFormTypes || [],
  inicio: state.logReducer.dataInicioLog,
  final: state.logReducer.dataFinalLog,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormLog,
      getLogs,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(LogList);
