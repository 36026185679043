/* eslint eqeqeq: 0 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  FLUXO_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  CONTAS_RECEBER_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

import ContasReceberPage from "../pages/ContasReceberPage";
import ContasReceberIncludePage from "../pages/ContasReceberIncludePage";
import ContasReceberUpdatePage from "../pages/ContasReceberUpdatePage";

export default () => (
  <Switch>
    <Route
      exact={true}
      path={`/${CONTAS_RECEBER_ROUTE_PATH}/${LISTA_ROUTE_PATH}`}
      component={ContasReceberPage}
    />
    <Route
      exact={true}
      path={`/${CONTAS_RECEBER_ROUTE_PATH}/${NOVO_ROUTE_PATH}`}
      component={ContasReceberIncludePage}
    />
    <Route
      exact={true}
      path={`/${CONTAS_RECEBER_ROUTE_PATH}/${FLUXO_ROUTE_PATH}/:idContasReceber`}
      component={ContasReceberUpdatePage}
    />
  </Switch>
);