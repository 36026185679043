/* eslint eqeqeq: 0 */

import { convertCurrencyToNumber } from "../../../../../utils/helpers/NumberHelper";

export const validate = (values) => {
  const conta = values.contaReceber;
  // const statusKey = conta?.status?.key;

  const isRec = conta?.tipoPagamento?.key == 3 

  let errors = {
    contaReceber: {
      tipoPagamento: !conta?.tipoPagamento ? "Preenchimento obrigatório" : null,
      idProdutoReceita: !conta?.idProdutoReceita ? "Preenchimento obrigatório" : null,
      idFaturarContra: !conta?.idFaturarContra ? "Preenchimento obrigatório" : null,
      idEmpresaFaturamento: !conta?.idEmpresaFaturamento ? "Preenchimento obrigatório" : null,

      idPatrimonioSeparado: !conta?.idPatrimonioSeparado ? "Preenchimento obrigatório" : null,
      idOperacao: !conta?.idOperacao ? "Preenchimento obrigatório" : null,
      idReceitaContrato: !conta?.idReceitaContrato ? "Preenchimento obrigatório" : null,
      idContaOrigem: !conta?.idContaOrigem && !isRec ? "Preenchimento obrigatório" : null,
      idOrigemRecurso: !conta?.idOrigemRecurso && !isRec ? "Preenchimento obrigatório" : null,
      modalidade: !conta?.modalidade && !isRec ? "Preenchimento obrigatório" : null,

      valorLiquido: !convertCurrencyToNumber(conta?.valorLiquido) ? "Preenchimento obrigatório" : null,
      grossUp: !conta?.grossUp ? "Preenchimento obrigatório" : null,
      valorBruto: !convertCurrencyToNumber(conta?.valorBruto) ? "Preenchimento obrigatório" : null,
      dataPagamento: !conta?.dataPagamento ? "Preenchimento obrigatório" : null,
      descricao: !conta?.descricao ? "Preenchimento obrigatório" : null,
    }
  };

  return errors;
};
