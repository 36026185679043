/* eslint eqeqeq: 0 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";

import {
    savePassivo,
    initializeForm,
} from '../../../../../actions/operacao/PassivoAction';

import {
    selectTab
} from '../../../../../actions/operacao/tabs/TabsAction';

import { OPERACAO_TABS } from './../OperacaoTabs';
import PassivoForm from './form/PassivoForm';
import ContainerPage from '../../../../common/container/ContainerPage';

const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");

class PassivoIncludePage extends Component {
    componentDidMount() {
        this.props.initializeForm();
    }

    render() {
        const {
            savePassivo,
            operacoes,
            gestora
        } = this.props;

        const idsOperacoes = operacoes.map(o => o.key);

        return (
            <ContainerPage
                tabs={OPERACAO_TABS}
                paramsRoute={idsOperacoes}
            >
                <PassivoForm
                    title={`Novo - ${gestora ? "Cota" : 'Passivo'}`}
                    onSubmit={savePassivo}
                    readOnly={false}
                    floatButtonVisible={true}
                />
            </ContainerPage>
        );
    }
}

const mapStateToProps = state => ({
    tab: state.tab,
    operacoes: state.operacaoReducer.selectedOperacoes,
    gestora: currEnv == process.env.REACT_APP_ENV_GEST_ID,
});

const mapDispacthToProps = dispatch => bindActionCreators({
    savePassivo,
    initializeForm,
    selectTab,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(PassivoIncludePage));
