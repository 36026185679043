/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import {
  saveReceita,
  loadFormReceita,
  showReceitaList,
} from "../../../../../actions/operacao/ReceitaAction";

import { OPERACAO_TABS } from "../OperacaoTabs";
import ReceitaForm from "./../../../operacao/pages/receita/form/ReceitaForm";
import ContainerPage from "../../../../common/container/ContainerPage";

const ReceitaIncludePage = (props) => {
  const { saveReceita, operacoes, loadFormReceita, showReceitaList } = props;
  const idsOperacoes = operacoes.map((o) => o.key);

  React.useEffect(() => {
    const query = new URLSearchParams(props.location.search).get("q");
    query
      ? loadFormReceita(null, operacoes[0]?.key || null, undefined, query)
      : showReceitaList(idsOperacoes);
  }, []);

  return (
    <ContainerPage tabs={OPERACAO_TABS} paramsRoute={idsOperacoes}>
      <ReceitaForm
        title={"Nova - Receita"}
        onSubmit={saveReceita}
        readOnly={false}
        floatButtonVisible={true}
      />
    </ContainerPage>
  );
};

const mapStateToProps = (state) => ({
  tab: state.tab,
  operacoes: state.operacaoReducer.selectedOperacoes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      saveReceita,
      loadFormReceita,
      showReceitaList,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ReceitaIncludePage)
);
