import React from "react";
import { DropdownList } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';

import {
    activateFocus,
    activateBlur
} from './../../../utils/helpers/StyleHelper';
import ReactTooltip from "react-tooltip";

const defaultValue = "Selecione...";

const messages = {
    emptyList: "Nenhum registro encontrado",
    emptyFilter: "Nenhum registro encontrado com esse filtro"
}

const getOptionsWithSelect = (props) => {
    let newOptions;

    if (props.options) {
        newOptions = [{ [props.textField]: defaultValue, [props.valueField]: null }].concat(props.options);
    } else {
        newOptions = [defaultValue].concat(optionsYesOrNo);
    }

    return newOptions;
}

const handleChange = (props, e) => {
    if (e[props.textField] === defaultValue || e === defaultValue) {
        props.input.onChange(null);
    } else {
        props.input.onChange(e);
    }
}

const optionsYesOrNo = ["Sim", "Não"]

const DropdownListInput = (props) => (
    <div className={`form-group field-floating form-list-select ${props.outerClassName} ${props.readOnly && 'disabled-field'}`}>
        <div className="dropdown-list-select">
            <DropdownList
                {...props.input}
                id={props.inputId}
                filter={props.filter}
                listProps={props.listProps}
                containerClassName={props.containerClassName}
                data={getOptionsWithSelect(props)}
                onChange={e => handleChange(props, e)}
                textField={props.textField}
                valueField={props.valueField}
                defaultValue={props.defaultValue}
                messages={messages}
                readOnly={props.readOnly}
                onBlur={activateBlur}
                onFocus={activateFocus}
                busy={props.busy}
            />
            {(props.meta && props.meta.error && props.meta.touched &&
                <p className="error-msg sp-error text-danger f-12 mb-0">{props.meta.error}</p>)
            }
        </div>
        <label
            htmlFor={props.name}
            className="control-label"
            id={"lbl_" + props.inputId}
        >
            {props.label}
            {props.required ? <span className="text-warning font-weight-bold ml-1">*</span> : ""}
         
            {props.icon && props.tooltipMessage && (
              <>
                <i
                  className={`fa fa-${props.icon} icon-margin-l-5`}
                  aria-hidden="true"
                  style={{color: props.iconColor, cursor: "default"}}
                  data-tip={props.tooltipMessage}
                  data-for={props.tooltipId || props.label}
                />
                <ReactTooltip
                  noArrow
                  effect="solid"
                  className="px-2 py-1"
                  globalEventOff="click"
                  style={{ width: "100px" }}
                  textColor="#333"
                  backgroundColor="#fff"
                  id={props.tooltipId || props.label}
                />
              </>
            )}
        </label>
    </div >
);


export default DropdownListInput;
