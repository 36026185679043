import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  EMPRESA_PRESTADORA_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  DETALHAR_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

import PrestadoraPage from "../../prestadora/pages/PrestadoraPage";
import PrestadoraVisualizePage from "../../prestadora/pages/PrestadoraVisualizePage";
import PrestadoraIncludePage from "../../prestadora/pages/PrestadoraIncludePage";
import PrestadoraUpdatePage from "../../prestadora/pages/PrestadoraUpdatePage";

export default () => (
  <Switch>
    <Route
      exact={true}
      path={`/${EMPRESA_PRESTADORA_ROUTE_PATH}`}
      component={PrestadoraPage}
    />
    <Route
      exact={true}
      path={`/${EMPRESA_PRESTADORA_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idPrestadora`}
      component={PrestadoraVisualizePage}
    />
    <Route
      exact={true}
      path={`/${EMPRESA_PRESTADORA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idPrestadora`}
      component={PrestadoraUpdatePage}
    />
    <Route
      exact={true}
      path={`/${EMPRESA_PRESTADORA_ROUTE_PATH}/${NOVO_ROUTE_PATH}`}
      component={PrestadoraIncludePage}
    />
  </Switch>
);
