import {
    LOAD_FILTER_FORM_MONITORAMENTO,

    //

    SALVA_FILTROS_MONITORAMENTOS,

    MONITORAMENTO_FETCHED,
    MONITORAMENTOS_FETCHED,
    MONITORAMENTOS_CONSULT_CURRENT,
    PAGAMENTO_RESET,
} from "../../actions/monitoramento/MonitoramentoAction";

const INITIAL_STATE = {
    pagamentos: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    externoPagamentoAF: null,
    checaLinkExternoAF: null,
    pagamentoModalPrecificacao: null,
    openModalExecucaoPrecificacao: {
        open: false,
        idPagamentoPrecificacao: null
    },
    pagamentosConsultCurrent: null,
    indicadores: null,
    messages: null, 
    showPagamentoDeleteDialog: {
        showDialog: false,
        paramsReiniciarPagamento: {},
    },
    isLoading: false,
    pagamentoFormTypes: {},
    monitoramentoFormTypesFilter: {},
    pagamentoInfo: {},
    pagamentoPagina: null,
    confirmDialogFechamento: false,
    confirmDialogValidacao: false,
    confirmDialogValidacaoConfirmacao: false,
    confirmDialogAgendamento: false,
    confirmDialogLancamento: false,
    confirmDialogPagamento: false,
    statusEtapas: [
        {id: 1, completed: false},
        {id: 2, completed: false},
        {id: 3, completed: false},
        {id: 4, completed: false},
        {id: 5, completed: false},
    ]
}

export default function (state = INITIAL_STATE, action = {}) {    
    switch(action.type) {
        case LOAD_FILTER_FORM_MONITORAMENTO:
            return {
                ...state,
                monitoramentoFormTypesFilter: action.payload,
            }
        case MONITORAMENTO_FETCHED:
            return {
                ...state,
                monitoramento: action.payload,
            }
        case MONITORAMENTOS_FETCHED:
            return {
                ...state,
                monitoramentos: action.payload.content,
                messages: action.payload.messages,
                isLoading: true,                
            }
        case MONITORAMENTOS_CONSULT_CURRENT:
            return {
                ...state,
                monitoramentosConsultCurrent: action.payload,      
                isLoading: true,     
            }
        case SALVA_FILTROS_MONITORAMENTOS:
            return {
                ...state,
                filtrosMonitoramento: action.payload
            }
        // 
        case PAGAMENTO_RESET:
            return {
                ...INITIAL_STATE
            }
        default:
            return state;
    }
}
