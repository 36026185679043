import React from 'react';
import { Route, Switch } from "react-router-dom";

import {
    ACESSO_PORTAL_ROUTE_PATH, LISTA_ROUTE_PATH, GERENCIAR_ROUTE_PATH, DETALHAR_ROUTE_PATH,
} from '../../../common/routers/constantesRoutes';

import AcessoPortalPage from '../pages/AcessoPortalPage';
import AcessoPortalPageGerenciar from '../pages/AcessoPortalPageGerenciar';
import AcessoPortalPageDetalhar from '../pages/AcessoPortalPageDetalhar';

export default () => (
    <Switch>
        <Route exact={true} path={`/${ACESSO_PORTAL_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={AcessoPortalPage} />
        <Route exact={true} path={`/${ACESSO_PORTAL_ROUTE_PATH}/${GERENCIAR_ROUTE_PATH}/:idUsuario`} component={AcessoPortalPageGerenciar} />
        <Route exact={true} path={`/${ACESSO_PORTAL_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idUsuario`} component={AcessoPortalPageDetalhar} />
    </Switch>
);
