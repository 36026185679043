/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";

import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";

import {
  exportAssembleiasToExcel,
  getAssembleias,
} from "../../../../../actions/assembleia/AssembleiaAction";
import ReactTooltip from "react-tooltip";
import { ASSEMBLEIA_ROUTE_PATH, EDITAR_ROUTE_PATH } from "../../../../common/routers/constantesRoutes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

const AssembleiaList = (props) => {
  const {
    getAssembleias,
    assembleiasData,
    assembleiasConsult,
    messages,
    exportAssembleiasToExcel,
  } = props;

  const arrHeader = [
    "PATRIMÔNIO SEPARADO",
    "CÓDIGO OPEA",
    "CRIADO POR",
    "DATA DA ASSEMBLEIA",
    "ORDEM CONVOCAÇÃO",
    "TIPO DE ASSEMBLEIA",
    "",
  ];

  const tableHeader = (
    <tr>
      {arrHeader.map((item, i) => (
        <th key={i}>
          <div className="text-center fw-700 d-flex justify-content-center w-100">
            {item}
          </div>
        </th>
      ))}
    </tr>
  );

  const emptyData = messages?.infos[0];

  const tableContent =
    assembleiasData &&
    assembleiasData.hasItems &&
    assembleiasData.items.map((item, index) => (
      <tr key={index} className={`tr-status`}>
        <td className="text-center fw-400">
          <div className="d-flex flex-row align-items-center justify-content-center">
            <div className="mr-3 td-status">
              <div
                data-type="light"
                data-for={`ttp-list-${index}`}
                data-tip={item?.status.value}
                className={`circle-status sts-ass-${item?.status.key} rounded-circle mx-0`}
              />
              <ReactTooltip
                noArrow
                effect="solid"
                id={`ttp-list-${index}`}
                className="px-2 py-1"
                globalEventOff="click"
                style={{ width: "100px" }}
              />
            </div>
            <span>{item?.patrimonioSeparado || "--"}</span>
          </div>
        </td>

        <td className="text-center fw-400">
          {item?.operacoes?.join(", ") || "--"}
        </td>

        <td className="text-center fw-400">
          {item?.criadoPor || "--"}
        </td>

        <td className="text-center fw-400">
          {item?.dataAssembleia ? getDateFormatedFromString(item.dataAssembleia) : "--"}
        </td>

        <td className="text-center fw-400">
          {item?.ordemConvocacao || "--"}
        </td>

        <td className="text-center fw-400">{item?.tipoAssembleia?.value || "--"}</td>

        <td className="text-center">
          <Link to={`/${ASSEMBLEIA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/${item.id}`} className="p-color-imp f-20 mr-3 ml-2 px-1">
            <FontAwesomeIcon color='#630a37' icon={faChevronRight} />
          </Link>
        </td>
      </tr>
    ));

  return (
    <div className="container-fluid">
      <div className="p-3">
        {assembleiasData?.totalCount > 0 && (
          <Table
            withoutHeader
            files={[
              { label: 'Exportar para Excel', function: () => exportAssembleiasToExcel(assembleiasConsult) }
            ]}
            classes="shadow-custom rounded-12 col-12 mt-xl-2 mt-1 mb-3 pd-10 bg-white"
            totalItems={assembleiasData.totalCount}
            pagination={
              <PaginationRB
                pageNumber={assembleiasData.pageIndex - 1}
                itemsPerPage={assembleiasData.pageSize}
                totalItems={assembleiasData?.totalCount}
                filter={assembleiasConsult}
                fetchFunction={getAssembleias}
                pageRange={5}
              />
            }
          >
            <TableHeader>{tableHeader}</TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        )}

        {assembleiasData?.totalCount <= 0 && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  assembleiasData: state.assembleiaReducer.assembleias,
  assembleiasConsult: state.assembleiaReducer.assembleiasConsultCurrent,
  messages: state.assembleiaReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getAssembleias,
      exportAssembleiasToExcel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(AssembleiaList);
