import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { editOpcao, loadFormOpcao } from "../../../../../../actions/precificacao/OpcaoAction";

import ContainerPage from "../../../../../common/container/ContainerPage";
import MovimentacaoFinanceiraForm from "./form/MovimentacaoFinanceiraForm";

class MovimentacaoFinanceiraUpdatePage extends Component {
  componentDidMount() {
    let idOpcao = this.props.match.params.idOpcao;
    this.props.loadFormOpcao(idOpcao, true, "movimentacao-financeira");
  }

  render() {
    const { editOpcao } = this.props;

    return (
      <ContainerPage>
        <MovimentacaoFinanceiraForm
          title={"Editar - Movimentação Financeira"}
          readOnly={false}
          floatButtonVisible={true}
          onSubmit={(e) => editOpcao(e, "movimentacao-financeira")}
        />
      </ContainerPage>
    );
  }
}

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormOpcao,
      editOpcao,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispacthToProps)(MovimentacaoFinanceiraUpdatePage));
