import React from "react";

import ContainerPage from "../../../../common/container/ContainerPage";
import PrecoListFilter from "./form/PrecoListFilter";
import PrecoList from "./list/PrecoList";

const PrecoPage = (props) => {
    return (
        <ContainerPage>
            <PrecoListFilter />
            <PrecoList />
        </ContainerPage>
    );
}

export default PrecoPage;