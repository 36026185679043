import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../../common/container/ContainerPage";
import DominioListFilter from "./form/DominioListFilter";
import DominioList from "./list/DominioList";

import { cleanResult, getDominio } from "../../../../../actions/precificacao/DominioAction";

class DominioPage extends Component {
  componentDidMount() {
    this.props.cleanResult();
    this.props.getDominio();
  }

  componentDidUpdate() {
    this.props.cleanResult();
  }

  render() {
    return (
      <ContainerPage>
        <DominioListFilter />
        <DominioList />
      </ContainerPage>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cleanResult,
      getDominio
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DominioPage);
