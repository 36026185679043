import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';

import { loadFormComplemento}  from '../../../../../actions/operacao/ComplementoAction';

import { OPERACAO_TABS } from './../OperacaoTabs';
import ComplementoForm from './form/ComplementoForm';
import ContainerPage from '../../../../common/container/ContainerPage';

class ComplementoVisualizePage extends Component {
    componentDidMount() {
        let idComplemento = this.props.match.params.idComplemento;
        this.props.loadFormComplemento(idComplemento);
    }

    render() {
        const {
            handleSubmit,
            operacoes,
        } = this.props;

        const idsOperacoes = operacoes.map(o => o.key);

        return (
            <ContainerPage
                tabs={OPERACAO_TABS}
                paramsRoute={idsOperacoes}
            >
                <ComplementoForm 
                    title={"Detalhar - Complemento"} 
                    handleSubmit={handleSubmit} 
                    readOnly={true}
                    floatButtonVisible={false}
                />
            </ContainerPage>
        );
    }
}

const mapStateToProps = state => ({ 
    tab: state.tab,
    operacoes: state.operacaoReducer.selectedOperacoes, 
});

const mapDispacthToProps = dispatch => bindActionCreators({ 
    loadFormComplemento,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(ComplementoVisualizePage));