import React from "react";
import Switch from "react-switch";

export default (props) => {
  const {
    inactiveLabel,
    activeLabel,
    input,
    uncheckedOffset,
    checkedOffset,
    classNameOut = "",
    classContentSwitch = "",
    textSide,
    classTextSide = "",
    labelUp,
    classLabelUp = "",
    labelUpStyle = "",
    requiredFlag,
    ...restProps
  } = props;
  return (
    <div className={classNameOut}>
      {labelUp && (
        <label className={`control-label ${classLabelUp}`} style={labelUpStyle}>
          { labelUp }
          {requiredFlag && (
            <span class="text-warning font-weight-bold ml-1">*</span>
          )}
        </label>
      )}

      <div className={classContentSwitch}>
        <Switch
          {...restProps}
          onChange={(checked) => input.onChange(checked)}
          checked={Boolean(input.value)}
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 14,
                color: "#ffffff",
                paddingRight: uncheckedOffset || 30,
              }}
            >
              {inactiveLabel}
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 14,
                color: "#ffffff",
                paddingLeft: checkedOffset || 30,
              }}
            >
              {activeLabel}
            </div>
          }
        />

        {textSide && <p className={`mb-0 ${classTextSide}`}>{textSide}</p>}
      </div>

      {(props.meta && props.meta.error && props.meta.touched &&
        <p className="error-msg sp-error text-danger f-12 mb-0 mt-2">{props.meta.error}</p>)
      }
    </div>
  );
};
