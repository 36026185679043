/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import PedidosForm from "./../../pedidos/pages/form/PedidosForm";
import {
  loadPedidosForm,
  savePedidos,
} from "../../../../actions/pedidos/PedidosAction";
import { CREATE, PEDIDOS } from "../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../actions/authorization/AuthorizationAction";

const PedidosIncludePage = (props) => {
  const { loadPedidosForm, savePedidos, authorizationFunction } = props;

  React.useEffect(() => {
    async function fetchLoad() {
      await authorizationFunction(
        { idAcao: CREATE, idFuncionalidade: PEDIDOS },
        () => loadPedidosForm()
      );
    }
  
    fetchLoad();
  }, []);

  return (
    <PedidosForm
      title={"Novo - Pedido de Compra"}
      onSubmit={savePedidos}
      readOnly={false}
      floatButtonVisible={true}
    />
  );
};

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadPedidosForm,
      savePedidos,
      authorizationFunction,
    },
    dispatch
  );

export default withRouter(
  connect(null, mapDispacthToProps)(PedidosIncludePage)
);
