import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, arrayInsert, arrayRemove } from "redux-form";
import { bindActionCreators } from "redux";

import If from "../../../../../common/If/If";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import Button from "../../../../../common/button/Button";
import { CSSTransition, TransitionGroup } from "react-transition-group";

class AtivoFormObjetoAgropecuario extends Component {
  componentDidMount() {
    if (this.props.objetoProdutoAgropecuarioList.length === 0) {
      this.addObjetoProdutoAgropecuario(
        this.props.objetoProdutoAgropecuarioList.length
      );
    }
  }

  addObjetoProdutoAgropecuario(index) {
    if (!this.props.readOnly) {
      this.props.arrayInsert(
        "ativoForm",
        "ativoObjetoProdutoAgropecuarioList",
        index,
        {}
      );
    }
  }

  removebjetoProdutoAgropecuario(index) {
    if (
      !this.props.readOnly &&
      this.props.objetoProdutoAgropecuarioList.length > 0
    ) {
      this.props.arrayRemove(
        "ativoForm",
        "ativoObjetoProdutoAgropecuarioList",
        index
      );
    }
  }

  render() {
    const {
      readOnly,
      objetoProdutoAgropecuarioList,
      originadorList,
      tipoNegocioList,
      tipoProdutoList,
      produtoList,
      finalidadeList,
    } = this.props;

    return (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4 mt-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Objeto Agropecuário</span>
          <div className="d-flex flex-column w-100">
            <div className="col more-fields p-0">
              <TransitionGroup>
                {objetoProdutoAgropecuarioList &&
                  objetoProdutoAgropecuarioList.map((item, index) => (
                    <CSSTransition key={index} timeout={300} classNames="item">
                      <div className="more-fields-item">
                        <div className="d-flex">
                          <div className="row w-100">
                            <div className="col-12 col-xl-3">
                              <Field
                                component={DropdownListInput}
                                inputId="originador"
                                name={`ativoObjetoProdutoAgropecuarioList[${index}].originador`}
                                label="Originador"
                                options={originadorList}
                                textField="value"
                                valueField="key"
                                readOnly={readOnly}
                                filter="contains"
                              />
                            </div>

                            <div className="col-12 col-xl-3">
                              <Field
                                component={DropdownListInput}
                                inputId="tipoNegocio"
                                name={`ativoObjetoProdutoAgropecuarioList[${index}].tipoNegocio`}
                                label="Tipo Negócio"
                                options={tipoNegocioList}
                                textField="value"
                                valueField="key"
                                readOnly={readOnly}
                                filter="contains"
                              />
                            </div>

                            <div className="col-12 col-xl-3">
                              <Field
                                component={DropdownListInput}
                                inputId="tipoProduto"
                                name={`ativoObjetoProdutoAgropecuarioList[${index}].tipoProduto`}
                                label="Tipo Produto"
                                options={tipoProdutoList}
                                textField="value"
                                valueField="key"
                                readOnly={readOnly}
                                filter="contains"
                              />
                            </div>

                            <div className="col-12 col-xl-3">
                              <Field
                                component={DropdownListInput}
                                inputId="produto"
                                name={`ativoObjetoProdutoAgropecuarioList[${index}].produto`}
                                label="Produto"
                                options={produtoList}
                                textField="value"
                                valueField="key"
                                readOnly={readOnly}
                                filter="contains"
                              />
                            </div>

                            <div className="col-12 col-xl-3">
                              <Field
                                component={DropdownListInput}
                                inputId="finalidade"
                                name={`ativoObjetoProdutoAgropecuarioList[${index}].finalidade`}
                                label="Finalidade"
                                options={finalidadeList}
                                textField="value"
                                valueField="key"
                                readOnly={readOnly}
                                filter="contains"
                              />
                            </div>

                            <If test={!readOnly}>
                              <div className="col-12 col-lg-2 d-flex align-items-center minus-box-btn">
                                <Button
                                  classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize"
                                  activeClassName={"col-lg-auto p-0"}
                                  buttonStyle={"success"}
                                  action={() =>
                                    this.removebjetoProdutoAgropecuario(index)
                                  }
                                  icon={"trash"}
                                  name={"Remover"}
                                />
                              </div>
                            </If>
                          </div>
                        </div>
                      </div>
                    </CSSTransition>
                  ))}
              </TransitionGroup>
            </div>
            <If test={!readOnly}>
              <div className="mx-auto mt-3">
                <Button
                  classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                  activeClassName={"col-lg-auto p-0"}
                  buttonStyle={"success"}
                  action={() =>
                    this.addObjetoProdutoAgropecuario(
                      objetoProdutoAgropecuarioList.length
                    )
                  }
                  icon={"plus"}
                  name={"Adicionar"}
                />
              </div>
            </If>
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector("ativoForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ arrayInsert, arrayRemove }, dispatch);

const mapStateToProps = (state) => ({
  objetoProdutoAgropecuarioList:
    selector(state, "ativoObjetoProdutoAgropecuarioList") || [],
  originadorList: state.ativoReducer.ativoFormTypes.originadorList,
  tipoNegocioList: state.ativoReducer.ativoFormTypes.tipoNegocioList,
  tipoProdutoList: state.ativoReducer.ativoFormTypes.tipoProdutoList,
  produtoList: state.ativoReducer.ativoFormTypes.produtoList,
  finalidadeList: state.ativoReducer.ativoFormTypes.finalidadeList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AtivoFormObjetoAgropecuario);
