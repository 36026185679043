
import React, { Component } from "react";

class NotificationIcoPlus extends Component {
  render() {
    const props = this.props;
    return (
      <div {...props}>
        <i className="fa fa-bell fa-lg"></i>
      </div>
    );
  }
}

export default NotificationIcoPlus