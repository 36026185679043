import React, { Component } from "react";
import { connect } from "react-redux";
import ContainerPage from "../../../common/container/ContainerPage";
import RemessaListFilter from "./form/RemessaListFilter";
import RemessaList from "./list/RemessaList";

class RemessaPage extends Component {
  render() {
    return (
      <ContainerPage>
        <RemessaListFilter />
        <RemessaList />
      </ContainerPage>
    );
  }
}

export default connect(null, null)(RemessaPage);
