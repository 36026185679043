export const GET_PREFIXADO_COMPOSTO = "GET_PREFIXADO_COMPOSTO";
export const GET_PREFIXADO_SIMPLES = "GET_PREFIXADO_SIMPLES";
export const GET_FLUTUANTE = "GET_FLUTUANTE";
export const GET_CORRECAO_MONETARIA = "GET_CORRECAO_MONETARIA";

const TIPO_PAGAMENTO_PREFIXADO_COMPOSTO = 1;
const TIPO_PAGAMENTO_PREFIXADO_SIMPLES = 4;
const TIPO_PAGAMENTO_FLUTUANTE = 2;
const TIPO_PAGAMENTO_CORRECAO_MONETARIA = 3;

export function setPrecificacaoPrecisao(tipoPagamento, dispatch) {
    let tipoPrecificacao = GET_PREFIXADO_COMPOSTO;

    if (tipoPagamento.key === TIPO_PAGAMENTO_PREFIXADO_COMPOSTO) {
        tipoPrecificacao = GET_PREFIXADO_COMPOSTO;
    } else if (tipoPagamento.key === TIPO_PAGAMENTO_PREFIXADO_SIMPLES) {
        tipoPrecificacao = GET_PREFIXADO_SIMPLES
    } else if (tipoPagamento.key === TIPO_PAGAMENTO_FLUTUANTE) {
        tipoPrecificacao = GET_FLUTUANTE
    } else if (tipoPagamento.key === TIPO_PAGAMENTO_CORRECAO_MONETARIA) {
        tipoPrecificacao = GET_CORRECAO_MONETARIA;
    }

    return  dispatch ({type: tipoPrecificacao });
}