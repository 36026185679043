import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, formValueSelector } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import Button from "../../../../common/button/Button";
import MaskedFormatInput from "../../../../common/input/MaskedFormatInput";
import {
  CNPJ,
  customFormatCnpjCpf,
} from "../../../../../utils/helpers/StringHelper";
import Input from "../../../../common/input/Input";

import { getPrestadoras, loadFormFilterPrestadora, mudaDependenteOnChange } from "../../../../../actions/prestadora/PrestadoraAction";

let PrestadoraListFilter = (props) => {
  const [idStatus, setIdStatus] = React.useState([]);
  const [counterStatus, setCounterStatus] = React.useState(0);

  const { 
    getPrestadoras, 
    cnpj,
    nomeSimplificado,
    pesquisou,
    statusEnumList,
    loadFormFilterPrestadora,
    mudaDependenteOnChange,
  } = props;

  const data = {
    cnpj,
    nomeSimplificado,
    idStatus,
  }

  React.useEffect(() => {
    mudaDependenteOnChange("prestadorFilterForm", "idStatus", idStatus)
  }, [idStatus]);

  React.useEffect(() => {
    setCounterStatus(statusEnumList?.length || 0);
  }, [statusEnumList]);

  React.useEffect(() => {
    if (!pesquisou) getPrestadoras(data)
    loadFormFilterPrestadora()
  }, [])

  const chooseStatus = (key) => {
    if (idStatus.includes(key)) {
      let newArrIds = idStatus.filter((elemento) => elemento !== key);
      setIdStatus(newArrIds);
    } else {
      setIdStatus([...idStatus, key]);
    }
  };

  const checkStatus = (key) => {
    return idStatus?.some((o) => o == key) ? key : "";
  };

  const selecionarStatus = () => {
    const chips = Array.from(document.querySelectorAll(".forn-sts"));
    let arrStatus = [];

    chips.forEach((el) => {
      if (!el.classList.contains("pe-none")) arrStatus.push(Number(el.dataset.status));
    })

    setIdStatus(arrStatus);
  }

  const cleanStatus = () => {
    let arrStatus = [];
    setIdStatus(arrStatus);
  }

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Pessoas"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getPrestadoras}
        >
          <div className="row">
            <div className="col-lg">
              <Field
                name="cnpj"
                label="CPF/CNPJ"
                maxLength="18"
                inputId="cnpj"
                className={"filter-input"}
                component={MaskedFormatInput}
                placeholder={CNPJ.placeholder}
                formatMask={customFormatCnpjCpf}
              />
            </div>
            <div className="col-lg">
              <Field
                component={Input}
                className={"filter-input"}
                inputId="nomeSimplificado"
                name="nomeSimplificado"
                type="text"
                label="Nome Simplificado"
                placeholder="Nome"
              />
            </div>
          </div>

          {statusEnumList.length > 0 && (
            <div className="row w-100 align-items-center mx-0 mb-2 mt-2 pl-0 pr-3">
              <span className="d-lg-block d-none col-lg-auto fw-500 f-12 pl-0 mb-1">
                Status
              </span>
              <div className="px-0 col-12 col-lg-11 w-100 d-flex flex-row flex-wrap align-items-center">
                {statusEnumList.map((sts, index) => {
                  return (
                    <div key={`sts-${index}`} className="col-auto mb-1 px-2 tr-status">
                      <div
                        role="button"
                        onClick={() => chooseStatus(sts.key)}
                        className="d-flex flex-row align-items-center td-status prevent-select"
                      >
                        <div data-status={sts.key} className={`forn-sts circle-status sts-forn-${checkStatus(sts.key)} rounded-circle mr-1`}></div>
                        <p className="mb-0 mt-1px">{sts.value}</p>
                      </div>  
                    </div>
                  );
                })}

                {idStatus.length < counterStatus && (
                  <span onClick={selecionarStatus} role="button" className="text-underline p-color-lighten mb-xl-1 ml-2">Selecionar todos</span>
                )}
                {idStatus.length == counterStatus && (
                  <span onClick={cleanStatus} role="button" className="text-underline p-color-lighten mb-xl-1 ml-2">Desmarcar todos</span>
                )}
              </div>
            </div>
          )}

          <div className="col-lg-12">
            <div className="row">
              <Button
                action={() => getPrestadoras(data)}
                classes="p-bg-imp rounded"
                icon={"search"}
                name={"Buscar"}
              />
            </div>
          </div>
        </FormContent>
      </Form>
    </div>
  );
};
PrestadoraListFilter = reduxForm({
  form: "prestadorFilterForm",
  initialValues: { cnpj: "", nomeSimplificado: "", pesquisou: false, page: 1 },
})(PrestadoraListFilter);
const selector = formValueSelector("prestadorFilterForm");

const mapStateToProps = (state) => ({
  cnpj: selector(state, "cnpj"),
  nomeSimplificado: selector(state, "nomeSimplificado"),
  pesquisou: selector(state, "pesquisou"),
  statusEnumList: state.prestadoraReducer.filters?.statusEnumList || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPrestadoras,
      loadFormFilterPrestadora,
      mudaDependenteOnChange,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrestadoraListFilter);
