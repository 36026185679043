/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";

import GenericPickerInput from "../../../../common/input/GenericPickerInput";
import Input from "../../../../common/input/Input";
import Button from "../../../../common/button/Button";

import {
  getHistoricos,
  getSugestaoFundoHistorico,
  exportarHistoricoCotas,
} from "../../../../../actions/historicocotas/HistoricoAction";

let HistoricoListFilter = (props) => {
  const { getHistoricos, exportarHistoricoCotas } = props;

  const [idsUltimasEtapas, setIdsUltimasEtapas] = React.useState([]);
  const [fundo, setFundo] = React.useState();
  const [dataInicio, setDataInicio] = React.useState("");
  const [dataFinal, setDataFinal] = React.useState("");

  const status = [
    {
      key: 1,
      value: "Disponível",
      raw: "Disponivel",
    },
    {
      key: 2,
      value: "Em Validação",
      raw: "EmValidacao",
    },
    {
      key: 3,
      value: "Validado",
      raw: "Validado",
    },
  ];

  const chooseStatus = (key) => {
    if (idsUltimasEtapas.includes(key)) {
      let newArrIds = idsUltimasEtapas.filter((elemento) => elemento !== key);
      setIdsUltimasEtapas(newArrIds);
    } else {
      setIdsUltimasEtapas([...idsUltimasEtapas, key]);
    }
  };

  const checkStatus = (key) => {
    return idsUltimasEtapas?.some((o) => o == key) ? key : "";
  };

  function recebeDados() {
    return {
      dataInicio,
      dataFinal,
      nomeFundo: fundo ? fundo.value : null,
      idFundo: fundo ? fundo.key : null,
      status: idsUltimasEtapas.map((e) => status.find((s) => s.key === e)),
    };
  }

  function searchFilters() {
    const data = recebeDados();
    getHistoricos(data);
  }

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Histórico de Cotas"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getHistoricos}
        >
          <div className="row w-100 align-items-start mx-0 mt-2">
            <div className="col-12 align-self-start mt-1p mb-xl-3 pl-0 pr-0 pr-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Busca
                </label>
                <GenericPickerInput
                  loadData={props.getSugestaoFundoHistorico}
                  options={props.options}
                  activeClassName="inputPgmt"
                  formName={"HistoricoForm"}
                  fieldName={"operacao"}
                  isMulti={false}
                  placeholder={"Pesquisar fundo ex.: FIDC Abc"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={fundo}
                  selectedOptionForm={(e) => setFundo(e)}
                />
              </div>
            </div>

            <div className="col-12 col-xl-2 align-self-start px-0 mt-xl-0 mt-3">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataInicio"
                type={"date"}
                name="dataInicio"
                label="Data Início"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </div>

            <div className="col-12 col-xl-2 align-self-start px-0 px-xl-2">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataFinal"
                type={"date"}
                name="dataFinal"
                label="Data Final"
                value={dataFinal}
                onChange={(e) => setDataFinal(e.target.value)}
              />
            </div>

            {status.length > 0 && (
              <div className="d-flex flex-column col-12 col-xl-8 px-0 px-xl-2">
                <label className="txt-grey f-10-5">Status Validação</label>
                <div className="col-12 col-xl-8 w-100 d-flex flex-row flex-wrap align-items-center px-0">
                  {status.map((sts, index) => {
                    return (
                      <div
                        key={`sts-${index}`}
                        className="col-auto mb-1 pl-0 pr-2"
                      >
                        <div key={`sts-${index}`} className="col-auto mb-1 px-2 tr-status">
                          <div
                            role="button"
                            onClick={() => chooseStatus(sts.key)}
                            className="d-flex flex-row align-items-center td-status prevent-select"
                          >
                            <div
                              data-status={sts.key}
                              className={`circle-status sts-hist-${checkStatus(sts.key)} rounded-circle mr-1`}
                            ></div>
                            <p className="mb-0 mt-1px">{sts.value}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="row w-100 align-items-start mx-0">
              <div className="col-12 col-xl-2 flex-xl-fill d-flex align-items-center justify-content-between justify-content-xl-center mb-2 mb-xl-0 pl-0 pr-2 w-100">
                <Button
                  activeClassName="w-100"
                  action={() => searchFilters()}
                  classes="p-bg-imp rounded btn-search h-38 mt-2 mr-2 w-100 d-flex justify-content-center px-2"
                  name={"Buscar"}
                />
              </div>
              {fundo && (
                <p className="h-38 my-2 d-flex align-items-center pl-xl-2">
                  <span
                    role="button"
                    className="p-color-lighten fw-400"
                    onClick={() =>
                      exportarHistoricoCotas({
                        ...recebeDados(),
                        tipoArquivo: "excel",
                      })
                    }
                  >
                    Exportar Histórico de Cotas
                  </span>
                </p>
              )}
            </div>
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

HistoricoListFilter = reduxForm({ form: "HistoricoForm" })(HistoricoListFilter);

const mapStateToProps = (state) => ({
  options: state.historicoCotasReducer.options,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHistoricos,
      getSugestaoFundoHistorico,
      exportarHistoricoCotas,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoricoListFilter);
