/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
/* no-mixed-operators: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";

import Button from "../../../../common/button/Button";

import {
  getParticipantes,
  importParticipantes,
  loadFilterFormParticipante,
  novoParticipante,
} from "../../../../../actions/participante/ParticipanteAction";

import { DropdownList, Multiselect } from "react-widgets";
import MaskedFormatInput from "../../../../common/input/MaskedFormatInput";
import { customFormatCnpjCpf, formatCNPJToString } from "../../../../../utils/helpers/StringHelper";
import FileButton from "../../../../common/input/FileButton";

let ParticipanteListFilter = (props) => {
  const {
    getParticipantes,
    loadFilterFormParticipante,
    novoParticipante,
    tipoList,
    participanteConsult,
    importParticipantes,
  } = props;

  const [nome, setNome] = React.useState("");
  const [documento, setDocumento] = React.useState("");
  const [tipos, setTipos] = React.useState(null);

  async function searchFilters() {
    const data = {
      nome,
      tipos,
      documento: formatCNPJToString(documento),
    };

    await getParticipantes(data);
  }

  React.useEffect(() => {
    const fetchData = async () =>{
      await loadFilterFormParticipante();
      if (!participanteConsult) searchFilters();
    }
    
    fetchData();
  }, []);

  const fileInputRef = React.useRef()

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Participantes"} />
        <FormContent
          handleSubmit={getParticipantes}
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
        >
          <div className="row w-100 align-items-start mx-0">
            <div className="col-12 col-xl px-0 mt-1px">
              <MaskedFormatInput
                input={{
                  value: documento,
                  onChange: (e) => setDocumento(e.target.value)
                }}
                inputId="CPF/CNPJ"
                label="CPF/CNPJ"
                className="filter-input"
                formatMask={(v) => customFormatCnpjCpf(v)}
              />
            </div>
            <div className="col-12 col-xl px-0 px-xl-3">
              <div className="form-group field-floating has-float-label mt-4">
                <input
                  type="text"
                  name="nome"
                  id="txtfield_nome"
                  defaultValue={nome}
                  className="form-control bg-transparent"
                  onChange={(e) => setNome(e.target.value)}
                />
                <label
                  className="control-label"
                  htmlFor="nome"
                  id="lbl_nome"
                >
                  Nome
                </label>
              </div>
            </div>

            <div className="col-12 col-xl align-self-start mt-3px mb-0 px-0">
              <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                Tipo
              </label>
              <Multiselect
                value={tipos}
                onChange={(e) => setTipos(e)}
                textField={"value"}
                valueField={"key"}
                messages={{
                  emptyList: "Lista vazia",
                  emptyFilter: "Nenhum registro encontrado",
                }}
                placeholder={"Todos"}
                filter={"contains"}
                data={tipoList}
              />
            </div>
          </div>

          <div className="row w-100 mx-0">
            <div className="col-xl-2 col-12 px-0 pr-xl-3">
              <Button
                name={"Buscar"}
                action={searchFilters}
                activeClassName="w-100"
                nameClass="text-capitalize f-14 lh-1 mt-1px"
                classes="p-bg-imp rounded btn-search h-38 mt-2 w-100 d-flex justify-content-center px-5"
              />
            </div>

            <div className="col-xl-2 col-12 px-0 pr-xl-3">
              <Button
                name={"Criar Registro"}
                icon="plus-circle"
                action={novoParticipante}
                activeClassName="w-100"
                nameClass="mt-2px text-capitalize f-14"
                classes="darkblue-bg-imp rounded btn-search h-38 mt-2 w-100 d-flex justify-content-center align-items-center"
              />
            </div>

            <FileButton
              ref={fileInputRef}
              accept=".xls, .xlsx"
              outerClassName="my-0 col-xl-2 col-12 px-0"
              label="Importar Planilha"
              onChange={async (e) => {
                await importParticipantes(e.target.files[0])
                getParticipantes()
                fileInputRef.current.value = ''
              }}
              className="text-center lh-normal darkblue-bg-imp text-white px-3 f-14 fw-400 rounded btn-search h-38 mt-2 m-0 d-flex justify-content-center align-items-center"
            />
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tipoList: state.participanteReducer.participanteFilter?.tipoList || [],
  participanteConsult: state.participanteReducer?.participanteConsultCurrent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getParticipantes,
      loadFilterFormParticipante,
      novoParticipante,
      importParticipantes,
    },
    dispatch
  );


const ConnectedParticipanteFormFilter = connect(mapStateToProps, mapDispatchToProps)(ParticipanteListFilter);

export default reduxForm({ form: "ParticipanteFormFilter"})(ConnectedParticipanteFormFilter);
