/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import {
  editReceita,
  loadFormReceita,
} from "../../../../../actions/operacao/ReceitaAction";

import { OPERACAO_TABS } from "../OperacaoTabs";
import ReceitaForm from "./../../../operacao/pages/receita/form/ReceitaForm";
import ContainerPage from "../../../../common/container/ContainerPage";

class ReceitaUpdatePage extends Component {
  async componentDidMount() {
    let idReceita = this.props.match.params.idReceita;
    await this.props.loadFormReceita(idReceita, null, true);
  }

  render() {
    const { editReceita, operacoes, receitaContrato } = this.props;
    const idsOperacoes = operacoes.map((o) => o.key);

    const statusValue = receitaContrato?.statusAprovacao?.key;

    return (
      <ContainerPage tabs={OPERACAO_TABS} paramsRoute={idsOperacoes}>
        <ReceitaForm
          title={"Editar - Receita"}
          onSubmit={editReceita}
          readOnly={statusValue == 2 || statusValue == 3}
          floatButtonVisible={true}
        />
      </ContainerPage>
    );
  }
}

const mapStateToProps = (state) => ({
  tab: state.tab,
  operacoes: state.operacaoReducer.selectedOperacoes,
  receitaContrato: state.receitaReducer.receitaFormTypes.receitaContrato || {},
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      editReceita,
      loadFormReceita,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ReceitaUpdatePage)
);
