import { isNumber } from "util";

/**
 * @description Verifica se algum elemento do Array está nulos, undefined ou vazios.
 * @param {Array} array
 */
export function containsElementEmptyOnArray(array) {
  return (
    array &&
    array.every(
      elem =>
        elem === null ||
        elem === "undefined" ||
        Object.entries(elem).length === 0
    )
  );
}

/**
 * @description Obtem um Array com elementos que não sejam nulos, undefined ou vazios.
 * @param {Array} array
 */
export function getArrayDeserialized(array) {
  return array && !containsElementEmptyOnArray(array) ? [...array] : [];
}

/**
 * @description Retorna uma array com as opções Sim e Não.
 */
export function getArrayYesOrNo() {
  return [
    { textField: "Selecione...", valueField: null },
    { textField: "Sim", valueField: true },
    { textField: "Não", valueField: false }
  ];
}

/**
 *
 * @description Retorna array contendo os valores entre numberStart e numberEnd
 * @param {int} numberStart
 * @param {int} numberEnd
 */
export function getArrayNumberRange(numberStart, numberEnd) {
  let arrayResult = [];

  if (isNumber(numberStart) && isNumber(numberEnd)) {
    for (let i = numberStart; i <= numberEnd; i++) {
      arrayResult.push({ textField: i, valueField: i });
    }
  }

  return arrayResult;
}

/**
 * @description Remove duplicidade de acordo com a propriedade informada
 * @param {*} myArr 
 * @param {*} prop 
 */
export function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}


export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

/**
 * @description Cálcula soma de propriedades númericas de um array
 * @param {Array} array 
 * @param {String} fielName
 */
export function calculateSum(array, field) {
  return array
    .map(item => parseInt(item[field]))
    .reduce((prev, curr) => prev + curr);
}

/**
 * @description Cálcula soma de propriedades númericas de um array
 * @param {Array} array 
 * @param {String} fielName
 */
export function calculateSumNorm(array, field, gestora = false) {
  return array
    .map(item => gestora ? (item[field] || 0) : parseInt(item[field] || 0))
    .reduce((prev, curr) => prev + curr);
}

/**
 * @description Retorna uma array com os Estados Brasileiros.
 */
export function getArrayUF() {
  return [
    { key: "AC", value: "AC"},
    { key: "AL", value: "AL"},
    { key: "AP", value: "AP"},
    { key: "AM", value: "AM"},
    { key: "BA", value: "BA"},
    { key: "CE", value: "CE"},
    { key: "DF", value: "DF"},
    { key: "ES", value: "ES"},
    { key: "GO", value: "GO"},
    { key: "MA", value: "MA"},
    { key: "MT", value: "MT"},
    { key: "MS", value: "MS"},
    { key: "MG", value: "MG"},
    { key: "PA", value: "PA"},
    { key: "PB", value: "PB"},
    { key: "PR", value: "PR"},
    { key: "PE", value: "PE"},
    { key: "PI", value: "PI"},
    { key: "RJ", value: "RJ"},
    { key: "RN", value: "RN"},
    { key: "RS", value: "RS"},
    { key: "RO", value: "RO"},
    { key: "RR", value: "RR"},
    { key: "SC", value: "SC"},
    { key: "SP", value: "SP"},
    { key: "SE", value: "SE"},
    { key: "TO", value: "TO"}
  ]
}