/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import {
  editDespesa,
  loadFormDespesa,
} from "../../../../../actions/operacao/DespesaAction";

import { OPERACAO_TABS } from "./../../../operacao/pages/OperacaoTabs";
import DespesaForm from "./../../../operacao/pages/despesa/form/DespesaForm";
import ContainerPage from "../../../../common/container/ContainerPage";

class DespesaUpdatePage extends Component {
  async componentDidMount() {
    let idDespesa = this.props.match.params.idDespesa;
    await this.props.loadFormDespesa(idDespesa, null, true);
  }

  render() {
    const { editDespesa, operacoes, despesaOperacao } = this.props;
    const idsOperacoes = operacoes.map((o) => o.key);

    const statusValue = despesaOperacao?.statusAprovacao?.key;

    return (
      <ContainerPage tabs={OPERACAO_TABS} paramsRoute={idsOperacoes}>
        <DespesaForm
          title={"Editar - Despesa"}
          onSubmit={editDespesa}
          readOnly={statusValue == 2 || statusValue == 3 || statusValue == 4}
          floatButtonVisible={true}
        />
      </ContainerPage>
    );
  }
}

const mapStateToProps = (state) => ({
  tab: state.tab,
  operacoes: state.operacaoReducer.selectedOperacoes,
  despesaOperacao: state.despesaReducer.despesaFormTypes.despesaOperacao || {},
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      editDespesa,
      loadFormDespesa,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(DespesaUpdatePage)
);
