import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';

import UsuarioPerfilForm from './form/UsuarioPerfilForm';

import { 
    editUsuario,
    loadFormUsuario,
    loadFilterFormUsuario,
    getContasUser,
    cleanResult
}  from '../../../../actions/usuario/UsuarioAction';


class UsuarioUpdatePage extends Component {

    componentDidMount() {
        this.props.cleanResult();
        let idUsuario = this.props.match.params.idUsuario;
        this.props.loadFormUsuario(idUsuario, true);
        this.props.loadFilterFormUsuario();
        this.props.getContasUser();
    }

    render() {
        return (
            <UsuarioPerfilForm
                title={"Editar - Associação Usuário X Perfil"}
                onSubmit={this.props.editUsuario}
                readOnly={false}
                userReadOnly={true}
                floatButtonVisible={true}
            />
        );
    }
}

const mapDispacthToProps = dispatch => bindActionCreators({
    editUsuario,
    loadFormUsuario,
    loadFilterFormUsuario,
    getContasUser,
    cleanResult
}, dispatch);

export default withRouter(connect(null, mapDispacthToProps)(UsuarioUpdatePage));