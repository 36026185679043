import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  saveAtivo,
  loadFormAtivo,
} from "../../../../../actions/ativo/AtivoAction";
import AtivoForm from "./AtivoForm";

const AtivoFormInclude = (props) => {
  const { readOnly, saveAtivo, loadFormAtivo } = props;

  React.useEffect(() => {
    loadFormAtivo();
  }, []);

  return (
    <AtivoForm
      title={"Novo - Ativo"}
      onSubmit={saveAtivo}
      readOnly={readOnly}
      floatButtonVisible={true}
    />
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveAtivo,
      loadFormAtivo,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AtivoFormInclude);
