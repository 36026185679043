/* eslint react-hooks/exhaustive-deps: 0 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FundoSubclasses from "./FundoSubclasses";

import { loadFormFundoSubclasse } from "../../../../../../actions/fundo/FundoAction";

const FundoFormSubclasses = (props) => {
  const { readOnly, fundoSubclasses, loadFormFundoSubclasse, fundoId } = props;

  useEffect(() => {
    if (fundoId) loadFormFundoSubclasse(fundoId);
  }, []);

  let table = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4 mb-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">Subclasses do Fundo</span>
        <div>
          <FundoSubclasses readOnly={readOnly} dataList={fundoId ? fundoSubclasses : []} />
        </div>
      </div>
    </div>
  );

  return <div className="d-flex flex-column mt-4">{table}</div>;
};

const mapStateToProps = (state) => ({
  fundoId: state.fundoReducer.fundoFormTypes?.fundo?.id || null,
  fundoSubclasses: state.fundoReducer?.fundoSubclasses || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormFundoSubclasse,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundoFormSubclasses);
