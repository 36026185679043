/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MaskedInput from "../../../../../common/input/MaskedInput";
import { Field } from "redux-form";
import { CURRENCY } from "../../../../../../utils/helpers/StringHelper";
import { zeraDependentesOnChange } from "../../../../../../actions/relatorios/regulatorioAction";

const Balanco = (props) => {
  const { readOnly, derivativo, zeraDependentesOnChange } = props;

  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!derivativo) {
      zeraDependentesOnChange([
        "balanco.ativoContratosATermo",
        "balanco.ativoFuturos",
        "balanco.ativoOpcoes",
        "balanco.ativoSwap",
        "balanco.passivoContratosATermo",
        "balanco.passivoFuturos",
        "balanco.passivoOpcoes",
        "balanco.passivoSwap",
      ])
    }
  }, [derivativo])

  const arrAtivo = [
    { num: "9.1", label: "Créditos Totais", id: "balanco.ativoCreditosTotais", readOnly: true },
    { num: "9.1.1", label: "Créditos a vencer sem parcela em atraso", id: "balanco.ativoCreditosAVencerSemParcelaEmAtraso", readOnly: readOnly },
    { num: "9.1.2", label: "Créditos a vencer com parcela em atraso", id: "balanco.ativoCreditosAVencerComParcelaEmAtraso", readOnly: readOnly },
    { num: "9.1.3", label: "Créditos vencidos e não pagos", id: "balanco.ativoCreditosVencidosENaoPagos", readOnly: readOnly },
    { num: "9.2", label: "Provisão para redução no valor de recuperação de crédito", id: "balanco.ativoProvisaoParaReducaoNoValorDeRecuperacaoDeCredito", readOnly: readOnly },
    { num: "9.3", label: "Caixa e equivalente de caixa", id: "balanco.ativoCaixaEEquivalenteDeCaixa", readOnly: true },
    { num: "9.3.1", label: "Títulos públicos federais", id: "balanco.ativoTitulosPublicosFederais", readOnly: readOnly },
    { num: "9.3.2", label: "Cotas de fundos", id: "balanco.ativoCotasDeFundos", readOnly: readOnly },
    { num: "9.3.3", label: "Operações compromissadas", id: "balanco.ativoOperacoesCompromissadas", readOnly: readOnly },
    { num: "9.3.4", label: "Outros", id: "balanco.ativoOutros", readOnly: readOnly },
    { num: "9.4", label: "Derivativos", id: "balanco.ativoDerivativos", readOnly: true },
    { num: "9.4.1", label: "Contratos a Termo", id: "balanco.ativoContratosATermo", readOnly: readOnly },
    { num: "9.4.2", label: "Futuros", id: "balanco.ativoFuturos", readOnly: readOnly },
    { num: "9.4.3", label: "Opções", id: "balanco.ativoOpcoes", readOnly: readOnly },
    { num: "9.4.4", label: "Swap", id: "balanco.ativoSwap", readOnly: readOnly },
    { num: "9.5", label: "Outros Ativos", id: "balanco.ativoOutrosAtivos", readOnly: readOnly }
  ];

  const arrPassivo = [
    { num: "10.1", label: "Derivativos", id: "balanco.passivoDerivativos", readOnly: true },
    { num: "10.1.1", label: "Contratos a termo", id: "balanco.passivoContratosATermo", readOnly: readOnly },
    { num: "10.1.2", label: "Futuros", id: "balanco.passivoFuturos", readOnly: readOnly },
    { num: "10.1.3", label: "Opções", id: "balanco.passivoOpcoes", readOnly: readOnly },
    { num: "10.1.4", label: "Swap", id: "balanco.passivoSwap", readOnly: readOnly },
    { num: "10.2", label: "Valor atualizado da emissão", id: "balanco.passivoValorAtualizadoDaEmissao", readOnly: true },
    { num: "10.3", label: "Redução no valor da emissão", id: "balanco.passivoReducaoNoValorDaEmissao", readOnly: true },
    { num: "10.4", label: "Outros", id: "balanco.passivoOutros", readOnly: readOnly },
    { num: "10.5", label: "Companhia securitizadora", id: "balanco.passivoCompanhiaSecuritizadora", readOnly: readOnly }
  ];

  const arrPS = [
    { num: "11.1", label: "Valor patrimônio líquido", id: "balanco.patrimonioLiquidoValor", readOnly: true },
  ];

  const fields = (arr) =>
    arr.map(({ num, label, id, readOnly }) => (
      <div className="row d-flex align-items-center my-lg-2 mb-4 mt-2" key={id}>
        <div className="col-1 mt-lg-2 mt-0 fw-400">{num}</div>
        <div className="col-lg-3 col-11 text-lg-left text-center mt-lg-2 mt-0 fw-400 px-2">{label}</div>
        <div className="col-lg col-12">
          <Field
            component={MaskedInput}
            outerClass="m-0"
            inputId={id}
            name={id}
            readOnly={readOnly}
            prefix="R$ "
            placeholder={CURRENCY.placeholder}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
          />
        </div>
      </div>
    ));

  return (
    <div className="container-fluid px-0">
      <div>
        <div className="column shadow-custom bg-white rounded-12 col-12 px-4 pt-1 pb-3 mb-3">
          <p className="h3 page-head__title my-3 mb-0">9. Ativo</p>
          {fields(arrAtivo)}
        </div>

        <div className="column shadow-custom bg-white rounded-12 col-12 px-4 pt-1 pb-3 mb-3">
          <p className="h3 page-head__title my-3 mb-0">10. Passivo</p>
          {fields(arrPassivo)}
        </div>
        
        <div className="column shadow-custom bg-white rounded-12 col-12 px-4 pt-1 pb-3">
          <p className="h3 page-head__title my-3 mb-0">11. Patrimônio Líquido</p>
          {fields(arrPS)}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispacthToProps = (dispatch) => bindActionCreators({
  zeraDependentesOnChange
}, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(Balanco);
