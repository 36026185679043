import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import PrestadoraForm from "./form/PrestadoraForm";
import {
  savePrestadora,
  loadFormPrestadora,
} from "../../../../actions/prestadora/PrestadoraAction";
import { formValueSelector } from "redux-form";

class PrestadoraIncludePage extends Component {
  componentDidMount() {
    const filter = {
      cnpj: this.props.cnpj || "",
      nomeSimplificado: this.props.nomeSimplificado || "",
      pesquisou: this.props.pesquisou || false,
      page: this.props.page || 1,
    };
    this.props.loadFormPrestadora(filter);
  }

  render() {
    const { savePrestadora } = this.props;
    return (
      <PrestadoraForm
        title={"Nova - Pessoa"}
        onSubmit={savePrestadora}
        readOnly={false}
        floatButtonVisible={true}
      />
    );
  }
}

const selector = formValueSelector("prestadorFilterForm");
const mapStateToProps = (state) => ({
  cnpj: selector(state, "cnpj"),
  nomeSimplificado: selector(state, "nomeSimplificado"),
  pesquisou: selector(state, "pesquisou"),
  page: selector(state, "page"),
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      savePrestadora,
      loadFormPrestadora,
    },
    dispatch
  );
export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(PrestadoraIncludePage)
);
