import React from "react";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../common/button/Button";
import { Close } from "@material-ui/icons";

let ModalDescricaoResumo = (props) => {
  const { close, open, resumo } = props;

  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-start justify-content-between">
            <div className="fw-500 p-color-imp pr-3">
              <p className="m-0 lh-sm fw-500 f-28">Descrição da Ordem do Dia</p>
              <p className="m-0 lh-normal fw-400 f-22">{resumo.assunto}</p>
            </div>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-4">
            <div className="col-12 px-0 text-center">{resumo.descricao}</div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default connect(null, null)(ModalDescricaoResumo);
