import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  LISTA_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  PARTICIPANTE_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

import ParticipantePage from "../pages/ParticipantePage";
import ParticipanteIncludePage from "../pages/ParticipanteIncludePage";
import ParticipanteUpdatePage from "../pages/ParticipanteUpdatePage";

export default () => (
  <Switch>
    <Route exact={true} path={`/${PARTICIPANTE_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={ParticipantePage} />
    <Route exact={true} path={`/${PARTICIPANTE_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={ParticipanteIncludePage} />
    <Route exact={true} path={`/${PARTICIPANTE_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idParticipante`} component={ParticipanteUpdatePage} />
  </Switch>
);
