/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Field,
  formValueSelector,
  change,
  arrayInsert,
  arrayRemove,
} from "redux-form";
import { bindActionCreators } from "redux";

import DropdownListInput from "../../../../../../common/input/DropdownListInput";
import Textarea from "../../../../../../common/input/Textarea";
import If from "./../../../../../../common/If/If";
import Input from "../../../../../../common/input/Input";
import { changeIdOperacao, limpaDependenteOnChange } from "../../../../../../../actions/operacao/ComplementoAction";

const TIPO_GARANTIA = 1;
const TIPO_GARANTIA_ACESSORIA = 2;

const TIPO_CONDICAO_A_SER_CONSTITUIDA = 2;
const TIPO_CONDICAO_SUSPENSIVA = 3;
const TIPO_CONDICAO_RESOLUTIVA = 4;
const TIPO_RATING = 3;

const STATUS_OPERACAO_APROVADA = 3;

class ComplementoFormGeral extends Component {
  tituloComplementosLista = [];
  ratingListLocal = [];

  UNSAFE_componentWillReceiveProps(nextProps) {
    let tipoComplemento = nextProps.complementoOperacao
      ? nextProps.complementoOperacao.tipoComplemento
      : null;
    this.setTituloAoEditar(tipoComplemento);
    this.setRatingAoEditar(nextProps.ratingAgencia);
  }

  setTituloAoEditar(tipoComplemento) {
    if (
      tipoComplemento &&
      this.props.complementoOperacao &&
      this.tituloComplementosLista.length === 0 &&
      this.props.complementoOperacao.tipoComplemento &&
      this.props.complementoOperacao.tipoComplemento.id === tipoComplemento.id
    ) {
      this.addTitulosPorTipo(this.props.complementoOperacao.tipoComplemento);
    }
  }

  filtrarTitulosComplemento(itemEscolhido) {
    this.addTitulosPorTipo(itemEscolhido);

    this.props.change("complementoForm", "tituloComplemento", null);

    if (
      itemEscolhido.id !== TIPO_GARANTIA &&
      itemEscolhido.id != TIPO_GARANTIA_ACESSORIA
    ) {
      this.props.change("complementoForm", "complementoCondicao", null);
      this.props.change("complementoForm", "condicaoImplementacao", null);
      this.props.change("complementoForm", "dadosAdicionais", null);
    }
  }

  addTitulosPorTipo(tipoComplemento) {
    if (tipoComplemento && tipoComplemento.tituloList) {
      this.tituloComplementosLista = [];

      tipoComplemento.tituloList.forEach((elem) => {
        this.tituloComplementosLista.push(elem);
      });
    }
  }
  filtrarAgenciaRating(itemEscolhido) {
    if (itemEscolhido.id) {
      this.addRatingPorAgencia(itemEscolhido);
      this.props.change("complementoForm", "ratingPassivo", null);
    } else {
      this.ratingListLocal = [];
      this.props.change("complementoForm", "ratingAgencia", null);
      this.props.change("complementoForm", "ratingPassivo", null);
    }
  }
  addRatingPorAgencia(agencia) {
    if (agencia && agencia.ratingList) {
      this.ratingListLocal = [];
    }
  }
  setRatingAoEditar(ratingAgencia) {
    if (ratingAgencia && this.props.ratingList) {
      let currentRating = this.props.ratingList.find((rating) => {
        return rating.id === ratingAgencia.id;
      });
      currentRating.ratingList.forEach((elem) => {
        this.ratingListLocal.push(elem);
      });
    }
  }

  habilitarCampoApenasParaOperacaoAprovada() {
    return (
      this.props.statusOperacao &&
      this.props.statusOperacao.key === STATUS_OPERACAO_APROVADA
    );
  }

  render() {
    const {
      readOnly,
      contratoComplementoList,
      nivelComplementoList,
      nivelComplemento,
      tipoComplementoList,
      tipoComplemento,
      complementoCondicao,
      complementoCondicaoList,
      complementoStatusGarantiaList,
      ratingList,
      perspectivaList,
      operacoesList,
      limpaDependenteOnChange,
      changeIdOperacao,
      currentIdOperation,
    } = this.props;

    const tipoGarantiaOuGarantiaAcessoria =
      tipoComplemento &&
      (tipoComplemento.id === TIPO_GARANTIA ||
        tipoComplemento.id === TIPO_GARANTIA_ACESSORIA);

    const condicaoSuspensivaOuResolutiva =
      complementoCondicao &&
      (complementoCondicao.id === TIPO_CONDICAO_A_SER_CONSTITUIDA ||
        complementoCondicao.id === TIPO_CONDICAO_SUSPENSIVA ||
        complementoCondicao.id === TIPO_CONDICAO_RESOLUTIVA);

    const tipoRating = tipoComplemento && tipoComplemento.id === TIPO_RATING;

    let complementoGeral = (
      <div>
        <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
          <div className="column">
            <span className="page-head__title h3 mb-3">Controle</span>
            <div>
              <div className="row">
                <div className="col-lg-3">
                  <Field
                    component={DropdownListInput}
                    inputId="nivelComplemento"
                    name="nivelComplemento"
                    label="Nível"
                    options={nivelComplementoList}
                    textField="value"
                    filter="contains"
                    valueField="key"
                    readOnly={readOnly}
                    onChange={() => {
                      if (nivelComplemento?.key == 2) {
                        this.props.change("complementoForm", "idOperacao", null);
                      } else {
                        this.props.change("complementoForm", "idOperacao", currentIdOperation);
                        changeIdOperacao(currentIdOperation);
                        limpaDependenteOnChange("complementoMonitoramento.monitorarAte");
                        limpaDependenteOnChange("complementoMonitoramento.dataValidade");
                      }
                    }}
                  />
                </div>

                {nivelComplemento?.key == 1 && (
                  <div className="col-lg-3">
                    <Field
                      component={DropdownListInput}
                      inputId="idOperacao"
                      name="idOperacao"
                      label="Operação"
                      options={operacoesList}
                      textField="descricao"
                      valueField="id"
                      filter="contains"
                      readOnly={readOnly}
                      onChange={(e) => {
                        e?.id ? changeIdOperacao(e.id) : changeIdOperacao();
                        limpaDependenteOnChange("complementoMonitoramento.monitorarAte");
                        limpaDependenteOnChange("complementoMonitoramento.dataValidade");
                      }}
                    />
                  </div>
                )}

                {nivelComplemento?.key == 2 && (
                  <div className="col-lg-3">
                    <Field
                      component={Input}
                      inputId="descricaoPatrimonioSeparado"
                      name="descricaoPatrimonioSeparado"
                      options={nivelComplementoList}
                      label="Patrimônio Separado"
                      readOnly={true}
                    />
                  </div>
                )}

                <div className="col-lg-3">
                  <Field
                    component={DropdownListInput}
                    inputId="tipoComplemento"
                    name="tipoComplemento"
                    label="Tipo"
                    options={tipoComplementoList}
                    textField="descricao"
                    filter="contains"
                    valueField="id"
                    readOnly={readOnly}
                    onChange={(itemEscolhido) =>
                      this.filtrarTitulosComplemento(itemEscolhido)
                    }
                  />
                </div>

                <div className="col-lg-3">
                  <Field
                    component={DropdownListInput}
                    inputId="tituloComplemento"
                    name="tituloComplemento"
                    label="Título"
                    options={this.tituloComplementosLista}
                    textField="descricao"
                    valueField="id"
                    filter="contains"
                    readOnly={readOnly}
                  />
                </div>

                <div className="col-lg-6">
                  <Field
                    component={DropdownListInput}
                    inputId="contratoComplemento"
                    name="contratoComplemento"
                    label="Contrato"
                    options={contratoComplementoList}
                    textField="descricao"
                    valueField="id"
                    filter="contains"
                    readOnly={readOnly}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <Field
                    component={Textarea}
                    inputId="referencia"
                    name="referencia"
                    label="Referência"
                    type="text"
                    rows={4}
                    readOnly={readOnly}
                    maxLength={500}
                    minHeight={4}
                    height={50}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <If test={tipoGarantiaOuGarantiaAcessoria}>
          <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
            <div className="column">
              <span className="page-head__title h3 mb-3">Garantia</span>
              <div className="row">
                <div className="col-12 col-lg-4">
                  <Field
                    component={DropdownListInput}
                    inputId="complementoStatusGarantia"
                    name="complementoStatusGarantia"
                    options={complementoStatusGarantiaList}
                    label="Status Garantia"
                    textField="descricao"
                    valueField="id"
                    readOnly={readOnly}
                  />
                </div>

                <div className="col-12 col-lg-4">
                  <Field
                    component={DropdownListInput}
                    inputId="complementoCondicao"
                    name="complementoCondicao"
                    options={complementoCondicaoList}
                    label="Condição"
                    textField="descricao"
                    valueField="id"
                    readOnly={readOnly}
                  />
                </div>
                <If test={condicaoSuspensivaOuResolutiva}>
                  <div className="col-12 col-lg-4">
                    <Field
                      component={Textarea}
                      inputId="condicaoImplementacao"
                      name="condicaoImplementacao"
                      label="Condição de Implementação"
                      type="text"
                      rows={3}
                      readOnly={readOnly}
                      maxLength={500}
                      height={50}
                    />
                  </div>
                </If>
              </div>
            </div>
          </div>
        </If>

        <If test={tipoRating}>
          <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
            <div className="column">
              <span className="page-head__title h3 mb-3">Rating</span>
              <div className="row">
                <div className="col-lg-3 col-xl-3">
                  <Field
                    component={DropdownListInput}
                    inputId="ratingAgencia"
                    name="ratingAgencia"
                    label="Agência"
                    options={ratingList}
                    textField="nome"
                    filter="contains"
                    valueField="id"
                    readOnly={
                      readOnly
                        ? readOnly
                        : this.habilitarCampoApenasParaOperacaoAprovada()
                    }
                    onChange={(agenciaEscolhida) =>
                      this.filtrarAgenciaRating(agenciaEscolhida)
                    }
                  />
                </div>

                <div className="col-lg-3 col-xl-3">
                  <Field
                    component={DropdownListInput}
                    inputId="ratingPassivo"
                    name="ratingPassivo"
                    label="Rating"
                    options={this.ratingListLocal}
                    textField="nome"
                    valueField="id"
                    filter="contains"
                    readOnly={
                      readOnly
                        ? readOnly
                        : this.habilitarCampoApenasParaOperacaoAprovada()
                    }
                  />
                </div>

                <div className="col-lg-3 col-xl-3">
                  <Field
                    component={DropdownListInput}
                    inputId="perspectivaRating"
                    name="perspectivaRating"
                    label="Perspectiva Rating"
                    options={perspectivaList}
                    textField="value"
                    filter="contains"
                    valueField="key"
                    readOnly={
                      readOnly
                        ? readOnly
                        : this.habilitarCampoApenasParaOperacaoAprovada()
                    }
                  />
                </div>

                <div className="col-lg-3 col-xl-3">
                  <Field
                    component={Input}
                    inputId="dataAtribuicaoRating"
                    name="dataAtribuicaoRating"
                    type="date"
                    label="Data atribuição"
                    readOnly={
                      readOnly
                        ? readOnly
                        : this.habilitarCampoApenasParaOperacaoAprovada()
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </If>
      </div>
    );

    return <div>{complementoGeral}</div>;
  }
}

const selector = formValueSelector("complementoForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      arrayInsert,
      arrayRemove,
      change,
      limpaDependenteOnChange,
      changeIdOperacao,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  rating: selector(state, "rating"),
  tipoComplemento: selector(state, "tipoComplemento"),
  complementoCondicao: selector(state, "complementoCondicao"),
  ratingAgencia: selector(state, "ratingAgencia"),
  ratingPassivo: selector(state, "ratingPassivo"),
  statusOperacao: selector(state, "statusOperacao"),
  nivelComplemento: selector(state, "nivelComplemento"),
  nivelComplementoList: state.complementoReducer.complementoFormTypes.niveisComplementos,
  tipoComplementoList: state.complementoReducer.complementoFormTypes.tipoComplementoList,
  contratoComplementoList: state.complementoReducer.complementoFormTypes.contratoComplementoList,
  regraAtividadeList: state.complementoReducer.complementoFormTypes.regraAtividadeList,
  regraCondicaoList: state.complementoReducer.complementoFormTypes.regraCondicaoList,
  complementoCondicaoList: state.complementoReducer.complementoFormTypes.complementoCondicaoList,
  complementoOperacao: state.complementoReducer.complementoFormTypes.complementoOperacao,
  currentIdOperation: state.complementoReducer.currentIdOperation || null,
  complementoStatusGarantiaList: state.complementoReducer.complementoFormTypes.complementoStatusGarantiaList,
  ratingList: state.complementoReducer.complementoFormTypes.ratingAgenciaList,
  perspectivaList: state.complementoReducer.complementoFormTypes.perspectivaRatingList,
  operacoesList: state.complementoReducer.complementoFormTypes.operacoes,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplementoFormGeral);
