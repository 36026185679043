import React, { Component } from "react";
import { NumericFormat } from "react-number-format";
import ReactTooltip from "react-tooltip";

export default class MaskedInput extends Component {
  blurFunction(blur, onValueChange, e) {
    if (blur) {
      onValueChange(e.target.value);
    } 
  }

  changeFunction(blur, onValueChange, e) {    
    if (!blur) {
      if (onValueChange && e.target) {
        onValueChange(e.target.value);
      } else if(onValueChange) {
        onValueChange(e);
      }
    }
  }

  classNameInput = (className) => {
    let name = 'form-control';
    
    if (className) {
      name += ' ' + className;
    }

    return name;
  }

  render() {
    const props = this.props;
    return (
      <div className={`form-group field-floating has-float-label ${props.outerClass} ${props.readOnly && 'disabled-field'}`}>
        <NumericFormat
          {...props.input}
          className={this.classNameInput(props.className)}
          id={"txtfield_" + props.inputId}
          type={props.type}
          prefix={props.prefix}
          suffix={props.suffix}
          thousandSeparator={props.thousandSeparator}
          decimalSeparator={props.decimalSeparator}
          decimalScale={props.decimalScale}
          fixedDecimalScale={props.fixedDecimalScale}
          allowNegative={props.allowNegative}
          // allowEmptyFormatting={props.allowEmptyFormatting}
          value={props.input.value}
          displayType={props.displayType}
          // format={props.formatMask}
          // removeFormatting={props.removeFormatting}
          // mask={props.mask}
          customInput={props.customInput}
          onValueChange={(e) => this.changeFunction(props.blur, props.onValueChange, e)}
          isAllowed={props.isAllowed}
          renderText={props.renderText}
          getInputRef={props.getInputRef}
          placeholder={props.placeholder}
          disabled={props.readOnly}
          maxLength={props.maxLength}
          onBlur={(e) => this.blurFunction(props.blur, props.onValueChange, e)}
          // onFocus={activateFocus}
        />
        {props.label && (
          <label
            htmlFor={props.name}
            className={`control-label ${props.classLabel}`}
            id={"lbl_" + props.inputId}
          >
            {props.label}
            {props.required ? <span className="text-warning font-weight-bold ml-1">*</span> : ""}
            {props.icon && props.tooltipMessage && (
              <>
                <i
                  className={`fa fa-${props.icon} icon-margin-l-5`}
                  aria-hidden="true"
                  style={{color: props.iconColor, cursor: "default"}}
                  data-tip={props.tooltipMessage}
                  data-for={props.tooltipId || props.label}
                />
                <ReactTooltip
                  noArrow
                  effect="solid"
                  className="px-2 py-1"
                  globalEventOff="click"
                  style={{ width: "100px" }}
                  textColor="#333"
                  backgroundColor="#fff"
                  id={props.tooltipId || props.label}
                />
              </>
            )}
          </label>
        )}
        {(props.meta && props.meta.error && props.meta.touched &&
          <p className="error-msg sp-error text-danger f-12 mb-0">{props.meta.error}</p>)
        }
      </div>
    );
  }
}
