import React from "react";
import { copiarParaArea } from "../../../utils/helpers/OthersHelpers";

export default (props) => {
  const {
    toCopy = "",
    label = "Copiar código",
    className = "",
    iconCustom = null,
    msgSuccess = "Código copiado para área de transferência"
  } = props;

  return (
    <div
      role="button"
      onClick={() => copiarParaArea(toCopy, msgSuccess)}
      className={`btn-copy  d-flex flex-row align-content-center justify-content-center rounded px-2 py-1 mt-2 mb-1 w-mx-cnt ${className}`}
    >
      {iconCustom ? iconCustom : <i className="fa fa-clone mr-2 mt-1"></i>}
      <span>{label}</span>
    </div>
  );
};
