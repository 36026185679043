/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { showIniciarImplantacao } from "../../../../actions/operacao/OperacaoAction";
import { authorizationFunction } from "../../../../actions/authorization/AuthorizationAction";

import Button from "./../../../common/button/Button";
import OperacaoPicker from "./../../pickers/OperacaoPicker";

import {
  OPERACAO_PASSIVO,
  CREATE,
} from "../../../../utils/actionsAndFeaturesAuth";

const authData = {
  idFuncionalidade: OPERACAO_PASSIVO,
  idAcao: CREATE,
};

const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");

const OperacaoHeader = (props) => {
  const { showIniciarImplantacao, gestora } = props;

  return (
    <div className="d-flex flex-column w-100 px-4 mb-4">
      <p className="page-head__title f-36 my-2 px-0 py-4">
        {gestora ? "Cota do Fundo" : "Operação"}
      </p>
      <div className="shadow-custom rounded-12 w-100 pd-12 d-flex flex-lg-row flex-column justify-content-between align-items-end bg-white">
        <div className="d-flex flex-column w-100">
          <label className="f-12 text-dark mb-1">Busca</label>
          <OperacaoPicker multiControl={true} />
        </div>
        <Button
          classes="rounded text-white p-bg-imp mb-0 h-38 px-4 py-lg-1 my-lg-0 mt-3 mb-1 w-100"
          activeClassName={"col-lg-auto px-0 pl-lg-3"}
          action={() =>
            props.authorizationFunction(authData, () =>
              showIniciarImplantacao()
            )
          }
          icon={"plus-circle"}
          name={`${gestora ? 'Cadastrar Cota' : 'Iniciar Implantação'}`}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showIniciarImplantacao,
      authorizationFunction,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  gestora: currEnv == process.env.REACT_APP_ENV_GEST_ID,
});

export default connect(mapStateToProps, mapDispatchToProps)(OperacaoHeader);
