/* eslint eqeqeq: 0 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  LISTA_ROUTE_PATH,
  APROVADORES_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  NOVO_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

import AprovadoresPage from "../pages/AprovadoresPage";
import AprovadoresIncludePage from "../pages/AprovadoresIncludePage";
import AprovadoresUpdatePage from "../pages/AprovadoresUpdatePage";

export default () => (
  <Switch>
    <Route exact={true} path={`/${APROVADORES_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={AprovadoresPage}/>
    <Route exact={true} path={`/${APROVADORES_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={AprovadoresIncludePage} />
    <Route exact={true} path={`/${APROVADORES_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idAprovador`} component={AprovadoresUpdatePage} />
  </Switch>
);
