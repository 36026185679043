/* eslint eqeqeq: 0 */

import { dataMaiorQue } from "../../../../../../utils/helpers/DateTimeHelper";
import { emailValido } from "../../../../../../utils/helpers/EmailHelper";

// const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");
// const gestora = currEnv == process.env.REACT_APP_ENV_GEST_ID;

export const validate = (values) => {
  const CL = values.contatos;

  const possuiPmtFlat = values?.possuiPagamentoFlat == "Sim";
  const despReembFlat = values?.despesaPagamentoFlat?.despesaReembolsavel == "Sim";
  const idForPmtFlat = values?.despesaPagamentoFlat?.idFormaPagamento?.key;
  const idDebTransfFlat = idForPmtFlat == 1 || idForPmtFlat == 3;

  const possuiPmtRecorrente = values?.possuiPagamentoRecorrente == "Sim";
  const possuiCorrecaoRecorrente = values?.despesaPagamentoRecorrente?.possuiCorrecao == "Sim";
  const despReembRecorrente = values?.despesaPagamentoRecorrente?.despesaReembolsavel == "Sim";
  const idForPmtRecorrente = values?.despesaPagamentoRecorrente?.idFormaPagamento?.key;
  const idDebTransfRecorrente = idForPmtRecorrente == 1 || idForPmtRecorrente == 3;

  const enviarParaRevisao = values?.enviarParaRevisao;
  const enviarParaValidacao = values?.enviarParaValidacao;

  let errors = {
    // Contrato
    controleDespesa: !values.controleDespesa ? "Preenchimento obrigatório" : null,
    descricaoPatrimonioSeparado: !values.descricaoPatrimonioSeparado ? "Preenchimento obrigatório" : null,
    serie: !values.serie ? "Preenchimento obrigatório" : null,
    idDespesaTipo: !values.idDespesaTipo ? "Preenchimento obrigatório" : null,
    idEmpresaPrestadora: !values.idEmpresaPrestadora ? "Preenchimento obrigatório" : null,
    idDocumentoSuporte: !values.idDocumentoSuporte ? "Preenchimento obrigatório" : null,
    referenciaDocumento: !values.referenciaDocumento ? "Preenchimento obrigatório" : null,
    
    // Pagamento Flat
    possuiPagamentoFlat: !values.possuiPagamentoFlat ? "Preenchimento obrigatório" : null,
    despesaPagamentoFlat: {
      valorBruto: possuiPmtFlat && (values.despesaPagamentoFlat.valorBruto == null || values.despesaPagamentoFlat.valorBruto === '') ? "Preenchimento obrigatório" : null,
      dataVencimento: possuiPmtFlat && !values.despesaPagamentoFlat.dataVencimento ? "Preenchimento obrigatório" : null,
      despesaReembolsavel: possuiPmtFlat && !values.despesaPagamentoFlat.despesaReembolsavel ? "Preenchimento obrigatório" : null,
      idReembolsante: possuiPmtFlat && despReembFlat && !values.despesaPagamentoFlat.idReembolsante ? "Preenchimento obrigatório" : null,
      idOrigemRecurso: possuiPmtFlat && !values.despesaPagamentoFlat.idOrigemRecurso ? "Preenchimento obrigatório" : null,
      idContaOrigem: possuiPmtFlat && !values.despesaPagamentoFlat.idContaOrigem ? "Preenchimento obrigatório" : null,
      idFormaPagamento: possuiPmtFlat && !values.despesaPagamentoFlat.idFormaPagamento ? "Preenchimento obrigatório" : null,
      idContaDestino: possuiPmtFlat && idDebTransfFlat && !values.despesaPagamentoFlat.idContaDestino ? "Preenchimento obrigatório" : null,
    },
    
    // Pagamento Recorrente
    possuiPagamentoRecorrente: !values.possuiPagamentoRecorrente ? "Preenchimento obrigatório" : null,
    despesaPagamentoRecorrente: {
      valorBrutoInicial: possuiPmtRecorrente && (values.despesaPagamentoRecorrente.valorBrutoInicial == null || values.despesaPagamentoRecorrente.valorBrutoInicial === '') ? "Preenchimento obrigatório" : null,
      dataPrimeiraParcela: possuiPmtRecorrente && !values.despesaPagamentoRecorrente.dataPrimeiraParcela ? "Preenchimento obrigatório" : null,
      dataVencimento: (possuiPmtRecorrente && !values.despesaPagamentoRecorrente.dataVencimento) ? "Preenchimento obrigatório" : (possuiPmtRecorrente && !dataMaiorQue(values.despesaPagamentoRecorrente.dataVencimento, values?.despesaPagamentoRecorrente?.dataPrimeiraParcela)) ? "A Data de Vencimento deve ser posterior à Data de Início" : null,
      diaPagamento: possuiPmtRecorrente && !values.despesaPagamentoRecorrente.diaPagamento ? "Preenchimento obrigatório" : null,
      periodicidade: possuiPmtRecorrente && !values.despesaPagamentoRecorrente.periodicidade ? "Preenchimento obrigatório" : null,
      quantidadeParcela: possuiPmtRecorrente && !values.despesaPagamentoRecorrente.quantidadeParcela ? "Preenchimento obrigatório" : null,
      valorContrato: possuiPmtRecorrente && (values.despesaPagamentoRecorrente.valorContrato == null || values.despesaPagamentoRecorrente.valorContrato === '') ? "Preenchimento obrigatório" : null,
      possuiCorrecao: possuiPmtRecorrente && !values.despesaPagamentoRecorrente.possuiCorrecao ? "Preenchimento obrigatório" : null,
      indexadorCorrecao: possuiPmtRecorrente && possuiCorrecaoRecorrente && !values.despesaPagamentoRecorrente.indexadorCorrecao ? "Preenchimento obrigatório" : null,
      mesesDefasagem: possuiPmtRecorrente && possuiCorrecaoRecorrente && (values.despesaPagamentoRecorrente.mesesDefasagem == null) ? "Preenchimento obrigatório" : null,
      mesCorrecao: possuiPmtRecorrente && possuiCorrecaoRecorrente && !values.despesaPagamentoRecorrente.mesCorrecao ? "Preenchimento obrigatório" : null,
      despesaReembolsavel: possuiPmtRecorrente && !values.despesaPagamentoRecorrente.despesaReembolsavel ? "Preenchimento obrigatório" : null,
      idReembolsante: possuiPmtRecorrente && despReembRecorrente && !values.despesaPagamentoRecorrente.idReembolsante ? "Preenchimento obrigatório" : null,
      idOrigemRecurso: possuiPmtRecorrente && !values.despesaPagamentoRecorrente.idOrigemRecurso ? "Preenchimento obrigatório" : null,
      idContaOrigem: possuiPmtRecorrente && !values.despesaPagamentoRecorrente.idContaOrigem ? "Preenchimento obrigatório" : null,
      idFormaPagamento: possuiPmtRecorrente && !values.despesaPagamentoRecorrente.idFormaPagamento ? "Preenchimento obrigatório" : null,
      idContaDestino: possuiPmtRecorrente && idDebTransfRecorrente && !values.despesaPagamentoRecorrente.idContaDestino ? "Preenchimento obrigatório" : null,
      modalidade: possuiPmtRecorrente && !values.despesaPagamentoRecorrente.modalidade ? "Preenchimento obrigatório" : null,
    },

    // Contato
    contatos:
      CL && CL.length
        ? CL.map((ct) => ({
            nome: !ct.nome
              ? "Preenchimento obrigatório"
              : ct.nome.trim().split(" ").length < 2
              ? "Insira nome e sobrenome"
              : null,

            telefone: !ct.telefone
              ? "Preenchimento obrigatório"
              : ct.telefone.length < 14
              ? "Formato inválido"
              : null,

            email: !ct.email
              ? "Preenchimento obrigatório"
              : !emailValido(ct.email)
              ? "Formato de email inválido "
              : null,
          })).filter(Boolean)
        : null,
  };

  if (enviarParaRevisao) {
    errors = {
      ...errors,
      descricaoValidacao: !values.descricaoValidacao ? "Preenchimento obrigatório" : null,
    }
  }

  if (enviarParaValidacao) {
    errors = {
      ...errors,
      documentos: !values.documentos || values.documentos?.length <= 0 ? "Obrigatório anexar o documento suporte" : null,
    }
  }

  return errors;
};
