import React from 'react';
import { NumericFormat } from 'react-number-format';
import ReactTooltip from 'react-tooltip';

const PercentageInput = ({
  input,
  meta,
  label,
  inputId,
  className,
  outerClass,
  readOnly,
  required,
  icon,
  tooltipMessage,
  tooltipId,
  iconColor,
  limitTo,
  limitToHundred,
  placeholder,
  decimalScale = 2,
  suffix = "%",
  decimalSeparator,
}) => {

  const handleValueChange = (values) => {
    const { floatValue } = values || {};

    let newValue = floatValue;

    if (limitTo !== undefined) {
      newValue = newValue > limitTo ? limitTo : newValue;
    } else if (limitToHundred) {
      newValue = newValue > 100 ? 100 : newValue;
    }

    input.onChange(newValue || '');
  };

  const classNameInput = (className) => {
    let name = 'form-control';
    if (className) name += ' ' + className;
    return name;
  };

  return (
    <div className={`form-group field-floating has-float-label ${outerClass} ${readOnly && 'disabled-field'}`}>
      <NumericFormat
        decimalSeparator={decimalSeparator}
        className={classNameInput(className)}
        id={"txtfield_" + inputId}
        type="text"
        suffix={suffix}
        fixedDecimalScale
        value={input.value}
        disabled={readOnly}
        placeholder={placeholder}
        decimalScale={decimalScale}
        onValueChange={handleValueChange}
        isAllowed={({ floatValue, value }) => (limitToHundred ? (floatValue <= 100 && floatValue >= 0) : true) || value === ''}
      />
      {label && (
        <label
          htmlFor={input.name}
          className={`control-label ${className}`}
          id={"lbl_" + inputId}
        >
          {label}
          {required && <span className="text-warning font-weight-bold ml-1">*</span>}
          {icon && tooltipMessage && (
            <>
              <i
                className={`fa fa-${icon} icon-margin-l-5`}
                aria-hidden="true"
                style={{color: iconColor, cursor: "default"}}
                data-tip={tooltipMessage}
                data-for={tooltipId || label}
              />
              <ReactTooltip
                noArrow
                effect="solid"
                className="px-2 py-1"
                globalEventOff="click"
                style={{ width: "100px" }}
                textColor="#333"
                backgroundColor="#fff"
                id={tooltipId || label}
              />
            </>
          )}
        </label>
      )}
      {meta && meta.error && meta.touched && (
        <p className="error-msg sp-error text-danger f-12 mb-0">{meta.error}</p>
      )}
    </div>
  );
};

export default PercentageInput;
