/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { bindActionCreators } from "redux";
import Input from "../../../../../common/input/Input";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import MaskedInput from "../../../../../common/input/MaskedInput";
import {
  loadClassesFundoList,
  limpaDependenteOnChange,
} from "../../../../../../actions/subclasse/SubclasseAction";

class SubclasseFormGeral extends Component {
  render() {
    const {
      loadClassesFundoList,
      limpaDependenteOnChange,

      readOnly,
      usuarios,
      administradorFiduciarios,
      gestoras,
      gestorasII,
      consultorCreditos,
      controladorias,
      distribuidores,
      custodiantes,
      agenteMonitoramentos,
      agenteFormalizacoes,
      agenteCobrancaJudiciais,
      agenteCobrancaExtrajudiciais,
      auditorias,
      assessorLegais,
      escrituradores,
      status,
      divulgacoes,
      fundosList,
      classesList,
      subClasses,

      possuiMultiGestao,
      possuiTaxaPerformance,
      possuiGrossUp,

      moedaDinamica,
      fundoOperacao,
      subClasseObj,
      classesLoaded,
      dataDaPrimeiraIntegralizacao,
      periodicidadeTaxasList
    } = this.props;

    let moedaSimbolo = moedaDinamica || fundoOperacao?.moeda?.simbolo || "R$";

    let subclasseHeader = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Subclasse</span>
          <div className="row">
            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="idFundo"
                name="idFundo"
                label="Fundo"
                options={fundosList}
                textField="label"
                valueField="key"
                readOnly={readOnly}
                filter="contains"
                onChange={(_props, e) => loadClassesFundoList(e)}
                required
              />
            </div>

            {classesLoaded && (
              <div className="col-lg-3 col-12">
                <Field
                  component={DropdownListInput}
                  inputId="idClasse"
                  name="idClasse"
                  label="Classe"
                  options={classesList}
                  textField="nomeDaClasse"
                  valueField="id"
                  readOnly={readOnly}
                  filter="contains"
                  required
                />
              </div>
            )}

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="idSubClasse"
                name="idSubClasse"
                label="Subclasse"
                options={subClasses}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={Input}
                inputId="codigoSubClasse"
                name="codigoSubClasse"
                type="text"
                label="Código Subclasse"
                readOnly={true}
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="idDivulgacao"
                name="idDivulgacao"
                label="Divulgação"
                options={divulgacoes}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="status"
                name="status"
                label="Status"
                options={status}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>
          </div>
        </div>
      </div>
    );

    let subclasseControle = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Controle</span>
          <div className="row">
            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="idUsuarioEstruturacao"
                name="idUsuarioEstruturacao"
                label="Estruturação"
                options={usuarios}
                textField="nome"
                valueField="id"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="idUsuarioGestao"
                name="idUsuarioGestao"
                label="Gestão"
                options={usuarios}
                textField="nome"
                valueField="id"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="idUsuarioServicoCredito"
                name="idUsuarioServicoCredito"
                label="Serviço de Crédito"
                options={usuarios}
                textField="nome"
                valueField="id"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="idUsuarioCompliance"
                name="idUsuarioCompliance"
                label="Compliance"
                options={usuarios}
                textField="nome"
                valueField="id"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>
          </div>
        </div>
      </div>
    );

    let subclasseDatasPrecos = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Datas e Preços</span>
          <div className="row">
            <div className="col-lg-3 col-12">
              <Field
                component={Input}
                inputId="dataEmissao"
                name="dataEmissao"
                type="date"
                label="Data da Emissão"
                readOnly={true}
                required
              />
            </div>

            {subClasseObj && (
              <>
                <div className="col-lg-3 col-12">
                  <Field
                    component={Input}
                    inputId="dataDaPrimeiraIntegralizacao"
                    name="dataDaPrimeiraIntegralizacao"
                    type={!dataDaPrimeiraIntegralizacao ? 'text' : 'date'}
                    label="Data da Primeira Integralização"
                    readOnly={true}
                  />
                </div>

                <div className="col-lg-3 col-12">
                  <Field
                    component={MaskedInput}
                    inputId="totalDistribuido"
                    name="totalDistribuido"
                    label="Total Distribuído"
                    readOnly={true}
                    prefix={`${moedaSimbolo} `}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={8}
                    fixedDecimalScale
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );

    let subclasseTaxas = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Taxas</span>
          <div className="row">
            <div className="col-lg-3 col-12">
              <Field
                component={MaskedInput}
                inputId="taxaGestao"
                name="taxaGestao"
                type="text"
                label="Taxa de Gestão"
                readOnly={readOnly}
                required
                suffix={"%"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={4}
                fixedDecimalScale
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="periodicidadeTaxa"
                name="periodicidadeTaxa"
                label="Periodicidade Taxa"
                options={periodicidadeTaxasList}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={Input}
                inputId="taxaGestaoMinima"
                name="taxaGestaoMinima"
                type="text"
                label="Taxa de Gestão (Mínima)"
                readOnly={readOnly}
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="possuiGrossUp"
                name="possuiGrossUp"
                label="Possui Gross-Up?"
                readOnly={readOnly}
                onChange={() => limpaDependenteOnChange("grossUp")}
                required
              />
            </div>

            {possuiGrossUp == "Sim" && (
              <div className="col-lg-3 col-12">
                <Field
                  component={MaskedInput}
                  inputId="grossUp"
                  name="grossUp"
                  type="text"
                  label="Gross-Up"
                  readOnly={readOnly}
                  required
                  suffix={"%"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={4}
                  fixedDecimalScale
                />
              </div>
            )}

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="possuiTaxaPerformance"
                name="possuiTaxaPerformance"
                label="Possui Taxa de Performance?"
                readOnly={readOnly}
                onChange={() => limpaDependenteOnChange("taxaPerformance")}
                required
              />
            </div>

            {possuiTaxaPerformance == "Sim" && (
              <div className="col-lg-3 col-12">
                <Field
                  component={Input}
                  inputId="taxaPerformance"
                  name="taxaPerformance"
                  type="text"
                  label="Taxa de Performance"
                  readOnly={readOnly}
                  required
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );

    let subclasseParticipantes = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Participantes</span>
          <div className="row">
            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="administradorFiduciario"
                name="administradorFiduciario"
                label="Administrador Fiduciário"
                options={administradorFiduciarios}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={true}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="gestora"
                name="gestora"
                label="Gestora"
                options={gestoras}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={true}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="possuiMultiGestao"
                name="possuiMultiGestao"
                label="Multigestão?"
                readOnly={true}
                filter="contains"
                onChange={() => limpaDependenteOnChange("gestoraII", null)}
                required
              />
            </div>

            {possuiMultiGestao == "Sim" && (
              <div className="col-lg-3 col-12">
                <Field
                  component={DropdownListInput}
                  inputId="gestoraII"
                  name="gestoraII"
                  label="Gestora II"
                  options={gestorasII}
                  textField="razaoSocialComCnpj"
                  valueField="idEmpresaPrestadora"
                  readOnly={true}
                  filter="contains"
                  required
                />
              </div>
            )}

            {/*  */}

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="consultorCredito"
                name="consultorCredito"
                label="Consultor de Crédito"
                options={consultorCreditos}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={true}
                filter="contains"
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="controladoria"
                name="controladoria"
                label="Controladoria"
                options={controladorias}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={true}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="distribuidor"
                name="distribuidor"
                label="Distribuidor"
                options={distribuidores}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="escriturador"
                name="escriturador"
                label="Escriturador"
                options={escrituradores}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            {/*  */}

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="custodiante"
                name="custodiante"
                label="Custodiante"
                options={custodiantes}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="agenteMonitoramento"
                name="agenteMonitoramento"
                label="Agente de Monitoramento"
                options={agenteMonitoramentos}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="agenteFormalizacao"
                name="agenteFormalizacao"
                label="Agente de Formalização"
                options={agenteFormalizacoes}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="assessorLegal"
                name="assessorLegal"
                label="Assessor Legal"
                options={assessorLegais}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
              />
            </div>

            {/*  */}

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="agenteCobrancaJudicial"
                name="agenteCobrancaJudicial"
                label="Agente de Cobrança Judicial"
                options={agenteCobrancaJudiciais}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="agenteCobrancaExtrajudicial"
                name="agenteCobrancaExtrajudicial"
                label="Agente de Cobrança Extrajudicial"
                options={agenteCobrancaExtrajudiciais}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="auditoria"
                name="auditoria"
                label="Auditor Independente"
                options={auditorias}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="d-flex flex-column mt-4">
        {subclasseHeader}
        {subclasseControle}
        {subclasseDatasPrecos}
        {subclasseTaxas}
        {subclasseParticipantes}
      </div>
    );
  }
}

const selector = formValueSelector("subclasseForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadClassesFundoList,
      limpaDependenteOnChange,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  usuarios: state.subclasseReducer.subclasseFormTypes.usuarios,
  administradorFiduciarios:
    state.subclasseReducer.subclasseFormTypes.administradorFiduciarios,
  gestoras: state.subclasseReducer.subclasseFormTypes.gestoras,
  gestorasII: state.subclasseReducer.subclasseFormTypes.gestorasII,
  consultorCreditos:
    state.subclasseReducer.subclasseFormTypes.consultorCreditos,
  controladorias: state.subclasseReducer.subclasseFormTypes.controladorias,
  distribuidores: state.subclasseReducer.subclasseFormTypes.distribuidores,
  custodiantes: state.subclasseReducer.subclasseFormTypes.custodiantes,
  agenteMonitoramentos:
    state.subclasseReducer.subclasseFormTypes.agenteMonitoramentos,
  agenteFormalizacoes:
    state.subclasseReducer.subclasseFormTypes.agenteFormalizacoes,
  agenteCobrancaJudiciais:
    state.subclasseReducer.subclasseFormTypes.agenteCobrancaJudiciais,
  agenteCobrancaExtrajudiciais:
    state.subclasseReducer.subclasseFormTypes.agenteCobrancaExtrajudiciais,
  auditorias: state.subclasseReducer.subclasseFormTypes.auditorias,
  assessorLegais: state.subclasseReducer.subclasseFormTypes.assessorLegais,
  escrituradores: state.subclasseReducer.subclasseFormTypes.escrituradores,
  status: state.subclasseReducer.subclasseFormTypes.status,
  divulgacoes: state.subclasseReducer.subclasseFormTypes.divulgacoes,
  fundosList: state.subclasseReducer.subclasseFormTypes.fundosList,
  classesList: state.subclasseReducer.subclasseFormTypes.classesList,
  periodicidadeTaxasList: state.subclasseReducer.subclasseFormTypes.periodicidadeTaxas,
  classesLoaded: state.subclasseReducer.subclasseFormTypes.classesLoaded,
  subClasses: state.subclasseReducer.subclasseFormTypes.subClasses,
  subClasseObj: state.subclasseReducer.subclasseFormTypes.subClasse,
  possuiMultiGestao: selector(state, "possuiMultiGestao") || null,
  possuiTaxaPerformance: selector(state, "possuiTaxaPerformance") || null,
  possuiGrossUp: selector(state, "possuiGrossUp") || null,
  dataDaPrimeiraIntegralizacao: selector(state, "dataDaPrimeiraIntegralizacao") || null,
});

export default connect(mapStateToProps, mapDispatchToProps)(SubclasseFormGeral);
