import {
    AGING_FETCHED,
    SHOW_GERAR_AGING_DIALOG
} from "../../actions/precificacao/AgingAction";


const INITIAL_STATE = {
    relatorioAging: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    showGerarAgingDialog: false,
    messages: null, 
    isLoading: false,
}

export default function (state = INITIAL_STATE, action = {}) {    
    switch(action.type) {
        case AGING_FETCHED:
            return {
                ...state,
                relatorioAging: action.payload.content,
                messages: action.payload.messages,
                isLoading: true,                
            }
        case SHOW_GERAR_AGING_DIALOG:
          return {
              ...state,
              showGerarAgingDialog: action.payload
          }
        default:
            return state;
    }
}