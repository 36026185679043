import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import GenericPickerInput from "../../common/input/GenericPickerInput";

import { 
    showPatrimonioList, 
    getSugestaoPatrimonio,
    selectedPatrimonioOption,
 } from "../../../actions/patrimonio/PatrimonioAction";

const PatrimonioPicker = (props) => (
    <div>
        <div>
            <GenericPickerInput 
                loadData={props.getSugestaoPatrimonio}
                options={props.options}
                selectedOptionForm={props.selectedPatrimonioOption}                
                placeholder={"Pesquisar Patrimônio"}
                loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                noOptionsMessage="Nenhum registro encontrado"
                isMulti={true}
                activeClassName={props.classes}
            />
        </div>
    </div>
);

const mapDispatchToProps = dispatch => bindActionCreators({
    getSugestaoPatrimonio,
    selectedPatrimonioOption,
    showPatrimonioList,
}, dispatch);

const mapStateToProps = state => ({
    options: state.patrimonioReducer.patrimoniosOptions,
    patrimonios: state.patrimonioReducer.selectedPatrimonioOptions
});

export default connect(mapStateToProps, mapDispatchToProps)(PatrimonioPicker);
