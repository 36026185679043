import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field, formValueSelector } from "redux-form";

import If from "../../../../../common/If/If";
import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";
import Input from "../../../../../common/input/Input";
import Button from "../../../../../common/button/Button";
import DropdownListInput from "../../../../../common/input/DropdownListInput";

import {
  changeTaxaFilter,
  getIndices,
  exportExcel,
} from "../../../../../../actions/precificacao/IndiceAction";

let IndiceListFilter = (props) => {
  const {
    getIndices,
    exportExcel,
    changeTaxaFilter,
    dataInicio,
    dataFim,
    tipoTaxa,
    taxaList,
    taxaMensal,
    alteraCabecalho,
  } = props;

  return (
    <div className="w-100 px-3">
      <Form>
        <FormHeader title={"Índices"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getIndices}
        >
          <div className="column">
            <div className="row px-0">
              <div className="col-lg-2">
                <Field
                  component={DropdownListInput}
                  inputId="taxa"
                  name="taxa"
                  label="Tipo Taxa"
                  options={taxaList}
                  textField="descricao"
                  valueField="id"
                  filter="contains"
                  onChange={(value) =>
                    changeTaxaFilter(value, dataInicio, alteraCabecalho)
                  }
                />
              </div>
              <div className="col-lg-3">
                <Field
                  component={Input}
                  className={"filter-input"}
                  inputId="dataInicio"
                  type={taxaMensal ? "month" : "date"}
                  name="dataInicio"
                  label="Data Início"
                />
              </div>
            </div>
            <div className="row px-0">
              <div className="col-lg-12 d-flex flex-row align-items-center">
                <Button
                  action={() => getIndices({ dataInicio, dataFim, tipoTaxa })}
                  classes="p-bg-imp rounded"
                  icon={"search"}
                  name={"Buscar"}
                />
                <If test={tipoTaxa}>
                  <label
                    role="button"
                    className="mb-0 p-color-lighten ml-3"
                    onClick={() =>
                      exportExcel({ dataInicio, dataFim, tipoTaxa }, taxaMensal)
                    }
                  >
                    Exportar para Excel
                  </label>
                </If>
              </div>
            </div>
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

IndiceListFilter = reduxForm({ form: "indiceForm" })(IndiceListFilter);
const selector = formValueSelector("indiceForm");

const mapStateToProps = (state) => ({
  dataInicio: selector(state, "dataInicio"),
  tipoTaxa: selector(state, "taxa"),
  taxaMensal: state.indiceReducer.taxaMensal,
  taxaList: state.indiceReducer.indiceFormTypes.taxaList || [],
  alteraCabecalho: state.indiceReducer.alterarCabecalhoParaTaxaMensal,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeTaxaFilter,
      getIndices,
      exportExcel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(IndiceListFilter);
