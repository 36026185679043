import axios from "axios";

import { showAllMessages } from "./../../actions/message/ErrorMessagesAPIAction";
import { downloadExcelFile, downloadFile } from "./../../utils/helpers/FileHelper";

import {
    deseralizeReportFormFilter,
    deserializeFieldsReportFormFilter,
    deserializeFieldsReportFormFilterExportExcel
} from "./../../components/business/relatorios/mappings/ReportMap";
import { change } from "redux-form";
import { toastr } from "react-redux-toastr";

export const LOAD_FILTER_FORM_INDICE_TIPO = "LOAD_FILTER_FORM_INDICE_TIPO";
export const REPORT_GETALL = "REPORT_GETALL";
export const SHOW_GERAR_REPORT_DIALOG = "SHOW_GERAR_REPORT_DIALOG";
export const GERAR_REPORT = "GERAR_REPORT";
export const CLEAR_REPORT = "CLEAR_REPORT";
export const CLEAR_SELECTEDS = "CLEAR_SELECTEDS";
export const GENERATE_REPORT_IDS = "GENERATE_REPORT_IDS";

const REPORT_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PRECIFICACAO_API + process.env.REACT_APP_PATH_AGING_API;

const SINTETIC_REPORT_API = 
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API +
    process.env.REACT_APP_PATH_PASSIVO_API + process.env.REACT_APP_PATH_RELATORIO_GESTAO_FIDUCIARIA;


const PASSIVO_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API + process.env.REACT_APP_PATH_PASSIVO_API;

export const RELATORIO_ANALITICO_TIPO_AGING = 1;
export const RELATORIO_ANALITICO_TIPO_GESTAO_FIDUCIARIA = 2;


export function handleSearchRepots(params, page = 1){
    const { typeReport, dataBase } = params;

    return (dispatch) =>{
        if (params.typeReport && params.typeReport.key) {
            if (params.typeReport.key === RELATORIO_ANALITICO_TIPO_AGING) {
                dispatch([getReports({typeReport, dataBase },page)])
            }
            if (params.typeReport.key === RELATORIO_ANALITICO_TIPO_GESTAO_FIDUCIARIA) {
                dispatch([getFiduciaryReports(params, page)])
            }
        }
    }
}    

export function getReports(filters, page = 1, ) {
    const params = deserializeFieldsReportFormFilter(filters, page);

    return (dispatch) => {
        return axios
            .post(`${REPORT_API}listar`, { ...params })
            .then(result =>
                dispatch([
                    {
                        type: REPORT_GETALL,
                        payload: result,
                    },
                ])
            );
    }
}

export async function getPendingReports() {
    await axios.get(`${PASSIVO_API}relatorios-pendentes`);

    return dispatch => {
        dispatch([])
    };
}

export function exportFileReport(idAnexo) {
    return (dispatch) => {
      return axios.get(`${PASSIVO_API}relatorio/${idAnexo}`).then((result) => {
        const { content } = result;
  
        dispatch([
          content?.arquivo
            ? downloadFile(content.arquivo, content.nomeArquivo)
            : toastr.info("Informação", "Arquivo em processamento na base, por favor, aguarde!"),
          showAllMessages(result.messages),
        ]);
      });
    };
  }

export function saveSelectedValues(arr) {
    let relatorios = [];

    if (Array.isArray(arr) && arr.length > 0) {
      relatorios = arr.map(el => ({ dataBase: el.dataBase, idOperacao: el.idOperacao }));
    }

    return dispatch => {
        dispatch({
            type: GENERATE_REPORT_IDS,
            payload: relatorios
        })
    };
}

export function loadFilterFormTipoRelatorio() {
    return dispatch => {
        return axios.get(REPORT_API + 'tela/filtro')
            .then(result =>
                dispatch({
                    type: LOAD_FILTER_FORM_INDICE_TIPO,
                    payload: result.content
                })
            );
    };
}

export function showGerarReportDialog(showDialog) {
    return (dispatch) => {
        return dispatch([
            {
                type: SHOW_GERAR_REPORT_DIALOG,
                payload: showDialog
            },
        ])
    }
}

export function exportRelatorioAgingToExcel(filters) {
    let params = deserializeFieldsReportFormFilterExportExcel(filters);

    return (dispatch) => {
        return axios
            .post(`${REPORT_API}exportar/excel`, { ...params })
            .then(result => {
                const fileName = 'RelatorioAging.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function createReport(filters) {
    let params = deseralizeReportFormFilter(filters);
    return (dispatch) => {
        return axios
            .post(`${REPORT_API}gerar`, { ...params })
            .then(result => {
                dispatch([
                    showGerarReportDialog(false),
                    {
                        type: GERAR_REPORT,
                        payload: result,
                    },
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function clearReport() {
    return (dispatch) => {
        dispatch([
            change("RelatorioForm", "dataBase", null),
            change("RelatorioForm", "operacao", null),
            change("RelatorioForm", "gestor", null),
            change("RelatorioForm", "status", null),
            saveSelectedValues(),
            {
                type: CLEAR_REPORT,
                payload: null,
            },
        ])
    }
}

export function clearSelecteds() {
    return (dispatch) => {
        dispatch([
            {
                type: CLEAR_SELECTEDS
            },
        ])
    }
}

export function sinteticRepot(params) {
    return (dispatch) => {
        return axios
            .post(SINTETIC_REPORT_API, { relatorios: params })
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function selectOperacaoNoForm(option, formName, fieldName) {
    return (dispatch) => {
        return dispatch(
            change(formName, fieldName, option)
        )
    }
}
export function getFiduciaryReports(filters, page = 1, ) {
    const params = deserializeFieldsReportFormFilter(filters, page);

    return (dispatch) => {
        return axios
            .post(`${SINTETIC_REPORT_API}/listar`, { ...params })
            .then(result =>
                dispatch([
                    saveSelectedValues(),
                    clearSelecteds(),
                    {
                        type: CLEAR_REPORT,
                        payload: null,
                    },
                    {
                        type: REPORT_GETALL,
                        payload: result,
                    },
                ])
            );
    }
}

export function exportRelatorioGestaoFiduciariaToExcel() {
    return (dispatch) => {
        return axios
            .post(`${SINTETIC_REPORT_API}/planilha`)
            .then(result => {
                const fileName = 'RelatorioGestaoFiduciaria.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}