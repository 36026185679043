/* eslint eqeqeq: 0 */

import axios from "axios";
import { toastr } from "react-redux-toastr";

import history from "../../components/common/routers/history";
import {
  OPCAO_SISTEMA_ROUTE_PATH,
  OPCAO_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  DETALHAR_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";

import { showAllMessages } from "../message/ErrorMessagesAPIAction";
import { showConfirmDialog } from "../common/modal/ConfirmDialogAction";
import { change, initialize } from "redux-form";
import { deserializeFieldsOpcaoForm } from "../../components/business/operacao/mappings/OpcaoMap";

export const INDICE_SHOW_DELETE_DIALOG = "INDICE_SHOW_DELETE_DIALOG";
export const LOAD_FILTER_FORM_OPCAO = "LOAD_FILTER_FORM_OPCAO";
export const LOAD_FORM_OPCAO = "LOAD_FORM_OPCAO";
export const CLEAN_LIST_CONTENT = "CLEAN_LIST_CONTENT";
export const SET_TIPO_VALUE = "SET_TIPO_VALUE";

const OPCAO_API = process.env.REACT_APP_URL_API;

export function showOpcaoList(tipo) {
  return (dispatch) => {
    return dispatch([
      cleanResult(),
      showConfirmDialog(false),
      history.push(
        `/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`
        ),
      tipo && changeTipoFilter(tipo),
      tipo && getTipos(tipo),
    ]);
  };
}

export function alterarFormArquivo(arquivo = null, nome = null) {
  return (dispatch) => {
    return dispatch([
      change("OpcaoForm", "arquivo", arquivo),
      change("OpcaoForm", "nomeArquivo", nome),
      document.getElementById("arquivoSeloOpcao").value = "",
    ]);
  };
}

export function getTipos(tipo, page = 1, tamanhoPagina = 10) {
  return (dispatch) => {
    return axios
      .get(OPCAO_API + `${tipo}?pagina=${page}&tamanhoPagina=${tamanhoPagina}`)
      .then((result) =>
        dispatch({
          type: LOAD_FILTER_FORM_OPCAO,
          payload: result.content,
        })
      );
  };
}

export function changeTipoFilter(id) {
  return (dispatch) => {
    return dispatch([
      {
        type: SET_TIPO_VALUE,
        payload: id,
      },
    ]);
  };
}

export function saveOpcao(item, tipo) {
  return (dispatch) => {
    let paramsDeserialized = item;
    if (tipo == 'tipo-aprovacao') paramsDeserialized = deserializeFieldsOpcaoForm(item);

    return axios
      .post(OPCAO_API + tipo, paramsDeserialized)
      .then((result) =>
        dispatch([showOpcaoList(tipo), showAllMessages(result.messages)])
      );
  };
}

export function editOpcao(item, tipo) {
  return (dispatch) => {
    let paramsDeserialized = item;
    if (tipo == 'tipo-aprovacao') paramsDeserialized = deserializeFieldsOpcaoForm(item);

    return axios
      .put(OPCAO_API + tipo, paramsDeserialized)
      .then((result) =>
        dispatch([showOpcaoList(tipo), showAllMessages(result.messages)])
      );
  };
}

export function changeValueForm(field, value = "") {
  return (dispatch) => {
    dispatch([change("OpcaoForm", field, value)]);
  };
}

export function removeOpcao(id, tipo) {
  return (dispatch) => {
    return axios
      .delete(OPCAO_API + `${tipo}/${id}`)
      .then((result) =>
        dispatch([
          showAllMessages(result.messages),
          showOpcaoDeleteDialog(false, 0),
          showOpcaoList(tipo),
        ])
      );
  };
}

export function loadFormOpcao(id, editAction, tipo) {
  return (dispatch) => {
    return axios
      .get(OPCAO_API + `${tipo}/detalhe/${id || ""}`)
      .then((result) => {
        let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;

        dispatch([
          initialize("OpcaoForm", result.content),
          id
            ? history.push(
                `/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${tipo}/${routeAction}/${id}`
              )
            : undefined,
          {
            type: LOAD_FORM_OPCAO,
            payload: result.content,
          },
        ]);
      });
  };
}

export function cleanResult() {
  return { type: CLEAN_LIST_CONTENT };
}

export function showOpcaoForm(tipo) {
  return (dispatch) => {
    return dispatch([
      history.push(
        `/${OPCAO_SISTEMA_ROUTE_PATH}/${OPCAO_ROUTE_PATH}/${tipo}/${NOVO_ROUTE_PATH}`
      ),
    ]);
  };
}

export function showOpcaoDeleteDialog(showDialog, idOpcao) {
  return (dispatch) => {
    return dispatch([
      {
        type: INDICE_SHOW_DELETE_DIALOG,
        payload: { showDialog, idOpcao },
      },
    ]);
  };
}
