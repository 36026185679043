import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PatrimonioSeriesVinculadas from "./PatrimonioSeriesVinculadas";

import { loadFormPatrimonioVinculos } from '../../../../../../actions/patrimonio/PatrimonioAction';

class PatrimonioFormSeriesVinculadas extends Component{
  componentDidMount() {
    const patrimonioId = this.props.patrimonioId;
    const patrimonioVinculos = this.props.patrimonioVinculos;
    if (patrimonioId && patrimonioVinculos.length <= 0) this.props.loadFormPatrimonioVinculos(patrimonioId);
  }
  
  render() {
    const {
      readOnly,
      patrimonioVinculos,
    } = this.props;

    let patrimonioCodigoB3Cetip = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4 mb-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Séries Vinculadas ao Patrimônio Separado</span>
          <div>
            <PatrimonioSeriesVinculadas
              readOnly={readOnly}
              dataList={patrimonioVinculos}
            />
          </div>    
        </div>
      </div>
    );
  
    return (
      <div className="d-flex flex-column mt-4">
        {patrimonioCodigoB3Cetip}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  patrimonioId: state.patrimonioReducer.patrimonioFormTypes?.patrimonioSeparado?.id || null,
  patrimonioVinculos: state.patrimonioReducer?.vinculos || [],
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
  loadFormPatrimonioVinculos
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PatrimonioFormSeriesVinculadas);
