import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";

import SingleFileInput from "../../../../../common/input/SingleFileInput";
import { turnFileToBase64 } from "../../../../../../utils/helpers/FileHelper";
import { lancarConfirmacaoPagamento } from "../../../../../../actions/precificacao/PagamentoAction";
import { getCurrentDateStringDefault } from "../../../../../../utils/helpers/DateTimeHelper";

let ModalConfirmarPagamento = (props) => {
  const {
    close,
    open,
    action = true,
    pagamento,
    lancarConfirmacaoPagamento,
  } = props;

  const [dataPagamento, setDataPagamento] = React.useState("");
  const [comprovante, setComprovante] = React.useState("");

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Confirmação de {action ? "Pagamento" : "Inadimplência"}
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-3">
            {action ? (
              <div className="row px-3">
                <div
                  className={`form-group field-floating has-float-label mb-0 pl-0 pr-lg-3 pr-0 col-lg-4`}
                >
                  <input
                    type="date"
                    name="dataPagamento"
                    value={dataPagamento}
                    className="form-control w-100"
                    onChange={(e) => setDataPagamento(e.target.value)}
                  />
                  <label htmlFor="dataPagamento" className="control-label">
                    Data do Pagamento
                    <span className="text-warning font-weight-bold ml-1">
                      *
                    </span>
                  </label>
                </div>

                <SingleFileInput
                  label="Comprovante"
                  value={comprovante}
                  outerClassName="mb-0 px-0 col-lg-8"
                  removeFile={() => setComprovante("")}
                  onChange={(e) => setComprovante(e.target.files[0])}
                />
              </div>
            ) : (
              <div>
                <p className="fw-400">
                  Você está prestes a{" "}
                  <span className="text-danger font-weight-bold">
                    confirmar a inadimplência
                  </span>{" "}
                  do evento de pagamento do dia {pagamento?.dataPagamento} para
                  a operação {pagamento?.codigoOpea} - {pagamento?.apelido}{" "}
                  (Código IF: {pagamento?.codigoIF}).
                </p>

                <p className="mt-4 mb-0 fw-400">Tem certeza que deseja continuar?</p>
              </div>
            )}
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={`${!action ? "Não, " : ""} Voltar`}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={`${!action ? "Sim, " : ""} Confirmar`}
              action={async () => {
                lancarConfirmacaoPagamento({
                  confirmarPagamento: action,
                  nomeArquivoComprovante: comprovante?.name,
                  idPrecificacao: pagamento?.idPrecificacao,
                  idPagamentoPrecificacao: pagamento?.idPagamentoPrecificacao,
                  dataPagamento: dataPagamento || getCurrentDateStringDefault(),
                  arquivoComprovante: comprovante
                    ? await turnFileToBase64(comprovante)
                    : null,
                });

                close();
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      lancarConfirmacaoPagamento,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ModalConfirmarPagamento);
