import { getCurrentDateByFormat } from "../../../../utils/helpers/DateTimeHelper";

export function deserializeFieldsCalculoFormFilter(filters, page) {
    return {
        idsOperacao: filters && filters.operacao ? [filters.operacao.key] : [],
        dataEventoInicial:  filters.dataEventoInicial,
        dataEventoFinal:  filters.dataEventoFinal,
        diario: filters.diario || false,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        },
    }
}

export function deserializeFieldsCalculoFormFilterExportExcel(filters) {
    let partesNomeOperacao = filters && filters.operacao ? filters.operacao.value.split('-') : [];
    let currentDate = getCurrentDateByFormat("YYYYMMDD");

    return {
        idsOperacao: filters && filters.operacao ? [filters.operacao.key] : [],
        nomeOperacao: filters && filters.operacao ? partesNomeOperacao[0].trim() + "_" + partesNomeOperacao[3].trim() + "_" + currentDate : [],
        dataEventoInicial:  filters.dataEventoInicial,
        dataEventoFinal:  filters.dataEventoFinal,
        diario: filters.diario || false,
    }
}

export function deserializeFieldsPrecoFormFilter(filters, page) {
    return {
        idsOperacao: filters && filters.operacao ? filters.operacao.map(o => o.key) : [],
        dataEventoInicial:  filters.dataEventoInicial,
        dataEventoFinal:  filters.dataEventoFinal,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        },
    }
}

export function deserializeFieldsPrecoFormFilterExportExcel(filters) {
    return {
        idsOperacao: filters && filters.operacao ? [filters.operacao.key] : [],
        dataEventoInicial:  filters.dataEventoInicial,
        dataEventoFinal:  filters.dataEventoFinal,
    }
}