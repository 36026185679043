import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";
import Input from "../../../../../common/input/Input";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import Button from "../../../../../common/button/Button";
import { exportArquivo, importRegulatorios, loadFilter,
  loadRegulatorios } from "../../../../../../actions/relatorios/regulatorioAction";

import GenericPickerInput from "../../../../../common/input/GenericPickerInput";
import { authorizationFunction } from "../../../../../../actions/authorization/AuthorizationAction";
import { RELATORIOS_REGULATORIOS, READ } from "../../../../../../utils/actionsAndFeaturesAuth";
import { getSugestaoPatrimonio } from "../../../../../../actions/patrimonio/PatrimonioAction";
import FileButton from "../../../../../common/input/FileButton";

let RegulatorioFilter = (props) => {
  const {
    getSugestaoPatrimonio,
    patrimoniosList,
    regulatoriosConsult,
    loadFilter,
    loadRegulatorios,
    usuarioList,
    opcoesRelatorioList,
    filters,
    authorizationFunction,
    statusList,
    importRegulatorios,
    exportArquivo,
    idsXml,
  } = props;

  const fileRef = React.useRef(null);

  const [dataFinal, setDataFinal] = React.useState()
  const [dataInicio, setDataInicio] = React.useState()
  const [dataReferencia, setDataReferencia] = React.useState()
  const [idsPatrimonioSeparado, setIdsPatrimonioSeparado] = React.useState()
  const [idGestor, setIdGestor] = React.useState()
  const [idRelatorio, setIdRelatorio] = React.useState()
  const [status, setStatus] = React.useState([]);

  const chooseStatus = (item) => {
    const updatedStatus = status.some(existingItem => existingItem.key === item.key)
      ? status.filter(existingItem => existingItem.key !== item.key)
      : [...status, item];
  
    setStatus(updatedStatus);
  };

  const checkStatus = (key) => status?.some(o => o.key === key) ? key : "";

  async function searchFilters() {
    const data = {
      dataFinal,
      dataInicio,
      idGestor,
      idRelatorio,
      dataReferencia,
      idsPatrimonioSeparado,
      status: status?.length > 0 ? status : null,
    };

    await loadRegulatorios(data);
  }

  React.useEffect(() => {
    authorizationFunction({ idAcao: READ, idFuncionalidade: RELATORIOS_REGULATORIOS }, () => {
      loadFilter()
    })
  }, []);

  React.useEffect(() => {
    if (Object.keys(filters).length > 0) {
      if (!regulatoriosConsult) searchFilters();
    }
  }, [filters]);

  return (
    <div className="w-100 px-2">
      <Form>
        <FormHeader title={"Relatórios Regulatórios"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
        >
          <div className="row">
            <div className="col-lg col-12">
              <Field
                component={DropdownListInput}
                inputId="relatorio"
                name="relatorio"
                label="Relatório"
                options={opcoesRelatorioList}
                textField="value"
                valueField="key"
                input={{
                  value: idRelatorio || opcoesRelatorioList?.find((item) => item.key == 1) || '',
                  onChange: (v) => setIdRelatorio(v)
                }}
              />
            </div>
            <div className="col-lg col-12 mb-lg-0 mb-3" style={{ paddingTop: 8 }}>
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Patrimônio Separado
                </label>
                <GenericPickerInput
                  loadData={getSugestaoPatrimonio}
                  options={patrimoniosList}
                  formName={"RegulatorioFilterForm"}
                  fieldName={"operacao"}
                  isMulti={true}
                  multiControl={true}
                  placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={idsPatrimonioSeparado}
                  selectedOptionForm={(v) => setIdsPatrimonioSeparado(v)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 col-12">
              <Field
                component={Input}
                className={"filter-input"}
                inputId="dataReferencia"
                name="dataReferencia"
                type={"month"}
                label="Data Referência"
                onChange={(e_, v) => setDataReferencia(v)}
              />
            </div>
            <div className="col-lg-2 col-12">
              <Field
                component={Input}
                className={"filter-input"}
                inputId="dataInicio"
                name="dataInicio"
                type={"date"}
                label="Data Início (Status)"
                onChange={(e_, v) => setDataInicio(v)}
              />
            </div>
            <div className="col-lg-2 col-12">
              <Field
                component={Input}
                className={"filter-input"}
                inputId="dataFinal"
                name="dataFinal"
                type={"date"}
                label="Data Final (Status)"
                onChange={(e_, v) => setDataFinal(v)}
              />
            </div>
            <div className="col-lg col-12">
              <Field
                component={DropdownListInput}
                inputId="gestor"
                name="gestor"
                options={usuarioList}
                label="Gestor"
                textField="nome"
                valueField="id"
                filter="contains"
                onChange={(e_, v) => setIdGestor(v)}
              />
            </div>
          </div>

          {statusList?.length > 0 && (
            <div className="row w-100 align-items-center mx-0 mb-2 mt-2 pl-0 pr-3">
              <span className="d-lg-block d-none col-lg-auto fw-500 f-12 pl-0 mb-1">
                Status
              </span>
              <div className="px-0 col-12 col-lg-11 w-100 d-flex flex-row flex-wrap align-items-center">
                {statusList.map((sts, index) => {
                  return (
                    <div
                      key={`sts-${index}`}
                      className="col-auto mb-1 px-2 tr-status"
                    >
                      <div
                        role="button"
                        onClick={() => chooseStatus(sts)}
                        className="d-flex flex-row align-items-center td-status prevent-select"
                      >
                        <div
                          data-status={sts.key}
                          className={`ass-sts circle-status sts-regu-${checkStatus(
                            sts.key
                          )} rounded-circle mr-1`}
                        ></div>
                        <p className="mb-0 mt-1px">{sts.value}</p>
                      </div>
                    </div>
                  );
                })}

                {(status.length < statusList?.length) && (
                  <span
                    role="button"
                    className="text-underline p-color-lighten mb-xl-1 ml-2"
                    onClick={() => setStatus(statusList)}
                  >
                    Selecionar todos
                  </span>
                )}
                {(status.length == statusList?.length) && (
                  <span
                    role="button"
                    onClick={() => setStatus([])}
                    className="text-underline p-color-lighten mb-xl-1 ml-2"
                  >
                    Desmarcar todos
                  </span>
                )}
              </div>
            </div>
          )}

          {/* ROW STATUS */}

          <div className="row">
            <Button
              name="Buscar"
              action={searchFilters}
              activeClassName="col-lg col-12"
              classes="p-bg-imp text-capitalize f-14 rounded h-38 d-flex align-items-center justify-content-center w-100 my-lg-2 my-1"
            />
            <FileButton
              ref={fileRef}
              accept=".xls, .xlsx"
              outerClassName="my-0 d-flex align-items-center col-lg col-12 w-100"
              label="Dados Servicer em Lote"
              onChange={async (e) => {
                const resp = await importRegulatorios('service', e.target.files[0])
                if (fileRef.current) fileRef.current.value = "";
                if (!resp.messages.hasError) searchFilters()
              }}
              className="darkblue-bg-imp text-white text-center fw-400 lh-1 px-2 text-capitalize f-14 rounded h-38 d-flex align-items-center justify-content-center w-100 my-lg-2 my-1"
            />
            <FileButton
              accept=".xls, .xlsx"
              outerClassName="my-0 d-flex align-items-center col-lg col-12 w-100"
              label="Dados Contábeis"
              onChange={async (e) => {
                await importRegulatorios('contabil', e.target.files[0])
                searchFilters()
              }}
              className="darkblue-bg-imp text-white text-center fw-400 lh-1 px-2 text-capitalize f-14 rounded h-38 d-flex align-items-center justify-content-center w-100 my-lg-2 my-1"
            />
            <FileButton
              accept=".xls, .xlsx"
              outerClassName="my-0 d-flex align-items-center col-lg col-12 w-100"
              label="Outros Dados"
              onChange={async (e) => {
                await importRegulatorios('outros-dados', e.target.files[0])
                searchFilters()
              }}
              className="darkblue-bg-imp text-white text-center fw-400 lh-1 px-2 text-capitalize f-14 rounded h-38 d-flex align-items-center justify-content-center w-100 my-lg-2 my-1"
            />
            {idsXml?.length >= 2 && (
              <Button
                name="Exportar .xml em Lote"
                activeClassName="col-lg col-12"
                action={() => exportArquivo('xml-lote', idsXml)}
                classes="scs-bg-dark-imp text-capitalize f-14 rounded h-38 d-flex align-items-center justify-content-center w-100 my-lg-2 my-1"
              />
            )}
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

RegulatorioFilter = reduxForm({ form: "RegulatorioFilterForm" })(RegulatorioFilter);

const mapStateToProps = (state) => ({
  patrimoniosList: state.patrimonioReducer.patrimoniosOptions || [],
  regulatoriosConsult: state.regulatorioReducer?.regulatoriosConsult,
  usuarioList: state.regulatorioReducer.filters?.usuarioList,
  opcoesRelatorioList: state.regulatorioReducer.filters?.opcoesRelatorioList,
  statusList: state.regulatorioReducer.filters?.statusList,
  filters: state.regulatorioReducer.filters,
  idsXml: state.regulatorioReducer.idsXml,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authorizationFunction,
      getSugestaoPatrimonio,
      loadFilter,
      loadRegulatorios,
      importRegulatorios,
      exportArquivo,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegulatorioFilter);
