import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { saveDominio } from "../../../../../actions/precificacao/DominioAction";

import ContainerPage from "../../../../common/container/ContainerPage";
import DominioForm from "./form/DominioForm";

const DominioIncludePage = (props) => {
  const { saveDominio } = props;

  return (
    <ContainerPage>
      <DominioForm title={"Novo - Domínio"} onSubmit={saveDominio} onEdit={true} readOnly={false} floatButtonVisible={true} />
    </ContainerPage>
  );
};

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDominio,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispacthToProps)(DominioIncludePage));
