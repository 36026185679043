import axios from "axios";
import {
  change,
  arrayRemove,
  arrayPush,
} from "redux-form";
import {
  deserializeFieldsParticipanteFormFilter,
  serializeFieldsParticipante,
  deserializeFieldsParticipante,
} from "../../components/business/participante/mappings/ParticipanteMap";
import {
  PARTICIPANTE_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  LISTA_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";
import history from "../../components/common/routers/history";
import { showAllMessages } from "../message/ErrorMessagesAPIAction";
import { downloadExcelFile, downloadFile } from "../../utils/helpers/FileHelper";
import { msgError } from "../../utils/helpers/OthersHelpers";
import { changeMessage } from "../infra/LoadingScreenAction";

export const PARTICIPANTES_FETCHED = "PARTICIPANTES_FETCHED";
export const PARTICIPANTES_CONSULT_CURRENT = "PARTICIPANTES_CONSULT_CURRENT";
export const LOAD_FILTER_FORM_PARTICIPANTE = "LOAD_FILTER_FORM_PARTICIPANTE";
export const LOAD_FORM_PARTICIPANTE = "LOAD_FORM_PARTICIPANTE";
export const SAVE_FORM_PARTICIPANTE_ERRORS = "SAVE_FORM_PARTICIPANTE_ERRORS";

const PARTICIPANTE_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_ASSEMBLEIA_API + process.env.REACT_APP_PATH_PARTICIPANTE_API;


// FILTER PARTICIPANTE (TELA INICIAL)

export function getParticipantes(filters, page = 1) {
  const payload = deserializeFieldsParticipanteFormFilter(filters, page);

  return (dispatch) => {
    dispatch(changeMessage("Carregando Participantes"))
    return axios.post(`${PARTICIPANTE_API}busca`, payload).then((result) =>
      dispatch([
        {
          type: PARTICIPANTES_FETCHED,
          payload: { result },
        },
        {
          type: PARTICIPANTES_CONSULT_CURRENT,
          payload: {
            ...filters,
            pagingConfig: { pageIndex: page, pageSize: 10 },
          },
        },
      ])
    );
  };
}

export function loadFilterFormParticipante() {
  return (dispatch) => {
    dispatch(changeMessage("Carregando Filtros dos Participantes"))
    return axios.get(`${PARTICIPANTE_API}filtro`).then((result) =>
      dispatch({
        type: LOAD_FILTER_FORM_PARTICIPANTE,
        payload: result.content,
      })
    );
  };
}

export function exportParticipantesToExcel(filters) {
  const payload = deserializeFieldsParticipanteFormFilter(filters);

  return (dispatch) => {
    dispatch(changeMessage("Baixando Lista de Participantes"))
    return axios
      .post(`${PARTICIPANTE_API}exportar`, payload)
      .then((result) => {
        if (result.content) {
          const { nomeArquivo, extensaoArquivo, arquivo } = result.content;
          dispatch([
            downloadExcelFile(arquivo, `${nomeArquivo}.${extensaoArquivo}`),
            showAllMessages(result.messages),
          ]);
        } else {
          dispatch([showAllMessages(result.messages)]);
        }
      });
  };
}

export function importParticipantes(file) {
  let formData = new FormData();
  formData.append("file", file);

  return (dispatch) => {
    dispatch(changeMessage("Enviando Lista de Participantes"))
    return axios
      .post(`${PARTICIPANTE_API}importar`, formData)
      .then((result) => {
        dispatch([
          showAllMessages(result.messages),
        ]);
      });
  };
}

export function exportParticipantesModel() {
  return (dispatch) => {
    dispatch(changeMessage("Baixando Modelo de Participantes"))
    return axios
      .post(`${PARTICIPANTE_API}exportar/modelo`)
      .then((result) => {
        if (result.content) {
          const { nomeArquivo, extensaoArquivo, arquivo } = result.content;
          dispatch([
            downloadExcelFile(arquivo, `${nomeArquivo}.${extensaoArquivo}`),
            showAllMessages(result.messages),
          ]);
        } else {
          dispatch([showAllMessages(result.messages)]);
        }
      });
  };
}

export function deleteParticipante(idParticipante) {
  return (dispatch) => {
    dispatch(changeMessage("Excluindo Participante"))
    return axios.delete(`${PARTICIPANTE_API}/${idParticipante}`).then((result) => 
      dispatch([
        showAllMessages(result.messages),
      ])
    );
  };
}

// PARTICIPANTE (FORM)

export function novoParticipante() {
  return (dispatch) => {
    return dispatch([
      history.push(`/${PARTICIPANTE_ROUTE_PATH}/${NOVO_ROUTE_PATH}`),
    ]);
  };
}

export function loadFormParticipante(idParticipante = '') {
  let url = PARTICIPANTE_API + `tela/${idParticipante}`;

  return (dispatch) => {
    if (idParticipante) dispatch(changeMessage("Carregando Participante"))
    return axios.get(url).then((result) => {
      if (idParticipante && !result?.content?.assembleiaParticipante) {
        dispatch([
          msgError("Participante não encontrado!", false),
          backToList(),
        ])
        return false
      }

      dispatch([
        {
          type: LOAD_FORM_PARTICIPANTE,
          payload: result.content,
        },
        serializeFieldsParticipante(result.content),
      ])
      
      return result.content;
    });
  };
}

export function saveParticipante(participante) {
  const participanteDeserialized = deserializeFieldsParticipante(participante);

  return (dispatch) => {
    dispatch(changeMessage("Salvando Participante"))

    dispatch([
      {
        type: SAVE_FORM_PARTICIPANTE_ERRORS,
        payload: null,
      },
    ]);
    
    return axios
      .post(PARTICIPANTE_API, participanteDeserialized)
      .then((result) => {
        return dispatch([
          backToList(),
          showAllMessages(result.messages),
        ])
      })
      .catch((err) => {
        if (err && err.data?.messages && err.data?.messages?.hasError) {
          document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" });
          dispatch([
            {
              type: SAVE_FORM_PARTICIPANTE_ERRORS,
              payload: err.data?.messages,
            },
          ]);
        }
      });
  };
}

export function editParticipante(participante) {
  const participanteDeserialized = deserializeFieldsParticipante(participante);

  return (dispatch) => {
    dispatch(changeMessage("Salvando Participante"))

    dispatch([
      {
        type: SAVE_FORM_PARTICIPANTE_ERRORS,
        payload: null,
      },
    ]);
    
    return axios
      .put(PARTICIPANTE_API, participanteDeserialized)
      .then((result) => {
        return dispatch([
          backToList(),
          showAllMessages(result.messages),
        ])
      })
      .catch((err) => {
        if (err && err.data?.messages && err.data?.messages?.hasError) {
          document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" });
          dispatch([
            {
              type: SAVE_FORM_PARTICIPANTE_ERRORS,
              payload: err.data?.messages,
            },
          ]);
        }
      });
  };
}

// HELPERS

export function AddToArrayOnChange(dependente, value) {
  return (dispatch) => {
    dispatch(arrayPush("ParticipanteForm", dependente, value));
  };
}

export function removeToArrayOnChange(dependente, index) {
  return (dispatch) => {
    dispatch(arrayRemove("ParticipanteForm", dependente, index));
  };
}

export function backToList() {
  return (dispatch) => {
    return dispatch([
      history.push(`/${PARTICIPANTE_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
    ]);
  };
}
