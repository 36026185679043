/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";

import GenericPickerInput from "../../../../../common/input/GenericPickerInput";
import Input from "../../../../../common/input/Input";

import {
  salvaFiltrosPagamento,
  getPagamentos,
  loadFilterFormPagamento,
} from "../../../../../../actions/precificacao/PagamentoAction";

import {
  getOperacoes,
  selectOperacaoNoForm,
} from "../../../../../../actions/operacao/OperacaoAction";
import { Multiselect } from "react-widgets";
import {
  primeiroDiaMesPassado,
  ultimoDiaProximoMes,
} from "../../../../../../utils/helpers/DateTimeHelper";
import Button from "../../../../../common/button/Button";

let PagamentoListFilter = (props) => {
  const {
    getPagamentos,
    loadFilterFormPagamento,
    salvaFiltrosPagamento,
    pagamentoConsult
  } = props;

  const ultimasEtapas = [
    {
      key: 1,
      value: "Futuro",
      raw: "Futuro",
    },
    {
      key: 2,
      value: "Disponível",
      raw: "Disponivel",
    },
    {
      key: 7,
      value: "Pago",
      raw: "Pago",
    },
  ];

  const [idsUltimasEtapas, setIdsUltimasEtapas] = React.useState(pagamentoConsult ? [] : [2]);
  const [idsUsuarioGestor, setIdsUsuarioGestor] = React.useState([]);
  const [idsAdministradorFiduciario, setIdsAdministradorFiduciario] = React.useState([]);
  const [idsOperacao, setIdsOperacao] = React.useState([]);
  const [dataInicio, setDataInicio] = React.useState(pagamentoConsult ? "" : primeiroDiaMesPassado());
  const [dataFim, setDataFim] = React.useState(pagamentoConsult ? "" : ultimoDiaProximoMes());

  const chooseStatus = (key) => {
    if (idsUltimasEtapas.includes(key)) {
      let newArrIds = idsUltimasEtapas.filter((elemento) => elemento !== key);
      setIdsUltimasEtapas(newArrIds);
    } else {
      setIdsUltimasEtapas([...idsUltimasEtapas, key]);
    }
  };

  const checkStatus = (key) => {
    return idsUltimasEtapas?.some((o) => o == key) ? key : "";
  };

  const selecionarStatus = () => {
    const chips = Array.from(document.querySelectorAll(".pgmt-sts"));
    let arrStatus = [];

    chips.forEach((el) => {
      if (!el.classList.contains("pe-none"))
        arrStatus.push(Number(el.dataset.status));
    });

    setIdsUltimasEtapas(arrStatus);
  };

  function searchFilters() {
    const data = {
      dataInicio,
      dataFim,
      idsUsuarioGestor,
      idsAdministradorFiduciario,
      idsOperacao,
      ultimasEtapas: idsUltimasEtapas,
    };

    getPagamentos(data);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      await loadFilterFormPagamento();
      if (!pagamentoConsult) searchFilters();
    };
  
    fetchData();
  }, []);

  // prettier-ignore
  React.useEffect(() => {
    const data = {
      idsOperacao,
      dataInicio,
      dataFim,
      ultimasEtapas: idsUltimasEtapas,
      idsUsuarioGestor,
      idsAdministradorFiduciario,
    }
    
    salvaFiltrosPagamento(data)
    
    if (idsUltimasEtapas.length <= 0) selecionarStatus();
  }, [idsOperacao, dataInicio, dataFim, idsUltimasEtapas, idsUsuarioGestor, idsAdministradorFiduciario]);

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Eventos de Pagamento"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getPagamentos}
        >
          <div className="row w-100 align-items-start mx-0 mt-2">
            <div className="col-xl-12 align-self-start mt-1p mb-xl-3 px-0">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Busca
                </label>
                <GenericPickerInput
                  loadData={props.getOperacoes}
                  options={props.options}
                  activeClassName="inputPgmt"
                  formName={"PagamentoForm"}
                  fieldName={"operacao"}
                  isMulti={true}
                  placeholder={"Pesquisar fundo ex.: FIDC Abc"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={idsOperacao}
                  selectedOptionForm={(e) => setIdsOperacao(e)}
                />
              </div>
            </div>

            <div className="col-12 col-xl-2 align-self-start mt-3 mt-xl-0 px-0 pr-xl-2">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataInicio"
                type={"date"}
                name="dataInicio"
                label="Data Início"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </div>

            <div className="col-12 col-xl-2 align-self-start px-0 px-xl-2">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataFim"
                type={"date"}
                name="dataFim"
                label="Data Final"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </div>

            <div className="col-12 col-xl-2 align-self-start mt-1p px-0 px-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Administrador Fiduciário
                </label>
                <Multiselect
                  value={idsAdministradorFiduciario}
                  onChange={(e) => setIdsAdministradorFiduciario(e)}
                  textField={"descricao"}
                  messages={{
                    emptyList: "Lista vazia",
                    emptyFilter: "Nenhum registro encontrado",
                  }}
                  placeholder={"Todos"}
                  filter={"contains"}
                  data={props.idsAdministradorFiduciarioList}
                />
              </div>
            </div>

            <div className="col-12 col-xl-2 align-self-start mt-1p px-0 px-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Responsável pela Gestão
                </label>
                <Multiselect
                  value={idsUsuarioGestor}
                  onChange={(e) => setIdsUsuarioGestor(e)}
                  textField={"descricao"}
                  messages={{
                    emptyList: "Lista vazia",
                    emptyFilter: "Nenhum registro encontrado",
                  }}
                  placeholder={"Todos"}
                  filter={"contains"}
                  data={props.idsUsuarioGestorList}
                />
              </div>
            </div>

            {ultimasEtapas.length > 0 && (
              <div className="col-auto d-flex flex-column align-items-start mb-xl-0 mb-2 px-xl-3 px-0">
                <label className="f-10-5 mt-1px lh-normal opacity-25 text-dark">
                  Status Validação
                </label>
                <div className="px-0 col-12 w-100 d-flex flex-row flex-wrap align-items-center">
                  {ultimasEtapas.map((sts, index) => {
                    return (
                      <div key={`sts-${index}`} className="col-auto mb-1 px-2 tr-status">
                        <div
                          role="button"
                          onClick={() => chooseStatus(sts.key)}
                          className="d-flex flex-row align-items-center td-status prevent-select"
                        >
                          <div
                            data-status={sts.key}
                            className={`pgmt-sts circle-status sts-${checkStatus(sts.key)} rounded-circle mr-1`}
                          ></div>
                          <p className="mb-0 mt-1px">{sts.value}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="col-xl-2 col-12 px-0 pr-xl-2 mt-0">
            <Button
              activeClassName="w-100"
              action={() => searchFilters()}
              classes="p-bg-imp rounded btn-search h-38 mt-2 w-100 d-flex justify-content-center"
              name={"Buscar"}
            />
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

PagamentoListFilter = reduxForm({ form: "PagamentoForm" })(PagamentoListFilter);

const mapStateToProps = (state) => ({
  options: state.operacaoReducer.operacoes,
  idsUsuarioGestorList: state.pagamentoReducer.pagamentoFormTypesFilter.idsUsuarioGestor || [],
  idsAdministradorFiduciarioList: state.pagamentoReducer.pagamentoFormTypesFilter.idsAdministradorFiduciario || [],
  pagamentoConsult: state.pagamentoReducer.pagamentosConsultCurrent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPagamentos,
      loadFilterFormPagamento,
      salvaFiltrosPagamento,
      getOperacoes,
      selectOperacaoNoForm,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PagamentoListFilter);
