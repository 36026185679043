import React from "react";
import { Button } from "@material-ui/core";

import If from "../If/If";
import CardAttachment from "./CardAttachment";

export default (props) => {
    const { outterClass = '' } = props;
    return (
        <>
            <If test={props.showCard}>
                <div className={`row pt-2 ${outterClass}`}>
                    <CardAttachment
                        isRemoveable={props.isRemoveable}
                        isDownloaded={props.isDownloaded}
                        onClick={props.handleRemoveFile}
                        onDownload={props.handleDownloadFile}
                        label={props.fileName} />
                </div>
            </If>
            <If test={!props.showCard}>
                <div className={`row pt-2 ${outterClass}`}>
                    <Button variant='outlined'
                        disabled={props.disabled}
                        onClick={props.handleUploadFile}
                        className="brd-p-color-imp p-color-imp bg-white">
                        <div className="d-flex "> Selecionar Arquivo </div>
                    </Button>
                </div>
            </If>
        </>
    );
}
