import {
  SUBCLASSE_OPTIONS_FETCHED,
  SELECTED_SUBCLASSE_OPTION,
  SUBCLASSE_FETCHED,
  SUBCLASSE_SHOW_LIST,
  SUBCLASSE_FORM_TAB,
  LOAD_FORM_SUBCLASSE,
  LOAD_FORM_SUBCLASSE_COTAS,
  SUBCLASSE_SHOW_DELETE_DIALOG,
  SUBCLASSE_CLEAN_LIST,
  LOAD_CLASSES_FUNDOLIST,
  LOAD_FORM_SUBCLASSE_COTAS_EMITIDAS,
  LOAD_TAB_SUBCLASSES_COTAS,
  SUBCLASSE_PAGINATION,
  RESET
} from "../../actions/subclasse/SubclasseAction";

const INITIAL_STATE = {
  subclasses: {
    lastPage: 0,
    totalCount: 0,
    hasItems: false,
    pageSize: 0,
    pageIndex: 0,
    items: [],
  },
  subclassesOptions: [],
  selectedSubclasseOptions: [],
  isLoading: false,
  messages: null,
  showSubclasseList: false,
  showSubclasseDeleteDialog: {
    showDialog: false,
    idSubclasse: 0,
  },
  changeSubclasseFormTab: "",
  subclasseFormTypes: {},
  subclasseCotasEmitidas: [],
  tabSubclassesCotas: [],
  pagination: 1,
};

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SUBCLASSE_CLEAN_LIST:
      return {
        ...state,
        subclasses: {
          lastPage: 0,
          totalCount: 0,
          hasItems: false,
          pageSize: 0,
          pageIndex: 0,
          items: [],
        },
      };
    case SUBCLASSE_OPTIONS_FETCHED:
      return {
        ...state,
        subclassesOptions: action.payload,
      };
    case SELECTED_SUBCLASSE_OPTION:
      return {
        ...state,
        selectedSubclasseOptions: action.payload,
      };
    case SUBCLASSE_FETCHED:
      return {
        ...state,
        subclasses: action.payload.content,
        messages: action.payload.messages,
        isLoading: true,
      };
    case SUBCLASSE_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      };
    case SUBCLASSE_SHOW_LIST:
      return {
        ...state,
        showSubclasseList: action.payload,
      };
    case LOAD_FORM_SUBCLASSE:
      return {
        ...state,
        subclasseFormTypes: action.payload,
      };
    case LOAD_CLASSES_FUNDOLIST:
        const { classes, classesLoaded} = action.payload;
        return {
          ...state,
          subclasseFormTypes: {...state.subclasseFormTypes, classesList: classes, classesLoaded},
        };
    case LOAD_FORM_SUBCLASSE_COTAS:
      return {
        ...state,
        cotas: action.payload,
      };
    case SUBCLASSE_FORM_TAB:
      return {
        ...state,
        changeSubclasseFormTab: action.payload,
      };
    case SUBCLASSE_SHOW_DELETE_DIALOG:
      return {
        ...state,
        showSubclasseDeleteDialog: action.payload,
      };
    case LOAD_FORM_SUBCLASSE_COTAS_EMITIDAS:
      return {
        ...state,
        subclasseCotasEmitidas: action.payload,
      };
    case LOAD_TAB_SUBCLASSES_COTAS:
      return {
        ...state,
        tabSubclassesCotas: action.payload,
      };
    case RESET: {
      return {
        ...INITIAL_STATE,
        selectedSubclasseOptions: state.selectedSubclasseOptions
      }
    }
    default:
      return state;
  }
}
