import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  CARTEIRA_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

import Carteira from "../pages/Carteira";
import CarteiraDetail from "../pages/form/CarteiraDetail";

export default () => (
  <Switch>
    <Route
      exact={true}
      path={`/${CARTEIRA_ROUTE_PATH}`}
      component={Carteira}
    />
    <Route
      exact={true}
      path={`/${CARTEIRA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idCarteira`}
      component={CarteiraDetail}
    />
  </Switch>
);
