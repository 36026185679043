import React, { Component } from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";

import Input from "../../../../../../common/input/Input";

class PassivoFormAprovacao extends Component {
  render() {
    return (
      <div className="mt-4">
        <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
          <div className="column">
            <span className="page-head__title h3 mb-3">Aprovação</span>
            <div className="row">
              <div className="col-lg-4">
                <Field
                  component={Input}
                  inputId="dataAtivacao"
                  name="dataAtivacao"
                  label="Data da Ativação"
                  readOnly={true}
                />
              </div>
              <div className="col-lg">
                <Field
                  component={Input}
                  inputId="responsavelAtivacao"
                  name="responsavelAtivacao"
                  label="Reponsável"
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <Field
                  component={Input}
                  inputId="dataAprovacaoCadastro"
                  name="dataAprovacaoCadastro"
                  label="Data da Aprovação do Cadastro"
                  readOnly={true}
                />
              </div>
              <div className="col-lg">
                <Field
                  component={Input}
                  inputId="responsavelCadastro"
                  name="responsavelCadastro"
                  label="Responsável"
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(PassivoFormAprovacao);
