import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  COTA_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

import Cota from "../pages/Cota";
import CotaDetail from "../pages/form/CotaDetail";

export default () => (
  <Switch>
    <Route
      exact={true}
      path={`/${COTA_ROUTE_PATH}`}
      component={Cota}
    />
    <Route
      exact={true}
      path={`/${COTA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idCota`}
      component={CotaDetail}
    />
  </Switch>
);
