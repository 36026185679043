/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field } from "redux-form";
import MaskedInput from "../../../../../common/input/MaskedInput";
import { CURRENCY } from "../../../../../../utils/helpers/StringHelper";

const FluxoCaixa = (props) => {
  const { readOnly, manual } = props;

  const arrFluxoCaixa = [
    { num: '15.1', label: 'Recebimentos dos Créditos', id: 'fluxoCaixa.recebimentosDosCreditos', readOnly: readOnly },
    { num: '15.2', label: 'Pagamentos de Despesas', id: 'fluxoCaixa.pagamentosDeDespesas', readOnly: readOnly },
    { num: '15.3', label: 'Pagamentos Classe Senior', id: 'fluxoCaixa.pagamentosClasseSenior', readOnly: true },
    { num: '15.3.1', label: 'Amortização Principal', id: 'fluxoCaixa.seniorAmortizacaoPrincipal', readOnly: ((!readOnly && !manual) || readOnly) },
    { num: '15.3.2', label: 'Juros', id: 'fluxoCaixa.seniorJuros', readOnly: ((!readOnly && !manual) || readOnly) },
    { num: '15.4', label: 'Pagamentos Classe Mezanino', id: 'fluxoCaixa.pagamentosClasseMezanino', readOnly: true },
    { num: '15.4.1', label: 'Amortização Principal', id: 'fluxoCaixa.mezaninoAmortizacaoPrincipal', readOnly: ((!readOnly && !manual) || readOnly) },
    { num: '15.4.2', label: 'Juros', id: 'fluxoCaixa.mezaninoJuros', readOnly: ((!readOnly && !manual) || readOnly) },
    { num: '15.5', label: 'Pagamentos Classe Júnior', id: 'fluxoCaixa.pagamentosClasseJunior', readOnly: true },
    { num: '15.5.1', label: 'Amortização Principal', id: 'fluxoCaixa.juniorAmortizacaoPrincipal', readOnly: ((!readOnly && !manual) || readOnly) },
    { num: '15.5.2', label: 'Juros', id: 'fluxoCaixa.juniorJuros', readOnly: ((!readOnly && !manual) || readOnly) },
    { num: '15.6', label: 'Recebimentos por alienação de caixa e equivalentes', id: 'fluxoCaixa.recebimentosPorAlienacaoDeCaixaEEquivalentes', readOnly: readOnly },
    { num: '15.7', label: 'Aquisição de caixa e equivalentes', id: 'fluxoCaixa.aquisicaoDeCaixaEEquivalentes', readOnly: readOnly },
    { num: '15.8', label: 'Aquisição de novos créditos', id: 'fluxoCaixa.aquisicaoDeNovosCreditos', readOnly: readOnly },
    { num: '15.9', label: 'Outros recebimentos', id: 'fluxoCaixa.outrosRecebimentos', readOnly: readOnly },
    { num: '15.10', label: 'Outros pagamentos', id: 'fluxoCaixa.outrosPagamentos', readOnly: readOnly },
    { num: '15.11', label: 'Variação líquida no caixa', id: 'fluxoCaixa.variacaoLiquidaNoCaixa', readOnly: true }
  ]

  const fields = (arr) => {
    return arr.map(({ num, label, id, readOnly }) =>
      id ? (
        <div className="row d-flex align-items-center my-lg-2 mb-4 mt-2" key={id}>
          <div className="col-1 mt-lg-2 mt-0 fw-400">{num}</div>
          <div className="col-lg-3 col-11 text-lg-left text-center mt-lg-2 mt-0 fw-400 px-2 pr-lg-2 pr-5">{label}</div>
          <div className="col-lg col-12">
            <Field
              component={MaskedInput}
              outerClass="m-0"
              inputId={id}
              name={id}
              readOnly={readOnly}
              prefix="R$ "
              placeholder={CURRENCY.placeholder}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
            />
          </div>
        </div>
      ) : (
        <div className="row d-flex align-items-center my-2 py-1" key={num}>
          <div className="col-1 mt-lg-2 mt-0 fw-400">{num}</div>
          <div className="col-lg-3 col-11 text-lg-left text-center mt-lg-2 mt-0 fw-400 px-2 pr-lg-2 pr-5">{label}</div>
        </div>
      )
    );
  }

  return (
    <div className="container-fluid px-0">
      <div className="column shadow-custom bg-white rounded-12 col-12 px-4 pt-1 pb-3">
      <p className="h3 page-head__title my-3 mb-0">15. Fluxo de Caixa Líquido</p>

        {fields(arrFluxoCaixa)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispacthToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(FluxoCaixa);
