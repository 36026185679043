import React, {Component} from 'react';
import gitVersion from "./git_commit.txt";

class VersionComponent extends Component {
  constructor (props){
    super(props);
    this.state={data :null};
  }
  
  componentDidMount() {
    fetch(gitVersion)
    .then(response => response.text())
    .then(data => {this.setState({ data })})
    .catch((err) => console.error(err))
  }

  render() {
      return (
        <div {...this.props}>
          Versão build: {this.state.data}
        </div>
      );
    }
}

export default VersionComponent; 