import {
  ATIVO_OPTIONS_FETCHED,
  SELECTED_ATIVO_OPTION,
  ATIVO_OPERACAO_FETCHED,
  ATIVO_FETCHED,
  ATIVO_SHOW_LIST,
  ATIVO_FORM_TAB,
  LOAD_FORM_ATIVO,
  LOAD_FORM_ATIVO_MOEDA,
  ATIVO_SHOW_FORM_VINCULO_OPERACAO,
  ATIVO_NAO_VINCULADO_OPTIONS_FETCHED,
  SELECTED_ATIVO_NAO_VINCULADO_OPTIONS,
  ATIVO_VINCULADO_OPERACAO_FETCHED,
  ATIVO_SHOW_DELETE_DIALOG,
  ATIVO_CLEAN_LIST
} from "../../actions/ativo/AtivoAction";

const INITIAL_STATE = {
  ativos: {
    lastPage: 0,
    totalCount: 0,
    hasItems: false,
    pageSize: 0,
    pageIndex: 0,
    items: [],
  },
  ativosOperacao: {
    lastPage: 0,
    totalCount: 0,
    hasItems: false,
    pageSize: 0,
    pageIndex: 0,
    items: [],
  },
  ativosOptions: [],
  selectedAtivoOptions: [],
  ativoNaoVinculadoOptions: [],
  selectedAtivoNaoVinculadoOptions: [],
  ativosVinculadoOperacao: [],
  isLoading: false,
  messages: null,
  showAtivoList: false,
  showAtivoFormVinculoOperacao: false,
  showAtivoDeleteDialog: {
    showDialog: false,
    idAtivo: 0
  },
  changeAtivoFormTab: "",
  ativoFormTypes: {}
};

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ATIVO_CLEAN_LIST:
      return {
        ...state,
        ativos: {
          lastPage: 0,
          totalCount: 0,
          hasItems: false,
          pageSize: 0,
          pageIndex: 0,
          items: [],
        },
      }
    case ATIVO_OPTIONS_FETCHED:
      return {
        ...state,
        ativosOptions: action.payload
      };
    case SELECTED_ATIVO_OPTION:
      return {
        ...state,
        selectedAtivoOptions: action.payload,
      };
    case ATIVO_FETCHED:
      return {
        ...state,
        ativos: action.payload.content,
        messages: action.payload.messages,
        isLoading: true
      };
    case ATIVO_OPERACAO_FETCHED:
      return {
        ...state,
        ativosOperacao: action.payload.content.items,
        messages: action.payload.messages,
        isLoading: true
      };
    case ATIVO_SHOW_LIST:
      return {
        ...state,
        showAtivoList: action.payload
      };
    case LOAD_FORM_ATIVO:
      return {
        ...state,
        ativoFormTypes: action.payload
      };
    case LOAD_FORM_ATIVO_MOEDA:
      return {
        ...state,
        moedaDinamica: action.payload
      };
    case ATIVO_FORM_TAB:
      return {
        ...state,
        changeAtivoFormTab: action.payload
      };
    case ATIVO_SHOW_DELETE_DIALOG:
      return {
        ...state,
        showAtivoDeleteDialog: action.payload
      }
    case ATIVO_SHOW_FORM_VINCULO_OPERACAO:
      return {
        ...state,
        showAtivoFormVinculoOperacao: action.payload,
        showAtivoList: !action.payload,
        ativoNaoVinculadoOptions: [],
        selectedAtivoNaoVinculadoOptions: [],
        ativosVinculadoOperacao: [],
        selectedAtivosVinculadoOperacao: [],
      }
    case SELECTED_ATIVO_NAO_VINCULADO_OPTIONS:
      return {
        ...state,
        selectedAtivoNaoVinculadoOptions: action.payload
      };
    case ATIVO_NAO_VINCULADO_OPTIONS_FETCHED:
      return {
        ...state,
        ativoNaoVinculadoOptions: action.payload
      }
    case ATIVO_VINCULADO_OPERACAO_FETCHED:
      return {
        ...state,
        ativosVinculadoOperacao: action.payload.items
      }
    default:
      return state;
  }
}
