import React from "react";

const FormContent = (props) => {
  return (
    <div className={`col-md-12 ${props.classes}`}>
      <div className="tab-content">
        <form onSubmit={props.handleSubmit} autoComplete="off">
          {props.children}
        </form>
      </div>
    </div>
  );
};

export default FormContent;
