import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, formValueSelector, } from "redux-form";

import { addCodigoB3Cetip } from '../../../../../../actions/ativo/AtivoAction';

import If from "../../../../../common/If/If";
import Input from "../../../../../common/input/Input";
import MaskedInput from "../../../../../common/input/MaskedInput";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import AtivoCodigoB3CetipList from "./AtivoCodigoB3CetipList";
import Button from "../../../../../common/button/Button";

const AtivoFormCodigoB3Cetip = props => {

  const {
    readOnly,
    tipoCodigoList,
    codigoB3Cetip,
    codigoB3CetipList,
    addCodigoB3Cetip,
    ativo,
    moedaDinamica
  } = props;

  let moedaSimbolo = moedaDinamica || ativo?.moeda?.simbolo || "R$";

  let ativoCodigoB3Cetip = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">Códigos</span>
        <div className="flex-column flex-fill">
          <If test={!readOnly}>
            <div className="d-flex w-100 align-items-center">
              <div className="col more-fields p-0">
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <Field
                      component={Input}
                      inputId="codigoB3Cetip"
                      name="codigoB3Cetip.codigo"
                      type="text"
                      label="Código do título na B3/CETIP"
                      readOnly={readOnly}
                    />
                  </div>

                  <div className="col-12 col-lg-4">
                    <Field
                      component={MaskedInput}
                      inputId="percentual"
                      name="codigoB3Cetip.percentual"
                      label="Percentual B3/CETIP"
                      readOnly={readOnly}
                      suffix={"%"}
                      thousandSeparator={'.'}
                      decimalSeparator={","}
                      decimalScale={4}
                      fixedDecimalScale
                      placeholder={"0,0000%"}
                    />
                  </div>

                  <div className="col-12 col-lg-4">
                    <Field
                      component={MaskedInput}
                      inputId="valorEmissaoAtual"
                      name="codigoB3Cetip.valorEmissaoAtual"
                      label="Valor Emissão Atual"
                      readOnly={readOnly}
                      prefix={`${moedaSimbolo} `}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScal
                      placeholder={`${moedaSimbolo} 0,00`}
                    />
                  </div>

                  <div className="col-12 col-lg-4">
                    <Field
                      component={DropdownListInput}
                      inputId="tipoCodigo"
                      name="codigoB3Cetip.tipoCodigo"
                      label="Tipo"
                      options={tipoCodigoList}
                      textField="value"
                      valueField="key"
                      filter="contains"
                      readOnly={readOnly}
                    />
                  </div>

                  <div className="col-12 col-lg-4">
                    <Field
                      component={Input}
                      inputId="dataEmissao"
                      name="codigoB3Cetip.dataEmissao"
                      type="date"
                      label="Data do emissão"
                      readOnly={readOnly}
                    />
                  </div>

                  <div className="col-12 col-lg-4">
                    <Field
                      component={Input}
                      inputId="dataVencimento"
                      name="codigoB3Cetip.dataVencimento"
                      type="date"
                      label="Data do vencimento"
                      readOnly={readOnly}
                    />
                  </div>
                </div>
              </div>
            </div>
          </If>

          <If test={!readOnly}>
              <div className="mx-auto d-flex justify-content-center mt-3">
                <Button
                  classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                  activeClassName={"col-lg-auto p-0"}
                  buttonStyle={"success"}
                  action={() => addCodigoB3Cetip(codigoB3Cetip)}
                  icon={"plus"}
                  name={"Adicionar"}
                />
              </div>
          </If>

          <div className="more-fields mt-4">
            <AtivoCodigoB3CetipList
              readOnly={readOnly} 
              dataList={codigoB3CetipList.reverse()}
            />
          </div>    
        </div>
      </div>
    </div>
  );

  return (
    <div className="d-flex flex-column mt-4">
      {ativoCodigoB3Cetip}
    </div>
  );
}

const selector = formValueSelector("ativoForm");

const mapStateToProps = state => ({
  tipoCodigoList: state.ativoReducer.ativoFormTypes.tipoCodigoList,
  codigoB3Cetip: selector(state, "codigoB3Cetip"),
  codigoB3CetipList: selector(state, "codigoB3CetipList") || [],
  ativo: state.ativoReducer.ativoFormTypes.ativo,
  moedaDinamica: state.ativoReducer.moedaDinamica || null,
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
  addCodigoB3Cetip,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AtivoFormCodigoB3Cetip);
