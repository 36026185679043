import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../common/table/TableEmptyData";
import PaginationRB from "../../../../../common/pagination/PaginationRB";

import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import { convertCurrencyNumber } from "../../../../../../utils/helpers/NumberHelper";

import { getCalculos } from "../../../../../../actions/precificacao/PrecificacaoAction";
import If from "../../../../../common/If/If";

const CalculoList = (props) => {
  const {
    getCalculos,
    calculoData,
    messages,
    operacao,
    dataEventoInicial,
    dataEventoFinal,
    diario,
  } = props;

  const tableHeader = (
    <tr>
      <th className="text-center fw-700 text-nowrap">Data</th>
      <th className="text-center fw-700 text-nowrap">Remuneração</th>
      <th className="text-center fw-700 text-nowrap">VNI</th>
      <th className="text-center fw-700 text-nowrap">VNA</th>
      <th className="text-center fw-700 text-nowrap">Juros</th>
      <th className="text-center fw-700 text-nowrap">VNJ</th>
      <th className="text-center fw-700 text-nowrap">Juros Pagos</th>
      <th className="text-center fw-700 text-nowrap">Amortização</th>
      <th className="text-center fw-700 text-nowrap">VRC</th>
      <th className="text-center fw-700 text-nowrap">Amortização Extra</th>
      <th className="text-center fw-700 text-nowrap">Perda</th>
      <th className="text-center fw-700 text-nowrap">VRCE</th>
      <th className="text-center fw-700 text-nowrap">Evento Genérico</th>
      <th className="text-center fw-700 text-nowrap">VN Com Resíduo</th>
    </tr>
  );

  const emptyData = messages && messages.infos && messages.infos[0];

  const tableContent =
    calculoData && calculoData.pageResult && calculoData.pageResult.hasItems ? (
      calculoData.pageResult.items.map((item, index) => (
        <tr key={index}>
          <td className="text-center fw-400 text-nowrap">
            {getDateFormatedFromString(item.dataEvento)}
          </td>
          <td className="text-center fw-400 text-nowrap">{item.remuneracao}</td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(
              item.valorNominalInicial,
              item?.moeda?.simbolo || "R$"
            )}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(
              item.valorNominalAtualizado,
              item?.moeda?.simbolo || "R$"
            )}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(
              item.jurosRemuneracao,
              item?.moeda?.simbolo || "R$"
            )}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(
              item.valorNominalComJuros,
              item?.moeda?.simbolo || "R$"
            )}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(
              item.jurosPago,
              item?.moeda?.simbolo || "R$"
            )}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(
              item.amortizacao,
              item?.moeda?.simbolo || "R$"
            )}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(
              item.valorNominalResidualCurva,
              item?.moeda?.simbolo || "R$"
            )}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(
              item.amortizacaoExtraordinaria,
              item?.moeda?.simbolo || "R$"
            )}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(
              item.valorPerda,
              item?.moeda?.simbolo || "R$"
            )}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(
              item.valorNominalResidualExtraodinario,
              item?.moeda?.simbolo || "R$"
            )}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(item.premio, item?.moeda?.simbolo || "R$")}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(
              item.valorNominalComResiduo,
              item?.moeda?.simbolo || "R$"
            )}
          </td>
        </tr>
      ))
    ) : (
      <TableEmptyData message={emptyData} />
    );

  const dadosOperacao = (
    <div className="card bg-transparent border-0 px-4 shadow-none">
      <div className="card-body shadow-custom bg-white rounded-12 col-12">
        <h4 className="page-head__title h3 m-0 f-22 font-weight-bold">
          Dados da Operação
        </h4>
        <hr></hr>
        <div className="row px-0">
          <div className="col-lg-6">
            <span className="font-weight-normal">Remuneração:</span>
            <span className="ml-2">{calculoData.remuneracao || "-"}</span>
          </div>
          <div className="col-lg-6">
            <span className="font-weight-normal">Vencimento:</span>
            <span className="ml-2">
              {getDateFormatedFromString(calculoData.vencimento) || "-"}
            </span>
          </div>
          <div className="col-lg-6">
            <span className="font-weight-normal">Ultimo Pagamento:</span>
            <span className="ml-2">
              {getDateFormatedFromString(calculoData.ultimoPagamento) || "-"}
            </span>
          </div>
          <div className="col-lg-6">
            <span className="font-weight-normal">Próximo Pagamento:</span>
            <span className="ml-2">
              {getDateFormatedFromString(calculoData.proximoPagamento) || "-"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-100">
      <If test={calculoData.pageResult.hasItems}>
        {dadosOperacao}
        <div className="px-4 mb-4">
          <Table
            withoutHeader
            classes="shadow-custom rounded-12 col-12 m-0 pd-10 bg-white"
            totalItems={calculoData.pageResult.totalCount}
            pagination={
              <PaginationRB
                pageNumber={calculoData.pageResult.pageIndex - 1}
                itemsPerPage={calculoData.pageResult.pageSize}
                totalItems={calculoData.pageResult.totalCount}
                filter={{
                  operacao,
                  dataEventoInicial,
                  dataEventoFinal,
                  diario,
                }}
                fetchFunction={getCalculos}
                pageRange={5}
              />
            }
          >
            <TableHeader>{tableHeader}</TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        </div>
      </If>
    </div>
  );
};

const selector = formValueSelector("calculoForm");

const mapStateToProps = (state) => ({
  calculoData: state.precificacaoReducer.precificacaoCalculo,
  messages: state.precificacaoReducer.messages,
  operacao: selector(state, "operacao"),
  dataEventoInicial: selector(state, "dataEventoInicial"),
  dataEventoFinal: selector(state, "dataEventoFinal"),
  diario: selector(state, "diario"),
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getCalculos,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(CalculoList);
