/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  reduxForm,
  formValueSelector,
  Field,
} from "redux-form";

import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";
import { validate } from "../validation/RegulatorioFormValidation";
import Button from "../../../../../common/button/Button";
import SimpleTabs from "../../../../../common/tab/SimpleTabs";
import FixedFloaterErrors from "../../../../../common/floats/FixedFloaterErrors";
// MODALS
import ModalAction from "../../../../../common/modal/ModalAction";
import { backToList, importRegulatorios } from "../../../../../../actions/relatorios/regulatorioAction";
import FileButton from "../../../../../common/input/FileButton";
import Geral from "../sections/Geral";
import Series from "../sections/Series";
import Balanco from "../sections/Balanco";
import Credito from "../sections/Credito";
import Derivativos from "../sections/Derivativos";
import Desembolsos from "../sections/Desembolsos";
import FluxoCaixa from "../sections/FluxoCaixa";
import OutrosDados from "../sections/OutrosDados";
import SwitchField from "../../../../../common/input/SwitchField";
import { getMonthYearString } from "../../../../../../utils/helpers/DateTimeHelper";

let RegulatorioForm = (props) => {
  const {
    title,
    invalid,
    readOnly,
    messages,
    // ACTIONS
    backToList,
    handleSubmit,
    importRegulatorios,
    // 
    possuiDerivativos,
    possuiAjusteManual,
    dataCompetencia,
    usuarioGestor,
    usuarioServicoCredito,
    status,
    patrimonioSeparado,
    regulatorioId,
  } = props;

  const fileRef = React.useRef(null);

  const [tabSelected, setTabSelected] = React.useState(0);
  const [confirmReturn, setConfirmReturn] = React.useState(false);

  const type = patrimonioSeparado?.descricao?.match(/CRA|CRI/)?.[0] || '';

  const tabsRegulatorio = [
    { label: 'GERAL', show: true },
    { label: 'SÉRIES', show: true },
    { label: 'BALANÇO', show: true },
    { label: 'CRÉDITO', show: true },
    { label: 'DERIVATIVOS', show: true },
    { label: 'DESEMBOLSOS', show: true },
    { label: 'FLUXO DE CAIXA', show: true },
    { label: 'OUTROS DADOS', show: true },
  ];

  const prevIndex = tabSelected - 1;
  const nextIndex = tabSelected + 1;

  return (
    <Form className="w-100">
      <FormHeader 
        title={`${title}${patrimonioSeparado?.descricao ? ` - ${patrimonioSeparado.descricao}` : ''}`}
        offSet={20}
      ></FormHeader>

      <FormContent handleSubmit={handleSubmit} classes="px-0">
        {/* ERRORS */}
        {messages?.hasError && (
          <FixedFloaterErrors messages={messages} title="Status do Cadastro" />
        )}

        <div className="col-12 px-0">
          <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
            <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
              <div className="row">
                <div className="col">
                  <p className="mb-1 txt-grey">Data Competência</p>
                  <p className="mb-0 fw-400">{dataCompetencia ? getMonthYearString(dataCompetencia) : "--"}</p>
                </div>
                <div className="col">
                  <p className="mb-1 txt-grey">Gestor</p>
                  <p className="mb-0 fw-400">{usuarioGestor?.nome || "--"}</p>
                </div>
                <div className="col">
                  <p className="mb-1 txt-grey">Serviço de Crédito</p>
                  <p className="mb-0 fw-400">{usuarioServicoCredito?.nome || "--"}</p>
                </div>
                <div className="col">
                  <p className="mb-1 txt-grey">Status</p>
                  <p className="mb-0 fw-400">{status?.value || "--"}</p>
                </div>
                <div className="col">
                  <p className="mb-1 txt-grey">Possui Derivativos?</p>
                  <div className="mb-0 fw-400 d-flex flex-row">
                    {typeof possuiDerivativos == 'boolean' ? (
                      <>
                        <Field
                        name="possuiDerivativos"
                        component={SwitchField}
                        width={40}
                        height={20}
                        uncheckedOffset={7}
                        checkedOffset={7}
                        onColor="#630A38"
                        offColor="#C1C1C1"
                        disabled={readOnly}
                      />

                      <span className="ml-2">{possuiDerivativos ? 'Sim' : 'Não'}</span>
                      </>
                    ) : (
                      <p className="mb-0 fw-400">--</p>
                    )}
                  </div>
                </div>
                <div className="col">
                  <p className="mb-1 txt-grey">Ajuste Manual?</p>
                  <div className="mb-0 fw-400 d-flex flex-row">
                    {typeof possuiAjusteManual == 'boolean' ? (
                      <>
                        <Field
                        name="possuiAjusteManual"
                        component={SwitchField}
                        width={40}
                        height={20}
                        uncheckedOffset={7}
                        checkedOffset={7}
                        onColor="#630A38"
                        offColor="#C1C1C1"
                        disabled={readOnly}
                      />

                      <span className="ml-2">{possuiAjusteManual ? 'Sim' : 'Não'}</span>
                      </>
                    ) : (
                      <p className="mb-0 fw-400">--</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <SimpleTabs
              className="my-2"
              key={tabSelected}
              scrollButtons="off"
              variant="scrollable"
              tabs={tabsRegulatorio}
              tabSelected={tabSelected}
              onTabChange={setTabSelected}
            />

            <div className="py-2 pg-regu f-16 lh-normal">
              {tabSelected === 0 && (<Geral type={type} readOnly={readOnly} />)}
              {tabSelected === 1 && (<Series manual={possuiAjusteManual} readOnly={readOnly} />)}
              {tabSelected === 2 && (<Balanco readOnly={readOnly} derivativo={possuiDerivativos} />)}
              {tabSelected === 3 && (<Credito type={type} manual={possuiAjusteManual} readOnly={readOnly} />)}
              {tabSelected === 4 && (<Derivativos readOnly={readOnly} derivativo={possuiDerivativos} />)}
              {tabSelected === 5 && (<Desembolsos manual={possuiAjusteManual} readOnly={readOnly} />)}
              {tabSelected === 6 && (<FluxoCaixa manual={possuiAjusteManual} readOnly={readOnly} />)}
              {tabSelected === 7 && (<OutrosDados readOnly={readOnly} />)}
            </div>

            <div className="d-flex flex-row align-items-center justify-content-between mt-3 p-color font-weight-bold bread-regu text-capitalize">
              <span
                onClick={() => prevIndex >= 0 && setTabSelected(prevIndex)}
                style={{ cursor: prevIndex >= 0 ? 'pointer' : 'default' }}
              >
                {prevIndex >= 0 ? `🠤 ${(tabsRegulatorio[prevIndex].label).toLocaleLowerCase()}` : ''}
              </span>
              <span
                onClick={() => nextIndex < tabsRegulatorio.length && setTabSelected(nextIndex)}
                style={{ cursor: nextIndex < tabsRegulatorio.length ? 'pointer' : 'default' }}
              >
                {nextIndex < tabsRegulatorio.length ? `${(tabsRegulatorio[nextIndex].label).toLocaleLowerCase()} 🠦` : ''}
              </span>
            </div>
          </div>
        </div>

        {/* --- ACTIONS --- */}
        <div className="col-12 px-0">
          <div className="d-flex flex-row justify-content-between align-items-center col-12 mb-4 mt-2 p-0">
            <Button
              type="button"
              name={`${readOnly ? 'Voltar' : 'Cancelar Criação'}`}
              classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={() => readOnly ? backToList() : setConfirmReturn(true)}
            />

            {!readOnly && (
              <>
                <FileButton
                  ref={fileRef}
                  accept=".xls, .xlsx"
                  outerClassName="my-0 d-flex align-items-center fw-400"
                  label="Importar Dados Servicer"
                  onChange={async (e) => {
                    const resp = await importRegulatorios('service', e.target.files[0], true, regulatorioId)
                    if (fileRef.current) fileRef.current.value = "";
                    if (!resp.messages.hasError) backToList()
                  }}
                  className="darkblue-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 h-34"
                />

                <Button
                  type="submit"
                  name={"Salvar Alterações"}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                />
              </>
            )}
          </div>
        </div>
      </FormContent>

      {/* MODAL RETURN */}
      <ModalAction
        action={backToList}
        open={confirmReturn}
        title="Cancelar Alterações"
        close={() => setConfirmReturn(false)}
        message="Todo o progresso será perdido"
      />
    </Form>
  );
};

const selector = formValueSelector("RegulatorioForm");

const mapStateToProps = (state) => ({
  possuiDerivativos: selector(state, "possuiDerivativos"),
  possuiAjusteManual: selector(state, "possuiAjusteManual"),
  dataCompetencia: selector(state, "dataCompetencia"),
  usuarioGestor: selector(state, "usuarioGestor"),
  usuarioServicoCredito: selector(state, "usuarioServicoCredito"),
  status: selector(state, "status"),
  patrimonioSeparado: selector(state, "patrimonioSeparado"),
  regulatorioId: state.regulatorioReducer.regulatorioForm?.relatorioRegulatorio?.id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      backToList,
      importRegulatorios,
    },
    dispatch
  );

const ConnectedParticipanteForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegulatorioForm)
);

export default reduxForm({ form: "RegulatorioForm", validate })(
  ConnectedParticipanteForm
);
