import React from "react";
import { Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import DropdownListInput from "../../../../../../common/input/DropdownListInput";
import { bindActionCreators } from "redux";
import { exportArquivoB3, limpaDependenteOnChange, updateRatingList } from "../../../../../../../actions/operacao/PassivoAction";
import { getBooleanValueFromString } from "../../../../../../../utils/helpers/BooleanHelper";
import Button from "../../../../../../common/button/Button";

const PassivoFormRegistro = (props) => {
  const {
    tipoArquivoList,
    negociacaoSecundariaList,
    artigoLeiList,
    readOnly,
    limpaDependenteOnChange,
    updateRatingList,
    exportArquivoB3,
    temRating,
    idAgenciaRating,
    agenciaList,
    ratingList,
  } = props;

  React.useEffect(() => {
    if (idAgenciaRating) {
      const idAgencia = idAgenciaRating?.id || idAgenciaRating;
      const list = agenciaList.find((item) => item.id === idAgencia)?.ratingList
      updateRatingList(list)
    }
  }, [idAgenciaRating])
  
  const isRating = getBooleanValueFromString(temRating)

  return (
    <div className="mt-4">
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Registro</span>
          <div className="row">
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="operacao.operacaoRegistro.idTipoArquivo"
                name="operacao.operacaoRegistro.idTipoArquivo"
                label="Tipo de Arquivo"
                options={tipoArquivoList}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="operacao.operacaoRegistro.idNegociacaoSecundaria"
                name="operacao.operacaoRegistro.idNegociacaoSecundaria"
                label="Negociação Secundária"
                options={negociacaoSecundariaList}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="operacao.operacaoRegistro.temCoobrigacaoEmissora"
                name="operacao.operacaoRegistro.temCoobrigacaoEmissora"
                label="Possui Coobrigação da Emissora?"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="operacao.operacaoRegistro.temPrevisaoIncorporarJuros"
                name="operacao.operacaoRegistro.temPrevisaoIncorporarJuros"
                label="Existe Previsão para Incorporação de Juros?"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="operacao.operacaoRegistro.temGarantiaFlutuante"
                name="operacao.operacaoRegistro.temGarantiaFlutuante"
                label="Possui Garantia Flutuante"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="operacao.operacaoRegistro.temAnuncioDistribuicao"
                name="operacao.operacaoRegistro.temAnuncioDistribuicao"
                label="Utilização do Anúncio de Distribuição"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="operacao.operacaoRegistro.temRating"
                name="operacao.operacaoRegistro.temRating"
                label="Operação Possui Rating"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
                onChange={() => {
                  limpaDependenteOnChange("operacao.operacaoRegistro.idAgenciaRating", null);
                  limpaDependenteOnChange("operacao.operacaoRegistro.idRatingAgencia", null);
                }}
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="operacao.operacaoRegistro.idAgenciaRating"
                name="operacao.operacaoRegistro.idAgenciaRating"
                label="Agência Classificadora de Risco"
                options={agenciaList}
                textField="nome"
                valueField="id"
                readOnly={readOnly || !isRating}
                outerClassName={readOnly && "disabled-field"}
                required
                onChange={() => limpaDependenteOnChange("operacao.operacaoRegistro.idRatingAgencia", null)}
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="operacao.operacaoRegistro.idRatingAgencia"
                name="operacao.operacaoRegistro.idRatingAgencia"
                label="Rating Série"
                options={ratingList}
                textField="nome"
                valueField="id"
                readOnly={readOnly || !isRating || !idAgenciaRating}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="operacao.operacaoRegistro.temFaculdade3Art7"
                name="operacao.operacaoRegistro.temFaculdade3Art7"
                label="Utilização da Faculdade do 3º do art. 7º?"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="operacao.operacaoRegistro.temRegistroCvm"
                name="operacao.operacaoRegistro.temRegistroCvm"
                label="Solicita Registro Provisório na CVM?"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="operacao.operacaoRegistro.enviarRegistroCvm"
                name="operacao.operacaoRegistro.enviarRegistroCvm"
                label="Envia Arquivos para Registro na CVM?"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="operacao.operacaoRegistro.idDestinacaoRecurso"
                name="operacao.operacaoRegistro.idDestinacaoRecurso"
                label="Artigo Lei 12.431"
                options={artigoLeiList}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
          </div>

          <Button
            type="button"
            name="Gerar arquivo .txt"
            action={() => exportArquivoB3() }
            classes="p-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mt-2"
          />
        </div>
      </div>
    </div>
  );
};

const selector = formValueSelector("passivoForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      limpaDependenteOnChange,
      updateRatingList,
      exportArquivoB3,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    agenciaList: state.passivoReducer.passivoFormTypes.agenciaList,
    ratingList: state.passivoReducer.passivoFormTypes.ratingList,
    temRating: selector(state, "operacao.operacaoRegistro.temRating"),
    idAgenciaRating: selector(state, "operacao.operacaoRegistro.idAgenciaRating"),
    tipoArquivoList: [
      { value: "Registro", key: 1, raw: "Registro" },
      { value: "Alteração", key: 2, raw: "Alteracao" },
    ],
    negociacaoSecundariaList: [
      { value: "Profissional", key: 1, raw: "Profissional" },
      { value: "Qualificado", key: 2, raw: "Qualificado" },
      { value: "Sem Restrição", key: 3, raw: "SemRestricao" },
    ],
    artigoLeiList: [
      { value: "Artigo 1º", key: 1, raw: "Artigo1" },
      { value: "Artigo 2º", key: 2, raw: "Artigo2" },
      { value: "Não se Aplica", key: 3, raw: "NaoSeAplica" },
    ],
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PassivoFormRegistro);
