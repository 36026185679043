export const CHANGE_SELECTED_ITEMS = 'CHANGE_SELECTED_ITEMS';


export function changeSelectItems(items) {
    return (dispatch) => {
        return dispatch([
            {
                type: CHANGE_SELECTED_ITEMS,
                payload: items
            }
        ])
    }
}


