import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AcessoPortalForm from "./form/AcessoPortalForm";
import { loadFormAcessoPortal } from "../../../../actions/acessoPortal/AcessoPortalAction";

class AcessoPortalPageGerenciar extends Component {
    componentDidMount() {
        this.props.loadFormAcessoPortal(this.getIdUsuario())
    }

    getIdUsuario() {
        let idUsuario = 0;

        if (this.props.match.params.idUsuario) {
            idUsuario = this.props.match.params.idUsuario;
        }

        return idUsuario;
    }

    render() {
        return <AcessoPortalForm />;
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ loadFormAcessoPortal }, dispatch);

export default connect(null, mapDispatchToProps)(AcessoPortalPageGerenciar);
