/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import {
  convertCurrencyNumber,
  formatPercent,
} from "../../../../../utils/helpers/NumberHelper";
import { withRouter } from "react-router-dom";
import Button from "../../../../common/button/Button";
import {
  getDateFormatedFromString,
  getDateFormatedFromStringWithTime,
} from "../../../../../utils/helpers/DateTimeHelper";
import {
  actionModal,
  loadContasReceber,
} from "../../../../../actions/aprovacao/AprovacaoAction";
import history from "../../../../common/routers/history";
import {
  APROVACAO_ROUTE_PATH,
  LISTA_ROUTE_PATH,
} from "../../../../common/routers/constantesRoutes";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import { APROVACAO, READ } from "../../../../../utils/actionsAndFeaturesAuth";
import { getStringBooleanValue } from "../../../../../utils/helpers/BooleanHelper";

let ModalContasReceber = (props) => {
  const {
    actionModal,
    open,
    contasReceber,
    permiteAprovar,
    idAprovacao,
    loadContasReceber,
    authorizationFunction,
  } = props;

  const [observacao, setObservacao] = React.useState(
    contasReceber?.observacao || ""
  );

  React.useEffect(() => {
    async function fetchLoad() {
      await authorizationFunction(
        { idAcao: READ, idFuncionalidade: APROVACAO },
        () => loadContasReceber(idAprovacao)
      );
    }

    if (open) fetchLoad();
  }, [open]);

  if (!open) return null;

  const close = () => {
    history.push(`/${APROVACAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`);
  };

  const moedaSimbolo = "R$";
  const idFormatado = contasReceber?.identificador || null;
  const identificador = contasReceber?.aprovacao?.identificador || null;

  return Object.keys(contasReceber)?.length > 0 ? (
    <Dialog maxWidth="xl" open={open} className="modalDialog" onClose={close}>
      <div className="modal-dialog modal-xl modal-fullscreen-2 modal-dialog-scrollable m-0 overflow-hidden">
        <div className="modal-content">
          <div className="modal-header px-4 pt-4 pb-2 d-flex flex-row align-items-start justify-content-between">
            <div className="d-flex flex-column">
              <span className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
                {contasReceber?.patrimonioSeparado?.descricao}
              </span>
              <span className="f-22 m-0 mt-2 txt-blue-link-dark">
                Contas a Receber
                {`${idFormatado ? ` — ${idFormatado}` : null}`}
              </span>
            </div>
            <Close role="button" className="text-danger" onClick={close} />
          </div>

          <div className="modal-body px-4 py-0">
            <div className="text-dark f-16">
              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-3">Contrato</div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Tipo de Pagamento</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.tipoPagamento?.value || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Tipo de Receita</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.produtoReceita?.nome || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Faturar Contra</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.faturarContra?.razaoSocialComCnpj || "--"}
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-4">
                Dados de Pagamento
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Conta Origem</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.contaOrigem?.conta || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Origem dos Recursos</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.origemRecurso?.descricao || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data de Pagamento</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.dataPagamento
                      ? getDateFormatedFromString(
                          contasReceber?.dataPagamento
                        )
                      : "--"}
                  </p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Valor Líquido</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.valorLiquido
                      ? convertCurrencyNumber(
                          contasReceber?.valorLiquido,
                          `${moedaSimbolo} `,
                          2
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Gross-up</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.grossUp
                      ? formatPercent(contasReceber?.grossUp, 2)
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Valor Bruto</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.valorBruto
                      ? convertCurrencyNumber(
                          contasReceber?.valorBruto,
                          `${moedaSimbolo} `,
                          2
                        )
                      : "--"}
                  </p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Descrição</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.descricao || "--"}
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-4">Aprovação</div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Enviado por</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.aprovacao?.solicitante?.nome || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data e Hora de Envio</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.aprovacao?.dataSolicitacao
                      ? getDateFormatedFromStringWithTime(
                          contasReceber?.aprovacao?.dataSolicitacao
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg"></div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Aprovador 1</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.aprovacao?.aprovadores[0]?.aprovador?.nome || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data e Hora Aprovação</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.aprovacao?.aprovadores[0]?.dataAprovacao
                      ? getDateFormatedFromStringWithTime(
                          contasReceber?.aprovacao?.aprovadores[0]?.dataAprovacao
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Observação</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.aprovacao?.aprovadores[0]?.observacao || "--"}
                  </p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Aprovador 2</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.aprovacao?.aprovadores[1]?.aprovador?.nome || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data e Hora Aprovação</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.aprovacao?.aprovadores[1]?.dataAprovacao
                      ? getDateFormatedFromStringWithTime(
                          contasReceber?.aprovacao?.aprovadores[1]?.dataAprovacao
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Observação</p>
                  <p className="fw-400 mb-0">
                    {contasReceber?.aprovacao?.aprovadores[1]?.observacao || "--"}
                  </p>
                </div>
              </div>

              {permiteAprovar && (
                <>
                  <div className="p-color f-20 fw-500 mb-2 mt-4">
                    Observação
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12 pr-0">
                      <textarea
                        className="w-100 rounded brd-1 p-2"
                        rows={7}
                        value={observacao}
                        onChange={(e) => setObservacao(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {permiteAprovar && (
            <div className="modal-footer px-3 py-2 d-flex flex-row align-content-center justify-content-between m-0">
              <Button
                name={"Rejeitar Contrato"}
                action={async () => {
                  await actionModal({
                    action: "reprovar",
                    identificador,
                    observacao,
                  });
                  close();
                }}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              />
              <Button
                name={"Aprovar Contrato"}
                action={async () => {
                  await actionModal({
                    action: "aprovar",
                    identificador,
                    observacao,
                  });
                  close();
                }}
                classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  contasReceber: state.aprovacaoReducer.contasReceberAprovacao || {},
  permiteAprovar: state.aprovacaoReducer.contasReceberAprovacao?.permiteAprovar,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      actionModal,
      loadContasReceber,
      authorizationFunction,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ModalContasReceber)
);
