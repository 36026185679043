/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import SingleFileInput from "../../../../../common/input/SingleFileInput";
import { DropdownList } from "react-widgets";
import { turnFileToBase64 } from "../../../../../../utils/helpers/FileHelper";
import { acoesPagamentoAF } from "../../../../../../actions/precificacao/PagamentoAction";

let ModalRevisarPagamento = (props) => {
  const { close, open, id, tipoMotivoEnum, acoesPagamentoAF, pagamento } = props;

  const [dataRevisao, setDataRevisao] = React.useState("");
  const [comprovante, setComprovante] = React.useState("");
  const [arquivoAnexo, setArquivoAnexo] = React.useState("");
  const [motivo, setMotivo] = React.useState("");
  const [descricaoMotivo, setDescricaoMotivo] = React.useState("");
  const [descricao, setDescricao] = React.useState("");

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">Revisão pela Gestão</p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <p className="mt-3 mb-0 f-16 fw-400">
            Você está prestes a revisar os dados de pagamento.
          </p>

          <div className="py-3">
            <div className="row px-3">
              <div
                className={`form-group field-floating has-float-label mb-0 px-0 col-lg-4`}
              >
                <input
                  type="date"
                  name="dataRevisao"
                  value={dataRevisao}
                  className="form-control w-100"
                  onChange={(e) => setDataRevisao(e.target.value)}
                />
                <label htmlFor="dataRevisao" className="control-label">
                  Data de Revisão
                  <span className="text-warning font-weight-bold ml-1">*</span>
                </label>
              </div>
            </div>
            <div className="row px-3 mt-2">
              <SingleFileInput
                value={comprovante}
                label="Comprovante"
                outerClassName="mb-0 px-0 col-12"
                removeFile={() => setComprovante("")}
                onChange={(e) => setComprovante(e.target.files[0])}
                required
              />
            </div>
            <div className="row px-3 mt-3">
              <div className={`mb-0 px-0 col-12`}>
                <span className="f-10-5 mb-2p lh-normal fw-400 txt-grey">
                  Motivo da Revisão
                  <span className="text-warning font-weight-bold ml-1">*</span>
                </span>
                <DropdownList
                  value={motivo}
                  valueField="key"
                  textField="value"
                  data={tipoMotivoEnum}
                  containerClassName="h-38-imp"
                  onChange={(e) => {
                    setMotivo(e.key);
                    if (e.key !== 1) setDescricaoMotivo("");
                  }}
                />
              </div>
            </div>
            {motivo == 1 && (
              <div className="row px-3 mt-2">
                <div
                  className={`form-group field-floating has-float-label mb-0 px-0 col-12`}
                >
                  <input
                    type="text"
                    name="descricaoMotivo"
                    value={descricaoMotivo}
                    className="form-control w-100"
                    onChange={(e) => setDescricaoMotivo(e.target.value)}
                  />
                  <label htmlFor="descricaoMotivo" className="control-label">
                    Qual o motivo?
                    <span className="text-warning font-weight-bold ml-1">*</span>
                  </label>
                </div>
              </div>
            )}
            <div className="row px-3 mt-2">
              <SingleFileInput
                value={arquivoAnexo}
                label="Anexar um arquivo"
                outerClassName="mb-0 px-0 col-12"
                removeFile={() => setArquivoAnexo("")}
                onChange={(e) => setArquivoAnexo(e.target.files[0])}
              />
            </div>
            <div className="row px-3 mt-3">
              <span className="f-10-5 mb-2p lh-normal fw-400 txt-grey">
                Descrição
              </span>
              <textarea
                rows="4"
                value={descricao}
                style={{ resize: "none" }}
                className="form-control brd-1 p-2"
                onChange={(e) => setDescricao(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Revisar Pagamento"}
              action={async () => {
                let userLogged = JSON.parse(localStorage.getItem("userLogged"));
                let emailLogged = userLogged?.email;

                let comp = comprovante;
                let compName = comprovante?.name;
                let anexo = arquivoAnexo;
                let anexoName = arquivoAnexo?.name;

                // prettier-ignore
                await acoesPagamentoAF({
                  id,
                  tipoMotivo: motivo,
                  motivo: descricaoMotivo,
                  dataRevisao: dataRevisao,
                  descricaoMotivo: descricao,
                  emailAprovacao: emailLogged,
                  idPagamentoPrecificacao: pagamento?.idPagamentoPrecificacao,
                  nomeArquivoComprovanteRevisao: compName,
                  arquivoComprovanteRevisao: comp ? await turnFileToBase64(comp) : null,
                  tipoArquivoComprovanteRevisao: compName?.substring(compName?.lastIndexOf('.') + 1).toLowerCase(),
                  nomeArquivoAnexoRevisao: anexoName,
                  arquivoAnexoRevisao: anexo ? await turnFileToBase64(anexo) : null,
                  tipoArquivoAnexoRevisao: anexoName?.substring(anexoName?.lastIndexOf('.') + 1).toLowerCase(),
                }, "enviar");

                close()
              }}
              classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
  id: state.pagamentoReducer.pagamentoFormTypes.id,
  tipoMotivoEnum: state.pagamentoReducer.pagamentoFormTypes.tipoMotivoEnum,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      acoesPagamentoAF,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ModalRevisarPagamento);
