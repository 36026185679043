import {
    LOAD_FILTER_FORM_APROVADORES,
    SALVA_FILTROS_APROVADORES,
    APROVADORES_FETCHED,
    APROVADORES_CONSULT_CURRENT,
    APROVADORES_RESET,
    LOAD_FORM_APROVADORES,
} from "../../actions/aprovadores/AprovadoresAction";

const INITIAL_STATE = {
    aprovadores: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    aprovadoresConsultCurrent: null,
    messages: null, 
    isLoading: false,
    aprovadoresFormTypesFilter: {},
    aprovadoresForm: {},
}

export default function (state = INITIAL_STATE, action = {}) {    
    switch(action.type) {
        case APROVADORES_FETCHED:
            return {
                ...state,
                aprovadores: action.payload.content,
                messages: action.payload.messages,
                isLoading: true,                
            }
        case APROVADORES_CONSULT_CURRENT:
            return {
                ...state,
                aprovadoresConsultCurrent: action.payload,      
                isLoading: true,     
            }
        case LOAD_FILTER_FORM_APROVADORES:
            return {
                ...state,
                aprovadoresFormTypesFilter: action.payload,
            }
        case LOAD_FORM_APROVADORES:
            return {
                ...state,
                aprovadoresForm: action.payload,
            }
        case APROVADORES_RESET:
            return {
                ...INITIAL_STATE
            }
        case SALVA_FILTROS_APROVADORES:
            return {
                ...state,
                filtrosAprovacao: action.payload
            }
        default:
            return state;
    }
}
