import React from 'react';
import ContainerRow from './ContainerRow';
import Tabs from '../tab/Tabs';
import TabHeaderWithRouter from '../tab/TabHeaderWithRouter';
import TabsContent from '../tab/TabsContent';
import If from '../If/If';
import Container from './Container';

const ContainerPage = props => (
    <Container>
        <If test={props.header}>
            <ContainerRow>
                {props.header}
            </ContainerRow>
        </If>
        <ContainerRow>
            <If test={props.tabs}>
                <Tabs>
                    <TabHeaderWithRouter
                        tabs={props.tabs}
                        className={props.className}
                        paramsRoute={props.paramsRoute}
                        indicatorColor={props.tabIndicatorColor || undefined}
                    />
                    <TabsContent>
                        {props.children}
                    </TabsContent>
                </Tabs>
            </If>
            <If test={!props.tabs}>
                {props.children}
            </If>
        </ContainerRow>
    </Container>
);

export default ContainerPage;
