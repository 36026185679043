/* eslint eqeqeq: 0 */

import { getBooleanValue, getBooleanValueFromString, getStringBooleanValue } from "../../../../utils/helpers/BooleanHelper";
import { getCurrentDateTimeStringNormal, getDateTimeString } from "../../../../utils/helpers/DateTimeHelper";
import { convertCurrencyToNumber } from "../../../../utils/helpers/NumberHelper";
import { extractFromObj } from "../../../../utils/helpers/ObjectHelper";
import { formatCNPJ } from "../../../../utils/helpers/StringHelper";

// envio
export function deserializeFieldsContasReceberFormFilter(filters, page) {
  let params = {};

  if (filters) {
    params = {
      ...filters,
      idPatrimonioSeparado: filters?.idPatrimonioSeparado?.key || null,
      idOperacao: filters?.idOperacao?.key || null,
      status: (filters?.status && filters?.status?.length) ? filters?.status : null,
      pagingConfig: {
        pageIndex: page,
        pageSize: 10,
      },
    };
  }

  return params;
}

// recebimento
export function serializeFieldsContasReceberFluxo(data) {
  let values = {
    ...data,
    contaOrigemList: serializeContasOrigem(data.contaOrigemList),
    receitaList: serializeContratoReceita(data.receitaList),
    contaReceber: {
      ...data.contaReceber,
      dataPagamento: getCurrentDateTimeStringNormal(data?.contaReceber?.dataPagamento),
    },
  }

  if (data.contaReceber?.aprovacao) {
    values = {
      ...values,
      contaReceber: {
        ...values?.contaReceber,
        aprovacao: {
          ...data.contaReceber?.aprovacao,
          dataSolicitacao: getCurrentDateTimeStringNormal(data?.contaReceber?.aprovacao?.dataSolicitacao),
        }
      }
    }
  }

  return values; 
}

// recebimento
export function serializeContasOrigem(contas) {
  let values = []

  if (contas && contas?.length) {
    return contas.map(item => ({
      ...item,
      nomeExibicao: `${item.tipoConta} (${item?.codigoBanco}_${item?.agencia}${item?.agenciaDigito ? `-${item?.agenciaDigito}` : ''}/${item?.conta}${item?.contaDigito ? `-${item.contaDigito}` : ''})`
    }));
  }

  return values; 
}

// recebimento
export function serializeContratoReceita(contrato) {
  let values = []

  if (contrato && contrato?.length) {
    return contrato.map(item => ({
      ...item,
      nomeExibicao: `${item?.identificador} - ${item?.tipoReceita}`
    }));
  }

  return values; 
}


// envio
export function deserializeFieldsContasReceberForm(conta) {
  const data = conta?.contaReceber;

  let values = {
    ...data,
    idOrigemRecurso: extractFromObj(data?.idOrigemRecurso),
    valorLiquido: convertCurrencyToNumber(data?.valorLiquido),
    idContaOrigem: extractFromObj(data?.idContaOrigem),
    idPatrimonioSeparado: extractFromObj(data?.idPatrimonioSeparado),
    idOperacao: extractFromObj(data?.idOperacao),
    idProdutoReceita: extractFromObj(data?.idProdutoReceita),
    idFaturarContra: extractFromObj(data?.idFaturarContra),
    idEmpresaFaturamento: extractFromObj(data?.idEmpresaFaturamento),
    idReceitaContrato: extractFromObj(data?.idReceitaContrato, "idReceitaContrato"),
  }

  // if (data?.escrituracao) {
  //   values = {
  //     ...values,
  //     escrituracao: {
  //       ...data.escrituracao,
  //       idNatureza: data?.escrituracao?.idNatureza ? extractFromObj(data?.escrituracao?.idNatureza) : null,
  //       idTes: data?.escrituracao?.idTes ? extractFromObj(data?.escrituracao?.idTes, "key") : null,
  //     }
  //   }
  // }

  return values;
}
