import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChipInput from 'material-ui-chip-input';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { pink } from '@material-ui/core/colors';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { arrayPush } from 'redux-form';

const TAB = 9;
const VIRGULA = 13;
const ESPACO = 32;
const ENTER = 188;
const PONTO_E_VIRGULA = 191;

const keys = [TAB, VIRGULA, ESPACO, ENTER, PONTO_E_VIRGULA];

const theme = getMuiTheme({
    textField: {
        focusColor: "rgba(0,0,0, 0.87)",
        floatingLabelColor: "rgba(0,0,0, 0.87)"
    }
});


const ChipInputCustom = ({ initialValue, input, hintText, floatingLabelText, className, disabled, required, params, arrayPush}) => {
    if(input.value === '') {
    input.onChange(initialValue);
    
    }
    function blurFunction (e, params) {
        const value = e.target.value;
        
        if (value !== "" && params.currentValue && !params.currentValue.includes(value)) {
            arrayPush(params.form, params.field, value);
            e.target.value = '';
        }
    }

    return (<MuiThemeProvider muiTheme={theme}>
        <ChipInput
            {...input}
            className={className}
            value={input.value || []}
            newChipKeyCodes={keys}
            fullWidth={true}
            fullWidthInput={true}
            disabled={disabled}
            required={required}
            style={{ backgroundColor: pink }}
            textFieldStyle={{ color: 'red' }}
            onBlur={(e) => blurFunction(e, params)}
            onRequestAdd={(addedChip) => {
                let values = input.value || [];
                values = values.slice();
                values.push(addedChip);
                input.onChange(values);
            }}
            onRequestDelete={(deletedChip) => {
                let values = input.value || [];
                values = values.filter(v => v !== deletedChip);
                input.onChange(values);
            }}
            hintText={hintText}
            floatingLabelText={floatingLabelText}
        />
    </MuiThemeProvider>);
};

const mapDispatchToProps = dispatch => bindActionCreators({ arrayPush }, dispatch);
export default connect(null, mapDispatchToProps)(ChipInputCustom);

