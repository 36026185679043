/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";

import { loadResumoFaq } from "../../../../../../../actions/operacao/PassivoAction";
import { bindActionCreators } from "redux";

import { Collapse } from "@material-ui/core";

const PassivoFormFaq = (props) => {
  const { passivoOperacao, loadResumoFaq } = props;
  const [collapseStates, setCollapseStates] = React.useState([]);

  const operacaoId = passivoOperacao?.operacao?.id;

  React.useEffect(() => {
    async function loadFaq() {
      const resp = await loadResumoFaq(operacaoId || "");
      const faq = Array.from(resp?.content).map((item) => ({ isOpen: false, ...item }));
      setCollapseStates(faq);
    }

    if (operacaoId) loadFaq();
  }, [operacaoId]);

  const handleCollapseClick = (index) => {
    const newCollapseStates = [...collapseStates];
    newCollapseStates[index].isOpen = !newCollapseStates[index].isOpen;
    setCollapseStates(newCollapseStates);
  };

  return (
    <div className="mt-4">
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">FAQ</span>

          {collapseStates.map((item, index) => {
            return (
              <div key={index} className="faq-row p-2 rounded mt-3 mb-2">
                <div
                  role="button"
                  onClick={() => handleCollapseClick(index)}
                  className="d-flex flex-row align-items-center justify-content-between"
                >
                  <p className="mb-0 fw-500 f-18">{item.pergunta}</p>
                  <i className={`fa fa-${item.isOpen ? "minus" : "plus"}`}></i>
                </div>
                <Collapse in={item.isOpen}>
                  <div>
                    <p className="mb-0 mt-2">{item.resposta}</p>
                  </div>
                </Collapse>
              </div>
            );
          })}

          {collapseStates?.length <= 0 && (
            <p className="mb-0 mt-2">Sem informações por enquanto</p>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadResumoFaq,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PassivoFormFaq);
