export function deserializeFieldsReportFormFilter(filters, page) {
    return {
        // REMOVER QUANDO AGING FOR REFATORADO
        idOperacao: filters && filters.operacoes ? filters.operacoes.map(o => o.key) : [], 
        
        // DEIXAR
        relatorioGerencial: filters && filters.typeReport ? filters.typeReport : null,
        statusOperacao: filters && filters.statusOperacao ? filters.statusOperacao : null,
        idGestor: filters?.gestor?.id || null,
        status: filters?.status && filters.status,
        dataBase: filters.dataBase ? `${filters.dataBase}-01` : null,
        pagingConfig: {
            pageIndex: page,
            pageSize: 50
        },
    };
}

export function deserializeFieldsReportFormFilterExportExcel(filters) {
    return {
        // REMOVER QUANDO AGING FOR REFATORADO
        idOperacao: filters && filters.operacao ? filters.operacao.map(o => o.key) : [],
        
        // DEIXAR
        relatorioGerencial: filters && filters.typeReport ? filters.typeReport : null,
        dataBase: filters.dataBase ? `${filters.dataBase}` : null,
    };
}

export function deseralizeReportFormFilter(filters){
    return {
        // REMOVER QUANDO AGING FOR REFATORADO
        idOperacao: filters && filters.operacao ? filters.operacao.map(o => o.key) : [],
        
        // DEIXAR
        relatorioGerencial: filters && filters.typeReport ? filters.typeReport : null,      
        dataBase: filters.dataBase ? `${filters.dataBase}-01` : null,
    }
}