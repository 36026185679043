/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";
import ConfirmDialog from "../../../../../common/modal/ConfirmDialog";
import { validate } from "../validation/despesaFormValidation";
import { showConfirmDialog } from "../../../../../../actions/common/modal/ConfirmDialogAction";
import FixedFloaterErrors from "../../../../../common/floats/FixedFloaterErrors";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import Input from "../../../../../common/input/Input";
import MaskedFormatInput from "../../../../../common/input/MaskedFormatInput";
import DocumentsInput from "../../../../../common/input/DocumentsInput";
import MaskedInput from "../../../../../common/input/MaskedInput";
import { getArrayNumberRange } from "../../../../../../utils/helpers/ArrayHelper";
import Button from "../../../../../common/button/Button";
import { customFormatPhone } from "../../../../../../utils/helpers/StringHelper";
import { multiply } from "mathjs";
import { convertCurrencyNumber, convertCurrencyToNumber } from "../../../../../../utils/helpers/NumberHelper";
import {
  showDespesaList,
  showMessageAlert,
  mudaDependenteOnChange,
  limpaDependentesOnChange,
  loadContasDestino,
  calculaParcelas,
  loadLancamentos,
  onDropDocument,
  onDeleteDocument,
  downloadPedidoDocument,
} from "../../../../../../actions/operacao/DespesaAction";
import {
  reduxForm,
  formValueSelector,
  Field,
  change,
  arrayInsert,
  arrayRemove,
} from "redux-form";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { withStyles } from "@material-ui/core/styles";
import Textarea from "../../../../../common/input/Textarea";
import { calcularDiferencaDataDias, getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import PaginationRB from "../../../../../common/pagination/PaginationRB";
import { useDropzone } from "react-dropzone";
import { msgError } from "../../../../../../utils/helpers/OthersHelpers";
import { convertFileToBase64 } from "../../../../../../utils/helpers/FileHelper";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const styles = (theme) => ({
  parent: {
    width: "100%",
    marginTop: "0.5%",
    backgroundColor: "transparent",
  },
  mrButton: {
    marginRight: theme.spacing.unit,
  },
  button: {
    color: "#630A37",
    "&:disabled": {
      color: "#D0D0D0",
    },
  },
  buttons: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "1rem !important",
  },
  stepper: {
    backgroundColor: "transparent",
  },
  active: {},
  completed: {},
  customStepLabel: {
    "& [class*='MuiStepLabel-labelContainer']": {
      display: "flex",
      justifyContent: "center",
    },
    "& [class*='MuiTypography-root']": {
      width: "max-content",
      padding: "0 10px",
      borderRadius: "20px",
      // fontSize: "12px",
      // textTransform: "uppercase",
      "&[class*='MuiStepLabel']": {
        backgroundColor: "#B8B8B8",
        color: "#121212c9",
        fontWeight: 500,
      },
      "&[class*='MuiStepLabel-active']": {
        backgroundColor: "#FFC107",
      },
      "&[class*='MuiStepLabel-completed']": {
        backgroundColor: "#83BF74",
        color: "#ffffff",
        "&.revisao": {
          backgroundColor: "#cc0000",
        },
      },
    },
  },
});


let DespesaForm = (props) => {
  const {
    match,
    location,
    handleSubmit,
    showDespesaList,
    title,
    readOnly,
    floatButtonVisible,
    operacao,
    operacoes,
    showConfirmDialog,
    showCancelConfirmDialog,
    //
    invalid,
    showMessageAlert,
    messages,
    controleDespesa,
    despesaReembolsavelRecorrente,
    despesaReembolsavelFlat,
    limpaDependentesOnChange,
    mudaDependenteOnChange,
    contatos,
    faturamentos,
    possuiPagamentoFlat,
    possuiPagamentoRecorrente,
    idForPmtFlat,
    idForPmtRec,
    possuiCorrecaoRecorrente,
    loadContasDestino,
    serie,
    dataPrimeiraParcela,
    dataVencimento,
    periodicidade,
    calculaParcelas,
    idOrigemRecursoRec,
    idOrigemRecursoFlat,
    // LISTS
    formasPagamentosList,
    fornecedoresList,
    indexadoresCorrecoesList,
    mesesList,
    origemRecursosList,
    periodicidadesList,
    tiposDespesasList,
    contasDestinosList,
    controleDespesasList,
    operacoesList,
    serieContasOrigensList,
    patrimonioSeparadoContasOrigensList,
    documentosSuporteList,
    documentos,
    // 
    classes,
    despesaOperacao,
    lancamentosData,
    lancamentosFetch,
    onDropDocument,
    onDeleteDocument,
    downloadPedidoDocument,
    statusAprovacao,
    loadLancamentos,
    arrayInsert,
    arrayRemove,
    modalidadeList,
    tipoCobrancaList,
    idModalidade,
  } = props;

  const user = JSON.parse(localStorage.getItem("userLogged"));
  const isAdmin = user.acessos.some((acesso) => acesso === 1);

  const [tabSelected, setTabSelected] = React.useState(1);
  const [isDragging, setIsDragging] = React.useState(false);

  const hasApproval = despesaOperacao?.aprovacao;

  const NAO_SE_APLICA = 20; // '20': key atual do 'Não se Aplica';
  
  const idsOperacoes = operacoes.map((o) => o.key);

  const despReembFlat = despesaReembolsavelFlat == "Sim";
  const despReembRec = despesaReembolsavelRecorrente == "Sim";

  const despRecNaoSeAplica = ((idOrigemRecursoRec?.id || idOrigemRecursoRec) == NAO_SE_APLICA);
  const despFlatNaoSeAplica = ((idOrigemRecursoFlat?.id || idOrigemRecursoFlat) == NAO_SE_APLICA);

  const possuiPmtFlat = possuiPagamentoFlat == "Sim";
  const possuiPmtRec = possuiPagamentoRecorrente == "Sim";
  const possuiCorrecaoRec = possuiCorrecaoRecorrente == "Sim";

  const contasOrigemList =
    controleDespesa?.key == 1 // PS
      ? patrimonioSeparadoContasOrigensList
      : controleDespesa?.key == 2 // SÉRIE
      ? serieContasOrigensList?.filter((c) => c?.idOperacao == serie?.id) || []
      : serieContasOrigensList || [];

  const statusValue = despesaOperacao?.statusAprovacao?.key;

  const REVISAO = statusValue == 5;

  let renderStatus = [
      {
        key: 1,
        value: "Cadastro Pendente",
        raw: "CadastroPendente",
        show: false,
        concluidos: [],
      },
      {
        key: 2,
        value: "Cadastrado",
        raw: "Cadastrado",
        show: true,
        concluidos: [1],
      },
      {
        key: 3,
        value: "Validado",
        raw: "Validado",
        show: !REVISAO,
        concluidos: [1, 2],
      },
      {
        key: 5,
        value: "Revisão",
        raw: "Revisao",
        show: REVISAO,
        concluidos: [1, 2],
      },
      {
        key: 4,
        value: "Aprovado",
        raw: "Aprovado",
        show: true,
        concluidos: [1, 2, 3],
      },
  ];

  const steps = renderStatus.filter((sts) => sts.show).map((sts) => sts.value);
  const concluidos = renderStatus.filter((sts) => sts.key === statusValue).flatMap((sts) => sts.concluidos);

  function CustomStepIcon({ active, completed, classes }) {
    const emRevisao = classes?.completed?.includes("revisao");
    const cRevi = "#cc0000";
    const cComp = "#83BF74";
    const cActv = "#FFC107";
    const cDflt = "#888888";

    return emRevisao ? (
      <RadioButtonChecked style={{ color: cRevi }} />
    ) : completed ? (
      <CheckCircleOutline style={{ color: cComp }} />
    ) : active ? (
      <RadioButtonChecked style={{ color: cActv }} />
    ) : (
      <RadioButtonUnchecked style={{ color: cDflt }} />
    );
  }

  // UPLOAD DOCUMENTS
  const onDrop = async (acceptedFiles) => {
    const files = acceptedFiles;

    let arquivos = await Promise.all(
      files.map(async (file) => ({
        nomeArquivo: file.name,
        idDespesaOperacao: despesaOperacao?.id || null,
        arquivoAnexado: await convertFileToBase64(file),
        formatoArquivo: file?.name?.split('.').pop().toLowerCase() || '',
      }))
    );

    onDropDocument(arquivos);
    setIsDragging(false);
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    // accept: {
    //   "application/pdf": [".pdf"],
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    //     ".xlsx",
    //   ],
    //   "application/vnd.ms-excel": [".xls"],
    // },
    onDragEnter: () => {
      setIsDragging(true);
    },
    onDragLeave: () => {
      setIsDragging(false);
    },
    onDropRejected: (rejected) => {
      const names = rejected.map((item) => item.file.name);
      names.forEach((name) => msgError(`Arquivo (${name}) inválido`));
    },
    disabled: readOnly,
  });

  const tableHeader = (
    <tr>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">ID</div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          STATUS
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          DATA DE PAGAMENTO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          VALOR BRUTO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          NÚMERO DOCUMENTO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          CONTA ORIGEM
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          CONTA DESTINO
        </div>
      </th>
    </tr>
  );

  const tableContent =
    lancamentosData &&
    lancamentosData.hasItems &&
    lancamentosData.items.map((item, index) => (
      <tr key={index} className={`tr-status`}>
        <td className="text-center fw-400">{item?.identificador || "--"}</td>
        <td className="text-center fw-400">{item?.status?.value || "--"}</td>
        <td className="text-center fw-400">{item?.dataPrevisaoPagamento ? getDateFormatedFromString(item.dataPrevisaoPagamento) : "--"}</td>
        <td className="text-center fw-400">{item?.valorBruto ? convertCurrencyNumber(item?.valorBruto, "R$ ", 2) : "--"}</td>
        <td className="text-center fw-400">{item?.numeroDocumento || "--"}</td>
        <td className="text-center fw-400">{item?.contaOrigem?.nomeExibicao || "--"}</td>
        <td className="text-center fw-400">{item?.contaDestino?.nomeExibicao || "--"}</td>
      </tr>
    ));

    const addContato = (index) => {
      if (!readOnly || (readOnly && statusAprovacao?.key == 3)) {
        arrayInsert("DespesaForm", "contatos", index, {});
      }
    }

    const removeContato = (index) => {
      if ((!readOnly || (readOnly && statusAprovacao?.key == 3)) && contatos.length > 0) {
        arrayRemove("DespesaForm", "contatos", index);
      }
    }

    const addFaturamento = (index) => {
      if (!readOnly) {
        arrayInsert("DespesaForm", "faturamentos", index, {});
      }
    }

    const removeFaturamento = (index) => {
      if (!readOnly && faturamentos.length > 0) {
        arrayRemove("DespesaForm", "faturamentos", index);
      }
    }

    const calcularValorContrato = (value, fieldName) => {
      const { valorBrutoRec, qntParcelaRec, change } = props;

      if (fieldName == "quantidadeParcela") value = parseFloat(value) || 0;
      if (fieldName == "valorBruto") value = convertCurrencyToNumber(value) || 0;

      let valorContrato = null;
      
      if (fieldName === "quantidadeParcela" && valorBrutoRec) {
        valorContrato = multiply(value, convertCurrencyToNumber(valorBrutoRec) || 0);
      } else if (fieldName === "valorBruto" && qntParcelaRec) {
        valorContrato = multiply(value, parseFloat(qntParcelaRec) || 0);
      }

      change("DespesaForm", "despesaPagamentoRecorrente.valorContrato", valorContrato);
    }

    const retornaDiasPeriodo = (periodicidadeKey) => {
      return {
        1: 30,
        2: 60,
        3: 90,
        4: 180,
        5: 365,
      }[periodicidadeKey] || 0;
    }

    const carregaLancamentos = (pageIndex = 1) => {
      let idDespesa = match.params.idDespesa;
      const query = new URLSearchParams(location.search).get("q");

      loadLancamentos((idDespesa || query), pageIndex);
    }

    return (
      <Form>
        <FormHeader
          title={title}
          col={"col-md"}
          offSet={20}
          codigoOpea={operacao ? operacao.codigoRb : operacoes.length > 0 ? operacoes[0].value : ""}
        ></FormHeader>

        <Stepper
            className="my-3"
            classes={{ root: classes.stepper }}
            activeStep={REVISAO ? null : concluidos.length || 0}
            alternativeLabel
          >
            {steps.map((step, index) => (
              <Step
                key={index}
                completed={concluidos.some((et) => et === index + 1)}
              >
                <StepLabel
                  completed={concluidos.some((et) => et === index + 1)}
                  className={classes.customStepLabel}
                  StepIconComponent={CustomStepIcon}
                  classes={{
                    completed: `${classes.completed} ${
                      REVISAO && index + 1 == 2 && "revisao"
                    }`,
                  }}
                  StepIconProps={{
                    classes: {
                      completed: `${classes.completed} ${
                        REVISAO && index + 1 == 2 && "revisao"
                      }`,
                      active: classes.active,
                      disabled: classes.disabled,
                    },
                  }}
                >
                  {step}
                </StepLabel>
              </Step>
            ))}
        </Stepper>

        <FormContent handleSubmit={handleSubmit}>
          <div className="row">
            {messages?.hasError && (
              <FixedFloaterErrors
                messages={messages}
                title="Status do Cadastro"
              />
            )}

            <div className="d-flex flex-row px-4 py-3 mb-2">
              <div
                role="button"
                className="mr-3 fw-400"
                onClick={() => setTabSelected(1)}
              >
                <p className="px-3 mb-2">GERAL</p>
                <div className={`tab-desp ${tabSelected == 1 ? 'active' : ''}`}></div>
              </div>
              <div
                role="button"
                className="mr-3 fw-400"
                onClick={() => {
                  setTabSelected(2)
                  carregaLancamentos();
                }}
              >
                <p className="px-3 mb-2">LANÇAMENTOS</p>
                <div className={`tab-desp ${tabSelected == 2 ? 'active' : ''}`}></div>
              </div>
            </div>

            {(tabSelected == 1) ? (
              <>
                {/* Status */}
                {Boolean(hasApproval) && (
                  <div className="col-12">
                    <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                      <span className="h3 page-head__title">Status</span>
                      <div className="row">
                        <div className="col-lg">
                          <Field
                            component={Input}
                            inputId="aprovacao.dataSolicitacao"
                            name="aprovacao.dataSolicitacao"
                            label="Data de Cadastro"
                            type={"date"}
                            readOnly={true}
                            required
                          />
                        </div>
                        <div className="col-lg">
                          <Field
                            component={Input}
                            inputId="aprovacao.solicitante.nome"
                            name="aprovacao.solicitante.nome"
                            label="Solicitante"
                            readOnly={true}
                            required
                          />
                        </div>
                        <div className="col-lg">
                          <Field
                            component={Input}
                            inputId="aprovacao.idFormatado"
                            name="aprovacao.idFormatado"
                            label="ID Aprovação"
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg">
                          <Field
                            component={Input}
                            inputId="aprovacao.aprovadores[0].aprovador.nome"
                            name="aprovacao.aprovadores[0].aprovador.nome"
                            label="Aprovador 1"
                            readOnly={true}
                            required
                          />
                        </div>
                        <div className="col-lg">
                          <Field
                            component={Input}
                            inputId="aprovacao.aprovadores[0].statusAprovacao.value"
                            name="aprovacao.aprovadores[0].statusAprovacao.value"
                            label="Status Aprovação 1"
                            readOnly={true}
                            required
                          />
                        </div>
                        <div className="col-lg">
                          <Field
                            component={Input}
                            inputId="aprovacao.aprovadores[0].observacao"
                            name="aprovacao.aprovadores[0].observacao"
                            label="Comentário Aprovador 1"
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg">
                          <Field
                            component={Input}
                            inputId="aprovacao.aprovadores[1].aprovador.nome"
                            name="aprovacao.aprovadores[1].aprovador.nome"
                            label="Aprovador 2"
                            readOnly={true}
                            required
                          />
                        </div>
                        <div className="col-lg">
                          <Field
                            component={Input}
                            inputId="aprovacao.aprovadores[1].statusAprovacao.value"
                            name="aprovacao.aprovadores[1].statusAprovacao.value"
                            label="Status Aprovação 2"
                            readOnly={true}
                            required
                          />
                        </div>
                        <div className="col-lg">
                          <Field
                            component={Input}
                            inputId="aprovacao.aprovadores[1].observacao"
                            name="aprovacao.aprovadores[1].observacao"
                            label="Comentário Aprovador 2"
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
    
                {/* Contrato */}
                <div className="col-12">
                  <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                    <span className="h3 page-head__title">Contrato</span>
                    <div className="row">
                      <div className="col-lg">
                        <Field
                          component={DropdownListInput}
                          inputId="serie"
                          name="serie"
                          label="Série"
                          options={operacoesList}
                          textField="descricao"
                          valueField="id"
                          filter="contains"
                          readOnly={true}
                          required
                          onChange={() => {
                            limpaDependentesOnChange([
                              "despesaPagamentoFlat.idContaOrigem",
                              "despesaPagamentoRecorrente.idContaOrigem",
                            ]);
                          }}
                        />
                      </div>
                      <div className="col-lg">
                        <Field
                          component={DropdownListInput}
                          inputId="idDespesaTipo"
                          name="idDespesaTipo"
                          label="Tipo de Despesa"
                          options={tiposDespesasList}
                          textField="descricao"
                          valueField="id"
                          filter="contains"
                          readOnly={readOnly}
                          required
                        />
                      </div>
                      <div className="col-lg">
                        <Field
                          component={DropdownListInput}
                          inputId="idEmpresaPrestadora"
                          name="idEmpresaPrestadora"
                          label="Fornecedor"
                          options={fornecedoresList}
                          textField="razaoSocialComCnpj"
                          valueField="idEmpresaPrestadora"
                          filter="contains"
                          readOnly={readOnly}
                          onChange={(_e, v) => {
                            limpaDependentesOnChange([
                              "despesaPagamentoFlat.idContaDestino",
                              "despesaPagamentoRecorrente.idContaDestino",
                            ])
    
                            loadContasDestino(v?.idEmpresaPrestadora);
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <Field
                          component={DropdownListInput}
                          inputId="idDocumentoSuporte"
                          name="idDocumentoSuporte"
                          label="Documento Suporte"
                          options={documentosSuporteList}
                          textField="descricao"
                          valueField="id"
                          filter="contains"
                          readOnly={readOnly}
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <Field
                          component={Input}
                          inputId="referenciaDocumento"
                          name="referenciaDocumento"
                          label="Referência Documento"
                          readOnly={readOnly}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-7">
                        <Field
                          component={Input}
                          inputId="observacao"
                          name="observacao"
                          label="Observações"
                          readOnly={readOnly}
                        />
                      </div>
                      <div className="col-lg-5">
                        <Field
                          component={Input}
                          inputId="identificador"
                          name="identificador"
                          label="Identificador"
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
    
                {/* Pagamento Flat */}
                <div className="col-12">
                  <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                    <span className="h3 page-head__title">Pagamento Flat</span>
                    <div className="row">
                      <div className="col-lg-4">
                        <Field
                          component={DropdownListInput}
                          inputId="possuiPagamentoFlat"
                          name="possuiPagamentoFlat"
                          label="Possui Pagamento Flat?"
                          readOnly={readOnly}
                          onChange={(_e, v) => {
                            if (v !== 'Sim') {
                              limpaDependentesOnChange([
                                "despesaPagamentoFlat.valorBruto",
                                "despesaPagamentoFlat.dataVencimento",
                                "despesaPagamentoFlat.despesaReembolsavel",
                                "despesaPagamentoFlat.idReembolsante",
                                "despesaPagamentoFlat.idOrigemRecurso",
                                "despesaPagamentoFlat.idContaOrigem",
                                "despesaPagamentoFlat.idFormaPagamento",
                                "despesaPagamentoFlat.idContaDestino"
                              ])
                            }
                          }}
                          required
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          component={MaskedInput}
                          inputId="despesaPagamentoFlat.valorBruto"
                          name="despesaPagamentoFlat.valorBruto"
                          label="Valor Bruto (Flat)"
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          prefix="R$ "
                          fixedDecimalScale
                          readOnly={readOnly || !possuiPmtFlat}
                          required={possuiPmtFlat}
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          component={Input}
                          inputId="despesaPagamentoFlat.dataVencimento"
                          name="despesaPagamentoFlat.dataVencimento"
                          label="Data Vencimento (Flat)"
                          type={"date"}
                          readOnly={readOnly || !possuiPmtFlat}
                          required={possuiPmtFlat}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoFlat.idContaOrigem"
                          name="despesaPagamentoFlat.idContaOrigem"
                          label="Conta Origem"
                          options={contasOrigemList}
                          textField="tipoConta"
                          valueField="id"
                          filter="contains"
                          readOnly={readOnly || !possuiPmtFlat}
                          required={possuiPmtFlat}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoFlat.idOrigemRecurso"
                          name="despesaPagamentoFlat.idOrigemRecurso"
                          label="Origem de Recursos"
                          options={origemRecursosList}
                          textField="descricao"
                          valueField="id"
                          filter="contains"
                          readOnly={readOnly || !possuiPmtFlat}
                          required={possuiPmtFlat}
                          onChange={(_e, v) => {
                            if (v?.id == NAO_SE_APLICA) mudaDependenteOnChange("despesaPagamentoFlat.despesaReembolsavel", "Sim");
                          }}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoFlat.despesaReembolsavel"
                          name="despesaPagamentoFlat.despesaReembolsavel"
                          label="Despesa Reembolsável?"
                          readOnly={readOnly || !possuiPmtFlat || despFlatNaoSeAplica}
                          onChange={(_e, v) => {
                            if (v == 'Não') mudaDependenteOnChange("despesaPagamentoFlat.idReembolsante", null);
                          }}
                          required={possuiPmtFlat}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoFlat.idReembolsante"
                          name="despesaPagamentoFlat.idReembolsante"
                          label="Reembolsante"
                          options={fornecedoresList}
                          textField="razaoSocialComCnpj"
                          valueField="idEmpresaPrestadora"
                          filter="contains"
                          readOnly={readOnly || !possuiPmtFlat || !despReembFlat}
                          required={despReembFlat && possuiPmtFlat}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoFlat.idFormaPagamento"
                          name="despesaPagamentoFlat.idFormaPagamento"
                          label="Forma de Pagamento"
                          options={formasPagamentosList}
                          textField="value"
                          valueField="key"
                          filter="contains"
                          readOnly={readOnly || !possuiPmtFlat}
                          required={possuiPmtFlat}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoFlat.idContaDestino"
                          name="despesaPagamentoFlat.idContaDestino"
                          label="Conta Destino"
                          options={contasDestinosList}
                          textField="tipoConta"
                          valueField="id"
                          filter="contains"
                          readOnly={readOnly || !possuiPmtFlat}
                          required={possuiPmtFlat && (idForPmtFlat == 1 || idForPmtFlat == 3)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Pagamento Recorrente */}
                <div className="col-12">
                  <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                    <span className="h3 page-head__title">
                      Pagamento Recorrente
                    </span>
                    <div className="row">
                      <div className="col-lg">
                        <Field
                          component={DropdownListInput}
                          inputId="possuiPagamentoRecorrente"
                          name="possuiPagamentoRecorrente"
                          label="Possui Pagamento Recorrente?"
                          readOnly={readOnly}
                          onChange={(_e, v) => {
                            if (v !== 'Sim') {
                              limpaDependentesOnChange([
                                "despesaPagamentoRecorrente.valorBrutoInicial",
                                "despesaPagamentoRecorrente.dataVencimento",
                                "despesaPagamentoRecorrente.diaPagamento",
                                "despesaPagamentoRecorrente.periodicidade",
                                "despesaPagamentoRecorrente.quantidadeParcela",
                                "despesaPagamentoRecorrente.valorContrato",
                                "despesaPagamentoRecorrente.possuiCorrecao",
                                "despesaPagamentoRecorrente.indexadorCorrecao",
                                "despesaPagamentoRecorrente.mesesDefasagem",
                                "despesaPagamentoRecorrente.mesCorrecao",
                                "despesaPagamentoRecorrente.despesaReembolsavel",
                                "despesaPagamentoRecorrente.idReembolsante",
                                "despesaPagamentoRecorrente.idOrigemRecurso",
                                "despesaPagamentoRecorrente.idContaOrigem",
                                "despesaPagamentoRecorrente.idFormaPagamento",
                                "despesaPagamentoRecorrente.idContaDestino",
                                "despesaPagamentoRecorrente.modalidade",
                                "despesaPagamentoRecorrente.tipoCobranca",
                              ])
                            }
                          }}
                          required
                        />
                      </div>
                      <div className="col-lg">
                        <Field
                          component={MaskedInput}
                          inputId="despesaPagamentoRecorrente.valorBrutoInicial"
                          name="despesaPagamentoRecorrente.valorBrutoInicial"
                          label="Valor Bruto Inicial (Recorrente)"
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          prefix="R$ "
                          fixedDecimalScale
                          onChange={(e) => calcularValorContrato(e.currentTarget.value, "valorBruto")}
                          readOnly={readOnly || !possuiPmtRec}
                          required={possuiPmtRec}
                        />
                      </div>
                      <div className="col-lg">
                        <Field
                          component={Input}
                          inputId="despesaPagamentoRecorrente.dataPrimeiraParcela"
                          name="despesaPagamentoRecorrente.dataPrimeiraParcela"
                          label="Data de Início (1ª Parcela)"
                          type={"date"}
                          readOnly={readOnly || !possuiPmtRec}
                          required={possuiPmtRec}
                          onChange={(v, _value) => {
                            if (dataVencimento && _value && periodicidade) {
                              const diff = calcularDiferencaDataDias(dataVencimento, _value);
                              const dias = retornaDiasPeriodo(periodicidade);
                              const parcelas = calculaParcelas(diff, dias);
                              calcularValorContrato(parcelas, "quantidadeParcela")
                            }
                          }}
                        />
                      </div>
                      <div className="col-lg">
                        <Field
                          component={Input}
                          inputId="despesaPagamentoRecorrente.dataVencimento"
                          name="despesaPagamentoRecorrente.dataVencimento"
                          label="Data de Vencimento (Contrato)"
                          type={"date"}
                          readOnly={readOnly || !possuiPmtRec}
                          required={possuiPmtRec}
                          onChange={(v, _value) => {
                            if (_value && dataPrimeiraParcela && periodicidade) {
                              const diff = calcularDiferencaDataDias(_value, dataPrimeiraParcela);
                              const dias = retornaDiasPeriodo(periodicidade);
                              const parcelas = calculaParcelas(diff, dias);
                              calcularValorContrato(parcelas, "quantidadeParcela")
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoRecorrente.diaPagamento"
                          name="despesaPagamentoRecorrente.diaPagamento"
                          label="Dia do Pagamento"
                          options={getArrayNumberRange(1, 31)}
                          textField="textField"
                          valueField="valueField"
                          readOnly={readOnly || !possuiPmtRec}
                          required={possuiPmtRec}
                        />
                      </div>
                      <div className="col-lg">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoRecorrente.periodicidade"
                          name="despesaPagamentoRecorrente.periodicidade"
                          label="Periodicidade"
                          options={periodicidadesList}
                          textField="value"
                          valueField="key"
                          filter="contains"
                          readOnly={readOnly || !possuiPmtRec}
                          required={possuiPmtRec}
                          onChange={(v, _value) => {
                            if (dataVencimento && dataPrimeiraParcela && _value?.key) {
                              const diff = calcularDiferencaDataDias(dataVencimento, dataPrimeiraParcela)
                              const dias = retornaDiasPeriodo(_value?.key);
                              const parcelas = calculaParcelas(diff, dias);
                              calcularValorContrato(parcelas, "quantidadeParcela")
                            }
                          }}
                        />
                      </div>
                      <div className="col-lg">
                        <Field
                          component={MaskedFormatInput}
                          inputId="despesaPagamentoRecorrente.quantidadeParcela"
                          name="despesaPagamentoRecorrente.quantidadeParcela"
                          label="Quantidade Parcelas"
                          readOnly={true}
                          onChange={(e) => calcularValorContrato(e.currentTarget.value, "quantidadeParcela")}
                          required={possuiPmtRec}
                        />
                      </div>
                      <div className="col-lg">
                        <Field
                          component={MaskedInput}
                          inputId="despesaPagamentoRecorrente.valorContrato"
                          name="despesaPagamentoRecorrente.valorContrato"
                          label="Valor do Contrato"
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          prefix="R$ "
                          fixedDecimalScale
                          icon="info-circle"
                          iconColor="#630a3679"
                          tooltipMessage="Quantidade Parcelas x Valor Bruto Inicial (Recorrente)"
                          tooltipId="despPmtValContr"
                          readOnly={true}
                          required={possuiPmtRec}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg">
                        <Field
                          component={DropdownListInput}
                          inputId="possuiCorrecao"
                          name="despesaPagamentoRecorrente.possuiCorrecao"
                          label="Possui Correção?"
                          readOnly={readOnly || !possuiPmtRec}
                          required={possuiPmtRec}
                          onChange={(_e, v) => {
                            if (v !== 'Sim') {
                              limpaDependentesOnChange([
                                "despesaPagamentoRecorrente.indexadorCorrecao",
                                "despesaPagamentoRecorrente.mesesDefasagem",
                                "despesaPagamentoRecorrente.mesCorrecao"
                              ])
                            }
                          }}
                        />
                      </div>
                      <div className="col-lg">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoRecorrente.indexadorCorrecao"
                          name="despesaPagamentoRecorrente.indexadorCorrecao"
                          label="Indexador de Correção"
                          options={indexadoresCorrecoesList}
                          textField="value"
                          valueField="key"
                          filter="contains"
                          readOnly={readOnly || !possuiPmtRec || !possuiCorrecaoRec}
                          required={possuiPmtRec && possuiCorrecaoRec}
                        />
                      </div>
                      <div className="col-lg">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoRecorrente.mesesDefasagem"
                          name="despesaPagamentoRecorrente.mesesDefasagem"
                          label="Meses de Defasagem"
                          options={getArrayNumberRange(0, 8)}
                          textField="textField"
                          valueField="valueField"
                          readOnly={readOnly || !possuiPmtRec || !possuiCorrecaoRec}
                          required={possuiPmtRec && possuiCorrecaoRec}
                        />
                      </div>
                      <div className="col-lg">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoRecorrente.mesCorrecao"
                          name="despesaPagamentoRecorrente.mesCorrecao"
                          label="Mês de Correção"
                          options={mesesList}
                          textField="value"
                          valueField="key"
                          filter="contains"
                          readOnly={readOnly || !possuiPmtRec || !possuiCorrecaoRec}
                          required={possuiPmtRec && possuiCorrecaoRec}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoRecorrente.idContaOrigem"
                          name="despesaPagamentoRecorrente.idContaOrigem"
                          label="Conta Origem"
                          options={contasOrigemList}
                          textField="tipoConta"
                          valueField="id"
                          filter="contains"
                          readOnly={readOnly || !possuiPmtRec}
                          required={possuiPmtRec}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoRecorrente.idOrigemRecurso"
                          name="despesaPagamentoRecorrente.idOrigemRecurso"
                          label="Origem de Recursos"
                          options={origemRecursosList}
                          textField="descricao"
                          valueField="id"
                          filter="contains"
                          readOnly={readOnly || !possuiPmtRec}
                          required={possuiPmtRec}
                          onChange={(_e, v) => {
                            if (v?.id) {
                              if (v?.id == NAO_SE_APLICA) {
                                mudaDependenteOnChange("despesaPagamentoRecorrente.despesaReembolsavel", "Sim");
                                mudaDependenteOnChange("despesaPagamentoRecorrente.modalidade", modalidadeList.find((e) => e.key == 1));
                                mudaDependenteOnChange("despesaPagamentoRecorrente.tipoCobranca", tipoCobrancaList.find((e) => e.key == 1));
                              } else {
                                mudaDependenteOnChange("despesaPagamentoRecorrente.modalidade", modalidadeList.find((e) => e.key == 2));
                                mudaDependenteOnChange("despesaPagamentoRecorrente.tipoCobranca", null);
                              }
                            } else {
                              limpaDependentesOnChange([
                                "despesaPagamentoRecorrente.despesaReembolsavel",
                                "despesaPagamentoRecorrente.modalidade",
                              ]);
                            }
                          }}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoRecorrente.despesaReembolsavel"
                          name="despesaPagamentoRecorrente.despesaReembolsavel"
                          label="Despesa Reembolsável?"
                          readOnly={readOnly || !possuiPmtRec || despRecNaoSeAplica}
                          required={possuiPmtRec}
                          onChange={(_e, v) => {
                            if (v == "Não") mudaDependenteOnChange("despesaPagamentoRecorrente.idReembolsante", null);
                          }}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoRecorrente.idReembolsante"
                          name="despesaPagamentoRecorrente.idReembolsante"
                          label="Reembolsante"
                          options={fornecedoresList}
                          textField="razaoSocialComCnpj"
                          valueField="idEmpresaPrestadora"
                          filter="contains"
                          readOnly={readOnly || !possuiPmtRec || !despReembRec}
                          required={possuiPmtRec}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoRecorrente.idFormaPagamento"
                          name="despesaPagamentoRecorrente.idFormaPagamento"
                          label="Forma de Pagamento"
                          options={formasPagamentosList}
                          textField="value"
                          valueField="key"
                          filter="contains"
                          readOnly={readOnly || !possuiPmtRec}
                          required={possuiPmtRec}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoRecorrente.idContaDestino"
                          name="despesaPagamentoRecorrente.idContaDestino"
                          label="Conta Destino"
                          options={contasDestinosList}
                          textField="tipoConta"
                          valueField="id"
                          filter="contains"
                          readOnly={readOnly || !possuiPmtRec}
                          required={possuiPmtRec && (idForPmtRec == 1 || idForPmtRec == 3)}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoRecorrente.modalidade"
                          name="despesaPagamentoRecorrente.modalidade"
                          label="Modalidade"
                          options={modalidadeList}
                          textField="value"
                          valueField="key"
                          filter="contains"
                          readOnly={true}
                          required={possuiPmtRec}
                          onChange={(_e, v) => {
                            mudaDependenteOnChange("despesaPagamentoRecorrente.tipoCobranca", v?.key == 1 ? 1 : null);
                          }}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          component={DropdownListInput}
                          inputId="despesaPagamentoRecorrente.tipoCobranca"
                          name="despesaPagamentoRecorrente.tipoCobranca"
                          label="Tipo de Cobrança"
                          options={(idModalidade == 2) ? tipoCobrancaList.filter((e) => e.key == 2) : tipoCobrancaList}
                          textField="value"
                          valueField="key"
                          filter="contains"
                          readOnly={!(isAdmin && possuiPmtRec)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
    
                {/* Faturamento */}
                <div className="col-12">
                  <div className="shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                    <div className="column">
                      <span className="page-head__title h3 mb-3">Faturamento</span>
                      <div className="d-flex flex-column w-100">
                        <div className="col more-fields p-0">
                          <TransitionGroup>
                            {faturamentos &&
                              faturamentos.map((item, index) => (
                                <CSSTransition key={index} timeout={300} classNames="item">
                                  <div key={index} className="more-fields-item">
                                      <div className="row">
                                        <div className="col-12 col-lg">
                                          <Field
                                            component={DropdownListInput}
                                            inputId="idEmpresaPrestadora"
                                            name={`faturamentos[${index}].id`}
                                            label="Razão Social e CNPJ de Faturamento"
                                            options={fornecedoresList}
                                            textField="razaoSocialComCnpj"
                                            valueField="idEmpresaPrestadora"
                                            filter="contains"
                                            readOnly={readOnly}
                                            required
                                          />
                                        </div>
        
                                        {!readOnly && (
                                          <div className="col-12 col-lg-auto mr-3 d-flex align-items-center">
                                            <Button
                                              classes="rounded bg-white shadow-none border-0 text-danger mx-0 mxh-40 p-0 mb-2 mt-3 text-center text-capitalize"
                                              activeClassName={"col-lg-auto p-0"}
                                              buttonStyle={"success"}
                                              action={() => removeFaturamento(index)}
                                              icon={"trash"}
                                              name={"Remover"}
                                            />
                                          </div>
                                        )}
                                      </div>
                                  </div>
                                </CSSTransition>
                              ))}
                          </TransitionGroup>
                        </div>
    
                        {!readOnly && (
                          <div className="mx-auto mt-3">
                            <Button
                              classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                              activeClassName={"col-lg-auto p-0"}
                              buttonStyle={"success"}
                              action={() => addFaturamento(faturamentos.length)}
                              icon={"plus"}
                              name={"Adicionar"}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
    
                {/* Contato */}
                <div className="col-12">
                  <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
                    <div className="column">
                      <span className="page-head__title h3 mb-3">Contato</span>
                      <div className="d-flex flex-column w-100">
                        <div className="col more-fields p-0">
                          <TransitionGroup>
                            {contatos &&
                              contatos.map((item, index) => (
                                <CSSTransition key={index} timeout={300} classNames="item">
                                  <div key={index} className="more-fields-item">
                                      <div className="row">
                                        <div className="col-12 col-lg">
                                          <Field
                                            component={Input}
                                            inputId="taxaJurosPagamentoList"
                                            name={`contatos[${index}].nome`}
                                            label="Nome"
                                            readOnly={readOnly && statusValue !== 4}
                                          />
                                        </div>
                                        <div className="col-12 col-lg">
                                          <Field
                                            label="Telefone"
                                            inputId="telefone"
                                            name={`contatos[${index}].telefone`}
                                            maxLength="15"
                                            readOnly={readOnly && statusValue !== 4}
                                            component={MaskedFormatInput}
                                            formatMask={customFormatPhone}
                                          />
                                        </div>
                                        <div className="col-12 col-lg">
                                          <Field
                                            component={Input}
                                            inputId="email"
                                            type={"email"}
                                            name={`contatos[${index}].email`}
                                            label="E-mail"
                                            readOnly={readOnly && statusValue !== 4}
                                          />
                                        </div>
        
                                        {!(readOnly && statusValue !== 4) && (
                                          <div className="col-12 col-lg-auto mr-3 d-flex align-items-center">
                                            <Button
                                              classes="rounded bg-white shadow-none border-0 text-danger mx-0 mxh-40 p-0 mb-2 mt-3 text-center text-capitalize"
                                              activeClassName={"col-lg-auto p-0"}
                                              buttonStyle={"success"}
                                              action={() => removeContato(index)}
                                              icon={"trash"}
                                              name={"Remover"}
                                            />
                                          </div>
                                        )}
                                      </div>
                                  </div>
                                </CSSTransition>
                              ))}
                          </TransitionGroup>
                        </div>
    
                        {!(readOnly && statusValue !== 4) && (
                          <div className="mx-auto mt-3">
                            <Button
                              classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                              activeClassName={"col-lg-auto p-0"}
                              buttonStyle={"success"}
                              action={() => addContato(contatos.length)}
                              icon={"plus"}
                              name={"Adicionar"}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
    
                {/* Validação */}
                {(statusValue == 2 || statusValue == 5) && (
                  <div className="col-12">
                    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
                      <div className="column">
                        <span className="page-head__title h3 mb-3">Validação</span>
                        <div className="d-flex flex-column w-100">
                          <div className="row">
                            {statusValue == 5 && (
                              <div className="col-lg-4">
                                <Field
                                  component={Input}
                                  inputId="usuarioValidacao"
                                  name="usuarioValidacao"
                                  label="Enviado Por"
                                  readOnly={true}
                                  type="text"
                                />
                              </div>
                            )}
                            <div className={`col-lg-${statusValue == 2 ? '12' : '8'}`}>
                              <Field
                                component={Textarea}
                                className={"filter-input"}
                                inputId="descricaoValidacao"
                                name="descricaoValidacao"
                                type="text"
                                label="Descrição"
                                readOnly={(readOnly && statusValue !== 2) || (!readOnly && statusValue == 5)}
                                minHeight={6}
                                maxLength={1000}
                                resize="vertical"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Documentos */}
                  <div className="col-12">
                    <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                      <span className="h3 page-head__title">Documentos</span>

                      <Field
                        name="documentos"
                        component={DocumentsInput}
                        getRootProps={getRootProps}
                        isDragging={isDragging}
                        getInputProps={getInputProps}
                        documentos={documentos}
                        despesaOperacao={despesaOperacao}
                        downloadPedidoDocument={downloadPedidoDocument}
                        onDeleteDocument={onDeleteDocument}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
              </>
            ) : (
              <>
                {lancamentosFetch && (
                  <>
                    <div className="col-12">
                      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
                        <div className="column">
                          <span className="page-head__title h3 mb-3">Estatísticas</span>
                          <div className="d-flex flex-column w-100">
                            <div className="row">
                              <div className="col-lg">
                                <Field
                                  component={MaskedInput}
                                  inputId="estatisticas.valorMedio"
                                  name="estatisticas.valorMedio"
                                  label="Valor Médio"
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  decimalScale={2}
                                  prefix="R$ "
                                  fixedDecimalScale
                                  readOnly={true}
                                />
                              </div>
                              <div className="col-lg">
                                <Field
                                  component={MaskedInput}
                                  inputId="estatisticas.totalPago"
                                  name="estatisticas.totalPago"
                                  label="Total Pago"
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  decimalScale={2}
                                  prefix="R$ "
                                  fixedDecimalScale
                                  readOnly={true}
                                />
                              </div>
                              <div className="col-lg">
                                <Field
                                  component={Input}
                                  inputId="estatisticas.parcelasPagas"
                                  name="estatisticas.parcelasPagas"
                                  label="Parcelas Pagas"
                                  readOnly={true}
                                />
                              </div>
                              <div className="col-lg">
                                <Field
                                  component={MaskedInput}
                                  inputId="estatisticas.variacaoPercentual"
                                  name="estatisticas.variacaoPercentual"
                                  label="Variação Percentual"
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  decimalScale={2}
                                  suffix="%"
                                  fixedDecimalScale
                                  readOnly={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container-fluid">
                      <div className="px-0 py-3">
                        {lancamentosData?.totalCount > 0 && (
                          <Table
                            withoutHeader
                            headerCustom={<span className="page-head__title h3 my-2 px-3">Lançamentos</span>}
                            classes="shadow-custom rounded-12 col-12 mb-2 p-3 mt-0 bg-white"
                            totalItems={lancamentosData?.totalCount}
                            pagination={
                              <PaginationRB
                                pageNumber={lancamentosData.pageIndex - 1}
                                itemsPerPage={lancamentosData.pageSize}
                                totalItems={lancamentosData?.totalCount}
                                fetchFunction={carregaLancamentos}
                                pageRange={5}
                              />
                            }
                          >
                            <TableHeader>{tableHeader}</TableHeader>
                            <TableContent>{tableContent}</TableContent>
                          </Table>
                        )}

                        {lancamentosData?.totalCount <= 0 && (
                          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
                            <p className="px-3 py-5 mb-0">Sem lançamentos</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

          </div>
          
          {/* ----- */}
          <div className="col-12 px-0">
            <div className="d-flex flex-row justify-content-around align-items-center col-12 mb-4 mt-2 p-0">
              {(statusValue !== 2 && statusValue !== 3) && (
                <Button
                  name={`Cancelar ${floatButtonVisible ? "Alterações" : ""}`}
                  classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => {
                    floatButtonVisible
                      ? showConfirmDialog(true)
                      : showDespesaList();
                  }}
                />
              )}

              {(floatButtonVisible && (statusValue == 2)) && (
                <Button
                  type="submit"
                  name={"Enviar para Revisão"}
                  classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => {
                    mudaDependenteOnChange("enviarParaRevisao", true);
                    mudaDependenteOnChange("enviarParaValidacao", false);
                    mudaDependenteOnChange("enviarParaAprovacao", false);

                    if (invalid) {
                      showMessageAlert("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.")
                    }
                  }}
                />
              )}

              {(floatButtonVisible && (statusValue == 0 || statusValue == 1 || statusValue == 4 || statusValue == 5)) && (
                <Button
                  type="submit"
                  name={"Salvar Cadastro"}
                  classes="warn-bg-imp text-dark px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => {
                    mudaDependenteOnChange("enviarParaValidacao", false);
                    mudaDependenteOnChange("enviarParaRevisao", false);
                    mudaDependenteOnChange("enviarParaAprovacao", false);

                    if (invalid) {
                      showMessageAlert("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.")
                    }
                  }}
                />
              )}

              {(floatButtonVisible && (statusValue == 0 || statusValue == 1 || statusValue == 5)) && (
                <Button
                  type="submit"
                  name={"Enviar para Validação"}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => {
                    mudaDependenteOnChange("enviarParaValidacao", true);
                    mudaDependenteOnChange("enviarParaRevisao", false);
                    mudaDependenteOnChange("enviarParaAprovacao", false);

                    if (invalid) {
                      showMessageAlert("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.")
                    }
                  }}
                />
              )}
              
              {(floatButtonVisible && (statusValue == 2)) && (
                <Button
                  type="submit"
                  name={"Enviar para Aprovação"}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => {
                    mudaDependenteOnChange("enviarParaAprovacao", true);
                    mudaDependenteOnChange("enviarParaValidacao", false);
                    mudaDependenteOnChange("enviarParaRevisao", false);

                    if (invalid) {
                      showMessageAlert("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.")
                    }
                  }}
                />
              )}
            </div>
          </div>
        </FormContent>
        <ConfirmDialog
          title="Aviso"
          contentText="Os dados do formulário serão perdidos, deseja realmente sair?"
          textButtonCancel="CANCELAR"
          textButtonConfirm="CONFIRMAR"
          showDialog={showCancelConfirmDialog}
          onClose={() => showConfirmDialog(false)}
          onConfirm={() => showDespesaList(idsOperacoes)}
          type="warning"
          maxWidth="md"
        />
      </Form>
    );
}

const selector = formValueSelector("DespesaForm");

const mapStateToProps = (state) => ({
  operacao: selector(state, "operacao"),
  operacoes: state.operacaoReducer.selectedOperacoes || [],
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
  // NEWS
  controleDespesa: selector(state, "controleDespesa"),
  despesaReembolsavelFlat: selector(state, "despesaPagamentoFlat.despesaReembolsavel") || null,
  despesaReembolsavelRecorrente: selector(state, "despesaPagamentoRecorrente.despesaReembolsavel") || null,
  possuiCorrecaoRecorrente: selector(state, "despesaPagamentoRecorrente.possuiCorrecao") || null,
  dataPrimeiraParcela: selector(state, "despesaPagamentoRecorrente.dataPrimeiraParcela") || null,
  dataVencimento: selector(state, "despesaPagamentoRecorrente.dataVencimento") || null,
  periodicidade: selector(state, "despesaPagamentoRecorrente.periodicidade")?.key || null,
  // LISTS
  messages: state.despesaReducer.messages || [],
  contatos: selector(state, "contatos") || [],
  faturamentos: selector(state, "faturamentos") || [],
  documentos: selector(state, "documentos") || null,
  serie: selector(state, "serie") || null,
  possuiPagamentoFlat: selector(state, "possuiPagamentoFlat") || null,
  possuiPagamentoRecorrente: selector(state, "possuiPagamentoRecorrente") || null,
  idForPmtFlat: selector(state, "despesaPagamentoFlat.idFormaPagamento")?.key || null,
  idForPmtRec: selector(state, "despesaPagamentoRecorrente.idFormaPagamento")?.key || null,
  valorBrutoRec: selector(state, "despesaPagamentoRecorrente.valorBrutoInicial") || null,
  qntParcelaRec: selector(state, "despesaPagamentoRecorrente.quantidadeParcela") || null,
  idOrigemRecursoRec: selector(state, "despesaPagamentoRecorrente.idOrigemRecurso") || null,
  idModalidade: selector(state, "despesaPagamentoRecorrente.modalidade")?.key || null,
  idOrigemRecursoFlat: selector(state, "despesaPagamentoFlat.idOrigemRecurso") || null,
  formasPagamentosList: state.despesaReducer.despesaFormTypes.formasPagamentos,
  fornecedoresList: state.despesaReducer.despesaFormTypes.fornecedores,
  indexadoresCorrecoesList: state.despesaReducer.despesaFormTypes.indexadoresCorreções,
  mesesList: state.despesaReducer.despesaFormTypes.meses,
  origemRecursosList: state.despesaReducer.despesaFormTypes.origemRecursos,
  periodicidadesList: state.despesaReducer.despesaFormTypes.periodicidades,
  tiposDespesasList: state.despesaReducer.despesaFormTypes.tiposDespesas,
  contasDestinosList: state.despesaReducer.despesaFormTypes.contasDestinos || [],
  controleDespesasList: state.despesaReducer.despesaFormTypes.controleDespesas,
  operacoesList: state.despesaReducer.despesaFormTypes.operacoes,
  serieContasOrigensList: state.despesaReducer.despesaFormTypes?.serieContasOrigens || [],
  patrimonioSeparadoContasOrigensList: state.despesaReducer.despesaFormTypes?.patrimonioSeparadoContasOrigens || [],
  documentosSuporteList: state.despesaReducer.despesaFormTypes.documentosSuporte,
  modalidadeList: state.despesaReducer.despesaFormTypes.modalidadeList,
  tipoCobrancaList: state.despesaReducer.despesaFormTypes.tipoCobrancaList,
  // 
  despesaOperacao: state.despesaReducer.despesaFormTypes.despesaOperacao || {},
  lancamentosData: state.despesaReducer.despesaFormTypes?.lancamentos?.lancamentos || {},
  lancamentosFetch: state.despesaReducer.despesaFormTypes?.lancamentos || null,
  statusAprovacao: state.despesaReducer.despesaFormTypes?.despesaOperacao?.statusAprovacao,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showDespesaList,
      showConfirmDialog,
      arrayInsert,
      arrayRemove,
      change,
      showMessageAlert,
      mudaDependenteOnChange,
      limpaDependentesOnChange,
      loadContasDestino,
      calculaParcelas,
      loadLancamentos,
      onDropDocument,
      onDeleteDocument,
      downloadPedidoDocument,
    },
    dispatch
  );

const ConnectedDespesaForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DespesaForm))
);
export default reduxForm({ form: "DespesaForm", validate })(
  ConnectedDespesaForm
);
