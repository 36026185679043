/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import {
  getResumoVotosAssembleia,
} from "../../../../../actions/assembleia/AssembleiaAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import ModalDescricaoResumo from "../modals/ModalDescricaoResumo";
import { formatDecimal, newFormatPercent } from "../../../../../utils/helpers/NumberHelper";

const ResumoVotosAssembleiaList = (props) => {
  const {
    readOnly,
    statusValue,
    idAssembleia,
    getResumoVotosAssembleia,
    resumoVotosAssembleiaData,
  } = props;

  const [modalDescricaoResumo, setModalDescricaoResumo] = React.useState(false);

  const arrHeader = [
    "ASSUNTO",
    "TIPO QUÓRUM",
    "APROVAÇÃO",
    "QTDE APROVAÇÃO",
    "QTDE REPROVAÇÃO",
    "QTDE ABSTENÇÃO",
    "% APROVAÇÃO SEM ABSTENÇÃO",
    "STATUS",
    "DESCRIÇÃO",
  ];

  const tableHeader = (
    <tr>
      {arrHeader.filter((item) => item).map((item, i) => (
        <th key={i}>
          <div className="text-center fw-700 d-flex justify-content-center w-100">
            {item}
          </div>
        </th>
      ))}
    </tr>
  );

  const tableContent =
    resumoVotosAssembleiaData &&
    resumoVotosAssembleiaData.hasItems &&
    resumoVotosAssembleiaData.items.map((item, index) => {
      return (
        <tr key={`list-${index}`}>
          <td className="text-center fw-400">{item?.assunto || "--"}</td>
          <td className="text-center fw-400">{item?.tipoQuorum?.value || "--"}</td>
          <td className="text-center fw-400">{item?.quorumAprovacao ? newFormatPercent(item?.quorumAprovacao, 4) : "--"}</td>
          <td className="text-center fw-400">{item?.quantidadeAprovacao ? formatDecimal(item?.quantidadeAprovacao, 0) : "--"}</td>
          <td className="text-center fw-400">{item?.quantidadeReprovacao ? formatDecimal(item?.quantidadeReprovacao, 0) : "--"}</td>
          <td className="text-center fw-400">{item?.quantidadeAbstencao ? formatDecimal(item?.quantidadeAbstencao, 0) : "--"}</td>
          <td className="text-center fw-400">{item?.aprovacaoSemAbstencao ? newFormatPercent(item?.aprovacaoSemAbstencao, 4) : "--"}</td>
          <td className="text-center fw-400 p-color-imp">{item?.status ? 'Aprovada' : 'Reprovada'}</td>

          <td className="text-center fw-400">
            {item?.descricao ? (
              <div className="d-flex flex-row align-items-center justify-content-center">
                <FontAwesomeIcon
                  color="#630a37"
                  size="lg"
                  className="mx-1 mb-1"
                  icon={faAlignJustify}
                  role="button"
                  onClick={() => setModalDescricaoResumo(item)}
                />
              </div>
            ) : '--'}
          </td>
        </tr>
      )
    });

  return (
    <div className="container-fluid px-0">
      <div>
        <p className="h3 page-head__title my-3 mb-0">Resumo dos Votos</p>
        
        <div>
          {resumoVotosAssembleiaData?.totalCount > 0 ? (
            <Table
              withoutHeader          
              classes="shadow-none col-12 my-1 pd-10 bg-white"
              totalItems={resumoVotosAssembleiaData.totalCount}
              pagination={
                <PaginationRB
                  pageNumber={resumoVotosAssembleiaData.pageIndex - 1}
                  itemsPerPage={resumoVotosAssembleiaData.pageSize}
                  totalItems={resumoVotosAssembleiaData?.totalCount}
                  filter={idAssembleia}
                  fetchFunction={getResumoVotosAssembleia}
                  pageRange={5}
                />
              }
            >
              <TableHeader>{tableHeader}</TableHeader>
              <TableContent>{tableContent}</TableContent>
            </Table>
          ) : null }
        </div>

        {/* MODAL DESCRICAO RESUMO */}
        {modalDescricaoResumo && (
          <ModalDescricaoResumo
            open={Boolean(modalDescricaoResumo)}
            resumo={modalDescricaoResumo}
            close={() => setModalDescricaoResumo(false)}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  resumoVotosAssembleiaData: state.assembleiaReducer.resumoVotosAssembleia,
  messages: state.assembleiaReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getResumoVotosAssembleia,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ResumoVotosAssembleiaList);
