import React, { Component } from "react";
import { NumberFormatBase } from "react-number-format";
import ReactTooltip from "react-tooltip";
import If from "../If/If";

export default class MaskedFormatInput extends Component {
  // activateFocus = e => {
  //   e.target.parentNode.classList.add("has-float-label");
  // };

  // activateBlur = e => {
  //   const element = e.target;
  //   element.parentNode.classList.remove("has-float-label");
  // };

  classNameInput = (className) => {
    let name = 'form-control';
    
    if (className) {
      name += ' ' + className;
    }

    return name;
  }

  render() {
    const props = this.props;
    // const { activateFocus } = this;
    return (
      <div className={`form-group field-floating has-float-label ${props.outerClass} ${props.readOnly && 'disabled-field'}`}>
        <NumberFormatBase
          {...props.input}
          className={this.classNameInput(props.className)}
          id={"txtfield_" + props.inputId}
          type={props.type}
          prefix={props.prefix}
          value={props.input.value}
          displayType={props.displayType}
          format={props.formatMask}
          removeFormatting={props.removeFormatting}
          customInput={props.customInput}
          isAllowed={props.isAllowed}
          renderText={props.renderText}
          getInputRef={props.getInputRef}
          placeholder={props.placeholder}
          disabled={props.readOnly}
          maxLength={props.maxLength}
          // onFocus={activateFocus}
        />
        <If test={props.label}>
          <label
            htmlFor={props.name}
            className="control-label"
            id={"lbl_" + props.inputId}
          >
            {props.label}
            {props.required ? <span className="text-warning font-weight-bold ml-1">*</span> : ""}
            {props.icon && props.tooltipMessage && (
              <>
                <i
                  className={`fa fa-${props.icon} icon-margin-l-5`}
                  aria-hidden="true"
                  style={{color: props.iconColor, cursor: "default"}}
                  data-tip={props.tooltipMessage}
                  data-for={props.tooltipId || props.label}
                />
                <ReactTooltip
                  noArrow
                  effect="solid"
                  className="px-2 py-1"
                  globalEventOff="click"
                  style={{ width: "100px" }}
                  textColor="#333"
                  backgroundColor="#fff"
                  id={props.tooltipId || props.label}
                />
              </>
            )}
          </label>
        </If>
        {(props.meta && props.meta.error && props.meta.touched &&
          <p className="error-msg sp-error text-danger f-12 mb-0">{props.meta.error}</p>)
        }
      </div>
    );
  }
}
