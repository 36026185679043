import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContainerPage from "../../../../common/container/ContainerPage";

import { loadFilterFormRelatorio } from '../../../../../actions/relatorios/analiticReportAction';
import AnaliticReportListFilter from "./form/analiticReportListFilter";

class RelatorioAnaliticoPage extends Component {
    componentDidMount() {
        this.props.loadFilterFormRelatorio();
    }

    render(){
        return (
            <ContainerPage>
                 <AnaliticReportListFilter />
            </ContainerPage>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
    loadFilterFormRelatorio
}, dispatch);

export default connect(null, mapDispatchToProps)(RelatorioAnaliticoPage);