/* eslint eqeqeq: 0 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  FLUXO_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  PEDIDOS_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

import PedidosPage from "../pages/PedidosPage";
import PedidosIncludePage from "../pages/PedidosIncludePage";
import PedidosUpdatePage from "../pages/PedidosUpdatePage";

export default () => (
  <Switch>
    <Route
      exact={true}
      path={`/${PEDIDOS_ROUTE_PATH}/${LISTA_ROUTE_PATH}`}
      component={PedidosPage}
    />
    <Route
      exact={true}
      path={`/${PEDIDOS_ROUTE_PATH}/${NOVO_ROUTE_PATH}`}
      component={PedidosIncludePage}
    />
    <Route
      exact={true}
      path={`/${PEDIDOS_ROUTE_PATH}/${FLUXO_ROUTE_PATH}/:idPedidos`}
      component={PedidosUpdatePage}
    />
  </Switch>
);