import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TablePagination from '@material-ui/core/TablePagination';

import { getDateFormatedFromString } from './../../../../../../../utils/helpers/DateTimeHelper';
import Table from '../../../../../../common/table/Table';
import TableHeader from '../../../../../../common/table/TableHeader';
import TableContent from '../../../../../../common/table/TableContent';
import { getLabelDisplayedRows } from '../../../../../../../utils/helpers/OthersHelpers';

const styles = theme => ({
    root: {
        display: 'block',
        width: '100%',
        overflowX: 'auto',
    },
    tablePagination: {
        fontSize: '12pt',
    }
});
class RatingPassivoList extends Component {
    state = {
        page: 0,
        rowsPerPage: 5,
    };
    handleChangePage = (event, page) => {
        this.setState({ page: page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render(){
        const {
            page,
            rowsPerPage,
        } = this.state;

        const { 
            classes,
            dataList,
        } = this.props;    
    
        return(   
            <div className={`table-responsive ${classes.root}`}>
                <Table
                    withoutHeader
                    classes="shadow-none border-0 col-12 bg-white"
                >
                    <TableHeader>
                        <tr>                                
                            <th className='text-center fw-700'>Tipo</th>
                            <th className='text-center fw-700'>Agência Emissora</th>
                            <th className='text-center fw-700'>Rating</th>
                            <th className='text-center fw-700'>Perspactiva Rating</th>
                            <th className='text-center fw-700'>Data Atribuição</th>                            
                        </tr>
                    </TableHeader>

                    <TableContent>
                    {
                        (dataList && dataList.length > 0) 
                        ? (
                            dataList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='text-center fw-400'>{item.tituloComplemento}</td>
                                        <td className='text-center fw-400'>{item.agencia}</td>
                                        <td className='text-center fw-400'>{item.rating}</td>
                                        <td className='text-center fw-400'>{(item.perspectivaRating) ? item.perspectivaRating.value : ""}</td>
                                        <td className='text-center fw-400'>{getDateFormatedFromString(item.dataAtribuicao)}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td
                                    align="center" 
                                    colSpan={12}
                                >
                                    Registros não encontrados
                                </td>
                            </tr>
                        )
                    }
                    </TableContent>
                </Table>

                <TablePagination
                    className={classes.tablePagination}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    labelDisplayedRows={getLabelDisplayedRows}
                    count={dataList != null ? dataList.length : 0}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Linhas por página"
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Página anterior',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Próxima página',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </div>
            );    
    }
    
};
RatingPassivoList = connect(null, null)(RatingPassivoList);
export default withStyles(styles)(RatingPassivoList);