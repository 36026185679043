/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { reduxForm, Field, getFormValues, formValueSelector } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import Input from "../../../../common/input/Input";
import MaskedInput from "../../../../common/input/MaskedInput";
import TextArea from "../../../../common/input/Textarea";
import SimpleTabs from "../../../../common/tab/SimpleTabs";
import {
  backToList,
  loadOperacoes,
  mudaDependenteOnChange,
} from "../../../../../actions/assembleia/AssembleiaAction";
import { getSugestaoPatrimonio } from "../../../../../actions/patrimonio/PatrimonioAction";
import GenericPickerInput from "../../../../common/input/GenericPickerInput";
import DropdownListInput from "../../../../common/input/DropdownListInput";
import { getArrayNumberRange } from "../../../../../utils/helpers/ArrayHelper";
import PercentageInput from "../../../../common/input/PercentageInput";
import DropzoneAssembleia from "../components/DropzoneAssembleia";
import { validate } from '../validation/AssembleiaFormValidation'
import { msgError } from "../../../../../utils/helpers/OthersHelpers";
import Button from "../../../../common/button/Button";
import StepperAssembleia from "../components/StepperAssembleia";
import FixedFloaterErrors from "../../../../common/floats/FixedFloaterErrors";
import OrdensAssembleiaList from "../sections/OrdensAssembleiaList";
import InvestidoresAssembleiaList from "../sections/InvestidoresAssembleiaList";
import CustodiantesAssembleiaList from "../sections/CustodiantesAssembleiaList";
import ResumoVotosAssembleiaList from "../sections/ResumoVotosAssembleiaList";
// MODALS
import ModalAction from "../../../../common/modal/ModalAction";
import ModalCriacaoAssembleia from "../modals/ModalCriacaoAssembleia";
import ModalConvocacaoAssembleia from "../modals/ModalConvocacaoAssembleia";
import ModalConfirmacaoAssembleia from "../modals/ModalConfirmacaoAssembleia";
import ModalSuspenderAssembleia from "../modals/ModalSuspenderAssembleia";
import ModalNovaAssembleia from "../modals/ModalNovaAssembleia";
import ModalEncerrarAssembleia from "../modals/ModalEncerrarAssembleia";
import MultSelectListInput from "../../../../common/input/MultSelectListInput";

let AssembleiaForm = (props) => {
  const {
    invalid,
    messages,
    title,
    readOnly,
    formValues,
    criarAssembleia,
    assembleia,
    ordensAssembleia,
    investidoresAssembleia,
    custodiantesAssembleia,
    resumoVotosAssembleia,
    // ACTIONS
    handleSubmit,
    getSugestaoPatrimonio,
    mudaDependenteOnChange,
    operacoes,
    backToList,
    loadOperacoes,
    // LISTS
    patrimoniosList,
    operacoesList,
    tipoAssembleiaList,
    origemDemandaList,
    formatoPublicadoList,
  } = props;

  const statusValue = assembleia?.status?.key;

  const tabsAssembleia = [
    { label: 'RESUMO VOTOS', show: statusValue >= 3 },
    { label: 'POSIÇÃO INVESTIDORES', show: true },
    { label: 'POSIÇÃO CUSTÓDIA', show: true }
  ];

  const [tabSelected, setTabSelected] = React.useState(0);

  const [confirmReturn, setConfirmReturn] = React.useState(false)
  const [confirmCreate, setConfirmCreate] = React.useState(false)
  const [confirmSummon, setConfirmSummon] = React.useState(false)
  const [confirmRealize, setConfirmRealize] = React.useState(false)
  const [confirmSuspend, setConfirmSuspend] = React.useState(false)
  const [confirmNewSummon, setConfirmNewSummon] = React.useState(false)
  const [confirmClose, setConfirmClose] = React.useState(false)

  const readOnlyGeral = readOnly || statusValue > 1
  const readOnlyDocsFirst = readOnly || statusValue > 1
  const readOnlyDocsSecond = readOnly || statusValue > 3
  const readOnlyOrdens = readOnly || statusValue > 1
  const readOnlyLists = readOnly || statusValue > 3

  React.useEffect(() => {
    setTabSelected(statusValue >= 3 ? 0 : 1)
  }, [statusValue])

  return (
    <Form className="w-100">
      <FormHeader title={title} offSet={20}></FormHeader>

      <StepperAssembleia assembleia={assembleia} />

      <FormContent handleSubmit={handleSubmit} classes="px-0">
        {/* ERRORS */}
        {messages?.hasError && (<FixedFloaterErrors messages={messages} title="Status do Cadastro" />)}

        <div className="col-12 px-0">
          <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
            {/* GERAL */}
            <span className="h3 page-head__title">Geral</span>
            <div className="row">
              <div className="col-lg col-12 form-group field-floating">
                <label className="control-label" style={{ top: -15 }}>
                  Patrimônio Separado
                </label>
                <Field
                  isClearable
                  placeholder=""
                  readOnly={readOnlyGeral}
                  name="patrimonioSeparado"
                  options={patrimoniosList}
                  inputId="patrimonioSeparado"
                  component={GenericPickerInput}
                  loadData={getSugestaoPatrimonio}
                  noOptionsMessage="Nenhum registro encontrado"
                  onChange={async (e, v) => {
                    mudaDependenteOnChange("operacoes", null)
                    
                    if (v?.key) {
                      await loadOperacoes(v?.key);
                    }
                  }}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  activeClassName={readOnlyGeral ? "input-async disabled" : ""}
                />
              </div>

              {formValues?.patrimonioSeparado && (
                <div className={`col-12 col-xl-${operacoes?.length >= 4 ? '8' : '6' }`}>
                  <Field
                    component={MultSelectListInput}
                    readOnly={readOnlyGeral}
                    inputId="operacoes"
                    name="operacoes"
                    label="Operações"
                    options={operacoesList}
                    dataKey="id"
                    textField="descricao"
                    valueField="id"
                    filter="contains"
                    outerClassName={readOnlyGeral && "disabled-field"}
                    className={readOnlyGeral && 'clear-inside'}
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-lg col-12">
                <Field
                  type="date"
                  required={true}
                  component={Input}
                  readOnly={readOnlyGeral}
                  name="dataAssembleia"
                  inputId="dataAssembleia"
                  label="Data da Assembleia"
                />
              </div>
              <div className="col-lg col-12">
                <Field
                  type="time"
                  required={true}
                  component={Input}
                  readOnly={readOnlyGeral}
                  name="horarioAssembleia"
                  inputId="horarioAssembleia"
                  label="Horário da Assembleia"
                />
              </div>
              <div className="col-lg col-12">
                <Field
                  readOnly={readOnlyGeral}
                  textField="textField"
                  name="ordemConvocacao"
                  valueField="valueField"
                  label="Ordem da Convocação"
                  component={DropdownListInput}
                  options={getArrayNumberRange(1, 15)}
                />
              </div>
              <div className="col-lg col-12">
                <Field
                  valueField="key"
                  textField="value"
                  readOnly={readOnlyGeral}
                  name="tipoAssembleia"
                  label="Tipo de Assembleia"
                  options={tipoAssembleiaList}
                  component={DropdownListInput}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg col-12">
                <Field
                  required={true}
                  decimalScale={4}
                  readOnly={readOnlyGeral}
                  limitToHundred={true}
                  name="quorumInstalacao"
                  inputId="quorumInstalacao"
                  decimalSeparator={","}
                  component={PercentageInput}
                  label="Quórum Mínimo de Instalação"
                />
              </div>
              <div className="col-lg col-12">
                <Field
                  type="date"
                  required={true}
                  component={Input}
                  readOnly={readOnlyGeral}
                  name="dataConvocacao"
                  inputId="dataConvocacao"
                  label="Data da Convocação"
                />
              </div>
              <div className="col-lg col-12">
                <Field
                  valueField="key"
                  textField="value"
                  readOnly={readOnlyGeral}
                  name="origemDemanda"
                  label="Origem da Demanda"
                  options={origemDemandaList}
                  component={DropdownListInput}
                />
              </div>
              <div className="col-lg col-12">
                <Field
                  valueField="key"
                  textField="value"
                  readOnly={readOnlyGeral}
                  name="formatoPublicacao"
                  label="Formato de Publicação"
                  component={DropdownListInput}
                  options={formatoPublicadoList}
                />
              </div>
            </div>

            {statusValue > 1 && (
              <div className="row">
                <div className="col-lg col-12">
                  <Field
                    required={true}
                    readOnly={true}
                    component={Input}
                    label="Agente Fiduciário"
                    name="agentesFiduciarios"
                    inputId="agentesFiduciarios"
                  />
                </div>
                {statusValue > 3 && (
                  <div className="col-lg-3 col-12">
                    <Field
                      required
                      prefix="R$ "
                      decimalScale={2}
                      fixedDecimalScale
                      decimalSeparator={","}
                      component={MaskedInput}
                      name="valorFaturamento"
                      thousandSeparator={"."}
                      label="Valor Faturamento"
                      inputId="valorFaturamento"
                      readOnly={readOnly || statusValue == 5}
                      className={readOnly || statusValue == 5 ? 'blocked' : ''}
                    />
                  </div>
                )}
              </div>
            )}
            
            <div className="row">
              <div className="col-lg col-12">
                <Field
                  type="url"
                  maxLength={5000}
                  component={Input}
                  readOnly={readOnlyGeral}
                  label="Link Fundos.Net - Edital de Convocação"
                  required={criarAssembleia || statusValue >= 2}
                  name="assembleiaComplemento.linkEdital"
                  inputId="assembleiaComplemento.linkEdital"
                />
              </div>
              <div className="col-lg col-12">
                <Field
                  type="url"
                  maxLength={5000}
                  component={Input}
                  readOnly={readOnlyGeral}
                  label="Link Fundos.Net - Proposta para Assembleia"
                  required={criarAssembleia || statusValue >= 2}
                  name="assembleiaComplemento.linkProposta"
                  inputId="assembleiaComplemento.linkProposta"
                />
              </div>

              {statusValue >= 3 && (
                <div className="col-lg col-12">
                  <Field
                    type="url"
                    maxLength={5000}
                    component={Input}
                    required={statusValue >= 3}
                    readOnly={readOnly || statusValue > 3}
                    label="Link Fundos.Net - Ata da Assembleia"
                    name="assembleiaComplemento.linkAta"
                    inputId="assembleiaComplemento.linkAta"
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-lg col-12">
                <Field
                  required
                  minHeight={7}
                  maxLength={5000}
                  label="Descrição"
                  readOnly={readOnlyGeral}
                  component={TextArea}
                  name="assembleiaComplemento.descricao"
                  inputId="assembleiaComplemento.descricao"
                />
              </div>
            </div>

            {assembleia && statusValue > 1 && (
              <div className="shadow-custom bg-white rounded-12 col-12 mb-4 px-4 pt-2 pb-4">
                <SimpleTabs
                  className="my-2"
                  key={tabSelected}
                  tabs={tabsAssembleia}
                  tabSelected={tabSelected}
                  onTabChange={setTabSelected}
                />

                {/* RESUMO VOTOS */}
                {tabSelected === 0 && (
                  <ResumoVotosAssembleiaList
                    readOnly={readOnlyLists}
                    statusValue={statusValue}
                    idAssembleia={assembleia?.id}
                  />
                )}
                {/* INVESTIDORES */}
                {tabSelected === 1 && (
                  <InvestidoresAssembleiaList
                    readOnly={readOnlyLists}
                    statusValue={statusValue}
                    idAssembleia={assembleia?.id}
                  />
                )}
                {/* CUSTÓDIA */}
                {tabSelected === 2 && (
                  <CustodiantesAssembleiaList
                    readOnly={readOnlyLists}
                    idAssembleia={assembleia?.id}
                  />
                )}
              </div>
            )}

            {/* DOCUMENTOS */}
            <span className="h3 page-head__title">Documentos</span>

            {statusValue >= 3 && (
              <div className="row pt-3">
                <div className="col-lg-6 col-12 mb-xl-0 mb-3">
                  <span className="p-color f-18 fw-400">
                    Ata da Assembleia
                  </span>
                  <Field
                    required
                    typeKey={3}
                    maxFiles={1}
                    multiple={false}
                    readOnly={readOnlyDocsSecond}
                    name="documentosAta"
                    component={DropzoneAssembleia}
                    accept={{
                      "application/pdf": [".pdf"]
                    }}
                  />
                </div>

                <div className="col-lg-6 col-12">
                  <span className="p-color f-18 fw-400">
                    Outros Documentos
                  </span>
                  <Field
                    multiple
                    typeKey={4}
                    readOnly={readOnlyDocsSecond}
                    name="documentosOutros"
                    component={DropzoneAssembleia}
                  />
                </div>
              </div>
            )}

            <div className="row pt-3">
              <div className="col-lg-6 col-12 mb-xl-0 mb-3">
                <span className="p-color f-18 fw-400">
                  Edital de Convocação
                </span>
                <Field
                  required
                  typeKey={1}
                  maxFiles={1}
                  multiple={false}
                  readOnly={readOnlyDocsFirst}
                  name="documentosEdital"
                  component={DropzoneAssembleia}
                  accept={{
                    "application/pdf": [".pdf"]
                  }}
                />
              </div>

              <div className="col-lg-6 col-12">
                <span className="p-color f-18 fw-400">
                  Proposta para Assembleia
                </span>
                <Field
                  multiple
                  required
                  typeKey={2}
                  maxFiles={2}
                  readOnly={readOnlyDocsFirst}
                  name="documentosProposta"
                  component={DropzoneAssembleia}
                  accept={{
                    "application/pdf": [".pdf"],
                    "application/msword": [".doc"],
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                      [".docx"],
                  }}
                />
              </div>
            </div>

            {/* ORDENS DA ASSEMBLEIA */}
            {assembleia && assembleia?.id && (
              <div className="mt-3">
                <span className="h3 page-head__title">Ordens da Assembleia</span>
                <div className="row pt-1">
                  <OrdensAssembleiaList
                    readOnly={readOnlyOrdens}
                    idAssembleia={assembleia.id}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* --- ACTIONS --- */}
        <div className="col-12 px-0">
          <div className="d-flex flex-row justify-content-between align-items-center col-12 mb-4 mt-2 p-0">
            <Button
              type="button"
              name={`${readOnly ? 'Voltar' : 'Cancelar Alterações'}`}
              classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={() => !readOnly ? setConfirmReturn(true) : backToList()}
            />

            {!readOnly && (
              <>
                <Button
                type="submit"
                name={"Salvar Alterações"}
                classes="warn-bg-imp text-dark px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                action={() => {
                  mudaDependenteOnChange("submitted", true)
                  mudaDependenteOnChange("criarAssembleia", false)
                  if (invalid) msgError("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.")
                }}
              />

              {(assembleia && statusValue == 1 && ordensAssembleia?.hasItems) && (
                <Button
                  type="button"
                  name={"Criar Assembleia"}
                  action={() => setConfirmCreate(true)}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                />
              )}

              {assembleia &&
                statusValue == 2 &&
                ordensAssembleia?.hasItems &&
                investidoresAssembleia?.hasItems &&
                custodiantesAssembleia.hasItems && (
                  <Button
                    type="button"
                    name={"Convocar Assembleia"}
                    action={() => setConfirmSummon(true)}
                    classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  />
                )}

              {assembleia &&
                statusValue == 3 &&
                ordensAssembleia?.hasItems &&
                investidoresAssembleia?.hasItems &&
                custodiantesAssembleia.hasItems && 
                resumoVotosAssembleia.hasItems && (
                  <Button
                    type="button"
                    name={"Confirmar Assembleia"}
                    action={() => setConfirmRealize(true)}
                    classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  />
                )}

              {assembleia &&
                statusValue == 4 && (
                  <>
                    <Button
                      type="button"
                      name={"Suspender Assembleia"}
                      action={() => setConfirmSuspend(true)}
                      classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                    />

                    <Button
                      type="button"
                      name={"Nova Convocação"}
                      action={() => setConfirmNewSummon(true)}
                      classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                    />

                    <Button
                      type="button"
                      name={"Encerrar Assembleia"}
                      action={() => setConfirmClose(true)}
                      classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </FormContent>

      {/* MODAL RETURN */}
      <ModalAction
        action={backToList}
        open={confirmReturn}
        title="Cancelar Alterações"
        close={() => setConfirmReturn(false)}
        message="Todo o progresso será perdido"
      />

      {/* MODAL CRIAR ASSEMBLEIA */}
      <ModalCriacaoAssembleia
        open={confirmCreate}
        confirm={async () => {
          await Promise.all([
            mudaDependenteOnChange("submitted", true),
            mudaDependenteOnChange("criarAssembleia", true)
          ]);

          setConfirmCreate(false);
          if (invalid) msgError("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.");
          handleSubmit()
        }}
        close={() => setConfirmCreate(false)}
      />

      {/* MODAL CONVOCAR ASSEMBLEIA */}
      <ModalConvocacaoAssembleia
        open={confirmSummon}
        confirm={async () => {
          await Promise.all([
            mudaDependenteOnChange("submitted", true),
            mudaDependenteOnChange("convocarAssembleia", true)
          ]);

          setConfirmSummon(false);
          if (invalid) msgError("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.");
          handleSubmit()
        }}
        close={() => setConfirmSummon(false)}
      />

      {/* MODAL CONFIRMAR ASSEMBLEIA */}
      <ModalConfirmacaoAssembleia
        open={confirmRealize}
        confirm={async () => {
          await Promise.all([
            mudaDependenteOnChange("submitted", true),
            mudaDependenteOnChange("confirmarAssembleia", true)
          ]);

          setConfirmRealize(false);
          if (invalid) msgError("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.");
          handleSubmit()
        }}
        close={() => setConfirmRealize(false)}
      />

      {/* MODAL SUSPENDER ASSEMBLEIA */}
      <ModalSuspenderAssembleia
        open={confirmSuspend}
        idAssembleia={assembleia?.id}
        close={() => setConfirmSuspend(false)}
      />

      {/* MODAL NOVA ASSEMBLEIA */}
      <ModalNovaAssembleia
        open={confirmNewSummon}
        confirm={async () => {
          await Promise.all([
            mudaDependenteOnChange("submitted", true),
            mudaDependenteOnChange("novaConvocacao", true)
          ]);

          setConfirmNewSummon(false);
          if (invalid) msgError("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.");
          handleSubmit()
        }}
        close={() => setConfirmNewSummon(false)}
      />

      {/* MODAL ENCERRAR ASSEMBLEIA */}
      <ModalEncerrarAssembleia
        open={confirmClose}
        confirm={async () => {
          await Promise.all([
            mudaDependenteOnChange("submitted", true),
            mudaDependenteOnChange("encerrarAssembleia", true)
          ]);

          setConfirmClose(false);
          if (invalid) msgError("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.");
          handleSubmit()
        }}
        close={() => setConfirmClose(false)}
      />
    </Form>
  );
};

const selector = formValueSelector("AssembleiaForm");

const mapStateToProps = (state) => ({
  operacoes: selector(state, "operacoes") || [],
  formValues: getFormValues("AssembleiaForm")(state),
  criarAssembleia: selector(state, "criarAssembleia") || false,
  patrimoniosList: state.patrimonioReducer.patrimoniosOptions,
  assembleia: state.assembleiaReducer.assembleiaForm?.assembleia || null,
  ordensAssembleia: state.assembleiaReducer.ordensAssembleia,
  investidoresAssembleia: state.assembleiaReducer.investidoresAssembleia,
  custodiantesAssembleia: state.assembleiaReducer.custodiantesAssembleia,
  resumoVotosAssembleia: state.assembleiaReducer.resumoVotosAssembleia,
  messages: state.assembleiaReducer.messages || [],
  tipoAssembleiaList: state.assembleiaReducer.assembleiaForm.tipoAssembleiaList || [],
  origemDemandaList: state.assembleiaReducer.assembleiaForm.origemDemandaList || [],
  formatoPublicadoList: state.assembleiaReducer.assembleiaForm.formatoPublicadoList || [],
  operacoesList: state.assembleiaReducer?.operacoesList || [],
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSugestaoPatrimonio,
      mudaDependenteOnChange,
      backToList,
      loadOperacoes,
    },
    dispatch
  );

const ConnectedAssembleiaForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(AssembleiaForm));

export default reduxForm({ form: "AssembleiaForm", validate })(ConnectedAssembleiaForm);

