import axios from "axios";
import {
    arrayPush,
    arrayRemove,
    change,
    formValueSelector,
} from 'redux-form';

import { selectTab } from '../operacao/tabs/TabsAction';
import { showAllMessages } from '../message/ErrorMessagesAPIAction';
import { PATRIMONIO_TAB } from '../../utils/tabs';
import { showConfirmDialog } from '../common/modal/ConfirmDialogAction';
import { downloadExcelFile, downloadFile } from '../../utils/helpers/FileHelper';

import history from '../../components/common/routers/history';
import {
    OPERACAO_ROUTE_PATH,
    PATRIMONIO_ROUTE_PATH,
    VINCULAR_ROUTE_PATH,
    LISTA_ROUTE_PATH,
    DETALHAR_ROUTE_PATH,
    EDITAR_ROUTE_PATH,
    NOVO_ROUTE_PATH,
    PATRIMONIO_OPERACAO_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";

import {
    serializeFieldsPatrimonioForm,
    deserializeFieldsPatrimonioForm,
    deserializePatrimoniosVinculados
} from "../../components/business/patrimonio/mappings/PatrimonioMap";
import { msgError } from "../../utils/helpers/OthersHelpers";

export const SERVICER = 7;

export const PATRIMONIO_FORM_TAB = 'PATRIMONIO_FORM_TAB';
export const SELECTED_PATRIMONIO_OPTION = 'SELECTED_PATRIMONIO_OPTION';
export const PATRIMONIO_OPTIONS_FETCHED = 'PATRIMONIO_OPTIONS_FETCHED';
export const PATRIMONIO_OPERACAO_FETCHED = 'PATRIMONIO_FETCHED';
export const PATRIMONIO_FETCHED = 'PATRIMONIO_FETCHED';
export const PATRIMONIO_SHOW_LIST = 'PATRIMONIO_SHOW_LIST';
export const PATRIMONIO_SHOW_FORM_VINCULO_OPERACAO = 'PATRIMONIO_SHOW_FORM_VINCULO_OPERACAO';
export const PATRIMONIO_NAO_VINCULADO_OPTIONS_FETCHED = 'PATRIMONIO_NAO_VINCULADO_OPTIONS_FETCHED';
export const SELECTED_PATRIMONIO_NAO_VINCULADO_OPTIONS = 'SELECTED_PATRIMONIO_NAO_VINCULADO_OPTIONS';
export const PATRIMONIO_VINCULADO_OPERACAO_FETCHED = 'PATRIMONIO_VINCULADO_OPERACAO_FETCHED';
export const PATRIMONIO_SHOW_DELETE_DIALOG = 'PATRIMONIO_SHOW_DELETE_DIALOG';
export const LOAD_FORM_PATRIMONIO = "LOAD_FORM_PATRIMONIO";
export const LOAD_FORM_PATRIMONIO_VINCULOS = "LOAD_FORM_PATRIMONIO_VINCULOS";
export const LOAD_FORM_PATRIMONIO_CONTAS = "LOAD_FORM_PATRIMONIO_CONTAS";
export const LOAD_FORM_PATRIMONIO_MOEDA = "LOAD_FORM_PATRIMONIO_MOEDA";
export const PATRIMONIO_CLEAN_LIST = "PATRIMONIO_CLEAN_LIST";
export const SAVE_FORM_PATRIMONIO_ERRORS = "SAVE_FORM_PATRIMONIO_ERRORS";
export const LIST_PS_OPERACOES = "LIST_PS_OPERACOES";

const PATRIMONIO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PATRIMONIO_API;

const OPERACAO_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API;

export function showMessageAlert(msg) {
    return () => {
        msgError(msg);
        return false;
    }
}

export async function limpaDependenteOnChange(dependente, value = null) {
    return async (dispatch) => {
      await dispatch([change("patrimonioForm", dependente, value)]);
    };
}

export function getSugestaoPatrimonio(term) {
    return (dispatch) => {
        return axios
            .get(`${PATRIMONIO_API}sugestoes?termo=${term}`)
            .then(result =>
                dispatch({
                    type: PATRIMONIO_OPTIONS_FETCHED,
                    payload: result.content
                })
            );
    };
}

export function getPatrimonios(idsPatrimonio, page = 1) {
    let params = idsPatrimonio.length > 0 ? idsPatrimonio : 0;

    return (dispatch) => {
        return axios
            .get(`${PATRIMONIO_API}?ids=${params}&pagina=${page}&tamanhoPagina=10`)
            .then(result =>
                dispatch(
                    {
                        type: PATRIMONIO_FETCHED,
                        payload: result
                    }
                )
            );
    };
}

export function downloadImagemEstrutura(idPatrimonio, nomeArquivo, arquivo) {
    return (dispatch) => {
        if (arquivo && arquivo.length > 0) {
            return dispatch([
                { type: '' },
                downloadFile(arquivo, nomeArquivo),
            ]);
        }

        return axios
            .get(`${PATRIMONIO_API}downloadestruturaoperacao/${idPatrimonio}`)
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                    downloadFile(result.content, nomeArquivo),
                ])
            });
    }
}

export function goToFormPage(editAction, idPatrimonio) {
    let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;
    return dispatch => {
        return dispatch([
            history.push(`/${OPERACAO_ROUTE_PATH}/${PATRIMONIO_ROUTE_PATH}/${routeAction}/${idPatrimonio}`)
        ]);
    };
}


export function getPatrimoniosOperacao(idsOperacoes, page = 1) {
    let params = idsOperacoes.length > 0 ? idsOperacoes : 0;

    return (dispatch) => {
        return axios
            .get(OPERACAO_API + `${params}/patrimonios?pagina=${page}&tamanhoPagina=10`)
            .then(result =>
                dispatch({
                    type: PATRIMONIO_OPERACAO_FETCHED,
                    payload: result
                })
            );
    }
}

export function getPatrimonio(idPatrimonio) {
    return (dispatch) => {
        return axios
            .get(PATRIMONIO_API + `tela/${idPatrimonio}`)
            .then(result => {
                dispatch([
                    {
                        type: LOAD_FORM_PATRIMONIO,
                        payload: result.content
                    },
                    serializeFieldsPatrimonioForm(result.content.patrimonioSeparado)
                ])
            });
    }
}

export function changeMoeda(simbolo) {
    return (dispatch) => {
        return dispatch({
            type: LOAD_FORM_PATRIMONIO_MOEDA,
            payload: simbolo || null
        })
    }  
}

export function loadFormPatrimonio(idPatrimonio, editAction) {
    return (dispatch) => {
        return axios.get(PATRIMONIO_API + `tela/${idPatrimonio || ''}`)
            .then(result => {
                let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;

                dispatch([
                    idPatrimonio ? history.push(`/${PATRIMONIO_ROUTE_PATH}/${routeAction}/${idPatrimonio}`) : undefined,
                    {
                        type: LOAD_FORM_PATRIMONIO,
                        payload: result.content
                    },
                    {
                        type: LOAD_FORM_PATRIMONIO_VINCULOS,
                        payload: []
                    },
                    {
                        type: LOAD_FORM_PATRIMONIO_CONTAS,
                        payload: []
                    },
                    serializeFieldsPatrimonioForm(result.content.patrimonioSeparado)
                ])
            });
    };
}

export function loadFormPatrimonioVinculos(idPatrimonio) {
    return (dispatch) => {
        return axios.get(PATRIMONIO_API + `${idPatrimonio}/vinculos`)
            .then(result => {
                dispatch([
                    {
                        type: LOAD_FORM_PATRIMONIO_VINCULOS,
                        payload: result.content
                    },
                ])
            });
    };
}

export function loadFormPatrimonioContas(idPatrimonio, page = 1) {
    return (dispatch) => {
        return axios.get(PATRIMONIO_API + `${idPatrimonio}/contas-vinculadas?&pagina=${page}&tamanhoPagina=10`)
            .then(result => {
                dispatch([
                    {
                        type: LOAD_FORM_PATRIMONIO_CONTAS,
                        payload: result.content?.hasItems ? result.content : [],
                    },
                ])
            });
    };
}

export function newPatrimonioForm() {
    return dispatch => {
        return dispatch([
            loadFormPatrimonio(),
            history.push(`/${PATRIMONIO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`)
        ]);
    };
}

export function getPatrimoniosNaoVinculados(term) {
    return (dispatch) => {
        return axios
            .get(`${PATRIMONIO_API}/sugestoesnaovinculado?termo=${term}`)
            .then(result =>
                dispatch({
                    type: PATRIMONIO_NAO_VINCULADO_OPTIONS_FETCHED,
                    payload: result.content
                })
            );
    }
}

export function savePatrimonio(patrimonio) {
    const patrimonioDeserialized = deserializeFieldsPatrimonioForm(patrimonio);

    return (dispatch) => {
        dispatch([
            {
                type: SAVE_FORM_PATRIMONIO_ERRORS,
                payload: null
            },
        ])
        return axios
            .post(PATRIMONIO_API, { ...patrimonioDeserialized })
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    goBackToList()
                ])
            ).catch((err) => {
                if (err && err.data?.messages && err.data?.messages?.hasError) {
                    document.getElementById('topContent').scrollTo({ top: 0, behavior: 'smooth' });
                    dispatch([
                        {
                            type: SAVE_FORM_PATRIMONIO_ERRORS,
                            payload: err.data?.messages
                        },
                    ])
                }
            })
    };
}

export function editPatrimonio(patrimonio) {
    const patrimonioDeserialized = deserializeFieldsPatrimonioForm(patrimonio);

    return (dispatch) => {
        return axios
            .put(PATRIMONIO_API, { ...patrimonioDeserialized })
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    goBackToList()
                ])
            );
    };
}

export function removePatrimonio(idPatrimonio, idsPatrimonios) {
    return (dispatch) => {
        return axios
            .delete(PATRIMONIO_API + idPatrimonio)
            .then(result =>
                dispatch([
                    showPatrimonioDeleteDialog(false, 0),
                    showPatrimonioList(true, idsPatrimonios),
                    showAllMessages(result.messages)
                ])
            );
    }
}

export function addPatrimonioNaoVinculado(idsPatrimonios) {
    return (dispatch) => {
        return axios
            .get(`${PATRIMONIO_API}/codigob3cetip?idsPatrimonios=${(idsPatrimonios && idsPatrimonios.length > 0) ? idsPatrimonios : 0}&pagina=1&tamanhoPagina=10`)
            .then(result =>
                dispatch(
                    {
                        type: PATRIMONIO_VINCULADO_OPERACAO_FETCHED,
                        payload: result.content
                    }
                )
            );
    }
}

export function savePatrimoniosVinculados(patrimonios, idOperacao) {
    const patrimoniosVinculadosDeserialized = deserializePatrimoniosVinculados(patrimonios, idOperacao);

    let operacaoIds = [...new Set(patrimoniosVinculadosDeserialized.map((item) => item.idOperacao))];

    return (dispatch) => {
        return axios
            .post(`${OPERACAO_API}vinculo/patrimonios`, { vinculoPatrimonios: patrimoniosVinculadosDeserialized })
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    saveSegmentoOperacao(operacaoIds),
                    showPatrimonioOperacaoList(true),
                    history.push(`/${OPERACAO_ROUTE_PATH}/${PATRIMONIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
                ])
            );
    }
}

export function saveSegmentoOperacao(ids) {
    return (dispatch) => {
        return axios
            .post(`${OPERACAO_API}segmento`, { operacaoIds: ids })
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                ])
            );
    }
}

export function selectedPatrimonioOption(option) {
    let patrimoniosSelected = option ? option.map(o => o.key) : [];

    return (dispatch) => {
        return dispatch([
            {
                type: SELECTED_PATRIMONIO_OPTION,
                payload: option
            },
            showPatrimonioList(true, patrimoniosSelected)
        ])
    }
}

export function selectedPatrimonioNaoVinculadoOption(option) {
    let idsPatrimonios = option
        ? option.map(o => o.key)
        : [];

    return (dispatch) => {
        return dispatch([
            {
                type: SELECTED_PATRIMONIO_NAO_VINCULADO_OPTIONS,
                payload: option
            },
            addPatrimonioNaoVinculado(idsPatrimonios)
        ])
    }
}

export function addCodigoB3Cetip(codigoB3Cetip) {
    return (dispatch) => {
        if (codigoB3Cetip) {
            return dispatch([
                arrayPush("patrimonioForm", "codigoB3CetipList", codigoB3Cetip),
                change("patrimonioForm", "codigoB3Cetip", {}),
            ]);
        }
    }
}

export function removeCodigoB3Cetip(index) {
    return (dispatch) => {
        return dispatch(
            arrayRemove("patrimonioForm", "codigoB3CetipList", index)
        );
    }
}

export function disassociateCodigoB3Cetip(itemDesvinculado) {
    return (dispatch, getState) => {
        const selector = formValueSelector('patrimonioForm')
        let listaDeRegra = selector(getState(), 'codigoB3CetipList') || null;

        const index = listaDeRegra.findIndex(item =>
            item.codigo === itemDesvinculado.codigo);

        return dispatch([
            change("patrimonioForm", `codigoB3CetipList[${index}].operacaoCodigoB3CetipList`, []),
            change("patrimonioForm", `codigoB3CetipList[${index}].codigoOpeaAssociadoList`, []),
            arrayPush("patrimonioForm", `codigoB3CetipDesvinculadoList`, ...itemDesvinculado.operacaoCodigoB3CetipList),
        ])
    }
}

export function showPatrimonioList(showList, idsPatrimonio) {
    return (dispatch) => {
        return dispatch([
            {
                type: PATRIMONIO_SHOW_LIST,
                payload: showList
            },
            getPatrimonios(idsPatrimonio)
        ])
    }
}

export function goBackToList() {
    return (dispatch) => {
        return dispatch([
            showConfirmDialog(false),
            // { type: PATRIMONIO_CLEAN_LIST },
            history.push(`/${PATRIMONIO_ROUTE_PATH}`)
        ])
    }
}

export function showPatrimonioOperacaoList(showList) {
    return (dispatch) => {
        return dispatch([
            showConfirmDialog(false),
            history.push(`/${OPERACAO_ROUTE_PATH}/${PATRIMONIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
        ])
    }
}

export function showPatrimonioFormVinculoOperacao(showForm) {
    return (dispatch) => {
        return dispatch([
            {
                type: PATRIMONIO_SHOW_FORM_VINCULO_OPERACAO,
                payload: showForm
            },
            history.push(`/${OPERACAO_ROUTE_PATH}/${PATRIMONIO_OPERACAO_ROUTE_PATH}/${VINCULAR_ROUTE_PATH}`),
        ])
    }
}

export function changePatrimonioFormTab(formTab) {
    return (dispatch) => {
        return dispatch([
            {
                type: PATRIMONIO_FORM_TAB,
                payload: formTab
            }
        ])
    }
}

export function showPatrimonioTabActive() {
    return dispatch => {
        return dispatch([selectTab(PATRIMONIO_TAB), showPatrimonioOperacaoList(true)]);
    };
}

export function showPatrimonioDeleteDialog(showDialog, idPatrimonio) {
    return (dispatch) => {
        return dispatch([
            {
                type: PATRIMONIO_SHOW_DELETE_DIALOG,
                payload: { showDialog, idPatrimonio }
            }
        ])
    }
}

export function importarPatrimoniosPorPlanilha(file) {
    let formData = new FormData();
    formData.append("file", file);

    return dispatch => {
        return axios
            .post(`${PATRIMONIO_API}importar/planilha`, formData)
            .then(result => {
                let operacaoIds = [...new Set(result.content.map((item) => item.idOperacao))];
                dispatch([
                    // vincularCodigoB3CetipAOperacao(result.content),
                    salvarPatrimoniosVinculados(result.content, operacaoIds),
                ])
            });
    };
}

export function salvarPatrimoniosVinculados(patrimonios, operacaoIds) {
    return (dispatch) => {
        return axios
            .post(`${OPERACAO_API}vinculo/patrimonios`, { vinculoPatrimonios: patrimonios })
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    saveSegmentoOperacao(operacaoIds),
                    showPatrimonioOperacaoList(true),
                    history.push(`/${OPERACAO_ROUTE_PATH}/${PATRIMONIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
                ])
            );
    }
}

// export function vincularCodigoB3CetipAOperacao(codigoB3CetipList) {
//     return dispatch => {
//         return axios
//             .post(`${PATRIMONIO_API}codigob3cetip/vinculo/operacao`, codigoB3CetipList)
//             .then(result => {
//                 dispatch([
//                     showAllMessages(result.messages),
//                 ])
//             });
//     };
// }

export function exportModeloPatrimonio() {
    return (dispatch) => {
        return axios
            .get(`${PATRIMONIO_API}exportar/excel/modelo`)
            .then(result => {
                const fileName = 'ModeloPatrimonio.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            }); 

    }
}

export function exportPlanilhaPatrimoniosVinculadosAOperacao(idsOperacoes) {
    let params = idsOperacoes.length > 0 ? idsOperacoes : 0;

    return (dispatch) => {
        return axios
            .get(OPERACAO_API + `export/planilha/patrimonios/${params}`)
            .then(result => {
                const fileName = 'Patrimonios_Planilha.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function getOperacoesByPS(idPS) {
    return (dispatch) => {
        dispatch([
            {
              type: LIST_PS_OPERACOES,
              payload: [],
            },
        ])
  
        return axios
            .get(`${PATRIMONIO_API}${idPS}/operacoes`)
            .then(result => {
                dispatch([
                    {
                      type: LIST_PS_OPERACOES,
                      payload: result?.content || [],
                    },
                ])
            });
    }
  }
