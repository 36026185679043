/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import { validate } from "../validation/ContasReceberValidation";
import { showConfirmDialog } from "../../../../../actions/common/modal/ConfirmDialogAction";
import FixedFloaterErrors from "../../../../common/floats/FixedFloaterErrors";
import DropdownListInput from "../../../../common/input/DropdownListInput";
import Input from "../../../../common/input/Input";
import Textarea from "../../../../common/input/Textarea";
import MaskedInput from "../../../../common/input/MaskedInput";
import Button from "../../../../common/button/Button";
import {
  reduxForm,
  formValueSelector,
  Field,
  change,
  getFormValues,
} from "redux-form";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { withStyles } from "@material-ui/core/styles";
import { mudaDependenteOnChange, backToList, onDeleteDocument, onDropDocument, limpaDependentesOnChange, loadContaOrigem, downloadContasReceberDocument, loadOperacoes, loadContratoReceita } from "../../../../../actions/contasReceber/ContasReceberAction";
import { convertFileToBase64 } from "../../../../../utils/helpers/FileHelper";
import { useDropzone } from "react-dropzone";
import { msgError } from "../../../../../utils/helpers/OthersHelpers";
import ModalCancelamentoContasReceber from "../modals/ModalCancelamentoContasReceber";
import ModalRevisaoContasReceber from "../modals/ModalRevisaoContasReceber";
import PercentageInput from "../../../../common/input/PercentageInput";
import { convertCurrencyToNumber } from "../../../../../utils/helpers/NumberHelper";
import ModalAction from "../../../../common/modal/ModalAction";

const styles = (theme) => ({
  parent: {
    width: "100%",
    marginTop: "0.5%",
    backgroundColor: "transparent",
  },
  mrButton: {
    marginRight: theme.spacing.unit,
  },
  button: {
    color: "#630A37",
    "&:disabled": {
      color: "#D0D0D0",
    },
  },
  buttons: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "1rem !important",
  },
  stepper: {
    backgroundColor: "transparent",
  },
  active: {},
  completed: {},
  customStepLabel: {
    "& [class*='MuiStepLabel-labelContainer']": {
      display: "flex",
      justifyContent: "center",
    },
    "& [class*='MuiTypography-root']": {
      width: "max-content",
      padding: "0 10px",
      borderRadius: "20px",
      // fontSize: "12px",
      // textTransform: "uppercase",
      "&[class*='MuiStepLabel']": {
        backgroundColor: "#B8B8B8",
        color: "#121212c9",
        fontWeight: 500,
      },
      "&[class*='MuiStepLabel-active']": {
        backgroundColor: "#FFC107",
      },
      "&[class*='MuiStepLabel-completed']": {
        backgroundColor: "#83BF74",
        color: "#ffffff",
        "&.revisao": {
          backgroundColor: "#cc0000",
        },
        "&.cancelado": {
          backgroundColor: "#cc0000",
        },
      },
    },
  },
});

let ContasReceberForm = (props) => {
  const {
    handleSubmit,
    title,
    readOnly,
    backToList,
    floatButtonVisible,
    showConfirmDialog,
    showCancelConfirmDialog,
    //
    invalid,
    messages,
    // 
    classes,

    mudaDependenteOnChange,
    limpaDependentesOnChange,
    onDeleteDocument,
    onDropDocument,
    loadContratoReceita,
    // 
    contasReceber,
    documentoList,
    tipoPagamento,
    idProdutoReceita,
    loadContaOrigem,
    loadOperacoes,
    // 
    produtoReceitaList,
    tipoPagamentoList,
    receitaList,
    contaOrigemList,
    origemRecursoList,
    modalidadeList,
    patrimonioSeparadoList,
    faturarContraList,
    empresaFaturamentoList,
    operacoesList,
    downloadContasReceberDocument,
    idPatrimonioSeparado,
    idOperacao,
  } = props;

  const [isDragging, setIsDragging] = React.useState(false);
  const [openCancelamento, setOpenCancelamento] = React.useState(false);
  const [openRevisar, setOpenRevisar] = React.useState(false);

  const statusValue = contasReceber?.statusAprovacao?.key;
  const hasApproval = contasReceber?.aprovacao;

  const user = JSON.parse(localStorage.getItem("userLogged"));
  const areas = user.areasAtuacoes;
  const acessosUser = user.acessos;
  const isTreasury = areas.some(({id}) => id === 9);
  const isAdmin = acessosUser.some((acesso) => acesso === 1);
  
  const APROVADO = statusValue == 3;
  const REVISAO = statusValue == 6;
  const CANCELADO = statusValue == 5;

  let renderStatus = [
      {
        key: 1,
        value: "Cadastro Pendente",
        raw: "CadastroPendente",
        show: false,
        concluidos: [],
      },
      {
        key: 2,
        value: "Cadastrado",
        raw: "Cadastrado",
        show: true,
        concluidos: [1],
      },
      {
        key: REVISAO ? 6 : 3,
        value: `${REVISAO ? 'A Revisar' : 'Aprovado'}`,
        raw: `${REVISAO ? 'ARevisar' : 'Aprovado'}`,
        show: true,
        concluidos: [1, 2],
      },
      {
        key: CANCELADO ? 5 : 4,
        value: `${CANCELADO ? 'Cancelado' : 'Exportado'}`,
        raw: `${CANCELADO ? 'Cancelado' : 'Exportado'}`,
        show: true,
        concluidos: [1, 2, 3],
      },
  ];

  const steps = renderStatus.filter((sts) => sts.show).map((sts) => sts.value);
  const concluidos = renderStatus.filter((sts) => sts.key === statusValue).flatMap((sts) => sts.concluidos);

  const isRecorrente = tipoPagamento?.key == 3;

  function CustomStepIcon({ active, completed, classes }) {
    const emRevisao = classes?.completed?.includes("revisao");
    const emCancelamento = classes?.completed?.includes("cancelado");
    const cRevi = "#cc0000";
    const cComp = "#83BF74";
    const cActv = "#FFC107";
    const cDflt = "#888888";

    return emRevisao || emCancelamento ? (
      <RadioButtonChecked style={{ color: cRevi }} />
    ) : completed ? (
      <CheckCircleOutline style={{ color: cComp }} />
    ) : active ? (
      <RadioButtonChecked style={{ color: cActv }} />
    ) : (
      <RadioButtonUnchecked style={{ color: cDflt }} />
    );
  }

  // UPLOAD DOCUMENTS
  
  const onDrop = async (acceptedFiles) => {
    const files = acceptedFiles;

    let arquivos = await Promise.all(
      files.map(async (file) => ({
        nomeArquivo: file.name,
        idContaReceber: contasReceber?.id || null,
        arquivoAnexado: await convertFileToBase64(file),
        formatoArquivo: file?.name?.split('.').pop().toLowerCase() || '',
      }))
    );

    onDropDocument(arquivos);
    setIsDragging(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls"],
    },
    onDragEnter: () => {
      setIsDragging(true);
    },
    onDragLeave: () => {
      setIsDragging(false);
    },
    onDropRejected: (rejected) => {
      const names = rejected.map((item) => item.file.name);
      names.forEach((name) => msgError(`Arquivo (${name}) inválido`));
    },
    disabled: readOnly && !APROVADO,
  });

  const calcularValorBruto = (value, fieldName, target) => {
    const { change, formValues } = props;
    const grossUp = (formValues[target].grossUp || 0) / 100;
    const valorLiquido = convertCurrencyToNumber(formValues[target].valorLiquido || 0);
    
    value = fieldName === "grossUp" ? (value || 0) / 100 : convertCurrencyToNumber(value) || 0;
  
    const valorBruto = fieldName === "grossUp" 
      ? valorLiquido / (1 - value) 
      : fieldName === "valorLiquido" 
      ? value / (1 - grossUp) 
      : null;
  
    const valorBrutoFinal = (valorBruto === Infinity || isNaN(valorBruto)) ? 0 : valorBruto;
  
    change("ContasReceberForm", `${target}.valorBruto`, valorBrutoFinal);
  }

  // -----

  return (
    <Form>
      <FormHeader
        title={title}
        col={"col-md"}
        offSet={20}
      ></FormHeader>

      <Stepper
          className="my-3"
          classes={{ root: classes.stepper }}
          activeStep={CANCELADO || REVISAO ? null : concluidos.length || 0}
          alternativeLabel
        >
          {steps.map((step, index) => (
            <Step
              key={index}
              completed={concluidos.some((et) => et === index + 1)}
            >
              <StepLabel
                completed={concluidos.some((et) => et === index + 1)}
                className={classes.customStepLabel}
                StepIconComponent={CustomStepIcon}
                classes={{
                  completed: `
                    ${classes.completed} 
                    ${((REVISAO && index + 1 == 2) || (CANCELADO && index + 1 == 3)) && "revisao"} 
                  `,
                }}
                StepIconProps={{
                  classes: {
                    completed: `
                      ${classes.completed} 
                      ${((REVISAO && index + 1 == 2) || (CANCELADO && index + 1 == 3)) && "revisao"}
                    `,
                    active: classes.active,
                    disabled: classes.disabled,
                  },
                }}
              >
                <span>{step}</span>
              </StepLabel>
            </Step>
          ))}
      </Stepper>

      <FormContent handleSubmit={handleSubmit}>
        <div className="row">
          {messages?.hasError && (
            <FixedFloaterErrors
              messages={messages}
              title="Status do Cadastro"
            />
          )}

          {/* Status */}
          {Boolean(hasApproval) && (
            <div className="col-12">
              <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                <span className="h3 page-head__title">Status</span>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="contaReceber.aprovacao.dataSolicitacao"
                      name="contaReceber.aprovacao.dataSolicitacao"
                      label="Data de Cadastro"
                      type={"date"}
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="contaReceber.aprovacao.solicitante.nome"
                      name="contaReceber.aprovacao.solicitante.nome"
                      label="Solicitante"
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="contaReceber.aprovacao.idFormatado"
                      name="contaReceber.aprovacao.idFormatado"
                      label="ID Aprovação"
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="contaReceber.aprovacao.aprovadores[0].aprovador.nome"
                      name="contaReceber.aprovacao.aprovadores[0].aprovador.nome"
                      label="Aprovador 1"
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="contaReceber.aprovacao.aprovadores[0].statusAprovacao.value"
                      name="contaReceber.aprovacao.aprovadores[0].statusAprovacao.value"
                      label="Status Aprovação 1"
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="contaReceber.aprovacao.aprovadores[0].observacao"
                      name="contaReceber.aprovacao.aprovadores[0].observacao"
                      label="Comentário Aprovador 1"
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="contaReceber.aprovacao.aprovadores[1].aprovador.nome"
                      name="contaReceber.aprovacao.aprovadores[1].aprovador.nome"
                      label="Aprovador 2"
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="contaReceber.aprovacao.aprovadores[1].statusAprovacao.value"
                      name="contaReceber.aprovacao.aprovadores[1].statusAprovacao.value"
                      label="Status Aprovação 2"
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="contaReceber.aprovacao.aprovadores[1].observacao"
                      name="contaReceber.aprovacao.aprovadores[1].observacao"
                      label="Comentário Aprovador 2"
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Identificação */}
          <div className="col-12">
            <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
              <span className="h3 page-head__title">Identificação</span>
              <div className="row">
                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="contaReceber.tipoPagamento"
                    name="contaReceber.tipoPagamento"
                    label="Tipo de Pagamento"
                    options={tipoPagamentoList}
                    textField="value"
                    valueField="key"
                    filter="contains"
                    readOnly={readOnly}
                    required
                    onChange={() => {
                      limpaDependentesOnChange([
                        "contaReceber.idReceitaContrato",
                        "contaReceber.idContaOrigem",
                        "contaReceber.idOrigemRecurso",
                        "contaReceber.modalidade",
                      ])
                    }}
                  />
                </div>
                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="contaReceber.idProdutoReceita"
                    name="contaReceber.idProdutoReceita"
                    label="Tipo de Receita"
                    options={produtoReceitaList}
                    textField="nome"
                    valueField="id"
                    filter="contains"
                    readOnly={readOnly}
                    required
                    onChange={(_e, v) => {
                      mudaDependenteOnChange("contaReceber.idReceitaContrato")

                      if (v?.id) {
                        if (idOperacao && idPatrimonioSeparado && tipoPagamento.key == 3) {
                          const OP = idOperacao?.id || idOperacao
                          const PS = idPatrimonioSeparado?.id || idPatrimonioSeparado

                          let append = `?idPatrimonioSeparado=${PS}&idOperacao=${OP}&tipoReceita=${v.id}`;
                          loadContratoReceita(append);
                        }
                      }
                    }}
                  />
                </div>
                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="contaReceber.idFaturarContra"
                    name="contaReceber.idFaturarContra"
                    label="Faturar Contra"
                    options={faturarContraList}
                    textField="razaoSocialComCnpj"
                    valueField="id"
                    filter="contains"
                    readOnly={readOnly}
                    required
                  />
                </div>
                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="contaReceber.idEmpresaFaturamento"
                    name="contaReceber.idEmpresaFaturamento"
                    label="Empresa Faturamento"
                    options={empresaFaturamentoList}
                    textField="razaoSocialComCnpj"
                    valueField="id"
                    filter="contains"
                    readOnly={readOnly}
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Alocação */}
          <div className="col-12">
            <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
              <span className="h3 page-head__title">Alocação</span>
              <div className="row">
                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="contaReceber.idPatrimonioSeparado"
                    name="contaReceber.idPatrimonioSeparado"
                    label="Patrimônio Separado"
                    options={patrimonioSeparadoList}
                    textField="descricao"
                    valueField="id"
                    filter="contains"
                    readOnly={readOnly}
                    required
                    onChange={async (e) => {
                      mudaDependenteOnChange("contaReceber.idOperacao")
                      mudaDependenteOnChange("contaReceber.idContaOrigem")
                      mudaDependenteOnChange("contaReceber.idReceitaContrato")

                      if (e?.id) {
                        await loadOperacoes(e?.id);
                        if (tipoPagamento.key !== 3) await loadContaOrigem(e?.id, (idOperacao?.id || idOperacao))
                      }
                    }}
                  />
                </div>
                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="contaReceber.idOperacao"
                    name="contaReceber.idOperacao"
                    label="Operação"
                    options={operacoesList}
                    textField="descricao"
                    valueField="id"
                    filter="contains"
                    readOnly={readOnly}
                    required
                    onChange={async (e) => {
                      mudaDependenteOnChange("contaReceber.idContaOrigem")
                      mudaDependenteOnChange("contaReceber.idReceitaContrato")

                      if (e?.id) {
                        if (tipoPagamento.key !== 3) await loadContaOrigem((idPatrimonioSeparado?.id || idPatrimonioSeparado), e?.id)

                        if (idProdutoReceita && tipoPagamento.key == 3) {
                          const PS = idPatrimonioSeparado?.id || idPatrimonioSeparado
                          const TR = idProdutoReceita?.id || idProdutoReceita

                          let append = `?idPatrimonioSeparado=${PS}&idOperacao=${e?.id}&idTipoReceita=${TR}`;
                          await loadContratoReceita(append);
                        }
                      }
                    }}
                  />
                </div>

                {(isRecorrente && idProdutoReceita && idPatrimonioSeparado && idOperacao) && (
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="contaReceber.idReceitaContrato"
                      name="contaReceber.idReceitaContrato"
                      label="Contrato de Receita"
                      options={receitaList}
                      textField="nomeExibicao"
                      valueField="idReceitaContrato"
                      filter="contains"
                      readOnly={readOnly || !isRecorrente || !(idProdutoReceita && idPatrimonioSeparado && idOperacao)}
                      required
                    />
                  </div>
                )}

              </div>
              <div className="row">
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="contaReceber.idContaOrigem"
                      name="contaReceber.idContaOrigem"
                      label="Conta Origem"
                      options={contaOrigemList}
                      textField="nomeExibicao"
                      valueField="id"
                      filter="contains"
                      readOnly={readOnly || isRecorrente}
                      required={!isRecorrente}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="contaReceber.idOrigemRecurso"
                      name="contaReceber.idOrigemRecurso"
                      label="Origem de Recursos"
                      options={origemRecursoList}
                      textField="descricao"
                      valueField="id"
                      filter="contains"
                      readOnly={readOnly || isRecorrente}
                      required={!isRecorrente}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="contaReceber.modalidade"
                      name="contaReceber.modalidade"
                      label="Modalidade"
                      options={modalidadeList}
                      textField="value"
                      valueField="key"
                      readOnly={readOnly || isRecorrente}
                      required={!isRecorrente}
                    />
                  </div>
              </div>
            </div>
          </div>

          {/* Dados de Pagamento */}
          <div className="col-12">
            <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
              <span className="h3 page-head__title">Dados de Pagamento</span>
              <div className="row">
                <div className="col-lg">
                  <Field
                    component={MaskedInput}
                    inputId="contaReceber.valorLiquido"
                    name="contaReceber.valorLiquido"
                    label="Valor Líquido"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                    prefix="R$ "
                    fixedDecimalScale
                    readOnly={readOnly}
                    required
                    onChange={(_e, v) => calcularValorBruto(v, "valorLiquido", "contaReceber")}
                  />
                </div>
                <div className="col-lg">
                  <Field
                      component={PercentageInput}
                      name="contaReceber.grossUp"
                      inputId="contaReceber.grossUp"
                      decimalScale={4}
                      limitTo={99.9999}
                      decimalSeparator={","}
                      label="Gross-up (Flat)"
                      readOnly={readOnly}
                      required
                      onChange={(_e, v) => calcularValorBruto(v, "grossUp", "contaReceber")}
                    />
                </div>
                <div className="col-lg">
                  <Field
                    component={MaskedInput}
                    inputId="contaReceber.valorBruto"
                    name="contaReceber.valorBruto"
                    label="Valor Bruto"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                    prefix="R$ "
                    fixedDecimalScale
                    readOnly={true}
                    required
                  />
                </div>
                <div className="col-lg">
                  <Field
                    component={Input}
                    inputId="contaReceber.dataPagamento"
                    name="contaReceber.dataPagamento"
                    label="Data de Pagamento"
                    type={"date"}
                    readOnly={readOnly}
                    required
                  />
                </div>
                
              </div>
              <div className="row">
                <div className="col-lg">
                  <Field
                    component={Textarea}
                    inputId="contaReceber.descricao"
                    name="contaReceber.descricao"
                    type="text"
                    minHeight={5}
                    maxLength={1000}
                    label="Descrição"
                    readOnly={readOnly}
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Documentos */}
          <div className="col-12">
            <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
              <span className="h3 page-head__title">Documentos</span>

              <div
                {...getRootProps()}
                className="w-100 d-flex flex-column justify-content-start align-items-center text-center position-relative p-2 rounded my-2"
                style={{
                  minHeight: "100px",
                  maxHeight: "200px",
                  transition: "border 0.3s ease-out",
                  border: isDragging ? "2px dashed #630A37" : `${readOnly ? '1px solid #B8B8B8' : '2px dashed #cccccc'}`,
                }}
              >
                <input {...getInputProps()} />
                {(!readOnly || (readOnly && APROVADO)) && (
                  <p className="py-2 mb-0">
                    Arraste os arquivos ou{" "}
                    <u className="btn-link-opea" role="button">
                      clique aqui
                    </u>{" "}
                    para upload
                  </p>
                )}
                <div className="d-flex flex-column h-100 overflow-auto w-100 p-2">
                  {documentoList?.length > 0 &&
                    documentoList?.map((file, i) => {
                      return (
                        <div
                          key={`file-${i}`}
                          onClick={(e) => e.stopPropagation()}
                          style={{
                            backgroundColor: "#E7E7E7",
                            border: "1px solid #B8B8B8",
                          }}
                          className="p-2 d-flex flex-row align-items-center justify-content-between rounded fw-400 my-1"
                        >
                          <span>{file.nomeArquivo || file.name}</span>
                          <div className="d-flex flex-row">
                            {(file?.nomeArquivo && contasReceber?.id) && (
                              <i
                                role="button"
                                className="fa fa-cloud-download f-20 mr-3"
                                onClick={() => downloadContasReceberDocument({
                                  idContaReceber: contasReceber?.id,
                                  nomeArquivo: file?.nomeArquivo
                                })}
                              ></i>
                            )}

                            {(!readOnly || (readOnly && APROVADO)) && (
                              <i
                                role="button"
                                onClick={() => onDeleteDocument(i)}
                                className="fa fa-minus-circle f-20 d-flex align-items-center mr-1"
                              ></i>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* --- ACTIONS --- */}
        <div className="col-12 px-0">
            <div className="d-flex flex-row justify-content-between align-items-center col-12 mb-4 mt-2 p-0">
              <Button
                name={"Voltar Tela"}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                action={() => {
                  floatButtonVisible
                    ? showConfirmDialog(true)
                    : backToList();
                }}
              />

              {(!statusValue || statusValue === 1 || statusValue === 6) && (
                <Button
                  type="submit"
                  name={"Salvar Alterações"}
                  classes="warn-bg-imp text-dark px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => {
                    mudaDependenteOnChange("contaReceber.typeSubmit");

                    invalid && msgError("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.")
                  }}
                />
              )}

              {(statusValue >= 2 && statusValue <= 3 && (isTreasury || isAdmin)) && (
                <>
                  <Button
                    tooltipType="opea"
                    name={"Cancelar Faturamento"}
                    action={() => setOpenCancelamento(true)}
                    classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  />
              
                  <Button
                    name={"Revisar Cadastro"}
                    classes="warn-bg-imp text-dark px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                    action={() => setOpenRevisar(true)}
                  />
                </>
              )}

              {(!statusValue || statusValue == 1 || statusValue == 6) && (
                <Button
                  type="submit"
                  name={"Enviar para Aprovação"}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => {
                    mudaDependenteOnChange("contaReceber.typeSubmit");
                    mudaDependenteOnChange("contaReceber.enviarParaAprovacao", true);
      
                    invalid && msgError("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.")
                  }}
                />
              )}
              
            </div>
        </div>
      </FormContent>
      <ModalAction
        title="Aviso"
        open={showCancelConfirmDialog}
        close={() => showConfirmDialog(false)}
        message="Todos os dados modificados serão perdidos"
        action={() => backToList()}
      />

      {(statusValue >= 2 && statusValue <= 5) && (
        <ModalCancelamentoContasReceber
          item={contasReceber}
          open={openCancelamento}
          close={() => setOpenCancelamento(false)}
        />
      )}

      {(statusValue == 2 || statusValue == 3) && (
        <ModalRevisaoContasReceber
          item={contasReceber}
          open={openRevisar}
          close={() => setOpenRevisar(false)}
        />
      )}
    </Form>
  );
}

const selector = formValueSelector("ContasReceberForm");

const mapStateToProps = (state) => ({
  formValues: getFormValues("ContasReceberForm")(state),
  valorBruto: selector(state, "contaReceber.valorBruto") || null,
  documentoList: selector(state, "contaReceber.documentoList") || [],
  idProdutoReceita: selector(state, "contaReceber.idProdutoReceita") || null,
  idPatrimonioSeparado: selector(state, "contaReceber.idPatrimonioSeparado") || null,
  idOperacao: selector(state, "contaReceber.idOperacao") || null,
  tipoPagamento: selector(state, "contaReceber.tipoPagamento") || [],
  contasReceber: state.contasReceberReducer.contasReceberFormTypes.contaReceber || [],
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
  produtoList: state.contasReceberReducer.contasReceberFormTypes.produtoList || [],
  contaOrigemList: state.contasReceberReducer.contasReceberFormTypes.contaOrigemList || [],
  origemRecursoList: state.contasReceberReducer.contasReceberFormTypes.origemRecursoList || [],
  movimentacaoFinanceiraList: state.contasReceberReducer.contasReceberFormTypes.movimentacaoFinanceiraList || [],
  patrimonioSeparadoList: state.contasReceberReducer.contasReceberFormTypes.patrimonioSeparadoList || [],
  modalidadeList: state.contasReceberReducer.contasReceberFormTypes.modalidadeList || [],
  receitaList: state.contasReceberReducer.contasReceberFormTypes.receitaList || [],
  empresaFaturamentoList: state.contasReceberReducer.contasReceberFormTypes.empresaFaturamentoList || [],
  faturarContraList: state.contasReceberReducer.contasReceberFormTypes.faturarContraList || [],
  produtoReceitaList: state.contasReceberReducer.contasReceberFormTypes.produtoReceitaList || [],
  tipoPagamentoList: state.contasReceberReducer.contasReceberFormTypes.tipoPagamentoList || [],
  operacoesList: state.contasReceberReducer.contasReceberFormTypes.serieList || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      backToList,
      showConfirmDialog,
      mudaDependenteOnChange,
      onDeleteDocument,
      onDropDocument,
      limpaDependentesOnChange,
      loadContaOrigem,
      downloadContasReceberDocument,
      loadOperacoes,
      loadContratoReceita,
      change,
    },
    dispatch
  );

const ConnectedContasReceberForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContasReceberForm))
);
export default reduxForm({ form: "ContasReceberForm", validate })(
  ConnectedContasReceberForm
);
