import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";

let ModalContatosResponsaveis = (props) => {
  const { close, open, contatos } = props;

  return (
    <>
      <Dialog
        open={open}
        maxWidth={false}
        PaperProps={{ style: { maxWidth: "max-content" } }}
        onClose={() => close()}
      >
        <div className="modal modal-dialog modal-dialog-scrollable m-0">
          <div className="modal-content">
            <div className="modal-header d-flex flex-row align-items-center justify-content-between pb-3">
              <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">Responsáveis</p>
              <Close
                role="button"
                className="text-danger"
                onClick={() => close()}
              />
            </div>

            <div className="modal-body text-dark fw-400 py-2 px-3">
              {contatos?.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="text-center fw-bold f-12-imp border-0"
                      >
                        NOME
                      </th>
                      <th
                        scope="col"
                        className="text-center fw-bold f-12-imp border-0"
                      >
                        FUNÇÃO
                      </th>
                      <th
                        scope="col"
                        className="text-center fw-bold f-12-imp border-0"
                      >
                        TELEFONE
                      </th>
                      <th
                        scope="col"
                        className="text-center fw-bold f-12-imp border-0"
                      >
                        E-MAIL
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {contatos?.map((item, i) => {
                      return (
                        <tr key={`table-regra-${i}`}>
                          <td className="text-center f-12-imp border-0 px-2 py-1">
                            {item?.nome}
                          </td>
                          <td className="text-center f-12-imp border-0 px-2 py-1">
                            {item?.funcao}
                          </td>
                          <td className="text-center f-12-imp border-0 px-2 py-1">
                            {item?.telefone}
                          </td>
                          <td className="text-center f-12-imp border-0 px-2 py-1">
                            {item?.email}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p className="py-2 px-2 mb-2">
                  Não foram encontrados responsáveis no momento
                </p>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispacthToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ModalContatosResponsaveis);
