import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Table from '../../../../../common/table/Table';
import TableHeader from '../../../../../common/table/TableHeader';
import TableContent from '../../../../../common/table/TableContent';
import TableEmptyData from '../../../../../common/table/TableEmptyData';
import PaginationRB from '../../../../../common/pagination/PaginationRB';
import FloatButton from '../../../../../common/button/FloatButton';
import FloatButtonGroup from '../../../../../common/button/FloatButtonGroup';
import { formatPercent } from '../../../../../../utils/helpers/NumberHelper';
import {
  getAtivosOperacao,
  showAtivoFormVinculoOperacao,
  exportPlanilhaAtivosVinculadosAOperacao,
} from '../../../../../../actions/ativo/AtivoAction';
import TableActionWithRouteAuthorization from '../../../../../common/table/TableActionWithRouteAuthorization';
import { authorizationFunction } from '../../../../../../actions/authorization/AuthorizationAction';
import { OPERACAO_ATIVO, CREATE } from '../../../../../../utils/actionsAndFeaturesAuth';
import history from '../../../../../common/routers/history';
import { ATIVO_ROUTE_PATH, DETALHAR_ROUTE_PATH, EDITAR_ROUTE_PATH } from '../../../../../common/routers/constantesRoutes';

const AtivoList = ({
  operacoes,
  ativoOperacaoData,
  messages,
  getAtivosOperacao,
  showAtivoFormVinculoOperacao,
  authorizationFunction,
  exportPlanilhaAtivosVinculadosAOperacao,
  match,
}) => {
  const getIdsOperacao = (operacoes) =>
    Array.isArray(operacoes) && operacoes.length ? operacoes.map((o) => o.key) : operacoes;

  const updateData = () => {
    const idsOperacao = getIdsOperacao(operacoes?.length ? operacoes : match.params.idsOperacao || []);
    getAtivosOperacao(idsOperacao);
  };

  useEffect(() => {
    updateData();
  }, [operacoes.length]);

  const idsOperacoes = operacoes.map((o) => o.key);
  const emptyData = messages?.infos?.[0];

  const tableHeader = (
    <tr>
      <th className="text-center fw-700 text-nowrap">Vínculo</th>
      <th className="text-center fw-700 text-nowrap">Cód. Ativo</th>
      <th className="text-center fw-700 text-nowrap">Contrato</th>
      <th className="text-center fw-700 text-nowrap">Devedor</th>
      <th className="text-center fw-700 text-nowrap">Qtd. Vinculados</th>
      <th className="text-center fw-700 text-nowrap">Total % Vinculados</th>
      <th className="text-center"></th>
    </tr>
  );

  const tableContent = ativoOperacaoData?.hasItems ? (
    ativoOperacaoData.items.map((ativo) => (
      <tr key={ativo.idAtivo}>
        <td className="text-center fw-400 text-nowrap">{ativo.codigoOpea}</td>
        <td className="text-center fw-400 text-nowrap">{ativo.codigoAtivo}</td>
        <td className="text-center fw-400 text-nowrap">{ativo.descricaoContrato}</td>
        <td className="text-center fw-400">{ativo.descricaoDevedor}</td>
        <td className="text-center fw-400 text-nowrap">{ativo.quantidadeCodigoB3CetipVinculado}</td>
        <td className="text-center fw-400 text-nowrap">{formatPercent(ativo.totalPercentualB3Cetip)}</td>
        <TableActionWithRouteAuthorization
          space="2"
          feature={OPERACAO_ATIVO}
          updateAction={() => history.push(`/${ATIVO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/${ativo.idAtivo}`)}
          viewAction={() => history.push(`/${ATIVO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/${ativo.idAtivo}`)}
        />
      </tr>
    ))
  ) : (
    <TableEmptyData message={emptyData} />
  );

  return (
    <div>
      {ativoOperacaoData.totalCount > 0 && (
        <div className="d-flex flex-column">
          <div className="mx-2 shadow-custom bg-white rounded-12">
            <p className="page-head__title f-28 mb-0 px-3 mt-4 mb-3">Ativos das Operações</p>
            <Table
              withoutHeader
              classes="card col-12 shadow-none bg-white py-0 m-0 mb-2 rounded-12"
              totalItems={ativoOperacaoData.totalCount}
              pagination={
                <PaginationRB
                  pageNumber={ativoOperacaoData.pageIndex - 1}
                  itemsPerPage={ativoOperacaoData.pageSize}
                  totalItems={ativoOperacaoData.totalCount}
                  filter={idsOperacoes}
                  fetchFunction={getAtivosOperacao}
                  pageRange={5}
                />
              }
            >
              <TableHeader>{tableHeader}</TableHeader>
              <TableContent>{tableContent}</TableContent>
            </Table>
          </div>
        </div>
      )}
      <FloatButtonGroup>
        <FloatButton
          activeClassName={`${ativoOperacaoData?.hasItems ? 'nd3' : 'nd1'} nds dark-grey-button`}
          title="Download de Ativos"
          icon="fa fa-file-excel-o edit edit-sub-menu"
          action={() => exportPlanilhaAtivosVinculadosAOperacao(getIdsOperacao())}
        />
        <FloatButton
          activeClassName="nd1 nds blue-button"
          title="Vincular Ativos"
          icon="fa fa-link edit edit-sub-menu"
          action={() =>
            authorizationFunction(
              { idAcao: CREATE, idFuncionalidade: OPERACAO_ATIVO },
              () => showAtivoFormVinculoOperacao(true)
            )
          }
        />
      </FloatButtonGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ativoOperacaoData: state.ativoReducer.ativos,
  messages: state.ativoReducer.messages,
  operacoes: state.operacaoReducer.selectedOperacoes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAtivosOperacao,
      showAtivoFormVinculoOperacao,
      authorizationFunction,
      exportPlanilhaAtivosVinculadosAOperacao,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AtivoList));
