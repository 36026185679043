import axios from "axios";
import {
  change,
  arrayRemove,
  arrayPush,
} from "redux-form";
import {
  deserializeFieldsAssembleiaFormFilter,
  serializeFieldsAssembleia,
  deserializeFieldsAssembleia,
} from "../../components/business/assembleia/mappings/AssembleiaMap";
import {
  ASSEMBLEIA_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  LISTA_ROUTE_PATH,
} from "./../../components/common/routers/constantesRoutes";
import history from "../../components/common/routers/history";
import { showAllMessages } from "../message/ErrorMessagesAPIAction";
import { downloadExcelFile, downloadFile } from "../../utils/helpers/FileHelper";
import { msgError } from "../../utils/helpers/OthersHelpers";
import { changeMessage } from "../infra/LoadingScreenAction";

export const ASSEMBLEIAS_FETCHED = "ASSEMBLEIAS_FETCHED";
export const ASSEMBLEIAS_CONSULT_CURRENT = "ASSEMBLEIAS_CONSULT_CURRENT";
export const LOAD_FILTER_FORM_ASSEMBLEIA = "LOAD_FILTER_FORM_ASSEMBLEIA";
export const LOAD_FORM_ASSEMBLEIA = "LOAD_FORM_ASSEMBLEIA";
export const SAVE_FORM_ASSEMBLEIA_ERRORS = "SAVE_FORM_ASSEMBLEIA_ERRORS";
export const ORDENS_ASSEMBLEIA_FETCHED = "ORDENS_ASSEMBLEIA_FETCHED";
export const INVESTIDORES_ASSEMBLEIA_FETCHED = "INVESTIDORES_ASSEMBLEIA_FETCHED";
export const RESUMO_VOTOS_ASSEMBLEIA_FETCHED = "RESUMO_VOTOS_ASSEMBLEIA_FETCHED";
export const CUSTODIANTES_ASSEMBLEIA_FETCHED = "CUSTODIANTES_ASSEMBLEIA_FETCHED";
export const LOAD_MODAL_ORDENS_ASSEMBLEIA = "LOAD_MODAL_ORDENS_ASSEMBLEIA";
export const LOAD_VOTOS_INVESTIDORES_ASSEMBLEIA = "LOAD_VOTOS_INVESTIDORES_ASSEMBLEIA";
export const CHANGE_LIST_OPERACOES = "CHANGE_LIST_OPERACOES";

const ASSEMBLEIA_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_ASSEMBLEIA_API;

const PATRIMONIO_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PATRIMONIO_API;


// FILTER ASSEMBLEIA (TELA INICIAL)

export function getAssembleias(filters, page = 1) {
  const payload = deserializeFieldsAssembleiaFormFilter(filters, page);

  return (dispatch) => {
    dispatch(changeMessage("Carregando Assembleias"))
    return axios.post(`${ASSEMBLEIA_API}buscar`, payload).then((result) =>
      dispatch([
        {
          type: ASSEMBLEIAS_FETCHED,
          payload: { result },
        },
        {
          type: ASSEMBLEIAS_CONSULT_CURRENT,
          payload: {
            ...filters,
            pagingConfig: { pageIndex: page, pageSize: 10 },
          },
        },
      ])
    );
  };
}

export function loadFilterFormAssembleia() {
  return (dispatch) => {
    dispatch(changeMessage("Carregando Filtros da Assembleia"))
    return axios.get(`${ASSEMBLEIA_API}tela/filtro`).then((result) =>
      dispatch({
        type: LOAD_FILTER_FORM_ASSEMBLEIA,
        payload: result.content,
      })
    );
  };
}

export function exportAssembleiasToExcel(filters) {
  const payload = deserializeFieldsAssembleiaFormFilter(filters);

  return (dispatch) => {
    dispatch(changeMessage("Baixando Lista de Assembleias"))
    return axios
      .post(`${ASSEMBLEIA_API}exportar/excel`, payload)
      .then((result) => {
        if (result.content) {
          const { nomeArquivo, extensaoArquivo, arquivo } = result.content;
          dispatch([
            downloadExcelFile(arquivo, `${nomeArquivo}.${extensaoArquivo}`),
            showAllMessages(result.messages),
          ]);
        } else {
          dispatch([showAllMessages(result.messages)]);
        }
      });
  };
}

// ASSEMBLEIA (FORM)

export function novaAssembleia() {
  return (dispatch) => {
    return dispatch([
      history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${NOVO_ROUTE_PATH}`),
    ]);
  };
}

export function loadFormAssembleia(idAssembleia = '') {
  let url = ASSEMBLEIA_API + `tela/${idAssembleia}`;

  return (dispatch) => {
    if (idAssembleia) dispatch(changeMessage("Carregando Assembleia"))
    return axios.get(url).then((result) => {
      if (idAssembleia && !result?.content?.assembleia) {
        dispatch([
          msgError("Assembleia não encontrada!", false),
          backToList(),
        ])
        return false
      }

      dispatch([
        {
          type: LOAD_FORM_ASSEMBLEIA,
          payload: result.content,
        },
        serializeFieldsAssembleia(result.content),
      ])
      
      return result.content;
    });
  };
}

export function saveAssembleia(assembleia) {
  const assembleiaDeserialized = deserializeFieldsAssembleia(assembleia);

  return (dispatch) => {
    dispatch(changeMessage("Salvando Assembleia"))
    dispatch([
      {
        type: SAVE_FORM_ASSEMBLEIA_ERRORS,
        payload: null,
      },
    ]);
    
    return axios
      .post(ASSEMBLEIA_API, assembleiaDeserialized)
      .then((result) => {
        return dispatch([
          result?.content ? history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/${result?.content}`) : null,
          showAllMessages(result.messages),
        ])
      })
      .catch((err) => {
        if (err && err.data?.messages && err.data?.messages?.hasError) {
          document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" });
          dispatch([
            {
              type: SAVE_FORM_ASSEMBLEIA_ERRORS,
              payload: err.data?.messages,
            },
          ]);
        }
      });
  };
}

export function editAssembleia(assembleia) {
  const assembleiaDeserialized = deserializeFieldsAssembleia(assembleia);

  return (dispatch) => {
    dispatch(changeMessage("Salvando Assembleia"))
    dispatch([
      {
        type: SAVE_FORM_ASSEMBLEIA_ERRORS,
        payload: null,
      },
    ]);
    
    return axios
      .put(ASSEMBLEIA_API, assembleiaDeserialized)
      .then((result) => {
        return dispatch([
          backToList(),
          showAllMessages(result.messages),
        ])
      })
      .catch((err) => {
        if (err && err.data?.messages && err.data?.messages?.hasError) {
          document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" });
          dispatch([
            {
              type: SAVE_FORM_ASSEMBLEIA_ERRORS,
              payload: err.data?.messages,
            },
          ]);
        }
      });
  };
}

export function editSuspenderAssembleia(payload) {
  return (dispatch) => {
    dispatch(changeMessage("Suspendendo Assembleia"))
    return axios.put(`${ASSEMBLEIA_API}suspender`, payload).then((result) =>
      dispatch([
        showAllMessages(result.messages),
        backToList(),
      ])
    );
  };
}

// CUSTODIANTES

export function getCustodiantesAssembleia(idAssembleia, page = 1) {
  const payload = {
    idAssembleia,
    pagingConfig: { pageIndex: page, pageSize: 5 }
  }

  return (dispatch) => {
    dispatch(changeMessage("Carregando Custodiantes"))
    return axios.post(`${ASSEMBLEIA_API}${idAssembleia}/custodia/buscar`, payload).then((result) => 
      dispatch([
        {
          type: CUSTODIANTES_ASSEMBLEIA_FETCHED,
          payload: result.content,
        },
      ])
    );
  };
}
export function importCustodiantesAssembleia(file, idAssembleia) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("idAssembleia", idAssembleia);

  return dispatch => {
      dispatch(changeMessage("Enviando Custodiantes"))
      return axios
          .post(`${ASSEMBLEIA_API}${idAssembleia}/custodia/importar`, formData)
          .then(result => {
              dispatch([
                showAllMessages(result.messages),
              ])
          });
  };
}

export function exportModeloCustodiantesAssembleia(idAssembleia) {
  return (dispatch) => {
      dispatch(changeMessage("Baixando Modelo de Custodiantes"))
      return axios
          .get(`${ASSEMBLEIA_API}${idAssembleia}/custodia/exportar/excel/modelo`)
          .then(result => {
              const fileName = 'Planilha_Posicao_Custodia.xlsx';
              dispatch([
                  downloadExcelFile(result.content, fileName),
                  showAllMessages(result.messages),
              ])
          }); 

  }
}

export function exportCustodiantesAssembleiaToExcel(idAssembleia) {
  return (dispatch) => {
    dispatch(changeMessage("Baixando Lista de Custodiantes"))
    return axios
      .post(`${ASSEMBLEIA_API}${idAssembleia}/custodia/exportar/excel`, { idAssembleia })
      .then((result) => {
        if (result.content) {
          const { nomeArquivo, extensaoArquivo, arquivo } = result.content;
          dispatch([
            downloadExcelFile(arquivo, `${nomeArquivo}.${extensaoArquivo}`),
            showAllMessages(result.messages),
          ]);
        } else {
          dispatch([showAllMessages(result.messages)]);
        }
      });
  };
}

// RESUMO VOTOS

export function getResumoVotosAssembleia(idAssembleia, page = 1) {
  const payload = {
    idAssembleia,
    pagingConfig: { pageIndex: page, pageSize: 5 }
  }

  return (dispatch) => {
    dispatch(changeMessage("Carregando Resumo dos Votos"))
    return axios.post(`${ASSEMBLEIA_API}${idAssembleia}/investidor/resumo/votos`, payload).then((result) => 
      dispatch([
        {
          type: RESUMO_VOTOS_ASSEMBLEIA_FETCHED,
          payload: result.content,
        },
      ])
    );
  };
}

// INVESTIDORES

export function getInvestidoresAssembleia(idAssembleia, page = 1) {
  const payload = {
    idAssembleia,
    pagingConfig: { pageIndex: page, pageSize: 5 }
  }

  return (dispatch) => {
    dispatch(changeMessage("Carregando Investidores"))
    return axios.post(`${ASSEMBLEIA_API}${idAssembleia}/investidor/buscar`, payload).then((result) => 
      dispatch([
        {
          type: INVESTIDORES_ASSEMBLEIA_FETCHED,
          payload: result.content,
        },
      ])
    );
  };
}

export const changeInvestidorCheck = (idAssembleia, type, idItem) => {
  return async () => {
    return await axios.post(`${ASSEMBLEIA_API}${idAssembleia}/investidor/tipo/${type}/${idItem}`); 
  };
};

export function importInvestidoresAssembleia(file, idAssembleia) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("idAssembleia", idAssembleia);

  return dispatch => {
      dispatch(changeMessage("Enviando Investidores"))
      return axios
          .post(`${ASSEMBLEIA_API}${idAssembleia}/investidor/importar`, formData)
          .then(result => {
              dispatch([
                showAllMessages(result.messages),
              ])
          });
  };
}

export function importInvestidoresVotoAssembleia(file, idAssembleia) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("idAssembleia", idAssembleia);

  return dispatch => {
      dispatch(changeMessage("Enviando Investidores"))
      return axios
          .post(`${ASSEMBLEIA_API}${idAssembleia}/investidor/importar/voto`, formData)
          .then(result => {
              dispatch([
                showAllMessages(result.messages),
              ])
          });
  };
}

export function exportModeloInvestidoresAssembleia(idAssembleia) {
  return (dispatch) => {
      dispatch(changeMessage("Baixando Modelo de Investidores"))
      return axios
          .get(`${ASSEMBLEIA_API}${idAssembleia}/investidor/exportar/excel/modelo`)
          .then(result => {
              const fileName = 'Planilha_Posicao_Investidores.xlsx';
              dispatch([
                  downloadExcelFile(result.content, fileName),
                  showAllMessages(result.messages),
              ])
          }); 

  }
}

export function exportModeloInvestidoresVotoAssembleia(idAssembleia) {
  return (dispatch) => {
      dispatch(changeMessage("Baixando Modelo de Investidores"))
      return axios
          .get(`${ASSEMBLEIA_API}${idAssembleia}/investidor/exportar/excel/modelo/voto`)
          .then(result => {
              const fileName = 'Planilha_Voto_Investidores.xlsx';
              dispatch([
                  downloadExcelFile(result.content, fileName),
                  showAllMessages(result.messages),
              ])
          }); 

  }
}

export function exportInvestidoresAssembleiaToExcel(idAssembleia) {
  return (dispatch) => {
    dispatch(changeMessage("Baixando Lista de Investidores"))
    return axios
      .post(`${ASSEMBLEIA_API}${idAssembleia}/investidor/exportar/excel`, { idAssembleia })
      .then((result) => {
        if (result.content) {
          const { nomeArquivo, extensaoArquivo, arquivo } = result.content;
          dispatch([
            downloadExcelFile(arquivo, `${nomeArquivo}.${extensaoArquivo}`),
            showAllMessages(result.messages),
          ]);
        } else {
          dispatch([showAllMessages(result.messages)]);
        }
      });
  };
}

export function loadVotosInvestidorAssembleia(idAssembleia, idInvestidor) {
  return (dispatch) => {
    dispatch(changeMessage("Carregando Dados"))
    return axios.get(`${ASSEMBLEIA_API}${idAssembleia}/investidor/${idInvestidor}/votos`).then((result) =>
      dispatch({
        type: LOAD_VOTOS_INVESTIDORES_ASSEMBLEIA,
        payload: result.content,
      })
    );
  };
}

export function editVotosInvestidorAssembleia(idAssembleia, idInvestidor, payload) {
  return (dispatch) => {
    dispatch(changeMessage("Salvando Dados"))
    return axios.put(`${ASSEMBLEIA_API}${idAssembleia}/investidor/${idInvestidor}/votos`, payload).then((result) =>
      dispatch([
        showAllMessages(result.messages),
      ])
    );
  };
}

// ORDENS

export function getOrdensAssembleia(idAssembleia, page = 1) {
  const payload = {
    idAssembleia,
    pagingConfig: { pageIndex: page, pageSize: 5 }
  }

  return (dispatch) => {
    dispatch(changeMessage("Carregando Ordens"))
    return axios.post(`${ASSEMBLEIA_API}ordem/buscar`, payload).then((result) => 
      dispatch([
        {
          type: ORDENS_ASSEMBLEIA_FETCHED,
          payload: result.content,
        },
      ])
    );
  };
}

export function getModalOrdem(id) {
  return (dispatch) => {
    dispatch(changeMessage("Carregando Ordem"))
    return axios.get(`${ASSEMBLEIA_API}ordem/tela/${id || ''}`).then((result) => 
      dispatch([
        {
          type: LOAD_MODAL_ORDENS_ASSEMBLEIA,
          payload: result.content,
        },
      ])
    );
  };
}

export function saveOrdemAssembleia(payload) {
  return (dispatch) => {
    dispatch(changeMessage("Salvando Ordem"))
    return axios.post(`${ASSEMBLEIA_API}ordem`, payload).then((result) => 
      dispatch([
        showAllMessages(result.messages),
      ])
    );
  };
}

export function editOrdemAssembleia(payload) {
  return (dispatch) => {
    dispatch(changeMessage("Salvando Ordem"))
    return axios.put(`${ASSEMBLEIA_API}ordem`, payload).then((result) => 
      dispatch([
        showAllMessages(result.messages),
      ])
    );
  };
}

export function deleteOrdemAssembleia(idAssembleia) {
  return (dispatch) => {
    dispatch(changeMessage("Excluindo Ordem"))
    return axios.delete(`${ASSEMBLEIA_API}ordem/${idAssembleia}`).then((result) => 
      dispatch([
        showAllMessages(result.messages),
      ])
    );
  };
}

export function exportOrdensAssembleiaToExcel(idAssembleia) {
  return (dispatch) => {
    dispatch(changeMessage("Baixando Ordens"))
    return axios
      .post(`${ASSEMBLEIA_API}ordem/exportar/excel`, { idAssembleia })
      .then((result) => {
        if (result.content) {
          const { nomeArquivo, extensaoArquivo, arquivo } = result.content;
          dispatch([
            downloadExcelFile(arquivo, `${nomeArquivo}.${extensaoArquivo}`),
            showAllMessages(result.messages),
          ]);
        } else {
          dispatch([showAllMessages(result.messages)]);
        }
      });
  };
}

// DOCUMENTOS

export function downloadAnexoAssembleia(file) {
  return (dispatch) => {
    dispatch(changeMessage("Baixando Arquivo"))
    return axios
      .get(`${ASSEMBLEIA_API}documentos/${file.idDocumento}/download`)
      .then((result) => {
        const { content, messages } = result
        
        if (content) {
          const fileName = `${file.nomeDocumento}.${file.formato}`
          dispatch([
            downloadFile(content, fileName),
          ]);
        }

        dispatch([
          showAllMessages(messages),
        ]);
      });
  };
}

// HELPERS

export function loadOperacoes(idPS) {
  return (dispatch) => {
      dispatch([
          {
            type: CHANGE_LIST_OPERACOES,
            payload: [],
          },
      ])

      dispatch(changeMessage("Carregando Lista de Operações"))

      return axios
          .get(`${PATRIMONIO_API}${idPS}/operacoes`)
          .then(result => {
              dispatch([
                  {
                    type: CHANGE_LIST_OPERACOES,
                    payload: result?.content || [],
                  },
              ])
          });
  }
}

export function mudaDependenteOnChange(dependente, value = null) {
  return (dispatch, getState) => {
    return new Promise(resolve => {
      dispatch(change("AssembleiaForm", dependente, value));
      resolve();
    });
  };
}

export function AddToArrayOnChange(dependente, value) {
  return (dispatch) => {
    dispatch(arrayPush("AssembleiaForm", dependente, value));
  };
}

export function removeToArrayOnChange(dependente, index) {
  return (dispatch) => {
    dispatch(arrayRemove("AssembleiaForm", dependente, index));
  };
}

export function backToList() {
  return (dispatch) => {
    return dispatch([
      history.push(`/${ASSEMBLEIA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
    ]);
  };
}
