import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContainerPage from "../../../../common/container/ContainerPage";

import {
  // loadFilterFormTipoRelatorio,
  // getPendingReports,
  // clearReport,
  // clearSelecteds,
} from "../../../../../actions/relatorios/regulatorioAction";
import RegulatorioList from "./list/RegulatorioList";
import RegulatorioFilter from "./form/RegulatorioFilter";

let RegulatorioPage = (props) => {
  return (
    <ContainerPage>
      <RegulatorioFilter />
      <RegulatorioList />
    </ContainerPage>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // loadFilterFormTipoRelatorio,
      // getPendingReports,
      // clearReport,
      // clearSelecteds,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(RegulatorioPage);
