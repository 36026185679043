import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getSubclassesCota,
  loadFormSubclasse,
} from "../../../../../../actions/subclasse/SubclasseAction";

import PaginationRB from "../../../../../common/pagination/PaginationRB";
import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../common/table/TableEmptyData";
import { SUBCLASSE } from "../../../../../../utils/actionsAndFeaturesAuth";
import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import TableActionWithRouteAuthorization from "../../../../../common/table/TableActionWithRouteAuthorization";
import { convertCurrencyNumber } from "../../../../../../utils/helpers/NumberHelper";

class SubclasseList extends Component {
  getIds = () => {
    let idsOperacao = 0;

    if (Array.isArray(this.props.operacoes) && this.props.operacoes.length) {
      idsOperacao = this.props.operacoes.map((o) => o.key);
    }

    return idsOperacao;
  };

  updateData = () => {
    this.props.getSubclassesCota(this.getIds());
  };

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.operacoes.length !== this.props.operacoes.length) {
      this.updateData();
    }
  }

  render() {
    const { operacoes, subclassesData, messages, getSubclassesCota, loadFormSubclasse } = this.props;

    const idsOperacoes = operacoes.map((o) => o.key);
    const emptyData = messages && messages.infos && messages.infos[0];

    const tableHeader = (
      <tr>
        <th className="text-center fw-700">Fundo</th>
        <th className="text-center fw-700">Classe</th>
        <th className="text-center fw-700">Subclasse</th>
        <th className="text-center fw-700">Data Emissão</th>
        <th className="text-center fw-700">Total Distribuído</th>
        <th className="text-center fw-700">Status</th>
        <th className="text-center"></th>
      </tr>
    );

    const tableContent =
      subclassesData && subclassesData.hasItems ? (
        subclassesData.items.map((subclasse) => (
          <tr key={subclasse.idSubClasse}>
            <td className="text-center fw-400">
              {subclasse.fundo || "--"}
            </td>
            <td className="text-center fw-400">
              {subclasse.classe || "--"}
            </td>
            <td className="text-center fw-400">
              {`${subclasse?.subClasse.value || '--'} ${subclasse?.codigoSubClasse}` || ""}
            </td>
            <td className="text-center fw-400">
              {getDateFormatedFromString(subclasse.dataEmissao) || "--"}
            </td>
            <td className="text-center fw-400">
              {convertCurrencyNumber(subclasse.totalDistribuido, "R$", 2)}
            </td>
            <td className="text-center fw-400">
              {subclasse?.status.value || "--"}
            </td>
            <TableActionWithRouteAuthorization
              space="2"
              feature={SUBCLASSE}
              viewAction={() => loadFormSubclasse(subclasse.idSubClasse)}
              updateAction={() => loadFormSubclasse(subclasse.idSubClasse, true)}
            />
          </tr>
        ))
      ) : (
        <TableEmptyData message={emptyData} />
      );

    return (
      <div>
        {subclassesData.totalCount > 0 && (
          <div className="d-flex flex-column">
            <div className="mx-2 shadow-custom bg-white rounded-12">
              <p className="page-head__title f-28 mb-0 px-3 mt-4 mb-2">
                Subclasses
              </p>
              <div className="mx-2">
                <Table
                  withoutHeader
                  classes="card col-12 shadow-none bg-white py-0 m-0 mb-2 rounded-12"
                  totalItems={subclassesData.totalCount}
                  pagination={
                    <PaginationRB
                      pageNumber={subclassesData.pageIndex - 1}
                      itemsPerPage={subclassesData.pageSize}
                      totalItems={subclassesData.totalCount}
                      filter={idsOperacoes}
                      fetchFunction={getSubclassesCota}
                      pageRange={5}
                    />
                  }
                >
                  <TableHeader>{tableHeader}</TableHeader>
                  <TableContent>{tableContent}</TableContent>
                </Table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subclassesData: state.subclasseReducer.tabSubclassesCotas,
  messages: state.complementoReducer.messages,
  operacoes: state.operacaoReducer.selectedOperacoes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getSubclassesCota,
      loadFormSubclasse
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(SubclasseList)
);
