import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field, formValueSelector } from "redux-form";

import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";
import Input from "../../../../../common/input/Input";
import Button from "../../../../../common/button/Button";

import OperacaoPrecificacaoPrecoPicker from "../../../../pickers/OperacaoPrecificacaoPrecoPicker";

import { getPrecos } from "../../../../../../actions/precificacao/PrecificacaoAction";

let PrecoListFilter = (props) => {
  const { getPrecos, operacao, dataEventoInicial, dataEventoFinal } = props;

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Preço"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getPrecos}
        >
          <div className="row">
            <div className="col-lg-12 mb-4">
              <div className="d-flex flex-column">
                <label className="f-12 text-dark mb-1">Busca</label>
                <Field
                  component={OperacaoPrecificacaoPrecoPicker}
                  name="operacao"
                />
              </div>
            </div>
            <div className="col-lg-3">
              <Field
                component={Input}
                inputId="dataEventoInicial"
                type="date"
                name="dataEventoInicial"
                label="Data Início"
                readOnly={false}
              />
            </div>
            <div className="col-lg-3">
              <Field
                component={Input}
                inputId="dataEventoFinal"
                type="date"
                name="dataEventoFinal"
                label="Data Fim"
                readOnly={false}
              />
            </div>
            <div className="col-lg-12 ml-3">
              <div className="row">
                <Button
                  action={() =>
                    getPrecos({ operacao, dataEventoInicial, dataEventoFinal })
                  }
                  classes="p-bg-imp rounded"
                  icon={"search"}
                  name={"Buscar"}
                />
              </div>
            </div>
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

PrecoListFilter = reduxForm({ form: "precoForm" })(PrecoListFilter);
const selector = formValueSelector("precoForm");

const mapStateToProps = (state) => ({
  operacao: selector(state, "operacao"),
  dataEventoInicial: selector(state, "dataEventoInicial"),
  dataEventoFinal: selector(state, "dataEventoFinal"),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPrecos,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PrecoListFilter);
