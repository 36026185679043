import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';

import { saveUsuario, cleanResult, loadFilterFormUsuario, getContasUser } from '../../../../actions/usuario/UsuarioAction';

import UsuarioPerfilForm from './form/UsuarioPerfilForm';

class UsuarioIncludePage extends Component {
    componentDidMount() {
        this.props.cleanResult();
        this.props.loadFilterFormUsuario();
        this.props.getContasUser();
    }

    render() {
        return (
            <UsuarioPerfilForm
                title={"Nova - Associação Usuário X Perfil"}
                onSubmit={this.props.saveUsuario}
                readOnly={false}
                floatButtonVisible={true}
            />
        );
    }
}

const mapDispacthToProps = dispatch => bindActionCreators({
    saveUsuario,
    cleanResult,
    loadFilterFormUsuario,
    getContasUser
}, dispatch);

export default withRouter(connect(null, mapDispacthToProps)(UsuarioIncludePage));