import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import { reiniciarPagamento } from "../../../../../../actions/precificacao/PagamentoAction";
import { getDateNormalInvertedFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";

let ModalReiniciarFechamento = (props) => {
  const { pagamento, close, open, reiniciarPagamento } = props;

  return (
    <>
      <Dialog open={open} onClose={() => close()}>
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Reiniciar Evento de Pagamento
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-3 text-dark f-16 fw-400">
            <p>
              {`Você está prestes a reiniciar o evento de pagamento do 
              dia ${pagamento.dataPagamento} para a operação ${pagamento.codigoOpea}
              (Código IF: ${pagamento.codigoIF}).`}
            </p>
            <p>
              Com isso, todos os dados inseridos no sistema até hoje serão
              desconsiderados e apagados.{" "}
              <span className="text-danger font-weight-bold">
                Esta ação é irreversível.
              </span>
            </p>
            <p className="mb-0">Tem certeza que deseja continuar?</p>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Não, Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Sim, Continuar"}
              action={() => {
                reiniciarPagamento({
                  idOperacao: pagamento?.idOperacao,
                  idPagamentoPrecificacao: pagamento?.idPagamentoPrecificacao,
                  dataPagamento: getDateNormalInvertedFormatedFromString(
                    pagamento?.dataPagamento
                  ),
                });

                close();
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      reiniciarPagamento,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ModalReiniciarFechamento);
