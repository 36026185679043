/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "../../../../../common/button/Button";
import Table from "../../../../../common/table/Table";
import TableContent from "../../../../../common/table/TableContent";
import TableHeader from "../../../../../common/table/TableHeader";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { toastr } from "react-redux-toastr";
import ModalSolicitarAgendamento from "../modals/ModalSolicitarAgendamento";
import { validacaoEmail } from "../../../../../../utils/helpers/EmailHelper";
import { exportaArquivoBancoLiquidante } from "../../../../../../actions/precificacao/PagamentoAction";
import ModalPossuiRecurso from "../modals/ModalPossuiRecurso";
import { CSSTransition, TransitionGroup } from "react-transition-group";

let SectionEtapaAgendamento = (props) => {
  const { pagamento, exportaArquivoBancoLiquidante } = props;

  const [openSolicitaAgendamento, setOpenSolicitaAgentamento] = React.useState(false);
  const [emailBanco, setEmailBanco] = React.useState("");
  const [bancoLiquidanteEmails, setBancoLiquidanteEmails] = React.useState([]);
  const [possuiRecurso, setPossuiRecurso] = React.useState("");
  const [openPossuiRecurso, setOpenPossuiRecurso] = React.useState(false);

  const statusId = pagamento?.ultimaEtapa?.id;
  const bancos = pagamento?.agendamento?.bancos;
  const saldoDisponivel = pagamento?.saldoDisponivel;
  const possuiRecursoPgmt = pagamento?.possuiRecurso;

  React.useEffect(() => {
    setPossuiRecurso(pagamento?.possuiRecurso ? 'Sim' : pagamento?.possuiRecurso === false ? 'Nao' : '');
  }, [pagamento?.possuiRecurso]);

  React.useEffect(() => {
    if (pagamento?.bancoLiquidanteEmails?.length > 0) {
      setBancoLiquidanteEmails([...pagamento?.bancoLiquidanteEmails]);
    }
  }, [pagamento?.bancoLiquidanteEmails]);

  // React.useEffect(() => {
  //   localStorage.setItem("emailsBL", bancoLiquidanteEmails)
  // }, [bancoLiquidanteEmails]);

  function checaTipoBanco(nome) {
    return (nome.toLowerCase().includes("liquidante") || nome.toLowerCase().includes("liquidação"));
  }

  return (
    <div className="card-body shadow-custom bg-white rounded-12 col-12 mt-4">
      <h4 className="page-head__title h3 mx-0 mt-0 mb-3 f-22 font-weight-bold">
        Etapa - Agendamento
      </h4>

      {!saldoDisponivel && statusId == 5 && (
        <div className="rounded-8 w-100 px-3 py-2 bg-bege-wrng mb-2">
          <p className="m-0 fw-400 f-14">
            Não foi informado que o saldo está disponível em conta para este
            evento de pagamento.
          </p>
        </div>
      )}

      {bancos?.length > 0 && (
        <div className="row px-0">
          <Table
            withoutHeader
            classesOuter="w-100"
            classes="shadow-none border-0 col-12 m-0 pd-10 bg-white"
          >
            <TableHeader>
              <tr>
                <th className="text-center text-nowrap">BANCO</th>
                <th className="text-center text-nowrap">AGÊNCIA</th>
                <th className="text-center text-nowrap">CONTA</th>
                <th className="text-center text-nowrap">TIPO</th>
                <th className="text-center text-nowrap">STATUS SINC.</th>
                <th className="text-center text-nowrap">DESCRIÇÃO</th>
                <th className="text-center text-nowrap"></th>
              </tr>
            </TableHeader>
            <TableContent>
              {bancos.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center fw-400 text-nowrap">
                      {item.banco || "--"}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.agencia || "--"}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.conta || "--"}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.tipo.value || "--"}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.statusSincronizacao.value || "--"}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.descricao || "--"}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {checaTipoBanco(item.tipo.value) && (
                        <Button
                          name="Baixar Arquivo"
                          icon="download f-14-imp"
                          action={() => {
                            exportaArquivoBancoLiquidante({
                              tipoArquivo: "xlsx",
                              idArquivo: item?.idArquivo,
                              codigoOpea: pagamento?.codigoOpea,
                              dataPagamento: pagamento?.dataPagamento,
                              idPrecificacao: pagamento?.idPrecificacao,
                              idPagamentoPrecificacao:
                                pagamento?.idPagamentoPrecificacao,
                            });
                          }}
                          classes="btn-out-df-imp rounded btn-search h-22 f-12 shadown-none px-2 py-1 m-0 text-capitalize fw-500"
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </TableContent>
          </Table>
        </div>
      )}

      {statusId == 5 && (
        <>
          <p className="page-head__title mt-3 mb-1">
            E-mail do Banco Liquidante
          </p>
          <div className="row align-items-center">
            <div className="col-xl-6 col-12">
              <div className="form-group field-floating has-float-label">
                <input
                  type="email"
                  name="emailBanco"
                  value={emailBanco}
                  className="form-control"
                  onChange={(e) => setEmailBanco(e.target.value)}
                />
                <label htmlFor="emailBanco" className="control-label">
                  E-mail
                </label>
              </div>
            </div>
            <div className="col-xl-2 col-4 pl-xl-0">
              <Button
                name={"Adicionar"}
                nameClass="f-14 text-capitalize fw-500"
                action={() => {
                  if (validacaoEmail(emailBanco, bancoLiquidanteEmails)) {
                    setEmailBanco("");
                    setBancoLiquidanteEmails([
                      ...bancoLiquidanteEmails,
                      emailBanco,
                    ]);
                  }
                }}
                classes="bg-white brd-p-color-imp d-flex align-items-center p-color-imp rounded btn-search h-38 px-3 m-0 mt-xl-2 shadow-none mb-xl-0 mb-2"
              />
            </div>
          </div>
        </>
      )}

      {statusId >= 6 && (
        <p className="page-head__title mt-3 mb-2">E-mails Enviados</p>
      )}

      {statusId >= 5 && (
        <div className="row px-3">
          <div className="d-flex flex-wrap mb-2">
            <TransitionGroup className={"d-flex flex-wrap"}>
              {bancoLiquidanteEmails.map((email, index) => {
                return (
                  <CSSTransition key={index} timeout={300} classNames="item">
                    <div
                      key={index}
                      className="mr-2 rounded-pill d-flex flex-row text-nowrap px-2 py-1 bg-df-gray align-items-center mb-2"
                    >
                      <label className="p-color-imp m-0 f-12">{email}</label>
                      {statusId == 5 && (
                        <i
                          role="button"
                          className="fa fa-trash text-danger ml-2"
                          onClick={() => {
                            bancoLiquidanteEmails.splice(index, 1);
                            setBancoLiquidanteEmails([...bancoLiquidanteEmails]);
                          }}
                        />
                      )}
                    </div>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </div>
        </div>
      )}

      <p className="page-head__title mt-2 mb-1">Transferência de Recursos</p>
      {statusId == 5 && (
        <>
          <div className="row px-3">
            <div className="d-flex flex-lg-row flex-column align-items-lg-center justify-content-lg-between w-100">
              <p className="m-0 fw-400">
                Transferência realizada para a conta centralizadora/liquidação?
              </p>
              <RadioGroup
                row
                name="possuiRecurso"
                value={possuiRecurso}
                onChange={(e) => setPossuiRecurso(e.target.value)}
              >
                <FormControlLabel
                  value="Sim"
                  label="Sim"
                  className="my-0"
                  control={ <Radio checkedIcon={<CheckCircle style={{ color: "#83BF74" }} /> } /> }
                />
                <FormControlLabel
                  value="Nao"
                  label="Não"
                  className="m-0"
                  control={ <Radio checkedIcon={<CheckCircle style={{ color: "#83BF74" }} />} /> }
                />
              </RadioGroup>
            </div>
          </div>

          <div className="row px-3 d-flex justify-content-lg-end mt-3">
            <Button
              name={"Solicitar Agendamento"}
              // prettier-ignore
              action={() => {
                if (bancoLiquidanteEmails.length <= 0) {
                  toastr.error("Erro", "Adicione e-mail(s) antes de solicitar")
                } else if (possuiRecurso === "") {
                  toastr.error("Erro", "Defina se a transferência está realizada ou não para a conta de liquidação antes de solicitar")
                } else {
                  setOpenSolicitaAgentamento(true);
                }
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
            <ModalSolicitarAgendamento
              open={openSolicitaAgendamento}
              emails={bancoLiquidanteEmails}
              close={() => setOpenSolicitaAgentamento(false)}
              possuiRecurso={possuiRecurso === "Sim" ? true : false}
            />
          </div>
        </>
      )}

      {statusId >= 6 && (
        <div className="row px-3">
          <div className="d-flex flex-column align-items-start justify-content-start w-100">
            <p className="m-0 fw-400 txt-grey">
              Transferência realizada para a conta centralizadora/liquidação?
            </p>
            <div className="d-flex flex-row align-items-center mt-2">
              <div className="mr-2 fw-400">
                <span>{possuiRecursoPgmt ? "Sim" : "Não"}</span>
              </div>
              {!possuiRecursoPgmt && (
                <div>
                  <span
                    role="button"
                    className="text-underline txt-blue-link"
                    onClick={() => setOpenPossuiRecurso(true)}
                  >
                    Alterar
                  </span>
                  <ModalPossuiRecurso
                    open={openPossuiRecurso}
                    close={() => setOpenPossuiRecurso(false)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      exportaArquivoBancoLiquidante,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(SectionEtapaAgendamento);
