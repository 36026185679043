/* eslint eqeqeq: 0 */

import { FUNDO_TAB, SUBCLASSE_TAB } from "../../../../utils/tabs";
import {
  OPERACAO_ROUTE_PATH,
  DASHBOARD_ROUTE_PATH,
  PATRIMONIO_ROUTE_PATH,
  PASSIVO_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  ATIVO_OPERACAO_ROUTE_PATH,
  COMPLEMENTO_ROUTE_PATH,
  FUNDO_ROUTE_PATH,
  SUBCLASSE_ROUTE_PATH,
  DESPESA_ROUTE_PATH,
  RECEITA_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

export const DASHBOARD_TAB = "DASHBOARD";
export const PASSIVO_TAB = "PASSIVO";
export const PATRIMONIO_TAB = "PATRIMONIO";
export const ATIVO_TAB = "ATIVO";
export const COMPLEMENTO_TAB = "COMPLEMENTO";
export const COTA_TAB = "COTA";
export const DESPESAS_TAB = "DESPESA";
export const RECEITAS_TAB = "RECEITA";

const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");
const gestora = currEnv == process.env.REACT_APP_ENV_GEST_ID;

export const OP_TABS = [
  {
    pathRoute: `/${OPERACAO_ROUTE_PATH}/${DASHBOARD_ROUTE_PATH}`,
    target: DASHBOARD_TAB,
    label: DASHBOARD_TAB,
  },
  {
    pathRoute: `/${OPERACAO_ROUTE_PATH}/${PATRIMONIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    target: PATRIMONIO_TAB,
    label: "PATRIMÔNIO SEPARADO",
  },
  {
    pathRoute: `/${OPERACAO_ROUTE_PATH}/${PASSIVO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    target: PASSIVO_TAB,
    label: PASSIVO_TAB,
  },
  {
    pathRoute: `/${OPERACAO_ROUTE_PATH}/${DESPESA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    target: DESPESAS_TAB,
    label: DESPESAS_TAB,
  },
  {
    pathRoute: `/${OPERACAO_ROUTE_PATH}/${RECEITA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    target: RECEITAS_TAB,
    label: RECEITAS_TAB,
  },
  {
    pathRoute: `/${OPERACAO_ROUTE_PATH}/${ATIVO_OPERACAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    target: ATIVO_TAB,
    label: ATIVO_TAB,
  },
  {
    pathRoute: `/${OPERACAO_ROUTE_PATH}/${COMPLEMENTO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    target: COMPLEMENTO_TAB,
    label: COMPLEMENTO_TAB,
  },
];

export const CT_TABS = [
  {
    pathRoute: `/${OPERACAO_ROUTE_PATH}/${FUNDO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    target: FUNDO_TAB,
    label: FUNDO_TAB,
  },
  {
    pathRoute: `/${OPERACAO_ROUTE_PATH}/${SUBCLASSE_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    target: SUBCLASSE_TAB,
    label: SUBCLASSE_TAB,
  },
  {
    pathRoute: `/${OPERACAO_ROUTE_PATH}/${PASSIVO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    target: PASSIVO_TAB,
    label: COTA_TAB,
  },
  {
    pathRoute: `/${OPERACAO_ROUTE_PATH}/${COMPLEMENTO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`,
    target: COMPLEMENTO_TAB,
    label: COMPLEMENTO_TAB,
  },
];

export const OPERACAO_TABS = gestora ? CT_TABS : OP_TABS;
