/**
 * @description Retorna um booleano de acordo com o valor passado no parâmetro
 * @param {String} object valor.
 */
export function getBooleanValue(object) {
  if (object === "true" || object === "True" || object === "1" || object === 1)
    return true;
  return false;
}

/**
 * @description Retorna Sim ou Não caso os valores sejam boolean.
 * @param {Object} object object
 */
export function getStringBooleanValue(object) {
  if (
    object === true ||
    object === "true" ||
    object === "True" ||
    object === "1" ||
    object === 1
  )
    return "Sim";
  else if (
    object === false ||
    object === "false" ||
    object === "False" ||
    object === "0" ||
    object === 0
  )
    return "Não";
  return object;
}

/**
 * @description Retorna true ou false de strings.
 * @param {String} value valor
 */
export function getBooleanValueFromString(value) {
  if (value === true || value === false) return value;
  
  let result = false;
  
  if (value && 
    ((value = value.trim()) === "Sim" ||
      value === "sim" ||
      value === "S"   ||
      value === "s"   ||
      value === 1
  )) {
    result = true;
  } else if(value === "Selecione..." || (value === null || value === 'undefined')) {
    result = null;
  }

  return result;
}

/**
 * @description Retorna true ou false de strings.
 * @param {String} value valor
 */
export function getBooleanValueFromStringNormal(value) {
  let result = false;
  
  if (value && 
    ((value = value.trim()) === "Sim" ||
      value === "sim" ||
      value === "S"   ||
      value === "s"   ||
      value === 1
  )) {
    result = true;
  }

  return result;
}
  
