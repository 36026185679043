import { initialize } from "redux-form";
import { getBooleanValueFromString, getStringBooleanValue } from "../../../../utils/helpers/BooleanHelper";
import { converterParaFormatoEUA, getDateFormatedFromString } from "../../../../utils/helpers/DateTimeHelper";
import { convertCurrency, convertCurrencyNumber, convertDecimalNormalized, newFormatPercent } from "../../../../utils/helpers/NumberHelper";

export function deserializeFieldsFilterForm(filters, page) {
  const { idGestor, idRelatorio, dataReferencia, idsPatrimonioSeparado } =
    filters;

  return {
    ...filters,
    idGestor: idGestor?.id || undefined,
    idRelatorio: idRelatorio?.key || undefined,
    dataReferencia: dataReferencia ? `${dataReferencia}-01` : undefined,
    idsPatrimonioSeparado:
      idsPatrimonioSeparado?.map((item) => item.key) || undefined,
    pagingConfig: {
      pageIndex: page,
      pageSize: 50,
    },
  };
}

export function serializeFieldsRegulatorio(content) {
  const {
    id,
    dataUltimoStatus,
    descricao,
    observacao,
    possuiDerivativos,
    possuiAjusteManual,
    dataCompetencia,
    usuarioGestor,
    usuarioServicoCredito,
    status,
    patrimonioSeparado,
    series: seriesList,
    relatorioRegulatorioCaracteristica: B_GERAL,
    relatorioRegulatorioSerie: B_SERIE,
    relatorioRegulatorioBalanco: B_BALANCO,
    relatorioRegulatorioCredito: B_CREDITO,
    relatorioRegulatorioDerivativo: B_DERIVATIVO,
    relatorioRegulatorioDesembolso: B_DESEMBOLSO,
    relatorioRegulatorioFluxoDeCaixa: B_FLUXO_CAIXA,
    relatorioRegulatorioOutrosDados: B_OUTROS_DADOS,
  } = content?.relatorioRegulatorio;

  let geral = {};
  let series = {};
  let outrosDados = {};

  const transformObj = (arrObj, key) => 
    arrObj.map(item => ({
      ...item,
      serie: item.numeroSerie,
      valor: item[key]
    }));

  const transformRead = (arrObj, key, formatType) => 
    arrObj.map(({ numeroSerie, [key]: value }) => {
      switch (formatType) {
        case 'currency':
          return `${numeroSerie}ª - ${value ? convertCurrency(value, 2) : ""}`;
        case 'quantity':
          return `${numeroSerie}ª - ${value ? convertCurrencyNumber(value, false, 0) : ""}`;
        case 'percent':
          return `${numeroSerie}ª - ${value ? newFormatPercent(value, 2) : ""}`;
        case 'date':
          return `${numeroSerie}ª - ${getDateFormatedFromString(value, 2) || ""}`;
        case 'serie':
          return `${numeroSerie}`;
        default:
          return `${numeroSerie}ª - ${value || ""}`;
      }
    }).join(' / ');

  const valorCertificadosObj = transformObj(seriesList, 'valorCertificados')
  const valorCertificadosRead = transformRead(seriesList, 'valorCertificados', 'currency')
  
  const rendimentoDistribuidoObj = transformObj(seriesList, 'rendimentoDistribuido')
  const rendimentoDistribuidoRead = transformRead(seriesList, 'rendimentoDistribuido', 'currency')
  
  const amortizacaoRealizadaObj = transformObj(seriesList, 'amortizacaoRealizada')
  const amortizacaoRealizadaRead = transformRead(seriesList, 'amortizacaoRealizada', 'currency')
  
  const indiceSubordinacaoMinimoObj = transformObj(seriesList, 'indiceSubordinacaoMinimo')
  const indiceSubordinacaoMinimoRead = transformRead(seriesList, 'indiceSubordinacaoMinimo', 'currency')
  
  const indiceSubordinacaoDataBaseObj = transformObj(seriesList, 'indiceSubordinacaoDataBase')
  const indiceSubordinacaoDataBaseRead = transformRead(seriesList, 'indiceSubordinacaoDataBase', 'currency')

  const rentabilidadeNoPeriodoRead = transformRead(seriesList, 'rentabilidadeNoPeriodo', 'percent')
  const classificacaoRiscoRead = transformRead(seriesList, 'classificacaoRisco')

  if (content?.relatorioRegulatorio) {
    geral = {
      ...B_GERAL,
      revolvencia: getStringBooleanValue(B_GERAL?.revolvencia),
      dataEmissao: B_GERAL?.dataEmissao ? getDateFormatedFromString(B_GERAL.dataEmissao) : "",
      tipoOferta: transformRead(seriesList, 'tipoOferta'),
      codigoNegociacaoMercado: transformRead(seriesList, 'codigoNegociacaoMercado'),
      codigoISIN: transformRead(seriesList, 'codigoISIN'),
      dataVencimento: transformRead(seriesList, 'dataVencimento', 'date'),
      situacao: transformRead(seriesList, 'situacao'),
      valorTotalIntegralizado: transformRead(seriesList, 'valorTotalIntegralizado', 'currency'),
      classe: transformRead(seriesList, 'classe'),
      numeroSerie: transformRead(seriesList, false, 'serie'),
      taxaJuros: transformRead(seriesList, 'taxaJuros'),
      periodicidade: transformRead(seriesList, 'periodicidade'),
    };
    
    series = {
      ...B_SERIE,
      classe: transformRead(seriesList, 'classe'),
      numeroSerie: transformRead(seriesList, false, 'serie'),
      quantidadeCertificados: transformRead(seriesList, 'quantidadeCertificados', 'quantity'),

      valorCertificadosObj,
      valorCertificadosRead,
      
      rendimentoDistribuidoObj,
      rendimentoDistribuidoRead,
      
      amortizacaoRealizadaObj,
      amortizacaoRealizadaRead,
      
      indiceSubordinacaoMinimoObj,
      indiceSubordinacaoMinimoRead,
      
      indiceSubordinacaoDataBaseObj,
      indiceSubordinacaoDataBaseRead,

      rentabilidadeNoPeriodoRead,
      classificacaoRiscoRead,
    };
    
    outrosDados = {
      ...B_OUTROS_DADOS,
      dataDaUltimaClassificacao: B_OUTROS_DADOS?.dataDaUltimaClassificacao ? getDateFormatedFromString(B_OUTROS_DADOS.dataDaUltimaClassificacao) : "",
    };
  }

  let serializeRegulatorio = {
    id,
    dataUltimoStatus,
    descricao,
    observacao,
    possuiDerivativos,
    possuiAjusteManual,
    dataCompetencia,
    usuarioGestor,
    usuarioServicoCredito,
    status,
    patrimonioSeparado,
    // 
    geral,
    series,
    balanco: B_BALANCO,
    credito: B_CREDITO,
    derivativo: B_DERIVATIVO,
    desembolso: B_DESEMBOLSO,
    fluxoCaixa: B_FLUXO_CAIXA,
    outrosDados,
    seriesList
  };

  return initialize("RegulatorioForm", serializeRegulatorio);
}

export function deserializeFieldsRegulatorio(regulatorio) {
  const {
    geral,
    seriesList,
    balanco,
    credito,
    derivativo,
    desembolso,
    fluxoCaixa,
    outrosDados,
    ...restRegulatorio
  } = regulatorio;

  const normalizedCurrencyString = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key,
        typeof value === 'string' && value.includes('R$') ? convertDecimalNormalized(value) : value
      ])
    );
  }

  const cleanCurrencyFields = (obj) => {
    return Object.keys(obj).reduce((acc, key) => ({
      ...acc,
      [key]: typeof obj[key] === 'string' && obj[key].includes('R$')
        ? convertDecimalNormalized(obj[key])
        : obj[key]
    }), {});
  }

  const {
    // CRA - CURRENCY
    producaoDeProdutosAgropecuarios,
    comercializacaoDeProdutosAgropecuarios,
    beneficiamentoDeProdutosAgropecuarios,
    industrializacaoDeProdutosAgropecuarios,
    producaoDeInsumosAgropecuarios,
    comercializacaoDeInsumosAgropecuarios,
    beneficiamentoDeInsumosAgropecuarios,
    industrializacaoDeInsumosAgropecuarios,
    producaoDeMaquinasEImplementos,
    comercializacaoDeMaquinasEImplementos,
    beneficiamentoDeMaquinasEImplementos,
    industrializacaoDeMaquinasEImplementos,
    // CRI - CURRENCY
    incorporacaoImobiliaria,
    alugueis,
    aquisiscaoDeImoveis,
    loteamento,
    multipropriedade,
    homeEquity,
    outros,
    // CURRENCY
    valorTotalDasParcelasEmAtraso,
    aVencerPorNatureza,
    aVencerPorPrazo,
    aVencerAte30Dias,
    aVencerDe31a60Dias,
    aVencerDe61a90Dias,
    aVencerDe91a120Dias,
    aVencerDe121a150Dias,
    aVencerDe151a180Dias,
    aVencerDe181a360Dias,
    aVencerAcimaDe361Dias,
    vencidosENaoPagos,
    vencidosAte30Dias,
    vencidosDe31a60Dias,
    vencidosDe61a90Dias,
    vencidosDe91a120Dias,
    vencidosDe121a150Dias,
    vencidosDe151a180Dias,
    vencidosDe181a360Dias,
    vencidosAcimaDe361Dias,
    prePagamentosNoPeriodo,
    montanteRecebidoNoPeriodo,
    valorDividasAdquiridas,
    // DROPDOWN
    periodicidadeDeAvaliacaoDasGarantis,
  } = credito

  const creditoCurrency = {
    // CRA
    producaoDeProdutosAgropecuarios,
    comercializacaoDeProdutosAgropecuarios,
    beneficiamentoDeProdutosAgropecuarios,
    industrializacaoDeProdutosAgropecuarios,
    producaoDeInsumosAgropecuarios,
    comercializacaoDeInsumosAgropecuarios,
    beneficiamentoDeInsumosAgropecuarios,
    industrializacaoDeInsumosAgropecuarios,
    producaoDeMaquinasEImplementos,
    comercializacaoDeMaquinasEImplementos,
    beneficiamentoDeMaquinasEImplementos,
    industrializacaoDeMaquinasEImplementos,
    // CRI
    incorporacaoImobiliaria,
    alugueis,
    aquisiscaoDeImoveis,
    loteamento,
    multipropriedade,
    homeEquity,
    outros,
    // 
    valorTotalDasParcelasEmAtraso,
    aVencerPorNatureza,
    aVencerPorPrazo,
    aVencerAte30Dias,
    aVencerDe31a60Dias,
    aVencerDe61a90Dias,
    aVencerDe91a120Dias,
    aVencerDe121a150Dias,
    aVencerDe151a180Dias,
    aVencerDe181a360Dias,
    aVencerAcimaDe361Dias,
    vencidosENaoPagos,
    vencidosAte30Dias,
    vencidosDe31a60Dias,
    vencidosDe61a90Dias,
    vencidosDe91a120Dias,
    vencidosDe121a150Dias,
    vencidosDe151a180Dias,
    vencidosDe181a360Dias,
    vencidosAcimaDe361Dias,
    prePagamentosNoPeriodo,
    montanteRecebidoNoPeriodo,
    valorDividasAdquiridas,
  }

  let deserializeRegulatorio = {
    ...restRegulatorio,
    relatorioRegulatorioCaracteristica: {
      ...geral,
      revolvencia: getBooleanValueFromString(geral?.revolvencia),
      dataEmissao: geral?.dataEmissao ? converterParaFormatoEUA(geral.dataEmissao) : "",
    },
    relatorioRegulatorioBalanco: normalizedCurrencyString(balanco),
    relatorioRegulatorioCredito: {
      ...credito,
      ...normalizedCurrencyString(creditoCurrency),
      periodicidadeDeAvaliacaoDasGarantis: periodicidadeDeAvaliacaoDasGarantis?.value || periodicidadeDeAvaliacaoDasGarantis || null,
    },
    relatorioRegulatorioDerivativo: normalizedCurrencyString(derivativo),
    relatorioRegulatorioDesembolso: normalizedCurrencyString(desembolso),
    relatorioRegulatorioFluxoDeCaixa: normalizedCurrencyString(fluxoCaixa),
    relatorioRegulatorioOutrosDados: {
      ...outrosDados,
      dataDaUltimaClassificacao: outrosDados?.dataDaUltimaClassificacao ? converterParaFormatoEUA(outrosDados.dataDaUltimaClassificacao) : "",
    },
    series: seriesList.map((item) => (cleanCurrencyFields(item))), 
  }

  return deserializeRegulatorio;
}
