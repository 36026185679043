import React from 'react';
import { Link } from 'react-router-dom';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        color:"#FFF !important",
    },
    root: {
        flexGrow: 1,
        width: '100%',
    },
    tabRoot: {
        color: "#000000",
        '&:hover': {
            color: 'inherit',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#630A38',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#630A38',
            outline: "none"
        }
    },
    tabSelected: {
        color: '#fff',
        fontWeight: theme.typography.fontWeightMedium,
    },
});
class TabHeaderWithRouter extends React.Component {
    state = {
        tabs: {},
        scrollButtons: "auto",
        activeTab: 0,
        paramsRoute: []
    };

    constructor(props) {
        super(props);
        this.tabsRef = React.createRef();
        this.state.tabs = props.tabs;
        this.state.paramsRoute = props.paramsRoute;
        this.state.activeTab = this.checkedTabActive(props.tabs);
    }

    checkedTabActive(tabs) {
        let location = window.location.pathname.split("/");
        const active = tabs.filter((tab) => {
            let target = tab.target.toLowerCase();
            return location.includes(target) ? tab : null;
        })

        return active && active.length ? tabs.indexOf(active[0]) : 0;
    }

    render() {
        const { tabs, activeTab, paramsRoute } = this.state;
        const { classes } = this.props;

        return (
                    <Tabs
                        className={`mb-3 mx-2 ${this.props.className}`}
                        TabIndicatorProps={{ style: { background: this.props.indicatorColor || '#630A37' } }}
                        value={activeTab}
                        variant="scrollable"
                        scrollButtons="off"
                        onChange={(ev, idx) => this.setState({ activeTab: idx })}
                    >
                        {(tabs).map((tab, index) => (
                            <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} key={index} label={tab.label} component={Link} to={`${tab.pathRoute}/${paramsRoute ? paramsRoute : ''}`} />
                        ))}
                    </Tabs>
            // <div id="myTab" className="mb-3">
            //     <AppBar position="static" style={{ backgroundColor: "#FAFAFA" }}>
            //     </AppBar>
            // </div>
        );

    }
}


export default withStyles(styles)(TabHeaderWithRouter);
