import React from 'react';

const TableEmptyData = (props) => {
    return (
        <tr style={ { display: 'visible' } }>
            <td colSpan='999' className='text-center'>
                <span>
                    {props.message}
                </span>
            </td>
        </tr>
    );
}

export default TableEmptyData;