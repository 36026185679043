/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PatrimonioContasVinculadas from "./PatrimonioContasVinculadas";

import { loadFormPatrimonioContas } from '../../../../../../actions/patrimonio/PatrimonioAction';

const PatrimonioFormContasVinculadas = (props) => {
  const {
    readOnly,
    patrimonioContas,
    patrimonioId,
    loadFormPatrimonioContas,
  } = props;

  React.useEffect(() => {
    if (patrimonioId && patrimonioContas?.length <= 0) {
      getContas();
    }
  }, [])

  function getContas (page = 1) {
    loadFormPatrimonioContas(patrimonioId, page);
  }
  
  let patrimonioCodigoB3Cetip = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4 mb-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">Contas Vinculadas ao Patrimônio Separado</span>
        <div>
          <PatrimonioContasVinculadas
            readOnly={readOnly}
            getContas={getContas}
            dataList={patrimonioContas}
          />
        </div>    
      </div>
    </div>
  );

  return (
    <div className="d-flex flex-column mt-4">
      {patrimonioCodigoB3Cetip}
    </div>
  );
}

const mapStateToProps = state => ({
  patrimonioId: state.patrimonioReducer.patrimonioFormTypes?.patrimonioSeparado?.id || null,
  patrimonioContas: state.patrimonioReducer?.contas,
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
  loadFormPatrimonioContas
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PatrimonioFormContasVinculadas);
