//Actions Routes
export const LISTA_ROUTE_PATH = "lista";
export const NOVO_ROUTE_PATH = "novo";
export const EDITAR_ROUTE_PATH = "editar";
export const DETALHAR_ROUTE_PATH = "detalhar";
export const VINCULAR_ROUTE_PATH = "vincular";
export const CALCULO_ROUTE_PATH = "calculo";
export const CALCULADORA_ROUTE_PATH = "calculadora";
export const PRECO_ROUTE_PATH = "preco";
export const AGENDA_ROUTE_PATH = "agenda";
export const INDICE_ROUTE_PATH = "indice";
export const OPCAO_ROUTE_PATH = "opcoes";
export const MOEDA_ROUTE_PATH = "moeda";
export const TIPO_APROVACAO_ROUTE_PATH = "tipo-aprovacao";
export const PRODUTO_ROUTE_PATH = "produto";
export const MOVIMENTACAO_FINANCEIRA_ROUTE_PATH = "movimentacao-financeira";
export const NATUREZA_ROUTE_PATH = "natureza";
export const BANCOS_ROUTE_PATH = "banco-externo";
export const ASSEMBLEIA_ASSUNTO_ROUTE_PATH = "assembleia/assunto";
export const RECEITA_PRODUTO_ROUTE_PATH = "receita/produto";
export const SELO_ROUTE_PATH = "selo";
export const PAGAMENTO_ROUTE_PATH = "pagamento";
export const FLUXO_ROUTE_PATH = "fluxo";
export const GERENCIA_ROUTE_PATH = "gerenciais";
export const ANALITICO_ROUTE_PATH = "analitico";
export const REGULATORIO_ROUTE_PATH = "regulatorio";
export const GERENCIAR_ROUTE_PATH = "gerenciar";
export const RESUMO_ROUTE_PATH = "resumo";
export const PARTICIPANTE_ROUTE_PATH = "participante";
export const DOCUMENTO_ROUTE_PATH = "documento";
export const AUDIT_ROUTE_PATH = "audit";
export const ERRO_ROUTE_PATH = "erro";
export const LOG_ROUTE_PATH = "log";
export const DOMINIO_ROUTE_PATH = "dominio";

//Module Routes
export const OPERACAO_ROUTE_PATH = "operacao";
export const ATIVO_ROUTE_PATH = "ativo";
export const PEDIDOS_ROUTE_PATH = "pedidos";
export const APROVACAO_ROUTE_PATH = "aprovacao";
export const APROVADORES_ROUTE_PATH = "aprovadores";
export const EXPORTACAO_ROUTE_PATH = "exportacao";
export const EXPORTACAO_CONTAS_ROUTE_PATH = "contas";
export const CONTAS_RECEBER_ROUTE_PATH = "contas/receber";
export const REMESSA_ROUTE_PATH = "remessa";
export const PATRIMONIO_ROUTE_PATH = "patrimonio";
export const EMPRESA_PRESTADORA_ROUTE_PATH = "prestadora";
export const USUARIO_ROUTE_PATH = "usuario";
export const DASHBOARD_ROUTE_PATH = "dashboard";
export const PASSIVO_ROUTE_PATH = "passivo";
export const ATIVO_OPERACAO_ROUTE_PATH = "ativo";
export const PATRIMONIO_OPERACAO_ROUTE_PATH = "patrimonio";
export const DESPESA_ROUTE_PATH = "despesa";
export const RECEITA_ROUTE_PATH = "receita/contrato";
export const COMPLEMENTO_ROUTE_PATH = "complemento";
export const PRECIFICACAO_ROUTE_PATH = "precificacao";
export const OPCAO_SISTEMA_ROUTE_PATH = "sistema";
export const AGING_ROUTE_PATH = "aging";
export const JOBS_ROUTE_PATH = "job";
export const RELATORIOS_ROUTE_PATH = "relatorios";
export const DIARIO_BORDO_ROUTE_PATH = "diariobordo";
export const ASSEMBLEIA_ROUTE_PATH = "assembleia";
export const ACESSO_PORTAL_ROUTE_PATH = "acessoPortal";
export const FUNDO_ROUTE_PATH = "fundo";
export const SUBCLASSE_ROUTE_PATH = "subclasse";
export const CARTEIRA_ROUTE_PATH = "carteira";
export const COTA_ROUTE_PATH = "cota";
export const HISTORICO_CARTEIRA_ROUTE_PATH = "historicocarteira";
export const HISTORICO_COTAS_ROUTE_PATH = "historicocotas";
export const MONITORAMENTO_ROUTE_PATH = "monitoramento";

// EXTERNALS
export const AF_ROUTE_PATH = "af";

const subdominio = process.env.REACT_APP_URL_SUBDOMINIO;
export const SUBDOMINIO = subdominio !== false ? subdominio : undefined;
