import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../common/table/TableEmptyData";
import PaginationRB from "../../../../../common/pagination/PaginationRB";
import FloatButton from "../../../../../common/button/FloatButton";

import {
  getIndices,
  showIndiceForm,
  showIndiceDeleteDialog,
  removeIndice,
} from "../../../../../../actions/precificacao/IndiceAction";

import { formatDecimal } from "../../../../../../utils/helpers/NumberHelper";
import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import If from "../../../../../common/If/If";

const IndiceList = (props) => {
  const {
    getIndices,
    showIndiceForm,
    indiceData,
    messages,
    tipoTaxa,
    dataInicio,
  } = props;

  const emptyData = messages && messages.infos && messages.infos[0];

  const tableContent =
    indiceData && indiceData.hasItems ? (
      indiceData.items.map((item, index) => (
        <tr key={index}>
          <td className="text-center fw-400 text-nowrap">{item.diaUtil}</td>
          <td className="text-center fw-400 text-nowrap">
            {getDateFormatedFromString(item.dataDiaUtil)}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {formatDecimal(item.valorTaxaDiasUteis, 2)}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {formatDecimal(item.valorTaxaCorridos, 2)}
          </td>
        </tr>
      ))
    ) : (
      <TableEmptyData message={emptyData} />
    );

  return (
    <div className="w-100 px-4 mx-2 mt-2">
      <If test={indiceData.totalCount > 0}>
        <Table
          withoutHeader
          classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
          totalItems={indiceData.totalCount}
          pagination={
            <PaginationRB
              pageNumber={indiceData.pageIndex - 1}
              itemsPerPage={indiceData.pageSize}
              totalItems={indiceData.totalCount}
              filter={{ tipoTaxa, dataInicio }}
              fetchFunction={getIndices}
              pageRange={5}
            />
          }
        >
          <TableHeader>
            <tr>
              <th className="text-center fw-700 text-nowrap" rowSpan={2}>
                Dia Útil
              </th>
              <th className="text-center fw-700 text-nowrap" rowSpan={2}>
                Data dia Útil
              </th>
              <th className="text-center fw-700 text-nowrap" colSpan={2}>
                DI x Pré
              </th>
            </tr>
            <tr>
              <th className="text-center fw-700 text-nowrap">
                252<sup>(2)(4)</sup>
              </th>
              <th className="text-center fw-700 text-nowrap">
                360<sup>(1)</sup>
              </th>
            </tr>
          </TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      </If>
      <FloatButton
        activeClassName={"float"}
        title="Novo"
        icon={"fa fa-plus my-float"}
        action={() => showIndiceForm()}
      />
    </div>
  );
};

const selector = formValueSelector("indiceForm");

const mapStateToProps = (state) => ({
  indiceData: state.indiceReducer.indicesDiFuturo,
  messages: state.indiceReducer.messages,
  dataInicio: selector(state, "dataInicio"),
  tipoTaxa: selector(state, "taxa"),
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getIndices,
      showIndiceForm,
      showIndiceDeleteDialog,
      removeIndice,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(IndiceList);
