import axios from "axios";
import { formValueSelector, change, reset } from "redux-form";

import { showConfirmDialog } from "../common/modal/ConfirmDialogAction";

import history from "../../components/common/routers/history";

import {
    deserializeFieldsUsuarioFormFilter,
    serializeFieldsUsuarioForm,
    deserializeFieldsUsuarioPerfilForm,
} from "../../components/business/usuario/mappings/UsuarioMap";

import { showAllMessages } from "../message/ErrorMessagesAPIAction";

import {
    NOVO_ROUTE_PATH,
    USUARIO_ROUTE_PATH,
    LISTA_ROUTE_PATH,
    EDITAR_ROUTE_PATH,
    DETALHAR_ROUTE_PATH
} from "../../components/common/routers/constantesRoutes";

const USUARIO_PERFIL_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_USUARIO_PERFIL_API;

export const LOAD_FILTER_FORM_USUARIO_PERFIL = "LOAD_FILTER_FORM_USUARIO_PERFIL";
export const USUARIOS_FETCHED = "USUARIOS_FETCHED";
export const CLEAN_LIST_CONTENT = "CLEAN_LIST_CONTENT";
export const LOAD_FORM_USUARIO = "LOAD_FORM_USUARIO";
export const USUARIO_SHOW_DELETE_DIALOG = "USUARIO_SHOW_DELETE_DIALOG";
export const LOGGED_ACCOUNTS = "LOGGED_ACCOUNTS";


export function loadFilterFormUsuario() {
    return dispatch => {
        return axios.get(`${USUARIO_PERFIL_API}tela`)
            .then(result =>
                dispatch({
                    type: LOAD_FILTER_FORM_USUARIO_PERFIL,
                    payload: result.content
                })
            );
    };
}

export function getUsuarios(filters, page = 1) {
    const params = deserializeFieldsUsuarioFormFilter(filters, page);

    return (dispatch) => {
        return axios
            .post(`${USUARIO_PERFIL_API}usuarios`, { ...params })
            .then(result =>
                dispatch({
                    type: USUARIOS_FETCHED,
                    payload: { result },
                })
            );
    }
}

export function cleanResult() {
    return dispatch => {
        return dispatch([
            reset('usuarioFilterForm'),
            { type: CLEAN_LIST_CONTENT }
        ]);
    }
}

export function showForm() {
    return dispatch => {
        return dispatch([
            loadFormUsuario(),
            history.push(`/${USUARIO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`)
        ]);
    };
}

export function loadFormUsuario(idUsuarioPerfil, editAction) {
    let userLogged = JSON.parse(localStorage.getItem('userLogged')) ?? localStorage.getItem('userLogged');

    return dispatch => {
        return axios.get(USUARIO_PERFIL_API + `tela/${idUsuarioPerfil || ''}`)
            .then(result => {
                let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;
                dispatch([
                    idUsuarioPerfil ? history.push(`/${USUARIO_ROUTE_PATH}/${routeAction}/${idUsuarioPerfil}`) : undefined,
                    idUsuarioPerfil ? changeConta(idUsuarioPerfil, userLogged.contas, true) : undefined,
                    serializeFieldsUsuarioForm(result.content.usuarioPerfil)
                ])
            });
    };
}

export function saveUsuario(usuarioPerfil) {
    const params = deserializeFieldsUsuarioPerfilForm(usuarioPerfil);
    return (dispatch) => {
        return axios
            .post(USUARIO_PERFIL_API, { ...params })
            .then(result =>
                dispatch([
                    showUsuarioList(),
                    showAllMessages(result.messages),
                ])
            );
    }
}

export function getContasUser() {
    return (dispatch) => {
        return dispatch([
            {
                type: LOGGED_ACCOUNTS,
                payload: JSON.parse(localStorage.getItem('userLogged')) ?? localStorage.getItem('userLogged'),
            },
        ])
    }
}

export function showUsuarioList(filters = {}) {
    return dispatch => {
        return dispatch([
            cleanResult(),
            showConfirmDialog(false),
            history.push(`/${USUARIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)
        ]);
    };
}

export function editUsuario(usuarioPerfil) {
    const params = deserializeFieldsUsuarioPerfilForm(usuarioPerfil);

    return (dispatch) => {
        return axios
            .put(USUARIO_PERFIL_API, { ...params })
            .then(result =>
                dispatch([
                    showUsuarioList(),
                    showAllMessages(result.messages),
                ])
            );
    }
}

export function showUsuarioPerfilDeleteDialog(showDialog, idUsuario) {
    return (dispatch) => {
        return dispatch([
            {
                type: USUARIO_SHOW_DELETE_DIALOG,
                payload: { showDialog, idUsuario }
            },
            history.push(`/${USUARIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)
        ])
    }
}

export function onChangeConta(checked, id) {
    return (dispatch, getState) => {
        const selector = formValueSelector('usuarioPerfilForm');
        const userLogged = selector(getState(), 'userLogged') || [];

        const exist = userLogged.findIndex(item => item?.id === id);

        exist !== -1 ? (userLogged[exist].checked = checked) : userLogged.push({ id, checked });

        return dispatch(change('usuarioPerfilForm', 'userLogged', userLogged))
    }
}

export function onChangeUsuario(usuario, perfilList) {
    return (dispatch) => {
        return axios.get(USUARIO_PERFIL_API + `buscarperfis/${usuario.id || ''}`)
            .then(result => {
                let perfisDoUsuario = result.content.length > 0 ? result.content : [];

                const newPerfilList = mergePerfilListComListaDePerfisDoUsuario(perfilList, perfisDoUsuario);

                return dispatch(change('usuarioPerfilForm', 'perfilList', newPerfilList));
            });
    }
}

export function changeConta(usuario, contasList, fromEdit) {
    return (dispatch) => {
        return axios.get(USUARIO_PERFIL_API + `tela/${!fromEdit ? usuario.id : usuario || ''}`)
        .then(result => {
            let contasDoUsuario = result.content ? result.content.contaList : [];

            const newContasList = contasList.map((contaLogged) => {
                let item = contasDoUsuario.find((conta) => conta.id === contaLogged.id && conta.checked);

                if (item) item.checked = true;
                
                return item;
            })

            return dispatch(change('usuarioPerfilForm', 'userLogged', newContasList));
        });
    }
}

export function mergePerfilListComListaDePerfisDoUsuario(perfilList, perfisDoUsuario) {
    const newPerfilList = perfilList.map(perfil => {
        let item = perfisDoUsuario.find(perfilDoUsuario => perfilDoUsuario.idPerfil === perfil.id);

        if (item) {
            item.checked = true;
        } else {
            item = {
                idUsuarioPerfil: null,
                idPerfil: perfil.id,
                checked: false
            }
        }

        return item;
    });

    return newPerfilList;
}

export function removerPerfisDoUsuario(idUsuario) {
    return (dispatch) => {
        return axios
            .delete(USUARIO_PERFIL_API + idUsuario)
            .then(result =>
                dispatch([
                    showUsuarioPerfilDeleteDialog(false),
                    showAllMessages(result.messages),
                    showUsuarioList(),
                ])
            );
    }
}
