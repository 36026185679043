import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import { formatStrToCnpjOrCpf } from "../../../../../utils/helpers/StringHelper";
import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import TableEmptyData from "../../../../common/table/TableEmptyData";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";
import FloatButton from "../../../../common/button/FloatButton";

import {
  getPrestadoras,
  showPrestadoraDeleteDialog,
  showPrestadoraForm,
  removePrestadora,
  validateDeleteDialogMessage,
  cleanSearch,
  exportPrestadoraExcel,
} from "../../../../../actions/prestadora/PrestadoraAction";
import TableActionWithRouteAuthorization from "../../../../common/table/TableActionWithRouteAuthorization";
import {
  DELETE,
  FORNECEDORES_PRESTADORA,
  CREATE,
} from "../../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import If from "../../../../common/If/If";
import ReactTooltip from "react-tooltip";

const PrestadoraList = (props) => {
  const {
    getPrestadoras,
    showPrestadoraDeleteDialog,
    showDeleteDialog,
    removePrestadora,
    prestadoraData,
    validateDeleteDialogMessage,
    messages,
    cnpj,
    nomeSimplificado,
    showPrestadoraForm,
    authorizationFunction,
    pesquisou,
    cleanSearch,
    idStatus,
    exportPrestadoraExcel,
  } = props;

  const filter = {
    cnpj,
    nomeSimplificado,
    pesquisou,
    idStatus,
    page: prestadoraData.pageIndex > 0 ? prestadoraData.pageIndex : 1,
  };

  const tableHeader = (
    <tr>
      <th className="text-center fw-700 text-nowrap">CPF/CNPJ</th>
      <th className="text-center fw-700 text-nowrap">Nome simplificado</th>
      <th className="text-center fw-700 text-nowrap">Ações</th>
    </tr>
  );

  const emptyData = messages && messages.infos && messages.infos[0];

  const tableContent =
    prestadoraData && prestadoraData.hasItems ? (
      prestadoraData.items.map((prestadora, index) => (
        <tr key={index} className="tr-status">
          <td className="text-center fw-400 td-status">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <div className="mr-3">
                <div
                  data-type="light"
                  data-for={`ttp-list-${index}`}
                  data-tip={prestadora?.statusAprovacao?.value}
                  className={`circle-status sts-forn-${prestadora?.statusAprovacao?.key} rounded-circle mx-0`}
                />
                <ReactTooltip
                  noArrow
                  effect="solid"
                  id={`ttp-list-${index}`}
                  className="px-2 py-1"
                  globalEventOff="click"
                  style={{ width: "100px" }}
                />
              </div>
              {formatStrToCnpjOrCpf(prestadora.cnpj)}
            </div>
          </td>
          <td className="text-center text-nowrap fw-400">
            {prestadora.nomeSimplificado}
          </td>
          <TableActionWithRouteAuthorization
            space="2"
            feature={FORNECEDORES_PRESTADORA}
            updateAction={() => {
              cleanSearch();
              showPrestadoraForm(prestadora.id, true);
            }}
            viewAction={() => {
              cleanSearch();
              showPrestadoraForm(prestadora.id, false);
            }}
            remove={() => validateDeleteDialogMessage(prestadora.id)}
          />
        </tr>
      ))
    ) : (
      <TableEmptyData message={emptyData} />
    );

  return (
    <div className="w-100 px-4 mx-1">
      <If test={prestadoraData.totalCount > 0}>
        <Table
          withoutHeader
          files={[
            { label: 'Exportar para Excel', function: () => exportPrestadoraExcel(filter) }
          ]}        
          classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
          totalItems={prestadoraData.totalCount}
          pagination={
            <PaginationRB
              pageNumber={prestadoraData.pageIndex - 1}
              itemsPerPage={prestadoraData.pageSize}
              totalItems={prestadoraData.totalCount}
              filter={{ cnpj, nomeSimplificado, idStatus }}
              fetchFunction={getPrestadoras}
              pageRange={5}
            />
          }
        >
          <TableHeader>{tableHeader}</TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      </If>
      <FloatButton
        activeClassName={"float"}
        title="Novo"
        icon={"fa fa-plus my-float"}
        action={() =>
          authorizationFunction(
            { idAcao: CREATE, idFuncionalidade: FORNECEDORES_PRESTADORA },
            () => showPrestadoraForm(null)
          )
        }
      />
      <ConfirmDialog
        title="Aviso"
        contentText={showDeleteDialog.message}
        textButtonCancel="CANCELAR"
        textButtonConfirm="CONFIRMAR"
        showDialog={showDeleteDialog.showDialog}
        onClose={() => showPrestadoraDeleteDialog(false)}
        onConfirm={() =>
          authorizationFunction(
            { idAcao: DELETE, idFuncionalidade: FORNECEDORES_PRESTADORA },
            () => removePrestadora(showDeleteDialog.idPrestadora, filter)
          )
        }
        type="warning"
        maxWidth="md"
      />
    </div>
  );
};

const selector = formValueSelector("prestadorFilterForm");
const mapStateToProps = (state) => ({
  cnpj: selector(state, "cnpj"),
  nomeSimplificado: selector(state, "nomeSimplificado"),
  idStatus: selector(state, "idStatus"),
  pesquisou: selector(state, "pesquisou"),
  showDeleteDialog: state.prestadoraReducer.showPrestadoraDeleteDialog,
  prestadoraData: state.prestadoraReducer.prestadoras,
  messages: state.prestadoraReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getPrestadoras,
      removePrestadora,
      showPrestadoraDeleteDialog,
      showPrestadoraForm,
      authorizationFunction,
      validateDeleteDialogMessage,
      cleanSearch,
      exportPrestadoraExcel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(PrestadoraList);
