import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";

import { 
    OPERACAO_ROUTE_PATH,
    DASHBOARD_ROUTE_PATH,
    PASSIVO_ROUTE_PATH,
    PATRIMONIO_ROUTE_PATH,
    ATIVO_OPERACAO_ROUTE_PATH,
    VINCULAR_ROUTE_PATH,
    COMPLEMENTO_ROUTE_PATH,
    LISTA_ROUTE_PATH,
    NOVO_ROUTE_PATH,
    DETALHAR_ROUTE_PATH,
    EDITAR_ROUTE_PATH,
    FUNDO_ROUTE_PATH,
    SUBCLASSE_ROUTE_PATH,
    DESPESA_ROUTE_PATH,
    RECEITA_ROUTE_PATH,
} from '../../../common/routers/constantesRoutes';

import PassivoPage from '../../operacao/pages/passivo/PassivoPage';
import PatrimonioPage from '../../operacao/pages/passivo/PatrimonioPage';
import DashboardPage from '../../operacao/pages/dashboard/DashboardPage';
import PassivoIncludePage from '../../operacao/pages/passivo/PassivoIncludePage';
import PassivoUpdatePage from '../../operacao/pages/passivo/PassivoUpdatePage';
import PassivoVisualizePage from '../../operacao/pages/passivo/PassivoVisualizePage';
import AtivoPage from '../../operacao/pages/ativo/AtivoPage';
import AtivoVinculoOperacaoPage from '../../operacao/pages/ativo/AtivoVinculoOperacaoPage';
import ComplementoPage from '../../operacao/pages/complemento/ComplementoPage';
import ComplementoIncludePage from '../../operacao/pages/complemento/ComplementoIncludePage';
import ComplementoUpdatePage from '../../operacao/pages/complemento/ComplementoUpdatePage';
import ComplementoVisualizePage from '../../operacao/pages/complemento/ComplementoVisualizePage';

import DespesaPage from '../../operacao/pages/despesa/DespesaPage';
import DespesaIncludePage from '../../operacao/pages/despesa/DespesaIncludePage';
import DespesaUpdatePage from '../../operacao/pages/despesa/DespesaUpdatePage';
import DespesaVisualizePage from '../../operacao/pages/despesa/DespesaVisualizePage';

import FundoPage from '../pages/fundo/FundoPage';
import SubclassePage from '../pages/subclasse/SubclassePage';

import ReceitaVisualizePage from '../pages/receita/ReceitaVisualizePage';
import ReceitaUpdatePage from '../pages/receita/ReceitaUpdatePage';
import ReceitaIncludePage from '../pages/receita/ReceitaIncludePage';
import ReceitaPage from '../pages/receita/ReceitaPage';

export default () => (
    <Switch>
        {/* <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}`} component={OperacaoPage} /> */}
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}`}>
            <Redirect to={`/${OPERACAO_ROUTE_PATH}/${PASSIVO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} />
        </Route>
        
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${DASHBOARD_ROUTE_PATH}/:idsOperacao?`} component={DashboardPage} />

        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${PATRIMONIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}/:idsOperacao?`} component={PatrimonioPage} />

        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${PASSIVO_ROUTE_PATH}/${LISTA_ROUTE_PATH}/:idsOperacao?`} component={PassivoPage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${PASSIVO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={PassivoIncludePage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${PASSIVO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idPassivo`} component={PassivoUpdatePage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${PASSIVO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idPassivo`} component={PassivoVisualizePage} />

        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${ATIVO_OPERACAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}/:idsOperacao?`} component={AtivoPage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${ATIVO_OPERACAO_ROUTE_PATH}/${VINCULAR_ROUTE_PATH}`} component={AtivoVinculoOperacaoPage} />
        
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${COMPLEMENTO_ROUTE_PATH}/${LISTA_ROUTE_PATH}/:idsOperacao?`} component={ComplementoPage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${COMPLEMENTO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={ComplementoIncludePage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${COMPLEMENTO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idComplemento`} component={ComplementoUpdatePage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${COMPLEMENTO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idComplemento`} component={ComplementoVisualizePage} />

        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${DESPESA_ROUTE_PATH}/${LISTA_ROUTE_PATH}/:idDespesa?`} component={DespesaPage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${DESPESA_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={DespesaIncludePage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${DESPESA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idDespesa`} component={DespesaUpdatePage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${DESPESA_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idDespesa`} component={DespesaVisualizePage} />

        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${RECEITA_ROUTE_PATH}/${LISTA_ROUTE_PATH}/:idReceita?`} component={ReceitaPage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${RECEITA_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={ReceitaIncludePage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${RECEITA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idReceita`} component={ReceitaUpdatePage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${RECEITA_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idReceita`} component={ReceitaVisualizePage} />

        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${FUNDO_ROUTE_PATH}/${LISTA_ROUTE_PATH}/:idCota?`} component={FundoPage} />
        <Route exact={true} path={`/${OPERACAO_ROUTE_PATH}/${SUBCLASSE_ROUTE_PATH}/${LISTA_ROUTE_PATH}/:idCota?`} component={SubclassePage} />
    </Switch>
);