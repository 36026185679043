import {
    COTA_FETCHED,
    COTA_CONSULT_CURRENT,
    LOAD_FILTER_FORM_COTA,
    LOAD_TELA_FLUXOS_COTA,
    RESET,
    COTA_SHOW_DELETE_DIALOG,
    FUNDOS_COTA_OPTIONS_FETCHED,
    INDICADORES_COTA_FETCHED
} from "../../actions/cota/CotaAction";

const INITIAL_STATE = {
    cota: null,
    cotaConsultCurrent: null,
    indicadores: null,
    messages: null, 
    showCotaDeleteDialog: {
        showDialog: false,
        paramsReiniciarCota: {},
    },
    isLoading: false,
    cotaFormTypes: {},
    cotaFormTypesFilter: null,
    options: null,
}

export default function (state = INITIAL_STATE, action = {}) {    
    switch(action.type) {
        case COTA_FETCHED:
            return {
                ...state,
                cota: action.payload?.content,
                messages: action.payload?.messages,
                isLoading: true,                
            }
        case INDICADORES_COTA_FETCHED:
            return {
                ...state,
                indicadores: action.payload,           
            }
        case FUNDOS_COTA_OPTIONS_FETCHED:
            return {
              ...state,
              options: action.payload,
            };
        case COTA_CONSULT_CURRENT:
            return {
                ...state,
                cotaConsultCurrent: action.payload,      
                isLoading: true,     
            }
        case LOAD_FILTER_FORM_COTA:
            return {
                ...state,
                cotaFormTypesFilter: action.payload,
            }
        case COTA_SHOW_DELETE_DIALOG:
            return {
                ...state,
                showCotaDeleteDialog: action.payload
            }
        case LOAD_TELA_FLUXOS_COTA:
            return {
                ...state,
                cotaFormTypes: action.payload,
            }
        case RESET:
            return {
                ...INITIAL_STATE
            }
        default:
            return state;
    }
}
