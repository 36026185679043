import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";

let ModalDescricaoRegra = (props) => {
  const { close, open, regra } = props;

  return (
    <>
      <Dialog
        open={open}
        maxWidth={false}
        PaperProps={{ style: { maxWidth: "max-content" } }}
        onClose={() => close()}
      >
        <div className="modal modal-dialog modal-dialog-scrollable m-0">
          <div className="modal-content">
            <div className="modal-header d-flex flex-row align-items-center justify-content-between pb-3">
              <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">Descrição da Regra</p>
              <Close
                role="button"
                className="text-danger"
                onClick={() => close()}
              />
            </div>

            <div className="modal-body text-dark f-16 fw-400 py-2">
              <p className="fw-500">{regra?.regra}</p>
              <p>{regra?.descricao}</p>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispacthToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ModalDescricaoRegra);
