import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  exportDiarioBordoToExcel,
} from "../../../../../actions/gestao/DiarioBordoAction";
import DiarioBordoListFilter from "./form/DiarioBordoListFilter";
import "./assets/DiarioBordoPage.css";
import EventoDiarioBordoListInfinity from "./list/EventoDiarioBordoListInfinity";
class DiarioBordoPage extends Component {
  render() {
    const listas = this.props.diariosBordoAno.map((obj) => obj.lista.length);
    const soma = listas.reduce((ac, comp) => ac + comp, 0);

    return (
      <section className="logbook h-100">
        <div className="row mx-0">
          <div className="col px-0">
            <DiarioBordoListFilter />
          </div>
        </div>
        <div className="row mx-0 w-100 h-30">
          <div className="col px-0 logbook__right-col">
            <EventoDiarioBordoListInfinity />
          </div>
        </div>

        {this.props.diariosBordoAno.length > 0 && (
          <div className="d-flex flex-row align-items-center justify-content-between mt-3 px-5">
            <label
              role="button"
              className="p-color-lighten mb-0"
              onClick={() => this.props.exportDiarioBordoToExcel()}
            >
              Exportar para Excel
            </label>
            <p className="text-gray mr-4 mb-0">Registros encontrados: {soma}</p>
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  diarioBordo: state.diarioBordoReducer.diariosBordo,
  diariosBordoAno: state.diarioBordoReducer.diariosBordoPorAno,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      exportDiarioBordoToExcel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DiarioBordoPage);
