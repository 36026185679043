import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ContainerPage from "../../../common/container/ContainerPage"
import PrestadoraListFilter from "./form/PrestadoraListFilter"
import PrestadoraList from './list/PrestadoraList'

import { cleanResult } from './../../../../actions/prestadora/PrestadoraAction';

class PrestadoraPage extends Component {
    componentWillUpdate(){
        this.props.cleanResult();
    }

    render() {
        return (
            <ContainerPage>
                <PrestadoraListFilter/>
                <PrestadoraList />
            </ContainerPage>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    cleanResult
}, dispatch);


export default connect(null, mapDispatchToProps)(PrestadoraPage);
