import React from "react";

export default props => (
    <div id="container-floating">
        {props.children}
        <div id="floating-button">
            <p className="plus">...</p>
            <i className="fa fa-ellipsis-v edit" aria-hidden="true" />
        </div>
    </div>
);