import React, { Component } from "react";
import { connect } from 'react-redux';
import ContainerPage from "../../../common/container/ContainerPage";
import ContasReceberListFilter from "./form/ContasReceberListFilter";
import ContasReceberList from "./list/ContasReceberList";

class ContasReceberPage extends Component {
    render() {
        return (
            <ContainerPage>
                <ContasReceberListFilter />
                <ContasReceberList />
            </ContainerPage>
        );
    }
}

export default connect(null, null)(ContasReceberPage);