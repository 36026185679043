import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContainerPage from "../../../../common/container/ContainerPage";
import SectionReportListFilter from "./form/sectionReportListFilter";
import SectionReportListDetail from "./list/sectionReportListDetail";

import { loadFilterFormTipoRelatorio, getPendingReports, clearReport, clearSelecteds } from '../../../../../actions/relatorios/reportAction';

class ViewMainRelatorios extends Component {
    async componentDidMount() {
        this.props.clearReport();
        this.props.clearSelecteds();
        await this.props.getPendingReports();
        this.props.loadFilterFormTipoRelatorio();
    }

    render(){
        return (
            <ContainerPage>
                 <SectionReportListFilter />
                 <SectionReportListDetail />
            </ContainerPage>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
    loadFilterFormTipoRelatorio,
    getPendingReports,
    clearReport,
    clearSelecteds
}, dispatch);

export default connect(null, mapDispatchToProps)(ViewMainRelatorios);