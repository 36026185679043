import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IconButton } from "material-ui";
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MoreVerIcon from '@material-ui/icons/MoreVert';

import If from "../If/If";

import { authorizationFunction } from '../../../actions/authorization/AuthorizationAction';

const PopoverButtonGroup = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(null);

    const handleMenuItemClick = (event, index, anyObject) => {
        setSelectedIndex(index);
        props.acoes(index, props.idItem, anyObject);
        handleClose();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div className={props.classNameDiv}>
            <IconButton aria-controls={open ? 'split-button-menu' : undefined}
                aria-describedby={props.idItem}
                aria-expanded={open ? 'true' : undefined}
                aria-label="ações"
                aria-haspopup="menu"
                onClick={(event) => handleClick(event)}
            >
                <If test={props.icon}> {props.icon} </If>
                <If test={!props.icon}> <MoreVerIcon /> </If>
            </IconButton>
            <Popover
                id={props.idItem}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: props.anchorOringVertical ? props.anchorOringVertial : 'bottom',
                    horizontal: props.anchorOringHorizontal ? props.anchorOringHorizontal : 'center',
                }}
                transformOrigin={{
                    vertical: props.transformOringVertical ? props.transformOringVertical : 'top',
                    horizontal: props.transformOringHorizontal ? props.transformOringHorizontal : 'center',
                }}
            >
                <MenuList id="split-button-menu">
                    {props.options.map((option, indexMenu) => (
                        <MenuItem
                            key={option.title}
                            selected={indexMenu === selectedIndex}
                            onClick={(event) => option.hasAuthentication ?
                                props.authorizationFunction(
                                    { idAcao: option.action, idFuncionalidade: option.feature },
                                    () => handleMenuItemClick(event, indexMenu, props.anyObject)
                                ) :
                                handleMenuItemClick(event, indexMenu, props.anyObject)
                            }
                        >
                            {option.title}
                        </MenuItem>
                    ))}
                </MenuList>
            </Popover>
        </div>
    );
}

const mapDispacthToProps = dispatch => bindActionCreators({
    authorizationFunction
}, dispatch);

export default connect(null, mapDispacthToProps)(PopoverButtonGroup);
