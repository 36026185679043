import axios from "axios";

export function setAuthHeader(config) {
    config.headers.common['Authorization'] = localStorage.getItem(process.env.REACT_APP_URL_TOKEN);
}

export function setUserHeader(config) {
    let user = JSON.parse(localStorage.getItem('userLogged'));
    config.headers.common['userAudit'] = user ? window.btoa(user.nome) : "";
}

export function setNewToken(response) {
    let newToken = response.headers['authorization'];
    if (newToken) {
        localStorage.setItem(process.env.REACT_APP_URL_TOKEN, newToken);
        axios.defaults.headers.common['Authorization'] = newToken;
    }
}
