import React, { Component } from "react";
import { connect } from 'react-redux';
import ContainerPage from "../../../common/container/ContainerPage";
import MonitoramentoListFilter from "./form/MonitoramentoListFilter";
import MonitoramentoList from "./list/MonitoramentoList";

class MonitoramentoPage extends Component {
    render() {
        return (
            <ContainerPage>
                <MonitoramentoListFilter />
                <MonitoramentoList />
            </ContainerPage>
        );
    }
}

export default connect(null, null)(MonitoramentoPage);