import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../../common/container/ContainerPage";
import LogListFilter from "./form/LogListFilter";
import LogList from "./list/LogList";
import { ERROS_TAB, LOG_TABS } from "../LogTabs";
import { selectTab } from "../../../../../actions/operacao/tabs/TabsAction";

class LogPage extends Component {
  componentDidMount() {
    this.props.selectTab(ERROS_TAB);
  }

  render() {
    return (
      <ContainerPage tabs={LOG_TABS} className="px-2">
        <LogListFilter />
        <LogList />
      </ContainerPage>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectTab,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(LogPage);
