/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import {
  getExportacao,
  loadFormExportacao,
} from "../../../../../actions/exportacaoContas/ExportacaoAction";
import TableActionWithRouteAuthorization from "../../../../common/table/TableActionWithRouteAuthorization";
import { EXPORTACAO_CONTAS_RECEBER } from "../../../../../utils/actionsAndFeaturesAuth";
import { convertCurrencyNumber } from "../../../../../utils/helpers/NumberHelper";
import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";

const ExportacaoList = (props) => {
  const {
    getExportacao,
    exportacaoData,
    exportacaoConsult,
    messages,
    loadFormExportacao,
  } = props;

  const classTable = "text-center fw-700 d-flex justify-content-center w-100";
  const emptyData = messages?.infos[0];

  const tableHeader = (
    <tr>
      <th>
        <div className={`${classTable}`}>ARQUIVO DE EXPORTAÇÃO</div>
      </th>
      <th>
        <div className={`${classTable}`}>TIPO DE PAGAMENTO</div>
      </th>
      <th>
        <div className={`${classTable}`}>RESPONSÁVEL</div>
      </th>
      <th>
        <div className={`${classTable}`}>DATA DE CRIAÇÃO</div>
      </th>
      <th>
        <div className={`${classTable}`}>QTDE FATURAMENTOS</div>
      </th>
      <th>
        <div className={`${classTable}`}>VALOR TOTAL</div>
      </th>
      <th>
        <div className={`${classTable}`}>STATUS</div>
      </th>
      <th className="text-center"></th>
    </tr>
  );

  const tableContent =
    exportacaoData &&
    exportacaoData?.hasItems &&
    exportacaoData?.items.map((item, index) => (
      <tr key={index}>
        <td className="text-center fw-400">
          {item?.arquivoExportacao || "--"}
        </td>
        <td className="text-center fw-400">{item?.tipoPagamento?.value || "--"}</td>
        <td className="text-center fw-400">{item?.responsavel || "--"}</td>
        <td className="text-center fw-400">
          {item?.dataInclusao
            ? getDateFormatedFromString(item?.dataInclusao)
            : "--"}
        </td>
        <td className="text-center fw-400">{item?.qtdeFaturamentos || "--"}</td>
        <td className="text-center fw-400">
          {item?.valorTotal
            ? convertCurrencyNumber(item?.valorTotal, "R$ ", 2)
            : "--"}
        </td>
        <td className="text-center fw-400">{item?.status?.value || "--"}</td>

        <TableActionWithRouteAuthorization
          arrow={true}
          feature={EXPORTACAO_CONTAS_RECEBER}
          viewAction={() => loadFormExportacao(item.id)}
          // data={{ validarAreaAtuacao: true }}
        />
      </tr>
    ));

  return (
    <div className="container-fluid">
      <div className="p-3">
        {exportacaoData && exportacaoData?.items?.length > 0 && (
          <>
            <Table
              withoutHeader
              classes="shadow-custom rounded-12 col-12 mt-2 mb-3 pd-10 bg-white"
              totalItems={exportacaoData.totalCount}
              pagination={
                <PaginationRB
                  pageNumber={exportacaoData.pageIndex - 1}
                  itemsPerPage={exportacaoData.pageSize}
                  totalItems={exportacaoData.totalCount}
                  filter={exportacaoConsult}
                  fetchFunction={getExportacao}
                  pageRange={5}
                />
              }
            >
              <TableHeader>{tableHeader}</TableHeader>
              <TableContent>{tableContent}</TableContent>
            </Table>
          </>
        )}

        {exportacaoData && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

formValueSelector("AprovacaoForm");

const mapStateToProps = (state) => ({
  exportacaoData: state.exportacaoContasReducer.exportacao,
  exportacaoConsult: state.exportacaoContasReducer.exportacaoConsultCurrent,
  messages: state.exportacaoContasReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getExportacao,
      loadFormExportacao,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(ExportacaoList);
