import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getFundosCota,
  loadFormFundo,
} from "../../../../../../actions/fundo/FundoAction";

import PaginationRB from "../../../../../common/pagination/PaginationRB";
import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../common/table/TableEmptyData";
import { FUNDO } from "../../../../../../utils/actionsAndFeaturesAuth";
import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import TableActionWithRouteAuthorization from "../../../../../common/table/TableActionWithRouteAuthorization";

class FundoList extends Component {
  getIds = () => {
    let idsOperacao = 0;

    if (Array.isArray(this.props.operacoes) && this.props.operacoes.length) {
      idsOperacao = this.props.operacoes.map((o) => o.key);
    }

    return idsOperacao;
  };

  updateData = () => {
    this.props.getFundosCota(this.getIds());
  };

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.operacoes.length !== this.props.operacoes.length) {
      this.updateData();
    }
  }

  render() {
    const { operacoes, fundosData, messages, getFundosCota, loadFormFundo } = this.props;

    const idsOperacoes = operacoes.map((o) => o.key);
    const emptyData = messages && messages.infos && messages.infos[0];

    const tableHeader = (
      <tr>
        <th className="text-center fw-700">Fundo</th>
        <th className="text-center fw-700">Data Constituição</th>
        <th className="text-center fw-700">Data de Vencimento</th>
        <th className="text-center fw-700">Status</th>
        <th className="text-center"></th>
      </tr>
    );

    const tableContent =
      fundosData && fundosData.hasItems ? (
        fundosData.items.map((fundo) => (
          <tr key={fundo.idFundo}>
            <td className="text-center fw-400">{fundo.fundo || "--"}</td>
            <td className="text-center fw-400">
              {getDateFormatedFromString(fundo.dataConstituicao) || "--"}
            </td>
            <td className="text-center fw-400">
              {getDateFormatedFromString(fundo.dataVencimento) || "--"}
            </td>
            <td className="text-center fw-400">
              {fundo?.status.value || "--"}
            </td>
            <TableActionWithRouteAuthorization
              space="2"
              feature={FUNDO}
              viewAction={() => loadFormFundo(fundo.idFundo)}
              updateAction={() => loadFormFundo(fundo.idFundo, true)}
            />
          </tr>
        ))
      ) : (
        <TableEmptyData message={emptyData} />
      );

    return (
      <div>
        {fundosData.totalCount > 0 && (
          <div className="d-flex flex-column">
            <div className="mx-2 shadow-custom bg-white rounded-12">
              <p className="page-head__title f-28 mb-0 px-3 mt-4 mb-2">
                Fundos
              </p>
              <div className="mx-2">
                <Table
                  withoutHeader
                  classes="card col-12 shadow-none bg-white py-0 m-0 mb-2 rounded-12"
                  totalItems={fundosData.totalCount}
                  pagination={
                    <PaginationRB
                      pageNumber={fundosData.pageIndex - 1}
                      itemsPerPage={fundosData.pageSize}
                      totalItems={fundosData.totalCount}
                      filter={idsOperacoes}
                      fetchFunction={getFundosCota}
                      pageRange={5}
                    />
                  }
                >
                  <TableHeader>{tableHeader}</TableHeader>
                  <TableContent>{tableContent}</TableContent>
                </Table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fundosData: state.fundoReducer.tabFundosCotas,
  messages: state.complementoReducer.messages,
  operacoes: state.operacaoReducer.selectedOperacoes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getFundosCota,
      loadFormFundo
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(FundoList)
);
