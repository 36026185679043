import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import { loadFormReceita } from "../../../../../actions/operacao/ReceitaAction";

import { OPERACAO_TABS } from "../OperacaoTabs";
import ReceitaForm from "../receita/form/ReceitaForm";
import ContainerPage from "../../../../common/container/ContainerPage";

class ReceitaVisualizePage extends Component {
  componentDidMount() {
    let idReceita = this.props.match.params.idReceita;
    this.props.loadFormReceita(idReceita);
  }

  render() {
    const { handleSubmit, operacoes } = this.props;
    const idsOperacoes = operacoes.map((o) => o.key);

    return (
      <ContainerPage tabs={OPERACAO_TABS} paramsRoute={idsOperacoes}>
        <ReceitaForm
          title={"Detalhar - Receita"}
          handleSubmit={handleSubmit}
          readOnly={true}
          floatButtonVisible={false}
        />
      </ContainerPage>
    );
  }
}

const mapStateToProps = (state) => ({
  tab: state.tab,
  operacoes: state.operacaoReducer.selectedOperacoes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormReceita,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ReceitaVisualizePage)
);
