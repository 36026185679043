import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { newAtivoForm } from "./../../../../actions/ativo/AtivoAction";
import Button from "./../../../common/button/Button";
import Container from "./../../../common/container/Container";
import ContainerRow from "./../../../common/container/ContainerRow";
import AtivoPicker from "../../pickers/AtivoPicker";
import AtivoList from "./list/AtivoList";
import { authorizationFunction } from "../../../../actions/authorization/AuthorizationAction";
import { CREATE, ATIVO } from "../../../../utils/actionsAndFeaturesAuth";

const Ativo = props => (
  <Container>
    <p className="page-head__title f-36 mb-0 mt-2 px-3 py-4">Ativos</p>
    <div className="col-md my-2">
      <ContainerRow>
      <div className="shadow-custom rounded-12 w-100 pd-12 mx-3 mb-3 mt-0 d-flex flex-row justify-content-between align-items-end bg-white">
          <div className="d-flex flex-column w-100">
            <label className="f-12 text-dark mb-1">Busca</label>
            <AtivoPicker />
          </div>
          <Button
            classes="rounded text-white p-bg-imp mb-0 h-38 px-4 py-1"
            activeClassName={"col-lg-auto pr-0"}
            buttonStyle={"success"}
            action={() => props.authorizationFunction(
              { idAcao: CREATE, idFuncionalidade: ATIVO },
              () => props.newAtivoForm()
            )}
            icon={"plus-circle"}
            name={"Cadastrar Ativo"}
          />
      </div>
      </ContainerRow>
      <ContainerRow>
        <div className="col-md">
          <AtivoList />
        </div>
      </ContainerRow>
    </div>
  </Container>
);

const mapDispatchToProps = dispatch => bindActionCreators({
  newAtivoForm, authorizationFunction
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Ativo));
