import React from "react";

import ContainerPage from "../../../../common/container/ContainerPage";
import CalculoListFilter from "./form/CalculoListFilter";
import CalculoList from "./list/CalculoList";

const CalculoPage = () => {
    return (
        <ContainerPage>
            <CalculoListFilter />
            <CalculoList />
        </ContainerPage>
    );
}

export default CalculoPage;