export function deserializeFieldsHistoricoPrecificacaoFormFilter(filters, page) {
    return {
        ...filters,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10,
        }
    };
}

export function deserializeFieldsHistoricoPrecificacaoFormFilterExportExcel(filters) {
    return {
        
    };
}

export function serializeFieldsHistoricoFluxo(historicoPrecificacao) {
    return {

    };
}

export function deserializeFieldsEtapaFechamento(historico, formValues) {
    return {
        
    }
}

export function deserializeFieldsHistoricoValidacao(params) {
    return {
        
    };
}

export function deserializeFieldsHistoricoValidacaoConfirmacao(params) {
    return {
        
    }
}

export function deserializeFieldsEtapaLancamento(params) {
    return {
       
    }
}

export function deserializeFieldsEtapaHistorico(formValues) {
    return {

    }
}

export function deserializeFieldsHistoricoAgendamento(params) {
    return {
       
    };
}