import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { TableCell, TableRow, Checkbox } from '@material-ui/core';

import TableSelect from '../../../../../common/table/TableSelect';
import { formatPercent } from '../../../../../../utils/helpers/NumberHelper';
import { changeSelectItems } from '../../../../../../actions/common/TableSelectAction';

const AtivoVinculoOperacaoList = (props) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { dataList, changeSelectItems } = props;

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = event => setRowsPerPage(event.target.value);

    const handleSelectAllClick = event => {
        const newSelectedItems = event.target.checked ? dataList : [];
        setSelectedItems(newSelectedItems);
        changeSelectItems(newSelectedItems);
    };

    const handleClick = (event, item) => {
        const newSelectedItems = selectedItems.includes(item)
            ? selectedItems.filter(selected => selected !== item)
            : [...selectedItems, item];

        setSelectedItems(newSelectedItems);
        changeSelectItems(newSelectedItems);
    };

    // Mover a definição da função isSelected para cima
    const isSelected = item => selectedItems.includes(item);

    const headerRows = [
        { id: 'codigoAtivo', numeric: false, disablePadding: false, label: 'Cód. Ativo' },
        { id: 'codigoB3Cetip', numeric: false, disablePadding: false, label: 'Cód. B3CETIP' },
        { id: 'percentualVinculado', numeric: false, disablePadding: false, label: 'Percentual Vinculado' },
    ];

    const contentRows = dataList
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
            const isRowSelected = isSelected(item); // Renomeie para evitar conflito

            return (
                <TableRow
                    hover
                    onClick={event => handleClick(event, item)}
                    role="checkbox"
                    aria-checked={isRowSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isRowSelected}
                >
                    <TableCell padding="checkbox" style={{ fontSize: '1em' }}>
                        <Checkbox checked={isRowSelected} color='default' />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none" style={{ fontSize: '1em' }}>
                        {item.codigoAtivo}
                    </TableCell>
                    <TableCell style={{ fontSize: '1em' }}>
                        {item.codigoB3Cetip}
                    </TableCell>
                    <TableCell style={{ fontSize: '1em' }}>
                        {formatPercent(item.percentual)}
                    </TableCell>
                </TableRow>
            );
        });

    return (
        <TableSelect
            titleTable={'Ativos para Vincular'}
            dataHeader={headerRows}
            data={dataList}
            emptyRowsMessage={'Nenhum ativo selecionado'}
            selectedItems={selectedItems}
            handleSelectAllClick={handleSelectAllClick}
            page={page}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Linhas por página"
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
            {contentRows}
        </TableSelect>
    );
};

const mapDispatchToProps = dispatch => bindActionCreators({
    changeSelectItems,
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(AtivoVinculoOperacaoList));
