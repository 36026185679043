import {
    RECEITA_FETCHED,
    RECEITA_FORM_TAB ,
    RECEITA_SHOW_DELETE_DIALOG,
    LOAD_FORM_RECEITA,
    SAVE_FORM_RECEITA_ERRORS,
    LOAD_CONTAS_DESTINO,
    RECEITA_FETCHED_FILTROS,
} from '../../actions/operacao/ReceitaAction';

const INITIAL_STATE = { 
    receitas: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    isLoading: false,
    messages: null,
    showReceitaDeleteDialog: {
        showDialog: false,
        idReceita: 0
    },
    changeReceitaFormTab: '',
    receitaFormTypes: {}
}

export default function (state = INITIAL_STATE, action = {}) {
    switch(action.type) {
        case RECEITA_FETCHED:
            return {
                ...state,
                receitas: action.payload.content,
                messages: action.payload.messages,
                isLoading: true
            };
        case RECEITA_FETCHED_FILTROS:
            return {
                ...state,
                filtrosReceita: action.payload.content,
            };      
        case RECEITA_FORM_TAB:
            return {
                ...state,
                changeReceitaFormTab: action.payload
            };
        case RECEITA_SHOW_DELETE_DIALOG:
            return {
                ...state,
                showReceitaDeleteDialog: action.payload
            }
        case LOAD_FORM_RECEITA:
            return {
                ...state,
                receitaFormTypes: action.payload
            }
        case LOAD_CONTAS_DESTINO:
            return {
                ...state,
                receitaFormTypes: {
                    ...state.receitaFormTypes,
                    contasDestinos: action.payload
                }
            }
        case SAVE_FORM_RECEITA_ERRORS:
            return {
              ...state,
              messages: action.payload
            }
        default:
            return state;
    }
}