/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, change } from "redux-form";
import { bindActionCreators } from "redux";
import FileUploadCard from "../../../../../common/File/FileUploadCard";
import Editor from "../../../../../common/editor/Editor";
import { convertFileToBase64, ACCEPT_FILE_OPTIONS } from "../../../../../../utils/helpers/FileHelper";
import { showErrorMessages } from "../../../../../../actions/message/ErrorMessagesAPIAction";
import { downloadImagemEstrutura } from "../../../../../../actions/patrimonio//PatrimonioAction";

const PatrimonioFormEstrutura = (props) => {
  const {
    readOnly,
    view,
    arquivoImagemEstrutura,
    nomeImagemEstrutura,
    idPatrimonio,
    showing,
  } = props;

  const handleUploadFile = () => {
    let element = document.createElement('input');
    element.type = 'file';
    element.multiple = false;
    element.accept = ACCEPT_FILE_OPTIONS.IMAGE;
    element.onchange = (e) => {
      let files = element.files;
      if (files == null) {
        return;
      } else if (files.length == 0) {
        return;
      } else {
        upload(files[0]);
      }
    };

    element.click();
  }

  const removeFile = () => {
    props.change('patrimonioForm', 'arquivoImagemEstrutura', '');
    props.change('patrimonioForm', 'nomeImagemEstrutura', '');
  }

  const upload = async (file) => {
    try {
      props.change('patrimonioForm', 'arquivoImagemEstrutura', await convertFileToBase64(file));
      props.change('patrimonioForm', 'nomeImagemEstrutura', file.name);
    } catch (e) {
      showErrorMessages("Erro ao importar o arquivo")
    }
  }

  return (
    <div className="column" id="ptr-sttr">
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4 mt-4">
        <div className="row">
          <div className="pl-3 pr-4">
            <span className="label-estrutura-operacao">Imagem da Estrutura</span>
          </div>
          <div className="pl-1">
            <FileUploadCard showCard={arquivoImagemEstrutura && arquivoImagemEstrutura.length > 0}
              outterClass="upload-button-position-estrutura-operacao"
              fileName={nomeImagemEstrutura}
              isRemoveable={!view}
              disabled={view}
              isDownloaded={idPatrimonio > 0}
              handleUploadFile={handleUploadFile}
              handleRemoveFile={removeFile}
              handleDownloadFile={() => props.downloadImagemEstrutura(idPatrimonio, nomeImagemEstrutura, arquivoImagemEstrutura)}
            />
          </div>
        </div>
        <div className="pb-2">
          <span className="label-estrutura-operacao">Detalhe Operação</span>
        </div>
        {showing && (
          <Field
            component={Editor}
            readOnly={readOnly}
            inputId="descricaoEstrutura"
            name="descricaoEstrutura"
            label="Detalhe Operação"
          />
        )}
      </div>
    </div>
  );
};

const selector = formValueSelector("patrimonioForm");

const mapStateToProps = state => ({
  arquivoImagemEstrutura: selector(state, 'arquivoImagemEstrutura') || undefined,
  nomeImagemEstrutura: selector(state, 'nomeImagemEstrutura') || '',
  descricaoEstrutura: selector(state, 'descricaoEstrutura') || '',
  idPatrimonio: selector(state, 'id') || 0,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ change, downloadImagemEstrutura }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PatrimonioFormEstrutura);
