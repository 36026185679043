import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../common/table/TableEmptyData";
import PaginationRB from "../../../../../common/pagination/PaginationRB";

import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import { convertCurrencyNumber } from "../../../../../../utils/helpers/NumberHelper";

import { getPrecos } from "../../../../../../actions/precificacao/PrecificacaoAction";
import If from "../../../../../common/If/If";

const PrecoList = (props) => {
  const {
    getPrecos,
    precoData,
    messages,
    operacao,
    dataEventoInicial,
    dataEventoFinal,
  } = props;

  const tableHeader = (
    <tr>
      <th className="text-center fw-700 text-nowrap">Cod. Opea</th>
      <th className="text-center fw-700 text-nowrap">Remuneração</th>
      <th className="text-center fw-700 text-nowrap">Evento</th>
      <th className="text-center fw-700 text-nowrap">PU na Curva (VRCE)</th>
      <th className="text-center fw-700 text-nowrap">PU Resíduo</th>
    </tr>
  );

  const emptyData = messages && messages.infos && messages.infos[0];

  const tableContent =
    precoData && precoData.hasItems ? (
      precoData.items.map((item, index) => (
        <tr key={index}>
          <td className="text-center fw-400 text-nowrap">
            {item.codigoOpea || "--"}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {item.remuneracao || "--"}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {getDateFormatedFromString(item.dataEvento) || "--"}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(
              item.valorNominalResidualExtraodinario,
              item.moeda.simbolo
            ) || "--"}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {convertCurrencyNumber(
              item.valorNominalComResiduo,
              item.moeda.simbolo
            ) || "--"}
          </td>
        </tr>
      ))
    ) : (
      <TableEmptyData message={emptyData} />
    );

  return (
    <div className="w-100 px-4 mt-4">
      <If test={precoData.totalCount > 0}>
        <Table
          withoutHeader
          classes="shadow-custom rounded-12 col-12 mb-4 mt-0 pd-10 bg-white"
          totalItems={precoData.totalCount}
          pagination={
            <PaginationRB
              pageNumber={precoData.pageIndex - 1}
              itemsPerPage={precoData.pageSize}
              totalItems={precoData.totalCount}
              filter={{ operacao, dataEventoInicial, dataEventoFinal }}
              fetchFunction={getPrecos}
              pageRange={5}
            />
          }
        >
          <TableHeader>{tableHeader}</TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      </If>
    </div>
  );
};

const selector = formValueSelector("precoForm");

const mapStateToProps = (state) => ({
  precoData: state.precificacaoReducer.precificacaoPreco,
  messages: state.precificacaoReducer.messages,
  operacao: selector(state, "operacao"),
  dataEventoInicial: selector(state, "dataEventoInicial"),
  dataEventoFinal: selector(state, "dataEventoFinal"),
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getPrecos,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(PrecoList);
