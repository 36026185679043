/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";
import ModalAction from "../../../../../common/modal/ModalAction";
import { validate } from "../validation/receitaFormValidation";
import { showConfirmDialog } from "../../../../../../actions/common/modal/ConfirmDialogAction";
import FixedFloaterErrors from "../../../../../common/floats/FixedFloaterErrors";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import Input from "../../../../../common/input/Input";
import MaskedFormatInput from "../../../../../common/input/MaskedFormatInput";
import MaskedInput from "../../../../../common/input/MaskedInput";
import PercentageInput from "../../../../../common/input/PercentageInput";
import { getArrayNumberRange } from "../../../../../../utils/helpers/ArrayHelper";
import Button from "../../../../../common/button/Button";
import { customFormatPhone } from "../../../../../../utils/helpers/StringHelper";
import { multiply } from "mathjs";
import { convertCurrencyToNumber } from "../../../../../../utils/helpers/NumberHelper";
import {
  showReceitaList,
  showMessageAlert,
  mudaDependenteOnChange,
  limpaDependentesOnChange,
} from "../../../../../../actions/operacao/ReceitaAction";
import {
  reduxForm,
  formValueSelector,
  Field,
  change,
  arrayInsert,
  arrayRemove,
  getFormValues,
  getFormSyncErrors,
} from "redux-form";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { withStyles } from "@material-ui/core/styles";
import { calcularQuantidadeParcelas } from "../../../../../../utils/helpers/DateTimeHelper";
import MultSelectListInput from "../../../../../common/input/MultSelectListInput";
import Textarea from "../../../../../common/input/Textarea";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { hasErrorsValidation } from "../../../../../../utils/helpers/ObjectHelper";
import { HiddenField } from "../../../../../common/input/HiddenField";

const styles = (theme) => ({
  parent: {
    width: "100%",
    marginTop: "0.5%",
    backgroundColor: "transparent",
  },
  mrButton: {
    marginRight: theme.spacing.unit,
  },
  button: {
    color: "#630A37",
    "&:disabled": {
      color: "#D0D0D0",
    },
  },
  buttons: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "1rem !important",
  },
  stepper: {
    backgroundColor: "transparent",
  },
  active: {},
  completed: {},
  customStepLabel: {
    "& [class*='MuiStepLabel-labelContainer']": {
      display: "flex",
      justifyContent: "center",
    },
    "& [class*='MuiTypography-root']": {
      width: "max-content",
      padding: "0 10px",
      borderRadius: "20px",
      // fontSize: "12px",
      // textTransform: "uppercase",
      "&[class*='MuiStepLabel']": {
        backgroundColor: "#B8B8B8",
        color: "#121212c9",
        fontWeight: 500,
      },
      "&[class*='MuiStepLabel-active']": {
        backgroundColor: "#FFC107",
      },
      "&[class*='MuiStepLabel-completed']": {
        backgroundColor: "#83BF74",
        color: "#ffffff",
        "&.revisao": {
          backgroundColor: "#cc0000",
        },
      },
    },
  },
});


let ReceitaForm = (props) => {
  const {
    match,
    location,
    handleSubmit,
    showReceitaList,
    title,
    readOnly,
    floatButtonVisible,
    operacoes,
    showConfirmDialog,
    showCancelConfirmDialog,
    //
    invalid,
    errors,
    submitFailed,
    showMessageAlert,
    messages,
    // 
    classes,
    
    // new all
    formValues,
    receitaContrato,
    faturarFlat,
    faturarRecorrente,
    operacao,
    patrimonioSeparado,
    companhiaEmissora,
    dataInicioRec,
    dataVencimentoRec,
    possuiPagamentoFlat,
    possuiPagamentoRecorrente,
    contatos,
    pagamentoProporcionalFlat,
    pagamentoProporcionalRec,
    idOrigemRecursoFlat,
    idOrigemRecursoRec,
    possuiCorrecaoRecorrente,
    // new lists
    produtoReceitaList,
    origemRecursoList,
    formaPagamentoList,
    contaOrigemList,
    mesList,
    periodicidadePagamentoList,
    modalidadeList,
    empresaFaturamentoList,
    taxaList,
    faturarContraList,
    statusList,
    // new actions
    arrayInsert,
    arrayRemove,
    mudaDependenteOnChange,
    limpaDependentesOnChange,
  } = props;
  
  const errorDivFlat = errors?.receitaContratoFlat?.somaDivisoes
  const errorDivRecorrente = errors?.receitaContratoRecorrente?.somaDivisoes

  const user = JSON.parse(localStorage.getItem("userLogged"));
  const areas = user.areasAtuacoes;

  const isAdmin = user.acessos.some((acesso) => acesso === 1);
  const isTeasury = areas.some(({ id }) => id === 9);
  
  const idsOperacoes = operacoes.map((o) => o.key);

  const possuiPmtFlat = possuiPagamentoFlat == "Sim";
  const possuiPmtRec = possuiPagamentoRecorrente == "Sim";
  const possuiCorrecaoRec = possuiCorrecaoRecorrente == "Sim";

  const statusValue = receitaContrato?.statusAprovacao?.key;
  const hasApproval = receitaContrato?.aprovacao;

  const REVISAO = statusValue == 4;

  let renderStatus = [
    { key: 2, value: "Cadastrado", raw: "Cadastrado", show: true, concluidos: [1] },
    { key: 3, value: "Aprovado", raw: "Aprovado", show: !REVISAO, concluidos: [1, 2] },
    { key: 4, value: "Revisado", raw: "Revisado", show: REVISAO, concluidos: [1, 2] },
  ];

  const steps = renderStatus.filter((sts) => sts.show).map((sts) => sts.value);
  const concluidos = renderStatus.filter((sts) => sts.key === statusValue).flatMap((sts) => sts.concluidos);

  function CustomStepIcon({ active, completed, classes }) {
    const emRevisao = classes?.completed?.includes("revisao");
    const cRevi = "#cc0000";
    const cComp = "#83BF74";
    const cActv = "#FFC107";
    const cDflt = "#888888";

    return emRevisao ? (
      <RadioButtonChecked style={{ color: cRevi }} />
    ) : completed ? (
      <CheckCircleOutline style={{ color: cComp }} />
    ) : active ? (
      <RadioButtonChecked style={{ color: cActv }} />
    ) : (
      <RadioButtonUnchecked style={{ color: cDflt }} />
    );
  }

    const addContato = (index) => {
      if (!(statusValue == 2)) {
        arrayInsert("ReceitaForm", "contatos", index, {});
      }
    }

    const removeContato = (index) => {
      if ((!(statusValue == 2)) && contatos.length > 0) {
        arrayRemove("ReceitaForm", "contatos", index);
      }
    }

    const addFaturarFlat = (index) => {
      if (!readOnly) {
        arrayInsert("ReceitaForm", "receitaContratoFlat.divisoes", index, {});
      }
    }

    const removeFaturarFlat = (index) => {
      if ((!readOnly) && faturarFlat.length > 0) {
        arrayRemove("ReceitaForm", "receitaContratoFlat.divisoes", index);
      }
    }

    const addFaturarRecorrente = (index) => {
      if (!readOnly) {
        arrayInsert("ReceitaForm", "receitaContratoRecorrente.divisoes", index, {});
      }
    }

    const removeFaturarRecorrente = (index) => {
      if ((!readOnly) && faturarRecorrente.length > 0) {
        arrayRemove("ReceitaForm", "receitaContratoRecorrente.divisoes", index);
      }
    }

    const calcularValorContrato = (value, fieldName) => {
      const { valorLiquidoRec, qntParcelaRec, change } = props;

      if (fieldName == "quantidadeParcela") value = parseFloat(value) || 0;
      if (fieldName == "valorLiquido") value = convertCurrencyToNumber(value) || 0;

      let valorContrato = null;
      
      if (fieldName === "quantidadeParcela" && valorLiquidoRec) {
        valorContrato = multiply(value, convertCurrencyToNumber(valorLiquidoRec) || 0);
      } else if (fieldName === "valorLiquido" && qntParcelaRec) {
        valorContrato = multiply(value, parseFloat(qntParcelaRec) || 0);
      }

      change("ReceitaForm", "receitaContratoRecorrente.valorContrato", valorContrato);
    }

    const calcularValorBruto = (value, fieldName, target) => {
      const { change, formValues } = props;
    
      const grossUp = (formValues[target].grossUp || 0) / 100;
      const valorLiquido = convertCurrencyToNumber(formValues[target].valorLiquido || 0);
      
      value = fieldName === "grossUp" ? (value || 0) / 100 : convertCurrencyToNumber(value) || 0;
    
      const valorBruto = fieldName === "grossUp" 
        ? valorLiquido / (1 - value) 
        : fieldName === "valorLiquido" 
        ? value / (1 - grossUp) 
        : null;
    
      const valorBrutoFinal = (valorBruto === Infinity || isNaN(valorBruto)) ? 0 : valorBruto;
    
      change("ReceitaForm", `${target}.valorBruto`, valorBrutoFinal);
    }

    return (
      <Form>
        <FormHeader
          title={title}
          col={"col-md"}
          offSet={20}
          codigoOpea={operacao ? operacao.codigoRb : operacoes.length > 0 ? operacoes[0].value : ""}
        ></FormHeader>

        <Stepper
            className="my-3"
            classes={{ root: classes.stepper }}
            activeStep={REVISAO ? null : concluidos.length || 0}
            alternativeLabel
          >
            {steps.map((step, index) => (
              <Step
                key={index}
                completed={concluidos.some((et) => et === index + 1)}
              >
                <StepLabel
                  completed={concluidos.some((et) => et === index + 1)}
                  className={classes.customStepLabel}
                  StepIconComponent={CustomStepIcon}
                  classes={{
                    completed: `${classes.completed} ${
                      REVISAO && index + 1 == 2 && "revisao"
                    }`,
                  }}
                  StepIconProps={{
                    classes: {
                      completed: `${classes.completed} ${
                        REVISAO && index + 1 == 2 && "revisao"
                      }`,
                      active: classes.active,
                      disabled: classes.disabled,
                    },
                  }}
                >
                  {step}
                </StepLabel>
              </Step>
            ))}
        </Stepper>

        <FormContent handleSubmit={handleSubmit}>
          <div className="row">
            {messages?.hasError && (
              <FixedFloaterErrors
                messages={messages}
                title="Status do Cadastro"
              />
            )}

            {/* Status */}
            {Boolean(hasApproval) && (
              <div className="col-12">
                <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                  <span className="h3 page-head__title">Status</span>
                  <div className="row">
                    <div className="col-lg">
                      <Field
                        component={Input}
                        inputId="aprovacao.dataSolicitacao"
                        name="aprovacao.dataSolicitacao"
                        label="Data de Cadastro"
                        type={"date"}
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className="col-lg">
                      <Field
                        component={Input}
                        inputId="aprovacao.solicitante.nome"
                        name="aprovacao.solicitante.nome"
                        label="Solicitante"
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className="col-lg">
                      <Field
                        component={Input}
                        inputId="aprovacao.idFormatado"
                        name="aprovacao.idFormatado"
                        label="ID Aprovação"
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg">
                      <Field
                        component={Input}
                        inputId="aprovacao.aprovadores[0].aprovador.nome"
                        name="aprovacao.aprovadores[0].aprovador.nome"
                        label="Aprovador 1"
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className="col-lg">
                      <Field
                        component={Input}
                        inputId="aprovacao.aprovadores[0].statusAprovacao.value"
                        name="aprovacao.aprovadores[0].statusAprovacao.value"
                        label="Status Aprovação 1"
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className="col-lg">
                      <Field
                        component={Input}
                        inputId="aprovacao.aprovadores[0].observacao"
                        name="aprovacao.aprovadores[0].observacao"
                        label="Comentário Aprovador 1"
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg">
                      <Field
                        component={Input}
                        inputId="aprovacao.aprovadores[1].aprovador.nome"
                        name="aprovacao.aprovadores[1].aprovador.nome"
                        label="Aprovador 2"
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className="col-lg">
                      <Field
                        component={Input}
                        inputId="aprovacao.aprovadores[1].statusAprovacao.value"
                        name="aprovacao.aprovadores[1].statusAprovacao.value"
                        label="Status Aprovação 2"
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className="col-lg">
                      <Field
                        component={Input}
                        inputId="aprovacao.aprovadores[1].observacao"
                        name="aprovacao.aprovadores[1].observacao"
                        label="Comentário Aprovador 2"
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Contrato */}
            <div className="col-12">
              <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                <span className="h3 page-head__title">Contrato</span>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="idPatrimonioSeparado"
                      name="idPatrimonioSeparado"
                      label="Patrimônio Separado"
                      input={{ value: patrimonioSeparado?.descricao || '' }}
                      textField="descricao"
                      valueField="id"
                      filter="contains"
                      readOnly={true}
                      required
                    />
                  </div>

                  <div className="col-lg col-12">
                    <Field
                      component={DropdownListInput}
                      inputId="idOperacao"
                      name="idOperacao"
                      label="Operação"
                      input={{ value: operacao ? `${operacao?.codigoOpea} - ${operacao?.apelido}` : '' }}
                      textField="descricao"
                      valueField="id"
                      filter="contains"
                      readOnly={true}
                      required
                      />
                  </div>

                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="idCompanhiaEmissora"
                      name="idCompanhiaEmissora"
                      label="Companhia Emissora"
                      input={{ value: companhiaEmissora || '' }}
                      textField="descricao"
                      valueField="id"
                      filter="contains"
                      readOnly={true}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="idEmpresaFaturamento"
                      name="idEmpresaFaturamento"
                      label="Empresa Faturamento"
                      options={empresaFaturamentoList}
                      textField="razaoSocialComCnpj"
                      valueField="id"
                      filter="contains"
                      readOnly={readOnly}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="idProdutoReceita"
                      name="idProdutoReceita"
                      label="Tipo de Receita"
                      options={produtoReceitaList}
                      textField="nome"
                      valueField="id"
                      filter="contains"
                      readOnly={readOnly}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="identificador"
                      name="identificador"
                      label="Identificador"
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="row">
                  {statusValue >= 3 && (
                    <>
                      <div className="col-lg">
                        <Field
                          component={DropdownListInput}
                          inputId="status"
                          name="status"
                          label="Status Contrato"
                          options={statusList}
                          textField="value"
                          valueField="key"
                          disabled={readOnly && !(isTeasury || isAdmin) && statusValue !== 3}
                          required
                        />
                      </div>

                      <div className="col-lg">
                        <Field
                          component={Input}
                          inputId="dataUltimaParcela"
                          name="dataUltimaParcela"
                          label="Data da Última Parcela (Contrato)"
                          type={"date"}
                          disabled={readOnly && !(isTeasury || isAdmin) && statusValue !== 3}
                          required
                        />
                      </div>
                    </>
                  )}
                  <div className="col-lg">
                    <Field
                      component={Textarea}
                      inputId="observacao"
                      name="observacao"
                      label="Observações"
                      rows={2}
                      readOnly={readOnly}
                      maxLength={5000}
                    />
                  </div>
                </div>
              </div>
            </div>
    
            {/* Receita Flat */}
            <div className="col-12">
              <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                <span className="h3 page-head__title">Receita Flat</span>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="possuiPagamentoFlat"
                      name="possuiPagamentoFlat"
                      label="Possui Pagamento Flat?"
                      readOnly={readOnly}
                      onChange={(_e, v) => {
                        if (v !== 'Sim') {
                          limpaDependentesOnChange([
                            "receitaContratoFlat.valorLiquido",
                            "receitaContratoFlat.grossUp",
                            "receitaContratoFlat.valorBruto",
                            "receitaContratoFlat.dataPagamento",
                            "receitaContratoFlat.formaPagamento",
                            "receitaContratoFlat.idOrigemRecurso",
                            "receitaContratoFlat.idContaOrigem",
                            "receitaContratoFlat.pagamentoProporcional",
                            "receitaContratoFlat.divisoes",
                          ])
                        }
                      }}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={MaskedInput}
                      inputId="receitaContratoFlat.valorLiquido"
                      name="receitaContratoFlat.valorLiquido"
                      label="Valor Líquido (Flat)"
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                      prefix="R$ "
                      fixedDecimalScale
                      readOnly={readOnly || !possuiPmtFlat}
                      required={possuiPmtFlat}
                      onChange={(_e, v) => calcularValorBruto(v, "valorLiquido", "receitaContratoFlat")}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={PercentageInput}
                      name="receitaContratoFlat.grossUp"
                      inputId="receitaContratoFlat.grossUp"
                      decimalScale={4}
                      limitTo={99.9999}
                      decimalSeparator={","}
                      label="Gross-up (Flat)"
                      readOnly={readOnly || !possuiPmtFlat}
                      required={possuiPmtFlat}
                      onChange={(_e, v) => calcularValorBruto(v, "grossUp", "receitaContratoFlat")}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={MaskedInput}
                      inputId="receitaContratoFlat.valorBruto"
                      name="receitaContratoFlat.valorBruto"
                      label="Valor Bruto (Flat)"
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                      prefix="R$ "
                      fixedDecimalScale
                      readOnly={true}
                      required={possuiPmtFlat}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="receitaContratoFlat.dataPagamento"
                      name="receitaContratoFlat.dataPagamento"
                      label="Data Pagamento (Flat)"
                      type={"date"}
                      readOnly={readOnly || !possuiPmtFlat}
                      required={possuiPmtFlat}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoFlat.formaPagamento"
                      name="receitaContratoFlat.formaPagamento"
                      label="Forma de Pagamento"
                      options={formaPagamentoList}
                      textField="value"
                      valueField="key"
                      filter="contains"
                      readOnly={readOnly || !possuiPmtFlat}
                      required={possuiPmtFlat}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoFlat.idOrigemRecurso"
                      name="receitaContratoFlat.idOrigemRecurso"
                      label="Origem de Recursos"
                      options={origemRecursoList}
                      textField="descricao"
                      valueField="id"
                      filter="contains"
                      readOnly={!possuiPmtFlat || (readOnly && !((isTeasury || isAdmin) && statusValue === 3))}
                      required={possuiPmtFlat}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoFlat.idContaOrigem"
                      name="receitaContratoFlat.idContaOrigem"
                      label="Conta Origem"
                      options={contaOrigemList}
                      textField="tipoConta"
                      valueField="id"
                      filter="contains"
                      readOnly={!possuiPmtFlat || (readOnly && !((isTeasury || isAdmin) && statusValue === 3))}
                      required={possuiPmtFlat && idOrigemRecursoFlat?.id !== 20}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoFlat.pagamentoProporcional"
                      name="receitaContratoFlat.pagamentoProporcional"
                      label="Pagamento Proporcional"
                      readOnly={readOnly || !possuiPmtFlat}
                      required={possuiPmtFlat}
                      onChange={(_v, e) => {
                        if (e == 'Não') {
                          mudaDependenteOnChange("receitaContratoFlat.divisoes", [])
                          arrayInsert("ReceitaForm", "receitaContratoFlat.divisoes", 0, { proporcao: 100, idPessoa: null });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={`d-flex flex-column w-100 ${(errorDivFlat && faturarFlat?.length > 0 && submitFailed) ? 'px-3' : ''}`}>
                    <div className={`col more-fields ${(errorDivFlat && faturarFlat?.length > 0 && submitFailed) ? 'col more-fields border-danger rounded border pt-2 pb-3 mt-3' : 'mt-2'}`}>
                      <TransitionGroup>
                        {faturarFlat &&
                          faturarFlat.map((item, index) => (
                            <CSSTransition key={index} timeout={300} classNames="item">
                              <div key={index} className="more-fields-item mb-1">
                                  <div className="row">
                                    <div className="col-lg">
                                      <Field
                                        component={DropdownListInput}
                                        inputId={`receitaContratoFlat.divisoes[${index}].idPessoa`}
                                        name={`receitaContratoFlat.divisoes[${index}].idPessoa`}
                                        label="Faturar Contra"
                                        options={faturarContraList}
                                        textField="razaoSocialComCnpj"
                                        valueField="id"
                                        filter="contains"
                                        readOnly={readOnly || !possuiPmtFlat}
                                        required={possuiPmtFlat}
                                      />
                                    </div>

                                    <div className="col-lg-3 col-12">
                                      <Field
                                        component={PercentageInput}
                                        name={`receitaContratoFlat.divisoes[${index}].proporcao`}
                                        inputId={`receitaContratoFlat.divisoes[${index}].proporcao`}
                                        decimalScale={8}
                                        limitToHundred={true}
                                        decimalSeparator={","}
                                        label="Proporção (%)"
                                        readOnly={readOnly || !possuiPmtFlat || pagamentoProporcionalFlat == 'Não'}
                                        required={possuiPmtFlat}
                                      />
                                    </div>
        
                                    {(!readOnly && possuiPmtFlat && pagamentoProporcionalFlat == 'Sim') && (
                                      <div className="col-12 col-lg-auto mr-3 d-flex align-items-center">
                                        <Button
                                          classes="rounded bg-white shadow-none border-0 text-danger mx-0 mxh-40 p-0 mb-2 mt-3 text-center text-capitalize"
                                          activeClassName={"col-lg-auto p-0"}
                                          buttonStyle={"success"}
                                          action={() => removeFaturarFlat(index)}
                                          icon={"trash"}
                                          name={"Remover"}
                                        />
                                      </div>
                                    )}
                                  </div>
                              </div>
                            </CSSTransition>
                          ))}
                      </TransitionGroup>
                    </div>

                    {(errorDivFlat && faturarFlat?.length > 0 && submitFailed) && (
                      <p className="error-msg sp-error text-danger f-12 mb-0 text-right mt-1">{ errorDivFlat }</p>
                    )}
    
                    {(!readOnly && possuiPmtFlat && pagamentoProporcionalFlat == 'Sim') && (
                      <>
                        <div className="mx-auto mt-3">
                          <Button
                            classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                            activeClassName={"col-lg-auto p-0"}
                            buttonStyle={"success"}
                            action={() => addFaturarFlat(faturarFlat.length)}
                            icon={"plus"}
                            name={"Adicionar"}
                          />
                        </div>


                        {(!faturarFlat?.length || hasErrorsValidation(errors)) && (<Field component={HiddenField} name="receitaContratoFlat.divisoes"></Field>)}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Receita Recorrente */}
            <div className="col-12">
              <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                <span className="h3 page-head__title">Receita Recorrente</span>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="possuiPagamentoRecorrente"
                      name="possuiPagamentoRecorrente"
                      label="Possui Pagamento Recorrente?"
                      readOnly={readOnly}
                      onChange={(_e, v) => {
                        if (v !== 'Sim') {
                          limpaDependentesOnChange([
                            "receitaContratoRecorrente.valorLiquido",
                            "receitaContratoRecorrente.grossUp",
                            "receitaContratoRecorrente.valorBruto",
                            "receitaContratoRecorrente.dataInicio",
                            "receitaContratoRecorrente.dataVencimento",
                            "receitaContratoRecorrente.idContaOrigem",
                            "receitaContratoRecorrente.idOrigemRecurso",
                            "receitaContratoRecorrente.modalidade",
                            "receitaContratoRecorrente.diaFaturamento",
                            "receitaContratoRecorrente.prazoPagamento",
                            "receitaContratoRecorrente.periodicidadePagamento",
                            "receitaContratoRecorrente.mesesPagamento",
                            "receitaContratoRecorrente.quantidadeParcelas",
                            "receitaContratoRecorrente.valorContrato",
                            "receitaContratoRecorrente.possuiCorrecao",
                            "receitaContratoRecorrente.idTaxa",
                            "receitaContratoRecorrente.mesesDefasagem",
                            "receitaContratoRecorrente.idPeriodicidadeCorrecao",
                            "receitaContratoRecorrente.mesesCorrecao",
                            "receitaContratoRecorrente.somenteVariacaoPositiva",
                            "receitaContratoRecorrente.dataInicioCorrecao",
                            "receitaContratoRecorrente.pagamentoProporcional",
                            "receitaContratoRecorrente.divisoes",
                          ])
                        }
                      }}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={MaskedInput}
                      inputId="receitaContratoRecorrente.valorLiquido"
                      name="receitaContratoRecorrente.valorLiquido"
                      label="Valor Líquido Inicial (Recorrente)"
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                      prefix="R$ "
                      fixedDecimalScale
                      readOnly={readOnly || !possuiPmtRec}
                      required={possuiPmtRec}
                      onChange={(_e, v) => {
                        calcularValorContrato(v, "valorLiquido")
                        calcularValorBruto(v, "valorLiquido", "receitaContratoRecorrente")
                      }}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={PercentageInput}
                      name="receitaContratoRecorrente.grossUp"
                      inputId="receitaContratoRecorrente.grossUp"
                      decimalScale={4}
                      limitTo={99.9999}
                      decimalSeparator={","}
                      label="Gross-up (Recorrente)"
                      readOnly={readOnly || !possuiPmtRec}
                      required={possuiPmtRec}
                      onChange={(_e, v) => calcularValorBruto(v, "grossUp", "receitaContratoRecorrente")}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={MaskedInput}
                      inputId="receitaContratoRecorrente.valorBruto"
                      name="receitaContratoRecorrente.valorBruto"
                      label="Valor Bruto (Recorrente)"
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                      prefix="R$ "
                      fixedDecimalScale
                      readOnly={true}
                      required={possuiPmtRec}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="receitaContratoRecorrente.dataInicio"
                      name="receitaContratoRecorrente.dataInicio"
                      label="Data de Início (1ª Parcela)"
                      type={"date"}
                      readOnly={readOnly || !possuiPmtRec}
                      required={possuiPmtRec}
                      onChange={async (_e, v) => {
                        if (v && dataVencimentoRec) {
                          const parcelas = calcularQuantidadeParcelas(v, dataVencimentoRec)
                          mudaDependenteOnChange("receitaContratoRecorrente.quantidadeParcelas", parcelas)
                          calcularValorContrato(parcelas, "quantidadeParcela")
                        };
                      }}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="receitaContratoRecorrente.dataVencimento"
                      name="receitaContratoRecorrente.dataVencimento"
                      label="Data de Vencimento (Contrato)"
                      type={"date"}
                      readOnly={!possuiPmtRec || (readOnly && !((isTeasury || isAdmin) && statusValue === 3))}
                      required={possuiPmtRec}
                      onChange={async (_e, v) => {
                        if (dataInicioRec && v) {
                          const parcelas = calcularQuantidadeParcelas(dataInicioRec, v)
                          mudaDependenteOnChange("receitaContratoRecorrente.quantidadeParcelas", parcelas);
                          calcularValorContrato(parcelas, "quantidadeParcela")
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoRecorrente.idContaOrigem"
                      name="receitaContratoRecorrente.idContaOrigem"
                      label="Conta Origem"
                      options={contaOrigemList}
                      textField="tipoConta"
                      valueField="id"
                      filter="contains"
                      readOnly={!possuiPmtRec || (readOnly && !((isTeasury || isAdmin) && statusValue === 3))}
                      required={possuiPmtRec && idOrigemRecursoRec?.id !== 20}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoRecorrente.idOrigemRecurso"
                      name="receitaContratoRecorrente.idOrigemRecurso"
                      label="Origem de Recursos"
                      options={origemRecursoList}
                      textField="descricao"
                      valueField="id"
                      filter="contains"
                      readOnly={!possuiPmtRec || (readOnly && !((isTeasury || isAdmin) && statusValue === 3))}
                      required={possuiPmtRec}
                      onChange={(_e, v) => {
                        if (v?.id == 20) {
                          mudaDependenteOnChange("receitaContratoRecorrente.modalidade", { key: 1, value: "Ao Cliente", raw: "AoCliente"})
                        } else if (v?.id) {
                          mudaDependenteOnChange("receitaContratoRecorrente.modalidade", { key: 2, value: "Intercompany", raw: "Intercompany"})
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoRecorrente.modalidade"
                      name="receitaContratoRecorrente.modalidade"
                      label="Modalidade"
                      options={modalidadeList}
                      textField="value"
                      valueField="key"
                      filter="contains"
                      readOnly={readOnly || !possuiPmtRec}
                      required={possuiPmtRec}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoRecorrente.diaFaturamento"
                      name="receitaContratoRecorrente.diaFaturamento"
                      label="Dia do Faturamento"
                      options={getArrayNumberRange(1, 31)}
                      textField="textField"
                      valueField="valueField"
                      readOnly={!possuiPmtRec || (readOnly && !((isTeasury || isAdmin) && statusValue === 3))}
                      required={possuiPmtRec}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoRecorrente.prazoPagamento"
                      name="receitaContratoRecorrente.prazoPagamento"
                      label="Prazo Pagamento"
                      options={getArrayNumberRange(1, 90)}
                      textField="textField"
                      valueField="valueField"
                      readOnly={!possuiPmtRec || (readOnly && !((isTeasury || isAdmin) && statusValue === 3))}
                      required={possuiPmtRec}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoRecorrente.idPeriodicidadePagamento"
                      name="receitaContratoRecorrente.idPeriodicidadePagamento"
                      label="Periodicidade Pagamento"
                      options={periodicidadePagamentoList}
                      textField="descricao"
                      valueField="id"
                      filter="contains"
                      readOnly={readOnly || !possuiPmtRec}
                      required={possuiPmtRec}
                      onChange={(_e, v) => {
                        if (v?.descricao == 'Mensal') mudaDependenteOnChange("receitaContratoRecorrente.mesesPagamento", [{ key: 13, value: "Todos", raw: "Todos" }])
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={MultSelectListInput}
                      inputId="receitaContratoRecorrente.mesesPagamento"
                      name="receitaContratoRecorrente.mesesPagamento"
                      label="Meses de Pagamento"
                      options={mesList}
                      textField="value"
                      valueField="key"
                      filter="contains"
                      readOnly={readOnly || !possuiPmtRec}
                      outerClassName={readOnly || !possuiPmtRec && "disabled-field"}
                      className={`${readOnly || !possuiPmtRec && 'clear-inside'} mb-0`}
                      required={possuiPmtRec}
                      onChange={(_e, v) => {
                        if (v) {
                          const completo = v.length === 12 && !v.some(mes => mes.key === 13) || v.some(mes => mes.key === 13);
                          if (completo) {
                            _e.preventDefault()
                            mudaDependenteOnChange("receitaContratoRecorrente.mesesPagamento", [{ key: 13, value: "Todos", raw: "Todos" }])
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={MaskedInput}
                      inputId="receitaContratoRecorrente.quantidadeParcelas"
                      name="receitaContratoRecorrente.quantidadeParcelas"
                      label="Quantidade Parcelas"
                      readOnly={true}
                      maxLength={2}
                      allowNegative={false}
                      required={possuiPmtRec}
                      onChange={(e) => calcularValorContrato(e.currentTarget.value, "quantidadeParcela")}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={MaskedInput}
                      inputId="receitaContratoRecorrente.valorContrato"
                      name="receitaContratoRecorrente.valorContrato"
                      label="Valor do Contrato"
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                      prefix="R$ "
                      fixedDecimalScale
                      readOnly={true}
                      required={possuiPmtRec}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoRecorrente.possuiCorrecao"
                      name="receitaContratoRecorrente.possuiCorrecao"
                      label="Possui Correção?"
                      readOnly={readOnly || !possuiPmtRec}
                      required={possuiPmtRec}
                      onChange={() => {
                        limpaDependentesOnChange([
                          "receitaContratoRecorrente.idTaxa",
                          "receitaContratoRecorrente.mesesDefasagem",
                          "receitaContratoRecorrente.idPeriodicidadeCorrecao",
                          "receitaContratoRecorrente.mesesCorrecao",
                          "receitaContratoRecorrente.somenteVariacaoPositiva",
                          "receitaContratoRecorrente.dataInicioCorrecao",
                        ])
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoRecorrente.idTaxa"
                      name="receitaContratoRecorrente.idTaxa"
                      label="Indexador de Correção"
                      options={taxaList}
                      textField="descricao"
                      valueField="id"
                      filter="contains"
                      readOnly={readOnly || !possuiPmtRec || !possuiCorrecaoRec}
                      required={possuiPmtRec && possuiCorrecaoRec}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoRecorrente.mesesDefasagem"
                      name="receitaContratoRecorrente.mesesDefasagem"
                      label="Meses de Defasagem"
                      options={getArrayNumberRange(0, 12)}
                      textField="textField"
                      valueField="valueField"
                      readOnly={readOnly || !possuiPmtRec || !possuiCorrecaoRec}
                      required={possuiPmtRec && possuiCorrecaoRec}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoRecorrente.idPeriodicidadeCorrecao"
                      name="receitaContratoRecorrente.idPeriodicidadeCorrecao"
                      label="Periodicidade Correção"
                      options={periodicidadePagamentoList}
                      textField="descricao"
                      valueField="id"
                      filter="contains"
                      readOnly={readOnly || !possuiPmtRec || !possuiCorrecaoRec}
                      required={possuiPmtRec && possuiCorrecaoRec}
                      onChange={(_e, v) => {
                        if (v?.descricao == 'Mensal') mudaDependenteOnChange("receitaContratoRecorrente.mesesCorrecao", [{ key: 13, value: "Todos", raw: "Todos" }])
                      }}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={MultSelectListInput}
                      inputId="receitaContratoRecorrente.mesesCorrecao"
                      name="receitaContratoRecorrente.mesesCorrecao"
                      label="Meses de Correção"
                      options={mesList}
                      textField="value"
                      valueField="key"
                      filter="contains"
                      outerClassName={`${readOnly || !possuiPmtRec && 'disabled-field'}`}
                      className={`${readOnly || !possuiPmtRec && 'clear-inside'} mb-0`}
                      readOnly={readOnly || !possuiPmtRec || !possuiCorrecaoRec}
                      required={possuiPmtRec && possuiCorrecaoRec}
                      onChange={(_e, v) => {
                        if (v) {
                          const completo = v.length === 12 && !v.some(mes => mes.key === 13) || v.some(mes => mes.key === 13);
                          if (completo) {
                            _e.preventDefault()
                            mudaDependenteOnChange("receitaContratoRecorrente.mesesCorrecao", [{ key: 13, value: "Todos", raw: "Todos" }])
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoRecorrente.somenteVariacaoPositiva"
                      name="receitaContratoRecorrente.somenteVariacaoPositiva"
                      label="Somente Variação Positiva?"
                      readOnly={readOnly || !possuiPmtRec || !possuiCorrecaoRec}
                      required={possuiPmtRec && possuiCorrecaoRec}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="receitaContratoRecorrente.dataInicioCorrecao"
                      name="receitaContratoRecorrente.dataInicioCorrecao"
                      label="Início da Correção"
                      type={"date"}
                      readOnly={readOnly || !possuiPmtRec || !possuiCorrecaoRec}
                      required={possuiPmtRec && possuiCorrecaoRec}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="receitaContratoRecorrente.pagamentoProporcional"
                      name="receitaContratoRecorrente.pagamentoProporcional"
                      label="Pagamento Proporcional"
                      readOnly={readOnly || !possuiPmtRec}
                      required={possuiPmtRec}
                      onChange={(_v, e) => {
                        if (e == 'Não') {
                          mudaDependenteOnChange("receitaContratoRecorrente.divisoes", [])
                          arrayInsert("ReceitaForm", "receitaContratoRecorrente.divisoes", 0, { proporcao: 100, idPessoa: null });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={`d-flex flex-column w-100 ${(errorDivRecorrente && faturarRecorrente?.length > 0 && submitFailed) ? 'px-3' : ''}`}>
                    <div className={`col more-fields ${(errorDivRecorrente && faturarRecorrente?.length > 0 && submitFailed) ? 'col more-fields border-danger rounded border pt-2 pb-3 mt-3' : 'mt-2'}`}>
                      <TransitionGroup>
                        {faturarRecorrente &&
                          faturarRecorrente.map((item, index) => (
                            <CSSTransition key={index} timeout={300} classNames="item">
                              <div key={index} className="more-fields-item mb-1">
                                  <div className="row">
                                    <div className="col-lg">
                                      <Field
                                        component={DropdownListInput}
                                        inputId={`receitaContratoRecorrente.divisoes[${index}].idPessoa`}
                                        name={`receitaContratoRecorrente.divisoes[${index}].idPessoa`}
                                        label="Faturar Contra"
                                        options={faturarContraList}
                                        textField="razaoSocialComCnpj"
                                        valueField="id"
                                        filter="contains"
                                        readOnly={readOnly || !possuiPmtRec}
                                        required={possuiPmtRec}
                                      />
                                    </div>

                                    <div className="col-lg-3 col-12">
                                      <Field
                                        component={PercentageInput}
                                        name={`receitaContratoRecorrente.divisoes[${index}].proporcao`}
                                        inputId={`receitaContratoRecorrente.divisoes[${index}].proporcao`}
                                        decimalScale={8}
                                        limitToHundred={true}
                                        decimalSeparator={","}
                                        label="Proporção (%)"
                                        readOnly={readOnly || !possuiPmtRec || pagamentoProporcionalRec == 'Não'}
                                        required={possuiPmtRec}
                                      />
                                    </div>
        
                                    {(!readOnly && possuiPmtRec && pagamentoProporcionalRec == 'Sim') && (
                                      <div className="col-12 col-lg-auto mr-3 d-flex align-items-center">
                                        <Button
                                          classes="rounded bg-white shadow-none border-0 text-danger mx-0 mxh-40 p-0 mb-2 mt-3 text-center text-capitalize"
                                          activeClassName={"col-lg-auto p-0"}
                                          buttonStyle={"success"}
                                          action={() => removeFaturarRecorrente(index)}
                                          icon={"trash"}
                                          name={"Remover"}
                                        />
                                      </div>
                                    )}
                                  </div>
                              </div>
                            </CSSTransition>
                          ))}
                      </TransitionGroup>
                    </div>

                    {(errorDivRecorrente && faturarRecorrente?.length > 0 && submitFailed) && (
                      <p className="error-msg sp-error text-danger f-12 mb-0 text-right mt-1">{ errorDivRecorrente }</p>
                    )}
    
                    {(!readOnly && possuiPmtRec && pagamentoProporcionalRec == 'Sim') && (
                      <>
                        <div className="mx-auto mt-3">
                          <Button
                            classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                            activeClassName={"col-lg-auto p-0"}
                            buttonStyle={"success"}
                            action={() => addFaturarRecorrente(faturarRecorrente.length)}
                            icon={"plus"}
                            name={"Adicionar"}
                          />
                        </div>

                        {(!faturarRecorrente?.length || hasErrorsValidation(errors)) && (<Field component={HiddenField} name="receitaContratoRecorrente.divisoes"></Field>)}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
    
            {/* Contato Faturamento */}
            <div className="col-12">
              <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
                <div className="column">
                  <span className="page-head__title h3 mb-3">Contato Faturamento</span>
                  <div className="d-flex flex-column w-100">
                    <div className="col more-fields p-0">
                      <TransitionGroup>
                        {contatos &&
                          contatos.map((item, index) => (
                            <CSSTransition key={index} timeout={300} classNames="item">
                              <div key={index} className="more-fields-item">
                                    <div className="row">
                                      <div className="col-12 col-lg">
                                        <Field
                                          component={Input}
                                          inputId="taxaJurosPagamentoList"
                                          name={`contatos[${index}].nome`}
                                          label="Nome"
                                          readOnly={statusValue == 2}
                                        />
                                      </div>
                                      <div className="col-12 col-lg">
                                        <Field
                                          label="Telefone"
                                          inputId="telefone"
                                          name={`contatos[${index}].telefone`}
                                          maxLength="15"
                                          readOnly={statusValue == 2}
                                          component={MaskedFormatInput}
                                          formatMask={customFormatPhone}
                                        />
                                      </div>
                                      <div className="col-12 col-lg">
                                        <Field
                                          component={Input}
                                          inputId="email"
                                          type={"email"}
                                          name={`contatos[${index}].email`}
                                          label="E-mail"
                                          readOnly={statusValue == 2}
                                        />
                                      </div>
          
                                      {!(statusValue == 2) && (
                                        <div className="col-12 col-lg-auto mr-3 d-flex align-items-center">
                                          <Button
                                            classes="rounded bg-white shadow-none border-0 text-danger mx-0 mxh-40 p-0 mb-2 mt-3 text-center text-capitalize"
                                            activeClassName={"col-lg-auto p-0"}
                                            buttonStyle={"success"}
                                            action={() => removeContato(index)}
                                            icon={"trash"}
                                            name={"Remover"}
                                          />
                                        </div>
                                      )}
                                    </div>
                              </div>
                            </CSSTransition>
                          ))}
                      </TransitionGroup>
                    </div>
    
                    {!(statusValue == 2) && (
                      <>
                        <div className="mx-auto mt-3">
                          <Button
                            classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                            activeClassName={"col-lg-auto p-0"}
                            buttonStyle={"success"}
                            action={() => addContato(contatos.length)}
                            icon={"plus"}
                            name={"Adicionar"}
                          />
                        </div>

                        {(!contatos?.length || hasErrorsValidation(errors)) && (<Field component={HiddenField} name="contatos"></Field>)}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* ----- */}
          <div className="col-12 px-0">
            <div className="d-flex flex-row justify-content-between align-items-center col-12 mb-4 mt-2 p-0">
              <Button
                name={`Cancelar ${floatButtonVisible && statusValue !== 2 ? "Alterações" : ""}`}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                action={() => {
                  floatButtonVisible
                    ? showConfirmDialog(true)
                    : showReceitaList();
                }}
              />

              {(floatButtonVisible && (!statusValue || statusValue == 1 || statusValue == 3 || statusValue == 4)) && (
                <Button
                  type="submit"
                  name={"Salvar Cadastro"}
                  classes="warn-bg-imp text-dark px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => {
                    const error = hasErrorsValidation(errors)
                    mudaDependenteOnChange("enviarParaAprovacao", false);

                    if (invalid || error) {
                      showMessageAlert("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.")

                      if (!contatos?.length) showMessageAlert("Adicione ao menos um contato", false)
                      if (!faturarFlat?.length && possuiPmtFlat && pagamentoProporcionalFlat !== null) showMessageAlert("Adicione ao menos um pagamento em Receita Flat", false)
                      if (!faturarRecorrente?.length && possuiPmtRec && pagamentoProporcionalRec !== null) showMessageAlert("Adicione ao menos um pagamento em Receita Recorrente", false)
                    }
                  }}
                />
              )}
              
              {(floatButtonVisible && (!statusValue || statusValue == 1 || statusValue == 4)) && (
                <Button
                  type="submit"
                  name={"Enviar para Aprovação"}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={() => {
                    const error = hasErrorsValidation(errors)
                    mudaDependenteOnChange("enviarParaAprovacao", true);

                    if (invalid && error) {
                      showMessageAlert("Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.")

                      if (!contatos?.length) showMessageAlert("Adicione ao menos um contato", false)
                      if (!faturarFlat?.length && possuiPmtFlat) showMessageAlert("Adicione ao menos um pagamento em Receita Flat", false)
                      if (!faturarRecorrente?.length && possuiPmtRec) showMessageAlert("Adicione ao menos um pagamento em Receita Recorrente", false)
                    }
                  }}
                />
              )}
            </div>
          </div>
        </FormContent>
        <ModalAction
          title="Aviso"
          open={showCancelConfirmDialog}
          close={() => showConfirmDialog(false)}
          message="Todos os dados modificados serão perdidos"
          action={() => showReceitaList(idsOperacoes)}
        />
      </Form>
    );
}

const selector = formValueSelector("ReceitaForm");

const mapStateToProps = (state) => ({
  // LISTS
  messages: state.receitaReducer.messages || [],
  statusList: state.receitaReducer.receitaFormTypes?.statusList || [],
  faturarContraList: state.receitaReducer.receitaFormTypes?.faturarContraList || [],
  contaOrigemList: state.receitaReducer.receitaFormTypes?.contaOrigemList || [],
  empresaFaturamentoList: state.receitaReducer.receitaFormTypes?.empresaFaturamentoList || [],
  taxaList: state.receitaReducer.receitaFormTypes?.taxaList || [],
  mesList: state.receitaReducer.receitaFormTypes?.mesList || [],
  periodicidadePagamentoList: state.receitaReducer.receitaFormTypes?.periodicidadePagamentoList || [],
  modalidadeList: state.receitaReducer.receitaFormTypes?.modalidadeList || [],
  formaPagamentoList: state.receitaReducer.receitaFormTypes?.formaPagamentoList || [],
  produtoReceitaList: state.receitaReducer.receitaFormTypes?.produtoReceitaList || [],
  origemRecursoList: state.receitaReducer.receitaFormTypes?.origemRecursoList || [],
  operacoesList: state.patrimonioReducer?.psOperacoesList || [],
  patrimoniosList: state.patrimonioReducer.patrimoniosOptions || [],
  fornecedorList: state.prestadoraReducer.fornecedores || [],
  operacoes: state.operacaoReducer.selectedOperacoes || [],
  
  // new
  errors: getFormSyncErrors('ReceitaForm')(state),
  contatos: selector(state, "contatos") || [],
  valorLiquidoRec: selector(state, "receitaContratoRecorrente.valorLiquido") || null,
  qntParcelaRec: selector(state, "receitaContratoRecorrente.quantidadeParcelas") || null,
  idOrigemRecursoRec: selector(state, "receitaContratoRecorrente.idOrigemRecurso") || null,
  idOrigemRecursoFlat: selector(state, "receitaContratoFlat.idOrigemRecurso") || null,
  possuiPagamentoFlat: selector(state, "possuiPagamentoFlat") || null,
  possuiPagamentoRecorrente: selector(state, "possuiPagamentoRecorrente") || null,
  formValues: getFormValues("ReceitaForm")(state),
  faturarFlat: selector(state, "receitaContratoFlat.divisoes") || [],
  faturarRecorrente: selector(state, "receitaContratoRecorrente.divisoes") || [],
  operacao: selector(state, "operacao"),
  patrimonioSeparado: selector(state, "patrimonioSeparado"),
  companhiaEmissora: selector(state, "companhiaEmissora"),
  dataInicioRec: selector(state, "receitaContratoRecorrente.dataInicio"),
  dataVencimentoRec: selector(state, "receitaContratoRecorrente.dataVencimento"),
  pagamentoProporcionalFlat: selector(state, "receitaContratoFlat.pagamentoProporcional"),
  pagamentoProporcionalRec: selector(state, "receitaContratoRecorrente.pagamentoProporcional"),
  possuiCorrecaoRecorrente: selector(state, "receitaContratoRecorrente.possuiCorrecao") || null,
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
  receitaContrato: state.receitaReducer.receitaFormTypes.receitaContrato || {},
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showReceitaList,
      showConfirmDialog,
      arrayInsert,
      arrayRemove,
      change,
      showMessageAlert,
      mudaDependenteOnChange,
      limpaDependentesOnChange,
    },
    dispatch
  );

const ConnectedReceitaForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReceitaForm))
);
export default reduxForm({ form: "ReceitaForm", validate })(
  ConnectedReceitaForm
);
