/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { newSubclasseForm, getSubclasses } from "../../../../actions/subclasse/SubclasseAction";
import Button from "../../../common/button/Button";
import Container from "../../../common/container/Container";
import ContainerRow from "../../../common/container/ContainerRow";
import SubclassePicker from "../../pickers/SubclassePicker";
import SubclasseList from "./list/SubclasseList";
import { authorizationFunction } from "../../../../actions/authorization/AuthorizationAction";
import { CREATE, SUBCLASSE } from "../../../../utils/actionsAndFeaturesAuth";

const Subclasse = (props) => (
  React.useEffect(() => {
    if (props.selectedSubclasses.length > 0 || props.page != 1) {
      const idsSubclasses = props.selectedSubclasses.map((o) => o.key);
      props.getSubclasses(idsSubclasses, props.page)
    } else {
      props.getSubclasses([])
    }
  }, []),

  <Container>
    <p className="page-head__title f-36 mb-0 mt-2 px-3 py-4">Subclasse</p>
    <div className="col-md my-2">
      <ContainerRow>
        <div className="shadow-custom rounded-12 w-100 pd-12 mx-3 mb-3 mt-0 d-flex flex-row justify-content-between align-items-end bg-white">
          <div className="d-flex flex-column w-100">
            <label className="f-12 text-dark mb-1">Busca</label>
            <SubclassePicker />
          </div>
          <Button
            classes="rounded text-white p-bg-imp mb-0 h-38 px-4 py-1"
            activeClassName={"col-lg-auto pr-0"}
            buttonStyle={"success"}
            action={() =>
              props.authorizationFunction(
                { idAcao: CREATE, idFuncionalidade: SUBCLASSE },
                () => props.newSubclasseForm()
              )
            }
            icon={"plus-circle"}
            name={"Cadastrar Subclasse"}
          />
        </div>
      </ContainerRow>
      <ContainerRow>
        <div className="col-md">
          <SubclasseList />
        </div>
      </ContainerRow>
    </div>
  </Container>
);

const mapStateToProps = (state) => ({
  selectedSubclasses: state.subclasseReducer.selectedSubclasseOptions,
  page: state.subclasseReducer.pagination,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      newSubclasseForm,
      authorizationFunction,
      getSubclasses,
      
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Subclasse));
