/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  loadFormFundo,
  saveFundo,
} from "../../../../../actions/fundo/FundoAction";
import FundoForm from "./FundoForm";

const FundoFormInclude = (props) => {
  const { readOnly, saveFundo, fundoFormTypes, loadFormFundo } = props;

  React.useEffect(() => {
    if (Object.keys(fundoFormTypes).length <= 0) loadFormFundo();
  }, []);

  return (
    <FundoForm
      title={"Novo - Fundo"}
      onSubmit={saveFundo}
      readOnly={readOnly}
      floatButtonVisible={true}
    />
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveFundo,
      loadFormFundo,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  fundoFormTypes: state.fundoReducer.fundoFormTypes,
});

export default connect(mapStateToProps, mapDispatchToProps)(FundoFormInclude);
