/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint jsx-a11y/alt-text: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../common/table/TableEmptyData";
import PaginationRB from "../../../../../common/pagination/PaginationRB";
import { Radio } from "@material-ui/core";
import {
  getDateFormatedFromStringWithTime,
  getMonthYearString,
} from "../../../../../../utils/helpers/DateTimeHelper";
import pdfIcon from "../../../../../../assets/img/extensions/pdf.svg";
import xlsxIcon from "../../../../../../assets/img/extensions/xlsx.svg";
import xmlIcon from "../../../../../../assets/img/extensions/xml.svg";

import {
  exportArquivo,
  exportControleToExcel,
  exportRegulatoriosModelo,
  loadRegulatorios,
  saveSelectedValues
} from "../../../../../../actions/relatorios/regulatorioAction";
import If from "../../../../../common/If/If";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { faEye, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { DETALHAR_ROUTE_PATH, EDITAR_ROUTE_PATH, REGULATORIO_ROUTE_PATH, RELATORIOS_ROUTE_PATH } from "../../../../../common/routers/constantesRoutes";
import { Link } from "react-router-dom";

const RegulatorioList = (props) => {
  const {
    regulatorioData,
    regulatoriosConsult,
    loadRegulatorios,
    messages,
    exportControleToExcel,
    exportRegulatoriosModelo,
    saveSelectedValues,
    selecteds,
    exportArquivo,
  } = props;

  const [selectedValues, setSelectedValues] = React.useState([]);
  const [allValuesSelecteds, setAllValuesSelecteds] = React.useState(false);

  const validData = regulatorioData?.items.filter((item) => item.status.key !== 1) || []

  React.useEffect(() => {
    clearSelects()
  }, [regulatorioData])

  function clearSelects() {
    setSelectedValues([]);
    setAllValuesSelecteds(false);
  }

  React.useEffect(() => {
    setAllValuesSelecteds(selectedValues.length === validData.length && validData.length !== 0)
    saveSelectedValues(selectedValues.map((item) => item.id));
  }, [selectedValues, validData.length]);

  React.useEffect(() => {
    clearSelects();
  }, [selecteds]);

  const handleRadioChange = (obj) => {
    if (selectedValues.includes(obj)) {
      const arr = selectedValues.filter((item) => item !== obj);
      setSelectedValues(arr);
    } else {
      setSelectedValues([...selectedValues, obj]);
    }
  };

  const selectAll = () => {
    setAllValuesSelecteds(!allValuesSelecteds);

    !allValuesSelecteds
      ? setSelectedValues(validData)
      : setSelectedValues([]);
  };

  const emptyData = messages && messages.infos && messages.infos[0];

  const isRadioSelected = (obj) => {
    const objString = JSON.stringify(obj);
    return selectedValues.some((item) => JSON.stringify(item) === objString);
  };

  const tableHeader = (
    <tr>
      <th className="text-center px-0 mx-0">
        <Radio
          className="pt-0 pb-1 px-3 mx-0"
          checked={allValuesSelecteds}
          onClick={() => selectAll()}
          icon={<CheckBoxOutlineBlank />}
          disabled={validData.length < 2}
          checkedIcon={<CheckBox style={{ color: "#83BF74" }} />}
        />
      </th>
      <th className="text-center fw-700 text-nowrap">PATRIMÔNIO SEPARADO</th>
      <th className="text-center fw-700 text-nowrap">DATA-BASE</th>
      <th className="text-center fw-700 text-nowrap">GESTOR</th>
      <th className="text-center fw-700 text-nowrap">DATA ÚLTIMO STATUS</th>
      <th className="text-center fw-700 text-nowrap">OBSERVAÇÃO</th>
      <th className="text-center fw-700 text-nowrap">AÇÕES</th>
    </tr>
  );

  const tableContent =
    regulatorioData && regulatorioData.hasItems ? (
      regulatorioData.items.map((item, index) => (
        <tr key={index} className={`tr-status`}>
          <td className="text-center px-0 mx-0">
            <Radio
              className="py-0 px-3 mx-0"
              onClick={() => handleRadioChange(item)}
              checked={isRadioSelected(item)}
              icon={<CheckBoxOutlineBlank />}
              disabled={item.status.key === 1}
              checkedIcon={<CheckBox style={{ color: "#83BF74" }} />}
            />
          </td>
          <td className="text-center fw-400">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <div className="mr-3 td-status">
                <div
                  data-type="light"
                  data-for={`ttp-list-${index}`}
                  data-tip={item.status.value}
                  className={`circle-status sts-regu-${item.status.key} rounded-circle mx-0`}
                />
                <ReactTooltip
                  noArrow
                  effect="solid"
                  id={`ttp-list-${index}`}
                  className="px-2 py-1"
                  globalEventOff="click"
                  style={{ width: "100px" }}
                />
              </div>
              <span>{item.patrimonioSeparado || "--"}</span>
            </div>
          </td>
          <td className="text-center fw-400 text-nowrap">
            {item?.dataBase ? getMonthYearString(item.dataBase) : "--"}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {item?.gestor}
          </td>
          <td className="text-center fw-400">
            {item?.dataFinal ? getDateFormatedFromStringWithTime(item.dataFinal) : "--"}
          </td>
          <td className="text-center fw-400" style={{ maxWidth: 300 }}>
            {item.observacao}
          </td>
          <td className="text-center fw-400 text-nowrap pb-2">
            <Link
              className="p-color-imp f-18 mx-1"
              to={`/${RELATORIOS_ROUTE_PATH}/${REGULATORIO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/${item.id}`}
            >
              <FontAwesomeIcon color="#630a37" icon={faPencilAlt} />
            </Link>

            <Link
              className="p-color-imp f-18 mx-1"
              to={`/${RELATORIOS_ROUTE_PATH}/${REGULATORIO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/${item.id}`}
            >
              <FontAwesomeIcon color="#630a37" icon={faEye} />
            </Link>

            <img
              role="button"
              src={pdfIcon}
              className="px-1 mb-1"
              style={{ width: 27 }}
              onClick={() => exportArquivo('pdf', item.id)}
            />
            <img
              role="button"
              src={xlsxIcon}
              className="px-1 mb-1"
              style={{ width: 30 }}
              onClick={() => exportArquivo('xlsx', item.id)}
            />
            <img
              role="button"
              src={xmlIcon}
              className="px-1 mb-1"
              style={{ width: 30 }}
              onClick={() => exportArquivo('xml', item.id)}
            />
          </td>
        </tr>
      ))
    ) : (
      <TableEmptyData message={emptyData} />
    );

  return (
    <div className="w-100 p-4">
      <If test={regulatorioData.totalCount > 0}>
        <Table
          withoutHeader
          files={[
            { label: 'Exportar Controle em Excel', function: () => exportControleToExcel(regulatoriosConsult) },
            { label: 'Baixar Modelo Servicer', function: () => exportRegulatoriosModelo("service") },
            { label: 'Baixar Modelo Contábil', function: () => exportRegulatoriosModelo("contabil") },
            { label: 'Baixar Modelo Outros Dados', function: () => exportRegulatoriosModelo("outros-dados") },
          ]}
          classes="shadow-custom rounded-12 col-12 mb-3 mt-0 pd-10 bg-white"
          totalItems={regulatorioData.totalCount}
          pagination={
            <PaginationRB
              pageNumber={regulatorioData.pageIndex - 1}
              itemsPerPage={regulatorioData.pageSize}
              totalItems={regulatorioData?.totalCount || 0}
              filter={regulatoriosConsult}
              fetchFunction={loadRegulatorios}
              pageRange={5}
            />
          }
        >
          <TableHeader>{tableHeader}</TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      </If>
    </div>
  );
};

const mapStateToProps = (state) => ({
  regulatorioData: state.regulatorioReducer.regulatorios,
  selecteds: state.regulatorioReducer.selectedsCount,
  messages: state.agingReducer.messages,
  regulatoriosConsult: state.regulatorioReducer?.regulatoriosConsult,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadRegulatorios,
      exportControleToExcel,
      exportRegulatoriosModelo,
      exportArquivo,
      saveSelectedValues,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(RegulatorioList);
