import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../common/container/ContainerPage";

import HistoricoListFilter from "./form/HistoricoListFilter";
import HistoricoList from "./list/HistoricoList";
import { resetPagina } from "../../../../actions/historicocotas/HistoricoAction";

class HistoricoPage extends Component {
  componentDidMount() {
    this.props.resetPagina();
  }

  render() {
    return (
      <ContainerPage>
        <HistoricoListFilter />
        <HistoricoList />
      </ContainerPage>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  resetPagina
}, dispatch);

export default connect(null, mapDispatchToProps)(HistoricoPage);
