import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { loadPagamentoForm } from "../../../../../../actions/precificacao/PagamentoAction";

import PagamentoFasePagamentoPage from "./PagamentoFasePagamentoPage";

class PagamentoFasesPage extends React.Component {
  componentDidMount() {
    this.props.loadPagamentoForm(this.props.match.params.idPrecificacao);
  }

  render() {
    return (
      <div className="mt-3">
        <PagamentoFasePagamentoPage />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  idPrecificacao: state.pagamentoReducer.pagamentoFormTypes.idPrecificacao,
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadPagamentoForm,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(PagamentoFasesPage);
