import React from 'react';
import { Route, Switch } from "react-router-dom";
import {
    DIARIO_BORDO_ROUTE_PATH
} from '../../../common/routers/constantesRoutes';
import DiarioBordoPage from '../pages/diarioBordo/DiarioBordoPage';

export default () => (
    <Switch>
        {/* Diário de Bordo */}
        <Route exact={true} path={`/${DIARIO_BORDO_ROUTE_PATH}`} component={DiarioBordoPage} />
    </Switch>
);