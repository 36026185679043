/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, change } from "redux-form";
import { bindActionCreators } from "redux";

import Input from "../../../../../../common/input/Input";
import MaskedInput from "../../../../../../common/input/MaskedInput";
import RegrasMonitoramentosList from "./RegrasMonitoramentosList";
import DraggableDialog from "./IncludeRegraMonitoramentoModal";
import If from "./../../../../../../common/If/If";

import {
  filtrarTitulosComplementoDeAcordoComOTipo,
  showModalComplementoRegraDialog,
  showModalComplementoContatoDialog,
  obterDataVencimentoOperacao,
} from "../../../../../../../actions/operacao/ComplementoAction";

import DropdownListInput from "../../../../../../common/input/DropdownListInput";
import ContatosMonitoramentosList from "./ContatosMonitoramentosList";
import ContatoMonitoramentoDialogModal from "./ContatoMonitoramentoDialogModal";
import Textarea from "../../../../../../common/input/Textarea";
import Button from "../../../../../../common/button/Button";

const TIPO_MONITORAMENTO_UNICO = 1;
const TIPO_MONITORAMENTO_RECORRENTE = 2;

const NOME_OBJETO_COMPLEMENTO_MONITORAMENTO = "complementoMonitoramento";
const NOME_FORM_COMPLEMENTO = "complementoForm";

const ENCERRRAMENTO_OPERACAO = 1;

class ComplementoFormGeralMonitoramento extends Component {
  limparCamposMonitoramento(monitorar, limparTipoMonitoramento = true) {
    if (monitorar && monitorar[0] === "N") {
      this.props.change(
        NOME_FORM_COMPLEMENTO,
        `${NOME_OBJETO_COMPLEMENTO_MONITORAMENTO}.dataValidade`,
        null
      );
      this.props.change(
        NOME_FORM_COMPLEMENTO,
        `${NOME_OBJETO_COMPLEMENTO_MONITORAMENTO}.dataPrimeiraRenovacao`,
        null
      );
      this.props.change(
        NOME_FORM_COMPLEMENTO,
        `${NOME_OBJETO_COMPLEMENTO_MONITORAMENTO}.antecedencia`,
        null
      );
      this.props.change(
        NOME_FORM_COMPLEMENTO,
        `${NOME_OBJETO_COMPLEMENTO_MONITORAMENTO}.recorrencia`,
        null
      );
      this.props.change(
        NOME_FORM_COMPLEMENTO,
        `${NOME_OBJETO_COMPLEMENTO_MONITORAMENTO}.emailResponsavel`,
        null
      );
      this.props.change(
        NOME_FORM_COMPLEMENTO,
        `${NOME_OBJETO_COMPLEMENTO_MONITORAMENTO}.descricao`,
        null
      );
      this.props.change(
        NOME_FORM_COMPLEMENTO,
        `${NOME_OBJETO_COMPLEMENTO_MONITORAMENTO}.monitorarAte`,
        null
      );
      this.props.change(
        NOME_FORM_COMPLEMENTO,
        `${NOME_OBJETO_COMPLEMENTO_MONITORAMENTO}.unidade`,
        null
      );
      this.props.change(
        NOME_FORM_COMPLEMENTO,
        `${NOME_OBJETO_COMPLEMENTO_MONITORAMENTO}.periodicidade`,
        null
      );

      if (limparTipoMonitoramento)
        this.props.change(
          NOME_FORM_COMPLEMENTO,
          `${NOME_OBJETO_COMPLEMENTO_MONITORAMENTO}.tipoMonitoramento`,
          null
        );
    }
  }

  limparCamposMonitoramentoPorTipoMonitoramento(tipoMonitoramentoSelected) {
    this.limparCamposMonitoramento("N", false);
  }

  wholeNumber(value) {
    const { formattedValue } = value;
    return formattedValue.indexOf(".") == -1;
  }

  render() {
    const {
      opcaoMonitorar,
      readOnly,
      monitoramentoRegrasList,
      modalComplementoRegra,
      showModalComplementoRegraDialog,
      tipoMonitoramentoList,
      opcaoTipoMonitoramento,
      monitoramentoContatoList,
      showModalComplementoContatoDialog,
      modalComplementoContato,
      monitorarAteList,
      idOperacao,
      obterDataVencimentoOperacao,
      unidadeList,
    } = this.props;

    let complementoGeralMonitoramento = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Monitoramento</span>
          <div className="column mt-2">
            <div className="row">
              <div className="col-lg-3">
                <Field
                  component={DropdownListInput}
                  inputId="opcaoMonitorar"
                  name="complementoMonitoramento.monitorar"
                  label="Monitorar Complemento?"
                  readOnly={readOnly}
                  onChange={(e) => this.limparCamposMonitoramento(e)}
                />
              </div>

              <If test={opcaoMonitorar === "Sim"}>
                <div className="col-lg-2">
                  <Field
                    component={DropdownListInput}
                    inputId="tipoMonitoramento"
                    name="complementoMonitoramento.tipoMonitoramento"
                    label="Tipo Monitoramento"
                    options={tipoMonitoramentoList}
                    textField="value"
                    filter="contains"
                    valueField="key"
                    readOnly={readOnly ? readOnly : opcaoMonitorar === "Não"}
                    onChange={(e) =>
                      this.limparCamposMonitoramentoPorTipoMonitoramento(e)
                    }
                  />
                </div>
              </If>

              <If
                test={
                  opcaoTipoMonitoramento &&
                  opcaoTipoMonitoramento.key === TIPO_MONITORAMENTO_RECORRENTE
                }
              >
                <div className="col-lg-3">
                  <Field
                    component={DropdownListInput}
                    inputId="monitorarAte"
                    name="complementoMonitoramento.monitorarAte"
                    label="Tipo(monitorar até)"
                    options={monitorarAteList}
                    textField="value"
                    filter="contains"
                    valueField="key"
                    readOnly={readOnly ? readOnly : opcaoMonitorar === "Não"}
                    onChange={(e) => {
                      if (idOperacao && (e.key && e.key === ENCERRRAMENTO_OPERACAO)) {
                        obterDataVencimentoOperacao(idOperacao);
                      }
                    }}
                  />
                </div>

                <div className="col-lg-2">
                  <Field
                    inputId="dataValidade"
                    name="complementoMonitoramento.dataValidade"
                    type={"date"}
                    label="Monitorar até"
                    component={Input}
                    readOnly={readOnly ? readOnly : opcaoMonitorar === "Não"}
                  />
                </div>
              </If>

              <If
                test={
                  opcaoTipoMonitoramento &&
                  (opcaoTipoMonitoramento.key === TIPO_MONITORAMENTO_UNICO ||
                    opcaoTipoMonitoramento.key ===
                      TIPO_MONITORAMENTO_RECORRENTE)
                }
              >
                <div className="col-lg-2">
                  <Field
                    inputId="dataPrimeiraRenovacao"
                    name="complementoMonitoramento.dataPrimeiraRenovacao"
                    type={"date"}
                    label="Data Renovação"
                    component={Input}
                    readOnly={readOnly ? readOnly : opcaoMonitorar === "Não"}
                  />
                </div>
              </If>
            </div>
            <div className="row">
              <If
                test={
                  opcaoTipoMonitoramento &&
                  (opcaoTipoMonitoramento.key === TIPO_MONITORAMENTO_UNICO ||
                    opcaoTipoMonitoramento.key ===
                      TIPO_MONITORAMENTO_RECORRENTE)
                }
              >
                <div className="col-lg-5">
                  <Field
                    component={MaskedInput}
                    inputId="antecedencia"
                    name="complementoMonitoramento.antecedencia"
                    type="text"
                    readOnly={readOnly ? readOnly : opcaoMonitorar === "Não"}
                    label="Dias de Antecedência"
                    maxLength={4}
                    allowNegative={false}
                    isAllowed={(value) => this.wholeNumber(value)}
                  />
                </div>
              </If>
              <If
                test={
                  opcaoTipoMonitoramento &&
                  opcaoTipoMonitoramento.key === TIPO_MONITORAMENTO_RECORRENTE
                }
              >
                <div className="col-lg-3">
                  <Field
                    component={DropdownListInput}
                    inputId="unidade"
                    name="complementoMonitoramento.unidade"
                    label="Unidade da recorrência"
                    options={unidadeList}
                    textField="value"
                    filter="contains"
                    valueField="key"
                    readOnly={readOnly ? readOnly : opcaoMonitorar === "Não"}
                  />
                </div>

                <div className="col-lg-4">
                  <Field
                    component={MaskedInput}
                    inputId="periodicidade"
                    name="complementoMonitoramento.periodicidade"
                    type="text"
                    label="Periodicidade da recorrência"
                    readOnly={readOnly ? readOnly : opcaoMonitorar === "Não"}
                    maxLength={4}
                    allowNegative={false}
                    isAllowed={(value) => this.wholeNumber(value)}
                  />
                </div>
              </If>

              <If
                test={
                  opcaoTipoMonitoramento &&
                  (opcaoTipoMonitoramento.key === TIPO_MONITORAMENTO_UNICO ||
                    opcaoTipoMonitoramento.key ===
                      TIPO_MONITORAMENTO_RECORRENTE)
                }
              >
                <div className="col-lg-12">
                  <Field
                    component={Textarea}
                    inputId="descricao"
                    name="complementoMonitoramento.descricao"
                    label="Título Monitoramento"
                    readOnly={readOnly}
                    type="text"
                    rows={4}
                    maxLength={500}
                    minHeight={4}
                  />
                </div>
              </If>

              <If
                test={
                  opcaoTipoMonitoramento &&
                  (opcaoTipoMonitoramento.key === TIPO_MONITORAMENTO_UNICO ||
                    opcaoTipoMonitoramento.key ===
                      TIPO_MONITORAMENTO_RECORRENTE)
                }
              >
                <div className="col-lg-12">
                  <Field
                    component={Textarea}
                    inputId="dadosAdicionais"
                    name="complementoMonitoramento.dadosAdicionais"
                    label="Informações Adicionais"
                    readOnly={readOnly}
                    type="text"
                    rows={5}
                    maxLength={500}
                    minHeight={5}
                  />
                </div>
              </If>
            </div>

            <div className="more-fields my-8">
              <div>
                <If test={opcaoMonitorar === "Sim" && !readOnly}>
                  <div className="mx-auto mt-3">
                    <Button
                      classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                      activeClassName={"col-lg-auto p-0"}
                      buttonStyle={"success"}
                      action={() => showModalComplementoContatoDialog(true, -1)}
                      icon={"plus"}
                      name={"Adicionar Contatos"}
                    />
                  </div>
                  <ContatoMonitoramentoDialogModal
                    showDialog={modalComplementoContato.showDialog}
                    onClose={() => showModalComplementoContatoDialog(false, -1)}
                  />
                </If>
              </div>

              <If test={monitoramentoContatoList.length > 0}>
                <ContatosMonitoramentosList
                  readOnly={readOnly}
                  dataList={monitoramentoContatoList}
                />
              </If>

              <div>
                <If test={opcaoMonitorar === "Sim" && !readOnly}>
                  <div className="mx-auto mt-3">
                    <Button
                      classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                      activeClassName={"col-lg-auto p-0"}
                      buttonStyle={"success"}
                      action={() => showModalComplementoRegraDialog(true, -1)}
                      icon={"plus"}
                      name={"Adicionar Regras"}
                    />
                  </div>
                  <DraggableDialog
                    showDialog={modalComplementoRegra.showDialog}
                    onClose={() => showModalComplementoRegraDialog(false, -1)}
                  />
                </If>
              </div>

              <If test={monitoramentoRegrasList.length > 0}>
                <RegrasMonitoramentosList
                  readOnly={readOnly}
                  dataList={monitoramentoRegrasList}
                />
              </If>
            </div>
          </div>
        </div>
      </div>
    );

    return <div>{complementoGeralMonitoramento}</div>;
  }
}

const selector = formValueSelector("complementoForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      filtrarTitulosComplementoDeAcordoComOTipo,
      showModalComplementoRegraDialog,
      showModalComplementoContatoDialog,
      change,
      obterDataVencimentoOperacao,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  // idOperacao: selector(state, "idOperacao"),
  opcaoMonitorar: selector(state, "complementoMonitoramento.monitorar"),
  opcaoTipoMonitoramento: selector(
    state,
    "complementoMonitoramento.tipoMonitoramento"
  ),
  monitoramentoRegrasList:
    selector(state, "complementoMonitoramento.monitoramentoRegrasList") || [],
  monitoramentoContatoList:
    selector(state, "complementoMonitoramento.monitoramentoContatoList") || [],
  statusOperacao: selector(state, "statusOperacao"),
  tituloComplementoList:
    state.complementoReducer.complementoFormTypes.tituloComplementoList,
  tituloParaTipoGarantiaOuGarantiaAcessoriaList:
    state.complementoReducer.complementoFormTypes
      .tituloParaTipoGarantiaOuGarantiaAcessoriaList,
  tituloParaTipoComplementoRatingList:
    state.complementoReducer.complementoFormTypes
      .tituloParaTipoComplementoRatingList,
  tituloParaTipoComplementoObrigacoesOuCovenantList:
    state.complementoReducer.complementoFormTypes
      .tituloParaTipoComplementoObrigacoesOuCovenantList,
  modalComplementoRegra: state.complementoReducer.showModalComplementoRegra,
  tipoMonitoramentoList:
    state.complementoReducer.complementoFormTypes.tipoMonitoramentoList,
  modalComplementoContato: state.complementoReducer.showModalComplementoContato,
  monitorarAteList:
    state.complementoReducer.complementoFormTypes.monitorarAteList,
  unidadeList: state.complementoReducer.complementoFormTypes.unidadeList || [],
  idOperacao: state.complementoReducer.complementoFormTypes.complementoOperacao?.idOperacao || null,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplementoFormGeralMonitoramento);
