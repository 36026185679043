/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import { validacaoEmail } from "../../../../../../utils/helpers/EmailHelper";
import {
  getCurrentDateTimeStringDefault,
  getDateFormatedFromString,
} from "../../../../../../utils/helpers/DateTimeHelper";
import { turnFileToBase64 } from "../../../../../../utils/helpers/FileHelper";
import { acoesPagamentoAF } from "../../../../../../actions/precificacao/PagamentoAction";

let ModalExternoEnviarEmailAF = (props) => {
  const { close, open, pagamento, motivo, acoesPagamentoAF, idExternoAF } =
    props;

  const [email, setEmail] = React.useState("");

  return (
    <>
      <Dialog maxWidth="sm" open={open} onClose={() => close()}>
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              {(pagamento.tipoModal === "Aprovar" && "Aprovar") || "Revisar"}{" "}
              Evento de Pagamento
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-3 text-dark f-16 fw-400">
            <p className="m-0">
              Você está prestes a
              {(pagamento.tipoModal === "Aprovar" && " aprovar ") ||
                " enviar para revisão "}
              os valores do evento de pagamento do dia{" "}
              {getDateFormatedFromString(pagamento.dataPagamento)} para a
              operação {pagamento.codigoOpea} - {pagamento.apelido}{" "}
              <span className="text-nowrap">
                (Código IF: {pagamento.codigoIf}).
              </span>
            </p>
            <p className="my-3">
              Para{" "}
              {(pagamento.tipoModal === "Aprovar" && "aprovar") || "enviar"}{" "}
              digite seu e-mail no campo abaixo.
            </p>

            <div className="row">
              <div className="col-12">
                <div className="form-group field-floating has-float-label">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="email" className="control-label">
                    E-mail
                    <span className="text-warning font-weight-bold ml-1">
                      *
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={`${pagamento.tipoModal}`}
              action={async () => {
                if (validacaoEmail(email)) {
                  if (pagamento.tipoModal == "Aprovar") {
                    acoesPagamentoAF(
                      {
                        isExternal: true,
                        emailAprovacao: email,
                        enviarEmail: true,
                        id: idExternoAF,
                      },
                      "aprovar"
                    );
                  } else {
                    let file = motivo.arquivoMotivo;
                    let fileName = motivo.arquivoMotivo?.name;

                    let { arquivoMotivo, ...newMotivo } = motivo;

                    acoesPagamentoAF(
                      {
                        ...newMotivo,
                        isExternal: true,
                        emailAprovacao: email,
                        enviarEmail: true,
                        nomeArquivoAnexoRevisao: fileName,
                        arquivoAnexoRevisao: file
                          ? await turnFileToBase64(file)
                          : null,
                        tipoArquivoAnexoRevisao: fileName
                          ?.substring(fileName?.lastIndexOf(".") + 1)
                          .toLowerCase(),
                        id: idExternoAF,
                        dataRevisao: getCurrentDateTimeStringDefault(),
                      },
                      "enviar"
                    );
                  }

                  close();
                }
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  idExternoAF: state.pagamentoReducer.externoPagamentoAF?.idExternoAF,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      acoesPagamentoAF,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ModalExternoEnviarEmailAF);
