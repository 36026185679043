/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import {
  deleteOrdemAssembleia,
  exportOrdensAssembleiaToExcel,
  getOrdensAssembleia,
} from "../../../../../actions/assembleia/AssembleiaAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../common/button/Button";
import ModalCriacaoOrdem from "../modals/ModalCriacaoOrdem";
import ModalAction from "../../../../common/modal/ModalAction";
import { newFormatPercent } from "../../../../../utils/helpers/NumberHelper";

const OrdensAssembleiaList = (props) => {
  const {
    readOnly,
    idAssembleia,
    getOrdensAssembleia,
    ordensAssembleiaData,
    deleteOrdemAssembleia,
    exportOrdensAssembleiaToExcel,
  } = props;

  const [selectedOrdem, setSelectedOrdem] = React.useState(null);
  const [modalOrdem, setModalOrdem] = React.useState(false);
  const [removeOrdemId, setRemoveOrdemId] = React.useState(null);

  const arrHeader = [
    "ASSUNTO",
    "TIPO QUÓRUM",
    "APROVAÇÃO",
    "DESCRIÇÃO",
    !readOnly ? 'AÇÕES' : null,
  ];

  const tableHeader = (
    <tr>
      {arrHeader.filter((item) => item).map((item, i) => (
        <th key={i}>
          <div className="text-center fw-700 d-flex justify-content-center w-100">
            {item}
          </div>
        </th>
      ))}
    </tr>
  );

  const tableContent =
    ordensAssembleiaData &&
    ordensAssembleiaData.hasItems &&
    ordensAssembleiaData.items.map((item, index) => (
      <tr key={index}>
        <td className="text-center fw-400">{item?.assembleiaAssunto?.assunto || "--"}</td>
        <td className="text-center fw-400">{item?.tipoQuorum?.value || "--"}</td>
        <td className="text-center fw-400">{item?.quorumAprovacao ? newFormatPercent(item.quorumAprovacao, 4) : "--"}</td>
        <td className="text-center fw-400">{item?.descricao || "--"}</td>
        
        {!readOnly && (
          <td className="text-center">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <FontAwesomeIcon
                color="#630a37"
                size="lg"
                className="mx-2"
                icon={faPencilAlt}
                role="button"
                onClick={() => {
                  setSelectedOrdem(item);
                  setModalOrdem(true);
                }}
              />

              <FontAwesomeIcon
                color="#630a37"
                size="lg"
                className="mx-2"
                icon={faTrashAlt}
                role="button"
                onClick={() => setRemoveOrdemId(item?.id)}
              />
            </div>
          </td>
        )}
      </tr>
    ));

  return (
    <div className="container-fluid">
      <div>
        {!readOnly && (
          <Button
            type="button"
            name={"Adicionar"}
            classes="default-bg-imp px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 my-2"
            action={() => {
              setSelectedOrdem(null);
              setModalOrdem(true);
            }}
          />
        )}

        <div>
          {ordensAssembleiaData?.totalCount > 0 ? (
            <Table
              withoutHeader
              files={[
                { label: 'Exportar para Excel', function: () => exportOrdensAssembleiaToExcel(idAssembleia) }
              ]}
              classes="shadow-none col-12 mt-xl-2 mt-1 mb-3 pd-10 bg-white"
              totalItems={ordensAssembleiaData.totalCount}
              pagination={
                <PaginationRB
                  pageNumber={ordensAssembleiaData.pageIndex - 1}
                  itemsPerPage={ordensAssembleiaData.pageSize}
                  totalItems={ordensAssembleiaData?.totalCount}
                  filter={idAssembleia}
                  fetchFunction={getOrdensAssembleia}
                  pageRange={5}
                />
              }
            >
              <TableHeader>{tableHeader}</TableHeader>
              <TableContent>{tableContent}</TableContent>
            </Table>
          ) : <p className="py-2 m-0 fw-400">Sem ordens vinculadas</p> }
        </div>
      </div>

      {/* MODAL CRIA/EDITA ASSEMBLEIA */}
      {modalOrdem && (
        <ModalCriacaoOrdem
          item={selectedOrdem}
          idAssembleia={idAssembleia}
          open={modalOrdem}
          close={() => {
            setModalOrdem(false);
            setSelectedOrdem(null);
          }}
        />
      )}

      {/* MODAL REMOVE ORDEM */}
      <ModalAction
        title="Remover Ordem"
        open={Boolean(removeOrdemId)}
        close={() => setRemoveOrdemId(false)}
        action={async () => {
          setRemoveOrdemId(false)
          await deleteOrdemAssembleia(removeOrdemId);
          getOrdensAssembleia(idAssembleia)
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ordensAssembleiaData: state.assembleiaReducer.ordensAssembleia,
  messages: state.assembleiaReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrdensAssembleia,
      deleteOrdemAssembleia,
      exportOrdensAssembleiaToExcel,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(OrdensAssembleiaList);
