/* eslint eqeqeq: 0 */

import { getDateFormatedFromString, getDateNormalInvertedFormatedFromString, getDateTimeString, getDateTimeStringNormalized} from "../../../../utils/helpers/DateTimeHelper";
import normalizeDecimals, { convertCurrencyToNumber, formatPercentToNumber, formatDecimal, convertCurrencyNumber } from "../../../../utils/helpers/NumberHelper";
import { getBooleanValueFromString } from "../../../../utils/helpers/BooleanHelper";

export function deserializeFieldsPagamentoPrecificacaoFormFilter(filters, page) {

    return {
        ...filters,
        // SEC
        registroDaSemana: filters && filters.registroDaSemana,
        operacoes: filters && filters.operacoes ? filters.operacoes.map(o => o.key) : [],
        idsPatrimoniosSeparados: filters && filters.idsPatrimoniosSeparados ? filters.idsPatrimoniosSeparados.map(o => o.key) : [],
        usuarioGestorOperacaoId: (filters && filters.usuarioGestorOperacaoId?.id) || null,
        usuarioServicoCreditoId: (filters && filters.usuarioServicoCreditoId?.id) || null,
        usuarioResponsavelId: (filters && filters.usuarioResponsavelId?.id) || null,
        tipoComplementoId: (filters && filters.tipoComplementoId?.id) || null,
        tituloComplementoId: (filters && filters.tituloComplementoId?.id) || null,
        agendaStatusId: (filters && filters.agendaStatusId?.id) || null,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        },
    };
}

// 

export function deserializeFieldsPagamentoPrecificacaoFormFilterExportExcel(filters) {
    return {
        idsOperacao: filters && filters.operacao ? filters.operacao.map(o => o.key) : [],
        dataInicio: filters.dataInicio,
        dataFim: filters.dataFim,
        etapaPagamentoPrecificacao: filters.etapaPagamentoPrecificacao,
    };
}