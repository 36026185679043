import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  editAtivo,
  loadFormAtivo,
} from "../../../../../actions/ativo/AtivoAction";
import AtivoForm from "./AtivoForm";

let AtivoFormUpdate = (props) => {
  const { loadFormAtivo, readOnly, editAtivo, match } = props;

  React.useEffect(() => {
    let idAtivo = match.params.idAtivo;
    loadFormAtivo(idAtivo);
  }, []);

  return (
    <AtivoForm
      title={"Editar - Ativo"}
      onSubmit={editAtivo}
      readOnly={readOnly}
      floatButtonVisible={true}
    />
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editAtivo,
      loadFormAtivo,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispatchToProps)(AtivoFormUpdate));
