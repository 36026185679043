import React from "react";
import { connect } from "react-redux";

let DominioListFilter = () => {
  return (
    <div className="w-100 px-4 py-3 mx-2">
      <h3 className="my-0 page-head__title f-36">Domínios</h3>
    </div>
  );
};

export default connect(null, null)(DominioListFilter);
