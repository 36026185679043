import axios from "axios";
import { initialize, change, formValueSelector } from "redux-form";

import history from '../../components/common/routers/history';
import {
    deserializeFieldsContasReceberForm,
    deserializeFieldsContasReceberFormFilter, serializeContasOrigem, serializeContratoReceita, serializeFieldsContasReceberFluxo,
} from "../../components/business/contasReceber/mappings/ContasReceberMap";

import { showAllMessages } from '../message/ErrorMessagesAPIAction';

import {
    LISTA_ROUTE_PATH,
    CONTAS_RECEBER_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";

import {
    downloadExcelFile,
    downloadFile,
} from "../../utils/helpers/FileHelper";
import { calculateDate } from "../../utils/helpers/DateTimeHelper";
import { showConfirmDialog } from "../common/modal/ConfirmDialogAction";
import { addZerosAEsquerda, msgError } from "../../utils/helpers/OthersHelpers";
import { changeMessage } from "../infra/LoadingScreenAction";

export const LOAD_FILTER_FORM_CONTAS_RECEBER = 'LOAD_FILTER_FORM_CONTAS_RECEBER';
export const CONTAS_RECEBER_CONSULT_CURRENT = 'CONTAS_RECEBER_CONSULT_CURRENT';
export const CONTAS_RECEBER_FETCHED = 'CONTAS_RECEBER_FETCHED';
export const SALVA_FILTROS_CONTAS_RECEBER = 'SALVA_FILTROS_CONTAS_RECEBER';
// 
export const CONTAS_RECEBER_RESET = 'CONTAS_RECEBER_RESET';
export const LOAD_TELA_FLUXOS_CONTAS_RECEBER = 'LOAD_TELA_FLUXOS_CONTAS_RECEBER';
export const INDICADORES_CONTAS_RECEBER_FETCHED = 'INDICADORES_CONTAS_RECEBER_FETCHED';
export const SAVE_FORM_CONTAS_RECEBER_ERRORS = 'SAVE_FORM_CONTAS_RECEBER_ERRORS';
export const CHANGE_LIST_RECEITAS = 'CHANGE_LIST_RECEITAS';
export const CHANGE_LIST_FORNECEDORES = 'CHANGE_LIST_FORNECEDORES';
export const CHANGE_LIST_CONTA_ORIGEM = 'CHANGE_LIST_CONTA_ORIGEM';
export const CHANGE_LIST_CONTA_DESTINO = 'CHANGE_LIST_CONTA_DESTINO';
export const CHANGE_LIST_OPERACOES = 'CHANGE_LIST_OPERACOES';

// 

const CONTAS_RECEBER_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_CONTAS_RECEBER_API;

const OPERACAO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API;
  
const RECEITA_API = OPERACAO_API + process.env.REACT_APP_PATH_RECEITA_API;

const PATRIMONIO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PATRIMONIO_API;

function updateFilters() {
  return (dispatch, getState) => {
    const contasReceberConsultCurrent = getState().contasReceberReducer.contasReceberConsultCurrent

    dispatch([
      change('ContasReceberForm', 'dataInicio', contasReceberConsultCurrent?.dataInicio || ''),
      change('ContasReceberForm', 'dataFinal', contasReceberConsultCurrent?.dataFinal || ''),
      change('ContasReceberForm', 'identificador', contasReceberConsultCurrent?.identificador || ''),
    ]);
  }
}

export function loadFilterFormContasReceber() {
  return (dispatch) => {
    dispatch(changeMessage("Carregando Filtros das Contas a Receber"))
    // updateFilters();
    return axios.get(`${CONTAS_RECEBER_API}tela/filtro`).then((result) =>
      dispatch([
        {
          type: LOAD_FILTER_FORM_CONTAS_RECEBER,
          payload: result.content,
        },
        // updateFilters(),
      ])
    );
  };
}

export function getContasReceber(filters, page = 1) {
    const params = deserializeFieldsContasReceberFormFilter(filters, page);
    
    return (dispatch) => {
        dispatch(changeMessage("Carregando Contas a Receber"))
        return axios
            .post(`${CONTAS_RECEBER_API}buscar`, params)
            .then(result =>
                dispatch([
                    {
                        type: CONTAS_RECEBER_FETCHED,
                        payload: result,
                    },
                    {
                        type: CONTAS_RECEBER_CONSULT_CURRENT,
                        payload: {...filters, pagingConfig: { pageIndex: page, pageSize: 10 }},
                    },
                ])
            );
    }
}

export function salvaFiltrosContasReceber(filters) {
    return (dispatch, getState) => {
        return dispatch([
            {
                type: SALVA_FILTROS_CONTAS_RECEBER,
                payload: filters
            },
        ]);
    }
}

export function onDropDocument(arquivos) {
    return (dispatch, getState) => {
        const selector = formValueSelector("ContasReceberForm");
        const documentos = selector(getState(), "contaReceber.documentoList") || [];

        dispatch([
            change("ContasReceberForm", "contaReceber.documentoList", [...documentos, ...arquivos])
        ])
    }
}

export function onDeleteDocument(index) {
    return (dispatch, getState) => {
        const selector = formValueSelector("ContasReceberForm");
        const documentos = selector(getState(), "contaReceber.documentoList") || [];

        const newFiles = [...documentos];
        newFiles.splice(index, 1);

        dispatch([
            change("ContasReceberForm", "contaReceber.documentoList", newFiles)
        ])
    }
}

export function loadContasReceberForm(idContasReceber) {
    return dispatch => {
        dispatch(changeMessage("Carregando Conta a Receber"))
        return axios.get(CONTAS_RECEBER_API + `tela/${idContasReceber || ''}`)
            .then(result => {
                const data = result.content;
                const values = serializeFieldsContasReceberFluxo(data);

                dispatch([
                    {
                        type: LOAD_TELA_FLUXOS_CONTAS_RECEBER,
                        payload: {}
                    },
                ]);

                if (result.messages.infos.length > 0) {
                    dispatch([
                        showAllMessages(result.messages),
                        sairDaPagina(),
                    ]);
                } else {
                    dispatch([
                        showAllMessages(result.messages),
                        initialize("ContasReceberForm", values),
                        {
                            type: LOAD_TELA_FLUXOS_CONTAS_RECEBER,
                            payload: values
                        },
                    ]);
                }

            });
    };
}

export function saveContasReceber(contaReceber) {
    const contaReceberDeserialized = deserializeFieldsContasReceberForm(contaReceber);
  
    return (dispatch) => {
      dispatch(changeMessage("Salvando Conta a Receber"))
      dispatch([
        {
          type: SAVE_FORM_CONTAS_RECEBER_ERRORS,
          payload: null,
        },
      ]);
      return axios
        .post(CONTAS_RECEBER_API, { ...contaReceberDeserialized })
        .then((result) =>
          dispatch([showAllMessages(result.messages), backToList()])
        )
        .catch((err) => {
          if (err && err.data?.messages && err.data?.messages?.hasError) {
            document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" });
            dispatch([
              {
                type: SAVE_FORM_CONTAS_RECEBER_ERRORS,
                payload: err.data?.messages,
              },
            ]);
          }
        });
    };
  }

export function editContasReceber(contaReceber) {
    const contaReceberDeserialized = deserializeFieldsContasReceberForm(contaReceber, true);
  
    return (dispatch, getState) => {
      dispatch(changeMessage("Salvando Conta a Receber"))

      const selector = formValueSelector("ContasReceberForm");
      const typeSubmit = selector(getState(), "contaReceber.typeSubmit");

      let url = CONTAS_RECEBER_API;
      let method = 'put';

      if (typeSubmit === 'enviarExportacao') {
        url = `${CONTAS_RECEBER_API}enviar-para-exportacao`;
        method = 'post';
      }

      if (typeSubmit === 'enviarPagamento') {
        url = `${CONTAS_RECEBER_API}enviar-para-pagamento`;
        method = 'post';
      }
      
      dispatch([
        {
          type: SAVE_FORM_CONTAS_RECEBER_ERRORS,
          payload: null,
        },
      ]);

      return axios
        .request({ method, url, data: { ...contaReceberDeserialized }})
        .then((result) => {
            dispatch([
              showAllMessages(result.messages),
              backToList(),
            ])
        })
        .catch((err) => {
          if (err && err.data?.messages && err.data?.messages?.hasError) {
            document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" });
            dispatch([
              {
                type: SAVE_FORM_CONTAS_RECEBER_ERRORS,
                payload: err.data?.messages,
              },
            ]);
          }
        });
    };
  }

export function loadOperacoes(idPS) {
  return (dispatch) => {
      dispatch([
          {
            type: CHANGE_LIST_OPERACOES,
            payload: [],
          },
      ])

      return axios
          .get(`${PATRIMONIO_API}${idPS}/operacoes`)
          .then(result => {
              if (!result?.content.length) msgError("Não foi encontrada nenhuma lista para Série", false);
              
              dispatch([
                  {
                    type: CHANGE_LIST_OPERACOES,
                    payload: result?.content || [],
                  },
              ])
          });
  }
}

export function loadContaOrigem(idPS, idOperacao = null) {
    return (dispatch) => {
        dispatch([
            {
              type: CHANGE_LIST_CONTA_ORIGEM,
              payload: [],
            },
        ])

        let url = `${PATRIMONIO_API}${idPS}/contas-arrecadadoras`;
        if (idOperacao) url = url + `?idOperacao=${idOperacao}`

        return axios
            .get(url)
            .then(result => {
                const values = serializeContasOrigem(result.content);

                if (!values?.length) msgError("Não foi encontrada nenhuma lista para Conta Origem", false);

                dispatch([
                    {
                      type: CHANGE_LIST_CONTA_ORIGEM,
                      payload: values,
                    },
                ])
            });
    }
}

export async function actionContasReceber(id, type) {
    return (dispatch) => {
        return axios
            .post(`${CONTAS_RECEBER_API}${type}/${id}`, { id })
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    type !== "revisar" ? backToList() : document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" }),
                    type == "revisar" ? loadContasReceberForm(id) : null,
                ])
            );
    }
}

export function downloadContasReceberDocument(params) {
    return (dispatch) => {
        return axios
            .post(`${CONTAS_RECEBER_API}exportar-documento`, params)
            .then(result => {
                if (result?.content){
                  const { content } = result;

                  dispatch([
                    downloadFile(content?.arquivoAnexado, `${content?.nomeArquivo}.${content?.formatoArquivo}`),
                    showAllMessages(result.messages),
                  ])
                } else {
                  msgError("Arquivo não encontrado", false);
                  return false;
                }
            });
    }
}

export function backToList() {
  return (dispatch) => {
    return dispatch([
      showConfirmDialog(false),
      sairDaPagina(),
    ]);
  };
}

export function sairDaPagina() {
    return dispatch => {
        return dispatch([
            { type: CONTAS_RECEBER_RESET },
            history.push(`/${CONTAS_RECEBER_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)
        ]);
    };
}

export function limpaDependentesOnChange(dependentes) {
    return (dispatch) => {
      const actions = dependentes.map(dependente => change("ContasReceberForm", dependente, null));
      dispatch(actions);
    };
  }
  
  export function mudaDependenteOnChange(dependente, value = null) {
    return (dispatch) => {
      dispatch([change("ContasReceberForm", dependente, value)]);
    };
  }

  export async function alteraNumeroDocumento(number, qnt) {
    const value = await addZerosAEsquerda(number, qnt);
    
    return (dispatch) => {
      dispatch([
        mudaDependenteOnChange("contaReceber.numeroDocumento", value)
      ]);
    };
  }

// 

export function getIndicadores(filters) {
    const data = {
      ...filters,
      idPatrimonioSeparado: filters?.idPatrimonioSeparado?.key || null,
      idOperacao: filters?.idOperacao?.key || null,
      status: (filters?.status && filters?.status?.length) ? filters?.status : null,
    }

    return (dispatch) => {
        dispatch(changeMessage("Carregando Indicadores das Contas a Receber"))
        return axios
            .post(`${CONTAS_RECEBER_API}indicadores`, {...filters, ...data})
            .then(result =>
                dispatch([
                    {
                        type: INDICADORES_CONTAS_RECEBER_FETCHED,
                        payload: result,
                    },
                ])
            );
    }
}

export function exportContasReceberToExcel(filters) {
  const params = deserializeFieldsContasReceberFormFilter(filters, 1);

  return (dispatch) => {
      return axios
          .post(`${CONTAS_RECEBER_API}exportar`, params)
          .then(result => {
              dispatch([
                  showAllMessages(result.messages),
              ])

              if (result.content) {
                const { content } = result;

                dispatch([
                  content ? downloadExcelFile(content?.arquivo, `${content?.nomeArquivo}.${content?.extensaoArquivo}`) : null,
                ])
              }
          }); 

  }
}

export function loadContratoReceita(append, showMsg = true) {
  return (dispatch) => {
      dispatch([
          {
            type: CHANGE_LIST_RECEITAS,
            payload: [],
          },
      ])

      let url = `${RECEITA_API}listar`
      url = url + append;

      return axios
          .get(url)
          .then(result => {
              const values = serializeContratoReceita(result.content);

              if (!values?.length && showMsg) msgError("Não foi encontrada nenhuma lista para Contrato de Receita", false);

              dispatch([
                  {
                    type: CHANGE_LIST_RECEITAS,
                    payload: values,
                  },
              ])
          });
  }
}