import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";

import { selectTab } from '../../../../../actions/operacao/tabs/TabsAction';

import { 
    OPERACAO_TABS, 
    PASSIVO_TAB
} from './../OperacaoTabs';
import OperacaoHeader from './../OperacaoHeader';
import PassivoList from './list/PassivoList';
import ContainerPage from '../../../../common/container/ContainerPage';

class PassivoPage extends Component {  
    componentDidMount() {
        this.props.selectTab(PASSIVO_TAB);
    }

    render() {
        return (
            <ContainerPage
                tabs={OPERACAO_TABS}
                paramsRoute={''}
                header={<OperacaoHeader />}
            >
                <PassivoList />
            </ContainerPage>
        );
    } 
}

const mapStateToProps = state => ({ 
    tab: state.tab,
    operacoes: state.operacaoReducer.selectedOperacoes, 
});

const mapDispacthToProps = dispatch => bindActionCreators({     
    selectTab,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(PassivoPage));
