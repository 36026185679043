
import  axios  from 'axios';

import { sereializeFieldsJobFilter } from '../../components/business/job/mappings/JobMap';
import { showAllMessages } from '../message/ErrorMessagesAPIAction';

export const GERENCIAMENTO_JOB_FETCHED = 'GERENCIAMENTO_JOB_FETCHED';
export const LOAD_FORM_GERENCIAMENTO_JOB = 'LOAD_FORM_GERENCIAMENTO_JOB';
export const CHANGE_FILTERS = "CHANGE_FILTERS";
export const GERENCIAMENTO_JOB_SHOW_DIALOG = "GERENCIAMENTO_JOB_SHOW_DIALOG";

const GERENCIAMENTO_JOB_API =
        process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_GERENCIAMENTO_JOB_API;

export function getJobs(filters, page = 1 ) {
   
    var filter = sereializeFieldsJobFilter(filters, page);
    return (dispatch) => {
        return axios
            .post(`${GERENCIAMENTO_JOB_API}consultar`, { ...filter })
            .then(result =>
                dispatch(
                    {
                        type: GERENCIAMENTO_JOB_FETCHED,
                        payload: result
                    }
                )
            );
    };
}
export function loadFilterFormGerenciamentoJob() {
    return dispatch => {
        return axios.get(`${GERENCIAMENTO_JOB_API}tela`)
            .then(result =>
                dispatch({
                    type: LOAD_FORM_GERENCIAMENTO_JOB,
                    payload: result.content
                })
            );
    };
}

export function processarJob(filter) {
    return (dispatch) => {
        return axios
            .post(`${GERENCIAMENTO_JOB_API}processar`, { ...filter })
            .then(result =>
                dispatch([
                    showProcessarJobDialog(false, null),
                    showAllMessages(result.messages),
                ])
            );
    };
}

export function showProcessarJobDialog(showDialog, tipoJob) {
    return (dispatch) => {
        return dispatch([
            {
                type: GERENCIAMENTO_JOB_SHOW_DIALOG,
                payload: { showDialog, tipoJob }
            }
        ])
    }
}
