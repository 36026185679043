import {SHOW_CONFIRM_DIALOG} from '../../../actions/common/modal/ConfirmDialogAction'

const INITIAL_STATE = {
    showConfirmDialog: false,
}

export default function (state = INITIAL_STATE, action = {}) {
    if( action.type === SHOW_CONFIRM_DIALOG) {
        return {
            ...state,
            showConfirmDialog: action.payload,
        };
    } else {
        return state
    }
}