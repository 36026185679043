import {
    CHANGE_SELECTED_ITEMS,
} from '../.././actions/common/TableSelectAction';

const INITIAL_STATE = {
    selectedItems: [],
};

export default function(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case CHANGE_SELECTED_ITEMS:
            return {
                ...state,
                selectedItems: action.payload
            }
        default:
            return state;
        }
}