const findParent = (el, cls) => {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
}

export const activateFocus = e => {
    findParent(e.target, "field-floating").classList.add("has-float-label");
};

export const activateBlur = e => {
    findParent(e.target, "field-floating").classList.remove("has-float-label");
};