import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";

import {
  changeFundoFormTab,
  goBackToList,
  resetPagina,
} from "../../../../../actions/fundo/FundoAction";
import { showConfirmDialog } from "../../../../../actions/common/modal/ConfirmDialogAction";

import FloatButton from "../../../../common/button/FloatButton";
import FloatButtonGroup from "../../../../common/button/FloatButtonGroup";
import Container from "../../../../common/container/Container";
import ContainerRow from "../../../../common/container/ContainerRow";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";
import Form from "../../../../common/form/Form";
import FormContent from "../../../../common/form/FormContent";
import FormHeader from "../../../../common/form/FormHeader";
import FormTab from "../../../../common/form/FormTab";
import If from "../../../../common/If/If";

import FundoFormSubclassesFundo from "./tabs/FundoFormSubclassesFundo";
import FundoFormCotasEmitidas from "./tabs/FundoFormCotasEmitidas";
import FundoFormGeral from "./tabs/FundoFormGeral";
import FundoFormEstrutura from "./tabs/FundoFormEstrutura";

const FUNDO_FORM_GERAL = "GERAL";
const FUNDO_FORM_ESTRUTURA = "ESTRUTURA DA OPERAÇÃO";
const FUNDO_FORM_SUBLASSES_DO_FUNDO = "SUBLASSES DO FUNDO";
const FUNDO_FORM_COTAS_EMITIDAS = "COTAS EMITIDAS";

class FundoForm extends Component {
  componentDidMount() {
    this.props.changeFundoFormTab(FUNDO_FORM_GERAL);
  }

  componentWillUnmount() {
    this.props.resetPagina();
  }

  isActivateForm(state) {
    return this.props.formActive === state;
  }

  render() {
    const {
      handleSubmit,
      readOnly,
      floatButtonVisible,
      title,
      showConfirmDialog,
      showCancelConfirmDialog,
      goBackToList,
    } = this.props;

    return (
      <Container>
        <ContainerRow>
          <div className="col-md">
            <Form>
              <FormHeader title={title} col={"col-md"} offSet={20}>
                <FormTab
                  formTabNames={[
                    FUNDO_FORM_GERAL,
                    FUNDO_FORM_ESTRUTURA,
                    FUNDO_FORM_SUBLASSES_DO_FUNDO,
                    FUNDO_FORM_COTAS_EMITIDAS,
                  ]}
                  formTabActive={this.props.formActive}
                  changeFormTabActive={this.props.changeFundoFormTab}
                />
              </FormHeader>
              <FormContent handleSubmit={handleSubmit}>
                <If test={this.isActivateForm(FUNDO_FORM_GERAL)}>
                  <FundoFormGeral readOnly={readOnly} />
                </If>
                <If test={this.isActivateForm(FUNDO_FORM_ESTRUTURA)}>
                  <FundoFormEstrutura readOnly={readOnly} />
                </If>
                <If test={this.isActivateForm(FUNDO_FORM_SUBLASSES_DO_FUNDO)}>
                  <FundoFormSubclassesFundo readOnly={readOnly} />
                </If>
                <If test={this.isActivateForm(FUNDO_FORM_COTAS_EMITIDAS)}>
                  <FundoFormCotasEmitidas readOnly={readOnly} />
                </If>
                <FloatButtonGroup>
                  <FloatButton
                    activeClassName={"nd1 nds"}
                    title={"Cancelar"}
                    icon={"fa fa-ban edit edit-sub-menu"}
                    action={() =>
                      floatButtonVisible
                        ? showConfirmDialog(true)
                        : goBackToList()
                    }
                  />
                  <If test={floatButtonVisible}>
                    <FloatButton
                      activeClassName={"nd3 nds"}
                      title={"Salvar"}
                      icon={"material-icons edit edit-sub-menu"}
                      cloudDone={true}
                      action={handleSubmit}
                    />
                  </If>
                </FloatButtonGroup>
              </FormContent>
              <ConfirmDialog
                title="Aviso"
                contentText="Os dados do formulário serão perdidos, deseja realmente sair?"
                textButtonCancel="CANCELAR"
                textButtonConfirm="CONFIRMAR"
                showDialog={showCancelConfirmDialog}
                onClose={() => showConfirmDialog(false)}
                onConfirm={() => goBackToList()}
                type="warning"
                maxWidth="md"
              />
            </Form>
          </div>
        </ContainerRow>
      </Container>
    );
  }
}

FundoForm = reduxForm({ form: "fundoForm" })(FundoForm);

const mapStateToProps = (state) => ({
  formActive: state.fundoReducer.changeFundoFormTab,
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeFundoFormTab,
      showConfirmDialog,
      goBackToList,
      resetPagina
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FundoForm)
);
