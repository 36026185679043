import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import GenericPickerInput from "../../common/input/GenericPickerInput";

import { 
    showAtivoList, 
    getSugestaoAtivo,
    selectedAtivoOption,
 } from "../../../actions/ativo/AtivoAction";

const AtivoPicker = (props) => (
    <div>
        <div>
            <GenericPickerInput 
                loadData={props.getSugestaoAtivo}
                options={props.options}
                selectedOptionForm={props.selectedAtivoOption}                
                placeholder={"Pesquisar Ativo"}
                loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                noOptionsMessage="Nenhum registro encontrado"
                isMulti={true}
                activeClassName={props.classes}
            />
        </div>
    </div>
);

const mapDispatchToProps = dispatch => bindActionCreators({
    getSugestaoAtivo,
    selectedAtivoOption,
    showAtivoList,
}, dispatch);

const mapStateToProps = state => ({
    options: state.ativoReducer.ativosOptions,
    ativos: state.ativoReducer.selectedAtivoOptions
});

export default connect(mapStateToProps, mapDispatchToProps)(AtivoPicker);
