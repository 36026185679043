import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { showLogList } from "../../../../../../actions/precificacao/LogAction";

import FormHeader from "../../../../../common/form/FormHeader";
import FloatButtonGroup from "../../../../../common/button/FloatButtonGroup";
import FloatButton from "../../../../../common/button/FloatButton";

import ReactJson from "react-json-view";
import If from "../../../../../common/If/If";
import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import { formatActionLog } from "../../../../../../utils/helpers/OthersHelpers";

let LogForm = (props) => {
  const { title, showLogList, log } = props;

  const { newValues, oldValues, dateTime, action, tableName } = log;

  const dataStyle = { borderRadius: 4, padding: 8, marginTop: 6 };

  return (
    <div className="w-100 p-3">
      <FormHeader title={title} col={"col-md"} offSet={20}></FormHeader>
      <div className="p-3">
        <div>
          <If test={dateTime || action || tableName}>
            <div className="d-flex flex-column mb-2">
              <span>
                <span className="font-weight-bold">Tabela:</span> {tableName ? tableName : "N/A"}
              </span>
              <span>
                <span className="font-weight-bold">Ação:</span> {action ? formatActionLog(action) : "N/A"}
              </span>
              <span>
                <span className="font-weight-bold">Data:</span> {dateTime ? getDateFormatedFromString(dateTime) : "N/A"}
              </span>
            </div>
          </If>
          <div className="row">
            <If test={oldValues}>
              <div className={`col-12 col-lg-${!newValues ? "12" : "6 me-4"}`}>
                <span className="h5 font-weight-bold">Antigos valores:</span>
                <ReactJson
                  displayDataTypes={false}
                  theme="twilight"
                  style={dataStyle}
                  className="rounded"
                  src={oldValues ? JSON.parse(oldValues) : false}
                />
              </div>
            </If>
            <If test={newValues}>
              <div className={`col-12 col-lg-${!oldValues ? "12" : "6"}`}>
                <span className="h5 font-weight-bold">Novos valores:</span>
                <ReactJson
                  displayDataTypes={false}
                  theme="twilight"
                  style={dataStyle}
                  className="rounded"
                  src={newValues ? JSON.parse(newValues) : false}
                />
              </div>
            </If>
          </div>
        </div>
        <FloatButtonGroup>
          <FloatButton activeClassName={"nd1 nds"} title={"Cancelar"} icon={"fa fa-ban edit edit-sub-menu"} action={() => showLogList()} />
        </FloatButtonGroup>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  log: state.logReducer.logForm,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLogList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LogForm);
