/* eslint no-useless-escape: 0 */

import Big from 'big.js';

/**
 * @description retorna o valor numérico de uma variável. Caso seja undefined ou NaN, retorna 0.
 * @param {Object} object: Object.
 */
export function getNumberValue(object) {
  if (object === undefined) return 0;
  else if (object === isNaN) return 0;
  else {
    let number = parseInt(object, 10);
    if (number === isNaN) return 0;
    return number;
  }
}

/**
 * @description Converte o valor numérico para a moeda do Brasil. Caso seja undefined ou NaN, retorna 0.
 * @param {Number} value: valor numérico.
 * @param {Number} minimumFractionDigits: quantidade minima de numeros fracionados.
 */
export function convertCurrency(value, minimumFractionDigits = 8) {
  return convertCurrencyNumber(value, true, minimumFractionDigits);
}

/**
 * @description Converte o valor numérico para a moeda do Brasil. Caso seja undefined ou NaN, retorna 0.
 * @param {Number} value valor numérico.
 * @param {Number} minimumFractionDigits quantidade minima de numeros fracionados.
 * @param {Boolean} withPrefix usar ou nao o prefixo R$
 */

export function convertCurrencyNumber(value, prefix, minimumFractionDigits = 8) {
  const pfx = typeof prefix === "string" ? `${prefix} ` : prefix ? "R$ " : "";

  if (!value || Number.isNaN(Number(value))) return value;

  const formattedValue = Number(value).toLocaleString("pt-BR", {
    minimumFractionDigits,
    maximumFractionDigits: minimumFractionDigits,
  });

  return `${pfx}${formattedValue}`;
}

export function convertCurrencyNumberNew(value, prefix, decimalPlaces = 8) {
  const pfx = typeof prefix === "string" ? `${prefix} ` : prefix ? "R$ " : "";

  if (!value || Number.isNaN(Number(value))) {
    return `${pfx}${Number(0).toLocaleString("pt-BR", {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    })}`;
  }

  return `${pfx}${Number(value).toLocaleString("pt-BR", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  })}`;
}

/**
 * @description Converte o valor da moeda do Brasil para um valor numérico.
 * Caso seja undefined ou NaN, retorna 0.
 * Eg.: R$ 1000,00 to 1000.00.
 * @param {Number} valor: valor numérico.
 */
export function convertCurrencyToNumber(valor, casasDecimais) {
  if (!valor || valor.length < 1 || valor.substring === undefined) return valor;

  valor = valor.replace(/\./g, "").replace(",", ".");
  let numero = Number(valor.replace(/[^0-9\.-]+/g, ""));

  if (casasDecimais !== undefined) numero = Number(numero.toFixed(casasDecimais));

  return numero;
}

export function intToRoman(num) {
  if (num < 1 || num > 10) {
    return num
  }

  const romanNumerals = {
    1: "I",
    2: "II",
    3: "III",
    4: "IV",
    5: "V",
    6: "VI",
    7: "VII",
    8: "VIII",
    9: "IX",
    10: "X"
  };

  return romanNumerals[num];
}

export function reorganizeSubclassArr(array) {
  const ordem = {
    "Sênior I": 1,
    "Mezanino I": 2,
    "Subordinada I": 3,
    "Sênior II": 4,
    "Mezanino II": 5,
    "Subordinada II": 6,
    "Sênior III": 7,
  };

  array.sort((a, b) => {
    const ordemA = ordem[a] || Infinity;
    const ordemB = ordem[b] || Infinity;

    return ordemA - ordemB;
  });

  return array;
}

export function newFormatPercent(value, maximumFractionDigits = 2) {
  if (!value || Number.isNaN(Number(value))) return value;
  const roundedValue = Number(value).toFixed(maximumFractionDigits);
  const formattedValue = roundedValue.replace('.', ',');
  return `${formattedValue}%`;
}

/**
 * @description Formata um float para exibir como porcentagem
 * eg: 23.34 -> 23,34%
 * @param {Number} value valor numérico.
 * @param {Number} maximumFractionDigits quantidade minima de numeros fracionados.
 */
export function formatPercent(value, maximumFractionDigits = 8) {
  if (!value || Number.isNaN(Number(value))) return value;
  return `${Number(value).toLocaleString("pt-BR", { maximumFractionDigits })}%`;
}

/**
 * @description Formata um float para exibir sem porcentagem.
 * eg: 23.34% -> 23,34
 * @param {String} value
 */
export function formatPercentToNumber(value) {
  if (!value || value.length < 1 || typeof value !== "string") return value;

  const numericValue = value.replace(/[^\d.,]/g, "");

  if (numericValue.indexOf(",") !== -1) {
    return parseFloat(numericValue.replace(",", "."));
  } else {
    return parseFloat(numericValue) / 100;
  }
}

export function formatDecimalToNumber(value) {
  if (!value || value.length < 1 || typeof value !== "string") return value;

  return value.replace(/\./g, "").replace(",", ".");
}

/**
 * @description Função que retorna apenas números
 * @param {String} value
 */
export function onlyNumbers(value) {
  const regex_cell = /[^0-9.]/g;

  if (value.match(regex_cell)) {
    value = value.replace(regex_cell, "");
  }

  return value;
}

/**
 * @description Obtém um valor número em formado de m².
 * @param {Number} value
 */
export function getAreaFormatted(value) {
  if (!value) return value;

  return `${Number(value).toLocaleString("pt-BR")}m²`;
}

export function formatDecimal(value, fraction = 16) {
  if (!value || Number.isNaN(Number(value))) return value;

  return Number(value).toLocaleString("pt-BR", {
    style: "decimal",
    minimumFractionDigits: fraction,
  });
}

export function truncate(value, fractionDigits) {
  if (!value || Number.isNaN(Number(value))) return value;

  var numPowerConverter = Math.pow(10, fractionDigits);
  return ~~(value * numPowerConverter) / numPowerConverter;
}

export function caracterDiferenteZero(value) {
  if (!value || Number.isNaN(Number(value))) return value;
  return Number(value) > 0 ? value : "";
}

export default function normalizeDecimals(number, precisaoValor = 16, arredondamento = false, precisao = false) {
  if (!isFinite(number)) number = 0;
  
  let bigNumber = new Big(number);

  if (precisao || !arredondamento) {
    bigNumber = bigNumber.round(precisaoValor, 0);
  }

  if (arredondamento) {
    bigNumber = bigNumber.round(precisaoValor, 1);
  }

  return bigNumber.toNumber();
}

export function convertDecimal(valor) {
  if (typeof valor === "string") {
    const numero = valor.replace(/[^\d.-]/g, "");
    const resultado = parseFloat(numero);

    if (isNaN(resultado)) {
      return 0;
    }

    return resultado;
  }

  return 0;
}

export function convertDecimalNormalized(valor) {
  if (typeof valor === "string") {
    const numero = valor.replace(/[^\d,.-]/g, "").replace(/\./g, "").replace(",", ".");
    const resultado = parseFloat(numero);

    return isNaN(resultado) ? 0 : resultado;
  }
  return typeof valor === "number" ? valor : 0;
}

export function convertPercentStringToFloat(valor) {
  if (valor == null) {
    return null;
  }

  if (typeof valor === 'string' && valor.indexOf('%') !== -1) {
    valor = valor.replace(',', '.').replace('%', '');
    return parseFloat(valor);
  } else if (typeof valor === 'string') {
    return parseInt(valor);
  } else if (typeof valor === 'number') {
    return valor;
  } else {
    return null;
  }
}
