import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { savePatrimonio, loadFormPatrimonio } from '../../../../../actions/patrimonio/PatrimonioAction';
import PatrimonioForm from './PatrimonioForm';
import { withRouter } from 'react-router-dom';

class PatrimonioFormInclude extends Component {
    componentDidMount() {
        this.props.loadFormPatrimonio();
    }

    render() {
        const { readOnly, savePatrimonio } = this.props;
        
        return (
            <PatrimonioForm 
                title={"Novo - Patrimônio"} 
                onSubmit={savePatrimonio} 
                readOnly={readOnly}
                floatButtonVisible={true} />
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    savePatrimonio,
    loadFormPatrimonio,
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(PatrimonioFormInclude));