import React from "react";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Button from "../button/Button";

let ModalAction = (props) => {
  const { close, open, action, title = 'Título', message } = props;

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal">{ title }</p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-4">
            {message && (<p className="mb-2">{ message }</p>)}
            <p className="mb-0">Tem certeza que deseja prosseguir?</p>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Não, Cancelar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Sim, Confirmar"}
              action={() => {
                action();
                close();
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default connect(null, null)(ModalAction);
