/* eslint eqeqeq: 0 */

import React from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { setAuthHeader, setUserHeader, setNewToken } from "./helpers/AuthorizationHelper";
import { changeLoading, changeMessage } from "../actions/infra/LoadingScreenAction";
import { store } from "./../index";
import { extrairCodigoSuporte } from "./helpers/OthersHelpers";
import ButtonCopy from "../components/common/button/ButtonCopy";
import { logoutMessage } from "../actions/login/LoginAction";
import { DETALHAR_ROUTE_PATH, ERRO_ROUTE_PATH, OPCAO_SISTEMA_ROUTE_PATH } from "../components/common/routers/constantesRoutes";

const ERROR_INVALID_TOKEN = process.env.REACT_APP_ERROR_API_INVALID_TOKEN;
const SET_USER_HEADER = process.env.REACT_APP_SET_USER_HEADER;

function loading(value) {
  store.dispatch(changeLoading(value));
}

function message(value) {
  store.dispatch(changeMessage(value));
}

export function axiosInterceptor() {

  axios.interceptors.request.use((config) => {
    loading(true);
    setAuthHeader(config);

    if (JSON.parse(SET_USER_HEADER)) {
      setUserHeader(config);
    }

    return config;

  }, (error) => {
    loading(false);
    message(null)
    return Promise.reject(error);
  });

  axios.interceptors.response.use((response) => {
    loading(false);
    message(null)

    if (response.status === 200 && response.data.messages && response.data.messages.hasError) {
      response.data.messages.errors.forEach((msg) => {
        const code = extrairCodigoSuporte(msg);
        const routeCode = `/${OPCAO_SISTEMA_ROUTE_PATH}/${ERRO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/${code}`

        const codeRender = () => {
          return (
            <div className="d-flex flex-column">
              <div className="my-1">
                <a href={routeCode} className="link-code" target="_blank">Ver detalhes</a>
              </div>
              <ButtonCopy toCopy={code} />
            </div>
          )
        }
        
        if (code) {
          toastr.error("Erro", msg, { component: codeRender, progressBar: false, timeOut: 0 });
        } else {
          toastr.error("Erro", msg);
        }
      });

      throw response;
    }

    if (response.status === 200 || response.status === 201) {
      setNewToken(response);
      return response.data;
    }

    return response;

  }, (error) => {
    loading(false);

    if (error.response) {
      let hasError = error.response.data.messages.hasError;
      let apiError = error.response.data.messages.errors;

      setNewToken(error.response);

      if (hasError && apiError) {
        if (error.response.status === 401 && apiError[0] === ERROR_INVALID_TOKEN) {
          store.dispatch(logoutMessage(ERROR_INVALID_TOKEN))
        } else {
          apiError.forEach((msg) => toastr.error("Erro", msg.replace(/[1-3]#/g, "")));
        }
      } else if (error.request && error.response.status == 403) {
        toastr.error("Erro", "Você não possui permissão para acessar este módulo");
      } else if (error.request) {
        toastr.error("Erro", "Houve um problema durante a requisição");
      } else {
        toastr.error("Erro", error.message);
      }
    }

    return Promise.reject(error);
  });
}
