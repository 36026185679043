/* eslint eqeqeq: 0 */

import { toastr } from "react-redux-toastr";

export function extractArrayEmailsFromString(strEmails) {
    let arrEmails = [];
    if (strEmails && strEmails !== '') {
        if (strEmails.slice(-1) === ';') {
            strEmails = strEmails.slice(0, -1);
        }

        arrEmails = strEmails.split(";")
    }

    return arrEmails;
}

export function concatArrayEmailsToString(arrayEmails){
    var resultString = ""
    if(arrayEmails) {
        arrayEmails.map((email) => resultString = resultString + `${email};` )
    }
    return resultString.substring(0, resultString.length - 1)
}

export function validacaoEmail(email, listaEmail) {
    const rgx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (listaEmail && listaEmail.length > 0) {
        if (listaEmail.some((em) => em == email)) {
          toastr.error("Erro", "E-mail já incluso");
          return false;
        }
    }
    
    if (!email || (email && !rgx.test(email))) {
      toastr.error("Erro", "Formato inválido de email");
      return false;
    } else {
      return email;
    }
}

export function emailValido(email) {
   if (!email) return false;
  const rgx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return rgx.test(email);
}