import axios from "axios";
import { change, formValueSelector, initialize } from "redux-form";

import history from "../../components/common/routers/history";
import {
  deserializeFieldsCota,
  deserializeFieldsCotaPrecificacaoFormFilter,
  serializeFieldsCotaFluxo,
} from "../../components/business/cota/mappings/CotaMap";

import { showAllMessages } from "../message/ErrorMessagesAPIAction";

import {
  PRECIFICACAO_ROUTE_PATH,
  COTA_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";
import { convertCurrencyToNumber } from "../../utils/helpers/NumberHelper";

export const COTA_FETCHED = "COTA_FETCHED";
export const INDICADORES_COTA_FETCHED = "INDICADORES_COTA_FETCHED";
export const COTA_CONSULT_CURRENT = "COTA_CONSULT_CURRENT";
export const LOAD_FILTER_FORM_COTA = "LOAD_FILTER_FORM_COTA";
export const LOAD_TELA_FLUXOS_COTA = "LOAD_TELA_FLUXOS_COTA";
export const RESET = "RESET";
export const FUNDOS_COTA_OPTIONS_FETCHED = "FUNDOS_COTA_OPTIONS_FETCHED";

export const EMAIL_NAO_ENVIADO = "Não foi enviado e-mail nesta etapa.";

export const COTA_SHOW_DELETE_DIALOG = "COTA_SHOW_DELETE_DIALOG";

const COTA_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_FUNDO_COTAS;

const FUNDO_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_FUNDO;

export function loadFilterFormCota(consult) {
  return async (dispatch) => {
    try {
      const result = await axios.get(COTA_API + "tela-validacoes-pendentes");
      const { dataFiltroInicial, dataFiltroFinal } = result.content;

      const dataInicio = dataFiltroInicial.slice(0, 10);
      const dataFinal = dataFiltroFinal.slice(0, 10);

      dispatch([
        {
          type: LOAD_FILTER_FORM_COTA,
          payload: result.content,
        },
        !consult && change("CotaForm", "dataInicio", dataInicio),
        !consult && change("CotaForm", "dataFinal", dataFinal),
        !consult && getCota({ dataInicio, dataFinal }),
        consult && getCota(consult, consult?.pagingConfig?.pageIndex),
      ]);
    } catch (error) {
      // Handle errors appropriately
    }
  };
}

export function getCota(filters, page = 1) {
  const params = deserializeFieldsCotaPrecificacaoFormFilter(filters, page);

  return (dispatch) => {
    return axios
      .post(`${COTA_API}validacoes-pendentes`, params)
      .then((resultValidacao) => {
        return axios
          .post(`${COTA_API}indicadores-validacoes-pendentes`, params)
          .then((result) => {
            const { dias, status } = result.content;

            dispatch([
              {
                type: COTA_FETCHED,
                payload: resultValidacao,
              },
              {
                type: INDICADORES_COTA_FETCHED,
                payload: { dias, status },
              },
              {
                type: COTA_CONSULT_CURRENT,
                payload: params,
              },
            ]);
          });
      });
    // .catch((error) => {
    //   const messages = error.response.data.messages

    //   dispatch([
    //     {
    //       type: COTA_FETCHED,
    //       payload: null,
    //     },
    //     {
    //       type: INDICADORES_COTA_FETCHED,
    //       payload: null,
    //     },
    //   ])
    // });
  };
}

export function getSugestaoFundoCota(term) {
  return (dispatch) => {
    return axios.get(`${FUNDO_API}sugestoes?termo=${term}`).then((result) =>
      dispatch({
        type: FUNDOS_COTA_OPTIONS_FETCHED,
        payload: result.content,
      })
    );
  };
}

export function resetPagina() {
  return (dispatch) => {
    return dispatch([{ type: RESET }]);
  };
}

export function sairDaPagina() {
  return (dispatch) => {
    return dispatch([
      { type: RESET },
      history.push(
        `/${PRECIFICACAO_ROUTE_PATH}/${COTA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`
      ),
    ]);
  };
}

export function loadCotaForm(idCota, pagina = null) {
  return (dispatch) => {
    return axios.get(COTA_API + `tela/${idCota || ""}`).then((result) => {
      const values = serializeFieldsCotaFluxo(result.content);

      if (result.messages.infos.length > 0) {
        dispatch([showAllMessages(result.messages), sairDaPagina()]);
      } else {
        dispatch([
          idCota
            ? history.push(`/${COTA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/${idCota}`)
            : null,
          showAllMessages(result.messages),
          initialize("CotaDetail", values),
          {
            type: LOAD_TELA_FLUXOS_COTA,
            payload: result.content,
          },
        ]);
      }
    });
  };
}

export function revisarDados(formValues) {
  const params = deserializeFieldsCota(formValues);

  return (dispatch) => {
    return axios.post(COTA_API + `revisar`, params).then((result) => {
      dispatch([
        showAllMessages(result.messages),
        history.push(`/${COTA_ROUTE_PATH}`),
      ]);
    });
  };
}

export function validarDados(formValues) {
  const params = deserializeFieldsCota(formValues);

  return (dispatch) => {
    return axios.post(COTA_API + `validar`, params).then((result) => {
      dispatch([
        showAllMessages(result.messages),
        history.push(`/${COTA_ROUTE_PATH}`),
      ]);
    });
  };
}

// CÁLCULOS VARIAÇÕES

// prettier-ignore
export function calcularVariacaoBenchmark(index) {
  return (dispatch, getState) => {
    const selector = formValueSelector("CotaDetail");

    let valorBenchmark = convertCurrencyToNumber(selector(getState(), `cotas[${index}].valorBenchmark`), 8) || 0;
    let valorBenchmarkAnterior = convertCurrencyToNumber(selector(getState(), `cotas[${index}].valorBenchmarkAnterior`), 8) || 0;
    let variacaoPercentualBenchmark = ((valorBenchmark - valorBenchmarkAnterior) / valorBenchmarkAnterior) * 100;
    
    if (variacaoPercentualBenchmark === undefined || isNaN(variacaoPercentualBenchmark)) variacaoPercentualBenchmark = 0;
  
    variacaoPercentualBenchmark = convertCurrencyToNumber(variacaoPercentualBenchmark.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));

    return dispatch([
      change("CotaDetail", `cotas[${index}].valorBenchmarkVariacao`, valorBenchmark > valorBenchmarkAnterior ? Math.abs(variacaoPercentualBenchmark) : -Math.abs(variacaoPercentualBenchmark)),
    ]);
  };
}

// prettier-ignore
export function calcularVariacaoCota(index) {
  return (dispatch, getState) => {
    const selector = formValueSelector("CotaDetail");

    let valorCota = convertCurrencyToNumber(selector(getState(), `cotas[${index}].valorCota`), 8) || 0;
    let valorCotaAnterior = convertCurrencyToNumber(selector(getState(), `cotas[${index}].valorCotaAnterior`), 8) || 0;
    let variacaoPercentualCota = ((valorCota - valorCotaAnterior) / valorCotaAnterior) * 100;
    
    if (variacaoPercentualCota === undefined || isNaN(variacaoPercentualCota)) variacaoPercentualCota = 0;
  
    variacaoPercentualCota = convertCurrencyToNumber(variacaoPercentualCota.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));

    return dispatch([
      change("CotaDetail", `cotas[${index}].valorCotaVariacao`, valorCota > valorCotaAnterior ? Math.abs(variacaoPercentualCota) : -Math.abs(variacaoPercentualCota)),
    ]);
  };
}