import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { showErroList } from "../../../../../../actions/precificacao/ErroAction";

import FormHeader from "../../../../../common/form/FormHeader";
import FloatButtonGroup from "../../../../../common/button/FloatButtonGroup";
import FloatButton from "../../../../../common/button/FloatButton";

import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import { formatTypeError } from "../../../../../../utils/helpers/OthersHelpers";

let ErroForm = (props) => {
  const { title, showErroList, erro } = props;

  const { date, exception, level, logger, message, thread, codigo } = erro;

  return (
    <div className="w-100 p-3">
      <FormHeader title={title} col={"col-md"} offSet={20}></FormHeader>
      <div className="p-3">
        <div>
          <div className="d-flex flex-column">
            <span className="mb-2">
              <span className="font-weight-bold">Tipo:</span>{" "}
              {level ? formatTypeError(level) : "N/A"}
            </span>
            <span className="mb-2">
              <span className="font-weight-bold">Thread:</span>{" "}
              {thread || "N/A"}
            </span>
            <span className="mb-2">
              <span className="font-weight-bold">Data:</span>{" "}
              {date ? getDateFormatedFromString(date) : "N/A"}
            </span>
            <span className="mb-2">
              <span className="font-weight-bold">Código:</span>{" "}
              {codigo || "N/A"}
            </span>
          </div>
          <div>
            <span className="font-weight-bold">Exception:</span>
            <p className="exception-style">{ exception }</p>
          </div>
          <div>
            <span className="font-weight-bold">Logger:</span>
            <p className="logger-style">{ logger }</p>
          </div>
          <div>
            <span className="font-weight-bold">Message:</span>
            <p className="message-style">{ message }</p>
          </div>
        </div>
        <FloatButtonGroup>
          <FloatButton
            activeClassName={"nd1 nds"}
            title={"Cancelar"}
            icon={"fa fa-ban edit edit-sub-menu"}
            action={() => showErroList()}
          />
        </FloatButtonGroup>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  erro: state.erroReducer.erroForm,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showErroList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ErroForm);
