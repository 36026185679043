/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint jsx-a11y/alt-text: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import fileDownload from "../../../../../../../src/assets/img/file-download.svg";

import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import TableEmptyData from "../../../../../common/table/TableEmptyData";
import PaginationRB from "../../../../../common/pagination/PaginationRB";
import { Radio } from "@material-ui/core";
import {
  getDateFormatedMonthYear,
  getDateFormatedFromString,
} from "./../../../../../../utils/helpers/DateTimeHelper";

import {
  handleSearchRepots,
  getReports,
  exportRelatorioAgingToExcel,
  saveSelectedValues,
  RELATORIO_ANALITICO_TIPO_AGING,
  RELATORIO_ANALITICO_TIPO_GESTAO_FIDUCIARIA,
  exportFileReport,
} from "../../../../../../actions/relatorios/reportAction";
import If from "../../../../../common/If/If";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";

const SectionReportListDetail = (props) => {
  const {
    handleSearchRepots,
    reportData,
    messages,
    dataBase,
    typeReport,
    operacoes,
    gestor,
    status,
    statusOperacao,
    saveSelectedValues,
    selecteds,
  } = props;

  const [selectedValues, setSelectedValues] = React.useState([]);
  const [allValuesSelecteds, setAllValuesSelecteds] = React.useState(false);

  function clearSelects() {
    setSelectedValues([]);
    setAllValuesSelecteds(false);
  }

  React.useEffect(() => {
    selectedValues.length > 0 &&
    selectedValues.length === reportData?.items.length
      ? setAllValuesSelecteds(true)
      : setAllValuesSelecteds(false);

    saveSelectedValues(selectedValues);
  }, [selectedValues]);

  React.useEffect(() => {
    clearSelects();
  }, [typeReport]);

  React.useEffect(() => {
    clearSelects();
  }, [selecteds]);

  const handleDownloadFile = (params) => {
    if (typeReport && typeReport?.key) {
      if (typeReport?.key === RELATORIO_ANALITICO_TIPO_AGING) {
        props.exportRelatorioAgingToExcel({
          typeReport: params.relatorioGerencial,
          dataBase: params.dataBase,
        });
      }
      if (typeReport?.key === RELATORIO_ANALITICO_TIPO_GESTAO_FIDUCIARIA) {
        const idAnexo = params.idArquivo || params.idDiarioBordo;
        props.exportFileReport(idAnexo);
      }
    }
  };

  const handleRadioChange = (obj) => {
    if (selectedValues.includes(obj)) {
      const arr = selectedValues.filter((item) => item !== obj);
      setSelectedValues(arr);
    } else {
      setSelectedValues([...selectedValues, obj]);
    }
  };

  const selectAll = () => {
    setAllValuesSelecteds(!allValuesSelecteds);

    !allValuesSelecteds
      ? setSelectedValues(reportData?.items)
      : setSelectedValues([]);
  };

  const emptyData = messages && messages.infos && messages.infos[0];

  const isRadioSelected = (obj) => {
    const objString = JSON.stringify(obj);
    return selectedValues.some((item) => JSON.stringify(item) === objString);
  };

  const paginationFunc = (params, page) => {
    clearSelects();
    handleSearchRepots(params, page);
  };

  const tableHeader = (
    <tr>
      {typeReport && typeReport?.key == 2 && (
        <>
          <th className="text-center px-0 mx-0">
            <Radio
              className="px-0 mx-0"
              checked={allValuesSelecteds}
              onClick={() => selectAll()}
              icon={<CheckBoxOutlineBlank />}
              checkedIcon={<CheckBox style={{ color: "#83BF74" }} />}
            />
          </th>
          <th className="text-center fw-700 text-nowrap">Codigo Opea</th>
        </>
      )}
      <th className="text-center fw-700 text-nowrap">Relatórios</th>
      <th className="text-center fw-700 text-nowrap">DataBase</th>
      <th className="text-center fw-700 text-nowrap">Status</th>
      {typeReport && typeReport?.key == 1 && (
        <th className="text-center fw-700 text-nowrap">Data Geração</th>
      )}
      {typeReport && typeReport?.key == 2 && (
        <th className="text-center fw-700 text-nowrap">Observação</th>
      )}
      <th className="text-center"></th>
    </tr>
  );

  const tableContent =
    reportData && reportData.hasItems ? (
      reportData.items.map((item, index) => (
        <tr key={index}>
          {typeReport && typeReport?.key == 2 && (
            <>
              <th className="text-center px-0 mx-0">
                <Radio
                  className="px-0 mx-0"
                  onClick={() => handleRadioChange(item)}
                  checked={isRadioSelected(item)}
                  icon={<CheckBoxOutlineBlank />}
                  checkedIcon={<CheckBox style={{ color: "#83BF74" }} />}
                />
              </th>
              <td className="text-center fw-400 text-nowrap">
                {item.codigoOpea}
              </td>
            </>
          )}
          <td className="text-center fw-400 text-nowrap">
            {item.relatorioGerencial.value}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {getDateFormatedMonthYear(item.dataBase)}
          </td>
          <td className="text-center fw-400 text-nowrap">
            {item.statusRelatorio.value}
          </td>
          {typeReport && typeReport?.key == 1 && (
            <td className="text-center fw-400 text-nowrap">
              {getDateFormatedFromString(item.dataInclusao)}
            </td>
          )}
          {typeReport && typeReport?.key == 2 && (
            <td className="text-center fw-400 text-nowrap">
              {item.observacao || "--"}
            </td>
          )}
          <td className="text-center">
            {((item.idArquivo || item.idDiarioBordo || typeReport?.key == 1) && (item.statusRelatorio.key == 2)) && (
              <a
                className="pointer"
                onClick={() =>
                  handleDownloadFile({
                    typeReport: item.relatorioGerencial,
                    dataBase: item.dataBase,
                    id: item.id,
                    codigoOpea: item.codigoOpea,
                    idArquivo: item.idArquivo,
                    idDiarioBordo: item.idDiarioBordo,
                  })
                }
              >
                <img src={fileDownload} className="file-download-size" />
              </a>
            )}
          </td>
        </tr>
      ))
    ) : (
      <TableEmptyData message={emptyData} />
    );

  return (
    <div className="w-100 px-4 mx-3 mt-4">
      <If test={reportData.totalCount > 0}>
        <Table
          withoutHeader
          classes="shadow-custom rounded-12 col-12 mb-3 mt-0 pd-10 bg-white"
          totalItems={reportData.totalCount}
          pagination={
            <PaginationRB
              pageNumber={reportData.pageIndex - 1}
              itemsPerPage={reportData.pageSize}
              totalItems={reportData?.totalCount || 0}
              filter={{ typeReport, dataBase, operacoes, gestor, status, statusOperacao }}
              fetchFunction={paginationFunc}
              pageRange={5}
            />
          }
        >
          <TableHeader>{tableHeader}</TableHeader>
          <TableContent>{tableContent}</TableContent>
        </Table>
      </If>
    </div>
  );
};

const selector = formValueSelector("RelatorioForm");

const mapStateToProps = (state) => ({
  reportData: state.reportReducer.listReport,
  selecteds: state.reportReducer.selectedsCount,
  relatorios: state.reportReducer.relatorios || [],
  messages: state.agingReducer.messages,
  dataBase: selector(state, "dataBase"),
  typeReport: selector(state, "typeReport"),
  operacoes: selector(state, "operacao"),
  gestor: selector(state, "gestor"),
  status: selector(state, "status"),
  statusOperacao: selector(state, "statusOperacao"),
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      handleSearchRepots,
      getReports,
      exportRelatorioAgingToExcel,
      exportFileReport,
      saveSelectedValues,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(SectionReportListDetail);
