/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import {
  resetAmortizacao,
  calcularJurosValorAPagar,
  calcularJurosPercentual,
  calcularAmortizacaoValorAPagar,
  calcularAmortizacaoPercentual,
  calcularValorFechamentoUnitario,
  calcularPagamentoTotalPMT,
  calcularSaldoFechamento,
  executarPrecificacao,
  maxJurosValorTotal,
  minJurosValorTotal,
  resetaAmex,
  cancelarRevisao,
  limpaModalPrecificacao,
} from "../../../../../../actions/precificacao/PagamentoAction";

import MaskedInput from "../../../../../common/input/MaskedInput";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import Input from "../../../../../common/input/Input";
import Textarea from "../../../../../common/input/Textarea";

import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Button from "../../../../../common/button/Button";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// MODAIS
import ModalIniciarPagamento from "../modals/ModalIniciarPagamento";
import ModalExecucaoPrecificacao from "../modals/ModalExecucaoPrecificacao";
import ModalReiniciarFechamento from "../modals/ModalReiniciarFechamento";
import { formatStatusPGMT } from "../../../../../../utils/helpers/OthersHelpers";
import { toastr } from "react-redux-toastr";
import { convertDecimal } from "../../../../../../utils/helpers/NumberHelper";

let PagamentoFasePagamentoPage = (props) => {
  // MODAIS
  const [openIniciar, setOpenIniciar] = React.useState(false);
  const [openExecutado, setOpenExecutado] = React.useState(false);
  const [openReiniciarFechamento, setOpenReiniciarFechamento] = React.useState(false);
  // CONDIÇÕES
  // const [emailAgente, setEmailAgente] = React.useState("");
  const [agenteFiduciarioEmails, setAgenteFiduciarioEmails] = React.useState([]);
  const [bloqueiaCamposJuros, setBloqueiaCamposJuros] = React.useState(false);
  const [bloqueiaAmex, setBloqueiaAmex] = React.useState(false);
  const [bloqueiaRadioAmortizacao, setBloqueiaRadioAmortizacao] = React.useState(false);
  const [pagamentoAmortizacao, setPagamentoAmortizacao] = React.useState(false);
  const [bloqueiaJurosValorAPagar, setBloqueiaJurosValorAPagar] = React.useState(false);
  const [bloqueiaJurosPercentual, setBloqueiaJurosPercentual] = React.useState(false);
  // const [bloqueiaAmortValorAPagar, setBloqueiaAmortValorAPagar] = React.useState(false);
  const [bloqueiaAmortPercentual, setBloqueiaAmortPercentual] = React.useState(false);

  const [loadedEmailsAF, setLoadedEmailsAF] = React.useState(false);

  // ACESSO
  const acessosUser = JSON.parse(localStorage.getItem("userLogged")).acessos;
  const acessoDefault = acessosUser.some((acesso) => acesso === 3 || acesso === 1);

  const {
    pagamento,
    resetAmortizacao,
    calcularJurosValorAPagar,
    calcularJurosPercentual,
    calcularAmortizacaoValorAPagar,
    calcularAmortizacaoPercentual,
    calcularValorFechamentoUnitario,
    calcularPagamentoTotalPMT,
    calcularSaldoFechamento,
    maxJurosValorTotal,
    minJurosValorTotal,
    resetaAmex,
    executarPrecificacao,
    formValues,
    precificacao,
    cancelarRevisao,
    limpaModalPrecificacao,
  } = props;

  // prettier-ignore
  const { precisao } = pagamento;

  let jurosValorAPagarPrecisao = precisao?.juros?.aplicarPrecisao ? precisao?.juros?.valorPrecisao : null;
  let amortizacaoValorAPagarPrecisao = precisao?.amortizacao?.aplicarPrecisao ? precisao?.amortizacao?.valorPrecisao : null;
  let amortizacaoPercentualPrecisao = precisao?.taxaAmortizacao?.aplicarPrecisao ? precisao?.taxaAmortizacao?.valorPrecisao : null;

  // prettier-ignore
  const moedaSimbolo = pagamento?.moeda?.simbolo || "R$";
  const statusId = pagamento?.ultimaEtapa?.id;
  const emRevisao = statusId == 8 && pagamento?.emRevisao;

  // AJUSTA TIPO
  React.useEffect(() => {
    let key = pagamento?.pagamentoJuros;
    if (key) {
      checaJurosPercentualValor(key);
      checaOpcoesRadio(key);
    }
  }, [pagamento?.pagamentoJuros]);

  // INSERE EMAILS
  React.useEffect(() => {
    let emails = pagamento?.agenteFiduciarioEmails;
    if (emails && emails.length > 0 && !loadedEmailsAF) {
      setLoadedEmailsAF(true);
      setAgenteFiduciarioEmails([...pagamento.agenteFiduciarioEmails]);
    }
  }, [pagamento?.agenteFiduciarioEmails]);

  React.useEffect(() => {
    let amortizacao = pagamento?.pagamentoAmortizacao;
    setPagamentoAmortizacao(amortizacao);
  }, [pagamento?.pagamentoAmortizacao]);

  // ABRE MODAL PRECIFICAÇÃO APÓS RESULTADO DA EXECUÇÃO
  React.useEffect(() => {
    if (precificacao?.open) {
      setOpenExecutado(true);
    }
  }, [precificacao?.open]);

  React.useEffect(() => {
    if (!pagamentoAmortizacao) {
      resetAmortizacao();
      calcularPagamentoTotalPMT();
    }
  }, [pagamentoAmortizacao]);

  function checaJurosPercentualValor(key) {
    setBloqueiaCamposJuros(key == 6 ? false : true);
  }

  function checaOpcoesRadio(key) {
    setBloqueiaRadioAmortizacao(key == 3 ? false : true);
    if (key !== 3) {
      resetAmortizacao();
      calcularPagamentoTotalPMT();
      setPagamentoAmortizacao(false);
    }
  }

  function checaTemPagamento() {
    return Object.keys(pagamento).length > 0;
  }

  return (
    <div className="card bg-transparent border-0 px-4 shadow-none">
      {/* RESUMO */}
      <div className="card-body shadow-custom bg-white rounded-12 col-12">
        {checaTemPagamento() ? (
          <>
            <div className="d-flex flex-xl-row flex-column justify-content-between align-items-xl-center">
              <div className="d-flex flex-row align-items-center">
                <h4 className="page-head__title m-0 f-36 font-weight-bold mr-3">
                  {pagamento.codigoOpea} - {pagamento.codigoIF}
                </h4>
                {pagamento?.ultimaEtapa?.descricao && (
                  <span
                    className={`rounded-pill text-uppercase border border-darken-1 px-2 py-0 workflow-tag sts-${statusId}`}
                  >
                    {formatStatusPGMT(statusId)}
                  </span>
                )}
              </div>

              {checaTemPagamento() &&
                !pagamento.responsavelGestao &&
                statusId <= 2 && (
                  <>
                    <Button
                      name={"Iniciar"}
                      action={() => setOpenIniciar(true)}
                      classes="blue-bg-imp rounded btn-search py-2 px-3 m-0 text-capitalize f-14"
                    />

                    {openIniciar && (
                      <ModalIniciarPagamento
                        open={openIniciar}
                        close={() => setOpenIniciar(false)}
                      />
                    )}
                  </>
                )}
            </div>
            <div className="row px-0 mt-2">
              <div className="col-xl-2">
                <Field
                  component={Input}
                  inputId="fundo"
                  name="fundo"
                  label="Fundo"
                  readOnly={true}
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={Input}
                  inputId="subClasse.value"
                  name="subClasse.value"
                  label="Subclasse"
                  readOnly={true}
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={Input}
                  inputId="emissao"
                  name="emissao"
                  label="Emissão"
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row px-0 mt-2">
              <div className="col-xl-2">
                <Field
                  component={Input}
                  inputId="camaraLiquidacao"
                  name={"camaraLiquidacao"}
                  label="Câmara Liquidação"
                  readOnly={true}
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={Input}
                  inputId="dataEvento"
                  name="dataEvento"
                  label="Data Evento"
                  readOnly={true}
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={Input}
                  inputId="dataPagamento"
                  name="dataPagamento"
                  label="Data Pagamento"
                  readOnly={true}
                />
              </div>
              {pagamento.responsavelGestao && (
                <div className="col-xl-4">
                  <Field
                    component={Input}
                    inputId="responsavelGestao"
                    name="responsavelGestao"
                    label="Responsável"
                    readOnly={true}
                  />
                </div>
              )}
            </div>
            <div className="row px-0">
              <div className="col-xl-2">
                <Field
                  component={MaskedInput}
                  inputId="valorNominalInicial"
                  name="valorNominalInicial"
                  label="Valor Nominal Inicial"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={8}
                  fixedDecimalScale
                  placeholder={`${moedaSimbolo} 0,00`}
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={MaskedInput}
                  inputId="valorNominalAtualizado"
                  name="valorNominalAtualizado"
                  label="Valor Nominal Atualizado"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={8}
                  fixedDecimalScale
                  placeholder={`${moedaSimbolo} 0,00`}
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={MaskedInput}
                  inputId="jurosRemuneracao"
                  name="jurosRemuneracao"
                  label="Juros Remuneração"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={8}
                  fixedDecimalScale
                  placeholder={`${moedaSimbolo} 0,00`}
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={Input}
                  inputId="pagamentoCorrecaoMonetaria.value"
                  name={"pagamentoCorrecaoMonetaria.value"}
                  label="Pagamento Correção"
                  readOnly={true}
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={MaskedInput}
                  inputId="correcaoMonetaria"
                  name="correcaoMonetaria"
                  label="Correção Monetária"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={8}
                  fixedDecimalScale
                  placeholder={`${moedaSimbolo} 0,00`}
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={MaskedInput}
                  inputId="quantidadeIntegralizada"
                  name="quantidadeIntegralizada"
                  label="Quantidade Integralizada"
                  readOnly={true}
                  decimalScale={0}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="row px-3">
            <div className="line-place rounded-8 md col-lg-3 my-0"></div>
            <div className="line-place rounded-8 md col-lg-11 my-3"></div>
            <div className="line-place rounded-8 md col-lg-8 my-0"></div>
          </div>
        )}
      </div>

      {/* VALIDAÇÃO */}
      <div className="card-body shadow-custom bg-white rounded-12 col-12 mt-4">
        <h4 className="page-head__title h3 mx-0 mt-0 mb-3 f-22 font-weight-bold">
          Etapa - Validação
        </h4>

        {checaTemPagamento() ? (
          <>
            <div className="row px-0">
              <div className="col-xl-3">
                <Field
                  component={DropdownListInput}
                  inputId="tipoEvento"
                  name="tipoEvento"
                  label="Tipo de Evento"
                  textField="value"
                  valueField="key"
                  readOnly={true}
                  filter="contains"
                />
              </div>
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  inputId="pagamentoValorPrevisto"
                  name="pagamentoValorPrevisto"
                  label="Valor Previsto"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                  placeholder={`${moedaSimbolo} 0,00`}
                />
              </div>
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  inputId="valorFechamento"
                  name="valorFechamento"
                  label="Valor do Fechamento"
                  className={
                    !pagamento.responsavelGestao && statusId == 2 && "blocked"
                  }
                  readOnly={
                    (!pagamento.responsavelGestao || statusId > 2) && !emRevisao
                  }
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                  onValueChange={() => {
                    calcularValorFechamentoUnitario();
                    calcularSaldoFechamento();
                  }}
                  blur={true}
                  required
                />
              </div>
            </div>
            <span className="page-head__title mt-3 mb-1">Juros</span>
            <div className="row px-0">
              <div className="col-xl-3">
                <Field
                  component={DropdownListInput}
                  inputId="pagamentoJuros"
                  name="pagamentoJuros"
                  label="Tipo"
                  options={pagamento.pagamentoJurosEnum}
                  textField="value"
                  valueField="key"
                  required
                  outerClassName={
                    !pagamento.responsavelGestao && statusId == 2 && "blocked"
                  }
                  readOnly={
                    (!pagamento.responsavelGestao || statusId > 2) && !emRevisao
                  }
                  filter="contains"
                  onChange={(e) => {
                    checaOpcoesRadio(e.key);
                    checaJurosPercentualValor(e.key);

                    if (e.key === 3) maxJurosValorTotal();

                    if (e.key === 9) minJurosValorTotal();

                    if (e.key === 12) {
                      resetaAmex();
                      minJurosValorTotal();
                      setBloqueiaAmex(true);
                    } else setBloqueiaAmex(false);
                  }}
                />
              </div>
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  inputId="jurosValorPrevisto"
                  name="jurosValorPrevisto"
                  label="Valor Previsto"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={jurosValorAPagarPrecisao}
                  fixedDecimalScale
                />
              </div>
              <div className="col-xl-2">
              <Field
                  component={MaskedInput}
                  inputId="jurosPercentual"
                  name="jurosPercentual"
                  label="Percentual"
                  className={
                    !pagamento.responsavelGestao && statusId == 2 && "blocked"
                  }
                  readOnly={
                    bloqueiaJurosPercentual ||
                    bloqueiaCamposJuros ||
                    ((!pagamento.responsavelGestao || statusId > 2) &&
                      !emRevisao)
                  }
                  suffix={"%"}
                  thousandSeparator={"."}
                  decimalSeparator={","}          
                  decimalScale={16}
                  fixedDecimalScale
                  onValueChange={(value) => {
                    setBloqueiaJurosValorAPagar(convertDecimal(value) ? true : false);
                    calcularJurosValorAPagar(jurosValorAPagarPrecisao);
                    calcularPagamentoTotalPMT();
                  }}
                  blur={true}
                  allowNegative={false}
                />
              </div>
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  inputId="jurosValorAPagar"
                  name="jurosValorAPagar"
                  label="Valor a Pagar"
                  className={
                    !pagamento.responsavelGestao && statusId == 2 && "blocked"
                  }
                  readOnly={
                    bloqueiaJurosValorAPagar || 
                    bloqueiaCamposJuros ||
                    ((!pagamento.responsavelGestao || statusId > 2) &&
                      !emRevisao)
                  }
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={jurosValorAPagarPrecisao}
                  fixedDecimalScale
                  onValueChange={(value) => {
                    setBloqueiaJurosPercentual(convertDecimal(value) ? true : false);
                    calcularJurosPercentual(jurosValorAPagarPrecisao);
                    calcularPagamentoTotalPMT();
                  }}
                  blur={true}
                  allowNegative={false}
                />
              </div>
            </div>
            <span className="page-head__title mt-3 mb-1">Amortização</span>
            <div className="row px-0">
              <div className="col-xl-3">
                <label className="control-label mx-0 mt-2 mb-0 txt-grey">
                  Pagar Amortização?
                  <span className="text-warning font-weight-bold ml-1">*</span>
                </label>
                {(statusId == 2 || emRevisao) && (
                  <RadioGroup
                    row
                    name="pagamentoAmortizacao"
                    // prettier-ignore
                    value={pagamentoAmortizacao ? "Sim" : "Nao"}
                    onChange={(e) =>
                      setPagamentoAmortizacao(
                        e.target.value == "Sim" ? true : false
                      )
                    }
                  >
                    <FormControlLabel
                      disabled={
                        bloqueiaRadioAmortizacao || !pagamento.responsavelGestao
                      }
                      value="Sim"
                      label="Sim"
                      control={
                        <Radio
                          checkedIcon={
                            <CheckCircle style={{ color: "#83BF74" }} />
                          }
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={
                        bloqueiaRadioAmortizacao || !pagamento.responsavelGestao
                      }
                      value="Nao"
                      label="Não"
                      control={
                        <Radio
                          checkedIcon={
                            <CheckCircle style={{ color: "#83BF74" }} />
                          }
                        />
                      }
                    />
                  </RadioGroup>
                )}
                {statusId > 2 && !emRevisao && (
                  <div className="mt-2 fw-400">
                    <span>
                      {pagamento.pagamentoAmortizacao ? "Sim" : "Não"}
                    </span>
                  </div>
                )}
              </div>
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  inputId="amortizacaoValorPrevisto"
                  name="amortizacaoValorPrevisto"
                  label="Valor Previsto"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={amortizacaoValorAPagarPrecisao}
                  fixedDecimalScale
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={MaskedInput}
                  inputId="amortizacaoPercentual"
                  name="amortizacaoPercentual"
                  label="Percentual"
                  className={
                    !pagamento.responsavelGestao && statusId == 2 && "blocked"
                  }
                  readOnly={
                    bloqueiaAmortPercentual ||
                    !pagamentoAmortizacao ||
                    ((!pagamento.responsavelGestao || statusId > 2) &&
                      !emRevisao)
                  }
                  suffix={"%"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={amortizacaoPercentualPrecisao}
                  fixedDecimalScale
                  onValueChange={(value) => {
                    // setBloqueiaAmortValorAPagar(convertDecimal(value) ? true : false);
                    calcularAmortizacaoValorAPagar(amortizacaoValorAPagarPrecisao, amortizacaoPercentualPrecisao);
                    calcularPagamentoTotalPMT();
                  }}
                  blur={true}
                  allowNegative={false}
                />
              </div>
              <div className="col-xl-3">
                <Field
                  component={MaskedInput}
                  inputId="amortizacaoValorAPagar"
                  name="amortizacaoValorAPagar"
                  label="Valor a Pagar"
                  className={
                    !pagamento.responsavelGestao && statusId == 2 && "blocked"
                  }
                  readOnly={
                    true
                    // bloqueiaAmortValorAPagar ||
                    // !pagamentoAmortizacao ||
                    // ((!pagamento.responsavelGestao || statusId > 2) &&
                    //   !emRevisao)
                  }
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={amortizacaoValorAPagarPrecisao}
                  fixedDecimalScale
                  placeholder={`${moedaSimbolo} 0,00000000`}
                  onValueChange={(value) => {
                    setBloqueiaAmortPercentual(convertDecimal(value) ? true : false);
                    calcularAmortizacaoPercentual(amortizacaoValorAPagarPrecisao, amortizacaoPercentualPrecisao);
                    calcularPagamentoTotalPMT();
                  }}
                  blur={true}
                  allowNegative={false}
                  icon="info-circle"
                  iconColor="#630a3679"
                  tooltipMessage="Para aumentar a quantidade de casas decimais do percentual de amortização, alterar a variável Taxa de Amortização (%) na aba de Pagamentos do Cadastro da Operação"
                  tooltipId="id-amortizacaoValorAPagar"
                />
              </div>
            </div>
            <span className="page-head__title mt-3 mb-1">Extraordinários</span>
            <div className="row px-0">
              <div className="col-xl-3 col-lg-4">
                <Field
                  component={MaskedInput}
                  inputId="amex"
                  name="amex"
                  label={`Amex (${moedaSimbolo})`}
                  className={
                    !pagamento.responsavelGestao && statusId == 2 && "blocked"
                  }
                  readOnly={
                    bloqueiaAmex ||
                    ((!pagamento.responsavelGestao || statusId > 2) &&
                      !emRevisao)
                  }
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={8}
                  allowNegative={false}
                  fixedDecimalScale
                  placeholder={`${moedaSimbolo} 0,00000000`}
                  onValueChange={() =>
                    calcularPagamentoTotalPMT()
                  }
                  blur={true}
                />
              </div>

              <div className="col-xl-3 col-lg-4">
                <Field
                  component={MaskedInput}
                  inputId="eventoGenerico"
                  name="eventoGenerico"
                  label={`Evento Genérico (${moedaSimbolo})`}
                  className={
                    !pagamento.responsavelGestao && statusId == 2 && "blocked"
                  }
                  readOnly={
                    (!pagamento.responsavelGestao || statusId > 2) && !emRevisao
                  }
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={8}
                  fixedDecimalScale
                  placeholder={`${moedaSimbolo} 0,00000000`}
                  onValueChange={() =>
                    calcularPagamentoTotalPMT()
                  }
                  blur={true}
                  allowNegative={false}
                />
              </div>
            </div>
            {(statusId == 2 ||
              (statusId > 2 && pagamento.observacao) ||
              emRevisao) && (
              <div className="row px-0">
                <div className="col-lg-12">
                  <Field
                    component={Textarea}
                    inputId="observacao"
                    name="observacao"
                    label="Observação"
                    type="text"
                    rows={6}
                    maxLength={500}
                    minHeight={4}
                    className={`
                      txtarea-fechamento${
                        statusId > 2 && pagamento.observacao ? "-auto" : ""
                      }
                      ${
                        !pagamento.responsavelGestao &&
                        statusId == 2 &&
                        "blocked"
                      }
                    `}
                    readOnly={
                      (!pagamento.responsavelGestao || statusId > 2) &&
                      !emRevisao
                    }
                  />
                </div>
              </div>
            )}
            <div className="row px-0">
              <div className="col-xl-2">
                <Field
                  component={MaskedInput}
                  inputId="valorFechamentoUnitario"
                  name="valorFechamentoUnitario"
                  label="Valor Fechamento (Unitário)"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={8}
                  fixedDecimalScale
                  placeholder={`${moedaSimbolo} 0,00000000`}
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={MaskedInput}
                  inputId="pagamentoTotalPmt"
                  name="pagamentoTotalPmt"
                  label="Pagamento Total PMT"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                  placeholder={`${moedaSimbolo} 0,00`}
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={MaskedInput}
                  inputId="pagamentoTotalPmtUnitario"
                  name="pagamentoTotalPmtUnitario"
                  label="Pagamento Total PMT (Unitário)"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  decimalScale={8}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={MaskedInput}
                  inputId="saldoFechamento"
                  name="saldoFechamento"
                  label="Saldo Fechamento"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                />
              </div>
              <div className="col-xl-2">
                <Field
                  component={MaskedInput}
                  inputId="saldoFechamentoUnitario"
                  name="saldoFechamentoUnitario"
                  label="Saldo Fechamento (Unitário)"
                  readOnly={true}
                  prefix={`${moedaSimbolo} `}
                  thousandSeparator={"."}
                  decimalScale={8}
                  fixedDecimalScale
                  decimalSeparator={","}
                />
              </div>
            </div>

            {/* EMAILS ADM. FUNDO */}
            <span className="page-head__title mt-3 mb-1">
              E-mails do Administrador do Fundo
            </span>
            <div className="row px-0">
              <div className="col-lg-6">
                <Field
                  component={Input}
                  inputId="agenteFiduciario"
                  name={"agenteFiduciario"}
                  label="Administrador"
                  readOnly={true}
                />
              </div>
            </div>

            <div className={`row px-3 ${!(statusId == 2 || emRevisao) && 'mt-2'}`}>
              <div className="d-flex flex-wrap mb-2">
                <TransitionGroup className={"d-flex flex-wrap"}>
                  {agenteFiduciarioEmails.map((email, index) => {
                    return (
                      <CSSTransition key={index} timeout={300} classNames="item">
                        <div
                          key={index}
                          className="mr-2 rounded-pill d-flex flex-row text-nowrap px-2 py-1 bg-df-gray align-items-center mb-2"
                        >
                          <label className="p-color-imp m-0 f-12">{email}</label>
                        </div>
                      </CSSTransition>
                    );
                  })}
                </TransitionGroup>
              </div>
            </div>
          </>
        ) : (
          <div className="row px-3">
            <div className="px-0 col-12">
              <div className="line-place rounded-8 md col-lg-5 mt-0"></div>
            </div>
            <div className="px-0 col-12">
              <div className="line-place rounded-8 md col-lg-4"></div>
            </div>
            <div className="line-place rounded-8 md col-lg-8"></div>
            <div className="line-place rounded-8 md col-lg-9"></div>
            <div className="line-place rounded-8 md col-lg-4"></div>
            <div className="line-place rounded-8 xlg col-lg-11"></div>
            <div className="line-place rounded-8 md col-lg-10"></div>
            <div className="line-place rounded-8 md col-lg-6 mb-0"></div>
          </div>
        )}
      </div>
      {/* BOTÕES INFERIORES */}
      <div className="card-body p-0 col-12 mt-4">
        {/* EXECUTAR PRECIFICAÇÃO */}
        {((checaTemPagamento() &&
          pagamento.responsavelGestao &&
          statusId == 2) ||
          emRevisao) && (
          <div
            className={`d-flex flex-row justify-content-${
              emRevisao ? "between" : "end"
            }`}
          >
            {emRevisao && (
              <Button
                name={"Cancelar Correção"}
                action={() => {
                  cancelarRevisao({
                    idPagamentoPrecificacao: pagamento?.idPagamentoPrecificacao,
                  });
                }}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              />
            )}

            {/* prettier-ignore */}
            <Button
              name={"Executar precificação"}
              action={() => {
                if (formValues.valorFechamento) {
                  executarPrecificacao(pagamento, {
                    ...formValues,
                    agenteFiduciarioEmails,
                    pagamentoAmortizacao,
                  });
                } else {
                  toastr.error("Erro", "Não é permitido realizar o fechamento sem informar o Valor do Fechamento");
                }
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        )}
        {/* REINICIAR FECHAMENTO */}
        {checaTemPagamento() && statusId >= 3 && statusId !== 8 && (
          <div className="d-flex flex-row justify-content-start">
            <Button
              name={"Reiniciar Fechamento"}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
              action={() => acessoDefault ? setOpenReiniciarFechamento(true) : toastr.error("Erro", "Usuário não possui permissão.")}
            />
          </div>
        )}
      </div>

      {/* OUTROS MODAIS */}
      {openReiniciarFechamento && (
        <ModalReiniciarFechamento
          open={openReiniciarFechamento}
          close={() => setOpenReiniciarFechamento(false)}
        />
      )}
      {openExecutado && (
        <ModalExecucaoPrecificacao
          open={openExecutado}
          close={() => {
            limpaModalPrecificacao();
            setOpenExecutado(false);
          }}
          idPagamentoPrecificacao={precificacao?.idPagamentoPrecificacao}
        />
      )}
    </div>
  );
};

PagamentoFasePagamentoPage = reduxForm({
  form: "FasePagamentoForm",
  destroyOnUnmount: true,
})(PagamentoFasePagamentoPage);

const selector = formValueSelector("FasePagamentoForm");

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
  precificacao: state.pagamentoReducer.openModalExecucaoPrecificacao,
  // DADOS DO FORMULÁRIO
  // prettier-ignore
  formValues: selector(
    state, "pagamentoValorPrevisto", "valorFechamento", "pagamentoJuros", "jurosValorPrevisto", "jurosPercentual",
    "jurosValorAPagar", "pagamentoAmortizacao", "amortizacaoValorPrevisto", "amortizacaoPercentual", "amortizacaoValorAPagar",
    "amex", "eventoGenerico", "observacao", "valorFechamentoUnitario", "pagamentoTotalPmt",
    "pagamentoTotalPmtUnitario", "saldoFechamento", "saldoFechamentoUnitario"
  ),
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      resetAmortizacao,
      calcularJurosValorAPagar,
      calcularJurosPercentual,
      calcularAmortizacaoValorAPagar,
      calcularAmortizacaoPercentual,
      calcularValorFechamentoUnitario,
      calcularPagamentoTotalPMT,
      calcularSaldoFechamento,
      maxJurosValorTotal,
      minJurosValorTotal,
      resetaAmex,
      executarPrecificacao,
      cancelarRevisao,
      limpaModalPrecificacao,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(PagamentoFasePagamentoPage);
