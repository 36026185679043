import { removeFormat } from '../../../../utils/helpers/StringHelper';

export function deserializeFieldsUsuarioPortalFormFilter(filters, page) {
    const {email, cpf, portalUsuarioStatusValidacao, nome } = filters;

    return {
        email,
        cpf: cpf ? removeFormat(cpf) : '',
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        },
        portalUsuarioStatusValidacao,
        nome,
    }
}
