import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { saveIndice } from "../../../../../actions/precificacao/IndiceAction";
import { loadFilterFormIndice, cleanResult } from "../../../../../actions/precificacao/IndiceAction";

import ContainerPage from "../../../../common/container/ContainerPage";
import IndiceForm from "./form/IndiceForm";

class IndiceIncludePage extends Component {
  componentDidMount() {
    if (!this.props.formTypes.taxaList) {
      this.props.loadFilterFormIndice();
      this.props.cleanResult();
    }
  }

  render() {
    const { saveIndice } = this.props;

    return (
      <ContainerPage>
        <IndiceForm title={"Novo - Índice"} onSubmit={saveIndice} readOnly={false} floatButtonVisible={true} />
      </ContainerPage>
    );
  }
}

const mapStateToProps = (state) => ({
  formTypes: state.indiceReducer.indiceFormTypes || {},
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      saveIndice,
      loadFilterFormIndice,
      cleanResult,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(IndiceIncludePage));
