import {
    LOADING_CHANGE,
    LOADING_MESSAGE
} from '../../actions/infra/LoadingScreenAction';

const INITIAL_STATE = {
    isLoading: false
}

export default function loadingScreen(state = INITIAL_STATE, action = {}) {
    switch(action.type) {
        case LOADING_CHANGE:
            return {
                ...state,
                isLoading: action.payload
            }
        case LOADING_MESSAGE:
            return {
                ...state,
                messageLoading: action.payload
            }
        default:
            return state;
    }
}