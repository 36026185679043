/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field, formValueSelector } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";

import GenericPickerInput from "../../../../common/input/GenericPickerInput";
import Input from "../../../../common/input/Input";
import Button from "../../../../common/button/Button";

import {
  getCota,
  getSugestaoFundoCota,
  loadFilterFormCota,
} from "../../../../../actions/cota/CotaAction";

import { DropdownList } from "react-widgets";

let CotaListFilter = (props) => {
  const { getCota, loadFilterFormCota, filtros, cotaConsult, dataInicioField, dataFinalField } = props;

  const [idsUltimasEtapas, setIdsUltimasEtapas] = React.useState([]);
  const [fundos, setFundos] = React.useState();
  const [dataInicio, setDataInicio] = React.useState("");
  const [dataFinal, setDataFinal] = React.useState("");
  const [idPrestadorEmpresa, setIdPrestadorEmpresa] = React.useState(null);

  const chooseStatus = (key) => {
    if (idsUltimasEtapas.includes(key)) {
      let newArrIds = idsUltimasEtapas.filter((elemento) => elemento !== key);
      setIdsUltimasEtapas(newArrIds);
    } else {
      setIdsUltimasEtapas([...idsUltimasEtapas, key]);
    }
  };

  const checkStatus = (key) => {
    return idsUltimasEtapas?.some((o) => o == key) ? key : "";
  };

  function recebeDados() {
    return {
      dataInicio,
      dataFinal,
      idsFundo: fundos?.map((e) => e.key),
      idPrestadorEmpresa: idPrestadorEmpresa?.idEmpresaPrestadora,
      status: idsUltimasEtapas.map((e) => filtros?.statusLista.find((s) => s.key === e)),
    };
  }

  function searchFilters() {
    const data = recebeDados();
    getCota(data);
  }

  React.useEffect(() => {
    if (dataInicioField && dataFinalField) {
      setDataInicio(dataInicioField);
      setDataFinal(dataFinalField);
    }
  }, [dataInicioField, dataFinalField])

  React.useEffect(() => {
    loadFilterFormCota(cotaConsult)
  }, [])

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Validações Pendentes - Cotas"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getCota}
        >
          <div className="row w-100 align-items-start mx-0 mt-2">
            <div className="col-12 align-self-start mt-1p mb-xl-3 pl-0 pr-0 pr-xl-2">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Busca
                </label>
                <GenericPickerInput
                  loadData={props.getSugestaoFundoCota}
                  options={props.options}
                  activeClassName="inputPgmt"
                  formName={"CotaForm"}
                  fieldName={"operacao"}
                  isMulti={true}
                  placeholder={"Pesquisar fundo ex.: FIDC Abc"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={fundos}
                  selectedOptionForm={(e) => setFundos(e)}
                />
              </div>
            </div>

            <div className="col-12 col-xl-2 align-self-start px-0 mt-xl-0 mt-3">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataInicio"
                type={"date"}
                name="dataInicio"
                label="Data Início"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </div>

            <div className="col-12 col-xl-2 align-self-start px-0 px-xl-2">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataFinal"
                type={"date"}
                name="dataFinal"
                label="Data Final"
                value={dataFinal}
                onChange={(e) => setDataFinal(e.target.value)}
              />
            </div>

            {filtros && filtros?.administradorFiduciarios?.length > 0 && (
              <div className="col-12 col-xl-2 align-self-start mt-1p px-0 px-xl-2 mb-xl-0 mb-3">
                <div className="d-flex flex-column">
                  <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                    Administrador Fiduciário
                  </label>
                  <DropdownList
                    value={idPrestadorEmpresa}
                    containerClassName="h-100"
                    className="h-38"
                    onChange={(e) => setIdPrestadorEmpresa(e?.idEmpresaPrestadora && e)}
                    textField={"nomeSimplificado"}
                    messages={{
                      emptyList: "Lista vazia",
                      emptyFilter: "Nenhum registro encontrado",
                    }}
                    data={[{ nomeSimplificado: "Selecione..." } , ...filtros?.administradorFiduciarios]}
                  />
                </div>
              </div>
            )}

            {filtros && filtros?.statusLista?.length > 0 && (
              <div className="d-flex flex-column col-12 col-xl-6 px-0 px-xl-2 mb-xl-0 mb-2">
                <label className="txt-grey f-10-5 mt-1p">Status Validação</label>
                <div className="col-12 col-xl-8 w-100 d-flex flex-row flex-wrap align-items-center px-0">
                  {filtros.statusLista.map((sts, index) => {
                    return (
                      <div
                        key={`sts-${index}`}
                        className="col-auto mb-1 pl-0 pr-2"
                      >
                        <div key={`sts-${index}`} className="col-auto mb-1 px-2 tr-status">
                          <div
                            role="button"
                            onClick={() => chooseStatus(sts.key)}
                            className="d-flex flex-row align-items-center td-status prevent-select"
                          >
                            <div
                              data-status={sts.key}
                              className={`circle-status sts-hist-${checkStatus(sts.key)} rounded-circle mr-1`}
                            ></div>
                            <p className="mb-0 mt-1px">{sts.value}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="row w-100 align-items-start mx-0">
              <div className="col-xl-2 col-12 px-0 mt-0">
                <Button
                  activeClassName="w-100"
                  action={() => searchFilters()}
                  classes="p-bg-imp rounded btn-search h-38 mt-2 mr-2 w-100 d-flex justify-content-center"
                  name={"Buscar"}
                />
              </div>
            </div>
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

CotaListFilter = reduxForm({ form: "CotaForm" })(CotaListFilter);
const selector = formValueSelector("CotaForm");

const mapStateToProps = (state) => ({
  options: state.cotaReducer.options,
  filtros: state.cotaReducer.cotaFormTypesFilter,
  cotaConsult: state.cotaReducer.cotaConsultCurrent || null,

  dataInicioField: selector(state, "dataInicio") || null,
  dataFinalField:  selector(state, "dataFinal") || null,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCota,
      getSugestaoFundoCota,
      loadFilterFormCota
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CotaListFilter);
