
import { initialize } from 'redux-form';
import { 
    formatPercentToNumber, 
    formatDecimalToNumber 
} from './../../../../utils/helpers/NumberHelper';

import {
    isTaxaMensal
} from './../../../../actions/precificacao/IndiceAction';

export function serializeFieldsIndiceForm(indice) {
    let indiceSerialized = {};
    
    
    if(indice) {
        let taxaMensal  = false;

        if (indice.taxa) {
            taxaMensal = isTaxaMensal(indice.taxa);
        }

        indiceSerialized = {
            ...indice,
            dataInicio: taxaMensal ? indice.dataInicio.slice(0, -3) : indice.dataInicio
        };
    } else {
        indiceSerialized = {
            valorTaxa: null,
            dataInicio: null,
            numeroIndice: null,
            valorTaxaProjecao: null,
            dataInicioProjecao: null,
            dataFimProjecao: null,
            taxa: {id: null, descricao: ''}
        };
    }

    return initialize("indiceForm", indiceSerialized);
}

export function deserializeFieldsIndiceForm(indice, isTaxaMensal) {

    return {
        ...indice,
        dataInicio: isTaxaMensal && indice.dataInicio ? `${indice.dataInicio}-01` : indice.dataInicio,
        valorTaxa: formatPercentToNumber(indice.valorTaxa),
        valorTaxaProjecao: formatPercentToNumber(indice.valorTaxaProjecao),
        numeroIndice: formatDecimalToNumber(indice.numeroIndice),
    }
}

export function deserializeFieldsCalculoVariacaoPercentualIndice(tipoTaxa, dataInicio, numeroIndice) {
    return {
        id: null,
        taxa: {id: tipoTaxa?.id ?? 0, descricao: tipoTaxa?.descricao ?? null},
        valorTaxa: null,
        dataInicio: dataInicio,
        numeroIndice: formatDecimalToNumber(numeroIndice),
        valorTaxaProjecao: null,
        dataInicioProjecao: null,
        dataFimProjecao: null,
    }
}

export function deserializeFieldsIndiceFormFilter(filters, page, taxaMensal) {
    return {
        taxa: filters.tipoTaxa ? filters.tipoTaxa : {id: 0},
        dataInicio: taxaMensal && filters.dataInicio ? `${filters.dataInicio}-01`  : filters.dataInicio,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        },
    }
}

export function deserializeFieldsIndiceFormFilterExportExcel(filters, taxaMensal) {
    return {
        taxa: filters.tipoTaxa,
        dataInicio:  taxaMensal && filters.dataInicio ? `${filters.dataInicio}-01` : filters.dataInicio,
    }
}