import { getDateFormatedFromString } from "../../../../utils/helpers/DateTimeHelper";
import { convertCurrencyToNumber } from "../../../../utils/helpers/NumberHelper";
import { formatCNPJ } from "../../../../utils/helpers/StringHelper";

export function deserializeFieldsCarteiraPrecificacaoFormFilter(filters, page) {
    return {
        ...filters,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10,
        },
    };
}

export function deserializeFieldsCarteiraPrecificacaoFormFilterExportExcel(filters) {
    return {
        
    };
}

export function serializeFieldsCarteiraFluxo(carteiraPrecificacao) {
    const { fundo, fundoCarteira } = carteiraPrecificacao;
    const { administradorFiduciario, cnpjDoFundo, dataDeConstituicao, dataDeVencimento } = fundo;

    return {
        administrador: administradorFiduciario?.nomeSimplificado,
        cnpjDoFundo: formatCNPJ(cnpjDoFundo),
        dataDeConstituicao: getDateFormatedFromString(dataDeConstituicao),
        dataDeVencimento: getDateFormatedFromString(dataDeVencimento),
        dataDeReferencia: getDateFormatedFromString(fundoCarteira?.dataBase),
        ...fundoCarteira,

        // form
        disponibilidadeCaixaBlocked: fundoCarteira.disponibilidadeCaixa,
        disponibilidadeCompromissadasBlocked: fundoCarteira.disponibilidadeCompromissadas,
        disponibilidadeTotalBlocked: fundoCarteira.disponibilidadeTotal,
        rendaFixaTotalBlocked: fundoCarteira.rendaFixaTotal,
        direitosCreditoriosTotalBlocked: fundoCarteira.direitosCreditoriosTotal,
        hedgeOpcoesBlocked: fundoCarteira.hedgeOpcoes,
        hedgeSwapBlocked: fundoCarteira.hedgeSwap,
        hedgeTotalBlocked: fundoCarteira.hedgeTotal,
        pddTotalBlocked: fundoCarteira.pddTotal,
        contasPagarBlocked: fundoCarteira.contasPagar,
        contasReceberBlocked: fundoCarteira.contasReceber,
        contasTotalBlocked: fundoCarteira.contasTotal,
    };
}

export function deserializeFieldsCarteira(formValues) {
    return {
        ...formValues,
        disponibilidadeCaixa: convertCurrencyToNumber(formValues.disponibilidadeCaixa),
        disponibilidadeCompromissadas: convertCurrencyToNumber(formValues.disponibilidadeCompromissadas),
        disponibilidadeTotal: convertCurrencyToNumber(formValues.disponibilidadeTotal),
        rendaFixaTotal: convertCurrencyToNumber(formValues.rendaFixaTotal),
        direitosCreditoriosTotal: convertCurrencyToNumber(formValues.direitosCreditoriosTotal),
        hedgeOpcoes: convertCurrencyToNumber(formValues.hedgeOpcoes),
        hedgeSwap: convertCurrencyToNumber(formValues.hedgeSwap),
        hedgeTotal: convertCurrencyToNumber(formValues.hedgeTotal),
        pddTotal: convertCurrencyToNumber(formValues.pddTotal),
        contasPagar: convertCurrencyToNumber(formValues.contasPagar),
        contasReceber: convertCurrencyToNumber(formValues.contasReceber),
        contasTotal: convertCurrencyToNumber(formValues.contasTotal),
    }
}

export function deserializeFieldsCarteiraValidacao(params) {
    return {
        
    };
}

export function deserializeFieldsCarteiraValidacaoConfirmacao(params) {
    return {
        
    }
}

export function deserializeFieldsEtapaLancamento(params) {
    return {
       
    }
}

export function deserializeFieldsEtapaCarteira(formValues) {
    return {

    }
}

export function deserializeFieldsCarteiraAgendamento(params) {
    return {
       
    };
}