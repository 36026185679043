import axios from "axios";

import { showAllMessages } from "./../../actions/message/ErrorMessagesAPIAction";
import { downloadExcelFile } from "./../../utils/helpers/FileHelper";

import {
    deseralizeAgingFormFilter,
    deserializeFieldsAgingFormFilter, 
    deserializeFieldsAgingFormFilterExportExcel
} from "./../../components/business/precificacao/mappings/AgingMap";

export const AGING_FETCHED = 'AGING_FETCHED';
export const GERAR_AGING = 'GERAR_AGING';
export const SHOW_GERAR_AGING_DIALOG = 'SHOW_GERAR_AGING_DIALOG';

const AGING_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PRECIFICACAO_API + process.env.REACT_APP_PATH_AGING_API;


export function getAging(filters, page = 1, ) {
    const params = deserializeFieldsAgingFormFilter(filters, page);

    return (dispatch) => {
        return axios
            .post(`${AGING_API}consultar`, { ...params })
            .then(result =>
                dispatch([
                    {
                        type: AGING_FETCHED,
                        payload: result,
                    },
                ])
            );
    }
}
export function showGerarAgingDialog(showDialog) {
    return (dispatch) => {
        return dispatch([
            {
                type: SHOW_GERAR_AGING_DIALOG,
                payload:  showDialog 
            },
        ])
    }
}

export function exportRelatorioAgingToExcel(filters) {
    let params = deserializeFieldsAgingFormFilterExportExcel(filters);

    return (dispatch) => {
        return axios
            .post(`${AGING_API}exportar/excel`, { ...params })
            .then(result => {
                const fileName = 'RelatorioAging.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function gerarAging(filters){
    let params = deseralizeAgingFormFilter(filters);
    return (dispatch) => {
        return axios
            .post(`${AGING_API}gerar`, { ...params })
            .then(result => {
                dispatch([
                    showGerarAgingDialog(false),
                    {
                        type: GERAR_AGING,
                        payload: result,
                    },
                    showAllMessages(result.messages),
                ])
            });
    }
}