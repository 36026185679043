import React from 'react';
import TablePrecificacaoPrecisao from './TablePrecificacaoPrecisao';

const descricoesList = [
    {descricaoCampo: 'Taxa de Amortização (%)', formula: 'Ta', id: 16}, 

    {descricaoCampo: 'Fator de Juros Diário', formula: '(1 + i)*BaseCalculo*(dp/dt)', rowSpan: 5, checkAplicar: true, id: 2,}, 
    {formula: '(1 + i)', checkAplicar: true, isRowSpan: true, id: 21}, 
    {formula: 'Base de Cálculo', checkAplicar: true, isRowSpan: true, id: 22}, 
    {formula: 'dp/dt', checkAplicar: true, isRowSpan: true, id: 23}, 
    {formula: '(1 + i)*BaseCalculo', checkAplicar: true, isRowSpan: true, id: 24}, 

    {descricaoCampo: 'Fator de Juros/Spread Acumulado', formula: 'Fator de Juros', id: 3}, 
    {descricaoCampo: 'Valor Nominal Inicial (R$)', formula: 'VNI', id: 1}, 
    {descricaoCampo: 'Valor Nominal com Juros (R$)', formula: 'VNJ', id: 7}, 
    {descricaoCampo: 'Valor Residual na Curva (R$)', formula: 'VRC / VRCE', id: 10}, 
    {descricaoCampo: 'Juros (R$)', formula: 'J', id: 4}, 
    {descricaoCampo: 'Amortização (R$)', formula: 'AM', id: 5}, 
    {descricaoCampo: 'Prestação paga Pagamento (R$)', formula: 'PMT', id: 6, disabled: true},  
];

export default (props)  => {
    return (
        <TablePrecificacaoPrecisao 
            listagem={descricoesList} 
            labelTable={props.tipoPagamento}
            readOnly={props.readOnly}
        />
    );
}
