import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import ExportacaoForm from "./form/ExportacaoForm";

import {
  loadFormExportacao,
} from "../../../../actions/exportacao/ExportacaoAction";

class ExportacaoUpdatePage extends Component {
  componentDidMount() {
    let idExportacao = this.props.match.params.idExportacao;
    this.props.loadFormExportacao(idExportacao);
  }

  render() {
    const { identificador } = this.props;

    return (
      <ExportacaoForm
        title={`Editar - Exportacão ${identificador ? `- ${identificador}` : ''}`}
        readOnly={true}
        edit={true}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  identificador: state.exportacaoReducer.exportacaoForm.exportacao?.identificador || null,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormExportacao,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ExportacaoUpdatePage)
);
