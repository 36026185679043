import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import RemessaForm from "./form/RemessaForm";

import {
  loadFormRemessa,
} from "../../../../actions/remessa/RemessaAction";

class RemessaUpdatePage extends Component {
  componentDidMount() {
    let idRemessa = this.props.match.params.idRemessa;
    if (!this.props?.identificador && idRemessa) {
      this.props.loadFormRemessa(idRemessa);
    }
  }

  render() {
    const { identificador } = this.props;

    return (
      <RemessaForm
        title={`Editar ${identificador ? `- ${identificador}` : ''}`}
        readOnly={true}
        edit={true}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  identificador: state.remessaReducer.remessaForm?.identificador || null,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormRemessa,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(RemessaUpdatePage)
);
