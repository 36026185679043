import {
    SHOW_DASHBOARD,
    FILTROS_CHART_FETCHED,
    MONITORAMENTOS_CHART_FETCHED,
    PAGAMENTOS_CHART_FETCHED,
    DIARIO_DE_BORDO_CHART_FETCHED,
    INDICES_DE_INFLACAO_CHART_FETCHED,
    GRUPOS_FETCHED,
    SALDO_FETCHED,
    SERIES_FETCHED,
    NOVAS_SERIES_FETCHED,
    PROXIMOS_PAGAMENTOS_FETCHED,
    PROXIMOS_MONITORAMENTOS_FETCHED,
    DISTRIBUICAO_FETCHED,
} from '../../actions/operacao/DashboardAction';

const INITIAL_STATE = {
    dashboard: null,
    grupoList: [],
    selectedGrupos: [],
    statusPagamentoChartList: [],
    statusMonitoramentoChartList: [],
    tipoDiarioBordoChartList: [],
    monitoramentosChartData: [],
    pagamentosChartData: [],
    diarioDeBordoChartData: [],
    indicesDeInflacaoChartData: [],
    saldoValue:null,
    messages: null,
    isLoading: false,
    seriesValue: 0,
    novasSeriesValue: 0,
    proximosPagamentosValue: 0,
    proximosMonitoramentosValue: 0,
    operacoesEmDistribuicao: [],
}

export default function(state = INITIAL_STATE, action = {}) {
    switch(action.type) {
        case SHOW_DASHBOARD:
            return {
                ...state,
                showDashboard: action.payload
            }
        case FILTROS_CHART_FETCHED:
            return {
                ...state,
                grupoList: action.payload.content.grupoList || [],
                statusPagamentoChartList: action.payload.content.statusPagamentoList || [],
                statusMonitoramentoChartList: action.payload.content.statusMonitoramentoList || [],
                tipoDiarioBordoChartList: action.payload.content.tipoDiarioBordoList || [],
                messages: action.payload.messages,
                isLoading: true,
            };
        case MONITORAMENTOS_CHART_FETCHED:
            return {
                ...state,
                monitoramentosChartData: action.payload.content || [],
                messages: action.payload.messages,
                isLoading: true,
            };
        case PAGAMENTOS_CHART_FETCHED:
            return {
                ...state,
                pagamentosChartData: action.payload.content || [],
                messages: action.payload.messages,
                isLoading: true,
            };
        case DIARIO_DE_BORDO_CHART_FETCHED:
            return {
                ...state,
                diarioDeBordoChartData: action.payload.content || [],
                messages: action.payload.messages,
                isLoading: true,
            };
        case INDICES_DE_INFLACAO_CHART_FETCHED:
            return {
                ...state,
                indicesDeInflacaoChartData: action.payload.content || [],
                messages: action.payload.messages,
                isLoading: true,
            };
        case GRUPOS_FETCHED:
            return {
                ...state,
                selectedGrupos: action.payload || [],
            };
        case SALDO_FETCHED:
            return {
                ...state,
                saldoValue: action.payload.content || [],
            };
        case SERIES_FETCHED:
            return {
                ...state,
                seriesValue: action.payload.content.resultado || 0
            }
        case NOVAS_SERIES_FETCHED:
            return {
                ...state,
                novasSeriesValue: action.payload.content.resultado || 0
            }
        case PROXIMOS_PAGAMENTOS_FETCHED:
            return {
                ...state,
                proximosPagamentosValue: action.payload.content.resultado || 0
            }
        case PROXIMOS_MONITORAMENTOS_FETCHED:
            return {
                ...state,
                proximosMonitoramentosValue: action.payload.content.resultado || 0
            }
        case DISTRIBUICAO_FETCHED:
            return {
                ...state,
                operacoesEmDistribuicao: action.payload.content || []
            }
        default:
            return state;
    }
}