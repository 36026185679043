import axios from "axios";
import { change, reset } from "redux-form";

import { selectTab } from "../operacao/tabs/TabsAction";
import { showAllMessages } from "../message/ErrorMessagesAPIAction";
import { SUBCLASSE_TAB } from "../../utils/tabs";
import { showConfirmDialog } from "../common/modal/ConfirmDialogAction";

import history from "../../components/common/routers/history";
import {
  DETALHAR_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  SUBCLASSE_ROUTE_PATH,
  LISTA_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";

import {
  serializeFieldsSubclasseForm,
  deserializeFieldsSubclasseForm,
} from "../../components/business/subclasse/mappings/SubclasseMap";
import { toastr } from "react-redux-toastr";

export const SERVICER = 7;

export const SUBCLASSE_FORM_TAB = "SUBCLASSE_FORM_TAB";
export const SELECTED_SUBCLASSE_OPTION = "SELECTED_SUBCLASSE_OPTION";
export const SUBCLASSE_OPTIONS_FETCHED = "SUBCLASSE_OPTIONS_FETCHED";
export const SUBCLASSE_FETCHED = "SUBCLASSE_FETCHED";
export const SUBCLASSE_SHOW_LIST = "SUBCLASSE_SHOW_LIST";
export const SUBCLASSE_SHOW_DELETE_DIALOG = "SUBCLASSE_SHOW_DELETE_DIALOG";
export const LOAD_FORM_SUBCLASSE = "LOAD_FORM_SUBCLASSE";
export const LOAD_FORM_SUBCLASSE_COTAS = "LOAD_FORM_SUBCLASSE_COTAS";
export const LOAD_CLASSES_FUNDOLIST = "LOAD_CLASSES_FUNDOLIST";
export const SUBCLASSE_CLEAN_LIST = "SUBCLASSE_CLEAN_LIST";
export const LOAD_FORM_SUBCLASSE_COTAS_EMITIDAS = "LOAD_FORM_SUBCLASSE_COTAS_EMITIDAS";
export const LOAD_TAB_SUBCLASSES_COTAS = "LOAD_TAB_SUBCLASSES_COTAS";
export const SUBCLASSE_PAGINATION = "SUBCLASSE_PAGINATION";
export const RESET = "RESET";

const SUBCLASSE_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_SUBCLASSE;

const FUNDO_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_FUNDO;

const OPERACAO_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API;

const PASSIVO_API = OPERACAO_API + process.env.REACT_APP_PATH_PASSIVO_API;

export function goToFormPage(editAction, idSubclasse) {
  let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;
  return (dispatch) => {
    return dispatch([
      history.push(`/${SUBCLASSE_ROUTE_PATH}/${routeAction}/${idSubclasse}`),
    ]);
  };
}

export function getSubclasse(idSubclasse) {
  return (dispatch) => {
    return axios.get(SUBCLASSE_API + `tela/${idSubclasse}`).then((result) => {
      let idFundo = result.content?.subClasse?.idFundo;

      dispatch([
        {
          type: LOAD_FORM_SUBCLASSE,
          payload: result.content,
        },
        serializeFieldsSubclasseForm(result.content.subClasse),
        loadClassesFundoList(idFundo, false),
      ]);
    });
  };
}

export function loadFormSubclasseCotas(idSubclasse) {
  return (dispatch) => {
    return axios.get(SUBCLASSE_API + `${idSubclasse}/cotas`).then((result) => {
      dispatch([
        {
          type: LOAD_FORM_SUBCLASSE_COTAS,
          payload: result.content,
        },
      ]);
    });
  };
}

export function loadFormSubclasseCotasEmitidas(idSubclasse) {
  return (dispatch) => {
    return axios
      .get(PASSIVO_API + `cotas-emitidas/sub-classe/${idSubclasse}`)
      .then((result) => {
        dispatch([
          {
            type: LOAD_FORM_SUBCLASSE_COTAS_EMITIDAS,
            payload: result.content,
          },
        ]);
      });
  };
}

export function saveSubclasse(subclasse) {
  const subclasseDeserialized = deserializeFieldsSubclasseForm(subclasse);

  return (dispatch) => {
    return axios
      .post(SUBCLASSE_API, { ...subclasseDeserialized })
      .then((result) =>
        dispatch([showAllMessages(result.messages), goBackToList()])
      );
  };
}

export function editSubclasse(subclasse) {
  const subclasseDeserialized = deserializeFieldsSubclasseForm(subclasse);

  return (dispatch) => {
    return axios
      .put(SUBCLASSE_API + subclasse.id, { ...subclasseDeserialized })
      .then((result) =>
        dispatch([showAllMessages(result.messages), goBackToList()])
      );
  };
}

export function removeSubclasse(idSubclasse, idsSubclasses) {
  return (dispatch) => {
    return axios
      .delete(SUBCLASSE_API + idSubclasse)
      .then((result) =>
        dispatch([
          showSubclasseDeleteDialog(false, 0),
          showSubclasseList(true, idsSubclasses),
          showAllMessages(result.messages),
        ])
      );
  };
}

export function goBackToList() {
  return (dispatch) => {
    return dispatch([
      showConfirmDialog(false),
      { type: SUBCLASSE_CLEAN_LIST },
      history.push(`/${SUBCLASSE_ROUTE_PATH}`),
    ]);
  };
}

export function changeSubclasseFormTab(formTab) {
  return (dispatch) => {
    return dispatch([
      {
        type: SUBCLASSE_FORM_TAB,
        payload: formTab,
      },
    ]);
  };
}

export function showSubclasseTabActive() {
  return (dispatch) => {
    return dispatch([selectTab(SUBCLASSE_TAB)]);
  };
}

export function showSubclasseDeleteDialog(showDialog, idSubclasse) {
  return (dispatch) => {
    return dispatch([
      {
        type: SUBCLASSE_SHOW_DELETE_DIALOG,
        payload: { showDialog, idSubclasse },
      },
    ]);
  };
}

export function selectedSubclasseOption(option) {
  let subclassesSelected = option ? option.map((o) => o.key) : [];

  return (dispatch) => {
    return dispatch([
      {
        type: SELECTED_SUBCLASSE_OPTION,
        payload: option,
      },
      showSubclasseList(true, subclassesSelected),
    ]);
  };
}

export function getSugestaoSubclasse(term) {
  return (dispatch) => {
    return axios.get(`${SUBCLASSE_API}sugestoes?termo=${term}`).then((result) =>
      dispatch({
        type: SUBCLASSE_OPTIONS_FETCHED,
        payload: result.content,
      })
    );
  };
}

export function getSubclasses(idsSubclasse, page = 1) {
  let params = idsSubclasse.length > 0 ? idsSubclasse : null;

  let route = `${SUBCLASSE_API}${LISTA_ROUTE_PATH}?pagina=${page}&tamanhoPagina=10`;
  let url = params ? `${route}&ids=${params}` : route;

  return (dispatch) => {
    return axios.get(url).then((result) =>
      dispatch([
        {
          type: SUBCLASSE_FETCHED,
          payload: result,
        },
        {
          type: SUBCLASSE_PAGINATION,
          payload: page,
        }
      ])
    );
  };
}

export function getSubclassesCota(idsOperacoes, page = 1) {
  let params = idsOperacoes.length > 0 ? idsOperacoes : 0;

  return (dispatch) => {
      return axios
          .get(SUBCLASSE_API + `cotas/${params}?pagina=${page}&tamanhoPagina=10`)
          .then(result =>
              dispatch({
                  type: LOAD_TAB_SUBCLASSES_COTAS,
                  payload: result.content
              })
          )
  }
}

export function showSubclasseList(showList, idsSubclasse) {
  return (dispatch) => {
    return dispatch([
      {
        type: SUBCLASSE_SHOW_LIST,
        payload: showList,
      },
      getSubclasses(idsSubclasse),
    ]);
  };
}

export function limpaDependenteOnChange(dependente, value = "") {
  return (dispatch) => {
    dispatch([change("subclasseForm", dependente, value)]);
  };
}

function changeParticipantes(dispatch, fundo, clean = true) {
  if (clean) {
    dispatch([
      change('subclasseForm', 'administradorFiduciario', fundo.administradorFiduciario),
      change('subclasseForm', 'gestora', fundo.gestora),
      change('subclasseForm', 'possuiMultiGestao', typeof fundo.possuiMultiGestao == "boolean" ? (fundo.possuiMultiGestao ? "Sim" : "Não") : null),
      change('subclasseForm', 'gestoraII', fundo.gestoraII),
      change('subclasseForm', 'consultorCredito', fundo.consultorCredito),
      change('subclasseForm', 'controladoria', fundo.controladoria),
      change('subclasseForm', 'distribuidor', fundo.distribuidor),
      change('subclasseForm', 'escriturador', fundo.escriturador),
      change('subclasseForm', 'custodiante', fundo.custodiante),
      change('subclasseForm', 'agenteMonitoramento', fundo.agenteMonitoramento),
      change('subclasseForm', 'agenteFormalizacao', fundo.agenteFormalizacao),
      change('subclasseForm', 'assessorLegal', fundo.assessorLegal),
      change('subclasseForm', 'agenteCobrancaJudicial', fundo.agenteCobrancaJudicial),
      change('subclasseForm', 'agenteCobrancaExtrajudicial', fundo.agenteCobrancaExtrajudicial),
      change('subclasseForm', 'auditoria', fundo.auditoria),
      // USUÁRIOS
      change('subclasseForm', 'idUsuarioGestao', fundo.idUsuarioGestao),
      change('subclasseForm', 'idUsuarioServicoCredito', fundo.idUsuarioServicoCredito),
      change('subclasseForm', 'idUsuarioCompliance', fundo.idUsuarioCompliance),
    ])
  }
}

export function loadFormSubclasse(idSubclasse, editAction) {
  return (dispatch) => {
    return axios
      .get(SUBCLASSE_API + `tela/${idSubclasse || ""}`)
      .then(async (result) => {
        let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;
        let idFundo = result.content?.subClasse?.idFundo;

        dispatch([
          idSubclasse
            ? history.push(
                `/${SUBCLASSE_ROUTE_PATH}/${routeAction}/${idSubclasse}`
              )
            : undefined,
          {
            type: LOAD_FORM_SUBCLASSE,
            payload: result.content,
          },
          {
            type: LOAD_FORM_SUBCLASSE_COTAS,
            payload: [],
          },
          change("subclasseForm", "idClasse", ""),
          {
            type: LOAD_CLASSES_FUNDOLIST,
            payload: {classes: [], classesLoaded: false},
          },
          serializeFieldsSubclasseForm(result.content.subClasse),
        ]);

        if (typeof idFundo !== "undefined" && !isNaN(idFundo)) {
          await axios
            .get(`${FUNDO_API}${idFundo}/classes`)
            .then((resClasse) => {
              const { classes, fundo } = resClasse.content;
              let cls = classes.length > 0;
              if (!cls) toastr.error("Não há classes vinculadas a esse fundo");

              dispatch([
                changeParticipantes(dispatch, fundo, false),
                {
                  type: LOAD_CLASSES_FUNDOLIST,
                  payload: {classes, classesLoaded: true},
                },
              ]);
            });
        }
      });
  };
}

export function loadClassesFundoList(idFundo, clean = true) {
  return (dispatch) => {
    dispatch([
      clean ? change("subclasseForm", "idClasse", null) : null,
      {
        type: LOAD_CLASSES_FUNDOLIST,
        payload: {classes: [], classesLoaded: false},
      },
    ]);

    idFundo = idFundo?.key || idFundo;

    if (typeof idFundo == "number") {
      return axios.get(`${FUNDO_API}${idFundo}/classes`).then((result) => {
        const { classes, fundo } = result.content;
        let cls = classes.length > 0;
        if (!cls) toastr.error("Não há classes vinculadas a esse fundo");
        
        dispatch([
          changeParticipantes(dispatch, fundo, clean),
          {
            type: LOAD_CLASSES_FUNDOLIST,
            payload: {classes, classesLoaded: true},
          },
        ]);
      });
    }
  };
}

export function newSubclasseForm() {
  return (dispatch) => {
    return dispatch([
      loadFormSubclasse(),
      history.push(`/${SUBCLASSE_ROUTE_PATH}/${NOVO_ROUTE_PATH}`),
    ]);
  };
}

export function resetPagina() {
  return (dispatch) => {
    return dispatch([
      reset("subclasseForm"),
      { type: RESET },
    ]);
  };
}