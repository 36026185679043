import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  parent: {
    width: "100%",
    marginTop: "0.5%",
    backgroundColor: "transparent",
  },
  mrButton: {
    marginRight: theme.spacing.unit,
  },
  button: {
    color: "#630A37",
    "&:disabled": {
      color: "#D0D0D0",
    },
  },
  buttons: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "1rem !important",
  },
  stepper: {
    backgroundColor: "transparent",
  },
  active: {},
  completed: {},
  customStepLabel: {
    "& [class*='MuiStepLabel-labelContainer']": {
      display: "flex",
      justifyContent: "center",
    },
    "& [class*='MuiTypography-root']": {
      width: "max-content",
      padding: "0 10px",
      borderRadius: "20px",
      // fontSize: "12px",
      // textTransform: "uppercase",
      "&[class*='MuiStepLabel']": {
        backgroundColor: "#B8B8B8",
        color: "#121212c9",
        fontWeight: 500,
      },
      "&[class*='MuiStepLabel-active']": {
        backgroundColor: "#FFC107",
      },
      "&[class*='MuiStepLabel-completed']": {
        backgroundColor: "#83BF74",
        color: "#ffffff",
      },
    },
  },
});

const StepperAssembleia = (props) => {
  const { assembleia, classes } = props;

  const statusValue = assembleia?.status?.key;

  let renderStatus = [
    {
      key: 1,
      value: "Criação Pendente",
      raw: "CriacaoPendente",
      show: false,
      concluidos: [],
    },
    {
      key: 2,
      value: "Assembleia Criada",
      raw: "AssembleiaCriada",
      show: true,
      concluidos: [1],
    },
    {
      key: 3,
      value: "Assembleia Convocada",
      raw: "AssembleiaConvocada",
      show: true,
      concluidos: [1, 2],
    },
    {
      key: 4,
      value: "Assembleia Realizada",
      raw: "AssembleiaRealizada",
      show: true,
      concluidos: [1, 2, 3],
    },
    ,
    {
      key: 5,
      value: "Assembleia Concluída",
      raw: "AssembleiaConcluida",
      show: true,
      concluidos: [1, 2, 3, 4],
    },
  ];

  const steps = renderStatus.filter((sts) => sts.show).map((sts) => sts.value);
  const concluidos = renderStatus
    .filter((sts) => sts.key === statusValue)
    .flatMap((sts) => sts.concluidos);

  function CustomStepIcon({ active, completed, classes }) {
    const cComp = "#83BF74";
    const cActv = "#FFC107";
    const cDflt = "#888888";

    return completed ? (
      <CheckCircleOutline style={{ color: cComp }} />
    ) : active ? (
      <RadioButtonChecked style={{ color: cActv }} />
    ) : (
      <RadioButtonUnchecked style={{ color: cDflt }} />
    );
  }

  return (
    <Stepper
      className="my-3"
      classes={{ root: classes.stepper }}
      activeStep={concluidos.length || 0}
      alternativeLabel
    >
      {steps.map((step, index) => (
        <Step key={index} completed={concluidos.some((et) => et === index + 1)}>
          <StepLabel
            completed={concluidos.some((et) => et === index + 1)}
            className={classes.customStepLabel}
            StepIconComponent={CustomStepIcon}
            classes={{
              completed: `${classes.completed}`,
            }}
            StepIconProps={{
              classes: {
                completed: `${classes.completed}`,
                active: classes.active,
                disabled: classes.disabled,
              },
            }}
          >
            <span>{step}</span>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

// const selector = formValueSelector("AssembleiaForm");

const mapStateToProps = (state) => ({
  //   submitted: selector(state, "submitted") || false,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StepperAssembleia));
