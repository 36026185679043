import React from "react";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import { formValueSelector } from "redux-form";

let ModalNovaAssembleia = (props) => {
  const { close, open, confirm, patrimonioSeparado } = props;

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Realizar Nova Convocação
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-4">
            <p className="text-dark-hard fw-400">
              Você está prestes a{" "}
              <strong className="text-danger fw-500">
                realizar nova convocação de assembleia
              </strong>{" "}
              da operação {patrimonioSeparado?.value}.
            </p>
            <p className="my-2 text-dark-hard fw-400">
              Com isso, será criada nova assembleia no sistema.
            </p>
            <p className="my-2 text-dark-hard fw-400">
              Tem certeza que deseja continuar?
            </p>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Não, Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Sim, Confirmar"}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={() => confirm()}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const selector = formValueSelector("AssembleiaForm");

const mapStateToProps = (state) => ({
  patrimonioSeparado: selector(state, "patrimonioSeparado") || false,
});

export default connect(mapStateToProps, null)(ModalNovaAssembleia);
