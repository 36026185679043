import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import {
    Checkbox,
    Input,
    ListItemText,
    MenuItem,
    Select
} from '@material-ui/core';
import {
    setGrupos
} from '../../../../../../actions/operacao/DashboardAction';

class DashboardFilter extends Component {
    render() {        
        const {
            grupos,
            selectedGrupos,
            setGrupos,
        } = this.props;

        const ITEM_HEIGHT = 64;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 260,
                },
            },
        };

        return (
            <div className='header'>
                <div className='header d-flex mt-3 mb-3'>
                    <h1 className='chart-title mr-3'>Gestão:</h1>
                    <Select
                        id="filter-mutiple-checkbox"
                        multiple
                        displayEmpty
                        value={selectedGrupos}
                        onChange={event => {setGrupos(event.target.value) }}
                        input={<Input />}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em>Todos</em>;
                            } 
                            return selected.map((selected)=>selected.nome).join(', ');
                        }}
                        MenuProps={MenuProps}
                        disabled={grupos.length === 0}
                        style={{ minWidth: 120, maxWidth: 300 }}
                    >
                        {grupos.map((grupo) => (
                            <MenuItem key={grupo.id} value={grupo}>
                                <Checkbox checked={selectedGrupos.indexOf(grupo) > -1} />
                                <ListItemText primary={grupo.nome} />
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>    
        );
    }}

const mapStateToProps = state => ({
    grupos: state.dashboardReducer.grupoList,
    selectedGrupos: state.dashboardReducer.selectedGrupos,
    selectedOperacoes: state.operacaoReducer.selectedOperacoes,
});


const mapDispacthToProps = dispatch => bindActionCreators({
    setGrupos,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(DashboardFilter));


