import {
    DESPESA_FETCHED,
    DESPESA_FORM_TAB ,
    DESPESA_SHOW_DELETE_DIALOG,
    LOAD_FORM_DESPESA,
    SAVE_FORM_DESPESA_ERRORS,
    LOAD_CONTAS_DESTINO,
    LOAD_LANCAMENTOS,
} from '../../actions/operacao/DespesaAction';

const INITIAL_STATE = { 
    despesas: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    isLoading: false,
    messages: null,
    showDespesaDeleteDialog: {
        showDialog: false,
        idDespesa: 0
    },
    changeDespesaFormTab: '',
    despesaFormTypes: {}
}

export default function (state = INITIAL_STATE, action = {}) {
    switch(action.type) {
        case DESPESA_FETCHED:
            return {
                ...state,
                despesas: action.payload.content,
                messages: action.payload.messages,
                isLoading: true
            };        
        case DESPESA_FORM_TAB:
            return {
                ...state,
                changeDespesaFormTab: action.payload
            };
        case DESPESA_SHOW_DELETE_DIALOG:
            return {
                ...state,
                showDespesaDeleteDialog: action.payload
            }
        case LOAD_FORM_DESPESA:
            return {
                ...state,
                despesaFormTypes: action.payload
            }
        case LOAD_CONTAS_DESTINO:
            return {
                ...state,
                despesaFormTypes: {
                    ...state.despesaFormTypes,
                    contasDestinos: action.payload
                }
            }
        case LOAD_LANCAMENTOS:
            return {
                ...state,
                despesaFormTypes: {
                    ...state.despesaFormTypes,
                    lancamentos: action.payload
                }
            }
        case SAVE_FORM_DESPESA_ERRORS:
            return {
              ...state,
              messages: action.payload
            }
        default:
            return state;
    }
}