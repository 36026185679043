import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../common/container/ContainerPage";

import ParticipanteListFilter from "./form/ParticipanteListFilter";
import ParticipanteList from "./list/ParticipanteList";

let ParticipantePage = () => {
  return (
    <ContainerPage>
      <ParticipanteListFilter />
      <ParticipanteList />
    </ContainerPage>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(null, mapDispatchToProps)(ParticipantePage);
