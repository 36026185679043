import React, { useRef } from "react";

const FileButton = React.forwardRef((props, ref) => {
  const fileInputRef = ref || useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (e) => {
    if (!e || !e.target) return;
    const value = props.filter ? props.filter(e.target.files) : e.target.files;
    if (props.input && props.input.onChange) props.input.onChange(value);
    if (props.onChange) props.onChange(e);
  };

  return (
    <div className={`form-group field-floating has-float-label ${props.outerClassName} ${props.readOnly && 'disabled-field'}`}>
      <input
        {...props.input}
        className="d-none"
        id={"txtfield_" + props.inputId}
        type="file"
        accept={props.accept}
        readOnly={props.readOnly}
        onChange={handleChange}
        ref={fileInputRef}
      />
      
      <div 
        className={`custom-file-trigger ${props.className}`} 
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        {props.label || "Select File"}
      </div>

      {(props.meta && props.meta.error && props.meta.touched &&
        <p className="error-msg sp-error text-danger f-12 mb-0">{props.meta.error}</p>)
      }
    </div>
  );
});

export default FileButton;