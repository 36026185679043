import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import { editVotosInvestidorAssembleia, loadVotosInvestidorAssembleia } from "../../../../../actions/assembleia/AssembleiaAction";
import Input from "../../../../common/input/Input";
import { convertCurrencyNumber } from "../../../../../utils/helpers/NumberHelper";
import { DropdownList } from "react-widgets";

let ModalVotosInvestidor = (props) => {
  const {
    close,
    open,
    readOnly,
    statusList,
    investidor,
    idAssembleia,
    interesseList,
    votosInvestidor,
    loadVotosInvestidorAssembleia,
    editVotosInvestidorAssembleia,
  } = props;

  React.useEffect(() => {
    loadVotosInvestidorAssembleia(idAssembleia, investidor?.id)
  }, []);

  const messages = {
    emptyList: "Nenhum registro encontrado",
    emptyFilter: "Nenhum registro encontrado com esse filtro",
  };

  const [votosInvestidorDto, setVotosInvestidorDto] = React.useState([])

  React.useEffect(() => {
    setVotosInvestidorDto(votosInvestidor)
  }, [votosInvestidor])

  const handleChangeConflitoInteresse = (index, newKey) => {
    const updatedVotos = [...votosInvestidorDto];
    updatedVotos[index].conflitoInteresse = newKey;
    setVotosInvestidorDto(updatedVotos);

    if (newKey) handleChangeVoto(index, { key: 3, value: "Abstenção", raw: "Abstencao" })
  };
  
  const handleChangeVoto = (index, newVoto) => {
    const updatedVotos = [...votosInvestidorDto];
    updatedVotos[index].voto = newVoto;
    setVotosInvestidorDto(updatedVotos);
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-start justify-content-between">
            <div className="fw-500 p-color-imp pr-3">
              <p className="m-0 lh-sm fw-500 f-28">Votos</p>
              <p className="m-0 lh-normal fw-400 f-22">
                {investidor.nome} - {investidor.documento}
              </p>
            </div>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-2">
            <div className="col-12 px-0">
              <Input
                inputId="quantidadeDetida"
                name="quantidadeDetida"
                label="Quantidade Detida"
                readOnly={true}
                outerClassName={"disabled-field"}
                allowNegative={false}
                type="tel"
                required
                input={{
                  value: convertCurrencyNumber(investidor.quantidade, false, 0),
                }}
              />
            </div>

            {votosInvestidorDto?.length > 0 && (
              <div className="col-12 px-0">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center border-top-0 fw-700">TÍTULO</th>
                      <th scope="col" className="text-center border-top-0 fw-700" style={{ width: 200 }}>CONFLITO DE INTERESSE</th>
                      <th scope="col" className="text-center border-top-0 fw-700" style={{ width: 200 }}>STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {votosInvestidorDto?.map((item, i) => {
                      let conflitoInteresse = interesseList.find((int) => int.key === item.conflitoInteresse)

                      return (
                        <tr key={i}>
                          <td className="text-center">{item.assunto}</td>
                          <td style={{ width: 200 }} className="text-center">
                            <div>
                              <DropdownList
                                valueField="key"
                                textField="value"
                                data={interesseList}
                                messages={messages}
                                disabled={readOnly}
                                value={conflitoInteresse}
                                onChange={(e) => handleChangeConflitoInteresse(i, e.key)}
                                containerClassName={`dropVotos ${conflitoInteresse?.key ? 'success' : 'danger'}`}
                              />
                            </div>
                          </td>
                          <td style={{ width: 200 }} className="text-center">
                            <div>
                              <DropdownList
                                value={item.voto}
                                valueField="key"
                                textField="value"
                                data={statusList}
                                messages={messages}
                                onChange={(e) => handleChangeVoto(i, e)}
                                disabled={readOnly || conflitoInteresse?.key}
                                containerClassName={`dropVotos ${item.voto.key == 1 ? 'success' : item.voto.key == 2 ? 'danger' : 'neutral'}`}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          {!readOnly && (
            <div className="d-flex flex-row align-content-center justify-content-between">
              <Button
                name={"Cancelar"}
                action={() => close()}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
              />

              {votosInvestidorDto?.length > 0 && (
                <Button
                  name={"Salvar"}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  action={async () => {
                    await editVotosInvestidorAssembleia(idAssembleia, investidor.id, votosInvestidorDto)
                    setVotosInvestidorDto([])
                    close()
                  }}
                />
              )}
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  votosInvestidor: state.assembleiaReducer.votosInvestidor || [],
  statusList: [
    { key: 1, value: "Aprovado", raw: "Aprovado" },
    { key: 2, value: "Reprovado", raw: "Reprovado" },
    { key: 3, value: "Abstenção", raw: "Abstencao" },
  ],
  interesseList: [
    { key: true, value: "Sim" },
    { key: false, value: "Não" },
  ]
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadVotosInvestidorAssembleia,
      editVotosInvestidorAssembleia,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ModalVotosInvestidor);
