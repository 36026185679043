/* eslint react-hooks/exhaustive-deps: 0 */
/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import Button from "../../../../../common/button/Button";
import {
  convertCurrencyNumber,
  convertCurrency,
  newFormatPercent,
} from "../../../../../../utils/helpers/NumberHelper";
import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import { DropdownList } from "react-widgets";
import CheckBox from "@material-ui/icons/CheckBox";
import { Field, formValueSelector } from "redux-form";
import CheckboxInput from "../../../../../common/input/CheckboxInput";
import { aprovarCadastro } from "../../../../../../actions/operacao/PassivoAction";

let ModalPassivoAprovacao = (props) => {
  const { 
    close,
    open,
    aprovacao,
    aprovarCadastro,
    tipoPagamentoPassivo,
  } = props;
  
  const pagamentoValue = tipoPagamentoPassivo?.key;

  const [liberaComboVariacaoPositiva, setLiberaComboVariacaoPositiva] = React.useState(false);
  const [somenteVariacaoPositiva, setSomenteVariacaoPositiva] = React.useState(Boolean(aprovacao?.somenteVariacaoPositiva));

  const [liberaComboResiduoInflacionario, setLiberaComboResiduoInflacionario] = React.useState(false);
  const [possuiResiduoInflacionario, setPossuiResiduoInflacionario] = React.useState(Boolean(aprovacao?.possuiResiduoInflacionario));

  const [envioSemComplementos, setEnvioSemComplementos] = React.useState(false)

  const hasComp = (aprovacao?.complementos || []).some((comp) => comp);

  const moedaSimbolo = aprovacao?.moeda?.simbolo || "R$";

  return (
    <>
      {aprovacao && (
        <Dialog
          maxWidth="xl"
          open={open}
          className="modalDialog"
          onClose={() => close()}
        >
          <div className="modal-dialog modal-xl modal-dialog-scrollable m-0 overflow-hidden">
            <div className="modal-content">
              <div className="modal-header p-4 d-flex flex-row align-items-start justify-content-between">
                <div className="d-flex flex-column">
                  <span className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
                    {aprovacao?.codigoOpea}
                    {aprovacao?.codigoOpea && aprovacao?.apelido && " - "}
                    {aprovacao?.apelido}
                  </span>
                  <span className="f-22 m-0 mt-2 txt-blue-link-dark">
                    {aprovacao?.codigoIF}
                    {aprovacao?.codigoIF && aprovacao?.codigoISIN && " | "}
                    {aprovacao?.codigoISIN}
                  </span>
                </div>
                <Close
                  role="button"
                  className="text-danger"
                  onClick={() => close()}
                />
              </div>

              <div className="modal-body px-4 py-0">
                <div className="text-dark f-16">
                  {/*  */}
                  <div className="p-color f-20 fw-500 my-2">
                    Características
                  </div>
                  <div className="row w-100 mb-3">
                    <div className="col-lg-5">
                      <p className="mb-1">Patrimônio Separado</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.descricaoPatrimonioSeparado || "--"}
                      </p>
                    </div>
                    <div className="col-lg">
                      <p className="mb-1">Emissão</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.emissao || "--"}
                      </p>
                    </div>
                    <div className="col-lg">
                      <p className="mb-1">Série</p>
                      <p className="fw-400 mb-0">{aprovacao?.serie || "--"}</p>
                    </div>
                    <div className="col-lg">
                      <p className="mb-1">Divulgação</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.divulgacao?.value || "--"}
                      </p>
                    </div>
                  </div>
                  <div className="row w-100 mb-3">
                    <div className="col-lg">
                      <p className="mb-1">Lastro</p>
                      <p className="fw-400 mb-0">{aprovacao?.lastro || "--"}</p>
                    </div>
                    <div className="col-lg">
                      <p className="mb-1">Segmento</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.segmento || "--"}
                      </p>
                    </div>
                    <div className="col-lg">
                      <p className="mb-1">Concentração</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.concentracao?.value || "--"}
                      </p>
                    </div>
                    <div className="col-lg">
                      <p className="mb-1">Natureza</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.natureza || "--"}
                      </p>
                    </div>
                    <div className="col-lg">
                      <p className="mb-1">Câmara de Liquidação</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.camaraLiquidacao || "--"}
                      </p>
                    </div>
                  </div>
                  <div className="row w-100 mb-3">
                    <div className="col-lg">
                      <p className="mb-1">Data de Emissão</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.dataEmissao || "--"}
                      </p>
                    </div>
                    <div className="col-lg">
                      <p className="mb-1">Data de Vencimento</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.dataVencimento || "--"}
                      </p>
                    </div>
                    <div className="col-lg">
                      <p className="mb-1">PU Emissão</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.puEmissao ? convertCurrencyNumber(aprovacao?.puEmissao, `${moedaSimbolo} `, 0) : "--"}
                      </p>
                    </div>
                    <div className="col-lg">
                      <p className="mb-1">Quantidade Emitida</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.quantidadeEmitida ? convertCurrencyNumber(aprovacao?.quantidadeEmitida, "", 0) : "--"}
                      </p>
                    </div>
                    <div className="col-lg">
                      <p className="mb-1">Total Emitido</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.totalEmitido ? convertCurrency(aprovacao?.totalEmitido, 2) : "--"}
                      </p>
                    </div>
                  </div>

                  {/*  */}
                  <div className="p-color f-20 fw-500 mb-2 mt-4">
                    Contas da Operação
                  </div>
                  {aprovacao?.contaArrecadadoras?.map((c, i) => {
                    const origemRecurso = (c?.contaArrecadadoraOrigemRecurso || []).map((item) => item?.origemRecurso?.descricao).filter(Boolean).join(", ");

                    return (
                      <div className="row w-100 mb-3" key={`cont-${i}`}>
                        <div className="col-lg-3">
                          <p className="mb-1">Tipo</p>
                          <p className="fw-400 mb-0">
                            {c?.tipoContaArrecadadora?.descricao || "--"}
                          </p>
                        </div>
                        <div className="col-lg-3">
                          <p className="mb-1">Banco</p>
                          <p className="fw-400 mb-0">
                            {c?.banco?.nome || "--"}
                          </p>
                        </div>
                        <div className="col-lg-3">
                          <p className="mb-1">Agência e Conta</p>
                          <p className="fw-400 mb-0">
                            {c?.agencia}
                            {c?.agenciaDigito && `-${c?.agenciaDigito}`}
                            {c?.agencia && c?.contaCorrente && " / "}
                            {c?.contaCorrente}
                            {c?.digito && `-${c?.digito}`}
                          </p>
                        </div>
                        <div className="col-lg-3">
                          <p className="mb-1">Tipo de Rendimento</p>
                          <p className="fw-400 mb-0">
                            {c?.tipoRendimento?.value || "--"}
                          </p>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <p className="mb-1">Origem dos Recursos</p>
                          <p className="fw-400 mb-0">{origemRecurso || "--"}</p>
                        </div>
                      </div>
                    );
                  })}

                  {/*  */}
                  <div className="p-color f-20 fw-500 mb-2 mt-4">
                    Integralização
                  </div>
                  <div className="row w-100 mb-3">
                    <div className="col-lg-3">
                      <p className="mb-1">Data 1ª Integralização</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.dataPrimeiraIntegralizacao || "--"}
                      </p>
                    </div>
                    <div className="col-lg-3">
                      <p className="mb-1">Quantidade Integralizada</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.quantidadeIntegralizada
                          ? convertCurrencyNumber(
                              aprovacao?.quantidadeIntegralizada,
                              "",
                              0
                            )
                          : "--"}
                      </p>
                    </div>
                    <div className="col-lg-3">
                      <p className="mb-1">Total Integralizado</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.totalIntegralizado ? convertCurrencyNumber(aprovacao?.totalIntegralizado, `${moedaSimbolo} `, 2) : "--"}
                      </p>
                    </div>
                    <div className="col-lg-3">
                      <p className="mb-1">% Integralizada</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.porcentagemIntegralizada ? newFormatPercent(aprovacao?.porcentagemIntegralizada, 2) : "--"}
                      </p>
                    </div>
                  </div>

                  {/*  */}
                  <div className="p-color f-20 fw-500 mb-2 mt-4">
                    Precificação
                  </div>
                  <div className="row w-100 mb-3">
                    <div className="col-lg-4">
                      <p className="mb-1">Remuneração</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.remuneracao || "--"}
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <p className="mb-1">Defasagem Taxa/Indexador</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.defasagemTaxaIndexador || "--"}
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <p className="mb-1">Somente Variação Positiva?</p>
                      {pagamentoValue == 3 ? (
                        <div className="d-flex flex-row align-items-center">
                          <Field
                            component={CheckboxInput}
                            inputId="somenteVariacaoPositiva"
                            inputName="somenteVariacaoPositiva"
                            name="somenteVariacaoPositiva"
                            value={liberaComboVariacaoPositiva}
                            checkedIcon={<CheckBox style={{ color: "#83BF74" }} />}
                            onChange={(e) => setLiberaComboVariacaoPositiva(e.target.checked)}
                          />
                          <DropdownList
                            value={somenteVariacaoPositiva}
                            data={[
                              { valueField: true, textField: "Sim" },
                              { valueField: false, textField: "Não" },
                            ]}
                            valueField="valueField"
                            textField="textField"
                            className={`w-100 mb-2 ${!liberaComboVariacaoPositiva ? "inputDisabled" : ''}`}
                            containerClassName="h-38-imp"
                            disabled={!liberaComboVariacaoPositiva}
                            readOnly={!liberaComboVariacaoPositiva}
                            onChange={(e) => setSomenteVariacaoPositiva(e.valueField)}
                          />
                        </div>
                      ) : (
                        <p className="fw-400 mb-0">--</p>
                      )}
                    </div>
                  </div>
                  <div className="row w-100 mb-3">
                    <div className="col-lg-6">
                      <p className="mb-1">Periodicidade Correção</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.periodicidadeCorrecao || "--"}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-1">Meses de Atualização Monetária</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.mesesAtualizacaoMonetaria || "--"}
                      </p>
                    </div>
                  </div>
                  <div className="row w-100 mb-3">
                    <div className="col-lg-3">
                      <p className="mb-1">Frequência Juros</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.frequenciaJuros || "--"}
                      </p>
                    </div>
                    <div className="col-lg-3">
                      <p className="mb-1">Frequência Amortizações</p>
                      <p className="fw-400 mb-0">
                        {aprovacao?.frequenciaAmortizacoes || "--"}
                      </p>
                    </div>
                    <div className="col-lg-3">
                      <p className="mb-1">Possui Resíduo Inflacionário?</p>
                      {pagamentoValue == 3 ? (
                        <div className="d-flex flex-row align-items-center">
                          <Field
                            component={CheckboxInput}
                            inputId="possuiResiduoInflacionario"
                            inputName="possuiResiduoInflacionario"
                            name="possuiResiduoInflacionario"
                            value={liberaComboResiduoInflacionario}
                            checkedIcon={<CheckBox style={{ color: "#83BF74" }} />}
                            onChange={(e) => setLiberaComboResiduoInflacionario(e.target.checked)}
                          />
                          <DropdownList
                            value={possuiResiduoInflacionario}
                            data={[
                              { valueField: true, textField: "Sim" },
                              { valueField: false, textField: "Não" },
                            ]}
                            valueField="valueField"
                            textField="textField"
                            className={`w-100 mb-2 ${!liberaComboResiduoInflacionario ? "inputDisabled" : ''}`}
                            containerClassName="h-38-imp"
                            disabled={!liberaComboResiduoInflacionario}
                            readOnly={!liberaComboResiduoInflacionario}
                            onChange={(e) => setPossuiResiduoInflacionario(e.valueField)}
                          />
                        </div>
                      ) : (
                        <p className="fw-400 mb-0">--</p>
                      )}
                    </div>
                    <div className="col-lg-3">
                      <p className="mb-1">Dia de Aniversário</p>
                      <p className="fw-400 mb-0">
                        {(aprovacao?.diaAniversario && pagamentoValue == 3) ? aprovacao?.diaAniversario : "--"}
                      </p>
                    </div>
                  </div>

                  {/*  */}
                  <div className="p-color f-20 fw-500 mb-2 mt-4">
                    Curva de Pagamentos
                  </div>
                  {aprovacao?.curvaPagamentoPassivoList?.length > 0 ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="border-top-0 text-center" scope="col">
                            Tipo do Evento
                          </th>
                          <th className="border-top-0 text-center" scope="col">
                            Data do Evento
                          </th>
                          <th className="border-top-0 text-center" scope="col">
                            Data de Pagamento
                          </th>
                          <th className="border-top-0 text-center" scope="col">
                            Juros
                          </th>
                          <th className="border-top-0 text-center" scope="col">
                            % Juros
                          </th>
                          <th className="border-top-0 text-center" scope="col">
                            % Amortização
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {aprovacao?.curvaPagamentoPassivoList?.map((comp, i) => {
                          return (
                            <tr key={`tbl-${i}`}>
                              <td className="text-center">
                                {comp?.tipoEvento?.value || "--"}
                              </td>
                              <td className="text-center">
                                {comp?.dataEvento ? getDateFormatedFromString(comp?.dataEvento) : "--"}
                              </td>
                              <td className="text-center">
                                {comp?.dataPagamento ? getDateFormatedFromString(comp?.dataPagamento) : "--"}
                              </td>
                              <td className="text-center">
                                {comp?.pagamentoJuros?.value || "--"}
                              </td>
                              <td className="text-center">
                                {comp?.percentualPagamento ? newFormatPercent(comp?.percentualPagamento) : "--"}
                              </td>
                              <td className="text-center">
                                {comp?.percentualPagamentoAmortizacao ? newFormatPercent(comp?.percentualPagamentoAmortizacao) : "--"}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <span>
                      Não há pagamentos cadastrados para a operação.
                    </span>
                  )}

                  {/*  */}
                  <div className="p-color f-20 fw-500 mb-2 mt-4">
                    Complementos
                  </div>
                  {aprovacao?.complementos?.length > 0 ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="border-top-0 text-center" scope="col">
                            Tipo
                          </th>
                          <th className="border-top-0 text-center" scope="col">
                            Título
                          </th>
                          <th className="border-top-0 text-center" scope="col">
                            Recorrência
                          </th>
                          <th className="border-top-0 text-center" scope="col">
                            Período
                          </th>
                          <th className="border-top-0 text-center" scope="col">
                            Data de Renovação
                          </th>
                          <th className="border-top-0 text-center" scope="col">
                            Monitorar até
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {aprovacao?.complementos?.map((comp, i) => {
                          return (
                            <tr key={`tbl-${i}`}>
                              <td className="text-center">
                                {comp?.tipoComplemento || "--"}
                              </td>
                              <td className="text-center">
                                {comp?.descricaoTitulo || "--"}
                              </td>
                              <td className="text-center">
                                {comp?.recorrencia?.value || "--"}
                              </td>
                              <td className="text-center">
                                {comp?.periodo || "--"}
                              </td>
                              <td className="text-center">
                                {comp?.dataProximaRenovacao || "--"}
                              </td>
                              <td className="text-center">
                                {comp?.monitorarAte
                                  ? getDateFormatedFromString(
                                      comp?.monitorarAte
                                    )
                                  : "--"}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <Field
                      component={CheckboxInput}
                      value={envioSemComplementos}
                      inputId="envioSemComplementos"
                      inputName="envioSemComplementos"
                      name="envioSemComplementos"
                      onChange={(e) => setEnvioSemComplementos(e.target.checked)}
                      checkedIcon={<CheckBox style={{ color: "#83BF74" }} />}
                      label="Certifico que a operação não possui Complementos para serem cadastrados no Gestão de Portfólio."
                    />
                  )}
      
                  <p className="text-center font-weight-bold my-2 p-2">Para avançar no processo de cadastro da operação, favor informar se está de acordo com as informações cadastradas clicando abaixo em “Aprovar Cadastro”.</p>
                </div>
              </div>

              <div className="modal-footer px-3 py-2 d-flex flex-row align-content-center justify-content-between m-0">
                <Button
                  name={"Voltar ao Cadastro"}
                  action={async () => close()}
                  classes="btn-outline-danger bg-white text-danger border border-danger shadow-none border-danger px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                />

                <Button
                  name={"Aprovar Cadastro"}
                  disabled={hasComp ? false : !envioSemComplementos}
                  action={async () => {
                    await aprovarCadastro({
                      possuiResiduoInflacionario,
                      somenteVariacaoPositiva,
                    });
                    close();
                  }}
                  classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                />
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

const selector = formValueSelector("passivoForm");

const mapStateToProps = (state) => ({
  aprovacao: state.passivoReducer.aprovacaoPassivoForm,
  pagamentoPassivo: state.passivoReducer.passivoFormTypes.passivoOperacao.pagamentoPassivo || {},
  tipoPagamentoPassivo: selector(state, "pagamentoPassivo.tipoPagamentoPassivo") || [],
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      aprovarCadastro
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ModalPassivoAprovacao)
);
