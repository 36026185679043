import React from "react";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";

let ModalGeracaoLote = (props) => {
  const { close, open, confirm } = props;

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        keepMounted={false}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row justify-content-between align-items-start">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Geração de relatório mensal em lote
            </p>
            <Close
              role="button"
              onClick={() => close()}
              className="text-danger mt-2"
            />
          </div>

          <p className="mt-3 my-4 f-16 fw-400">
            Deseja gerar o relatório mensal de gestão fiduciária para diversas
            operações?
          </p>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Confirmar"}
              action={() => confirm()}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default connect(null, null)(ModalGeracaoLote);
