import {
  FUNDO_OPTIONS_FETCHED,
  SELECTED_FUNDO_OPTION,
  FUNDO_FETCHED,
  FUNDO_SHOW_LIST,
  FUNDO_FORM_TAB,
  LOAD_FORM_FUNDO,
  LOAD_FORM_FUNDO_SUBCLASSES,
  FUNDO_SHOW_DELETE_DIALOG,
  FUNDO_INSERT_NOME_PASTA_CEDOC,
  CEDOC_ID,
  ULTIMO_VINCULO_CEDOC,
  FUNDO_CLEAN_LIST,
  LOAD_FORM_FUNDO_COTAS,
  LOAD_TAB_FUNDOS_COTAS,
  FUNDO_PAGINATION,
  RESET
} from "../../actions/fundo/FundoAction";

const INITIAL_STATE = {
  fundos: {
    lastPage: 0,
    totalCount: 0,
    hasItems: false,
    pageSize: 0,
    pageIndex: 0,
    items: [],
  },
  fundosOptions: [],
  selectedFundoOptions: [],
  isLoading: false,
  messages: null,
  showFundoList: false,
  showFundoDeleteDialog: {
    showDialog: false,
    idFundo: 0,
  },
  changeFundoFormTab: "",
  fundoFormTypes: {},
  showInserirNomePastaCedocDialog: {
    showDialog: false,
    idAssembleia: 0,
  },
  fundoCotas: [],
  tabFundosCotas: [],
  pagination: 1,
};

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case FUNDO_CLEAN_LIST:
      return {
        ...state,
        fundos: {
          lastPage: 0,
          totalCount: 0,
          hasItems: false,
          pageSize: 0,
          pageIndex: 0,
          items: [],
        },
      };
    case FUNDO_OPTIONS_FETCHED:
      return {
        ...state,
        fundosOptions: action.payload,
      };
    case CEDOC_ID:
      return {
        ...state,
        fundoFormTypes: {
          ...state.fundoFormTypes,
          idCedoc: action.payload,
        },
      };
    case ULTIMO_VINCULO_CEDOC:
      return {
        ...state,
        fundoFormTypes: {
          ...state.fundoFormTypes,
          ultimoVinculo: action.payload,
        },
      };
    case SELECTED_FUNDO_OPTION:
      return {
        ...state,
        selectedFundoOptions: action.payload,
      };
    case FUNDO_FETCHED:
      return {
        ...state,
        fundos: action.payload.content,
        messages: action.payload.messages,
        isLoading: true,
      };
    case FUNDO_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      };
    case LOAD_FORM_FUNDO_SUBCLASSES:
      return {
        ...state,
        fundoSubclasses: action.payload.content,
        messages: action.payload.messages,
        isLoading: true,
      };
    case FUNDO_SHOW_LIST:
      return {
        ...state,
        showFundoList: action.payload,
      };
    case FUNDO_INSERT_NOME_PASTA_CEDOC:
      return {
        ...state,
        showInserirNomePastaCedocDialog: action.payload,
      };
    case LOAD_FORM_FUNDO:
      return {
        ...state,
        fundoFormTypes: action.payload,
      };
    case FUNDO_FORM_TAB:
      return {
        ...state,
        changeFundoFormTab: action.payload,
      };
    case FUNDO_SHOW_DELETE_DIALOG:
      return {
        ...state,
        showFundoDeleteDialog: action.payload,
      };
    case LOAD_FORM_FUNDO_COTAS:
      return {
        ...state,
        fundoCotas: action.payload,
      };
    case LOAD_TAB_FUNDOS_COTAS:
      return {
        ...state,
        tabFundosCotas: action.payload,
      };
    case RESET: {
      return {
        ...INITIAL_STATE,
        selectedFundoOptions: state.selectedFundoOptions
      }
    }
    default:
      return state;
  }
}
