import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Input from "../../../../../common/input/Input";
import { Field } from "redux-form";

const Geral = (props) => {
  const { type, readOnly } = props;

  const CRA = [
    { num: '1.1', label: 'Companhia Emissora', id: 'geral.companhiaEmissora', readOnly: true },
    { num: '1.1.1', label: 'CNPJ da Emissora', id: 'geral.cnpjEmissora', readOnly: true },
    { num: '1.2', label: 'Agente Fiduciário', id: 'geral.agenteFiduciario', readOnly: true },
    { num: '1.3', label: 'Custodiante/Registradora', id: 'geral.custodianteRegistradora', readOnly: true },
    { num: '1.4', label: 'Instituição de Regime Fiduciário', id: 'geral.instituicaoRegimeFiduciario', readOnly: true },
    { num: '1.5', label: 'Revolvência', id: 'geral.revolvencia', readOnly: true },
    { num: '1.6', label: 'Tipo da Oferta', id: 'geral.tipoOferta', readOnly: true },
    { num: '1.7', label: 'Número da Emissão', id: 'geral.numeroEmissao', readOnly: true },
    { num: '1.7.1', label: 'Nome da Emissão', id: 'geral.nomeEmissao', readOnly: true },
    { num: '1.7.2', label: 'Código de Negociação no Mercado', id: 'geral.codigoNegociacaoMercado', readOnly: true },
    { num: '1.7.3', label: 'Código ISIN', id: 'geral.codigoISIN', readOnly: true },
    { num: '1.7.4', label: 'Quantidade de Séries', id: 'geral.quantidadeSeries', readOnly: true },
    { num: '1.7.5', label: 'Data de Emissão', id: 'geral.dataEmissao', readOnly: true },
    { num: '1.7.6', label: 'Data de Vencimento', id: 'geral.dataVencimento', readOnly: true },
    { num: '1.7.7', label: 'Situação', id: 'geral.situacao', readOnly: true },
    { num: '1.8', label: 'Valor Total Integralizado', id: 'geral.valorTotalIntegralizado', readOnly: true },
    { num: '1.9', label: 'Tipo de Lastro', id: 'geral.tipoLastro', readOnly: true },
    { num: '1.9.1', label: 'Detalhamento do Lastro', id: 'geral.detalhamentoLastro', readOnly: true },
    { num: '1.10.1', label: 'Classe', id: 'geral.classe', readOnly: true },
    { num: '1.10.2', label: 'Número da Série', id: 'geral.numeroSerie', readOnly: true },
    { num: '1.10', label: 'Taxa de Juros', id: 'geral.taxaJuros', readOnly: true },
    { num: '1.11.1', label: 'Periodicidade', id: 'geral.periodicidade', readOnly: true },
    { num: '1.11.2', label: 'Mês base de Apuração', id: 'geral.mesBaseApuracao', readOnly: true },
    { num: '1.12', label: 'Sobrecolaterização', id: 'geral.sobrecolateralizacao', readOnly: readOnly },
    { num: '1.13', label: 'Outras Características', id: 'geral.outrasCaracteristicas', readOnly: readOnly },
    { num: '1.13.1', label: 'Cadeia de Produção', id: 'geral.cadeiaProducao', readOnly: true },
    { num: '1.13.2', label: 'Tipo de Segmento', id: 'geral.tipoSegmento', readOnly: true },
    { num: '1.14', label: 'Tipos de Retenção de Risco', id: 'geral.tiposRetencaoRisco', readOnly: readOnly },
    { num: '1.14.1', label: 'Retentor de Risco', id: 'geral.retentorRisco', readOnly: readOnly }
  ];

  const CRI = [
    { num: "1.1", label: "Companhia Emissora", id: "geral.companhiaEmissora", readOnly: true },
    { num: "1.1.1", label: "CNPJ da Emissora", id: "geral.cnpjEmissora", readOnly: true },
    { num: "1.2", label: "Agente Fiduciário", id: "geral.agenteFiduciario", readOnly: true },
    { num: "1.3", label: "Custodiante/Registradora", id: "geral.custodianteRegistradora", readOnly: true },
    { num: "1.4", label: "Instituição de Regime Fiduciário", id: "geral.instituicaoRegimeFiduciario", readOnly: true },
    { num: "1.4.1", label: "Revolvência", id: "geral.revolvencia", readOnly: true },
    { num: "1.5", label: "Tipo da Oferta", id: "geral.tipoOferta", readOnly: true },
    { num: "1.6", label: "Número da Emissão", id: "geral.numeroEmissao", readOnly: true },
    { num: "1.6.1", label: "Nome da Emissão", id: "geral.nomeEmissao", readOnly: true },
    { num: "1.6.2", label: "Código de Negociação no Mercado", id: "geral.codigoNegociacaoMercado", readOnly: true },
    { num: "1.6.3", label: "Código ISIN", id: "geral.codigoISIN", readOnly: true },
    { num: "1.6.4", label: "Quantidade de Séries", id: "geral.quantidadeSeries", readOnly: true },
    { num: "1.6.5", label: "Data de Emissão", id: "geral.dataEmissao", readOnly: true },
    { num: "1.6.6", label: "Data de Vencimento", id: "geral.dataVencimento", readOnly: true },
    { num: "1.6.7", label: "Situação", id: "geral.situacao", readOnly: true },
    { num: "1.7", label: "Valor Total Integralizado", id: "geral.valorTotalIntegralizado", readOnly: true },
    { num: "1.8", label: "Tipo de Lastro", id: "geral.tipoLastro", readOnly: true },
    { num: "1.8.1", label: "Detalhamento do Lastro", id: "geral.detalhamentoLastro", readOnly: true },
    { num: "1.9.1", label: "Classe", id: "geral.classe", readOnly: true },
    { num: "1.9.2", label: "Número da Série", id: "geral.numeroSerie", readOnly: true },
    { num: "1.10", label: "Taxa de Juros", id: "geral.taxaJuros", readOnly: true },
    { num: "1.10.1", label: "Periodicidade", id: "geral.periodicidade", readOnly: true },
    { num: "1.10.2", label: "Mês base de Apuração", id: "geral.mesBaseApuracao", readOnly: true },
    { num: "1.11", label: "Sobrecolaterização", id: "geral.sobrecolateralizacao", readOnly: readOnly },
    { num: "1.12", label: "Outras Características", id: "geral.outrasCaracteristicas", readOnly: readOnly },
    { num: "1.13", label: "Tipos de Retenção de Risco", id: "geral.tiposRetencaoRisco", readOnly: readOnly },
    { num: "1.13.1", label: "Retentor de Risco", id: "geral.retentorRisco", readOnly: readOnly },
  ];
  
  
  const fields = (arr) =>
    arr.map(({ num, label, id, readOnly }) => (
      <div className="row d-flex align-items-center my-lg-2 mb-4 mt-2" key={id}>
        <div className="col-1 mt-lg-2 mt-0 fw-400">{num}</div>
        <div className="col-lg-3 col-11 text-lg-left text-center mt-lg-2 mt-0 fw-400 px-2">{label}</div>
        <div className="col-lg col-12">
          <Field
            component={Input}
            outerClassName="m-0"
            inputId={id}
            name={id}
            readOnly={readOnly}
          />
        </div>
      </div>
    ));

  return (
    <div className="container-fluid px-0">
      <div className="column shadow-custom bg-white rounded-12 col-12 px-4 pt-1 pb-3">
        <p className="h3 page-head__title my-3 mb-0">
          1. Características Gerais
        </p>

        {type && (type === 'CRA' ? fields(CRA) : type === 'CRI' ? fields(CRI) : [])}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispacthToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(Geral);
