import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../../../common/container/ContainerPage";
import ReceitaProdutoForm from "./form/ReceitaProdutoForm";
import { saveOpcao } from "../../../../../../actions/precificacao/OpcaoAction";

const ReceitaProdutoIncludePage = (props) => {
  const { saveOpcao } = props;

  return (
    <ContainerPage>
      <ReceitaProdutoForm
        title={"Novo - Produto (Receita)"}
        readOnly={false}
        floatButtonVisible={true}
        onSubmit={(e) => saveOpcao(e, "receita/produto")}
      />
    </ContainerPage>
  );
};

const mapStateToProps = (state) => ({});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      saveOpcao
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ReceitaProdutoIncludePage)
);
