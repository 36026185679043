import {
  LOAD_FORM_DOMINIO,
  LOAD_FILTER_FORM_DOMINIO,
  DOMINIO_SHOW_DELETE_DIALOG,
  CLEAN_LIST_CONTENT,
} from "../../actions/precificacao/DominioAction";

const INITIAL_STATE = {
  dominio: {
    lastPage: 0,
    totalCount: 0,
    hasItems: false,
    pageSize: 0,
    pageIndex: 0,
    items: [],
  },
  dominioFormTypes: {},
  updateCurrentId: "",
  showDominioDeleteDialog: {
    showDialog: false,
    idDominio: 0,
  },
  messages: null,
  isLoading: false,
};

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case DOMINIO_SHOW_DELETE_DIALOG:
      return {
        ...state,
        showDominioDeleteDialog: action.payload,
      };
    case LOAD_FORM_DOMINIO:
      return {
        ...state,
        dominioForm: action.payload,
      };
    case LOAD_FILTER_FORM_DOMINIO:
      return {
        ...state,
        dominioFormTypes: action.payload,
      };
    case CLEAN_LIST_CONTENT:
      return {
        ...state,
        dominio: {
          lastPage: 0,
          totalCount: 0,
          hasItems: false,
          pageSize: 0,
          pageIndex: 0,
          items: [],
        },
        dominioForm: null,
        dominioFormTypes: null,
      };
    default:
      return state;
  }
}
