import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import { acoesPagamentoAF } from "../../../../../../actions/precificacao/PagamentoAction";

let ModalProsseguirPagamento = (props) => {
  const { pagamento, close, open, acoesPagamentoAF } = props;

  return (
    <>
      <Dialog open={open} onClose={() => close()}>
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Prosseguir com Pagamento
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-3 text-dark f-16 fw-400">
            <p className="mb-0">
              Você está prestes a prosseguir com o pagamento sem envio de novo
              e-mail para validação do Agente Fiduciário. Com isso, ele seguirá
              para a próxima etapa{" "}
              <span className="font-weight-bold">
                sem nova validação do Agente Fiduciário.
              </span>
            </p>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Não, Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Sim, Continuar"}
              action={() => {
                acoesPagamentoAF(
                  {
                    emRevisao: false,
                    id: pagamento?.id,
                    idPagamentoPrecificacao: pagamento?.idPagamentoPrecificacao,
                  },
                  "prosseguir"
                );
                close();
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      acoesPagamentoAF,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ModalProsseguirPagamento);
