import React from 'react';
import "../assets/EventoDiarioBordoCard.css";

let FooterCardDiarioBordo = (props) => {
    
    return (
        <div className="logbook__card-body-footer row mx-0" style={{ background: props.color }}>
            <div className="col p-0 d-flex logbook__card-body-footer-spacers">
                {props.link}            
            </div>
            <div className="col-md-2 p-0 d-flex align-items-center justify-content-center logbook__card-body-footer-spacers">
                {props.view}
            </div>
            <div className="col-md-2 p-0 d-flex align-items-center justify-content-center logbook__card-body-footer-spacers">
                {props.edit}
            </div>
            <div className="col-md-2 p-0 d-flex align-items-center justify-content-center logbook__card-body-footer-spacers">
                {props.delete}
            </div>
            <div className="col-md-2 p-0 d-flex align-items-center justify-content-center">
                {props.filesList}
            </div>
        </div>
    );
}

export default FooterCardDiarioBordo;
