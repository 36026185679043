import axios from "axios";
import { toastr } from "react-redux-toastr";

import history from "../../components/common/routers/history";
import {
  DOMINIO_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  DETALHAR_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";

import { showAllMessages } from "../message/ErrorMessagesAPIAction";
import { showConfirmDialog } from "../common/modal/ConfirmDialogAction";
import { change } from "redux-form";

export const DOMINIO_SHOW_DELETE_DIALOG = "DOMINIO_SHOW_DELETE_DIALOG";
export const LOAD_FILTER_FORM_DOMINIO = "LOAD_FILTER_FORM_DOMINIO";
export const LOAD_FORM_DOMINIO = "LOAD_FORM_DOMINIO";
export const CLEAN_LIST_CONTENT = "CLEAN_LIST_CONTENT";

const DOMINIO_API = process.env.REACT_APP_URL_API;

export function showDominioList() {
  return (dispatch) => {
    return dispatch([cleanResult(), showConfirmDialog(false), history.push(`/${DOMINIO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`), getDominio()]);
  };
}

export function getDominio(page = 1, tamanhoPagina = 20) {
  return (dispatch) => {
    return axios.get(DOMINIO_API + `contamestre?pagina=${page}&tamanhoPagina=${tamanhoPagina}`).then((result) =>
      dispatch({
        type: LOAD_FILTER_FORM_DOMINIO,
        payload: result.content,
      })
    );
  };
}

export function saveDominio(item) {
  return (dispatch) => {
    let { descricao } = item;

    if (descricao) {
      return axios.post(DOMINIO_API + "contamestre", item).then((result) => dispatch([showDominioList(), showAllMessages(result.messages)]));
    } else {
      return toastr.error("Há campos não preenchidos");
    }
  };
}

export function editDominio(item) {
  return (dispatch) => {
    let { id, descricao } = item;

    if (id && descricao) {
      return axios.put(DOMINIO_API + "contamestre", item).then((result) => dispatch([showDominioList(), showAllMessages(result.messages)]));
    } else {
      return toastr.error("Há campos não preenchidos");
    }
  };
}

export function removeDominio(idDominio) {
  return (dispatch) => {
    return axios
      .delete(DOMINIO_API + `contamestre/${idDominio}`)
      .then((result) => dispatch([showAllMessages(result.messages), showDominioDeleteDialog(false, 0), showDominioList()]));
  };
}

export function loadFormDominio(id, editAction) {
  return (dispatch) => {
    return axios.get(DOMINIO_API + `contamestre/detalhe/${id || ""}`).then((result) => {
      let routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;

      dispatch([
        id ? history.push(`/${DOMINIO_ROUTE_PATH}/${routeAction}/${id}`) : undefined,
        id ? change("dominioForm", "dominio", result.content.descricao) : undefined,
        {
          type: LOAD_FORM_DOMINIO,
          payload: result.content,
        },
      ]);
    });
  };
}

export function cleanResult() {
  return { type: CLEAN_LIST_CONTENT };
}

export function showDominioForm() {
  return (dispatch) => {
    return dispatch([cleanResult(), history.push(`/${DOMINIO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`)]);
  };
}

export function showDominioDeleteDialog(showDialog, idDominio) {
  return (dispatch) => {
    return dispatch([
      {
        type: DOMINIO_SHOW_DELETE_DIALOG,
        payload: { showDialog, idDominio },
      },
    ]);
  };
}
