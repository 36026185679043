import React from "react";
import { Route, Switch } from "react-router-dom";

import { HISTORICO_CARTEIRA_ROUTE_PATH } from "../../../common/routers/constantesRoutes";

import Historico from "../pages/Historico";

export default () => (
  <Switch>
    <Route
      exact={true}
      path={`/${HISTORICO_CARTEIRA_ROUTE_PATH}`}
      component={Historico}
    />
  </Switch>
);
