/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";

export default props => (
    <a
        className={props.activeClassName}
        data-toggle="tooltip"
        data-placement="left"
        title={props.title}
        data-original-title={props.title}
        onClick={props.action}
    >
        <i 
            className={props.icon} 
            aria-hidden="true"
        >
            {props.cloudDone ? 
                'cloud_done' :
                ''
            }
        </i>
    </a>
);
