import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';

import {
    loadFormUsuario,
    loadFilterFormUsuario,
    getContasUser,
    cleanResult
} from '../../../../actions/usuario/UsuarioAction';

import UsuarioPerfilForm from './form/UsuarioPerfilForm';

class UsuarioVisualizePage extends Component {

    componentDidMount() {
        this.props.cleanResult();
        let idUsuario = this.props.match.params.idUsuario;
        this.props.loadFormUsuario(idUsuario);
        this.props.loadFilterFormUsuario();
        this.props.getContasUser();
    }

    render() {
        return (
            <UsuarioPerfilForm
                title={"Detalhar - Associação Usuário X Perfil"}
                readOnly={true}
                userReadOnly={true}
                floatButtonVisible={false}
            />
        );
    }
}

const mapDispacthToProps = dispatch => bindActionCreators({ 
    loadFormUsuario,
    loadFilterFormUsuario,
    getContasUser,
    cleanResult
}, dispatch);

export default withRouter(connect(null, mapDispacthToProps)(UsuarioVisualizePage));