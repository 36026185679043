import axios from "axios";
import { change } from "redux-form";

import { showAllMessages } from "./../../actions/message/ErrorMessagesAPIAction";
import { downloadExcelFile } from "./../../utils/helpers/FileHelper";

export const LOAD_FILTER_TIPO = "LOAD_FILTER_TIPO";

const BASE_EXPORT_ANALITIC_REPORT_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API +
    process.env.REACT_APP_PATH_PASSIVO_API + process.env.REACT_APP_PATH_RELATORIO_ANALITICO;

const PAYMENT_ANALITIC_REPORT_API = process.env.REACT_APP_URL_API + 
    process.env.REACT_APP_PATH_PRECIFICACAO_API + process.env.REACT_APP_PATH_PAGAMENTO_TAXA_API 
    + process.env.REACT_APP_PATH_RELATORIO_ANALITICO;

const EXPENSE_CONTRACT_ANALITIC_REPORT_API = process.env.REACT_APP_URL_API + 
    process.env.REACT_APP_PATH_OPERACAO_API + process.env.REACT_APP_PATH_DESPESA_API 
    + process.env.REACT_APP_PATH_RELATORIO_ANALITICO;

const REVENUE_CONTRACT_ANALITIC_REPORT_API = process.env.REACT_APP_URL_API + 
    process.env.REACT_APP_PATH_OPERACAO_API + process.env.REACT_APP_PATH_RECEITA_API 
    + process.env.REACT_APP_PATH_RELATORIO_ANALITICO;
    
export function loadFilterFormRelatorio() {
    return dispatch => {
        return axios.get(BASE_EXPORT_ANALITIC_REPORT_API + 'filtro')
            .then(result =>
                dispatch({
                    type: LOAD_FILTER_TIPO,
                    payload: result.content
                })
            );
    };
}

export function expenseContractReport() {
    return (dispatch) => {
        return axios
            .post(EXPENSE_CONTRACT_ANALITIC_REPORT_API)
            .then(result => {
                const { content } = result;
                const fileName = `${content?.nomeArquivo}.${content?.extensaoArquivo}`;

                dispatch([
                    downloadExcelFile(content?.arquivo, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function revenueContractReport() {
    return (dispatch) => {
        return axios
            .post(REVENUE_CONTRACT_ANALITIC_REPORT_API)
            .then(result => {
                const { content } = result;
                const fileName = `${content?.nomeArquivo}.${content?.extensaoArquivo}`;

                dispatch([
                    downloadExcelFile(content?.arquivo, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}


export function createAnaliticReport(filters) {
    const params = {
        tipoRelatorioAnalitico: filters && filters.typeReport ? filters.typeReport : null
    };

    return (dispatch) => {
        return axios
            .post(BASE_EXPORT_ANALITIC_REPORT_API, { ...params })
            .then(result => {
                const fileName = 'RelatorioAnaliticoExportacaoBase.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function paymentAnalitcRepot(filters) {
    let params = {
        dataInicio: filters.dataInicio,
        dataFim: filters.dataFim
    };
    return (dispatch) => {
        return axios
            .post(PAYMENT_ANALITIC_REPORT_API ,  params )
            .then(result => {
                const fileName = 'RelatorioAnaliticoPagamentos.xlsx';
                dispatch([
                    downloadExcelFile(result.content, fileName),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function selectOperacaoNoForm(option, formName, fieldName) {
    return (dispatch) => {
        return dispatch(
            change(formName, fieldName, option)
        )
    }
}

