import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  editSubclasse,
  loadFormSubclasse,
} from "../../../../../actions/subclasse/SubclasseAction";
import SubclasseForm from "./SubclasseForm";

class SubclasseFormUpdate extends Component {
  componentDidMount() {
    let idSubclasse = this.props.match.params.idSubclasse;
    this.props.loadFormSubclasse(idSubclasse, true);
  }

  render() {
    const { readOnly, editSubclasse } = this.props;

    return (
      <SubclasseForm
        title={"Editar - Subclasse"}
        onSubmit={editSubclasse}
        readOnly={readOnly}
        floatButtonVisible={true}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editSubclasse,
      loadFormSubclasse,
    },
    dispatch
  );

export default withRouter(
  connect(null, mapDispatchToProps)(SubclasseFormUpdate)
);
