import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../../common/container/ContainerPage";
import ErroListFilter from "./form/ErroListFilter";
import ErroList from "./list/ErroList";
import { LOG_TABS } from "../LogTabs";

class ErroPage extends Component {
  render() {
    return (
      <ContainerPage tabs={LOG_TABS} className="px-2">
        <ErroListFilter />
        <ErroList />
      </ContainerPage>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(null, mapDispatchToProps)(ErroPage);
