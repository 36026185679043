import {
    SHOW_PRIMEIRO_ACESSO,
    SET_AUTHENTICATION,
    SET_ACCESS_PRIMARY,
    LOGOUT,
} from "../../actions/login/LoginAction";
import { customFormatCnpjCpf } from "../../utils/helpers/StringHelper";


const INITIAL_STATE = {
    authenticate: false,
    accessPrimary: false,
    invalidToken: true,
    login: {},
};

export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case SHOW_PRIMEIRO_ACESSO:
            return {
                ...state,
                accessPrimary: action.payload
            }
        case SET_AUTHENTICATION:
            localStorage.setItem('userLogged', JSON.stringify(action.payload.content))
            let data = action.payload.content;

            data.cpf = customFormatCnpjCpf(data.cpf);

            return {
                ...state,
                login: data,
                authenticate: action.payload.content.autenticado,
                accessPrimary: action.payload.content.primeiroAcesso,
                invalidToken: false,
            };
        case SET_ACCESS_PRIMARY:
            return {
                ...state,
                login: action.payload.content,
                authenticate: action.payload.content.autenticado,
                accessPrimary: action.payload.content.primeiroAcesso,
                invalidToken: false
            };
        case LOGOUT:
            localStorage.setItem('userLogged', null)
            /*Descomentar caso queira que as mensagem seja removidas após logOff*/
            //localStorage.setItem('notifications', null)
            localStorage.setItem(process.env.REACT_APP_URL_TOKEN, null)
            localStorage.setItem('userAdAzure', null)
            localStorage.removeItem('currEnv')
            return {
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
}
