/* eslint eqeqeq: 0 */

import * as Msal from "msal";
import history from "../../components/common/routers/history";

const AD_AZURE_CLIENT_APPLICATION_ID = process.env.REACT_APP_AD_AZURE_CLIENT_APPLICATION_ID;
const AD_AZURE_TENANT = process.env.REACT_APP_AD_AZURE_TENANT;

let msalConfig = {
    auth: {
        clientId: AD_AZURE_CLIENT_APPLICATION_ID,
        authority: `https://login.microsoftonline.com/${AD_AZURE_TENANT}`
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

let graphConfig = { 
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    getMemberObjectsEndpoint: "https://graph.microsoft.com/v1.0/users/[OBJECT_ID_USER]/getMemberObjects",
};

let requestObj = {
    scopes: ["user.read"]
};

let myMSALObj = new Msal
    .UserAgentApplication(msalConfig);
    
myMSALObj
    .handleRedirectCallback(authRedirectCallBack);

myMSALObj.acquireTokenSilent(requestObj).then(function (tokenResponse) {
    getGroupUser(tokenResponse.accessToken);
}).catch(function (error) {
    myMSALObj.acquireTokenPopup(requestObj).then(function (tokenResponse) {
        getGroupUser(tokenResponse.accessToken);
    }).catch(function (error) { 
        //...
    });
});

export async function signIn() {
    history.replace('/');
    localStorage.setItem('userAdAzure', null);
    localStorage.setItem('idsGroup', undefined);
    localStorage.setItem("reaload_ad", "1");

    try {
        const loginResponse = await myMSALObj.loginPopup(requestObj);
        authorizationSuccess();
        return loginResponse;
    } catch (error) {
        console.log(error);
    }
}

export function signOut() {
    myMSALObj.logout();
}

function callMSGraph(theUrl, accessToken, callback, method, params) {
    let xmlHttp = new XMLHttpRequest();

    xmlHttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200){
            callback(JSON.parse(this.responseText));
        } else {
            callback(JSON.parse(this.responseText));
        }
    }

    xmlHttp.open(method, theUrl, true);
    xmlHttp.setRequestHeader('Authorization', 'Bearer ' + accessToken);

    if(params != null) {
        xmlHttp.setRequestHeader("Content-Type", "application/json");
        xmlHttp.setRequestHeader("Content-length", params.length);
    }

    xmlHttp.send(params);
}

function graphAPICallback(data) {
    document.getElementById("json").innerHTML = JSON.stringify(data, null, 2);
}

function authorizationSuccess() {
    localStorage.setItem('userAdAzure', JSON.stringify({
        accountIdentifier: myMSALObj.getAccount().accountIdentifier,
        userName: myMSALObj.getAccount().name,
        email: myMSALObj.getAccount().userName,
        idsGroup: [],
        token: ''
    }));

    history.go('/');
}

function authRedirectCallBack(error, response) {
    if (error) {
        console.log(error);
    } else {
        if (response.tokenType === "access_token") {
            callMSGraph(graphConfig.graphMeEndpoint, response.accessToken, graphAPICallback, "GET", null);
        } else {
            console.log("token type is:" + response.tokenType);
        }
    }
}

function getGroupUser(token) {
    let urlGetGroupsUser = graphConfig.getMemberObjectsEndpoint.replace("[OBJECT_ID_USER]", myMSALObj.getAccount().accountIdentifier);
    callMSGraph(urlGetGroupsUser, token, callbackGetGroupUser, "POST", JSON.stringify({"securityEnabledOnly" : false}));
}

function callbackGetGroupUser(data) {
    let result = ( data != null && data != "null" && data != "" && data != undefined && data.value) ? data.value : undefined;
    localStorage.setItem("idsGroup", result);

    if(localStorage.getItem('reaload_ad') == "1") {
        localStorage.setItem('reaload_ad', "0");

        history.go('/');
    }
}
