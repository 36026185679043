/* eslint eqeqeq: 0 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  LISTA_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  REMESSA_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

import RemessaPage from "../pages/RemessaPage";
import RemessaIncludePage from "../pages/RemessaIncludePage";
import RemessaUpdatePage from "../pages/RemessaUpdatePage";

export default () => (
  <Switch>
    <Route exact={true} path={`/${REMESSA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`} component={RemessaPage}/>
    <Route exact={true} path={`/${REMESSA_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={RemessaIncludePage} />
    <Route exact={true} path={`/${REMESSA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idRemessa`} component={RemessaUpdatePage} />
  </Switch>
);
