/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formValueSelector } from "redux-form";

import { withStyles } from "@material-ui/core/styles";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import {
  editarRegraDeMonitoramento,
  removeRegraDeMonitoramento,
  showDeleteDialog,
} from "../../../../../../../actions/operacao/ComplementoAction";

import {} from "../../../../../../../actions/operacao/ComplementoAction";

import If from "./../../../../../../common/If/If";
import { TablePagination } from "@material-ui/core";
import ConfirmDialog from "../../../../../../common/modal/ConfirmDialog";
import Table from "../../../../../../common/table/Table";
import TableHeader from "../../../../../../common/table/TableHeader";
import TableContent from "../../../../../../common/table/TableContent";
import { getLabelDisplayedRows } from "../../../../../../../utils/helpers/OthersHelpers";

const styles = (theme) => ({
  root: {
    display: "block",
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  icon: {
    fontSize: "1.3em",
    cursor: "pointer",
  },
  tablePagination: {
    fontSize: "12pt",
  },
});

class RegrasMonitoramentosList extends Component {
  state = {
    page: 0,
    rowsPerPage: 5,
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  getIndexArray(id) {
    let index = this.props.dataList.findIndex((item) => item.id === id);

    return index;
  }

  render() {
    const { page, rowsPerPage } = this.state;

    const {
      classes,
      dataList,
      readOnly,
      editarRegraDeMonitoramento,
      removeRegraDeMonitoramento,
      showDeleteDialog,
      showModal,
    } = this.props;

    return (
      <div className={`table-responsive ${classes.root} mb-4`}>
        <span className="page-head__title h3 mb-3">
          Regras de Monitoramento
        </span>
        <Table
          withoutHeader
          classes="col-12 my-3 bg-white shadow-none border-0"
        >
          <TableHeader>
            <tr>
              <th className="text-center fw-700">Regra</th>
              <th className="text-center fw-700">Atividade</th>
              <th className="text-center fw-700 text-nowrap">Valor de Ref.</th>
              <th className="text-center fw-700">Condição</th>
              <th className="text-center fw-700">Descrição</th>
              <If test={this.props.opcaoMonitorar === "Sim" && !readOnly}>
                <th className="col-2"></th>
              </If>
            </tr>
          </TableHeader>
          <TableContent>
            {dataList.length > 0 ? (
              dataList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <tr key={`reg-${index}`}>
                      <td className="text-center fw-400">{item.regra}</td>
                      <td className="text-center fw-400">
                        {item.regraAtividade
                          ? item.regraAtividade.descricao
                          : null}
                      </td>
                      <td className="text-center fw-400">
                        {item.valorReferencia}
                      </td>
                      <td className="text-center fw-400">
                        {item.regraCondicao
                          ? item.regraCondicao.descricao
                          : null}
                      </td>
                      <td className="text-center fw-400">{item.descricao}</td>
                      <If
                        test={this.props.opcaoMonitorar === "Sim" && !readOnly}
                      >
                        <td className="text-center fw-400">
                          <div className="d-flex flex-row justify-content-center">
                            <a
                              className="col-lg-2 table-action-button p-color-imp f-22 mx-2"
                              onClick={() =>
                                showDeleteDialog(
                                  true,
                                  this.getIndexArray(item.id)
                                )
                              }
                            >
                              <DeleteIcon className={classes.icon} />
                            </a>
                            <a
                              className="col-lg-2 table-action-button p-color-imp f-22 mx-2"
                              onClick={() =>
                                editarRegraDeMonitoramento(
                                  this.getIndexArray(item.id)
                                )
                              }
                            >
                              <EditIcon className={classes.icon} />
                            </a>
                          </div>
                        </td>
                      </If>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td align="center" colSpan={12}>
                  Não existem regras
                </td>
              </tr>
            )}
          </TableContent>
        </Table>
        <TablePagination
          className={classes.tablePagination}
          labelDisplayedRows={getLabelDisplayedRows}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataList.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Linhas por página"
          page={page}
          backIconButtonProps={{
            "aria-label": "Página anterior",
          }}
          nextIconButtonProps={{
            "aria-label": "Próxima página",
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <ConfirmDialog
          title="Aviso"
          contentText="Você realmente deseja excluir a regra de monitoramento selecionada ?"
          textButtonCancel="CANCELAR"
          textButtonConfirm="CONFIRMAR"
          showDialog={showModal.showDialog}
          onClose={() => showDeleteDialog(false, null)}
          onConfirm={() => removeRegraDeMonitoramento(showModal.idComplemento)}
          type="warning"
          maxWidth="md"
        />
      </div>
    );
  }
}

const selector = formValueSelector("complementoForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editarRegraDeMonitoramento,
      removeRegraDeMonitoramento,
      showDeleteDialog,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  showModal: state.complementoReducer.showComplementoDeleteDialog,
  opcaoMonitorar: selector(state, "complementoMonitoramento.monitorar"),
});

RegrasMonitoramentosList = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegrasMonitoramentosList);

export default withStyles(styles)(RegrasMonitoramentosList);
