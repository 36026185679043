export const LOADING_CHANGE = "LOADING_CHANGE";
export const LOADING_MESSAGE = "LOADING_MESSAGE";

export function changeLoading(value) {
  return [
    {
      type: LOADING_CHANGE,
      payload: value,
    }
  ];
}

export function changeMessage(message) {
  return [
    {
      type: LOADING_MESSAGE,
      payload: message,
    },
  ];
}
