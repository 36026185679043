import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Chart, Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import moment from "moment";

import { annotationLineColor, backgroundColors, borderColors } from "./chartColors";

class IndicesDeInflacaoChart extends Component {
  componentDidMount() {
    Chart.register(annotationPlugin);
  }

  render() {
    const { indicesDeInflacaoChartData } = this.props;

    // Criação de um objeto inicial agrupando por categorias
    let minDate = "999999";
    let maxDate = "000000";
    const initialGroupedData = {};
    indicesDeInflacaoChartData.map((item) => {
      initialGroupedData[item.descricaoTaxa] = {};
      if (minDate > item.descricaoAnoMesTaxa) minDate = item.descricaoAnoMesTaxa;
      if (maxDate < item.descricaoAnoMesTaxa) maxDate = item.descricaoAnoMesTaxa;
    });

    // Injeta dados nulos quando algum dado está faltando em algum período
    while (minDate <= maxDate) {
      Object.keys(initialGroupedData).forEach((key) => {
        initialGroupedData[key][minDate] = null;
      });

      minDate = moment(minDate, "YYYYMM").add(1, "months").format("YYYYMM");
    }

    // Agrupamento dos dados de acordo com as categorias
    const groupedData = indicesDeInflacaoChartData.reduce((accumulator, item) => {
      accumulator[item.descricaoTaxa][item.descricaoAnoMesTaxa] = item.valorTaxa;
      return accumulator;
    }, initialGroupedData);

    // Ordenação dos dados por data
    const groupedDataSorted = {};
    Object.keys(groupedData).forEach((key) => {
      const orderedObj = Object.keys(groupedData[key])
        .sort()
        .reduce((acc, date) => {
          acc[date] = groupedData[key][date];
          return acc;
        }, {});

      groupedDataSorted[key] = orderedObj;
    });

    const unformattedLabels = Object.keys(groupedDataSorted).length > 0 ? Object.keys(groupedDataSorted[Object.keys(groupedDataSorted)[0]]) : [];

    const labels = unformattedLabels.map((dateStr) => moment(dateStr, "YYYYMM").format("MMM/YY"));

    let datasetCount = 0;
    const datasets = Object.keys(groupedDataSorted).map((key) => {
      datasetCount += 1;
      return {
        label: key,
        data: Object.values(groupedDataSorted[key]),
        fill: false,
        backgroundColor: backgroundColors[datasetCount - 1],
        borderColor: borderColors[datasetCount - 1],
      };
    });

    const data = {
      labels: labels,
      datasets: datasets,
    };

    const options = {
      aspectRatio: 1.8,
      devicePixelRatio: 4,
      scales: {
        y: {
          beginAtZero: true,
          grace: "2%",
          ticks: {
            precision: 2,
            callback: function (value, index, values) {
              return value.toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%";
            },
          },
        },
      },
      interaction: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        annotation: {
          annotations: {
            line1: {
              type: "line",
              yMin: 0,
              yMax: 0,
              borderColor: annotationLineColor,
              borderWidth: 2,
            },
          },
        },
        tooltip: {
          position: "nearest",
          callbacks: {
            label: function (context) {
              var label = context.dataset.label || "";

              if (label) {
                label += ": ";
              }
              if (context.parsed.y !== null) {
                label +=
                  new Intl.NumberFormat("pt-BR", { style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3 }).format(context.parsed.y) +
                  "%";
              }
              return label;
            },
          },
        },
        legend: {
          labels: {
            boxWidth: 12,
          },
        },
      },
    };

    const LineChart = () => (
      <>
        <div className="header">
          <h1 className="chart-title fw-400">Índices</h1>
        </div>
        <Line type="line" data={data} options={options} />
      </>
    );

    return (
      <div className="shadow-custom rounded-12 p-3 bg-white">
        <div className="internal-aspect-ratio">
          <LineChart />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  indicesDeInflacaoChartData: state.dashboardReducer.indicesDeInflacaoChartData,
});

export default withRouter(connect(mapStateToProps)(IndicesDeInflacaoChart));
