import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import { loadFormDespesa } from "../../../../../actions/operacao/DespesaAction";

import { OPERACAO_TABS } from "./../../../operacao/pages/OperacaoTabs";
import DespesaForm from "./../../../operacao/pages/despesa/form/DespesaForm";
import ContainerPage from "../../../../common/container/ContainerPage";

class DespesaVisualizePage extends Component {
  componentDidMount() {
    let idDespesa = this.props.match.params.idDespesa;
    this.props.loadFormDespesa(idDespesa);
  }

  render() {
    const { handleSubmit, operacoes } = this.props;
    const idsOperacoes = operacoes.map((o) => o.key);

    return (
      <ContainerPage tabs={OPERACAO_TABS} paramsRoute={idsOperacoes}>
        <DespesaForm
          title={"Detalhar - Despesa"}
          handleSubmit={handleSubmit}
          readOnly={true}
          floatButtonVisible={false}
        />
      </ContainerPage>
    );
  }
}

const mapStateToProps = (state) => ({
  tab: state.tab,
  operacoes: state.operacaoReducer.selectedOperacoes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormDespesa,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(DespesaVisualizePage)
);
