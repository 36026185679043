/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../../common/container/ContainerPage";
import logo from "../../../../../assets/img/LOGO_OPEA_POS_RGB.svg";
import { DropdownList } from "react-widgets";
import SingleFileInput from "../../../../common/input/SingleFileInput";
import Button from "../../../../common/button/Button";

import Message from "../../../../common/messages/Message";
import ModalExternoEnviarEmailAF from "./modals/ModalExternoEnviarEmailAF";
import {
  buscaExternoPagamentoAF,
  verificaLinkExternoPagamentoAF,
} from "../../../../../actions/precificacao/PagamentoAction";
import LoadingScreen from "../../../../common/loading/LoadingScreen";
import {
  getDateFormatedFromString,
  getDateTimeStringNormalized,
} from "../../../../../utils/helpers/DateTimeHelper";
import {
  convertCurrencyNumberNew,
  formatDecimal,
} from "../../../../../utils/helpers/NumberHelper";
import { downloadExcelFile } from "../../../../../utils/helpers/FileHelper";

let AgenteFiduciarioPageExterna = (props) => {
  const {
    buscaExternoPagamentoAF,
    verificaLinkExternoPagamentoAF,
    pagamento,
    linkExterno,
  } = props;

  const [arquivoAnexo, setArquivoAnexo] = React.useState("");
  const [motivo, setMotivo] = React.useState("");
  const [descricaoMotivo, setDescricaoMotivo] = React.useState("");
  const [descricao, setDescricao] = React.useState("");

  const [modalEmail, setModalEmail] = React.useState(false);

  const enviado = pagamento?.emailAprovacao && pagamento?.dataRevisao && !pagamento?.aprovadoEm;
  const aprovado = pagamento?.emailAprovacao && ((pagamento?.aprovadoEm && !pagamento?.dataRevisao) || (pagamento?.aprovadoEm && pagamento?.dataRevisao));
  const exibeBotoes =
    !pagamento?.emailAprovacao &&
    !pagamento?.dataRevisao &&
    !pagamento?.aprovadoEm;

  const moeda = pagamento?.moeda?.simbolo;

  React.useEffect(() => {
    localStorage.clear();
    document.body.style.backgroundColor = "#FFFCFB";
    let idPagamentoPrecificacao = window.location.pathname.split("/").pop();
    verificaLinkExternoPagamentoAF(idPagamentoPrecificacao);
  }, []);

  React.useEffect(() => {
    if (linkExterno !== null && !linkExterno) {
      let idPagamentoPrecificacao = window.location.pathname.split("/").pop();
      buscaExternoPagamentoAF(idPagamentoPrecificacao);
    }
  }, [linkExterno]);

  return (
    <>
      <ContainerPage>
        <div className="w-100 p-lg-4 p-3 fw-400 f-16">
          <div className="col-12 pb-4 pt-2 mb-lg-4 mb-3 justify-content-center d-flex">
            <img src={logo} title="Logo Opea" alt="Logo Opea" height="50px" />
          </div>

          {pagamento && (
            <>
              <div className="card-body shadow-custom bg-white rounded-12 col-12">
                <div className="d-flex flex-row justify-content-between align-items-start">
                  <div className="d-flex flex-row align-items-center">
                    <h4 className="page-head__title m-0 f-36 font-weight-bold lh-normal mb-2">
                      {pagamento.codigoOpea} - {pagamento.codigoIf}
                    </h4>
                  </div>
                </div>
                <div className="row px-0">
                  <div className="col-lg col-sm-4 col-12">
                    <p className="control-label mx-0 m-0 txt-grey">
                      Forma de Cálculo
                    </p>
                    <p>{pagamento.formaCalculo}</p>
                  </div>
                  <div className="col-lg col-sm-4 col-12">
                    <p className="control-label mx-0 m-0 txt-grey">
                      Câmara Liquidação
                    </p>
                    <p>{pagamento.camaraLiquidacao}</p>
                  </div>
                  <div className="col-lg col-sm-4 col-12">
                    <p className="control-label mx-0 m-0 txt-grey">
                      Data Evento/Aniversário
                    </p>
                    <p>{getDateFormatedFromString(pagamento.dataEvento)}</p>
                  </div>
                  <div className="col-lg col-sm-4 col-12">
                    <p className="control-label mx-0 m-0 txt-grey">
                      Data Pagamento
                    </p>
                    <p>{getDateFormatedFromString(pagamento.dataPagamento)}</p>
                  </div>
                  <div className="col-lg col-sm-4 col-12">
                    <p className="control-label mx-0 m-0 txt-grey">
                      Responsável
                    </p>
                    <p>{pagamento.responsavelGestao}</p>
                  </div>
                </div>
                <div className="row px-0">
                  <div className="col-lg col-sm-4 col-12">
                    <p className="control-label mx-0 m-0 txt-grey">
                      Valor Nominal Inicial
                    </p>
                    <p>
                      {convertCurrencyNumberNew(
                        pagamento.valorNominalInicial,
                        moeda
                      )}
                    </p>
                  </div>
                  <div className="col-lg col-sm-4 col-12">
                    <p className="control-label mx-0 m-0 txt-grey">
                      Valor Nominal Atualizado
                    </p>
                    <p>
                      {convertCurrencyNumberNew(
                        pagamento.valorNominalAtualizado,
                        moeda
                      )}
                    </p>
                  </div>
                  <div className="col-lg col-sm-4 col-12">
                    <p className="control-label mx-0 m-0 txt-grey">
                      Juros Remuneração
                    </p>
                    <p>
                      {convertCurrencyNumberNew(
                        pagamento.jurosRemuneracao,
                        moeda
                      )}
                    </p>
                  </div>
                  <div className="col-lg col-sm-4 col-12">
                    <p className="control-label mx-0 m-0 txt-grey">
                      Correção Monetária
                    </p>
                    <p>
                      {convertCurrencyNumberNew(
                        pagamento.correcaoMonetaria,
                        moeda
                      )}
                    </p>
                  </div>
                  <div className="col-lg col-sm-4 col-12">
                    <p className="control-label mx-0 m-0 txt-grey">
                      Quantidade Integralizada
                    </p>
                    <p>{formatDecimal(pagamento.quantidadeIntegralizada, 0)}</p>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="d-flex flex-lg-row flex-column mt-4">
                <div
                  className={`card-body shadow-custom bg-white rounded-12 col-lg-6 mr-1-5 position-relative ${
                    exibeBotoes && "pb-card-af"
                  }`}
                >
                  <div className="d-flex flex-row justify-content-between align-items-start">
                    <div className="d-flex flex-row align-items-center">
                      <h4 className="page-head__title m-0 f-28 font-weight-bold">
                        Aprovar Valores
                      </h4>
                    </div>
                  </div>

                  <p className="my-3">
                    Caso concorde com nosso cálculo basta clicar no botão abaixo
                    para aprovar e avançarmos com o pagamento.
                  </p>

                  <div className="row mx-0 w-100">
                    <p className="p-color-imp fw-500 mb-3">
                      Cálculo Precificação
                    </p>
                    <div className="col-12 p-0">
                      <div className="d-flex flex-row justify-content-between mb-3">
                        <span>VNA</span>
                        <span>
                          {convertCurrencyNumberNew(
                            pagamento.valorNominalAtualizado,
                            moeda
                          )}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between mb-3">
                        <span>Amortização</span>
                        <span>
                          {convertCurrencyNumberNew(
                            pagamento.amortizacaoValorAPagar,
                            moeda
                          )}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between pb-3 border-bottom">
                        <span>Incorporação de Juros</span>
                        <span>
                          {convertCurrencyNumberNew(
                            pagamento.jurosIncorporacao,
                            moeda
                          )}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between mb-3 pt-3 fw-500 border-top">
                        <span>Saldo Residual</span>
                        <span>
                          {convertCurrencyNumberNew(
                            pagamento.saldoResidual,
                            moeda
                          )}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between pb-3 border-bottom">
                        <span>Amex</span>
                        <span>
                          {convertCurrencyNumberNew(pagamento.amex, moeda)}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between mb-3 pt-3 fw-500 border-top">
                        <span>Saldo Residual Extra</span>
                        <span>
                          {convertCurrencyNumberNew(
                            pagamento.saldoResidualExtra,
                            moeda
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row mx-0 w-100">
                    <p className="p-color-imp fw-500 my-3">
                      Cálculo Valor Pagamento
                    </p>
                    <div className="col-12 p-0">
                      <div className="d-flex flex-row justify-content-between mb-3">
                        <span>Juros</span>
                        <span>
                          {convertCurrencyNumberNew(pagamento.juros, moeda)}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between mb-3">
                        <span>Amortização</span>
                        <span>
                          {convertCurrencyNumberNew(
                            pagamento.amortizacaoValorAPagar,
                            moeda
                          )}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between mb-3">
                        <span>Amex</span>
                        <span>
                          {convertCurrencyNumberNew(pagamento.amex, moeda)}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between pb-3 border-bottom">
                        <span>Evento Genérico</span>
                        <span>
                          {convertCurrencyNumberNew(
                            pagamento.eventoGenerico,
                            moeda
                          )}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between mb-3 pt-3 fw-500 border-top">
                        <span>Pagamento Unitário</span>
                        <span>
                          {convertCurrencyNumberNew(
                            pagamento.pagamentoTotalPmtUnitario,
                            moeda
                          )}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between pb-3 border-bottom">
                        <span>Quantidade Integralizada</span>
                        <span>
                          {formatDecimal(pagamento.quantidadeIntegralizada, 0)}
                        </span>
                      </div>
                      <div className="p-color-imp d-flex flex-row justify-content-between mb-3 pt-3 fw-500 border-top">
                        <span>Valor Pagamento Total</span>
                        <span>
                          {convertCurrencyNumberNew(
                            pagamento.pagamentoTotalPmt,
                            moeda,
                            2
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  {exibeBotoes && (
                    <Button
                      name={"Aprovar Estes Valores"}
                      action={() => setModalEmail("Aprovar")}
                      activeClassName="position-absolute fixed-bottom d-flex justify-content-center mb-3"
                      classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                    />
                  )}
                </div>
                {/*  */}
                <div
                  className={`card-body shadow-custom bg-white rounded-12 col-lg-6 ml-1-5 mt-lg-0 mt-4 position-relative ${
                    exibeBotoes && "pb-card-af"
                  }`}
                >
                  <div className="d-flex flex-row justify-content-between align-items-start">
                    <div className="d-flex flex-row align-items-center">
                      <h4 className="page-head__title m-0 f-28 font-weight-bold">
                        {enviado
                          ? "Valores Enviados"
                          : aprovado
                          ? "Valores Aprovados"
                          : "Enviar Valores"}
                      </h4>
                    </div>
                  </div>

                  <p className="my-3">
                    {enviado
                      ? `Valores enviados para revisão da OPEA em ${getDateTimeStringNormalized(
                          pagamento.dataRevisao
                        )} pelo usuário com e-mail ${
                          pagamento.emailAprovacao
                        }. Muito obrigado pela sua resposta.`
                      : aprovado
                      ? `Valores aprovados em: ${getDateTimeStringNormalized(
                          pagamento.aprovadoEm
                        )} pelo usuário com e-mail ${
                          pagamento.emailAprovacao
                        }. Muito obrigado pela sua resposta.`
                      : "Caso discorde do nosso cálculo envie seus valores para conferência"}
                  </p>

                  {exibeBotoes && (
                    <div className="w-100">
                      <div className="mb-4">
                        <div className={`mb-0 px-0 col-12`}>
                          <span className="f-12 txt-grey">
                            Motivo da discordância
                            <span className="text-warning font-weight-bold ml-1">
                              *
                            </span>
                          </span>
                          <DropdownList
                            value={motivo}
                            valueField="key"
                            textField="value"
                            containerClassName="h-38-imp"
                            data={pagamento.tipoMotivoEmum}
                            onChange={(e) => {
                              setMotivo(e.key);
                              if (e.key !== 1) setDescricaoMotivo("");
                            }}
                          />
                        </div>
                      </div>
                      {motivo == 1 && (
                        <div className="mb-4">
                          <div
                            className={`form-group field-floating has-float-label mb-0 px-0 col-12`}
                          >
                            <input
                              type="text"
                              name="descricaoMotivo"
                              value={descricaoMotivo}
                              className="form-control w-100"
                              onChange={(e) =>
                                setDescricaoMotivo(e.target.value)
                              }
                            />
                            <label
                              htmlFor="descricaoMotivo"
                              className="control-label"
                            >
                              Qual Motivo?
                              <span className="text-warning font-weight-bold ml-1">
                                *
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                      <div className="mb-2">
                        <SingleFileInput
                          value={arquivoAnexo}
                          label="Anexar um arquivo"
                          outerClassName="mb-0 px-0 col-12"
                          removeFile={() => setArquivoAnexo("")}
                          onChange={(e) => setArquivoAnexo(e.target.files[0])}
                        />
                      </div>
                      <div>
                        <span className="f-12 txt-grey">Descrição</span>
                        <textarea
                          rows={15}
                          value={descricao}
                          style={{ resize: "none" }}
                          className="form-control brd-1 p-2"
                          onChange={(e) => setDescricao(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  )}
                  {enviado && (
                    <div className="w-100">
                      <div>
                        <p className="f-12 txt-grey m-0">Motivo</p>
                        <p>{pagamento.tipoMotivo || "--"}</p>
                      </div>
                      {pagamento.motivo && (
                        <div>
                          <p className="f-12 txt-grey m-0">Qual motivo?</p>
                          <p>{pagamento.motivo || "--"}</p>
                        </div>
                      )}
                      <div className="mb-3">
                        <p className="f-12 txt-grey m-0">Arquivo Anexado</p>
                        {pagamento?.base64ArquivoAnexoRevisao ? (
                          <span
                            role="button"
                            onClick={() =>
                              downloadExcelFile(
                                pagamento.base64ArquivoAnexoRevisao,
                                pagamento.nomeArquivoAnexoRevisao
                              )
                            }
                            className="txt-blue-link text-underline"
                          >
                            <span className="mr-2">
                              {pagamento.nomeArquivoAnexoRevisao}
                            </span>
                            <i className="fa fa-download f-14"></i>
                          </span>
                        ) : (
                          <span>--</span>
                        )}
                      </div>
                      <div>
                        <p className="f-12 txt-grey m-0">Descrição</p>
                        <p>{pagamento.descricaoMotivo || "--"}</p>
                      </div>
                    </div>
                  )}

                  {exibeBotoes && (
                    <Button
                      name={"Enviar Valores"}
                      action={() => setModalEmail("Enviar")}
                      activeClassName="position-absolute fixed-bottom d-flex justify-content-center mb-3"
                      classes="default-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                    />
                  )}
                </div>
              </div>
            </>
          )}

          {linkExterno && (
            <div className="card-body shadow-custom bg-white rounded-12 col-12 maxvh-expired-card d-flex flex-column justify-content-center align-items-center">
              <p className="mb-5 p-color-imp f-36 font-weight-bold text-center lh-normal">
                Link expirado.
              </p>

              <p className="fw-400 text-center">
                A página de consulta do evento de pagamento que selecionou não
                está mais disponível.
              </p>
              <p className="fw-400 mb-0 text-center">
                Caso tenha dúvidas ou precise informar uma correção envie um
                e-mail para{" "}
                <a
                  className="p-color-lighten-imp"
                  href="mailto:gestao@opeacapital.com"
                >
                  gestao@opeacapital.com.
                </a>
              </p>
            </div>
          )}
        </div>

        <ModalExternoEnviarEmailAF
          close={() => setModalEmail("")}
          open={modalEmail ? true : false}
          pagamento={{ ...pagamento, tipoModal: modalEmail }}
          motivo={
            modalEmail == "Enviar"
              ? {
                  arquivoMotivo: arquivoAnexo,
                  tipoMotivo: motivo,
                  motivo: descricaoMotivo,
                  descricaoMotivo: descricao,
                }
              : null
          }
        />
      </ContainerPage>
      <Message />
      <LoadingScreen />
    </>
  );
};

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.externoPagamentoAF,
  linkExterno: state.pagamentoReducer.checaLinkExternoAF,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buscaExternoPagamentoAF,
      verificaLinkExternoPagamentoAF,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgenteFiduciarioPageExterna);
