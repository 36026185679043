import React, { Component } from "react";
import { connect } from 'react-redux';
import ContainerPage from "../../../common/container/ContainerPage";
import AprovacaoListFilter from "./form/AprovacaoListFilter";
import AprovacaoList from "./list/AprovacaoList";

class AprovacaoPage extends Component {
    render() {
        const { showModal } = this.props;

        return (
            <ContainerPage>
                <AprovacaoListFilter showModal={showModal} />
                <AprovacaoList />
            </ContainerPage>
        );
    }
}

export default connect(null, null)(AprovacaoPage);