import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";

import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";

import { getCota, loadCotaForm } from "../../../../../actions/cota/CotaAction";
import TableActionWithRouteAuthorization from "../../../../common/table/TableActionWithRouteAuthorization";
import { CARTEIRA } from "../../../../../utils/actionsAndFeaturesAuth";
import ReactTooltip from "react-tooltip";
import {
  convertCurrencyNumber,
  formatDecimal,
} from "../../../../../utils/helpers/NumberHelper";

const CotaList = (props) => {
  const {
    getCota,
    cotaData,
    cotaConsult,
    indicadores,
    messages,
    loadCotaForm,
  } = props;

  const borders = ["#DF6378", "#900E37", "#301217"];

  const temStatus = indicadores?.status?.length > 0;
  const temDias = indicadores?.dias?.length > 0;

  const tableHeader = (
    <tr>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Nome do Fundo
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Data-Base
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Patrimônio Líquido
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Cota Sênior
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Cota Mezanino
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          Cota Subordinada
        </div>
      </th>
      <th className="text-center"></th>
    </tr>
  );

  const emptyData = messages?.infos[0];

  // prettier-ignore
  function indicadorBase(ind, i) {
    return (
      <div
        key={`indicadorBase-${i}`}
        className={`w-100 shadow-custom rounded-8 bg-white mx-lg-1 mb-lg-0 p-2 overflow-hidden border-bottom-card-pgmt-base ${ind.status?.raw} mb-xl-0 mb-2`}
      >
        <p className="d-flex flex-row align-items-center justify-content-end card-pgmt-warn"></p>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p
            className={`fw-500 lgt-${ind.quantidadeCotas.toString().length >= 9 && "sm"}`}
          >
            {formatDecimal(ind.quantidadeCotas, 0)}
          </p>
          <div
            data-type="opea"
            data-for={`ttp-${i}`}
            className="text-nowrap overflow-hidden text-overflow-ellipsis"
            data-tip={`R$ ${convertCurrencyNumber(ind.patrimonioLiquido, false, 2) || 0}`}
          >
            <p className="fw-400 text-nowrap overflow-hidden text-overflow-ellipsis text-center">
              R$ {convertCurrencyNumber(ind.patrimonioLiquido, false, 2) || 0}
            </p>
            <ReactTooltip
              effect="solid"
              className="px-2 py-1"
              globalEventOff="click"
              id={`ttp-${i}`}
              style={{ width: "100px" }}
            />
          </div>
          <span className="fw-500 text-nowrap">{ind.status?.value}</span>
        </div>
      </div>
    );
  }

  // prettier-ignore
  function indicadorBaseDia(ind, border, i) {
    return (
      <div
        key={`indicadorBaseDia-${i}`}
        style={{ borderColor: border }}
        className={`w-100 shadow-custom rounded-8 bg-white mx-lg-1 mb-lg-0 p-2 overflow-hidden border-bottom-card-pgmt-base mb-xl-0 mb-2`}
      >
        <p className="d-flex flex-row align-items-center justify-content-end card-pgmt-warn"></p>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p
            className={`fw-500 lgt-${ind.quantidadeCotas.toString().length >= 9 && "sm"}`}
          >
            {formatDecimal(ind.quantidadeCotas, 0)}
          </p>
          <div
            data-type="opea"
            data-for={`ttp-dia-${i}`}
            className="text-nowrap overflow-hidden text-overflow-ellipsis"
            data-tip={`R$ ${convertCurrencyNumber(ind.patrimonioLiquido, false, 2) || 0}`}
          >
            <p className="fw-400 text-nowrap overflow-hidden text-overflow-ellipsis text-center">
              R$ {convertCurrencyNumber(ind.patrimonioLiquido, false, 2) || 0}
            </p>
            <ReactTooltip
              effect="solid"
              className="px-2 py-1"
              globalEventOff="click"
              id={`ttp-dia-${i}`}
              style={{ width: "100px" }}
            />
          </div>
          <span className="fw-500 text-nowrap">
            {getDateFormatedFromString(ind.dataBase)}
          </span>
        </div>
      </div>
    );
  }

  const tableContent =
    cotaData &&
    cotaData.hasItems &&
    cotaData.items.map((item, index) => (
      <tr key={index} className={`tr-status sts-hist-${item?.status?.key}`}>
        <td className="text-center td-status">
          <div className="d-flex justify-content-start align-items-center pl-2">
            {item?.status?.key && (
              <>
                <div
                  data-type="light"
                  data-for={`ttp-list-${index}`}
                  data-tip={item.status.value}
                  className={`circle-status sts-hist-${item.status.key} rounded-circle mr-2`}
                />
                <ReactTooltip
                  noArrow
                  effect="solid"
                  id={`ttp-list-${index}`}
                  className="px-2 py-1"
                  globalEventOff="click"
                  style={{ width: "100px" }}
                />
              </>
            )}
            <span className="fw-400 mt-1 mx-0 mb-0">
              {item.nomeFundo || "--"}
            </span>
          </div>
        </td>
        <td className="text-center fw-400">
          {getDateFormatedFromString(item.dataBase) || "--"}
        </td>
        <td className="text-center fw-400">
          {convertCurrencyNumber(item.patrimonioLiquido, "R$", 2) || "--"}
        </td>
        <td className="text-center fw-400">{convertCurrencyNumber(item.cotaSenior, "", 8)}</td>
        <td className="text-center fw-400">{convertCurrencyNumber(item.cotaMezanino, "", 8)}</td>
        <td className="text-center fw-400">{convertCurrencyNumber(item.cotaSubordinada, "", 8)}</td>
        <TableActionWithRouteAuthorization
          feature={CARTEIRA}
          arrow={true}
          updateAction={() => loadCotaForm(item.id)}
        />
      </tr>
    ));

  // prettier-ignore
  return (
    <div className="container-fluid">
      <div className="p-3">
        {indicadores && (
          <div className="row cards-pgmt mt-3 px-xl-2 px-0">
            {temStatus && (
              <div className={`col-12 col-xl-${temDias ? "6" : "12"} mt-4 mt-xl-0 px-xl-2 px-3`}>
                <div className="w-100 h-100 position-relative border-content-pgmt rounded-8 p-3 d-flex flex-column flex-lg-row">
                  <span className="position-absolute fw-400 title-pgmt px-2">
                    Status Cota
                  </span>
                  {indicadores.status.map((ind, i) => indicadorBase(ind, i))}
                </div>
              </div>
            )}

            {temDias && (
              <div className={`col-12 col-xl-${temStatus ? "6" : "12"} mt-4 mt-xl-0 px-xl-2 px-3`}>
                <div className="w-100 h-100 position-relative border-content-pgmt rounded-8 p-3 d-flex flex-column flex-lg-row">
                  <span className="position-absolute fw-400 title-pgmt px-2">
                    Patrimônio Líquido sob Gestão
                  </span>
                  {indicadores.dias.map((ind, i) => indicadorBaseDia(ind, borders[i], i))}
                </div>
              </div>
            )}
          </div>
        )}

        {cotaData && cotaData?.items?.length > 0 && (
          <Table
            withoutHeader
            classes="shadow-custom rounded-12 col-12 mt-4 mb-3 pd-10 bg-white"
            totalItems={cotaData.totalCount}
            pagination={
              <PaginationRB
                pageNumber={cotaData.pageIndex - 1}
                itemsPerPage={cotaData.pageSize}
                totalItems={cotaData.totalCount}
                filter={cotaConsult}
                fetchFunction={getCota}
                pageRange={5}
              />
            }
          >
            <TableHeader>{tableHeader}</TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        )}

        {!cotaData && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

formValueSelector("CotaForm");

const mapStateToProps = (state) => ({
  cotaData: state.cotaReducer.cota,
  cotaConsult: state.cotaReducer.cotaConsultCurrent,
  indicadores: state.cotaReducer.indicadores,
  messages: state.cotaReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getCota,
      loadCotaForm,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(CotaList);
