import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  editDominio,
  saveDominio,
  showDominioList,
} from "../../../../../../actions/precificacao/DominioAction";
import { showConfirmDialog } from "../../../../../../actions/common/modal/ConfirmDialogAction";

import If from "../../../../../common/If/If";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";
import FloatButtonGroup from "../../../../../common/button/FloatButtonGroup";
import FloatButton from "../../../../../common/button/FloatButton";
import ConfirmDialog from "../../../../../common/modal/ConfirmDialog";

let DominioForm = (props) => {
  const {
    title,
    showDominioList,
    floatButtonVisible,
    showConfirmDialog,
    showCancelConfirmDialog,
    dominioForm,
    editDominio,
    saveDominio,
  } = props;

  let { id, descricao } = dominioForm;

  const [dominio, setDominio] = useState(descricao || "");

  const data = {
    id: id,
    descricao: dominio || descricao,
  };

  let activateFocus = (e) => {
    e.target.parentNode.classList.add("has-float-label");
  };

  return (
    <div className="w-100 px-4 mx-2">
      <FormHeader title={title} offSet={20}></FormHeader>
      <FormContent classes="shadow-custom rounded-12 bg-white px-3 py-2">
        <div className="column">
          <p className="page-head__title f-22 my-2">Controle</p>
          <div className="form-group field-floating">
            <input
              name="dominio"
              id="txtfield_dominio"
              className="form-control"
              defaultValue={descricao}
              onInput={(v) => setDominio(v.target.value)}
              onFocus={activateFocus}
              required={true}
            />
            <label className="control-label" htmlFor="dominio" id="lbl_dominio">
              Domínio
            </label>
            <span className="material-input" />
          </div>
        </div>
        <FloatButtonGroup>
          <FloatButton
            activeClassName={"nd1 nds"}
            title={"Cancelar"}
            icon={"fa fa-ban edit edit-sub-menu"}
            action={() =>
              floatButtonVisible ? showConfirmDialog(true) : showDominioList()
            }
          />
          <If test={floatButtonVisible}>
            <FloatButton
              activeClassName={"nd3 nds"}
              title={"Salvar"}
              icon={"material-icons edit edit-sub-menu"}
              cloudDone={true}
              action={() => (id ? editDominio(data) : saveDominio(data))}
            />
          </If>
        </FloatButtonGroup>
      </FormContent>

      <ConfirmDialog
        title="Aviso"
        contentText="Os dados serão perdidos, deseja realmente sair?"
        textButtonCancel="CANCELAR"
        textButtonConfirm="CONFIRMAR"
        showDialog={showCancelConfirmDialog}
        onClose={() => showConfirmDialog(false)}
        onConfirm={() => showDominioList()}
        type="warning"
        maxWidth="md"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
  dominioForm: state.dominioReducer.dominioForm || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showDominioList,
      showConfirmDialog,
      saveDominio,
      editDominio,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DominioForm);
