import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';

import { showComplementoList } from '../../../../../../actions/operacao/ComplementoAction';
import { showConfirmDialog } from '../../../../../../actions/common/modal/ConfirmDialogAction';

import ComplementoFormGeral from './tabs/ComplementoFormGeral';
import ComplementoFormGeralMonitoramento from './tabs/ComplementoFormGeralMonitoramento';

import If from './../../../../../common/If/If';
import Form from './../../../../../common/form/Form';
import FormHeader from './../../../../../common/form/FormHeader';
import FormContent from './../../../../../common/form/FormContent';
import FloatButtonGroup from './../../../../../common/button/FloatButtonGroup';
import FloatButton from './../../../../../common/button/FloatButton';
import ConfirmDialog from './../../../../../common/modal/ConfirmDialog';

let ComplementoForm = (props) => {
    const { 
        handleSubmit, 
        showComplementoList, 
        title, 
        readOnly,
        floatButtonVisible,
        codigoRbOperacao,
        showConfirmDialog,
        showCancelConfirmDialog,
        selectedOperation,
    } = props;

    return (
        <Form>
            <FormHeader 
                title={title}
                codigoOpea={codigoRbOperacao ? codigoRbOperacao : (selectedOperation.length > 0) ? selectedOperation[0].value : ''}
                col={"col-md-8"}
                offSet={20}>
            </FormHeader>
            <FormContent handleSubmit={handleSubmit}>

                <ComplementoFormGeral readOnly={readOnly} />
                
                <ComplementoFormGeralMonitoramento readOnly={readOnly} />

                <FloatButtonGroup >
                    <FloatButton
                        activeClassName={'nd1 nds'}
                        title={'Cancelar'}
                        icon={'fa fa-ban edit edit-sub-menu'}
                        action={() => (floatButtonVisible) ? showConfirmDialog(true) : showComplementoList()}
                    />
                    <If test={floatButtonVisible}>
                        <FloatButton
                            activeClassName={'nd3 nds'} 
                            title={'Salvar'}
                            icon={'material-icons edit edit-sub-menu'}
                            cloudDone={true}
                            action={handleSubmit}
                        />
                    </If>
                </FloatButtonGroup>
            </FormContent>

            <ConfirmDialog
                title="Aviso"
                contentText="Os dados do formulário serão perdidos, deseja realmente sair?"
                textButtonCancel="CANCELAR"
                textButtonConfirm="CONFIRMAR"
                showDialog={showCancelConfirmDialog}
                onClose={() => showConfirmDialog(false)}
                onConfirm={() => showComplementoList()}
                type="warning"
                maxWidth="md"
            />
        </Form>
    );
}

ComplementoForm = reduxForm({ form: 'complementoForm' })(ComplementoForm);
const selector = formValueSelector('complementoForm');

const mapStateToProps = state => ({     
    codigoRbOperacao: selector(state, 'codigoRB') || '',
    showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
    selectedOperation: state.operacaoReducer.selectedOperacoes || [],
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
    showComplementoList,
    showConfirmDialog
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ComplementoForm);
