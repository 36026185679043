import axios from "axios";

import { selectTab } from './tabs/TabsAction';
import { DASHBOARD_TAB } from '../../utils/tabs';

const DASHBOARD_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_DASHBOARD_API;
    
const AGRUPAMENTO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API + 
    process.env.REACT_APP_PATH_AGRUPAMENTO_API;

export const SHOW_DASHBOARD= 'SHOW_DASHBOARD';
export const FILTROS_CHART_FETCHED = "FILTROS_CHART_FETCHED";
export const PAGAMENTOS_CHART_FETCHED = "PAGAMENTOS_CHART_FETCHED";
export const MONITORAMENTOS_CHART_FETCHED = "MONITORAMENTOS_CHART_FETCHED";
export const DIARIO_DE_BORDO_CHART_FETCHED = "DIARIO_DE_BORDO_CHART_FETCHED";
export const INDICES_DE_INFLACAO_CHART_FETCHED = "INDICES_DE_INFLACAO_CHART_FETCHED";
export const GRUPOS_FETCHED = "GRUPOS_FETCHED";
export const SALDO_FETCHED = "SALDO_FETCHED";
export const SERIES_FETCHED = "SERIES_FETCHED";
export const NOVAS_SERIES_FETCHED = "NOVAS_SERIES_FETCHED";
export const PROXIMOS_PAGAMENTOS_FETCHED = "PROXIMOS_PAGAMENTOS_FETCHED";
export const PROXIMOS_MONITORAMENTOS_FETCHED = "PROXIMOS_MONITORAMENTOS_FETCHED";
export const DISTRIBUICAO_FETCHED = "DISTRIBUICAO_FETCHED";

export function showDashboard(showDashboard) {
    return (dispatch) => {
        return dispatch([
            {
                type: SHOW_DASHBOARD,
                payload: showDashboard
            }
        ])
    }
}

export function showDashboardTabActive(IdsOperacoes = []) {
    return (dispatch) => {
        return dispatch([
            selectTab(DASHBOARD_TAB),
            showDashboard(true)
        ])
    }
}

export function getFiltros() {
    return dispatch => {
        return axios
            .get(DASHBOARD_API + 'filtros')
            .then(result => {
                dispatch({
                    type: FILTROS_CHART_FETCHED,
                    payload: result
                });
            });
    };
}

export function getPagamentos(idsOperacoes, idsGrupos = null, moeda = null) {
    return dispatch => {
        return axios
            .post(DASHBOARD_API + 'pagamento', {
                idsOperacoes: idsOperacoes && idsOperacoes.length > 0 ? idsOperacoes : null,
                idsGrupos: idsGrupos && idsGrupos.length > 0 ? idsGrupos : null,
                moeda: moeda ?? moeda, 
            })
            .then(result => {
                dispatch({
                    type: PAGAMENTOS_CHART_FETCHED,
                    payload: result
                });
            });
    };
}

export function getMonitoramentos(idsOperacoes, idsTiposMonitoramento, idsGrupos = null) {
    return dispatch => {
        return axios
            .post(DASHBOARD_API + 'monitoramento', {
                    idsOperacoes: idsOperacoes && idsOperacoes.length > 0 ? idsOperacoes : null,
                    idsTiposMonitoramento: idsTiposMonitoramento,
                    idsGrupos: idsGrupos && idsGrupos.length > 0 ? idsGrupos : null,
                })
            .then(result => {
                dispatch({
                    type: MONITORAMENTOS_CHART_FETCHED,
                    payload: result
                });
            });
    };
}

export function getDiarioDeBordo(idsOperacoes, idsGrupos = null) {
    return dispatch => {
        return axios
            .post(DASHBOARD_API + 'diariobordo', {
                idsOperacoes: idsOperacoes && idsOperacoes.length > 0 ? idsOperacoes : null,
                idsGrupos: idsGrupos && idsGrupos.length > 0 ? idsGrupos : null,
            })
            .then(result => {
                dispatch({
                    type: DIARIO_DE_BORDO_CHART_FETCHED,
                    payload: result
                });
            });
    };
}

export function getIndicesDeInflacao() {
    return dispatch => {
        return axios
            .get(DASHBOARD_API + 'indice')
            .then(result => {
                dispatch({
                    type: INDICES_DE_INFLACAO_CHART_FETCHED,
                    payload: result,
                });
            });
    };
}

export function setGrupos(selectedGrupos) {
    let idsGrupo = selectedGrupos.length > 0 ? selectedGrupos.map(o => o.id): null;

    return (dispatch) => {
        return dispatch([
            { type: GRUPOS_FETCHED, payload: selectedGrupos },
            getMonitoramentos(null, null, idsGrupo),
            getPagamentos(null, idsGrupo),
            getDiarioDeBordo(null, idsGrupo),
            getSaldo(null,idsGrupo),
            getQuantitativoSeries(null, idsGrupo),
            getQuantitativoNovasSeries(null, idsGrupo),
            getQuantitativoProximosPagamentos(null, idsGrupo),
            getQuantitativoProximosMonitoramentos(null, idsGrupo),
            getOperacoesEmDistribuicao(null,idsGrupo),
        ]);
    };
}

export function clearGrupos() {

    return (dispatch) => {
        return dispatch([
            { type: GRUPOS_FETCHED, payload: [] },
        ]);
    };
}

export function getSaldo(idsOperacoes, idsGrupos = null) {
    return dispatch => {
        return axios
            .post(AGRUPAMENTO_API + 'saldo', {
                idsOperacoes: idsOperacoes && idsOperacoes.length > 0 ? idsOperacoes : null,
                idsGrupos: idsGrupos && idsGrupos.length > 0 ? idsGrupos : null,
            })
            .then(result => {
                dispatch({
                    type: SALDO_FETCHED,
                    payload: result
                });
            });
    };
}

export function getQuantitativoSeries(idsOperacoes, idsGrupos = null) {
    return dispatch => {
        var data = {
            idsOperacoes: idsOperacoes && idsOperacoes.length > 0 ? idsOperacoes : null,
            idsGrupos: idsGrupos && idsGrupos.length > 0 ? idsGrupos : null,
        };

        return axios
            .post(AGRUPAMENTO_API + 'series', data)
            .then(result => {
                dispatch({
                    type: SERIES_FETCHED,
                    payload: result
                });
            });
    };
}

export function getQuantitativoNovasSeries(idsOperacoes, idsGrupos = null) {
    return dispatch => {
        var data = {
            idsOperacoes: idsOperacoes && idsOperacoes.length > 0 ? idsOperacoes : null,
            idsGrupos: idsGrupos && idsGrupos.length > 0 ? idsGrupos : null,
        };

        return axios
            .post(AGRUPAMENTO_API + 'novasseries', data)
            .then(result => {
                dispatch({
                    type: NOVAS_SERIES_FETCHED,
                    payload: result
                });
            });
    };
}

export function getQuantitativoProximosPagamentos(idsOperacoes, idsGrupos = null) {
    return dispatch => {
        var data = {
            idsOperacoes: idsOperacoes && idsOperacoes.length > 0 ? idsOperacoes : null,
            idsGrupos: idsGrupos && idsGrupos.length > 0 ? idsGrupos : null,
        };

        return axios
            .post(AGRUPAMENTO_API + 'pagamentos', data)
            .then(result => {
                dispatch({
                    type: PROXIMOS_PAGAMENTOS_FETCHED,
                    payload: result
                });
            });
    };
}

export function getQuantitativoProximosMonitoramentos(idsOperacoes, idsGrupos = null) {
    return dispatch => {
        var data = {
            idsOperacoes: idsOperacoes && idsOperacoes.length > 0 ? idsOperacoes : null,
            idsGrupos: idsGrupos && idsGrupos.length > 0 ? idsGrupos : null,
        };

        return axios
            .post(AGRUPAMENTO_API + 'monitoramentos', data)
            .then(result => {
                dispatch({
                    type: PROXIMOS_MONITORAMENTOS_FETCHED,
                    payload: result
                });
            });
    };
}

export function getOperacoesEmDistribuicao(idsOperacoes, idsGrupos = null) {
    return dispatch => {
        var data = {
            idsOperacoes: idsOperacoes && idsOperacoes.length > 0 ? idsOperacoes : null,
            idsGrupos: idsGrupos && idsGrupos.length > 0 ? idsGrupos : null,
        };

        return axios
            .post(AGRUPAMENTO_API + 'emdistribuicao', data)
            .then(result => {
                dispatch({
                    type: DISTRIBUICAO_FETCHED,
                    payload: result
                });
            });
    };
}