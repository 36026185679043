import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ContainerPage from "../../../common/container/ContainerPage";
import UsuarioFilter from "./form/UsuarioFilter";
import UsuarioList from "./list/UsuarioList";

import { loadFilterFormUsuario, cleanResult, getContasUser } from '../../../../actions/usuario/UsuarioAction';


class UsuarioPage extends Component {
    componentDidMount() {
        this.props.cleanResult();
        this.props.loadFilterFormUsuario();
        this.props.getContasUser();
    }

    render() {
        return (
            <ContainerPage>
                <UsuarioFilter/>
                <UsuarioList />
            </ContainerPage>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ loadFilterFormUsuario, cleanResult, getContasUser }, dispatch);


export default connect(null, mapDispatchToProps)(UsuarioPage);
