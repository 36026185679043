/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";
import {
  exportAprovacaoExcel,
  getAprovacoes,
} from "../../../../../actions/aprovacao/AprovacaoAction";
import ReactTooltip from "react-tooltip";
import { APROVACAO_ROUTE_PATH, EDITAR_ROUTE_PATH } from "../../../../common/routers/constantesRoutes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

const AprovacaoList = (props) => {
  const {
    getAprovacoes,
    aprovacaoData,
    aprovacaoConsult,
    messages,
    exportAprovacaoExcel,
  } = props;

  const classTable = "text-center fw-700 d-flex justify-content-center w-100";
  const emptyData = messages?.infos[0];

  const aprovadorTd = (item, index) => {
    return item ? (
      <td className="text-center td-status">
        <div className="d-flex justify-content-center align-items-center">
          <div
            data-type="light"
            data-for={`ttp-list-${index}`}
            data-tip={item?.statusAprovacao?.value}
            className={`circle-status sts-passivo-${item?.statusAprovacao?.key} rounded-circle mr-2`}
          />
          <ReactTooltip
            noArrow
            effect="solid"
            id={`ttp-list-${index}`}
            className="px-2 py-1"
            globalEventOff="click"
            style={{ width: "100px" }}
          />
          <span className="fw-400 mt-1px mx-0 mb-0">
            {item?.aprovador?.nome || "--"}
          </span>
        </div>
      </td>
    ) : (
      <td className="text-center">--</td>
    );
  };

  const tableHeader = (
    <tr>
      <th>
        <div className={`${classTable}`}>ID APROVAÇÃO</div>
      </th>
      <th>
        <div className={`${classTable}`}>PATRIMÔNIO SEPARADO</div>
      </th>
      <th>
        <div className={`${classTable}`}>CÓD. OPEA</div>
      </th>
      <th>
        <div className={`${classTable}`}>DATA SOLICITAÇÃO</div>
      </th>
      <th>
        <div className={`${classTable}`}>SOLICITANTE</div>
      </th>
      <th>
        <div className={`${classTable}`}>TIPO</div>
      </th>
      <th>
        <div className={`${classTable}`}>ID CADASTRO</div>
      </th>
      <th>
        <div className={`${classTable}`}>APROVADOR 1</div>
      </th>
      <th>
        <div className={`${classTable}`}>APROVADOR 2</div>
      </th>
      <th className="text-center"></th>
    </tr>
  );

  const tableContent =
    aprovacaoData &&
    aprovacaoData.hasItems &&
    aprovacaoData.items.map((item, index) => {
      const id = item?.aprovacaoTipo?.id;
      
      let to;
      switch (id) {
        case 2: to = `contrato`; break;
        case 3: to = `pedido`; break;
        case 4: to = `contrato/receita`; break;
        case 5: to = `contas`; break;
        default: to = '#';
      }

      return (
        <tr key={index} className={`tr-status sts-${item?.ultimaEtapa?.id}`}>
          <td className="text-center fw-400">
            {item?.idFormatado || "--"}
          </td>
          <td className="text-center fw-400">
            {item?.patrimonioSeparado?.descricao || "--"}
          </td>
          <td className="text-center fw-400">
            {item?.operacao?.CodigoOpea || "--"}
          </td>
          <td className="text-center fw-400">
            {getDateFormatedFromString(item?.dataSolicitacao) || "--"}
          </td>
          <td className="text-center fw-400">
            {item?.solicitante?.nome || "--"}
          </td>
          <td className="text-center fw-400">
            {item?.aprovacaoTipo?.descricao || "--"}
          </td>
          <td className="text-center fw-400">{item?.idCadastro || "--"}</td>

          {aprovadorTd(item?.aprovadores[0], index)}
          {aprovadorTd(item?.aprovadores[1], index)}

          <td className="text-center">
            <Link to={`/${APROVACAO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/${to}/${item.codigo}`} className="p-color-imp f-20 mr-3 ml-2 px-1">
              <FontAwesomeIcon color='#630a37' icon={faChevronRight} />
            </Link>
          </td>
        </tr>
      )
    });

  return (
    <div className="container-fluid">
      <div className="p-3">
        {aprovacaoData.totalCount > 0 && (
          <Table
            withoutHeader
            files={[
              { label: 'Exportar para Excel', function: () => exportAprovacaoExcel(aprovacaoConsult) }
            ]}
            classes="shadow-custom rounded-12 col-12 mt-2 mb-3 pd-10 bg-white"
            totalItems={aprovacaoData.totalCount}
            pagination={
              <PaginationRB
                pageNumber={aprovacaoData.pageIndex - 1}
                itemsPerPage={aprovacaoData.pageSize}
                totalItems={aprovacaoData.totalCount}
                filter={aprovacaoConsult}
                fetchFunction={getAprovacoes}
                pageRange={5}
              />
            }
          >
            <TableHeader>{tableHeader}</TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        )}

        {aprovacaoData.totalCount <= 0 && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

formValueSelector("AprovacaoForm");

const mapStateToProps = (state) => ({
  aprovacaoData: state.aprovacaoReducer.aprovacoes,
  aprovacaoConsult: state.aprovacaoReducer.aprovacoesConsultCurrent,
  messages: state.aprovacaoReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getAprovacoes,
      exportAprovacaoExcel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(AprovacaoList);
