import axios from "axios";
import { reset } from "redux-form";

import history from "../../components/common/routers/history";
import { showAllMessages } from "../message/ErrorMessagesAPIAction";
import { downloadFile, downloadFileBlob } from "../../utils/helpers/FileHelper";
import { showErrorMessages } from "../message/ErrorMessagesAPIAction";
import { uploadBlobMultipleFiles, downloadBlobFile, removeBlobFile } from "../externalService/BlobFileAction";

import { deserializeFieldsUsuarioPortalFormFilter } from "../../components/business/acessoPortal/mappings/AcessoPortalMap";

import { DETALHAR_ROUTE_PATH, GERENCIAR_ROUTE_PATH, ACESSO_PORTAL_ROUTE_PATH, LISTA_ROUTE_PATH } from "../../components/common/routers/constantesRoutes";

export const USUARIOS_PORTAL_LOAD_FILTER = 'USUARIOS_PORTAL_LOAD_FILTER';
export const USUARIOS_PORTAL_FETCHED = 'USUARIOS_PORTAL_FETCHED';
export const CLEAN_LIST_CONTENT = 'CLEAN_LIST_CONTENT';
export const ACESSO_PORTAL_LOAD_FORM = 'ACESSO_PORTAL_LOAD_FORM';
export const ACESSO_PORTAL_SHOW_ANEXO_DELETE_DIALOG = "ACESSO_PORTAL_SHOW_ANEXO_DELETE_DIALOG";
const NO_ACTION = { type: '' };

const ACESSO_PORTAL_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_ACESSO_PORTAL_API;

export function loadFilter() {
    return (dispatch) => {
        return axios
            .get(`${ACESSO_PORTAL_API}tela/filtro`)
            .then(result =>
                dispatch({
                    type: USUARIOS_PORTAL_LOAD_FILTER,
                    payload: result.content,
                })
            );
    }
}

export function getUsuariosPortal(filters, page = 1) {
    const params = deserializeFieldsUsuarioPortalFormFilter(filters, page);

    return (dispatch) => {
        return axios
            .post(`${ACESSO_PORTAL_API}usuarios`, { ...params })
            .then(result =>
                dispatch({
                    type: USUARIOS_PORTAL_FETCHED,
                    payload: { result },
                })
            );
    }
}

export function cleanResult() {
    return (dispatch) => {
        return dispatch([
            reset('acessoPortalFilterForm'),
            { type: CLEAN_LIST_CONTENT },
        ])
    }
}

export function acoes(idAcao, idUsuario) {
    // ordem dos ids: Detalhar(0), Gerenciar(1)
    console.log({ idAcao, idUsuario })
    const gerenciar = idAcao === 1;

    return dispatch => {
        if (idAcao > 1)
            return dispatch(NO_ACTION);
        else
            return dispatch(goToGerenciarPage(idUsuario, gerenciar));
    }
}

export function goToGerenciarPage(idUsuario, editAction) {
    let routeAction = editAction ? GERENCIAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;
    return dispatch => {
        return dispatch([
            NO_ACTION,
            history.push(`/${ACESSO_PORTAL_ROUTE_PATH}/${routeAction}/${idUsuario}`)
        ]);
    }
}

export function loadFormAcessoPortal(idUsuario) {
    return dispatch => {
        return axios.get(`${ACESSO_PORTAL_API}${idUsuario}`)
            .then(result =>
                dispatch({
                    type: ACESSO_PORTAL_LOAD_FORM,
                    payload: result.content
                })
            );
    };
}

export function downloadDocumento(idDocumento, nomeArquivo) {
    return (dispatch) => {
        return axios
            .get(`${ACESSO_PORTAL_API}downloaddocumento/${idDocumento}`)
            .then(result => {
                dispatch([
                    NO_ACTION,
                    downloadFile(result.content, nomeArquivo)
                ])
            });
    }
}

export function validarUsuario(idUsuarioPortal) {
    return (dispatch) => {
        return axios
            .put(`${ACESSO_PORTAL_API}validardocumento`, { idUsuarioPortal })
            .then(result => {
                dispatch([
                    cleanResult(),
                    history.push(`/${ACESSO_PORTAL_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
                    showAllMessages(result.messages),
                ])
            });
    }
}

export function cancelarValidarUsuario() {
    return dispatch => {
        return dispatch([
            { type: CLEAN_LIST_CONTENT },
            history.push(`/${ACESSO_PORTAL_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)
        ]);
    }
}

export function removerDocumento(idAnexo, idUsuario, fileFolder, fileHash) {
    return (dispatch) => {
        return axios
            .delete(`${ACESSO_PORTAL_API}documento/${idAnexo}`)
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    loadFormAcessoPortal(idUsuario),
                    showAnexoDeleteDialog(false, idUsuario, idAnexo, fileHash)
                ])
            );
    }
}

export function showAnexoDeleteDialog(showDialog, idUsuario, idAnexo, fileHash) {
    return (dispatch) => {
        return dispatch([
            {
                type: ACESSO_PORTAL_SHOW_ANEXO_DELETE_DIALOG,
                payload: { showDialog, idUsuario, idAnexo, fileHash }
            },
        ])
    }
}

export function updloadDocumentosDiversos(idPortalUsuario, filesToUpload, fileFolder) {
    return (dispatch) => {
        let files = [];

        for (let i = 0; i < filesToUpload.length; i++) {
            const errors = arquivoDiversoValido(filesToUpload[i]);

            if (errors.length > 0) {
                return dispatch([
                    showErrorMessages(errors)
                ]);
            }

            files.push(filesToUpload[i].all);
        }

        uploadBlobMultipleFiles(`${fileFolder}`, files)
            .then(responseBlobFile => {
                let promises = [];
                let messages = [];
        
                for (let i = 0; i < filesToUpload.length; i++) {
                    const dto = {
                        idPortalUsuario,
                        nomeArquivo: filesToUpload[i].name,
                        tipoArquivo: { key: 1, raw: 'Documento' },
                        //arquivo: filesToUpload[i].arquivo,
                        statusDocumento: { key: 3, raw: 'NaoValidado' },
                        hashArquivo: getFileHash(responseBlobFile.files, filesToUpload[i].name),
                    };
        
                    promises.push(
                        axios.post(`${ACESSO_PORTAL_API}documento/upload`, { ...dto })
                            .then(result => {
                                messages.push(result.messages)
                            })
                    );
                }
        
                return Promise.all(promises).then(result => {
                    dispatch([
                        showAllMessages(messages[0]),
                        loadFormAcessoPortal(idPortalUsuario)
                    ])
                });
            }).catch((error) => {
                return dispatch([
                    showErrorMessages(["Erro inesperado, não foi possível realizar upload. Por favor tente novamente."])
                ]);
            });
    }
}

function arquivoDiversoValido(dto) {
    let errors = [];

    if (dto.arquivo === undefined || dto.arquivo.length === 0) {
        errors.push("O campo Arquivo é obrigatório.");
    }

    if (dto.name === undefined || dto.name.length === 0) {
        errors.push("O campo Nome Arquivo é obrigatório.");
    }

    if (dto.name !== undefined && dto.name.length > 0) {
        const extensao = dto.name.split('.')[1];
        const extensoesValidas = ["png", "jpeg", "jpg", "pdf", "xls", "xlsx"];
        if (!extensoesValidas.some(e => e === extensao)) {
            errors.push("O arquivo informado não está num formato válido.");
        }
    }

    return errors;
}

function getFileHash(files, fileName) {
    let hash = files
        .find((file) => file.name === fileName)
        .fileHash;

    return hash;
}

export function downloadDocumentoBlobFile(document) {
    return (dispatch) => {
        let folderPath = `${document.folderName}`;

        downloadBlobFile(folderPath, document.fileHash).then(result => {
            return dispatch([
                        NO_ACTION,
                        downloadFileBlob(result, document.fileName),
                    ]);
        }).catch((error) => {
            console.log(error);
            return dispatch([
                showErrorMessages(["Erro inesperado, não foi possível realizar download. Por favor tente novamente."])
            ]);
        });
    }
}

export function removerDocumentoBlobFile(idAnexo, idUsuario, fileFolder, fileHash) {
    return (dispatch) => {
        removeBlobFile(fileFolder, fileHash).then(resultBlob => {
            return axios
                .delete(`${ACESSO_PORTAL_API}documento/${idAnexo}`)
                .then(result =>
                    dispatch([
                        showAllMessages(result.messages),
                        loadFormAcessoPortal(idUsuario),
                        showAnexoDeleteDialog(false, idUsuario, idAnexo, fileHash)
                    ])
                );
        }).catch((error) => {
            console.log(error);
            return dispatch([
                showErrorMessages(["Erro inesperado, não foi possível excluir documento. Por favor tente novamente."])
            ]);
        });
    }
}
