import axios from "axios";
import { change, formValueSelector } from "redux-form";

import { showAllMessages } from './../../actions/message/ErrorMessagesAPIAction';
import { listItems } from './../common/Pagination';

import { downloadExcelFile } from "./../../utils/helpers/FileHelper";
import { getDateFormatedFromString } from "../../utils/helpers/DateTimeHelper";
import { formatPercent } from "./../../utils/helpers/NumberHelper";

import { deserializeFieldsCalculadoraFilter } from "../../components/business/precificacao/mappings/CalculadoraMap";

export const CALCULADORA_FETCH = 'CALCULADORA_FETCH';
export const CALCULADORA_PAGINATION = 'CALCULADORA_PAGINATION';
export const CALCULADORA_SPINNER = 'CALCULADORA_SPINNER';
export const CALCULADORA_CLEAN_FORM = 'CALCULADORA_CLEAN_FORM';

export const TAXA_KEY = '1';
export const PRECO_KEY = '2';
export const LIMIT_ITEMS_PER_PAGE = 10;

const CALCULADORA_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_CALCULADORA_API;

    const CALCULADORA_API_FUNCTION 
        = process.env.REACT_APP_URL_API_CALCULADORA_FUNCTION; 
        //+ process.env.REACT_APP_PATH_CALCULADORA_API;

export function consultar(filters) {
    const params = deserializeFieldsCalculadoraFilter(filters);
    const currEnv = localStorage.getItem('currEnv') ?? localStorage.getItem('currEnv');

    return (dispatch) => {
        return axios
        .post(`${CALCULADORA_API_FUNCTION}`, { ...params }, { headers: { Domain: currEnv } })
        .then(result => {
                const labelTaxaPreco = filters.tipoRelatorio === TAXA_KEY ? "Taxa" : "Preço Unitário"

                dispatch([
                    { 
                        type: CALCULADORA_FETCH, payload: {
                            ...result, 
                            labelTaxaPreco
                        }
                    },
                    paginacao(result.aging.eventoAgingList),
                    { type: CALCULADORA_SPINNER, payload: false }
                ]);
                
            }).catch((e) => {
                return dispatch({ type: CALCULADORA_SPINNER, payload: false });
            });
    }
}

export function getTaxaPorOperacao(value) {
    return (dispatch, getState) => {
        const nenhumaAcao = { type: '' };
        const selector = formValueSelector('calculadoraFilterForm');

        let tipoRelatorio = selector(getState(), 'tipoRelatorio');
        let operacao = selector(getState(), 'operacao');
        
        if (tipoRelatorio === PRECO_KEY && operacao && operacao.key) {
            return axios
                .get(`${CALCULADORA_API}buscar/taxa/${operacao.key}`)
                .then(result => {
                    dispatch([
                        result.content.taxaJurosSpread 
                        ? change("calculadoraFilterForm", "taxa", formatPercent(result.content.taxaJurosSpread, 4)) 
                        : nenhumaAcao,
                        
                        result.content.percentualFlutuante 
                        ? change("calculadoraFilterForm", "percentualFlutuante", formatPercent(result.content.percentualFlutuante, 4)) 
                        : nenhumaAcao,

                        result.content.taxaPosFixada 
                        ? change("calculadoraFilterForm", "labelTaxaPosFixada", result.content.taxaPosFixada) 
                        : nenhumaAcao,

                        result.content.tipoOperacao 
                        ? change("calculadoraFilterForm", "tipoOperacao", result.content.tipoOperacao.key)
                        : nenhumaAcao
                    ])
                });
        } else {
            return dispatch(nenhumaAcao);
        }
    }
}

export function paginacao(result, page = 1) {
    const itensPaginado = listItems(result, page, LIMIT_ITEMS_PER_PAGE);
    return (dispatch) => {
        return dispatch(
            {
                type: CALCULADORA_PAGINATION,
                payload: {items: itensPaginado, pageIndex: page},
            },
        );
    }
}

export function download(data, messages) {
    return (dispatch) => {
        const fileName = `calculadoraPU-${getDateFormatedFromString(data.dataBase)}-${data.idOperacao}.xlsx`;
        return dispatch([
                data.excelFile.length > 0 ? downloadExcelFile(data.excelFile, fileName) : undefined,
                showAllMessages(messages),
        ]);
    }
}

export function loadSpinner(load) {
    return {type: CALCULADORA_SPINNER, payload: load}
}

export function cleanForm() {
    return {type: CALCULADORA_CLEAN_FORM}
}
