import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import SubclasseForm from "./SubclasseForm";
import { getSubclasse } from "../../../../../actions/subclasse/SubclasseAction";

class SubclasseFormVisualize extends Component {
  componentDidMount() {
    let idSubclasse = this.props.match.params.idSubclasse;
    this.props.getSubclasse(idSubclasse);
  }

  render() {
    return (
      <SubclasseForm
        title={"Detalhar - Subclasse"}
        readOnly={true}
        floatButtonVisible={false}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSubclasse,
    },
    dispatch
  );

export default withRouter(
  connect(null, mapDispatchToProps)(SubclasseFormVisualize)
);
