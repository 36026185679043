import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog, FormControlLabel, Checkbox } from "@material-ui/core";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";

import SingleFileInput from "../../../../../common/input/SingleFileInput";
import { turnFileToBase64 } from "../../../../../../utils/helpers/FileHelper";
import { confirmaCamaraPagamento } from "../../../../../../actions/precificacao/PagamentoAction";

let ModalConfirmaEnvioCamara = (props) => {
  const { close, open, pagamento, confirmaCamaraPagamento } = props;

  const [dataEnvio, setDataEnvio] = React.useState("");
  const [comprovante, setComprovante] = React.useState("");
  const [radioChecked, setRadioChecked] = React.useState(false);

  const existeExtra =
    pagamento?.lancamentoCamaraLiquidacao?.existePagamentoExtraordinario;

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Confirmação de Envio à Câmara
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-3">
            <div className="row px-3">
              <div
                className={`form-group field-floating has-float-label mb-0 pl-0 pr-lg-3 pr-0 col-lg-4`}
              >
                <input
                  type="date"
                  name="dataEnvio"
                  value={dataEnvio}
                  className="form-control w-100"
                  onChange={(e) => setDataEnvio(e.target.value)}
                />
                <label htmlFor="dataEnvio" className="control-label">
                  Data do Envio
                  <span className="text-warning font-weight-bold ml-1">*</span>
                </label>
              </div>

              <SingleFileInput
                value={comprovante}
                outerClassName="mb-0 px-0 col-lg-8"
                label="Comprovante de Aprovação"
                removeFile={() => setComprovante("")}
                onChange={(e) => setComprovante(e.target.files[0])}
              />
            </div>
          </div>

          {existeExtra && (
            <div className="py-2 text-dark f-16">
              <div>
                <FormControlLabel
                  className="d-flex flex-row align-items-start"
                  control={
                    <Checkbox
                      color="default"
                      className="pt-0"
                      checked={radioChecked}
                      onChange={(e) => setRadioChecked(e.target.checked)}
                    />
                  }
                  label="Certifico que cadastrei os eventos extraordinários na câmara de liquidação."
                />
              </div>
            </div>
          )}

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Confirmar"}
              disabled={(existeExtra && !radioChecked) || false}
              action={async () => {
                confirmaCamaraPagamento({
                  dataEnvio,
                  confirmarLancamento: true,
                  nomeArquivoComprovante: comprovante?.name,
                  idPrecificacao: pagamento?.idPrecificacao,
                  idArquivo: pagamento?.arquivoCamaraLiquidacao,
                  idPagamentoPrecificacao: pagamento?.idPagamentoPrecificacao,
                  arquivoComprovante: comprovante ? await turnFileToBase64(comprovante) : null,
                });

                close();
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      confirmaCamaraPagamento,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ModalConfirmaEnvioCamara);
