import {
    LOAD_FILTER_FORM_APROVACAO,
    SALVA_FILTROS_APROVACAO,
    APROVACOES_FETCHED,
    APROVACOES_CONSULT_CURRENT,
    APROVACAO_RESET,
    LOAD_CONTRATO_DESPESA_APROVACAO,
    LOAD_CONTRATO_RECEITA_APROVACAO,
    LOAD_PEDIDO_COMPRA_APROVACAO,
    LOAD_CONTAS_RECEBER_APROVACAO,
} from "../../actions/aprovacao/AprovacaoAction";

const INITIAL_STATE = {
    aprovacoes: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    aprovacoesConsultCurrent: null,
    messages: null, 
    isLoading: false,
    aprovacaoFormTypesFilter: {},
    statusEtapas: [
        {id: 1, completed: false},
        {id: 2, completed: false},
        {id: 3, completed: false},
        {id: 4, completed: false},
        {id: 5, completed: false},
    ],
    contratoDespesaAprovacao: {},
    pedidoCompraAprovacao: {},
}

export default function (state = INITIAL_STATE, action = {}) {    
    switch(action.type) {
        case APROVACOES_FETCHED:
            return {
                ...state,
                aprovacoes: action.payload.content,
                messages: action.payload.messages,
                isLoading: true,                
            }
        case APROVACOES_CONSULT_CURRENT:
            return {
                ...state,
                aprovacoesConsultCurrent: action.payload,      
                isLoading: true,     
            }
        case LOAD_FILTER_FORM_APROVACAO:
            return {
                ...state,
                aprovacaoFormTypesFilter: action.payload,
            }
        case LOAD_CONTRATO_DESPESA_APROVACAO:
            return {
                ...state,
                contratoDespesaAprovacao: action.payload,
            }
        case LOAD_CONTRATO_RECEITA_APROVACAO:
            return {
                ...state,
                receitaContratoAprovacao: action.payload,
            }
        case LOAD_CONTAS_RECEBER_APROVACAO:
            return {
                ...state,
                contasReceberAprovacao: action.payload,
            }
        case LOAD_PEDIDO_COMPRA_APROVACAO:
            return {
                ...state,
                pedidoCompraAprovacao: action.payload,
            }
        case APROVACAO_RESET:
            return {
                ...INITIAL_STATE
            }
        case SALVA_FILTROS_APROVACAO:
            return {
                ...state,
                filtrosAprovacao: action.payload
            }
        default:
            return state;
    }
}
