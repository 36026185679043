import React, { Component } from "react";
import { connect } from "react-redux";
import ContainerPage from "../../../common/container/ContainerPage";
import ExportacaoListFilter from "./form/ExportacaoListFilter";
import ExportacaoList from "./list/ExportacaoList";

class ExportacaoPage extends Component {
  render() {
    return (
      <ContainerPage>
        <ExportacaoListFilter />
        <ExportacaoList />
      </ContainerPage>
    );
  }
}

export default connect(null, null)(ExportacaoPage);
