import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from "redux-form";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { FormControl, FormControlLabel, Radio } from '@material-ui/core';
import RadioGroup from "@material-ui/core/RadioGroup";

class RadioGroupList extends React.Component {
  constructor(props) {
    super(props);
    this.handlerChange = this.handlerChange.bind(this);
  }
  handlerChange(event, value) {
    this.props.change(this.props.formName, this.props.input.name, value);
    
    if (typeof this.props.changeAction === 'function') {
      this.props.changeAction();
    }
  };


  render() {
    return (<MuiThemeProvider>
      <FormControl component="fieldset">
        <RadioGroup
          row={this.props.inLine}
          {...this.props.input}
          aria-label={this.props.input.name}
          onChange={(event, value) => this.handlerChange(event, value)}
        >
          {this.props.options.map((option, index) => (
            <FormControlLabel
              key={index}
              label={option.label}
              value={option.value}
              labelPlacement="end"
              control={<Radio color="primary" />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch)
export default connect(null, mapDispatchToProps)(RadioGroupList);

