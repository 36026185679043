/* eslint eqeqeq: 0 */

import FileSaver from "file-saver";
import b64toBlob from "b64-to-blob";

/**
 * @description Exibe o pdf em tela
 * @param {*} blob
 */
export function showFile(blob) {
    let myFile = new Blob([blob], { type: process.env.REACT_APP_PDF });
    let fileURL = window.URL.createObjectURL(myFile);
    window.open(fileURL, "_blank_");
}


/**
 * @description Faz o download de um arquivo Excel com base na string base64 passada.
 * @param {String} base64String
 * @param {String} fileName
 */
export function downloadExcelFile(base64String, fileName) {
    if (base64String && base64String !== "")
        return FileSaver
            .saveAs(new Blob([convertBase64ToArrayBuffer(base64String)],
                { type: "application/octet-stream" }), fileName)
}

export function downloadFile(base64String, fileName) {
    if (base64String && base64String !== "") {
        const mimeType = getMimeTypeByExtension(fileName.split('.')[1]);
        return FileSaver
            .saveAs(new Blob([convertBase64ToArrayBuffer(base64String)],
                { type: mimeType }), fileName);
    }
}

function getMimeTypeByExtension(extension) {
    var mimeType = "";

    if ("jpeg" === extension || "jpg" === extension)
        mimeType = "image/jpeg";
    if ("png" === extension)
        mimeType = "image/png";
    if ("pdf" === extension)
        mimeType = "application/pdf";
    if ("xls" === extension || "xlsx" === extension)
        mimeType = "application/octet-stream";
    if ("zip" == extension)
        mimeType = "application/zip";
    if ("xml" === extension)
        mimeType = "application/xml";

    return mimeType;
}

/**
 * @description Faz o download de um arquivo Txt com base na string base64 passada.
 * @param {String} base64String 
 * @param {String} fileName 
 */
export function downloadTxtFile(base64String, fileName) {
    if (base64String && base64String !== "")
        return FileSaver
            .saveAs(new Blob([convertBase64ToArrayBuffer(base64String)],
                { type: process.env.REACT_APP_TXT }), fileName)
}

function convertBase64ToArrayBuffer(base64) {
    return b64toBlob(base64, "application/octet-stream");
}

export const convertFileToBase64 = (filename, filepath) => new Promise((resolve, reject) => {
    var file = new File([filename], filepath);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
});

export function turnFileToBase64(formData) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      const blob = new Blob([formData], { type: 'multipart/form-data' });
  
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });
}

export const ACCEPT_FILE_OPTIONS = {
    PDF: '.pdf',
    EXCEL: '.xlsx, .xls',
    PDF_E_EXCEL: '.xlsx, .xls, .pdf',
    PDF_EXCEL_IMAGE: '.pdf, .xlsx, .xls, .png, .jpg, .jpeg',
    IMAGE: '.png, .jpg, .jpeg',
    PDF_EXCEL_WORD: '.xlsx, .xls, .pdf, .doc, .docx',
}

export function downloadFileBlob(file, fileName) {    
    const mimeType = getMimeTypeByExtension(fileName.split('.')[1]);

    return FileSaver
        .saveAs(new Blob([file], { type: mimeType }), fileName);
}
