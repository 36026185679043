import axios from "axios";

import { downloadExcelFile } from "./../../utils/helpers/FileHelper";
import { showAllMessages } from "./../../actions/message/ErrorMessagesAPIAction";

import { 
    deserializeFieldsCalculoFormFilter,
    deserializeFieldsPrecoFormFilter,
    deserializeFieldsCalculoFormFilterExportExcel, 
    deserializeFieldsPrecoFormFilterExportExcel,
} from "./../../components/business/precificacao/mappings/PrecificacaoMap";

export const PRECIFICACAO_CALCULO_FETCHED = "PRECIFICACAO_CALCULO_FETCHED";
export const PRECIFICACAO_PRECO_FETCHED = "PRECIFICACAO_PRECO_FETCHED";

const PRECIFICACAO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API + process.env.REACT_APP_PATH_PRECIFICACAO_API;

export function getCalculos(filters, page = 1) {
    let params = deserializeFieldsCalculoFormFilter(filters, page);

    return (dispatch) => {
        return axios
            .post(`${PRECIFICACAO_API}calculos`, { ...params })
            .then(result => 
                dispatch([
                    {
                        type: PRECIFICACAO_CALCULO_FETCHED,
                        payload: result,
                    },
                ])
            );
    }
}  

export function getPrecos(filters, page = 1) {
    let params = deserializeFieldsPrecoFormFilter(filters, page);

    return (dispatch) => {
        return axios
            .post(`${PRECIFICACAO_API}precos`, { ...params })
            .then(result => 
                dispatch([
                    {
                        type: PRECIFICACAO_PRECO_FETCHED,
                        payload: result,
                    },
                ])
            );
    }
}  

export function exportPrecificacaoCalculoToExcel(filters) {
    let params = deserializeFieldsCalculoFormFilterExportExcel(filters);

    let pieces = filters.operacao.label.replaceAll(" ","").split("-");
    let tzoffset = (new Date()).getTimezoneOffset() * 60000;
    let localISOTime = (new Date(Date.now() - tzoffset)).toISOString().replace("T", " - T").slice(0, -1);

    return (dispatch) => {
        return axios
        .post(`${PRECIFICACAO_API}calculos/exportar/excel`, { ...params })
        .then(result =>
            dispatch([
                downloadExcelFile(result.content, `Precificação ${pieces[0]} - ${pieces[1]} - ${localISOTime}.xlsx`), 
                showAllMessages(result.messages), 
            ])
        );
    }
}

export function exportPrecificacaoCalculoToExcelSimplificada(filters) {
    let params = deserializeFieldsCalculoFormFilterExportExcel(filters);

    return (dispatch) => {
        return axios
        .post(`${PRECIFICACAO_API}calculos/exportar/excel/simplificado`, { ...params })
        .then(result =>
            dispatch([
                downloadExcelFile(result.content, 'Memoria_Calculo_'+params.nomeOperacao+'.xlsx'), 
                showAllMessages(result.messages), 
            ])
        );
    }
}



export function getPrecificacaoPrecoToExcel(filters) {
    let params = deserializeFieldsPrecoFormFilterExportExcel(filters);

    return (dispatch) => {
        return axios
        .post(`${PRECIFICACAO_API}precos/exportar/excel`, { ...params })
        .then(result =>
            dispatch([
                downloadExcelFile(result.content, 'Preços Precificação'), 
                showAllMessages(result.messages), 
            ])
        );
    }
}