import { toastr } from "react-redux-toastr";

export const ERROR_MESSAGES_ADD = 'ERROR_MESSAGES_ADD';
export const ERROR_MESSAGE_REMOVE = 'ERROR_MESSAGE_REMOVE';
export const ERROR_MESSAGES_UNATHORIZED_ADD = 'ERROR_MESSAGES_UNATHORIZED_ADD';
export const ERROR_MESSAGE_UNATHORIZED_REMOVE = 'ERROR_MESSAGE_UNATHORIZED_REMOVE';

export function addErrorMessages(messages) {
    return {
        type: ERROR_MESSAGES_ADD,
        payload: messages
    }
}

export function cleanErrorMessages() {
    return {
        type: ERROR_MESSAGES_ADD
    }
}

export function removeErrorMessage(index) {
    return {
        type: ERROR_MESSAGE_REMOVE,
        payload: index
    }
}

export function addErrorMessagesUnathorized(messages) {
    return {
        type: ERROR_MESSAGES_UNATHORIZED_ADD,
        payload: messages
    }
}

export function cleanErrorMessagesUnathorized() {
    return {
        type: ERROR_MESSAGES_UNATHORIZED_ADD,
        payload: []
    }
}

export function removeErrorMessageUnathorized(index) {
    return {
        type: ERROR_MESSAGE_UNATHORIZED_REMOVE,
        payload: index
    }
}

export function showAllMessages(messages) {
    messages.errors.forEach(message => {
        return toastr.error("Erro", message, { showCloseButton: false, closeOnToastrClick: true });
    });

    messages.warnings.forEach(message => {
        return toastr.warning("Atenção", message, { showCloseButton: false, closeOnToastrClick: true });
    });

    messages.successes.forEach(message => {
        return toastr.success("Sucesso", message, { showCloseButton: false, closeOnToastrClick: true });
    });

    messages.infos.forEach(message => {
        return toastr.info("Informação", message, { showCloseButton: false, closeOnToastrClick: true });
    });
}

export function storageAllMessagesNotification(messages, guid) {

    let listMessage = localStorage.getItem('notifications') && localStorage.getItem('notifications').length > 0 ? JSON.parse(localStorage.getItem('notifications')) : [];   

    messages.errors.forEach(message => {
        listMessage.push({nome: "danger", valor: message, key: guid})
    });

    messages.warnings.forEach(message => {
        listMessage.push({nome: "warning", valor: message, key: guid})
    });

    messages.successes.forEach(message => {
        listMessage.push({nome: "success", valor: message, key: guid})
    });

    messages.infos.forEach(message => {
        listMessage.push({nome: "info", valor: message, key: guid})
    });
    
    localStorage.setItem('notifications', listMessage.length > 0 ? JSON.stringify(listMessage) : [])
}

export function showErrorMessages(errors) {
    errors.forEach(message => {
        return toastr.error("Erro", message);
    });
}