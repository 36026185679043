import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const CheckboxInput = ({ input, label, color, disabled, checkedIcon }) => (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
            color={color ? color : 'default'}
            checkedIcon={checkedIcon}
          />
        }
        label={label}
        disabled={disabled}
      />
    </div>
);

export default CheckboxInput;

