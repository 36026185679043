import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../common/container/ContainerPage";

import { resetPagina } from "../../../../actions/cota/CotaAction";
import CotaListFilter from "./form/CotaListFilter";
import CotaList from "./list/CotaList";

class CotaPage extends Component {
  componentDidMount() {
    this.props.resetPagina();
  }

  render() {
    return (
      <ContainerPage>
        <CotaListFilter />
        <CotaList />
      </ContainerPage>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPagina,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CotaPage);
