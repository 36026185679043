import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  FUNDO_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  DETALHAR_ROUTE_PATH,
} from "../../../common/routers/constantesRoutes";

import Fundo from "../pages/Fundo";
import FundoFormInclude from "../pages/form/FundoFormInclude";
import FundoFormUpdate from "../pages/form/FundoFormUpdate";
import FundoFormVisualize from "../pages/form/FundoFormVisualize";

export default () => (
  <Switch>
    <Route exact={true} path={`/${FUNDO_ROUTE_PATH}`} component={Fundo} />
    <Route
      exact={true}
      path={`/${FUNDO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`}
      component={FundoFormInclude}
    />
    <Route
      exact={true}
      path={`/${FUNDO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idFundo`}
      component={FundoFormUpdate}
    />
    <Route
      exact={true}
      path={`/${FUNDO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idFundo`}
      component={FundoFormVisualize}
    />
  </Switch>
);
