/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import {
  exportCustodiantesAssembleiaToExcel,
  exportModeloCustodiantesAssembleia,
  getCustodiantesAssembleia,
  importCustodiantesAssembleia,
} from "../../../../../actions/assembleia/AssembleiaAction";
import FileButton from "../../../../common/input/FileButton";
import { formatStrToCnpjOrCpf } from "../../../../../utils/helpers/StringHelper";
import { newFormatPercent, formatPercent, formatDecimal } from "../../../../../utils/helpers/NumberHelper";

const CustodiantesAssembleiaList = (props) => {
  const {
    readOnly,
    idAssembleia,
    getCustodiantesAssembleia,
    custodiantesAssembleiaData,
    importCustodiantesAssembleia,
    exportModeloCustodiantesAssembleia,
    exportCustodiantesAssembleiaToExcel,
  } = props;

  const fileInputRef = React.useRef(null);

  const arrHeader = [
    "CÓDIGO B3",
    "NOME CUSTODIANTE",
    "CPF/CNPJ CUSTODIANTE",
    "E-MAIL",
    "QTDE",
    "% QTDE",
  ];

  const tableHeader = (
    <tr>
      {arrHeader.filter((item) => item).map((item, i) => (
        <th key={i}>
          <div className="text-center fw-700 d-flex justify-content-center w-100">
            {item}
          </div>
        </th>
      ))}
    </tr>
  );

  const tableContent =
    custodiantesAssembleiaData &&
    custodiantesAssembleiaData.hasItems &&
    custodiantesAssembleiaData.items.map((item, index) => {
      return (
        <tr key={index}>
          <td className="text-center fw-400">{item?.codigoB3 || "--"}</td>
          <td className="text-center fw-400">{item?.nome || "--"}</td>
          <td className="text-center fw-400">{item?.documento ? formatStrToCnpjOrCpf(item?.documento) : "--"}</td>
          <td className="text-center fw-400">{item?.email ? item?.email?.split(";").join("; ") : "--"}</td>
          <td className="text-center fw-400">{item?.quantidade ? formatDecimal(item?.quantidade, 0) : "--"}</td>
          <td className="text-center fw-400">{item?.quantidadePorcentagem ? newFormatPercent(item?.quantidadePorcentagem, 4) : "--"}</td>
        </tr>
      )
    });

  return (
    <div className="container-fluid px-0">
      <div>
        <p className="h3 page-head__title my-3">Custódia</p>
        {!readOnly && (
          <FileButton
            ref={fileInputRef}
            accept=".xls, .xlsx"
            outerClassName="my-1"
            label="Anexar Controle de Custódia"
            onChange={async (e) => {
              await importCustodiantesAssembleia(e.target.files[0], idAssembleia)
              getCustodiantesAssembleia(idAssembleia)
              fileInputRef.current.value = ''
            }}
            className="default-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 w-mx-cnt"
          />
        )}

        <div>
          {custodiantesAssembleiaData?.totalCount > 0 ? (
            <Table
              withoutHeader
              files={[
                { label: 'Baixar Modelo de Importação', function: () => exportModeloCustodiantesAssembleia(idAssembleia) },
                { label: 'Exportar para Excel', function: () => exportCustodiantesAssembleiaToExcel(idAssembleia) }
              ]}            
              classes="shadow-none col-12 my-1 pd-10 bg-white"
              totalItems={custodiantesAssembleiaData.totalCount}
              pagination={
                <PaginationRB
                  pageNumber={custodiantesAssembleiaData.pageIndex - 1}
                  itemsPerPage={custodiantesAssembleiaData.pageSize}
                  totalItems={custodiantesAssembleiaData?.totalCount}
                  filter={idAssembleia}
                  fetchFunction={getCustodiantesAssembleia}
                  pageRange={5}
                />
              }
            >
              <TableHeader>{tableHeader}</TableHeader>
              <TableContent>{tableContent}</TableContent>
            </Table>
          ) : (
            <label
              role="button"
              className="p-color-lighten mt-3 mb-0"
              onClick={() => exportModeloCustodiantesAssembleia(idAssembleia)}
            >
              Baixar Modelo de Importação
            </label> 
          ) }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  custodiantesAssembleiaData: state.assembleiaReducer.custodiantesAssembleia,
  messages: state.assembleiaReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getCustodiantesAssembleia,
      importCustodiantesAssembleia,
      exportModeloCustodiantesAssembleia,
      exportCustodiantesAssembleiaToExcel,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(CustodiantesAssembleiaList);
