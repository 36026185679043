import {
    LOAD_FILTER_FORM_ASSEMBLEIA,
    ASSEMBLEIAS_FETCHED,
    LOAD_FORM_ASSEMBLEIA,
    ASSEMBLEIAS_CONSULT_CURRENT,
    SAVE_FORM_ASSEMBLEIA_ERRORS,
    ORDENS_ASSEMBLEIA_FETCHED,
    INVESTIDORES_ASSEMBLEIA_FETCHED,
    RESUMO_VOTOS_ASSEMBLEIA_FETCHED,
    CUSTODIANTES_ASSEMBLEIA_FETCHED,
    LOAD_MODAL_ORDENS_ASSEMBLEIA,
    LOAD_VOTOS_INVESTIDORES_ASSEMBLEIA,
    CHANGE_LIST_OPERACOES,
} from "../../actions/assembleia/AssembleiaAction";

const INITIAL_STATE = {
    assembleias: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    ordensAssembleia: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    investidoresAssembleia: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    resumoVotosAssembleia: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    custodiantesAssembleia: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    modalOrdem: {},
    votosInvestidor: [],
    assembleiaFilter: {},
    assembleiaForm: {},
    messages: null,
}

export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case ASSEMBLEIAS_FETCHED:
            return {
                ...state,
                assembleias: action.payload.result.content,
            }
        case ORDENS_ASSEMBLEIA_FETCHED:
            return {
                ...state,
                ordensAssembleia: action.payload,
            }
        case INVESTIDORES_ASSEMBLEIA_FETCHED:
            return {
                ...state,
                investidoresAssembleia: action.payload,
            }
        case RESUMO_VOTOS_ASSEMBLEIA_FETCHED:
            return {
                ...state,
                resumoVotosAssembleia: action.payload,
            }
        case CUSTODIANTES_ASSEMBLEIA_FETCHED:
            return {
                ...state,
                custodiantesAssembleia: action.payload,
            }
        case SAVE_FORM_ASSEMBLEIA_ERRORS:
            return {
              ...state,
              messages: action.payload
            }
        case LOAD_FORM_ASSEMBLEIA:
            return {
                ...state,
                assembleiaForm: action.payload,
            }
        case CHANGE_LIST_OPERACOES:
            return {
              ...state,
              operacoesList: action.payload,
            };
        case LOAD_FILTER_FORM_ASSEMBLEIA:
            return {
                ...state,
                assembleiaFilter: action.payload
            }
        case LOAD_MODAL_ORDENS_ASSEMBLEIA:
            return {
                ...state,
                modalOrdem: action.payload
            }
        case LOAD_VOTOS_INVESTIDORES_ASSEMBLEIA:
            return {
                ...state,
                votosInvestidor: action.payload
            }
        case ASSEMBLEIAS_CONSULT_CURRENT:
            return {
                ...state,
                assembleiasConsultCurrent: action.payload,
                isLoading: true,
            };
        default:
            return state;
    }
}
