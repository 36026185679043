/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import GenericPickerInput from "../../common/input/GenericPickerInput";

import { 
    getOperacoes, 
    selectedOperacaoPrecificacaoCalculoOption, 
} from "../../../actions/operacao/OperacaoAction";

const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");
const gestora = currEnv == process.env.REACT_APP_ENV_GEST_ID;

const OperacaoPrecificacaoCalculoPicker = (props) => (
    <div className="">
        <div>
            <GenericPickerInput
                loadData={props.getOperacoes}
                options={props.options}
                selectedOptionForm={props.selectedOperacaoPrecificacaoCalculoOption}
                isMulti={false}
                placeholder={`${gestora ? 'Pesquisar fundo ex.: FIDC Abc' : 'Pesquisar operação ex.:CRI.1.SEC.2'}`}
                loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                noOptionsMessage="Nenhum registro encontrado"
                value={props.operacoes}
            />
        </div>
    </div>
);

const mapDispatchToProps = dispatch => bindActionCreators({
    getOperacoes,
    selectedOperacaoPrecificacaoCalculoOption,
}, dispatch);

const mapStateToProps = state => ({
  options: state.operacaoReducer.operacoes,
  operacoes: state.operacaoReducer.selectedOperacoesPrecificacao,
});

export default connect(mapStateToProps, mapDispatchToProps)(OperacaoPrecificacaoCalculoPicker);