import {
    LOAD_FILTER_FORM_INDICE_TIPO,
    REPORT_GETALL,
    SHOW_GERAR_REPORT_DIALOG,
    CLEAR_REPORT,
    CLEAR_SELECTEDS,
    GENERATE_REPORT_IDS,
} from "../../actions/relatorios/reportAction";

const INITIAL_STATE = {
    listReport: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    relatorios: [],
    tiposFormTypes: {},
    showGerarReportDialog: false,
    messages: null,
    isLoading: false,
    selectedsCount: 0,
}

export default function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case LOAD_FILTER_FORM_INDICE_TIPO:
            return {
                ...state,
                tiposFormTypes: action.payload
            }
        case REPORT_GETALL:
            return {
                ...state,
                listReport: action.payload.content,
                messages: action.payload.messages,
                isLoading: true,                
            }
        case CLEAR_SELECTEDS: {
            return {
                ...state,
                selectedsCount: state.selectedsCount += 1,
            }
        }
        case CLEAR_REPORT: {
            return {
                ...state,
                listReport: {},
            }
        }
        case GENERATE_REPORT_IDS: {
            return {
                ...state,
                relatorios: action.payload,
            }
        }
        case SHOW_GERAR_REPORT_DIALOG:
          return {
              ...state,
              showGerarReportDialog: action.payload
          }
        default:
            return state;
    }
}