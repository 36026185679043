/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "../../../../../common/button/Button";
import Table from "../../../../../common/table/Table";
import TableContent from "../../../../../common/table/TableContent";
import TableHeader from "../../../../../common/table/TableHeader";
import ModalConfirmaEnvioCamara from "../modals/ModalConfirmaEnvioCamara";
import { exportaArquivoCamaraPagamento } from "../../../../../../actions/precificacao/PagamentoAction";
import { convertCurrencyNumberNew } from "../../../../../../utils/helpers/NumberHelper";
import { getDateTimeString } from "../../../../../../utils/helpers/DateTimeHelper";
import { downloadExcelFile } from "../../../../../../utils/helpers/FileHelper";

let SectionEtapaLancamento = (props) => {
  const { pagamento, exportaArquivoCamaraPagamento } = props;
  const [openConfirmaEnvioCamara, setOpenConfirmaEnvioCamara] =
    React.useState(false);

  const statusId = pagamento?.ultimaEtapa?.id;
  const camaras = pagamento?.lancamentoCamaraLiquidacao?.pagamentos;
  const existeExtra =
    pagamento?.lancamentoCamaraLiquidacao?.existePagamentoExtraordinario;

  return (
    <div className="card-body shadow-custom bg-white rounded-12 col-12 mt-4">
      <h4 className="page-head__title h3 mx-0 mt-0 mb-3 f-22 font-weight-bold">
        Etapa - Lançamento
      </h4>

      {existeExtra && statusId == 4 && (
        <div className="rounded-8 w-100 px-3 py-2 bg-bege-wrng mb-2">
          <p className="m-0 font-weight-bold text-danger">Atenção!</p>
          <p className="my-2 fw-400">
            Há eventos extraordinários que devem ser cadastrados na Câmara de
            Liquidação.
          </p>
          <div className="row fw-400">
            <div className="col-lg-3">
              <p className="mb-0 text-gray">
                Amex (R$){" "}
                <span className="text-dark">
                  {convertCurrencyNumberNew(
                    pagamento?.lancamentoCamaraLiquidacao?.valorAmex
                  )}
                </span>
              </p>
            </div>
            <div className="col-lg-3">
              <p className="mb-0 text-gray">
                Evento Genérico (R$){" "}
                <span className="text-dark">
                  {convertCurrencyNumberNew(
                    pagamento?.lancamentoCamaraLiquidacao?.valorEventoGenerico
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
      )}

      {camaras?.length > 0 && (
        <div className="row px-0">
          <Table
            withoutHeader
            classesOuter="w-100"
            classes="shadow-none border-0 col-12 m-0 pd-10 bg-white"
          >
            {/* prettier-ignore */}
            <TableHeader>
                <tr>
                  <th className="text-center text-nowrap">TIPO</th>
                  <th className="text-center text-nowrap">CÂMARA LIQUIDAÇÃO</th>
                  <th className="text-center text-nowrap">DATA/HORA</th>
                  <th className="text-center text-nowrap">STATUS SINC.</th>
                  <th className="text-center text-nowrap">DESCRIÇÃO</th>
                  <th className="text-center text-nowrap"></th>
                </tr>
              </TableHeader>
            <TableContent>
              {camaras.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center fw-400 text-nowrap">
                      {item?.tipo?.value || "--"}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.camaraLiquidacao || "--"}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.dataLancamento || "--"}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item?.statusSincronizacao?.value || "--"}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item.descricao || "--"}
                    </td>
                    <td className="text-center fw-400 text-nowrap">
                      {item?.tipo?.key === 1 && (
                        <Button
                          name="Baixar Arquivo"
                          icon="download f-14-imp"
                          action={() => {
                            exportaArquivoCamaraPagamento({
                              tipoArquivo: "txt",
                              idArquivo: item?.idArquivo,
                              codigoOpea: pagamento?.codigoOpea,
                              dataPagamento: pagamento?.dataPagamento,
                              idPrecificacao: pagamento?.idPrecificacao,
                              idPagamentoPrecificacao:
                                pagamento?.idPagamentoPrecificacao,
                            });
                          }}
                          classes="btn-out-df-imp rounded btn-search h-22 f-12 shadown-none px-2 py-1 m-0 text-capitalize fw-500"
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </TableContent>
          </Table>
        </div>
      )}

      <div className="row px-3 mt-3 mb-1">
        <div className="col-lg-6 pl-0 pr-lg-3 pr-0 mb-lg-0 mb-2">
          <p className="mb-0 text-gray fw-400 f-10-5">Agente de Pagamento</p>
          <p className="mb-0 fw-400">
            {pagamento?.agentePagamento?.value || "--"}
          </p>
        </div>
      </div>

      {statusId >= 5 && (
        <>
          <p className="page-head__title mt-3 mb-1">Comprovação Manual</p>
          <div className="row px-3">
            <div className="col-lg-6 pl-0 pr-lg-3 pr-0 mb-lg-0 mb-3">
              <p className="mb-0 text-gray fw-400 f-10-5">Data</p>
              <p className="mb-0 fw-400">
                {getDateTimeString(pagamento?.dataEnvioComprovanteCamaraLiquidacao) || "--"}
              </p>
            </div>
            <div className="col-lg-6 px-0">
              <p className="mb-0 text-gray fw-400 f-10-5">Comprovante</p>
              {pagamento?.base64ArquivoComprovanteCamaraLiquidacao ? (
                <span
                  role="button"
                  className="txt-blue-link text-underline"
                  onClick={() =>
                    downloadExcelFile(
                      pagamento?.base64ArquivoComprovanteCamaraLiquidacao,
                      pagamento?.nomeArquivoComprovanteCamaraLiquidacao
                    )
                  }
                >
                  <span className="mr-2">
                    {pagamento?.nomeArquivoComprovanteCamaraLiquidacao}
                  </span>
                  <i className="fa fa-download f-14"></i>
                </span>
              ) : (
                <span>--</span>
              )}
            </div>
          </div>
        </>
      )}

      {statusId == 4 && (
        <div className="row px-3 d-flex justify-content-lg-end mt-3">
          <Button
            name={"Confirmar Envio"}
            action={() => setOpenConfirmaEnvioCamara(true)}
            classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
          />
          <ModalConfirmaEnvioCamara
            open={openConfirmaEnvioCamara}
            close={() => setOpenConfirmaEnvioCamara(false)}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pagamento: state.pagamentoReducer.pagamentoFormTypes,
  statusId: state.pagamentoReducer.pagamentoFormTypes,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      exportaArquivoCamaraPagamento,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(SectionEtapaLancamento);
