/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";
import {
  changeInvestidorCheck,
  exportInvestidoresAssembleiaToExcel,
  exportModeloInvestidoresAssembleia,
  exportModeloInvestidoresVotoAssembleia,
  getInvestidoresAssembleia,
  importInvestidoresAssembleia,
  importInvestidoresVotoAssembleia,
  getResumoVotosAssembleia,
} from "../../../../../actions/assembleia/AssembleiaAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import FileButton from "../../../../common/input/FileButton";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import CheckBox from "@material-ui/icons/CheckBox";
import ModalVotosInvestidor from "../modals/ModalVotosInvestidor";
import { formatStrToCnpjOrCpf } from "../../../../../utils/helpers/StringHelper";
import { formatDecimal, newFormatPercent } from "../../../../../utils/helpers/NumberHelper";

const InvestidoresAssembleiaList = (props) => {
  const {
    readOnly,
    statusValue,
    idAssembleia,
    getInvestidoresAssembleia,
    investidoresAssembleiaData,
    importInvestidoresAssembleia,
    importInvestidoresVotoAssembleia,
    getResumoVotosAssembleia,
    changeInvestidorCheck,
    exportModeloInvestidoresAssembleia,
    exportModeloInvestidoresVotoAssembleia,
    exportInvestidoresAssembleiaToExcel,
  } = props;

  const fileInputRef = React.useRef(null);

  const [modalVotos, setModalVotos] = React.useState(false);
  const [refreshList, setRefreshList] = React.useState(0);

  const CheckboxInput = ({ checked, onChange, label, checkedIconStyle = { color: readOnly ? '#00000054' : "#630a37" }, key }) => {
    return (
      <FormControlLabel
        className="mt-1 mx-0"
        key={key}
        disabled={readOnly}
        labelPlacement={undefined}
        control={
          <Checkbox
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            checkedIcon={<CheckBox style={checkedIconStyle} />}
            disabled={readOnly}
          />
        }
        label={label}
      />
    );
  };

  const arrHeader = [
    "CÓDIGO B3",
    "NOME",
    "CPF/CNPJ",
    "E-MAIL",
    "QTDE",
    "% QTDE",
    "PARTE RELACIONADA",
    "DOCUMENTAÇÃO",
    "PRESENÇA",
    statusValue >= 3 ? 'STATUS VOTO' : null,
  ];

  const tableHeader = (
    <tr>
      {arrHeader.filter((item) => item).map((item, i) => (
        <th key={i}>
          <div className="text-center fw-700 d-flex justify-content-center w-100">
            {item}
          </div>
        </th>
      ))}
    </tr>
  );

  const handleInvestidorCheck = async (idItem, index, key, value) => {
    investidoresAssembleiaData.items[index][key] = value;
    setRefreshList((prev) => prev + 1);
    
    try {
      await changeInvestidorCheck(idAssembleia, key, idItem)
    } catch (error) {
      investidoresAssembleiaData.items[index][key] = !value;
      setRefreshList((prev) => prev + 1);
    }
  };

  const tableContent =
    investidoresAssembleiaData &&
    investidoresAssembleiaData.hasItems &&
    investidoresAssembleiaData.items.map((item, index) => {
      return (
        <tr key={`list-${refreshList}-${index}`}>
          <td className="text-center fw-400">{item?.codigoB3 || "--"}</td>
          <td className="text-center fw-400">{item?.nome || "--"}</td>
          <td className="text-center fw-400">{item?.documento ? formatStrToCnpjOrCpf(item?.documento) : "--"}</td>
          <td className="text-center fw-400">{item?.email ? item?.email.split(";").join("; ") : "--"}</td>
          <td className="text-center fw-400">{item?.quantidade ? formatDecimal(item?.quantidade, 0) : "--"}</td>
          <td className="text-center fw-400">{item?.quantidadePorcentagem ? newFormatPercent(item?.quantidadePorcentagem, 4) : "--"}</td>
          <td className="text-center fw-400">{item?.parteRelacionada ? "Sim" : "Não"}</td>
          <td className="text-center fw-400">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <CheckboxInput
                checked={item.documentacao}
                onChange={(v) => handleInvestidorCheck(item.id, index, 'documentacao', v)}
              />
            </div>
          </td>
          <td className="text-center fw-400">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <CheckboxInput
                checked={item.presenca}
                onChange={(v) => handleInvestidorCheck(item.id, index, 'presenca', v)}
              />
            </div>
          </td>

          {(statusValue >= 3) && (
            <td className="text-center fw-400">
              <div className="d-flex flex-row align-items-center justify-content-center">
                <CheckboxInput
                  checked={item.voto}
                  onChange={(v) => handleInvestidorCheck(item.id, index, 'voto', v)}
                />
                
                <FontAwesomeIcon
                  color="#630a37"
                  size="lg"
                  className="mx-1 mb-1"
                  icon={faAlignJustify}
                  role="button"
                  onClick={() => setModalVotos(item)}
                />
              </div>
            </td>
          )}
        </tr>
      )
    });

  const downloadModelo = () => {
    if (statusValue >= 3) {
      exportModeloInvestidoresVotoAssembleia(idAssembleia)
    } else {
      exportModeloInvestidoresAssembleia(idAssembleia)
    }
  }

  const uploadImport = async (e) => {
    if (statusValue >= 3) {
      await importInvestidoresVotoAssembleia(e.target.files[0], idAssembleia)
      getResumoVotosAssembleia(idAssembleia)
    } else {
      await importInvestidoresAssembleia(e.target.files[0], idAssembleia)
    }
  }

  return (
    <div className="container-fluid px-0">
      <div>
        <p className="h3 page-head__title my-3">Investidores</p>
        {!readOnly && (
          <FileButton
            ref={fileInputRef}
            accept=".xls, .xlsx"
            outerClassName="my-1"
            label="Anexar Controle de Investidores"
            onChange={async (e) => {
              await uploadImport(e)
              getInvestidoresAssembleia(idAssembleia)
              fileInputRef.current.value = ''
            }}
            className="default-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 w-mx-cnt"
          />
        )}

        <div>
          {investidoresAssembleiaData?.totalCount > 0 ? (
            <Table
              withoutHeader
              files={[
                { label: 'Baixar Modelo de Importação', function: () => downloadModelo() },
                { label: 'Exportar para Excel', function: () => exportInvestidoresAssembleiaToExcel(idAssembleia) }
              ]}            
              classes="shadow-none col-12 my-1 pd-10 bg-white"
              totalItems={investidoresAssembleiaData.totalCount}
              pagination={
                <PaginationRB
                  pageNumber={investidoresAssembleiaData.pageIndex - 1}
                  itemsPerPage={investidoresAssembleiaData.pageSize}
                  totalItems={investidoresAssembleiaData?.totalCount}
                  filter={idAssembleia}
                  fetchFunction={getInvestidoresAssembleia}
                  pageRange={5}
                />
              }
            >
              <TableHeader>{tableHeader}</TableHeader>
              <TableContent>{tableContent}</TableContent>
            </Table>
          ) : (
            <label
              role="button"
              className="p-color-lighten mt-3 mb-0"
              onClick={() => downloadModelo() }
            >
              Baixar Modelo de Importação
            </label> 
          ) }
        </div>

        {/* MODAL VOTOS INVESTIDOR */}
        {modalVotos && (
          <ModalVotosInvestidor
            readOnly={readOnly}
            open={Boolean(modalVotos)}
            investidor={modalVotos}
            idAssembleia={idAssembleia}
            close={() => setModalVotos(false)}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  investidoresAssembleiaData: state.assembleiaReducer.investidoresAssembleia,
  messages: state.assembleiaReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getInvestidoresAssembleia,
      importInvestidoresAssembleia,
      importInvestidoresVotoAssembleia,
      getResumoVotosAssembleia,
      changeInvestidorCheck,
      exportModeloInvestidoresAssembleia,
      exportModeloInvestidoresVotoAssembleia,
      exportInvestidoresAssembleiaToExcel,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(InvestidoresAssembleiaList);
