import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import AprovadoresForm from "./form/AprovadoresForm";

import {
  loadFormAprovadores,
  editAprovador,
} from "../../../../actions/aprovadores/AprovadoresAction";

class AprovadoresUpdatePage extends Component {
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("userLogged"));
    let acessosUser = user.acessos;
    let permitido = acessosUser.some((acesso) => acesso === 1);
    
    if (permitido) {
      let idAprovador = this.props.match.params.idAprovador;
      this.props.loadFormAprovadores(idAprovador);
    }
  }

  render() {
    const { editAprovador } = this.props;

    return (
      <AprovadoresForm
        title={"Editar - Aprovador"}
        onSubmit={editAprovador}
        readOnly={false}
        readOnlyField={true}
        floatButtonVisible={true}
      />
    );
  }
}

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      editAprovador,
      loadFormAprovadores,
    },
    dispatch
  );

export default withRouter(
  connect(null, mapDispacthToProps)(AprovadoresUpdatePage)
);
