import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import {
    showDespesaForm,
    getDespesas,
    removeDespesa,
    showDespesaDeleteDialog,
    loadFormDespesa,
    goToFormPage
} from '../../../../../../actions/operacao/DespesaAction';

import Table from '../../../../../common/table/Table';
import TableHeader from '../../../../../common/table/TableHeader';
import TableContent from '../../../../../common/table/TableContent';
import FloatButton from '../../../../../common/button/FloatButton';
import If from '../../../../../common/If/If';
import TableEmptyData from '../../../../../common/table/TableEmptyData';
import PaginationRB from '../../../../../common/pagination/PaginationRB';
import ConfirmDialog from '../../../../../common/modal/ConfirmDialog';
import TableActionWithRouteAuthorization from '../../../../../common/table/TableActionWithRouteAuthorization';
import { authorizationFunction } from '../../../../../../actions/authorization/AuthorizationAction';
import { OPERACAO_DESPESAS, CREATE, DELETE } from '../../../../../../utils/actionsAndFeaturesAuth';
import { getDateFormatedFromString } from '../../../../../../utils/helpers/DateTimeHelper';
import { convertCurrencyNumber } from '../../../../../../utils/helpers/NumberHelper';
import { Field, reduxForm } from 'redux-form';
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import Input from "../../../../../common/input/Input";
import Button from "../../../../../common/button/Button";
import ModalAction from '../../../../../common/modal/ModalAction';

class DespesaList extends Component {
    getIdsOperacao = () => {
      let idsOperacao = 0;

      if (Array.isArray(this.props.operacoes) && this.props.operacoes.length) {
        idsOperacao = this.props.operacoes.map((o) => o.key);
      }

      return idsOperacao;
    };

    updateData = () => {
      this.props.getDespesas(this.getIdsOperacao());
    };

    componentDidMount() {
      this.updateData();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.operacoes.length !== this.props.operacoes.length) {
        this.updateData();
      }
    }

    render() {
        const {
            operacoes,
            despesaData,
            messages,
            removeDespesa,
            showDespesaForm,
            getDespesas,
            showDespesaDeleteDialog,
            showDeleteDialog,
            goToFormPage,
            authorizationFunction
        } = this.props;

        const idsOperacoes = operacoes.map(o => o.key);
        const emptyData = messages && messages.infos && messages.infos[0];

        const tableHeader = (
            <tr>
                <th className='text-center fw-700'>Cód. Opea</th>
                <th className='text-center fw-700'>Produto</th>
                <th className='text-center fw-700'>Fornecedor</th>
                <th className='text-center fw-700'>Data Último PMT</th>
                <th className='text-center fw-700'>Valor Último PMT</th>
                <th className='text-center fw-700'>Data Próximo PMT</th>
                <th className='text-center fw-700'>Origem Recursos</th>
                <th className='text-center fw-700'>Status</th>
                <th className="text-center"></th>
            </tr>
        );

        const tableContent = (
            despesaData && despesaData.hasItems
                ? despesaData.items.map(despesa =>
                    (<tr key={despesa.idDespesa}>
                        <td className='text-center'>{despesa?.codigoOpea || '--'}</td>
                        <td className='text-center'>{despesa?.tipoDespesa || '--'}</td>
                        <td className='text-center'>{despesa?.fornecedor || '--'}</td>
                        <td className='text-center'>{despesa.dataUltimoPMT ? getDateFormatedFromString(despesa?.dataUltimoPMT) : '--'}</td>
                        <td className='text-center'>{despesa?.valorUltimoPMT ? convertCurrencyNumber(despesa?.valorUltimoPMT) : '--'}</td>
                        <td className='text-center'>{despesa?.dataProximoPMT ? getDateFormatedFromString(despesa?.dataProximoPMT) : '--'}</td>
                        <td className='text-center'>{despesa?.origemRecurso || '--'}</td>
                        <td className='text-center'>{despesa?.status?.value || '--'}</td>
                        <TableActionWithRouteAuthorization
                            space="2"
                            feature={OPERACAO_DESPESAS}
                            updateAction={() => goToFormPage(true, despesa.idDespesa)}
                            viewAction={() => goToFormPage(false, despesa.idDespesa)}
                            remove={() => showDespesaDeleteDialog(true, despesa.idDespesa)}
                        />
                    </tr>)
                )
                : <TableEmptyData message={emptyData} />
        );

        return (
            <div>
                <div className="mx-2 shadow-custom bg-white rounded-12 py-1 d-flex flex-lg-row flex-column align-items-center px-lg-0 mb-3">
                  <div className="col-lg-5">
                    <Field
                      component={DropdownListInput}
                      inputId="status"
                      name="status"
                      label="Status"
                      textField="description"
                      valueField="key"
                      options={this.props.statusList}
                    />
                  </div>
                  <div className="col-lg-5 px-lg-0">
                    <Field
                      component={Input}
                      inputId="identificador"
                      name="identificador"
                      label="Identificador"
                      options={this.props.statusList}
                    />
                  </div>
                  <div className="col-lg-2 pb-lg-0 pb-3 mb-2p">
                    <Button
                      classes="rounded text-white p-bg-imp mb-0 h-38 px-4 py-1 w-100"
                      action={() =>
                        authorizationFunction({ idAcao: CREATE, idFuncionalidade: OPERACAO_DESPESAS }, () => this.updateData())
                      }
                      name="Buscar"
                    />
                  </div>
                </div>
                {despesaData && despesaData.hasItems && (
                    <div className="d-flex flex-column">
                        <div className="mx-2 shadow-custom bg-white rounded-12">
                            <p className="page-head__title f-28 mb-0 px-3 mt-4 mb-3">Despesas das Operações</p>
                            <Table
                                withoutHeader
                                classes="card col-12 shadow-none bg-white py-0 m-0 mb-2 rounded-12"
                                totalItems={despesaData.totalCount}
                                pagination={
                                    <PaginationRB
                                        pageNumber={despesaData.pageIndex - 1}
                                        itemsPerPage={despesaData.pageSize}
                                        totalItems={despesaData.totalCount}
                                        filter={idsOperacoes}
                                        fetchFunction={getDespesas}
                                        pageRange={5}
                                    />
                                }
                            >
                                <TableHeader >
                                    {tableHeader}
                                </TableHeader>
                                <TableContent>
                                    {tableContent}
                                </TableContent>
                            </Table>
                        </div>
                    </div>
                )}

                <If test={operacoes && operacoes.length === 1}>
                    <FloatButton
                        activeClassName={"float"}
                        title="Novo"
                        icon={"fa fa-plus my-float"}
                        action={
                            () => authorizationFunction(
                                { idAcao: CREATE, idFuncionalidade: OPERACAO_DESPESAS },
                                () => showDespesaForm(operacoes[0].key)
                            )
                        }
                    />
                </If>

                <ModalAction
                    title="Aviso"
                    open={showDeleteDialog.showDialog}
                    close={() => showDespesaDeleteDialog(false)}
                    message="Excluindo despesa selecionada"
                    action={() =>
                        authorizationFunction(
                        { idAcao: DELETE, idFuncionalidade: OPERACAO_DESPESAS },
                        () => removeDespesa(showDeleteDialog.idDespesa, idsOperacoes)
                        )
                    }
                />
            </div>
        );
    }
}

DespesaList = reduxForm({ 
    form: "despesaListForm",
    initialValues: { "status" : { description: 'Validação Pendente', key: 2 } }
})(DespesaList);

const mapStateToProps = state => ({
    showDeleteDialog: state.despesaReducer.showDespesaDeleteDialog,
    despesaData: state.despesaReducer.despesas,
    messages: state.despesaReducer.messages,
    operacoes: state.operacaoReducer.selectedOperacoes,
    statusList: [
        { description: 'Cadastro Pendente', key: 1 },
        { description: 'Validação Pendente', key: 2 },
        { description: 'Aprovacao Pendente', key: 3 },
        { description: 'Aprovado', key: 4 },
        { description: 'Revisado', key: 5 },
      ]
});
const mapDispacthToProps = dispatch => bindActionCreators({
    getDespesas,
    removeDespesa,
    showDespesaForm,
    showDespesaDeleteDialog,
    loadFormDespesa,
    authorizationFunction,
    goToFormPage,
    change
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(DespesaList));