import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Line } from 'react-chartjs-2';
import moment from 'moment';

class DiarioDeBordoChart extends Component {
    render() {
        const {
            tipoDiarioBordoChartList,
            diarioDeBordoChartData,
        } = this.props;

        const groupedDataSorted = {};
        let tipoDiarioBordoCorList = [];

        if (tipoDiarioBordoChartList && tipoDiarioBordoChartList.length !== 0 && diarioDeBordoChartData && diarioDeBordoChartData.length !== 0) {
            // Criação de um objeto inicial agrupando por categorias
            let minDate = moment().subtract(12, 'months').format('YYYYMM');
            const maxDate = moment().format('YYYYMM');
            const initialGroupedData = tipoDiarioBordoChartList.map(item => item.descricao).reduce((acc, item) => {
                return {
                    ...acc,
                    [item]: {},
                }
            }, {});
            
            tipoDiarioBordoCorList = tipoDiarioBordoChartList.map(item => item.cor);

            // Injeta dados nulos quando algum dado está faltando em algum período
            while (minDate <= maxDate) {
                Object.keys(initialGroupedData).forEach(key => {
                    initialGroupedData[key][minDate] = 0;
                });

                minDate = moment(minDate, 'YYYYMM').add(1, 'months').format('YYYYMM');
            }

            // Agrupamento dos dados de acordo com as categorias
            const groupedData = diarioDeBordoChartData.reduce((accumulator, item) => {
                accumulator[item.tipoEvento][item.mesAnoOcorrencia] = item.quantidade;
                return accumulator;
            }, initialGroupedData);

            // Ordenação dos dados por data
            Object.keys(groupedData).forEach((key) => {
                const orderedObj = Object.keys(groupedData[key]).sort().reduce(
                    (acc, date) => { 
                        acc[date] = groupedData[key][date]; 
                        return acc;
                    }, 
                    {}
                );

                groupedDataSorted[key] = orderedObj;
            });
        }

        const unformattedLabels = Object.keys(groupedDataSorted).length > 0
            ? Object.keys(groupedDataSorted[Object.keys(groupedDataSorted)[0]])
            : [];

        const labels = unformattedLabels.map(dateStr => moment(dateStr, 'YYYYMM').format('MMM/YY'));

        let datasetCount = 0;
        const datasets = Object.keys(groupedDataSorted).map(key => {
            datasetCount += 1;
            return {
                label: key,
                data: Object.values(groupedDataSorted[key]),
                fill: false,
                backgroundColor: tipoDiarioBordoCorList[datasetCount-1],
                borderColor: tipoDiarioBordoCorList[datasetCount-1] + '65',
            };
        });

        const data = {
            labels: labels,
            datasets: datasets,
        };
        
        const options = {
            aspectRatio: 1.8,
            devicePixelRatio: 4,
            scales: {
                y: {
                    beginAtZero: true,
                    grace: '1%',
                    ticks: {
                        precision: 0,
                    },
                },
            },
            interaction: {
                mode: 'index',
                intersect: false,
            },
            plugins: {
                tooltip: {
                    position: 'nearest',
                },
                legend: {
                    labels: {
                        boxWidth: 12,
                    },
                },
            },
        };

        const LineChart = () => (
            <>
                <div className='header'>
                    <h1 className='chart-title fw-400'>Diário de Bordo</h1>
                </div>
                <Line type="line" data={data} options={options} />
            </>
        );
          

        return (
            <div className="shadow-custom rounded-12 p-3 bg-white">
                <div className="internal-aspect-ratio">
                    <LineChart />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tipoDiarioBordoChartList: state.dashboardReducer.tipoDiarioBordoChartList,
    diarioDeBordoChartData: state.dashboardReducer.diarioDeBordoChartData,
});

export default withRouter(connect(mapStateToProps)(DiarioDeBordoChart));
