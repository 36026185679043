import React, { Component } from 'react';
import EventoDiarioBordoCard from './EventoDiarioBordoCard';
import { Collapse, Fade } from '@material-ui/core';
import '../assets/ItemsAnualDiarioBordoList.css';
import { Grid, IconButton } from '@material-ui/core';
export class ItemsAnualCollapseDiarioBordo extends Component {

    constructor(props) {
        super(props);
        this.state = { isOpened: true };

        // Aqui utilizamos o `bind` para que o `this` funcione dentro da nossa callback
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState(state => ({
            isOpened: !state.isOpened
        }));
    }


    render() {
        const { isOpened } = this.state;

        return (
            <div className="row mx-0 logbook__timeline-item">
                <div className="col px-0">
                    <div className="row mx-0 logbook__timeline-item-header" onClick={this.handleClick}>
                        <div>
                            <IconButton className="logbook__timeline-btn">
                                {isOpened ? '-' : '+'}
                            </IconButton>
                        </div>
                        <div className="col d-flex align-items-center logbook__timeline-btn-title">
                            <div className="mr-4"><b>{this.props.diario.ano}</b></div>
                            <div className="logbook__timeline-btn-horizontal-line"></div>
                        </div>
                    </div>
                    <div className="row mx-0 logbook__timeline-item-content">
                        <div className={`logbook__timeline-item-greenline ${isOpened ? '' : 'hide'}`}>
                            <div className="h-100"></div>
                        </div>
                        <div className={`col pt-3 logbook__timeline-cards-area ${isOpened ? '' : 'hide'}`}>
                            <Collapse in={isOpened} timeout={500}>
                                <Fade in={isOpened} timeout={500}>
                                    <Grid container spacing={0}>
                                        {this.props.diario.lista.map((item, index) =>
                                            (
                                                <Grid item sm={12} md={6} lg={4} xl={2} key={index}>
                                                    <EventoDiarioBordoCard 
                                                        diarioBordoAtual={item}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                </Fade>
                            </Collapse>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ItemsAnualCollapseDiarioBordo