import React from 'react';
import { Route, Switch } from "react-router-dom";
 
import { 
    ATIVO_ROUTE_PATH, 
    NOVO_ROUTE_PATH, 
    EDITAR_ROUTE_PATH, 
    DETALHAR_ROUTE_PATH 
} from "../../../common/routers/constantesRoutes";

import Ativo from '../pages/Ativo';
import AtivoFormInclude from '../pages/form/AtivoFormInclude';
import AtivoFormUpdate from '../pages/form/AtivoFormUpdate';
import AtivoFormVisualize from '../pages/form/AtivoFormVisualize';

export default () => (
    <Switch>
        <Route exact={true} path={`/${ATIVO_ROUTE_PATH}`} component={Ativo} />
        <Route exact={true} path={`/${ATIVO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={AtivoFormInclude} />
        <Route exact={true} path={`/${ATIVO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idAtivo`} component={AtivoFormUpdate} />
        <Route exact={true} path={`/${ATIVO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idAtivo`} component={AtivoFormVisualize} />
    </Switch>
);