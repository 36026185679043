import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { editOpcao, loadFormOpcao } from "../../../../../../actions/precificacao/OpcaoAction";

import ContainerPage from "../../../../../common/container/ContainerPage";
import ReceitaProdutoForm from "./form/ReceitaProdutoForm";

class ReceitaProdutoUpdatePage extends Component {
  componentDidMount() {
    let idOpcao = this.props.match.params.idOpcao;
    this.props.loadFormOpcao(idOpcao, true, "receita/produto");
  }

  render() {
    const { editOpcao } = this.props;

    return (
      <ContainerPage>
        <ReceitaProdutoForm
          title={"Editar - Produto (Receita)"}
          readOnly={false}
          floatButtonVisible={true}
          onSubmit={(e) => editOpcao(e, "receita/produto")}
        />
      </ContainerPage>
    );
  }
}

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormOpcao,
      editOpcao,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispacthToProps)(ReceitaProdutoUpdatePage));
