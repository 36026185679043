/* eslint eqeqeq: 0 */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import If from '../../../../../common/If/If';
import AgrupamentoCard from '../card/AgrupamentoCard'
import PagamentosChart from '../charts/PagamentosChart';
import MonitoramentosChart from '../charts/MonitoramentosChart';
import DiarioDeBordoChart from '../charts/DiarioDeBordoChart';
import IndicesInflacaoChart from '../charts/IndicesDeInflacaoChart';
import DashboardFilter from '../form/DashboardFilter';
import OperacaoDistribuicao from './OperacaoDistribuicao';

import {
    getFiltros,
    getMonitoramentos,
    getPagamentos,
    getDiarioDeBordo,
    getIndicesDeInflacao,
    clearGrupos,
    getSaldo,
    getQuantitativoSeries,
    getQuantitativoNovasSeries,
    getQuantitativoProximosPagamentos,
    getQuantitativoProximosMonitoramentos,
    getOperacoesEmDistribuicao,
} from '../../../../../../actions/operacao/DashboardAction';


class DashboardList extends Component {
    componentDidMount() {
        this.props.getFiltros();
        this.props.getIndicesDeInflacao();
        this.refreshData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.operacoes.length !== this.props.operacoes.length) {
            this.refreshData();
        }
    }

    refreshData = () => {
        const idsOperacoes = this.props.operacoes.map(o => o.key);
        this.props.getMonitoramentos(idsOperacoes);
        this.props.getPagamentos(idsOperacoes);
        this.props.getDiarioDeBordo(idsOperacoes);
        this.props.getSaldo(idsOperacoes);
        this.props.getQuantitativoSeries(idsOperacoes);
        this.props.getQuantitativoNovasSeries(idsOperacoes);
        this.props.getQuantitativoProximosPagamentos(idsOperacoes);
        this.props.getQuantitativoProximosMonitoramentos(idsOperacoes);
        this.props.getOperacoesEmDistribuicao(idsOperacoes);
        this.props.clearGrupos();
    }

    render() {
        const { operacoes } = this.props;

        const idsOperacoes = operacoes.map(o => o.key);

        return (
            <div className="container overflow-hidden" style={{ maxWidth: 1700 }}>
                <If test={idsOperacoes.length == 0}>
                    <div>
                        <DashboardFilter/>
                    </div>
                </If>
                <AgrupamentoCard/>
                <div className="row row-cols-xl-2">
                    <div className="col p-3 pb-5" >
                        <PagamentosChart operacoes={idsOperacoes} />
                    </div>
                    <div className="col p-3 pb-5" >
                        <MonitoramentosChart />
                    </div>
                    <div className="col p-3 pb-5" >
                        <DiarioDeBordoChart />
                    </div>
                    <div className="col p-3 pb-5" >
                        <IndicesInflacaoChart />
                    </div>
                    <If test={idsOperacoes.length == 0}>
                        <div className="col p-3 pb-5" >
                            <OperacaoDistribuicao />
                        </div>
                    </If>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    operacoes: state.operacaoReducer.selectedOperacoes,
    messages: state.passivoReducer.messages,
});

const mapDispacthToProps = dispatch => bindActionCreators({
    getFiltros,
    getMonitoramentos,
    getPagamentos,
    getDiarioDeBordo,
    getIndicesDeInflacao,
    clearGrupos,
    getSaldo,
    getQuantitativoSeries,
    getQuantitativoNovasSeries,
    getQuantitativoProximosPagamentos,
    getQuantitativoProximosMonitoramentos,
    getOperacoesEmDistribuicao,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(DashboardList));
