import React from "react";
import { Multiselect } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';

import {
    activateFocus,
    activateBlur
} from './../../../utils/helpers/StyleHelper';

const messages = {
    emptyList: "Nenhum registro encontrado",
    emptyFilter: "Nenhum registro encontrado com esse filtro"
}

const MultSelectListInput = (props) => (
    <div className="form-group field-floating ">
        <div className="dropdown-list-select">
            <Multiselect
                {...props.input}
                id={props.inputId}
                value={props.input.value || []}
                filter={props.filter}
                className={props.className}
                data={props.options}
                valueField={props.valueField}
                textField={props.textField}
                defaultValue={props.defaultValue}
                allowCreate={props.allowCreate}
                autoFocus={props.autoFocus}
                dropUp={props.dropUp}
                messages={messages}
                readOnly={props.readOnly}
                onBlur={activateBlur}
                onFocus={activateFocus}
                onChange={(value, { dataItem }) => {
                    const isFixedItem = props?.fixedItems?.some(item => item[props.valueField] === dataItem[props.valueField]);
                    if (!(props?.fixedItems?.length > 0 && isFixedItem)) props.input.onChange(value);
                }}
            />
            {(props.meta && props.meta.error && props.meta.touched &&
                <p className="error-msg sp-error text-danger f-12 mb-0">{props.meta.error}</p>)
            }
        </div>
        <label
            htmlFor={props.name}
            className="control-label"
            id={"lbl_" + props.inputId}
        >
            {props.label}
            {props.required ? <span className="text-warning font-weight-bold ml-1">*</span> : ""}
        </label>
    </div>
);


export default MultSelectListInput;
