import React, { Component } from "react";
import { NumericFormat } from "react-number-format";

export default class SimpleMaskedInput extends Component {
  render() {
    const props = this.props;
    const { activateFocus } = this;
    return (
      <>
        <NumericFormat
          {...props.input}
          className={`form-control field-floating ${props.className} ${props.readOnly && 'disabled' }`}
          id={"txtfield_" + props.inputId}
          type={props.type}
          prefix={props.prefix}
          suffix={props.suffix}
          thousandSeparator={props.thousandSeparator}
          decimalSeparator={props.decimalSeparator}
          decimalScale={props.decimalScale}
          fixedDecimalScale={props.fixedDecimalScale}
          allowNegative={props.allowNegative}
          // allowEmptyFormatting={props.allowEmptyFormatting}
          value={props.input.value}
          displayType={props.displayType}
          format={props.formatMask}
          removeFormatting={props.removeFormatting}
          mask={props.mask}
          customInput={props.customInput}
          isAllowed={props.isAllowed}
          renderText={props.renderText}
          getInputRef={props.getInputRef}
          placeholder={props.placeholder}
          disabled={props.readOnly}
          maxLength={props.maxLength}
          style={{paddingLeft: '5px'}}
          onFocus={activateFocus}
        />
        {(props.meta && props.meta.error && props.meta.touched &&
            <p className="error-msg sp-error text-danger f-12 mb-0">{props.meta.error}</p>)
        }
      </>
    );
  }
}
