import { SIDEBAR_ITEM_SELECTED } from './../../../actions/common/sidebar/SideBarAction';

const INITIAL_STATE = { selected: '' }

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SIDEBAR_ITEM_SELECTED:
            return {
                ...state,
                selected: action.payload
            }
        default:
            return state
    }
}