/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { getFundos, newFundoForm } from "../../../../actions/fundo/FundoAction";
import Button from "../../../common/button/Button";
import Container from "../../../common/container/Container";
import ContainerRow from "../../../common/container/ContainerRow";
import FundoPicker from "../../pickers/FundoPicker";
import FundoList from "./list/FundoList";
import { authorizationFunction } from "../../../../actions/authorization/AuthorizationAction";
import { CREATE, FUNDO } from "../../../../utils/actionsAndFeaturesAuth";

const Fundo = (props) => (
  React.useEffect(() => {
    if (props.selectedFundos.length > 0 || props.page != 1) {
      const idsFundos = props.selectedFundos.map((o) => o.key);
      props.getFundos(idsFundos, props.page)
    } else {
      props.getFundos([])
    }
  }, []),

  <Container>
    <p className="page-head__title f-36 mb-0 mt-2 px-3 py-4">Fundo</p>
    <div className="col-md my-2">
      <ContainerRow>
        <div className="shadow-custom rounded-12 w-100 pd-12 mx-3 mb-3 mt-0 d-flex flex-row justify-content-between align-items-end bg-white">
          <div className="d-flex flex-column w-100">
            <label className="f-12 text-dark mb-1">Busca</label>
            <FundoPicker />
          </div>
          <Button
            classes="rounded text-white p-bg-imp mb-0 h-38 px-4 py-1"
            activeClassName={"col-lg-auto pr-0"}
            buttonStyle={"success"}
            action={() =>
              props.authorizationFunction(
                { idAcao: CREATE, idFuncionalidade: FUNDO },
                () => props.newFundoForm()
              )
            }
            icon={"plus-circle"}
            name={"Cadastrar Fundo"}
          />
        </div>
      </ContainerRow>
      <ContainerRow>
        <div className="col-md">
          <FundoList />
        </div>
      </ContainerRow>
    </div>
  </Container>
);

const mapStateToProps = (state) => ({
  selectedFundos: state.fundoReducer.selectedFundoOptions,
  page: state.fundoReducer.pagination,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      newFundoForm,
      authorizationFunction,
      getFundos,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Fundo));
