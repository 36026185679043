import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
    getPatrimonios,
    removePatrimonio,
    showPatrimonioDeleteDialog,
    loadFormPatrimonio,
} from '../../../../../actions/patrimonio/PatrimonioAction';

import Table from '../../../../common/table/Table';
import TableHeader from '../../../../common/table/TableHeader';
import TableContent from '../../../../common/table/TableContent';
import TableEmptyData from '../../../../common/table/TableEmptyData';
import PaginationRB from '../../../../common/pagination/PaginationRB';
import ConfirmDialog from '../../../../common/modal/ConfirmDialog';
import TableActionWithRouteAuthorization from '../../../../common/table/TableActionWithRouteAuthorization';
import { PATRIMONIO, DELETE } from '../../../../../utils/actionsAndFeaturesAuth';
import { authorizationFunction } from '../../../../../actions/authorization/AuthorizationAction';
import If from '../../../../common/If/If';

class PatrimonioList extends Component {
    render() {
        const {
            selectedPatrimonios,
            patrimonioData,
            messages,
            getPatrimonios,
            removePatrimonio,
            showPatrimonioDeleteDialog,
            showDeleteDialog,
            loadFormPatrimonio,
            authorizationFunction,
        } = this.props;

        const idsPatrimonios = selectedPatrimonios.map(o => o.key);
        const emptyData = messages && messages.infos && messages.infos[0];

        const tableHeader = (
            <tr>
                <th className="text-center fw-700">Patrimônio Separado</th>
                <th className="text-center fw-700">Quantidade de Emissões</th>
                <th className="text-center fw-700">Quantidade de Séries</th>
                <th className="text-center"></th>
            </tr>
        );

        const tableContent = (
            patrimonioData && patrimonioData.hasItems
                ? patrimonioData.items.map(patrimonio =>
                    (<tr key={patrimonio.id}>
                        <td className="text-center fw-400">{patrimonio.descricao || "--"}</td>
                        <td className="text-center fw-400">{patrimonio.quantidadeEmissoes || "--"}</td>
                        <td className="text-center fw-400">{patrimonio.quantidadeSeries || "--"}</td>
                        <TableActionWithRouteAuthorization
                            space="2"
                            feature={PATRIMONIO}
                            viewAction={() => loadFormPatrimonio(patrimonio.id)}
                            updateAction={() => loadFormPatrimonio(patrimonio.id, true)}
                        />
                    </tr>)
                )
                : <TableEmptyData message={emptyData} />
        );

        return (
            <div>
                <If test={patrimonioData.totalCount > 0}>
                    <Table
                        withoutHeader
                        classes="shadow-custom rounded-12 col-12 my-3 pd-10 bg-white"
                        totalItems={patrimonioData.totalCount}
                        pagination={    
                            <PaginationRB
                                pageNumber={patrimonioData.pageIndex - 1}
                                itemsPerPage={patrimonioData.pageSize}
                                totalItems={patrimonioData.totalCount}
                                filter={idsPatrimonios}
                                fetchFunction={getPatrimonios}
                                pageRange={5}
                            />
                        }
                    >
                        <TableHeader >
                            {tableHeader}
                        </TableHeader>
                        <TableContent>
                            {tableContent}
                        </TableContent>
                    </Table>
                </If>

                <ConfirmDialog
                    title="Aviso"
                    contentText="Confirmar exclusão do patrimonio selecionado?"
                    textButtonCancel="CANCELAR"
                    textButtonConfirm="CONFIRMAR"
                    showDialog={showDeleteDialog.showDialog}
                    onClose={() => showPatrimonioDeleteDialog(false)}
                    onConfirm={() => authorizationFunction(
                        { idAcao: DELETE, idFuncionalidade: PATRIMONIO },
                        () => removePatrimonio(showDeleteDialog.idPatrimonio, idsPatrimonios)
                    )}
                    type="warning"
                    maxWidth="md"
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    showDeleteDialog: state.patrimonioReducer.showPatrimonioDeleteDialog,
    patrimonioData: state.patrimonioReducer.patrimonios,
    messages: state.patrimonioReducer.messages,
    selectedPatrimonios: state.patrimonioReducer.selectedPatrimonioOptions,
})

const mapDispacthToProps = dispatch => bindActionCreators({
    getPatrimonios,
    removePatrimonio,
    showPatrimonioDeleteDialog,
    loadFormPatrimonio,
    authorizationFunction,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(PatrimonioList));
