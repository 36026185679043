/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog, FormControlLabel, Checkbox } from "@material-ui/core";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import {
  buscaPagamentoModalPrecificacao,
  validaPagamentoModalPrecificacao,
} from "../../../../../../actions/precificacao/PagamentoAction";
import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import {
  convertCurrencyNumberNew,
  formatDecimal,
} from "../../../../../../utils/helpers/NumberHelper";
import { withRouter } from "react-router-dom";
import ModalProsseguirPagamento from "../modals/ModalProsseguirPagamento";

let ModalExecucaoPrecificacao = (props) => {
  const [radioChecked, setRadioChecked] = React.useState(false);
  const [openProsseguirPagamento, setOpenProsseguirPagamento] =
    React.useState(false);

  const {
    close,
    open,
    pagamento,
    pagamentoForm,
    buscaPagamentoModalPrecificacao,
    validaPagamentoModalPrecificacao,
    idPagamentoPrecificacao,
  } = props;

  const moeda = pagamento?.moeda?.simbolo;

  React.useEffect(() => {
    if (idPagamentoPrecificacao) {
      buscaPagamentoModalPrecificacao(idPagamentoPrecificacao);
    }
  }, [idPagamentoPrecificacao]);

  return (
    <>
      {pagamento && (
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={open}
          onClose={() => close()}
        >
          <div className="p-4">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
                {pagamento.codigoOpea} - {pagamento.apelido}
              </p>
              <Close
                role="button"
                className="text-danger"
                onClick={() => close()}
              />
            </div>

            <div className="py-4 text-dark f-16">
              <div className="row w-100 mb-3">
                <div className="col-lg-3">
                  <p className="mb-1">Data do Evento</p>
                  <p className="fw-400 mb-0">
                    {getDateFormatedFromString(pagamento.dataEvento)}
                  </p>
                </div>
                <div className="col-lg-3">
                  <p className="mb-1">Data do Pagamento</p>
                  <p className="fw-400 mb-0">
                    {getDateFormatedFromString(pagamento.dataPagamento)}
                  </p>
                </div>
                <div className="col-lg-3">
                  <p className="mb-1">Código IF</p>
                  <p className="fw-400 mb-0">{pagamento.codigoIF}</p>
                </div>
                <div className="col-lg-3">
                  <p className="mb-1">Agente Fiduciário</p>
                  <p className="fw-400 mb-0">{pagamento.agenteFiduciario}</p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg-3">
                  <p className="mb-1">Tipo de Evento</p>
                  <p className="fw-400 mb-0">{pagamento.tipoEvento}</p>
                </div>
                <div className="col-lg-3">
                  <p className="mb-1">Pagamento de Juros</p>
                  <p className="fw-400 mb-0">{pagamento.pagamentoJuros}</p>
                </div>
                <div className="col-lg-3">
                  <p className="mb-1">Pagamento de Amortização</p>
                  <p className="fw-400 mb-0">
                    {pagamento.pagamentoAmortizacao ? "Sim" : "Não"}
                  </p>
                </div>
              </div>
              {pagamento.observacao && (
                <div className="row w-100 mb-3">
                  <div className="col-12">
                    <p className="mb-1">Observação</p>
                    <p className="fw-400 mb-0">{pagamento.observacao}</p>
                  </div>
                </div>
              )}
              {/*  */}
              <div className="row mx-0 w-100 mb-3 mt-4">
                <div className="col-lg-6 pl-0 pr-lg-3 pr-0 border-right">
                  <p className="p-color-imp fw-500 mb-2">
                    Cálculo Precificação
                  </p>
                  <div className="col-12 p-0">
                    <div style={{ height: "29px" }} />
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <span>VNA</span>
                      <span>
                        {convertCurrencyNumberNew(
                          pagamento.valorNominalAtualizado,
                          moeda
                        )}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <span>Amortização</span>
                      <span>
                        {convertCurrencyNumberNew(
                          pagamento.amortizacaoValorAPagar,
                          moeda
                        )}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between pb-2 border-bottom">
                      <span>Incorporação de Juros</span>
                      <span>
                        {convertCurrencyNumberNew(
                          pagamento.jurosIncorporacao,
                          moeda
                        )}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2 pt-2 fw-500 border-top">
                      <span>Saldo Residual</span>
                      <span>
                        {convertCurrencyNumberNew(
                          pagamento.saldoResidual,
                          moeda
                        )}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between pb-2 border-bottom">
                      <span>Amex</span>
                      <span>
                        {convertCurrencyNumberNew(pagamento.amex, moeda)}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2 pt-2 fw-500 border-top">
                      <span>Saldo Residual Extra</span>
                      <span>
                        {convertCurrencyNumberNew(
                          pagamento.saldoResidualExtra,
                          moeda
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 border-left mt-lg-0 mt-3 px-lg-3 px-0">
                  <p className="p-color-imp fw-500 mb-2">
                    Cálculo Valor Pagamento
                  </p>
                  <div className="col-12 p-0">
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <span>Juros</span>
                      <span>
                        {convertCurrencyNumberNew(pagamento.juros, moeda)}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <span>Amortização</span>
                      <span>
                        {convertCurrencyNumberNew(
                          pagamento.amortizacaoValorAPagar,
                          moeda
                        )}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <span>Amex</span>
                      <span>
                        {convertCurrencyNumberNew(pagamento.amex, moeda)}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between pb-2 border-bottom">
                      <span>Evento Genérico</span>
                      <span>
                        {convertCurrencyNumberNew(
                          pagamento.eventoGenerico,
                          moeda
                        )}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2 pt-2 fw-500 border-top">
                      <span>Pagamento Unitário</span>
                      <span>
                        {convertCurrencyNumberNew(
                          pagamento.pagamentoTotalPmtUnitario,
                          moeda
                        )}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between pb-2 border-bottom">
                      <span>Quantidade Integralizada</span>
                      <span>
                        {formatDecimal(pagamento.quantidadeIntegralizada, 0)}
                      </span>
                    </div>
                    <div className="p-color-imp d-flex flex-row justify-content-between mb-2 pt-2 fw-500 border-top">
                      <span>Valor Pagamento Total</span>
                      <span>
                        {convertCurrencyNumberNew(
                          pagamento.pagamentoTotalPmt,
                          moeda,
                          2
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={radioChecked}
                      onChange={(e) => setRadioChecked(e.target.checked)}
                    />
                  }
                  label="Certifico que as informações acima prestadas foram verificadas e validadas."
                />
              </div>
            </div>

            <div
              className={`d-flex flex-row align-content-center justify-content-between`}
            >
              <Button
                name={"Cancelar"}
                action={() => close()}
                classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
              />
              {pagamentoForm?.emRevisao && (
                <>
                  <Button
                    disabled={!radioChecked}
                    name={"Prosseguir com Pagamento"}
                    action={() => setOpenProsseguirPagamento(true)}
                    classes="default-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                  />
                  <ModalProsseguirPagamento
                    open={openProsseguirPagamento}
                    close={() => {
                      setOpenProsseguirPagamento(false);
                      close();
                    }}
                  />
                </>
              )}
              <Button
                disabled={!radioChecked}
                name={"Enviar para Validação"}
                action={() => {
                  validaPagamentoModalPrecificacao({
                    ...pagamento,
                    emRevisao: false,
                  });
                  close();
                }}
                classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              />
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  pagamentoForm: state.pagamentoReducer.pagamentoFormTypes,
  pagamento: state.pagamentoReducer.pagamentoModalPrecificacao,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      buscaPagamentoModalPrecificacao,
      validaPagamentoModalPrecificacao,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ModalExecucaoPrecificacao)
);
