import {
    TELA_FECHAMENTO,
    TELA_VALIDACAO,
    TELA_VALIDACAO_CONFIRMACAO,
    TELA_AGENDAMENTO,
    TELA_LANCAMENTO,
    TELA_PAGAMENTO,

    SALVA_FILTROS_PAGAMENTO,

    PAGAMENTOS_FETCHED,
    EXTERNO_PAGAMENTO_AF,
    CHECK_LINK_EXTERNO_PAGAMENTO_AF,
    PAGAMENTO_MODAL_PRECIFICACAO,
    PAGAMENTOS_CONSULT_CURRENT,
    INDICADORES_FETCHED,
    LOAD_FILTER_FORM_PAGAMENTO,
    LOAD_INFO_PAGAMENTO,
    PAGAMENTO_IR_PARA_PAGINA,
    CHANGE_STATUS_PAGAMENTO_DIALOGS,
    LOAD_TELA_FLUXOS_PAGAMENTO,
    PAGAMENTO_RESET,
    // PAGAMENTO_ATUALIZAR_STEPPERS_STATUS,
    PAGAMENTO_SHOW_DELETE_DIALOG,

    OPEN_MODAL_EXECUCAO_PRECIFICACAO,
} from "../../actions/precificacao/PagamentoAction";

const INITIAL_STATE = {
    pagamentos: {
        lastPage: 0,
        totalCount: 0,
        hasItems: false,
        pageSize: 0,
        pageIndex: 0,
        items: [],
    },
    externoPagamentoAF: null,
    checaLinkExternoAF: null,
    pagamentoModalPrecificacao: null,
    openModalExecucaoPrecificacao: {
        open: false,
        idPagamentoPrecificacao: null
    },
    pagamentosConsultCurrent: null,
    indicadores: null,
    messages: null, 
    showPagamentoDeleteDialog: {
        showDialog: false,
        paramsReiniciarPagamento: {},
    },
    isLoading: false,
    pagamentoFormTypes: {},
    pagamentoFormTypesFilter: {},
    pagamentoInfo: {},
    pagamentoPagina: null,
    confirmDialogFechamento: false,
    confirmDialogValidacao: false,
    confirmDialogValidacaoConfirmacao: false,
    confirmDialogAgendamento: false,
    confirmDialogLancamento: false,
    confirmDialogPagamento: false,
    statusEtapas: [
        {id: 1, completed: false},
        {id: 2, completed: false},
        {id: 3, completed: false},
        {id: 4, completed: false},
        {id: 5, completed: false},
    ]
}

export default function (state = INITIAL_STATE, action = {}) {    
    switch(action.type) {
        case CHECK_LINK_EXTERNO_PAGAMENTO_AF:
            return {
                ...state,
                checaLinkExternoAF: action.payload.content,    
                messages: action.payload.messages,
                isLoading: true,             
            }
        case EXTERNO_PAGAMENTO_AF:
            return {
                ...state,
                externoPagamentoAF: action.payload.content,    
                messages: action.payload.messages,
                isLoading: true,             
            }
        case PAGAMENTO_MODAL_PRECIFICACAO:
            return {
                ...state,
                pagamentoModalPrecificacao: action.payload.content,    
                messages: action.payload.messages,
                isLoading: true,             
            }
        case PAGAMENTOS_FETCHED:
            return {
                ...state,
                pagamentos: action.payload.content,
                messages: action.payload.messages,
                isLoading: true,                
            }
        case OPEN_MODAL_EXECUCAO_PRECIFICACAO:
            return {
                ...state,
                openModalExecucaoPrecificacao: action.payload,              
            }
        case PAGAMENTOS_CONSULT_CURRENT:
            return {
                ...state,
                pagamentosConsultCurrent: action.payload,      
                isLoading: true,     
            }
        case INDICADORES_FETCHED:
            return {
                ...state,
                indicadores: action.payload.content,
                isLoading: true,                
            }
        case LOAD_FILTER_FORM_PAGAMENTO:
            return {
                ...state,
                pagamentoFormTypesFilter: action.payload,
            }
        case LOAD_INFO_PAGAMENTO:
            return {
                ...state,
                pagamentoInfo: action.payload,
            }
        case PAGAMENTO_SHOW_DELETE_DIALOG:
            return {
                ...state,
                showPagamentoDeleteDialog: action.payload
            }
        case PAGAMENTO_IR_PARA_PAGINA:
            return {
                ...state,
                pagamentoPagina: action.payload
            }
        case CHANGE_STATUS_PAGAMENTO_DIALOGS:
            return {
                ...state,
                confirmDialogFechamento: action.payload.tela === TELA_FECHAMENTO ? action.payload.valor : false,
                confirmDialogValidacao: action.payload.tela === TELA_VALIDACAO ? action.payload.valor : false,
                confirmDialogValidacaoConfirmacao: action.payload.tela === TELA_VALIDACAO_CONFIRMACAO ? action.payload.valor : false,
                confirmDialogAgendamento: action.payload.tela === TELA_AGENDAMENTO ? action.payload.valor : false,
                confirmDialogLancamento: action.payload.tela === TELA_LANCAMENTO ? action.payload.valor : false,
                confirmDialogPagamento: action.payload.tela === TELA_PAGAMENTO ? action.payload.valor : false,
            }
        case LOAD_TELA_FLUXOS_PAGAMENTO:
            return {
                ...state,
                pagamentoFormTypes: action.payload,
            }
        case PAGAMENTO_RESET:
            return {
                ...INITIAL_STATE
            }
        case SALVA_FILTROS_PAGAMENTO:
            return {
                ...state,
                filtrosPagamento: action.payload
            }
        // case PAGAMENTO_ATUALIZAR_STEPPERS_STATUS:
        //     const etapasConcluidas = action.payload.etapasConcluidas;
        //     let newStatus = deepClone(state.statusEtapas);
        //     if (etapasConcluidas.length > 0) {
        //         etapasConcluidas.forEach(e => {
        //             let etapa = e >= 2 ? e - 1 : e;
                    
        //             if (etapa >= 6){
        //                 etapa = 5;
        //             }

        //             let index = newStatus.findIndex(s => s.id === etapa);
        //             newStatus[index].completed = true;
        //         });
        //     }
            
        //     return {
        //         ...state,
        //         statusEtapas: newStatus
        //     }
        default:
            return state;
    }
}
