/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Table from "../../../../common/table/Table";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import PaginationRB from "../../../../common/pagination/PaginationRB";

import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";
import {
  formatStatusPDDInverted,
  formatStatusPDD,
} from "../../../../../utils/helpers/OthersHelpers";

import {
  getPedidos,
  exportPedidosToExcel,
} from "../../../../../actions/pedidos/PedidosAction";
import ReactTooltip from "react-tooltip";
import {
  convertCurrencyNumber,
  formatDecimal,
} from "../../../../../utils/helpers/NumberHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { FLUXO_ROUTE_PATH, PEDIDOS_ROUTE_PATH } from "../../../../common/routers/constantesRoutes";

const PedidosList = (props) => {
  const {
    getPedidos,
    pedidosData,
    pedidosConsult,
    statusPedidos,
    tipoItem,
    messages,
    exportPedidosToExcel,
  } = props;

  const user = JSON.parse(localStorage.getItem("userLogged"));
  const areas = user.areasAtuacoes;
  const isTeasury = areas.some(({ id }) => id === 9);

  const [selectedTab, setSelectedTab] = React.useState("Status dos Pedidos")

  const tabs = ["Status dos Pedidos", "Tipo de Item"];

  const tableHeader = (
    <tr>
      <th className="text-center"></th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">ID</div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          DATA CRIAÇÃO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          CRIADO POR
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          PATRIMÔNIO SEPARADO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          CÓDIGO OPEA
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          TIPO DE ITEM
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          TIPO DE DESPESA
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          FORNECEDOR
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          DATA PAGAMENTO
        </div>
      </th>
      <th>
        <div className="text-center fw-700 d-flex justify-content-center w-100">
          VALOR BRUTO
        </div>
      </th>
      <th className="text-center"></th>
    </tr>
  );

  const emptyData = messages?.infos[0];

  function indicadorBaseTipo(ind, mb = true, type) {
    return (
      <div
        key={`indicador-${type}-${ind?.dia}`}
        className={`w-100 shadow-custom rounded-8 bg-white mx-lg-1 mb-lg-0 p-2 overflow-hidden border-bottom-card-pdd-base ${type} mb-${mb ? "3" : "0"}`}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="fw-500 mt-4">{formatDecimal(ind.quantidade, 0)}</p>
          <div
            data-type="opea"
            data-for={`ttp-${type}-${ind?.dia}`}
            className="text-nowrap overflow-hidden text-overflow-ellipsis"
            data-tip={`R$ ${convertCurrencyNumber(ind.valor, false, 2)}`}
          >
            <p className="fw-400 text-nowrap overflow-hidden text-overflow-ellipsis text-center">
              R$ {convertCurrencyNumber(ind.valor, false, 2)}
            </p>
            <ReactTooltip
              effect="solid"
              className="px-2 py-1"
              globalEventOff="click"
              id={`ttp-${type}-${ind?.dia}`}
              style={{ width: "100px" }}
            />
          </div>
          <span className="fw-500 text-nowrap">
            {ind?.dia}
          </span>
        </div>
      </div>
    );
  }

  function indicadorBase(ind, mb = true) {
    return (
      <div
        key={`indicador-${ind?.status?.value}`}
        className={`w-100 shadow-custom rounded-8 bg-white mx-lg-1 mb-lg-0 p-2 overflow-hidden border-bottom-card-pdd-base ${
          ind?.status?.raw
        } mb-${mb ? "3" : "0"}`}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="fw-500 mt-4">{formatDecimal(ind.quantidade, 0)}</p>
          <div
            data-type="opea"
            data-for={`ttp-${ind?.status?.value}`}
            className="text-nowrap overflow-hidden text-overflow-ellipsis"
            data-tip={`R$ ${convertCurrencyNumber(ind.valor, false, 2)}`}
          >
            <p className="fw-400 text-nowrap overflow-hidden text-overflow-ellipsis text-center">
              R$ {convertCurrencyNumber(ind.valor, false, 2)}
            </p>
            <ReactTooltip
              effect="solid"
              className="px-2 py-1"
              globalEventOff="click"
              id={`ttp-${ind?.status?.value}`}
              style={{ width: "100px" }}
            />
          </div>
          <span className="fw-500 text-nowrap">
            {formatStatusPDD(formatStatusPDDInverted(ind?.status?.value))}
          </span>
        </div>
      </div>
    );
  }

  function indicadorOuter(ind, mb = true) {
    return (
      <div
        key={`indicador-${ind?.status?.value}`}
        className={`d-flex flex-column justify-content-between w-100 h-100 shadow-custom rounded-8 bg-white border-left-card-pdd-base ${
          ind?.status?.raw
        } px-3 py-2 mb-${mb ? "3" : "0"}`}
      >
        <div className="d-flex flex-row align-items-lg-center justify-content-between">
          <span className="fw-500 mr-2">
            {formatStatusPDD(formatStatusPDDInverted(ind?.status?.value))}
          </span>
        </div>
        <div className="d-flex flex-xl-row flex-column align-items-xl-end justify-content-xl-between justify-content-start mb-1 mt-2">
          <span className="f-22 lh-22 fw-400 text-nowrap mb-xl-0 mb-2 mr-2">
            {formatDecimal(ind.quantidade, 0)}
          </span>
          <div
            data-type="opea"
            data-for={`ttp-${ind?.status?.value}`}
            className="text-nowrap overflow-hidden text-overflow-ellipsis text-xl-right text-left"
            data-tip={`R$ ${convertCurrencyNumber(ind.valor, false, 2)}`}
          >
            <span className="f-12 lh-12 fw-400 text-nowrap overflow-hidden w-auto text-overflow-ellipsis text-xl-right text-left">
              R$ {convertCurrencyNumber(ind.valor, false, 2)}
            </span>
            <ReactTooltip
              effect="solid"
              className="px-2 py-1"
              globalEventOff="click"
              id={`ttp-${ind?.status?.value}`}
              style={{ width: "100px" }}
            />
          </div>
        </div>
      </div>
    );
  }

  const tableContent =
    pedidosData &&
    pedidosData.hasItems &&
    pedidosData.items.map((item, index) => (
      <tr key={index} className={`tr-status`}>
        <td className="text-center td-status">
          <div className="d-flex justify-content-start align-items-center pl-2">
            <div
              data-type="light"
              data-for={`ttp-list-${index}`}
              data-tip={item.status.value}
              className={`circle-status sts-pd-${item.status.key} rounded-circle mr-2`}
            />
            <ReactTooltip
              noArrow
              effect="solid"
              id={`ttp-list-${index}`}
              className="px-2 py-1"
              globalEventOff="click"
              style={{ width: "100px" }}
            />
          </div>
        </td>
        <td className="text-center fw-400">{item?.identificador || "--"}</td>
        <td className="text-center fw-400">
          {item?.dataCriacao
            ? getDateFormatedFromString(item.dataCriacao)
            : "--"}
        </td>
        <td className="text-center fw-400">{item?.criadoPor || "--"}</td>
        <td className="text-center fw-400">
          {item?.patrimonioSeparado || "--"}
        </td>
        <td className="text-center fw-400">{item?.codigoOpea || "--"}</td>
        <td className="text-center fw-400">{item?.tipoItem || "--"}</td>
        <td className="text-center fw-400">{item?.tipoDespesa || "--"}</td>
        <td className="text-center fw-400">{item?.fornecedor || "--"}</td>
        <td className="text-center fw-400">
          {item?.dataPrevisaoPagamento
            ? getDateFormatedFromString(item.dataPrevisaoPagamento)
            : "--"}
        </td>
        <td className="text-center fw-400">
          {item?.valorBruto
            ? convertCurrencyNumber(item?.valorBruto, "R$ ", 2)
            : "--"}
        </td>
        <td className="text-center">
          <Link to={`/${PEDIDOS_ROUTE_PATH}/${FLUXO_ROUTE_PATH}/${item.id}`} className="p-color-imp f-20 mr-3 ml-2 px-1">
            <FontAwesomeIcon color='#630a37' icon={faChevronRight} />
          </Link>
        </td>
      </tr>
    ));

    const statusPedidosBase = ['Cadastrado', 'Aprovado', 'Escriturado', 'Exportado', 'Pago'];
    const statusPedidosOuter = ['A Revisar', 'Cancelado'];
    const pedidosCompras = tipoItem?.pedidosCompras || [];
    const movimentacoesFinanceiras = tipoItem?.movimentacoesFinanceiras || [];

  return (
    <div className="container-fluid">
      <div className="p-3">

        {statusPedidos && (
          <div className="d-flex flex-row align-items-center pb-2">
            {tabs.map((tab, i) => {
              return (
                <div
                  role="button"
                  key={`tab-${i}`}
                  onClick={() => setSelectedTab(tab)}
                  className={`px-3 py-2 brd-opea-tab-pdd p-color fw-400 ${selectedTab == tab ? 'active' : ''}`}
                >
                  {tab}
                </div>
              )
            })}
          </div>
        )}

        {(statusPedidos && selectedTab === 'Status dos Pedidos') && (
          <div className="row cards-pgmt mt-3">
            <div className="col-12">
              <div className="w-100 position-relative border-content-pgmt rounded-8 p-3 d-flex flex-column flex-lg-row">
                <span className="position-absolute fw-400 title-pgmt px-2">Status dos Pedidos</span>
                {statusPedidosBase.map((status) =>
                  statusPedidos
                    .filter((ind) => ind?.status?.value === status)
                    .map((ind) => indicadorBase(ind))
                )}
                <div className="d-flex flex-column ml-0 ml-lg-2 px-0 col-lg-2 col-12">
                  {statusPedidosOuter.map((status) =>
                    statusPedidos
                      .filter((ind) => ind?.status?.value === status)
                      .map((ind) => indicadorOuter(ind, status === 'A Revisar'))
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {(tipoItem && selectedTab === 'Tipo de Item') && (
          <div className="d-flex flex-lg-row flex-column cards-pgmt mt-3">
            <div className="col-lg-6 col-12 mb-lg-0 mb-3 px-0 mr-lg-3 mr-0">
              <div className="w-100 h-100 position-relative border-content-pgmt rounded-8 p-3 d-flex flex-column flex-lg-row">
                <span className="position-absolute fw-400 title-pgmt px-2">Ordem de Compra</span>
                {pedidosCompras.map((pedido) => indicadorBaseTipo(pedido, true, 'Pedido'))}
              </div>
            </div>
            <div className="col-lg-6 col-12 px-0">
              <div className="w-100 h-100 position-relative border-content-pgmt rounded-8 p-3 d-flex flex-column flex-lg-row">
                <span className="position-absolute fw-400 title-pgmt px-2">Movimentação Financeira</span>
                {movimentacoesFinanceiras.map((movimentacao) => indicadorBaseTipo(movimentacao, true, 'Movimentacao'))}
              </div>
            </div>
          </div>
        )}

        {pedidosData?.totalCount > 0 && (
          <Table
            withoutHeader
            files={[
              { label: 'Exportar para Excel', function: () => exportPedidosToExcel(pedidosConsult) },
              { label: 'Exportar Relatório Financeiro', function: isTeasury ? () => exportPedidosToExcel({...pedidosConsult, retornarRelatorioFinanceiro: true}) : null }
            ]}
            classes="shadow-custom rounded-12 col-12 mt-4 mb-3 pd-10 bg-white"
            totalItems={pedidosData?.totalCount}
            pagination={
              <PaginationRB
                pageNumber={pedidosData.pageIndex - 1}
                itemsPerPage={pedidosData.pageSize}
                totalItems={pedidosData?.totalCount}
                filter={pedidosConsult}
                fetchFunction={getPedidos}
                pageRange={5}
              />
            }
          >
            <TableHeader>{tableHeader}</TableHeader>
            <TableContent>{tableContent}</TableContent>
          </Table>
        )}

        {pedidosData.totalCount <= 0 && emptyData && (
          <div className="shadow-custom rounded-12 col-12 mt-4 mb-3 py-5 pd-10 bg-white d-flex align-items-center justify-content-center f-24 fw-400 txt-grey">
            <p className="px-3 py-5 mb-0">{emptyData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

formValueSelector("PedidosForm");

const mapStateToProps = (state) => ({
  pedidosData: state.pedidosReducer.pedidos,
  pedidosConsult: state.pedidosReducer.pedidosConsultCurrent,
  statusPedidos: state.pedidosReducer.indicadores?.statusPedidos || null,
  tipoItem: state.pedidosReducer.indicadores?.tipoItem || null,
  messages: state.pedidosReducer.messages,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      getPedidos,
      exportPedidosToExcel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(PedidosList);
