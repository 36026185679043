/* eslint eqeqeq: 0 */

import { initialize } from "redux-form";

import { getDateInvertedFormatedFromString } from "../../../../utils/helpers/DateTimeHelper";
import { convertPercentStringToFloat } from "../../../../utils/helpers/NumberHelper";
import { extractFromObj } from "../../../../utils/helpers/ObjectHelper";
import { getStringBooleanValue } from "../../../../utils/helpers/BooleanHelper";

// prettier-ignore
export function serializeFieldsSubclasseForm(subclasse) {
    let subclasseSerialized = {};

    if(subclasse) {
        subclasseSerialized = {
            ...subclasse,
            dataEmissao: getDateInvertedFormatedFromString(subclasse.dataEmissao),
            dataDaPrimeiraIntegralizacao: getDateInvertedFormatedFromString(subclasse.dataDaPrimeiraIntegralizacao),
            possuiTaxaPerformance: getStringBooleanValue(subclasse.possuiTaxaPerformance),
            possuiGrossUp: getStringBooleanValue(subclasse.possuiGrossUp),
            possuiMultiGestao: getStringBooleanValue(subclasse.possuiMultiGestao),
        }
    } else {
        subclasseSerialized = {};
    }

    return initialize("subclasseForm", subclasseSerialized);
}

function verificaObjetoParticipante(obj) {
    return obj?.idTipoServicoPrestado ? obj : null; 
}

// prettier-ignore
export function deserializeFieldsSubclasseForm(subclasse) {
    let { arquivoImagemEstrutura, nomeImagemEstrutura, descricaoEstrutura, classes, ...newSubclasse } = subclasse;

    return {
        ...newSubclasse,
        idFundo: extractFromObj(subclasse.idFundo, "key"),
        idClasse: extractFromObj(subclasse.idClasse),
        idUsuarioCompliance: extractFromObj(subclasse.idUsuarioCompliance),
        idUsuarioEstruturacao: extractFromObj(subclasse.idUsuarioEstruturacao),
        idUsuarioGestao: extractFromObj(subclasse.idUsuarioGestao),
        idUsuarioServicoCredito: extractFromObj(subclasse.idUsuarioServicoCredito),
        classe: subclasse.idClasse,
        dataEmissao: subclasse.dataEmissao ? subclasse.dataEmissao : null,
        possuiTaxaPerformance: subclasse.possuiTaxaPerformance ? (subclasse.possuiTaxaPerformance == "Sim" ? true : false) : null,
        // precoUnitario: convertCurrencyToNumber(subclasse.precoUnitario),
        possuiMultiGestao: !subclasse.possuiMultiGestao ? null : subclasse.possuiMultiGestao == "Não" ? false : true,
        possuiGrossUp: !subclasse.possuiGrossUp ? null : subclasse.possuiGrossUp == "Não" ? false : true,
        taxaGestao: convertPercentStringToFloat(subclasse.taxaGestao),
        grossUp: convertPercentStringToFloat(subclasse.grossUp),
        // PARTICIPANTES
        administradorFiduciario: verificaObjetoParticipante(subclasse.administradorFiduciario),
        gestora: verificaObjetoParticipante(subclasse.gestora),
        gestoraII: verificaObjetoParticipante(subclasse.gestoraII),
        consultorCredito: verificaObjetoParticipante(subclasse.consultorCredito),
        controladoria: verificaObjetoParticipante(subclasse.controladoria),
        distribuidor: verificaObjetoParticipante(subclasse.distribuidor),
        escriturador: verificaObjetoParticipante(subclasse.escriturador),
        custodiante: verificaObjetoParticipante(subclasse.custodiante),
        agenteMonitoramento: verificaObjetoParticipante(subclasse.agenteMonitoramento),
        agenteFormalizacao: verificaObjetoParticipante(subclasse.agenteFormalizacao),
        assessorLegal: verificaObjetoParticipante(subclasse.assessorLegal),
        agenteCobrancaJudicial: verificaObjetoParticipante(subclasse.agenteCobrancaJudicial),
        agenteCobrancaExtrajudicial: verificaObjetoParticipante(subclasse.agenteCobrancaExtrajudicial),
        auditoria: verificaObjetoParticipante(subclasse.auditoria),
    };
}
