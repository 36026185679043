import React from 'react';
import { Route, Switch } from "react-router-dom";
 
import { 
    PATRIMONIO_ROUTE_PATH, 
    NOVO_ROUTE_PATH, 
    EDITAR_ROUTE_PATH, 
    DETALHAR_ROUTE_PATH 
} from "../../../common/routers/constantesRoutes";

import Patrimonio from '../pages/Patrimonio';
import PatrimonioFormInclude from '../pages/form/PatrimonioFormInclude';
import PatrimonioFormUpdate from '../pages/form/PatrimonioFormUpdate';
import PatrimonioFormVisualize from '../pages/form/PatrimonioFormVisualize';

export default () => (
    <Switch>
        <Route exact={true} path={`/${PATRIMONIO_ROUTE_PATH}`} component={Patrimonio} />
        <Route exact={true} path={`/${PATRIMONIO_ROUTE_PATH}/${NOVO_ROUTE_PATH}`} component={PatrimonioFormInclude} />
        <Route exact={true} path={`/${PATRIMONIO_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/:idPatrimonio`} component={PatrimonioFormUpdate} />
        <Route exact={true} path={`/${PATRIMONIO_ROUTE_PATH}/${DETALHAR_ROUTE_PATH}/:idPatrimonio`} component={PatrimonioFormVisualize} />
    </Switch>
);