import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { withStyles } from "@material-ui/core/styles";

import { getDateFormatedFromString } from "../../../../../../utils/helpers/DateTimeHelper";
import Table from "../../../../../common/table/Table";
import TableHeader from "../../../../../common/table/TableHeader";
import TableContent from "../../../../../common/table/TableContent";
import { convertCurrencyNumber } from "../../../../../../utils/helpers/NumberHelper";

const styles = (theme) => ({});

class FundoSublassesFundo extends Component {
  render() {
    const { classes, dataList } = this.props;

    return (
      <div className={`table-responsive ${classes.root}`}>
        <Table
          withoutHeader
          classes="border-0 shadow-none col-12 my-0 pd-10 bg-white"
        >
          <TableHeader>
            <tr>
              <th className={`text-center fw-700`}>Classe</th>
              <th className={`text-center fw-700`}>Subclasse</th>
              <th className={`text-center fw-700`}>Data de Emissão</th>
              <th className={`text-center fw-700`}>Taxa de Gestão</th>
              <th className={`text-center fw-700`}>Taxa de Performance</th>
              <th className={`text-center fw-700`}>Total Distribuído</th>
              <th className={`text-center fw-700`}>Status</th>
            </tr>
          </TableHeader>
          <TableContent>
            {dataList.length > 0 ? (
              dataList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className={`text-center fw-400`}>
                      {item.classe || "--"}
                    </td>
                    <td className={`text-center fw-400`}>
                      {item.subClasse || "--"} {item?.codigoSubClasse || ""}
                    </td>
                    <td className={`text-center fw-400`}>
                      {getDateFormatedFromString(item.dataEmissao) || "--"}
                    </td>
                    <td className={`text-center fw-400`}>
                      {item.taxaGestao || "--"}
                    </td>
                    <td className={`text-center fw-400`}>
                      {item.taxaPerformance || "--"}
                    </td>
                    <td className={`text-center fw-400`}>
                      {convertCurrencyNumber(item.totalDistribuido, "R$", 2) || "--"}
                    </td>
                    <td className={`text-center fw-400`}>
                      {item?.status.value || "--"}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={12}
                  align="center"
                  className={`text-center fw-400`}
                >
                  Não há subclasses
                </td>
              </tr>
            )}
          </TableContent>
        </Table>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

FundoSublassesFundo = connect(null, mapDispatchToProps)(FundoSublassesFundo);

export default withStyles(styles)(FundoSublassesFundo);
