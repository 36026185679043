import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const MyTabs = ({ tabs, onTabChange, tabSelected, ...tabsProps }) => {
  const [value, setValue] = useState(tabSelected);

  const handleChange = (e, val) => {
    setValue(val);
    onTabChange && onTabChange(val);
  };

  return (
    <Tabs value={value} onChange={handleChange} {...tabsProps}>
      {tabs.map((tab, index) => (
        <Tab
          key={index} 
          {...tab.props} 
          label={tab.label} 
          disabled={tab.disabled} 
          className={!tab.show ? 'd-none pe-none' : ''}
        />
      ))}
    </Tabs>
  );
};

export default MyTabs;
