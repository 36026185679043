/* eslint no-loop-func: 0 */
/* eslint no-useless-concat: 0 */
/* eslint eqeqeq: 0 */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
// import { NumberUtils } from 'rb-core/lib/Utils';

import { backgroundColors, borderColors } from './chartColors';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';

import { getPagamentos } from '../../../../../../actions/operacao/DashboardAction';
import { convertCurrencyNumber } from '../../../../../../utils/helpers/NumberHelper';

class PagamentosChart extends Component {
    constructor(props) {
        super(props);
        // Initialize state
        this.state = {
            moedaSelected: ""
        };
    }

    render() {
        const {
            statusPagamentoChartList,
            pagamentosChartData,
            moedas,
            operacoes,
            getPagamentos
        } = this.props;

        const groupedDataSorted = {};

        const selectedFilter = pagamentosChartData[0]?.moeda || "";

        if (statusPagamentoChartList && statusPagamentoChartList.length !== 0 && pagamentosChartData && pagamentosChartData.length !== 0) {
            // Criação de um objeto inicial agrupando por categorias
            let minDate = moment().subtract(6, 'months').format('YYYYMM');
            const maxDate = moment().add(6, 'months').format('YYYYMM');
            const initialGroupedData = statusPagamentoChartList.reduce((acc, item) => {
                return {
                    ...acc,
                    [item]: {},
                }
            }, {});

            // Injeta dados nulos quando algum dado está faltando em algum período
            while (minDate <= maxDate) {
                Object.keys(initialGroupedData).forEach(key => {
                    initialGroupedData[key][minDate] = {
                        totalJurosPago: 0,
                        totalAmortizacao: 0,
                        totalAmex: 0,
                        totalPremio: 0,
                    };
                });

                minDate = moment(minDate, 'YYYYMM').add(1, 'months').format('YYYYMM');
            }

            // Agrupamento dos dados de acordo com as categorias
            const groupedData = pagamentosChartData.reduce((accumulator, item) => {
                accumulator[item.status][item.mesAnoPagamento] = {
                    totalJurosPago: item.totalJurosPago,
                    totalAmortizacao: item.totalAmortizacao,
                    totalAmex: item.totalAmex,
                    totalPremio: item.totalPremio,
                };
                return accumulator;
            }, initialGroupedData);

            // Ordenação dos dados por data
            Object.keys(groupedData).forEach((key) => {
                const orderedObj = Object.keys(groupedData[key]).sort().reduce(
                    (acc, date) => {
                        acc[date] = groupedData[key][date]; 
                        return acc;
                    },
                    {}
                );

                groupedDataSorted[key] = orderedObj;
            });
        }

        const unformattedLabels = Object.keys(groupedDataSorted).length > 0
            ? Object.keys(groupedDataSorted[Object.keys(groupedDataSorted)[0]])
            : [];

        const labels = unformattedLabels.map(dateStr => moment(dateStr, 'YYYYMM').format('MMM/YY'));

        let datasetCount = 0;
        const datasets = Object.keys(groupedDataSorted).map(key => {
            datasetCount += 1;
            return {
                label: key,
                data: Object.values(groupedDataSorted[key]).map(item => {
                    return item.totalJurosPago + item.totalAmortizacao + item.totalAmex + item.totalPremio;
                }),
                fill: false,
                backgroundColor: backgroundColors[datasetCount-1],
                borderColor: borderColors[datasetCount-1],
            };
        });

        const data = {
            labels: labels,
            datasets: datasets,
        };

        const prefix = selectedFilter || "R$";
        
        const options = {
            aspectRatio: 1.8,
            devicePixelRatio: 4,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                    beginAtZero: true,
                    grace: '1%',
                    ticks: {
                        precision: 0,
                        callback: function(value, index, values) {
                            return convertCurrencyNumber(value, `${prefix} `, 2)
                        },
                    },
                },
            },
            interaction: {
                mode: 'nearest',
                // intersect: false,
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            var label = context.dataset.label || '';
    
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += `${prefix} ` + new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL' }).format(context.parsed.y);
                            }
                            return label;
                        },
                        afterLabel: (context) => {
                            const data = groupedDataSorted[context.dataset.label][unformattedLabels[context.parsed.x]];
                            return (data.totalJurosPago ? ('\n' + 'Juros: ' + `${prefix} ` + new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL' }).format(data.totalJurosPago)) : '')
                                + (data.totalAmortizacao ? ('\n' + 'Amortização: ' + `${prefix} ` + new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL' }).format(data.totalAmortizacao)) : '')
                                + (data.totalAmex ? ('\n' + 'AMEX: ' + `${prefix} ` + new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL' }).format(data.totalAmex)) : '')
                                + (data.totalPremio ? ('\n' + 'Prêmio: ' +  `${prefix} ` + new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL' }).format(data.totalPremio)) : '');
                        },
                    },
                },
                legend: {
                    labels: {
                        boxWidth: 12,
                    },
                },
            },
        };

        const LineChart = () => (
            <>
                <div className='header d-flex flex-row justify-content-between'>
                    <h1 className='chart-title fw-400'>Pagamentos</h1>
                    <FormControl variant="standard">
                        <InputLabel id="demo-simple-select-standard-label">Moeda</InputLabel>
                        <Select
                            id="filter-mutiple-checkbox"
                            value={selectedFilter}
                            style={{ minWidth: 80, maxWidth: 120 }}
                            disabled={pagamentosChartData.length === 0}
                            onChange={event => selectedFilter != event.target.value ? getPagamentos(operacoes, null, event.target.value) : null }
                        >
                            { moedas && moedas.length > 0 &&
                                moedas.map((item, index) =>
                                    <MenuItem key={index} value={item.moeda}>
                                        {item.moeda}
                                    </MenuItem>
                                ) 
                            }
                        </Select>
                    </FormControl>
                </div>
                <Bar type="bar" data={data} options={options} />
            </>
        );
          

        return (
            <div className="shadow-custom rounded-12 p-3 bg-white">
                <div className="internal-aspect-ratio">
                    <LineChart />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    statusPagamentoChartList: state.dashboardReducer.statusPagamentoChartList,
    pagamentosChartData: state.dashboardReducer.pagamentosChartData,
    moedas: state.dashboardReducer.saldoValue || [],
});

const mapDispacthToProps = dispatch => bindActionCreators({
    getPagamentos,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(PagamentosChart));
