/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
/* no-mixed-operators: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";

import Button from "../../../../common/button/Button";

import {
  salvaFiltrosRemessa,
  getRemessa,
  createRemessa,
  loadFilterFormRemessa,
} from "../../../../../actions/remessa/RemessaAction";

import {
  getOperacoes,
  selectOperacaoNoForm,
} from "../../../../../actions/operacao/OperacaoAction";
import { DropdownList } from "react-widgets";
import { CREATE, REMESSA } from "../../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import Input from "../../../../common/input/Input";

let RemessaListFilter = (props) => {
  const {
    getRemessa,
    createRemessa,
    loadFilterFormRemessa,
    salvaFiltrosRemessa,
    remessaConsult,
    statusList,
    authorizationFunction,
  } = props;

  const [identificador, setIdentificador] = React.useState("");
  const [dataInicio, setDataInicio] = React.useState("");
  const [dataFinal, setDataFinal] = React.useState("");
  const [status, setStatus] = React.useState(null);

  function searchFilters() {
    const data = {
      status,
      identificador,
      dataInicio,
      dataFinal,
    };

    getRemessa(data);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      await loadFilterFormRemessa();
      if (!remessaConsult) searchFilters();
    };
  
    fetchData();
  }, []);

  // prettier-ignore
  React.useEffect(() => {
    salvaFiltrosRemessa({
      status,
      identificador,
      dataInicio,
      dataFinal,
    })

  }, [status, identificador, dataInicio, dataFinal]);

  const messages = {
    emptyList: "Nenhum registro encontrado",
    emptyFilter: "Nenhum registro encontrado com esse filtro",
  };

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Arquivos de Remessa"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getRemessa}
        >
          <div className="row w-100 align-items-start mx-0 mt-2">
            <div className="col-xl align-self-start px-0 pr-xl-3">
              <Field
                component={Input}
                inputId="identificador"
                name="identificador"
                label="Arquivo Remessa"
                value={identificador}
                onChange={(e_, v) =>  setIdentificador(v)}
              />
            </div>
            
            <div className="col-xl align-self-start px-0 pr-xl-3 mt-1">
              <div className="d-flex flex-column mt-2px">
                <label className="f-10-5 mb-1 lh-normal opacity-25 text-dark">
                  Status
                </label>
                <DropdownList
                  value={status?.key ? status : ""}
                  valueField="key"
                  textField="value"
                  data={[
                    { key: null, value: "Selecione..." },
                    ...statusList
                  ]}
                  placeholder="Todos"
                  containerClassName="h-38-imp"
                  messages={messages}
                  onChange={(e) => setStatus(e)}
                />
              </div>
            </div>

            <div className="col-xl align-self-start px-0 pr-xl-3 mt-3 mt-xl-0">
              <Field
                component={Input}
                inputId="dataInicio"
                type={"date"}
                name="dataInicio"
                label="Data Início"
                value={dataInicio}
                onChange={(e_, v) => setDataInicio(v)}
              />
            </div>

            <div className="col-xl align-self-start px-0">
              <Field
                component={Input}
                inputId="dataFinal"
                type={"date"}
                name="dataFinal"
                label="Data Final"
                value={dataFinal}
                onChange={(e_, v) => setDataFinal(v)}
              />
            </div>
          </div>

          <div className="row w-100 mx-0">
            <div className="col-xl-2 col-12 px-0 pr-xl-3">
              <Button
                activeClassName="w-100"
                action={() => searchFilters()}
                classes="p-bg-imp rounded btn-search h-38 mt-3 w-100 d-flex justify-content-center"
                name={"Buscar"}
              />
            </div>
            <div className="col-xl-2 col-12 px-0">
              <Button
                activeClassName="w-100"
                action={() =>
                  authorizationFunction({
                      idAcao: CREATE,
                      idFuncionalidade: REMESSA,
                      validarAreaAtuacao: true,
                  }, () => createRemessa())
                }
                classes="darkblue-bg-imp rounded btn-search h-38 mt-3 w-100 d-flex justify-content-center align-items-center"
                icon="plus-circle mr-2"
                nameClass="mt-2px text-capitalize f-14"
                name={"Importar Arquivo"}
              />
            </div>
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

RemessaListFilter = reduxForm({ form: "RemessaForm" })(RemessaListFilter);

const mapStateToProps = (state) => ({
  statusList: state.remessaReducer.remessaFormTypesFilter.statusList || [],
  remessaConsult: state.remessaReducer.remessaConsultCurrent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRemessa,
      createRemessa,
      loadFilterFormRemessa,
      salvaFiltrosRemessa,
      getOperacoes,
      selectOperacaoNoForm,
      authorizationFunction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemessaListFilter);
