import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, change } from "redux-form";
import { bindActionCreators } from "redux";
import BootstrapTable from "react-bootstrap-table-next";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import {
  uploadCurvaEventoFile,
  removeCurvaFromTable,
  editCurvaFromTable,
  saveData,
  cancelEditCurvaPagamento,
  handleChangeDataEventoCurvaPagamento,
  handleChangePercentualPagamentoAmortizacao,
  KEY_TIPO_EVENTO_ATUALIZADO,
  PAGAMENTO_AMORTIZACAO_NAO_HA,
  updateTablePagination,
  exportModeloCurvaPagamento,
} from "../../../../../../../actions/operacao/PassivoAction";

import If from "../../../../../../common/If/If";
import Input from "../../../../../../common/input/Input";
import FileInput from "../../../../../../common/input/FileInput";
import MaskedInput from "../../../../../../common/input/MaskedInput";
import DropdownListInput from "../../../../../../common/input/DropdownListInput";

import {
  convertCurrencyNumber,
  formatPercent,
} from "../../../../../../../utils/helpers/NumberHelper";

import { getDateFormatedFromString } from "../../../../../../../utils/helpers/DateTimeHelper";
import PaginationRB from "../../../../../../common/pagination/PaginationRB";
import Button from "../../../../../../common/button/Button";

const TIPO_PAGAMENTO_CORRECAO_MONETARIA = 3;

const PAGAMENTO_JUROS_TOTAL = 3,
  PAGAMENTO_JUROS_PARCIAL = 6,
  PAGAMENTO_JUROS_INCORPORACAO_TOTAL = 9,
  PAGAMENTO_JUROS_CARREGAR_JUROS = 12;

const PAGAMENTO_JUROS_INCORPORACAO_TOTAL_RAW = "IncorporacaoTotal",
  PAGAMENTO_AMORTIZACAO_NAO_HA_RAW = "NaoHa";

const PAGAMENTO_AMORTIZACAO_PAGAMENTO = 3;

const CORRECAO_MONETARIA_SEM_ATUALIZACAO_KEY = 1;
const PAGAMENTO_CORRECAO_MONETARIA_NAO_HA_KEY = 1;

const PassivoFormCurvaPagamentos = (props) => {

  const {
    exportModeloCurvaPagamento
  } = props;

  const handleChangePercentualPagamento = (
    idCurva,
    key,
    curvaPagamentoPassivoList,
    curvasPagamentosOriginais
  ) => {
    let nameField = `curvaPagamentoToEdit.enableField`;
    props.change("passivoForm", nameField, false);

    if (!props.readOnly) {
      let value = key === PAGAMENTO_JUROS_TOTAL ? 100 : 0;

      if (
        key === PAGAMENTO_JUROS_PARCIAL ||
        key === PAGAMENTO_JUROS_CARREGAR_JUROS
      ) {
        props.change("passivoForm", nameField, true);
      }

      props.change(
        "passivoForm",
        `curvaPagamentoToEdit.percentualPagamento`,
        value
      );
      if (
        props.tipoPagamentoPassivo &&
        props.tipoPagamentoPassivo.key === TIPO_PAGAMENTO_CORRECAO_MONETARIA &&
        key === PAGAMENTO_JUROS_CARREGAR_JUROS
      ) {
        let opcaoCorrecaoMonetaria = props.correcaoMonetariaList.find(
          (obj) => obj.key === CORRECAO_MONETARIA_SEM_ATUALIZACAO_KEY
        );

        let opcaoPagamentoCorrecaoMonetaria =
          props.pagamentoCorrecaoMonetariaList.find(
            (obj) => obj.key === PAGAMENTO_CORRECAO_MONETARIA_NAO_HA_KEY
          );

        props.change(
          "passivoForm",
          "curvaPagamentoToEdit.correcaoMonetaria",
          opcaoCorrecaoMonetaria
        );
        props.change(
          "passivoForm",
          "curvaPagamentoToEdit.pagamentoCorrecaoMonetaria",
          opcaoPagamentoCorrecaoMonetaria
        );
      }

      updateAmexAndPerdaValues(
        idCurva,
        key,
        curvaPagamentoPassivoList,
        curvasPagamentosOriginais
      );
    }
  };

  const updateAmexAndPerdaValues = (
    idCurva,
    keyAtual,
    curvaPagamentoPassivoList,
    curvasPagamentosOriginais
  ) => {
    let values = null;
    const index = curvaPagamentoPassivoList.findIndex(
      (curva) => curva.id === idCurva
    );

    if (index > 0) {
      const pagamentoJurosAnterior = curvaPagamentoPassivoList[index]
        .pagamentoJuros
        ? curvaPagamentoPassivoList[index].pagamentoJuros.key
        : null;

      if (keyAtual === PAGAMENTO_JUROS_CARREGAR_JUROS) {
        values = {
          amex: 0.0,
          perda: 0.0,
        };
      } else if (
        keyAtual === pagamentoJurosAnterior &&
        keyAtual !== PAGAMENTO_JUROS_CARREGAR_JUROS &&
        curvasPagamentosOriginais !== null &&
        curvasPagamentosOriginais.length > 0
      ) {
        const indexOriginal = curvasPagamentosOriginais.findIndex(
          (curva) => curva.id === idCurva
        );
        values = {
          amex: curvasPagamentosOriginais[indexOriginal].amex || 0.0,
          perda: curvasPagamentosOriginais[indexOriginal].perda || 0.0,
        };
      }

      if (values != null) {
        props.change("passivoForm", `curvaPagamentoToEdit.amex`, values.amex);
        props.change("passivoForm", `curvaPagamentoToEdit.perda`, values.perda);
      }
    }
  };

  const handleChangeTivoEvento = (
    idCurva,
    keyAtual,
    dataEvento,
    curvaPagamentopassivoList,
    curvasPagamentosOriginais
  ) => {
    let values = null;
    const index = curvaPagamentopassivoList.findIndex(
      (curva) => curva.id === idCurva
    );

    if (index >= 0) {
      const tipoEventoAnterior = curvaPagamentopassivoList[index].tipoEvento
        ? curvaPagamentopassivoList[index].tipoEvento.key
        : null;

      if (keyAtual === KEY_TIPO_EVENTO_ATUALIZADO) {
        values = {
          pagamentoJuros: {
            key: PAGAMENTO_JUROS_INCORPORACAO_TOTAL,
            raw: PAGAMENTO_JUROS_INCORPORACAO_TOTAL_RAW,
          },
          pagamentoAmortizacao: {
            key: PAGAMENTO_AMORTIZACAO_NAO_HA,
            raw: PAGAMENTO_AMORTIZACAO_NAO_HA_RAW,
          },
          percentualPagamento: 0.0,
          dataPagamento: dataEvento,
        };
      } else if (
        tipoEventoAnterior === KEY_TIPO_EVENTO_ATUALIZADO &&
        keyAtual !== KEY_TIPO_EVENTO_ATUALIZADO
      ) {
        if (
          curvasPagamentosOriginais !== null &&
          curvasPagamentosOriginais.length > 0
        ) {
          values = {
            pagamentoJuros: curvasPagamentosOriginais[index].pagamentoJuros,
            pagamentoAmortizacao:
              curvasPagamentosOriginais[index].pagamentoAmortizacao,
            percentualPagamento:
              curvasPagamentosOriginais[index].percentualPagamento,
            dataPagamento: curvasPagamentosOriginais[index].dataPagamento,
          };
        }
      }
    }

    if (values != null) {
      onChanteTipoEventoUpdateFields(values);
    }
  };

  const onChanteTipoEventoUpdateFields = (values) => {
    props.change(
      "passivoForm",
      `curvaPagamentoToEdit.pagamentoJuros`,
      values.pagamentoJuros
    );
    props.change(
      "passivoForm",
      `curvaPagamentoToEdit.pagamentoAmortizacao`,
      values.pagamentoAmortizacao
    );
    props.change(
      "passivoForm",
      `curvaPagamentoToEdit.percentualPagamento`,
      values.percentualPagamento
    );
    props.change(
      "passivoForm",
      `curvaPagamentoToEdit.dataPagamento`,
      values.dataPagamento
    );
  };

  const passivoPagamentoUploadCurvaEventos = (
    <If test={!props.readOnly}>
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">
            Upload de Curva de Pagamento
          </span>
          <div className="row mt-3">
            <div className="col-md-6">
              <FileInput
                id="inputFileCurva"
                accept=".xlsx, .xls"
                className="inputfile inputfile-1"
                placeholder="Informe um arquivo da extensão .CSV"
                onChange={async (e) => {
                  await props.uploadCurvaEventoFile(e.target.files[0],props.curvaPagamentoPassivoTodosList);
                  document.getElementById("inputFileCurva").value = "";
                }}
              />
            </div>
          </div>

          <div className="mt-3 w-mx-cnt">
            <span
              role="button"
              onClick={() => exportModeloCurvaPagamento()}
              className="text-underline txt-blue-link fw-400"
            >
                Baixar Planilha Modelo
            </span>
          </div>
        </div>
      </div>
    </If>
  );

  const columns = [
    {
      dataField: "acoes",
      text: "Ações",
      formatter: (cell, row) => (
        <React.Fragment>
          <EditIcon
            disabled
            className="pr-2"
            onClick={() => props.editCurvaFromTable({ ...row })}
          />
          <DeleteOutlineIcon
            disabled
            onClick={() => props.removeCurvaFromTable(row["id"])}
          />
        </React.Fragment>
      ),
      hidden: props.readOnly,
    },
    {
      dataField: "tipoEvento.value",
      text: "Tipo Evento",
    },
    {
      dataField: "dataEvento",
      text: "Data do Evento/Aniversário",
      formatter: (cell) => getDateFormatedFromString(cell),
    },
    {
      dataField: "dataPagamento",
      text: "Data de Pagamento",
      formatter: (cell) => getDateFormatedFromString(cell),
    },
    {
      dataField: "id",
      text: "Id",
      hidden: true,
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <div className="row">
        <div className="col-4">
          <p>
            <strong>Tipo Evento: </strong>
            {row.tipoEvento ? row.tipoEvento.value : ""}
          </p>
        </div>
        <div className="col-4">
          <p>
            <strong>Data do Evento/Aniversário: </strong>
            {row.dataEvento ? getDateFormatedFromString(row.dataEvento) : ""}
          </p>
        </div>
        <div className="col-4">
          <p>
            <strong>Data de Pagamento: </strong>
            {row.dataEvento ? getDateFormatedFromString(row.dataEvento) : ""}
          </p>
        </div>
        <div className="col-4">
          <p>
            <strong>Pagamento Juros: </strong>
            {row.pagamentoJuros ? row.pagamentoJuros.value : ""}
          </p>
        </div>
        <div className="col-4">
          <p>
            <strong>Percentual de Pagamento de Juros: </strong>
            {row.percentualPagamento
              ? formatPercent(row.percentualPagamento, 16)
              : ""}
          </p>
        </div>
        <div className="col-4">
          <p>
            <strong>Pagamento Amortização: </strong>
            {row.pagamentoAmortizacao ? row.pagamentoAmortizacao.value : ""}
          </p>
        </div>
        <div className="col-4">
          <p>
            <strong>Percentual de Pagamento de Amortização: </strong>
            {row.percentualPagamentoAmortizacao
              ? formatPercent(row.percentualPagamentoAmortizacao, 16)
              : ""}
          </p>
        </div>

        <If
          test={
            props.tipoPagamentoPassivo &&
            props.tipoPagamentoPassivo.key === TIPO_PAGAMENTO_CORRECAO_MONETARIA
          }
        >
          <div className="col-4">
            <p>
              <strong>Correção Monetária: </strong>
              {row.correcaoMonetaria ? row.correcaoMonetaria.value : ""}
            </p>
          </div>
          <div className="col-4">
            <p>
              <strong>Pagamento Correção Monetária: </strong>
              {row.pagamentoCorrecaoMonetaria
                ? row.pagamentoCorrecaoMonetaria.value
                : ""}
            </p>
          </div>
        </If>

        <div className="col-4">
          <p>
            <strong>Amex: </strong>
            {row.amex ? convertCurrencyNumber(row.amex, true, 8) : 0.0}
          </p>
        </div>
        <div className="col-4">
          <p>
            <strong>Perda: </strong>
            {row.perda ? convertCurrencyNumber(row.perda, true, 8) : 0.0}
          </p>
        </div>
        <div className="col-4">
          <p>
            <strong>Prêmio: </strong>
            {row.premio ? convertCurrencyNumber(row.premio, true, 8) : 0.0}
          </p>
        </div>
      </div>
    ),
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <b>-</b>;
      }
      return <b>+</b>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <b>-</b>;
      }
      return <b>...</b>;
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    parentClassName: "bg-light",
  };

  let moedaSimbolo =
    props.moedaDinamica || props.passivoOperacao?.moeda?.simbolo || "R$";

  const passivoPagamentoCurvaPagamento = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">Curva de Pagamento</span>
        <If test={!props.readOnly}>
          <div className="d-flex mt-4 w-100">
            <div className="col more-fields p-0">
              <div className="more-fields-item">
                <div className="d-flex flex-column">
                  <div className="row">
                    <div className="col-xl-3 col-lg-4">
                      <Field
                        component={DropdownListInput}
                        inputId="tipoEvento"
                        name="curvaPagamentoToEdit.tipoEvento"
                        label="Tipo Evento"
                        options={props.pagamentoTipoEventoList}
                        textField="value"
                        valueField="key"
                        readOnly={props.readOnly}
                        filter="contains"
                        onChange={(e) =>
                          handleChangeTivoEvento(
                            props.curvaPagamentoToEdit.id,
                            e.key,
                            props.curvaPagamentoToEdit.dataEvento,
                            props.curvaPagamentoPassivoList,
                            props.curvasPagamentosOriginais
                          )
                        }
                      />
                    </div>

                    <div className="col-xl-3 col-lg-4">
                      <Field
                        component={Input}
                        inputId="dataEvento"
                        name="curvaPagamentoToEdit.dataEvento"
                        type="date"
                        required={true}
                        label="Data do Evento/Aniversário"
                        readOnly={props.readOnly}
                        onBlur={(e) =>
                          props.handleChangeDataEventoCurvaPagamento(
                            e,
                            props.curvaPagamentoToEdit.tipoEvento
                          )
                        }
                        blur
                      />
                    </div>

                    <div className="col-xl-3 col-lg-4">
                      <Field
                        component={Input}
                        inputId="dataPagamento"
                        name="curvaPagamentoToEdit.dataPagamento"
                        type="date"
                        required={true}
                        label="Data de Pagamento"
                        readOnly={
                          props.readOnly
                            ? props.readOnly
                            : props.curvaPagamentoToEdit.tipoEvento &&
                              props.curvaPagamentoToEdit.tipoEvento.key ===
                                KEY_TIPO_EVENTO_ATUALIZADO
                        }
                      />
                    </div>

                    <div className="col-xl-3 col-lg-4">
                      <Field
                        component={DropdownListInput}
                        inputId="pagamentoJuros"
                        name="curvaPagamentoToEdit.pagamentoJuros"
                        label="Pagamento Juros"
                        options={props.pagamentoJurosList}
                        textField="value"
                        valueField="key"
                        readOnly={
                          props.readOnly
                            ? props.readOnly
                            : props.curvaPagamentoToEdit.tipoEvento &&
                              props.curvaPagamentoToEdit.tipoEvento.key ===
                                KEY_TIPO_EVENTO_ATUALIZADO
                        }
                        filter="contains"
                        onChange={(e) =>
                          handleChangePercentualPagamento(
                            props.curvaPagamentoToEdit.id,
                            e.key,
                            props.curvaPagamentoPassivoList,
                            props.curvasPagamentosOriginais
                          )
                        }
                      />
                    </div>

                    <div className="col-xl-3 col-lg-4">
                      <Field
                        component={MaskedInput}
                        inputId="percentualPagamento"
                        name="curvaPagamentoToEdit.percentualPagamento"
                        label="Percentual de Pagamento de Juros"
                        readOnly={
                          !props.readOnly
                            ? props.curvaPagamentoToEdit.enableField
                              ? false
                              : true
                            : props.readOnly
                        }
                        suffix={"%"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={16}
                        fixedDecimalScale
                      />
                    </div>

                    <div className="col-xl-3 col-lg-4">
                      <Field
                        component={DropdownListInput}
                        inputId="pagamentoAmortizacao"
                        name="curvaPagamentoToEdit.pagamentoAmortizacao"
                        label="Pagamento Amortização"
                        options={props.pagamentoAmortizacaoList}
                        textField="value"
                        valueField="key"
                        readOnly={
                          props.readOnly
                            ? props.readOnly
                            : props.curvaPagamentoToEdit.tipoEvento &&
                              props.curvaPagamentoToEdit.tipoEvento.key ===
                                KEY_TIPO_EVENTO_ATUALIZADO
                        }
                        filter="contains"
                        onChange={(e) =>
                          props.handleChangePercentualPagamentoAmortizacao(
                            e.key
                          )
                        }
                      />
                    </div>

                    <div className="col-xl-3 col-lg-4">
                      <Field
                        component={MaskedInput}
                        inputId="percentualPagamentoAmortizacao"
                        name="curvaPagamentoToEdit.percentualPagamentoAmortizacao"
                        classLabel="text-nowrap"
                        label="Percentual de Pagamento de Amortização"
                        readOnly={
                          props.readOnly
                            ? props.readOnly
                            : (props.curvaPagamentoToEdit
                                .pagamentoAmortizacao &&
                                props.curvaPagamentoToEdit.pagamentoAmortizacao
                                  .key !== PAGAMENTO_AMORTIZACAO_PAGAMENTO) ||
                              (props.curvaPagamentoToEdit.tipoEvento &&
                                props.curvaPagamentoToEdit.tipoEvento.key ===
                                  KEY_TIPO_EVENTO_ATUALIZADO)
                            ? true
                            : false
                        }
                        suffix={"%"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={16}
                        fixedDecimalScale
                      />
                    </div>

                    <If
                      test={
                        props.tipoPagamentoPassivo &&
                        props.tipoPagamentoPassivo.key ===
                          TIPO_PAGAMENTO_CORRECAO_MONETARIA
                      }
                    >
                      <div className="col-xl-3 col-lg-4">
                        <Field
                          component={DropdownListInput}
                          inputId="correcaoMonetaria"
                          name="curvaPagamentoToEdit.correcaoMonetaria"
                          label="Correção Monetária"
                          options={props.correcaoMonetariaList}
                          textField="value"
                          valueField="key"
                          readOnly={props.readOnly}
                          filter="contains"
                        />
                      </div>

                      <div className="col-xl-3 col-lg-4">
                        <Field
                          component={DropdownListInput}
                          inputId="pagamentoCorrecaoMonetaria"
                          name="curvaPagamentoToEdit.pagamentoCorrecaoMonetaria"
                          label="Pagamento Correção Monetária"
                          options={props.pagamentoCorrecaoMonetariaList}
                          textField="value"
                          valueField="key"
                          readOnly={props.readOnly}
                          filter="contains"
                        />
                      </div>
                    </If>
                    <div className="col-xl-3 col-lg-4">
                      <Field
                        component={MaskedInput}
                        inputId="amex"
                        name="curvaPagamentoToEdit.amex"
                        label="Amex"
                        readOnly={
                          props.readOnly ||
                          (props.curvaPagamentoToEdit.pagamentoJuros &&
                            props.curvaPagamentoToEdit.pagamentoJuros.key ===
                              PAGAMENTO_JUROS_CARREGAR_JUROS)
                        }
                        prefix={`${moedaSimbolo} `}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={8}
                        fixedDecimalScale
                        placeholder={"R$ 0,00000000"}
                        allowNegative={false}
                      />
                    </div>
                    <div className="col-xl-3 col-lg-4">
                      <Field
                        component={MaskedInput}
                        inputId="perda"
                        name="curvaPagamentoToEdit.perda"
                        label="Perda"
                        readOnly={
                          props.readOnly ||
                          (props.curvaPagamentoToEdit.pagamentoJuros &&
                            props.curvaPagamentoToEdit.pagamentoJuros.key ===
                              PAGAMENTO_JUROS_CARREGAR_JUROS)
                        }
                        prefix={`${moedaSimbolo} `}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={8}
                        fixedDecimalScale
                        placeholder={"R$ 0,00000000"}
                      />
                    </div>
                    <div className="col-xl-3 col-lg-4">
                      <Field
                        component={MaskedInput}
                        inputId="premio"
                        name="curvaPagamentoToEdit.premio"
                        label="Prêmio"
                        readOnly={props.readOnly}
                        prefix={`${moedaSimbolo} `}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={8}
                        fixedDecimalScale
                        placeholder={"R$ 0,00000000"}
                        allowNegative={false}
                      />
                    </div>

                    <div className="col-12 col-lg-2 d-flex align-items-center minus-box-btn">
                      <Button
                        classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize"
                        activeClassName={"col-lg-auto p-0"}
                        buttonStyle={"success"}
                        action={() => props.cancelEditCurvaPagamento()}
                        icon={"close"}
                        name={"Remover"}
                      />
                    </div>
                  </div>

                  <div className="mx-auto mt-3">
                    <Button
                      classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                      activeClassName={"col-lg-auto p-0"}
                      buttonStyle={"success"}
                      action={() => props.saveData()}
                      icon={"plus"}
                      name={"Adicionar"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </If>
        <div className="d-inline-flex justify-content-center w-100 mt-4">
          <div>
            <BootstrapTable
              keyField="id"
              data={props.curvaPagamentoPassivoList}
              columns={columns}
              expandRow={expandRow}
              noDataIndication={() => (
                <span>Nenhuma informação encontrada.</span>
              )}
            />
            <div className="d-flex justify-content-end">
              <PaginationRB
                pageNumber={props.curvaPaggingInfo.pageIndex - 1}
                itemsPerPage={props.curvaPaggingInfo.pageSize}
                totalItems={props.curvaPaggingInfo.totalCount || 0}
                pageRange={props.curvaPaggingInfo.pageRange}
                fetchFunction={props.updateTablePagination}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`d-flex flex-column mt-4`}>
      {passivoPagamentoUploadCurvaEventos}
      {passivoPagamentoCurvaPagamento}
    </div>
  );
};

const selector = formValueSelector("passivoForm");
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadCurvaEventoFile,
      change,
      removeCurvaFromTable,
      editCurvaFromTable,
      saveData,
      cancelEditCurvaPagamento,
      handleChangeDataEventoCurvaPagamento,
      handleChangePercentualPagamentoAmortizacao,
      updateTablePagination,
      exportModeloCurvaPagamento,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  tipoPagamentoPassivo: selector(
    state,
    "pagamentoPassivo.tipoPagamentoPassivo"
  ),
  curvaPagamentoPassivoList: selector(state, "curvaPaggingInfo.items") || [],
  curvaPaggingInfo: selector(state, "curvaPaggingInfo") || {},
  curvaPagamentoToEdit: selector(state, "curvaPagamentoToEdit") || {},
  pagamentoJurosList: state.passivoReducer.passivoFormTypes.pagamentoJurosList,
  pagamentoAmortizacaoList:
    state.passivoReducer.passivoFormTypes.pagamentoAmortizacaoList,
  curvasPagamentosOriginais:
    state.passivoReducer.passivoFormTypes.passivoOperacao !== null
      ? state.passivoReducer.passivoFormTypes?.passivoOperacao?.pagamentoPassivo?.curvaPagamentoPassivoList
      : [],
  correcaoMonetariaList:
    state.passivoReducer.passivoFormTypes.correcaoMonetariaList,
  pagamentoTipoEventoList: state.passivoReducer.passivoFormTypes.tipoEventoList,
  pagamentoCorrecaoMonetariaList:
    state.passivoReducer.passivoFormTypes.pagamentoCorrecaoMonetariaList,
  curvaPagamentoPassivoTodosList:
    selector(state, "pagamentoPassivo.curvaPagamentoPassivoList") || [],
  passivoOperacao: state.passivoReducer.passivoFormTypes.passivoOperacao || [],
  moedaDinamica: state.passivoReducer.moedaDinamica || null,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PassivoFormCurvaPagamentos);
