import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FundoForm from "./FundoForm";
import { getFundo } from "../../../../../actions/fundo/FundoAction";

class FundoFormVisualize extends Component {
  componentDidMount() {
    let idFundo = this.props.match.params.idFundo;
    this.props.getFundo(idFundo);
  }

  render() {
    return (
      <FundoForm
        title={"Detalhar - Fundo"}
        readOnly={true}
        floatButtonVisible={false}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFundo,
    },
    dispatch
  );

export default withRouter(
  connect(null, mapDispatchToProps)(FundoFormVisualize)
);
