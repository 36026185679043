import { getDateFormatedFromString } from "../../../../utils/helpers/DateTimeHelper";
import { convertCurrencyToNumber } from "../../../../utils/helpers/NumberHelper";
import { formatCNPJ } from "../../../../utils/helpers/StringHelper";

export function deserializeFieldsCotaPrecificacaoFormFilter(filters, page) {
    return {
        ...filters,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10,
        },
    };
}

export function deserializeFieldsCotaPrecificacaoFormFilterExportExcel(filters) {
    return {
        
    };
}

function normalizarCotas(cota) {
    const formatarValor = (valor) => parseFloat(valor?.toFixed(8));
    
    cota.valorCota = formatarValor(cota.valorCota);
    cota.valorCotaAnterior = formatarValor(cota.valorCotaAnterior);
    cota.valorCotaVariacao = formatarValor(cota.valorCotaVariacao);
    cota.valorBenchmark = formatarValor(cota.valorBenchmark);
    cota.valorBenchmarkAnterior = formatarValor(cota.valorBenchmarkAnterior);
    cota.valorBenchmarkVariacao = formatarValor(cota.valorBenchmarkVariacao);
  }

export function serializeFieldsCotaFluxo(cotaPrecificacao) {
    const { fundo, cotas, patrimonioLiquido, observacao, status, dataBase } = cotaPrecificacao;
    const { administradorFiduciario, cnpjDoFundo, dataDeConstituicao, dataDeVencimento } = fundo;

    for (let i = 0; i < cotas.length; i++) {
        normalizarCotas(cotas[i]);
    }

    return {
        administrador: administradorFiduciario?.nomeSimplificado,
        cnpjDoFundo: formatCNPJ(cnpjDoFundo),
        dataDeConstituicao: getDateFormatedFromString(dataDeConstituicao),
        dataDeVencimento: getDateFormatedFromString(dataDeVencimento),
        dataDeReferencia: getDateFormatedFromString(dataBase),
        cotas: cotas,
        patrimonioLiquido: patrimonioLiquido,
        observacao: observacao,
        status: status,
    };
}

export function deserializeFieldsCota(formValues) {
    const { cotas } = formValues;

    for (let i = 0; i < cotas.length; i++) {
        cotas[i].valorCota = convertCurrencyToNumber(cotas[i].valorCota) || 0;
        cotas[i].valorCotaAnterior = convertCurrencyToNumber(cotas[i].valorCotaAnterior) || 0;
        cotas[i].valorCotaVariacao = convertCurrencyToNumber(cotas[i].valorCotaVariacao) || 0;
        cotas[i].valorBenchmark = convertCurrencyToNumber(cotas[i].valorBenchmark) || 0;
        cotas[i].valorBenchmarkAnterior = convertCurrencyToNumber(cotas[i].valorBenchmarkAnterior) || 0;
        cotas[i].valorBenchmarkVariacao = convertCurrencyToNumber(cotas[i].valorBenchmarkVariacao) || 0;
    }

    return {
        ...formValues,
    }
}

export function deserializeFieldsCotaValidacao(params) {
    return {
        
    };
}

export function deserializeFieldsCotaValidacaoConfirmacao(params) {
    return {
        
    }
}

export function deserializeFieldsEtapaLancamento(params) {
    return {
       
    }
}

export function deserializeFieldsEtapaCota(formValues) {
    return {

    }
}

export function deserializeFieldsCotaAgendamento(params) {
    return {
       
    };
}