import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";

import { selectTab } from '../../../../../actions/operacao/tabs/TabsAction';

import OperacaoHeader from './../../../operacao/pages/OperacaoHeader';
import { OPERACAO_TABS, DESPESAS_TAB} from './../../../operacao/pages/OperacaoTabs';
import DespesaList from './../../../operacao/pages/despesa/list/DespesaList';
import ContainerPage from '../../../../common/container/ContainerPage';

class DespesaPage extends Component {
    componentDidMount() {
        this.props.selectTab(DESPESAS_TAB);
    }

    render() {
        const { 
            operacoes,
        } = this.props;
    
        const idsOperacoes = operacoes.map(o => o.key);
    
        return (
            <ContainerPage
                tabs={OPERACAO_TABS}
                paramsRoute={idsOperacoes}
                header={<OperacaoHeader />}
            >
                <DespesaList/>
            </ContainerPage>
        );
    }
}

const mapStateToProps = state => ({ 
    tab: state.tab,
    operacoes: state.operacaoReducer.selectedOperacoes, 
});

const mapDispacthToProps = dispatch => bindActionCreators({     
    selectTab,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(DespesaPage));
