/* eslint react-hooks/exhaustive-deps: 0 */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';

import {
  getDiariosBordo,
  loadFilterForm,
}
  from '../../../../../../actions/gestao/DiarioBordoAction';
import EventoDiarioBordoListView from './EventoDiarioBordoListView';

const CARGA_REGISTRO_INICIAL = 30
let contadorCargaDeBusca = 0


function EventoDiarioBordoListInfinity({ itemsToDisplay = CARGA_REGISTRO_INICIAL, diarioBordo, diariosBordoAno, getDiariosBordo, loadFilterForm, messages }) {

  React.useEffect(() => {
    const fetchData = async () => {
      await loadFilterForm();
      fetch(itemsToDisplay);
    };
  
    fetchData();
  }, []);

  async function fetch(count) {
    contadorCargaDeBusca += count;
    await getDiariosBordo(count)
  }

  const loadMoreRegister = () => diarioBordo && diarioBordo.items.length > 0 && fetch(diarioBordo.items.length + CARGA_REGISTRO_INICIAL);

  return (
    <div className='mt-2 px-3 overflow-auto' style={{ height: "calc(100vh - 470px)" }} >
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreRegister}
        hasMore={diarioBordo && diarioBordo.items.length !== diarioBordo.totalCount}
        loader={<div key={1}>{diarioBordo && diarioBordo.items.length === contadorCargaDeBusca ? "" : ""}</div>}
        threshold={50}
        useWindow={false}
      >
        {
          diariosBordoAno && diariosBordoAno.length > 0 
          ? (
            <EventoDiarioBordoListView
              diariosEncontrados={diariosBordoAno}
            />
          )            
          : (
            <h3 className='mt-5 mb-4 txt-grey d-flex px-4 w-100 text-center justify-content-center'>Nenhuma informação encontrada.</h3>
          )
        }
      </InfiniteScroll>
    </div>
  );
}

const mapStateToProps = state => ({
  diarioBordo: state.diarioBordoReducer.diariosBordo,
  diariosBordoAno: state.diarioBordoReducer.diariosBordoPorAno,
})

const mapDispacthToProps = dispatch => bindActionCreators({
  getDiariosBordo,
  loadFilterForm,
}, dispatch);


export default withRouter(connect(mapStateToProps, mapDispacthToProps)(EventoDiarioBordoListInfinity));