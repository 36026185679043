import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import ContainerPage from "../../../../../common/container/ContainerPage";
import OpcaoForm from "./form/SeloForm";
import { saveOpcao } from "../../../../../../actions/precificacao/OpcaoAction";

const SeloIncludePage = (props) => {
  const { saveOpcao } = props;

  return (
    <ContainerPage>
      <OpcaoForm
        title={"Novo - Selo"}
        readOnly={false}
        floatButtonVisible={true}
        onSubmit={(e) => {
          saveOpcao({
            ...e,
            extensaoArquivo: e?.nomeArquivo?.split(".").pop(),
          }, "selo")
        }}
      />
    </ContainerPage>
  );
};

const mapStateToProps = (state) => ({});

const mapDispacthToProps = (dispatch) => bindActionCreators(
  {
    saveOpcao,
  }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(SeloIncludePage)
);
