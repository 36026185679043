import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  editFundo,
  loadFormFundo,
} from "../../../../../actions/fundo/FundoAction";
import FundoForm from "./FundoForm";

class FundoFormUpdate extends Component {
  componentDidMount() {
    let idFundo = this.props.match.params.idFundo;
    this.props.loadFormFundo(idFundo, true);
  }

  render() {
    const { readOnly, editFundo } = this.props;

    return (
      <FundoForm
        title={"Editar - Fundo"}
        onSubmit={editFundo}
        readOnly={readOnly}
        floatButtonVisible={true}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editFundo,
      loadFormFundo,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispatchToProps)(FundoFormUpdate));
