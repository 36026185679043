import history from "../../../components/common/routers/history";

export const SIDEBAR_ITEM_SELECTED = 'SIDEBAR_ITEM_SELECTED';

export function selectSideBarItem(itemId = '', route = "/") {
    return (dispatch) => {
        return dispatch([
            {
                type: SIDEBAR_ITEM_SELECTED,
                payload: itemId
            },
            history.push(route),
        ]);
    }
}