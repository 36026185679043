/* eslint react-hooks/exhaustive-deps: 0 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FundoCotasEmitidas from "./FundoCotasEmitidas";

import { loadFormFundoCotas } from "../../../../../../actions/fundo/FundoAction";

const FundoFormCotasEmitidas = (props) => {
  const { readOnly, fundoCotas, loadFormFundoCotas, fundoId } = props;

  useEffect(() => {
    if (fundoId) loadFormFundoCotas(fundoId);
  }, []);

  let fundoCodigoB3Cetip = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4 mb-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">
          Cotas Emitidas pelo Fundo
        </span>
        <div>
          <FundoCotasEmitidas readOnly={readOnly} dataList={fundoId ? fundoCotas : []} />
        </div>
      </div>
    </div>
  );

  return <div className="d-flex flex-column mt-4">{fundoCodigoB3Cetip}</div>;
};

const mapStateToProps = (state) => ({
  fundoId: state.fundoReducer.fundoFormTypes?.fundo?.id || null,
  fundoCotas: state.fundoReducer?.fundoCotas || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormFundoCotas,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundoFormCotasEmitidas);
