import {
    OPERACAO_ROUTE_PATH,
    ATIVO_ROUTE_PATH,
} from './../routers/constantesRoutes';

export const EMPRESA_PRESTADORA_SIDEBAR_ITEM = 'PRESTADORA';
export const OPERACAO_SIDEBAR_ITEM = 'OPERACAO';
export const ATIVO_SIDEBAR_ITEM = 'ATIVO';

export const SIDEBAR_ITEMS = [
    {
        pathRoute: `/${OPERACAO_ROUTE_PATH}`,
        target: OPERACAO_SIDEBAR_ITEM,
        label: "Operação",
        icon: "chat",
    },
    {
        pathRoute: `/${ATIVO_ROUTE_PATH}`,
        target: ATIVO_SIDEBAR_ITEM,
        label: "Ativo",
        icon: "insert_drive_file",
    },
]