import React, { Component } from "react";
import { connect } from "react-redux";
import { axiosInterceptor } from "./utils/axiosInterceptor";
import "./assets/css/App.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Main from "./Main";
import LoginPage from "./components/business/login/pages/LoginPage";
import LoginPrimeiroAcessoPage from "./components/business/login/pages/LoginPrimeiroAcessoPage";
import useIsMobile from "./utils/isMobile";

const App = (props) => {
  axiosInterceptor();
  useIsMobile();

  let autenticado = localStorage.getItem(process.env.REACT_APP_URL_TOKEN);
  let usuario = JSON.parse(localStorage.getItem("userLogged"));

  if (autenticado && autenticado !== "null" && !props.accessPrimary) {
    return <Main credentials={usuario} />;
  } else if (props.accessPrimary) {
    return <LoginPrimeiroAcessoPage />;
  } else if ( autenticado === null || autenticado === "undefined" || autenticado === "null" ) {
    return <LoginPage />
  } else {
    return false;
  }
}

const mapStateToProps = (state) => ({
  accessPrimary: state.loginReducer.accessPrimary,
  invalidToken: state.loginReducer.invalidToken,
});

export default connect(mapStateToProps, null)(App);
