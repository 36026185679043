import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";

import { changeAtivoFormTab, goBackToList } from "../../../../../actions/ativo/AtivoAction";
import { showConfirmDialog } from "../../../../../actions/common/modal/ConfirmDialogAction";

import FloatButton from "../../../../common/button/FloatButton";
import FloatButtonGroup from "../../../../common/button/FloatButtonGroup";
import Container from "../../../../common/container/Container";
import ContainerRow from "../../../../common/container/ContainerRow";
import ConfirmDialog from "../../../../common/modal/ConfirmDialog";
import Form from "../../../../common/form/Form";
import FormContent from "../../../../common/form/FormContent";
import FormHeader from "../../../../common/form/FormHeader";
import FormTab from "../../../../common/form/FormTab";
import If from "../../../../common/If/If";

import AtivoFormCodigoB3Cetip from "./tabs/AtivoFormCodigoB3Cetip";
import AtivoFormGeral from "./tabs/AtivoFormGeral";
import AtivoFormObjetoAgropecuario from "./tabs/AtivoFormObjetoAgropecuario";
import AtivoFormObjetoImovel from "./tabs/AtivoFormObjetoImovel";

const ATIVO_FORM_GERAL = "GERAL";
const ATIVO_FORM_CODIGO_B3CETIP = "VÍNCULOS";
const ATIVO_FORM_OBJETO_IMOBILIARIO = "OBJ. IMOBILIÁRIO";
const ATIVO_FORM_OBJETO_AGROPECUARIO = "OBJ. AGROPECUÁRIO";

class AtivoForm extends Component {
  componentDidMount() {
    this.props.changeAtivoFormTab(ATIVO_FORM_GERAL);
  }

  isActivateForm(state) {
    return this.props.formActive === state;
  }

  render() {
    const {
      handleSubmit,
      readOnly,
      floatButtonVisible,
      title,
      showConfirmDialog,
      showCancelConfirmDialog,
      goBackToList
    } = this.props;

    return (
      <Container>
        <ContainerRow>
          <div className="col-md">
            <Form>
              <FormHeader title={title} col={"col-md"} offSet={20}>
                <FormTab
                  formTabNames={[
                    ATIVO_FORM_GERAL,
                    ATIVO_FORM_CODIGO_B3CETIP,
                    ATIVO_FORM_OBJETO_IMOBILIARIO,
                    ATIVO_FORM_OBJETO_AGROPECUARIO
                  ]}
                  formTabActive={this.props.formActive}
                  changeFormTabActive={this.props.changeAtivoFormTab}
                />
              </FormHeader>
              <FormContent handleSubmit={handleSubmit}>
                <If test={this.isActivateForm(ATIVO_FORM_GERAL)}>
                  <AtivoFormGeral readOnly={readOnly} />
                </If>
                <If test={this.isActivateForm(ATIVO_FORM_CODIGO_B3CETIP)}>
                  <AtivoFormCodigoB3Cetip readOnly={readOnly} />
                </If>
                <If test={this.isActivateForm(ATIVO_FORM_OBJETO_IMOBILIARIO)}>
                  <AtivoFormObjetoImovel readOnly={readOnly} />
                </If>
                <If test={this.isActivateForm(ATIVO_FORM_OBJETO_AGROPECUARIO)}>
                  <AtivoFormObjetoAgropecuario readOnly={readOnly} />
                </If>
                <FloatButtonGroup>
                  <FloatButton
                    activeClassName={"nd1 nds"}
                    title={"Cancelar"}
                    icon={"fa fa-ban edit edit-sub-menu"}
                    action={() =>
                      floatButtonVisible
                        ? showConfirmDialog(true)
                        : goBackToList()
                    }
                  />
                  <If test={floatButtonVisible}>
                    <FloatButton
                      activeClassName={"nd3 nds"}
                      title={"Salvar"}
                      icon={"material-icons edit edit-sub-menu"}
                      cloudDone={true}
                      action={handleSubmit}
                    />
                  </If>
                </FloatButtonGroup>
              </FormContent>
              <ConfirmDialog
                title="Aviso"
                contentText="Os dados do formulário serão perdidos, deseja realmente sair?"
                textButtonCancel="CANCELAR"
                textButtonConfirm="CONFIRMAR"
                showDialog={showCancelConfirmDialog}
                onClose={() => showConfirmDialog(false)}
                onConfirm={() => goBackToList()}
                type="warning"
                maxWidth="md"
              />
            </Form>
          </div>
        </ContainerRow>
      </Container>
    );
  }
}

AtivoForm = reduxForm({ form: "ativoForm" })(AtivoForm);

const mapStateToProps = state => ({
  formActive: state.ativoReducer.changeAtivoFormTab,
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeAtivoFormTab,
      showConfirmDialog,
      goBackToList
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AtivoForm)
);
