import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RemessaForm from "./form/RemessaForm";

class RemessaIncludePage extends Component {
  render() {
    return (
      <RemessaForm
        title={"Nova - Remessa"}
        readOnly={false}
        edit={false}
      />
    );
  }
}


export default withRouter(
  connect(null, null)(RemessaIncludePage)
);
