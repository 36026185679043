/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import PedidosForm from "./../../pedidos/pages/form/PedidosForm";
import {
  loadPedidosForm,
  editPedidos,
} from "../../../../actions/pedidos/PedidosAction";
import { authorizationFunction } from "../../../../actions/authorization/AuthorizationAction";
import { PEDIDOS, UPDATE } from "../../../../utils/actionsAndFeaturesAuth";

class PedidosUpdatePage extends Component {
  componentDidMount() {
    let idPedidos = this.props.match.params.idPedidos;
    const { authorizationFunction, loadPedidosForm } = this.props;

    async function fetchLoad() {
      await authorizationFunction(
        { idAcao: UPDATE, idFuncionalidade: PEDIDOS },
        () => loadPedidosForm(idPedidos)
      );
    }
  
    fetchLoad();
  }

  render() {
    const { editPedidos, pedidoCompra } = this.props;

    const statusValue = pedidoCompra?.status?.key;
    const identificador = pedidoCompra?.identificador;
    const hasApproval = pedidoCompra?.aprovacao || (!pedidoCompra?.aprovacao && pedidoCompra?.idTipoPagamento?.key == 3);

    const blockeds = [2, 3, 4, 5, 6, 8];

    const readOnly = blockeds.some((b) => b == statusValue);

    return (
      <PedidosForm
        title={`Editar - Pedido de Compra ${identificador ? `- ${identificador}` : ""}`}
        onSubmit={editPedidos}
        readOnly={readOnly && Boolean(hasApproval)}
        floatButtonVisible={true}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  pedidoCompra: state.pedidosReducer.pedidosFormTypes.pedidoCompra || {},
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      editPedidos,
      loadPedidosForm,
      authorizationFunction,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(PedidosUpdatePage)
);
