
import { initialize } from 'redux-form';
import { getBooleanValueFromString, getStringBooleanValue} from "./../../../../utils/helpers/BooleanHelper";
import { extractArrayEmailsFromString, concatArrayEmailsToString } from './../../../../utils/helpers/EmailHelper';

export function serializeFieldsComplementoForm(complemento, idOperacao = 0) {
    let complementoOperacao = {};

    if(complemento) {
        if(complemento.complementoMonitoramento){
            complementoOperacao = {
                ...complemento,
                
                complementoMonitoramento : {
                    ...complemento.complementoMonitoramento,
                    monitorar : (complemento.complementoMonitoramento)?
                            getStringBooleanValue(complemento.complementoMonitoramento.monitorar): "Não",
                    emailResponsavel : extractArrayEmailsFromString(complemento.complementoMonitoramento.emailResponsavel),
                    monitoramentoContatoList: complemento.complementoMonitoramento.monitoramentoContatoList ? complemento.complementoMonitoramento.monitoramentoContatoList.map(contato =>({
                        ...contato,
                        email: extractArrayEmailsFromString(contato.email)
                    })) : []
                },
                ratingAgencia: complemento.agenciaRating ? complemento.agenciaRating: null,
                ratingPassivo: complemento.agenciaRating && complemento.agenciaRating.ratingSelecionado ? complemento.agenciaRating.ratingSelecionado: null,
                agendaRatingsList: complemento.agendaRatings,
            };
        }
        else{
            complementoOperacao = {
                ...complemento,
                idOperacao: idOperacao,
                complementoMonitoramento: {
                    monitorar: "Não"
                }
            };
        }
    } else {
        complementoOperacao = {
            idOperacao: idOperacao,
            complementoMonitoramento: {
                monitorar: "Não"
            }
        };
    }

    return initialize("complementoForm", complementoOperacao);
}

export function deserializeFieldsComplementoForm(complemento) {
    var _idOperacao = complemento?.idOperacao && complemento?.idOperacao?.id ? complemento?.idOperacao?.id : complemento?.idOperacao;

    if(!complemento.complementoMonitoramento){
        complemento.complementoMonitoramento = { }
    }
    return {
        ...complemento,
        idOperacao : _idOperacao,
        complementoMonitoramento : {
            ...complemento.complementoMonitoramento,
            monitorar : (complemento.complementoMonitoramento.monitorar)?  
                        getBooleanValueFromString(complemento.complementoMonitoramento.monitorar) : false,
            emailResponsavel:(complemento.complementoMonitoramento.emailResponsavel)?  
                        concatArrayEmailsToString(complemento.complementoMonitoramento.emailResponsavel): "",
            monitoramentoContatoList: complemento.complementoMonitoramento.monitoramentoContatoList ? complemento.complementoMonitoramento.monitoramentoContatoList.map(contato =>({
                ...contato,
                email: concatArrayEmailsToString(contato.email),
                id: contato.id < 0 ? 0 : contato.id
            })) : [],
            monitoramentoRegrasList: complemento.complementoMonitoramento.monitoramentoRegrasList ? complemento.complementoMonitoramento.monitoramentoRegrasList.map(regra => ({
                ...regra,
                id: regra.id < 0 ? 0 : regra.id
            })) : [],
        },
        agenciaRating : {
            ...complemento.ratingAgencia,
            ratingSelecionado : {
                ...complemento.ratingPassivo
            }
        },
        dataAtribuicaoRating: complemento.dataAtribuicaoRating
    }
}

export function deserializeFieldsComplementoFilterExportExcel(filters) {
    return {
        idOperacao: filters && filters.operacoes ? filters.operacoes.map(o => o.key) : [],
    };
}