import React from "react";

const DocumentsInput = (props) => {
    const {
        getRootProps,
        isDragging,
        getInputProps,
        documentos,
        despesaOperacao,
        downloadPedidoDocument,
        onDeleteDocument,
        readOnly,
    } = props;

    return (
      <>
        <div
          {...getRootProps()}
          className="w-100 d-flex flex-column justify-content-start align-items-center text-center position-relative p-2 rounded my-2"
          style={{
            minHeight: "100px",
            maxHeight: "200px",
            transition: "border 0.3s ease-out",
            border: isDragging ? "2px dashed #630A37" : `${readOnly ? '1px solid #B8B8B8' : '2px dashed #cccccc'}`,
          }}
        >
          <input {...getInputProps()} />
          {(!readOnly) && (
            <p className="py-2 mb-0">
              Arraste os arquivos ou{" "}
              <u className="btn-link-opea" role="button">
                clique aqui
              </u>{" "}
              para upload
            </p>
          )}
          <div className="d-flex flex-column h-100 overflow-auto w-100 p-2">
            {documentos?.length > 0 &&
              documentos?.map((file, i) => {
                return (
                  <div
                    key={`file-${i}`}
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      backgroundColor: "#E7E7E7",
                      border: "1px solid #B8B8B8",
                    }}
                    className="p-2 d-flex flex-row align-items-center justify-content-between rounded fw-400 my-1"
                  >
                    <span>{file.nomeArquivo || file.name}</span>
                    <div className="d-flex flex-row">
                      {(file?.nomeArquivo && despesaOperacao?.id) && (
                        <i
                          role="button"
                          className="fa fa-cloud-download f-20 mr-3"
                          onClick={() => downloadPedidoDocument({
                            idDespesaOperacao: despesaOperacao?.id,
                            nomeArquivo: file?.nomeArquivo
                          })}
                        ></i>
                      )}

                      {(!readOnly) && (
                        <i
                          role="button"
                          onClick={() => onDeleteDocument(i)}
                          className="fa fa-minus-circle f-20 d-flex align-items-center mr-1"
                        ></i>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {(props.meta && props.meta.error && props.meta.touched &&
            <p className="error-msg sp-error text-danger f-12 mb-0">{props.meta.error}</p>)
        }
      </>
    )
};

export default DocumentsInput;
