import React from "react";

export default (props) => {
    const color = props.colorBar ? props.colorBar : 'grey';
    const { marginRightClass = '', marginLeftClass = '' } = props;
    const { marginTopClass = 'mt-2' } = props;
    return (
        <div className={`border ${color} ${marginRightClass} ${marginLeftClass} ${marginTopClass}`}>
            <div className={props.titleClass}>
                <strong>{props.title}:</strong>
            </div>
            <div className={props.valueClass}>
                <strong>{props.value}</strong>
            </div>
        </div>
    );
}
