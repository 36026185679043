import {useClearCache} from "react-clear-cache";

export function ControlClearCache() {

  /* CONTROLA A LIMPEZA DA CACHE VIA HOOK */
    //Set to true to auto-reload the page whenever an update is available.
    const { isLatestVersion, emptyCacheStorage } = useClearCache({ auto: true });
   /* CONTROLA A LIMPEZA DA CACHE VIA HOOK */

    if (!isLatestVersion) {
      emptyCacheStorage()
    }
}