import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import Button from "../../../../common/button/Button";
import { Close } from "@material-ui/icons";
import { DropdownList } from "react-widgets";
import PercentageInput from "../../../../common/input/PercentageInput";
import {
  editOrdemAssembleia,
  getModalOrdem,
  getOrdensAssembleia,
  saveOrdemAssembleia,
} from "../../../../../actions/assembleia/AssembleiaAction";

let ModalCriacaoOrdem = (props) => {
  const {
    close,
    open,
    idAssembleia,
    item,
    // 
    tituloList,
    tipoQuorumList,
    assembleiaOrdem,
    // 
    editOrdemAssembleia,
    saveOrdemAssembleia,
    getOrdensAssembleia,
    getModalOrdem,
  } = props;

  const [titulo, setTitulo] = React.useState(null);
  const [tipoQuorum, setTipoQuorum] = React.useState(null);
  const [quorumAprovacao, setQuorumAprovacao] = React.useState(null);
  const [descricao, setDescricao] = React.useState("");

  React.useEffect(() => {
    const fetchData = async () => {
      await getModalOrdem(item?.id || null)
    }

    fetchData()
  }, [])

  React.useEffect(() => {
    if (item?.id) {
      const { assembleiaAssunto, tipoQuorum, quorumAprovacao, descricao } = assembleiaOrdem
      
      setTitulo(assembleiaAssunto);
      setTipoQuorum(tipoQuorum);
      setQuorumAprovacao(quorumAprovacao);
      setDescricao(descricao || '');
    }
  }, [assembleiaOrdem])

  const closeModal = () => {
    setTitulo(null);
    setTipoQuorum("");
    setQuorumAprovacao(null);
    setDescricao("");
    close();
  };

  const messagesDropdown = {
    emptyList: "Lista vazia",
    emptyFilter: "Nenhum registro encontrado",
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth={true}
        onClose={() => closeModal()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              {item ? "Editar" : "Cadastrar"} - Ordem da Assembleia
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => closeModal()}
            />
          </div>

          <div className="py-2 container-fluid">
            <div className="row">
              <div className="col align-self-start mt-3px mb-xl-3 mb-3 pl-0 pr-0">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Assunto{" "}
                  <span className="text-warning font-weight-bolder">*</span>
                </label>
                <DropdownList
                  value={titulo || ""}
                  onChange={(e) => setTitulo(e?.id ? e : null)}
                  valueField="id"
                  textField={"assunto"}
                  messages={messagesDropdown}
                  containerClassName="h-38-imp"
                  filter={"contains"}
                  data={[{ id: null, assunto: "Selecione..." }, ...tituloList]}
                />
              </div>
            </div>
            <div className="row mt-xl-3 mt-0">
              <div className="col-xl-6 col-12 align-self-start mt-3px mb-xl-3 mb-3 pl-0 pr-0 pr-xl-2">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Tipo Quórum{" "}
                  <span className="text-warning font-weight-bolder">*</span>
                </label>
                <DropdownList
                  value={tipoQuorum || ""}
                  onChange={(e) => setTipoQuorum(e?.key ? e : null)}
                  valueField="key"
                  textField={"value"}
                  messages={messagesDropdown}
                  containerClassName="h-38-imp"
                  filter={"contains"}
                  data={[{ key: null, value: "Selecione..." }, ...tipoQuorumList]}
                />
              </div>

              <div className="col-xl-6 col-12 align-self-start mt-1px mb-xl-3 mb-3 pl-0 pr-0">
                <PercentageInput
                  input={{
                    value: quorumAprovacao,
                    onChange: (e) => setQuorumAprovacao(e),
                  }}
                  decimalSeparator={","}
                  label="Quórum Mínimo de Instalação"
                  inputId="percentageInput"
                  required={true}
                  limitToHundred={true}
                  decimalScale={4}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 align-self-start mt-3px mb-xl-3 mb-3 pl-0 pr-0">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Descrição{" "}
                  <span className="text-warning font-weight-bolder">*</span>
                </label>
                <textarea
                  className="w-100 rounded brd-1 p-2"
                  style={{ resize: "none" }}
                  rows={7}
                  maxLength={10000}
                  value={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                ></textarea>
                <p className="text-right m-0 p-0 f-10 w-100 lh-normal">
                  {`${descricao?.length || 0}/${10000}`}
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Cancelar"}
              action={() => closeModal()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Salvar"}
              disabled={
                !titulo || !tipoQuorum || !quorumAprovacao || !descricao
              }
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={async () => {
                const payload = { idAssembleia, idAssembleiaAssunto: titulo?.id, tipoQuorum, quorumAprovacao, descricao };

                item
                  ? await editOrdemAssembleia({ id: item.id, ...payload })
                  : await saveOrdemAssembleia(payload);

                getOrdensAssembleia(idAssembleia)
                closeModal()
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  tituloList: state.assembleiaReducer.modalOrdem?.assembleiaAssuntoList || [],
  tipoQuorumList: state.assembleiaReducer.modalOrdem?.tipoQuorumEnumList  || [],
  assembleiaOrdem: state.assembleiaReducer.modalOrdem?.assembleiaOrdem  || [],
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      editOrdemAssembleia,
      saveOrdemAssembleia,
      getOrdensAssembleia,
      getModalOrdem,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(ModalCriacaoOrdem);
