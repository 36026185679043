/* eslint eqeqeq: 0 */

export function deserializeFieldsAprovacaoFormFilter(filters, page) {
    return {
        ...filters,
        idsOperacoes: filters && filters.idsOperacoes ? filters.idsOperacoes.map(o => o.key) : [],
        idsPatrimonioSeparados: filters && filters.idsPatrimonioSeparados ? filters.idsPatrimonioSeparados.map(o => o.key) : [],
        idSolicitante: filters.idSolicitante,
        idAprovador: filters.idAprovador,
        status: filters.status,
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        },
    };
}

// recebimento
export function serializeContaOrigem(conta) {
    let values = []
  
    if (conta) {
      return {
        ...conta,
        nomeExibicao: `${`${conta?.tipoContaArrecadadora?.descricao} ` || ''}(${conta?.banco?.codigo ? `${conta?.banco?.codigo}_` : ''}${conta?.agencia}${conta?.agenciaDigito ? `-${conta.agenciaDigito}` : ''}/${conta?.contaCorrente}${conta?.digito ? `-${conta.digito}` : ''})`
      }
    }
  
    return values; 
}

export function deserializeModalPedidoCompra(pedido) {
    let values = {}

    if (values) {
        values = {
            ...pedido,
            contaOrigem: serializeContaOrigem(pedido?.contaOrigem),
        }
    }

    return values;
}