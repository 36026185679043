import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field, formValueSelector } from "redux-form";

import Form from "../../../../../common/form/Form";
import FormHeader from "../../../../../common/form/FormHeader";
import FormContent from "../../../../../common/form/FormContent";
import Input from "../../../../../common/input/Input";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import ConfirmDialog from "../../../../../common/modal/ConfirmDialog";
import Button from "../../../../../common/button/Button";

import {
  handleSearchRepots,
  getReports,
  getFiduciaryReports,
  showGerarReportDialog,
  createReport,
  clearReport,
  selectOperacaoNoForm,
  sinteticRepot,
  RELATORIO_ANALITICO_TIPO_AGING,
  RELATORIO_ANALITICO_TIPO_GESTAO_FIDUCIARIA,
  exportRelatorioGestaoFiduciariaToExcel,
} from "../../../../../../actions/relatorios/reportAction";
import { getOperacoes } from "../../../../../../actions/operacao/OperacaoAction";
import GenericPickerInput from "../../../../../common/input/GenericPickerInput";
import { authorizationFunction } from "../../../../../../actions/authorization/AuthorizationAction";
import {
  RELATORIOS_GERENCIAIS,
  GENERATE,
} from "../../../../../../utils/actionsAndFeaturesAuth";
import ModalGeracaoLote from "../modals/ModalGeracaoLote";
import MultSelectListInput from "../../../../../common/input/MultSelectListInput";

let SectionReportListFilter = (props) => {
  const {
    showGerarReportDialog,
    showGerarReport,
    typeReport,
    dataBase,
    gestor,
    status,
    statusOperacao,
    getReports,
    handleSearchRepots,
    createReport,
    sinteticRepot,
    clearReport,
    operacoes,
    authorizationFunction,
    exportRelatorioGestaoFiduciariaToExcel,
    relatorios,
    // LISTS
    relatorioGerencialList,
    usuarios,
    relatoriosGestaoFiduciariaStatutsList,
    statusOperacaoList,
  } = props;

  const [openModal, setOpenModal] = React.useState(false);

  const callRepotConfirmDialog = () => {
    if (typeReport && typeReport.key) {
      showGerarReportDialog(true);
    }
  };

  const callGenerateReport = (params) => {
    if (typeReport && typeReport.key) {
      if (typeReport.key === RELATORIO_ANALITICO_TIPO_AGING) {
        createReport({
          typeReport: params.typeReport,
          dataBase: params.dataBase,
        });
      }
      if (typeReport.key === RELATORIO_ANALITICO_TIPO_GESTAO_FIDUCIARIA) {
        sinteticRepot(relatorios);
      }
      showGerarReportDialog(false);
    }
  };

  const callFunctionReport = () => {
    let acessosUser = JSON.parse(localStorage.getItem("userLogged")).acessos;
    let canGenerate = acessosUser.some((acesso) => acesso === 3 || acesso === 1);
    let auth = { idAcao: GENERATE, idFuncionalidade: RELATORIOS_GERENCIAIS };

    if (typeReport && typeReport.key) {
      if (typeReport.key === RELATORIO_ANALITICO_TIPO_AGING) {
        auth = { idAcao: canGenerate ? GENERATE : 3, idFuncionalidade: RELATORIOS_GERENCIAIS };
      }
    }

    authorizationFunction(auth, () => callRepotConfirmDialog());
    setOpenModal(false);
  };

  const tipoRelatorioGestaoFiduciaria =
    typeReport && typeReport.key === RELATORIO_ANALITICO_TIPO_GESTAO_FIDUCIARIA;
  const tipoRelatorioAging =
    typeReport && typeReport.key === RELATORIO_ANALITICO_TIPO_AGING;

  return (
    <div className="w-100 px-4">
      <Form>
        <FormHeader title={"Relatório Gerencial"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getReports}
        >
          <div className="row">
            <div className="col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="typeReport"
                name="typeReport"
                label="Relatório"
                options={relatorioGerencialList}
                textField="value"
                valueField="key"
                filter="contains"
                onChange={() => clearReport()}
              />
            </div>
            <div className="col-lg-3">
              <Field
                component={Input}
                className={"filter-input"}
                inputId="dataBase"
                name="dataBase"
                type={"month"}
                label="Data Base"
              />
            </div>
            {tipoRelatorioGestaoFiduciaria && (
              <>
                <div className="col-lg-3">
                  <Field
                    component={DropdownListInput}
                    inputId="gestor"
                    name="gestor"
                    options={usuarios}
                    label="Gestor"
                    textField="nome"
                    valueField="id"
                    filter="contains"
                  />
                </div>
                <div className="col-lg-3">
                  <Field
                    component={DropdownListInput}
                    inputId="status"
                    name="status"
                    options={relatoriosGestaoFiduciariaStatutsList}
                    label="Status do Job"
                    textField="value"
                    valueField="key"
                    filter="contains"
                  />
                </div>
                <div className="col-lg-3">
                  <Field
                    component={MultSelectListInput}
                    inputId="statusOperacao"
                    name="statusOperacao"
                    label="Status da Operação"
                    options={statusOperacaoList}
                    textField="value"
                    valueField="key"
                    filter="contains"
                  />
                </div>
                <div className="col-lg-9 mb-3" style={{ paddingTop: 23 }}>
                  <GenericPickerInput
                    loadData={props.getOperacoes}
                    options={props.options}
                    formName={"RelatorioForm"}
                    fieldName={"operacao"}
                    isMulti={true}
                    multiControl={true}
                    placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                    loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                    noOptionsMessage="Nenhum registro encontrado"
                    value={props.operacoes}
                    selectedOptionForm={(v) => props.selectOperacaoNoForm(v, 'RelatorioForm', 'operacao')}
                  />
                </div>
              </>
            )}
          </div>
          <div className="d-flex flex-row align-items-center">
            <Button
              action={() => {
                handleSearchRepots({
                  typeReport,
                  dataBase,
                  operacoes,
                  gestor,
                  status,
                  statusOperacao,
                });
              }}
              classes="p-bg-imp rounded"
              icon={"search"}
              name={"Buscar"}
            />
            {tipoRelatorioGestaoFiduciaria &&
              relatorios &&
              relatorios.length > 0 && (
                <Button
                  action={() => {
                    relatorios.length > 1
                      ? setOpenModal(true)
                      : callFunctionReport();
                  }}
                  disabled={relatorios.length <= 0}
                  classes="p-bg-imp rounded ml-2"
                  icon={"file-text"}
                  name={"Gerar"}
                />
              )}

            {tipoRelatorioAging && dataBase && (
              <Button
                action={() => callFunctionReport()}
                classes="p-bg-imp rounded ml-2"
                icon={"file-text"}
                name={"Gerar"}
              />
            )}

            {tipoRelatorioGestaoFiduciaria && (
              <label
                role="button"
                className="mb-0 p-color-lighten ml-3"
                onClick={() => exportRelatorioGestaoFiduciariaToExcel()}
              >
                Exportar para Excel
              </label>
            )}

            <ConfirmDialog
              title="Aviso"
              contentText="Todos os dados para esta database serão sobrescritos e poderá demorar alguns instantes. Deseja continuar?"
              textButtonCancel="CANCELAR"
              textButtonConfirm="CONFIRMAR"
              showDialog={showGerarReport}
              onClose={() => showGerarReportDialog(false)}
              onConfirm={() =>
                callGenerateReport({
                  typeReport,
                  dataBase,
                  operacoes,
                  gestor,
                  status,
                })
              }
              type="warning"
              maxWidth="md"
            />

            <ModalGeracaoLote
              open={openModal}
              close={() => setOpenModal(false)}
              confirm={() => callFunctionReport()}
            />
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

SectionReportListFilter = reduxForm({ form: "RelatorioForm" })(
  SectionReportListFilter
);
const selector = formValueSelector("RelatorioForm");

const mapStateToProps = (state) => ({
  relatorioGerencialList:
    state.reportReducer.tiposFormTypes.relatorioGerencialList || [],
  relatorios: state.reportReducer.relatorios || [],
  usuarios: state.reportReducer.tiposFormTypes.usuarios || [],
  relatoriosGestaoFiduciariaStatutsList: state.reportReducer.tiposFormTypes.relatoriosGestaoFiduciariaStatutsList || [],
  statusOperacaoList: state.reportReducer.tiposFormTypes.statusOperacao || [],
  reportData: state.reportReducer.listReport,
  showGerarReport: state.reportReducer.showGerarReportDialog,
  typeReport: selector(state, "typeReport"),
  dataBase: selector(state, "dataBase"),
  operacoes: selector(state, "operacao"),
  gestor: selector(state, "gestor"),
  status: selector(state, "status"),
  statusOperacao: selector(state, "statusOperacao"),
  options: state.operacaoReducer.operacoes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleSearchRepots,
      getReports,
      getFiduciaryReports,
      showGerarReportDialog,
      createReport,
      sinteticRepot,
      authorizationFunction,
      getOperacoes,
      selectOperacaoNoForm,
      exportRelatorioGestaoFiduciariaToExcel,
      clearReport,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionReportListFilter);
