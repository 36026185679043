import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ContainerPage from "../../../../common/container/ContainerPage";
import IndiceListFilter from "./form/IndiceListFilter";
import IndiceList from "./list/IndiceList";

import { loadFilterFormIndice, cleanResult } from '../../../../../actions/precificacao/IndiceAction';
import If from "../../../../common/If/If";
import IndiceDiFuturoList from "./list/IndiceDiFuturoList";

class IndicePage extends Component {

    componentDidMount() {
        this.props.loadFilterFormIndice();
        this.props.cleanResult();
    }

    render() {
        return (
            <ContainerPage>
                <IndiceListFilter />
                <If test={!this.props.taxaFilterDiFuturo}>
                    <IndiceList />
                </If>
                <If test={this.props.taxaFilterDiFuturo}>
                    <IndiceDiFuturoList />
                </If>
            </ContainerPage>
        );
    }
}

const mapStateToProps = state => ({
    taxaFilterDiFuturo: state.indiceReducer.taxaFilterDiFuturo,
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
    loadFilterFormIndice, cleanResult
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(IndicePage);