import axios from "axios";
import history from "../../components/common/routers/history";
import { deserializeFieldsRemessaFormFilter, serializeFieldsRemessaForm, deserializeFieldsRemessaForm } from "../../components/business/remessa/mappings/RemessaMap";
import { showAllMessages } from "../message/ErrorMessagesAPIAction";

import {
  REMESSA_ROUTE_PATH,
  EDITAR_ROUTE_PATH,
  LISTA_ROUTE_PATH,
  NOVO_ROUTE_PATH,
  OPERACAO_ROUTE_PATH,
  PASSIVO_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";

import { downloadFile } from "../../utils/helpers/FileHelper";
import { showConfirmDialog } from "../common/modal/ConfirmDialogAction";

export const REMESSA_RESET = "REMESSA_RESET";
export const REMESSA_CONSULT_CURRENT = "REMESSA_CONSULT_CURRENT";
export const REMESSA_FETCHED = "REMESSA_FETCHED";
export const SALVA_FILTROS_REMESSA = "SALVA_FILTROS_REMESSA";
export const LOAD_FILTER_FORM_REMESSA = "LOAD_FILTER_FORM_REMESSA";
export const LOAD_FORM_REMESSA = "LOAD_FORM_REMESSA";

const PEDIDO_API =
  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PEDIDO_COMPRA_API;

export function loadFilterFormRemessa() {
  return (dispatch, getState) => {
    return axios.get(`${PEDIDO_API}${REMESSA_ROUTE_PATH}/filtro`).then((result) =>
      dispatch([
        {
          type: LOAD_FILTER_FORM_REMESSA,
          payload: result.content,
        },
      ])
    );
  };
}

export function salvaFiltrosRemessa(filters) {
  const params = deserializeFieldsRemessaFormFilter(filters, 1);

  return (dispatch, getState) => {
    return dispatch([
      {
        type: SALVA_FILTROS_REMESSA,
        payload: params,
      },
    ]);
  };
}

export function importarRemessa() {
  return (dispatch, getState) => {
    return axios.get(`${PEDIDO_API}${REMESSA_ROUTE_PATH}/importar`).then((result) =>
      dispatch([
        showAllMessages(result.messages),
        // {
        //   type: LOAD_FILTER_FORM_REMESSA,
        //   payload: result.content,
        // },
      ])
    );
  };
}

export function getRemessa(filters, page = 1) {
  const params = deserializeFieldsRemessaFormFilter(filters, page);

  return (dispatch) => {
    return axios.post(`${PEDIDO_API}${REMESSA_ROUTE_PATH}/busca`, params).then((result) =>
      dispatch([
        {
          type: REMESSA_FETCHED,
          payload: result,
        },
        {
          type: REMESSA_CONSULT_CURRENT,
          payload: {
            ...filters,
            pagingConfig: { pageIndex: page, pageSize: 10 },
          },
        },
      ])
    );
  };
}

export function sairDaPagina() {
  return (dispatch) => {
    return dispatch([
      { type: REMESSA_RESET },
      showConfirmDialog(false),
      history.push(`/${REMESSA_ROUTE_PATH}/${LISTA_ROUTE_PATH}`),
    ]);
  };
}

export function actionRemessa(data) {
  return (dispatch) => {
    const { action, id } = data;

    return axios({
      method: 'POST',
      url: `${PEDIDO_API}${REMESSA_ROUTE_PATH}/${action}/${id}`,
      data: data
    }).then((result) => {
      dispatch([
        showAllMessages(result.messages),
        sairDaPagina(),
        getRemessa(),
      ]);
    })
  };
}

export function loadFormRemessa(id) {
  let appendUrl = "";

  if (id) appendUrl = appendUrl + `/${id}`;

  return (dispatch) => {
    let routeAction = id ? `${EDITAR_ROUTE_PATH}/${id}` : NOVO_ROUTE_PATH;

    return axios.get(`${PEDIDO_API}${REMESSA_ROUTE_PATH}/` + appendUrl).then((result) => {
      dispatch([
        {
          type: LOAD_FORM_REMESSA,
          payload: result.content
        },
      ])

      dispatch([
        history.push(`/${REMESSA_ROUTE_PATH}/${routeAction}`),
        serializeFieldsRemessaForm(result.content)
      ])
    });
  };
}

export function editRemessa(id) {
  return (dispatch) => {
    dispatch([
      history.push(`/${REMESSA_ROUTE_PATH}/${EDITAR_ROUTE_PATH}/${id}`),
    ])
  };
}

export function createRemessa() {
  return (dispatch) => {
    return axios.post(`${PEDIDO_API}${REMESSA_ROUTE_PATH}/gerar`).then((result) => {
      let routeAction = result.content?.id ? `${EDITAR_ROUTE_PATH}/${result.content?.id}` : NOVO_ROUTE_PATH;

      dispatch([
        {
          type: LOAD_FORM_REMESSA,
          payload: result.content
        },
      ])

      dispatch([
        history.push(`/${REMESSA_ROUTE_PATH}/${routeAction}`),
        serializeFieldsRemessaForm(result.content),
      ])
    });
  };
}

export async function uploadArquivoRemessa(item, load = true) {
  return async (dispatch) => {
    return await axios.post(`${PEDIDO_API}${REMESSA_ROUTE_PATH}/upload`, item).then((result) => {
      dispatch([
        load ? loadFormRemessa(item?.idRemessa) : null,
      ])
    });
  };
}

export async function removeArquivoRemessa(item) {
  return async (dispatch) => {
    return await axios.post(`${PEDIDO_API}${REMESSA_ROUTE_PATH}/remover-arquivo`, item).then((result) => {
      dispatch([
        loadFormRemessa(item?.idRemessa),
      ])
    });
  };
}

export async function downloadArquivoRemessa(item) {
  return async (dispatch) => {
    return await axios.post(`${PEDIDO_API}${REMESSA_ROUTE_PATH}/download`, item).then((result) => {
      dispatch([
        downloadFile(result?.content?.arquivo, item?.nome)
      ])
    });
  };
}