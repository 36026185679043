import { PRECO_KEY, TAXA_KEY } from "./../../../../actions/precificacao/CalculadoraAction";
import { convertCurrencyToNumber, convertPercentStringToFloat } from "./../../../../utils/helpers/NumberHelper";

export function deserializeFieldsCalculadoraFilter(filters) {
    let taxa = null;
    let preco = null;
    let percentualFlutuante = null;

    if (filters.tipoRelatorio) {
        if (filters.tipoRelatorio === PRECO_KEY) {
            taxa = convertPercentStringToFloat(filters.taxa);
            percentualFlutuante = convertPercentStringToFloat(filters.percentualFlutuante);
        } else if (filters.tipoRelatorio === TAXA_KEY) {
            preco = convertCurrencyToNumber(filters.preco);
        }
    } 

    return {
        idOperacao: filters && filters.operacao ? filters.operacao.key : null,
        tipoRelatorio: filters.tipoRelatorio,
        taxa: taxa,
        preco: preco,
        percentualFlutuante: percentualFlutuante,
        data: filters.data,
        projecao: filters.projecao ? true : false
    }
}