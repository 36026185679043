import React from "react";
import { connect } from "react-redux";
import { Checkbox, Dialog, FormControlLabel } from "@material-ui/core";
import Button from "../../../../../common/button/Button";
import { Close } from "@material-ui/icons";

import { withRouter } from "react-router-dom";
import { convertCurrencyNumber } from "../../../../../../utils/helpers/NumberHelper";
import { finalizaDistribuicao } from "../../../../../../actions/operacao/PassivoAction";
import { bindActionCreators } from "redux";
import { formValueSelector } from "redux-form";

let ModalFinalizaDistribuicao = (props) => {
  const {
    close,
    open,
    passivoOperacao,
    finalizaDistribuicao,
    quantidadeTotalSubscrita,
  } = props;

  const [radioChecked, setRadioChecked] = React.useState(false);

  const percentualIntegralizado = ((passivoOperacao?.quantidadeDistribuida / passivoOperacao?.quantidadeEmitida) * 100) || 0;  

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => close()}
      >
        <div className="p-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <p className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
              Finalização da Distribuição 
            </p>
            <Close
              role="button"
              className="text-danger"
              onClick={() => close()}
            />
          </div>

          <div className="py-3">
            <div className="row px-0 pt-2">
              <div className="col-lg">
                <p className="mb-1">Quantidade Emitida</p>
                <p className="fw-400 mb-0">
                  {passivoOperacao?.quantidadeEmitida ? convertCurrencyNumber(passivoOperacao?.quantidadeEmitida, "", 0) : "--"}
                </p>
              </div>
              <div className="col-lg">
                <p className="mb-1">Quantidade Subscrita</p>
                <p className="fw-400 mb-0">
                  {quantidadeTotalSubscrita ? convertCurrencyNumber(quantidadeTotalSubscrita, "", 0) : "--"}
                </p>
              </div>
              <div className="col-lg">
                <p className="mb-1">Quantidade Integralizada</p>
                <p className="fw-400 mb-0">
                  {passivoOperacao?.quantidadeDistribuida ? convertCurrencyNumber(passivoOperacao?.quantidadeDistribuida, "", 0) : "--"}
                </p>
              </div>
            </div>

            <div className="mt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    checked={radioChecked}
                    onChange={(e) => setRadioChecked(e.target.checked)}
                  />
                }
                label={`Certifico que a distribuição da operação foi finalizada, tendo integralizado ${percentualIntegralizado.toFixed(2)}% da quantidade emitida.`}
              />
            </div>
          </div>

          <div className="d-flex flex-row align-content-center justify-content-between">
            <Button
              name={"Voltar"}
              action={() => close()}
              classes="text-danger bg-white px-2-5 f-14 py-1-5 text-capitalize border border-danger rounded btn-search m-0"
            />
            <Button
              name={"Confirmar"}
              disabled={!radioChecked}
              action={async () => {
                await finalizaDistribuicao();
                close();
              }}
              classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const selector = formValueSelector("passivoForm");

const mapStateToProps = (state) => ({
  passivoOperacao: state.passivoReducer.passivoFormTypes.passivoOperacao || {},
  quantidadeTotalSubscrita: selector(state, "quantidadeTotalSubscrita") || [],
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      finalizaDistribuicao
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ModalFinalizaDistribuicao)
);
