import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import ContainerPage from './../../../common/container/ContainerPage';

import GerenciamentoJobList from './list/GerenciamentoJobList';
import GerenciamentoJobListFilter from './form/GerenciamentoJobListFilter';
import { loadFilterFormGerenciamentoJob} from '../../../../actions/job/GerenciamentoJobAction';


class GerenciamentoJobPage extends Component {

    
    componentDidMount() {
        this.props.loadFilterFormGerenciamentoJob();
    }

    render() {
        return (
            <ContainerPage>
                <GerenciamentoJobListFilter />
                <GerenciamentoJobList />
            </ContainerPage>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    loadFilterFormGerenciamentoJob
}, dispatch);


export default connect(null, mapDispatchToProps)(GerenciamentoJobPage);
