import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ContainerPage from "../../../../common/container/ContainerPage";
import CalculadoraListFilter from "./form/CalculadoraListFilter";
import CalculadoraList from "./list/CalculadoraList";

class CalculadoraPage extends Component {

    componentDidMount() {
    }

    render() {
        return (
            <ContainerPage>
                <CalculadoraListFilter />
                <CalculadoraList />
            </ContainerPage>
        );
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(null, mapDispatchToProps)(CalculadoraPage);