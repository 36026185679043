import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import Input from "../../../../../../common/input/Input";
import DropdownListInput from "../../../../../../common/input/DropdownListInput";
import Textarea from "../../../../../../common/input/Textarea";

function RegrasMonitoramentosForm(props) {
  const {
    readOnly,
    regraAtividadeList,
    regraCondicaoList,
    regraCondicao,
    umaRegra,
    umaRegraChange,
  } = props;

  React.useEffect(() => {
    umaRegraChange(umaRegra);
  }, [umaRegra, umaRegraChange]);

  return (
    <div>
      <div className="d-flex w-100 align-items-center">
        <div className="col more-fields px-0">
          <div className="row">
            <div className="col-xl-6 col-lg-4">
              <Field
                component={Input}
                inputId="regra"
                name="umaRegra.regra"
                type="text"
                label="Regra"
                readOnly={readOnly}
                maxLength={500}
                required
              />
            </div>

            <div className="col-xl-6 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="regraAtividade"
                name="umaRegra.regraAtividade"
                label="Atividade"
                textField="descricao"
                valueField="id"
                options={regraAtividadeList}
                readOnly={readOnly}
                required
                listProps={{
                  style: {
                    maxHeight: '160px',
                    overflowY: 'auto',
                  },
                }}
              />
            </div>

            <div className="col-xl-6 col-lg-4">
              <Field
                component={Input}
                inputId="valorReferencia"
                name="umaRegra.valorReferencia"
                label="Valor de Referência"
                type="text"
                readOnly={readOnly}
                maxLength={500}
                required={regraCondicao && regraCondicao?.id !== 3}
              />
            </div>

            <div className="col-xl-6 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="regraCondicao"
                name="umaRegra.regraCondicao"
                label="Condição"
                options={regraCondicaoList}
                textField="descricao"
                valueField="id"
                readOnly={readOnly}
                required
                listProps={{
                  style: {
                    maxHeight: '90px',
                    overflowY: 'auto',
                  },
                }}
              />
            </div>

            <div className="col-xl-12 col-lg-4">
              <Field
                component={Textarea}
                inputId="descricao"
                name="umaRegra.descricao"
                label="Descrição"
                type="text"
                rows={3}
                readOnly={readOnly}
                maxLength={500}
                height={50}
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const selector = formValueSelector("complementoForm");

const mapStateToProps = (state) => ({
  umaRegra: selector(state, "umaRegra"),
  regraCondicao: selector(state, "umaRegra.regraCondicao"),
  regraAtividadeList:
    state.complementoReducer.complementoFormTypes.regraAtividadeList,
  regraCondicaoList:
    state.complementoReducer.complementoFormTypes.regraCondicaoList,
  indexRegra: state.complementoReducer.showModalComplementoRegra.indexRegra,
});

export default connect(mapStateToProps, null)(RegrasMonitoramentosForm);
